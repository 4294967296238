

import React, { useState, useEffect } from 'react';
import { Box, Button,  Snackbar, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import Loader from '../SewaPage/Loader.jsx';
import { apiSewa } from 'api/index.js';



const AddCategory = ({setShowCategoryForm}) => {
    const [categoryName, setCategoryName] = useState("");
    const [categoryDescription, setCategoryDescription] = useState("");
    const [confirmCategoryName, setConfirmCategoryName] = useState("");
    const [categoryList, setCategoryList] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [loading, setLoading] = useState(false);

    // const { instituteId } = useParams();/

    const  instituteId = 15292;

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await apiSewa.get(`/categories`);
                setCategoryList(response.data); 
            } catch (error) {
                // setSnackbarMessage("Error fetching categories. Please try again.");
                setOpenSnackbar(true);
            }
        };

        fetchCategories();
    }, []);

    const handleSubmit = async () => {
        if (categoryName === confirmCategoryName) {
            setLoading(true);
            try {
                const response = await apiSewa.post(`/categories/create/${instituteId}`, { name: categoryName  , description:categoryDescription});
                console.log(response);
                const newCategory = response.data;
                setCategoryList((prevList) => [...prevList, newCategory]);
                setCategoryName("");
                setCategoryDescription("")
                setConfirmCategoryName("");
                setSnackbarMessage("Category added successfully!");
                setLoading(false);
            } catch (error) {
                setSnackbarMessage("Error adding category. Please try again.");
            } finally {
                setLoading(false);
                setOpenSnackbar(true);
            }
        } else {
            setSnackbarMessage("Confirm category and category do not match!");
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };



const handleBack = () => {
  setShowCategoryForm(false);
}

if (loading) {
  return <Loader text={'Wait for a Moment'} />;
}

    return (
<Box sx={{
   width: "95%",
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
   justifyContent:"center",
   marginTop:"2rem",
  // postion:"relative"
  
}}>



<Typography
            sx={{
                fontFamily: 'Poppins',
                fontSize: '1rem',
                fontWeight: 400,
                lineHeight: '24px',
                textAlign: 'left',
                color: "#575757",
                marginBottom:"3rem",
            }}
        >
            Selecting your category helps us personalize opportunities for you, ensuring that you get the most relevant resources and recommendations from the start.
        </Typography>



        <Box
        sx={{
          position: "relative", 
          background: "#f8f8ff",
          borderRadius: "1.5rem",
          width: "100%",
          display: "flex",          
          flexDirection: {xl :"row", sm:"column" , md:"row" },
          justifyContent:"center",
          alignItems: "center",
          boxSizing: "border-box",
          ":before": {
            content: '""', 
            background: "linear-gradient(122.52deg, rgba(35, 86, 254, 0.7) 1.25%, rgba(35, 86, 254, 0.1) 98.44%)", 
            borderRadius: "1.5rem",
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: "1", 
            margin: "-1rem",
          }
        }}
        >
<Box
  sx={{
    position: "absolute",
    top: { xl: "15%", lg: "13%", md: "20%", xs: "91%" },
    zIndex: "99",
    display: "flex",
    justifyContent:  "start", 
    width: "100%", 
  }}
>
  <Button
    variant="contained"
    onClick={handleBack}
    sx={{
      borderTopRightRadius: "2rem",
      borderBottomRightRadius: "2rem",
      backgroundColor: "rgba(35, 86, 254, 0.7)",
      padding: { xs: "0.3rem 1rem", md: "0.5rem 0.75rem" }, 
      fontSize: { xs: "0.75rem", md: "1rem" },
      minWidth: { xs: "8rem", md: "10rem" }, 
    }}
  >
    <ArrowBackIcon
      sx={{
        alignSelf: "center",
        marginRight: "1rem",
        fontSize: { xs: "1rem", md: "1.1rem" },
      }}
    />
    Back
  </Button>
</Box>

          
            <Box sx={{
                
                background: "linear-gradient(152.93deg, #F3F6FA 0.9%, #F1EFD8 34.79%, #D6F1E1 69.6%)",
                width: "100%",
                height: "44rem",
                display: { xs: "none", xl: "flex" , md:"flex" , lg:"flex" }, 
                flexDirection: "column",
                flex:"1",
                zIndex: "5", 
                boxSizing: "border-box",
                borderTopLeftRadius:"1.5rem",
                borderBottomLeftRadius:"1.5rem",
            }}>
                <Box sx={{
                    width: "60px",
                    height: "45px",
                    marginTop: "2rem",
                    marginLeft: "1rem",
                    
                }}>  
<img src="/uploads/logoYum.png" alt="logo" />
</Box>

         <Box
    sx={{
        width : {xl:"70%" , sm:"75%"},
        height: "40%",
        
        marginTop: "8rem",
        marginLeft: "1rem",
        borderRadius: "23px",
        border: "2px solid #04BED7",
        boxSizing: "border-box",
        overflow:"hidden",
        display: "flex",            
        flexDirection: "column",     
        justifyContent: "space-between",
               }}
               > 
                   <Typography sx={{
     marginTop:"1rem",
    fontFamily: "Poppins",
    fontSize: {xl:"16px" , md:"14px" , sm:"12px"},
    fontWeight: "500",
    textAlign: "center", 
}}>
 <MessageOutlinedIcon sx={{marginRight:"0.5rem" , color:"#04BED7"}}/>   New Messages in Last 24 Hours
</Typography>

<Box sx={{
 textAlign:"center",
 display:"flex",
  alignItems:"center",
  padding:"10px",
  gap:"1",
 marginLeft:"3rem"
}}>

<Typography
    sx={{
        fontFamily: "Poppins",
        fontSize: {xl:"24px" , md:"22px" , sm:"20px"},
        fontWeight: "500",
        textAlign: "start",
        color: "#000000", 
        marginRight:"1rem"
        
         
    }}
>
    16,048

   
</Typography>

 <Typography
    sx={{
        width: "54.05px",                 
        height: "23px",                  
        border: "1.15px solid #04BED7", 
        textAlign: "center", 
        borderRadius:"2rem"
       
    }}
>
     3%
</Typography>
</Box>

<img src='/uploads/wave.png' alt='wave' style={{
    width:"100%",   
    alignSelf: "flex-end",
}}/>

            </Box>

             <Box
    sx={{
      width : {xl:"70%" , sm:"75%"},
      height: "40%",
        marginTop: "1.2rem",
        marginLeft: {xl:"6rem" , md:"4rem" , sm:"3rem"},
        marginBottom:"11rem",
        borderRadius: "23px",
        border: "2px solid #FF6C01",
        boxSizing: "border-box",
        overflow:"hidden",
        display: "flex",            
        flexDirection: "column",     
        justifyContent: "space-between",
               }}
               > 
                   <Typography sx={{
     marginTop:"1rem",
    fontFamily: "Poppins",
    fontSize: {xl:"16px" , md:"14px" , sm:"12px"},
    fontWeight: "500",
    textAlign: "center", 
}}>
 <MessageOutlinedIcon sx={{marginRight:"0.5rem" , color:"#FF6C01"}}/>   total Registration 
</Typography>

<Box sx={{
 textAlign:"center",
 display:"flex",
  alignItems:"center",
  padding:"10px",
 marginLeft:"3rem"
}}>

<Typography
    sx={{
        fontFamily: "Poppins",
        fontSize: {xl:"24px" , md:"22px" , sm:"20px"},        fontWeight: "500",
        lineHeight: "36px",
        textAlign: "start",
        color: "#000000", 
        marginRight:"1rem"
         
    }}
>
    98,048

   
</Typography>

 <Typography
    sx={{
        width: "54.05px",                 
        height: "23px",                  
        border: "1.15px solid #FF6C01", 
        textAlign: "center", 
        borderRadius:"2rem"
       
    }}
>
     53%
</Typography>
</Box>

<img src='/uploads/wave2.png' alt='wave' style={{
    width:"100%",
    color:"#FFD4B5",   
    alignSelf: "flex-end",
}}/>

            </Box>

            <Box
  component="img"
  src="/uploads/telegram-1.png"
  alt="telegram"
  sx={{
    position: "absolute",
    width: { xl: "9rem", md: "8rem", sm: "6rem", xs: "4rem" }, 
    height: { xl: "9rem", md: "8rem", sm: "6rem", xs: "4rem" }, 
    left: { xl: "-4.2%", lg: "-4.8%", md: "-4%", sm: "-3%"},
    bottom: { xl: "2%", lg: "3%", md: "4%", sm: "5%", xs: "6%" },
  }}
/>

<img src='/uploads/sparkle.png' alt='telegram' style={{
    position:"absolute",
    width: "2rem",              
    height: "3rem", 
    right: "60%",    
    top: "9%",

}}/>
<img src='/uploads/sparkle.png' alt='telegram' style={{
    position:"absolute",
    width: "3rem",              
    height: "2rem", 
    transform: "rotate(-180deg)",
    left: "25%",    
    bottom: "8%",

}}/>
<img src='/uploads/telegram-3.png' alt='telegram' style={{
    position:"absolute",
    width: "3rem",              
    height: "3rem", 
    right:"68%",
    top: "10%",

}}/>
<Box
  component="img"
  src="/uploads/telegram-2.png"
  alt="telegram"
  sx={{
    position: "absolute",
    width: { xl: "5rem", lg: "4.5rem", md: "4rem", sm: "3rem" }, 
    height: { xl: "5rem", lg: "4.5rem", md: "4rem", sm: "3rem" },
    left: "37%",    
    bottom: "48%",
  }}
/>

<img src='/uploads/sparkle.png' alt='telegram' style={{
    position:"absolute",
    width: "2rem",              
    height: "2rem", 
    left: "10%",    
  transform: "rotate(-10deg)",
    top: "21%",

}}/>

    </Box>
    <Box
  sx={{
    width: "100%",
  height: "44rem",
  display: "flex",
  // justifyContent:"center",
  flexDirection: "column",
  borderTopRightRadius:"1.5rem",
  borderBottomRightRadius:"1.5rem",
  flex: "1", 
  zIndex: "5", 
  boxSizing: "border-box", 
  alignItems: "center",
  padding: "1rem", 
  background: "#f8f8ff",
  gap: "1.5rem", 
  }}
>
  <Typography
    component="span"
    variant="h4"
    sx={{
      fontFamily: "Poppins",
      fontWeight: 700,
      lineHeight: "43.31px",
      textAlign: "center",
      color: "#2356FE",
      marginLeft:{xl:"2.5rem" , xs:"0.5rem"}
    }}
  >
    Category
    <Typography
      variant="h4"
      sx={{
        fontFamily: "Poppins",
        fontWeight: 600,
        lineHeight: "43.31px",
        color: "#000000",
        textAlign: "center",
        display: "block",
      }}
    >
      Registration Form
    </Typography>
  </Typography>

  <Box
    sx={{
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize:"1rem",
      color: "#000000",
      marginTop: "1rem",
      display:"flex",
      width:"100%",
      justifyContent:"flex-start"
    }}
  >
    Add Mentor
  </Box>

  <div  style={{width: '100%', marginBottom: '0.5rem'   }}>
      <div style={{ marginBottom: '1.5rem' }}>
        <input
          type="text"
          id="categoryName"
          name="categoryName"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          placeholder="Enter category name"
          style={{
            width: '100%',
            padding: '12px',
            border: '1.83px solid #B1B1B1',
            borderRadius: '1.2rem',
            backgroundColor: '#F4F4F4',
            color: 'blue',
            fontSize: '1rem',
            outline: 'none',
            boxShadow: 'none',
          }}
        />
      </div>

      <div style={{ marginBottom: '1.5rem' }}>
       
        <input
          type="text"
          id="confirmCategoryName"
          name="confirmCategoryName"
          value={confirmCategoryName}
          onChange={(e) => setConfirmCategoryName(e.target.value)}
          placeholder="Confirm category name"
          style={{
            width: '100%',
            padding: '12px',
            border: '2px solid #B1B1B1',
            borderRadius: '1.2rem',
            backgroundColor: '#F4F4F4',
            color: 'blue',
            fontSize: '1rem',
            outline: 'none',
            boxShadow: 'none',
          }}
        />
      </div>

      <div style={{ marginBottom: '0.2rem' }}>
        
        <textarea
          id="categoryDescription"
          name="categoryDescription"
          rows={5}
          value={categoryDescription}
          onChange={(e) => setCategoryDescription(e.target.value)}
          placeholder="Enter category description"
          style={{
            width: '100%',
            padding: '12px',
            border: '1.83px solid #B1B1B1',
            borderRadius: '1.2rem',
            backgroundColor: '#F4F4F4',
            color: 'blue',
            fontSize: '1rem',
            outline: 'none',
            boxShadow: 'none',
            resize: 'none',
          }}
        ></textarea>
      </div>
    </div>

  <Button
    sx={{
      alignSelf: "center",
      padding: "8px 1.5rem",
      background: "#2356FE",
      border: "none",
      color: "#FFFFFF",
      fontWeight: "600",
      borderRadius: "1rem",
      width: "fit-content",
    }}
    variant="contained"
    onClick={handleSubmit}
    fullWidth
    disabled={!categoryDescription || !confirmCategoryName || !categoryName}
  >
    {loading ? "Submitting..." : "Submit"}
  </Button>
</Box>


            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <MuiAlert onClose={handleCloseSnackbar} severity={categoryName === confirmCategoryName ? "success" : "error"} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Box>

        </Box>
    );
};

export default AddCategory;

