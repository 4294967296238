import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Avatar } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { UserContext } from "global/context";
import { ClubContext } from "../TimelineClub";
import { toast } from "react-toastify";
import { apiJson, apiJsonAuth } from "api";
import { UI2DashboardClub } from "pages/UI-2.0-Dashboard/component";
import { Link } from "react-router-dom"
import ClubAddPost from "./ClubAddPost";
import ShowUserClubPosts from "./ShowUserClubPosts";
import { useParams } from 'react-router-dom';

const ClubProfileAbout = ({ profileUserCurrent, profileUser, value }) => {
  const { userData } = useContext(UserContext)
  const { clickclubUserDetail, clickuserDetail } = useContext(ClubContext)


  const [showExperienceModal, setShowExperienceModal] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showSkillsModal, setShowSkillsModal] = useState(false);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showInterest, setShowInterest] = useState(false);

  // useState for all field
  const [profileAbout, setProfileAbout] = useState('Hello');

  const [profileExperience, setProfileExperience] = useState([])

  // const [profileUser, setProfileUser] = useState({});
  // const [profileUserCurrent, setProfileUserCurrent] = useState({});
  const { userId } = useParams();

  // const getProfileData = () =>{
  //     apiJson(`api/v2/profile/userDetail/role/student/user/${userId}`)
  //     .then((res)=>{
  //       console.log("res?.data?.userDetail", res?.data?.userDetail)
  //       setProfileUser(res?.data?.userDetail)

  //     })
  //     .catch((error)=>{
  //       console.log(error.message);
  //     })
  //   } 
  //   const getProfileDataCurrent = () =>{
  //     apiJson(`api/v2/profile/userDetail/role/${userData.role}/user/${userData.id}`)
  //     .then((res)=>{
  //       console.log("res?.data?.userDetail", res?.data?.userDetail)
  //       setProfileUserCurrent(res?.data?.userDetail)

  //     })
  //     .catch((error)=>{
  //       console.log(error.message);
  //     })
  //   } 

  // useEffect(()=>{
  //   getProfileData()
  //   getProfileDataCurrent()
  //   console.log("profileUser", profileUser)
  // },[])

  // console.log("profileAbout", profileAbout)

  const handlUpdateProfile = () => {
    let data = {
      ...clickclubUserDetail,
      about: profileAbout,
    }
    apiJsonAuth.put("club/updateProfileClub/" + userData.id + '/' + userData.role, data)
      .then((res) => {
        setProfileAbout(res.data.profile.about);
        toast.dismiss();
        // setProfileAbout('')
        setShowAboutModal(false)
        toast.success("Successfully Update Bio")
      }).catch((error) => {
        toast.dismiss()
        // setProfileAbout('')
        toast.error("Internal Server Error")
        setShowAboutModal(false)
      })
  }

  const handleChangeExperience = (event) => {
    event.preventDefault();

    const companyName = event.target.companyName.value;
    const designation = event.target.designation.value;
    const shortDescription = event.target.shortDescription.value;
    const fromDate = event.target.fromDate.value;
    const toDate = event.target.toDate.value;
    // console.log("clubUserDetail.experience", clubUserDetail.experience)
    let array = []
    const newExperience = {
      companyName,
      designation,
      shortDescription,
      fromDate,
      toDate,
    };
    let blankArray = []
    if (profileExperience) {
      array = [...profileExperience, newExperience]
      setProfileExperience([...profileExperience, newExperience]);
    } else {
      array = [...blankArray, newExperience]

      setProfileExperience([...blankArray, newExperience]);
    }
    let data = { ...clickclubUserDetail, experience: array }
    apiJsonAuth.put("club/updateProfileClub/" + userData.id + '/' + userData.role, data)
      .then((res) => {
        toast.dismiss();
        toast.success("Successfully Add Experience")
        event.target.reset();
        setShowExperienceModal(false)
      }).catch((error) => {
        setShowExperienceModal(false)
        toast.error("Internal Server Error")
      })
  }

  const handleDeleteExperience = (index) => {
    let array = []
    let newExperience = [...profileExperience]

    if (index >= 0 && index < newExperience.length) {
      newExperience.splice(index, 1);
    } else {
      console.log("Index out of range");
    }
    array = newExperience
    setProfileExperience(newExperience)

    let data = { ...clickclubUserDetail, experience: array }
    apiJsonAuth.put("club/updateProfileClub/" + userData.id + '/' + userData.role, data)
      .then((res) => {
        toast.dismiss();
        toast.success("Deleted Successfully")
        //  event.target.reset();
        //  setShowExperienceModal(false)
      }).catch((error) => {
        //  setShowExperienceModal(false)
        toast.error("Internal Server Error")
      })
  }

  const calculateRange = (s, e) => {
    // console.log('s,eeeee', s, e)
    let startDate = new Date(s);
    let endDate = new Date(e);
    if (!e) {
      endDate = new Date()
    }
    if (!s) {
      startDate = new Date()
    }

    const yearDiff = endDate.getFullYear() - startDate.getFullYear();
    const monthDiff = endDate.getMonth() - startDate.getMonth();
    const dayDiff = endDate.getDate() - startDate.getDate();

    // console.log('year', yearDiff, monthDiff, dayDiff)
    if (!yearDiff) {
      return (`${monthDiff} mos`)
    }
    if (!monthDiff && !yearDiff) {
      return (`${dayDiff} days`)
    }
    return (`${yearDiff} yrs ${monthDiff} mos `)
  }

  // useEffect(()=>{

  //   setProfileAbout(clickclubUserDetail.about)
  //   setProfileExperience(clickclubUserDetail.experience)
  // },[clickclubUserDetail])

  // useEffect(()=>{
  //   setProfileAbout(userDetail.about)
  // },[userDetail])

  // const [profileData, setProfileData] = useState([])
  const getSinglePageData =()=>{
    apiJson.get(`club/getAllPostFromSingleUser/15579/institute`)
    .then((res)=>{
      // console.log(res.data.allPosts)
      // setProfileData(res.data.allPosts)
    })
    .catch((err)=>{
      console.log(err.message);
    })

  }

  // console.log("profileData", profileData)

    useEffect(()=>{
    // getSinglePageData()
  },[])

  return (
    <>
      <div>
        {
          ['student', 'teacher','institute'].includes(!userData?.role) && 
          <div style={{
            background: 'var(--club-component-backgroun', borderRadius: "16px", fontFamily: "inter"
          }} className="about  yui-card mb-3">
            <div className="ms- " >
              <div className="d-flex justify-content-between  " >
                <h4 className="mb-4 mb-3 fw-bold" >About</h4>
                {profileUser?.id === userData.id && <EditIcon
                  style={{ cursor: "pointer", color: "#d874e9" }}
                  onClick={() => {
                    setShowAboutModal(true);
                  }}
                />}
              </div>
              <p className="fw-normal  fs-6" style={{ color: "#262626" }}>
                {profileAbout || (profileUser?.experience?.length && profileUser?.experience[0]?.description)}
              </p>
            </div>
          </div>
        }

        {/* Club */}
        <UI2DashboardClub />

        {/* Interest */}
        <div>
          <div
            className="mb-3 yui-card"
            style={{ borderRadius: "16px", fontFamily: "inter" }}
          >
            <div className="d-flex justify-content-between">
              <h5 className="fs-4 mb-3 fw-bold">Interests</h5>
              {profileUser?.interests?.length > 4 && (
                <button
                  className="fw-bold text-secondary"
                  style={{ background: 'none', border: 'none', cursor: 'pointer', textDecoration: 'none' }}
                  onClick={() => setShowInterest(!showInterest)}
                >
                  {showInterest ? 'See less' : 'See all'}
                </button>
              )}
            </div>

            {
              profileUser?.interests?.length > 0 &&
              <div
                className="mt-3 p-2"
                style={{ borderRadius: "16px", fontFamily: "inter" }}
              >
                <div className="d-flex flex-wrap">
                  {profileUser?.interests?.slice(0, showInterest ? profileUser?.interests?.length : 4)?.map((ele, i) => {
                    return (
                      <>
                        <span key={i}
                          className="p-2 mx-2 my-2 my-lg-1"
                          style={{
                            color: "#630092",
                            borderRadius: "13.5px",
                            background: "#F2DEFF",
                          }}
                        >
                          {ele?.name}
                        </span>
                      </>
                    );
                  })}
                </div>
              </div>
            }
          </div>
        </div>

      </div>
      <div>
        <ClubAddPost userDetail={profileUserCurrent} />
      </div>

      <div>
        <ShowUserClubPosts userDetail={profileUser} value={1}/>
      </div>

      <div>
        {/* <ShowAll */}
        {/* <ClubPostCenter/> */}
      </div>

      <Modal
        show={showAboutModal}
        onHide={() => {
          setShowAboutModal(false);
        }}
      >
        <Modal.Header className="fw-bold fs-4" style={{ color: "black" }}>Edit About</Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-floating mb-3">
              <textarea
                className="form-control rounded "
                value={profileAbout}
                id="floatingInput"
                style={{ height: "250px", resize: "none", background: "rgb(239, 239, 239)" }}
                rows="5"
                onChange={(e) => { setProfileAbout(e.target.value) }}
              ></textarea>
              <label htmlFor="floatingTextarea" className="fw-bold">About</label>
            </div>
            <div className="row">
              <button onClick={() => {
                handlUpdateProfile()
              }} className="btn rounded btn-sm px-3 btn btn-md btn-primary col-6 mx-auto border-0 fs-6" style={{ height: "40px", color: "white", background: "#6100FF" }}>
                Edit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showExperienceModal}
        onHide={() => {
          setShowExperienceModal(false);
        }}
      >
        <Modal.Header>Add Experience</Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={handleChangeExperience}>
              <div className="form-floating mb-3">
                <input type="text" className="form-control rounded-1" name="companyName" />
                <label htmlFor="companyName">Company Name</label>
              </div>
              <div className="form-floating mb-3">
                <input type="text" className="form-control rounded-1" name="designation" />
                <label htmlFor="designation">Designation</label>
              </div>
              <div className="form-floating mb-3">
                <input type="text" className="form-control rounded-1" name="shortDescription" />
                <label htmlFor="shortDescription">Short Description</label>
              </div>
              <div className="row g-2">
                <div className="col-md">
                  <div className="form-floating">
                    <input type="date" className="form-control rounded-1" id="fromDate" name="fromDate" />
                    <label htmlFor="fromDate">From Date</label>
                  </div>
                </div>
                <div className="col-md">
                  <div className="form-floating">
                    <input type="date" className="form-control rounded-1" id="toDate" name="toDate" />
                    <label htmlFor="toDate">To Date</label>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary mt-3">Submit</button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSkillsModal}
        onHide={() => {
          setShowSkillsModal(false);
        }}
      >
        <Modal.Header>Add Skills</Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
              />
              <label for="floatingInput">Email address</label>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showProjectModal}
        onHide={() => {
          setShowProjectModal(false);
        }}
      >
        <Modal.Header>Add Project</Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
              />
              <label for="floatingInput">Email address</label>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClubProfileAbout;
