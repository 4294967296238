import { createContext, useState, useContext, useEffect } from "react";
import { apiJson } from "api"; // Assuming your apiJson function is imported correctly
import { useGlobalContext } from "global/context";

// Create the context
export const CareerContext = createContext();

// Context provider component
export const CareerProvider = ({ children }) => {
	const { userData } = useGlobalContext();
	const [role, setRole] = useState(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		return user ? user.role : "";
	});
	const [selectedStudentId, setSelectedStudentId] = useState("");
	const [studentsList, setStudentsList] = useState([]); // Add studentsList state
	const [classInfo, setClassInfo] = useState(null);
	const [selectedNavOption, setSelectedNavOption] = useState("RIASEC"); // Default option
	const [selectedSubNavOption, setSelectedSubNavOption] =
		useState("Test Cards"); // Default
	const [isSidebarVisible, setIsSidebarVisible] = useState(true);
	const [isDetailsVisible, setIsDetailsVisible] = useState(true);
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1024);
	const [attempts, setAttempts] = useState({ RIASEC: {}, LSMT: {} }); // Updated structure
	const [riasecClasses, setRiasecClasses] = useState([]); // State for RIASEC classes
	const [lsmtClasses, setLsmtClasses] = useState([]); // State for LSMT classes

	// Fetch class info based on user role when the component mounts
	useEffect(() => {
		const fetchClassInfo = async () => {
			if (role === "student") {
				try {
					const res = await apiJson(
						`/api/v2/user/studentClass/${userData?.id}/${userData?.role}`
					);
					if (res?.data?.result?.class) {
						setClassInfo(res?.data?.result?.class);
					} else {
						setClassInfo(null);
					}
				} catch (error) {
					setClassInfo(null);
				}
			}
		};

		if (userData && role === "student") {
			fetchClassInfo();
		}
	}, [role, userData]);

	// Fetch test attempts on load and when selectedNavOption changes
	useEffect(() => {
		const fetchTestAttempts = async () => {
			if (role === "student" && userData) {
				const testTypes = ["RIASEC", "LSMT"];
				const fetchPromises = testTypes.map(async (testType) => {
					try {
						const res = await apiJson(
							`/api/v2/${testType.toLowerCase()}Test/getAttempt/${
								userData.id
							}/${role}`
						);
						return {
							type: testType,
							attemptCount: res?.data?.result?.attemptCount || 0,
						};
					} catch (err) {
						console.error(
							`Error fetching ${testType.toLowerCase()} attempt:`,
							err
						);
						return { type: testType, attemptCount: 0 };
					}
				});

				const results = await Promise.all(fetchPromises);
				const newAttempts = {};

				results.forEach(({ type, attemptCount }) => {
					newAttempts[type] = {
						canAttempt: attemptCount < 2, // Can attempt if less than 2
						attemptCount: attemptCount,
					};
				});

				setAttempts((prevAttempts) => ({
					...prevAttempts,
					...newAttempts,
				}));
			}
		};

		fetchTestAttempts();
	}, [selectedNavOption, role, userData]);
	// Fetch teacher-specific navigation options
	useEffect(() => {
		const fetchTeacherNavOptions = async () => {
			if (role === "teacher" && userData) {
				try {
					const res = await apiJson(
						`/api/v2/riasecTest/riasec-guide-info/${userData.id}`
					);
					//console.log("res was: ", res);
					if (res.data?.result === null) {
						return;
					}
					const isAssigned = res?.data?.result?.isAssigned || [];
					//console.log("Got this: ", res);
					let riasecOptions = isAssigned[0]?.riasec || [];
					let lsmtOptions = isAssigned[0]?.lsmt || [];

					// Ensure options are in ascending order
					riasecOptions = riasecOptions.sort((a, b) => a - b);
					lsmtOptions = lsmtOptions.sort((a, b) => a - b);

					const navOptions = {
						RIASEC: riasecOptions,
						LSMT: lsmtOptions,
					};

					//console.log("nav options are: ", navOptions);
					setRiasecClasses(navOptions.RIASEC);
					setLsmtClasses(navOptions.LSMT);
				} catch (error) {
					console.error("Error fetching teacher navigation options:", error);
				}
			}
		};
		//console.log("Function Called!!");
		fetchTeacherNavOptions();
	}, [role, userData]);

	useEffect(() => {
		//console.log("User Attempts: ", attempts);
	}, [attempts]);

	// Handle screen resizing to toggle the sidebar and details panel
	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth <= 1024);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	// Handle sidebar and details visibility based on screen size and selected topic
	useEffect(() => {
		if (isSmallScreen) {
			if (selectedNavOption) {
				setIsSidebarVisible(false);
				setIsDetailsVisible(true);
				document.body.classList.add("show-details");
			} else {
				setIsSidebarVisible(true);
				setIsDetailsVisible(false);
				document.body.classList.remove("show-details");
			}
		} else {
			setIsSidebarVisible(true);
			setIsDetailsVisible(true);
			document.body.classList.remove("show-details");
		}
	}, [selectedNavOption, isSmallScreen]);

	useEffect(() => {
		if (role === "institute" || role === "teacher") {
			if (selectedNavOption === "RIASEC") {
				if (
					!["Classes", "Student's List", "View Result"].includes(
						selectedSubNavOption
					)
				) {
					setSelectedSubNavOption("Classes"); // Default for institute role on RIASEC
				}
			} else if (selectedNavOption === "LSMT") {
				if (
					!["Classes", "Student's List", "View Result"].includes(
						selectedSubNavOption
					)
				) {
					setSelectedSubNavOption("Classes"); // Default for institute role on LSMT
				}
			}
		} else if (role === "student") {
			if (classInfo) {
				if (selectedNavOption === "RIASEC") {
					if (
						![
							"Test Cards",
							"Take test",
							"Career Guidance",
							"View Result",
						].includes(selectedSubNavOption)
					) {
						setSelectedSubNavOption("Test Cards"); // Default for student role on RIASEC
					}
				} else if (selectedNavOption === "LSMT") {
					if (
						!["Test Cards", "Take test", "View Result"].includes(
							selectedSubNavOption
						)
					) {
						setSelectedSubNavOption("Test Cards"); // Default for student role on LSMT
					}
				}
			}
		}
	}, [role, selectedNavOption, classInfo]);

	// Navigation options based on the role and class info
	const navOptions = () => {
		if (role === "institute") {
			return {
				RIASEC: [],
				LSMT: [],
				"Manage Teachers": [],
			};
		} else if (role === "student") {
			if (classInfo && classInfo >= 9) {
				return {
					RIASEC: ["Test Cards", "Take test", "Career Guidance", "View Result"],
				};
			} else if (classInfo && classInfo >= 6) {
				return {
					RIASEC: ["Test Cards", "Take test", "Career Guidance", "View Result"],
					LSMT: ["Test Cards", "Take test", "View Result"],
				};
			} else {
				return {};
			}
		} else if (role === "teacher") {
			// Corrected conditions for RIASEC and LSMT
			if (riasecClasses.length > 0 && lsmtClasses.length > 0) {
				//console.log("Both RIASEC and LSMT found");
				return {
					RIASEC: [],
					LSMT: [],
				};
			} else if (riasecClasses.length > 0) {
				//console.log("Only RIASEC found");
				return {
					RIASEC: [],
				};
			} else if (lsmtClasses.length > 0) {
				//console.log("Only LSMT found");
				return {
					LSMT: [],
				};
			} else {
				//console.log("No RIASEC or LSMT classes found");
				return {};
			}
		}
		return {};
	};

	// Save class information and update the context
	const saveStudentClass = async (className) => {
		setClassInfo({ className });
		try {
			await apiJson.post(
				`/api/v2/user/updateClass/${userData.id}/${userData.role}`,
				{
					classValue: className,
				}
			);
		} catch (err) {
			console.error("Error saving class information:", err);
		}
	};

	// Toggle the sidebar visibility
	const toggleSidebar = () => {
		setIsSidebarVisible((prev) => !prev);
		setIsDetailsVisible((prev) => !prev);
		if (isSmallScreen) {
			document.body.classList.toggle("show-details");
		}
	};

	// Fetch attempt details based on selected navigation option
	const getScore = async (userId, role = "student") => {
		const testType = selectedNavOption.toLowerCase();
		try {
			const res = await apiJson(
				`/api/v2/${testType}Test/getAttempt/${userId}/${role}`
			);
			//console.log("Result: ", res);
			return res?.data?.result?.result;
		} catch (err) {
			//console.error(`Error fetching ${testType} attempt:`, err);
			return null;
		}
	};

	// Post attempt details based on selected navigation option
	const postAttempt = async (userId, role = "student", categoryScores) => {
		const testType = selectedNavOption.toLowerCase();
		const updatedAttemptCount = attempts[selectedNavOption].attemptCount + 1; // Increment the attempt count

		const data = {
			userId,
			role,
			result: categoryScores,
			attemptCount: updatedAttemptCount, // Send the updated attempt count
		};

		try {
			const res = await apiJson.post(
				`/api/v2/${testType}Test/postAttempt`,
				data
			);
			setAttempts((prevAttempts) => ({
				...prevAttempts,
				[selectedNavOption]: {
					canAttempt: updatedAttemptCount < 2, // Can attempt if less than 2
					attemptCount: updatedAttemptCount,
				},
			}));

			return res;
		} catch (err) {
			console.error(`Error posting ${testType} attempt:`, err);
			return null;
		}
	};

	return (
		<CareerContext.Provider
			value={{
				role,
				classInfo,
				setClassInfo,
				selectedNavOption,
				setSelectedNavOption,
				selectedSubNavOption,
				setSelectedSubNavOption,
				navOptions: navOptions(),
				saveStudentClass,
				isSidebarVisible,
				isSmallScreen,
				isDetailsVisible,
				toggleSidebar,
				getScore,
				studentsList,
				setStudentsList,
				postAttempt,
				attempts,
				selectedStudentId,
				setSelectedStudentId,
				riasecClasses,
				lsmtClasses,
			}}
		>
			{children}
		</CareerContext.Provider>
	);
};

// Custom hook for easier context access
export const useCareerContext = () => useContext(CareerContext);
