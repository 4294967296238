/* eslint-disable no-sparse-arrays */
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./ClubPage.css";
import ClubSideBar from "../ClubSideBar/ClubSideBar";
import ChallengeRenderer from "../ChallengeRenderer/ChallengeRenderer";

const CustomClubPage = () => {
    const initialOptions = [
        {
            title: "IP Innocation Club",
            topics: [
                {
                    title: "Work Shops",
                    subtopics: [
                        {
                            title: "Coding",
                            tasks: []
                        },
                        {
                            title: "Desinging",
                            tasks: []
                        },
                    ],
                },
                {
                    title: "Training Programs",
                    subtopics: [
                        {
                            title: "Coding",
                            tasks: []
                        },
                        {
                            title: "Science Lab",
                            tasks: []
                        },
                    ],
                },
                {
                    title: "Exhibitions",
                    subtopics: [
                        {
                            title: "Talent Show",
                            tasks: []
                        },
                        {
                            title: "Tech Fest",
                            tasks: []
                        },
                    ],
                },
                {
                    title: "Entrepreneurship",
                    subtopics: [
                        {
                            title: "Learning",
                            tasks: []
                        },
                        {
                            title: "Implementation",
                            tasks: []
                        },
                    ],
                },
                {
                    title: "Contests",
                    subtopics: [
                        {
                            title: "Speech",
                            tasks: []
                        },
                        {
                            title: "Quiz",
                            tasks: []
                        },
                    ],
                },
                {
                    title: "Hackathons",
                    subtopics: [
                        {
                            title: "Coding",
                            tasks: []
                        },
                        {
                            title: "Competition",
                            tasks: []
                        },
                    ],
                },
                {
                    title: "Innovation",
                    subtopics: [
                        {
                            title: "Research",
                            tasks: []
                        },
                        {
                            title: "Presentation",
                            tasks: []
                        },
                    ],
                },
                {
                    title: "Bootcamps",
                    subtopics: [
                        {
                            title: "Selection",
                            tasks: []
                        },
                        {
                            title: "InterSchool Student and Teacher Contests",
                            tasks: []
                        },
                    ],
                },
                {
                    title: "Innovation Fests",
                    subtopics: [
                        {
                            title: "Interschool Contests",
                            tasks: []
                        },
                        {
                            title: "Awards",
                            tasks: []
                        },
                    ],
                },
                {
                    title: "Poster Design Competition",
                    subtopics: [
                        {
                            title: "SICLD",
                            tasks: []
                        },
                        {
                            title: "SICLD",
                            tasks: []
                        },
                    ],
                },
            ]
        },
        {
            title: "ECO CLUB",
            topics: [
                {
                    title: "21 Days Challenges",
                    subtopics: [
                        {
                            title: "21 Days Challenge",
                            tasks: [],
                        },
                    ],
                },
                {
                    title: "75 Stages Towards Life",
                    subtopics: [
                        {
                            title: "Energy Save",
                            tasks: [0, 18],
                        },
                        {
                            title: "Water Save",
                            tasks: [19, 33],
                        },
                        {
                            title: "Single use (Plastic Reduced)",
                            tasks: [34, 44],
                        },
                        {
                            title: "Systems adopted",
                            tasks: [45, 51],
                        },
                        {
                            title: "Waste Reduced",
                            tasks: [52, 61],
                        },
                        {
                            title: "Healthy lifestyles Adopted",
                            tasks: [62, 70],
                        },
                        {
                            title: "E-Waste Reduced",
                            tasks: [71, 74],
                        },
                    ],
                },
                {
                    title: "Carbon Footprint Calculator",
                    subtopics: [
                        {
                            title: "Calculate your Carbon Footprint",
                            tasks: [{ title: "Calculate Carbon Footprint", completed: false }],
                        },
                    ],
                },
                {
                    title: "Sustainability Action Calculator",
                    subtopics: [
                        {
                            title: "Calculate Sustainable Actions",
                            tasks: [
                                { title: "Calculate Sustainable Actions", completed: false },
                            ],
                        },
                    ],
                },
            ],
        }
    ];

    const [options, setOptions] = useState(initialOptions);
    const [selectedSubTopic, setSelectedSubTopic] = useState(null);
    const [selectedMainTopic, setSelectedMainTopic] = useState(window.innerWidth >= 1024 ? {
        title: "21 Days Challenges",
        subtopics: [
            {
                title: "21 Days Challenge",
                tasks: [],
            },
        ],
    } : null);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1024);
    const [taskCompletionStatus, setTaskCompletionStatus] = useState(() => {
        const savedStatus = localStorage.getItem('Club_Tasks_Status');
        return savedStatus ? JSON.parse(savedStatus) : {};
    });
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const [isDetailsVisible, setIsDetailsVisible] = useState(false);

    useEffect(() => {
        localStorage.setItem('Club_Tasks_Status', JSON.stringify(taskCompletionStatus));
    }, [taskCompletionStatus]);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1024);
            if (window.innerWidth > 1024) {
                setIsSidebarVisible(true);
                setIsDetailsVisible(true);
                document.body.classList.remove('show-details');
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isSmallScreen) {
            if (selectedSubTopic || selectedMainTopic) {
                setIsSidebarVisible(false);
                setIsDetailsVisible(true);
                document.body.classList.add('show-details');
            } else {
                setIsSidebarVisible(true);
                setIsDetailsVisible(false);
                document.body.classList.remove('show-details');
            }
        } else {
            setIsSidebarVisible(true);
            setIsDetailsVisible(true);
            document.body.classList.remove('show-details');
        }
    }, [selectedSubTopic, selectedMainTopic, isSmallScreen]);

    const handleTaskCompletion = (subtopicTitle, taskTitle) => {
        setTaskCompletionStatus(prevStatus => {
            const updatedStatus = { ...prevStatus };
            if (!updatedStatus[subtopicTitle]) {
                updatedStatus[subtopicTitle] = {};
            }
            updatedStatus[subtopicTitle][taskTitle] = true;
            return updatedStatus;
        });
    };

    const handleToggle = () => {
        setIsSidebarVisible(prev => !prev);
        setIsDetailsVisible(prev => !prev);
        if (isSmallScreen) {
            document.body.classList.toggle('show-details');
        }
    };
    return (
        <section className="dashboard-content-container">
            {isSidebarVisible && (
                <div className="options-progress-panel">
                    <ClubSideBar
                        pageName="Clubs"
                        navigationData={options}
                        setActiveTopic={setSelectedMainTopic}
                        setActiveSubtopic={setSelectedSubTopic}
                        taskCompletionStatus={taskCompletionStatus}
                    />
                </div>
            )}

            {isDetailsVisible && (
                <section className={`options-progress-details-container ${isSmallScreen && !isSidebarVisible ? 'hide' : ''}`}>
                    <Box
                        sx={{
                            borderRadius: "1rem",
                            backgroundColor: "#f0f0f0",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            padding: "2rem",
                            boxSizing: "border-box",
                            height: "fit-content",
                        }}
                    >
                        <div className="lets-begin-your-first-day-cha-parent">
                            <Typography
                                component={"b"}
                                sx={{
                                    fontSize: "1.3rem",
                                    fontWeight: 900,
                                    fontFamily: "cursive",
                                }}
                                className="preview"
                            >
                                {selectedSubTopic?.guideline ? selectedSubTopic.guideline : "Let's Begin your First Day Challenge"}
                            </Typography>
                            <img className="tick-icon" alt="" src="/tick.svg" />
                        </div>
                        <div className="click-here-to-container">
                            <Typography
                                component={"span"}
                                sx={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                }}
                                className="click-here"
                            >
                                Click here
                            </Typography>
                            <Typography component={"span"} className="to-know-more">
                                {" "}to know more.
                            </Typography>
                        </div>
                    </Box>

                    <Box>
                        <Typography
                            component={"h1"}
                            sx={{
                                fontSize: "2rem",
                                fontWeight: 600,
                                padding: "1rem 0rem 1rem 0rem",
                            }}
                        >
                            {selectedMainTopic.title}
                        </Typography>
                    </Box>
                    <ChallengeRenderer
                        mainTopic={selectedMainTopic}
                        subTopic={selectedSubTopic}
                        setSelectedSubTopic={setSelectedSubTopic}
                        markSubTopicComplete={handleTaskCompletion}
                        taskCompletionStatus={taskCompletionStatus}
                    />
                </section>
            )}

            {isSmallScreen && !isSidebarVisible && (
                <button
                    className="btn clubPanelToggle coursePanelToggle btn-light border-dark rounded-2 bg-light py-2 px-1"
                    onClick={handleToggle}
                >
                    <img
                        src="https://cdn-icons-png.flaticon.com/512/5994/5994725.png"
                        alt=""
                        width={30}
                    />
                </button>
            )}
        </section>
    );
};

export default CustomClubPage;