import React from 'react'
import Universities from './Universities'
import { EnviromentLogo } from './EnviromentLogo'
import { useNavigate } from 'react-router-dom';


const Ministries = () => {
  const navigate = useNavigate();
    

  return (
    <>
    {/* =======================univercity  section======================= */}

      <section className="univercitySection py-2 mt-5">
        <Universities></Universities>
      </section>

      <section>
        <EnviromentLogo />
      </section>
 {/* ===================================== yt videos =============== */}

 <section>
    <h2 className='text-center mt-3 fs-2 fw-semibold'>Dignitaries Speaking about yuvamanthan</h2>
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
            <div className="d-flex gap-2 d-flex justify-content-between">
                <div className="col-6 col-lg-4">
                  <iframe
                    className="rounded-3"
                    width="100%"
                    height="150"
                    src="https://www.youtube.com/embed/SxTQV51MTiU"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="col-6 col-lg-8 align-content-center">
                  <span className="fs-6 fw-500">
                   Shri Nayab Singh Saini
                  </span>
                  <p className='fs-6 fw-500'>Honorable Chief Minister of Haryana</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
            <div className="d-flex gap-2 d-flex justify-content-between">
                <div className="col-6 col-lg-4">
                  <iframe
                    className="rounded-3"
                    width="100%"
                    height="150"
                    src="https://www.youtube.com/embed/N3IjDkW5kpM"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="col-6 col-lg-8 align-content-center">
                 
                    <span className="fs-6 fw-500">
                      Shri Pramod Sawant
                  </span>
                 <p className='fs-6 fw-500' >Honorable Chief Minister of Goa</p>
                  
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6">
            <div className="d-flex gap-2 d-flex justify-content-between">
                <div className="col-6 col-lg-4">
                  <iframe
                    className="rounded-3"
                    width="100%"
                    height="150"
                    src="https://www.youtube.com/embed/fnWDosuwhv4"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="col-6 col-lg-8 align-content-center">
                    <span className="fs-6 fw-500">
                    Shri Arun Sao
                  </span>
                  <p className='fs-6 fw-500' >Honorable Deputy Chief Minister of Chhattisgarh </p>

                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6">
            <div className="d-flex gap-2 d-flex justify-content-between">
                <div className="col-6 col-lg-4">
                  <iframe
                    className="rounded-3"
                    width="100%"
                    height="150"
                    src="https://www.youtube.com/embed/_37CPIB6iGA"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe> 
                </div>
                <div className="col-6 col-lg-8 align-content-center">
                
                    <span className="fs-6 fw-500">
                    Shri Arjun Munda
                    </span>
                  <p className='fs-6 fw-500 mb-0' >Honorable Union Minister of Tribal Affairs </p>
                  <p className='fs-6 fw-500' >Honorable Agriculture and Farmer Welfare </p>

                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-6">
            <div className="d-flex gap-2 d-flex justify-content-between">
                <div className="col-6 col-lg-4">
                  <iframe
                    className="rounded-3"
                    width="100%"
                    height="150"
                    src="https://www.youtube.com/embed/7oX8pMhShDo"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="col-6 col-lg-8 align-content-center">
                    <span className="fs-6 fw-500">
                    Dr K laxman
                  </span>
                  <p className="fs-6 fw-500 mb-0">Honorable Minister of parliament,Rajya Sabha</p>
                  <p className="fs-6 fw-500 ">Honorable National President OBC Morcha</p>

                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
            <div className="d-flex gap-2 d-flex justify-content-between">
                <div className="col-6 col-lg-4">
                  <iframe
                    className="rounded-3"
                    width="100%"
                    height="150"
                    src="https://www.youtube.com/embed/EpuHspvHZXI"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="col-6 col-lg-8 align-content-center">
            
                    <span className="fs-6 fw-500">
                   Shri Bhupendra Singh Choudhary
                  </span>
                  <p className="fs-6 fw-500">President,BJP Uttar pradesh</p>

                </div>
              </div>
            </div>

          </div>
        </div>

        <button
          type="button"
          style={{ background: "#7e27ec", color: "white", border: "none" }}
          onClick={() => navigate("/yt-videos")}
          className="m-auto btn btn-outline-success d-block mt-3 rounded-3"
        >
          Show More
        </button>
      </section>
   
    </>
  )
}

export default Ministries