import { apiJson } from 'api';
import React, { useEffect, useState } from 'react'
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PendingIcon from '@mui/icons-material/Pending';
import TaskIcon from '@mui/icons-material/Task';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { useNavigate } from 'react-router-dom';

export const ViewerFollowUp = () => {
    const [viewerFollowUpData, setViewrFollowUpData] = useState([]);
    const navigate = useNavigate();

    const getAllViewerRemiderStatus = ()=>{
        apiJson.get('api/v2/admin/getAllViewerRemider')
        .then((res)=>{
            setViewrFollowUpData(res?.data?.getAllReminder);
        })
        .catch((error)=>{
            console.log(error.message);
        })
    }

    useEffect(()=>{
        getAllViewerRemiderStatus()
    },[])

  return (
    <div>
        <div className='col-10 mx-auto'>
            <div><span className='fs-4 fw-500'>Follow-Up</span></div>

            <div className='row'>
            {viewerFollowUpData?.map((ele)=>{
          return (
            <div key={ele?.id} onClick={()=> {navigate(`/viewer/ViewerSingleStatus/${ele?.schoolId}`)}} className='col-12 col-md-6 col-lg-4 rounded shadow-sm border-3 text-center mt-5 p-3 cursor-pointer'>
                                    <div className='mt-4'>
                                        <div><span className='fw-600 fs-6'>Institute Name: </span><span> {ele?.institution_name}</span></div>
                                    </div>
                                    {/* <div className='mt-2'>
                                        <div><span className='fw-600 fs-6'>Email: </span><a href={`mailto:${ele.email}`}>{ele?.email}</a></div>
                                    </div> */}
                                    {/* <div className='mb-2 mt-2'>
                                    <div><span className='text-danger fw-bold'>{
                                 ele?.call_status === 'disconnected'
                                  ? <span>Call Disconnected  <PhoneDisabledIcon/></span>
                                  : ele?.call_status === 'ringing'
                                  ? <span>Ringing  <RingVolumeIcon/></span>
                                  : ele?.call_status === 'not interested'
                                  ? <span>Not Interested  <PhoneDisabledIcon/></span>
                                  : ele?.call_status === 'wrongnumber'
                                    ?  <span>Call Wrong Number  <PhoneDisabledIcon/></span>
                                    : ele?.call_status === 'cancelled'
                                      ? <span>Call Cancelled  <PhoneDisabledIcon/></span>
                                      : ''}</span></div>
                                    </div> */}


                                    {/* <div className='mt-2 fw-bold'>
                                        <div><span className={ele?.registration_status === 'done' ? 'text-success' : 'text-danger'}>{ele?.registration_status === 'done' ? <span>Registration Done <HowToRegIcon/></span> : ele?.registration_status === 'pending' ? <span>Registration Pending <PendingIcon/></span> : ''}</span></div>
                                    </div>
                                    <div className='mt-2 fw-bold'>
                                        <div><span className={ele?.detail_sent_status === 'sent' ? 'text-success' : 'text-danger'}>{ele?.detail_sent_status === 'sent' ?  <span>Details Sent <TaskIcon/></span> : ele?.detail_sent_status === 'notsent' ?  <span>Details Not Sent <AssignmentLateIcon/></span> : '' }</span></div>
                                    </div> */}
                                    <div className='mb-2'>
                                        <div>{ele?.reminder_status ? <span className='fw-bold text-warning'>Reminder {ele?.reminder_status} <PendingActionsIcon /></span> : ""}</div>
                                    </div>
                                </div>
          )
        })}
            </div>
        </div>
    </div>
  )
}
