import React, { useState, useEffect } from 'react';
import {
    Button,
    Select,
    MenuItem,
    CircularProgress,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Box,
    Chip,
    Paper,
    Pagination,
} from '@mui/material';
import { apiJson } from 'api';
import { Popup } from 'layout/Popup';
import YuvaLoader from 'pages/Forum/components/Loader/YuvaLoader';

const AdminAssignCSV = () => {
    const [instituteDetails, setInstituteDetails] = useState([]);
    const [subAdmins, setSubAdmins] = useState([]);
    const [selectedSubAdmin, setSelectedSubAdmin] = useState({});
    const [isAssigning, setIsAssigning] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(12);

    // Fetch institute details
    const fetchInstituteDetails = async () => {
        setIsLoading(true);
        try {
            const res = await apiJson('/api/v2/csvUpload/fetch-institute-list');
            // console.log(res);
            if (res.status === 200) {
                setInstituteDetails(res?.data?.result || []);
            }
        } catch (err) {
            if (err?.response?.status === 404) {
                setInstituteDetails([]);
            }
            else {
                Popup('error', 'Server Error', 'Error fetching Institute Details', 3000);
            }
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch sub-admin list
    const fetchSubAdmins = async () => {
        try {
            const res = await apiJson('/api/v2/csvUpload/subadmin-list');
            if (res.status === 200) {
                setSubAdmins(res?.data?.result || []);
            }
        } catch (error) {
            Popup('error', 'Server Error', 'Error fetching sub-admins', 3000);
        }
    };

    // Handle sub-admin selection for an institute
    const handleSubAdminSelect = (instituteId, subadminId) => {
        setSelectedSubAdmin((prev) => ({
            ...prev,
            [instituteId]: subadminId,
        }));
    };

    // Assign sub-admin to institute and refetch details after assignment
    const handleAssign = async (instituteId) => {
        const subAdminId = selectedSubAdmin[instituteId];
        const assignedSubAdmin = instituteDetails.find((inst) => inst.id === instituteId)?.assignedTo;

        // Check if the sub-admin ID is the same as the current one or if no sub-admin is selected
        if (assignedSubAdmin === subAdminId || !subAdminId) return;

        setIsAssigning((prev) => ({
            ...prev,
            [instituteId]: true,
        }));

        try {
            await apiJson.put(`/api/v2/csvUpload/subadmin-assign`, { subId: subAdminId, instituteId });
            Popup('success', 'Sucess', 'Assigned SuccessFully !!', 3000);
        } catch (error) {
            Popup('error', 'Server Error', 'Error assigning or updating sub-admin', 3000);
        } finally {
            await fetchInstituteDetails();
            await fetchSubAdmins();
            setIsAssigning((prev) => ({
                ...prev,
                [instituteId]: false,
            }));
        }
    };

    useEffect(() => {
        fetchInstituteDetails();
        fetchSubAdmins();
    }, []);

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const totalItems = instituteDetails.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const currentData = instituteDetails.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {isLoading && <YuvaLoader setShow={false} show={isLoading} />}
            <h2 style={{ textAlign: 'center' }}>Manage Institute's CSV Files</h2>
            <Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: '20%', backgroundColor: '#2356FE', fontWeight: 'bold', color: 'white' }}>Institute Name</TableCell>
                                <TableCell sx={{ width: '20%', backgroundColor: '#2356FE', fontWeight: 'bold', color: 'white' }}>Institute Email</TableCell>
                                <TableCell sx={{ width: '20%', backgroundColor: '#2356FE', fontWeight: 'bold', color: 'white' }}>Status</TableCell>
                                <TableCell sx={{ width: '20%', backgroundColor: '#2356FE', fontWeight: 'bold', color: 'white' }}>Select Sub-admin</TableCell>
                                <TableCell sx={{ width: '20%', backgroundColor: '#2356FE', fontWeight: 'bold', color: 'white' }}>Click to Assign</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentData.length > 0 ? (
                                currentData.map((institute) => (
                                    <TableRow key={institute.id}>
                                        <TableCell sx={{ width: '20%' }}>{institute.institution_name}</TableCell>
                                        <TableCell sx={{ width: '20%' }}>{institute.email}</TableCell>
                                        <TableCell sx={{ width: '20%' }}>
                                            {institute.assignedTo ? (
                                                <Chip label="Assigned" color="success" />
                                            ) : (
                                                <Chip label="Not Assigned" color="error" />
                                            )}
                                        </TableCell>
                                        <TableCell sx={{ width: '20%' }}>
                                            <Select
                                                value={selectedSubAdmin[institute.id] || institute.assignedTo || ''}
                                                onChange={(e) => handleSubAdminSelect(institute.id, e.target.value)}
                                                displayEmpty
                                                fullWidth
                                            >
                                                <MenuItem value="" disabled>
                                                    {institute.assignedTo ? 'Reassign Sub-admin' : 'Select Sub-admin'}
                                                </MenuItem>
                                                {subAdmins.map((subadmin) => (
                                                    <MenuItem key={subadmin.id} value={subadmin.id}>
                                                        {subadmin?.first_name + " " + subadmin?.last_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </TableCell>
                                        <TableCell sx={{ width: '20%' }}>
                                            <Button
                                                variant="contained"
                                                sx={{ backgroundColor: '#2356FE' }}
                                                disabled={!selectedSubAdmin[institute.id]
                                                    || isAssigning[institute.id]
                                                    || selectedSubAdmin[institute.id] === institute.assignedTo
                                                }
                                                onClick={() => handleAssign(institute.id)}
                                            >
                                                {isAssigning[institute.id] ? (
                                                    <CircularProgress size={24} color="inherit" />
                                                ) : (
                                                    "Assign"
                                                )}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        No institutes found.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {totalItems > itemsPerPage && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            siblingCount={0}
                            boundaryCount={1}
                            onChange={handlePageChange}
                            color="primary"
                            size="medium"
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default AdminAssignCSV;
