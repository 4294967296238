import React from 'react'

// import sideImage from './images/onBoarding/sideImage.jpg'
export const OnBoardingSidebar = () => {
  return (
    <>
      <div
        className="p-0 text-center position-sm-fixed position-md-fixed  h-100"
        style={{
          overflow: 'hidden', // Hide any potential overflow
        }}
      >
        <img
          src="./images/onBoarding/side2.png"
          alt="Sidebar Image"
          className="h-100"
          style={{
            width: '100%',
            borderRadius: '0 49px 49px 0',
            objectFit: 'cover' // Hide any potential overflow
          }}
        />
        {/* Your content goes here */}
      </div>
    </>
  )
}
