import React from 'react'
import  "./lifeAtYuva.css"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';


export const LifeAtYuvamanthan = () => {
    const life = [
    {
        lifeImg: '/lifeAtYuvamanthan/1.jpeg',
        left: true,
        right: false,
        rightImg1: '/lifeAtYuvamanthan/2.jpeg',
        rightImg2: '/lifeAtYuvamanthan/8.jpeg',
    },
    {
        lifeImg: '/lifeAtYuvamanthan/4.jpeg',
        left: false,
        right: true,
        rightImg1: '/lifeAtYuvamanthan/7.jpeg',
        rightImg2: '/lifeAtYuvamanthan/6.jpeg',
    },
    {
        lifeImg: '/lifeAtYuvamanthan/5.jpeg',
        left: false,
        right: true,
        rightImg1: '/lifeAtYuvamanthan/9.jpeg',
        rightImg2: '/lifeAtYuvamanthan/10.jpeg',
    },
    {
        lifeImg: '/lifeAtYuvamanthan/16.jpeg',
        left: false,
        right: true,
        rightImg1: '/lifeAtYuvamanthan/12.jpeg',
        rightImg2: '/lifeAtYuvamanthan/13.jpeg',
    },
    {
        lifeImg: '/lifeAtYuvamanthan/17.jpeg',
        left: false,
        right: true,
        rightImg1: '/lifeAtYuvamanthan/14.jpeg',
        rightImg2: '/lifeAtYuvamanthan/15.jpeg',
    },
    {
        lifeImg: '/lifeAtYuvamanthan/18.jpeg',
        left: false,
        right: true,
        rightImg1: '/lifeAtYuvamanthan/19.jpeg',
        rightImg2: '/lifeAtYuvamanthan/20.jpeg',
    },
    {
        lifeImg: '/lifeAtYuvamanthan/22.jpeg',
        left: false,
        right: true,
        rightImg1: '/lifeAtYuvamanthan/21.jpeg',
        rightImg2: '/lifeAtYuvamanthan/25.jpeg',
    },
    {
        lifeImg: '/lifeAtYuvamanthan/24.jpeg',
        left: false,
        right: true,
        rightImg1: '/lifeAtYuvamanthan/26.jpeg',
        rightImg2: '/lifeAtYuvamanthan/28.jpeg',
    },
    {
        lifeImg: '/lifeAtYuvamanthan/27.jpeg',
        left: false,
        right: true,
        rightImg1: '/lifeAtYuvamanthan/29.jpeg',
        rightImg2: '/lifeAtYuvamanthan/30.jpeg',
    },
    {
        lifeImg: '/lifeAtYuvamanthan/31.jpeg',
        left: false,
        right: true,
        rightImg1: '/lifeAtYuvamanthan/32.jpeg',
        rightImg2: '/lifeAtYuvamanthan/33.jpeg',
    },
    {
        lifeImg: '/lifeAtYuvamanthan/34.jpeg',
        left: false,
        right: true,
        rightImg1: '/lifeAtYuvamanthan/36.jpeg',
        rightImg2: '/lifeAtYuvamanthan/37.jpeg',
    },
    {
        lifeImg: '/lifeAtYuvamanthan/39.jpeg',
        left: false,
        right: true,
        rightImg1: '/lifeAtYuvamanthan/38.jpeg',
        rightImg2: '/lifeAtYuvamanthan/40.jpeg',
    },
    {
        lifeImg: '/lifeAtYuvamanthan/41.jpeg',
        left: false,
        right: true,
        rightImg1: '/lifeAtYuvamanthan/42.jpeg',
        rightImg2: '/lifeAtYuvamanthan/43.jpeg',
    },
    {
        lifeImg: '/lifeAtYuvamanthan/44.jpeg',
        left: false,
        right: true,
        rightImg1: '/lifeAtYuvamanthan/45.jpeg',
        rightImg2: '/lifeAtYuvamanthan/23.jpeg',
    },
    ]

    function srcset(image, size, rows = 1, cols = 1) {
        return {
          src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
          srcSet: `${image}?w=${size * cols}&h=${
            size * rows
          }&fit=crop&auto=format&dpr=2 2x`,
        };
      }
    
  return (
    <>
      <div className='container'>

         {/* { <div class="rowForImg">
          <div class="columnForImg">
            <img src="/lifeAtYuvamanthan/1.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/2.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/3.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/4.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/5.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/6.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/7.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/7.jpeg" style={{width:"100%"}} />
        </div>
          <div class="columnForImg">
            <img src="/lifeAtYuvamanthan/8.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/9.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/10.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/11.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/12.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/13.jpeg" style={{width:"100%"}} />
        </div>
          <div class="columnForImg">
            <img src="/lifeAtYuvamanthan/15.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/16.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/17.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/18.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/19.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/20.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/21.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/21.jpeg" style={{width:"100%"}} />
        </div>
          <div class="columnForImg">
            <img src="/lifeAtYuvamanthan/22.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/23.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/23.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/24.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/25.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/26.jpeg" style={{width:"100%"}} />
        </div>
          <div class="columnForImg">
             <img src="/lifeAtYuvamanthan/27.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/28.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/29.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/30.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/31.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/32.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/33.jpeg" style={{width:"100%"}} />
        </div>
          <div class="columnForImg">
            <img src="/lifeAtYuvamanthan/34.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/35.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/36.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/37.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/38.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/39.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/40.jpeg" style={{width:"100%"}} />
        </div>
          <div class="columnForImg">
             <img src="/lifeAtYuvamanthan/41.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/42.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/3.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/4.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/5.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/6.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/7.jpeg" style={{width:"100%"}} />
        </div>
          <div class="columnForImg">
            <img src="/lifeAtYuvamanthan/15.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/16.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/17.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/18.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/19.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/20.jpeg" style={{width:"100%"}} />
            <img src="/lifeAtYuvamanthan/21.jpeg" style={{width:"100%"}} />
        </div>
          </div>} */}

<ImageList
      sx={{  width: '100vw', height: '100vh' }}
      variant="quilted"
      cols={4}
      rowHeight={300}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
      </div>
      </>
  )
}

const itemData = [
    {
      img: '/lifeAtYuvamanthan/1.jpeg',
      title: 'Breakfast',
      rows: 2,
      cols: 2,
    },
    {
      img: '/lifeAtYuvamanthan/2.jpeg',
      title: 'Burger',
    },
    {
      img: '/lifeAtYuvamanthan/3.jpeg',
      title: 'Camera',
    },
    {
      img: '/lifeAtYuvamanthan/4.jpeg',
      title: 'Coffee',
      cols: 2,
    },
    {
      img: '/lifeAtYuvamanthan/5.jpeg',
      title: 'Hats',
      cols: 2,
    },
    {
      img: '/lifeAtYuvamanthan/6.jpeg',
      title: 'Honey',
      author: '@arwinneil',
      rows: 2,
      cols: 2,
    },
    {
      img: '/lifeAtYuvamanthan/7.jpeg',
      title: 'Basketball',
    },
    {
      img: '/lifeAtYuvamanthan/8.jpeg',
      title: 'Fern',
    },
    {
      img: '/lifeAtYuvamanthan/9.jpeg',
      title: 'Mushrooms',
      rows: 2,
      cols: 2,
    },
    {
      img: '/lifeAtYuvamanthan/10.jpeg',
      title: 'Tomato basil',
    },
    {
      img: '/lifeAtYuvamanthan/11.jpeg',
      title: 'Sea star',
    },
    {
      img: '/lifeAtYuvamanthan/12.jpeg',
      title: 'Bike',
      cols: 2,
    },
  ];