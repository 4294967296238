
import { Box, Button, TextField, RadioGroup, FormControlLabel, Radio, Typography, Select, MenuItem, IconButton, InputLabel, FormControl,Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import React, { useState, useEffect } from "react";
import { useFormik, FieldArray, FormikProvider } from "formik";
import * as yup from "yup";
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import axios from 'axios';

const validationSchema = yup.object({
    projectName: yup.string().trim().required("Project name is required"),
    participationType: yup.string().required("Participation type is required"), 
    memberRollNos: yup.array().when('participationType', {
        is: 'team',
        then: yup.array().of(yup.string().required("Member Id is required")).min(2, "At least 2 members are required").max(5, "No more than 5 members are allowed"),
        otherwise: yup.array().min(0)
    }),
    category: yup.string().required("Category is required"),
    description : yup.string().required("description  is required"),
});

const StudentApply = () => {
    const [categories, setCategories] = useState([]);
    const [registrationData, setRegistrationData] = useState(null);
    // const [alertMessage, setAlertMessage] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");


const userData = JSON.parse(localStorage.getItem('user')); 
const studentId = userData.id;

console.log(studentId);

// When creating a project, send instituteId to SEWA backend
// const createProject = async (projectData) => {
//     try {
//         const response = await fetch('/api/sewa/createProject', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({
//                 ...projectData,
//                 instituteId: instituteId, // Pass the instituteId
//             }),
//         });
//         const result = await response.json();
//         console.log(result);
//     } catch (error) {
//         console.error('Error creating project:', error);
//     }
// };


    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get("http://localhost:3000/api/categories/all");
                setCategories(response.data);
            } catch (error) {
                console.error("Failed to fetch categories:", error);
            }
        };

        fetchCategories();
    }, []);

    // useEffect(() => {
    //     const userData = JSON.parse(localStorage.getItem("user"));
    
    //     if (userData) {
    //       axios.post('http://localhost:3001/api/user_localstorage', { data: userData })
    //         .then(response => {
    //           console.log('Data sent successfully:', response.data);
    //         })
    //         .catch(error => {
    //           console.error('Error sending data:', error);
    //         });
    //     }
    //   }, []);

    const formik = useFormik({
        initialValues: {
            projectName: "",
            participationType: "individual",
            memberRollNos: [],
            category: "",
            description : ""
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await axios.post(
                    // creating projecct 
                    `http://localhost:3000/api/project/create/${studentId}`,
                    // "http://localhost:3000/api/project/create/66b68c59241a366b501c54e5",
                    values
                );

                // console.log("Form submitted successfully:", response.data.project);
                setRegistrationData(response.data.appliedEntry);
                setOpenSnackbar(true);
            setSnackbarMessage("project added successfully!");
            setSnackbarSeverity("success");
                resetForm();
            } catch (error) {
                setOpenSnackbar(true);
                setSnackbarMessage("Failed to add project. Please try again.");
                setSnackbarSeverity("error");
                console.error("Error adding project:", error);
            }
        },
    });
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

   

    return (
        <Box
            sx={{
                padding: "2rem",
                boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
                borderRadius: "1rem",
                backgroundColor: "var(--color-ghostwhite)",
                border: "5px solid #2356fe",
                width: "100%",
                margin: "2rem auto",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
            }}
        >
            <Typography variant="h4" sx={{ marginBottom: "1rem", textAlign: "center", color: "#2356fe" }}>
                PROJECT REGISTRATION FORM
            </Typography>
            <form onSubmit={formik.handleSubmit} >
                <Box sx={{ display: "flex", alignItems: "center", gap: "1rem", marginBottom: "1rem" }}>
                    <InputLabel htmlFor="projectName" sx={{ flex: "1", color: "#2356fe" }}>Project Name</InputLabel>
                    <TextField
                        fullWidth
                        id="projectName"
                        name="projectName"
                        value={formik.values.projectName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.projectName && Boolean(formik.errors.projectName)}
                        helperText={formik.touched.projectName && formik.errors.projectName}
                        sx={{
                            flex: "3",
                            borderColor: "#2356fe",
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: "#2356fe",
                                },
                                '&:hover fieldset': {
                                    borderColor: "#2356fe",
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: "#2356fe",
                                },
                            },
                        }}
                    />
                </Box>
                <Box sx={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
                    <InputLabel htmlFor="participationType" sx={{ flex: "1", color: "#2356fe" }}>Participating as</InputLabel>
                    <Box sx={{ flex: "3" }}>
                        <RadioGroup
                            row
                            name="participationType"
                            value={formik.values.participationType}
                            onChange={formik.handleChange}
                            sx={{ flex: "3" }}
                        >
                            <FormControlLabel value="individual" control={<Radio />} label="Individual" />
                            <FormControlLabel value="team" control={<Radio />} label="Team" />
                        </RadioGroup>

                        {formik.values.participationType === "team" && (
                            <FormikProvider value={formik}>
                                <FieldArray
                                    name="memberRollNos"
                                    render={arrayHelpers => (
                                        <>
                                            {formik.values.memberRollNos.map((member, index) => (
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginBottom: "1rem",
                                                        gap: "1rem",
                                                        flex: "3",
                                                    }}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        name={`memberRollNos[${index}]`}
                                                        value={member}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.memberRollNos?.[index] && Boolean(formik.errors.memberRollNos?.[index])}
                                                        helperText={formik.touched.memberRollNos?.[index] && formik.errors.memberRollNos?.[index]}
                                                        label={`Member ${index + 1}`}
                                                        sx={{
                                                            borderColor: "#2356fe",
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: "#2356fe",
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: "#2356fe",
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: "#2356fe",
                                                                },
                                                            },
                                                        }}
                                                    />
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                        disabled={formik.values.memberRollNos.length <= 2}
                                                    >
                                                        <RemoveCircle />
                                                    </IconButton>
                                                </Box>
                                            ))}
                                            <Button
                                                type="button"
                                                variant="outlined"
                                                onClick={() => arrayHelpers.push('')}
                                                startIcon={<AddCircle />}
                                                sx={{ color: "#2356fe", borderColor: "#2356fe", marginTop: "1rem" }}
                                                disabled={formik.values.memberRollNos.length >= 5}
                                            >
                                                Add Member
                                            </Button>
                                        </>
                                    )}
                                />
                            </FormikProvider>
                        )}
                    </Box>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", gap: "1rem", marginBottom: "1rem" }}>
                    <InputLabel htmlFor="category" sx={{ flex: "1", color: "#2356fe" }}>Category</InputLabel>
                    <FormControl fullWidth sx={{ flex: "3" }}>
                        <Select
                            id="category"
                            name="category"
                            value={formik.values.category}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.category && Boolean(formik.errors.category)}
                            sx={{
                                borderColor: "#2356fe",
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: "#2356fe",
                                    },
                                    '&:hover fieldset': {
                                        borderColor: "#2356fe",
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: "#2356fe",
                                    },
                                },
                            }}
                        >
                            <MenuItem value="" disabled>Select Category</MenuItem>
                            {categories.map((category) => (
                                <MenuItem key={category._id} value={category.name}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", gap: "1rem", marginBottom: "1rem" }}>
                    <InputLabel htmlFor="description" sx={{ flex: "1", color: "#2356fe" }}>Project Description</InputLabel>
                    <TextField
                        fullWidth
                        id="description"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                        sx={{
                            flex: "3",
                            borderColor: "#2356fe",
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: "#2356fe",
                                },
                                '&:hover fieldset': {
                                    borderColor: "#2356fe",
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: "#2356fe",
                                },
                            },
                        }}
                    />
                </Box>

                {formik.touched.category && formik.errors.category && (
                    <Typography color="error" variant="body2" sx={{ marginBottom: "1rem" }}>
                        {formik.errors.category}
                    </Typography>
                )}
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={!formik.isValid || !formik.dirty}
                        sx={{
                            alignSelf: "center",
                            padding: "10px 1rem",
                            backgroundColor: "#2356FE",
                            border: "none",
                            color: "#FFFFFF",
                            fontWeight: "600",
                            borderRadius: "10px",
                            width: "fit-content"
                        }}
                    >
                        SUBMIT
                    </Button>
                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
                </Box>
            </form>
        </Box>
    );
};

export default StudentApply;
