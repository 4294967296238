import React, { useState } from 'react';
import { FaArrowLeftLong, FaMagnifyingGlass } from 'react-icons/fa6';
import { IoMdCloseCircle } from 'react-icons/io';
import RuralImg from "../../assets/CarbonFootPrints/Rural.png";
import UrbanImg from "../../assets/CarbonFootPrints/Urban.png";
import BusImg from "../../assets/CarbonFootPrints/Bus.png";
import CarImg from "../../assets/CarbonFootPrints/Car.png";
import BicycleImg from "../../assets/CarbonFootPrints/Bicycle.png";
import BiomassImg from "../../assets/CarbonFootPrints/biomass.png";
import CalenderWeekImg from "../../assets/CarbonFootPrints/calendar-1-week.png";
import CalenderOnceImg from "../../assets/CarbonFootPrints/calender-days.png";
import CalenderMultipleImg from "../../assets/CarbonFootPrints/calendar-n-week.png";
import AirConditionerImg from "../../assets/CarbonFootPrints/air-conditioner.png";
import CeilingFanImg from "../../assets/CarbonFootPrints/ceiling-fan.png";
import CNGImg from "../../assets/CarbonFootPrints/Car.png";
import E_StoveImg from "../../assets/CarbonFootPrints/electric-stovetop.png";
import ElectrictyImg from "../../assets/CarbonFootPrints/electricity.png";
import FuelImg from "../../assets/CarbonFootPrints/fuel.png";
import GasPipeImg from "../../assets/CarbonFootPrints/gas.png";
import GasImg from "../../assets/CarbonFootPrints/gas-pipe-natural.png";
import RareImg from "../../assets/CarbonFootPrints/rare.png";
// import TempDecrImg from "../../assets/CarbonFootPrints/temperature-decrease.png";
import PetrolCanImg from "../../assets/CarbonFootPrints/petrol-can-gas.png";
import VegetarianImg from "../../assets/CarbonFootPrints/vegetarian.png";
import VeganImg from "../../assets/CarbonFootPrints/vegan.png";
import OmnivoreImg from "../../assets/CarbonFootPrints/omnivore.png";
import PescatarianImg from "../../assets/CarbonFootPrints/pescatarian.png";
import TempCoolImg from "../../assets/CarbonFootPrints/temp-cooling.png";
import TurbineImg from "../../assets/CarbonFootPrints/wind-turbine.png";

import * as yup from "yup";
import { useFormik } from 'formik';
import './CarbonFootPrintOption.css';
import CarbonCalculationResult from './CarbonCalculationResult';

const questionDetails = {
    'Transportation': [
        {
            type: 'radio',
            question: 'Residential Area?',
            name: 'residentialArea',
            options: [
                { value: 'Rural', label: 'Rural', imgSrc: RuralImg },
                { value: 'Urban', label: 'Urban', imgSrc: UrbanImg }
            ]
        },
        {
            type: 'radio',
            question: 'Vehicle?',
            name: 'vehicle',
            options: [
                { value: 'Car', label: 'Car', imgSrc: CarImg },
                { value: 'Bicycle', label: 'Bicycle', imgSrc: BicycleImg },
                { value: 'Bus', label: 'Bus', imgSrc: BusImg }
            ]
        },
        {
            type: 'text',
            question: 'Vehicle Make And Model?',
            name: 'vehicleMake',
            placeholder: 'Type Here',
            helperText: 'Helper message'
        },
        {
            type: 'radio',
            question: 'Vehicle Fuel Type?',
            name: 'fuelType',
            options: [
                { value: 'Petrol', label: 'Petrol', imgSrc: PetrolCanImg },
                { value: 'Diesel', label: 'Diesel', imgSrc: FuelImg },
                { value: 'CNG', label: 'CNG', imgSrc: CNGImg },
                { value: 'Electric', label: 'Electric', imgSrc: ElectrictyImg }
            ]
        },
        {
            type: 'text',
            question: 'Vehicle Fuel Efficiency (Mileage)',
            name: 'vehicleEfficiency',
            placeholder: 'Type Here',
            helperText: 'Helper message'
        },
        {
            type: 'text',
            question: 'Average Daily Commute Distance (in kilometers)',
            name: 'vehicleCommuteDistance',
            placeholder: 'Type Here',
            helperText: 'Helper message'
        },
        {
            type: 'text',
            question: 'Average Daily Commute Frequency (days per week)',
            name: 'vehicleCommuteFrequency',
            placeholder: 'Type Here',
            helperText: 'Helper message'
        }
    ],
    'Cooking': [
        {
            type: 'radio',
            question: 'Residential Area?',
            name: 'residentialArea',
            options: [
                { value: 'Rural', label: 'Rural', imgSrc: RuralImg },
                { value: 'Urban', label: 'Urban', imgSrc: UrbanImg }
            ]
        },
        {
            type: 'radio',
            question: 'Frequency of Cooking?',
            name: 'freqOfCooking',
            options: [
                { value: 'Daily', label: 'Daily', imgSrc: CalenderOnceImg },
                { value: 'Several times a week', label: 'Several times a week', imgSrc: CalenderMultipleImg },
                { value: 'Once a week', label: 'Once a week', imgSrc: CalenderWeekImg },
                { value: 'Rarely', label: 'Rarely', imgSrc: RareImg }
            ]
        },
        {
            type: 'radio',
            question: 'Cooking Fuel',
            name: 'cookingFuel',
            options: [
                { value: 'LPG', label: 'LPG', imgSrc: GasPipeImg },
                { value: 'Natural Gas', label: 'Natural Gas', imgSrc: GasImg },
                { value: 'Electric Stove', label: 'Electric Stove', imgSrc: E_StoveImg },
                { value: 'Biomass', label: 'Biomass', imgSrc: BiomassImg }
            ]
        },
        {
            type: 'text',
            question: 'Number of people in the household',
            name: 'peopleInhouse',
            placeholder: 'Type Here',
            helperText: 'Helper message'
        }, {
            type: 'text',
            question: 'On average, how many hours per day do you cook?',
            name: 'noOfHours',
            placeholder: 'On average, how many hours per day do you cook?',
            helperText: 'Helper message'
        }
    ],
    'Home Heating & Cooling': [
        {
            type: 'radio',
            question: 'Residential Area?',
            name: 'residentialArea',
            options: [
                { value: 'Rural', label: 'Rural', imgSrc: RuralImg },
                { value: 'Urban', label: 'Urban', imgSrc: UrbanImg }
            ]
        },
        {
            type: 'radio',
            question: 'Cooking Fuel',
            name: 'cookingFuel',
            options: [
                { value: 'LPG', label: 'LPG', imgSrc: GasPipeImg },
                { value: 'Natural Gas', label: 'Natural Gas', imgSrc: GasImg },
                { value: 'Electric Stove', label: 'Electric Stove', imgSrc: E_StoveImg },
                { value: 'Biomass', label: 'Biomass', imgSrc: BiomassImg },
                { value: 'No Heating', label: 'No Heating', imgSrc: TempCoolImg }
            ]
        },
        {
            type: 'radio',
            question: 'Cooling Source',
            name: 'coolingSource',
            options: [
                { value: 'Air Conditioning', label: 'Air Conditioning', imgSrc: AirConditionerImg },
                { value: 'Ceiling Fans', label: 'Ceiling Fans', imgSrc: CeilingFanImg },
                { value: 'Ceiling Fans', label: 'Ceiling Fans', imgSrc: TempCoolImg },
            ]
        },
        {
            type: 'radio',
            question: 'Heating Frequency',
            name: 'heatingFrequency',
            options: [
                { value: 'Daily', label: 'Daily', imgSrc: CalenderOnceImg },
                { value: 'Several times a week', label: 'Several times a week', imgSrc: CalenderMultipleImg },
                { value: 'Once a week', label: 'Once a week', imgSrc: CalenderWeekImg },
                { value: 'Rarely', label: 'Rarely', imgSrc: RareImg }
            ]
        },
        {
            type: 'radio',
            question: 'Cooling Frequency',
            name: 'coolingFrequency',
            options: [
                { value: 'Daily', label: 'Daily', imgSrc: CalenderOnceImg },
                { value: 'Several times a week', label: 'Several times a week', imgSrc: CalenderMultipleImg },
                { value: 'Once a week', label: 'Once a week', imgSrc: CalenderWeekImg },
                { value: 'Rarely', label: 'Rarely', imgSrc: RareImg }
            ]
        },
    ],
    'Energy Consumption': [
        {
            type: 'radio',
            question: 'Residential Area?',
            name: 'residentialArea',
            options: [
                { value: 'Rural', label: 'Rural', imgSrc: RuralImg },
                { value: 'Urban', label: 'Urban', imgSrc: UrbanImg }
            ]
        },
        {
            type: 'radio',
            question: 'Cooking Fuel',
            name: 'cookingFuel',
            options: [
                { value: 'Electricity', label: 'Electricity', imgSrc: ElectrictyImg },
                { value: 'Solar Panels', label: 'Solar Panels', imgSrc: UrbanImg },
                { value: 'Wind turbine', label: 'Wind turbine', imgSrc: TurbineImg },
            ]
        },
        {
            type: 'text',
            question: 'Monthly Electricity Consumption (in kWh)',
            name: 'electricyConsumption',
            placeholder: 'Type In Kwh.',
            helperText: 'Helper message'
        },

    ],
    'Diet': [
        {
            type: 'radio',
            question: 'Residential Area?',
            name: 'residentialArea',
            options: [
                { value: 'Rural', label: 'Rural', imgSrc: RuralImg },
                { value: 'Urban', label: 'Urban', imgSrc: UrbanImg }
            ]
        },
        {
            type: 'radio',
            question: 'Dietary Habits',
            name: 'dietaryHabits',
            options: [
                { value: 'Vegetarian', label: 'Vegetarian', imgSrc: VegetarianImg },
                { value: 'Vegan', label: 'Vegan', imgSrc: VeganImg },
                { value: 'Omnivore', label: 'Omnivore', imgSrc: OmnivoreImg },
                { value: 'Pescatarian', label: 'Pescatarian', imgSrc: PescatarianImg }
            ]
        },
        {
            type: 'radio',
            question: 'Frequency of Meat Consumption',
            name: 'freqOfMeatConsumption',
            options: [
                { value: 'Daily', label: 'Daily', imgSrc: CalenderOnceImg },
                { value: 'Several times a week', label: 'Several times a week', imgSrc: CalenderMultipleImg },
                { value: 'Once a week', label: 'Once a week', imgSrc: CalenderWeekImg },
                { value: 'Rarely', label: 'Rarely', imgSrc: RareImg }
            ]
        },
        {
            type: 'radio',
            question: 'Frequency of Processed Food Consumption',
            name: 'freqOfProcessedFoodConsumption',
            options: [
                { value: 'Daily', label: 'Daily', imgSrc: CalenderOnceImg },
                { value: 'Several times a week', label: 'Several times a week', imgSrc: CalenderMultipleImg },
                { value: 'Once a week', label: 'Once a week', imgSrc: CalenderWeekImg },
                { value: 'Rarely', label: 'Rarely', imgSrc: RareImg }
            ]
        },
    ]
};

const CarbonFootPrintOption = ({ title, onBack }) => {
    const [charCounts, setCharCounts] = useState({});
    const [isCompleted, setIsCompelete] = useState(false);
    const questions = questionDetails[title] || [];

    const validationSchema = yup.object().shape(
        questions.reduce((schema, question) => {
            if (question.type === 'text') {
                schema[question.name] = yup.string().required(`Required*`);
            } else if (question.type === 'radio') {
                schema[question.name] = yup.string().required(`Select your ${question.question}`);
            }
            return schema;
        }, {})
    );

    const initialValues = questions.reduce((values, question) => {
        values[question.name] = '';
        return values;
    }, {});


    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            console.log(values);
            resetForm();
            setIsCompelete(true);
        },
    });

    const handleInputClear = (name) => {
        formik.setFieldValue(name, '');
        setCharCounts((prevCharCounts) => ({
            ...prevCharCounts,
            [name]: 0
        }));
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCharCounts((prevCharCounts) => ({
            ...prevCharCounts,
            [name]: value.length
        }));
        formik.handleChange(e);
    };

    return (
        isCompleted ? <CarbonCalculationResult onBack={onBack} /> :
            <div className="carbon-option-container" >
                <div className="carbon-option-heading">
                    <h2>{title}</h2>
                    <FaArrowLeftLong className="back-arrow" onClick={onBack} />
                </div>
                <div className="carbon-option-questions">
                    <form onSubmit={formik.handleSubmit}>
                        {questions.map((question, index) => (
                            <div key={index} className="question-wrapper">
                                <h3 className='subheading'>{question.question}</h3>
                                {question.type === 'radio' ? (
                                    <>
                                        {formik.touched[question.name] && formik.errors[question.name] && (
                                            <span className="error" style={{ color: "red", margin: "-1rem 0" }}>{formik.errors[question.name]}</span>
                                        )}
                                        <div className="questions-options">
                                            {question.options.map((option, i) => (
                                                <div
                                                    key={i}
                                                    className={`option ${formik.values[question.name] === option.value ? 'selected' : ''}`}
                                                    onClick={() => formik.setFieldValue(question.name, option.value)}
                                                >
                                                    <div className="img-wrapper">
                                                        <img src={option.imgSrc} alt={option.label} />
                                                    </div>
                                                    <h4>{option.label}</h4>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                ) : question.type === 'text' ? (
                                    <div className="question-form">
                                        <label htmlFor={question.name}>
                                            {question.question} <span className="required">*</span>
                                        </label>
                                        <div className="input-container">
                                            {/* {question.question.includes('?') && <FaMagnifyingGlass />} */}
                                            <input
                                                type="text"
                                                name={question.name}
                                                id={question.name}
                                                placeholder={question.placeholder}
                                                value={formik.values[question.name]}
                                                onChange={handleInputChange}
                                                onBlur={formik.handleBlur}
                                                maxLength={100}
                                            />
                                            <IoMdCloseCircle onClick={() => handleInputClear(question.name)} />
                                        </div>

                                        <p className="helper-text">
                                            {formik.touched[question.name] && formik.errors[question.name] ? (
                                                <span style={{ color: "red" }}>
                                                    {formik.errors[question.name]}
                                                </span>
                                            ) : (
                                                <span>{question.helperText}</span>
                                            )}
                                            <span>{`${charCounts[question.name] || 0}/100`}</span>
                                        </p>
                                    </div>
                                ) : null}
                            </div>
                        ))}

                        <button type="submit" className="calculate-btn" >Calculate</button>
                    </form>
                </div>
            </div>
    );
};

export default CarbonFootPrintOption;