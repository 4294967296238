import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Rating } from '@mui/material';
import styled from 'styled-components';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import ArrowBackIosTwoToneIcon from '@mui/icons-material/ArrowBackIosTwoTone';

const SlideWrapper = styled.div`
  height: 500px;
  max-height: 500px;
  width: 300px; /* Set this width for mobile view */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 3.53px 56.52px #00000040;
  @media (min-width: 768px) {
    width: 407px; /* Adjust width for larger screens if needed */
  }
`;

const SwiperWrapper = styled(Swiper)`

  .swiper-slide {
    transition: transform 0.3s, filter 0.3s;
    transform: scale(0.8);
    filter: blur(3px);
  
  }

  .swiper-slide-active {
    transform: scale(0.9) !important;
    filter: blur(0);
  }

  .swiper-wrapper {
    padding: 3rem !important;
  }
  .ShapeTxt{
    padding:22px;
  }
  @media (max-width: 768px) {
.swiper-wrapper {
  padding: 1.3rem !important;
}

}
@media (max-width: 500px) {
  .shapeWrapper{
    height: 500px;
  margin-top: -35%;
  
  }
  .ShapeTxt{
padding: 10px 10px 0px 10px;
  }
}
`;

const CustomNavigation = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const CustomButton = styled.button`
  background-color: transparent;
  color: #007bff;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border: 2px solid #007bff;
  border-radius:30px;
  font-weight:bold;
  cursor: pointer;
  font-size: 16px;
  transition:all 0.5s ease-in-out;

  &:hover {
    background-color: #007bff;
    color:#fff;
  }
`;


const SlideComp = () => {
  return (
    <SlideWrapper>
      <div className="d-flex flex-column align-items-center justify-content-center overflow-hidden">
        <h2 className='fs-1 fw-semibold mt-3 mb-0'>~Prof.S.K.Singh </h2>
        <span className='fs-6 fw-normal p-0'>(Vice Chancellor)</span>
        <p className='mt-3 mb-0' style={{fontSize:"14px"}}>Rajasthan Technical University Kota</p>
        <div className='shapeWrapper position-relative w-100 '>
          <img src="./Shapecircle.svg" alt="" className='w-100 h-100 ' />
        </div>
        <div className="position-absolute" style={{bottom:"6%"}}>
          <div className="d-flex flex-column justify-content-center align-items-center ">
            <span className=' fw-normal ShapeTxt' style={{fontSize:"16px",color:"#FFFFFF",padding:" 0 10px !important"}}>"Vice Chancellor Prof. SK Singh in his message said that Yuva Manthan model is a good opportunity for the youth to come forward and share their views on global issues which zis a good initiative to solve the problems faced by the global citizens. Yuva Manthan model is an extraordinary platform designed to provide a transformative learning experience to the future youth of India."</span>
            <Rating value={4.5} readOnly />
          </div>
        </div>
      </div>
    </SlideWrapper>
  );
};


const Slider = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <>
      <SwiperWrapper
        centeredSlides={true}
        spaceBetween={30}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1100: {
            slidesPerView: 3,
          },
          1400: {
            slidesPerView: 4,
          },
        }}
        modules={[Navigation]}
        className="mySwiper w-100"
      >
        <SwiperSlide><SlideComp /></SwiperSlide>
        <SwiperSlide><SlideComp /></SwiperSlide>
        <SwiperSlide><SlideComp /></SwiperSlide>
        <SwiperSlide><SlideComp /></SwiperSlide>
        <SwiperSlide><SlideComp /></SwiperSlide>
      </SwiperWrapper>
      <CustomNavigation>
        <CustomButton ref={prevRef}>
         <ArrowBackIosTwoToneIcon />
        </CustomButton>
        <CustomButton ref={nextRef}>
          <ArrowForwardIosTwoToneIcon />
        </CustomButton>
      </CustomNavigation>
    </>
  );
};

export default Slider;
