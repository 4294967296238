import React, { useEffect, useState } from "react";
import "../Css/main.css";
import { Link, useNavigate } from "react-router-dom";
import seventyfiveimg from "../assests/seventyfive.png";
import { apiJson } from "api";
import { useGlobalContext } from "global/context";
import { ThreeDots } from "react-loader-spinner";

const SeventyFiveMain = () => {
  const { userData } = useGlobalContext();
  const [status, setStatus] = useState();
  const [attemptId, setAttemptId] = useState();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)

  const userDetails = {
    userId: userData.id,
    role: userData.role,
    instituteId: userData.instituteId,
  };
  const startSteps = () => {
    try {
      setLoader(true)
      apiJson(
        `/api/v2/75daysChallenge/checkUserAttempt/${userData.id}/role/${userData.role}`
      ).then((res) => {
        setStatus(res?.data?.result?.status);
        setAttemptId(res?.data?.result?.id);
        setLoader(false)
      });
    } catch (err) {
      console.log(err.message);
    }
  };
  const startGameSteps = async () => {
    try {
      const response = await apiJson.post(
        "/api/v2/75daysChallenge/addAttempts",
        userDetails
      );
      setAttemptId(response?.data?.result?.id);
      navigate(`/steps/seventy-five-steps/today-steps/${response?.data?.result?.id}`);
    }catch(err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    startSteps();
  }, []);

  return (
    <>
      <div className="mainContent position-relative">
        <img
          src={seventyfiveimg}
          alt="environment"
          className="d-block m-auto w-75"
          loading="lazy"
        />
        <p className="text-center w-75 m-auto">
          These unique categories are thematically aligned to global
          sustainability, namely - water saving, single-use plastic, sustainable
          food systems, waste reduction, energy, e-waste, and healthy lifestyle.
        </p>

        <div className="d-flex justify-content-center box-shadow-0">
        {
          loader? <ThreeDots/> : status === "pending" ? (
            <a
              onClick={()=>navigate(`/steps/seventy-five-steps/today-steps/${attemptId}`)}
              className="mt-4 text-decoration-none text-center "
            >
              Continute
            </a>
          ) : status === "completed" ? (
            <a
              onClick={startGameSteps}
              className="mt-4 text-decoration-none text-center "
            >
              Retake
            </a>
          ) : (
            <a
              onClick={startGameSteps}
              className="mt-4 text-decoration-none text-center "
            >
              Start Game
            </a>
          )
        }
        </div>

        <hr className="w-75 m-auto mt-5" />

        <div className="lowerContent">
          <p className="text-center my-4">How to Play</p>

          <div className="tasks row text-center m-auto p-0 m-0 d-flex justify-content-center box-shadow-0">
            <div className="col-lg-3 col-sm-6 box-shadow-0">
              <p className="text-center text-white one m-auto">1</p>
              <p className="secondpara text-center mt-3">Take a daily assigned challenge</p>
            </div>
            <div className="col-lg-3 col-sm-6 box-shadow-0">
              <p className="text-start text-white one m-auto">2</p>
              <p className="secondpara text-center mt-3">
                Complete the challenge by doing activity
              </p>
            </div>
            <div className="col-lg-3 col-sm-6 box-shadow-0">
              <p className="text-start text-white one m-auto">3</p>
              <p className="secondpara text-center mt-3">Take a selfie of a challenge</p>
            </div>
            <div className="col-lg-3 col-sm-6 box-shadow-0">
              <p className="text-start text-white one m-auto">4</p>
              <p className="secondpara text-center mt-3">Upload the selfie</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeventyFiveMain;
