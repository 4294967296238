import React from "react";
import { useNavigate } from "react-router-dom";
import { ForwardOutlined, SmartDisplayTwoTone } from "@mui/icons-material";

export const StudentCourseCard = ({ data }) => {
  const navigate = useNavigate();
  const handleNavigate = (id) => {
    navigate(`/new-dashboard/all-courses/course-overview/${id}`);
  };
  return (
    <>
      <div className="course-card cursor-pointer my-2 position-relative p-3 m-5">
        <div className="image-Container top-0 position-relative d-flex align-items-center">
          <img src={data?.bg_image} alt="Shailesh" width="100%" />
          <img
            src={data?.thumbnail}
            alt="Overlay Image"
            className="position-absolute start-0 overlay-image h-100"
          />
          <div className="courseStatus position-absolute d-flex justify-content-end w-100">
            {data?.status === "Complete" ? (
              <>
                <span
                  className="fs-11 px-2 py-1 rounded-3"
                  style={{
                    background: "#DDFFE7",
                    border: "1px solid #92FFB7",
                    color: "#92FFB7",
                    fontWeight: 700,
                  }}
                >
                  Completed
                </span>
              </>
            ) : (
              <>
                <span
                  className="fs-11 px-2 py-1 rounded-3"
                  style={{
                    background: "#FFF8B5",
                    color: "#A6A900",
                    border: "1px solid #A6A900",
                    fontWeight: 700,
                  }}
                >
                  In Progress
                </span>
              </>
            )}
          </div>
        </div>

        <p className="CardTitle mt-2 mb-0">{data?.course_title}</p>
        <p className="CourseText mt-1">
          A course by <span className="courseBy">{data?.course_by}</span>
        </p>

        <div className="d-block d-md-flex flex-column mt-2">
          <div className="d-flex flex-column">
            <span className="CourseText">
              Language: <span className="courseBy">{data?.language}</span>
            </span>
            <span className="CourseText">
              Duration:{" "}
              <span className="courseBy" style={{ color: "#555555" }}>
                {data?.duration}
              </span>
            </span>
          </div>
          <button
            className="cartbutton rounded-3 cursor-pointer py-2"
            onClick={() => {
              navigate("/new-dashboard/student-course");
            }}
          >
            {data?.status === "Complete" ? (
              <ForwardOutlined
                sx={{ fontSize: "17px", paddingBottom: "2px" }}
              />
            ) : (
              <SmartDisplayTwoTone
                sx={{ fontSize: "17px", paddingBottom: "2px" }}
              />
            )}{" "}
            {data?.status === "Complete" ? "Completed" : "Resume Course"}
          </button>
        </div>
      </div>
    </>
  );
};
