import React, { useEffect } from 'react'

const ShowProofId = ({ title, showProof, url, setShowProof }) => {
  useEffect(() => {
    const proofIdUrl = url;

    if (proofIdUrl) {
      const isPdf = proofIdUrl.endsWith('.pdf');
      setShowProof({ src: proofIdUrl, isPdf: isPdf });
    }
  }, [url]);
  return (
    <div className='mt-3'>
      <div>

        <span className='fs-5 fw-500'>{title}</span>
      </div>
      <div className='card mt-5'>
        <div className='d-flex justify-content-center p-3'>
          {showProof?.isPdf ? (
            <iframe src={"https://docs.google.com/gview?embedded=true&url=" + showProof?.src} height={300} style={{ width: "100%" }}></iframe>
          ) : (
            <div className='d-flex justify-content-center' style={{ maxHeight: "200px", maxWidth: 200, maxHeight: "200px", objectFit: "contain", textAlign: "center" }}>
              <img className='h-100 w-100' src={showProof?.src} alt="Proof" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ShowProofId