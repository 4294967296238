import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import { apiJson } from "api";
import { useGlobalContext } from "global/context";
import YuvaLoader from "pages/Forum/components/Loader/YuvaLoader";
import { toast } from "react-toastify";

const CareerAdviceSection = () => {
  const [careers, setCareers] = useState([]);
  const [selectedCareer, setSelectedCareer] = useState(null);
  const [careerDetails, setCareerDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { userData } = useGlobalContext();
  console.log(careers);

  const fetchCareers = async () => {
    setIsLoading(true);
    try {
      const response = await apiJson(
        `api/v2/riasecTest/riasec-careers/${userData?.id}`
      );
      if (response.data?.result?.length) {
        setCareers(response.data.result);
        setError(null);
        setSelectedCareer(response.data.result[0]);
        setCareerDetails(response.data.result[0]);
      } else {
        setError("No careers available.");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError("Could not fetch the Careers list. Please try again later.");
      toast.error("Could not fetch the Careers list. Please try again later.");
      console.error("Error fetching careers:", error);
    }
  };

  const fetchCareerDetails = (careerId) => {
    const career = careers.find((c) => c.careerId === careerId);
    if (career) {
      setCareerDetails(career);
    }
  };

  useEffect(() => {
    if (userData?.id) {
      fetchCareers();
    }
  }, [userData]);

  return (
    <Box sx={{ mt: 4, textAlign: "center" }}>
      {isLoading ? (
        <YuvaLoader setShow={isLoading} show={isLoading} />
      ) : careers.length > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            {careers.map((career) => (
              <Card
                key={career.careerId}
                onClick={() => {
                  setSelectedCareer(career);
                  fetchCareerDetails(career.careerId);
                }}
                sx={{
                  cursor: "pointer",
                  marginBottom: "1rem",
                  border: "2px solid #2356fe",
                  background:
                    selectedCareer?.careerId === career.careerId
                      ? "#2356fe"
                      : "#fff",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color:
                        selectedCareer?.careerId === career.careerId
                          ? "#fff"
                          : "#2356fe",
                    }}
                  >
                    {career.careerName}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Grid>

          <Grid item xs={12} md={8}>
            {careerDetails ? (
              <Box
                sx={{
                  border: "13px solid #2356fe",
                  backgroundColor: "#e6f0ff",
                  borderRadius: "2rem",
                  textAlign: "left",
                  padding: "1rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      color: "#fff",
                      mb: 2,
                      textAlign: "end",
                      backgroundColor: "#2356fe",
                      padding: "1rem",
                      borderLeft: "0.5rem",
                      borderTopLeftRadius: "2rem",
                    }}
                  >
                    Yuvamanthan - CAREER GUIDE
                  </Typography>
                </Box>

                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    color: "#2356fe",
                    mb: 2,
                    textAlign: "start",
                    textDecoration: "underline",
                  }}
                >
                  {careerDetails.careerName}
                </Typography>

                {/* Render the Markdown content */}
                <ReactMarkdown
                  children={careerDetails.careerDetails}
                  components={{
                    h1: ({ node, ...props }) => (
                      <Typography
                        variant="h2"
                        sx={{
                          color: "#2356fe",
                        }}
                        {...props}
                      />
                    ),
                    h2: ({ node, ...props }) => (
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#2356fe",
                          mb: 2,
                        }}
                        {...props}
                      />
                    ),
                    h3: ({ node, ...props }) => (
                      <Typography
                        variant="h6"
                        sx={{
                          mb: 2,
                          mt: 1,
                          fontWeight: "bold",
                        }}
                        {...props}
                      />
                    ),
                    p: ({ node, ...props }) => (
                      <Typography variant="p" sx={{ mb: 2 }} {...props} />
                    ),
                    ol: ({ node, ...props }) => (
                      <ol
                        style={{
                          paddingLeft: "1.5rem",
                        }}
                        {...props}
                      />
                    ),
                    li: ({ node, ...props }) => (
                      <Typography
                        component="li"
                        variant="li"
                        sx={{ mb: 0.5 }}
                        {...props}
                      />
                    ),
                  }}
                />
              </Box>
            ) : (
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "#2356fe" }}
              >
                Please select a career to see the details.
              </Typography>
            )}
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#f44336", mt: 4 }}
          >
            {error ||
              "No careers available at the moment. Please try again later."}
          </Typography>
          <Button variant="contained" sx={{ mt: 2 }} onClick={fetchCareers}>
            Try Again
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CareerAdviceSection;
