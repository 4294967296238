import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { apiAuth, apiJson } from "api";
import Seva_Logo from "../../assets/Seva/Seva_logo.png";

const MentorPOW = () => {
  const [fileName, setFileName] = useState("");
  const [dailyTaskFileLink, setPOWFileLink] = useState("");
  const [dailyTaskFileError, setPOWFileError] = useState("");
  const [activeDayData, setActiveDayData] = useState({
    credit: 25,
    task_count: 1,
  });
  const [isComplete, setIsComplete] = useState(false);
  const [userData] = useState(() => JSON.parse(localStorage.getItem("user")));
  const submitEndpoint = "/api/v2/seva/submitPOW";

  const getImageLink = (imageFileData) => {
    if (imageFileData) {
      apiAuth
        .post("v2/register/uploadInstituteLogo", { img: imageFileData })
        .then((res) => {
          console.log(res, "response value");
          setPOWFileLink(res?.data?.result);
        })
        .catch((error) => {
          console.log("Internal server error", error.message);
        });
    }
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, { resetForm }) => {
      debugger;
      if (!dailyTaskFileLink) {
        setPOWFileError("Please select file");
        return;
      }

      const data = {
        userId: userData.id,
        role: userData.role,
        instituteId: userData.instituteId,
        taskId: activeDayData?.taskId || null,
        status: "Completed",
        images: dailyTaskFileLink,
      };

      try {
        const response = await apiJson.post(submitEndpoint, data);
        if (response.status === 200) {
          console.log(data);
          setIsComplete(true);
          resetForm();
          setPOWFileLink("");
          setFileName("");
        } else {
          console.log("Failed to submit the challenge");
        }
      } catch (err) {
        console.error("API call error:", err);
      }
    },
  });

  const handleFiles = (files) => {
    setPOWFileError("");
    const file = files[0];
    if (file && file.type === "application/zip") {
      getImageLink(file);
      setFileName(file.name);
    } else {
      setPOWFileError("Invalid File. Please upload a zip folder.");
    }
  };

  useEffect(() => {
    const dropArea = document.getElementById("drop-area");

    if (!dropArea) {
      return;
    }

    const events = ["dragenter", "dragover", "dragleave", "drop"];

    const preventDefaults = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const highlight = (e) => {
      dropArea.classList.add("highlight");
    };

    const unHighlight = (e) => {
      dropArea.classList.remove("highlight");
    };

    const handleDrop = (e) => {
      const dt = e.dataTransfer;
      const files = dt.files;
      handleFiles(files);
    };

    events.forEach((eventName) => {
      dropArea.addEventListener(eventName, preventDefaults, false);
    });

    ["dragenter", "dragover"].forEach((eventName) => {
      dropArea.addEventListener(eventName, highlight, false);
    });

    ["dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, unHighlight, false);
    });

    dropArea.addEventListener("drop", handleDrop, false);

    return () => {
      events.forEach((eventName) => {
        dropArea.removeEventListener(eventName, preventDefaults, false);
      });

      ["dragenter", "dragover"].forEach((eventName) => {
        dropArea.removeEventListener(eventName, highlight, false);
      });

      ["dragleave", "drop"].forEach((eventName) => {
        dropArea.removeEventListener(eventName, unHighlight, false);
      });

      dropArea.removeEventListener("drop", handleDrop, false);
    };
  }, []);

  return (
    <>
      {!isComplete && (
        <Box
          sx={{
            padding: "2rem 2rem 2rem 1.5rem",
            boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
            borderRadius: "1rem",
            backgroundColor: "var(--color-ghostwhite)",
            border: "5px solid #2356fe",
            boxSizing: "border-box",
            width: "auto",
          }}
        >
          {/* Challenge Description Wrapper - Top Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "@media(max-width:900px)": {
                flexDirection: "column-reverse",
              },
            }}
          >
            {/* Challenge Details - Left Pane */}
            <Box>
              {/* Challenge Title */}
              <Typography
                component={"h1"}
                sx={{
                  fontSize: "1.6rem",
                  fontWeight: 900,
                  color: "#2356fe",
                  padding: "1rem 0rem 1rem 0rem",
                }}
              >
                Upload Mentor Documents
              </Typography>
              {/* Challenge Description */}
              <Typography
                component={"p"}
                sx={{
                  fontWeight: 600,
                  color: "gray",
                }}
              >
                {activeDayData
                  ? activeDayData?.description
                  : "Carry A Non-Plastic Bottle While Steeping Out Of Homes"}
              </Typography>
            </Box>

            {/* Credits Display Container - Right Pane */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                alignItems: "center",
              }}
            >
              {/* Logo */}
              <Box>
                <Typography
                  component={"img"}
                  src={Seva_Logo}
                  alt="Seva Logo"
                  sx={{ width: "150px" }}
                />
              </Box>
            </Box>
          </Box>

          {/* Challenge Display Area - Bottom Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              "@media(max-width:900px)": {
                flexDirection: "column",
              },
            }}
          >
            {/* Task Upload - Left Pane */}
            <Box
              sx={{
                width: "100%",
                padding: "0.8rem",
                "@media(max-width:900px)": {
                  width: "100%",
                },
              }}
            >
              {/* File Upload Section */}
              <Box>
                <Box
                  sx={{
                    position: "relative",
                  }}
                  id="drop-area"
                >
                  {/* Input Section */}
                  <Typography
                    component={"input"}
                    sx={{
                      display: "none",
                    }}
                    type="file"
                    id="fileElem"
                    accept="image/*"
                    onChange={(e) => handleFiles(e.target.files)}
                  />
                  {/* Label for the Image Input */}
                  <Typography
                    component={"label"}
                    sx={{
                      padding: "10px",
                      cursor: "pointer",
                      borderRadius: "6px",
                      minWidth: "100%",
                      maxWidth: "100%",
                      boxSizing: "border-box",
                      border: "2px dashed black",
                      height: "150px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f6f6f6",
                      ":hover": {
                        backgroundColor: "#e9e9e9",
                      },
                      ":active": {
                        backgroundColor: "#f6f6f6",
                      },
                    }}
                    htmlFor="fileElem"
                  >
                    <Typography
                      component={"div"}
                      sx={{
                        color: "#666",
                        userSelect: "none",
                        textAlign: "center",
                      }}
                    >
                      Drag Image here or click to upload
                    </Typography>
                  </Typography>

                  {/* Line Below the Input Section */}
                  <Typography
                    component={"p"}
                    sx={{
                      fontSize: "0.85rem",
                      padding: "0.2rem",
                      textAlign: "center",
                    }}
                  >
                    Drag File Here Or Click To{" "}
                    <Typography component={"span"} sx={{ fontWeight: 600 }}>
                      Select File To Upload{" "}
                    </Typography>{" "}
                    Documents
                  </Typography>
                  {/* File Upload Error Box */}
                  <Typography
                    component={"p"}
                    sx={{
                      textAlign: "center",
                      color: "red",
                      padding: 0,
                    }}
                  >
                    {dailyTaskFileError}
                  </Typography>
                  {/* File Name Display */}
                  <Typography
                    component={"p"}
                    sx={{
                      textAlign: "center",
                      color: "#666",
                      padding: 0,
                    }}
                  >
                    {fileName}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Bottom Section: Call to Action Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "0.8rem",
              "@media(max-width:900px)": {
                justifyContent: "center",
              },
            }}
          >
            {/* Submit Button */}
            <Button
              onClick={formik.handleSubmit}
              sx={{
                padding: "0.5rem 1.5rem",
                backgroundColor: "#2356fe",
                color: "white",
                fontWeight: 700,
                textTransform: "none",
                borderRadius: "0.8rem",
                fontSize:"1.2rem",
                ":hover": {
                  backgroundColor: "#0038ff",
                },
                ":active": {
                  backgroundColor: "#2356fe",
                },
                ":disabled": {
                  backgroundColor: "#2356fe",
                  opacity: 0.5,
                },
              }}
            >
              Upload 
            </Button>
          </Box>
        </Box>
      )}

      {/* Task Completion Section */}
      {isComplete && (
        <Box>
          <Typography
            component={"h1"}
            sx={{
              fontSize: "1.6rem",
              fontWeight: 900,
              color: "#2356fe",
              padding: "1rem 0rem 1rem 0rem",
            }}
          >
            Task Completed
          </Typography>
        </Box>
      )}
    </>
  );
};

export default MentorPOW;
