import React from "react";
import HealthPageContent from "./HealthPageContent";

export const HealthPage = () => {
  return (
    <>
      <div className="px-2 py-4 mb-3 ">
        <HealthPageContent />
      </div>
    </>
  );
};
