import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

export default function CourseDetailsTab() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
            <Box sx={{ maxWidth: { xs: 320, sm: 480, md: "100%" }, display: 'flex', justifyContent: { xs: 'flex-start', md: 'center' } }}>
                    <TabList onChange={handleChange} variant='scrollable' scrollButtons="auto" aria-label="lab API tabs example">
                        <Tab label="Course Overview" value="1" className='fw-bold text-capitalize' />
                        <Tab label="What you will learn" value="2" className='fw-bold text-capitalize' />
                        <Tab label="Benefits of this Course" value="3" className='fw-bold text-capitalize' />
                    </TabList>
                </Box>
                <div className="container">
                <TabPanel value="1">
                    <p className='text-md-justify'>An e-Module for school students from classes 8th to 12th to educate, inform and activate the knowledge of IPR
                        systems. The e-Module will explain in detail both nations and the international IPR ecosystem to the students and
                        will help them understand the foundational elements that constitute the importance of IPR with a focus on Patents,
                        Copyrights, Trademarks, Design and Geographical Indications.</p>
                </TabPanel>
                <TabPanel value="2">
                    <h6>Introduction to IPR Awareness</h6>
                    <h6>What are Intellectual Property Rights?</h6>
                    <h6>What are the different types of Intellectual Properties?</h6>
                    <h6>Invention, creation and Ownership</h6>
                    <h6>Famous inventors and inventions</h6>
                    <h6>Famous Indian inventions</h6>
                    <h6>IP in everyday life</h6>
                    <h6>Importance of IP?</h6>
                    <ul className='fs-6 ms-5 mb-3' style={{ listStyleType: 'disc' }}>
                        <li className='text-md-justify mt-2' ><span className='fw-bold text-secondary fs-6'>Importance to customers:</span> Intellectual Property (IP) advantages for individuals who purchase
                            and utilize the product.</li>
                        <li className='text-md-justify mt-2'><span className="fw-bold text-secondary fs-6">Importance to companies:</span> How Intellectual Property (IP) generates income and enhances the
                            company's overall value.</li>
                        <li className='text-md-justify mt-2'><span className="fw-bold text-secondary fs-6">Importance to society:</span> How society gains from individuals or organizations employing this
                            Intellectual Property (IP).</li>
                    </ul>
                    <h6>Types of IPRs</h6>
                    <ul className="fs-6 ms-5 mb-3" style={{ listStyleType: 'disc' }}>
                        <li>Patents</li>
                        <li>Designs</li>
                        <li>Trademarks</li>
                        <li>Geographical Indications</li>
                        <li>Copyrights</li>
                        <li>Semiconductor Integrated Circuit Layout Designs</li>
                    </ul>
                    <h6>What is a Patent</h6>
                    <ul className="fs-6 ms-4 mb-3">
                        <li>- What are different types of Patents</li>
                        <li>- Why do you need to get a Patent</li>
                        <li>- How to get a Patent in India</li>
                        <li>- Expiry of Patents</li>
                    </ul>
                    <h6>What is Copyrights</h6>
                    <ul className="fs-6 ms-4 mb-3">
                        <li>- What is Plagiarism</li>
                        <li>- Expiry of Copyright?</li>
                        <li>- Internet and Copyrights</li>
                        <li>- What is protected on the www</li>
                        <li>- Do’s and Dont’s for the Internet</li>
                    </ul>
                    <h6>What is a Trademark</h6>
                    <ul className="fs-6 ms-4 mb-3">
                        <li>- Examples of Trademarks</li>
                        <li>- How to recognise a Trademark</li>
                        <li>- Benefits of getting a Trademark</li>
                    </ul>
                    <h6>Other forms of IPRs (GI, Designs and Circuits)</h6>
                    <h6>Benefits of IP Protection</h6>
                    <h6>Case Studies - Famous examples of IP in the Indian industry (at least 4 case studies)</h6>
                    <h6>Ways to Protect Yourself From Counterfeiting and Piracy</h6>
                    <h6>How IP contributes to a country’s economy</h6>
                    <h6>Key Challenges for IP protection</h6>
                    <h6>Quiz Module</h6>
                </TabPanel>
                <TabPanel value="3">
                    <ul>
                        <li className='text-md-justify mt-2'>● Explore various forms of intellectual property rights (IPR), such as copyright, patent, and trademark, and delve into the corresponding legal safeguards for each.</li>
                        <li className='text-md-justify mt-2'>● Grasp the intricacies and advantages of securing and preserving your IPR.</li>
                        <li className='text-md-justify mt-2'>● Uncover valuable resources to effectively uphold and defend your intellectual property rights.</li>

                    </ul>
                </TabPanel>
                </div>
            </TabContext>
        </Box>
    );
}
