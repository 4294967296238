import React from "react";
import challengeBg from "../../../pages/twentyOneDaysChallenge/assests/21_challenge_bg.png";
import heading21Img from "../../../pages/twentyOneDaysChallenge/assests/21_challenge_heading.png";
import heading75Img from "../../../pages/seventyFiveDaysGame/assests/seventyfive.png";
import winnerHero from "../../../pages/twentyOneDaysChallenge/assests/winner_img.png";
import "./CompleteTaskPage.css";

const CompleteTaskPage = ({ totalDays, taskDetails, handleNextDay }) => {
  console.log("Current Day Details: ", taskDetails);
  return (
    <>
      <div
        className="challenge-completion"
        style={{ backgroundImage: `url(${challengeBg})` }}
      >
        <div className="left-area">
          <div className="img-wrapper">
            <img
              src={totalDays === 75 ? heading75Img : heading21Img}
              alt={totalDays === 75 ? "75 Days Challenge" : "21 Days Challenge"}
            />
          </div>

          <h1>
            {taskDetails.day_count}th Day Challenge
            <br />
            Completed
            <br />
            Successfully
          </h1>
        </div>
        <div className="right-area">
          <div className="details">
            <h3>
              {taskDetails.day_count}/{totalDays === 75 ? 75 : 21} Challenges
              Completed
            </h3>
            <p className="success-msg">File Has Been Submitted</p>
            <p className="sub-heading">
              Your {taskDetails.credit} Credits has been Granted to You!
            </p>
          </div>
          <div className="img-wrapper">
            <img src={winnerHero} alt="Task Completed" />
          </div>
          <button className="primary-btn" onClick={handleNextDay}>
            Next
          </button>
          {/* {subTopic.checked ? 'Completed' : 'Next'}</button> */}
        </div>
      </div>
    </>
  );
};

export default CompleteTaskPage;
