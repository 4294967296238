// import { Box, Button, TextField, Typography, InputLabel, Snackbar } from "@mui/material";
// import MuiAlert from '@mui/material/Alert';
// import React, { useState } from "react";
// import { useFormik } from "formik";
// import * as yup from "yup";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';
// import weekday from 'dayjs/plugin/weekday';
// import axios from "axios";

// dayjs.extend(weekday);

// const validationSchema = yup.object({
//     activityPlan: yup.string().required("Activity Plan is required"),
// });

// const HourlySchedule = () => {
//     const [dayCount, setDayCount] = useState(1);
//     const [currentDate, setCurrentDate] = useState(dayjs());
//     const [openSnackbar, setOpenSnackbar] = useState(false);
//     const [snackbarMessage, setSnackbarMessage] = useState("");
//     const [snackbarSeverity, setSnackbarSeverity] = useState("success");

//     const formik = useFormik({
//         initialValues: {
//             activityPlan: "",
//             date: currentDate,
//         },
//         validationSchema,
//         onSubmit: async (values, { resetForm }) => {
//             try {
                
//                 setOpenSnackbar(true);
//                 setSnackbarMessage("Plan submitted successfully!");
//                 setSnackbarSeverity("success");
//                 resetForm();
//             } catch (error) {
//                 setOpenSnackbar(true);
//                 setSnackbarMessage("Failed to submit plan. Please try again.");
//                 setSnackbarSeverity("error");
//                 console.error("Error submitting plan:", error);
//             }
//         },
//     });

//     const handleCloseSnackbar = () => {
//         setOpenSnackbar(false);
//     };

//     const handleSave = async () => {
//         try {
//             const values = formik.values;
            
//             const response = await axios.post('/api/document/hourly-schedule', {
//                 formType: 'sewaHourlySchedule',
//                 dayCount: dayCount,
//                 date: values.date,
//                 activityPlan: values.activityPlan,
//             });
    
//             setOpenSnackbar(true);
//             setSnackbarMessage(response.data.message || "Plan saved successfully!");
//             setSnackbarSeverity("success");
//         } catch (error) {
//             setOpenSnackbar(true);
//             setSnackbarMessage("Failed to save plan. Please try again.");
//             setSnackbarSeverity("error");
//             console.error("Error saving plan:", error);
//         }
//     };

//     const handleNext = () => {
//         if (dayCount < 8) {

//             setDayCount(dayCount + 1);
//             const nextDate = currentDate.add(1, 'day');
//             setCurrentDate(nextDate);

//             formik.setFieldValue('date', nextDate);
//             formik.resetForm();  
//         }
//     };

//     return (
//         <Box
//             sx={{
//                 padding: "2rem",
//                 boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
//                 borderRadius: "1rem",
//                 backgroundColor: "var(--color-ghostwhite)",
//                 border: "5px solid #2356fe",
//                 width: "100%",
//                 margin: "2rem auto",
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "1rem",
//             }}
//         >
//             <Typography variant="h4" sx={{ marginBottom: "1rem", textAlign: "center", color: "#2356fe" }}>
//                 HOURLY SCHEDULE DAY {dayCount}
//             </Typography>
//             <form onSubmit={formik.handleSubmit}>
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                     <Box
//                         sx={{
//                             display: 'flex',
//                             alignItems: 'center',
//                             gap: '1rem',
//                             marginBottom: '1rem',
//                         }}
//                     >
//                         <InputLabel
//                             htmlFor="date"
//                             sx={{ flex: '1', color: '#2356fe' }}
//                         >
//                             Select Date
//                         </InputLabel>
//                         <DatePicker
//                             id="date"
//                             name="date"
//                             value={formik.values.date}
//                             onChange={(newValue) => formik.setFieldValue('date', newValue)}
//                             onBlur={formik.handleBlur}
//                             renderInput={(params) => (
//                                 <TextField
//                                     {...params}
//                                     fullWidth
//                                     error={formik.touched.date && Boolean(formik.errors.date)}
//                                     helperText={formik.touched.date && formik.errors.date}
//                                     sx={{
//                                         flex: '3',
//                                         '& .MuiOutlinedInput-root': {
//                                             '& fieldset': {
//                                                 borderColor: '#2356fe',
//                                             },
//                                             '&:hover fieldset': {
//                                                 borderColor: '#2356fe',
//                                             },
//                                             '&.Mui-focused fieldset': {
//                                                 borderColor: '#2356fe',
//                                             },
//                                         },
//                                     }}
//                                 />
//                             )}
//                             disablePast
//                             inputFormat="DD/MM/YYYY"
//                         />
//                     </Box>
//                 </LocalizationProvider>

//                 <Box sx={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
//                     <InputLabel htmlFor="activityPlan" sx={{ flex: "1", color: "#2356fe" }}>Activity Plan</InputLabel>
//                     <TextField
//                         fullWidth
//                         id="activityPlan"
//                         name="activityPlan"
//                         value={formik.values.activityPlan}
//                         onChange={formik.handleChange}
//                         onBlur={formik.handleBlur}
//                         error={formik.touched.activityPlan && Boolean(formik.errors.activityPlan)}
//                         helperText={formik.touched.activityPlan && formik.errors.activityPlan}
//                         sx={{
//                             flex: "3",
//                             borderColor: "#2356fe",
//                             '& .MuiOutlinedInput-root': {
//                                 '& fieldset': {
//                                     borderColor: "#2356fe",
//                                 },
//                                 '&:hover fieldset': {
//                                     borderColor: "#2356fe",
//                                 },
//                                 '&.Mui-focused fieldset': {
//                                     borderColor: "#2356fe",
//                                 },
//                             },
//                         }}
//                     />
//                 </Box>

//                 <Box sx={{ display: "flex", justifyContent: "end", gap: "1rem", marginTop: "2rem" }}>
//                     {dayCount < 8 ? (
//                         <>
//                             <Button
//                                 type="button"
//                                 variant="contained"
//                                 color="primary"
//                                 onClick={handleNext}
//                                 disabled={!formik.values.activityPlan.trim()}
//                                 sx={{ borderRadius: "10px" }}
//                             >
//                                 Next
//                             </Button>
//                             <Button
//                                 type="button"
//                                 variant="contained"
//                                 color="success"
//                                 onClick={handleSave}
//                                 sx={{ borderRadius: "10px" }}
//                             >
//                                 Save
//                             </Button>
//                         </>
//                     ) : (
//                         <Button
//                             type="button"
//                             variant="contained"
//                             color="success"
//                             onClick={handleSave}
//                             sx={{ borderRadius: "10px" }}
//                         >
//                             Save
//                         </Button>
//                     )}
//                 </Box>
//             </form>

//             <Snackbar
//                 open={openSnackbar}
//                 autoHideDuration={6000}
//                 onClose={handleCloseSnackbar}
//             >
//                 <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={snackbarSeverity}>
//                     {snackbarMessage}
//                 </MuiAlert>
//             </Snackbar>
//         </Box>
//     );
// };

// export default HourlySchedule;




import React from "react";
import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DownloadIcon from '@mui/icons-material/Download'; // Import the download icon

function MentorDoc() {
  const initialValues = {
    name: "",
    email: "",
    address: "",
    phoneNumber: "",
    dob: "",
    country: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    address: Yup.string().required("Address is required"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .matches(/^[0-9]+$/, "Phone Number must be digits")
      .min(10, "Phone Number must be at least 10 digits")
      .max(15, "Phone Number can't be longer than 15 digits"),
    dob: Yup.date().required("Date of Birth is required"),
    country: Yup.string().required("Country is required"),
  });

  const handleDownloadPDF = (values) => {
    const input = document.getElementById("form-content");

    // Hide buttons before capturing the screenshot
    document.querySelectorAll('.button-to-hide').forEach(button => {
      button.style.display = 'none';
    });

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const imgWidth = pdfWidth - 20; // Margin of 10mm on each side
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const xOffset = 10; // Left margin of 10mm
      const yOffset = 10; // Top margin of 10mm

      // Set the PDF margins and remove additional borders
      pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);
      pdf.save("form-data.pdf");

      // Show buttons again
      document.querySelectorAll('.button-to-hide').forEach(button => {
        button.style.display = '';
      });
    });
  };

  return (
    <div style={formContainerStyle}>
      <DialogTitle style={dialogTitleStyle}>Mentor Information Form</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleDownloadPDF}
      >
        {({ isValid, touched, errors }) => (
          <Form id="form-content" style={formStyle}>
            <DialogContent style={dialogContentStyle}>
              <Field
                as={TextField}
                autoFocus
                margin="dense"
                label="Name"
                name="name"
                fullWidth
                variant="outlined"
                style={textFieldStyle}
                error={touched.name && !!errors.name}
                helperText={<ErrorMessage name="name" />}
              />
              <Field
                as={TextField}
                margin="dense"
                label="Email"
                name="email"
                type="email"
                fullWidth
                variant="outlined"
                style={textFieldStyle}
                error={touched.email && !!errors.email}
                helperText={<ErrorMessage name="email" />}
              />
              <Field
                as={TextField}
                margin="dense"
                label="Address"
                name="address"
                fullWidth
                variant="outlined"
                style={textFieldStyle}
                error={touched.address && !!errors.address}
                helperText={<ErrorMessage name="address" />}
              />
              <Field
                as={TextField}
                margin="dense"
                label="Phone Number"
                name="phoneNumber"
                type="tel"
                fullWidth
                variant="outlined"
                style={textFieldStyle}
                error={touched.phoneNumber && !!errors.phoneNumber}
                helperText={<ErrorMessage name="phoneNumber" />}
              />
              <Field
                as={TextField}
                margin="dense"
                label="Date of Birth"
                name="dob"
                type="date"
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                style={textFieldStyle}
                error={touched.dob && !!errors.dob}
                helperText={<ErrorMessage name="dob" />}
              />
              <Field
                as={TextField}
                margin="dense"
                label="Country"
                name="country"
                fullWidth
                variant="outlined"
                select
                style={textFieldStyle}
                error={touched.country && !!errors.country}
                helperText={<ErrorMessage name="country" />}
              >
                <MenuItem value="India">India</MenuItem>
                <MenuItem value="United States">United States</MenuItem>
                <MenuItem value="United Kingdom">United Kingdom</MenuItem>
                <MenuItem value="Canada">Canada</MenuItem>
                <MenuItem value="Australia">Australia</MenuItem>
              </Field>
            </DialogContent>
            <DialogActions style={dialogActionsStyle}>
              <Button
                type="submit"
                style={{
                  ...downloadButtonStyle,
                  backgroundColor: isValid ? "#FFA500" : "#9E9E9E",
                }}
                disabled={!isValid}
                startIcon={<DownloadIcon />}
                className="button-to-hide" // Add this class to hide buttons in PDF
              >
                Download
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}

const formContainerStyle = {
  width: "auto",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#f5f5f5",
  padding: "20px",
  boxSizing: "border-box",
};

const dialogTitleStyle = {
  color: "#333",
  fontWeight: "bold",
  fontSize: "24px",
  paddingBottom: "10px",
};

const dialogContentStyle = {
  width: "100%",
  maxWidth: "600px",
  backgroundColor: "#ffffff",
  padding: "20px",
  borderRadius: "8px",
  border: "1px solid #ccc",
  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
};

const textFieldStyle = {
  marginBottom: "15px",
};

const dialogActionsStyle = {
  width: "100%",
  maxWidth: "600px",
  display: "flex",
  justifyContent: "flex-end",
  padding: "10px 20px",
};

const downloadButtonStyle = {
  color: "#fff",
  fontWeight: "bold",
  padding: "10px 20px",
  borderRadius: "5px",
  textTransform: "none",
  marginLeft: "auto",
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
};

export default MentorDoc;
