import { Box, Typography } from "@mui/material";
import React from "react";

const HealthRules = () => {
  return (
    <>
      <Box>
        <Box>
          <Typography
            component={"h1"}
            sx={{
              fontWeight: 900,
              fontSize: "1.3rem",
              padding: "1rem 0rem 0.5rem 0rem",
            }}
          >
            Sign-up Instructions:{" "}
          </Typography>
          <Typography
            component={"ul"}
            sx={{ listStyle: "unset", padding: "0rem 0rem 1rem  2rem" }}
          >
            <Typography component={"li"}>
              Accurate Information: Use accurate and up-to-date information when
              signing up.{" "}
            </Typography>
            <Typography component={"li"}>
              Mobile Number: Ensure you use the registered mobile number
              provided to the school.{" "}
            </Typography>
            <Typography component={"li"}>
              Unique Login: Each parent should create a unique login to access
              their child’s information.
            </Typography>
          </Typography>
        </Box>

        <Box>
          <Typography
            component={"h1"}
            sx={{
              fontWeight: 900,
              fontSize: "1.3rem",
              padding: "0rem 0rem 0.5rem 0rem",
            }}
          >
            Filling the Medical Form:{" "}
          </Typography>
          <Typography
            component={"ul"}
            sx={{ listStyle: "unset", padding: "0rem 0rem 1rem  2rem" }}
          >
            <Typography component={"li"}>
              Accurate Medical History: Provide accurate and comprehensive
              medical history and current health status of your child.{" "}
            </Typography>
            <Typography component={"li"}>
              Attachments: If required, upload necessary medical documents or
              reports in the specified formats (PDF, JPG).{" "}
            </Typography>
            <Typography component={"li"}>
              Review Information: Double-check all entered information for
              accuracy before submitting.
            </Typography>
            <Typography component={"li"}>
              Confidentiality: All medical information will be kept confidential
              and used solely for the purpose of ensuring student health and
              safety.
            </Typography>
          </Typography>
        </Box>

        <Box>
          <Typography
            component={"h1"}
            sx={{
              fontWeight: 900,
              fontSize: "1.3rem",
              padding: "0rem 0rem 0.5rem 0rem",
            }}
          >
            General Guidelines:{" "}
          </Typography>
          <Typography
            component={"ul"}
            sx={{ listStyle: "unset", padding: "0rem 0rem 1rem  2rem" }}
          >
            <Typography component={"li"}>
              Timeliness: Complete and submit the form by the specified deadline
              [Insert Deadline Date].{" "}
            </Typography>
            <Typography component={"li"}>
              Support: For any issues during sign-up or form filling, contact
              our support team at [Insert Contact Information].{" "}
            </Typography>
            <Typography component={"li"}>
              Updates: Keep your contact information updated in case we need to
              reach you for any follow-up.
            </Typography>
            <Typography component={"li"}>
              Compliance: Ensure compliance with all school policies and
              guidelines while using the Student Dashboard.
            </Typography>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default HealthRules;
