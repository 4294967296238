import { useGlobalContext } from "global/context";
import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import Faq from "./Faq";
import OnBoardContext from "../context/onBoardContext";
export const OnBoardingStudentNav = () => {
  const { userData, removeToken, removeUser, } = useGlobalContext();
  const [showGetHelpModal, setGetHelpModal] = useState(false);
  const {updateInstituteOnboardData} = useContext(OnBoardContext)
  // Modal Toggle
  const handleShowModal = () =>{
    setGetHelpModal(true)
  }
  const handleClose = () =>{
    setGetHelpModal(false)
  }


  return (
    <div>
    <div className="d-flex justify-content-between align-items-center">
      <div className="ps-2 d-flex flex-column user-select-none justify-content-start">
      <div>

        <span
          style={{
            fontWeight: "500",
          }}
          classname="me-2"
        >
          Having Trouble?
        </span>
        &nbsp;
        <span
          style={{
            color: "#7341e6",
            fontWeight: "600",
            cursor: "pointer",
          }}
          onClick={handleShowModal}
        >
          Get Help
        </span>
      </div>

      <div>
        <a
          className="d-flex justify-content-start me-4"
          style={{ color: "#3700AC", fontWeight: "300", fontSize: "15px" }}
          href=""
        >
          {userData.email}
        </a>
      </div>
      </div>

      <button className="btn rounded btn-outline-primary p-1 p-sm-0 p-md-2" onClick={()=>{
        removeUser()
        removeToken()
        // removeOnboardingData()
        updateInstituteOnboardData()
      
      }}
                  >Logout</button>
    </div>
     <Modal
     size="lg"
     show={showGetHelpModal}
     onHide={handleClose}
     aria-labelledby="example-modal-sizes-title-lg"
   >
     {/* <Modal.Header closeButton>
       <Modal.Title id="example-modal-sizes-title-lg">
         Help
       </Modal.Title>
     </Modal.Header> */}
     <Modal.Body>
     <div className="mt-3">

<span
  style={{
    fontWeight: "500",
  }}
  classname="me-2"
>
  Having Trouble?
</span>
&nbsp;
<span
  style={{
    // color: "#7341e6",
    fontWeight: "600",
    cursor: "pointer",
  }}
>
  Get Help
</span>
</div>

          <div className="mt-3"><h3 style={{ fontSize: "17px", fontWeight: "600" }}>Contact our dedicated customer support at <a href="mailto:help@yuvamanthan.org">help@yuvamanthan.org</a> or call us on
          9560771911</h3>
          </div>

          <div>
            <div className="mt-4"><span style={{ fontSize: "1.3rem", fontWeight: "600" }}>FAQs</span></div>

            <Faq />
          </div>

     </Modal.Body>
   </Modal>
   </div>
  );
};
