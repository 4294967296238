import { Button } from '@mui/joy';
import { TextField } from '@mui/material';
import { apiJson } from 'api';
import { useGlobalContext } from 'global/context';
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import Select from 'react-select';
import { useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import { Spinner } from "react-bootstrap";

export const StudentEditProfileView = () => {
  const [experience, setExperince] = useState(false)
  const [acchivements, setAcchivements] = useState(false);
  const [showInterest, setShowInterest] = useState(false);
  const [showActivities, setShowActivities] = useState(false);
  const [studentDetaill, setStudentDetal] = useState(null)
  const [current, setCurrent] = useState(false)
  const { userData } = useGlobalContext();
  const [loading, setLoading] = useState(false)

  const handleExperince = () => {
    if (!experience) {
      setExperince(true)
    }
    else {
      setExperince(false)
    }
  }

  const handleAcchivements = () => {
    if (!acchivements) {
      setAcchivements(true)
    }
    else {
      setAcchivements(false)
    }
  }

  const role = userData?.role === 'student' ? 'student' : 'teacher'
  const getProfileDetail = () => {
    const id = userData?.id;
    apiJson.get(`api/v2/institute/getStudentProfileDetail/${role}/${id}`)
      .then((res) => {
        setStudentDetal(res?.data?.getStudentPreFerence)
        // console.log(res.data);
      })
      .catch((error) => {
        console.log(error.message);
      })
  }

  useEffect(() => {
    getProfileDetail()
  }, [])


// all profile form
  const studentProfileFormik = useFormik({
    initialValues: {
      interests: studentDetaill?.interests,
      activities: studentDetaill?.activities,
      experience: studentDetaill?.experience,
      achievements: studentDetaill?.achievements,
    },
    // validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        interests: values?.interests,
        activities: values?.activities,
        experience: values?.experience,
        achievements: values?.achievements,
      }
      setLoading(true);
      const id = userData?.id
      apiJson.put(`api/v2/institute/updateStudentProfileDetail/${role}/${id}`, data)
        .then((res) => {
          setStudentDetal(res?.data?.getStudentPreFerence);
          getProfileDetail();
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.message);
        })
    },
  });


  // Interest
  const handleChange = (selectedOption) => {
    if (studentProfileFormik?.values?.interests?.length >= 5) {
      toast.dismiss()
      toast.warning('You can select up to 5')
    }
    else {
      const newInterstArray = [...studentProfileFormik.values.interests, selectedOption]
      studentProfileFormik.setFieldValue('interests', newInterstArray)
    }
  }
  const removeInterest = (index) => {
    const deleteInterst = [...studentProfileFormik.values.interests];
    deleteInterst.splice(index, 1);
    studentProfileFormik.setFieldValue('interests', deleteInterst);
  }


  // Activity
  const handleChangeActivity = (selectedOption) => {
    if (studentProfileFormik?.values?.activities?.length >= 5) {
      toast.dismiss()
      toast.warning('You can select up to 5')
    }
    else {
      const newActivitiesArray = [...studentProfileFormik.values.activities, selectedOption]
      studentProfileFormik.setFieldValue('activities', newActivitiesArray)
    }
  }
  const removeActivities = (index) => {
    const deleteactivities = [...studentProfileFormik.values.activities];
    deleteactivities.splice(index, 1);
    studentProfileFormik.setFieldValue('activities', deleteactivities);
  }


  // experience
  const validation = Yup.object({
    company: Yup.string().required('Required'),
    designation: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
  })
  const initialValues = {
    company: '',
    designation: '',
    description: '',
    since: '',
    till: '',
    country: '',
    state: '',
    city: '',
  }
  const experinceFormik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      const newExperienceArray = [...studentProfileFormik.values.experience, values]
      studentProfileFormik.setFieldValue('experience', newExperienceArray);
      resetForm();
    }
  })
  useEffect(() => {
    if (current) {

      experinceFormik.setFieldValue('till', 'Present')
    }
    else {
      experinceFormik.setFieldValue('till', '')
    }
  }, [current])

  const removeExperience = (index) => {
    const deleteExperience = [...studentProfileFormik.values.experience];
    deleteExperience.splice(index, 1);
    studentProfileFormik.setFieldValue('experience', deleteExperience);
  }


  // acchievements
  const validationAchivements = Yup.object({
    position: Yup.string().required('Required'),
    competition: Yup.string().required('Required'),
  })
  const achivementsValues = {
    position: '',
    competition: '',
  }
  const achivementsFormik = useFormik({
    initialValues: achivementsValues,
    validationSchema: validationAchivements,
    onSubmit: async (values, { resetForm }) => {
      const newAchievementsArray = [...studentProfileFormik.values.achievements, values]
      studentProfileFormik.setFieldValue('achievements', newAchievementsArray)
      resetForm();
    }
  })
  const removeAchievements = (index) => {
    const deleteAchievements = [...studentProfileFormik.values.achievements];
    deleteAchievements.splice(index, 1);
    studentProfileFormik.setFieldValue('achievements', deleteAchievements);
  }



  return (
    <div>
      <form onSubmit={studentProfileFormik.handleSubmit}>
        <div className='row mt-3'>
          <div className='col-12 col-md-5 col-lg-5'><h3 className='fs-5 fw-500'>Interest</h3></div>
          <div className='col-12 col-md-5 col-lg-6'>
            <Select
              styles={customStyles}
              // defaultValue={interestTopic}
              onChange={handleChange}
              options={interestTopic}
              getOptionLabel={
                (option) => {
                  return (
                    <>
                      <span>{option.name}</span>
                    </>
                  )
                }
              }
            />

            {/* Interest */}
            <div>
              <div
                className="mb-3 yui-card"
                style={{ borderRadius: "16px", fontFamily: "inter" }}
              >
                <div className="d-flex justify-content-between">
                  {/* <h5 className="fs-4 mb-3 fw-bold">Interests</h5> */}
                  {studentProfileFormik?.values?.interests?.length > 2 && (
                    <button type='button'
                      className="fw-bold text-secondary"
                      style={{ background: 'none', border: 'none', cursor: 'pointer', textDecoration: 'none' }}
                      onClick={(e) => setShowInterest(!showInterest)}
                    >
                      {showInterest ? 'See less' : 'See all'}
                    </button>
                  )}
                </div>

                {studentProfileFormik?.values?.interests?.length > 0 &&
                  <div
                    className="mt-3 p-2"
                    style={{ borderRadius: "16px", fontFamily: "inter" }}
                  >
                    <div className="d-flex flex-wrap">
                      {studentProfileFormik?.values?.interests?.slice(0, showInterest ? studentProfileFormik?.values?.length : 2)?.map((ele, i) => {
                        return (
                          <>
                            <span key={i}
                              className="p-1 mx-2 my-2 my-lg-1"
                              style={{
                                color: "#630092",
                                borderRadius: "13.5px",
                                background: "#F2DEFF",
                              }}
                            >
                              {ele?.name} <CloseIcon onClick={() => removeInterest(i)} sx={{ fontSize: 15, cursor: 'pointer' }} />
                            </span>
                          </>
                        );
                      })}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        <div className='row mt-3'>
          <div className='col-12 col-md-5 col-lg-5'><h3 className='fs-5 fw-500'>Activity</h3></div>
          <div className='col-12 col-md-5 col-lg-6'>
            <Select
              styles={customStyles}
              // defaultValue={interestTopic}
              onChange={handleChangeActivity}
              options={activities}
              getOptionLabel={
                (option) => {
                  return (
                    <>
                      <span>{option.name}</span>
                    </>
                  )
                }
              }
            />

            <div>
              <div
                className="mb-3 yui-card"
                style={{ borderRadius: "16px", fontFamily: "inter" }}
              >
                <div className="d-flex justify-content-between">
                  {/* <h5 className="fs-4 mb-3 fw-bold">Interests</h5> */}
                  {studentProfileFormik?.values?.activities?.length > 2 && (
                    <button type='button'
                      className="fw-bold text-secondary"
                      style={{ background: 'none', border: 'none', cursor: 'pointer', textDecoration: 'none' }}
                      onClick={(e) => setShowActivities(!showActivities)}
                    >
                      {showActivities ? 'See less' : 'See all'}
                    </button>
                  )}
                </div>

                {studentProfileFormik?.values?.activities?.length > 0 &&
                  <div
                    className="mt-3 p-2"
                    style={{ borderRadius: "16px", fontFamily: "inter" }}
                  >
                    <div className="d-flex flex-wrap">
                      {studentProfileFormik?.values?.activities?.slice(0, showActivities ? studentProfileFormik?.values?.activities?.length : 2)?.map((ele, i) => {
                        return (
                          <>
                            <span key={i}
                              className="p-1 mx-2 my-2 my-lg-1"
                              style={{
                                color: "#630092",
                                borderRadius: "13.5px",
                                background: "#F2DEFF",
                              }}
                            >
                              {ele?.name} <CloseIcon onClick={() => removeActivities(i)} sx={{ fontSize: 15, cursor: 'pointer' }} />
                            </span>
                          </>
                        );
                      })}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        <div className='row mt-3'>
          <div className='col-12 col-md-5 col-lg-5'><h3 className='fs-5 fw-500'>Experience</h3></div>
          <div className='col-12 col-md-5 col-lg-6'>
            <div className=''>
              <div>
                <Button className='text-center p-2' onClick={handleExperince}>Edit Experience</Button>
              </div>
            </div>

            <div>
              <div
                className="mb-3 yui-card"
                style={{ borderRadius: "16px", fontFamily: "inter" }}
              >
                {/* <div className="d-flex justify-content-between">
                  {studentProfileFormik?.values?.experience?.length > 3 && (
                    <button type='button'
                      className="fw-bold text-secondary"
                      style={{ background: 'none', border: 'none', cursor: 'pointer', textDecoration: 'none' }}
                      onClick={(e) => setShowActivities(!showActivities)}
                    >
                      {showActivities ? 'See less' : 'See all'}
                    </button>
                  )}
                </div> */}

                {studentProfileFormik?.values?.experience?.length > 0 &&
                  <div
                    className="mt-3 p-2"
                    style={{ borderRadius: "16px", fontFamily: "inter" }}
                  >
                    <div className="d-flex flex-wrap">
                      {/* {studentProfileFormik?.values?.experience?.slice(0, showActivities ? studentProfileFormik?.values?.experience?.length : 3)?.map((ele, i) => { */}
                      {studentProfileFormik?.values?.experience.map((ele, i) => {
                        return (
                          <>
                            <div className='' style={{
                                color: "",
                                borderRadius: "13.5px",
                                // background: "#F2DEFF",
                              }}>
                              <span className='fw-semibold p-1 mx-2'><span>{ele?.designation}</span> at <span style={{color: "#da9eff"}}>{ele?.company}</span> <CloseIcon onClick={() => removeExperience(i)} sx={{ fontSize: 15, cursor: 'pointer' }} /></span>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        <div className='row mt-3'>
          <div className='col-12 col-md-5 col-lg-5'><h3 className='fs-5 fw-500'>Achievements</h3></div>
          <div className='col-12 col-md-5 col-lg-6'>
            <div className=''>
              <div>
                <Button className='text-center p-2' onClick={handleAcchivements}>Edit Achievements</Button>
              </div>
            </div>

            <div>
              <div
                className="mb-3 yui-card"
                style={{ borderRadius: "16px", fontFamily: "inter" }}
              >
                {/* {<div className="d-flex justify-content-between">
                  {studentProfileFormik?.values?.achievements?.length > 2 && (
                    <button type='button'
                      className="fw-bold text-secondary"
                      style={{ background: 'none', border: 'none', cursor: 'pointer', textDecoration: 'none' }}
                      onClick={(e) => setShowActivities(!showActivities)}
                    >
                      {showActivities ? 'See less' : 'See all'}
                    </button>
                  )}
                </div>} */}

                {studentProfileFormik?.values?.achievements?.length > 0 &&
                  <div
                    className="mt-3 p-2"
                    style={{ borderRadius: "16px", fontFamily: "inter" }}
                  >
                    <div className="d-flex flex-wrap">
                      {/* {studentProfileFormik?.values?.achievements?.slice(0, showActivities ? studentProfileFormik?.values?.achievements?.length : 2)?.map((ele, i) => { */}
                      {studentProfileFormik?.values?.achievements?.map((ele, i) => {
                        return (
                          <>
                            <div className='' style={{
                                color: "",
                                borderRadius: "13.5px",
                                // background: "#F2DEFF",
                              }}>
                              <span className='fw-semibold p-1 mx-2'><span>{ele?.position}</span> in <span style={{color: "#da9eff"}}>{ele?.competition}</span> <CloseIcon onClick={() => removeAchievements(i)} sx={{ fontSize: 15, cursor: 'pointer' }} /></span>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        <div className='mt-5'>
          <div className=''>
          <Button className='px-4 py-2' disabled={loading} startIcon={loading ? <Spinner size="sm" /> : <CheckCircleOutlineOutlined />} color="success" variant="outlined" size="large" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </form>



      <Modal show={experience} onHide={handleExperince}>
        <ModalBody>
          <div><span className='fs-5 fw-bold'>Edit Experience</span></div>

          <form onSubmit={experinceFormik.handleSubmit}>
            <div className='row mt-3'>
              <div className='col-12 col-lg-6'>
                <label className='fw-500 fs-6 mb-2 d-block text-dark'>Name of the organization</label>
                <div>
                  <input value={experinceFormik.values.company} className="form-control border-0 box-shadow-0 background-grey " placeholder="Write organization name..." onChange={(e) => experinceFormik.setFieldValue('company', e.target.value)} type="text" />
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <label className='fw-500 mb-2 d-block text-dark'>Designation</label>
                <div>
                  <input value={experinceFormik.values.designation} className="form-control border-0 box-shadow-0 background-grey " placeholder="Write designation..." onChange={(e) => experinceFormik.setFieldValue('designation', e.target.value)} type="text" />

                </div>
              </div>

            </div>

            <div className='mb-2 fw-500 mb-2 d-block text-dark'>
              <label>Describe your work here</label>
              <div>
                <input value={experinceFormik.values.description} className={`form-control rounded-3 resize-none  box-shadow-0 background-grey`} placeholder="Write description..." onChange={(e) => experinceFormik.setFieldValue('description', e.target.value)} type="text" style={{ height: '54px' }} />
              </div>
            </div>

            <div className="col-12 col-md-12 mb-2">
              <div className="card col-5 ">
                <div className="card-body px-2 py-1">
                  <div className="d-flex  "><input class="form-check-input me-2" type="checkbox"
                    value={current} onChange={() => setCurrent(!current)}
                    checked={current}
                  >
                  </input>
                    <span className="fw-500 fs-14px mb-2 d-block text-secondary">Currently Working</span></div>
                </div>
              </div>
            </div>


            <div className='row'>

              <div className="col-12 col-md-6 mb-2">
                <span className="fw-500 fs-6 mb-2 d-block text-dark">Since</span>
                <input
                  value={experinceFormik.values.since}
                  className="form-control border-0 box-shadow-0 background-grey "
                  onChange={(e) => experinceFormik.setFieldValue('since', e.target.value)}
                  type="date" />
                {
                  experinceFormik.errors.since && experinceFormik.touched.since && <span className="text-danger">
                    {experinceFormik.errors.since}
                  </span>
                }
              </div>
              <div className="col-12 col-md-6 mb-2">
                <span className="fw-500 fs-6 mb-2 d-block text-dark">Till</span>
                {current || experinceFormik.values.till ?
                  <input value={experinceFormik.values.till} className="form-control border-0 box-shadow-0 background-grey " disabled={current} onChange={(e) => experinceFormik.setFieldValue('till', e.target.value)} type="text" />
                  :
                  <input
                    value={experinceFormik.values.till}
                    className="form-control border-0 box-shadow-0 background-grey "
                    onChange={(e) => experinceFormik.setFieldValue('till', e.target.value)}
                    type="date" />

                }
                {
                  experinceFormik.errors.till && experinceFormik.touched.till && <span className="text-danger">
                    {experinceFormik.errors.till}
                  </span>
                }
              </div>

            </div>

            <div className='row'>
              <div className="col-12 col-md-6 mb-2">
                <span className="fw-500 fs-6 mb-2 d-block text-dark">Country</span>
                <input
                  value={experinceFormik.values.country}
                  className="form-control border-0 box-shadow-0 background-grey "
                  placeholder="Enter Country..."
                  onChange={(e) => experinceFormik.setFieldValue('country', e.target.value)}
                  type="text" />

              </div>
              <div className="col-12 col-md-6 mb-2">
                <span className="fw-500 fs-6 mb-2 d-block text-dark">State</span>
                {/* <input value={formik.values.state} className="form-control border-0 box-shadow-0 background-grey " placeholder="Enter State..." onChange={(e)=>formik.setFieldValue('state', e.target.value)} type="text" /> */}
                <Select
                  // isSearchable={true}
                  // styles={customStyles}
                  options={statesOfIndia}
                  // value={statesOfIndia.find((i) => i.name === formik.values.state)}
                  onChange={(selected) => {

                    experinceFormik.setFieldValue('state', selected.name)
                  }}
                  getOptionLabel={
                    (option) => {
                      return (
                        <>
                          <span>{option.name}</span>
                        </>
                      )
                    }
                  }
                />

              </div>
            </div>

            <div className='row'>
              <div className="col-12 col-md-6 mb-2">
                <span className="fw-500 fs-6 mb-2 d-block text-dark">City</span>
                <input
                  value={experinceFormik.values.city}
                  className="form-control border-0 box-shadow-0 background-grey " placeholder="Enter City..."
                  onChange={(e) => experinceFormik.setFieldValue('city', e.target.value)}
                  type="text" />
                {/* {
              experinceFormik.errors.city && experinceFormik.touched.city &&
               <span className="text-danger">
                {experinceFormik.errors.city}
              </span>
            } */}
              </div>

              <div className="text-end">
                <button type="submit" className="btn-onboard-fill">

                  <span >Edit Experience</span>
                </button>
              </div>
            </div>
          </form>


        </ModalBody>
      </Modal>

      <Modal show={acchivements} onHide={handleAcchivements} size="lg">
        <ModalBody>
          <div><span className='fs-5 fw-bold'>Edit Achievements</span></div>
          <form onSubmit={achivementsFormik.handleSubmit}>
            <div className='row mt-3'>
              <div className='col-12 col-lg-6 mb-2'>
                <div><label className='fw-500 fs-6 mb-2 d-block text-dark'>Position Earned</label></div>
                {/* <input value={studentProfileFormik.values.state} className="form-control border-0 box-shadow-0 background-grey " placeholder="Enter State..." onChange={(e)=>studentProfileFormik.setFieldValue('state', e.target.value)} type="text" /> */}
                <Select
                  styles={customStyles}
                  options={positions}
                  // defaultValue={positions.find((o)=>o.name===achivementsFormik.values.position)}
                  // onChange = {(e)=>{
                  //   achivementsFormik.setFieldValue('position', e.name)
                  // }}
                  onChange={(selected) => {
                    achivementsFormik.setFieldValue('position', selected.name)
                  }}
                  getOptionLabel={
                    (option) => {
                      return (
                        <>
                          <div className="p-2">
                            <span className=" fs-6 ">{option.name}</span>
                          </div>
                        </>
                      )
                    }
                  }
                />
              </div>

              <div className="col-12 col-md-6 mb-2">
                <span className="fw-500 fs-6 mb-2 d-block text-dark">Name of the title or a competition</span>
                <input
                  value={achivementsFormik.values.competition}
                  className="form-control border-0 box-shadow-0 background-grey "
                  placeholder="Write competition..."
                  onChange={(e) => achivementsFormik.setFieldValue('competition', e.target.value)}
                  type="text" />
              </div>

              <div className="text-end">
                <button type="submit" className="btn-onboard-fill">
                  <span >Edit Achievements</span>
                </button>
              </div>

            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  )
}


const customStyles = {
  control: (provided) => ({
    ...provided,
    padding: '5px',
    // border: 'none',
    // boxShadow:'none'
  }),
}

const statesOfIndia = [
  { value: "Andhra Pradesh", name: "Andhra Pradesh" },
  { value: "Arunachal Pradesh", name: "Arunachal Pradesh" },
  { value: "Assam", name: "Assam" },
  { value: "Bihar", name: "Bihar" },
  { value: "Chhattisgarh", name: "Chhattisgarh" },
  { value: "Goa", name: "Goa" },
  { value: "Gujarat", name: "Gujarat" },
  { value: "Haryana", name: "Haryana" },
  { value: "Himachal Pradesh", name: "Himachal Pradesh" },
  { value: "Jharkhand", name: "Jharkhand" },
  { value: "Karnataka", name: "Karnataka" },
  { value: "Kerala", name: "Kerala" },
  { value: "Madhya Pradesh", name: "Madhya Pradesh" },
  { value: "Maharashtra", name: "Maharashtra" },
  { value: "Manipur", name: "Manipur" },
  { value: "Meghalaya", name: "Meghalaya" },
  { value: "Mizoram", name: "Mizoram" },
  { value: "Nagaland", name: "Nagaland" },
  { value: "Odisha", name: "Odisha" },
  { value: "Punjab", name: "Punjab" },
  { value: "Rajasthan", name: "Rajasthan" },
  { value: "Sikkim", name: "Sikkim" },
  { value: "Tamil Nadu", name: "Tamil Nadu" },
  { value: "Telangana", name: "Telangana" },
  { value: "Tripura", name: "Tripura" },
  { value: "Uttar Pradesh", name: "Uttar Pradesh" },
  { value: "Uttarakhand", name: "Uttarakhand" },
  { value: "West Bengal", name: "West Bengal" },
  { value: "Andaman and Nicobar Islands", name: "Andaman and Nicobar Islands" },
  { value: "Chandigarh", name: "Chandigarh" },
  { value: "Dadra and Nagar Haveli and Daman and Diu", name: "Dadra and Nagar Haveli and Daman and Diu" },
  { value: "Delhi", name: "Delhi" },
  { value: "Lakshadweep", name: "Lakshadweep" },
  { value: "Puducherry", name: "Puducherry" }
];

const interestTopic = [
  { name: "Future of Work and Careers", value: "Future of Work and Careers", pick: false },
  { name: "Climate Change and Environmental Sustainability", value: "Climate Change and Environmental Sustainability", pick: false },
  { name: "Mental Health and Well-being", value: "Mental Health and Well-being", pick: false },
  { name: "Artificial Intelligence and Robotics", value: "Artificial Intelligence and Robotics", pick: false },
  { name: "Digital Innovation and Technology Trends", value: "Digital Innovation and Technology Trends", pick: false },
  { name: "Social Media and Online Influence", value: "Social Media and Online Influence", pick: false },
  { name: "Personal Finance and Budgeting", value: "Personal Finance and Budgeting", pick: false },
  { name: "Global Politics and International Relations", value: "Global Politics and International Relations", pick: false },
  { name: "Gender Equality and Women Empowerment", value: "Gender Equality and Women Empowerment", pick: false },
  { name: "Cybersecurity and Online Safety", value: "Cybersecurity and Online Safety", pick: false },
  { name: "Cultural Diversity and Inclusivity", value: "Cultural Diversity and Inclusivity", pick: false },
  { name: "Health and Fitness", value: "Health and Fitness", pick: false },
  { name: "Space Exploration and Astronomy", value: "Space Exploration and Astronomy", pick: false },
  { name: "Sustainable Development Goals (SDGs)", value: "Sustainable Development Goals (SDGs)", pick: false },
  { name: "Education and Learning Strategies", value: "Education and Learning Strategies", pick: false },
  { name: "Entrepreneurship and Startups", value: "Entrepreneurship and Startups", pick: false },
  { name: "Human Rights and Social Justice", value: "Human Rights and Social Justice", pick: false },
  { name: "Innovation in Science and Technology", value: "Innovation in Science and Technology", pick: false },
  { name: "Sports and Athletics", value: "Sports and Athletics", pick: false },
  { name: "Arts and Creativity", value: "Arts and Creativity", pick: false },
  { name: "Career Planning and Skill Development", value: "Career Planning and Skill Development", pick: false },
  { name: "Travel and Adventure", value: "Travel and Adventure", pick: false },
  { name: "Nutrition and Healthy Eating", value: "Nutrition and Healthy Eating", pick: false },
  { name: "Philanthropy and Social Impact", value: "Philanthropy and Social Impact", pick: false },
  { name: "Time Management and Productivity", value: "Time Management and Productivity", pick: false },
  { name: "Language Learning and Linguistics", value: "Language Learning and Linguistics", pick: false },
  { name: "Fashion and Lifestyle", value: "Fashion and Lifestyle", pick: false },
  { name: "Mindfulness and Meditation", value: "Mindfulness and Meditation", pick: false },
  { name: "Conservation and Wildlife Preservation", value: "Conservation and Wildlife Preservation", pick: false },
  { name: "History and Historical Events", value: "History and Historical Events", pick: false },
];


const activities = [
  { name: "Sports (e.g., football, basketball, tennis, swimming)", pick: false, value: "Sports (e.g., football, basketball, tennis, swimming)" },
  { name: "Music (e.g., singing, playing instruments)", pick: false, value: "Music (e.g., singing, playing instruments)" },
  { name: "Dance (e.g., classical, contemporary, hip-hop)", pick: false, value: "Dance (e.g., classical, contemporary, hip-hop)" },
  { name: "Drama and Theater", pick: false, value: "Drama and Theater" },
  { name: "Debate and Public Speaking", pick: false, value: "Debate and Public Speaking" },
  { name: "Robotics and STEM Clubs", pick: false, value: "Robotics and STEM Clubs" },
  { name: "Chess Club", pick: false, value: "Chess Club" },
  { name: "Art and Painting", pick: false, value: "Art and Painting" },
  { name: "Photography", pick: false, value: "Photography" },
  { name: "Writing and Creative Writing", pick: false, value: "Writing and Creative Writing" },
  { name: "Model United Nations (MUN)", pick: false, value: "Model United Nations (MUN)" },
  { name: "Science Clubs and Science Fairs", pick: false, value: "Science Clubs and Science Fairs" },
  { name: "Coding and Computer Programming", pick: false, value: "Coding and Computer Programming" },
  { name: "Environmental Clubs and Eco-friendly Initiatives", pick: false, value: "Environmental Clubs and Eco-friendly Initiatives" },
  { name: "Community Service and Volunteering", pick: false, value: "Community Service and Volunteering" },
  { name: "Film Making and Video Production", pick: false, value: "Film Making and Video Production" },
  { name: "Cooking and Culinary Clubs", pick: false, value: "Cooking and Culinary Clubs" },
  { name: "Foreign Language Clubs", pick: false, value: "Foreign Language Clubs" },
  { name: "Book Clubs and Reading Circles", pick: false, value: "Book Clubs and Reading Circles" },
  { name: "Yoga and Meditation", pick: false, value: "Yoga and Meditation" },
  { name: "Entrepreneurship Clubs and Business Competitions", pick: false, value: "Entrepreneurship Clubs and Business Competitions" },
  { name: "Astronomy and Space Exploration Clubs", pick: false, value: "Astronomy and Space Exploration Clubs" },
  { name: "Cultural and Heritage Clubs", pick: false, value: "Cultural and Heritage Clubs" },
  { name: "Debate and Mock Trial Clubs", pick: false, value: "Debate and Mock Trial Clubs" },
  { name: "Leadership and Student Government", pick: false, value: "Leadership and Student Government" },
  { name: "Journalism and School Newspaper", pick: false, value: "Journalism and School Newspaper" },
  { name: "Quizzing and Trivia Clubs", pick: false, value: "Quizzing and Trivia Clubs" },
  { name: "Public Service and Social Outreach", pick: false, value: "Public Service and Social Outreach" },
  { name: "Gardening and Horticulture Clubs", pick: false, value: "Gardening and Horticulture Clubs" },
  { name: "Model Building and Crafting", pick: false, value: "Model Building and Crafting" },
];

const positions = [
  {
    value: 1,
    name: 'Winner',
  },
  {
    value: 2,
    name: 'First Runner Up',
  },
  {
    value: 3,
    name: 'Second Runner Up',
  },
  {
    value: 4,
    name: 'Third Runner Up',
  },
  {
    value: 5,
    name: 'Fourth Runner Up',
  },
  {
    value: 6,
    name: 'Fifth Runner Up',
  },
  {
    value: 7,
    name: 'Finalist',
  },
  {
    value: 8,
    name: 'Semi-Finalist',
  },
  {
    value: 9,
    name: 'Honorable Mention',
  },
]

const profileUser = [
  { name: 'Future of Work and Careers' },
  { name: 'Climate Change and Environmental Sustainability' },
  { name: 'Mental Health and Well-being' },
  { name: 'Artificial Intelligence and Robotics' },
  { name: 'Digital Innovation and Technology Trends' },
]