import React from "react";
import { Box, Typography } from "@mui/material";
import credit_icon from "../../pages/twentyOneDaysChallenge/assests/Group 381.svg";

const DayCard = ({ item, isActive, handleDays, totalDays }) => {
  return (
    <Box
      sx={{
        border: isActive ? "2px solid #2356fe" : "2px solid transparent",
        boxShadow: "0px 5px 5px 0px #cbc8c8",
        padding: "0.2rem",
        borderRadius: "0.5rem",
        background: "#ffff",
        margin: "1rem 0",
        cursor: isActive ? "pointer" : "not-allowed",
        overflowX: "hidden",
        overflowY: "scroll",
        flex: 1,
        "@media(max-width:900px)": {
          minWidth: "auto",
          overflow: "visible",
        },
      }}
      onClick={isActive ? () => handleDays(item) : null}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 1,
          flexDirection: "row",
          padding: "2px",
          flexWrap: "wrap",
          flex: 1,
          width: "100%",
          "@media(max-width:900px)": {
            flexDirection: "column",
            gap: 2,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "1rem 2rem",
            borderRadius: "0.5rem",
            background: "#2356fe",
            flex: 0.7,
          }}
        >
          <Typography
            component="p"
            sx={{ fontWeight: "bolder", color: "white" }}
          >
            {totalDays === 21 ? "DAY" : "STEP"}
          </Typography>
          <Typography
            component="p"
            sx={{ fontWeight: "bolder", fontSize: "1.5rem", color: "white" }}
          >
            {item.day_count}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              component="p"
              sx={{ fontSize: "0.8rem", fontWeight: 500 }}
            >
              {item.description}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              gap: "10px",
            }}
          >
            <Box sx={{ display: "flex", gap: 0.48, alignItems: "center" }}>
              <Typography
                component="img"
                sx={{ width: "25px" }}
                src={credit_icon}
                alt="credit icon"
              />
              <Typography
                component="span"
                sx={{ fontSize: "0.6rem", color: "gray" }}
              >
                {item.credit}
              </Typography>
              <Typography
                component="span"
                sx={{ fontSize: "0.6rem", color: "gray" }}
              >
                Credits
              </Typography>
            </Box>

            <Box>
              <Typography
                component="p"
                sx={{ fontSize: "0.6rem", color: "gray" }}
              >
                {item.day_count}/{totalDays} DAYS
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DayCard;
