import React, { createContext, useContext, useEffect, useState } from "react";
import ClubTopBanner from "./components/ClubTopBanner";
import ClubAddPost from "./components/ClubAddPost";
import ClubPostCenter from "./components/ClubPostCenter";

import { ClubContext } from "./TimelineClub";
import { UserContext } from "global/context";
import { useParams } from "react-router";
import ShowAllClubs from "./components/ShowAllClubs";
import { toast } from "react-toastify";
import ClubPostSkeleton from "./components/ClubPostSkeleton";
import { apiJson } from "api";
import ClubDetails from "./components/ClubDetails";
import RiasecBanner from "pages/RIASEC/components/RiasecBanner";
import RiasecClubJoin from "pages/RIASEC/components/RiasecClubJoin";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
export const ClubPostContext = createContext();

export const MyProvider = ({ children, post }) => {
  const [postDetail, setPostDetail] = useState({});
  const [postComments, setPostComments] = useState([]);
  const [user, setUser] = useState({});
  const [postUserDetail, setpostUserDetail] = useState({});
  const [postDetailIsLoading, setpostDetailIsLoading] = useState(false);
  const fetchpostDetail = () => {
    // console.log(postDetail, "/", postComments, "/",postDetailIsLoading)
    setpostDetailIsLoading(true);
    apiJson
      .get("club/postDetail/post/" + post.id)
      .then((res) => {
        setPostDetail(res.data);
        setPostComments(res.data.allComments);
        setpostUserDetail(res.data.postUserDetail);
        setpostDetailIsLoading(false);
        setUser(res.data.userDetail);
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Internal Server Error");
        setpostDetailIsLoading(false);
        console.log(error.message);
      });
  };

  const contextValue = {
    postDetailIsLoading,
    postUserDetail,
    user,
    postDetail,
    postComments,
    fetchpostDetail,
  };

  return <ClubPostContext.Provider value={contextValue}>{children}</ClubPostContext.Provider>;
};

const ClubPosts = () => {
  const { id } = useParams();

  const { userData } = useContext(UserContext);
  const instituteId = userData?.role ==="institute" ? userData?.id : userData?.instituteId
  const { clubPost, getClubDetail, fetchClubPosts, clubDetail, isLoadingClubPosts, setisLoadingClubPosts } = useContext(ClubContext);

  const [showNoPostMessage, setShowNoPostMessage] = useState(false);
  const [profileUserCurrent, setProfileUserCurrent] = useState({});
  const [isJoined, setIsjoined] = useState(false)
  const [value, setValue] = React.useState('0');
  const [filteredPost,setFilteredPost] = useState([])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getProfileDataCurrent = () =>{
    apiJson(`api/v2/profile/userDetail/role/${userData.role}/user/${userData.id}`)
    .then((res)=>{
      setProfileUserCurrent(res?.data?.userDetail)
    })
    .catch((error)=>{
      console.log(error.message);
    })
  }
  useEffect(()=>{
    if(clubPost){
      // Filter clubPost based on active tab
      if(value === '0') {
        setFilteredPost(clubPost.filter((i) => i.instituteId === instituteId));
      } else {
        setFilteredPost(clubPost);
      }
    }
  },[clubPost, instituteId, value]);
  const checkIfJoined = ()=>{
    apiJson(`api/v2/clubs/checkIfUserJoined/${id}/user/${userData?.id}/role/${userData?.role}/checkUser`)
    .then((res)=>{
      toast.dismiss()
      setIsjoined(res?.data?.result)
    }).catch((err)=>{
      toast.dismiss()
      toast.error('Internal server serror')
    })
  }
  const joinClub = ()=>{
    apiJson.post(`api/v2/clubs/joinClub/${id}/user/${userData?.id}/role/${userData?.role}/instituteId/${userData?.role==='institute'? userData?.id:userData?.instituteId}/joinClub`)
    .then((res)=>{
      toast.dismiss()

      checkIfJoined()
    }).catch((err)=>{
      toast.dismiss()
      toast.error('internal server error')
    })
  }
  const leftClub = ()=>{
    apiJson.get(`api/v2/clubs/leftClub/${id}/user/${userData?.id}/role/${userData?.role}/leftClub`)
    .then((res)=>{
      toast.dismiss()
      checkIfJoined()
    }).catch((err)=>{
      toast.dismiss()
      toast.error('internal server error')
    })
  }

  useEffect(()=>{
    checkIfJoined()
  },[])
  useEffect(() => {
    if (!isLoadingClubPosts) {
      const timeout = setTimeout(() => {
        setShowNoPostMessage(true);
      }, 400);

      return () => clearTimeout(timeout);
    }
  }, [isLoadingClubPosts]);

  useEffect(() => {
    getClubDetail(id);
  }, [id]);

  // const [clubPost, setClubPost]= useState([])

  useEffect(() => {
    setisLoadingClubPosts(true);
    fetchClubPosts(id);
    getProfileDataCurrent()
  }, []);

  useEffect(() => {
    fetchClubPosts(id);
  }, [id]);

  return (
    <>
      {/* <div className="mb-4">
        <ClubTopBanner clubDetail={clubDetail} />
      </div> */}

      {/* <RiasecClubJoin/> */}
        <div className="mb-5">
          <ClubDetails clubDetail={clubDetail} isJoined={isJoined} joinClub={joinClub}/>
        </div>
     <div className="mb-4">
        <ClubAddPost clubDetail={clubDetail} userDetail={profileUserCurrent} isJoined={isJoined}/>
      </div>
     
   <div className="mb-4 mt-4">
        <RiasecBanner/>
      </div>
      <div className="mt-5 mb-5" >
          <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value="0" label="Posts"  className="fw-semibold"/>
        <Tab value="1" label="Community"  className="fw-semibold"/>

      </Tabs>
     </div>
     <div className="d-flex justify-content-center">
      <div className="mb-4 col-12 col-md-9">
        {isLoadingClubPosts ? (
          <ClubPostSkeleton />
        ) : filteredPost?.length > 0 ? (
          <>
            {filteredPost?.map((post, index) => {
              return (
                <MyProvider key={post.id} clubId={id} post={post}>
                  <ClubPostCenter clubDetail={clubDetail} key={post.id} post={post} index={index} />
                </MyProvider>
              );
            })}
          </>
        ) : (
          showNoPostMessage && filteredPost?.length === 0 && 
          <div className='d-flex justify-content-center'>
        <div style={{height: "50%", width: "70%"}}>
        <img className='h-100 w-100' src={"/ProfileImg/NoPost.svg"} alt='Not Post Yet'/>
        </div>
        </div>
        )}
      </div>
      </div>
    </>
  );
};

export default ClubPosts;
