import React, { useState } from 'react';
import './CarbonFootPrintCalculator.css';
import CarbonFootPrintOption from "./CarbonFootPrintOption.jsx";
import { FaNutritionix, FaTemperatureLow, FaTruckDroplet } from 'react-icons/fa6';
import { PiCookingPotFill } from "react-icons/pi";
import { SlEnergy } from "react-icons/sl";

const options = [
    { name: 'Transportation', icon: <FaTruckDroplet /> },
    { name: 'Cooking', icon: <PiCookingPotFill /> },
    { name: 'Home Heating & Cooling', icon: <FaTemperatureLow /> },
    { name: 'Energy Consumption', icon: <SlEnergy /> },
    { name: 'Diet', icon: <FaNutritionix /> }
];

const CarbonFootPrintCalculator = () => {
    const [selected, setSelected] = useState(null);

    const handleButtonClick = (title) => {
        setSelected(title);
    };

    const handleBack = () => {
        setSelected(null);
    };

    return (
        <div className="carbon-container">
            {selected ? (
                <CarbonFootPrintOption title={selected} onBack={handleBack} />
            ) : (
                <div className="carbon-options-wrapper">
                    {options.map((option) => (
                        <button
                            key={option.name}
                            className="carbon-option"
                            onClick={() => handleButtonClick(option.name)}
                        >
                            <span style={{ fontSize: "2rem" }}>{option.icon}</span>
                            <span>{option.name}</span>
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CarbonFootPrintCalculator;