import React, { useContext, useEffect, useState } from 'react'
import CarbonQuestion from './CarbonQuestion'
import CarbonCategorySelector from './CarbonCategorySelector'
import { CFContext } from '../context/carbonContext'
import { useNavigate } from 'react-router-dom'
import { apiJson } from 'api'
import { toast } from 'react-toastify'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import PropTypes from "prop-types";

const CarbonLatestApp = () => {
  const navigate = useNavigate()

  const [currentCategory, setCurrentCategory] = useState([]);
  const [questions, setQuestions] = useState(tempQuestion)
  const [categories, setCategories] = useState(tempcategory)
  const [categoryQuestions, setCategoryQuestion] = useState([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answerSheet, setAnswerSheet] = useState([])
  const [totalMarks, setTotalMarks] = useState()
  const [progressCounter, setProgressCounter] = useState(0)

  const getQuestions = () => {
    apiJson('api/v2/cfc/cfcGetQuestion')
      .then((res) => {
        setQuestions(res.data.questions)
      }).catch((error) => {
        toast.dismiss()
        toast.error('Internal Server Error')
      })
  }

  const getCategory = () => {
    apiJson('api/v2/cfc/cfcCategory')
      .then((res) => {
        setCategories(res.data.categories)
      }).catch((error) => {
        toast.dismiss()
        toast.error('Internal Server Error')
      })
  }
  // useEffect(()=>{
  //     getQuestions()
  //     getCategory()
  // },[])

  useEffect(() => {
    if (questions?.length > 0 && currentCategory) {
      // console.log('inside the question change')
      setCategoryQuestion(questions?.filter(q => q.cat_id === currentCategory.id))
      // console.log(questions?.filter(q => q.cat_id === currentCategory.id))
    }
  }, [questions, currentCategory])

  useEffect(() => {
    if (categories?.length > 0) {
      setCurrentCategory(categories[0])
    }
  }, [categories])

  const handleNext = () => {
    const nextCategoryIndex = (categories?.findIndex(c => c.id === currentCategory?.id) + 1);

    if (currentQuestionIndex < categoryQuestions.length - 1) {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
      setProgressCounter(prev => prev + 1)
    } else {
      if (nextCategoryIndex >= categories.length) { // Check if it's the last category
        console.log('Reached the last question of the last category');
        console.log('here is your score', calculatePercentage(answerSheet, totalMarks))
        navigate(`result/${calculatePercentage(answerSheet, totalMarks)}`)
        return;
      } else {
        const nextCategory = categories[nextCategoryIndex];
        setCurrentCategory(nextCategory);
        setCurrentQuestionIndex(0); // Go to the first question of the next category
        setProgressCounter(prev => prev + 1)
      }
    }

  };


  const handlePrev = () => {
    const prevCategoryIndex = categories?.findIndex(c => c.id === currentCategory?.id) - 1;
    if (currentQuestionIndex <= 0 && prevCategoryIndex < 0) {
      setProgressCounter(0)
      console.log('Already at the first question of the first category');
      return;
    } else {
      if (prevCategoryIndex >= 0) {
        const prevCategory = categories[prevCategoryIndex];
        const prevCategoryQuestions = questions.filter(q => q.cat_id === prevCategory.id);
        setCurrentCategory(prevCategory);
        setCurrentQuestionIndex(prevCategoryQuestions.length - 1);
        setProgressCounter(prev => prev - 1)
      } else {
        // Go to the last question of the current category
        setProgressCounter(prev => prev - 1)
        setCurrentQuestionIndex(currentQuestionIndex - 1);
      }
    }

  };

  function getTotalHighestMarks(questions) {
    let totalMarks = 0;

    questions.forEach(question => {
      let highestScore = 0;
      question.option.forEach(option => {
        if (option.score > highestScore) {
          highestScore = option.score;
        }
      });
      totalMarks += highestScore;
    });

    return totalMarks;
  }

  function calculatePercentage(answers, totalHighestMarks) {
    let totalScore = 0;
    // Calculate total score obtained
    answers.forEach(answer => {
      totalScore += answer.score;
    });
    // Calculate percentage
    const percentage = (totalScore / totalHighestMarks) * 100;
    return percentage.toFixed(2); // Round to 2 decimal places
  }

  function LinearProgressWithLabel({ currentQuestionIndex, totalQuestions }) {
    const progress = ((currentQuestionIndex) / totalQuestions) * 100; // Calculate progress percentage

    return (
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <div className=''>
          <h1 className='fs-3 fw-semibold text-success'>{`${Math.round(progress)}%`}</h1>
        </div>
        <Box sx={{ width: "79%", mr: 1 }}>
          <LinearProgress sx={{ height: '7px', color: 'green' }} color='success' variant="determinate" value={progress} />
        </Box>

      </div>
    );
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * Index of the current question
     */
    currentQuestionIndex: PropTypes.number.isRequired,

    /**
     * Total number of questions
     */
    totalQuestions: PropTypes.number.isRequired,
  };

  useEffect(() => {
    if (questions?.length > 0) {
      console.log(getTotalHighestMarks(questions))
      setTotalMarks(getTotalHighestMarks(questions))
    }
  }, [questions])

  const handleSetAnswer = (data) => {
    setAnswerSheet(data)


  }




  return (
    <div className='container'>
      <div className='mt-4 mb-4 text-center d-flex justify-content-between'>
        <h1 className='fs-3 text-success fw-semibold text-center m-auto'>Yuvamanthan - UNDP Sustainability Action Calculator</h1>
        {/* <Button varient="contained" onClick={()=> navigate("/new-dashboard/carbon/carbon-leaderboard")} className="bg-success text-white">Leaderboard</Button> */}
      </div>

      <div className=" d-flex flex-column  mt-5" style={{ height: '65vh' }}>

        <Box sx={{ width: "100%" }}>
          <LinearProgressWithLabel
            currentQuestionIndex={progressCounter}
            totalQuestions={questions?.length}
          />
        </Box>
        <div className='mt-4' >
          <CarbonCategorySelector
            categories={categories}
            selectedCategory={currentCategory}
            onSelectCategory={setCurrentCategory}
          />
          <div className='row align-items-center'>
            <div className='col-2 d-none d-md-block'>
              <NavigateBeforeIcon className=' cursor-pointer fw-normal ' sx={{ fontSize: '120px', color: '' }} color='success' onClick={() => { handlePrev() }} />

            </div>
            <div className='col-12 col-md-8'>
              <CarbonQuestion handleSetAnswer={handleSetAnswer} handleNext={handleNext} question={categoryQuestions[currentQuestionIndex]} />

            </div>
            <div className='col-2 d-none d-md-block'>
              <ChevronRightIcon className=' cursor-pointer ' sx={{ fontSize: '120px', color: '' }} color='success' onClick={() => { handleNext() }} />

            </div>
            <div className='d-block d-md-none mt-4 col-12'>
              <div className='d-flex w-100 justify-content-around'>
                <Button className="" onClick={handlePrev} color="error" variant="contained">Prev</Button>
                <Button className="" onClick={handleNext} color="success" variant="contained">Next</Button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );

}

const tempcategory = [
  {

    id: 12,
    title: "",
    description: "",
    order: 1,
    createdAt: "2023-12-07T07:07:09.000Z",
    updatedAt: "2023-12-22T06:27:58.000Z",
  },
  // {

  //   id: 14,
  //   title: "Transport",
  //   description: "cook",
  //   order: 2,
  //   createdAt: "2023-12-07T07:07:09.000Z",
  //   updatedAt: "2023-12-22T06:27:58.000Z",
  // },
  // {

  //   id: 15,
  //   title: "Energy",
  //   description: "cook",
  //   order: 3,
  //   createdAt: "2023-12-07T07:07:09.000Z",
  //   updatedAt: "2023-12-22T06:27:58.000Z",
  // },
]


const tempQuestion = [
  {
    id: 18,
    cat_id: 12,
    text: "Use bicycles for local or short commute?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'Hardly',
        score: 1
      },
      {
        id: 2,
        opt: 'Sometimes',
        score: 2
      },
      {
        id: 3,
        opt: 'Frequently',
        score: 3
      }
    ]
  },
  {
    id: 19,
    cat_id: 12,
    text: "Switch off appliances from plug points when not in use?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'No',
        score: 1
      },
      {
        id: 2,
        opt: 'Yes',
        score: 2
      }
    ]
  },
  {
    id: 20,
    cat_id: 12,
    text: "Take the stairs instead of an elevator wherever possible?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'Hardly',
        score: 1
      },
      {
        id: 2,
        opt: 'Sometimes',
        score: 2
      },
      {
        id: 3,
        opt: 'Frequently',
        score: 3
      }
    ]
  },
  {
    id: 21,
    cat_id: 12,
    text: "Opt for buckets instead of showers for bathing purposes?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'Hardly',
        score: 1
      },
      {
        id: 2,
        opt: 'Sometimes',
        score: 2
      },
      {
        id: 3,
        opt: 'Frequently',
        score: 3
      }
    ]
  },
  {
    id: 22,
    cat_id: 12,
    text: "Reuse water from water purifier / AC to water plants or clean surfaces?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'Hardly',
        score: 1
      },
      {
        id: 2,
        opt: 'Sometimes',
        score: 2
      },
      {
        id: 3,
        opt: 'Frequently',
        score: 3
      }
    ]
  },
  {
    id: 23,
    cat_id: 12,
    text: "Turn off running taps when not in active use?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'No',
        score: 1
      },
      {
        id: 2,
        opt: 'Yes',
        score: 2
      }
    ]
  },
  {
    id: 24,
    cat_id: 12,
    text: "Carry your own non-plastic water bottle wherever possible?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'Hardly',
        score: 1
      },
      {
        id: 2,
        opt: 'Sometimes',
        score: 2
      },
      {
        id: 3,
        opt: 'Frequently',
        score: 3
      }
    ]
  },
  {
    id: 25,
    cat_id: 12,
    text: "Use cloth bag instead of plastic bags?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'Hardly',
        score: 1
      },
      {
        id: 2,
        opt: 'Sometimes',
        score: 2
      },
      {
        id: 3,
        opt: 'Frequently',
        score: 3
      }
    ]
  },
  {
    id: 26,
    cat_id: 12,
    text: "Opt for reusable steel lunchboxes?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'Hardly',
        score: 1
      },
      {
        id: 2,
        opt: 'Sometimes',
        score: 2
      },
      {
        id: 3,
        opt: 'Frequently',
        score: 3
      }
    ]
  },
  {
    id: 27,
    cat_id: 12,
    text: "Repair and use electronic devices over discarding the devices?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'Hardly',
        score: 1
      },
      {
        id: 2,
        opt: 'Sometimes',
        score: 2
      },
      {
        id: 3,
        opt: 'Frequently',
        score: 3
      }
    ]
  },
  {
    id: 28,
    cat_id: 12,
    text: "Responsibly dispose of e-waste at appropriate collection centres?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'No',
        score: 1
      },
      {
        id: 2,
        opt: 'Yes',
        score: 2
      }
    ]
  },
  {
    id: 29,
    cat_id: 12,
    text: "Segregate e-waste from regular waste at home / school?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'No',
        score: 1
      },
      {
        id: 2,
        opt: 'Yes',
        score: 2
      }
    ]
  },
  {
    id: 30,
    cat_id: 12,
    text: "Refrain from carrying plastic / disposable cutlery to school?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'Hardly',
        score: 1
      },
      {
        id: 2,
        opt: 'Sometimes',
        score: 2
      },
      {
        id: 3,
        opt: 'Frequently',
        score: 3
      }
    ]
  },
  {
    id: 31,
    cat_id: 12,
    text: "Compost food waste at home / school?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'Hardly',
        score: 1
      },
      {
        id: 2,
        opt: 'Sometimes',
        score: 2
      },
      {
        id: 3,
        opt: 'Frequently',
        score: 3
      }
    ]
  },
  {
    id: 32,
    cat_id: 12,
    text: "Helped to create a kitchen garden / terrace gardens at home / school?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'No',
        score: 1
      },
      {
        id: 2,
        opt: 'Yes',
        score: 2
      }
    ]
  },
  {
    id: 33,
    cat_id: 12,
    text: "Refrain from littering on campus?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'Hardly',
        score: 1
      },
      {
        id: 2,
        opt: 'Sometimes',
        score: 2
      },
      {
        id: 3,
        opt: 'Frequently',
        score: 3
      }
    ]
  },
  {
    id: 34,
    cat_id: 12,
    text: "Recycle and reuse paper from old notebooks and newspapers?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'Hardly',
        score: 1
      },
      {
        id: 2,
        opt: 'Sometimes',
        score: 2
      },
      {
        id: 3,
        opt: 'Frequently',
        score: 3
      }
    ]
  },
  {
    id: 35,
    cat_id: 12,
    text: "Set the AC temperature to 24 degrees in classrooms / laboratories or at home?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'Hardly',
        score: 1
      },
      {
        id: 2,
        opt: 'Sometimes',
        score: 2
      },
      {
        id: 3,
        opt: 'Frequently',
        score: 3
      }
    ]
  },
  {
    id: 36,
    cat_id: 12,
    text: "Donate old clothes and books?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'No',
        score: 1
      },
      {
        id: 2,
        opt: 'Yes',
        score: 2
      }
    ]
  },
  {
    id: 37,
    cat_id: 12,
    text: "Set printer default to double-side printing?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'No',
        score: 1
      },
      {
        id: 2,
        opt: 'Yes',
        score: 2
      }
    ]
  },
  {
    id: 38,
    cat_id: 12,
    text: "Practice segregation of dry and wet waste?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'No',
        score: 1
      },
      {
        id: 2,
        opt: 'Yes',
        score: 2
      }
    ]
  },
  {
    id: 39,
    cat_id: 12,
    text: "Initiate and/or join green clubs in your school / residential area?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'No',
        score: 1
      },
      {
        id: 2,
        opt: 'Yes',
        score: 2
      }
    ]
  },
  {
    id: 40,
    cat_id: 12,
    text: "Plant trees to reduce the impact of pollution?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'Hardly',
        score: 1
      },
      {
        id: 2,
        opt: 'Sometimes',
        score: 2
      },
      {
        id: 3,
        opt: 'Frequently',
        score: 3
      }
    ]
  },
  {
    id: 41,
    cat_id: 12,
    text: "Refill ink in pens and reuse old stationary?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'Hardly',
        score: 1
      },
      {
        id: 2,
        opt: 'Sometimes',
        score: 2
      },
      {
        id: 3,
        opt: 'Frequently',
        score: 3
      }
    ]
  },
  {
    id: 42,
    cat_id: 12,
    text: "Use public transport and carpooling wherever possible?",
    type: "radio",
    points: 0,
    option: [
      {
        id: 1,
        opt: 'Hardly',
        score: 1
      },
      {
        id: 2,
        opt: 'Sometimes',
        score: 2
      },
      {
        id: 3,
        opt: 'Frequently',
        score: 3
      }
    ]
  }
];


const answerSheet = [

]

export default CarbonLatestApp