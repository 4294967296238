import React, { useEffect, useState } from "react";
import "../Model-UN/style/MunApplicants.css";
import { apiJson } from "api";
import { useGlobalContext } from "global/context";
import { TailSpin } from "react-loader-spinner";
import { Button, Chip, Divider } from "@mui/joy";

export const UsersUnderReview = () => {
  const { userData } = useGlobalContext()
  const approveAccess = ['Admin', 'Moderator', 'Manager']
  const [fetchAllData, setFetchAllData] = useState([]);
  const [loader, setLoader] = useState(false)
let instituteId ;
if(userData?.role==="institute"){
  instituteId= userData?.id
}else{
  instituteId= userData?.instituteId
}
  async function fetchData() {
    setLoader(true)
    try {
      const response = await apiJson(`/api/v2/institute/getAllUsers/institute/${instituteId}`)
      setFetchAllData([
        ...response.data.allStudent,
        ...response.data.allTeacher,
      ]);
      setLoader(false)
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false)

    }
  }
  useEffect(() => {
    fetchData();
  }, []);


  const checkRoles = ()=>{

    if(userData?.role==='institute'){
      return true
    }
    else{
      if(userData?.subRoles?.some(i=>approveAccess?.includes(i))){
        return true
      }
      return false
    }
  }

  const [data, setData] = useState([]);
  const [filterValue, setFilterValue] = useState("All");

  useEffect(() => {
    setData(fetchAllData)
  }, [fetchAllData])

  useEffect(() => {
    setFilterValue("All")
  }, [])

  let filterList = filterValue === "All" ? data : data?.filter((val) => val.status === filterValue)

  const updateStatus = async (studentId, studentRole) => {
    // console.log("student id is = "+ studentId);
    if(checkRoles){
      const updateApi = `/api/v2/institute/approveUser/institute/${instituteId}/student/${studentId}/role/${studentRole}`;
      try {
        const response = await apiJson.put(updateApi)
        // console.log(response.data)
        setFetchAllData([
          ...response.data.allStudent,
          ...response.data.allTeacher,
        ]);
  
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

  }

  //  Reject
  const RejectStatus = async (studentId, studentRole) => {
    // console.log("student id is = "+ studentId);
    
    if(checkRoles()){
      const updateApi = `/api/v2/institute/rejectUserNew/institute/${instituteId}/student/${studentId}/role/${studentRole}`;
    try {
      const response = await apiJson.put(updateApi)
      // console.log(response.data)
      setFetchAllData([
        ...response.data.allStudent,
        ...response.data.allTeacher,
      ]);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
    }

  }


  return (
    <div>
      <div className="col-10  mx-auto mt-4">
        <div className="mb-5">
          <div>
            <div>
              <span className="approve-request">Approval Requests</span>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between mt-3">
          <div className="d-flex mb-3">
            <div>
              <span
                onClick={() => {

                  setFilterValue("All")
                }}
                className={`fw-400 cp cursor-pointer 
                ${filterValue === "All" ? "active-status" : null}
                 `}
                style={{ fontFamily: "Lexend" }}
              >
                All
              </span>
            </div>
            <div>
              <span
                onClick={() => {
                  setFilterValue(0)
                }}
                className={`fw-400 cp cursor-pointer ${filterValue === 0 ? "active-status" : null}`}
                style={{ fontFamily: "Lexend" }}
              >
                Pending
              </span>
            </div>
            <div>
              <span
                onClick={() => setFilterValue(1)}
                className={`fw-400 cp cursor-pointer ${filterValue === 1 ? "active-status" : null}`}
                style={{
                  fontFamily: "Lexend",
                  borderRadius: "5px",
                  height: "22px",
                  width: "83px",
                }}
              >
                Approved
              </span>
            </div>
           
          </div>
        </div>

      
        <Divider />
        {loader ? (<div className="d-flex justify-content-center align-items-center"><TailSpin /> </div>) : (filterList?.length > 0 ? filterList?.map((ele) => {
          return (
            <>
              <div>
                <div className="row py-3" style={ele?.id==userData?.id?{opacity:'0.5'}:null}>
                  <div className="col-md-6 col-lg-6">
                    <div className="d-flex justify-content-between align-items-center col-6 w-100">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <div style={{ height: "49px", width: "49px" }}>
                            <img
                              src={ele?.profile}
                              className="h-100 w-100"
                              style={{ borderRadius: "50%" }}
                              alt=""
                            />
                          </div>
                        </div>

                        <div className="ms-3">
                          <div className="d-flex">
                            <div className="">
                              <span
                                className="fs-17-50px fw-400 text-md-start text-capitalize"
                                style={{ fontFamily: "Lexend" }}
                              >
                                {ele?.first_name + " " + ele?.last_name}
                              </span>
                            </div>
                          </div>
                        </div>

                        <Chip
                          variant="soft"
                          color={
                            ele?.role === "student" ? "primary" : "warning"
                          }
                          className="text-capitalize mx-3"
                        >
                          {ele.role}
                        </Chip>
                      </div>

                      <div className="d-flex justify-content-center align-items-center">
                      
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6 d-flex align-items-center justify-content-end">
                    <div className="d-flex align-items-center approve-reject-box">
                      <div className="d-flex justify-content-lg-between flex-column flex-sm-row col-9">
                    {  ele?.id ===userData?.id ?null:  <div className="approve-inner-css d-flex justify-content-sm-between  gap-3">
                          <div>
                           
                            <Button
                              variant="soft"
                              color="success"
                              disabled={ele?.status}
                              onClick={() => updateStatus(ele?.id, ele?.role)}
                            >
                              {!ele?.status ? "Approve" : "Approved"}
                            </Button>
                          </div>
                          <div>
                          
                            <Button
                              variant="soft"
                              color="danger"
                              disabled={ele?.status === 0}
                              onClick={() => RejectStatus(ele?.id, ele?.role)}
                            >
                             {ele?.status ? "Reject" : "Rejected"}
                            </Button>
                          </div>
                        </div>}
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-8">
                    <hr />
                  </div> */}
                </div>
              </div>
              <Divider />
            </>
          );
        }) : <img src="/nodata.jpg" alt="" width="70%" className="d-block m-auto" />)}
      </div>
    </div>
  );
};

