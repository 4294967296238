import React, { useEffect, useState } from "react";
import styles from "./Nipam.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import KeyboardDoubleArrowRightTwoToneIcon from "@mui/icons-material/KeyboardDoubleArrowRightTwoTone";
import NipamTxtSlider from "./NipamTxtSlider";
import BenefitCard from "./BenefitCard";
import CourseDetailsTab from "./CourseDetailsTab";
import Slider from "./Slider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Input } from "@mui/joy";
import { CheckCircle, ErrorOutlineRounded } from "@mui/icons-material";
import StyledAlert from "components/elements/StyledAlert";
import { apiJson } from "api";
import { toast } from "react-toastify";
import { useGlobalContext } from "global/context";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const NewNip = () => {
  const { userData } = useGlobalContext();
  const { setToken, setUser } = useGlobalContext();
  const navigate = useNavigate();
  const isNipam = "true";
  const [showEyeIconEnterPass, setShowEyeIconEnterPass] = useState(false);
  const [showEyeIconNew, setShowEyeIconNew] = useState(false);
  const [showEyeIconConfirm, setShowEyeIconConfirm] = useState(false);
  const [emailExistLogin, setemailLoginExist] = useState(true);
  const [showCredential, setShowCredential] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailExist, setemailExist] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [sentVerificationEmail, setsentVerificationEmail] = useState(false);
  const [currentToken, setCurrentToken] = useState("");
  const [newStudentLoader, setNewStudentLoader] = useState(false);
  const [step, setStep] = useState(1);
  const [isHovered, setIsHovered] = useState(false);
  const stepCount = new URLSearchParams(window.location.search).get("count");
  const types = new URLSearchParams(window.location.search).get("type");
  const localUser = JSON.parse(localStorage.getItem('user'));

  const politicians = [
    {
      id: 1,
      name: "~Shri Narendra Modi",
      profile: "/assets/nipam/Pm Modi.png",
      circle: "background-circle",
      position: "Hon’ble Prime Minister of India",
      Desc: "The rise in patent applications in India demonstrates the rising innovative zeal of our youth and is a very positive sign for the times to come.",
    },
    {
      id: 2,
      name: "~Shri Piyush Goyal",
      circle: "background-circle1",
      profile: "/assets/nipam/piyush.png",
      position: "Hon’ble Minister of Commerce & Industry",
      Desc: "Technology and ideas serve as the twin engines of growth, with the intellectual property regime acting as the fuel that powers this engine.",
    },
    {
      id: 3,
      name: "~Shri Unnat P.Pandit",
      circle: "background-circle2",
      profile: "/assets/nipam/Unnat.png",
      position: "Prof. (Dr.) Unnat P. Pandit, CGPDTM",
      Desc: "Intellectual property is the fuel that drives innovation & creativity... protect it fiercely.",
    },
  ];
  useEffect(() => {
    if (stepCount && types) {
      if (types === "Institution") {
        setFormikData("type", types);
        setStep(Number(stepCount));

        return;
      }
      setFormikData("type", types);
      setStep(Number(stepCount));
    }
  }, []);
  const userDetail = useFormik({
    initialValues: {
      email: "",
      type: "Institution",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email address"),
    }),
  });
  useEffect(() => {
    if (isVerified) {
      setStep(4);
    }
  }, [isVerified]);
  useEffect(() => {
    setemailLoginExist(true);
    setemailExist(false);
  }, [step]);
  //========= Handle Email exist function  start=================\\
  const checkIfEmailExistLogIn = (email) => {
    if (email) {
      setEmailLoading(true);
      apiJson
        .post("v2/register/checkEmailExist", { email: email })
        .then((res) => {
          setemailLoginExist(res.data.result);
          setEmailLoading(false);

          if (res.data.result) {
            setStep(5);
          }
        })
        .catch((error) => {
          setEmailLoading(false);
          toast.dismiss();
          toast.error("Server Busy. Please try again later");
        });
    } else {
      toast.dismiss();
      toast.error("Email is Required");
    }
  };
  const checkIfEmailExist = (email) => {
    // console.log('=>>>', email)
    if (email) {
      setEmailLoading(true);
      apiJson
        .post("v2/register/checkEmailExist", { email: email, isNipam })
        .then((res) => {
          setEmailLoading(false);
          setemailExist(res.data.result);
          setemailLoginExist(res.data.result);
          // setFormikData('email', "")
          // console.log(res.data.result)
          toast.dismiss();

          if (res.data.result) {
            toast.dismiss();
            toast.error("Email Already Exist");
            // console.log('Email Already Exist')
          } else {
            setStep(3);
            sendVerifyEmail(email);
            toast.dismiss();
          }
        })
        .catch((error) => {
          setEmailLoading(false);
          toast.dismiss();
          toast.error("Server Busy. Please try again later");
        });
    } else {
      toast.dismiss();
      toast.error("Email is Required");
    }
  };

  //========= Handle Email exist function  End=================\\
  const checkEmailVerification = (email) => {
    // console.log("===>>>>", email)
    if (userDetail.values.type === "Institution") {
      apiJson
        .post("v2/register/is-verified", { email })
        .then((res) => {
          setEmailLoading(false);
          setIsVerified(res.data.result);
          if (res.data.result) {
            clearInterval(checkInterval);
          }

          // console.log(res.data.result)
        })
        .catch((error) => {
          setEmailLoading(false);
          console.log(error.message);
        });
    }
  };
  //============ Verification mail function =================
  let checkInterval;

  const sendVerifyEmail = (email) => {
    setEmailLoading(true);

    // if (checkInterval) {
    //   clearInterval(checkInterval);
    // }

    // Set up the interval
    // checkInterval = setInterval(() => {
    //   const tempVerified = isVerified;
    //   if (tempVerified) {
    //     clearInterval(checkInterval);
    //     setsentVerificationEmail(false);
    //   } else {
    //     checkEmailVerification(userDetail.values.email);
    //   }
    // }, 5000);

    // for Institutes

    if (userDetail.values.type === "Institution") {
      apiJson
        .post("/v2/register/institution/verify-email", { email, isNipam })
        .then((res) => {
          setsentVerificationEmail(true);
          toast.dismiss();
          console.log("response value", res)

          toast.success("Email sent SuccessFully");
          setCurrentToken(res.data.token);
          setUser({ email: res.data.email, token: res.data.token });
        })
        .catch((error) => {
          setEmailLoading(false);
          toast.dismiss();

          toast.warning("Server Busy. Please try again later");
        });
    }
  };

  const newInstituteRegister = () => {
    const data = {
      email: localUser?.email,
      password: formik.values.newPassword,
      isNipam,
    };
    setNewStudentLoader(true);

    apiJson
      .post("v2/register/newInstituteRegister", data)
      .then((res) => {
        setNewStudentLoader(false);
        if (res.data.success === true) {
          navigate("/login");

          //call nipam api here
        }
      })
      .then(() => {
        setNewStudentLoader(false);
        // window.location.href = '/institute-on-boarding';
        navigate("/login");
        toast.dismiss();
      })
      .catch((error) => {
        setNewStudentLoader(false);
        console.log(error.message);
        toast.dismiss();

        toast.dismiss("Server Busy. Please try again later");
      });
  };

  //========= Handle login function  start=================\\
  const handleLogIn = () => {
    const data = {
      email: userDetail.values.email,
      password: userDetail.values.password,
    };
    if (!data.password && data.email) {
      setShowCredential("Enter your Password");
    } else {
      apiJson
        .post("v2/auth/v2-login", data)
        .then((res) => {
          if (res.data.success === true) {
            if (
              (res?.data?.user?.role === "teacher" &&
                res?.data.user?.nipamCheck) ||
              (res?.data?.user?.role === "student" &&
                res?.data.user?.nipamCheck)
            ) {
              setUser(res.data.user);
              setToken(res.data.jwt);
              navigate("/course/overview/4");
            } else {
              setUser(res.data.user);
              setToken(res.data.jwt);
              if (res?.data?.user?.role === "admin") {
                navigate("/admin/allInstituteDetails");
              } else {
                navigate("/new-dashboard");
              }
            }
          } else {
            setShowCredential("Invalid password");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  //========= Handle login function  End=================\\

  const setFormikData = (name, value) => {
    userDetail.setFieldValue(name, value);
  };
  const nextButtonStyles = {
    border: "1px solid #2356FE",
    color: isHovered ? "#FFFFFF" : "#2356FE",
    backgroundColor: isHovered ? "#2356FE" : "transparent",
    fontSize: "16px",
    height: "50px",
    width: "50%",
    textAlign: "center",
    cursor: "pointer",
    transition: "background-color 0.3s, color 0.3s",
  };
  // ========  Show hide  password  function  handler =======
  const handleEyeToogleEnterPass = () => {
    setShowEyeIconEnterPass(!showEyeIconEnterPass);
  };
  ///=============Password Reset Function start ============ \\\
  const sendResetPassword = (email) => {
    apiJson(`v2/auth/v2-reset-password-email/${email}`)
      .then((res) => {
        navigate(`/v2/auth/password-reset/email/verify?email=${email}`);
        toast.dismiss();
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss();

        toast.error("Server Busy. Please try again later");
      });
  };
  ///=============Password Reset Function End ============ \\\

  const validation = Yup.object().shape({
    newPassword: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])(?=.{6,})/,
        "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 symbol, 1 number, and be at least 6 characters"
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };
  const onSubmit = () => {
    // console.log("asfjasklfj")
    checkpassExistorNot();
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit,
  });

  const checkpassExistorNot = async () => {
    try {
      const result = await apiJson.get(
        // `v2/register/instituteGetData/${userDetail.values.email}`
        `v2/register/instituteGetData/${localUser?.email}`
      );
      if (result?.data?.success === true) {
        Swal.fire({
          text: result?.data?.message,
          icon: "warning",
          confirmButtonText: "Login",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            navigate("/login");
          }
        });
      } else {
        if (userDetail?.values?.type === "Institution") {
          newInstituteRegister();
        }
      }
    } catch (error) {
      console.log(error?.message);
    }
  };
  const roles = [
    { text: "IP Commercialization", color: "#B67EFF" },
    { text: "Out-of-the-box thinking", color: "#BFEE00" },
    { text: "Prototyping", color: "#2400FF" },
    { text: "Design thinking", color: "#FD01F5" },
    { text: "Creative thinking", color: "#DD0411" },
    { text: "Innovation", color: "#CC00FF" },
    { text: "Ideation", color: "#00FFC2" },
  ];

  let currentIndex = 0;

  useEffect(() => {
    const roleElement = document.getElementById("role");

    function showNextRole() {
      if (!roleElement) return;

      roleElement.classList.remove(styles.visible);
      setTimeout(() => {
        roleElement.textContent = roles[currentIndex].text;
        roleElement.style.color = roles[currentIndex].color;
        roleElement.classList.add(styles.visible);
        currentIndex = (currentIndex + 1) % roles.length;
      }, 500);
    }

    const intervalId = setInterval(showNextRole, 2000);
    showNextRole();

    return () => clearInterval(intervalId);
  }, [roles]);

  const renderStepContent = () => {
    // step 1 Login
    // step 2 create
    // step 3 Verify
    // step  4 create password
    //Step 5 Password for login

    switch (step) {
      // Login
      case 1:
        return (
          <div className="d-flex flex-column px-3 py-2">
            <span
              className="fs-3 fw-semibold mt-3"
              style={{ color: "#2356FE" }}
            >
              Proceed as..
            </span>
            <div
              className="badge rounded-pill mt-4 text-center fs-3 fw-normal d-flex justify-content-center align-items-center flex-row"
              style={{
                height: "75px",
                color: "#2356FE",
                paddingLeft: "38px",
                background: "rgb(35 86 254 / 2%)",
                shadow: "#2356FE",
                borderRadius: "60px",
                boxShadow: "0px 2px #2356FE",
              }}
            >
              <span>Institute</span>
              <div
                className="object-fit-contain h-100 w-100"
                style={{ marginBottom: "55px" }}
              >
                <img
                  src="./view12.png"
                  alt="college"
                  style={{ width: "100px", transform: "scale(1.20)" }}
                />
              </div>
            </div>
            <form>
              <div className="row">
                <div className="col-12 mb-2 mb-md-4 mt-3">
                  <span className="fw-light fs-6   mb-2 d-block">
                    Enter Mail Id
                  </span>
                  <Input
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.type === "click") {
                        e.preventDefault();
                        if (userDetail.isValid) {
                          checkIfEmailExistLogIn(userDetail.values.email);
                        }
                      }
                    }}
                    type="email"
                    variant="plain"
                    color={!emailExistLogin ? "danger" : "neutral"}
                    className="custom-placeholder"
                    endDecorator={
                      !emailExistLogin ? (
                        <ErrorOutlineRounded color="error" />
                      ) : (
                        <CheckCircle
                          color={
                            userDetail?.values?.email ? "success" : "disabled"
                          }
                        />
                      )
                    }
                    placeholder="Registered Email Address"
                    aria-label=".form-control-lg example"
                    value={userDetail.values.email}
                    onBlur={userDetail.handleBlur}
                    onChange={(e) => {
                      const email = e.target.value;
                      setFormikData("email", email);
                      setemailLoginExist(true);

                      // Email validation using a regular expression
                      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      const isValidEmail = emailRegex.test(email);

                      // You can add additional validation if needed
                      if (!isValidEmail) {
                        setemailLoginExist(false);
                      }
                    }}
                  />

                  {userDetail.errors.email ? (
                    // <span className='text-danger'>{userDetail.errors.email}</span>
                    <StyledAlert
                      type="danger"
                      content={userDetail.errors.email}
                    />
                  ) : null}
                  {!emailExistLogin && !userDetail.errors.email && (
                    // <span className='text-danger'>Email Not Exist</span>
                    <StyledAlert
                      type="danger"
                      content="Email does not exist, please check for typos in your email or create a new account"
                    />
                  )}
                </div>

                <div className="col-12 mb-2 mb-md-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <button
                      disabled={emailLoading}
                      type="button"
                      className={`${styles.loginbtn} fw-semibold btn btn-primary p-0 fs-5 w-50 rounded-4`}
                      style={{ height: "50px" }}
                      onClick={(e) => {
                        if (userDetail.isValid) {
                          checkIfEmailExistLogIn(userDetail.values.email);
                        }
                      }}
                    >
                      Login{" "}
                      <KeyboardDoubleArrowRightTwoToneIcon className="d-none d-lg-inline-block" />
                    </button>
                    <button
                      disabled={emailLoading}
                      type="button"
                      className="btn btn-outline-secondary fw-semibold rounded-4 p-0 fs-5 w-50 ms-3"
                      onClick={(e) => {
                        e.preventDefault();
                        if (userDetail.isValid) {
                          checkIfEmailExist(userDetail.values.email);
                        }
                      }}
                      style={{ color: "#000000", height: "45px" }}
                    >
                      Register{" "}
                      <KeyboardDoubleArrowRightTwoToneIcon className="d-none d-lg-inline-block" />
                    </button>
                  </div>
                </div>

                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src="./NipamLogo.svg"
                    alt="Nipamlogo"
                    style={{
                      width: "60%",
                      maxWidth: "140px",
                      height: "100%",
                      maxHeight: "100px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div className="d-flex justify-content-end align-items-center my-2 ">
                  <span
                    type="button"
                    className="cursor-pointer fs-6 fw-medium cursor-pointer"
                    style={{ color: "#000000" }}
                  >
                    Having Trouble?{" "}
                    <span style={{ color: "#2356FE" }}>Get Help</span>
                  </span>
                </div>
              </div>
            </form>
          </div>
        );

      //=====   Create Account ======\\
      case 2:
        return (
          <div className="d-flex flex-column px-3 py-2">
            <span
              className="fs-3 fw-semibold mt-3"
              style={{ color: "#2356FE" }}
            >
              Enter Mail
            </span>
            <div
              className="badge rounded-pill mt-4 text-center fs-3 fw-normal d-flex justify-content-center align-items-center flex-row"
              style={{
                height: "75px",
                color: "#2356FE",
                paddingLeft: "38px",
                background: "rgb(35 86 254 / 2%)",
                shadow: "#2356FE",
                borderRadius: "60px",
                boxShadow: "0px 2px  #2356FE",
              }}
            >
              <span>Institute</span>
              <div
                className="object-fit-contain h-100 w-100"
                style={{ marginBottom: "55px" }}
              >
                <img
                  src="./view12.png"
                  alt="college"
                  style={{ width: "100px", transform: "scale(1.20)" }}
                />
              </div>
            </div>
            <form>
              <p className="fw-medium my-2 fs-18-07px">
                Please Enter your E-mail id to get Verification Mail.
              </p>
              <div className="row">
                <div className="col-12 mb-2 mb-md-4">
                  <span className="fw-light fs-6 fs-md-5  mb-2 d-block">
                    Enter Mail ID
                  </span>
                  <input
                    placeholder="Enter Your email"
                    className="form-control rounded-3 border-0 box-shadow-0 background-grey"
                    type="email"
                  />
                </div>

                <div className="col-12 mt-4 mb-2 mb-md-4">
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      type="button"
                      className={`${styles.sendEmailbtn} btn btn-outline-secondary fs-6 fw-normal rounded-3 w-100`}
                      onClick={() => {
                        setStep(3);
                      }}
                      style={{ color: "#000000", height: "px" }}
                    >
                      Send Verification Mail{" "}
                      <KeyboardDoubleArrowRightTwoToneIcon className="d-none d-md-inline-block" />
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center my-2 ">
                  <span
                    type="button"
                    className={`fs-6 fw-medium cursor-pointer `}
                    style={{ color: "#000000" }}
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    Back
                  </span>
                  <span
                    type="button"
                    className="cursor-pointer fs-6 fw-medium cursor-pointer"
                    style={{ color: "#000000" }}
                  >
                    Having Trouble?{" "}
                    <span style={{ color: "#2356FE" }}>Get Help</span>
                  </span>
                </div>
              </div>
            </form>
          </div>
        );
      //=====   Verify Account ======\\
      case 3:
        return (
          <div className="d-flex flex-column px-3 py-2">
            <span
              className="fs-3 fw-semibold mt-3"
              style={{ color: "#2356FE" }}
            >
              Email Verification
            </span>
            <p className="fw-medium mb-2 fs-18-07px mt-4 text-break">
              A verification email was sent to{" "}
              <span style={{ color: "#2356FE" }}>
                “{userDetail.values.email}”
              </span>
              , check your email & click on the link to verify email.
            </p>

            <div className="my-4">
              {!isVerified &&
                !emailLoading &&
              {
                /* <button onClick={()=>sendVerifyEmail(userDetail?.values?.email)} disabled={emailLoading} className={`fw-semibold rounded-3 btn btn-outline-primary`} style={{ width: "auto", padding: "14px 30px", fontSize: "16px" }} >
                <span className={emailLoading?`spinner-border spinner-border-sm`:``} >{emailLoading?``:`Resend Verification Email`}</span>
               </button> */
              }}

              {/* <button type="button" className={`fw-semibold rounded-3 btn btn-outline-primary`} style={{ width: "auto", padding: "14px 30px", fontSize: "16px" }} >Resend Verification Email</button> */}
            </div>
            <div className="d-flex justify-content-between align-items-center mt-1 mt-mt-3 mb-2 mb-md-3">
              <button
                className={` fw-semibold   w-50 rounded-3 text-center`}
                style={nextButtonStyles}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => {
                  setStep(4);
                }}
              >
                Next{" "}
                <KeyboardDoubleArrowRightTwoToneIcon className="d-none d-md-inline-block" />
              </button>
              <button
                className={` ${styles.sendEmailbtn} btn btn-outline-secondary fw-semibold rounded-3 fs-6 w-50  ms-3 p-0`}
                onClick={() => {
                  setFormikData("email", "");
                  setEmailLoading(false);
                  setStep(1);
                }}
                style={{ color: "#000000", height: "50px" }}
              >
                Change Email{" "}
                <KeyboardDoubleArrowRightTwoToneIcon className="d-none d-md-inline-block" />
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <img
                src="./NipamLogo.svg"
                alt="Nipamlogo"
                style={{
                  width: "60%",
                  maxWidth: "140px",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center my-4">
              <span
                type="button"
                className={`fs-6 fw-medium cursor-pointer `}
                style={{ color: "#000000" }}
                onClick={() => {
                  setFormikData("email", "");
                  setEmailLoading(false);
                  setStep(1);
                }}
              >
                Back
              </span>
              <span
                type="button"
                className="cursor-pointer fs-6 fw-medium cursor-pointer"
                style={{ color: "#000000" }}
              >
                Having Trouble?{" "}
                <span style={{ color: "#2356FE" }}>Get Help</span>
              </span>
            </div>
          </div>
        );
      //============= create password  ============\\
      case 4:
        return (
          <div className="d-flex flex-column px-3 py-2">
            <span
              className="fs-3 fw-semibold mt-3 mb-4"
              style={{ color: "#2356FE" }}
            >
              Set Password
            </span>
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-12 mb-2 mb-md-2 mt-3">
                  <span className="fw-light fs-6 fs-md-5  mb-2 d-block">
                    Password
                  </span>
                  <div className="d-flex justify-content-center align-items-center background-grey box-shadow-0 rounded-3 border-0 sm-wfull">
                    <input
                      style={{ fontSize: "18px" }}
                      placeholder="********"
                      value={formik.values.newPassword}
                      name={"newPassword"}
                      onChange={formik.handleChange}
                      type={showEyeIconNew ? "text" : "password"}
                      className="form-control rounded-3 background-grey box-shadow-0 border-0 sm-wfull ps-3"
                    />
                    {showEyeIconNew ? (
                      <div className="pe-2">
                        <VisibilityIcon
                          className="cursor-pointer"
                          onClick={() => {
                            setShowEyeIconNew(!showEyeIconNew);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="pe-2">
                        <VisibilityOffIcon
                          className="cursor-pointer"
                          onClick={() => {
                            setShowEyeIconNew(!showEyeIconNew);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12 mb-2 mb-md-3">
                  <span className="fw-light fs-6 fs-md-5  mb-2 d-block">
                    Confirm Password
                  </span>
                  <div className="d-flex justify-content-center align-items-center rounded-3 background-grey box-shadow-0 border-0 sm-wfull">
                    <input
                      style={{ fontSize: "18px" }}
                      placeholder="********"
                      value={formik.values.confirmPassword}
                      name={"confirmPassword"}
                      onChange={formik.handleChange}
                      type={showEyeIconConfirm ? "text" : "password"}
                      className="form-control rounded-3 background-grey box-shadow-0 border-0 sm-wfull ps-3"
                    />
                    {showEyeIconConfirm ? (
                      <div className="pe-2">
                        <VisibilityIcon
                          className="cursor-pointer"
                          onClick={() => {
                            setShowEyeIconConfirm(!showEyeIconConfirm);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="pe-2">
                        <VisibilityOffIcon
                          className="cursor-pointer"
                          onClick={() => {
                            setShowEyeIconConfirm(!showEyeIconConfirm);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 mb-2 mb-md-0">
                  <div className="d-flex justify-content-center align-items-center mt-0 mb-0">
                    <button
                      type="submit"
                      className={`${styles.loginbtn} fw-semibold btn btn-primary p-0 fs-5 w-100 rounded-4`}
                      style={{ height: "50px" }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src="./NipamLogo.svg"
                    alt="Nipamlogo"
                    style={{
                      width: "60%",
                      maxWidth: "140px",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center mt-0 mb-0">
                  <span
                    type="button"
                    className={`fs-6 fw-medium cursor-pointer `}
                    style={{ color: "#000000" }}
                    onClick={() => {
                      setStep(3);
                    }}
                  >
                    Back
                  </span>
                  <span
                    type="button"
                    className="cursor-pointer fs-6 fw-medium cursor-pointer"
                    style={{ color: "#000000" }}
                  >
                    Having Trouble?{" "}
                    <span style={{ color: "#2356FE" }}>Get Help</span>
                  </span>
                </div>
              </div>
            </form>
          </div>
        );
      case 5:
        return (
          <div className="d-flex flex-column px-3 py-2">
            <span
              className="fs-3 fw-semibold mt-3"
              style={{ color: "#2356FE" }}
            >
              Enter password
            </span>
            <div
              className="badge rounded-pill mt-4 text-center fs-3 fw-normal d-flex justify-content-center align-items-center flex-row"
              style={{
                height: "75px",
                color: "#2356FE",
                paddingLeft: "38px",
                background: "rgb(35 86 254 / 2%)",
                shadow: "#2356FE",
                borderRadius: "60px",
                boxShadow: "0px 2px #2356FE",
              }}
            >
              <span>Institute</span>
              <div
                className="object-fit-contain h-100 w-100"
                style={{ marginBottom: "55px" }}
              >
                <img
                  src="./view12.png"
                  alt="college"
                  style={{ width: "100px", transform: "scale(1.20)" }}
                />
              </div>
            </div>
            <form>
              <div className="row">
                <div className="col-12 mb-2 mb-md-2 mt-3">
                  <span className="fw-light fs-6 fs-md-5  mb-2 d-block">
                    Password
                  </span>
                  <div className="d-flex justify-content-center align-items-center rounded-3 background-grey box-shadow-0 border-0 sm-wfull">
                    <input
                      style={{ fontSize: "18px" }}
                      placeholder="********"
                      onChange={(e) =>
                        setFormikData("password", e.target.value)
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.type === "click") {
                          handleLogIn();
                        }
                      }}
                      type={showEyeIconEnterPass ? "text" : "password"}
                      className="form-control rounded-3 background-grey box-shadow-0 border-0 sm-wfull ps-3"
                    />
                    {showEyeIconEnterPass ? (
                      <div className="pe-2">
                        <VisibilityIcon
                          className="cursor-pointer"
                          onClick={handleEyeToogleEnterPass}
                        />
                      </div>
                    ) : (
                      <div className="pe-2">
                        <VisibilityOffIcon
                          className="cursor-pointer"
                          onClick={handleEyeToogleEnterPass}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    {showCredential ? (
                      <span className="text-danger">{showCredential}</span>
                    ) : null}
                  </div>
                </div>
                <div
                  className="py-2"
                  onClick={() => {
                    sendResetPassword(userDetail?.values?.email);
                  }}
                >
                  <a className="text-primary text-decoration-underline">
                    Forgot password?
                  </a>
                </div>
                <div className="col-12 mb-2 mb-md-1 mt-2">
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <button
                      type="button"
                      className={`${styles.loginbtn} fw-semibold btn btn-primary p-0 fs-5 w-100 rounded-4`}
                      style={{ height: "50px" }}
                      onClick={() => {
                        handleLogIn();
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </div>

                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src="./NipamLogo.svg"
                    alt="Nipamlogo"
                    style={{
                      width: "60%",
                      maxWidth: "140px",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center my-2 ">
                  <span
                    type="button"
                    className={`fs-6 fw-medium cursor-pointer `}
                    style={{ color: "#000000" }}
                    onClick={() => {
                      setFormikData("password", "");
                      setStep(1);
                    }}
                  >
                    Back
                  </span>
                  <span
                    type="button"
                    className="cursor-pointer fs-6 fw-medium cursor-pointer"
                    style={{ color: "#000000" }}
                  >
                    Having Trouble?{" "}
                    <span style={{ color: "#2356FE" }}>Get Help</span>
                  </span>
                </div>
              </div>
            </form>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="mt-3 px-2 px-md-5 py-3">
        <div className="fs-2 text-center">
          <span style={{ fontFamily: "samarn", color: "#2C2C73" }}>
            Yuva Manthan
          </span>{" "}
          promoting culture of{" "}
          <span id="role" className={`${styles.role} fw-500`}></span> <br />
          <span>
            {" "}
            Through{" "}
            <span className="fw-medium">
              National Intellectual Property Awareness Mission{" "}
              <span className={`${styles.text}`}>(NIPAM) </span>{" "}
            </span>
          </span>
        </div>
        {/* ============== form  area  start here ================== */}
        <div className="row mt-5 mb-100">
          <div className="col-12 col-md-6 mb-3 mb-md-0">
            <div className="d-flex justify-content-center align-items-center">
              <img
                src="./SIC ILLUSTRATION.png"
                alt="ILLUSTRATION"
                style={{
                  maxHeight: "450px",
                  objectFit: "contain",
                  width: "100%",
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 mt-5 mt-md-0 ">
            <div className="d-flex justify-content-center align-items-center">
              <div className={`${styles.parantForm}`}>
                <div className={`${styles.window}`}>
                  <div
                    className=""
                    style={{
                      background: "#fff",
                      width: "100%",
                      height: "100%",
                      borderRadius: "15px",
                    }}
                  >
                    {renderStepContent()}
                  </div>
                </div>
                <div
                  className={`${styles.cirTopleft} p-absolute  rounded-circle`}
                ></div>
                <div
                  className={`${styles.cirTopright} p-absolute  rounded-circle`}
                ></div>
                <div
                  className={`${styles.cirBotLeft} p-absolute  rounded-circle`}
                ></div>
                <div
                  className={`${styles.cirBotRight} p-absolute  rounded-circle`}
                ></div>
              </div>
            </div>
          </div>
        </div>
        {/* ============== form  area  End here ================== */}

        {/*================== Politian Bio section start here  ==================*/}
        <div className="container pt-5 mb-100">
          <div className="row justify-content-center p-relative pt-5">
            {politicians.map((politician, index) => (
              <div className="col-md-4 position-relative mb-5" key={index}>
                <div className={styles[politician?.circle]}></div>
                <div className={`${styles.card} text-center`}>
                  <img
                    src={politician?.profile}
                    className="card-img-top object-fit-cover  mx-auto mt-5"
                    alt="Narendra Modi"
                    style={{ width: "170px", maxHeight: "170px" }}
                  />
                  <div className="card-body">
                    <div style={{ height: "155px" }}>
                      <p
                        className={` card-text fs-6 fw-normal`}
                        style={{ color: "#222223" }}
                      >
                        <span className={`${styles.txtbefore}`}>"</span>{" "}
                        {politician?.Desc}
                        <span className={`${styles.txtafter}`}>"</span>
                      </p>
                    </div>
                    <div classname="mt-4">
                      <h5
                        className={`${styles["card-title"]} fw-semibold fs-5`}
                      >
                        {politician?.name}
                      </h5>
                      <p className={`${styles["card-subtitle"]} fw-light`}>
                        {politician?.position}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div
              className={`${styles.backgroundcircle3} d-none d-lg-block`}
            ></div>
            <div
              className={`${styles.backgroundcircle4} d-none d-xl-block`}
            ></div>
            <div
              className={`${styles.backgroundcircle5} d-none d-xl-block`}
            ></div>
          </div>
        </div>
        {/*================== Politian Bio section End here  ==================*/}
      </div>
      {/*================== Testominal section start here  ==================*/}

      <div className={`${styles.carouselItem} mb-80`}>
        <div
          className="d-block w-100"
          style={{ height: "200px", borderTop: "14px solid #D9D9D9" }}
        >
          <NipamTxtSlider />
        </div>
      </div>
      {/*================== Testominal section End here  ==================*/}
      {/*================== Benefit card section start here  ==================*/}

      <div
        className="container position-relative mb-80"
        style={{
          padding: "60px 0",
          boxShadow: "0 0 54px #0000001A",
          borderRadius: "20px",
        }}
      >
        <div
          className="position-absolute d-none d-lg-block "
          style={{
            top: "14%",
            left: "20%",
            width: "75%",
            maxWidth: "800px",
            height: "75%",
            maxHeight: "800px",
            background: "#F9FBFF",
            zIndex: -1,
            borderRadius: "50%",
          }}
        ></div>
        <div
          className={`${styles.topribbon}  d-flex justify-content-between align-items-center py-3 mb-60`}
        >
          <div className="Nut ms-4">
            <img src="./nut.svg" alt="nut" />
          </div>
          <p className="fs-2 text-white fw-semibold">Benefits of IPR</p>
          <div className="Nut me-4">
            <img src="./nut.svg" alt="nut" />
          </div>
        </div>
        <div className={`${styles.parantCardben}`}>
          {benefits.map((benefit, index) => (
            <BenefitCard key={index} benefit={benefit} />
          ))}
        </div>
      </div>
      {/*================== Benefit card section End here  ==================*/}
      {/*================== ip Innovation  section Start here  ==================*/}
      <div className="mb-100">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div
              className="d-flex justify-content-end"
              style={{
                background: "rgb(28,91,36)",
                background:
                  "linear-gradient(90deg, rgba(56,182,72,1) 0%, rgba(43,140,55,1) 3%, rgba(37,119,47,1) 6%, rgba(28,91,36,1) 13%)",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                maxHeight: "600px",
              }}
            >
              <img src="./IpClub.svg" alt="Ip_Club" className="rounded-4" />
            </div>
          </div>
          <div className="col-12 col-lg-6 px-4">
            <div className="iptext mt-4">
              <span className="fs-1 fw-bold mt-4" style={{ color: "#2356FE" }}>
                Join the IP Innovation Club
              </span>
              <p className="fs-3 fw-semibold" style={{ color: "#424242" }}>
                Unleash Your Innovation At Yuvamanthan's IP Innovation Club!
                Dive Into IPR Activities, Discussions, And Projects.
              </p>
              <p
                className="fs-6 fw-normal mt-3"
                style={{ color: "#424242", lineHeight: 1.5 }}
              >
                Students from classes 8th to 12th from each school are
                encouraged to join the IP Innovation Club on the Yuvamanthan
                website. Here they will be able to organise and manage
                activities related to Intellectual Property Rights awareness
                while encouraging the spirit of entrepreneurship, creativity and
                innovation. Participate in IPR sessions, discussions, projects
                and workshops. The club members will have multitudes of
                opportunities to get certificates as they are encouraged to
                undertake activities under the purview of the National
                Intellectual Property Awareness Mission (NIPAM).
              </p>
            </div>
            <div className="Ipbutton">
              <button className="btn btn-primary rounded-3 fs-2 fs-semibold px-3 mt-5">
                Join IP Innovation Club
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*================== Nipam course  section Start here  ==================*/}
      <div className=" mb-100">
        <div
          className="position-relative"
          style={{
            widows: "100%",
            height: "8px",
            borderRadius: "52% 54% 54% 54% / 18% 14% 14% 18%",
            background: "#D9D9D9",
          }}
        >
          <span
            className={`${styles.moduleHeading} fs-3 fw-semibold position-absolute d-flex justify-content-center align-items-center`}
          >
            Certifications
          </span>
        </div>
      </div>
      <div className="mb-100">
        <div className="row mb-60">
          <div class="col-12 col-lg-6 d-flex">
            <div className="iptext ms-4 mt-4 d-flex justify-content-center align-items-start flex-column">
              <span
                className="fs-1 fw-semibold mt-4"
                style={{ color: "#000000" }}
              >
                NIPAM Certificate Course for Students and Teachers
              </span>
              <p
                className="fs-6 fw-normal mt-3"
                style={{ color: "#424242", lineHeight: 1.5 }}
              >
                These complimentary training courses offer an invaluable
                resource for staff and students keen on IP, providing a
                comprehensive understanding of IP fundamentals and highlighting
                its significance.
              </p>
              <p className="fs-3 fw-semibold" style={{ color: "#000000" }}>
                Course Duration: <span className="fw-normal">40 minutes</span>
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div
              className="mainposterBox"
              style={{
                background:
                  "linear-gradient(180deg, #2757FF 100%, #2356FE 100%)",
                boxShadow: "0 14px 44px #00000059",
                borderRadius: "10px 0px 0px 10px",
                height: "540px",
              }}
            >
              <img
                src="./Nipam.png"
                alt="Nipam"
                className="h-100 w-75  rounded-3 ps-3 py-3"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <CourseDetailsTab />
        </div>
      </div>

      <div className=" mb-100">
        <div
          className="position-relative"
          style={{
            widows: "100%",
            height: "8px",
            borderRadius: "52% 54% 54% 54% / 18% 14% 14% 18%",
            background: "#D9D9D9",
          }}
        >
          <span
            className={`${styles.moduleHeading} fs-3 fw-semibold position-absolute d-flex justify-content-center align-items-center`}
          >
            Active discussions on IP Innovation Club
          </span>
        </div>
      </div>
      <div className="container mb-40">
        <h2 className="text-center">Innovation Board</h2>
        <p className="text-center">
          Innovation Board Chat! Share your brilliant ideas, inspire others, and
          let your voice be heard. Upvote the ideas you love to help them rise
          to the top. Let's ignite creativity and make a difference together!
        </p>
      </div>
      <div className="chatbox mb-60">
        <img src="./chatbox.png" alt="chatbox" className="w-100" />
      </div>
      <div className=" mb-100">
        <div
          className="position-relative"
          style={{
            widows: "100%",
            height: "8px",
            borderRadius: "52% 54% 54% 54% / 18% 14% 14% 18%",
            background: "#D9D9D9",
          }}
        >
          <span
            className={`${styles.moduleHeading} fs-3 fw-semibold position-absolute d-flex justify-content-center align-items-center`}
          >
            What People Are Saying About Us!
          </span>
        </div>
      </div>
      <div className="container mb-40">
        <p className="text-center">
          We strive to improve our platform through feedback. Se what students,
          teachers and other educational stakeholders have to share about us.
        </p>
      </div>
      <Slider />
    </>
  );
};

const benefits = [
  {
    id: 1,
    background: "#FFE59C",
    title: "Protects Economy",
    desc: "Intellectual property safefguards the economy by influencing GDP, employement, tax revenues, devfelopement, & mainating competitiveness within various Industries.",
  },
  {
    id: 2,
    background: "#FFC5F2",
    title: "Leverage Capital",
    desc: "via sales, licensing, or using intellectual property as collateral for debt financing. IPRs enhance opportunities for government funding.",
  },
  {
    id: 3,
    background: "#FDFCFF",
    title: "Fuels innovation",
    desc: "Intellectual property boosts funding for research & development, and enables companies to realize & capitalize on the value of innovation.",
  },
  {
    id: 4,
    background: "#D3FFFF",
    title: "Prevents Counterfeiting & Piracy",
    desc: "Benefiting consumers, and driving innovative solutions to address society's needs and challenges.",
  },
  {
    id: 5,
    background: "#FFD3D3",
    title: "Elevates Market Value",
    desc: "Utilize IPRs for business growth, licensing, and commercialization, enhancing market share, profits, and overall business value in transactions..",
  },
  {
    id: 6,
    background: "#E8D6FF",
    title: "Boosts exports",
    desc: "Registered IPRs empower businesses to market products globally using brands, designs, and franchising agreements, expanding opportunities and reach.",
  },
];
export default NewNip;