import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import { Modal } from "react-bootstrap";
import { useGlobalContext } from "global/context";
import { apiJson } from "api";
import Button from '@mui/material/Button';
import Swal from "sweetalert2";
import Avatar from '@mui/material/Avatar';

const ClubDetails = ({ clubDetail, isJoined, joinClub }) => {
  const { userData } = useGlobalContext();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showRulesModal, setShowRulesModal] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);
  const showRulesButtonTo = ["LiFE Club", "IP Innovation Club"];
  const [clubArray, setclubArray] = useState([]);
  const [instituteName, setInstituteName] = useState("");
  const logo = userData?.logo || userData?.profile
  const getInstitutionName = () => {
    const id =
      userData?.role === "institute" ? userData?.id : userData?.instituteId;
    apiJson(`api/v2/institute/getInstituteId/${id}`)
      .then((res) => {
        setInstituteName(res?.data?.getInstituttionName?.institution_name);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    getInstitutionName();
  }, []);

  const joinClubAlert = ()=>{
    Swal.fire({
      html: `
      <p className="fs-6 fw-semibold">
      By joining this group, you agree to abide by these rules. Let's
      create a dynamic and supportive community within the
      <span className="text-primary"> ${clubDetail?.name}</span>
    </p>
  `,
  confirmButtonText: `Join ${clubDetail?.name}`,
  showCancelButton: true,
    })
    .then((result)=>{
      if (result.isConfirmed) {
        joinClub();
        console.log(result.isConfirmed)
        Swal.fire({
          title: "congratulations",
          text: `You have successfully join ${clubDetail?.name} .`,
          icon: "success"
        })
      }else{
          console.log(result.isConfirmed)
      }
    })
  }


  return (
    <>
      <div className="shadow rounded-3 py-1 clubdetails">
        <div className="row flex flex-column-reverse flex-sm-row">
          <div className="col-sm-7 d-flex flex-column justify-content-center align-items-center">
           <div>
             <Avatar src={logo} alt="Institute_logo"   sx={{ width: 56, height: 56 }}/>
              </div>
            <div className="text-center">
              <div>
                <h3 className="fs-3 fw-semibold">Welcome to the</h3>
              </div>
             
              <span className="text-primary fw-semibold fs-3">
                <span className="text-black">
                  {instituteName} {"(Chapter)"}{" "}
                </span>{" "}
                <span style={{ color: "#00e8ff" }} className="fs-3">{clubDetail?.name}</span>
              </span>
              <p className='fs-6 text-center mt-3'>{clubDetail?.description}</p>
            </div>
            <div className="d-flex justify-content-around gap-1 my-2 pb-2">
        {clubDetail?.info && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setShowInfoModal(true)}
            className="rounded-2 p-0 m-0"
          >
            <InfoOutlinedIcon style={{ fontSize: "15px" }} /> Info
          </Button>
        )}

        {clubDetail?.rules?.rulesArray?.length > 0 && (
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={() => setShowRulesModal(true)}
            className="rounded-2 clubbtn"
          >
            <GavelOutlinedIcon style={{ fontSize: "15px" }} /> Rules
          </Button>
        )}
        {!isJoined && (
          <Button
            size="small"
            variant="contained"
            color="success"
            onClick={() => joinClubAlert()}
            className="rounded-2 clubbtn"
          >
            <GroupAddOutlinedIcon style={{ fontSize: "15px" }} /> Join Club
          </Button>
        )}
      </div>


            
          </div>

          <div className="col-sm-5">
            <div className="h-100 overflow-hidden">
                <img src={clubDetail?.logo} alt="img" width="100%" className="overflow-hidden club-right-banner" />
            </div>
          </div>
        </div>
      </div>
    
      <Modal
        scrollable
        size="lg"
        show={showInfoModal}
        onHide={() => setShowInfoModal(false)}
      >
        <Modal.Body className="">
          <div
            className="py-2 px-1"
            dangerouslySetInnerHTML={{ __html: clubDetail?.info }}
          >
            {/* {clubDetail?.info?.split('\n').map((line, index) => (
                            <p className='' key={index}>{line}</p>
                        ))} */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setShowInfoModal(false)}
            className="btn btn-sm btn-outline-danger rounded-3"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        scrollable
        size="lg"
        show={showRulesModal}
        onHide={() => setShowRulesModal(false)}
      >
        <Modal.Body className="">
          <div className="py-2 px-1">
            <div className="mb-3">
              <h3 className="fs-3 fw-semibold ">{clubDetail?.rules?.title}</h3>
            </div>
            <div>
              {clubDetail?.rules?.rulesArray?.map((rule) => {
                return (
                  <>
                    <div>
                      <h3 className="fs-5 fw-semibold">{rule?.rulesTitle} :</h3>
                    </div>
                    <div>
                      {rule?.rulesPara?.split("\n").map((i) => (
                        <p>{i}</p>
                      ))}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setShowRulesModal(false)}
            className="btn btn-sm btn-outline-danger rounded-3"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showJoinModal} onHide={() => setShowJoinModal(false)}>
        <Modal.Body>
          <div>
            <p className="fs-6 fw-semibold">
              {" "}
              By joining this group, you agree to abide by these rules. Let's
              create a dynamic and supportive community within the
              <span className="text-primary"> {clubDetail?.name}</span>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              joinClub();
              setShowJoinModal(false);
            }}
            className="btn btn-outline-success btn-sm rounded-3 "
          >
            Join {clubDetail?.name}
          </button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

const data = [
  {
    name: "LiFE Club",
    about: "About LiFE (Lifestyle for Environment)",
    desc: `LiFE stands as a visionary initiative championed by the esteemed Prime Minister Narendra Modi. Envisioned as a global mass movement led by India, Mission LiFE aims to catalyze individual and collective efforts toward environmental protection and preservation. The core concept advocates for an eco-conscious lifestyle, emphasizing 'mindful and deliberate utilization' over 'mindless and destructive consumption.' The P3 (pro-planet people) movement reinforces India's commitment to addressing climate change, urging citizens to adopt a lifestyle harmonious with the Earth.

        Initiated by the government, this movement empowers every citizen to collectively shoulder the responsibility of living in harmony with the environment, minimizing risks. Individuals embracing this ethos are recognized as Pro Planet People under LiFE. Acknowledging the challenge of transforming ingrained habits deeply rooted in our daily lives, the Prime Minister highlighted the adverse impact of our 'throw culture' and rampant consumerism on climate change. The imperative shift from the current 'take-make-use-dispose' economy to a circular economy is crucial.

        As the effects of climate change become increasingly evident in our surroundings, unexpected calamities over the past decades underscore the urgency for change. The introduction of the LiFE Mission took place during the 26th United Nations Climate Change Conference of the Parties (COP26) in Glasgow, aligning with India's updated nationally determined contributions (NDC) officially submitted to the UNFCC in August this year. The LiFE Mission is not just a call for change; it is a commitment to a sustainable and resilient future for our planet.`,
    rules: {
      title: "Rules for Lifestyle for Environment Club",
      rulesArray: [
        {
          rulesTitle: "Respectful Communication",
          rulesPara: `Maintain a positive and respectful tone in all interactions. Avoid offensive language,personal attacks, or any form of discrimination. Let's create a welcoming environment for everyone`,
        },
        {
          rulesTitle: "Relevance to Theme",
          rulesPara: `Share content directly related to the theme of "Lifestyle for Environment." Ensure that your posts, comments, and discussions contribute to fostering a sustainable and eco-friendly lifestyle`,
        },
        {
          rulesTitle: "No Promotions or Spam",
          rulesPara: `Refrain from promoting unrelated products, services, or spamming the group with irrelevant content. This group is focused on sharing knowledge and experiences related to an environmentally conscious lifestyle `,
        },
        {
          rulesTitle: "Fact-Check Information",
          rulesPara: `Before sharing information, verify its accuracy to prevent the spread of misinformation. Encourage evidence-based discussions and support claims with reliable sources `,
        },
        {
          rulesTitle: "Diversity and Inclusivity",
          rulesPara: `Embrace diversity and inclusivity. Be open to different perspectives and experiences that contribute to the group's richness. Avoid any form of discrimination or exclusion`,
        },
        {
          rulesTitle: "Original Content Preferred",
          rulesPara: `Prioritize original content over reposting. Share your personal experiences, tips, and insights that align with the group's theme. If sharing external content, give credit to the source`,
        },
        {
          rulesTitle: "Constructive Criticism",
          rulesPara: `Engage in constructive discussions. If providing feedback or expressing disagreement, do so in a respectful manner. Encourage healthy debates that contribute to the group's learning environment.`,
        },
        {
          rulesTitle: "Event Participation",
          rulesPara: `Actively participate in group events and discussions. Your involvement enhances the collective knowledge and encourages a sense of community within the group`,
        },
        {
          rulesTitle: "No Political or Controversial Topics",
          rulesPara: `Avoid discussions or posts related to politics, controversial subjects, or anything that may lead to unnecessary conflicts. Let's maintain a focus on positive lifestyle choices for the environment `,
        },
        {
          rulesTitle: "Reporting Inappropriate Content",
          rulesPara: `If you come across any content that violates these rules, report it to the group administrators. Help us maintain a safe and enjoyable space for all members `,
        },
      ],
    },
  },
  {
    name: "IP Innovation Club",
    about: "About IP Innovation Club",
    desc: `Intellectual Property Rights (IPR) expressions such as Patents, Copyrights, Trademarks,  Design and Geographical Indications will play a critical role in achieving the target of a developed country. As more businesses uptake the digital economy and digitally driven business models, the implications for intellectual property (IP) become more significant. In the last decade, there has been a substantial rise in unicorns in India—as of February 2023, the country has 115 unicorns with a total valuation of US$350 billion. Cross Cross-border innovation is not possible without strengthening IPR through IPR awareness. Strengthening IP rights will be crucial to empower individuals to offer their products as part of the global value chains.
        While the government diligently works towards enhancing the transparency and user-friendliness of the IP system, a crucial component in propelling individuals and businesses towards Intellectual Property Rights (IPRs) is heightened awareness. In this noble pursuit, the 'National Intellectual Property Awareness Mission (NIPAM)' emerges as a guiding force to instil foundational knowledge of IPR among school students. This ambitious mission seeks to cultivate a spirit of creativity and innovation among students in higher education (classes 8 to 12) while igniting inspiration in students to innovate and safeguard their creations.
        The envisioned outcome is not only the advancement of the country but also a transformative shift where a significant number of IP holders transition from being job seekers to becoming job providers. 

        Students are encouraged to join the IP Innovation Club. Here they will be able to organise and manage activities related to Intellectual Property Rights awareness while encouraging the spirit of entrepreneurship, creativity and innovation. The system is designed to take information from the office of the Controller General of Patents, Designs & Trade Mark and disseminate the same among the students as they participate in IPR sessions, discussions, projects and workshops. The club members will have multitudes of opportunities to get certificates as they are encouraged to undertake activities under the purview of the National Intellectual Property Awareness Mission (NIPAM).`,
    rules: {
      title: "Rules for Lifestyle for Environment Club",
      rulesArray: [
        {
          rulesTitle: "Relevance to Theme",
          rulesPara:
            "All posts and discussions should align with the theme of IP Innovation, focusing on Intellectual Property Rights, entrepreneurship, creativity, and innovation.",
        },
        {
          rulesTitle: "Respectful Engagement",
          rulesPara:
            "Maintain a respectful and positive environment. Encourage constructive discussions and support fellow members in their intellectual pursuits.",
        },
        {
          rulesTitle: "Information Accuracy",
          rulesPara:
            "Share accurate information obtained from reliable sources, ensuring that content disseminated aligns with the guidelines provided by the Controller General of Patents, Designs & Trade Mark.",
        },
        {
          rulesTitle: "Active Participation",
          rulesPara:
            "Actively engage in IPR sessions, discussions, projects, and workshops organized by the club. Participation is key to fostering a vibrant community.",
        },
        {
          rulesTitle: "Certificates and NIPAM Activities",
          rulesPara:
            "Seize opportunities to earn certificates by actively undertaking activities within the National Intellectual Property Awareness Mission (NIPAM). Showcase your dedication to intellectual property awareness.",
        },
        {
          rulesTitle: "No Irrelevant Promotions",
          rulesPara:
            "Avoid promoting unrelated products or services. The focus here is on intellectual property-related activities, not commercial promotions.",
        },
        {
          rulesTitle: "Respect Intellectual Property",
          rulesPara:
            "Respect the intellectual property rights of others. Do not share copyrighted material without proper attribution or permission.",
        },
        {
          rulesTitle: "Friendly Collaboration",
          rulesPara:
            "Encourage collaboration among members. Share ideas, experiences, and insights to collectively enhance understanding and awareness of intellectual property.",
        },
        {
          rulesTitle: "Moderator Guidance",
          rulesPara:
            "Follow the guidance of group moderators. They are here to ensure the group functions smoothly and stays true to its objectives.",
        },
        {
          rulesTitle: "Inspire and Be Inspired",
          rulesPara:
            "Share inspirational stories, projects, or innovations related to intellectual property. Encourage and be inspired by the accomplishments of fellow members.",
        },
      ],
    },
  },
];

export default ClubDetails;
