import React, { useEffect, useRef, useState } from "react";
import { UI2Feature1 } from ".";
import { apiAuth, apiJsonAuth } from "api";
import { Form, Modal } from "react-bootstrap";
import { useGlobalContext } from "global/context";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { toast } from "react-toastify";
import { IconButton, TextField } from "@mui/material";
import Swal from "sweetalert2";
import MyCKEditor from "components/MyCKEditor";
// import ClubPage from "./ClubPage";
import CustomClubPage from "./ClubPage/CustomClubPage";

export const UI2DashboardClub = () => {
  const { userData } = useGlobalContext();
  const instituteId =
    userData.role === "institute" ? userData?.id : userData?.instituteId;
  let role = userData?.role;
  const fileInputRef = useRef(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState("");
  const [clubsData, setClubsData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = React.useState("1");
  const [instituteData, setInstituteData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [info, setInfo] = useState("");
  const [rules, setRules] = useState([]);

  const addRule = () => {
    setRules([...rules, { rulesTitle: "", rulesPara: "" }]);
  };

  const removeRule = (indexToRemove) => {
    setRules(rules.filter((rule, index) => index !== indexToRemove));
  };
  const handleTitleChange = (index, event) => {
    const newRules = [...rules];
    newRules[index].rulesTitle = event.target.value;
    setRules(newRules);
  };

  const handleDescriptionChange = (index, event) => {
    const newRules = [...rules];
    newRules[index].rulesPara = event.target.value;
    setRules(newRules);
  };

  const alertForEdit = () => {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Go To Club",
      denyButtonText: `Edit`,
      cancelButtonText: "Delete",
      cancelButtonColor: "#dc3545", // Optional: Customize button color
      denyButtonClass: "btn-jump", // Optional: Customize button class
      denyButtonColor: "#17a2b8", // Optional: Customize button color
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("Saved!", "", "success");
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle the delete action
        Swal.fire("Deleted!", "", "error");
      } else if (result.dismiss === Swal.DismissReason.deny) {
        // Handle the jump action
        Swal.fire("Jumping!", "", "warning");
      }
    });
  };

  const mergedArray = clubsData?.concat(instituteData);

  const handleHide = () => {
    setShowModal(false);
    resetClubForm();
    // alertForEdit()
  };
  const handleShow = () => {
    setShowModal(true);
  };
  const handleButtonClick = () => {
    setValue("1");
    fileInputRef.current.click();
  };

  const getPublicClubs = async () => {
    try {
      const clubs = await apiJsonAuth.get(`/club/public/`);
      if (clubs.status === 200) {
        const publicClubs = clubs?.data;
        setClubsData(publicClubs);
      }
    } catch (error) {
      console.log(error, "error in public");
    }
  };
  const getInstituteClubs = async () => {
    try {
      // console.log("private club342==>")

      const InsClub = await apiJsonAuth(
        `/club/private/institute/${instituteId}`
      );
      if (InsClub.status === 200) {
        const instituteClubs = InsClub?.data;
        setInstituteData(instituteClubs);
      }
    } catch (error) {
      console.log("Error In Institute Clubs", error);
    }
  };
  useEffect(() => {
    getPublicClubs();
  }, []);
  useEffect(() => {
    // console.log("private club==>")
    if (instituteId) {
      // console.log("private club2==>")

      getInstituteClubs();
    }
  }, [instituteId]);

  const resetClubForm = () => {
    setName("");
    setDescription("");
    setImage("");
    setSelectedImage(null);
    setShowModal(false);
    setRules([]);
    setInfo("");
  };

  const handleSubmit = () => {
    if (!name || !description) {
      toast.dismiss();
      toast.error("Fields are require");
    } else {
      let type;
      let instituteId;
      if (userData.role === "institute") {
        instituteId = userData.id;
        type = "private";
      } else if (userData.role === "admin") {
        type = "public";
      }
      let data = {
        name,
        description,
        type,
        instituteId,
        rules: {
          title: `Rules for ${name}`,
          rulesArray: rules,
        },
        info,
      };
      if (userData.role === "institute" || userData.role === "admin") {
        apiAuth
          .post(process.env.REACT_APP_API_BASE_URL + "club", {
            ...data,
            img: image,
          })
          .then((res) => {
            toast.success("succesfully created");
            getInstituteClubs();
            handleHide();
            resetClubForm();
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
  };

  return (
    <>
      <div className="px-4">
        {/* <div className="d-flex justify-content-between align-items-center px-3">
          <h3 className="fs-1 fw-semibold ">Clubs</h3>
          <div>
            {userData?.role === "institute" && (
              <button
                className="rounded-3 shadow btn btn-outline-success me-3"
                onClick={handleShow}
              >
                Add Club
              </button>
            )}

            {mergedArray?.length > 4 && (
              <button
                className="btn btn-outline-primary rounded-3 shadow-lg"
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? "Show Less" : "Show all"}
              </button>
            )}
          </div>
        </div> */}
        {/* <div className="w-100 px-3 py-3">
          {mergedArray.length === 0 && (
            <span className="text-center">No Club To Show</span>
          )}

          <div className="row">
            {(showAll ? mergedArray : mergedArray.slice(0, 4))?.map((club) => {
              return (
                <>
                  <UI2Feature1 data={club} key={club?.id} getInstituteClubs={getInstituteClubs} />
                </>
              );
            })}
          </div>
          
        </div> */}
        <CustomClubPage />
      </div>
      <Modal
        scrollable
        className="thin-scroll"
        show={showModal}
        onHide={handleHide}
        size="lg"
      >
        <Modal.Title
          className=" fw-semibold "
          style={{ padding: "12px", fontSize: "25px", fontFamily: "Poppins" }}
        >
          Add New Club
        </Modal.Title>
        <Modal.Body className="thin-scroll">
          <div>
            <div className="row">
              <div label="Name" className="mb-3 col-12 col-md-6">
                {/* <Form.Control className="p-3 rounded fw-500 box-shadow-0 resize-none border-0" style={{ background: "rgb(239, 239, 239)", fontSize: "16px" }} value={name} type="text" placeholder="Club Name" onChange={(e) => setName(e.target.value)} /> */}
                <TextField
                  label="Club Name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-100"
                />
              </div>

              <div className="mb-3 col-12 col-md-6" label="Description">
                {/* <Form.Control className="p-3 rounded fw-500 box-shadow-0 resize-none border-0" style={{ background: "rgb(239, 239, 239)", fontSize: "16px" }} value={description} type="text" placeholder="Description" onChange={(e) => setDescription(e.target.value)} /> */}
                <TextField
                  label="Description"
                  variant="outlined"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-100"
                />
              </div>
              <div className="mb-3 col-12 " label="info">
                <label htmlFor="Info" className="fw-bold fs-6">
                  Info
                </label>
                <MyCKEditor content={info} setContent={setInfo} />
              </div>
              <div className="col-12 mb-4">
                <div className="d-flex align-items-center justify-content-start m-2 ms-2">
                  <input
                    type="file"
                    id="img"
                    style={{ display: "none", background: "#da9eff" }}
                    accept=".png, .jpg, .jpeg"
                    name="files[]"
                    ref={fileInputRef}
                    onChange={(e) => {
                      if (e.target.files.length) {
                        setImage(e.target.files[0]);
                        setSelectedImage(
                          URL.createObjectURL(e.target.files[0])
                        );
                      }
                    }}
                  />
                  <label htmlFor="img">
                    <button
                      style={{
                        background: "#da9eff",
                        color: "white",
                        borderRadius: "5px",
                      }}
                      className="text-capitalize font-weight-bold btn-sm"
                      onClick={handleButtonClick}
                    >
                      <CameraAltIcon sx={{ color: "#6100ff" }} /> &nbsp;Media
                    </button>
                  </label>
                </div>
              </div>
              <div className="col-12 col-md-6 text-center mb-4">
                {selectedImage && (
                  <div
                    className=""
                    style={{ height: "200px", border: "1px solid lightGrey" }}
                  >
                    <img
                      src={selectedImage}
                      alt="Selected"
                      style={{ width: "80%", objectFit: "contain" }}
                      className="h-100"
                    />
                  </div>
                )}
              </div>

              <div className=" d-flex align-items-center mt-3 mb-3">
                <h1 className="fs-3 fw-semibold m-0 ">Add Rules</h1>
                <IconButton onClick={addRule} className="ms-2  cursor-pointer">
                  <ControlPointOutlinedIcon className="fs-3 text-success" />
                </IconButton>
              </div>
              {rules?.map((rule, index) => (
                <div
                  key={index}
                  className="py-2 rounded-3 mb-3"
                  style={{ border: "1px solid lightGrey" }}
                >
                  <div className="mb-3 col-12 col-md-8" label="Description">
                    <div className="d-flex align-items-center">
                      <TextField
                        label="Rule Title"
                        variant="outlined"
                        value={rule?.rulesTitle}
                        onChange={(e) => handleTitleChange(index, e)}
                        className="w-100"
                      />
                      <IconButton
                        className="ms-2 cursor-pointer"
                        onClick={() => removeRule(index)}
                      >
                        <RemoveCircleOutlineOutlinedIcon className="fs-3 text-danger" />
                      </IconButton>
                    </div>
                  </div>
                  <div className="mb-3 col-12" label="Description">
                    <TextField
                      label="Rule Description"
                      variant="outlined"
                      value={rule?.rulesPara}
                      onChange={(e) => handleDescriptionChange(index, e)}
                      className="w-100"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between ">
          <button
            onClick={handleHide} handleSubTopicClick
            className="btn btn-outline-danger  rounded-3"
          >
            Close
          </button>
          <button
            className="btn btn-outline-primary  rounded-3"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
