import React, { useState, useEffect} from "react";

const CountDownTimer = ({date, time, sendDataToParent}) => {
    let month = new Date(date).getMonth() + 1;
    let eventdate = new Date(date).getDate();
    let year = new Date(date).getFullYear();
    // Set the date we're counting down to
    
    let [day, setDay] = useState()
    let [hour, setHour] = useState()
    let [minute, setMinute] = useState()
    let [second, setSecond] = useState()
    let [distance, setDistance] = useState(true)
    // Update the count down every 1 second
  useEffect(() => {
  var countDownDate = new Date(`${month} ${eventdate}, ${year} ${time}:00`).getTime();
  var timer = setInterval(()=>{
  // Get today's date and time
  var now = new Date().getTime();
  // Find the distance between now and the count down date
  var distance = countDownDate - now;

    // If the count down is over, write some text 
    if (distance <= 0) {
      setDistance(false)
     
      sendDataToParent(false);
    }

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  setDay(days)
  setHour(hours)
  setMinute(minutes)
  setSecond(seconds)

}, 1000);
return () => clearInterval(timer);
}, [date, time])
useEffect(()=>{
  sendDataToParent(distance)
},[distance,date, time])
  return (
    <div className="m-0 p-0">
      <div id="demo" style={{height:"31px"}} className="mt-2 mb-0 p-0 d-flex justify-content-between align-items-center" >

        {
          distance? (
            <>
            <p style={{fontSize:'23px', color:"#6A4D01"}}>{day}D</p>
            <p style={{fontSize:'23px', color:"#6A4D01"}}>{hour}H</p>
            <p style={{fontSize:'23px', color:"#6A4D01"}}>{minute}M</p>
            <p style={{fontSize:'23px', color:"#6A4D01"}}>{second}S</p>
            </>
          ) : ( <div className="d-flex justify-content-between w-100">
            <p className="text-center p-0 m-0 w-100 text-danger">EXPIRED</p>
          </div>  )
        }
       
      </div>
    </div>
  );
};

export default CountDownTimer;
