import { Button } from '@mui/material'
import { apiJson } from 'api'
import { useGlobalContext } from 'global/context'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

const RiasecBanner = () => {
    const navigate = useNavigate()
    const {id} = useParams()
    const {userData} = useGlobalContext()
    const [userAttempt, setUserAttempt] = useState()
    const [showRiasecModal, setShowRiasecModal] = useState(false)
    const hideRiasecModal =()=>{
        setShowRiasecModal(false)
    }
   
    const getRiasecTest = ()=>{
        apiJson(`api/v2/riasecTest/getAttempt/${userData?.id}/${userData?.role}`)
        .then((res)=>{
            setUserAttempt(res.data.result)
            setShowRiasecModal(!res.data.result)
        }).catch((error)=>{
            console.log(error.message)
        })
    }
    useEffect(()=>{
        if(id == 6){
            getRiasecTest()
        }
    },[])


  return (
    <>
            <div className="row shadow rounded-4 mt-4">
        <div className="col-12 col-lg-5 d-flex overflow-hidden flex-column justify-content-center align-items-center">
            <img src="/modals/5.png" alt="sliderimg" width="100%" style={{transform:"scale(1.7)"}} />
            <p className="text-primary mt-3">Explore the innovator / creator in you </p>
        </div>

        <div className='col-12 col-lg-7 py-5 px-3'>
            <div className='mb-4'>
                <span className='fs-6 fw-semibold'>Test your personality and career acumen with the </span>
                <h1 className='fs-3 fw-semibold text-primary mt-4'>Yuvamanthan's Dynamic Career Assessment Tool</h1>
            </div>
            <div className='mb-4'>
                <span className='fs-6 fw-semibold'>By answering some question we can suggest you the best career paths to take in the future.</span>
            </div>

            <div>
                <span className='fs-6 fw-semibold' >Find out if you are:</span>
                <div className=' '>
                        <h1 className='fs-6 fw-semibold'>REALISTIC INVESTIGATIVE ARTISTIC SOCIAL  ENTERPRISING CONVENTIONAL</h1>
                </div>
            </div>
            <div className='d-flex justify-content-center w-100'>
                <Button onClick={()=>navigate('/new-dashboard/riasec')} className='fs-4 fw-bolder w-100 rounded-5' color='primary' variant='contained'>{userAttempt?'Retake Test':'TAKE THE TEST'}</Button>
            </div>
        </div>
    </div>


        <Modal size='lg'  className='rounded-5' show={showRiasecModal} onHide={hideRiasecModal}>
            <Modal.Body className='rounded-5'>
            <div className="row  rounded-5">
        <div className="col-12 col-lg-5 overflow-hidden d-flex flex-column justify-content-center align-items-center">
            <img src="/modals/5.png" alt="sliderimg" width="100%" style={{transform:"scale(1.7)"}} />
            <p className="text-primary mt-3">Explore the innovator / creator in you </p>
        </div>

        <div className='col-12 col-lg-7 py-5 px-3'>
            <div className='mb-4'>
                <span className='fs-6 fw-semibold'>Test your personality and career acumen with the </span>
                <h1 className='fs-3 fw-semibold text-primary mt-4'>Yuvamanthan's Dynamic Career Assessment Tool</h1>
            </div>
            <div className='mb-4'>
                <span className='fs-6 fw-semibold'>By answering some question we can suggest you the best career paths to take in the future.</span>
            </div>

            <div>
                <span className='fs-6 fw-semibold' >Find out if you are:</span>
                <div className=' '>
                        <h1 className='fs-6 fw-semibold'>REALISTIC INVESTIGATIVE ARTISTIC SOCIAL  ENTERPRISING CONVENTIONAL</h1>
                </div>
            </div>
            <div className='d-flex justify-content-center w-100'>
                <Button onClick={()=>navigate('/new-dashboard/riasec')} className='fs-4 fw-bolder w-100 rounded-5' color='primary' variant='contained'>{userAttempt?'Retake Test':'TAKE THE TEST'}</Button>
            </div>
        </div>
    </div>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default RiasecBanner