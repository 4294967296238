import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { api, apiJson, apiJsonAuth } from "api";
import {toast} from "react-toastify"
import { Card, CardBody, Modal } from "react-bootstrap";
import { Button, Divider } from '@mui/joy';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {Link} from 'react-router-dom'
import { FilePresentTwoTone } from "@mui/icons-material";
import * as XLSX from 'xlsx';
import DeleteIcon from '@mui/icons-material/Delete';

export const AdminDataProvider = () => {
  const [showAssign, setShowAssign] = useState(false);
  const [showUpdateAssign, setShowUpdateAssign] = useState(false);
  const [showDeleteAssign, setShowDeleteAssign] = useState(false);
  const [assignData, setAssignData] = useState([]);
  const [getAllassignData, setGetAllAssignData] = useState([]);
  const [condtionModal, setCondtionModal] = useState("");
  const [updateId, setUpdateId] = useState(null);
  const [showDelteChallenge, setShowDelteChallenge] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [allState, setAllStates] = useState([])
  const [districtData, setDistrictData] = useState([]);
  const [selectDate, setSelectDate] = useState({ startDate: "", endDate: "" });
  const [dateByCall , setDateByCall] = useState('submit')


  const navigate = useNavigate();

  const animatedComponents = makeAnimated();

  // formik for projects
  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    formik.setFieldValue(
      "projects",
      formik?.values?.projects === name ? "" : name
    );
  };

  const handleChangeSubAdmin = (selectedOptions) => {
    const slectData = selectedOptions?.map((ele) => ele?.id);
    console.log("Selected Data (slectData):", slectData);

    // Log the getAllassignData to inspect its content
    console.log("All Assigned Data (getAllassignData):", getAllassignData);

    // Extract all subAdmin_Id from getAllassignData
    const assignedIds = getAllassignData.map(data => data?.subAdmin_Id);
    console.log('assignedIds-----', assignedIds)

 // Check if any of the selected ids are already assigned
 const alreadyAssigned = slectData.filter(id => assignedIds.includes(id));
 console.log("alreadyAssigned", alreadyAssigned)
 if (alreadyAssigned.length > 0) {
     alert(`This users are already assigned`);
 }

 // Update form field value with all selected IDs
 formik.setFieldValue('subAdmin_Id', slectData);
  
    // Check if all selected ids are already assigned
    // const allAssigned = slectData.every(id => assignedIds.includes(id));
  
    // if (allAssigned) {
    //   alert("All selected users are already assigned.");
    // } else {
    //   formik.setFieldValue('subAdmin_Id', slectData);
    // }
  
 }

  //  const handleChangeSubAdmin = (selectedOptions) => {
  //    const slectData = selectedOptions?.map((ele) => ele?.id);
  //   console.log("Selected Data (slectData):", slectData);

  //   // Log the getAllassignData to inspect its content
  //   console.log("All Assigned Data (getAllassignData):", getAllassignData);

  //   const alreadyAssigned = getAllassignData.some(option => {
  //     console.log("Checking if", option?.subAdmin_Id, "is in", slectData);
  //     return slectData.includes(option?.subAdmin_Id);
  //   });

  //   console.log("Already Assigned:", alreadyAssigned);

  //   if (alreadyAssigned) {
  //     alert("This user is already assigned.");
  //     return;
  //   }

  //   formik.setFieldValue('subAdmin_Id', slectData);
  // }

  const [currentPage, setCurrentPage] = useState(1);

  const goToPreviousPage = () => {
    setCurrentPage(1);
  };

  const goToNextPage = () => {
    setCurrentPage(2);
  };

  const validationSchema = yup.object().shape({
    state: yup.string().required("State is required"),
    district: yup.string(),
    projects: yup.string().required("Project is required"),
    subAdmin_Id: yup.number().required("Subadmin is required"),
  });

  const formik = useFormik({
    initialValues: {
      subAdmin_Id: [],
      state: "",
      district: "",
      projects: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }, action) => {
      console.log(values);
      const data = {
        subAdmin_Id: values?.subAdmin_Id,
        state: values?.state,
        district: values?.district,
        projects: values?.projects,
      };
      if (condtionModal === "Add") {
        // AddChallenges(values);
        await apiJson
          .post("api/v2/admin/adminCreateAssign", data)
          .then((res) => {
            getAllAsignData();
            // console.log(res.data);
          })
          .catch((error) => {
            console.log(error.message);
          });
      } else if (condtionModal === "Edit") {
        // updateChallenges();
      }
      resetForm();
    },
  });

  // Get subAdmin name
  const getSubAdminData = async () => {
    await apiJson
      .get("api/v2/admin/getSubadminData")
      .then((res) => {
        setAssignData(res?.data?.getSubAdmin);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // state data
  const getAllState = async ()=>{
    await apiJson.get('api/v2/admin/getAllStateName')
    .then((res)=>{
      console.log(res.data.states)
      setAllStates(res?.data?.states);
    })
    .catch((err)=>{
      console.log(err.message);
    })

  }

  // district data
  const getAllDistrcitData = async ()=>{
    const state = formik.values.state;
    // console.log(state)
    await apiJson.get(`api/v2/admin/getAllDistrictName/${state}`)
    .then((res)=>{
      setDistrictData(res?.data?.district);
    })
    .catch((error)=>{
      console.log(error.message);
    })
  }

  // get all subadmin data
  const getAllAsignData = async () => {
    apiJson("api/v2/admin/getAllAssignData")
      .then((res) => {
        setGetAllAssignData(res?.data?.allAssignData);
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    getSubAdminData();
    getAllAsignData();
    getAllState();
  },[])

  useEffect(()=>{
    getAllDistrcitData()
  },[formik?.values?.state])

  const handleShowAssign = () => {
    setCondtionModal("Add");
    setShowAssign(true);
  };
  const handleCloseAssign = () => {
    setShowAssign(false);
    formik.setErrors({});
    formik.handleReset();
  };


  const handleDateSelect = (name, value) => {
    setSelectDate(prevState =>
        ({ ...prevState, [name]: value }));
}

  const handleGetCallData = async ()=>{
    await apiJson.get(`api/v2/admin/getCallAsPerDayData/${selectDate?.startDate}/${selectDate?.endDate}/${dateByCall}`)
    .then((res)=>{
      setDateByCall('download')
    })
    .catch((err)=>{
      console.log(err.message);
    })
  }

  const downloadData = async () => {
    await apiJson(`api/v2/admin/getCallAsPerDayData/${selectDate?.startDate}/${selectDate?.endDate}/${dateByCall}`)
        .then((res) => {
            const allData = res?.data?.getStatus;
            setSelectDate({ startDate: "", endDate: "" })
            setDateByCall('submit')

            // Export data to Excel
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(allData);
            XLSX.utils.book_append_sheet(wb, ws, "Call Data");
            XLSX.writeFile(wb, "Call_data.xlsx");
        })
        .catch((err) => {
            console.log(err);
        });
}


// deleteAssignData
const deleteAssignedData = async ()=>{
  await apiJson.delete(`api/v2/admin/deleteAssignData/${deleteId}`)
  .then((res)=>{
  setShowDelteChallenge(false);
  getAllAsignData();
  })
  .catch((err)=>{
    console.log(err.message);
  })
}

const handleShowDeleteModal = (id) => {
  setDeleteId(id);
  setShowDelteChallenge(true);
};

const handleCloseDeleteModal = () => {
  setShowDelteChallenge(false);
};
 
  return (
    <div>
      <div className="col-10 mx-auto">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <span className="fs-3 fw-500 d-flex align-items-center">
              CBSE Data Transfer{" "}
              <Button
                className="py-0 px-1"
                onClick={() => {
                  navigate("/admin/cbse-data");
                }}
              >
                Previous
              </Button>
            </span>
          </div>
          <div>
            <Button
              onClick={handleShowAssign}
              style={{ background: "#da9eff" }}
            >
              Add Data
            </Button>
          </div>
        </div>

        <div><span>All Call Data</span></div>
        <div className='row  mb-2'>
                    <div className='col-12 col-md-6 col-lg-3'>
                    <input type="date" className='form-control box-shadow-0 rounded background-grey border-1 mb-2' style={{height:45}}
                        onChange={(e) => handleDateSelect('startDate', e.target.value)} value={selectDate?.startDate} name="startDate" id=""  /></div>
                    <div className='col-12 col-md-6 col-lg-3'>
                    <input type="date" className='form-control box-shadow-0 rounded background-grey border-1 mb-2' style={{height:45}}
                        onChange={(e) => handleDateSelect('endDate', e.target.value)} value={selectDate?.endDate} name="endDate" id="" /></div>
                    <div className='col-12 col-md-12 col-lg-3 d-flex align-items-center  justify-content-between mb-2'>
                        {/* <Button onClick={() => resetFunctionData()} variant='outlined' color='danger' className='w-50 me-2' sx={{height:45}}>Reset</Button> */}
                       <Button onClick={handleGetCallData} className='w-50' sx={{height:45}}>Submit</Button>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3 mt-3 mt-md-0'>
                        <Button type="button" variant="outlined" color="success" style={{ border: "1px solid #8fcdff",height:45 }} data-bs-toggle="" data-bs-target="#staticBackdrop" onClick={downloadData} className="text-capitalize  rounded-3 px-4 w-100">
                            <FilePresentTwoTone />
                            Download&nbsp;Sheet
                        </Button>
                    </div>
                </div>


        <div className="mt-5 mb-2">
                <TableContainer component={Paper} >
                    <Table aria-label="simple table">
                        <TableHead sx={{background:"#9700de"}}>
                            <TableRow>
                                <TableCell className='fw-semibold' align='center'  sx={{color:"#ffffff"}}>S.No</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>State</TableCell>
                                {/* <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>District</TableCell> */}
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>Projects</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>Sub Admin</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>View</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ padding: "5px 10px" }}>
                            {getAllassignData?.map((row,i) => (
                                <TableRow
                                    key={row?.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, marginTop: "5px" }}
                                >
                                <TableCell align='center'>{i + 1}</TableCell>
                                    <TableCell align="center"><span className=''>{row?.state}</span></TableCell>
                                    {/* <TableCell align="center">{row?.district}</TableCell> */}
                                    <TableCell align='center'>{row?.projects}</TableCell>
                                    <TableCell align='center'>{row?.subAdmin}</TableCell>
                                    <TableCell align='center' onClick={()=> {navigate(`/admin/callingTeamstatus/${row?.subAdmin_Id}`)}}><span className=''><Link>View Calls</Link></span></TableCell>
                                    <TableCell align='center' className={`text-capitalize fw-bolder`} onClick={() => handleShowDeleteModal(row?.id)}><DeleteIcon style={{color: '#6a03dd', cursor: 'pointer'}}/></TableCell>
                                    {/* <TableCell align='center'>{row?.address}</TableCell>
        <div className="mt-5 mb-2">
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead sx={{ background: "#9700de" }}>
                <TableRow>
                  <TableCell
                    className="fw-semibold"
                    align="center"
                    sx={{ color: "#ffffff" }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    className="fw-semibold"
                    align="center"
                    sx={{ color: "#ffffff" }}
                  >
                    State
                  </TableCell>
                  <TableCell
                    className="fw-semibold"
                    align="center"
                    sx={{ color: "#ffffff" }}
                  >
                    District
                  </TableCell>
                  <TableCell
                    className="fw-semibold"
                    align="center"
                    sx={{ color: "#ffffff" }}
                  >
                    Projects
                  </TableCell>
                  <TableCell
                    className="fw-semibold"
                    align="center"
                    sx={{ color: "#ffffff" }}
                  >
                    Sub Admin
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ padding: "5px 10px" }}>
                {getAllassignData?.map((row, i) => (
                  <TableRow
                    key={row?.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      marginTop: "5px",
                    }}
                  >
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell align="center">
                      <span className="">{row?.state}</span>
                    </TableCell>
                    <TableCell align="center">{row?.district}</TableCell>
                    <TableCell align="center">{row?.projects}</TableCell>
                    <TableCell align="center">
                      {row?.subAdmin}
                    </TableCell>
                    {/* <TableCell align='center'>{row?.address}</TableCell>
                                    <TableCell align='center' onClick={()=>deleteCbseDetail(row?.id)}><Button>Delete</Button></TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Divider className="my-3" />
      </div>

      <Modal show={showAssign} onHide={handleCloseAssign}>
        <Modal.Header closeButton>
          <Modal.Title>Data Assign</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <div className="p-2">
              <form onSubmit={formik.handleSubmit}>
              <div className="mt-3">

              <div className="mt-3">
                      <span className="d-block">Subadmin</span>
                      {/* <select onChange={(e) => {
                        formik.setFieldValue("subAdmin_Id", e.target.value)
                      }} class="form-select form-control rounded-3" aria-label="Default select example">
                        <option selected>Select Subadmin</option>
                        {
                          assignData?.map((type) => {
                            return <option value={type.id}>{type?.full_name}</option>
                          })
                        }
                      </select> */}

                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        onChange={handleChangeSubAdmin}

                        // defaultValue={assignData[0]}
                        isMulti
                        options={assignData}
                        getOptionLabel={(option) => option.full_name}
                      />
                      {formik.errors.subAdmin_Id && formik.touched.subAdmin_Id ? (
                        <span className="text-danger">{formik.errors.subAdmin_Id}</span>
                      ) : null}
                    </div>

                      {/* Projects */}
                    <div className="mt-3">
                      <span className="d-block">Projects</span>
                      <div className='d-flex gap-2'>
                    <span className="d-block ">Nipam</span>
                    <input
                      type="checkbox"
                      // className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                      // style={{
                      //   color: "black",
                      //   background: "rgb(239, 239, 239)",
                      //   fontSize: "16px",
                      //   height: "40px",
                      // }}
                      value={formik.values.projects}
                      onChange={handleCheckboxChange}
                      checked={formik?.values?.projects === 'Nipam'}
                      name="Nipam"
                    />
                    {/* {formik.errors.projects && formik.touched.projects ? (
                      <span className="text-danger">
                        {formik.errors.projects}
                      </span>
                    ) : null} */}
                      <div className='d-flex gap-2'>
                    <span className="d-block">Ymun</span>
                    <input
                      type="checkbox"
                      // className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                      // style={{
                      //   color: "black",
                      //   background: "rgb(239, 239, 239)",
                      //   fontSize: "16px",
                      //   height: "40px",
                      // }}
                      value={formik.values.projects}
                      onChange={handleCheckboxChange}
                      checked={formik?.values?.projects === 'Ymun'}
                      name="Ymun"
                    />
                    {/* {formik.errors.projects && formik.touched.projects ? (
                      <span className="text-danger">
                        {formik.errors.projects}
                      </span>
                    ) : null} */}
                  </div>
                      <div className='d-flex gap-2'>
                    <span className="d-block">Safe in School</span>
                    <input
                      type="checkbox"
                      // className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                      // style={{
                      //   color: "black",
                      //   background: "rgb(239, 239, 239)",
                      //   fontSize: "16px",
                      //   height: "40px",
                      // }}
                      value={formik.values.projects}
                      onChange={handleCheckboxChange}
                      checked={formik?.values?.projects === 'Safe in School'}
                      name="Safe in School"
                    />
                  </div>
                  </div>
                    {formik.errors.projects && formik.touched.projects ? (
                      <span className="text-danger">
                        {formik.errors.projects}
                      </span>
                    ) : null}
                    </div>

                      <span className="d-block mt-2">State</span>
                      <select onChange={(e) => {
                        formik.setFieldValue("state", e.target.value)
                      }} class="form-select form-control rounded-3" aria-label="Default select example">
                        <option selected>Select State</option>
                        {
                          allState?.map((type) => {
                            return <option value={type.state}>{type.state}</option>
                          })
                        }
                      </select>
                      {formik.errors.state && formik.touched.state ? (
                        <span className="text-danger">{formik.errors.state}</span>
                      ) : null}
                    </div>

                    {/* <div className="mt-3">
                      <span className="d-block">District</span>
                      <select onChange={(e) => {
                        formik.setFieldValue("district", e.target.value)
                      }} class="form-select form-control rounded-3" aria-label="Default select example">
                        <option selected>Select District</option>
                        {
                          districtData?.map((type) => {
                            return <option value={type.district}>{type.district}</option>
                          })
                        }
                      </select> */}
                      {/* {formik.errors.district && formik.touched.district ? (
                        <span className="text-danger">{formik.errors.district}</span>
                      ) : null} */}
                    {/* </div> */}

                   

                <div className="mt-3 d-flex justify-content-end">
                  <Button
                    type="submit"
                    size="lg"
                    value="Add"
                    className="text-capitalize px-3"
                    style={{ background: "#0808ff", color: "white", fontSize: "16px" }}
                    disabled={isLoading}                  >
                    {/* {isLoading ? }Add Challenge */}
                    {isLoading ?
                      <>
                        <div className="px-3">
                          <div className="spinner-border text-light mx-auto d-flex" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        <div className="px-1">
                          <span>Assign Data</span>
                        </div>
                      </>
                    }
                  </Button>
                </div>
              </form>
            </div>
      </Modal.Body>
      </Modal>

        <Modal show={showDelteChallenge} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Challenges</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <span> Are you sure want to delete?</span>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex gap-2">
            <div>
              <Button
                size="lg"
                // style={{ color: "grey" }}
                className="text-capitalize"
                onClick={handleCloseDeleteModal}
              >
                Discard
              </Button>
            </div>

            <div>
              <Button
                size="lg"
                className="text-capitalize"
                style={{ background: "#0808ff", color: "white" }}
                onClick={deleteAssignedData}
                disabled={isLoading}
              >
                {isLoading ?
                  <>
                    <div className="px-3">
                      <div className="spinner-border text-light mx-auto d-flex" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="px-1">
                      <span>Delete</span>
                    </div>
                  </>
                }
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

// const handleShowUpdateAssign = (id) => {
//   setCondtionModal("Edit");
//   setUpdateId(id);

//   // const selectedChallenge = challenge?.find((ele) => ele?.id === id)
//   // setEditFormValues(selectedChallenge)
//   // setShowUpdateChallenge(true);
// };

// const handleCloseUpdateAssign = () => {
//   setShowUpdateAssign(false);
//   formik.setErrors({});
//   formik.handleReset();
// };

// const handleShowDeleteModal = (id) => {
//   setDeleteId(id);
//   setShowDeleteAssign(true);
// };

// const handleCloseDeleteModal = () => {
//   setShowDeleteAssign(false);
// };
