import React, { useEffect, useState } from "react";
import "../Css/challengeday.css";
import groupimg from "../assests/Group 381.svg";
import uploadimg from "../assests/Upload to the Cloud.svg";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import seventyfiveimg from "../assests/seventyfive.png"
import { apiJson, apiAuth } from "api";
import { useGlobalContext } from "global/context";
import { toast } from "react-toastify";
import SeventyCreditPoints from "./SeventyCreditPoints";
import { ThreeDots } from "react-loader-spinner";

const SeventyFiveTodayChallenComponent = () => {
  const [taskId, setTaskId] = useState();
  const [imageLink, setImageLink] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [todayData, setTodayData] = useState([])
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [experience, setExperience] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const [taskCompletedStatus, setTaskCompletedStatus] = useState();
  const [uploadedExperience, setUploadedExperience] = useState();
  const { userData } = useGlobalContext();
  const [credit, setCredit] = useState();
  const [dayCount, setDayCount] = useState()
  const [uploadedImg, setUploadedImg] = useState();
  const [loader, setLoader] = useState(false)


  const getTodaysData = async ()=>{
    try{
      setLoader(true)
      const response = await apiJson(`api/v2/75daysChallenge/pastChallengeDetail/${params?.attemptId}/task/${params?.taskid}`);
      setTodayData([response?.data?.result]);
      setTaskId(response?.data?.result?.id);
      setUploadedExperience(response?.data?.result?.experience);
      setCredit(response?.data?.result?.credit);
      setDayCount(response?.data?.result?.day_count);
      setTaskCompletedStatus(response?.data?.result?.status);
      setUploadedImg(response?.data?.result?.images);
      setLoader(false)
    }catch(err){
      console.log(err.message)
    }
  }

  const upload = (e) => {
    setUploadedFileName(e.target.files[0].name);
    const file = e.target.files[0];

    if (file) {
      // Create a URL for the image preview
      const objectURL = URL.createObjectURL(file);
      setImagePreview(objectURL);
    }
  };

  const getImageLink = (e) => {
    const file = e.target.files[0];
    apiAuth
      .post("v2/register/uploadInstituteLogo", { img: file })
      .then((res) => {
        setImageLink(res.data.result);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error("Internal Server Error");
      });
  };


  const submit = async (e) => {
    e.preventDefault();

    if(!experience || !imageLink){
      toast.warning("All fields are Required" ,{
        position:toast.POSITION.TOP_RIGHT
      })
    }else{

    const payload = {
      userId: userData.id,
      role: userData.role,
      instituteId: userData.instituteId,
      taskId: taskId,
      attemptId: params.attemptId,
      status: "Completed",
      experience: experience,
      credit_earned: credit,
      images: imageLink,
    };
    try {
      const response = await apiJson.post("api/v2/75daysChallenge/submitChallenge", payload);
      console.log(response)
      {
        dayCount === 75 ? 
        navigate(`/steps/seventy-five-steps/today-steps/${params.attemptId}/todaytask/Congress`)
        : navigate(`/steps/seventy-five-steps/today-steps/${params.attemptId}`);
      }
      
    } catch (err) {
      console.log(err.message);
    }
  }
  };
  useEffect(()=>{
    getTodaysData()
  },[])

  return (
    <>
      <div className="challengeDays p-3">
        <div className="challengehead position-relative d-flex justify-content-between align-items-center">
          <img
            src={seventyfiveimg}
            alt="environment"
            className="sm-w-30"
          />
          <div className="d-flex align-items-center gap-2"> 
          <span className="backArrow" onClick={()=>navigate(`/steps/seventy-five-steps/today-steps/${params?. attemptId}`)}> <ArrowBackIosIcon sx= {{color:"#7700FF", marginLeft:'0.5rem'}} onClick={() =>navigate("/gameday")}/> 
          </span>
          <h4 className="my-3 sm-fs-10px sm-w-50 text-center">Today's challenge</h4>
          </div>

          <SeventyCreditPoints />
        </div>
        {
          loader? <div className="d-flex justify-content-center align-items-center"><ThreeDots/></div>  :   taskCompletedStatus === "Completed" ? (
            <div className="d-flex flex-column align-items-center">
          <p className="text-success text-center h4 my-3 " style={{textShadow:"1px 1px 30px green"}}>Congratulation's Your Today Task Is Completed</p>
            <img src={uploadedImg} alt="" width="50%" className="rounded-3 shadow-lg" />
            <p className="text-capitalize my-3">{uploadedExperience}</p>
          </div>
          ):(
            <>
            <div className="todayChallenge">

<div className="challnDays d-flex justify-content-between align-items-center">
{
  loader? <div className="d-flex justify-content-between align-items-center">
    <ThreeDots/>
  </div> :todayData?.map((val) => {
      return (
        <>
          <div className="dayVal text-white"> Step {val?.day_count}</div>
          <div className="daypara ps-3">
          <div className="align-align-self-lg-stretch">
            <p>{val?.status? 'Completed' : 'InCompleted'}</p>
            <p>{val?.description}</p>
          </div>
          <div className="daycredits">
            <p>{val?.credit ? val?.credit : val?.credit_earned} Credit</p>
          </div>
          </div>
        </>
      );
  })
}
</div>
</div>

<div className="pastChallenge">
<div className="upload mt-5 text-center position-relative">
  <img src={uploadimg} alt="uploadimg" />
    <input type="file" name="file" id="file" onChange={(e) => {
          upload(e);
          getImageLink(e);
        }} />
  <p>
    Drag file here or click to <span>{selectedFile?selectedFile:"Select file to upload"}</span> proof of activity
  </p>
  {
    imagePreview?
  <img src={imagePreview} alt="uploadedImg" width="100%" height="300px" style={{objectFit:"cover", objectPosition:"center"}} />:null
  }
</div>

<div className="experience">
  <p className="mt-5">Share your experience</p>

  <input type="text" placeholder=" Eg - I am very happy to share that i have used stairs instead of
    an elevator, it was tough using stairs all the day, but have loose
    my wait too. So its not only eco friendly activity but healthy
    activity."
    onChange={(e) => setExperience(e.target.value)}
     />


  <div className="d-flex justify-content-between expbtns">
    <button className="border-0" onClick={()=>navigate('/steps/seventy-five-steps')}>Later</button>
    <button className="border-0" onClick={submit}>Submit</button>
  </div>
</div>
</div>
            </>
          )
        }
       
      </div>
    </>
  );
};

export default SeventyFiveTodayChallenComponent;
