import React, { useEffect, useState } from "react";
import "../Css/home.css";
import "../Css/challengeday.css";
import "../Css/congress.css";
import groupimg from "../assests/Group 381.svg";
import confitiBall from "../assests/Confetti Ball.svg";
import environGroup from "../assests/seventyfive.png";
import { useNavigate, useParams } from "react-router-dom";
import SeventyCreditPoints from "./SeventyCreditPoints";
import { apiJson } from "api";
import { useGlobalContext } from "global/context";

const SeventyFiveCongress = () => {
  const navigate = useNavigate();
  const { userData } = useGlobalContext();
  const [creditValue, setCreditValue] = useState()
  const [pastStepsData, setPastStepsData] = useState([])
  const params = useParams()

  const getPastSteps = async ()=>{
    const response = await apiJson(`/api/v2/75daysChallenge/pastChallenge/${params?.attemptId}`)
    setPastStepsData(response?.data?.result)
  }

  const totalCredit = (value)=>{
    setCreditValue(value)
  }
  const userDetails = {
    userId: userData.id,
    role: userData.role,
    instituteId: userData.instituteId,
  };
  const startGameSteps = async () => {
    try {
      const response = await apiJson.post(
        "/api/v2/75daysChallenge/addAttempts",
        userDetails
      );
      console.log(response)
      navigate(`/steps/seventy-five-steps/today-steps/${response.data.result.id}`);
    }catch(err) {
      console.log(err.message);
    }
  };

  useEffect(()=>{
    getPastSteps()
  },[])

  return (
    <>
      <header>
        <main>
          <div className="my_container">
            <div className="challengehead position-relative d-flex justify-content-between align-items-center w-75 m-auto">
              <img
                src={environGroup}
                alt="environment"
                className="sm-w-40"
                style={{margin:'0 2.5rem 0.5rem 0'}}
              />

                <SeventyCreditPoints totalCredit={totalCredit} />
            </div>

            <div className="mainCongressSec m-auto w-75 d-flex justify-content-center position-relative">
              <img
                src={environGroup}
                alt="environment"
                className="position-absolute mt-3"
              />
              <img src={confitiBall} alt="confitiBall" className="mt-5 pt-4" />
            </div>
            <div className="d-flex justify-content-center">
              <h3 className="text-center cong">Congratulations!</h3>
            </div>
            <p className="text-center congpara m-auto w-75 pb-3">
            By adopting a eco friendly habits and completing 75 Steps towards LiFE, you have earned {creditValue} Carbon Credits.
            </p>

            <button
              className="retakebtn border-0 "
              onClick={startGameSteps}
            >
              Retake
            </button>

            <div className="pastChallenges w-75 m-auto mt-3">
              <h3>Past Challenges</h3>

              {pastStepsData?.map((val) => {
                return (
                  <div
                    className="challnDays d-flex justify-content-between align-items-center my-4 w-100"
                  >
                    <>
                      <div className="dayVal text-white sm-w-40"> Step {val?.day_count}</div>
                      <div className="daypara ps-3">
                      <div className="align-align-self-lg-stretch">

                        <p>{val?.status === null? 'Incompleted' : 'Completed'}</p>
                        <p>{val?.description}</p>
                      </div>
                      <div className="daycredits">
                        <p>{val?.total_credit} Credit</p>
                      </div>
                      </div>
                    </>
                  </div>
                );
              })}
            </div>
          </div>
        </main>
      </header>
    </>
  );
};

export default SeventyFiveCongress;
