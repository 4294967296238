import React, { useEffect, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Button from "@mui/material/Button";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { apiJsonAuth } from "api";
import { toast } from "react-toastify"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useMediaQuery, useTheme } from "@mui/material";

export const AdminRatings = () => {
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [showUpdateRating, setShowUpdateRating] = useState(false);
  const [showDelteChallenge, setShowDelteChallenge] = useState(false);
  const [reviews, setShowReviews] = useState(false);
  const [condtionModal, setCondtionModal] = useState("");
  const [ratings, setRatings] = useState([]);
  const [discReviews, setDiscReviews] = useState("");
  const [updateId, setUpdateId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [screenWidth, setScreenWidth] = useState(document.documentElement.clientWidth);

  const validationSchema = yup.object().shape({
    name: yup.string().required("name is required"),
    rating: yup.string().required("Rating is required"),
    reviews: yup.string().required("Reviews is required"),
    organization: yup.string().required("Organization is required"),
  });


  const ratingFomik = useFormik({
    initialValues: {
      name: "",
      rating: "",
      reviews: "",
      organization: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm },) => {
      const data = {
        name: values.name,
        rating: values.rating,
        reviews: values.reviews,
        organization: values.organization
      }
      if (condtionModal === "Add") {
        apiJsonAuth.post(`api/v2/reviews/postReview_rating`, data)
          .then((res) => {
            setIsLoading(false)
            getAllRatings()
            handleCloseRatingModal()
            toast.dismiss();
            toast.success("Rating added successfully")
          })
          .catch((error) => {
            setIsLoading(false)
            toast.dismiss()
            toast.error("Internal server error")
            console.log("error", error);
          })
        resetForm();
      }
      else if (condtionModal === "Edit") {
        setIsLoading(true)
        apiJsonAuth
          .put(`api/v2/reviews/editReview_rating/${updateId}`, data)
          .then((res) => {
            setIsLoading(false)
            getAllRatings()
            handleCloseUpdateRatings();
            toast.dismiss()
            toast.success("Update challenge successfully");
          })
          .catch((error) => {
            toast.dismiss()
            toast.error("Internal server error")
            console.log("error", error);
          });
      }
    }
  })

  const getAllRatings = () => {
    apiJsonAuth
      .get("api/v2/reviews/getReview_rating")
      .then((res) => {
        setRatings(res?.data?.getUserReview);
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Internal server error")
        console.log("error", error);
      });
  };

  useEffect(() => {
    getAllRatings();
  }, [])

  const handleShowReviews = (rev) => {
    setDiscReviews(rev);
    setShowReviews(true);
  };
  const handleCloseReviews = () => {
    setShowReviews(false);
  };

  const handleShowRatingModal = () => {
    setCondtionModal('Add')
    setShowRatingModal(true)
  }

  const handleCloseRatingModal = () => {
    setShowRatingModal(false)

    ratingFomik.setErrors({});
    ratingFomik.handleReset();
  }

  const setEditFormValues = (revratings) => {
    ratingFomik.setValues({
      name: revratings?.name,
      rating: revratings?.rating,
      reviews: revratings?.reviews,
      organization: revratings?.organization,
    })
  }
  const handleShowUpdateRatings = (id) => {
    setCondtionModal('Edit')
    setUpdateId(id)

    const selectedRating = ratings?.find((ele) => ele?.id === id)
    setEditFormValues(selectedRating)
    setShowUpdateRating(true)
  }
  const handleCloseUpdateRatings = () => {
    setShowUpdateRating(false)

    ratingFomik.setErrors({});
    ratingFomik.handleReset();
  }

  const handleShowDeleteModal = (id) => {
    setDeleteId(id);
    setShowDelteChallenge(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDelteChallenge(false);
  };

  const handleShowDeleteRatings = () => {
    apiJsonAuth.delete(`api/v2/reviews/deleteReview_rating/${deleteId}`)
      .then((res) => {
        setIsLoading(false)
        getAllRatings()
        handleCloseDeleteModal();
        toast.dismiss();
        toast.success("Delete challenge successfully")
      })
      .catch((error) => {
        setIsLoading(false)
        toast.dismiss();
        toast.error("Internal server error")
        console.log("error", error);
      })
  }

  const handleResize = () => {
    setScreenWidth(document.documentElement.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  return (
    <div>
      <div className='col-10 mx-auto'>
        <div className="d-flex">
          <div className="col-6 d-flex align-items-center">
            <div className="">
              <ArrowBackIosNewIcon sx={{ color: "#da9eff" }} />
            </div>
            <div className=" fs-3">Manage Ratings</div>
          </div>

          <div className='d-flex gap-1'>
            <div
              onClick={handleShowRatingModal}
              className="d-flex align-items-center"
            >
              <Button
                className="text-capitalize"
                size="lg"
                style={{ background: "#da9eff", color: "#ffff" }}
              >
                Add Ratings
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="margin-sm-screen mt-5 col-lg-10 ms-4 p-md-3 p-lg-3 mb-5" style={{ width: screenWidth < 500 ? `${screenWidth - 50}px` : "" }}>
        <TableContainer component={Paper}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#914EFF",
              height: 4,
              width: 95,
              marginLeft: "2px"
            }
          }}
          variant={isMobile ? 'scrollable' : 'standard'}
          scrollButtons={isMobile ? 'auto' : 'off'}
        >
          <Table aria-label="">
            <TableHead>
              <TableRow style={{ background: "#bbdefb" }}>
                <TableCell align="center" style={{ fontSize: "16px", fontWeight: "600", color: "#8c32ff" }}>Name</TableCell>
                <TableCell align="center" style={{ fontSize: "16px", fontWeight: "600", color: "#8c32ff" }}>Ratings</TableCell>
                <TableCell align="center" style={{ fontSize: "16px", fontWeight: "600", color: "#8c32ff" }}>organization</TableCell>
                <TableCell align="center" style={{ fontSize: "16px", fontWeight: "600", color: "#8c32ff" }}>Reviews</TableCell>
                <TableCell align="center" style={{ fontSize: "16px", fontWeight: "600", color: "#8c32ff" }}>Edit</TableCell>
                <TableCell align="center" style={{ fontSize: "16px", fontWeight: "600", color: "#8c32ff" }}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {ratings?.map((ele, i) => {
                return (
                  <TableRow>
                    <TableCell align="center" style={{ fontSize: "15px", fontWeight: "600" }}>{ele?.name}</TableCell>
                    <TableCell align="center" style={{ fontSize: "15px", fontWeight: "600" }}>{ele?.rating}</TableCell>
                    <TableCell align="center" style={{ fontSize: "15px", fontWeight: "600" }}>{ele?.organization}</TableCell>
                    <TableCell align="center" style={{ fontSize: "", fontWeight: "700", cursor: "pointer", color: "#4f8df6", textDecoration: "underline" }} onClick={() => handleShowReviews(ele?.reviews)}>View Reviews</TableCell>
                    <TableCell align="center" style={{ fontSize: "15px", fontWeight: "600" }}><Button className="text-capitalize" style={{ background: "#0571ed", color: "white" }} onClick={() => handleShowUpdateRatings(ele?.id)}>Edit</Button></TableCell>
                    <TableCell align="center" style={{ fontSize: "15px", fontWeight: "600" }}><Button className="text-capitalize" style={{ background: "#f40157", color: "white" }} onClick={() => handleShowDeleteModal(ele?.id)}>Delete</Button></TableCell>
                  </TableRow>
                )
              })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {
        condtionModal === "Add" ?
          <Modal show={showRatingModal} onHide={handleCloseRatingModal}>
            <Modal.Header closeButton>
              <Modal.Title>Create Ratings</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="p-2">
                <form onSubmit={ratingFomik.handleSubmit}>
                  <div>
                    <span className="d-block">Name</span>
                    <input
                      type="text"
                      className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                      style={{
                        color: "black",
                        background: "rgb(239, 239, 239)",
                        fontSize: "16px",
                        height: "40px",
                      }}
                      value={ratingFomik.values.name}
                      onChange={ratingFomik.handleChange}
                      name="name"
                      placeholder="Name"
                    />
                    {ratingFomik.errors.name && ratingFomik.touched.name ? (
                      <span className="text-danger">
                        {ratingFomik.errors.name}
                      </span>
                    ) : null}
                  </div>

                  <div className="mt-3">
                    <span className="d-block">Ratings</span>
                    <select onChange={(e) => {
                      ratingFomik.setFieldValue("rating", e.target.value)
                    }} class="form-select form-control rounded-3" aria-label="Default select example">
                      <option selected>Select Ratings</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      {/* {
                          category?.map((type) => {
                            return <option value={type.id}>{type.name}</option>
                          })
                        } */}
                    </select>
                    {ratingFomik.errors.rating && ratingFomik.touched.rating ? (
                      <span className="text-danger">{ratingFomik.errors.rating}</span>
                    ) : null}
                  </div>

                  <div className="mt-3">
                    <span className="d-block">Organization</span>
                    <input
                      type="text"
                      name="organization"
                      placeholder="Organization"
                      className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                      style={{
                        color: "black",
                        background: "rgb(239, 239, 239)",
                        fontSize: "16px",
                        height: "40px",
                      }}
                      value={ratingFomik.values.organization}
                      onChange={ratingFomik.handleChange}
                    />
                    {ratingFomik.errors.organization && ratingFomik.touched.organization ? (
                      <span className="text-danger">{ratingFomik.errors.organization}</span>
                    ) : null}
                  </div>

                  <div className="mt-3">
                    <span className="d-block">Reviews</span>
                    <textarea
                      type="text"
                      className="form-control p-3 rounded fw-500 box-shadow-0 resize-none border-0"
                      name="reviews"
                      id=""
                      placeholder="Write Discription"
                      style={{
                        color: "black",
                        background: "rgb(239, 239, 239)",
                        fontSize: "16px",
                      }}
                      value={ratingFomik.values.reviews}
                      onChange={ratingFomik.handleChange}
                    ></textarea>
                    {ratingFomik.errors.reviews && ratingFomik.touched.reviews ? (
                      <span className="text-danger">
                        {ratingFomik.errors.reviews}
                      </span>
                    ) : null}
                  </div>

                  <div className="mt-3 d-flex justify-content-end">
                    <Button
                      type="submit"
                      size="lg"
                      value="Add"
                      className="text-capitalize px-3"
                      style={{ background: "#0808ff", color: "white", fontSize: "16px" }}
                      disabled={isLoading}                  >
                      {/* {isLoading ? }Add Challenge */}
                      {isLoading ?
                        <>
                          <div className="px-3">
                            <div className="spinner-border text-light mx-auto d-flex" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </>
                        :
                        <>
                          <div className="px-1">
                            <span>Add Ratings</span>
                          </div>
                        </>
                      }
                    </Button>
                  </div>
                </form>
              </div>
            </Modal.Body>
          </Modal> :
          condtionModal === "Edit" &&
          <Modal show={showUpdateRating} onHide={handleCloseUpdateRatings}>
            <Modal.Header closeButton>
              <Modal.Title>Create Ratings</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="p-2">
                <form onSubmit={ratingFomik.handleSubmit}>
                  <div>
                    <span className="d-block">Name</span>
                    <input
                      type="text"
                      className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                      style={{
                        color: "black",
                        background: "rgb(239, 239, 239)",
                        fontSize: "16px",
                        height: "40px",
                      }}
                      value={ratingFomik.values.name}
                      onChange={ratingFomik.handleChange}
                      name="name"
                      placeholder="Name"
                    />
                    {ratingFomik.errors.name && ratingFomik.touched.name ? (
                      <span className="text-danger">
                        {ratingFomik.errors.name}
                      </span>
                    ) : null}
                  </div>

                  <div className="mt-3">
                    <span className="d-block">Ratings</span>
                    <select onChange={(e) => {
                      ratingFomik.setFieldValue("rating", e.target.value)
                    }} value={ratingFomik.values.rating} class="form-select form-control rounded-3" aria-label="Default select example">
                      <option selected>Select Ratings</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      {/* {
                         category?.map((type) => {
                           return <option value={type.id}>{type.name}</option>
                         })
                       } */}
                    </select>
                    {ratingFomik.errors.ratings && ratingFomik.touched.rating ? (
                      <span className="text-danger">{ratingFomik.errors.rating}</span>
                    ) : null}
                  </div>

                  <div className="mt-3">
                    <span className="d-block">Organization</span>
                    <input
                      type="text"
                      name="organization"
                      placeholder="Organization"
                      className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                      style={{
                        color: "black",
                        background: "rgb(239, 239, 239)",
                        fontSize: "16px",
                        height: "40px",
                      }}
                      value={ratingFomik.values.organization}
                      onChange={ratingFomik.handleChange}
                    />
                    {ratingFomik.errors.organization && ratingFomik.touched.organization ? (
                      <span className="text-danger">{ratingFomik.errors.organization}</span>
                    ) : null}
                  </div>

                  <div className="mt-3">
                    <span className="d-block">Reviews</span>
                    <textarea
                      type="text"
                      className="form-control p-3 rounded fw-500 box-shadow-0 resize-none border-0"
                      name="reviews"
                      id=""
                      placeholder="Write Discription"
                      style={{
                        color: "black",
                        background: "rgb(239, 239, 239)",
                        fontSize: "16px",
                      }}
                      value={ratingFomik.values.reviews}
                      onChange={ratingFomik.handleChange}
                    ></textarea>
                    {ratingFomik.errors.reviews && ratingFomik.touched.reviews ? (
                      <span className="text-danger">
                        {ratingFomik.errors.reviews}
                      </span>
                    ) : null}
                  </div>

                  <div className="mt-3 d-flex justify-content-end">
                    <Button
                      type="submit"
                      size="lg"
                      value="Add"
                      className="text-capitalize px-3"
                      style={{ background: "#0808ff", color: "white", fontSize: "16px" }}
                      disabled={isLoading}                  >
                      {/* {isLoading ? }Add Challenge */}
                      {isLoading ?
                        <>
                          <div className="px-3">
                            <div className="spinner-border text-light mx-auto d-flex" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </>
                        :
                        <>
                          <div className="px-1">
                            <span>Edit Ratings</span>
                          </div>
                        </>
                      }
                    </Button>
                  </div>
                </form>
              </div>
            </Modal.Body>
          </Modal>
      }

      <Modal show={reviews} onHide={handleCloseReviews}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div><span className=" fs-5 font-weight-600">Description</span></div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <span style={{ fontSize: "17px", fontWeight: "500" }}>
              {discReviews}
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="fw-700 text-capitalize px-3" style={{ color: "grey", fontSize: "18px", background: "#f0f2f5" }} onClick={handleCloseReviews}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDelteChallenge} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Challenges</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <span> Are you sure want delete this rating?</span>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex gap-2">
            <div>
              <Button
                size="lg"
                style={{ color: "grey" }}
                className="text-capitalize"
                onClick={handleShowDeleteModal}
              >
                Discard
              </Button>
            </div>

            <div>
              <Button
                size="lg"
                className="text-capitalize"
                style={{ background: "#0808ff", color: "white" }}
                onClick={handleShowDeleteRatings}
                disabled={isLoading}
              >
                {isLoading ?
                  <>
                    <div className="px-3">
                      <div className="spinner-border text-light mx-auto d-flex" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="px-1">
                      <span>Delete</span>
                    </div>
                  </>
                }
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
