import { useSubAdminCSV } from 'contexts/SubAdminCSVContext';
import React, { useEffect } from 'react';
import { Box, Button, Typography, Card, CardContent } from '@mui/material';
import YuvaLoader from 'pages/Forum/components/Loader/YuvaLoader';

const SheetSelection = () => {
    const { parsedData, submittedSheets, setSelectedSheet, handleNext, selectedSheet, loading, fileName } = useSubAdminCSV();

    // Check if parsedData is an object and not null
    const isParsedDataValid = (data) => data && typeof data === 'object' && !Array.isArray(data);

    // Generate sheet buttons from parsed data
    const renderSheets = () => {
        if (!isParsedDataValid(parsedData)) {
            return (
                <Typography variant="body1" color="textSecondary">
                    No sheets available to select.
                </Typography>
            );
        }
        // console.log("Data: ", parsedData);
        // console.log("fileName is: ", fileName);
        // console.log("Submit Sheets: ", submittedSheets[fileName]);
        return Object.keys(parsedData).map((sheetName, index) => {
            const isDisabled = submittedSheets[fileName]?.includes(index);
            const isSelected = selectedSheet === index;

            return (
                <Card
                    key={index}
                    sx={{
                        mb: 3,
                        border: `1px solid ${isDisabled ? '#B0BEC5' : isSelected ? '#2356FE' : '#3F51B5'}`,
                        borderRadius: 2,
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        backgroundColor: isSelected ? '#E3F2FD' : 'white', // Highlight background if selected
                    }}
                >
                    <CardContent>
                        <Typography variant="h6" sx={{ mb: 2 }}>{sheetName}</Typography>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: isDisabled ? 'grey' : '#2356FE',
                                textTransform: 'capitalize',
                                width: 'fit-content'
                            }}
                            onClick={() => {
                                if (!isDisabled) {
                                    setSelectedSheet(index);
                                    handleNext();
                                }
                            }}
                            disabled={isDisabled}
                            fullWidth
                        >
                            {isDisabled ? "Submitted" : "Select Sheet"}
                        </Button>
                        {isDisabled && (
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                This sheet has already been submitted.
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            );
        });
    };

    return (
        <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
            {loading && (
                <YuvaLoader setShow={false} show={loading} />
            )}
            {renderSheets()}
        </Box>
    );
};

export default SheetSelection;
