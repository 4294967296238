import React, { useEffect, useState } from 'react'
import MoodTwoToneIcon from '@mui/icons-material/MoodTwoTone';
import AlternateEmailTwoToneIcon from '@mui/icons-material/AlternateEmailTwoTone';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import { Mention, MentionsInput } from 'react-mentions';
import { useDiscussionContext } from '../discussionBoardContext/discussionContext';
import { useGlobalContext } from 'global/context';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useParams, useNavigate } from 'react-router';
import { Avatar, Button, IconButton, Menu, MenuItem } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import { Modal } from 'react-bootstrap';
const users = [
    {
        id: '1',
        display: 'Nitesh',
    },
    {
        id: '2',
        display: 'Shailesh',
    },
    {
        id: '3',
        display: 'Sahil'
    },
    {
        id: '4',
        display: 'Rahul'
    },
];

const mentionsInputStyle = {
    control: {
        fontSize: 12,
        // fontWeight: 'normal',
    },

    "&multiLine": {
        control: {

            minHeight: 30
        },
        highlighter: {
            padding: 9
        },
        input: {
            outline: "none",
            border: "none",
            padding: 9
        }
    },

    "&singleLine": {
        display: "inline-block",


        highlighter: {
            padding: 1
        },
        input: {
            outline: "none",
            border: "none",
            padding: 1
        }
    },

    suggestions: {
        list: {
            fontSize: 16
        },
        item: {
            padding: "5px 15px",

            "&focused": {
                backgroundColor: "#cee4e5"
            }
        }
    }
};


export const DiscussionBoardMessageBox = ({ socket }) => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { userData } = useGlobalContext()

    const { setCurrentMessage } = useDiscussionContext()
    const [comments, setComments] = useState('')
    const [vpoint, setVpoint] = useState(false);
    const [remainingVotablePoint, setremainingVotablePoint] = useState(4)
    const [blankError, setBlankError] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const [showOption, setShowOption] = useState(false)

    const handleOptionModal = () => {
        setShowOption(prev => !prev)
    }

    const handleShowInfo = () => {
        setShowInfo(prev => !prev)
    }
    // const [commentList, setCommentList] = useState([]);
    // console.log('socket', socket)

    // const handleClick = () => {
    //     setCommentList((commentList) => [...commentList, comments])
    // }
    const handleInputChange = (e, newValue, newPlainTextValue, mentions) => {

        setComments(newPlainTextValue);
        console.log('newplain', newPlainTextValue)
        setBlankError(false)
    };

    const handleInputChangePoint = (e) => {
        setComments(e.target.value);
        setBlankError(false)
    }

    const handlePostChat = () => {
        const trimmedValue = comments.trim()
        if (trimmedValue) {
            handleSubmitComment()
        } else {
            console.log('You need to write something')
            // setBlankError(true)
        }
    }

    const handleSubmitComment = () => {
        const chatData = {
            user_id: userData.id,
            role: userData.role,
            institute_id: userData?.role === 'institute' ? userData.id : userData?.instituteId,
            content: comments,
            discussion_id: id,
            type: vpoint ? 'point' : ''
        }
        if (vpoint && remainingVotablePoint === 0) {
            return;
        }
        if (vpoint) {
            setremainingVotablePoint((prev) => prev - 1 > 0 ? prev - 1 : 0)
        }
        console.log('message', comments)
        setCurrentMessage(comments)
        socket?.emit('addChat', chatData)
        setComments('')
        setVpoint(false)
    }



    useEffect(() => {
        socket?.on('votablePointCount', count => {
            console.log('count votable point', count)
            setremainingVotablePoint(count)
        })
    }, [socket])



    return (
        <>
            <div className="d-flex px-3" >
                <div className='d-none d-md-block' style={{ width: "46px", height: "46px", borderRadius: "50%" }}>
                    <Avatar src={`${userData?.profile || userData?.logo}`} alt={userData?.first_name || userData?.institution_name} />

                </div>
                {/*<============= Message Box Ui Start ============> */}
                <div className='w-100 rounded-3 ms-2 p-3' style={{ background: "#F3F3F3", }}>
                    {/* <<============= Tag anyone and message input field start ============>> */}
                    <div >
                        <div className='d-flex justify-content-between'>
                            <div>

                                <span className='rounded-3 me-4 cursor-pointer' style={{ background: "#DBDBDB", padding: "5px", fontSize: "12px", color: "#525252" }} ><span className='d-inline-block text-center' style={{ background: "#9D9D9D", height: "19px", width: "19px", borderRadius: "9px", color: "#FFFFFF", marginRight: "5px" }}>#</span>Everyone in the discussion</span>
                                <IconButton onClick={handleShowInfo} >
                                    <InfoOutlinedIcon />
                                </IconButton>
                            </div>

                            <IconButton onClick={handleOptionModal} >
                                <ListIcon />
                            </IconButton>
                        </div>


                        <div className='w-100 mt-1 thin-scroll' style={{ maxHeight: '100px', overflow: 'scroll', overflowX: 'hidden' }}>
                            {vpoint ? (<>
                                <div className="d-flex mt-2">
                                    <span className='fs-6 fw-semibold p-2 rounded-2' style={{ background: "#A38CFF", color: "#ffffff", fontSize: "13px" }}>Point</span>
                                    <div className='w-100'>
                                        <input type="text" value={comments} onChange={(e) => handleInputChangePoint(e)} className='border border-0 outline-0 fw-normal my-2 w-100' style={{ fontSize: "12px", background: "#F3F3F3", color: "#5E0080", marginLeft: "10px", paddingLeft: "8px" }} placeholder='We need to reduce a carbon' />
                                        {/* <input type="text"   className='border '/> */}
                                    </div>
                                </div>
                            </>) : (
                                <MentionsInput
                                    className="mentions__mention"
                                    placeholder='Start typing a message'
                                    value={comments}
                                    onChange={handleInputChange}
                                    style={mentionsInputStyle} >
                                    <Mention displayTransform={(id, display) => `@${display}`}
                                        data={users}
                                        style={{ color: "red" }}
                                    />
                                </MentionsInput>
                            )}
                            {blankError && <span className='text-danger'>You need to write something</span>}

                        </div>
                    </div>
                    {/* <<============= Bottom icon section and Button section start ============>> */}

                    <div className='"d-grid gap-2 d-md-flex justify-content-md-between mt-1'>
                        <div className='cursor-pointer'>
                            {/* <MoodTwoToneIcon sx={{ color: "#FFB800", height: "17px", width: "17px", marginRight: "10px" }} />
                            <AlternateEmailTwoToneIcon sx={{ color: "#3B1BFF", height: "17px", width: "17px", marginRight: "10px" }} /> */}
                            {remainingVotablePoint ?
                                <>
                                    <AddCircleTwoToneIcon sx={{ color: "#6100FF", height: "35px", width: "35px" }} onClick={() => setVpoint(!vpoint)} />
                                    <span className='fw-normal' style={{ fontSize: "12px", color: "#130031", marginTop: "10px" }}>{remainingVotablePoint}&nbsp;votable points left</span>
                                </>
                                : <span>
                                    No Votable Point Left
                                </span>
                            }
                        </div>
                        <div className='d-flex justify-content-end'>
                            <button className='rounded-2 px-3 py-2 mx-2' style={{ background: "#E3E3E3", color: "#535353", fontSize: "13px", fontWidth: 500 }}>Discard</button>
                            <button onClick={handlePostChat} className='rounded-2 px-3 py-2' style={{ background: "#8CB3FF", color: "#000000", fontSize: "13px", fontWidth: 500 }} >Post</button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showInfo} onHide={handleShowInfo} centered>
                <Modal.Body>
                    <div className='row '>
                        <div className='col-12  '>
                            <div >
                                <span className='fs-5 fw-semibold'>Title: </span><span className='fs-5 fw-semibold'>This is Title</span>
                            </div>
                        </div>
                        <div className='col-12 col-6 '>
                            <div >
                                <span className='fs-5 fw-semibold'>Description: </span><span className='fs-5 fw-semibold'>This is Description</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showOption} onHide={handleOptionModal} centered size='sm'>
                <Modal.Body>
                    <div className='row p-2 justify-content-center'>
                       <Button onClick={()=>navigate(`/new-dashboard/discussion-leaderboard/${id}`)} className='col-10 m-2  ' variant="outlined" color="success">
                            LeaderBoard
                        </Button>
                        <Button onClick={()=>navigate(`/new-dashboard/discussion-judgingAndJury/${id}`)} className='col-10 m-2 ' variant="outlined" color="warning">
                            Judging and Jury
                        </Button>
                        <Button className='col-10 m-2 ' variant="outlined" color="error">
                            Exit 
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
