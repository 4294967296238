import React, { useEffect, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { BlockQuote } from "@ckeditor/ckeditor5-block-quote";
const MyCKEditor = ({ content, setContent }) => {
  // const handleEditorChange = ( editor) => {
  //   const data = editor.getData();
  //   setContent(data);
  // };
  // useEffect(() => {
  //   if (content?.length === 0) setContent("");
  // }, []);

  const editorRef = useRef();

  useEffect(() => {
    if (content?.length === 0) setContent("");
  }, []);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setContent(data);
  };
  return (
    <div>
      {/* <CKEditor editor={ClassicEditor} data={content} onChange={handleEditorChange} /> */}
      <CKEditor
        editor={ClassicEditor}
        data={content}
        onChange={handleEditorChange}
        onReady={editor => {
          // Save editor instance reference
          editorRef.current = editor;
        }}
      />
    </div>
  );
};

export default MyCKEditor;
