import * as React from 'react';
import Button from '@mui/joy/Button';
// import { Button,CardContent, } from '@mui/material'; 
// import { CardActions } from '@mui/material';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
// import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';
import { StarRateRounded } from '@mui/icons-material';

export default function StatsCard({ title, subtitle, icon, color, value, progressValue, onAction, actionTitle, actionDisabled = false }) {
  return (
    <Card variant="soft" color={color? color : "primary"} invertedColors>
      <CardContent orientation="horizontal">
        <CircularProgress size="lg" determinate value={progressValue ? progressValue : 20}>
          <StarRateRounded/>
        </CircularProgress>
        <CardContent>
          <Typography level="body-md">{title}</Typography>
          <Typography level="h2">{value}</Typography>
        </CardContent>
      </CardContent>
      <div>
        {/* <Button variant="soft" size="sm">
          Add to Dashboard
        </Button> */}
        <Button variant="solid" onClick={onAction}
        disabled={actionDisabled}
        >
          {actionTitle ? actionTitle : "See Available Slots"}
        </Button>
      </div>
    </Card>
  );
}