import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { Popup } from 'layout/Popup';
import { apiJson } from 'api';
import { useGlobalContext } from 'global/context';

// Create the context
const SubAdminCSVContext = createContext();

// Create a provider component
const SubAdminCSVProvider = ({ children }) => {
    const { userData } = useGlobalContext();
    const [loading, setLoading] = useState(true);
    const [institutes, setInstitutes] = useState([]);
    const [data, setData] = useState({});
    const [fileData, setFileData] = useState([]);
    const [fileName, setFileName] = useState("");
    const [parsedData, setParsedData] = useState(null);
    const [sheetView, setSheetView] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedSheet, setSelectedSheet] = useState(null);
    const [selectedHeaderRow, setSelectedHeaderRow] = useState(0);
    const [headerMappings, setHeaderMappings] = useState({});
    const [submittedSheets, setSubmittedSheets] = useState({});
    const [validationErrors, setValidationErrors] = useState([]);
    const [mappedHeaders, setMappedHeaders] = useState([]);
    const [role, setRole] = useState("");
    const [instituteId, setInstituteId] = useState(null);

    const requiredColumns = [
        {
            name: "First Name", key: "first_name", required: true,
            suggested_mappings: ["Student Name", "First", "first_name"],
            validation: {
                regex: /^[A-Za-z]+$/,
                errorMessage: "First name must contain only letters."
            }
        },
        {
            name: "Middle Name", key: "middle_name", required: false,
            suggested_mappings: ["Middle", "middle_name"],
            validation: {
                regex: /^[A-Za-z]+$/,
                errorMessage: "Last name must contain only letters."
            }
        },
        {
            name: "Last Name", key: "last_name", required: false,
            suggested_mappings: ["last_name", "Surname", "last name"],
            validation: {
                regex: /^[A-Za-z]+$/,
                errorMessage: "Last name must contain only letters."
            }
        },
        {
            name: "Contact", key: "contact", required: false,
            suggested_mappings: ["Phone", "Phone Number", "Mobile", "phone_number", "contact_number", "ph. no"],
            validation: {
                regex: /^\d{10}$/,
                errorMessage: "Contact must be a valid 10-digit number."
            }
        },
        {
            name: "Email", key: "email", required: false,
            suggested_mappings: ["Mail", "Email Address", "Email id", "email_id"],
            validation: {
                regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                errorMessage: "Invalid email format."
            }
        },
        {
            name: "Date of Birth", key: "dob", required: true,
            suggested_mappings: ["date of birth", "date _of_birth", "DOB", "Birth Date", "Birth", "date_of_birth", "birthday", "d.o.b"],
            validation: {
                type: 'date',
                errorMessage: "Date of birth must be a valid date."
            }
        },
        { name: "Gender", key: "gender", required: false, suggested_mappings: ["Sex"] },
        { name: "Father Name", key: "father_name", required: false, suggested_mappings: ["Dad", "Father", "Father's Name", "father_name"] },
        { name: "Mother Name", key: "mother_name", required: false, suggested_mappings: ["Mom", "Mother", "Mother's Name", "mother_name"] },
        { name: "Guardian 1", key: "guardian1", required: false, suggested_mappings: ["Guardian1", "Primary Guardian", "Guardian 1"] },
        { name: "Guardian 2", key: "guardian2", required: false, suggested_mappings: ["Guardian2", "Secondary Guardian", "Guardian 2"] },
        { name: "Address", key: "address", required: false, suggested_mappings: ["Location", "Address Line 1", "Home Address", "Full Address"] },
        ...(role === "student"
            ? [
                { name: "Class", key: "class", required: true, suggested_mappings: ["Grade", "Year", "class_name", "class name"] },
                { name: "Section", key: "section", required: false, suggested_mappings: ["division"] },
                { name: "Stream", key: "stream", required: true, suggested_mappings: ["Course", "subject"] },
            ]
            : [])
    ];

    const fetchInstitutes = async (role) => {
        try {
            let response;
            // Determine which API to call based on role or path
            if (role === 'admin') {
                response = await apiJson('/api/v2/csvUpload/fetch-institute-list');
            } else if (role === 'subAdmin') {
                response = await apiJson.post('/api/v2/csvUpload/fetch-assigned-institute-list', { id: userData?.id });
            }

            if (response?.status === 200) {
                setInstitutes(response?.data?.result);
            }
        } catch (err) {
            if (err?.response?.status === 404) {
                setInstitutes([]);
            }
            else {
                throw err;
            }
        }
    };

    const parseExcelFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                const parsedData = {};
                workbook.SheetNames.forEach(sheetName => {
                    const worksheet = workbook.Sheets[sheetName];
                    const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                    // Filter out empty rows
                    const filteredSheetData = sheetData.filter(row =>
                        row.some(cell => cell !== null && cell !== undefined && cell !== "")
                    );

                    parsedData[sheetName] = filteredSheetData;
                });

                resolve(parsedData);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsArrayBuffer(file);
        });
    };

    const formatData = (data) => {
        return data.reduce((acc, { role, file_name }) => {
            if (!acc[role]) {
                acc[role] = [];
            }
            acc[role].push(file_name);
            return acc;
        }, {});
    };

    // Function to handle fetching data
    const fetchData = async (instituteId) => {
        if (!instituteId) return;

        setInstituteId(instituteId);
        setLoading(true);
        try {
            const response = await apiJson(`/api/v2/csvUpload/fetch-list/${instituteId}`);
            const tempData = formatData(response?.data || []);
            // console.log("Fetched Data: ", response);
            const submittedSheets = response?.data?.reduce((acc, item) => {
                acc[item.file_name] = item.sheetIndexes || [];
                return acc;
            }, {});
            // console.log("tempData", tempData);
            // console.log("submittedSheets: ", submittedSheets);
            setData(tempData);
            setSubmittedSheets(submittedSheets || {});
        } catch (err) {
            Popup('error', 'Server Error', 'Something went wrong!', 3000);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle file upload/update
    const handleUpdate = async (fileName, role) => {
        setRole(role);
        setLoading(true);
        setFileName(fileName);
        try {
            const downloadLinkResponse = await apiJson.get(`/api/v2/csvUpload/get-download-link/${instituteId}/${role}/${fileName}`);
            const downloadLink = downloadLinkResponse.data.downloadLink;
            const fileResponse = await fetch(downloadLink);
            if (!fileResponse.ok) {
                throw new Error('File download failed');
            }
            const fileBlob = await fileResponse.blob();
            try {
                const parsedExcelData = await parseExcelFile(fileBlob);
                setParsedData(parsedExcelData);
                setSheetView(true);
                setCurrentStep(0);
            } catch (parseError) {
                Popup('error', 'File Error', 'Failed to parse file', 3000);
                setLoading(false);
                return;
            }
        } catch (err) {
            Popup('error', 'Server Error', 'Failed to upload file', 3000);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle file upload and parsing locally
    const handleFileUpload = async (event) => {
        // const file = event.target.files[0];
        // if (file) {
        //     setLoading(true);
        //     try {
        //         const parsedExcelData = await parseExcelFile(file);
        //         setParsedData(parsedExcelData);
        //         setSheetView(true);
        //         setCurrentStep(0);
        //     } catch (error) {
        //         Popup('error', 'File Error', 'Failed to parse file', 3000);
        //     } finally {
        //         setLoading(false);
        //     }
        // }
    };

    const handleNext = async () => {
        // console.log("Handle next for: ", currentStep);
        setLoading(true);

        try {
            if (currentStep === 2) {
                const { isValid, errors } = validateFields();

                if (!isValid) {
                    const errorMessage = errors.length > 0
                        ? errors.join(", ")
                        : "Please fix the validation errors.";
                    Popup('error', "Validation Errors", errorMessage);
                    return; // Exit early if there are validation errors
                }

                const sheetName = Object.keys(parsedData)[selectedSheet];
                const structuredData = await createFileData(parsedData[sheetName], mappedHeaders);
                setFileData(structuredData);
            }

            setCurrentStep((prev) => prev + 1);
        } catch (error) {
            console.error("Error in handleNext:", error);
            Popup('error', "Error", "An unexpected error occurred. Please try again.");
        } finally {
            setLoading(false); // Ensure loading is set to false regardless of outcome
        }
    };


    // Function to move back a step
    const handleBack = () => {
        // console.log("Handle Back Called!!");
        if (currentStep === 1) {
            setParsedData(null);
            setSheetView(false);
            setMappedHeaders([]);
            setFileData([]);
            setFileName("");
            setSelectedSheet(null);
            setSelectedHeaderRow(0);
            setHeaderMappings({});
            setSubmittedSheets({});
        }
        setValidationErrors([]);
        setCurrentStep((prev) => prev - 1)
    };

    // Handle changes in header mapping
    const updateHeaderMapping = (index, newDestination) => {
        const updatedMappings = [...mappedHeaders];
        updatedMappings[index].destination = newDestination;
        updatedMappings[index].include = newDestination !== "";
        setMappedHeaders(updatedMappings);
        setValidationErrors((prevErrors) => {
            if (newDestination) {
                return prevErrors.filter((error) => error !== updatedMappings[index].header);
            }
            return prevErrors;
        });
    };

    const validateFields = () => {
        const missingRequiredColumns = [];
        const requiredFields = ["first_name", "dob"];
        if (role === "student") {
            requiredFields.push("class", "stream");
        }

        requiredFields.forEach((field) => {
            if (!mappedHeaders.some((header) => header.include && header.destination === field)) {
                const column = requiredColumns.find((col) => col.key === field);
                if (column) {
                    missingRequiredColumns.push(column.name);
                }
            }
        });

        const hasContactOrEmail = mappedHeaders.some(
            (header) =>
                header.include &&
                (header.destination === "contact" || header.destination === "email")
        );
        if (!hasContactOrEmail) {
            missingRequiredColumns.push("At least one of Contact or Email");
        }

        const hasParentOrGuardian = mappedHeaders.some(
            (header) =>
                header.include &&
                (["father_name", "mother_name", "guardian1", "guardian2"].includes(header.destination))
        );
        if (!hasParentOrGuardian) {
            missingRequiredColumns.push("At least one of Father, Mother, Guardian 1, or Guardian 2");
        }

        setValidationErrors(missingRequiredColumns);
        return {
            isValid: missingRequiredColumns.length === 0,
            errors: missingRequiredColumns,
        };
    };

    // Prepare header mapping based on uploaded file data
    const prepareHeaderMapping = (headerRow, filteredData) => {
        if (!headerRow || !Array.isArray(headerRow) || !filteredData || !Array.isArray(filteredData)) {
            return;
        }
        const mapped = headerRow.map((header, index) => {
            const headerValue = header?.toString()?.toLowerCase().trim();

            const matchedColumn = requiredColumns.find((col) => {
                const suggestions = Array.isArray(col.suggested_mappings) ? col.suggested_mappings.map((s) => s.toLowerCase()) : [];
                const isNameMatched = col.name?.toLowerCase() === headerValue;
                const isSuggestionMatched = suggestions.includes(headerValue);

                return isNameMatched || isSuggestionMatched;
            });

            // Ensure filteredData has rows and the current row has the expected index
            const sampleData = filteredData.slice(0, 4).map((row) => row && row[index] !== undefined ? row[index]?.toString() : "N/A");

            return {
                header: header,
                sampleData: sampleData,
                destination: matchedColumn ? matchedColumn.key : "",
                include: !!matchedColumn
            };
        });

        setMappedHeaders(mapped);
    };

    useEffect(() => {
        // console.log("Doing parsing in Use Effect !!");
        if (parsedData && selectedSheet !== null && selectedHeaderRow !== null) {
            const sheetKeys = Object.keys(parsedData);
            setLoading(true);
            // Ensure selectedSheet is within bounds of sheetKeys
            if (sheetKeys[selectedSheet]) {
                const currentSheetKey = sheetKeys[selectedSheet];
                const currentSheet = parsedData[currentSheetKey];

                // Ensure selectedHeaderRow exists in the current sheet
                if (currentSheet && currentSheet[selectedHeaderRow]) {
                    const headerRow = currentSheet[selectedHeaderRow];

                    // Filter out the header row from the remaining data
                    const filteredData = currentSheet.slice(selectedHeaderRow + 1);

                    // Prepare the header mapping with both header row and filtered data
                    prepareHeaderMapping(headerRow, filteredData);
                }
            }
            setLoading(false);
        }
        // console.log("Did nothing!!");
    }, [selectedSheet, selectedHeaderRow]);

    // Function to submit a sheet
    const handleSubmitSheet = async (submittedPayload) => {
        setLoading(true);
        const BATCH_SIZE = 250;

        // Split data into batches to manage payload size
        const splitIntoBatches = (dataList, batchSize) => {
            const batches = [];
            for (let i = 0; i < dataList.length; i += batchSize) {
                batches.push(dataList.slice(i, i + batchSize));
            }
            return batches;
        };

        const correctDataBatches = splitIntoBatches(submittedPayload.correctData, BATCH_SIZE);
        const incorrectDataBatches = splitIntoBatches(submittedPayload.incorrectData, BATCH_SIZE);

        const processBatch = async (dataBatch) => {
            const batchPayload = {
                role,
                correctData: dataBatch.correctData || [],
                incorrectData: dataBatch.incorrectData || [],
                fileName,
                instituteId,
                submittedSheets: [...(submittedSheets[fileName] || []), selectedSheet],
                totalSize: Object.keys(parsedData)?.length
            };

            try {
                const response = await apiJson.post('/api/v2/csvUpload/bulklogin-admin-csv', batchPayload);
                return response.data;
            } catch (error) {
                throw error;
            } finally {
                setLoading(false);
            }
        };

        try {
            let allDataSaved = [];
            let allDataFailed = [];
            let message = '';

            for (let i = 0; i < correctDataBatches.length || i < incorrectDataBatches.length; i++) {
                const correctBatch = correctDataBatches[i] || [];
                const incorrectBatch = incorrectDataBatches[i] || [];

                const batchResult = await processBatch({
                    correctData: correctBatch,
                    incorrectData: incorrectBatch,
                });

                allDataSaved = allDataSaved.concat(batchResult.dataSaved || []);
                allDataFailed = allDataFailed.concat(batchResult.dataFailed || []);
                message = batchResult.message;
            }

            // Display success message on completion
            Popup('success', 'Success', 'Sheet Submitted Successfully!!', 3000);
            setSheetView(false);
            fetchData(instituteId);

            // Update submittedSheets state to include the selectedSheet
            setSubmittedSheets((prev) => ({
                ...prev,
                [fileName]: [...(prev[fileName] || []), selectedSheet]
            }));

        } catch (error) {
            Popup('error', 'Server Error', 'An error occurred during submission', 3000);
            setSubmittedSheets((prev) => prev.filter(sheet => sheet !== selectedSheet));
        }
    };

    const createFileData = (sheetData, headers) => {
        // console.log("Creating File's data");
        const headerRow = sheetData[selectedHeaderRow];
        return sheetData.slice(selectedHeaderRow + 1).map((row) => {
            const rowData = {};
            const otherData = {};
            headers.forEach(header => {
                const index = headerRow.findIndex(h => h.trim() === header.header.trim());
                if (index !== -1) {
                    if (header.destination) {
                        rowData[header.destination] = (row[index] !== undefined) ? row[index] : '';
                    }
                }
            });

            headerRow.forEach((headerName, index) => {
                const isMapped = headers.some(h => h.header.trim() === headerName.trim() && h.destination);
                if (!isMapped) {
                    const value = row[index];
                    otherData[headerName.trim()] = (value !== undefined) ? value : '';
                }
            });
            rowData.otherData = otherData;
            return rowData;
        });
    };

    return (
        <SubAdminCSVContext.Provider
            value={{
                loading, data, parsedData, fileData, fileName, sheetView, currentStep, selectedSheet, requiredColumns, role,
                selectedHeaderRow, headerMappings, submittedSheets, mappedHeaders, validationErrors,
                fetchInstitutes, institutes, setLoading, setRole, fetchData, handleUpdate, handleFileUpload, handleNext, handleBack, handleSubmitSheet,
                setParsedData, setSheetView, setCurrentStep, setSelectedSheet, setSelectedHeaderRow, setHeaderMappings, updateHeaderMapping, setInstituteId
            }}
        >
            {children}
        </SubAdminCSVContext.Provider>
    );
};

// Custom hook to use the SubAdminCSVContext
const useSubAdminCSV = () => {
    const context = useContext(SubAdminCSVContext);
    if (!context) {
        throw new Error('useSubAdminCSV must be used within a SubAdminCSVProvider');
    }
    return context;
};

export { SubAdminCSVProvider, useSubAdminCSV };
