import React, { useEffect, useState } from "react";
import environment from "../assests/Group 386.svg";
import "../Css/challengeday.css";
import groupimg from "../assests/Group 381.svg";
import uploadimg from "../assests/Upload to the Cloud.svg";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { apiAuth, apiJson } from "api";
import { useGlobalContext } from "global/context";
import { useFormik } from "formik";
import { object } from "yup";
import { toast } from "react-toastify";
import CreditPointes from "./CreditPointes";
import { ThreeDots } from "react-loader-spinner";


const TodayChallenCompoent = () => {
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate();
  const params = useParams();
  const { userData } = useGlobalContext();
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [experience, setExperience] = useState("");
  const [todayChallenge, setTodayChallenge] = useState([]);
  const [taskId, setTaskId] = useState();
  const [credit, setCredit] = useState();
  const [imageLink, setImageLink] = useState();
  const [taskCompletedStatus, setTaskCompletedStatus] = useState();
  const [uploadedExperience, setUploadedExperience] = useState();
  const [uploadedImg, setUploadedImg] = useState();
  const [dayCount, setDayCount] = useState()


  const getTodayChallenge = async () => {
    try {
      setLoader(true)
      const response = await apiJson.get(`/api/v2/21daysChallenge/showTaskDetailById/${params?.attemptId}/task/${params?.tastId}`);
      setTodayChallenge([response?.data?.result]);
      setTaskId(response?.data?.result?.id);
      setCredit(response?.data?.result?.credit);
      setLoader(false)
    } catch (err) {
      console.log(err.message);
    }
  };

  const [imagePreview, setImagePreview] = useState(null);
  const upload = (e) => {
    setUploadedFileName(e.target.files[0].name);
    const file = e.target.files[0];

    if (file) {
      // Create a URL for the image preview
      const objectURL = URL.createObjectURL(file);
      setImagePreview(objectURL);
    }
  };

  const getImageLink = (e) => {
    const file = e.target.files[0];
    apiAuth
      .post("v2/register/uploadInstituteLogo", { img: file })
      .then((res) => {
        setImageLink(res.data.result);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error("Internal Server Error");
      });
  };

  const getTastPerformanceDetail = async () => {
    const response = await apiJson.get(
      `/api/v2/21daysChallenge/showTaskDetailById/${params?.attemptId}/task/${params?.tastId}`
    );
    setTaskCompletedStatus(response?.data?.result?.status);
    setUploadedExperience(response?.data?.result?.experience);
    setUploadedImg(response?.data?.result?.images);
    setDayCount(response.data.result.day_count)
  };
  useEffect(() => {
    getTodayChallenge();
    getTastPerformanceDetail();
  }, []);

  const submit = async (e) => {
    e.preventDefault();

    if(!experience || !imageLink){
      toast.warning("All fields are Required" ,{
        position:toast.POSITION.TOP_RIGHT
      })
    }else{

    const payload = {
      userId: userData.id,
      role: userData.role,
      instituteId: userData.instituteId,
      taskId: taskId,
      attemptId: params.attemptId,
      status: "Completed",
      experience: experience,
      credit_earned: credit,
      images: imageLink,
    };
    try {
      const response = await apiJson.post(
        "/api/v2/21daysChallenge/submitChallenge/",
        payload
      );
      {
        dayCount === 21 ? 
        navigate(`/challenge/twenty-one-day/gameday/${params.attemptId}/Today-Challenge/Congress`)
        : navigate(`/challenge/twenty-one-day/gameday/${params.attemptId}`);
      }
      
    } catch (err) {
      console.log(err.message);
    }
  }


  };

  return (
    <>
      <div className="challengeDays p-3">
        <div className="challengehead position-relative d-flex justify-content-between align-items-center">
          <img
            src={environment}
            alt="environment"
            className="sm-w-30"
          />
          <div className="d-flex align-items-center gap-2">
            <span className="backArrow">
              {" "}
              <ArrowBackIosIcon
                sx={{ color: "#7700FF"}}
                onClick={() => navigate(`/challenge/twenty-one-day/gameday/${params?.attemptId}`)}
              />{" "}
            </span>
            <h4 className="my-3 sm-fs-10px sm-w-50 text-center">Today's challenge</h4>
          </div>
        <div>
        <CreditPointes img={groupimg} />
        </div>
        </div>
        {taskCompletedStatus === "Completed" ? (
          <div className="d-flex flex-column align-items-center">
          <p className="text-success text-center h4 my-3 " style={{textShadow:"1px 1px 30px green"}}>Congratulation's Your Today Task Is Completed</p>
            <img src={uploadedImg} alt="" width="50%" className="rounded-3 shadow-lg" />
            <p className="text-capitalize my-3">{uploadedExperience}</p>
          </div>
        ) : (
          <>
            <div className="todayChallenge">
              {
                loader? <div className='d-flex justify-content-center align-items-center'><ThreeDots/></div> :todayChallenge?.map((value) => {
                return (
                  <>
                    <div className="challnDays d-flex justify-content-between align-items-center">
                      <div className="dayVal text-white">
                        Day {value?.day_count}
                      </div>
                      <div className="daypara ps-3">
                        <div className="align-align-self-lg-stretch">
                        <p>
                          {value?.status == null ? "Not Done" :<span className="text-success">Completed</span> }
                        </p>
                        <p>{value?.description}</p>
                        </div>
                      <div className="daycredits">
                        <p>{value?.credit} Credit</p>
                      </div>
                    </div>
                      </div>
                  </>
                );
              })
              }
              
            </div>

            <form className="pastChallenge">
              <div className="upload mt-5 text-center position-relative">
                <img src={uploadimg} alt="uploadimg" />
                <input
                  type="file"
                  name="image"
                  id="file"
                  onChange={(e) => {
                    upload(e);
                    getImageLink(e);
                  }}
                />
                <p>
                  Drag file here or click to{" "}
                  <span>
                    {uploadedFileName
                      ? uploadedFileName
                      : "Select file to upload"}
                  </span>{" "}
                  proof of activity
                </p>
                {uploadedFileName ? (
                  <img
                    src={imagePreview}
                    alt="uploadedImg"
                    width="100%"
                    height="300px"
                    style={{ objectFit: "cover", objectPosition: "center" }}
                  />
                ) : null}
              </div>

              <div className="experience">
                <p className="mt-5">Share your experience</p>
                <input
                  type="text"
                  placeholder="Eg - I am very happy to share that i have used stairs instead of
  an elevator, it was tough using stairs all the day, but have loose
  my wait too. So its not only eco friendly activity but healthy
  activity."
                  name="experience"
                  onChange={(e) => setExperience(e.target.value)}
                />

                <div className="d-flex justify-content-between expbtns">
                <button  onClick={()=>navigate('/challenge/twenty-one-day')} className="border-0">Later</button>
                  <button className="border-0" type="submit" onClick={submit}>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
};

export default TodayChallenCompoent;
