import { Button,FormGroup, FormControlLabel} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { apiJson } from 'api'
import { useGlobalContext } from 'global/context'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const RiasecClubJoin = ({ showModal, setShowModal,proceedEnroll }) => {

    const navigate = useNavigate()
    const {userData} = useGlobalContext()
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const hideModal = ()=>{
        setShowModal(false);
    }
    const handleSubmit = ()=>{
        apiJson.post(`api/v2/clubs/joinClub/${6}/user/${userData?.id}/role/${userData?.role}/instituteId/${userData?.role==='institute'? userData?.id:userData?.instituteId}/joinClub`)
        .then((res)=>{
            proceedEnroll()
        }).catch((err)=>{
            console.log(err.message)
          navigate('/clubs/6')

        })
        // hideModal()  
    }


  return (
   <>
     <Modal size='lg' show={showModal} onHide={hideModal}>
        <Modal.Body className='p-0 rounded-4'>
        <div className="row overflow-hidden">
        <div className="col-12 col-md-5 d-none d-lg-block position-relative">
            <img src="/modals/6.png" alt="modal's" height="115%" width="200%" style={{position:"absolute",left:"-47%",top:"-5%"}} className='rounded-4' />
            <img src="/modals/4.png" alt="modal" width="110%" style={{position:"absolute",zIndex:"50",top:"30%",transform:"scale(1.3)"}} />
        </div>

        <div className='col-12 col-md-10 col-lg-7 m-auto py-4 px-3'>
            <div className='mb-4'>
                <span className='fs-4 '>By Taking the NIPAM Certification you will be added to the prestigious <span className='fw-bolder'>Bharat IP Innovation Club</span>.</span>
            </div>
            <div className='mb-4'>
                <span className='fs-6 fw-semibold'>In this club students will actively participate in a variety of workshops, community excercises, DIY activities and training sessions aimed at introducing them to the concepts of innovation  and entrepreneurship.
                </span>
            </div>
            <div className='d-flex justify-content-center mb-4'>
            
            <FormGroup sx={{zIndex:100000}} >
      <FormControlLabel  control={<Checkbox  checked={isChecked} onChange={handleCheckboxChange}/>}  label="Yes, Add me to Bharat Ip Innovation Club"/>
    </FormGroup>
            </div>
            <div>
            {isChecked ? (
                <Button onClick={handleSubmit} className='fs-4 fw-bolder rounded-4 text-capitalize px-5 py-2' color='primary' variant='contained'>{'Proceed'}</Button> ) :(<Button variant="contained" className='fs-4 fw-bolder rounded-4 text-capitalize px-5 py-2' disabled>Proceed</Button>)
            }
                
            </div>
        </div>

        </div>

        </Modal.Body>
    </Modal>
   </>
  )
}

export default RiasecClubJoin