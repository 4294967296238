import React, { useState } from 'react';
import { Box, Grid, Card, CardContent, Typography } from '@mui/material';
import { useCareerContext } from 'contexts/CareerContext';
import { apiJson } from 'api';
import { Popup } from 'layout/Popup';
import YuvaLoader from 'pages/Forum/components/Loader/YuvaLoader';

const ClassCardView = () => {
    const { role, selectedNavOption, setSelectedSubNavOption, setClassInfo, setStudentsList, lsmtClasses } = useCareerContext();
    const [loading, setLoading] = useState(false);

    const userData = JSON.parse(localStorage.getItem('user'));
    const instituteId = role === 'teacher' ? userData.instituteId : role === 'institute' ? userData.id : '';

    let classNames = [];
    if (role === 'institute') {
        classNames = ['Class 6', 'Class 7', 'Class 8'];
    } else if (role === 'teacher') {
        if (selectedNavOption === 'LSMT' && lsmtClasses.length > 0) {
            classNames = lsmtClasses.map(classId => `Class ${classId}`);
        }
    }


    // Handle card click to fetch students for a specific class
    const handleCardClick = async (className) => {
        if (!instituteId) {
            console.error('Institute ID is missing in userData.');
            return; // Exit if instituteId is not available
        }
        setLoading(true);
        try {
            const classNumber = className.split(' ')[1];
            console.log(`Data being sent: ${instituteId} and Class: ${classNumber}`);
            const res = await apiJson.post(`/api/v2/lsmtTest/lsmt-attempt-status`, {
                instituteId: instituteId,
                Class: classNumber
            });
            console.log("Response received:", res);
            const studentsList = res?.data?.result || [];

            if (studentsList.length > 0) {
                setStudentsList(studentsList);
            } else {
                setStudentsList([]);
                Popup('error', `No students found for Class ${className}`, '', 5000)
            }

            setClassInfo({ className });
            setSelectedSubNavOption("Student's List");

        } catch (error) {

            if (error?.response?.status === 404) {
                console.error('Error fetching student list:', error);
                Popup('error', `No students found for Class ${className}`, '', 5000)
            } else {
                console.error('Error fetching student list:', error);
                Popup("error", "Error fetching student list", "", 5000)
            }
            setStudentsList([]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
            {loading && <YuvaLoader setShow={false} show={loading} />}
            <Grid container spacing={2} sx={{ height: '100%' }}>
                {classNames.map((className, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <Card
                            onClick={() => handleCardClick(className)}
                            sx={{
                                backgroundColor: '#C7D4FF',
                                borderColor: '#2356fe',
                                borderWidth: 2,
                                borderStyle: 'solid',
                                color: '#2356fe',
                                '&:hover': {
                                    backgroundColor: '#2356fe',
                                    color: '#fff',
                                },
                                transition: 'none',
                                cursor: 'pointer',
                                height: '100%',
                            }}
                        >
                            <CardContent sx={{ textAlign: 'center', height: '100%' }}>
                                <Typography variant="h5" component="div">
                                    {className}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ClassCardView;
