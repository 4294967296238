// import React, { useState, useEffect, useRef } from 'react';
// import { Box, Button, TextField, IconButton, Tooltip, Typography } from '@mui/material';
// import MaterialTable from '@material-table/core';
// import axios from 'axios';
// import PhotoSizeSelectSmallOutlinedIcon from '@mui/icons-material/PhotoSizeSelectSmallOutlined';
// import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';

// const CategoriesDetails = () => {
//     const [data, setData] = useState([]);
//     const [currentId, setCurrentId] = useState(null);
//     const [expandedDescription, setExpandedDescription] = useState({});
//     const newNameRef = useRef("");
//     const descriptionRef = useRef(""); 

//     const institute_id = 15292; 

//     useEffect(() => {
//         const fetchCategories = async () => {
//             try {
//                 const response = await axios.get('http://localhost:3000/api/categories/all');
//                 const filteredData = response.data.filter(category =>
//                     category.register_id === null || category.register_id === undefined || category.register_id === institute_id
//                 );
//                 setData(filteredData);
//             } catch (error) {
//                 console.error("Error fetching categories:", error);
//             }
//         };

//         fetchCategories();
//     }, [institute_id]);

//     const handleEdit = (id) => {
//         setCurrentId(id);
//         const category = data.find(category => category.category_id === id);
//         if (category) {
//             newNameRef.current = category.name; 
//             descriptionRef.current = category.description; 
//         }
//     };

//     const handleSave = async () => {
//         if (currentId) {
//             try {
//                 await axios.patch(`http://localhost:3000/api/categories/update/${currentId}`, {
//                     name: newNameRef.current,
//                     description: descriptionRef.current
//                 });
//                 const updatedData = data.map(category =>
//                     category.category_id === currentId
//                         ? { ...category, name: newNameRef.current, description: descriptionRef.current }
//                         : category
//                 );
//                 setData(updatedData);
//                 setCurrentId(null); 
//                 newNameRef.current = ""; 
//                 descriptionRef.current = ""; 
//             } catch (error) {
//                 console.error("Error updating category:", error);
//             }
//         }
//     };

//     const handleDelete = async (id) => {
//         try {
//             await axios.delete(`http://localhost:3000/api/categories/delete/${id}`);
//             const updatedData = data.filter(category => category.category_id !== id);
//             setData(updatedData);
//         } catch (error) {
//             console.error("Error deleting category:", error);
//         }
//     };

//     const handleToggleDescription = (id) => {
//         setExpandedDescription(prev => ({
//             ...prev,
//             [id]: !prev[id]
//         }));
//     };

//     const columns = [
//         {
//             title: "Category Name",
//             field: "name",
//             cellStyle: {
//                 textAlign: 'left',
//             }
//         },
//         {
//             title: "Description",
//             field: "description",
//             cellStyle: {
//                 textAlign: 'left',
//             },
//             render: (rowData) => (
//                 <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
//                     <Tooltip title={expandedDescription[rowData.category_id] ? "Collapse" : "Expand"}>
//                         <IconButton onClick={() => handleToggleDescription(rowData.category_id)}>
//                             {expandedDescription[rowData.category_id] ? <CloseFullscreenOutlinedIcon /> : <PhotoSizeSelectSmallOutlinedIcon />}
//                         </IconButton>
//                     </Tooltip>
//                     {expandedDescription[rowData.category_id] && (
//                         <Box sx={{
//                             marginTop: '1rem',
//                             padding: '1rem',
//                             border: '1px solid #ddd',
//                             borderRadius: '8px',
//                             backgroundColor: '#f9f9f9',
//                             boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
//                             maxWidth: '100%',
//                             wordWrap: 'break-word'
//                         }}>
//                             <Typography variant="body2" color="textSecondary">
//                                 {rowData.description}
//                             </Typography>
//                         </Box>
//                     )}
//                 </Box>
//             ),
//         },
//         {
//             title: "Action",
//             field: "action",
//             headerStyle: {
//                 textAlign: 'center',
//                 justifyContent: "center",
//                 padding: '0.5rem',
//             },
//             cellStyle: {
//                 textAlign: 'start',
//             },
//             render: (rowData) => (
//                 institute_id === rowData?.register_id ? (
//                     <Box
//                         sx={{
//                             display: "flex",
//                             gap: "1rem",
//                             justifyContent: "center",
//                             width: "100%",
//                         }}
//                     >
//                         {currentId === rowData.category_id ? (
//                             <Box
//                                 sx={{
//                                     display: "flex",
//                                     alignItems: "center",
//                                     gap: "1rem",
//                                     justifyContent: "center",
//                                     width: "100%",
//                                 }}
//                             >
//                                 <TextField
//                                     label="Category Name"
//                                     defaultValue={newNameRef.current}
//                                     onChange={(e) => newNameRef.current = e.target.value}
//                                     fullWidth
//                                     sx={{ flex: 1 }}
//                                 />
//                                 <TextField
//                                     label="Description"
//                                     multiline
//                                     rows={4}
//                                     defaultValue={descriptionRef.current}
//                                     onChange={(e) => descriptionRef.current = e.target.value}
//                                     fullWidth
//                                     sx={{ flex: 1 }}
//                                 />
//                                 <Button
//                                     sx={{
//                                         padding: "10px 1rem",
//                                         backgroundColor: "#2356FE",
//                                         border: "none",
//                                         color: "#FFFFFF",
//                                         fontWeight: "600",
//                                         borderRadius: "10px",
//                                     }}
//                                     variant="contained"
//                                     onClick={handleSave}
//                                 >
//                                     Save
//                                 </Button>
//                             </Box>
//                         ) : (
//                             <Box
//                                 sx={{
//                                     display: "flex",
//                                     gap: "1rem",
//                                     justifyContent: "start",
//                                     width: "100%",
//                                 }}
//                             >
//                                 <Button
//                                     sx={{
//                                         padding: "10px 1rem",
//                                         backgroundColor: "#2356FE",
//                                         border: "none",
//                                         color: "#FFFFFF",
//                                         fontWeight: "600",
//                                         borderRadius: "10px",
//                                     }}
//                                     variant="contained"
//                                     onClick={() => handleEdit(rowData.category_id)}
//                                 >
//                                     Edit
//                                 </Button>
//                                 <Button
//                                     sx={{
//                                         padding: "10px 1rem",
//                                         backgroundColor: "#FF0000",
//                                         border: "none",
//                                         color: "#FFFFFF",
//                                         fontWeight: "600",
//                                         borderRadius: "10px",
//                                     }}
//                                     variant="contained"
//                                     onClick={() => handleDelete(rowData.category_id)}
//                                 >
//                                     Remove
//                                 </Button>
//                             </Box>
//                         )}
//                     </Box>
//                 ) : "-"
//             ),
//         },
//     ];

//     return (

// <Box sx={{
//    width: "100%",
//    display: "flex",
//    flexDirection: "column",
//    alignItems: "center",
   
//   //  marginTop:"5rem",
  
// }}>

// <Typography
//             sx={{
//                 fontFamily: 'Poppins',
//                 fontSize: '1rem',
//                 fontWeight: 400,
//                 lineHeight: '24px',
//                 textAlign: 'left',
//                 color: "#575757",
//                 marginBottom:"3rem",
//             }}
//         >
//             Selecting your category helps us personalize opportunities for you, ensuring that you get the most relevant resources and recommendations from the start.
//         </Typography>



//         <Box
//             sx={{
//                 padding: "1rem",
//                 backgroundColor: "var(--color-ghostwhite)",
//                 position:"relative",
//                 boxSizing: "border-box",
//                 width: "100%",
//                 borderRadius: "1.5rem",
//                 overflow: "hidden",
//                 ":before": {
//                     content: '""', 
//                     background: "linear-gradient(122.52deg, rgba(35, 86, 254, 0.7) 1.25%, rgba(35, 86, 254, 0.1) 98.44%)", 
//                     borderRadius: "1.5rem",
//                     position: "absolute",
//                     top: 0,
//                     bottom: 0,
//                     right: 0,
//                     left: 0,
//                     zIndex: "1", 
//                     margin: "-1rem",
//                 }
//             }}
//         >
           
//            <Box sx={{
//              width: "100%",
//              zIndex: "5",
//              borderTopRadius: "1.5rem",
//              position: "relative",
//               backgroundColor: "#FFFFFF",
//            }} >

// <Box sx={{
//     padding: "0.5rem 0rem 0.5rem 1rem",
//     borderTopLeftRadius: "1.5rem",
//     borderTopRightRadius: "1.5rem",
// }}> 
//     <Typography 
//         variant="h3" 
//         component="div" 
//         gutterBottom 
//         sx={{
//             fontFamily: 'Poppins',
//             fontWeight: 700,
//             textAlign: 'left',
//             // padding: "0.5rem 0rem 0.5rem 1rem",
//         }}
//     >
//         <span style={{ color: "blue" }}>View</span> Category
//     </Typography>
//     <Typography 
//         gutterBottom  
//         component="div" 
//         sx={{
//             fontFamily: 'Poppins',
//             fontWeight: 400,
//             lineHeight: '18.97px',
//             textAlign: 'left',
//             width: "80%",
//             // padding: "0.2rem 0rem 0.5rem 1rem",
//         }}
//     >
//         Explore the categories below to find the services and opportunities that best match your interests and skills.
//     </Typography>
// </Box>

//       <MaterialTable
//         style={{
//         //   boxShadow: "none",
//           width: "100%",
//           zIndex: "5",
//           borderRadius: "1.5rem",
//         }}
//         options={{
//           search: false, 
//           toolbar: false,
//           showTitle: false, 
//           pageSize: 5,
//           paging: true,
//           paginationAlignment: "right",
//           paginationType: "normal",
//           headerStyle: {
//             background: "#F3F3F3",
//             color: '#000000',
//             padding: "1rem",
//             textAlign: 'center',
//             fontWeight: 'bold',
//             fontSize: '1rem',
//             justifyContent: "center",
//             alignItems: "center",
//           },
//           cellStyle: {
//             textAlign: 'center',
//           },
//         }}
//         columns={columns}
//         data={data}
//       />
//     </Box>

        
//         </Box>
//         </Box>
//     );
// };

// export default CategoriesDetails;

import React, { useState, useEffect } from 'react';
import {
    Box, Button, TextField, IconButton, Tooltip, Typography,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TablePagination, InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import AddCategory from './AddCategory';
import { useParams } from 'react-router-dom';

import Loader from '../SewaPage/Loader';
import { CustomPagination } from './ProjectDetails';
import { apiSewa } from 'api';


const CategoriesDetails = () => {
    const [data, setData] = useState([]);
    const [currentId, setCurrentId] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [showCategoryForm, setShowCategoryForm] = useState(false);
    const [newName, setNewName] = useState("");
    const [newDescription, setNewDescription] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [fullDescription, setFullDescription] = useState("");
    const [category , setCategory] = useState("");
    const [loading , setloading] = useState(false);

    const { instituteId } = useParams();

    const institute_id = instituteId;
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setloading(true);
                const response = await apiSewa.get(`/categories/all`);
                const filteredData = response.data.filter(category => {
                    const registerId = category.register_id;
                    console.log("Type of registerId:", typeof registerId);

                    return registerId === undefined || registerId === null || registerId == institute_id;
                });
                
                setData(filteredData);
                setloading(false);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchCategories();
    }, [institute_id]);

    const handleEdit = (id) => {
        setCurrentId(id);
        const category = data.find(category => category.category_id === id);
        if (category) {
            setNewName(category.name);
            setNewDescription(category.description);
        }
    };

    const handleSave = async () => {
        if (currentId) {
            try {

                await apiSewa.patch(`/categories/update/${currentId}`, {
                    name: newName,
                    description: newDescription
                });
                const updatedData = data.map(category =>
                    category.category_id === currentId
                        ? { ...category, name: newName, description: newDescription }
                        : category
                );
                setData(updatedData);
                setCurrentId(null);
                setNewName("");
                setNewDescription("");
                
            } catch (error) {
                console.error("Error updating category:", error);
            }
        }
    };

    const handleAddCategory = () => {
        setShowCategoryForm(true);
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this category?");
        if (confirmDelete) {
            try {
                setloading(true);
                await apiSewa.delete(`/categories/delete/${id}`);
                const updatedData = data.filter(category => category.category_id !== id);
                setData(updatedData);
                setloading(false)
            } catch (error) {
                console.error("Error deleting category:", error);
            }
        }
    };

    const handleOpenDialog = (description , cat) => {
        setFullDescription(description);
        setCategory(cat);
        setOpenDialog(true);
    };


    const handleCloseDialog = () => {
        setOpenDialog(false);
        setFullDescription("");
    };


    // const handleToggleDescription = (id) => {
    //     setExpandedDescription(prev => ({
    //         ...prev,
    //         [id]: !prev[id]
    //     }));
    // };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter(category =>
        category.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length - page * rowsPerPage);

    if (showCategoryForm) {
        return <AddCategory setShowCategoryForm={setShowCategoryForm}/>;
    }

    if (loading) {
        return <Loader text={'Wait for a Moment'} />;
    }

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: { xs: "1px" , md:"5px" } }}>
            <Typography
                sx={{
                    fontFamily: 'Poppins',
                    fontSize: { xs: '0.875rem', sm: '1rem' }, 
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left',
                    color: "#575757",
                    marginBottom: "3rem",
                    marginTop: {xl : "1.2rem" , xs:"1px"},
                }}
            >
                Selecting your category helps us personalize opportunities for you, ensuring that you get the most relevant resources and recommendations from the start.
            </Typography>
    
            <Box
                sx={{
                    padding: "1rem",
                    backgroundColor: "var(--color-ghostwhite)",
                    position: "relative",
                    boxSizing: "border-box",
                    width: "100%",
                    borderRadius: "1rem",
                    overflow: "hidden",
                    ":before": {
                        content: '""',
                        background: "linear-gradient(122.52deg, rgba(35, 86, 254, 0.7) 1.25%, rgba(35, 86, 254, 0.1) 98.44%)",
                        borderRadius: "1rem",
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        zIndex: "1",
                        margin: "-1rem",
                    }
                }}
            >
                <Box sx={{
                    width: "100%",
                    zIndex: "5",
                    position: "relative",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "1rem",
                }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" }, 
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            padding: {md: "1.5rem 1rem" , xl: "2rem 1.5rem" , xs:"1rem 0.5rem"},
                            borderTopLeftRadius: "1rem",
                            borderTopRightRadius: "1rem",
                        }}
                    >
                        <Box sx={{ width: { xs: "100%", sm: "90%" } }}>
                            <Typography
                                variant="h4"
                                component="div"
                                gutterBottom
                                sx={{
                                    fontFamily: 'Poppins',
                                    fontWeight: 700,
                                    textAlign: 'left',
                                }}
                            >
                                <span style={{ color: "#2356FE" }}>View</span> Category
                            </Typography>
                            <Typography
                                gutterBottom
                                component="div"
                                sx={{
                                    fontFamily: 'Poppins',
                                    fontWeight: 400,
                                    lineHeight: '18.97px',
                                    fontSize:{md:"14px" , xs:"12px"},
                                    textAlign: 'left',
                                    color: "#6D6D6D",
                                }}
                            >
                                Explore the categories below to find the services and opportunities that best match your interests and skills.
                            </Typography>
                        </Box>
    
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                                alignItems: {md:"flex-end" , xs:"center"},
                                justifyContent: {md:"flex-end" , xs:"center"},
                                marginTop: "1rem",
                                width: { xs: "100%", sm: "auto" },
                            }}
                        >
                            <div style={{
                                position: 'relative',
                                width: '100%', 
                                maxWidth: '16rem', 
                                height: '2.5rem',
                                backgroundColor: '#F4F4F4',
                                borderRadius: '0.7rem',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '0 12px'
                            }}>
                                <SearchIcon style={{ color: '#333', marginRight: '8px' }} />
                                <input
                                    type="text"
                                    placeholder="Search Categories"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        border: 'none',
                                        outline: 'none',
                                        backgroundColor: 'transparent',
                                        fontSize: '1rem',
                                        color: '#333',
                                        padding: '0',
                                    }}
                                />
                            </div>
                            <Button
                                onClick={handleAddCategory}
                                variant="contained"
                                sx={{
                                    zIndex: 5,
                                    color: "#FFFFFF",
                                    width: { xs: "50%", sm: "9rem" }, 
                                    fontSize: {md:"12px" , xs:"9px"},
                                    padding: {md: "8px 8px" , xs:"4px 6px"},
                                    borderRadius: {md:"0.7rem" , xs:"5px"},
                                    justifyContent:"center",
                                    backgroundColor: "#2356FE",
                                    '&:hover': {
                                        backgroundColor: "#2336FE",
                                        boxShadow: "0 6px 18px rgba(0, 0, 0, 0.15)",
                                    },
                                    transition: "all 0.3s ease",
                                }}
                            >
                                Add Category
                            </Button>
                        </Box>
                    </Box>
    
                    <TableContainer component={Paper} sx={{minHeight:{md:"26rem" , xs:"23rem"}}}>
                        <Table sx={{ minWidth: 700 }} aria-label="category table">
                            <TableHead style={{ backgroundColor: "#F3F3F3" }}>
                                <TableRow>
                                    <TableCell align="start" sx={{ fontWeight: 600, fontSize: "1rem", fontFamily: "Poppins", color: "#000000" }}>
                                        Category Name
                                    </TableCell>
                                    <TableCell align="start" sx={{ fontWeight: 600, fontSize: "1rem", fontFamily: "Poppins", color: "#000000" }}>
                                        Description
                                    </TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 600, fontSize: "1rem", fontFamily: "Poppins", color: "#000000" }}>
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
    
                            <TableBody>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                    <TableRow key={row.category_id}>
                                      <TableCell component="th" scope="row" marginLeft="1rem">
  {currentId === row.category_id ? (
    <TextField
      value={newName}
      onChange={(e) => setNewName(e.target.value)}
      fullWidth
      inputProps={{
        maxLength: 50, 
      }}
      sx={{
        wordBreak: "break-word",  
        fontSize: { md: "1rem", xs: "0.75rem" },
        maxWidth:{md: "9rem" , xs:"8rem"}, 
      }}
    />
  ) : (
    <span style={{ 
      wordBreak: "break-word", 
      display: "inline-block", 
      whiteSpace: "normal", 
      fontSize: { md: "1rem", xs: "0.75rem" }, 
      maxWidth:{md: "9rem" , xs:"8rem"}, 
      overflowWrap: "break-word", 
      overflow: "hidden", 
      textOverflow: "ellipsis"  
    }}>
      {row.name}
    </span>
  )}
</TableCell>

                                        <TableCell align="start">
                                            <Button onClick={() => handleOpenDialog(row.description, row.name)} variant="text" sx={{
                                                color: "#2356FE",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: {md:"1rem" , xs:"0.75rem"}
                                            }}>
                                                View More
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                            {institute_id == row?.register_id ? (
                                                <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                                                    {currentId == row.category_id ? (
                                                        <Button
                                                            onClick={handleSave}
                                                            variant="outlined"
                                                            sx={{
                                                                color: "#000000",
                                                                borderRadius: "0.5rem",
                                                                padding: "5px 1em",
                                                                borderColor: "green",
                                                            }}
                                                        >
                                                            Save
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            onClick={() => handleEdit(row.category_id)}
                                                            variant="outlined"
                                                            sx={{
                                                                color: "#000000",
                                                                borderRadius: "0.5rem",
                                                                padding: "5px 1em",
                                                                borderColor: "blue",
                                                            }}
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}
                                                    <Button
                                                        onClick={() => handleDelete(row.category_id)}
                                                        variant="outlined"
                                                        sx={{
                                                            color: "#000000",
                                                            borderRadius: "0.5rem",
                                                            padding: {md:"5px 1em" , xs:"3px 6px"},
                                                            borderColor: "red",
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>
                                                </Box>
                                            ) : (
                                                <button style={{
                                                    width: '108px',
                                                    height: '32px',
                                                    padding: {md:'5px 23px 5px 15px' , xs:"3px 10px 3px 10px"},
                                                    gap: '2.66px',
                                                    backgroundColor: '#E2E2E2',
                                                    borderRadius: '5.31px 0px 0px 0px',
                                                    border: 'none',
                                                    cursor: 'not-allowed'
                                                }}>No Action</button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {/* {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )} */}
                            </TableBody>
                        </Table>
                    </TableContainer>
    
                    {/* <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ padding: 2 }}>
                        <Typography variant="body1"></Typography>
                        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                sx={{ flexGrow: 1 }}
                            />
                        </Box>
                    </Box> */}

<Box
    sx={{
        display: 'flex',
        justifyContent: { xs: 'center', md: 'space-between' }, // Center on small screens, space-between on larger
        alignItems: 'center',
        padding: '0.5rem 1rem',
        zIndex: "5",
        flexWrap: 'wrap', 
        gap: { xs: 2, md: 0 }, 
    }}
>
    <TablePagination
        component="div"
        count={10}
        page={0}
        onPageChange={() => {}}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Rows per page"
        sx={{
            flexShrink: 0,
            '& .MuiTablePagination-toolbar': {
                paddingLeft: { xs: 0, md: '16px' },
            },
            '& .MuiTablePagination-selectLabel': {
                marginLeft: 2,
            },
            '& .MuiSelect-select': {
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '6px 8px',
            },
        }}
        labelDisplayedRows={() => ''}
        slotProps={{
            actions: {
                nextButton: { sx: { display: 'none' } },
                backButton: { sx: { display: 'none' } },
                firstButton: { sx: { display: 'none' } },
                lastButton: { sx: { display: 'none' } },
            },
        }}
    />

    <CustomPagination
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        sx={{
            marginTop: { xs: '1rem', md: 0 }, 
            width: { xs: '100%', md: 'auto' }, 
        }}
    />
</Box>
    
                    <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            '& .MuiPaper-root': {
                                width: { xs: "90%", sm: "350px" }, 
                                height: "300px",
                                maxWidth: "none",
                                borderRadius: "1rem",
                                background: "#2356FE",
                                padding:"5px"
                            }
                        }}
                    >
                        <DialogTitle
                            sx={{
                                background: "#2356FE",
                                color: "#FFD700",
                                padding: "1rem",
                            }}
                        >
                            {category}
                        </DialogTitle>
                        <DialogContent
                            sx={{
                                background: "#2356FE",
                                display: 'flex',
                                flexDirection: 'column',
                                padding: "1rem",
                                justifyItems: "left",
                                height: '100%',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: "#fff",
                                    textAlign: 'left',
                                }}
                            >
                                {fullDescription}
                            </Typography>
                        </DialogContent>
                    </Dialog>
    
                </Box>
            </Box>
        </Box>
    );
    
};

export default CategoriesDetails;



// <TableCell align="center">
// { institute_id === row?.register_id ? ( <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
//      {currentId === row.category_id ? (
//          <Button
//              onClick={handleSave}
//              variant="contained"
//              sx={{ 
//                  backgroundColor: "green", 
//                  color: "#fff", 
//                  borderRadius: "0.5rem", 
//                  padding: "5px 1em", 
//              }}
//          >
//              Save
//          </Button>
//      ) : (
//          <Button
//              onClick={() => handleEdit(row.category_id)}
//              variant="contained"
//              sx={{ 
//                  backgroundColor: "blue", 
//                  color: "#fff", 
//                  borderRadius: "0.5rem", 
//                  padding: "5px 1em", 
//              }}
//          >
//              Edit
//          </Button>
//      )}
//      <Button
//          onClick={() => handleDelete(row.category_id)}
//          variant="contained"
//          sx={{
//              backgroundColor: "red",
//              color: "#fff",
//              borderRadius: "0.5rem",
//              padding: "5px 1em",
//          }}
//      >
//          Remove
//      </Button>
//  </Box>) : "-"}
// </TableCell>