import { apiJson } from 'api';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PendingIcon from '@mui/icons-material/Pending';
import TaskIcon from '@mui/icons-material/Task';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { Button } from '@mui/joy';

export const ViewerCallStatus = () => {
    const [callNotConnected, setCallNotConnected] = useState([]);
    const navigate = useNavigate();

    // viewer call connected
    const getViewerCallStatus = ()=>{
        apiJson.get('api/v2/admin/getViewerCallStatus')
        .then((res)=>{
            setCallNotConnected(res?.data?.getCallDisconnetedData)
        })
        .catch((err)=>{
            console.log(err.message);
        })
    }

    useEffect(()=>{
        getViewerCallStatus()
    },[])
  return (
    <div>
        <div className='col-10 mx-auto'>
            <div className='d-flex align-items-center justify-content-between'>
              <div><span className='fw-500 fs-4'>Viewer Call Status</span></div>

              <div><Button onClick={()=>{navigate('/viewer/ViewerFollowUp')}}>Follow-Up</Button></div>
              </div>

            <div>
            <div className='row'>
        {callNotConnected?.map((ele, i)=>{
          return (
            <div key={ele?.id}
            //  onClick={()=> {navigate(`/viewer/ViewerSingleStatus/${ele?.schoolId}`)}} className='col-12 col-md-6 col-lg-4 rounded shadow-sm border-3 text-center mt-5 p-3 cursor-pointer'
            onClick={(ele?.call_status !== 'connected' && ele?.detail_sent_status == 'sent' && ele?.registration_status == 'done') || 
              ( ele?.call_status !== 'connected' && ele?.detail_sent_status == 'notsent' && ele?.registration_status == 'pending') ||
              (ele?.call_status !== 'connected' && ele?.detail_sent_status == 'sent' && ele?.registration_status == 'pending' ) ||
              (ele?.call_status !== 'connected' && ele?.detail_sent_status == 'notsent' && ele?.registration_status == 'done' )             
               ? () => navigate(`/viewer/ViewerSingleStatus/${ele?.schoolId}`) : null}
              className={`col-12 col-md-6 col-lg-4 rounded shadow-sm border-3 text-center mt-5 p-3 cursor-pointer
               ${
                 (ele?.call_status == 'connected' && ele?.detail_sent_status == 'sent' && ele?.registration_status == 'done') || 
              ( ele?.call_status == 'connected' && ele?.detail_sent_status == 'notsent' && ele?.registration_status == 'pending') ||
              (ele?.call_status == 'connected' && ele?.detail_sent_status == 'sent' && ele?.registration_status == 'pending' ) ||
              (ele?.call_status == 'connected' && ele?.detail_sent_status == 'notsent' && ele?.registration_status == 'done' )
                 ? 'btn-onboard-fill-disabled-Inter' : null}`
               }
             >
              <div>{i+1}</div>
                                    <div className='mt-1'>
                                        <div><span className='fw-600 fs-6'>Institute Name: </span><span> {ele?.institution_name}</span></div>
                                    </div>
                                    {ele?.project ?
                                    <div className='mt-1'>
                                        <div><span className='fw-600 fs-6'>Project: </span><span className='fw-500'>{ele?.project}</span></div>
                                    </div> : ''}
                                    <div className='mt-1'>
                                    <div><span className='text-danger fw-bold'>{
                                 ele?.call_status === 'disconnected'
                                  ? <span>Call Disconnected  <PhoneDisabledIcon/></span>
                                  : ele?.call_status === 'ringing'
                                  ? <span>Ringing  <RingVolumeIcon/></span>
                                  : ele?.call_status === 'not interested'
                                  ? <span>Not Interested  <PhoneDisabledIcon/></span>
                                  : ele?.call_status === 'wrongnumber'
                                    ?  <span>Call Wrong Number  <PhoneDisabledIcon/></span>
                                    : ele?.call_status === 'cancelled'
                                      ? <span>Call Cancelled  <PhoneDisabledIcon/></span>
                                      : ''}</span></div>
                                    </div>

                                    <div className='fw-bold'>
                                        <div><span className={ele?.registration_status === 'done' ? 'text-success' : 'text-danger'}>{ele?.registration_status === 'done' ? <span>Registration Done <HowToRegIcon/></span> : ele?.registration_status === 'pending' ? <span>Registration Pending <PendingIcon/></span> : ''}</span></div>
                                    </div>
                                    <div className=' fw-bold'>
                                        <div><span className={ele?.detail_sent_status === 'sent' ? 'text-success' : 'text-danger'}>{ele?.detail_sent_status === 'sent' ?  <span>Details Sent <TaskIcon/></span> : ele?.detail_sent_status === 'notsent' ?  <span>Details Not Sent <AssignmentLateIcon/></span> : '' }</span></div>
                                    </div>
                                    <div className='mb-2'>
                                        <div>{ele?.reminder_status ? <span className='fw-bold text-warning'>Reminder {ele?.reminder_status} <PendingActionsIcon className='blink_reminder'/></span> : ""}</div>
                                    </div>
                                </div>
          )
        })}
      </div>
            </div>
        </div>
    </div>
  )
}
