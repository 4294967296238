import React, { useContext, useEffect, useState } from 'react'
import { Card, Modal } from 'react-bootstrap';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Divider from '@mui/material/Divider';
import ModelUnContext from '../context/contextModelUn';
import { apiJson, apiJsonAuth } from 'api';
import { useGlobalContext } from 'global/context';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Input from '@mui/joy/Input';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Swal from 'sweetalert2';
import { Button } from "@mui/material";

export const ModelunManageSlot = () => {
  const { userData } = useGlobalContext()
  const { format } = useContext(ModelUnContext)
  const [selectedType, setSelectedType] = useState(eventType[0]?.type);
  const [selectedEventType, setSelectedEventType] = useState()
  const [selectedFormatData, setSelectedFormatData] = useState();
  const [EventModal, setEventModal] = useState(false)
  const [eventText, setEventText] = useState('')
  const [getEventDetails, setEventDetail] = useState()
  const [committeeArray, setCommitteeArray] = useState([])
  const [committeeForm, setCommitteeForm] = useState();
 const  [isloading,setIsLoading] = useState(false)
 const [inputClicked, setInputClicked] = useState(false);
 const [showDetailModal, setShowDetailModal] = useState(false)
const [comIndex, setcomIndex] = useState(false)
 const LIFEEVENT = [
  'COP29','All India Political Parties Meet (AIPPM)','UNDP (United Nations Development Programme)',
  'UNEP (United Nations Environment Programme)','ECOSOC (United Nations Economic and Social Council)',
]

  const formik =useFormik({
    initialValues : {
      eventType : "Intra Institutional",
      format : "",
      eventTheme : "",
      subTheme : "",
      slectedCommittee : [],
      last_date : "",
      date_proposed : "",
      event_venue:getEventDetails?.eventDetails?.event_venue || "",
      event_time:"",
    },
  onSubmit : async(values) =>{
    const hasInvalidSlots = committeeForm.some(item => item.slots < item?.minSlots  || item?.slots == null);
    if(committeeForm?.length < 1){
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please select at least one committee.",
      });
      return;
    }
    if(committeeForm?.length > 3){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "It is not possible to select more than three committees.",
      });
      return;
    }
    if (hasInvalidSlots) {
          toast.dismiss();
          toast.error('Slot value should be greater or equal to min Requirement.');
          return
        }
    // Perform your existing logic when slots are valid
   
    if(getEventDetails && !hasInvalidSlots){
      setIsLoading(true)
     try {
       const res = await apiJsonAuth.put(`api/v2/modelUn-institute/EditEventDetailsWithSlots/${getEventDetails?.eventDetails?.id}/instituteId/${userData?.id}`,values)
       if(res.status===200){
         setInputClicked(false);
         setIsLoading(false)
         particularEventDetails()
         toast.dismiss()
         toast.success('Event Details updated successfully.')
       }
     } catch (error) {
       setIsLoading(false)
       setInputClicked(false);
       toast.dismiss();
       toast.error('server eroor')
     }
    }
  
  }
  })
  const getAllCommittee = ()=>{
    apiJson('api/v2/modelUn-institute/getAllCommitee')
    .then((res)=>{
      if(formik?.values?.format==="Lifestyle for Environment in Partnership with UNDP"){
      setCommitteeArray(res?.data?.result?.filter((i)=>LIFEEVENT.includes(i?.committees)))

      }
      else{
        setCommitteeArray(res.data.result)
      }
    })
  }
  useEffect(()=>{
    getAllCommittee()
  },[formik?.values?.format])
  const particularEventDetails = async () => {
    try {
      const res = await apiJsonAuth.get(`api/v2/modelUn-institute/getEventDetailsWithSlot/${userData?.id}`)
      setEventDetail(res?.data)
      setCommitteeForm(res?.data?.slectedCommittee)
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (userData?.id) {
      particularEventDetails()
    }
  }, [userData?.id])
  useEffect(()=>{
    if(getEventDetails){

      formik.setValues({
        eventType: getEventDetails?.eventDetails?.event_type || "",
        format: getEventDetails?.eventDetails?.format || "",
        eventTheme: getEventDetails?.eventDetails?.event_theme || "",
        subTheme:getEventDetails?.eventDetails?.sub_theme || "",
        last_date:getEventDetails?.eventDetails?.last_date || "",
        date_proposed:getEventDetails?.eventDetails?.date_proposed || "",
        event_venue: getEventDetails?.eventDetails?.event_venue || "",
        event_time: getEventDetails?.eventDetails?.event_time || "",
        slectedCommittee : getEventDetails?.slectedCommittee || []
      });
    }
  },[getEventDetails])

  useEffect(() => {
    if (format?.length > 0 && getEventDetails) {
      setSelectedFormatData(format?.find((item) => item?.event_format === getEventDetails?.eventDetails?.format))
      setSelectedEventType(eventType?.find((i) => i?.type === getEventDetails?.eventDetails?.event_type))
    }
  }, [format,getEventDetails])

  // =========== Find event format which is selected ==========\\


  const handleEventModal = (modal) => {
    setEventText(modal)
    setEventModal(true)
  };
  const hideEventModal = () => {
    setEventModal(false)
  }
  const formatDate = (inputDate) => {
    if (!inputDate) return ''; // Handle null or undefined case
    const dateObject = new Date(inputDate);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const handlePositionsChange = (e,title,change,id, minSlots) => {
    const isAlreadyAdded = committeeForm?.some((item) => item?.committee === title);

    if (isAlreadyAdded) {
     
      const newSlotsValue = parseInt(e.target.value);
      if(!isNaN(newSlotsValue) && change){
        setCommitteeForm((prevForm) =>
        prevForm.map((item) =>
          item.committee === title
            ? { ...item, slots: parseInt(e.target.value), committeeId:id, minSlots }
            : item
        )
      );
      }
      else{
      setCommitteeForm((prevForm) => prevForm?.filter((item) => item?.committee !== title));
      }
    } else {
      setCommitteeForm((prevForm) => [...prevForm, { committee: title, slots: change && e.target.value, committeeId:id, minSlots}]);
    }
};

const handleTrack = (track, committeeId) => {

  const hasG20Committee = committeeForm?.find((ele) => ele?.committee === "G20");

  if (hasG20Committee) {
    const existingCommitteeForm = [...committeeForm];

    const index = existingCommitteeForm.findIndex((ele) => ele?.committee === "G20");
    if (index !== -1) {
      let tracksTemp = existingCommitteeForm[index]?.tracks || [];
      let currentValue = existingCommitteeForm[index]?.slots || 0;
      // Check if the track is already selected
      const isTrackSelected = tracksTemp.some((t) => t.name === track);
      if (isTrackSelected) {
        // If track is selected, decrease the value by 20
        const updatedValue = Number(currentValue) - 20;
        existingCommitteeForm[index] = {
          ...existingCommitteeForm[index],
          tracks: tracksTemp.filter((t) => t.name !== track),
          slots: updatedValue >= 0 ? updatedValue : 0,
        };
        
      } else {
        // If track is not selected, increase the value by 20
        const updatedValue = Number(currentValue) + 20;
        existingCommitteeForm[index] = {
          ...existingCommitteeForm[index],
          tracks: [...tracksTemp, { name: track }],
          slots: updatedValue,
        };
      }
      
      // Use setCommitteeForm to update the state
      setCommitteeForm(existingCommitteeForm);

    }
  }
};
const handleSelectedCommittee = ()=>{
  const hasInvalidSlots = committeeForm.some(item => item.slots <item?.minSlots || item?.slots == null);
  if (!hasInvalidSlots) {
    formik.setFieldValue('slectedCommittee', committeeForm);
  }
}

const handleClickSlots=(e,title)=>{
  const isAlreadyAdded = committeeForm?.some((item) => item?.committee === title);
  if (isAlreadyAdded && !inputClicked) {
  Swal.fire({
    icon: "warning",
    title:"Are you sure?",
    html: `<p>If you do not want this committee, then give it to another committee for those students who were selected for it.</p>
     <p>If you do not wish to remove this committee, you can increase or decrease its slot.</p>`,
    
  });
  setInputClicked(true);
}
}

/// handle change the p

  return (
    <>
      <div className="col-12 col-md-11 px-1 mt-4">
      <form onSubmit={formik.handleSubmit}>
        <div className='d-flex justify-content-between'>
            <span className="fw-500 fs-32px" style={{ color: "#000000" }}><KeyboardArrowLeftIcon sx={{ color: "#7700FF" }} />  Manage Event</span>
            {isloading ? (<LoadingButton
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              color="secondary"
            >
              Saving
            </LoadingButton>) : (
              <button type='submit' onClick={handleSelectedCommittee} className='px-4 py-2 rounded-3' style={{ background: "#7000FF", color: "#ffffff" }}>Save</button>
            )}
        </div>
      
          <div className="mt-4">
            <span className="fw-semibold fs-25px">Event Type</span> &nbsp; <span className='fw-normal fs-25px cursor-pointer' style={{ color: "#3300FF", }} onClick={() => handleEventModal('eventType')}>Change</span>
          </div>

          <div className="col-12">
            <div className=" d-flex flex-column justify-content-between mt-3" >
              <Card
                style={{ borderColor: "transparent", background: "#FAFAFA" }}
                className={`cursor-pointer rounded-4 mb-4`}
              >
                <Card.Body className=""  >
                  <div className="form-check d-flex align-items-center">
                    <span>
                      <input
                        className="form-check-input "
                        type="radio"
                        name="eventType"
                        id={selectedEventType?.type}
                        checked={eventType?.find((i) => i?.type === getEventDetails?.eventDetails?.event_type)}
                      />
                    </span>
                    <div className="form-check-label ms-2 " htmlFor={selectedEventType?.type}>
                      <div>
                        <span className="fw-500 fs-22px">
                          {selectedEventType?.type}
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            color: "#9A9A9A",
                          }}
                          className="fs-15px fw-500"
                        >
                          {selectedEventType?.body}
                        </span>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
          <Divider  sx={{color:"#0000000D",height:"2px"}}/>
          {/*============ Event Format============= */}
          <div className="mt-4">
            <span className="fw-semibold fs-25px">Event Format</span> &nbsp; <span className='fw-normal fs-25px cursor-pointer' style={{ color: "#3300FF", }} onClick={() => handleEventModal('eventFormat')}>Change</span>
          </div>

          <div className="col-12">
            <div className=" d-flex flex-column justify-content-between mt-3" >
              <Card
                style={{ borderColor: "transparent", background: "#FAFAFA" }}
                className={`cursor-pointer rounded-4 mb-4`}
              >
                <Card.Body className=""  >
                  <div className="form-check d-flex align-items-center ">
                    <span>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="format"
                        checked={format?.find((item) => item?.event_format === getEventDetails?.eventDetails?.format)}
                      />
                    </span>
                    <div className="form-check-label ms-2 " htmlFor={selectedFormatData?.event_format}>
                      <div>
                        <span className="fw-500 fs-22px">
                          {selectedFormatData?.event_format}
                        </span>
                      </div>
                      <div>
                        <span
                          style={{ color: "#9A9A9A" }}
                          className="fs-15px fw-500"
                        >
                          {selectedFormatData?.description}
                        </span>
                      </div>
                      <div>
                        <span
                          style={{ color: "#9A9A9A" }}
                          className="fs-13px fw-500"
                        >
                          {selectedFormatData?.event_content}
                        </span>
                      </div>
                    </div>

                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
          <span className='text-muted fs-20px' style={{ marginTop: "60px" }}>Choose a theme for the event that you want to organise.</span>
          <p className='fs-25px fs-semibold mt-2' style={{ color: "#0000001A" }}>Sub Theme</p>
          {/* <div className="w-100 rounded-3 px-3 d-flex align-items-center" style={{background:"#F9F9F9",height:"49px"}}><span className='py-4 text-muted'>Not Available</span></div> */}
          <div className="w-100">
            <Select
              disabled
              placeholder="Not Available"
              indicator={<KeyboardArrowDown />}
              sx={{
                background: "#F9F9F9",
                height: 45,
                width: "100%",
                [`& .${selectClasses.indicator}`]: {
                  transition: '0.2s',
                  [`&.${selectClasses.expanded}`]: {
                    transform: 'rotate(-180deg)',
                  },
                },
              }}
            >
              <Option value="dog">Dog</Option>
            </Select>
          </div>
          <div className="my-4">
            <Divider  sx={{color:"#0000000D",height:"2px"}}/>
          </div>
          {/*========== Event Schedule manage ============= */}
          <div className="mt-4">
            <span className="fw-semibold fs-25px">Event Schedule</span> &nbsp;
          </div>
          <div className="row ">
            <div className="col-12 col-md-6 mb-3 mt-3">
              <label htmlFor="event_venue" className='fs-20px fw-normal' style={{ color: "#979797" }}>Event Venue</label>
              <Input placeholder="Type in here…" id='event_venue' name="event_venue" size="lg" sx={{ width: '100%', background: "#F9F9F9", }} onChange={formik.handleChange} value={formik.values.event_venue} />
            </div>
            <div className="col-12 col-md-6 mb-3 mt-md-3">
              <label htmlFor="lats_date" className='fs-20px fw-normal' style={{ color: "#979797" }}>Last Date of registration</label>
              <Input  type='date' value={formatDate(formik.values?.last_date)} onChange={(e) => formik.setFieldValue('last_date', e.target.value)} id='lats_date' size="lg" sx={{ width: '100%', background: "#F9F9F9", }} />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label htmlFor="event_date" className='fs-20px fw-normal' style={{ color: "#979797" }}>Date of Event</label>
              <Input type='date'  id='event_date' value={formatDate(formik.values?.date_proposed)} sx={{ width: '100%', background: "#F9F9F9", height:"45px" }}  onChange={(e) => formik.setFieldValue('date_proposed', e.target.value)}/>
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label htmlFor="event_time" className='fs-20px fw-normal' style={{ color: "#979797" }}>Time of Event</label>
              <Input type='time' id='event_time' size="lg" value={formik.values?.event_time} onChange={formik.handleChange} sx={{ width: '100%', background: "#F9F9F9", }} />
            </div>
          </div>
          <div className="my-4">
            <Divider  sx={{color:"#0000000D",height:"2px"}}/>
          </div>
    {/* ============ Slot management ==============*/}
          <div className="col-12">

            <div className="row ">

              <div className="d-flex justify-content-between col-12 col-md-8">
                <div><span className="fs-19px fw-600">Committee</span></div>
                <div><span className="fs-19px fw-600">No. of Students</span></div>
              </div>

              <div className="">
                <div className="row">
                  {committeeArray?.map((ele, i) => {
                      const committeeItem = committeeForm?.find((item, i) => item?.committee === ele?.committees) || { committee: "", slots: 0};
                    return (
                      <>
                        <div key={i} className={`col-6 col-lg-6 mt-3  ${ele?.diabledcss ? "btn-onboard-fill-disabled-Committee" : null}`}>
                          <div className="d-flex align-items-center">
                            <div>
                            <input
                              style={{ height: "18px", width: "18px" }}
                              onClick={() => ele?.committees === "G20"}
                              className="inputcheck"
                              type="checkbox"
                              name="committee"
                               id={ele?.committees}
                               checked={committeeItem?.committee === ele?.committees} onChange={(e)=>{
                                handlePositionsChange(e,ele?.committees, false, ele?.id,ele?.minSlots)}}
                            />
                            </div>

                            <div className="col-12 ms-lg-3">
                              <span className="committee-content fw-500 d-block" style={{ fontFamily: "Poppins" }}>{ele?.committees}</span>
                              {/* <span className="fs-19px fw-600" style={{ color: "#4A00E8", fontFamily: "Poppins" }}>{ele?.range}</span> */}
                            </div>
                          </div>
                        </div>

                        <div className={`col-6 col-lg-6 mt-3  `}>
                          <div className="d-flex justify-content-start justify-content-md-around align-items-center MunSlotsContainer " >
                            <div>

                              <div className="MunSlots">
                                <input className={`MunSlotsInput ${ele?.committees === "G20" || ele?.committees === "G20" && committeeForm?.find((i) => i?.committee === "G20")?.tracks?.length > 0 ? "btn-onboard-fill-disabled-Committee" : null} `}
                                htmlFor ={ele?.committees}
                                  placeholder={`Min ${ele?.minSlots}`}
                                  type="number" name="slots" 
                                  onClick={(e)=>handleClickSlots(e,ele?.committees)}
                                  value={committeeForm?.find((i)=>i?.committee===ele?.committees)?.slots || ""} onChange={(e)=> handlePositionsChange(e,ele?.committees, true, ele.id, ele?.minSlots)}
                                  disabled={ele?.committees === "G20" || ele?.committees === "G20" && committeeForm?.find((i) =>i?.committee === "G20")?.tracks?.length > 0}
                                />
                              </div>

                            </div>
                            {ele?.detail && <div className=" cursor-pointer">
                              <Button onClick={() => { setShowDetailModal(true); setcomIndex(i) }} className="fw-semibold rounded-3 fs-6 text-capitalize" style={{ color: "#4A00E8" }}>Details</Button>
                            </div>}
                          </div>
                        </div>

                        <div>
                          {ele?.committees === "G20" && (
                            <div>
                              {committeeItem?.committee === ele?.committees &&
                                <div>
                                  <div>
                                    <span>Select Track</span>
                                  </div>
                                  {committeeTrack?.map((ele, i) => {
                                    const committeeTracks =
                                    committeeForm?.find((item, i) => item?.committee === "G20") || {
                                      committee: "",
                                      slots: 0,
                                    };
                                  
                                  const committeeTrackMap = Object.fromEntries(
                                    committeeTracks.tracks?.map((item) => [item.name, item]) || []
                                  );
        
                                  const trackInfo = committeeTrackMap[ele?.track] || {
                                    committee: "",
                                    slots: 0,
                                  };


                                    return (
                                      <div key={ele?.id}>
                                        <div className="expand">
                                          <div className="col-3">
                                            <div className="d-flex align-items-center mt-2">
                                              <div>
                                                <input
                                                  type="checkbox"
                                                  className="inputcheck"
                                                  checked={trackInfo?.name === ele?.track} 
                                                  name="tracks"
                                                  style={{ width: "18px", height: "18px" }}
                                                  onChange={(e) => handleTrack(ele?.track, ele.id)}
                                                />
                                              </div>
                                              <div>
                                                <span>{ele?.track}</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>

                              }
                            </div>
                          )}
                        </div>
                        <div className="col-10">
                          <hr style={{ color: "#F1F1F1" }} />
                        </div>
                      </>
                    )
                  })
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/*=========== A modal for change event type and event format =========== */}
      <Modal
        size='lg'
        show={EventModal}
        onHide={hideEventModal}
      >

        <div className="px-3 py-4">
          {eventText == "eventType" ? (
            <>
              <p className='fw-normal fs-24px'>Change the event type</p>
              {eventType.map((acc, index) => {
                return (
                  <>
                    {!acc.showFor && (
                      <Card
                        onClick={() => setSelectedType(acc.type)}
                        style={{ borderColor: "transparent", background: "#FAFAFA" }}
                        className={`cursor-pointer rounded-4 mb-4  border-3 ${index === 0 && "border-blue"}
                     ${acc.diabledcss ? 'btn-onboard-fill-disabled-Inter' : null}`
                        }
                      >
                        <Card.Body className=""  >
                          <div className="form-check d-flex ">
                            <span>
                              <input
                                //  onChange={() => setSelectedType(acc.type)}

                                className="form-check-input"
                                type="radio"
                                name="eventType"
                                id={acc.type}
                                //  value={acc.type}

                                checked={index === 0}
                              />
                            </span>
                            <div className="form-check-label ms-2 " for={acc.type}>
                              <div>
                                <span className="fw-500 fs-22px">
                                  {acc.type}
                                  &nbsp;
                                  {index === 1 &&
                                    <span className="fw-500" style={{ color: "#413B64", fontSize: "16px" }}>Upcoming Feature</span>
                                  }
                                </span>
                              </div>
                              <div>
                                <span
                                  style={{ color: "#9A9A9A" }}
                                  className="fs-15px fw-500"
                                >
                                  {acc.body}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Card.Body>

                      </Card>
                    )}

                  </>
                );
              })}

            </>
          ) : (
            <>
              <p>Change the event format</p>
              {format.map((acc, index) => {
                return (
                  <>
                    {!acc.showFor && (
                      <Card
                     onClick={() => {setSelectedType(acc.event_format)
                    formik.setFieldValue("format", acc.event_format)
                  }}
                  style={{ borderColor: "transparent" , background: "#FAFAFA"}}
                   className={`cursor-pointer rounded-4 mb-4  border-3 
                   ${formik.values.format === acc.event_format && "border-blue"}
                   `}

                      >
                        <Card.Body className=""  >
                          <div className="form-check d-flex ">
                            <span>
                              <input
                               onChange={(e)=> {                             
                               formik.setFieldValue("format", acc.event_format)
                               }}
                                className="form-check-input"
                                type="radio"
                                name="format"
                                id={acc.event_format}
                              checked={formik.values.format === acc.event_format}
                                
                              />
                            </span>
                            <div className="form-check-label ms-2 " htmlFor={acc.event_format}>
                              <div>
                                <span className="fw-500 fs-22px">
                                  {acc.event_format}
                                </span>
                              </div>
                              <div>
                                <span style={{ color: "#9A9A9A", }}
                                  className="fs-15px fw-500">
                                  {acc.description}
                                </span>
                              </div>
                              <div>
                                <span style={{ color: "#9A9A9A", }}
                                  className="fs-13px fw-500"
                                >
                                  {acc.event_content}
                                </span>
                              </div>
                            </div>

                          </div>
                        </Card.Body>
                      </Card>
                    )}
                  </>
                );
              })}
            </>
          )}
          <div className="d-flex align-self-end">

            <button className="btn-onboard-fill btn-block ms-auto" onClick={()=>{setEventModal(false)}}>
              Save
              <span className="ms-4">
                <img src="/images/onBoarding/right-icon.png" alt="right-icon" />
              </span>
            </button>
          </div>
        </div>
      </Modal>

      {/*============== A modal for showing the details ======================= */}
      <Modal size="lg" show={showDetailModal} scrollable onHide={()=>setShowDetailModal(false)}>
                <Modal.Body>
                        <div>
                          <div className="mb-3">
                            <h3 className="fs-4 fw-semibold">{committeeArray[comIndex]?.detail?.title}</h3>
                          </div>
                          <div>
                            {
                              committeeArray[comIndex]?.detail?.info?.map((i)=>{
                                return (<>
                                    <div className=" ">
                                      <h3 className="fs-5 fw-semibold">{i?.infoTitle}: </h3><p className="fs-6 fw-300">{i?.infoDetail}</p>
                                    </div>
                                </>)
                              })
                            }
                          </div>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                  <button onClick={()=>setShowDetailModal(false)} className="btn btn-sm btn-outline-danger rounded-3">Close</button>
                </Modal.Footer>
              </Modal>

    </>
  )
}

const eventType = [
  {
    type: "Intra Institutional",
    body: "Students from the same educational institute can participate",
    show: "Register Student",
  },
  {
    type: "Inter Institutional",
    body: "Students from other educational institutions will also participate",
    show: "Register Teacher",
    diabledcss: "btn-onboard-fill-disabled-Inter",
  },
];
const committeeTrack = [
  { 
    id : 1,
    name : "G20",
    track : "Leader Track"
  },
  { 
    id : 2,
    name : "G20",
    track : "Sherpa Track"
  },
  { 
    id : 3,
    name : "G20",
    track : "Foreign minister"
  },
  { 
    id : 4,
    name : "G20",
    track : "Finance Track"
  }
]