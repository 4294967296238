import React, { useContext, useEffect, useState, useRef } from "react";
import { Card, Modal } from "react-bootstrap";
import ImageIcon from "@mui/icons-material/Image";
import GroupIcon from '@mui/icons-material/Group';
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { UserContext } from "global/context";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { ClubContext } from "../TimelineClub";
import { Avatar } from "@mui/material";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { apiAuth, apiJson } from "api";
import "../style/clubStyle.css"
import EmojiPicker, { EmojiStyle, } from "emoji-picker-react";
import { MentionsInput, Mention } from 'react-mentions'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { Divider } from "@mui/joy";
import Swal from "sweetalert2";

const ClubAddPost = ({ clubDetail, userDetail ,isJoined}) => {
  const location = useLocation();
  const [isProfilePage, setIsProfilePage] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("profile")) {
      setIsProfilePage(true);
    }
  }, []);
  const navigate = useNavigate();
  const { setClubPost, setclickuserAllPost, allFollowedClub } = useContext(ClubContext);
  const { userData, token } = useContext(UserContext);
  // const { userDetail} = useContext(ClubContext);

  // const [userDetail, setUserDetail] = useState({})
  // const fetchUserDetail = () => {
  //   apiJson.get("club/getUserDetail/" + userData.role + "/" + userData.id).then((res) => {
  //     setUserDetail(res.data.user);
  //   });
  // };
  useEffect(() => {
    // fetchUserDetail()
    console.log("userDetail===>",userDetail)
  }, [])

  const { first_name, last_name, institution_name } = userDetail;

  const [modalShow, setModalShow] = useState(false);
  const [modalDiscussuionShow, setModalDiscussuionShow] = useState(false);

  const [image, setImage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [postObject, setPostObject] = useState({
    content: "",
    image: "",
  });

  const [showClubModal, setShowClubModal] = useState(false);
  const [clubIdProfile, setClubIdProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState("");
  const [videoId, setVideoId] = useState("");
  const [showYt, setshowYt] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false)
  const extractVideoId = (link) => {
    const regex = /(?:[?v=]|\/embed\/|\/\d\/|\/vi\/|youtu.be\/|\/v\/|\/e\/|\/embed\/|\/user\/|\/videos\/|\/channels\/[^\/]+\/|\/c\/[^\/]+\/|\/user\/[^\/]+\/|\/[a-zA-Z0-9_-]{11})[a-zA-Z0-9_-]{11}/;
    const match = link.match(regex);
    return match ? match[0].substr(-11) : null;
  };

  const handleSubmitYoutube = (event) => {
    event.preventDefault();
    const videoId = extractVideoId(link);
    setVideoId(videoId);
    setImage(null);
    setSelectedImage(null)
    // setLink("")
  };

  const handleSubmit = (e, clubId) => {
    e.preventDefault();

    if (!token) {
      toast.info("you need to login");
      navigate("/login");
    }

    const postby = first_name + " " + last_name;

    const newPost = {
      postBy:  userData?.role === "institute" ? institution_name :  postby,
      // logo: logo,
      content: postObject.content,
      userId: userData.id,
      type: "club",
      image: postObject.image,
      clubId: isProfilePage ? clubId : clubDetail.id,
      userType: userData.role,
      youTubeLink: link,
      instituteId: userData.role === "institute" ? userData.id :userData?.instituteId,
      youTubeId: videoId,
    };

    if (!newPost.content && !newPost.youTubeId) {
      toast.dismiss();
      toast.error("You Have To Write Something Before Publish");
      return;
    }
    setIsLoading(true);
    apiAuth
      .post(`club/createClubPost/user/${userData.id}/club/${clubDetail.id}/?pageType=${isProfilePage ? "profile" : "club"}`, { ...newPost, img: image })
      .then((res) => {
        clearFields();
        if (isProfilePage) {
          // console.log(res.data.posts)
          // console.log("insidet the profile page ")
          setclickuserAllPost(res.data.posts);

          setShowClubModal(false);
        } else {
          setClubPost(res.data.posts);
          setShowClubModal(false);
        }
        setIsLoading(false);

        setImage(null);
        hide();
        setSelectedImage(null);
        toast.dismiss();
        toast.success("Published Successfully");
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Internal Server Error");
        // console.log(error.message);
        setIsLoading(false);
      });
  };
  const handleSubmitDirect = (e) => {
    e.preventDefault();

    if (!token) {
      toast.info("you need to login");
      navigate("/login");
    }

    const postby = first_name + " " + last_name;

    const newPost = {
      postBy: userData?.role === "institute" ? institution_name :  postby,
      // logo: logo,
      content: postObject.content,
      userId: userData.id,
      type: "profile",
      image: postObject.image,
      userType: userData.role,
      youTubeLink: link,
      instituteId: userData.role === "institute" ? userData.id : userData.instituteId,
      youTubeId: videoId,
    };

    if (!newPost?.content && !newPost?.youTubeId) {
      toast.dismiss();
      toast.error("You Have To Write Something Before Publish");
      return;
    }
    setIsLoading(true);
    apiAuth
      .post(`api/v2/profile/createPost`, { ...newPost, img: image })
      .then((res) => {
        clearFields();
        if (isProfilePage) {
          // console.log(res.data.posts)
          // console.log("insidet the profile page ")
          setclickuserAllPost(res.data.posts);

          setShowClubModal(false);
        } else {
          setClubPost(res.data.posts);
          setShowClubModal(false);
        }
        setIsLoading(false);
        setVideoId("");
        setLink("");
        setshowYt("")

        setImage(null);
        hide();
        setSelectedImage(null);
        toast.dismiss();
        toast.success("Published Successfully");
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Internal Server Error");
        // console.log(error.message);
        setIsLoading(false);
      });
  };
  const clearFields = () => {
    setPostObject({
      text: "",
      image: "",
    });
  };

  const setPostObjectFunction = (newValues) => {
    setPostObject((prevState) => ({
      ...prevState,
      ...newValues,
    }));
  };

  const showModal = () => {
    if(isJoined || isProfilePage){
      setModalShow(true);
    }else{
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You Need To Join Club!",
      });
    }
  };

  const hide = () => {
    setModalShow(false);
  };

  const showDiscussionModal = () => {
    setModalDiscussuionShow(true);
  };

  const hideDiscussionModal = () => {
    setModalDiscussuionShow(false);
  };

  function showFileInput() {
    // if (role === 'institute' && type === 1 && token) {
    //   fetchAdminDetails();
    // }
    var img = document.getElementById("img");
    img.click();
  }


  const [emojiTogggle, setEmojiTogggle] = useState("")
  const containerRef = useRef(null);
  const [inputView, setInputView] = useState(true);
  const [mentions, setMentions] = useState([]);


  const [allStudent, setAllstudent] = useState([])

  const getAllStudent = () => {
    const id = userData?.role === "institute" ? userData?.id : userData?.instituteId
    apiJson(`api/v2/getAllProfile/getAllFriendTeacher/${id}`)
      .then((res) => {
        setAllstudent(res?.data?.allStudents)
      }).catch((error) => {
        console.log("error", error.message);
      })
  }

  const [allTeacher, setAllTeacher] = useState([])
  const getAllTeacher = () => {
    try {
      apiJson(`api/v2/getAllProfile/getAllFriendTeacher/${userData.role === "institute" ? userData?.id : userData?.instituteId}`)
        .then((res) => {
          // console.log("res.data.allTeachers", res.data.allTeachers)
          setAllTeacher(res?.data?.allTeachers)
        })
    } catch (error) {
      console.log(error.message)
    }
  }
  useEffect(() => {
    getAllStudent();
    getAllTeacher();
  }, [])


  useEffect(() => {
    const allStudentTeacher = [...allStudent, ...allTeacher];
    // console.log("allStudentTeacher", allStudentTeacher)
    const menTionData = allStudentTeacher?.map((ele, i) => {
      return { id: ele?.id, display: ele?.first_name + " " + ele?.last_name }
    })
    setMentions(menTionData)
    // console.log("menTionData", menTionData);
  }, [allStudent, allTeacher])

  const mentionsInputStyle = {
    control: {

      fontSize: 16,
    height: "120px",
      background: "#efefef",
      borderRadius: "8px",
      resize: "none",
      border: "none",
     
    },
    "&multiLine": {
      control: {
        minHeight: 30
      },
      highlighter: {
        padding: 9,
      },
      input: {
        outline: "none",
        border: "none",
        padding: 9,
        overflow:"auto"
      }
    },
    "&singleLine": {
      display: "inline-block",

      highlighter: {
        padding: 1,
      },
      input: {
        outline: "none",
        border: "none",
        padding: 1,
        overflow:"auto"
      }
    },
    suggestions: {


      list: {
        // border: '1px solid rgba(0,0,0,0.15)',
        borderRadius: 5,
        fontSize: 10,
        width: "100%",
        maxHeight: '100px',
        overflowY: 'auto',

      },
      item: {
        fontSize: 12,
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#da9eff',
          borderRadius: 5,
        },
      },
    },
  };

  const handleInputChange = (e, newValue, newPlainTextValue) => {
    //   // console.log('Added newPlainTextValue:', newPlainTextValue);
    //   const temp =[...mentions]
    //   console.log("mentions list" ,newPlainTextValue,mentions)
    // //   const filteredSuggestions = temp.filter((mention) =>
    // //     mention?.display?.toLowerCase()?.includes(newPlainTextValue.slice(1)?.toLowerCase())
    // //   );
    // // console.log("filteredSuggestions",filteredSuggestions)
    //   // // Limit the number of suggestions displayed
    //   const slicedSuggestions = temp.slice(0, 2);

    //   // Update the suggestions
    //   setMentions(slicedSuggestions); 

    setPostObject((prevState) => ({
      ...prevState,
      content: newPlainTextValue,
    }));
  };

  const handleEmojiClick = (emoji) => {
    setPostObject((prevContent) => {
      return {
        ...prevContent,
        content: prevContent.content + emoji.emoji,
      };
    });
  };

  useEffect(() => {
    const closeEmojiPicker = (event) => {
      // Check if the click target is outside the specified area
      if (containerRef.current && !containerRef.current.contains(event.target) && !event.target.closest('.avatar-area') // Add a class to the avatar area container
      ) {
        // Close the emoji picker here
        setEmojiTogggle(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener('click', closeEmojiPicker);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('click', closeEmojiPicker);
    };
  }, [setEmojiTogggle]);

  const handleMentionInputClick = () => {
    setEmojiTogggle(false); // Close emoji picker when clicking on the mention input.
  };


  return (
    <>
      <div className="mb-3 p-1" >
        <div onClick={()=>{
                  showModal()
                  setshowYt(false)
                }} className="yui-card-new px-3 py-2" 
        style={{border: "1px solid #e3e3e3"}}
        >
          <div className="">
            <div style={{ cursor: "pointer" }} className="" >
              <div className="d-flex " style={{ color: "black", cursor: "pointer" }}>
                <Avatar src={userDetail?.logo || userDetail?.profile} className="me-2 mb-2"></Avatar>

                <div className="col-9 ">
                  <div className="" style={{ border: "none" }}>

                    <input type="text" name="" id=""  
                      style={{
                        width: "100%",
                        border: "1px solid #d0d0d0",
                        color: "#999999",
                        background: "#f3f3f3",
                        height: "40px",
                        padding: '4px 8px',

                        borderRadius: "28px",
                        outline:"none",
                      }}
                        placeholder="Whats Going On?"
                    />
                  </div>
               
                </div>

              </div>
              <div className="col-12">
              <Divider sx={{height:2,background:"#cccccc",width:"100%"}}/>

              <div className="d-flex justify-content-between justify-content-md-start   mt-3 ">
                <div style={{ color: "red" }} className="me-2 me-md-5 mb-2 commentPost" onClick={()=>{
                  showModal()
                  setshowYt(false)
                }}>
                  <small style={{ color: "#333333", fontWeight: "700", fontSize: "15.07px" }}>
                    <AddToPhotosIcon sx={{ color: "#0808ff" }} /> Photos
                  </small>
                </div>
                <div style={{ color: "red" }} className="me-2 mb-2 ms-2 ms-md-5 commentPost " onClick={()=>{
                  showModal()
                  setshowYt(true)
                }}>
                  <small style={{ color: "#333333", fontWeight: "700", fontSize: "15.07px" }}>
                    <YouTubeIcon sx={{ color: "#FF0000"}} />  Videos
                  </small>
                </div>
              </div>
              </div>


            </div>
          </div>
        </div>
        <Modal  show={modalShow} onHide={() => {
          hide()
          handleMentionInputClick()
        }
        }>
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold" style={{ color: "black" }}>Create Post</Modal.Title>
          </Modal.Header>
          <Modal.Body ref={containerRef}>
            <div>
              <div className="d-flex mb-2">
                <div style={{ width: "44px", height: "44px", marginRight: "20px" }}>
                  <Avatar src={userDetail?.logo || userDetail?.profile}></Avatar>
                </div>
                <div>
                  {userDetail?.role === 'institute' ? <h5 className="mb-0">{userDetail?.institution_name}</h5> : <h5 className="mb-0">{first_name + " " + last_name}</h5>}
                  <label className="rounded-3 px-2 py-1" style={{background:"#bebebe"}}>Public</label>
                </div>
              </div>
            </div>
            <div className={`mb-3`}>
  
            <div>
            {
                inputView &&
                <div className="mb-3" onClick={handleMentionInputClick}>
                  <MentionsInput
                    value={postObject.content}
                    onChange={handleInputChange}
                    placeholder={`Whats going on...\n@Mention`}
                    style={mentionsInputStyle}
                  >
                    <Mention
                      trigger="@"
                      data={mentions}
                      displayTransform={(id, display) => `@${display}`}
                    // onAdd={handleAddMention}
                    />
                  </MentionsInput>
                </div>

              }
              {selectedImage && (
                <Card className="mt-2" >
                  <Card.Body>
                    <div>
                      {
                        <div style={{height:"300px",}}>
                          <img src={selectedImage} alt="Selected" style={{ width: "100%", objectFit: "contain",height:"100%" }} />
                        </div>
                      }
                    </div>
                  </Card.Body>
                </Card>
              )}
            </div>
            <div>
            {videoId && (
                <Card className="mb-3">
                  <Card.Body>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        width: "100%",
                      }}>
                      <div style={{ position: "relative", width: "100%", paddingBottom: "56.25%", height: "0" }}>
                        <iframe title="YouTube video player" width="100%" height="100%" src={`https://www.youtube.com/embed/${videoId}`} frameBorder="0" allowFullScreen style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }} />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              )}
            </div>
            {showYt && (
              <>
                <Card className="my-3">
                  <Card.Body>
                    <div className="d-flex justify-content-between">
                      <input type="text" value={link} onChange={(event) => setLink(event.target.value)} placeholder="Paste Youtube Link Here" style={{ outline: "none", border: "none", width: "100%" }} />
                      <button className="youtube-button" onClick={handleSubmitYoutube} type="submit">
                        Upload
                      </button>
                    </div>
                  </Card.Body>
                </Card>
              </>
            )}
            </div>
             
             
            <div className=" mb-4">
              <Card>
                <Card.Body style={{ background: "rgb(238 245 255)" }}>
                  <div className="d-flex justify-content-between align-items-center add-post-emoji-div">
                    <div onClick={() => { handleMentionInputClick() }} style={{ width: "100%" }}>
                      <div style={{ width: "100%" }}>
                        <h6>Add To Post</h6>
                      </div>
                    </div>

                    <div className="d-flex justify-content-around align-items-center">
                      <div className="me-1">
                        <ImageIcon onClick={showFileInput} style={{ cursor: "pointer", color: "#0808ff" }} />
                        <input
                          type="file"
                          id="img"
                          style={{ display: "none" }}
                          accept=".png, .jpg, .jpeg"
                          name="files[]"
                          onChange={(e) => {
                            if (e.target.files.length) {
                              setImage(e.target.files[0]);
                              setSelectedImage(URL.createObjectURL(e.target.files[0]));
                              setLink(null)
                              setVideoId(null)
                              setshowYt(false)
                            }
                          }}
                        />
                      </div>
                      <div className="me-1">
                        <YouTubeIcon
                          onClick={() => setshowYt((prev) => !prev)}
                          style={{ cursor: "pointer", fontSie: "17px", color: "#FF0000" }}
                          color="primary"
                        />
                      </div>

                      <div className="ms-1">
                        <InsertEmoticonIcon className="" onClick={() => setEmojiTogggle((prev) => !prev)} style={{ cursor: "pointer", color: "#fdd231" }} />
                      </div>
                      {/* <div> */}
                      <div className='post-emoji-div'>
                        {
                          emojiTogggle &&
                          <EmojiPicker
                            height={350}
                            // width="50%"
                            emojiVersion="5.0"
                            lazyLoadEmojis={true}
                            onEmojiClick={handleEmojiClick}
                            emojiStyle={EmojiStyle.APPLE}
                          />
                        }
                      </div>
                      {/* </div> */}
                      {/* <div className="ms-1">
                        <AlternateEmailIcon style={{ cursor: "pointer", color: "black" }}/>
                      </div> */}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div
              onClick={() => handleMentionInputClick()}
              className="row">
              <button
                style={{ height: "40px", padding: "4px", color: "white", background: "#6100FF" }}
                className="btn btn-md btn-primary col-7 mx-auto rounded border-0"
                onClick={(e) => {
                  if (isProfilePage) {
                    handleSubmitDirect(e);
                  } else {
                    handleSubmit(e);
                  }
                }}
                disabled={isLoading}>
                {/* {isLoading ? "Loading..." : "Submit"} */}
                {isLoading ?
                  <>
                    <div className="px-3">
                      <div className="spinner-border text-light mx-auto d-flex" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="px-1">
                      <span>Submit</span>
                    </div>
                  </>
                }
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={modalDiscussuionShow} onHide={hideDiscussionModal} aria-labelledby="contained-modal-title-vcenter">
          <Modal.Header closeButton><Modal.Title id="contained-modal-title-vcenter" className=" fw-bold color-black" style={{ fontSize: "25px", fontFamily: "Poppins" }}>Discussion</Modal.Title></Modal.Header>

          <Modal.Body>
            <div>
              <div className="mt-1" style={{ fontFamily: "Poppins" }}>
                <div><span style={{ color: "black" }} className="fw-500">Title</span></div>
                <textarea type="text" className="form-control ps-3 pt-2 rounded fw-500 box-shadow-0 resize-none border-0" name="" id="" placeholder="Title" style={{ color: "black", background: "rgb(239, 239, 239)", fontSize: "16px", height: "30px" }}>
                </textarea>
              </div>
              <div className="mt-2" style={{ fontFamily: "Poppins" }}>
                <div><span style={{ color: "black" }} className="fw-500">Discription</span></div>
                <textarea type="text" className="form-control p-3 rounded fw-500 box-shadow-0 resize-none border-0" name="" id="" placeholder="Write Discription" style={{ color: "black", background: "rgb(239, 239, 239)", fontSize: "16px", }}>
                </textarea>
              </div>

              <div className="mt-3 mb-3 d-flex justify-content-between align-items-center text-center">
                <div>
                  <button onClick={hideDiscussionModal} className="rounded" style={{ height: "36px", width: "122px", color: "#747474" }}>Discard</button>
                </div>
                <div>
                  <button type="submit" className="rounded" style={{ height: "36px", width: "140px", background: "rgb(97, 0, 255)", color: "white" }}>Add Discussion</button>
                </div>
              </div>
            </div>
          </Modal.Body>

        </Modal>

      </div>
      <Modal dialogClassName="club-modal"
        show={showClubModal}
        onHide={() => {
          setShowClubModal(false);
        }}>
        <Modal.Header className="fw-bold" style={{ color: "black", fontSize: "20px" }}>Choose Club</Modal.Header>

        <Modal.Body>
          <div>
            <div className="">
              {allFollowedClub?.map((club) => {
                return (
                  <button
                    style={{ color: "white", background: "#da9eff", width: "70px" }}
                    key={club.id}
                    className="btn btn-outline-primary rounded-2 mb-2 px-5 mx-4 border-0"
                    // value={club.name}
                    onClick={(e) => {
                      setClubIdProfile(club.id);
                      handleSubmit(e, club.id);
                    }}
                    disabled={isLoading}>
                    {club.name}
                  </button>
                );
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showJoinModal} onHide={()=>setShowJoinModal(false)} aria-labelledby="contained-modal-title-vcenter">

          <Modal.Body>
            <div>
              <p>You Need To Join Club </p>
            </div>
          </Modal.Body>

        </Modal>
    </>
  );
};

export default ClubAddPost;
