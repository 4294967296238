import React, { useEffect, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Button from "@mui/material/Button";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { apiJsonAuth } from "api";
import {toast} from "react-toastify"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useMediaQuery, useTheme } from "@mui/material";

export const Admin75DaysChallenge = () => {
  const [showCategory, setShowCategory] = useState(false);
  const [showChallenge, setShowChallenge] = useState(false);
  const [showUpdateChallenge, setShowUpdateChallenge] = useState(false);
  const [showDelteChallenge, setShowDelteChallenge] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [condtionModal, setCondtionModal] = useState("");
  const [descriptionModal, setDescriptionModal] = useState("");
  const [category, setCategory] = useState([]);
  const [challenge, setChallenge] = useState([]);
  const [updateId, setUpdateId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [screenWidth, setScreenWidth] = useState(document.documentElement.clientWidth);
  
  const validationSchema = yup.object().shape({
    category: yup.string().required("category is required"),
    description: yup.string().required("Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      category: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }, action) => {
      const data = {
        name: values?.category,
        description: values?.description
      }
      setIsLoading(true)
      apiJsonAuth
        .post("api/v2/75daysChallenge/addCategory", data)
        .then((res) => {
          setIsLoading(false)
          getAllCategory();
          handleCloseCategory()
          toast.dismiss();
          toast.success("Category added successfully")
        })
        .catch((error) => {
          setIsLoading(false)
          toast.dismiss()
          toast.error("Internal server error")
          console.log("error", error);
        });
      resetForm();
    },
  });

  // Get all categories
  const getAllCategory = () => {
    apiJsonAuth
      .get("api/v2/75daysChallenge/getAllCategory")
      .then((res) => {
        setCategory(res?.data?.result);
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Internal server error")
        console.log("error", error);
      });
  };

  //   Get all task
  const getAllTask = () => {
    apiJsonAuth
      .get("api/v2/75daysChallenge/getAllTask")
      .then((res) => {
        setChallenge(res?.data?.result);
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Internal server error")
        console.log("error", error);
      });
  };

  useEffect(() => {
    getAllCategory()
    getAllTask()
  }, [])

  const validationChallengesSchema = yup.object().shape({
    day_count: yup.string().required("Day count is required"),
    categoryType: yup.string().required("categoryType count is required"),
    credit: yup.string().required("Credit is required"),
    description: yup.string().required("Description is required"),
    order: yup.number().required("Order is required"),
  });

  const challengesformik = useFormik({
    initialValues: {
      day_count: "",
      categoryType: "",
      credit: "",
      description: "",
      order: "",
    },
    validationSchema: validationChallengesSchema,
    onSubmit: async (values, { resetForm }, action) => {
      const data = {
        day_count: values?.day_count,
        category_id: values?.categoryType,
        description: values?.description,
        credit: values?.credit,
        order: values?.order,
      }
      if (condtionModal === "Add") {
        setIsLoading(true)
        apiJsonAuth
          .post("api/v2/75daysChallenge/addTask", data)
          .then((res) => {
            setIsLoading(false)
            getAllTask();
            handleCloseCategory()
            toast.dismiss();
            toast.success("Challenge added successfully")
          })
          .catch((error) => {
            setIsLoading(false)
            toast.dismiss()
            toast.error("Internal server error")
            console.log("error", error);
          });
        resetForm();
        handleCloseChallenge()
      }
      else if (condtionModal === "Edit") {
        //  Update challenge
        setIsLoading(true)
        apiJsonAuth
          .put(`api/v2/75daysChallenge/updateTask/${updateId}`, data)
          .then((res) => {
            setIsLoading(false)
            // nameCategory()
            getAllTask()
            handleCloseUpdateChallenge();
            toast.dismiss()
            toast.success("Update challenge successfully");
          })
          .catch((error) => {
            toast.dismiss()
            toast.error("Internal server error")
            console.log("error", error);
          });
      }
      resetForm();
    }
  });

  const nameCategory = (challenge) => {
    const categoryData = category.find((obj1) => obj1.id === challenge.category_id);
    return categoryData ? categoryData.name : "";
  }

  //   Delete Challenges
  const deleteChallenges = () => {
    setIsLoading(true)
    apiJsonAuth
      .delete(`api/v2/75daysChallenge/deleteTask/${deleteId}`)
      .then((res) => {
        setIsLoading(false)
        getAllTask();
        handleCloseDeleteModal();
        toast.dismiss();
        toast.success("Delete challenge successfully")
      })
      .catch((error) => {
        setIsLoading(false)
        toast.dismiss();
        toast.error("Internal server error")
        console.log("error", error);
      });
  };




  const handleShowCategory = () => {
    setShowCategory(true);
  };
  const handleCloseCategory = () => {
    setShowCategory(false);
    formik.setErrors({});
    formik.handleReset();
  };
  const handleShowChallenge = () => {
    setCondtionModal("Add");
    setShowChallenge(true);
  };
  const handleCloseChallenge = () => {
    setShowChallenge(false);
    challengesformik.setErrors({});
    challengesformik.handleReset();
  };

  const handleShowDescription = (descrip) => {
    setDescriptionModal(descrip);
    setShowDescription(true);
  };
  const handleCloseDescription = () => {
    setShowDescription(false);
  };


  const setEditFormValues = (challenge) => {
    challengesformik.setValues({
      day_count: challenge?.day_count,
      categoryType: challenge?.category_id,
      credit: challenge?.credit,
      description: challenge?.description,
      order: challenge?.order
    })
  }

  const handleShowUpdateChallenge = (id) => {
    getAllCategory()
    setCondtionModal("Edit");
    setUpdateId(id);

    const selectedChallenge = challenge?.find((ele) => ele?.id === id)
    setEditFormValues(selectedChallenge)
    setShowUpdateChallenge(true);
  };
  const handleCloseUpdateChallenge = () => {
    setShowUpdateChallenge(false);
    challengesformik.setErrors({});
    challengesformik.handleReset();
  };

  const handleShowDeleteModal = (id) => {
    setDeleteId(id);
    setShowDelteChallenge(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDelteChallenge(false);
  };


  const handleResize = () => {
    setScreenWidth(document.documentElement.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

  return (
    <div>
        <div className='col-10 mx-auto mt-4'>
        <div className="d-flex">
          <div className="col-6 d-flex align-items-center">
            <div className="">
              <ArrowBackIosNewIcon sx={{ color: "#da9eff" }} />
            </div>
            <div className=" fs-3">Seventy Five Days Challenge</div>
          </div>

          <div className='d-flex gap-1'>
            <div
              onClick={handleShowCategory}
              className="d-flex align-items-center"
            >
              <Button
                className="text-capitalize"
                size="lg"
                style={{ background: "#da9eff", color: "#ffff" }}
              >
                Add Category
              </Button>
            </div>
            <div
              onClick={handleShowChallenge}
              className="d-flex align-items-center"
            >
              <Button
                className="text-capitalize"
                size="lg"
                style={{ background: "#da9eff", color: "#ffff" }}
              >
                Add Challenges
              </Button>
            </div>

          </div>
        </div>

        <div className="margin-sm-screen mt-5 col-lg-10 ms-4 p-md-3 p-lg-3 mb-5" style={{ width: screenWidth < 500 ? `${screenWidth - 50}px` : "" }}>
          <TableContainer component={Paper}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#914EFF",
                height: 4,
                width: 95,
                marginLeft: "2px"
              }
            }}
            variant={isMobile ? 'scrollable' : 'standard'}
            scrollButtons={isMobile ? 'auto' : 'off'}
          >
            <Table aria-label="">
              <TableHead>
                <TableRow style={{ background: "#bbdefb" }}>
                  {/* <TableCell>Dessert (100g serving)</TableCell> */}
                  <TableCell align="center" style={{ fontSize: "16px", fontWeight: "600", color: "#8c32ff" }}>Day Count</TableCell>
                  <TableCell align="center" style={{ fontSize: "16px", fontWeight: "600", color: "#8c32ff" }}>Type</TableCell>
                  <TableCell align="center" style={{ fontSize: "16px", fontWeight: "600", color: "#8c32ff" }}>Credit</TableCell>
                  <TableCell align="center" style={{ fontSize: "16px", fontWeight: "600", color: "#8c32ff" }}>Description</TableCell>
                  <TableCell align="center" style={{ fontSize: "16px", fontWeight: "600", color: "#8c32ff" }}>Order</TableCell>
                  <TableCell align="center" style={{ fontSize: "16px", fontWeight: "600", color: "#8c32ff" }}>Edit</TableCell>
                  <TableCell align="center" style={{ fontSize: "16px", fontWeight: "600", color: "#8c32ff" }}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {
                  challenge?.map((ele, i) => {
                    const categoryName = nameCategory(ele);
                    return (
                      <TableRow>
                        <TableCell align="center" style={{ fontSize: "15px", fontWeight: "600" }}>{ele?.day_count}</TableCell>
                        <TableCell align="center" style={{ fontSize: "15px", fontWeight: "600" }}>{categoryName}</TableCell>
                        <TableCell align="center" style={{ fontSize: "15px", fontWeight: "600" }}>{ele?.credit}</TableCell>
                        <TableCell align="center" style={{ fontSize: "", fontWeight: "700", cursor: "pointer", color: "#4f8df6", textDecoration: "underline" }} onClick={() => handleShowDescription(ele?.description)}>View Description</TableCell>
                        <TableCell align="center" style={{ fontSize: "15px", fontWeight: "600" }}>{ele?.order}</TableCell>
                        <TableCell align="center" style={{ fontSize: "15px", fontWeight: "600" }}><Button className="text-capitalize" style={{ background: "#0571ed", color: "white" }} onClick={() => handleShowUpdateChallenge(ele?.id)}>Edit</Button></TableCell>
                        <TableCell align="center" style={{ fontSize: "15px", fontWeight: "600" }}><Button className="text-capitalize" style={{ background: "#f40157", color: "white" }} onClick={() => handleShowDeleteModal(ele?.id)}>Delete</Button></TableCell>
                      </TableRow>
                    )
                  })
                }

              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <Modal show={showCategory} onHide={handleCloseCategory}>
          <Modal.Header closeButton>
            <Modal.Title>Create Category</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="p-2">
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <span className="d-block">category</span>
                  <input
                    type="text"
                    className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                    style={{
                      color: "black",
                      background: "rgb(239, 239, 239)",
                      fontSize: "16px",
                      height: "40px",
                    }}
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    name="category"
                    placeholder="Category"
                  />
                  {formik.errors.category && formik.touched.category ? (
                    <span className="text-danger">
                      {formik.errors.category}
                    </span>
                  ) : null}
                </div>

                <div className="mt-3">
                  <span className="d-block">Description</span>
                  <textarea
                    type="text"
                    className="form-control p-3 rounded fw-500 box-shadow-0 resize-none border-0"
                    name="description"
                    id=""
                    placeholder="Write Discription"
                    style={{
                      color: "black",
                      background: "rgb(239, 239, 239)",
                      fontSize: "16px",
                    }}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  ></textarea>
                  {formik.errors.description && formik.touched.description ? (
                    <span className="text-danger">
                      {formik.errors.description}
                    </span>
                  ) : null}
                </div>

                <div className="mt-3 d-flex justify-content-end">
                  <Button
                    type="submit"
                    size="lg"
                    value="Add"
                    className="text-capitalize px-3"
                    style={{ background: "#0808ff", color: "white", fontSize: "16px" }}
                    disabled={isLoading}                  >
                    {/* {isLoading ? }Add Challenge */}
                    {isLoading ?
                      <>
                        <div className="px-3">
                          <div className="spinner-border text-light mx-auto d-flex" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        <div className="px-1">
                          <span>Add Challenge</span>
                        </div>
                      </>
                    }
                  </Button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
        
        {
          condtionModal === "Add" ? (
            <Modal show={showChallenge} onHide={handleCloseChallenge}>
              <Modal.Header closeButton>
                <Modal.Title>Create Challenges</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="p-2">
                  <form onSubmit={challengesformik.handleSubmit}>
                    <div>
                      <span className="d-block">Day Count</span>
                      <input
                        type="text"
                        className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                        style={{
                          color: "black",
                          background: "rgb(239, 239, 239)",
                          fontSize: "16px",
                          height: "40px",
                        }}
                        value={challengesformik.values.day_count}
                        onChange={challengesformik.handleChange}
                        name="day_count"
                        placeholder="Day Count"
                      />
                      {challengesformik.errors.day_count && challengesformik.touched.day_count ? (
                        <span className="text-danger">
                          {challengesformik.errors.day_count}
                        </span>
                      ) : null}
                    </div>

                    <div className="mt-3">
                      <span className="d-block">Category Type</span>
                      <select onChange={(e) => {
                        challengesformik.setFieldValue("categoryType", e.target.value)
                      }} class="form-select form-control rounded-3" aria-label="Default select example">
                        <option selected>Select Type of Question</option>
                        {
                          category?.map((type) => {
                            return <option value={type.id}>{type.name}</option>
                          })
                        }
                      </select>
                      {challengesformik.errors.categoryType && challengesformik.touched.categoryType ? (
                        <span className="text-danger">{challengesformik.errors.categoryType}</span>
                      ) : null}
                    </div>

                    <div className="mt-3">
                      <span className="d-block">Credit</span>
                      <input
                        type="text"
                        name="credit"
                        placeholder="Credit"
                        className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                        style={{
                          color: "black",
                          background: "rgb(239, 239, 239)",
                          fontSize: "16px",
                          height: "40px",
                        }}
                        value={challengesformik.values.credit}
                        onChange={challengesformik.handleChange}
                      />
                      {challengesformik.errors.credit && challengesformik.touched.credit ? (
                        <span className="text-danger">{challengesformik.errors.credit}</span>
                      ) : null}
                    </div>

                    <div className="mt-3">
                      <span className="d-block">Description</span>
                      <textarea
                        type="text"
                        className="form-control p-3 rounded fw-500 box-shadow-0 resize-none border-0"
                        name="description"
                        id=""
                        placeholder="Write Discription"
                        style={{
                          color: "black",
                          background: "rgb(239, 239, 239)",
                          fontSize: "16px",
                        }}
                        value={challengesformik.values.description}
                        onChange={challengesformik.handleChange}
                      ></textarea>
                      {challengesformik.errors.description && challengesformik.touched.description ? (
                        <span className="text-danger">
                          {challengesformik.errors.description}
                        </span>
                      ) : null}
                    </div>

                    <div className="mt-3">
                      <span className="d-block">Order</span>
                      <input
                        type="text"
                        name="order"
                        placeholder="Order"
                        className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                        style={{
                          color: "black",
                          background: "rgb(239, 239, 239)",
                          fontSize: "16px",
                          height: "40px",
                        }}
                        value={challengesformik.values.order}
                        onChange={challengesformik.handleChange}
                      />
                      {challengesformik.errors.order && challengesformik.touched.order ? (
                        <span className="text-danger">{challengesformik.errors.order}</span>
                      ) : null}
                    </div>

                    <div className="mt-3 d-flex justify-content-end">
                      <Button
                        type="submit"
                        size="lg"
                        value="Add"
                        className="text-capitalize px-3"
                        style={{ background: "#0808ff", color: "white", fontSize: "16px" }}
                        disabled={isLoading}                  >
                        {/* {isLoading ? }Add Challenge */}
                        {isLoading ?
                          <>
                            <div className="px-3">
                              <div className="spinner-border text-light mx-auto d-flex" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          </>
                          :
                          <>
                            <div className="px-1">
                              <span>Add Challenge</span>
                            </div>
                          </>
                        }
                      </Button>
                    </div>
                  </form>
                </div>
              </Modal.Body>
            </Modal>

          ) : condtionModal === "Edit" && (
            <Modal show={showUpdateChallenge} onHide={handleCloseUpdateChallenge}>
              <Modal.Header closeButton>
                <Modal.Title>Create Challenges</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="p-2">
                  <form onSubmit={challengesformik.handleSubmit}>
                    <div>
                      <span className="d-block">Day Count</span>
                      <input
                        type="text"
                        className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                        style={{
                          color: "black",
                          background: "rgb(239, 239, 239)",
                          fontSize: "16px",
                          height: "40px",
                        }}
                        value={challengesformik.values.day_count}
                        onChange={challengesformik.handleChange}
                        name="day_count"
                        placeholder="Day Count"
                      />
                      {challengesformik.errors.day_count && challengesformik.touched.day_count ? (
                        <span className="text-danger">
                          {challengesformik.errors.day_count}
                        </span>
                      ) : null}
                    </div>

                    <div className="mt-3">
                      <span className="d-block">Category Type</span>
                      <select onChange={(e) => {
                        challengesformik.setFieldValue("categoryType", e.target.value)
                      }} value={challengesformik.values.categoryType}
                        class="form-select form-control rounded-3" aria-label="Default select example">
                        <option selected>Select Category</option>
                        {
                          category?.map((type) => {
                            return <option value={type.id}>{type.name}</option>
                          })
                        }
                      </select>
                      {challengesformik.errors.categoryType && challengesformik.touched.categoryType ? (
                        <span className="text-danger">{challengesformik.errors.categoryType}</span>
                      ) : null}
                    </div>

                    <div className="mt-3">
                      <span className="d-block">Credit</span>
                      <input
                        type="text"
                        name="credit"
                        placeholder="Credit"
                        className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                        style={{
                          color: "black",
                          background: "rgb(239, 239, 239)",
                          fontSize: "16px",
                          height: "40px",
                        }}
                        value={challengesformik.values.credit}
                        onChange={challengesformik.handleChange}
                      />
                      {challengesformik.errors.credit && challengesformik.touched.credit ? (
                        <span className="text-danger">{challengesformik.errors.credit}</span>
                      ) : null}
                    </div>

                    <div className="mt-3">
                      <span className="d-block">Description</span>
                      <textarea
                        type="text"
                        className="form-control p-3 rounded fw-500 box-shadow-0 resize-none border-0"
                        name="description"
                        id=""
                        placeholder="Write Discription"
                        style={{
                          color: "black",
                          background: "rgb(239, 239, 239)",
                          fontSize: "16px",
                        }}
                        value={challengesformik.values.description}
                        onChange={challengesformik.handleChange}
                      ></textarea>
                      {challengesformik.errors.description && challengesformik.touched.description ? (
                        <span className="text-danger">
                          {challengesformik.errors.description}
                        </span>
                      ) : null}
                    </div>

                    <div className="mt-3">
                      <span className="d-block">Order</span>
                      <input
                        type="text"
                        name="order"
                        placeholder="Order"
                        className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                        style={{
                          color: "black",
                          background: "rgb(239, 239, 239)",
                          fontSize: "16px",
                          height: "40px",
                        }}
                        value={challengesformik.values.order}
                        onChange={challengesformik.handleChange}
                      />
                      {challengesformik.errors.order && challengesformik.touched.order ? (
                        <span className="text-danger">{challengesformik.errors.order}</span>
                      ) : null}
                    </div>

                    <div className="mt-3 d-flex justify-content-end">
                      <Button
                        type="submit"
                        size="lg"
                        value="Add"
                        className="text-capitalize px-3"
                        style={{ background: "#0808ff", color: "white", fontSize: "16px" }}
                        disabled={isLoading}                  >
                        {/* {isLoading ? }Add Challenge */}
                        {isLoading ?
                          <>
                            <div className="px-3">
                              <div className="spinner-border text-light mx-auto d-flex" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          </>
                          :
                          <>
                            <div className="px-1">
                              <span>Edit</span>
                            </div>
                          </>
                        }
                      </Button>
                    </div>
                  </form>
                </div>
              </Modal.Body>
            </Modal>
          )
        }

        <Modal show={showDescription} onHide={handleCloseDescription}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <div><span className=" fs-5 font-weight-600">Description</span></div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-2">
              <span style={{ fontSize: "17px", fontWeight: "500" }}>
                {descriptionModal}
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="fw-700 text-capitalize px-3" style={{ color: "grey", fontSize: "18px", background: "#f0f2f5" }} onClick={handleCloseDescription}>Close</Button>
          </Modal.Footer>
        </Modal>  

        <Modal show={showDelteChallenge} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Challenges</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <span> Are you sure want delete this challenge?</span>
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex gap-2">
              <div>
                <Button
                  size="lg"
                  style={{ color: "grey" }}
                  className="text-capitalize"
                  onClick={handleCloseDeleteModal}
                >
                  Discard
                </Button>
              </div>

              <div>
                <Button
                  size="lg"
                  className="text-capitalize"
                  style={{ background: "#0808ff", color: "white" }}
                  onClick={deleteChallenges}
                  disabled={isLoading}
                >
                  {isLoading ?
                    <>
                      <div className="px-3">
                        <div className="spinner-border text-light mx-auto d-flex" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className="px-1">
                        <span>Delete</span>
                      </div>
                    </>
                  }
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        </div>
    </div>
  )
}
