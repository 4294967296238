// ResultView.js
import React, { useEffect, useState } from "react";
import {
	Box,
	Typography,
	Button,
	TextField,
	Grid,
	Card,
	CardContent,
	Paper,
} from "@mui/material";
import { useCareerContext } from "contexts/CareerContext";
import { Popup } from "layout/Popup";
import { apiJson } from "api"; // Make sure to import your API utility
import YuvaLoader from "pages/Forum/components/Loader/YuvaLoader";
import { useGlobalContext } from "global/context";
import ReactMarkdown from "react-markdown";
import { CircularProgressbar } from "react-circular-progressbar";
const ResultView = () => {
	const { role, getScore, selectedStudentId, setSelectedSubNavOption } =
		useCareerContext();
	const { userData } = useGlobalContext();
	const [categoryScores, setCategoryScores] = useState({});
	const [teacherComments, setTeacherComments] = useState([]);
	const [personalizedMessage, setPersonalizedMessage] = useState("");
	const [noFeedbackMessage, setNoFeedbackMessage] = useState(false);
	const [careers, setCareers] = useState([]);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);

	const RIASECFullForm = {
		r: "Realistic",
		a: "Artistic",
		i: "Investigative",
		s: "Social",
		e: "Enterprising",
		c: "Conventional",
	};

	const [selectedModernCareer, setSelectedModernCareer] = useState(null);
	const [selectedTraditionalCareer, setSelectedTraditionalCareer] =
		useState(null);
	console.log(
		"🚀 ~ ResultView ~ selectedTraditionalCareer:",
		selectedTraditionalCareer
	);

	useEffect(() => {
		// Set default selections for Modern and Traditional Careers
		if (careers.length > 0) {
			const modernCareers = careers.slice(0, 3);
			const traditionalCareers = careers.slice(3);

			if (modernCareers.length > 0) setSelectedModernCareer(modernCareers[0]);
			if (traditionalCareers.length > 0)
				setSelectedTraditionalCareer(traditionalCareers[0]);
		}
	}, [careers]);

	const handleModernCareerClick = (career) => {
		setSelectedModernCareer(career);
	};

	const handleTraditionalCareerClick = (career) => {
		setSelectedTraditionalCareer(career);
	};

	const renderCareerCards = (
		careerGroup,
		label,
		handleClick,
		selectedCareer
	) => (
		<>
			<Typography
				variant="h6"
				sx={{ my: 3, fontSize: "24px", fontWeight: "bold" }}
			>
				{label}{" "}
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="36"
					height="36"
					marginLeft="10"
					fill="currentColor"
					class="bi bi-arrow-right"
					viewBox="0 0 16 16"
				>
					<path
						fill-rule="evenodd"
						d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
					/>
				</svg>
			</Typography>

			<Grid
				container
				spacing={2}
				width={"100%"}
				alignItems="center"
				// justifyContent="center"
			>
				{careerGroup.map((career, index) => (
					<Grid
						item
						xs="auto"
						key={index}
						display="flex"
						justifyContent="center"
					>
						<Card
							sx={{
								flex: 1,
								minWidth: "150px",
								padding: "0.5rem 1rem",
								height: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								backgroundColor:
									selectedCareer?.careerName === career.careerName && "#2356fe",

								border: "2px solid #2356fe",
								cursor: "pointer",
								transition: "transform 0.3s",
								"&:hover": {
									transform: "scale(1.05)",
								},
							}}
							onClick={() => handleClick(career)}
						>
							<CardContent sx={{ textAlign: "center" }}>
								<Typography
									variant="h6"
									sx={{
										fontSize: "1rem",
										fontWeight: "bold",
										color:
											selectedCareer?.careerName === career.careerName
												? "#ffffff"
												: "#2356fe",
									}}
								>
									{career.careerName}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>
		</>
	);

	const modernCareers = careers.slice(0, 3); // First three are Modern Era Careers
	const traditionalCareers = careers.slice(3); // Remaining are Traditional Careers

	// Combine selectedModernCareer and selectedTraditionalCareer to show only the selected one
	// const selectedCareer = selectedModernCareer || selectedTraditionalCareer;

	useEffect(() => {
		const fetchCategoryScores = async () => {
			try {
				const scores = await getScore(selectedStudentId, "student");
				if (scores) {
					setCategoryScores(scores);
					fetchFeedback();
				} else {
					Popup("error", "", "No scores received. Please try again.", 3000);
				}
			} catch (error) {
				console.error("Error fetching scores:", error);
				Popup(
					"error",
					"",
					"Error occurred while fetching scores. Please try again.",
					3000
				);
			}
		};

		fetchCategoryScores();
	}, [getScore, selectedStudentId]);

	useEffect(() => {
		const fetchCareers = async () => {
			setLoading(true);
			try {
				const response = await apiJson(
					`api/v2/riasecTest/riasec-careers/${selectedStudentId}`
				);
				if (response.data?.result?.length) {
					// console.log("Careers are", response);
					setCareers(response.data.result);
					setError(false);
				} else {
					setError(true);
				}
			} catch (error) {
				console.error("Error fetching careers:", error);
				setError(true);
			} finally {
				setLoading(false);
			}
		};

		if (selectedStudentId) {
			fetchCareers();
		}
	}, [selectedStudentId]);

	const handleFeedbackChange = (event) => {
		setPersonalizedMessage(event.target.value);
	};

	const handleSubmitFeedback = async () => {
		try {
			const feedbackPayload = {
				userId: userData.id,
				studentId: selectedStudentId,
				role,
				feedback: personalizedMessage,
			};

			await apiJson.post(
				"/api/v2/riasecTest/riasec-student-feedback",
				feedbackPayload
			);
			Popup("success", "", "Feedback submitted successfully!", 3000);
			setPersonalizedMessage(""); // Clear feedback input
			fetchFeedback(); // Fetch feedback after successful submission
		} catch (error) {
			Popup("error", "", "Failed to submit feedback. Please try again.", 3000);
			console.error("Error submitting feedback:", error);
		}
	};

	const fetchFeedback = async () => {
		try {
			const response = await apiJson(
				`/api/v2/riasecTest/riasec-feedbacks/${selectedStudentId}`
			);

			if (response.status === 204) {
				setNoFeedbackMessage(true);
				setTeacherComments([]);
				return;
			}

			const feedbackData = response.data.result;

			// Check if feedbackData is valid
			if (!feedbackData) {
				setNoFeedbackMessage(true);
				return;
			}

			// Ensure that teacherFeedback and instituteFeedback are valid objects
			const teacherFeedback = feedbackData.teacherFeedback || {};
			const instituteFeedback = feedbackData.instituteFeedback || {};

			const feedbackList = [];

			// Iterate over teacherFeedback to extract comments
			for (const teacher in teacherFeedback) {
				if (Array.isArray(teacherFeedback[teacher])) {
					teacherFeedback[teacher].forEach((comment) => {
						feedbackList.push({ role: `Teacher (${teacher})`, comment });
					});
				}
			}

			// Iterate over instituteFeedback to extract comments
			for (const institute in instituteFeedback) {
				if (Array.isArray(instituteFeedback[institute])) {
					instituteFeedback[institute].forEach((comment) => {
						feedbackList.push({ role: `Institute`, comment });
					});
				}
			}

			// Sort feedback by latest first
			setTeacherComments(feedbackList.reverse());
			setNoFeedbackMessage(feedbackList.length === 0);
		} catch (error) {
			Popup("error", "", "Failed to fetch feedback. Please try again.", 3000);
			console.error("Error fetching feedback:", error);
		}
	};

	const handleBack = () => {
		setSelectedSubNavOption("Student's List");
	};

	const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

	// Update `isMobile` state on window resize
	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth < 900);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<Box
			sx={{
				padding: "2rem",
				boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
				borderRadius: "1rem",
				display: "flex",
				flexDirection: "column",
				// alignItems: "center",
				gap: "2rem",
				minHeight: "80vh",
				width: "100%",
				// textAlign: "center",
				backgroundColor: "#f9f9f9",
			}}
		>
			{loading && <YuvaLoader setShow={false} show={loading} />}

			{/* back button*/}

			<button
				style={{
					// backgroundColor: "#2356fe",
					// paddingInline: "1.5rem",
					// paddingBlock: ".7rem",
					color: "#2356fe",
					cursor: "pointer",
					borderRadius: ".5rem",
					display: !isMobile && "none",
				}}
				onClick={() => handleBack()}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="25"
					height="25"
					fill="#2356fe"
					class="bi bi-arrow-left"
					viewBox="0 0 16 16"
					style={{ marginRight: 10 }}
				>
					<path
						fill-rule="evenodd"
						d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
					/>
				</svg>
				Back
			</button>

			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					position: "relative",
				}}
			>
				<Typography
					variant="h2"
					sx={{
						fontWeight: "bold",
						color: "#2356fe",
						fontSize: { xs: "2rem", sm: "2.5rem" },
					}}
				>
					Assessment <span style={{ color: "black" }}>Report</span>
				</Typography>

				<div
					style={{
						backgroundColor: "#2356fe",
						paddingInline: "1.5rem",
						paddingBlock: ".7rem",
						color: "white",
						cursor: "pointer",
						borderTopLeftRadius: "2rem",
						borderBottomLeftRadius: "2rem",
						position: "absolute",
						right: "-36.7px",
						display: isMobile && "none",
					}}
					onClick={() => handleBack()}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="currentColor"
						class="bi bi-arrow-left"
						viewBox="0 0 16 16"
						style={{ marginRight: 10 }}
					>
						<path
							fill-rule="evenodd"
							d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
						/>
					</svg>
					Back
				</div>
			</div>

			{/* progressbar */}
			<div
				style={{
					display: "flex",
					// flexWrap: isMobile && "wrap",
					justifyContent: "space-between",
					overflowX: isMobile && "scroll",
					gap: 15,
				}}
			>
				{Object.entries(categoryScores).map(([key, score]) => {
					return (
						<div
							key={key}
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
								gap: 8,
							}}
						>
							<div
								style={{
									width: 95,
									height: 95,
								}}
							>
								<CircularProgressbar
									value={score}
									maxValue={7}
									minValue={1}
									text={score}
									styles={{
										path: {
											stroke:
												role === "teacher"
													? " rgba(35, 86, 254, 1)"
													: "#5498A7",
										},
										trail: {
											stroke:
												role === "teacher"
													? " rgba(35, 86, 254, 0.4)"
													: " rgba(84, 152, 167, 0.4)",
										},
										text: {
											color:
												role === "teacher"
													? " rgba(35, 86, 254, 1)"
													: "#5498A7",
										},
									}}
								/>
							</div>

							<Box
								sx={{
									marginTop: "0.3rem",
								}}
							>
								<Typography
									component={"p"}
									sx={{
										paddingInline: "13px",
										paddingBlock: "7px",
										backgroundColor:
											role === "teacher" ? " rgba(35, 86, 254, 1)" : "#5498A7",
										borderRadius: "10px",
										color: "white",
										fontWeight: "bold",
									}}
								>
									{RIASECFullForm[key]}
								</Typography>
							</Box>
						</div>
					);
				})}
			</div>

			{/* result summary */}

			<Box sx={{ mt: 4, width: "100%" }}>
				<Typography
					variant="h6"
					sx={{
						fontSize: { xs: "2rem", sm: "2.5rem" },
						fontWeight: "bold",
						color: "#2356fe",
						mb: 2,
						textAlign: "left",
					}}
				>
					Result <span style={{ color: "black" }}>Summary</span>
				</Typography>

				<Paper
					sx={{
						mt: 4,
						p: 3,
						border: "2px solid #2356fe",
						backgroundColor:
							role === "teacher" ? " rgba(35, 86, 254, 1)" : "#ffffff",
						textAlign: "left",
					}}
				>
					<h5 style={{ color: role === "teacher" ? "#ffffff " : " black " }}>
						Looking at Lakshya’s marks it has been concluded that :
					</h5>
					<ul
						style={{
							paddingInline: "15px",
						}}
					>
						<li
							style={{
								listStyle: "disc",
								color: role === "teacher" ? "#ffffff " : " black ",
							}}
						>
							Lakshya is an Artistic and Realistic guy scoring most marks in the
							category.{" "}
						</li>
						<li
							style={{
								listStyle: "disc",
								color: role === "teacher" ? "#ffffff " : " black ",
							}}
						>
							Lakshya is an Artistic and Realistic guy scoring most marks in the
							category.{" "}
						</li>
						<li
							style={{
								listStyle: "disc",
								color: role === "teacher" ? "#ffffff " : " black ",
							}}
						>
							Lakshya is an Artistic and Realistic guy scoring most marks in the
							category.{" "}
						</li>
						<li
							style={{
								listStyle: "disc",
								color: role === "teacher" ? "#ffffff " : " black ",
							}}
						>
							Lakshya is an Artistic and Realistic guy scoring most marks in the
							category.{" "}
						</li>
					</ul>
				</Paper>
			</Box>

			{/* Careers Section */}
			<Box sx={{ mt: 4, width: "100%" }}>
				<Typography
					variant="h4"
					sx={{
						fontWeight: "bold",
						color: "#2356fe",
						textAlign: "left",
						fontSize: { xs: "2rem", sm: "2.5rem" },
					}}
				>
					Career <span style={{ color: "black" }}>Guidance</span>
				</Typography>

				{!error && (
					<>
						{/* Render Modern Era Careers */}
						{modernCareers.length > 0 &&
							renderCareerCards(
								modernCareers,
								"Modern Era Careers",
								handleModernCareerClick,
								selectedModernCareer
							)}
						{selectedModernCareer && (
							<Paper
								sx={{
									mt: 4,
									p: 3,
									border: "2px solid #2356fe",
									backgroundColor:
										role === "teacher" ? " rgba(35, 86, 254, 1)" : "#ffffff",
									textAlign: "left",
								}}
							>
								<Typography
									variant="h5"
									sx={{
										fontWeight: "bold",
										color:
											role === "teacher" ? "#ffffff" : " rgba(35, 86, 254, 1)",
										mb: 2,
									}}
								>
									{selectedModernCareer.careerName}
								</Typography>

								<ReactMarkdown
									children={selectedModernCareer.careerDetails}
									components={{
										h1: ({ node, ...props }) => (
											<Typography
												variant="h4"
												sx={{
													color: role === "teacher" ? "#ffffff" : "black",
													fontWeight: "bold",
													mb: 2,
												}}
												{...props}
											/>
										),
										h2: ({ node, ...props }) => (
											<Typography
												variant="h5"
												sx={{
													color: role === "teacher" ? "#ffffff" : "black",
													fontWeight: "bold",
												}}
												{...props}
											/>
										),
										h3: ({ node, ...props }) => (
											<Typography
												variant="h6"
												sx={{
													color: role === "teacher" ? "#ffffff" : "black",
													fontWeight: "bold",
												}}
												{...props}
											/>
										),
										h4: ({ node, ...props }) => (
											<Typography
												variant="body1"
												sx={{
													color: role === "teacher" ? "#ffffff" : "black",
													fontWeight: "bold",
												}}
												{...props}
											/>
										),
										p: ({ node, ...props }) => (
											<Typography
												variant="body1"
												sx={{
													mb: 1,
													color: role === "teacher" ? "#ffffff" : "black",
												}}
												{...props}
											/>
										),
										ul: ({ node, ...props }) => (
											<ul
												style={{
													paddingLeft: "1.5rem",
													color: role === "teacher" ? "#ffffff" : "black",
												}}
												{...props}
											/>
										),
										li: ({ node, ...props }) => (
											<Typography
												component="li"
												variant="body1"
												sx={{
													mb: 0.5,
													color: role === "teacher" ? "#ffffff" : "black",
												}}
												{...props}
											/>
										),
									}}
								/>
							</Paper>
						)}

						{/* Render Traditional Careers */}
						{traditionalCareers.length > 0 &&
							renderCareerCards(
								traditionalCareers,
								"Traditional Careers",
								handleTraditionalCareerClick,
								selectedTraditionalCareer
							)}

						{/* Render career details dynamically based on selected career */}
						{selectedTraditionalCareer && (
							<Paper
								sx={{
									mt: 4,
									p: 3,
									border: "2px solid #2356fe",
									backgroundColor:
										role === "teacher" ? " rgba(35, 86, 254, 1)" : "#ffffff",
									textAlign: "left",
								}}
							>
								<Typography
									variant="h5"
									sx={{
										fontWeight: "bold",
										color:
											role === "teacher" ? "white" : " rgba(35, 86, 254, 1)",
										mb: 2,
									}}
								>
									{selectedTraditionalCareer.careerName}
								</Typography>

								<ReactMarkdown
									children={selectedTraditionalCareer.careerDetails}
									components={{
										h1: ({ node, ...props }) => (
											<Typography
												variant="h4"
												sx={{
													color: role === "teacher" ? "#ffffff" : "black",
													fontWeight: "bold",
													mb: 2,
												}}
												{...props}
											/>
										),
										h2: ({ node, ...props }) => (
											<Typography
												variant="h5"
												sx={{
													color: role === "teacher" ? "#ffffff" : "black",
													fontWeight: "bold",
												}}
												{...props}
											/>
										),
										h3: ({ node, ...props }) => (
											<Typography
												variant="h6"
												sx={{
													color: role === "teacher" ? "#ffffff" : "black",
													fontWeight: "bold",
												}}
												{...props}
											/>
										),
										h4: ({ node, ...props }) => (
											<Typography
												variant="body1"
												sx={{
													color: role === "teacher" ? "#ffffff" : "black",
													fontWeight: "bold",
												}}
												{...props}
											/>
										),
										p: ({ node, ...props }) => (
											<Typography
												variant="body1"
												sx={{
													mb: 1,
													color: role === "teacher" ? "#ffffff" : "black",
												}}
												{...props}
											/>
										),
										ul: ({ node, ...props }) => (
											<ul
												style={{
													paddingLeft: "1.5rem",
													color: role === "teacher" ? "#ffffff" : "black",
												}}
												{...props}
											/>
										),
										li: ({ node, ...props }) => (
											<Typography
												component="li"
												variant="body1"
												sx={{
													mb: 0.5,
													color: role === "teacher" ? "#ffffff" : "black",
												}}
												{...props}
											/>
										),
									}}
								/>
							</Paper>
						)}
					</>
				)}
			</Box>

			{/* Feedback Section */}
			<Box sx={{ mt: 4, width: "100%" }}>
				<Typography
					variant="h6"
					sx={{
						fontSize: { xs: "2rem", sm: "2.5rem" },
						fontWeight: "bold",
						color: "#2356fe",
						mb: 2,
						textAlign: "left",
					}}
				>
					Add <span style={{ color: "black" }}>Feedback</span>
				</Typography>
				<TextField
					fullWidth
					multiline
					rows={4}
					variant="outlined"
					value={personalizedMessage}
					onChange={handleFeedbackChange}
					placeholder="Type your feedback here..."
					sx={{ mt: 2 }}
				/>
				<Button
					variant="contained"
					sx={{ mt: 2, backgroundColor: "#2356fe", color: "#fff" }}
					onClick={handleSubmitFeedback}
				>
					Submit Feedback
				</Button>
			</Box>

			{/* Existing Feedback */}
			<Box sx={{ mt: 4, width: "100%" }}>
				<Typography
					variant="h6"
					sx={{
						fontSize: { xs: "2rem", sm: "2.5rem" },
						fontWeight: "bold",
						color: "#2356fe",
						mb: 2,
						textAlign: "left",
					}}
				>
					Other <span style={{ color: "black" }}>Feedbacks</span>
				</Typography>
				{noFeedbackMessage ? (
					<Typography variant="body2" sx={{ color: "#666", textAlign: "left" }}>
						No comments have been made yet.
					</Typography>
				) : (
					teacherComments.map((feedback, index) => (
						<Card
							key={index}
							sx={{
								mb: 1,
								backgroundColor: "#f9f9f9",
								border: "1px solid #e0e0e0",
								borderRadius: "8px",
								boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
								padding: "1rem",
							}}
						>
							<CardContent sx={{ display: "flex", flexDirection: "column" }}>
								<Box sx={{ display: "flex", alignItems: "center" }}>
									<Typography
										variant="body1"
										sx={{ fontWeight: "bold", color: "#2356fe", mr: 1 }}
									>
										{feedback.role}:
									</Typography>
									<Typography
										variant="body2"
										sx={{
											color: "#333",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
										}}
									>
										{feedback.comment}
									</Typography>
								</Box>
							</CardContent>
						</Card>
					))
				)}
			</Box>
		</Box>
	);
};

export default ResultView;
