import React, { useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { Stepper, Step } from "react-form-stepper";
import EmailInput from './EmailInput';

export default function EmailDeleteByAdmin() {
  const [goSteps, setGoSteps] = useState(0);
  const [studentSteps, setStudentSteps] = useState(0); 

  
  const handleInstitutionSteps = () => {
    setGoSteps((prev) => prev + 1);
  };

  
  const handleStudentSteps = () => {
    setStudentSteps((prev) => prev + 1);
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1200px",
        margin: "0 auto",
        padding: { xs: "1rem", md: "2rem" }, 
      }}
    >
      {}
      <Typography
        component={"h1"}
        sx={{
          fontWeight: "600",
          fontSize: { xs: "1.2rem", md: "1.8rem" }, 
          textAlign: "center",
          color: "#333",
          marginBottom: { xs: "1rem", md: "2rem" },
        }}
      >
        Delete Email
      </Typography>

      {}
      <Box sx={{ marginBottom: "2rem", padding: { xs: "1rem", md: "2rem" } }}>
        {/* <Typography
          variant="h6"
          sx={{
            fontWeight: 300,
            marginBottom: "1rem",
            color: "#555",
            fontSize: { xs: "1rem", md: "1.25rem" }, 
          }}
        >
          Institution Email Delete
        </Typography> */}
        <Stepper
          activeStep={goSteps}
          styleConfig={{
            activeBgColor: "#2356FE",
            completedBgColor: "#2356FE",
            inactiveBgColor: "#E0E0E0",
            activeTextColor: "#fff",
            completedTextColor: "#fff",
            inactiveTextColor: "#000",
          }}
          connectorStyleConfig={{
            activeColor: "#2356FE",
            completedColor: "#2356FE",
            disabledColor: "#E0E0E0",
          }}
        >
          <Step onClick={() => setGoSteps(0)} label="Institutions" />
          <Step onClick={() => setGoSteps(1)} label="Institute Register" />
          <Step onClick={() => setGoSteps(2)} label="Institute Register Details" />
        </Stepper>

        <Box sx={{ marginTop: "2rem" }}>
          {goSteps === 0 && (
            <EmailInput
              title="Institutions"
              getSearchData="/find-from-institute"
              deleteData="/delete-from-institute"
              goSteps={goSteps}
              errormsg={"this email doesn't exist in institutions. click on next button to proceed "}
              handleSteps={handleInstitutionSteps} 
            />
          )}
          {goSteps === 1 && (
            <EmailInput
              title="Institute Register"
              getSearchData="/find-from-register"
              deleteData="/delete-from-register"
              goSteps={goSteps}
              errormsg={"this email doesn't exist in institute register. click on next button to proceed "}
              handleSteps={handleInstitutionSteps}
            />
          )}
          {goSteps === 2 && (
            <EmailInput
              title="Institute Reg Details"
              getSearchData="/find-from-reg"
              deleteData="/delete-from-reg"
              goSteps={goSteps}
              errormsg={"this email doesn't exist in institute reg details. click on next button to proceed "}
              handleSteps={handleInstitutionSteps}
            />
          )}
        </Box>
      </Box>

    </Box>
  );
}
