import React from "react";
import { UI2Feature } from ".";
import CloudDoneTwoToneIcon from '@mui/icons-material/CloudDoneTwoTone';
import CloudCircleSharpIcon from '@mui/icons-material/CloudCircleSharp';
import PendingTwoToneIcon from '@mui/icons-material/PendingTwoTone';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import PsychologyIcon from '@mui/icons-material/Psychology';
const features = [
  {
    id: 1,
    featureIcon: <CloudDoneTwoToneIcon className="dashboardIcon"/>,
    name: "Lifestyle for Environment",
    url:"/life",
    isDiseble:false
  },
  {
    id: 2,
    featureIcon: <CloudCircleSharpIcon className="dashboardIcon"/>,
    name: "Climate Change",
    url:"/climate-change-and-disaster-risk-reduction",
    isDiseble:false
  },

  {
    id: 3,
    featureImg: "/ui2.0dashboard/Taj Mahal.svg",
    name: "Bharat at 2047",
    url:"/new-dashboard",
    isDiseble:true
  },
  {
    id: 4,
    featureIcon: <PsychologyIcon sx={{color: "#6909ff",width: "40px", height: "35px"}} className="dashboardIcon"/>,
    featureImg: "/ui2.0dashboard/Innovation.svg",
    name: "IP Innovation",
    url:"/new-dashboard",
    isDiseble:true
  },
  {
    id: 5,
    // featureIcon: <BusinessCenterRoundedIcon sx={{color: "#6909ff",width: "40px", height: "40px"}} className=""/>,
    featureImg: "/ui2.0dashboard/Business.svg",
    name: "Future of Work",
    url:"/future-of-work",
    isDiseble:false
  },
  {
    id: 6,
    featureImg: "/ui2.0dashboard/Peace Pigeon.svg",
    name: "Peace building and Reconciliation Boards",
    url:"/peacebuilding-and-reconciliation",
    isDiseble:false
  },
  {
    id: 7,
    // featureIcon: <HandshakeTwoToneIcon className="" sx={{color: "#6909ff",width: "40px", height: "35px"}}/>,
    featureImg: "/ui2.0dashboard/Handshake.svg",
    name: "Shared Future",
    url:"/shared-future",
    isDiseble:false
  },
  {
    id: 8,
  //   featureIcon : <svg style={{color: "#6909ff"}} xmlns="http://www.w3.org/2000/svg" width="35" height="50" fill="currentColor" class="bi bi-heart-pulse-fill" viewBox="0 0 16 16">
  //   <path d="M1.475 9C2.702 10.84 4.779 12.871 8 15c3.221-2.129 5.298-4.16 6.525-6H12a.5.5 0 0 1-.464-.314l-1.457-3.642-1.598 5.593a.5.5 0 0 1-.945.049L5.889 6.568l-1.473 2.21A.5.5 0 0 1 4 9z"/>
  //   <path d="M.88 8C-2.427 1.68 4.41-2 7.823 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C11.59-2 18.426 1.68 15.12 8h-2.783l-1.874-4.686a.5.5 0 0 0-.945.049L7.921 8.956 6.464 5.314a.5.5 0 0 0-.88-.091L3.732 8H.88Z"/>
  // </svg>,
    featureImg: "/ui2.0dashboard/Heart with Pulse.svg",
    name: "Health, Well-being & Sports",
    url:"/health-well-being-and-sports",
    isDiseble:false
  },
];
export const UI2DashboardResources = () => {
  return (
    <>
      <div className="w-100 rounded-4 shadow mb-2">
          <div className="row px-4 px-md-5">
            <h3 className="fs-3 fw-bolder py-3">Resourses</h3>
            {features.map((feature, index) => {
              return (
                <>
                  <div className="col-3 col-sm-3 col-md-3 col-lg-2" key={index}>
                    <UI2Feature data={feature} />
                  </div>
                </>
              );
            })}
          </div>
        </div>
    </>
  );
};
