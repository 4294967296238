import { useGlobalContext } from "global/context";
import React from "react";
import { useNavigate } from "react-router-dom";

const downloads = [
  {
    id: 1,
    logo: "/ui2.0dashboard/Downloads Folder.svg",
    name: "Event Collaterals",
    path:"/Download/EventCollaterals"
  },
  // {
  //   id: 2,
  //   logo: "/ui2.0dashboard/Cleanup Noise.svg",
  //   name: "Background Guides",
  //   path:"/Download/background-Guides"
  // },

  // {
  //   id: 3,
  //   logo: "/ui2.0dashboard/Versions.svg",
  //   name: "Formats",
  //   path:"/Download/formats"
  // },
];
export const UI2DashboardDownload = () => {
const {userData} = useGlobalContext()
const nipamStudent = userData?.isNipam?true:false
  const navigate = useNavigate()
  const  handleNavigate=(item)=>{
    navigate(item)
  }
  return (
    <>
      {(userData.role === 'institute' && !nipamStudent) &&
        <div className="rounded-4 shadow px-3 py-3">
          <h3 className="fs-3 fw-bold py-3 px-3">Downloads</h3>
          <div className="w-100 px-3 pt-3">

            <div className="row">
              {downloads?.map((download, index) => {
                return (
                  <div
                    className={`col-3 col-sm-3 col-md-4 col-lg-2 text-center me-2 me-md-0`}
                    key={download?.id}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center mb-4">
                      <div
                        className='border border-1 rounded-circle p-4 download_card downloadCardcolor cursor-pointer'
                        onClick={() => handleNavigate(download?.path)}
                      >
                        <img
                          src={download?.logo ? download?.logo : "/ui2.0dashboard/Ellipse 80.png"}
                          alt={download?.name}
                          className="p-1 DownloadImages"
                        />
                      </div>
                      <span className="fw-400 text-center dashboardFeatureTitle">{download?.name}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      }
    </>
  );
};
