import React, { useContext, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";

import Avatar from "@mui/material/Avatar";
import { useLocation, useParams } from "react-router-dom";
import { ClubContext } from "../TimelineClub";
import { UserContext, useGlobalContext } from "global/context";
import { toast } from "react-toastify";
import { apiJson } from "api";
import "../style/clubStyle.css";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import WorkIcon from "@mui/icons-material/Work";
import "react-circular-progressbar/dist/styles.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";

import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import ShareRegister from "../ShareRegister";

const ClubProfileTopDetail = ({ clickuserDetail, clickclubUserDetail }) => {
  const location = useLocation();
  const { userId } = useParams();
  const isInstitute = location.pathname.includes("institute");
  const { userData } = useContext(UserContext);
  const { fetchClickClubUserDetail, fetchClubUserDetail } =
    useContext(ClubContext);

  const [showDetailModal, setshowDetailModal] = useState(false);
  const [extra_curriculum, setextra_curriculum] = useState("nothing extra");
  const [education, seteducation] = useState("nothing extra");
  const [achievements, setachievements] = useState("No acchivements");
  const [small_bio, setsmall_bio] = useState("Nothing about me");
  const [profileUser, setProfileUser] = useState([]);
  const [open, setOpen] = useState(false);
  const [getApplicantDetails,setApplicantDetails] = useState({})
  const[certDetails,setCertDetails] = useState({})
  const [registerLink, setRegisterLink] = useState(`${process.env.REACT_APP_MAIN_URL}registration?registration_type=url&count=4&type=Student&instituteId=${userData?.id}`)
  const [copyLink, setCopyLink] = useState({
      Student: false,
      Teacher: false
  })
  const qrCodeRef = useRef(null);
    const qrCodeRefteacher = useRef(null)
  const [profileLoader, setProfileLoader] = useState(false);

const handleCopy = (type) => {
  let linkElement;
  let copyText;

  if (type === 'Student') {
      linkElement = document.getElementById('StudentLink');
  } else {
      linkElement = document.getElementById('TeacherLink');
  }
  setCopyLink({
      Student: type === "Student" ? !copyLink.Student : false,
      Teacher: type === "Teacher" ? !copyLink.Teacher : false,

  })

  if (linkElement) {

      copyText = linkElement.textContent || linkElement.innerText;
      const textarea = document.createElement('textarea');
      textarea.value = copyText;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');

      // Highlight the link by adding a temporary red background
      const originalBackgroundColor = linkElement.style.backgroundColor;
      linkElement.style.backgroundColor = '#9ed060';

      // Set a timeout to remove the red background after a brief period
      setTimeout(() => {
          linkElement.style.backgroundColor = originalBackgroundColor;
          setCopyLink({
              Student: false,
              Teacher: false,

          })
      }, 2000); // Adjust the duration as needed

      // Remove the dynamically created textarea
      document.body.removeChild(textarea);
  }
};

  const getProfileData = () => {
    setProfileLoader(true)
    apiJson(
      `api/v2/profile/userDetail/role/${isInstitute ? "institute" : "student"
      }/user/${userId}`
    )
      .then((res) => {
        setProfileUser(res?.data?.userDetail);
        setProfileLoader(false)
        // console.log("res?.data?.userDetail", res?.data?.userDetail)
      })
      .catch((error) => {
        setProfileLoader(false)
        console.log(error.message);
      });
  };

  const ApplicantDetails =() =>{
    apiJson(`api/v2/modelUn-student/getApplicantDetails/${userId}`)
      .then((res) => {
        setApplicantDetails(res?.data?.ApplicantsDetails);
        // console.log("res?.data?.userDetail", res?.data?.userDetail)
      })
      .catch((error) => {
        console.log(error.message);
      });
  }
  const findCertificateDetails =() =>{
    apiJson(`api/v2/modelUn-student/getNipamCertificate/${userId}`)
      .then((res) => {
        setCertDetails(res?.data?.ApplicantsDetails);
        // console.log("res?.data?.userDetail", res?.data?.userDetail)
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  useEffect(() => {
    getProfileData();
    if(userData?.role !== 'institute'){
      ApplicantDetails()
      findCertificateDetails()
    }
  }, []);
  // console.log("profileUser", profileUser)
  useEffect(() => {
    getProfileData();
  }, [location.pathname]);



  const handleChangeDetail = (event) => {
    event.preventDefault();
    let data = clickclubUserDetail;
    data = { ...data, achievements, small_bio, extra_curriculum };

    // console.log("this is dataaaaaaaaaaaaaaaaaaa", data)
    apiJson
      .put(
        process.env.REACT_APP_API_BASE_URL +
        "club/updateProfileClub/" +
        userData.id +
        "/" +
        userData.role,
        data
      )
      .then((res) => {
        // console.log("res.data", res.data)
        toast.dismiss();
        toast.success("Successfully Updata Details");
        event.target.reset();
        fetchClubUserDetail();
        fetchClickClubUserDetail(userData.id, userData.role);
        setshowDetailModal(false);
      })
      .catch((error) => {
        setshowDetailModal(false);
        toast.error("Internal Server Error");
      });
  };

  let studentLink = `${process.env.REACT_APP_MAIN_URL}registration?registration_type=url&count=4&type=Student&instituteId=${userData?.id}`
  let teacherLink = `${process.env.REACT_APP_MAIN_URL}registration?registration_type=url&count=4&type=Teacher&instituteId=${userData?.id}`
  
  const [shareUserRole , setUserRole] = useState('')
  const handleShareSet = (type)=>{
    if(type === "Student"){
      setUserRole(type)
    }
    else{
      setUserRole(type)
    }
  }
  return (
    <>
        {profileLoader ? <div>
          <div className="drawing" id="loading">
                <div className="mx-auto loading-dot-top"></div>
              </div>
        </div>
        : 
      <div
        className="rounded-4  mb-4- pb-"
        style={{ background: "var(--club-component-backgroun" }}
      >
        <div className="col p-4">
          <div className="col-12">
            <div className="row align-items-center">
              {/* same circular div  for all*/}
              <div className="d-flex justify-content-center col-md-3">
                <div className="col-md-9 profile-img-circular-bar">
                  <CircularProgressbarWithChildren
                    value={profileUser?.profilePercentage}
                    maxValue={100}
                    text={profileUser?.profilePercentage}
                    styles={buildStyles({
                      pathColor:
                        profileUser?.profilePercentage < 40
                          ? "red"
                          : profileUser?.profilePercentage < 80
                            ? "orange"
                            : "#9555fd",
                    })}
                  >
                    <div className="profile-img-dimension">
                      <Avatar
                        className="h-100 w-100"
                        src={profileUser?.logo || profileUser?.profile}
                      />
                    </div>
                  </CircularProgressbarWithChildren>
                  <div className="display-institute-role text-center">
                    <button className="" style={{ fontFamily: "Lexend" }}>
                      {profileUser?.role === 'student' ? 'Student' : profileUser?.role === 'teacher' ? 'Teacher' : 'Institute'}
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-9 ">
                <div className="col-12 col-md-12 col-lg-12">
                  {/* new */}

                  <div className="mt-2">
                    {/* showing name and institute name for teacher and student */}
                    {['student', 'teacher'].includes(profileUser?.role) ? (
                      <div className="row justify-content-between align-items-center">
                        <span
                          className="col-12 col-md-6 text-sm-center  institute-teacher-student-name"
                          style={{ color: "#000000" }}
                        >
                          {profileUser?.first_name +
                            " " +
                            profileUser?.last_name}
                        </span>
                        <div
                          className="col-12 col-md-6 text-center qualification"
                          style={{
                            color: "#AD00FF",
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                        >
                          {profileUser?.class}
                        </div>
                      </div>
                    )

                      // showing institute name for instiute
                      : (
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="fw-700 fs-33px"
                            style={{ color: "#000000" }}
                          >
                            {profileUser?.institution_name}{" "}
                            <img src="/ProfileImg/institutelogo.svg" alt />
                          </span>
                        </div>
                      )}
                  </div>
                  {/* showing institute name for all  */}
                  {['student', 'teacher'].includes(profileUser?.role) && <div className="mt-3 d-flex align-items-center">
                    <div>
                      <span>
                        <img
                          style={{ height: "26px", width: "26px" }}
                          src="/ProfileImg/StudentCenter.png"
                          alt=""
                        />
                      </span>
                    </div>
                    <div className="ms-2">
                      <span
                        className="fw-700 fs-15px"
                        style={{ fontFamily: "Poppins", color: "#2F2F2F" }}
                      >
                        {profileUser?.institution_name}
                      </span>
                    </div>
                  </div>}
                  {getApplicantDetails  && userData?.role !=="institute" &&(
                    <div className="d-flex mt-3">
                      <div className="" style={{ height: "20px", width: "25px" }}>
                        {/* <WorkIcon sx={{ color: "#a97c3b" }} /> */}
                        <img className="w-100 h-100" src="/activism.png" />
                      </div>
                      <div className="">
                      <span className={`ms-2 fw-700 fs-16-07px ${getApplicantDetails?.status === "approved" ? "text-success" : "text-warning"}`}
                          style={{fontFamily: "Inter"}}>
                          {getApplicantDetails?.status === "approved" ? "Participant " : "Applicant "}
                          </span>
                        <span
                          className="ms-2 fw-700 fs-16-07px"
                          style={{
                            fontFamily: "Inter",
                            color: "#000000",
                          }}
                        >
                           in YMUN as
                        </span>
                        <span style={{ color: "#9b5ffd" }} > {getApplicantDetails?.pref_committee} {getApplicantDetails?.pref_designation} {getApplicantDetails?.pref_country} {getApplicantDetails?.pref_role} </span>

                      </div>
                    </div>
                  )}
                  {certDetails && userData?.role !=="institute" && (
                    <div className="d-flex mt-3">
                      <div className="" style={{ height: "20px", width: "25px" }}>
                        {/* <WorkIcon sx={{ color: "#a97c3b" }} /> */}
                        <img className="w-100 h-100" src="/certificate.png" />
                      </div>
                      <div className="">
                        <span
                          className="ms-2 fw-700 fs-6 text-success"
                          style={{
                            fontFamily: "Inter",
                            color: "#000000",
                          }}
                        >
                          Took NIPAM certification successfully.
                        </span>

                      </div>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </div>

          <div className="row row-cols-1 row-cols-md-2">

            {/* user information for institutes*/}
            {isInstitute && <div className="col mt-3">
              <div
                className="col-10"
                style={{ lineHeight: "25.5px" }}
              >
                <div className="">
                  <span
                    className="fw-700"
                    style={{
                      fontSize: "17px",
                      color: "#2F2F2F",
                      fontFamily: "Poppins",
                    }}
                  >
                    Contact Information
                  </span>
                </div>
              </div>

              {/* Address */}
              <div className="mt-3" style={{ lineHeight: "22.5px" }}>
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "15px",
                    color: "#2F2F2F",
                    fontFamily: "Poppins",
                    color: "#2F2F2F",
                  }}
                >
                  Address:{" "}
                </span>
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "15px",
                    fontFamily: "Poppins",
                    color: "#2F2F2F",
                  }}
                >
                  {profileUser?.institution_address}
                </span>
              </div>

              {/* Contact no */}
              <div
                className="col-10 mt-3"
                style={{ lineHeight: "22.5px" }}
              >
                <span
                  style={{
                    color: "#2F2F2F",
                    fontWeight: "700",
                    fontSize: "15px",
                    fontFamily: "Poppins",
                  }}
                >
                  Contact No: +91-{profileUser?.contact}
                </span>
              </div>

              {/* Website */}
              {profileUser?.website && (
                <div
                  className="col-10 mt-3"
                  style={{ lineHeight: "22.5px" }}
                >
                  <span
                    style={{
                      color: "#2F2F2F",
                      fontWeight: "700",
                      fontSize: "15px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Website:{" "}
                    <span
                      style={{
                        color: "#2F2F2F",
                        fontWeight: "400",
                        fontSize: "15px",
                        fontFamily: "Poppins",
                      }}
                    >
                      <a target="_blank" href={`http://${profileUser?.website?.replace(/^https?:\/\//, '').replace(/^www\./, '')}`}>{profileUser?.website.replace(/^https?:\/\//, '')}</a>
                    </span>
                  </span>
                </div>
              )}
            </div>}

            {/* institute details */}
            {isInstitute && <div className="col mt-3">
              <div className="Infrastructure-div">
                <div>
                  <span
                    className=""
                    style={{
                      fontWeight: "700",
                      fontSize: "17px",
                      fontFamily: "Poppins",
                      color: "#2F2F2F",
                      lineHeight: "22.5px",
                    }}
                  >
                    Infrastructure
                  </span>
                </div>
              </div>

              {/* showing no. of students for institutes */}
              <div
                className="Infrastructure-data"
                style={{ lineHeight: "22.5px" }}
              >
                <span
                  style={{
                    color: "#2F2F2F",
                    fontWeight: "700",
                    fontSize: "15px",
                    fontFamily: "Poppins",
                  }}
                >
                  No.of Students:{" "}
                  <span
                    style={{
                      color: "#2F2F2F",
                      fontWeight: "400",
                      fontSize: "15px",
                      fontFamily: "Poppins",
                    }}
                  >
                    {profileUser?.totalStudent || "0"}
                  </span>
                </span>
              </div>
              {/* showing no. of teacher for institutes */}
              <div
                className="Infrastructure-data"
                style={{ lineHeight: "22.5px" }}
              >
                <span
                  style={{
                    color: "#2F2F2F",
                    fontWeight: "700",
                    fontSize: "15px",
                    fontFamily: "Poppins",
                  }}
                >
                  No. of Teachers:{" "}
                  <span
                    style={{
                      color: "#2F2F2F",
                      fontWeight: "400",
                      fontSize: "15px",
                      fontFamily: "Poppins",
                    }}
                  >
                    {profileUser?.totalTeacher || "0"}
                  </span>
                </span>
              </div>


            </div>}
          </div>



          <div className="mt-5">
            <div>
              {/* experience for student and teacher */}
              {['student', 'teacher'].includes(profileUser?.role) && (
                <div>
                  {profileUser?.experience?.length > 0 && (
                    <div className="row mt-3">
                      <div className=" col-12 col-md-5 col-lg-3">
                        <div className="d-flex">
                          <div>
                            <WorkIcon sx={{ color: "#a97c3b" }} />
                          </div>
                          <div className="">
                            <span
                              className="ms-2 fw-700 fs-16-07px"
                              style={{
                                fontFamily: "Inter",
                                color: "#000000",
                              }}
                            >
                              Experience
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ marginLeft: "" }}
                        className="student-experience col-12 col-md-7 col-lg-9"
                      >
                        {profileUser?.experience?.slice(0, 3)?.map((ele) => {
                          return (
                            <>
                              <div className="ms-">
                                {/* <div><BadgeIcon sx={{color: "#fbc02d"}}/></div> */}
                                <div>
                                  <span
                                    className="fw-600 fs-14-07px"
                                    style={{
                                      fontFamily: "inter",
                                      color: "#000000",
                                    }}
                                  >
                                    <span
                                      className="text-uppercase fw-600 fs-14-07px"
                                      style={{
                                        fontFamily: "inter",
                                        color: "#000000",
                                      }}
                                    >
                                      {ele?.designation}
                                    </span>{" "}
                                    at{" "}
                                    <span
                                      className="text-capitalize"
                                      style={{ color: "#9b5ffd" }}
                                    >
                                      {ele?.company}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )}

              
                <div>
                {['student', 'teacher'].includes(profileUser?.role) && (
                <div>
                  {profileUser?.activities?.length > 0 && (
                    <div className="row mt-3">
                      <div className=" col-12 col-md-5 col-lg-3">
                        <div className="d-flex">
                          <div style={{height: "20px", width: "25px"}}>
                            {/* <WorkIcon sx={{ color: "#a97c3b" }} /> */}
                            <img className="h-100 w-100" src="/ProfileImg/checklist.png" alt="" />
                          </div>
                          <div className="">
                            <span
                              className="ms-2 fw-700 fs-16-07px"
                              style={{
                                fontFamily: "Inter",
                                color: "#000000",
                              }}
                            >
                              Activities
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ marginLeft: "" }}
                        className="student-experience col-12 col-md-7 col-lg-9"
                      >
                        {profileUser?.activities?.slice(0, 3)?.map((ele) => {
                          return (
                            <>
                              <div className="ms-">
                                {/* <div><BadgeIcon sx={{color: "#fbc02d"}}/></div> */}
                                <div>
                                  <span
                                    className="fw-600 fs-14-07px"
                                    style={{
                                      fontFamily: "inter",
                                      color: "#000000",
                                    }}
                                  >
                                    <span
                                      className="fw-600 fs-14-07px"
                                      style={{
                                        fontFamily: "inter",
                                        color: "#000000",
                                      }}
                                    >
                                      {ele?.name}
                                    </span>{" "}
                                    {/* at{" "} */}
                                    <span
                                      className="text-capitalize"
                                      style={{ color: "#9b5ffd" }}
                                    >
                                      {ele?.company}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  )}

                    {profileUser?.interests?.length > 0 && (
                      <div className="row mt-3">
                        <div className=" col-12 col-md-5 col-lg-3">
                          <div className="d-flex">
                            <div className="" style={{height: "20px", width: "25px"}}>
                              {/* <WorkIcon sx={{ color: "#a97c3b" }} /> */}
                              <img className="w-100 h-100" src="/ProfileImg/hobbies.png"/>
                            </div>
                            <div className="">
                              <span
                                className="ms-2 fw-700 fs-16-07px"
                                style={{
                                  fontFamily: "Inter",
                                  color: "#000000",
                                }}
                              >
                                Interests
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ marginLeft: "" }}
                          className="student-experience col-12 col-md-7 col-lg-9"
                        >
                          {profileUser?.interests?.slice(0, 3)?.map((ele) => {
                            return (
                              <>
                                <div className="ms-">
                                  <div>
                                    <span
                                      className="fw-600 fs-14-07px"
                                      style={{
                                        fontFamily: "inter",
                                        color: "#000000",
                                      }}
                                    >
                                      <span
                                        className="fw-600 fs-14-07px"
                                        style={{
                                          fontFamily: "inter",
                                          color: "#000000",
                                        }}
                                      >
                                        {ele?.name}
                                      </span>{" "}
                                      <span
                                        className="text-capitalize"
                                        style={{ color: "#9b5ffd" }}
                                      >
                                        {ele?.interests}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    )}

                </div>
              )}
                </div>

              {/* achievement for student */}
              {['student','teacher'].includes(profileUser?.role) && (
                <div>
                  {profileUser?.achievements?.length !== 0 && (
                    <div className="row mt-3">
                      <div className=" col-12 col-md-5 col-lg-3">
                        <div className="d-flex">
                          <div>
                            <img src="/ProfileImg/militaryMedal.svg" alt="" />
                          </div>
                          <div className="">
                            <span
                              className="ms-2 fw-700 fs-16-07px"
                              style={{
                                fontFamily: "Inter",
                                color: "#000000",
                              }}
                            >
                              Achievements
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="student-experience col-12 col-md-7 col-lg-9">
                        {profileUser?.achievements
                          .slice(0, 3)
                          ?.map((item) => (
                            <div>
                              <div>
                                <span
                                  className="fw-600 fs-14-07px"
                                  style={{
                                    fontFamily: "inter",
                                    color: "#000000",
                                  }}
                                >
                                  {item?.position} in
                                </span>{" "}
                                <span
                                  className="text-capitalize"
                                  style={{ color: "#9b5ffd" }}
                                >
                                  {" "}
                                  {item?.competition}
                                </span>
                              </div>
                              {/* <div><span className='fw-600 fs-14-07px' style={{ fontFamily: "inter" }}>2nd Prize <span className='fw-400'>in</span> Singing Competition</span></div> */}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              )}


            </div>
          </div>
        </div>
      </div>
        }


      {/* depricated modal */}
      <Modal
        show={showDetailModal}
        onHide={() => {
          setshowDetailModal(false);
        }}
      >
        <Modal.Header>Add Details</Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={handleChangeDetail}>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  value={small_bio}
                  onChange={(e) => setsmall_bio(e.target.value)}
                  className="form-control rounded-1"
                  name="Education"
                />
                <label htmlFor="companyName">Bio</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  value={education}
                  onChange={(e) => seteducation(e.target.value)}
                  className="form-control rounded-1"
                  name="Education"
                />
                <label htmlFor="companyName">Education</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  value={achievements}
                  onChange={(e) => setachievements(e.target.value)}
                  className="form-control rounded-1"
                  name="Achievements"
                />
                <label htmlFor="designation">Achievements</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  value={extra_curriculum}
                  onChange={(e) => setextra_curriculum(e.target.value)}
                  className="form-control rounded-1"
                  name="ExtraCurriculum"
                />
                <label htmlFor="shortDescription">Extra Curriculum</label>
              </div>

              <button type="submit" className="btn btn-primary mt-3">
                Submit
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClubProfileTopDetail;

const profileTeacher = [
  {
    exp: "Faculty",
    ins: "Amity Business School",
  },
  {
    exp: "2nd Prize in ",
    ins: "Singing Competition",
  },
];

const skills = [
  {
    skill: "Photography",
  },
  {
    skill: "Debating",
  },
  {
    skill: "Football",
  },
  {
    skill: "Science",
  },
  {
    skill: "History",
  },
];


const old_code = () => {
  // clickuserDetail?.role === "institute" ? (
  //   <div
  //     className="rounded-4  hover-shadow-for-component mb-4- pb-"
  //     style={{ background: "var(--club-component-backgroun" }}
  //   >
  //     <div className="col p-4">
  //       <div className="col-12">
  //         <div className="row">
  //         {/* same circular div  for all*/}
  //           <div className="d-flex justify-content-center col-md-3">
  //             <div className="col-md-9 profile-img-circular-bar">
  //               {/* Profile img */}
  //               <CircularProgressbarWithChildren
  //                 value={profileUser?.profilePercentage}
  //                 maxValue={100}
  //                 text={profileUser?.profilePercentage}
  //                 styles={buildStyles({
  //                   pathColor:
  //                     profileUser?.profilePercentage < 40
  //                       ? "red"
  //                       : profileUser?.profilePercentage < 80
  //                       ? "orange"
  //                       : "#9555fd",
  //                 })}
  //               >
  //                 <div className="profile-img-dimension">
  //                   <Avatar
  //                     className="h-100 w-100"
  //                     src={profileUser?.logo || profileUser?.profile}
  //                   />
  //                 </div>
  //               </CircularProgressbarWithChildren>

  //               <div className="display-institute-role text-center">
  //                 <button className="" style={{ fontFamily: "Lexend" }}>
  //                   College
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //         {/* end same circular div  for all */}
  //           <div className="col-12 col-md-9 ">
  //             <div className="col-12 col-md-12 col-lg-12">

  //               <div className="mt-2">
  //               {/* showing  name and class for student */}
  //                 {clickuserDetail?.role === "student" ? (
  //                   <div className="d-flex justify-content-between align-items-center">
  //                     <span
  //                       className="fw-700 fs-33px"
  //                       style={{ color: "#000000" }}
  //                     >
  //                       {profileUser?.first_name +
  //                         " " +
  //                         profileUser?.last_name}
  //                     </span>
  //                     <div
  //                       style={{
  //                         color: "#AD00FF",
  //                         fontSize: 19,
  //                         fontFamily: "Poppins",
  //                         fontWeight: "400",
  //                         wordWrap: "break-word",
  //                       }}
  //                     >
  //                       {profileUser?.class}
  //                     </div>
  //                   </div>
  //                 ) 
  //                     // showing institute name and logo 
  //                 : (
  //                   <div className="d-flex justify-content-between align-items-center">
  //                     <span
  //                       className="fw-700 fs-33px"
  //                       style={{ color: "#000000" }}
  //                     >
  //                       {profileUser?.institution_name}{" "}
  //                       <img src="/ProfileImg/institutelogo.svg" alt />
  //                     </span>
  //                   </div>
  //                 )}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>

  //       <div className="all-institute-data">
  //       {/* institute data  */}
  //         <div className="d-flex">
  //           <div>

  //             <div>
  //               <div className="row">
  //               {/* user information for institutes*/}
  //                 <div className="col-12 col-md-5">
  //                   <div
  //                     className="col-10"
  //                     style={{ lineHeight: "25.5px" }}
  //                   >
  //                     <div className="">
  //                       <span
  //                         className="fw-700"
  //                         style={{
  //                           fontSize: "17px",
  //                           color: "#2F2F2F",
  //                           fontFamily: "Poppins",
  //                         }}
  //                       >
  //                         Contact Information
  //                       </span>
  //                     </div>
  //                   </div>

  //                   {/* Address */}
  //                   <div className="mt-3" style={{ lineHeight: "22.5px" }}>
  //                     <span
  //                       style={{
  //                         fontWeight: "700",
  //                         fontSize: "15px",
  //                         color: "#2F2F2F",
  //                         fontFamily: "Poppins",
  //                         color: "#2F2F2F",
  //                       }}
  //                     >
  //                       Address:{" "}
  //                     </span>
  //                     <span
  //                       style={{
  //                         fontWeight: "400",
  //                         fontSize: "15px",
  //                         fontFamily: "Poppins",
  //                         color: "#2F2F2F",
  //                       }}
  //                     >
  //                       {profileUser?.institution_address}
  //                     </span>
  //                   </div>

  //                   {/* Contact no */}
  //                   <div
  //                     className="col-10 mt-3"
  //                     style={{ lineHeight: "22.5px" }}
  //                   >
  //                     <span
  //                       style={{
  //                         color: "#2F2F2F",
  //                         fontWeight: "700",
  //                         fontSize: "15px",
  //                         fontFamily: "Poppins",
  //                       }}
  //                     >
  //                       Contact No: +91-{profileUser?.contact}
  //                     </span>
  //                   </div>

  //                   {/* Website */}
  //                   {profileUser?.website !== null && (
  //                     <div
  //                       className="col-10 mt-3"
  //                       style={{ lineHeight: "22.5px" }}
  //                     >
  //                       <span
  //                         style={{
  //                           color: "#2F2F2F",
  //                           fontWeight: "700",
  //                           fontSize: "15px",
  //                           fontFamily: "Poppins",
  //                         }}
  //                       >
  //                         Website:{" "}
  //                         <span
  //                           style={{
  //                             color: "#2F2F2F",
  //                             fontWeight: "400",
  //                             fontSize: "15px",
  //                             fontFamily: "Poppins",
  //                           }}
  //                         >
  //                           <a href={profileUser?.website} />
  //                         </span>
  //                       </span>
  //                     </div>
  //                   )}
  //                 </div>
  //                           {/* institute details */}
  //                 <div className="col-12 col-md-5">
  //                   <div className="Infrastructure-div">
  //                     <div>
  //                       <span
  //                         className=""
  //                         style={{
  //                           fontWeight: "700",
  //                           fontSize: "17px",
  //                           fontFamily: "Poppins",
  //                           color: "#2F2F2F",
  //                           lineHeight: "22.5px",
  //                         }}
  //                       >
  //                         Infrastructure
  //                       </span>
  //                     </div>
  //                   </div>

  //                         {/* showing no. of students for institutes */}
  //                   <div
  //                     className="Infrastructure-data"
  //                     style={{ lineHeight: "22.5px" }}
  //                   >
  //                     <span
  //                       style={{
  //                         color: "#2F2F2F",
  //                         fontWeight: "700",
  //                         fontSize: "15px",
  //                         fontFamily: "Poppins",
  //                       }}
  //                     >
  //                       No.of Students:{" "}
  //                       <span
  //                         style={{
  //                           color: "#2F2F2F",
  //                           fontWeight: "400",
  //                           fontSize: "15px",
  //                           fontFamily: "Poppins",
  //                         }}
  //                       >
  //                         {profileUser?.totalStudent || "0"}
  //                       </span>
  //                     </span>
  //                   </div>
  //                         {/* showing no. of teacher for institutes */}
  //                   <div
  //                     className="Infrastructure-data"
  //                     style={{ lineHeight: "22.5px" }}
  //                   >
  //                     <span
  //                       style={{
  //                         color: "#2F2F2F",
  //                         fontWeight: "700",
  //                         fontSize: "15px",
  //                         fontFamily: "Poppins",
  //                       }}
  //                     >
  //                       No. of Teachers:{" "}
  //                       <span
  //                         style={{
  //                           color: "#2F2F2F",
  //                           fontWeight: "400",
  //                           fontSize: "15px",
  //                           fontFamily: "Poppins",
  //                         }}
  //                       >
  //                         {profileUser?.totalTeacher || "0"}
  //                       </span>
  //                     </span>
  //                   </div>


  //                 </div>
  //                         {/* showing social accounts for user */}
  //                { profileUser?.fb !== "" || profileUser?.twitter !== "" || profileUser?.lkd !== "" || profileUser?.insta !== "" || profileUser?.insta !== "" || profileUser?.ytb !== ""  &&
  //                   <div>
  //                   <div className='social-media-content mt-5'>
  //                     <span style={{color: "#2F2F2F", fontSize: "17px",fontWeight: "700", fontFamily: "Poppins"}}>Social</span>
  //                   </div>

  //                   <div className='d-flex gap-2 flex-wrap'>
  //                       {profileUser?.fb !== "" && 
  //                       <div className='p-1 mt-2' style={{background: "#F1EBFF",borderRadius: "8px", cursor: "pointer"}}><FacebookIcon   onClick={() => window.location.href=profileUser?.fb}  sx={{color: "#3f51b5", fontSize: "35px"}}/></div>
  //                       }
  //                       {profileUser?.twitter !== "" &&
  //                       <div className='p-1 mt-2' style={{background: "#F1EBFF",borderRadius: "8px", cursor: "pointer"}}><img src='/ProfileImg/TwitterX.svg' alt='twitter'  onClick={() =>window.open profileUser?.twitter}/></div>
  //                       }
  //                       {profileUser?.lkd !== "" &&
  //                       <div className='p-1 mt-2' style={{background: "#F1EBFF",borderRadius: "8px", cursor: "pointer"}}><LinkedInIcon sx={{color: "#0288d1", fontSize: "35px"}} onClick={() => window.location.href=profileUser?.lkd}/></div>
  //                       }
  //                       {profileUser?.insta !== "" &&
  //                       <div className='p-1 mt-2' style={{background: "#F1EBFF",borderRadius: "8px", cursor: "pointer"}}><img src='/ProfileImg/Instagram.svg' alt='instragram'  onClick={() => window.location.href=profileUser?.insta}/></div>
  //                       }
  //                       {profileUser?.insta !== "" &&
  //                       <div className='p-2 mt-2' style={{background: "#F1EBFF",borderRadius: "8px", cursor: "pointer"}}><img src='/ProfileImg/thread.svg' alt='thread' /></div>
  //                       }
  //                       {profileUser?.ytb !== "" &&
  //                       <div className='p-1 mt-2' style={{background: "#F1EBFF",borderRadius: "8px", cursor: "pointer"}}><YouTubeIcon sx={{color: "#ff3d00", fontSize: "35px"}}  onClick={() => window.location.href=profileUser?.ytb}/></div>
  //                       }
  //                   </div>
  //                 </div>
  //                }

  //                       </div>
  //                   </div>
  //               </div>
  //             </div>

  //                {/* social accounts  */}
  //             <div>
  //               <div className="social-media-content mt-5">
  //                 <span
  //                   style={{
  //                     color: "#2F2F2F",
  //                     fontSize: "17px",
  //                     fontWeight: "700",
  //                     fontFamily: "Poppins",
  //                   }}
  //                 >
  //                   Social
  //                 </span>
  //               </div>

  //               <div className="d-flex gap-2 flex-wrap">
  //                 <div
  //                   className="p-1 mt-2"
  //                   style={{
  //                     background: "#F1EBFF",
  //                     borderRadius: "8px",
  //                     cursor: "pointer",
  //                   }}
  //                 >
  //                   <FacebookIcon
  //                     onClick={() =>
  //                       (window.location.href = profileUser?.fb)
  //                     }
  //                     sx={{ color: "#3f51b5", fontSize: "35px" }}
  //                   />
  //                 </div>
  //                 <div
  //                   className="p-1 mt-2"
  //                   style={{
  //                     background: "#F1EBFF",
  //                     borderRadius: "8px",
  //                     cursor: "pointer",
  //                   }}
  //                 >
  //                   <img
  //                     src="/ProfileImg/TwitterX.svg"
  //                     alt="twitter"
  //                     onClick={() =>
  //                       (window.location.href = profileUser?.twitter)
  //                     }
  //                   />
  //                 </div>
  //                 <div
  //                   className="p-1 mt-2"
  //                   style={{
  //                     background: "#F1EBFF",
  //                     borderRadius: "8px",
  //                     cursor: "pointer",
  //                   }}
  //                 >
  //                   <LinkedInIcon
  //                     sx={{ color: "#0288d1", fontSize: "35px" }}
  //                     onClick={() =>
  //                       (window.location.href = profileUser?.lkd)
  //                     }
  //                   />
  //                 </div>
  //                 <div
  //                   className="p-1 mt-2 d-flex justify-content-center align-items-center"
  //                   style={{
  //                     background: "#F1EBFF",
  //                     borderRadius: "8px",
  //                     cursor: "pointer",
  //                     width:'40px'
  //                   }}
  //                 >
  //                   <img
  //                     src="/ProfileImg/Instagram.svg"
  //                     alt="instragram"
  //                     width='100%'
  //                     onClick={() =>
  //                       (window.location.href = profileUser?.insta)
  //                     }
  //                   />
  //                 </div>
  //                 <div
  //                   className="p-1 mt-2 d-flex justify-content-center align-items-center"
  //                   style={{
  //                     background: "#F1EBFF",
  //                     borderRadius: "8px",
  //                     cursor: "pointer",
  //                     fontSize:"25px",
  //                     width:"40px"

  //                   }}
  //                 >
  //                 <i className="fa-brands fa-square-threads"></i>
  //                 </div>
  //                 <div
  //                   className="p-1 mt-2"
  //                   style={{
  //                     background: "#F1EBFF",
  //                     borderRadius: "8px",
  //                     cursor: "pointer",
  //                   }}
  //                 >
  //                   <YouTubeIcon
  //                     sx={{ color: "#ff3d00", fontSize: "35px" }}
  //                     onClick={() =>
  //                       (window.location.href = profileUser?.ytb)
  //                     }
  //                   />
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  // )


  //  : clickuserDetail?.role === "teacher" ? (
  //   <div>
  //     <div
  //       className="rounded-4  hover-shadow-for-component mb-4- pb-"
  //       style={{ background: "var(--club-component-backgroun" }}
  //     >
  //       <div className="col p-4">
  //         <div className="col-12">
  //           <div className="row">
  //           {/* same circular div */}
  //             <div className="d-flex justify-content-center col-md-3">
  //               <div className="col-11 profile-img-circular-bar">
  //                 {/* <div className='d-flex justify-content-betwee'> */}
  //                 <CircularProgressbarWithChildren
  //                   value={profileUser?.profilePercentage}
  //                   maxValue={100}
  //                   text={profileUser?.profilePercentage}
  //                   styles={buildStyles({
  //                     pathColor:
  //                       profileUser?.profilePercentage < 40
  //                         ? "red"
  //                         : profileUser?.profilePercentage < 80
  //                         ? "orange"
  //                         : "#9555fd",
  //                   })}
  //                 >
  //                   <div className="profile-img-dimension">
  //                     <Avatar
  //                       className="h-100 w-100"
  //                       src={profileUser?.logo || profileUser?.profile}
  //                     />
  //                   </div>
  //                 </CircularProgressbarWithChildren>
  //               </div>
  //             </div>
  //             {/* end same circular div */}

  //             <div className="col-12 col-md-11 col-lg-9">
  //               <div className="col-12 col-md-12 col-lg-12">

  //                 <div className="mt-2">
  //                 {/* showing name and class for student */}
  //                   {clickuserDetail?.role === "student" ? (
  //                     <div className="d-flex justify-content-between align-items-center">
  //                       <span
  //                         className="institute-teacher-student-name "
  //                         style={{ color: "#000000" }}
  //                       >
  //                         {profileUser?.first_name +
  //                           " " +
  //                           profileUser?.last_name}{" "}
  //                       </span>
  //                     </div>
  //                   ) 
  //                        // showing name and logo for institute 
  //                   : (
  //                     <span
  //                       className="institute-name"
  //                       style={{ color: "#000000" }}
  //                     >
  //                       {profileUser?.institution_name}
  //                     </span>
  //                   )}
  //                 </div>
  //                 {/* showing institute name */}
  //                 <div className="mt-3 d-flex align-items-center">
  //                   <div>
  //                     <span>
  //                       <img
  //                         style={{ height: "26px", width: "26px" }}
  //                         src="/ProfileImg/StudentCenter.png"
  //                         alt=""
  //                       />
  //                     </span>
  //                   </div>
  //                   <div className="ms-2">
  //                     <span
  //                       className="fw-700 fs-15px"
  //                       style={{ fontFamily: "Poppins", color: "#2F2F2F" }}
  //                     >
  //                       {profileUser?.institution_name}
  //                     </span>
  //                   </div>
  //                 </div>


  //               </div>
  //             </div>
  //           </div>
  //         </div>


  //       </div>
  //     </div>
  //   </div>
  // )


}