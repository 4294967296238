// import React, { useState, useEffect } from 'react';
// import { Box } from '@mui/material';
// import MaterialTable from '@material-table/core';
// import axios from 'axios';

// const StudentDetails = () => {
//     const [data, setData] = useState([]);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const institute_id = "66b5db033d9f051457e4708c"; 

//                 const response = await axios.get(`http://localhost:3000/api/approval/project/members/${institute_id}`);
//                 console.log(response.data);

//                 const combinedData = response.data.map(projectDetail => {
//                     return projectDetail.members.map(member => ({
//                         ...member,
//                         participationType: projectDetail.project.participationType,
//                         projectId: projectDetail.project.projectId,
//                         projectName: projectDetail.project.projectName,
                        
//                     }));
//                 }).flat(); 
//                 setData(combinedData);
//             } catch (error) {
//                 console.error("Error fetching the project data:", error);
//             }
//         };

//         fetchData();
//     }, []);

//     const columns = [
//         {
//             title: "Student ID",
//             field: "rollNo",
//             headerStyle: {
//                 textAlign: "start",
//                 borderTopLeftRadius: "10px",
//             },
//             cellStyle: {
//                 textAlign: 'start',
//             }
//         },
//         {
//             title: "Student Name",
//             field: "name",
//             headerStyle: {
//                 textAlign: "center",
//             },
//             cellStyle: {
//                 textAlign: 'center',
//             }
//         },
//         {
//             title: "Participation Type",
//             field: "participationType",
//             headerStyle: {
//                 textAlign: "center",
//             },
//             cellStyle: {
//                 textAlign: 'center',
//             }
//         },
//         {
//             title: "Project ID",
//             field: "projectId",
//             headerStyle: {
//                 textAlign: "center",
//             },
//             cellStyle: {
//                 textAlign: 'center',
//             }
//         },
//         {
//             title: "Marks",
//             field: "marks",
//             headerStyle: {
//                 textAlign: "center",
//                 borderTopRightRadius: "10px",
//             },
//             cellStyle: {
//                 textAlign: 'start',
//             }
//         }
//     ];

//     return (
//         <Box
//             sx={{
//                 padding: "1vw",
//                 borderRadius: "1rem",
//                 backgroundColor: "var(--color-ghostwhite)",
//                 border: "5px solid #2356fe",
//                 boxSizing: "border-box",
//                 width: "100%",
//                 overflow: "hidden",
//             }}
//         >
//             <MaterialTable
//                 style={{
//                     boxShadow: "none",
//                     width: "100%"
//                 }}
//                 options={{
//                     search: true,
//                     searchFieldStyle: {
//                         alignSelf: "center",
//                         width: '100%'
//                     },
//                     toolbar: true,
//                     showTitle: false,
//                     pageSize: 5,
//                     paging: true,
//                     paginationAlignment: "right",
//                     paginationType: "normal",
//                     headerStyle: {
//                         backgroundColor: '#2356fe',
//                         color: '#FFF',
//                         padding: "1.25rem",
//                     },
//                 }}
//                 columns={columns}
//                 data={data}
//             />
//         </Box>
//     );
// };

// export default StudentDetails;



import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Button, TextField, IconButton, Tooltip, Typography,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TablePagination,InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { CustomPagination } from './ProjectDetails';
import { useParams } from 'react-router-dom';
import Loader from '../SewaPage/Loader';
import { apiSewa } from 'api';


const StudentDetails = () => {
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    const { instituteId } = useParams();


    const institute_id = instituteId

    // useEffect(() => {
    //     const fetchCategories = async () => {
    //         try {
    //             const response = await axios.get('http://localhost:3000/api/categories/all');
    //             const filteredData = response.data.filter(category =>
    //                 category.register_id === null || category.register_id === undefined || category.register_id === institute_id
    //             );
    //             setData(filteredData);
    //         } catch (error) {
    //             console.error("Error fetching categories:", error);
    //         }
    //     };

    //     fetchCategories();
    // }, [institute_id]);

    useEffect(() => {
                const fetchData = async () => {
                    try {
                        setLoading(true)
                        const response = await apiSewa.get(`/approval/project/members/${institute_id}`);
                        console.log(response.data);
        
                        const combinedData = response.data.map(projectDetail => {
                            return projectDetail.members.map(member => ({
                                ...member,
                                participationType: projectDetail.project.participationType,
                                projectId: projectDetail.project.projectId,
                                projectName: projectDetail.project.projectName,
                                
                            }));
                        }).flat(); 
                        setData(combinedData);
                        setLoading(false);
                    } catch (error) {
                        console.error("Error fetching the project data:", error);
                    }
                };
        
                fetchData();
            }, []);
        


            const handleChangePage = (event, newPage) => {
                setPage(newPage);
            };
        
            const handleChangeRowsPerPage = (event) => {
                setRowsPerPage(parseInt(event.target.value, 1));
                setPage(0);
            };

    const filteredData = data.filter(category =>
        category.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredData.length - (page * rowsPerPage));

    console.log(emptyRows)

    if (loading) {
        return <Loader text={'Wait for a Moment'} />;
    }

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography
                sx={{
                    fontFamily: 'Poppins',
                    fontSize: {md:'1rem' , xs:"12px"},
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left',
                    color: "#575757",
                    marginBottom: "3rem",
                }}
            >
                Selecting your category helps us personalize opportunities for you, ensuring that you get the most relevant resources and recommendations from the start.
            </Typography>

            <Box
                sx={{
                    padding: "1rem",
                    backgroundColor: "var(--color-ghostwhite)",
                    position: "relative",
                    boxSizing: "border-box",
                    width: "100%",
                    borderRadius: "1rem", 
                    overflow: "hidden",
                    ":before": {
                        content: '""',
                        background: "linear-gradient(122.52deg, rgba(35, 86, 254, 0.7) 1.25%, rgba(35, 86, 254, 0.1) 98.44%)",
                        borderRadius: "1rem", 
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        zIndex: "1",
                        margin: "-1rem",
                    }
                }}
            >
                <Box sx={{
                    width: "100%",
                    zIndex: "5",
                    position: "relative",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "1rem",
                   
                }} >
                    <Box 
    sx={{ 
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: "center", 
        padding: { xs: "1rem", md: "2rem 1.5rem" }, // Adjust padding for different screen sizes
        flexDirection: { xs: "column", md: "row" }, // Stack items on smaller screens
        borderTopLeftRadius: "1rem", 
        borderTopRightRadius: "1rem", 
    }}
> 
    <Box sx={{ textAlign: { xs: 'center', md: 'left' }, mb: { xs: "1rem", md: 0 } }}> 
        <Typography 
            variant="h4" 
            component="div" 
            gutterBottom 
            sx={{
                fontFamily: 'Poppins',
                fontWeight: 700,
                width: { xs: "100%", md: "80%" }, // Full width on small screens
                textAlign: { xs: 'center', md: 'left' }, // Center text on small screens
            }}
        >
            <span style={{ color: "blue" }}>Student</span> Details
        </Typography>
        <Typography 
            gutterBottom  
            component="div" 
            sx={{
                fontFamily: 'Poppins',
                fontWeight: 400,
                lineHeight: '18.97px',
                textAlign: { xs: 'center', md: 'left' }, 
                width: { xs: "100%", md: "100%" }, 
                color:"#6D6D6D",
            }}
        >
            Explore the categories below to find the services and opportunities that best match your interests and skills.
        </Typography>
    </Box>
    
    <Box
        sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" }, // Center the search bar on small screens
            width: { xs: "100%", md: "16rem" }, // Full width on small screens, fixed width on larger screens
            position: "relative",
            zIndex: "5",
            mt: { xs: "1rem", md: 0 }, // Add top margin for spacing on small screens
        }}
    >
        <div 
            style={{ 
                width: '100%', 
                height: '2.5rem', 
                backgroundColor: '#e7e7e7', 
                borderRadius: '0.7rem', 
                display: 'flex', 
                alignItems: 'center', 
                padding: '0 12px',
                zIndex: "5"
            }}
        >
            <SearchIcon style={{ color: '#333', marginRight: '8px' }} />
            <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    outline: 'none',
                    backgroundColor: 'transparent',
                    fontSize: '1rem',
                    color: '#333',
                    padding: '0',
                    zIndex: "5"
                }}
            />
        </div>
    </Box>
</Box>

                    <TableContainer component={Paper} sx={{ borderBottomRadius: "1rem", zIndex: 5 }}>
                        <Table>
                            <TableHead sx={{ background: "#F3F3F3" }}>
                                <TableRow>
                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>Student Name</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>Participation Type</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>Project Id</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>Marks</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData.length > 0 && (
                                    (rowsPerPage > 0
                                        ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : filteredData
                                    ).map((row) => (
                                        <TableRow key={row._id}>
                                            <TableCell align="center">{row.name}</TableCell>
                                            <TableCell align="center">
                                                {row.participationType}
                                            </TableCell>
                                            <TableCell align="center">
                                               {row.projectId}
                                            </TableCell>
                                            <TableCell align="center">
                                               {row.marks}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}

                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={3} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                      
           </TableContainer>

           <Box
    sx={{
        display: 'flex',
        justifyContent: { xs: 'center', md: 'space-between' }, // Center on small screens, space-between on larger
        alignItems: 'center',
        padding: '0.5rem 1rem',
        zIndex: "5",
        flexWrap: 'wrap', 
        gap: { xs: 2, md: 0 }, 
    }}
>
    <TablePagination
        component="div"
        count={10}
        page={0}
        onPageChange={() => {}}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Rows per page"
        sx={{
            flexShrink: 0,
            '& .MuiTablePagination-toolbar': {
                paddingLeft: { xs: 0, md: '16px' }, 
            },
            '& .MuiTablePagination-selectLabel': {
                marginRight: 2,
            },
            '& .MuiSelect-select': {
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '6px 8px',
            },
        }}
        labelDisplayedRows={() => ''}
        slotProps={{
            actions: {
                nextButton: { sx: { display: 'none' } },
                backButton: { sx: { display: 'none' } },
                firstButton: { sx: { display: 'none' } },
                lastButton: { sx: { display: 'none' } },
            },
        }}
    />

    <CustomPagination
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        sx={{
            marginTop: { xs: '1rem', md: 0 }, 
            width: { xs: '100%', md: 'auto' }, 
        }}
    />
</Box>

                    {/* <TablePagination
                        rowsPerPageOptions={[5]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{ zIndex: 5, position: "relative", borderRadius: "1rem" }}
                    /> */}
                </Box>
            </Box>
        </Box>
    );
};

export default StudentDetails;
