import { Button } from '@mui/joy';
import { apiJson } from 'api'
import { useGlobalContext } from 'global/context'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

export const SubAdminAssginData = () => {
    const {userData} = useGlobalContext();
    const [assignedData, setAssignData] = useState([]);
    const navigate = useNavigate();

    const getStateAssignedData = ()=>{
        apiJson.get(`api/v2/admin/getAssignedData/${userData?.id}`)
        .then((res)=>{
            setAssignData(res?.data?.getAssigned)
        })
        .catch((error)=>{
            console.log(error.message);
        })
    }

    useEffect(()=>{
        getStateAssignedData()
    },[])
  return (
    <div>
        <div className='col-10 mx-auto'>
            <div className='d-flex align-items-center justify-content-between'><div><span className='fs-3 fw-500'>Assigned State</span></div> 
            <div><Button className='p-1' onClick={()=>navigate('/new-dashboard')}>Follow-Up</Button></div>
            </div>

            <div className='row mt-3'>
            {
                assignedData?.map((ele)=>{
                    return (
                <div onClick={()=>{navigate(`/subAdmin/stateData/${ele?.state}`)}} className='col-12 col-lg-4 cursor-pointer shadow-lg border-2 rounded p-4 gap-3'>
                <div className='fw-500  fs-6'><span> {ele?.state}</span></div>
                <div className='fw-500  fs-6 mt-2'> <span> {ele?.projects}</span></div>
                </div>

                    )
                })
            }

            </div>
        </div>
    </div>
  )
}
