import React, { useContext, useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import ModelUnContext from '../context/contextModelUn';
import dayjs from 'dayjs';


const DatePickerPropDate = ({ setDate, formikValue}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const { formik} = useContext(ModelUnContext)

  const handleDateClick = (newValue) => {
    console.log(newValue);
    const formattedDate = dayjs(newValue).format('YYYY-MM-DD');
    setDate(formattedDate);
  };

  const isDateDisabled = (date) => {
      const lastDateRegis = dayjs(formik?.values?.lastDateRegis);
      const currentDate = dayjs(date);
    const isBefore7DaysFromLastRegis = currentDate.diff(lastDateRegis, 'day') < 7;
    return isBefore7DaysFromLastRegis;
  };
   

  return (
    <div className="calendar rounded">

<div className="px-3 py-4 w-100">

<LocalizationProvider dateAdapter={AdapterDayjs}>
  <StaticDatePicker orientation="portrait" 
  className='w-100'
  value={selectedDate || formik?.values?.lastDateEvent} 
  disablePast
  onChange={(newValue) => {
    setSelectedDate(newValue);
    handleDateClick(newValue)
     }} 
     shouldDisableDate={isDateDisabled}
  />
</LocalizationProvider>
</div>

    </div>
  );
};

export default DatePickerPropDate;