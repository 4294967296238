import React from "react";
import {
  Button,
  TextField,
  Typography,
  DialogActions,
  Box,
} from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DownloadIcon from "@mui/icons-material/Download";

function PromiseDOC() {
  const initialValues = {
    studentName: "",
    class: "",
    activity: "",
    duration: "",
    hours: "",
    mentorName: "",
    date: "",
  };

  const validationSchema = Yup.object({
    studentName: Yup.string().required("Student's name is required"),
    class: Yup.string().required("Class is required"),
    activity: Yup.string().required("Activity description is required"),
    duration: Yup.string().required("Duration is required"),
    hours: Yup.string()
      .required("Estimated hours are required")
      .matches(/^[0-9]+$/, "Estimated hours must be digits"),
    mentorName: Yup.string().required("Mentor teacher's name is required"),
    date: Yup.date().required("Date is required"),
  });

  const handleDownloadPDF = () => {
    const input = document.getElementById("form-content");

    // Hide buttons during capture
    document.querySelectorAll(".button-to-hide").forEach((button) => {
      button.style.display = "none";
    });

    html2canvas(input, {
      scale: 2,
      useCORS: true,
      scrollY: -window.scrollY,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const imgWidth = pdfWidth - 20;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
      pdf.save("sewa-promise-form.pdf");

      // Restore buttons after capture
      document.querySelectorAll(".button-to-hide").forEach((button) => {
        button.style.display = "";
      });
    });
  };

  return (
    <Box sx={containerStyle}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleDownloadPDF}
      >
        {({ isValid, touched, errors }) => (
          <Form id="form-content" style={formStyle}>
            <Typography variant="h5" sx={titleStyle}>
              SEWA Promise Form
            </Typography>
            <Typography variant="body1" sx={introStyle}>
              SEWA is a firm step to prepare you for life. It is a voluntary
              project experience you have to complete. My SEWA Promise Form must
              be completed and prior approval for the activity/project obtained.
              The selection of a SEWA activity, development, implementation of the
              proposal, and evaluation of the activity are the responsibility of
              each student. The signature of the parent indicates review and
              approval of this proposal.
            </Typography>

            <Box sx={fieldGroupStyle}>
              <Typography sx={labelStyle}>Student Name:</Typography>
              <Field
                as={TextField}
                name="studentName"
                variant="outlined"
                fullWidth
                error={touched.studentName && Boolean(errors.studentName)}
                helperText={touched.studentName && errors.studentName}
                sx={textFieldStyle}
              />
            </Box>

            <Box sx={fieldGroupStyle}>
              <Typography sx={labelStyle}>Class:</Typography>
              <Field
                as={TextField}
                name="class"
                variant="outlined"
                fullWidth
                error={touched.class && Boolean(errors.class)}
                helperText={touched.class && errors.class}
                sx={textFieldStyle}
              />
            </Box>

            <Box sx={fieldGroupStyle}>
              <Typography sx={labelStyle}>
                Brief Description of the Activity:
              </Typography>
              <Field
                as={TextField}
                name="activity"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                error={touched.activity && Boolean(errors.activity)}
                helperText={touched.activity && errors.activity}
                sx={textFieldStyle}
              />
            </Box>

            <Box sx={durationGroupStyle}>
              <Box sx={durationFieldStyle}>
                <Typography sx={labelStyle}>
                  Duration (Days and Time):
                </Typography>
                <Field
                  as={TextField}
                  name="duration"
                  variant="outlined"
                  fullWidth
                  error={touched.duration && Boolean(errors.duration)}
                  helperText={touched.duration && errors.duration}
                  sx={textFieldStyle}
                />
              </Box>
              <Box sx={durationFieldStyle}>
                <Typography sx={labelStyle}>Estimated Hours:</Typography>
                <Field
                  as={TextField}
                  name="hours"
                  variant="outlined"
                  fullWidth
                  error={touched.hours && Boolean(errors.hours)}
                  helperText={touched.hours && errors.hours}
                  sx={textFieldStyle}
                />
              </Box>
            </Box>

            <Box sx={fieldGroupStyle}>
              <Typography sx={labelStyle}>Name of Mentor Teacher:</Typography>
              <Field
                as={TextField}
                name="mentorName"
                variant="outlined"
                fullWidth
                error={touched.mentorName && Boolean(errors.mentorName)}
                helperText={touched.mentorName && errors.mentorName}
                sx={textFieldStyle}
              />
            </Box>

            <Box sx={fieldGroupStyle}>
              <Typography sx={labelStyle}>Date:</Typography>
              <Field
                as={TextField}
                name="date"
                type="date"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={touched.date && Boolean(errors.date)}
                helperText={touched.date && errors.date}
                sx={dateFieldStyle}
              />
            </Box>

            <Box sx={signaturesStyle}>
              <Box sx={signatureBoxStyle}>
                <Typography sx={labelStyle}>Student Signature:</Typography>
                <Box sx={signaturePlaceholderStyle} />
              </Box>
              <Box sx={signatureBoxStyle}>
                <Typography sx={labelStyle}>Parent Signature:</Typography>
                <Box sx={signaturePlaceholderStyle} />
              </Box>
            </Box>

            <DialogActions sx={dialogActionsStyle}>
              <Button
                type="submit"
                sx={{
                  ...downloadButtonStyle,
                  backgroundColor: isValid ? "#FFA500" : "#9E9E9E",
                }}
                disabled={!isValid}
                startIcon={<DownloadIcon />}
                className="button-to-hide"
              >
                Download
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

const containerStyle = {
  width: "100%",
  maxWidth: "900px",
  margin: "0 auto",
  padding: "20px",
  backgroundColor: "#fff",
  boxSizing: "border-box",
  borderRadius: "8px",
  border: "1px solid #ccc", // Main border
  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
  overflow: "auto",
};

const titleStyle = {
  textAlign: "center",
  marginBottom: "10px",
  fontWeight: "bold",
  fontSize: "1.5rem",
};

const introStyle = {
  marginBottom: "20px",
  color: "#333",
  fontSize: "16px",
  lineHeight: "1.5",
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
};

const fieldGroupStyle = {
  display: "flex",
  flexDirection: "column",
  marginBottom: "20px",
  gap: "10px",
};

const durationGroupStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  marginBottom: "20px",
  "@media(max-width:600px)": {
    flexDirection: "column",
  },
};

const durationFieldStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

const labelStyle = {
  marginBottom: "8px",
  fontSize: "16px",
  fontWeight: "bold",
  color: "#333",
};

const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
  },
};

const dateFieldStyle = {
  "& .MuiOutlinedInput-root": {
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
  },
};

const signaturesStyle = {
  display: "flex",
  justifyContent: "space-between",
  gap: "20px",
  marginBottom: "20px",
  "@media(max-width:600px)": {
    flexDirection: "column",
  },
};

const signatureBoxStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

const signaturePlaceholderStyle = {
  height: "60px",
  border: "1px dashed #ccc",
  borderRadius: "4px",
  backgroundColor: "#f6f6f6",
};

const dialogActionsStyle = {
  marginTop: "20px",
  justifyContent: "center",
};

const downloadButtonStyle = {
  color: "#fff",
  fontWeight: "bold",
  padding: "10px 20px",
  borderRadius: "5px",
  textTransform: "none",
};

export default PromiseDOC;