import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useCareerContext } from 'contexts/CareerContext';
import TestCards from './TestCards';
import QuestionsSection from './QuestionsSection';
import ClassCardView from './ClassCardView';
import StudentListView from './StudentListView';
import ResultView from './ResultView';
import YuvaLoader from 'pages/Forum/components/Loader/YuvaLoader';
import CompleteLsmt from './CompleteLsmt';
import { useGlobalContext } from 'global/context';
import { Popup } from 'layout/Popup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { apiJson } from 'api';


const LSMT = () => {
    const { userData } = useGlobalContext();
    const { role, postAttempt, selectedNavOption, selectedSubNavOption, setSelectedSubNavOption, classInfo } = useCareerContext();
    const [loading, setLoading] = useState(false);

    const handleTestCompletion = async (categoryScores) => {
        if (userData?.id) {
            setLoading(true);
            try {
                const res = await postAttempt(userData.id, userData.role, categoryScores);
                if (!res) {
                    Popup("error", "", "Problem occurred while posting the attempt. Please try again.", 3000)
                    setSelectedSubNavOption('Test Cards'); // Redirect to Test Cards
                } else {
                    Popup("success", "Successfull Attempt!", "Test Attempt Saved SuccesFully", 3000)
                    try {
                        const emailRes = await apiJson.post(`/api/v2/lsmtTest/lsmt-result-email/${userData.id}`);
                        if (emailRes && emailRes.status === 200) {
                            console.log("Email sent Successfully !!");
                        }
                    }
                    catch (e) {
                        console.log("Error occured during sending email !!", e);
                    }
                    setSelectedSubNavOption('View Result'); // Redirect to results page
                }
            } catch (error) {
                Popup("error", "", "Error occurred while posting the attempt. Please try again.", 3000)
                console.error("Error posting attempt:", error);
                setSelectedSubNavOption("Test Cards");
            } finally {
                setLoading(false);
            }
        }
    };
    const handleBackClick = () => {
        if (selectedSubNavOption === "Student's List") {
            setSelectedSubNavOption('Classes');
        } else {
            setSelectedSubNavOption("Student's List");
        }
    };
    return (
        <Box sx={{ padding: 2 }}>
            {loading && <YuvaLoader setShow={false} show={loading} />}
            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, alignItem: "center" }}>
                <Typography
                    component={"h1"}
                    sx={{
                        fontSize: "2rem",
                        fontWeight: 600,
                        padding: "1rem 0",
                    }}
                >
                    {selectedNavOption} - {selectedSubNavOption} {classInfo && selectedSubNavOption === "Student's List" ? ` for ${classInfo.className}` : ""}
                </Typography>
                {(role === "institute" || role === 'teacher') && selectedSubNavOption !== "Classes" && (
                    <IconButton
                        onClick={handleBackClick}
                        sx={{
                            color: "#2356fe",
                            '&:hover': {
                                backgroundColor: "white",
                                transform: "translateX(-5px)",
                            },
                        }}
                    >
                        <ArrowBackIcon sx={{ fontSize: { xs: "1.5rem", sm: "2.5rem" } }} />
                    </IconButton>
                )}
            </Box>

            {role === "institute" || role == 'teacher' ? (
                selectedSubNavOption === 'Classes' ? (
                    <ClassCardView />
                ) : selectedSubNavOption === "Student's List" ? (
                    <StudentListView />
                ) : selectedSubNavOption === 'View Result' ? (
                    <ResultView />
                ) : (
                    <Typography>Invalid view for institution</Typography>
                )
            ) : (
                selectedSubNavOption === 'Test Cards' ? (
                    <TestCards
                        handleTakeTest={() => setSelectedSubNavOption('Take test')}
                        handleViewResults={() => setSelectedSubNavOption('View Result')}
                    />
                ) : selectedSubNavOption === 'Take test' ? (
                    <QuestionsSection
                        onComplete={handleTestCompletion}
                    />
                ) : selectedSubNavOption === 'View Result' ? (
                    <CompleteLsmt
                        handleTryAgain={() => setSelectedSubNavOption('Take test')}
                    />
                ) : (
                    <Typography>Invalid view for student</Typography>
                )
            )}
        </Box>
    );
};

export default LSMT;
