import React, { useState } from 'react';
import { Avatar, IconButton, Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem, ListItemText } from '@mui/material';
import { Facebook, Instagram, LinkedIn, Twitter, YouTube } from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { Box, Button } from "@mui/material";


const SewaSideBar = ({ pageName, navigationData, setActiveTopic, setActiveSubtopic,userOptions }) => {
    const [expanded, setExpanded] = useState(null);
    const [activeTopic, setActiveTopicState] = useState(null);
    const [activeSubtopic, setActiveSubtopicState] = useState(null);
    const modules = {};

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleTopicClick = (topic) => {
        setActiveTopic(topic);
        setActiveSubtopic(null);
        setActiveTopicState(topic); 
    };

    const handleSubtopicClick = (topic, subtopic) => {
        setActiveTopic(topic);
        setActiveSubtopic(subtopic);
        setActiveSubtopicState(subtopic);
    };


    let step , stepCount ;

    switch( !activeTopic ||  activeTopic.title) {
        case 'Category':
             step = 'ONE' ;
             stepCount = 1;
             break;  
        case "Add Mentor":
            step = 'TWO' ;
            stepCount = 2;      
            break;
        
        case "View Projects":
            step = 'THREE' ;
            stepCount = 3;
             break;
        case "Student Details":
            step = 'FOUR' ;
            stepCount = 4;
            break;
            case 'Project Requests':
                step = 'ONE' ;
                stepCount = 1;
                break;  
           case "Project Details":
               step = 'TWO' ;
               stepCount = 2;      
               break;
           
           case "Student":
               step = 'THREE' ;
               stepCount = 3;
                break;
           case "Marking":
               step = 'FOUR' ;
               stepCount = 4;
               break;
               case "Work Proof":
                step = 'FIVE' ;
                stepCount = 5
                break;
                case "Documentation":
                    step = 'SIX' ;
                    stepCount = 6;
                    break;
               
                        break;
        default:
            step = 'ONE' ;
            stepCount = 1;
              }

    return (
        <section style={{ paddingRight: "0px", display: "flex", flexDirection: "column", gap: "1.5rem" }}>
            <div style={{
                width: '17.1rem',
                height: '69px',
                gap: '0px',
                borderRadius: '0px 3rem 3rem 0px',
                backgroundColor: '#2356FE',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '20px',
                marginTop: "1rem"
            }}>
                <h2 style={{
                    fontFamily: 'Inter',
                    fontSize: '45px',
                    fontWeight: 700,
                    lineHeight: '54.46px',
                    textAlign: 'left',
                    color: 'white',
                    margin: 0,
                    textTransform:"uppercase"
                }}>
                    {pageName}
                </h2>
                <h3 style={{
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontWeight: 600,
                    lineHeight: '19.36px',
                    textAlign: 'center',
                    color: 'white',
                    marginLeft: '1.5rem',
                }}>
                    Progress
                </h3>
            </div>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
                marginLeft: "2.5rem",
                marginTop:"1.2rem"
            }}>
                <div style={{
                    width: '50px',
                    height: '50px',
                    boxShadow: '3.8px 3.8px 7.6px 0px #00000040',
                    borderRadius: '50%',
                    fontFamily: 'Poppins',
                    fontSize: '1.5rem',
                    fontWeight: 600,
                    lineHeight: '51px',
                    textAlign: 'center',
                    color: "#000000"
                }}>
                    {stepCount}
                </div>
                <div style={{
                    fontFamily: 'Poppins',
                    fontSize: '1.6rem',
                    fontWeight: 600,
                    lineHeight: '51px',
                    textAlign: 'left',
                    color: "#000000"
                }}>
                    STEP {step}
                </div>
            </div>

            <div style={{ alignContent: "center", paddingLeft: "0px", marginTop: "5rem", gap: "1.5rem", width: '12rem' }}>
                {navigationData.map((option, index) => (
                    <Accordion
                        key={index}
                        expanded= {false}
                        onChange={handleChange(index)}
                        sx={{
                            overflow: 'hidden',
                            '&:not(:last-of-type)': {
                                borderBottomRightRadius: '1.5rem',
                                borderTopRightRadius: '1.5rem',
                            },
                            '&:not(:first-of-type)': {
                                borderBottomRightRadius: '1.5rem',
                                borderTopRightRadius: '1.5rem',
                            },
                            paddingBottom: option.subtopics?.length > 0 ? "0" : "initial",
                            marginBottom: '1.5rem', 
                        }}>
                        <AccordionSummary
                            expandIcon={option.subtopics?.length > 0 ? <ExpandMoreIcon sx={{ color: "white" }} /> : ""}
                            sx={{
                                backgroundColor: activeTopic === option ? "#58B84F" : "#134afc",
                               
                                cursor: option.subtopics?.length > 0 ? 'pointer' : 'default',
                                paddingBottom: option.subtopics?.length > 0 ? "0" : "initial",
                            }}
                            onClick={() => option.subtopics?.length === 0 && handleTopicClick(option)}
                        >
                            <Typography sx={{  color: activeTopic === option ? "#000000" : "#ffffff",}}>
                                {option.title}
                            </Typography>
                        </AccordionSummary>
                        {option.subtopics?.length > 0 && (
                            <AccordionDetails>
                                <List>
                                    {option.subtopics.map((subtopic, idx) => (
                                        <ListItem
                                            button
                                            key={idx}
                                            onClick={() => handleSubtopicClick(option, subtopic)}
                                            sx={{
                                                backgroundColor: activeSubtopic === subtopic ? '#e0e0e0' : 'transparent', 
                                                transition: 'background-color 0.3s', 
                                                marginBottom: '0.5rem', 
                                                '&:hover': {
                                                    backgroundColor: '#f0f0f0', 
                                                },
                                            }}
                                        >
                                            <ListItemText primary={subtopic.title} />
                                        </ListItem>
                                    ))}
                                </List>
                            </AccordionDetails>
                        )}
                    </Accordion>
                ))}
            </div>

  

            <div className="social-media-section" style={{display:"flex" ,justifyContent: 'flex-start' , flexDirection:"column"}}>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft:"4rem"   }}>
                    <Avatar
                        alt={modules?.author}
                        src={"/android-chrome-192x192.png"}
                        sx={{ width: 66, height: 66 }}
                    />
                    <h5>{modules?.author}</h5>
                </div>
                <div style={{ display: 'flex',     }}>
                    <IconButton href="https://www.facebook.com/profile.php?id=100089167461647&mibextid=ZbWKwL" target={"_blank"}>
                        <Facebook sx={{ color: "blue" }} />
                    </IconButton>
                    <IconButton href="https://twitter.com/Yuvamanthan_org" target={"_blank"}>
                        <Twitter sx={{ color: "skyblue" }} />
                    </IconButton>
                    <IconButton href="https://www.linkedin.com/in/yuva-manthan-09aa2025b/" target={"_blank"}>
                        <LinkedIn sx={{ color: "blue" }} />
                    </IconButton>
                    <IconButton href="https://www.instagram.com/yuvamanthan_org/" target={"_blank"}>
                        <Instagram sx={{ color: "tomato" }} />
                    </IconButton>
                    <IconButton href="https://www.youtube.com/@yuvamanthan_" target={"_blank"}>
                        <YouTube sx={{ color: "red" }} />
                    </IconButton>
                </div>
            </div>
        </section>
    );
};

export default SewaSideBar;
