import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, Pagination } from "swiper";
import "swiper/css/free-mode";
import "swiper/css/pagination";

const PressReleaseYuva2 = () => {
  let [pressReleseData, setPressReleseDate] = useState([
    {
      newsImage: "pressRelese/1.png",
      title: "Amrit Vichar",
      publishedDate: "18-02-2014",
    },
    {
      newsImage: "pressRelese/10.png",
      title: "Hindustan",
      publishedDate: "22-02-2024",
    },
    {
      newsImage: "pressRelese/11.png",
      title: "Rashtriya Sahara",
      publishedDate: "18-02-2024",
    },
    {
      newsImage: "pressRelese/12.png",
      title: "Prabhat Khabar",
      publishedDate: "22-02-2024",
    },
    {
      newsImage: "pressRelese/13.png",
      title: "Dainik Jagran",
      publishedDate: "22-02-2024",
    },
    {
      newsImage: "pressRelese/14.png",
      title: "Dainik Bhaskar",
      publishedDate: "17-02-2024",
    },
    {
      newsImage: "pressRelese/15.png",
      title: "Aaj",
      publishedDate: "22-02-2024",
    },
    {
      newsImage: "pressRelese/16.png",
      title: "Prabhat Khabar",
      publishedDate: "23-02-2024",
    },
    {
      newsImage: "pressRelese/17.png",
      title: "Amrit Vichar",
      publishedDate: "18-02-2024",
    },
    {
      newsImage: "pressRelese/18.png",
      title: "Janmorcha",
      publishedDate: "18-02-2024",
    },
    {
      newsImage: "pressRelese/19.png",
      title: "Dainik Bhaskar",
      publishedDate: "22-02-2024",
    },
    {
      newsImage: "pressRelese/20.png",
      title: "Dubila Dadhi",
      publishedDate: "22-02-2024",
    },
    {
      newsImage: "pressRelese/21.png",
      title: "Dainik Jagran",
      publishedDate: "18-02-2024",
    },
    {
      newsImage: "pressRelese/22.png",
      title: "Rashtriya Sagar",
      publishedDate: "22-02-2024",
    },
    {
      newsImage: "pressRelese/23.png",
      title: "Aaj",
      publishedDate: "22-02-2024",
    },
    {
      newsImage: "pressRelese/2.png",
      title: "Dainik Bhaskar",
      publishedDate: "17-02-2024",
    },
    {
      newsImage: "pressRelese/3.png",
      title: "Rashtriya Sahara",
      publishedDate: "21-02-2024",
    },
    {
      newsImage: "pressRelese/4.png",
      title: "Hindustan",
      publishedDate: "31-01-2024",
    },
    {
      newsImage: "pressRelese/5.png",
      title: "Punjab Express Bureau",
      publishedDate: "02-11-2023",
    },
    {
      newsImage: "pressRelese/6.png",
      title: "Rashtriya Sahara",
      publishedDate: "02-11-2023",
    },
    {
      newsImage: "pressRelese/7.png",
      title: "Rashtriya Sahara",
      publishedDate: "31-01-2024",
    },
    {
      newsImage: "pressRelese/8.png",
      title: "Swatantra Bharat",
      publishedDate: "18-02-2024",
    },
    {
      newsImage: "pressRelese/9.png",
      title: "Amrit Vichar",
      publishedDate: "21-02-2024",
    },
  ]);
  const [openMoadal, setOpenModal] = useState(false);
  const [indexId, setIndexId] = useState({});

  return (
    <>
      <div className="container overflow-hidden">
        <h1 className="fw-semibold mx-2 fs-1 mt-5 mb-3">Media Coverage</h1>
        <div className="infaSliderTwo">
          {/* <Swiper
            slidesPerView={1}
            spaceBetween={0}
            freeMode={true}
            loop={true}
            grabCursor={true}
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
            }}
            speed={16000}
            pagination={{
              clickable: true,
            }}
            freemodemomentum={"false"}
            modules={[Autoplay, FreeMode, Pagination]}
            className="galleryswiper"
          >
            <SwiperSlide>
              <div className="bg-white d-flex gap-5">
                {pressReleseData?.map((val, ind) => {
                  return (
                    <>
                      <img
                        src={val?.newsImage}
                        loading="lazy"
                        alt=""
                        className="shadow-lg rounded-3  object-fit-cover"
                        style={{ width: "300px", height: "200px" }}
                        onClick={() => {
                          setOpenModal(true);
                          setIndexId({
                            newsImage: val?.newsImage,
                            title: val?.title,
                            publishedDate: val?.publishedDate,
                          });
                        }}
                      />
                    </>
                  );
                })}
              </div>
            </SwiperSlide>
          </Swiper> */}

          <marquee behavior="scroll" direction="left">
            <div className="bg-white d-flex gap-5">
              {pressReleseData?.map((val, ind) => {
                return (
                  <>
                    <img
                      src={val?.newsImage}
                      loading="lazy"
                      alt=""
                      className="shadow-lg rounded-3  object-fit-cover"
                      style={{ width: "300px", height: "200px" }}
                      onClick={() => {
                        setOpenModal(true);
                        setIndexId({
                          newsImage: val?.newsImage,
                          title: val?.title,
                          publishedDate: val?.publishedDate,
                        });
                      }}
                    />
                  </>
                );
              })}
            </div>
          </marquee>
        </div>

        {/* <div className="logos">
                <div className="logos-slide">
                {
                    pressReleseData?.map((val, ind)=>{
                        return(
                            <>
                            <img src={val?.newsImage} loading='lazy' alt='' className="shadow-lg rounded-3  object-fit-cover" style={{width:'300px', height:'200px' , zIndex:'-12', border: "1px solid"}}  onClick={()=>{
                                setOpenModal(true)
                                setIndexId({ newsImage:val?.newsImage, title: val?.title, publishedDate: val?.publishedDate})
                            }}/>
                            </>
                        )
                    })
                }
                </div>
            </div> */}
      </div>
      <Modal
        show={openMoadal}
        onHide={() => {
          setOpenModal(false);
        }}
      >
        <Modal.Header closeButton>
          <h2 className="fs-3">Media Preview</h2>
        </Modal.Header>
        <Modal.Body>
          <div
            className="w-100 text-center"
            style={{ minHeight: 400, height: 400 }}
          >
            {indexId !== null && (
              <>
                <div className="mb-4">
                  <img
                    src={indexId?.newsImage}
                    alt=""
                    className="yui-card-new "
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      border: "1px solid",
                    }}
                  />
                </div>
                {indexId?.title && (
                  <div className="mb-2">
                    <span className="fw-semibold fs-6">Published By:</span>{" "}
                    <span> {indexId?.title}</span>
                  </div>
                )}
                {indexId?.publishedDate && (
                  <div className="">
                    <span className="fw-semibold fs-6">Published Date:</span>{" "}
                    <span>{indexId?.publishedDate}</span>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
         <Button variant="outline-secondary" className='rounded-3' onClick={()=>{setOpenModal(false)}}>
            Close
          </Button>
         </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default PressReleaseYuva2;
