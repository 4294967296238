import React, { useEffect } from 'react'
import { AllStepInstitute } from './stepperComponent';
import { useGlobalContext } from 'global/context';
import { useNavigate } from 'react-router-dom';

export const OnBoardingInstituteComponent = () => {
  const navigate = useNavigate()
  const {userData} = useGlobalContext()
  useEffect(()=>{
    if(userData?.onBoardStatus===true && userData?.reviewStatus===true){
      navigate('/new-dashboard')
    }
  },[])
  const renderStep = ()=>{
    return (
      <AllStepInstitute/>
    )
  }

  return (
   <>
     <div className="card border-0">
        <div className="card-body">{renderStep()}</div>
      </div>
   </>
  )
}
