import React from 'react'

const Unsubscribe = () => {
  return (
    <>
        <div className='container text-center'>
            <h1>SuccessFully Unsubscribe</h1>
        </div>
    </>
  )
}

export default Unsubscribe