import React, { useRef } from 'react';
import {
    Box,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { useSubAdminCSV } from 'contexts/SubAdminCSVContext';

const FileList = ({ role }) => {
    const { data, handleUpdate, handleFileUpload, setRole } = useSubAdminCSV();
    // const fileInputRef = useRef(null);

    if (!data[role] || data[role].length === 0) {
        return null;
    }

    // const handleUploadClick = () => {
    //     if (fileInputRef.current) {
    //         fileInputRef.current.click();
    //         setRole(role);
    //     }
    // };

    return (
        <Box>
            <Typography variant="h6" color="#2356FE" sx={{ marginBottom: 2 }}>
                {role.charAt(0).toUpperCase() + role.slice(1)} Files
            </Typography>
            <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '40%', backgroundColor: '#2356FE', fontWeight: 'bold', color: 'white' }} >
                                File Name
                            </TableCell>
                            <TableCell sx={{ width: '40%', backgroundColor: '#2356FE', fontWeight: 'bold', color: 'white' }} >
                                Date
                            </TableCell>
                            <TableCell sx={{ width: '20%', backgroundColor: '#2356FE', fontWeight: 'bold', color: 'white' }} align='center'>
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data[role].map((fileName, index) => {
                            const date = fileName.split('_')[0];
                            return (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row" sx={{ width: '40%' }}>
                                        File {index + 1}
                                    </TableCell>
                                    <TableCell sx={{ width: '40%' }}>{date}</TableCell>
                                    <TableCell sx={{ width: '20%' }}>
                                        <Box
                                            display="flex"
                                            flexDirection={{ xs: 'column', sm: 'row' }}
                                            justifyContent="flex-end"
                                            sx={{
                                                '& > button': {
                                                    marginBottom: { xs: 1, sm: 0 },
                                                    marginRight: { sm: 1 },
                                                },
                                            }}
                                        >
                                            {/* <Button
                                                color="secondary"
                                                variant="outlined"
                                                onClick={handleUploadClick}
                                            >
                                                Upload
                                            </Button> */}
                                            <Button
                                                color="success"
                                                variant="outlined"
                                                onClick={() => handleUpdate(fileName, role)}
                                            >
                                                Update
                                            </Button>
                                        </Box>
                                        {/* <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            accept=".xlsx, .xls"
                                            onChange={(e) => handleFileUpload(e)}
                                        /> */}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default FileList;
