import React, { useContext, useEffect, useState } from "react";
import OnBoardContext from "pages/OnBoarding/context/onBoardContext";
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import { Modal } from 'react-bootstrap';
import { apiAuth, apiJson } from "api";
import { useGlobalContext } from "global/context";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const admins  =[
  {
    name:'Yuvamanthan', 
    designation:'Admin',
  }
]





export const StudentStepUnderReview = () => {

  const {userData, token, setUser,removePreUser} = useGlobalContext()
  const navigate = useNavigate()
    
  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    studentFormik,
    updateInstituteOnboardData
  } = useContext(OnBoardContext);

  const [moderators, setmoderators] = useState([])
  const [underReviewStatus, setUnderReviewStatus] = useState(false)
  const [instituteAdmin, setinstituteAdmin] = useState()
  const checkUnderReviewStatusUser = ()=>{
    apiJson.get('v2/register/checkUnderReviewStatus/email/'+userData?.email+'/role/'+userData?.role)
    .then((res)=>{
        setUnderReviewStatus(res.data.result) 
    }).catch((error)=>{
        console.log(error.message)
    })
}
useEffect(()=>{
  // correct here later add under Review status in UserData
  if(token  && userData.onBoardStatus===true && userData.reviewStatus===false){
     
      if(userData?.role==='teacher' || userData?.role==='student'){
             setActiveStep(3)
            setActiveChildStep(0)
            setCount(3)
          let checkInterval = setInterval(()=>{
              if(underReviewStatus){
                  clearInterval(checkInterval)
              }
              else{
                  checkUnderReviewStatusUser()
              }
          },5000);
          return ()=>{
              clearInterval(checkInterval)
          }
      }
     
  }
},[userData])
useEffect(()=>{
  if(underReviewStatus){
    if(['teacher', 'student'].includes(userData?.role) && userData?.nipamCheck){
      console.log("NIpam check",userData?.nipamCheck)
      removePreUser();
  
        localStorage.removeItem('studentOnboarding');
      setUser({ ...userData, reviewStatus: true })
      navigate('/course/detail/4')
    }
    else{
      removePreUser();
      localStorage.removeItem('studentOnboarding');
  
      setUser({ ...userData, reviewStatus: true })
      navigate('/new-dashboard')
    }
  }
},[underReviewStatus])
  const getManagerData = ()=>{
    const instituteId = studentFormik?.values.institute_id || userData?.instituteId
    apiJson('api/v2/institute/getInstituteManager/institute/'+instituteId)
    .then((res)=>{
      setmoderators(res.data.result);
    })
    .catch((error)=>{
      console.log(error.message)
    })
  }
  useEffect(()=>{
    getManagerData()
  },[])


  const handleBack = () => {
    setActiveChildStep(activeChildStep - 1);
    if (activeChildStep <= 0) {
      setCount(count - 1);
      setActiveStep(activeStep - 1);
      setActiveChildStep(0);
    }
    if (activeStep < 0) {
      setActiveStep(0);
    }
    if (activeChildStep < 0) {
      setActiveChildStep(0);
    }
    console.log("active", activeStep, " activechild", activeChildStep, " ");
  };

  const handleNextChild = () => {
    setActiveChildStep(activeChildStep + 1);

    if (activeChildStep >= stepperArray[count]?.childStep?.length - 1) {
      setActiveStep(activeStep + 1);
      setCount(count + 1);
      setActiveChildStep(0);
      console.log(
        "active step",
        activeStep,
        "stepperlength",
        stepperArray.length
      );
      if (activeStep > stepperArray.length - 2) {
        console.log("inside the if condition");
        setCount(0);
        setActiveStep(0);
        setActiveChildStep(0);
      }
    }
    // if(activeStep>=stepperArray?.length-1){
    //   console.log("active step ", activeStep,"stepperArraydddddddddddddd", stepperArray.length)
    //   setActiveStep(0)
    //   setCount(0)
    // }

    console.log("this is length", stepperArray?.length);
    console.log(
      "active step ",
      activeStep,
      "stepperArray",
      stepperArray.length
    );
  }
  // Modal Toggle
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
    const handleShowModal = () =>{
      setShowModal(true)
    }
    const handleClose = () =>{
      setShowModal(false)
    }
    const getInstituteAdmin = ()=>{
      apiJson(`api/v2/institute/fetchInstituteDetails/${userData?.instituteId}`)
      .then((res)=>{
        toast.dismiss()
        // console.log(res.data.result)
        setinstituteAdmin(res.data.result)
      }).catch((err)=>{
        toast.dismiss()
        toast.warning('Server Busy! Try Again Later')
      })
    }

    const postMessage = ()=>{
      if(message){
        apiJson.post('api/v2/institute/addMessageToAdmin',{
          message,
          email:studentFormik.values.email,
        }).then((res)=>{
            handleClose()
           Swal.fire({
            title: "Good job!",
            text: "Message Sent Successfully !",
            icon: "success"
          });
          setMessage("")
        }).catch((err)=>{
          console.log(err.message)
        })
      }
  }

  useEffect(()=>{
    getInstituteAdmin()
  },[])
  return (
    <>
       <div className="">


<div className="mb-2">
  <span className=" fs-35px fw-600">Under Review</span>
</div>

    <div className="mb-4">
      <span
        className=""
        style={{
          color: "#989898",
          fontSize: "19px",
          fontWeight: "500",
        }}
      >
          You are just one step away from activating your Yuvamanthan account to access all features. Reach out to any of the institutional admins or moderators listed below to initiate the activation process.
      </span>
    </div>

        <div>
          {/* <div className="mb-4">
            <span  className="fs-4 fw-semibold" style={{
            }}>Yuvamanthan Admin</span>
            <div className="mt-4">
              {
                admins?.map((a)=>{
                  return <>
                    <div className="row justify-content-between mb-4 ms-4">
                        <div className="col-4">
                          <span style={{fontWeight:'500',color:'#300095'}} className="me-4 ">{a?.name}</span>
                        </div>
                        <div className="col-4">
                        <span style={{color:'#7B5EA1'}}>{a?.designation}</span>
                        </div>
                      <div className="col-4">
                          <div className="d-flex">
                            <div className="me-4 cursor-pointer"><MailIcon onClick={handleShowModal} style={{color:'#FAB400'}}/></div>
                            <div className="cursor-pointer"><a href="tel:9560771911"><PhoneIcon style={{color:'#1300F3'}} /> </a></div>
                          </div>
                      </div>
                    </div>
                  </>
                })
              }
            </div>
          </div> */}
          <div className="mb-4">
              <div>
                <h1 className="fs-4 fw-semibold">Institute Admin</h1>
              </div>
              <div className="row justify-content-between mb-4 ms-4">
                        <div className="col-4">
                          <span style={{fontWeight:'500',color:'#300095'}} className="me-4 ">{instituteAdmin?.name}</span>
                        </div>
                        <div className="col-4">
                        <span style={{color:'#7B5EA1'}}>{'Admin'}</span>
                        </div>
                      <div className="col-4">
                          <div className="d-flex">
                            <div className="me-4 cursor-pointer"><MailIcon onClick={handleShowModal} style={{color:'#FAB400'}}/></div>
                            <div className="cursor-pointer"><a href={`tel:${instituteAdmin?.contact}`}><PhoneIcon style={{color:'#1300F3'}} /> </a></div>
                          </div>
                      </div>
                    </div>
          </div>
          <div className="mb-50">
          { moderators?.length>0 && <div className="mb-4">
              <span className="fw-500 fs-19px">Moderators</span>
            </div>}
            <div>
              {
                moderators?.map((m)=>{
                  return (
                    <div className="row justify-content-between mb-4 ms-4">
                        <div className="col-4">
                          <span className="me-4" style={{fontWeight:'500',color:'#300095'}}>{m.name}</span>
                        </div>
                       <div className="col-4">
                        <span style={{color:'#7B5EA1'}}>{m.designation}</span>
                       </div>
                      <div className="col-4">
                      <div className="d-flex ">
                          <div className="me-4">
                            <span className="cursor-pointer"><MailIcon onClick={handleShowModal} style={{color:'#FAB400'}}/></span>
                          </div>
                          <div>
                            <span className="cursor-pointer" ><PhoneIcon style={{color:'#1300F3'}}/></span>
                          </div>
                      </div>
                      </div>

                    </div>
                  )
                })

              }
            </div>
          </div>
          <div>
        <Modal show={showModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter">
          <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className=" fw-bold color-black" style={{fontSize: "25px",fontFamily: "Poppins"}}>
          Request Approval
          </Modal.Title>
          </Modal.Header>

          <Modal.Body className="">
            

            <div>
              <div className="mt-2">
              <span className="fw-500" style={{fontFamily: "Poppins", fontSize: "16px", color: "black"}}>Message</span>
              </div>

              <div className="mt-2" style={{ fontStyle: 'italic', fontFamily: "Poppins"}}>
                <textarea type="text" className="form-control p-3 rounded fw-500 box-shadow-0 resize-none border-0" name="" id="" onChange={(e)=> setMessage(e.target.value)} placeholder="Eg. Hi, Please complete my verification process."  style={{background: "#EDEDED", fontSize: "16px"}}>
                </textarea>
              </div>

              <div className="mt-3 mb-3 d-flex justify-content-between align-items-center text-center">
                <div>
                  <button onClick={handleClose} className="rounded" style={{ height: "36px", width: "122px" , background: "#E7E7E7", color: "#747474"}}>Discard</button>
                </div>
                <div>
                  <button type="submit" className="rounded" onClick={postMessage} style={{ height: "36px", width: "99px", background: "#C08EFF", color: "#2B0062"}}>Send</button>
                </div>
              </div>

              <div>

              </div>
            </div>

          </Modal.Body>
        </Modal>
        </div>
        </div>

        
    
  <div className="d-flex justify-content-between mt-4 z-index-1 ">
    <button disabled onClick={handleBack} className="btn-onboard-disabled">
      Previous
    </button>
    {/* btn-onboard-disabled */}
    {/* btn-onboard-fill-disabled? */}
    <button disabled onClick={handleNextChild} className="btn-onboard-fill-disabled" >
      Next Step <span className='ms-4'><img src='./images/onBoarding/right-icon.png'/></span>
    </button>
  </div>
</div>
    </>
  )
}
