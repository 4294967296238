// import React, { useState, useEffect } from 'react';
// import {
//     Box,
//     Button,
//     TextField,
//     MenuItem,
//     Snackbar,
//     Alert,
//     IconButton,
//     Dialog,
//     DialogActions,
//     DialogContent,
//     DialogTitle,
//     Typography,
//     Radio,
//     RadioGroup,
//     FormControl,
//     FormLabel,
//     Select,
//     Pagination,
//     TablePagination,
//     TableRow,
//     TableCell,
// } from '@mui/material';
// import axios from 'axios';
// import RemoveIcon from '@mui/icons-material/Remove';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { styled } from '@mui/system';
// import SelectOptionDialog from './SelectedComponent';
// import Loader from '../SewaPage/Loader';
// import { FaMinus } from 'react-icons/fa';
// import { useParams } from 'react-router-dom';
// import { CustomPagination } from './ProjectDetails';

// const MentorDetails = () => {
//     const [data, setData] = useState([]);
//     const [categoryOptions, setCategoryOptions] = useState([]);
//     const [classOptions, setClassOptions] = useState([]);
//     const [snackbarMessage, setSnackbarMessage] = useState("");
//     const [snackbarSeverity, setSnackbarSeverity] = useState("success");
//     const [openSnackbar, setOpenSnackbar] = useState(false);
//     const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
//     const [mentorToRemove, setMentorToRemove] = useState(null);
   
//     const [openModal, setOpenModal] = useState(true);
//     const [selectedOption, setSelectedOption] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [selectedModalwindow , setselectedModalwindow] = useState(false)
//     const itemsPerPage = 5;

//     const [page, setPage] = useState(0);
//     const [rowsPerPage, setRowsPerPage] = useState(5);


//     // const instituteId = 15292;
//     const { instituteId } = useParams();

//     useEffect(() => {
//         const fetchTeachers = async () => {
//             try {
//                 const teachersResponse = await axios.get(`http://localhost:3000/api/mentors/all/${instituteId}`);
//                 if (teachersResponse.data.status) {
//                     const teachers = teachersResponse.data.data;
                    
//                     const mentorChecks = await Promise.all(
//                         teachers.map(async (teacher) => {
//                             const mentorCheck = await axios.get(`http://localhost:3000/api/mentors/exist/${teacher.id}`);
//                             return { ...teacher, isMentor: mentorCheck.data.status };
//                         })
//                     );
    
//                     const formattedData = mentorChecks.map(teacher => ({
//                         fullName: `${teacher.first_name} ${teacher.last_name}`.trim(),
//                         uid: teacher.id,
//                         email: teacher.email,
//                         selectedCategories: [],
//                         isMentor: teacher.isMentor
//                     }));
    
//                     setData(formattedData);
//                 }
//             } catch (error) {
//                 console.error("Error fetching teachers:", error);
//             }
//         };
    
//         fetchTeachers();
//     }, [instituteId]);
    
//     useEffect(() => {
//         const fetchCategories = async () => {
//             try {
//                 const response = await axios.get('http://localhost:3000/api/categories/all');
//                 setCategoryOptions(Array.isArray(response.data) ? response.data : []);
//             } catch (error) {
//                 console.error("Failed to fetch categories:", error);
//                 setCategoryOptions([]);
//             }
//         };

//         const fetchClasses = () => {
//             setClassOptions([9, 10, 11, 12]);
//         };

//         fetchCategories();
//         fetchClasses();
//     }, []);

//     // const CustomRadio = styled(Radio)({
//     //     '&.Mui-checked': {
//     //         color: '#1068ec',
//     //     },
//     // });

//     const handleSelectCategory = (mentorId, event) => {
//         const value = event.target.value;
//         setData(prevData => prevData.map(mentor =>
//             mentor.uid === mentorId
//                 ? { ...mentor, selectedCategories: [...new Set([...mentor.selectedCategories, value])] }
//                 : mentor
//         ));
//     };

//     const handleAddMentor = async (mentorId, categories) => {
//         try {
//             const response = await axios.post(`http://localhost:3000/api/mentors/updateCategory/${mentorId}`, { data: categories });
//             if (response.data.status) {
//                 setSnackbarMessage(response.data.message);
//                 setSnackbarSeverity("success");
//                 setData(prevData =>
//                     prevData.map(item =>
//                         item.uid === mentorId ? { ...item, isMentor: true } : item
//                     )
//                 );
//             } else {
//                 setSnackbarMessage(response.data.message);
//                 setSnackbarSeverity("info");
//             }
//         } catch (error) {
//             setSnackbarMessage("Operation failed. Please try again.");
//             setSnackbarSeverity("error");
//             console.error("Error adding mentor:", error);
//         }
//         setOpenSnackbar(true);
//     };

//     const handleRemoveMentor = async (mentorId) => {
//         try {
//             await axios.delete(`http://localhost:3000/api/mentors/remove/${mentorId}`);
//             setSnackbarMessage("Mentor removed successfully!");
//             setSnackbarSeverity("success");
//             setData(prevData => prevData.map(item =>
//                 item.uid === mentorId ? { ...item, isMentor: false } : item
//             ));
//         } catch (error) {
//             setSnackbarMessage("Operation failed. Please try again.");
//             setSnackbarSeverity("error");
//             console.error("Error removing mentor:", error);
//         }
//         setOpenSnackbar(true);
//     };


//     const handleOpenConfirmDialog = (mentorId) => {
//         setMentorToRemove(mentorId);
//         setOpenConfirmDialog(true);
//     };

//     const handleCloseConfirmDialog = () => {
//         setOpenConfirmDialog(false);
//         setMentorToRemove(null);
//     };

//     const handleConfirmRemoveMentor = () => {
//         if (mentorToRemove) {
//             handleRemoveMentor(mentorToRemove);
//         }
//         handleCloseConfirmDialog();
//     };

//     const handleModalConfirm = () => {
//         setLoading(true);
//         setTimeout(() => {
//             setOpenModal(false);
//             setLoading(false);
//         }, 1000);
//     };

//     // const handlePageChange = (event, value) => {
//     //     setCurrentPage(value);
//     // };

    
//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//     };

//     const handleChangeRowsPerPage = (event) => {
//         setRowsPerPage(parseInt(event.target.value, 10));
//         setPage(0);
//     };

    
//     const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

//     // Create padding rows if the paginated data is less than rowsPerPage
//     const emptyRows = rowsPerPage - paginatedData.length;

//     if (loading) {
//         return <Loader />;
//     }

// const handleOpenDialog = ()=>{
//      setselectedModalwindow(true);
// }
// const handlecloseSelectedWindow = ()=>{
//     setselectedModalwindow(false);
// }
    
// const handleRemoveCategory = (mentorId, category) => {
//     setData(prevData => prevData.map(mentor =>
//         mentor.uid === mentorId
//             ? { ...mentor, selectedCategories: mentor.selectedCategories.filter(cat => cat !== category) }
//             : mentor
//     ));
// };
//     // Calculate pagination
//     const totalPages = Math.ceil(data.length / itemsPerPage);
//     const displayedData = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

//     return (
//         <>
//             {/* {openModal ? (
//                 <SelectOptionDialog
//                     open={openModal}
//                     selectedOption={selectedOption}
//                     onSelectOption={setSelectedOption}
//                     onConfirm={handleModalConfirm}
//                 />
//             ) 
//             :  */}
//             {/* ( */}
//                 <Box
//                     sx={{
//                         padding: "1vw",
//                         borderRadius: "1rem",
//                         backgroundColor: "var(--color-ghostwhite)",
//                         border: "5px solid #2356fe",
//                         boxSizing: "border-box",
//                         width: "100%",
//                         overflow: "hidden",
//                     }}
//                 >
//                     {/* <Box
//                         sx={{
//                             display: "flex",
//                             justifyContent: "flex-end",
//                             alignItems: "baseline",
//                             padding: "16px",
//                             borderRadius: "8px",
//                             marginBottom: "16px",
//                         }}
//                     >
//                         <Button
//                             variant="contained"
//                             color="primary"
//                             sx={{
//                                 fontSize: "1rem",
//                                 textTransform: "none",
//                                 padding: "10px 20px",
//                                 display: "flex",
//                                 backgroundColor: "#1068ec",
//                                 borderRadius: "12px",
//                                 boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
//                                 '&:hover': {
//                                     backgroundColor: "#0b57d0",
//                                     boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
//                                 }
//                             }}
//                             onClick={() => setOpenModal(true)}
//                         >
//                             Change Criteria
//                             <IconButton
//                                 sx={{
//                                     color: "#fff",
//                                     marginLeft: "8px",
//                                     padding: "8px",
//                                 }}
//                             >
//                                 <MoreVertIcon sx={{ fontSize: "1.25rem" }} />
//                             </IconButton>
//                         </Button>
//                     </Box> */}

//                     <Box sx={{ borderRadius: '1.5rem', overflow: 'hidden' }}>
//                         <table style={{ width: "100%", borderCollapse: "collapse" }}>
//                             <thead>
//                                 <tr>
//                                     <th style={{ textAlign: 'center', padding: '10px',backgroundColor:"#F3F3F3", color: '#000000' , height:"4rem" }}>Name</th>
//                                     <th style={{ textAlign: 'center', padding: '10px', backgroundColor:"#F3F3F3", color: '#000000' }}>Email</th>
//                                     <th style={{ textAlign: 'center', padding: '10px', backgroundColor:"#F3F3F3", color: '#000000' }}>Class / Category</th>
//                                     <th style={{ textAlign: 'center', padding: '10px',backgroundColor:"#F3F3F3", color: '#000000' }}>Selected</th>
//                                     <th style={{ textAlign: 'center', padding: '10px', backgroundColor:"#F3F3F3", color: '#000000'}}>Actions</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {displayedData.map((rowData) => (
//                                     <tr key={rowData.uid}>
//                                         <td style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ddd' }}>{rowData.fullName}</td>
//                                         <td style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ddd' }}>{rowData.email}</td>
//                                         <td style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ddd' }}>
//                                             <FormControl fullWidth>
//                                                 <FormLabel id="class-label">
//                                                     <Select
//                                                         labelId="class-label"
//                                                         value={rowData.selectedCategories[0] || classOptions[0] || ''}
//                                                         onChange={(e) => handleSelectCategory(rowData.uid, e)}
//                                                         variant="outlined"
//                                                         sx={{ minWidth: 120 }}
//                                                     >
//                                                         {classOptions.length > 0 && (
//                                                             <MenuItem value="" disabled>
//                                                                 {classOptions[0]}
//                                                             </MenuItem>
//                                                         )}
//                                                         {classOptions.map((option) => (
//                                                             <MenuItem key={option} value={option}>
//                                                                 {option}
//                                                             </MenuItem>
//                                                         ))}
//                                                     </Select>
//                                                 </FormLabel>
//                                             </FormControl>
//                                         </td>
//                                         <td style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ddd' }}>
//                                             <Button
//                                                 variant="outlined"
//                                                 onClick={() => handleOpenDialog()}
//                                             >
//                                                 View
//                                             </Button>
//                                         </td>
//                                         <td style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ddd' }}>
//                                             {rowData.isMentor ? (
//                                                 <Button
//                                                     variant="outlined"
//                                                     color="error"
//                                                     onClick={() => handleOpenConfirmDialog(rowData.uid)}
//                                                     startIcon={<RemoveIcon />}
//                                                 >
//                                                     Remove
//                                                 </Button>
//                                             ) : (
//                                                 <Button
//                                                     variant="contained"
//                                                     color="primary"
//                                                     onClick={() => handleAddMentor(rowData.uid, rowData.selectedCategories)}
//                                                 >
//                                                     Add
//                                                 </Button>
//                                             )}
//                                         </td>
//                                     </tr>


//                                 ))}
                                
// {emptyRows > 0 && (
//     <TableRow style={{ height: 53 * emptyRows  }}>
//         <TableCell colSpan={3}  />
//     </TableRow>
// )}
//                             </tbody>
//                         </table>
//                     </Box>

//                     <Box
//     sx={{
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         padding: '0.5rem 1rem',
//         // position: "relative",
//         zIndex:"5"
//     }}
// >
// <TablePagination
//     component="div"
//     count={50} 
//     page={0} 
//     onPageChange={() => {}}
//     rowsPerPage={rowsPerPage}
//     onRowsPerPageChange={handleChangeRowsPerPage} 
//     rowsPerPageOptions={[5, 10, 25]}
//     labelRowsPerPage="Rows per page"
//     sx={{
//         flexShrink: 0,
//         '& .MuiTablePagination-toolbar': {
//             paddingLeft: 0,
//         },
//         '& .MuiTablePagination-selectLabel': {
//             marginRight: 2, 
//         },
//         '& .MuiSelect-select': {
//             border: '1px solid #ccc',
//             borderRadius: '4px',
//             padding: '6px 8px', 
//         },
        
//     }}
//     labelDisplayedRows={() => ''}
//     slotProps={{
//         actions: {
//             nextButton: { sx: { display: 'none' } },
//             backButton: { sx: { display: 'none' } }, 
//             firstButton: { sx: { display: 'none' } }, 
//             lastButton: { sx: { display: 'none' } }, 
//         },
//     }}
// />
//     <CustomPagination
//         // count={studentData.length}
//         page={page}
//         rowsPerPage={rowsPerPage}
//         onPageChange={handleChangePage}
//     />
// </Box>


//                     {/* <Pagination
//                         count={totalPages}
//                         page={currentPage}
//                         onChange={handlePageChange}
//                         variant="outlined"
//                         shape="rounded"
//                         sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}
//                     /> */}

//                     <Snackbar
//                         open={openSnackbar}
//                         autoHideDuration={6000}
//                         onClose={() => setOpenSnackbar(false)}
//                     >
//                         <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
//                             {snackbarMessage}
//                         </Alert>
//                     </Snackbar>

//                     <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
//                         <DialogTitle>Confirm Removal</DialogTitle>
//                         <DialogContent>
//                             <Typography>Are you sure you want to remove this mentor?</Typography>
//                         </DialogContent>
//                         <DialogActions>
//                             <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
//                             <Button onClick={handleConfirmRemoveMentor} color="error">Remove</Button>
//                         </DialogActions>
//                     </Dialog>

//                     <Dialog 
//     open={selectedModalwindow} 
//     onClose={handlecloseSelectedWindow}
//     sx={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         '& .MuiPaper-root': {
//             width: "350px", 
//             height: "300px", 
//             maxWidth: "none", 
//             background: "#2356FE",
//         }
//     }}
// >
//     <DialogContent 
//         sx={{
//             background:"#2356FE",
//             display: 'flex',
//             flexDirection: 'row',
//             justifyItems:"space-between",
//             height: '100%',
//         }}
//     >
//         <Typography 
//             sx={{ 
//                 color: "#fff",
//                 textAlign: 'left',
//             }} 
//         >
//             {/* selected category of the row in which view is clicked  */}
//         </Typography>
//         <Button
//         onClick={handleRemoveCategory}
//             sx={{ 
//                 color: "#fff",
//                 textAlign: 'left',
//             }} 
//         >
//             <FaMinus/>
//         </Button>
//     </DialogContent>
// </Dialog>
//                 </Box>
//             {/* ) */}
            
//             {/* // } */}
//         </>
//     );
// };

// export default MentorDetails;



import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    TextField,
    MenuItem,
    Snackbar,
    Alert,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    Select,
    TableRow,
    TableCell,
    TablePagination,
    FormControlLabel,
    Switch,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import Loader from '../SewaPage/Loader';
import { CustomPagination } from './ProjectDetails';
import { apiSewa } from 'api';

const MentorDetails = () => {
    const [data, setData] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [classOptions, setClassOptions] = useState([]);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [mentorToRemove, setMentorToRemove] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Category');
    const [loading, setLoading] = useState(false);
    const [selectedModalwindow, setSelectedModalWindow] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCategories, setSelectedCategories] = useState({});

const [page, setPage] = useState(0);
const [rowsPerPage, setRowsPerPage] = useState(5);
    // const { instituteId } = useParams();
    const  instituteId = 15292;
    const [checked, setChecked] = useState(false);

    const [selectedRowData, setSelectedRowData] = useState(null);

    const handleOpenDialog = (rowData) => {
      setSelectedRowData(rowData); // Store the clicked row data
      setOpenModal(true); // Open the dialog
    };
  
    const handleCloseDialog = () => {
      setOpenModal(false);
      setSelectedRowData(null); // Clear the selected data when closing
    };

    const  handleChange = async (event) =>  {
        setChecked(event.target.checked);
         setLoading(true);
        if(checked) {
          await  setSelectedOption("Category");
        }else{
           await setSelectedOption("Class");

        }
 setLoading(false);
    };
  
    useEffect(() => {
        
        const fetchTeachers = async () => {
            try {
                setLoading(true);
                const teachersResponse = await  apiSewa.get(`/mentors/all/${instituteId}`);
                if (teachersResponse.data.status) {
                    const teachers = teachersResponse.data.data;

                    const mentorChecks = await Promise.all(
                        teachers.map(async (teacher) => {
                            const mentorCheck = await apiSewa.get(`/mentors/exist/${teacher.id}`);
                            setLoading(true);
                            return { ...teacher, isMentor: mentorCheck.data.status };

                        })
                    );

                    const formattedData = mentorChecks.map(teacher => ({
                        fullName: `${teacher.first_name} ${teacher.last_name}`.trim(),
                        uid: teacher.id,
                        email: teacher.email,
                        selectedCategories: [],
                        isMentor: teacher.isMentor
                    }));

                    setData(formattedData);
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching teachers:", error);
            }
        };

        fetchTeachers();
    }, [instituteId]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setLoading(true)
                const response = await apiSewa.get('/categories/all');
                setCategoryOptions(Array.isArray(response.data) ? response.data : []);
                setLoading(false)
            } catch (error) {
                console.error("Failed to fetch categories:", error);
                setCategoryOptions([]);
            }
        };

        const fetchClasses = () => {
            setClassOptions([9, 10, 11, 12]);
        };

        fetchCategories();
        fetchClasses();
    }, []);

    const handleSelectCategory = (mentorId, event) => {
        console.log("in handleSelectCategory");
        const value = event.target.value;
        console.log(value)
        setData(prevData => prevData.map(mentor =>
            mentor.uid === mentorId
                ? { ...mentor, selectedCategories: [...new Set([...mentor.selectedCategories, value])] }
                : mentor
        ));
        setSelectedCategories(value);
    };

console.log("selected category" , selectedCategories);

    const handleAddMentor = async (mentorId, categories) => {
        try {
            setLoading(true)
            const response = await apiSewa.post(`/mentors/updateCategory/${mentorId}`, { data: categories });
            if (response.data.status) {
                setSnackbarMessage(response.data.message);
                setSnackbarSeverity("success");
                setData(prevData =>
                    prevData.map(item =>
                        item.uid === mentorId ? { ...item, isMentor: true } : item
                    )
                );
                setLoading(false)
            } else {
                setSnackbarMessage(response.data.message);
                setSnackbarSeverity("info");
            }
        } catch (error) {
            setSnackbarMessage("Operation failed. Please try again.");
            setSnackbarSeverity("error");
            console.error("Error adding mentor:", error);
        }
        setOpenSnackbar(true);
    };

    const handleRemoveMentor = async (mentorId) => {
        try {
            setLoading(true)
            await apiSewa.delete(`/mentors/remove/${mentorId}`);
            setSnackbarMessage("Mentor removed successfully!");
            setSnackbarSeverity("success");
            setData(prevData => prevData.map(item =>
                item.uid === mentorId ? { ...item, isMentor: false } : item
            ));
            setLoading(false);
        } catch (error) {
            setSnackbarMessage("Operation failed. Please try again.");
            setSnackbarSeverity("error");
            console.error("Error removing mentor:", error);
        }
        setOpenSnackbar(true);
    };

    const handleOpenConfirmDialog = (mentorId) => {
        setMentorToRemove(mentorId);
        setOpenConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
        setMentorToRemove(null);
    };

    const handleConfirmRemoveMentor = () => {
        if (mentorToRemove) {
            handleRemoveMentor(mentorToRemove);
        }
        handleCloseConfirmDialog();
    };

    const handleModalConfirm = () => {
        setLoading(true);
        setTimeout(() => {
            setOpenModal(false);
            setLoading(false);
        }, 1000);
    };


    const handleCloseSelectedWindow = () => {
        setSelectedModalWindow(false);
    };

const handleChangePage = (event, newPage) => {
    setPage(newPage);
};

const handleRemoveCategory = (mentorId, category) => {
    const updatedData = data.map(mentor =>
        mentor.uid === mentorId
            ? { ...mentor, selectedCategories: mentor.selectedCategories.filter(cat => cat !== category) }
            : mentor
    );

    setData(updatedData);

    if (selectedRowData && selectedRowData.uid === mentorId) {
        setSelectedRowData(prev => ({
            ...prev,
            selectedCategories: prev.selectedCategories.filter(cat => cat !== category)
        }));
    }
};


const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};

const onSelectOption = (value) => {
    setSelectedOption(value);
};

const CustomRadio = styled(Radio)({
    '&.Mui-checked': {
        color: '#1068ec',
    },
});


const filteredData = data.filter(data =>
    data.fullName.toLowerCase().includes(searchQuery.toLowerCase())
);

const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    if (loading) {
        return <Loader text={'Wait for a Moment'} />;
    }

    return (
        <>

<Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography
                sx={{
                    fontFamily: 'Poppins',
                    fontSize: '1rem',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left',
                    color: "#575757",
                    marginBottom: "3rem",
                    marginTop: "1rem",
                }}
            >
                This second step tracks all pending, ongoing, and completed projects, helping users manage progress, mentors, and timelines effectively.
            </Typography>

            <Box
                sx={{
                    padding: "1rem",
                    backgroundColor: "var(--color-ghostwhite)",
                    position: "relative",
                    boxSizing: "border-box",
                    width: "100%",
                    borderRadius: "1rem", 
                    overflow: "hidden",
                    zIndex: 1,
                    ":before": {
                        content: '""',
                        background: "linear-gradient(122.52deg, rgba(35, 86, 254, 0.7) 1.25%, rgba(35, 86, 254, 0.1) 98.44%)",
                        borderRadius: "1rem", 
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        zIndex: "1",
                        margin: "-1rem",
                    }
                }}
            >

<Box sx={{display:"flex" , flexDirection: "column" , position: "relative",
                            zIndex: 5}}>

<Box  
  sx={{  
    display: "flex", 
    flexDirection: { xs: "column", md: "row" }, // Stack on small screens, row on larger screens
    justifyContent: "space-between",  
    alignItems: { xs: "flex-start", md: "center" }, // Align differently on small vs large screens
    padding: { xs: "1rem", md: "2rem 1.5rem" }, 
    borderTopLeftRadius: "1rem", 
    borderTopRightRadius: "1rem", 
    position: "relative",
    backgroundColor: "#ffffff",
    zIndex: 5
  }}
> 
  <Box> 
    <Typography 
      variant="h4" 
      component="div" 
      gutterBottom 
      sx={{
        fontFamily: 'Poppins',
        fontWeight: 700,
        width: { xs: "100%", md: "90%" }, // Adjust width on different screen sizes
        textAlign: 'left',
        position: "relative",
        zIndex: "5"
      }}
    >
      <span style={{ color: "blue", zIndex: "5" }}>Add</span> Mentor
    </Typography>
    <Typography 
      gutterBottom  
      component="div" 
      sx={{
        fontFamily: 'Poppins',
        fontWeight: 400,
        lineHeight: '18.97px',
        fontSize: { xs: "10px", md: "12.65px" }, // Smaller text on smaller screens
        textAlign: 'left',
        width: { xs: "100%", md: "90%" },
        color: "#6D6D6D",
        zIndex: "5"
      }}
    >
      Explore key details such as project name, description, mentor, assigned students, start date, and progress status for an overview of all ongoing projects.
    </Typography>
  </Box>

  <Box
    sx={{
      display: "flex",
      flexDirection:{md:"column" , xs : "row"},
      justifyContent: { md: "flex-end" },
      alignItems: "center",
      width: { xs: "100%", md: "16rem" }, 
      position: "relative",
      zIndex: "5",
      mt: { xs: "1rem", md: 0 }, 
    }}
  >
    <Box 
      sx={{ 
        position: 'relative', 
        zIndex: "5",
        width: { xs: "100%", md: '16rem' }, 
        height: '2.5rem', 
        backgroundColor: '#e7e7e7', 
        borderRadius: '0.7rem', 
        display: 'flex', 
        alignItems: 'center', 
        padding: '0 12px',
        // marginBottom: { xs: "1rem", md: "0" } 
      }}
    >
      <SearchIcon style={{ color: '#42A5F5', marginRight: '8px' }} />
      <input
        type="text"
        placeholder="Search Categories"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          outline: 'none',
          backgroundColor: 'transparent',
          fontSize: '1rem',
          color: '#333',
          padding: '0',
          zIndex: "5"
        }}
      />
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
  <FormControlLabel
    control={
      <Switch
        checked={checked}
        onChange={handleChange}
        sx={{
          width: "80px",
          height: "28px",
          display: "flex",
          alignItems: "center",
          padding: "0px 5px 0px 5px",
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: "0px",
            transition: 'transform 0.3s ease',
            '&.Mui-checked': {
              transform: 'translateX(52px)', 
              color: '#fff',
            },
            '&.Mui-checked + .MuiSwitch-track': {
              backgroundColor: 'rgba(76, 175, 80, 0.5)', 
              opacity: 1,
              border: 'none',
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 28,
            height: 28,
            borderRadius: '50%',
            backgroundColor: checked ? '#4CAF50' : '#2356FE', 
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            transition: 'background-color 0.3s ease',
          },
          '& .MuiSwitch-track': {
            borderRadius: 28 / 2,
            backgroundColor: checked
              ? 'rgba(76, 175, 80, 0.4)' 
              : 'rgba(35, 86, 254, 0.5)', 
            opacity: 1,
            transition: 'background-color 0.3s ease',
          },
        }}
      />
    }
  />
</Box>



  </Box>
</Box>


            <Box
                sx={{
                    borderBottomLeftRadius: "1rem",
                    borderBottomRightRadius: "1rem",
                    backgroundColor:"#ffffff" ,
                    boxSizing: "border-box",
                    width: "100%",
                    overflow: "hidden",
                    position: "relative",
                    zIndex:5
                }}
            >
                <Box sx={{  overflow: 'auto' ,  position: "relative", backgroundColor:"#ffffff" ,minHeight:{md:"26rem" , xs:"23rem"}}}>
                    <table style={{ width: "100%", borderCollapse: "collapse" ,  position: "relative", zIndex:5 }}>
                        <thead>
                            <tr>
                                <th style={{ color: '#000000', backgroundColor:"#f0eded" ,  fontWeight: '600', textAlign: 'center' , height:"5rem" }}>Name</th>
                                <th style={{ tcolor: '#000000', backgroundColor:"#f0eded" ,  fontWeight: '600', textAlign: 'center' }}>Email</th>
                                <th style={{ color: '#000000', backgroundColor:"#f0eded" ,  fontWeight: '600', textAlign: 'center' }}>{selectedOption === 'Category' ? "Category" : "Class"}</th>
                                <th style={{color: '#000000', backgroundColor:"#f0eded" ,  fontWeight: '600', textAlign: 'center' }}>Selected</th>
                                <th style={{ color: '#000000', backgroundColor:"#f0eded" ,  fontWeight: '600', textAlign: 'center' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((rowData) => (
                                <tr key={rowData.uid}>
                                    <td style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ddd' }}>{rowData.fullName}</td>
                                    <td style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ddd' }}>{rowData.email}</td>
                                    <td style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ddd' }}>
              <FormControl fullWidth>
                <Select
                  value={rowData.selectedCategories.length > 0 ? rowData.selectedCategories[rowData.selectedCategories.length - 1] : ""}
                  onChange={(e) => handleSelectCategory(rowData.uid, e)}
                  variant="outlined"
                  sx={{
                    minWidth: 80,
                    maxWidth: 120,
                    '.MuiSelect-select': {
                      display: 'block',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }
                  }}                >
                  <MenuItem value="" disabled>Select</MenuItem>
                  {(selectedOption === 'Category' ? categoryOptions : classOptions).map((option) => (
                    <MenuItem
                      key={option._id || option}
                      value={option.name || option}
                      disabled={rowData.selectedCategories.includes(option.name || option)}
                    >
                      {option.name || option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </td>
                                    <td style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ddd', color:"#2356FE" }}>
                          
               <Button
                //   variant="contained"
                  onClick={() => handleOpenDialog(rowData)}
                  sx={{  color: '#2356FE' , border:"none" }}
                >
                  View
                </Button>       
                                             </td>
                                    <td style={{ textAlign: 'center', padding: '10px', borderBottom: '1px solid #ddd' }}>
                                        {rowData.isMentor ? (
                                            <>
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={() => handleOpenConfirmDialog(rowData.uid)}
                                                >
                                                    Remove
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() =>
                                                        handleAddMentor(rowData.uid, rowData.selectedCategories)
                                                    }
                                                >
                                                    Add
                                                </Button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}

{/* {emptyRows > 0 && (
    <TableRow style={{ height: 53 * emptyRows  }}>
        <TableCell colSpan={3}  />
    </TableRow>
)} */}

                        </tbody>
                    </table>
                </Box>
<Box
    sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.5rem 1rem',
        zIndex:"5"
    }}
>
<TablePagination
    component="div"
    // count={50} 
    page={page} 
    onPageChange={() => {}}
    rowsPerPage={rowsPerPage}
    onRowsPerPageChange={handleChangeRowsPerPage} 
    rowsPerPageOptions={[5, 10, 25]}
    labelRowsPerPage="Rows per page"
    sx={{
        flexShrink: 0,
        '& .MuiTablePagination-toolbar': {
            paddingLeft: 0,
        },
        '& .MuiTablePagination-selectLabel': {
            marginRight: 2, 
        },
        '& .MuiSelect-select': {
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '6px 8px', 
        },
        
    }}
    labelDisplayedRows={() => ''}
    slotProps={{
        actions: {
            nextButton: { sx: { display: 'none' } },
            backButton: { sx: { display: 'none' } }, 
            firstButton: { sx: { display: 'none' } }, 
            lastButton: { sx: { display: 'none' } }, 
        },
    }}
/>
    <CustomPagination
        // count={paginatedData.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
    />
</Box>
            </Box>

            <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to remove this mentor?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
                    <Button onClick={handleConfirmRemoveMentor} color="error">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>


            {selectedRowData && (
                <Dialog
                    open={openModal}
                    onClose={handleCloseDialog}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& .MuiPaper-root': {
                            width: { xs: '90%', sm: '350px' },
                            height: '300px',
                            maxWidth: 'none',
                            borderRadius: '1rem',
                            background: '#2356FE',
                            padding: '5px',
                        },
                    }}
                >
                    <DialogContent
                        sx={{
                            background: '#2356FE',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '1rem',
                            justifyItems: 'left',
                            height: '100%',
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography
                                sx={{
                                    color: '#fff',
                                    textAlign: 'left',
                                }}
                            >
                                Selected Categories
                            </Typography>
                            <IconButton onClick={handleCloseDialog} sx={{ color: '#fff' }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        {selectedRowData.selectedCategories && (
                            <Box>
                                {selectedRowData.selectedCategories.map((cat) => (
                                    <Box key={cat} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="body2" sx={{ color: '#FFD700', marginRight: '8px' }}>
                                            {cat}
                                        </Typography>
                                        <IconButton onClick={() => handleRemoveCategory(selectedRowData.uid, cat)} sx={{ color: '#fff' }}>
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                ))}
                            </Box>
                        )}

                    </DialogContent>
                </Dialog>
            )}

            <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            </Box> 
            </Box> 
            </Box> 
        </>
    );
};

export default MentorDetails;






// <Dialog open={selectedModalwindow} onClose={handleCloseSelectedWindow}>
// <DialogTitle>Remove Category</DialogTitle>
// <DialogContent>
//     <SelectOptionDialog />
// </DialogContent>
// <DialogActions>
//     <Button onClick={handleCloseSelectedWindow}>Cancel</Button>
//     <Button variant="contained" color="primary" onClick={handleCloseSelectedWindow}>
//         Confirm
//     </Button>
// </DialogActions>
// </Dialog>


