import React, { useEffect, useState } from 'react'
import "../style/MunApplicants.css"
import ViewListIcon from '@mui/icons-material/ViewList';
import GridOnIcon from '@mui/icons-material/GridOn';
import Box from '@mui/material/Box';
import { Avatar,Button } from "@mui/joy";
import { DataGrid } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useGlobalContext } from 'global/context';
import { apiJson, apiJsonAuth } from 'api';
import { Modal } from "react-bootstrap";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Select from "react-select"
import { toast } from 'react-toastify';
import MunGridParticipant from './MunGridParticipant';
import { ButtonGroup } from 'pages/ModelUnParliament';

export const MunParticipants = () => {
  const columns = [
    // ... (your existing columns)
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'blue-header',
      width: 125,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            size="sm"
            src={params.row?.avatarUrl ? params.row?.avatarUrl : '/modelUn/download3.png'}
            alt={params.row.name}
          />
          <div className='ms-2'><span className='text-wrap' style={{ fontFamily: "Lexend" }}>{params.row?.name} </span></div>
        </div>
      ),
      //   headerClassName: 'blue-header',
    },
    {
      field: 'Class',
      headerName: 'Class',
      width: 84,
      headerClassName: 'blue-header',
      renderCell: (params) => {
        const classInfo = params.row?.class ? JSON.parse(params.row.class) : null;
        const course = classInfo?.course || 'N/A';

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className='fw-400 fs-13-5px text-wrap' style={{ color: "#000000", fontFamily: "Lexend" }}>{course}</span>
          </div>
        );
      },

    },
    {
      field: 'age',
      headerName: 'Age',
      width: 74,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className='fw-400 fs-13-5px text-wrap' style={{ color: "#000000", fontFamily: "Lexend" }}>{params.row?.age}</span>
        </div>
      ),
      headerClassName: 'blue-header',

    },
    {
      field: 'gender',
      headerName: 'Gender',
      width: 96,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className='fw-400 fs-13-5px text-wrap' style={{ color: "#000000", fontFamily: "Lexend" }}>{params.row?.gender}</span>
        </div>
      ),
      headerClassName: 'blue-header',
    },
    {
      field: 'preferredComm',
      headerName: 'Committee',
      width: 180,
      renderCell: (params) => (
        <div>
          <span className='fw-400 fs-13-5px text-wrap text-center' style={{ color: "#000000", fontFamily: "Lexend" }}>{params.row?.preferredComm ? params.row?.preferredComm : "-"}</span>
        </div>
      ),
      headerClassName: 'blue-header',

    },
    {
      field: 'pref_designation',
      headerName: 'Designation',
      width: 180,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className='fw-400 fs-13-5px text-wrap' style={{ color: "#000000", fontFamily: "Lexend" }}>{params.row.pref_designation ? params.row?.pref_designation : "-"}</span>
        </div>
      ),
      headerClassName: 'blue-header',

    },
    {
      field: 'preferredRole',
      headerName: 'Country/Role',
      width: 180,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className='text-wrap fw-400 fs-13-5px text-wrap' style={{ color: "#000000", fontFamily: "Lexend" }}>{params.row?.preferredRole || params.row?.preferredCountry  ? params.row?.preferredRole 
          ||params.row?.preferredCountry  : "-"} </span>
        </div>
      ),
      headerClassName: 'blue-header',

    },
    {
      field: 'status',
      headerName: 'Status',
      width: 122,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className='fw-400 fs-13-5px text-wrap text-capitalize' style={{ color: "#000000", fontFamily: "Lexend" }}>{params?.row?.status==="approved" ? "Participant" : params?.row?.status==="rejected" ?  "Rejected"  : "Applicant"}</span>
        </div>
      ),
      headerClassName: 'blue-header',

    },
    {
      field: 'Actions',
      headerName: 'Actions',
      width: 92,
      renderCell: (params) => (
        <>
          <div style={{ display: 'flex', alignItems: 'center', height: "25px", cursor: "pointer", width: "25px" }}
            data-bs-toggle="dropdown">
            <MoreVertIcon sx={{ color: "#23538f", cursor: "pointer" }} className='h-100 w-100' />
          </div>

          {/*============ Action button is Define Here ==============*/}
          <div className="dropdown-menu border-0 rounded-3" style={{boxShadow: "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px"}}>
            <div className="d-flex flex-column">
             
              <span className="actionType">Add as a winner</span>
              {params?.row?.status !== "rejected" ? (
                <span className="actionType border-bottom-0" onClick={() => {
                  handleOpenReject(params?.row?.id, params?.row?.registerId)
                }}>Reject Applicant</span>) : null}

            </div>

          </div>
          {/*============ Action button is End Here ==============*/}

        </>

      ),
      headerClassName: 'blue-header',

    },
  ];

  const {userData} = useGlobalContext();
  const [isloading,setIsLoading] = useState(false);
  const [allApplicants,setAllApplicants] = useState([])
  const [getAllCommiteeList,setAllCommiteeList] = useState([])
  const [eventDetail, setEventDetail] = useState({})
  const [selectCom,setSelectCom] = useState([{
    value:"all",
    title:"All"
  }])
  const [discussionDetail,setDiscussionDetails]= useState([])
  const [filteredRows, setfilteredRows] = useState([])
  const [uniqueCommitteeTypes,setUniqueCommitteeTypes] = useState([])
  const [copiedLink, setCopiedLink] = useState(null);
  const [copyLink,setCopyLink] = useState(false)
  const [openDiscuss,setOpenDiscuss] = useState(false)
  const [isGridView, setIsGridView] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [getStudentId, setStudentId] = useState('');
  const [getRegisterId, setRegisterId] = useState('')
  const [rejectError,setRejectError] = useState(false)
  const [getReason,setReason] = useState("")
  const [RejectDec,setRejectDec] = useState("")
  const [value, setValue] =useState('all');

  const handleChange = ( event,newValue) => {
    setValue(newValue);
  };

  const toggleView = () => {
    setIsGridView(!isGridView);
  };
  let role = userData?.role
  let userId = userData?.id
  let instituteId = '';

  if (role === 'institute') {
      instituteId = userData.id;
  } else {
      instituteId = userData.instituteId;
  }
   // handle open reject modal open handle = \\
   const handleOpenReject = (studentId, registerId) => {
    setRejectModal(true);
    setStudentId(studentId);
    setRegisterId(registerId);

  }
   // ======= Handle Hide reject Modal handle ======= \\
   const hideRejectmodal = () => {
    setRejectModal(false);
  }
//=========  a function for open  discuss  modal =================\\
const handleOpenDiscuss = ()=>{
  setOpenDiscuss(true)
}
const handlehideDiscuss = ()=>{
  setOpenDiscuss(false)
}
// ============= Event Details Api  ==========
  const geteventDetail = ()=>{
    apiJson('api/v2/modelUn-student/getEventDetails/institute/'+userData?.id)
    .then((res)=>{
      setEventDetail(res?.data?.result)
     
    })
    .catch((error)=>{
      console.log(error.message)
    })
  }


 
  useEffect(()=>{
    setUniqueCommitteeTypes([...new Set(allApplicants?.filter(row => row?.is_participant===true && row?.committeeType !== null).map(participant => participant.committeeType ))])
  },[allApplicants])

  const getAllCommitee = async()=>{
    try {
      const getCommiteeList = await apiJsonAuth.get(`/api/v2/modelUn-student/getAllCommitteenew/${userData?.id}`)
      setAllCommiteeList(getCommiteeList?.data?.updatedAllDelegate)
     
    } catch (error) {
      console.log(error)
    }
  }
  
  useEffect(() => {
  if(userData?.id){
    geteventDetail()
    getAllCommitee();
  }
  }, [])
  useEffect(() => {
    setfilteredRows(value === "all" ? (allApplicants && allApplicants) : allApplicants?.filter(row => row?.preferredComm === value))
  }, [allApplicants,value])

 //=============== Student Reject  api handler ================= \\

 const HandleRejectStudent = async () => {
  try {
    if(getReason === ""){
      setRejectError(true)
      return
    }
    const assignRes = await apiJsonAuth.put(`/api/v2/modelUn-institute/rejectApplicant/student/${getStudentId}/register/${getRegisterId}`, { role:userData?.role,instituteId:userData?.id,rejectOption:getReason,RejectDec })
    if (assignRes?.status === 200) {
      toast.success(assignRes?.data?.message)
      getAllApplicants();
      setRejectModal(false)
    }
  } catch (error) {
    console.log(error, "Error in Secure nominate")
  }
}
// ============= Get all Applicants details  api ===============\\
const getAllApplicants=async()=>{
  try {
    setIsLoading(true)
    let applicants = await apiJsonAuth.get(`api/v2/modelUn-student/getAllStudentsDetailNew/${userData?.id}`)
    setAllApplicants(applicants?.data?.studentsParticipants?.filter(row => row.status === "approved" && row?.is_participant===true));
    const data = applicants?.data?.studentsParticipants?.filter(row => row.status === "approved" && row?.is_participant === true)?.map((i) => ({
      value: i?.preferredComm,
      title: i?.preferredComm
    })) || [];

    const uniqueValuesSet = new Set([...data.map(item => item.value), ...selectCom.map(item => item.value)]);
    const concatenatedArray = Array.from(uniqueValuesSet, value => ({
      value,
      title: value
    }));
    const indexAll = concatenatedArray.findIndex(item => item.value === "all");
    if (indexAll !== -1) {
      const allItem = concatenatedArray.splice(indexAll, 1)[0];
      concatenatedArray.unshift(allItem);
    }
    setSelectCom(concatenatedArray)
    setIsLoading(false)
  } catch (error) {
    console.log("Error on Applicants Api",error)
    setIsLoading(false)
  }
}
useEffect(()=>{
getAllApplicants();
// CommiteeUserDetails();
},[])
const checkAttendeeType = (prefComm,role)=>{
  if(prefComm==='Secretariat' && role ==="Jury Member"){
    return 'jury'
  }else if(prefComm==='Secretariat' && role !=="Jury Member"){
    return 'Sec'
  }else if(prefComm==='Press Corps'){
    return 'PressCorp'
  }else{
    return 'Delegate'
  }
}
  //========== A function for create discussion ===========\\
  const handleCreateDiscussion =async(item)=>{
    const committeeDiscussExist = discussionDetail?.find((i)=> i?.user_id === userData?.id && i?.committeeType===item)
   if(committeeDiscussExist?.id){
   toast.dismiss();
   toast.error(`You've already created the discussion for ${item}.`);
   setOpenDiscuss(false)
   return;
    }
    const  discussArray=allApplicants?.filter((res)=>res?.committeeType===item ||(res.preferredComm === "Secretariat" || res.preferredComm === "PressCorp") )?.map((i)=>{
     const data ={
      id: i?.studentId,
      role: i?.role,
      email:i?.email,
      name:i?.name,
      attend_type:checkAttendeeType(i?.preferredComm,i?.preferredRole)
     }
     return data

    })
    // console.log('discussArray==>',discussArray)
    let data = {
      discTitle: eventDetail?.event_venue || "",
      eventDate: eventDetail?.date_proposed || "",
      eventSTime: eventDetail?.event_time || "",
      eventETime:  "",
      event_id: eventDetail?.id || "",
      event_name:"event",
      type:"YMUN",
      committeeType:item,
      attendees: discussArray,
    }
    try {
      const res = await apiJson.post(`/api/v2/discussion_board/createDiscussion?instituteId=${instituteId}&role=${role}&userId=${userId}`, data);
      if (res) {
        toast.dismiss();
        toast.success("Discussion created.")
        getDiscussionDetails()
       
      }
  } catch (error) {
      // setSubmitState(false)
      toast.dismiss();
      toast.error("Server Error")
      console.log("Error", error)
  }
  }
   const getDiscussLink= (item)=>{
    setCopiedLink(item)
    const committeeDiscussExist = discussionDetail?.find((i)=> i?.user_id === userData?.id && i?.committeeType===item);
    if(committeeDiscussExist?.id){
      let link = `${process.env.REACT_APP_MAIN_URL}new-dashboard/discussion-chat-room/${committeeDiscussExist?.id}`
      const input = document.createElement('input');
      input.value = link;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      setCopyLink(true)
      setTimeout(()=>{
       setCopyLink(false)
       setOpenDiscuss(false)
      },3000)
    }else {

      toast.dismiss();
      toast.error("Please Create the discussion for link.")
      setOpenDiscuss(false)
      return
    }
    
  }
  const getDiscussionDetails = async()=>{
    try {
        const res = await apiJsonAuth.get(`/api/v2/discussion_board/discussion-list/${userData?.id}`)
        setDiscussionDetails(res?.data?.allDiscussion)
    } catch (error) {
      console.log(error?.message)
    }
  }
  useEffect(()=>{
    if(userData?.id){
      getDiscussionDetails()
    }
  },[])
  return (
    <>
      <div className="col-11 col-md-10 mx-auto mt-4">
        <div className="row">

          <div className="d-flex flex-column flex-md-row justify-content-between">
              <div>
                <span className="fs-32px-fw-600">Participants</span>
              </div>


            <div className="d-flex justify-content-between justify-content-md-end flex-row-reverse col-12 col-md-7 gap-0 gap-md-3">
              {/* <div className="col-12 col-md-4 d-flex justify-content-between align-items-center mb-3 mb-md-0">
                <div style={{ fontFamily: 'Lexend', wordWrap: 'break-word' }}>
                  <span className="fs-17-50px fw-400">Event</span>
                </div>
                <div>
                  <Select
                    styles={customStyles}
                  />
                </div>
              </div>


              <div className="col-12 col-md-4  d-flex justify-content-between align-items-center mb-3 mb-md-0">
                <div style={{ fontFamily: 'Lexend', wordWrap: 'break-word' }}>
                  <span className="fs-17-50px fw-400">Comm</span>
                </div>
                <div>
                  <Select
                    styles={customStyles}
                  />
                </div>
              </div> */}
             
              <div >
              <Button variant="soft" onClick={handleOpenDiscuss} >Create Discussion</Button>
              </div>

              <div className="d-flex align-items-center mb-3 mb-md-0">
                <div className="d-flex justify-content-evenly align-items-center cursor-pointer"
                  style={{ background: "#F9ECFF", borderRadius: "7px", width: "81px", height: "40px", padding:"8px 10px" }}
                >
                  <div className='' style={{ width: "40px" }} onClick={toggleView}>
                    <ViewListIcon sx={{ color: isGridView ? "#DBCFFF" : "#3D00FF", width: "28px", height: "28px" }} />
                  </div>
                  <div className='' style={{ width: "40px" }} onClick={toggleView}>
                    <GridOnIcon sx={{ color: isGridView ? "#3D00FF" : "#DBCFFF", width: "28px", height: "28px" }} />
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className='d-flex justify-content-between mt-3'>
            <div className='d-flex justify-content-between ms-md-3 col-12 col-md-5'>

            </div>

          </div>
        </div>
        {/* <hr className='mt-4'/> */}
        {isloading ? (<>
          <div className="d-flex justify-content-center my-4">
            <div className="spinner-border  text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </>):(
         isGridView ? (
              <>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" variant='scrollable' scrollButtons="auto">
                    {selectCom?.map((item)=>(
                      <Tab label={item?.title} value={item?.value}  key={item?.value}/>
                    ))}
                    
                    </TabList>
                  </Box> 
                <MunGridParticipant data={filteredRows} />
                </TabContext>
              </>
         ):(
        <Box sx={{ height: 461, width: '100%', marginTop: "15px" }}>
          <DataGrid
            rowHeight={70}
            disableColumnMenu
            rows={allApplicants}
            columns={columns}
            paginationMode="client"
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            disableSelectionOnClick
            disableRowSelectionOnClick

          // MuiDataGrid-cellCheckbox="cellCheckbox"
          />
        </Box>))}

      </div>

      {/* //======== Applicants Rejection  reason  Modal Start here ==========*/}
      <Modal
        size="lg"
        show={rejectModal}
        onHide={hideRejectmodal}
      >
        <div className="px-3 py-4">

        <div className="fs-6 fw-semibold" style={{ color: "#000000", marginBottom: "10px" }}>Reason of Reject Applicant</div>

        <Select
          options={reasons}
          isSearchable={false}
          onChange={(selectedOption)=>{
            setReason(selectedOption?.value)
            setRejectError(false)
          }}
          styles={{ ...customStyles, ...customSelectStyles }} getOptionLabel={
            (option) => {
              return (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className='fs-6 cursor-pointer fw-500' style={{ color: "#3A3A3A" }}>{option?.name}</div>
                  </div>
                </>
              )
            }
          }

        />
        {rejectError   &&  (
          <span className="text-danger fs-6">Please select a reason</span>
        )}
        {getReason ==="Any other (Please specify)" && (
          <>
          <label className="my-3 fs-6 fw-semibold" style={{ color: "#000000" }}>Please specify for reject applicant.</label>
          <textarea className="form-control rounded-3 shadow-none resize-none"  onChange={(e)=>setRejectDec(e?.target?.value)} placeholder="Write the reason." id="floatingTextarea" required></textarea>
          </>
        )}


          <ButtonGroup btnName="Submit" btnPrev="Cancel" handlePrev={hideRejectmodal} handleNext={HandleRejectStudent} />
        </div>
      </Modal>
      
      <Modal
        size="lg"
        show={openDiscuss}
        onHide={handlehideDiscuss}
      >

        <div className="px-3 py-4">
        <h2 className='fw-semibold fs-22px' > Create your Discussion particular commitee</h2>
        {uniqueCommitteeTypes.length>0 && uniqueCommitteeTypes?.map((item,index)=>{
          return (

        <div className='mt-3 d-flex justify-content-between' key={index}>
          <p className='fw-normal text-center'>Create discussion for {item}</p>
          <div className='d-flex '>
          <div className='' >
              <Button variant="soft" onClick={()=>handleCreateDiscussion(item)} className='me-3'  disabled = {discussionDetail?.some((i)=>i?.committeeType?.includes(item))}>Create Discussion</Button>
          </div>
          {copiedLink === item &&  copyLink ? (    
                <div >
                <Button variant="soft" color='success'>Copied Link</Button>
                </div> ):(
                <div >
                <Button variant="soft" color='warning' onClick={()=>getDiscussLink(item)} disabled = {discussionDetail?.some((i)=>{i?.committeeType?.includes(!item)
              
                })}>Get Link</Button>
                </div>)
              }
       </div>
        </div>
          )
        }) }
        </div>
      </Modal>
    </>
  )
}
const reasons = [
  { name: "Late application", value: "Late application" },
  { name: "Applicant wants preferred role only", value: "Applicant wants preferred role only" },
  { name: "Does not fulfil participation criteria", value: "Does not fulfil participation criteria" },
  { name: "Not available on the event day", value: "Not available on the event day" },
  { name: "Did not join pre-event activities", value: "Did not join pre-event activities" },
  { name: "Any other (Please specify)", value: "Any other (Please specify)" }
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "143px",
    height: "40px",
    padding: "3px",
    border: "none",
    background: "#F9ECFF",
    color: 'black'
    // boxShadow:'none'
  }),
};
//======== custom style for select Input Feild  =============\\
const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#F9F9F9",
    border: "none",
    outline: "none"
  }),
}