import React from 'react'

const MunGridParticipant = ({ data }) => {
    return (
        <>
            <div className="modelUN_comittees">
                <div className="d-flex flex-column flex-md-row align-items-center align-items-md-stretch ">
                    {data.map((ele) => {
                        return (
                            <>
                                <div className="py-4  " style={{ minWidth: "250px", width: "290px", maxWidth: "400px" }}>
                                    <div className="card margin-right-25px  h-100  px-6 py-2 border-0 shadow rounded-5" >
                                        <div className="card-body h-100 mx-auto d-flex px-0 py-0">
                                            <div className="h-100 w-100" >

                                                <div className="margin-top-11px d-flex justify-content-center">
                                                    <div style={{ objectFit: "cover", height: "98px", width: "98px", }}>
                                                        <img className="h-100 w-100" style={{ borderRadius: "64.5px", border: "4px solid rgb(239 201 10)" }}
                                                            src={ele?.avatarUrl ? ele?.avatarUrl : "'/modelUn/download3.png'"}
                                                            alt={ele?.name}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="degination d-flex justify-content-center align-items-center" style={{ background: "#FFE7AA", position: "relative", bottom: "8px" }} >
                                                    <span className="font-family-Lexend font-size-11px d-block text-center" style={{ color: "#7B4300" }} >
                                                        {ele?.role?.toUpperCase()}
                                                    </span>
                                                </div>
                                                <div className="text-center">
                                                    <p className="fs-6 font-family-Lexend fw-semibold text-center">
                                                        {ele?.name}
                                                    </p>
                                                </div>

                                            </div>

                                        </div>
                                        <div className='mb-3'>
                                            <div className='px-3'>
                                                <span className='fs-6 fw-semibold'>Committee :</span> <span style={{ fontSize: "14px" }}>{ele?.preferredComm}</span>
                                            </div>
                                            <div className='px-3'>
                                                <span className='fs-6 fw-semibold'>Designation :</span> <span style={{ fontSize: "14px" }}>{ele?.pref_designation}</span>
                                            </div>
                                            {ele?.preferredCountry && (
                                                <div className='px-3'>
                                                    <span className='fs-6 fw-semibold'>Country :</span> <span style={{ fontSize: "14px" }}>{ele?.preferredCountry}</span>
                                                </div>
                                            )}
                                            {ele?.preferredRole && (
                                                <div className='px-3' >
                                                    <span className='fs-6 fw-semibold'>Role:</span> <span style={{ fontSize: "14px" }}>{ele?.preferredRole}</span>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
        </>
    )
}

export default MunGridParticipant