import { MyContext } from "pages/EventTimeline/EventTimeline";
import React, { useContext} from "react";
import ShowAllClubComponent from "./ShowAllClubComponent";

const ShowAllClubsTimeLIne = () => {
  const { allClub, getAllClubs, allClubStudent, getAllStudentClub, privateClub, publicClub, allClubInstitute, getAllInstituteClub } = useContext(MyContext);

  return (
    <>
      <ShowAllClubComponent allClub={allClub} getAllClubs={getAllClubs} privateClub={privateClub} publicClub={publicClub} allClubInstitute={allClubInstitute} getAllInstituteClub={getAllInstituteClub} allClubStudent={allClubStudent} getAllStudentClub={getAllStudentClub} />
    </>
  );
};

export default ShowAllClubsTimeLIne;
