

import React from 'react';
import { Box, Typography } from '@mui/material';

const Loader = ({text}) => {
  return (
    <Box
      id="container"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh', 
        width: '100%', 
        
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: {md:'25rem' , xs:"15rem"},
          height: {md:'16rem' , xs:"12rem"},
          borderRadius:"1rem",
          display: 'flex',
          justifyContent: 'center',
          padding:{md:"8px" , xs:"12px"},
          alignItems: 'center',
          backgroundColor: '#fff', 
        }}
      >

        <Typography sx={{
          position : 'absolute',
          top:"4%",
          fontFamily:"poppins",
          fontWeight:"600",
          color:"#000000",
          fontSize:{md:"26px" , xs:"16px"}
        }}>
          {text}...
        </Typography>
        <Box
          id="circle"
          sx={{
            position: 'absolute',
            width:{md:'16rem' , xs:"10rem"},
            height: '14rem',
            overflow: 'auto',
          }}
        >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 300 300"
            enableBackground="new 0 0 300 300"
            xmlSpace="preserve"
            style={{
              position: 'absolute', 
              left: 0,
              top: "7%",
              width: '100%',
              height: '100%',
              animation: 'rotate 3.5s linear infinite reverse',
              color:'#2356FE'
            }}
          >
            <defs>
              <path
                id="circlePath"
                d="M 150, 150 m -100, 0 a 100,100 0 1,1 200,0 a 100,100 0 1,1 -200,0"
              />
            </defs>
            <circle cx="120" cy="110" r="120" fill="none" />
            <g>
              <use xlinkHref="#circlePath" fill="none" />
              <text fill="#2356FE" fontSize="18"  letterSpacing="2.5" >
    <textPath xlinkHref="#circlePath" >
    WORLD'S LARGEST YOUTH DEVELOPMENT PLATFORM  

     </textPath>     

     {/* <circle cx="120" cy="110" r="5" fill="#FFDD55" /> */}
     </text>

            </g>
          </svg>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            width:'165px',
            height: {md:'130px' , xs:"140px"},
            overflow: 'hidden',
            padding:{md:"2rem" , xs:"3rem"},
            top:{md:"31%", xs:"23%"}
          }}
        >
          <img
            src="/uploads/loaderLogo.png"
            alt="Center Image"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Box>
      </Box>

      <style>{`
        @keyframes rotate {
          from { transform: rotate(360deg); }
          to { transform: rotate(0deg); }
        }
      `}</style>
    </Box>
  );
};

export default Loader;
