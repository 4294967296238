import React, { useState, useEffect } from "react";
import {
  Box, Button, Typography, Snackbar, TablePagination
} from "@mui/material";
import axios from "axios";
import MaterialTable from '@material-table/core';
import SearchIcon from '@mui/icons-material/Search';
import { CustomPagination } from "../Sewa-Institute/ProjectDetails";
import { paddingLeft } from "@xstyled/styled-components";

const Marking = () => {
  const [projects, setProjects] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [marks, setMarks] = useState({}); 
  const [tempMarks, setTempMarks] = useState({}); 
  const [editStates, setEditStates] = useState({}); 

  const mentorId = '66f55670c72396ed3073406c';

  useEffect(() => {
    const fetchProjectMembers = async () => {
      try {
        const response = await axios.get(`http://localhost:3000/api/approval/project/assigned/${mentorId}`);
        if (response.data && response.data.length > 0) {
          const projectData = response.data.map(item => {
            const project = item.project;
            const members = item.members; 
            project.marks = members.map(member => member.marks);

            console.log("projects",project);

            return project;  
          });
          setProjects(projectData);
        } else {
          console.log('No projects found for this mentor.');
        }
      } catch (error) {
        console.error('Error fetching project members:', error);
      }
    };

    if (mentorId) {
      fetchProjectMembers();
    }
  }, [mentorId , marks]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
};
const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};

  const handleSave = async (projectId) => {
    try {
      await axios.put(`http://localhost:3000/api/approval/project/giveMarks/${projectId}`, { marks: tempMarks[projectId] });
      setOpenSnackbar(true);
      setSnackbarMessage("Marks updated successfully!");
      setSnackbarSeverity("success");

      setMarks((prevMarks) => ({
        ...prevMarks,
        [projectId]: tempMarks[projectId],
      }));

    } catch (error) {
      setOpenSnackbar(true);
      setSnackbarMessage("Failed to update marks. Please try again.");
      setSnackbarSeverity("error");
    }

    setEditStates((prevStates) => ({
      ...prevStates,
      [projectId]: false,
    }));
  };

  const handleEditToggle = (projectId) => {
    setEditStates((prevStates) => ({
      ...prevStates,
      [projectId]: !prevStates[projectId],
    }));
  };

  const handleMarksChange = (projectId, value) => {
    setTempMarks((prevMarks) => ({
      ...prevMarks,
      [projectId]: value,
    }));
  };

  const columns = [
    {
      title: "Project Id",
      field: "projectId",
      headerStyle: { textAlign: "center", padding: "1rem", fontWeight: "600", fontSize: "14px" },
      cellStyle: { textAlign: 'center', fontSize: "13px",paddingRight: "4rem", }
    },
    {
      title: "Project Name",
      field: "projectName",
      headerStyle: { textAlign: "center", fontWeight: "600", fontSize: "14px" },
      cellStyle: { textAlign: 'left', fontSize: "13px" }
    },
    {
      title: "category",
      field: "category",
      headerStyle: { textAlign: "center", fontWeight: "600", fontSize: "14px", paddingLeft: "30px" },
      cellStyle: { textAlign: 'left', fontSize: "13px", paddingLeft: "35px" }
    },
    {
      title: "marks",
      field: "marks",
      headerStyle: { textAlign: "center", fontWeight: "600", fontSize: "14px", paddingLeft:"30px" },
      cellStyle: { textAlign: 'left', fontSize: "13px", paddingLeft: "40px" },
      render: (rowData) =>  rowData.projectName && (
        <Typography>
        {rowData.members && rowData.members.length > 0 && rowData.members[0].marks !== undefined
          ? rowData.members[0].marks
          : "No marks"}
      </Typography>

      )
    },
    {
      title: "Action",
      field: "action",
      headerStyle: { textAlign: "center", fontWeight: "600", fontSize: "14px" , paddingLeft:"40px"},
      cellStyle: { textAlign: 'center' },
      render: (rowData) => rowData.projectName && (
        <Box sx={{ display: "flex", flexDirection:"column",gap: "0.5rem " , justifyContent:"center"
         }}>
          {editStates[rowData.projectId] ? (
            <>
              <input
                type="number"
                placeholder="marks..."
                style={{ padding: "4px", border: "1px solid #ccc", borderRadius: "5px", fontSize: "0.8rem" }}
                value={tempMarks[rowData.projectId] || ""}
                onChange={(e) => handleMarksChange(rowData.projectId, e.target.value)}
                autoFocus
              />
              <Button
                sx={{ padding: "2px 2px", fontWeight: "600", borderRadius: "5px", fontSize: "12px",
                }}
                variant="contained"
                onClick={() => handleSave(rowData.projectId)}
              >
                Save
              </Button>
            </>
          ) : (
            
<Box
sx={{
    display: "flex",
    gap: "5px",
    flexDirection:"row",
    justifyContent: "start",
    // flexWrap: "wrap",
}}
>
<Button
    sx={{
        padding: "1px",
        border: "2px solid #2356FE",
        fontWeight: "600",
        borderRadius: "5px",
        fontSize: "12px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#000000",
    }}
    variant="outlined"
    onClick={() => handleEditToggle(rowData.projectId)}
>
    Edit
</Button>

<Button
    sx={{
        padding: "4px 0.5rem",
        border: "2px solid #f44336",
        fontWeight: "600",
        borderRadius: "5px",
        fontSize: "0.8rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#000000",
    }}
    variant="outlined"
    color="error"
>
    Delete
</Button>
</Box>
          )}
        </Box>
      )
    }
  ];


    return (


        <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent:"center",
            marginTop:"2rem",           
         }}>
         
         
         
         <Typography
                     sx={{
                         fontFamily: 'Poppins',
                         fontSize: { xs: "12px", sm: "14px"  },
                         fontWeight: 400,
                         lineHeight: {md:'24px' , xs:"20px"},
                         textAlign: 'left',
                         color: "#575757",
                         marginBottom:"3rem",
                     }}
                 >
                     Mentors will review and accept incoming requests to guide and support mentees, ensuring a personalized and engaging mentorship experience.
                 </Typography>
         
         
         
                 <Box
                 sx={{
                   position: "relative", 
                   background: "#f8f8ff",
                   borderRadius: "1.5rem",
                   width: "100%",
                   display: "flex",          
                   flexDirection: {xl :"row", sm:"column" , md:"row" },
                   justifyContent:"center",
                   alignItems: "center",
                   boxSizing: "border-box",
                   ":before": {
                     content: '""', 
                     background: "linear-gradient(122.52deg, rgba(35, 86, 254, 0.7) 1.25%, rgba(35, 86, 254, 0.1) 98.44%)", 
                     borderRadius: "1.5rem",
                     position: "absolute",
                     top: 0,
                     bottom: 0,
                     right: 0,
                     left: 0,
                     zIndex: "1", 
                     margin: "-1rem",
                   }
                 }}
                 >
        <Box
            sx={{
                borderRadius: "1rem",
                position: "relative", 
                zIndex:"5",
                backgroundColor: "var(--color-ghostwhite)",
                boxSizing: "border-box",
                width: "100%",
                overflow: "auto",
            }}
        >
         
         <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 5,
  }}
>
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      padding: { xs: "1rem", sm: "1rem 1.5rem" }, 
      borderTopLeftRadius: "1rem",
      borderTopRightRadius: "1rem",
      position: "relative",
      backgroundColor: "#ffffff",
      zIndex: 5,
      flexDirection: { xs: "column", sm: "row" }, 
    }}
  >
    <Box sx={{ width: { xs: "100%", sm: "90%" } }}> 
      <Typography
        variant="h4"
        component="div"
        gutterBottom
        sx={{
          fontFamily: "Poppins",
          fontWeight: 700,
          textAlign: "left",
          position: "relative",
          zIndex: 5,
        }}
      >
        <span style={{ color: "blue", zIndex: 5 }}>Projects</span> Request
      </Typography>
      <Typography
        gutterBottom
        component="div"
        sx={{
          fontFamily: "Poppins",
          width:{md:"80%" , xs:"100%"},
          fontWeight: 400,
          lineHeight: "18.97px",
          fontSize: { xs: "10px", sm: "12px"  },
          textAlign: "left",
          color: "#6D6D6D",
          zIndex: 5,
        }}
      >
        Explore key details such as project name, description, mentor, assigned students, start date, and progress status for an overview of all ongoing projects.
      </Typography>
    </Box>

    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent:"flex-start",
        position: "relative",
        zIndex: 5,
      }}
    >
      <div
        style={{
          position: "relative",
          zIndex: 5,
          width: { xs: "100%", sm: "16rem"  }, 
          height: "2.5rem",
          backgroundColor: "#e7e7e7",
          borderRadius: "0.8rem",
          display: "flex",
          alignItems: "center",
          padding: "0 12px",
        }}
      >
        <SearchIcon style={{ color: "#ACACAC", marginRight: "8px" }} />
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            fontSize: { xs: "0.9rem", sm: "1rem" }, 
            color: "#ACACAC",
            padding: "0",
            zIndex: 5,
          }}
        />
      </div>
    </Box>
  </Box>
</Box>

 

<MaterialTable
        sx={{
            boxShadow: "none",
            width: "100%",
        }}
        options={{
            search: false,
            searchFieldStyle: {
                alignSelf: "center",
                width: '100%',
                fontSize: "0.9rem"
            },
            toolbar: false,
            showTitle: false,
            paging: false,
            headerStyle: {
                backgroundColor: '#F3F3F3',
                color: '#000000',
                padding: { xs: "0.5rem", sm: "1rem", md: "1.25rem" },
                fontSize: "0.8rem",
            },
            rowStyle: (rowData, index) => ({
                borderBottom: '1px solid #e0e0e0', 
                padding: '5px 0', 
                height:"60px"
            }),
        }}
        columns={columns}
        data={projects.length >= 5 ? projects : [...projects, ...Array(5 - projects.length).fill({})]}/>
            
           <Box
    sx={{
        display: 'flex',
        justifyContent: { xs: 'center', md: 'space-between' }, 
        alignItems: 'center',
        padding: '0.5rem 1rem',
        zIndex: "5",
        flexWrap: 'wrap', 
        gap: { xs: 2, md: 0 }, 
        backgroundColor:"#F3F3F3"   }}
>
    <TablePagination
        component="div"
        count={10}
        page={0}
        onPageChange={() => {}}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Rows per page"
        sx={{
            flexShrink: 0,
            '& .MuiTablePagination-toolbar': {
                paddingLeft: { xs: 0, md: '16px' }, 
            },
            '& .MuiTablePagination-selectLabel': {
                marginRight: 2,
            },
            '& .MuiSelect-select': {
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '6px 8px',
            },
        }}
        labelDisplayedRows={() => ''}
        slotProps={{
            actions: {
                nextButton: { sx: { display: 'none' } },
                backButton: { sx: { display: 'none' } },
                firstButton: { sx: { display: 'none' } },
                lastButton: { sx: { display: 'none' } },
            },
        }}
    />

    <CustomPagination
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        sx={{
            marginTop: { xs: '1rem', md: 0 }, 
            width: { xs: '100%', md: 'auto' }, 
        }}
    />
</Box>
        </Box>
        </Box>

        </Box>
    );
};

export default Marking;





    // const formik = useFormik({
    //     initialValues: {
    //         projectId: "",
    //         marks: [],
    //     },
    //     validationSchema,
    //     onSubmit: async (values) => {
    //         const updatedStudentDetails = students.map((student, index) => ({
    //             memberId: student.memberId,
    //             marks: values.marks[index] || 0,  
    //         }));
            
           
    //         console.log("Updated Students:", updatedStudentDetails);

    //         try {
    //             await axios.put(`http://localhost:3000/api/approval/project/giveMarks/${projectId}`, { marks: updatedStudentDetails });

    //             setOpenSnackbar(true);
    //             setSnackbarMessage("Marks updated successfully!");
    //             setSnackbarSeverity("success");
    //         } catch (error) {
    //             setOpenSnackbar(true);
    //             setSnackbarMessage("Failed to update marks. Please try again.");
    //             setSnackbarSeverity("error");
    //         }

    //         // Clear the form after submission
    //         formik.resetForm();
    //         setStudents([]);
    //         setSelectedProject(null);
    //     },
    // });

    // const handleCloseSnackbar = () => {
    //     setOpenSnackbar(false);
    // };



    // useEffect(async () => {
        // try {
            // const response = await axios.get(`http://localhost:3000/api/approval/project/members/${projectId}`);

            // console.log(response);
            // console.log("marking" ,response)
            // const members = response.data.members;
            // const project = response.data.project;
       
            // if (members) {
            //     setSelectedProject(project);
            //     // const projectName =  project.name ;

            //     const projectStudents = members.map(member => ({
            //         memberId: member._id,
            //         rollNo: member.rollNo,
            //         marks: member.marks || 0,
            //     }));
            //     setStudents(projectStudents);
            //     formik.setFieldValue("projectId", projectId);
            //     formik.setFieldValue("marks", projectStudents.map(student => student.marks));
            // } else {
            //     setSelectedProject(null);
            //     setStudents([]);
            //     formik.setFieldValue("projectId", projectId);
            //     formik.setFieldValue("marks", []);
            // }
        // } catch (error) {
            // console.error("Error fetching project details:", error);
            // setSelectedProject(null);
            // setStudents([]);
            // formik.setFieldValue("projectId", projectId);
            // formik.setFieldValue("marks", []);
        // }
    // });

    // useEffect(() => {
    //     if (formik.values.projectId) {
    //         // handleProjectSearch(formik.values.projectId);
    //     }
    // }, [formik.values.projectId]);

      // <Box
        //     sx={{
        //         padding: "2rem",
        //         boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
        //         borderRadius: "1rem",
        //         backgroundColor: "var(--color-ghostwhite)",
        //         border: "5px solid #2356fe",
        //         width: "100%",
        //         margin: "2rem auto",
        //         display: "flex",
        //         flexDirection: "column",
        //         gap: "1rem",
        //     }}
        // >
        //     <Typography variant="h4" sx={{ marginBottom: "1rem", textAlign: "center", color: "#2356fe" }}>
        //         STUDENT MARKING FORM
        //     </Typography>

        //     <Box sx={{ display: "flex", alignItems: "center", gap: "1rem", marginBottom: "1rem" }}>
        //         <InputLabel htmlFor="projectId" sx={{ flex: "1", color: "#2356fe" }}>Project ID</InputLabel>
        //         <FormControl fullWidth sx={{ flex: "3" }}>
        //             <TextField
        //                 id="projectId"
        //                 name="projectId"
        //                 label="Enter Project ID"
        //                 value={formik.values.projectId}
        //                 onChange={(e) => formik.setFieldValue("projectId", e.target.value)}
        //                 onBlur={formik.handleBlur}
        //                 error={formik.touched.projectId && Boolean(formik.errors.projectId)}
        //                 helperText={formik.touched.projectId && formik.errors.projectId}
        //                 sx={{
        //                     borderColor: "#2356fe",
        //                     '& .MuiOutlinedInput-root': {
        //                         '& fieldset': {
        //                             borderColor: "#2356fe",
        //                         },
        //                         '&:hover fieldset': {
        //                             borderColor: "#2356fe",
        //                         },
        //                         '&.Mui-focused fieldset': {
        //                             borderColor: "#2356fe",
        //                         },
        //                     },
        //                 }}
        //             />
        //         </FormControl>
        //     </Box>

        //     {selectedProject && (
        //         <form onSubmit={formik.handleSubmit}>
        //             <Box sx={{ marginBottom: "1rem" }}>
        //                 <TextField
        //                     fullWidth
        //                     label="Project Name"
        //                     value={selectedProject.projectName}
        //                     disabled
        //                     sx={{
        //                         marginBottom: "1rem",
        //                         '& .MuiOutlinedInput-root': {
        //                             '& fieldset': {
        //                                 borderColor: "#2356fe",
        //                             },
        //                         },
        //                     }}
        //                 />
        //                 <TextField
        //                     fullWidth
        //                     label="Category"
        //                     value={selectedProject.category}
        //                     disabled
        //                     sx={{
        //                         marginBottom: "1rem",
        //                         '& .MuiOutlinedInput-root': {
        //                             '& fieldset': {
        //                                 borderColor: "#2356fe",
        //                             },
        //                         },
        //                     }}
        //                 />
        //             </Box>

        //             {students.map((student, index) => (
        //                 <Box key={student.memberId} sx={{ display: "flex", alignItems: "center", gap: "1rem", marginBottom: "1rem" }}>
        //                     <TextField
        //                         fullWidth
        //                         label={`Roll No ${index + 1}`}
        //                         value={student.rollNo}
        //                         disabled
        //                         sx={{
        //                             flex: "3",
        //                             '& .MuiOutlinedInput-root': {
        //                                 '& fieldset': {
        //                                     borderColor: "#2356fe",
        //                                 },
        //                             },
        //                         }}
        //                     />
        //                     <TextField
        //                         fullWidth
        //                         name={`marks[${index}]`}
        //                         label="Marks"
        //                         value={formik.values.marks[index]}
        //                         onChange={formik.handleChange}
        //                         onBlur={formik.handleBlur}
        //                         error={formik.touched.marks?.[index] && Boolean(formik.errors.marks?.[index])}
        //                         helperText={formik.touched.marks?.[index] && formik.errors.marks?.[index]}
        //                         sx={{
        //                             flex: "1",
        //                             '& .MuiOutlinedInput-root': {
        //                                 '& fieldset': {
        //                                     borderColor: "#2356fe",
        //                                 },
        //                                 '&:hover fieldset': {
        //                                     borderColor: "#2356fe",
        //                                 },
        //                                 '&.Mui-focused fieldset': {
        //                                     borderColor: "#2356fe",
        //                                 },
        //                             },
        //                         }}
        //                     />
        //                 </Box>
        //             ))}

        //             <Box sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
        //                 <Button
        //                     color="primary"
        //                     variant="contained"
        //                     type="submit"
        //                     disabled={!formik.isValid || !formik.dirty}
        //                     sx={{
        //                         padding: "12px 1.5rem",
        //                         backgroundColor: "#2356FE",
        //                         border: "none",
        //                         color: "#FFFFFF",
        //                         fontSize: "16px",
        //                         fontWeight: "600",
        //                         borderRadius: "10px",
        //                     }}
        //                 >
        //                     SUBMIT MARKS
        //                 </Button>
        //                 <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
        //                     <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
        //                     {snackbarMessage}
        //                     </MuiAlert>
        //                 </Snackbar>
        //             </Box>
        //         </form>
        //     )}
        // </Box>
