import { TextField } from '@mui/material'
import { apiJson } from 'api'
import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const AuthV2PassReset = () => {
    const location = useLocation()
    const email = new URLSearchParams(location.search).get('email')
    const navigate = useNavigate()
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setconfirmPassword] = useState('')
    const [showEyeIconNew, setShowEyeIconNew] = useState(false)
    const [showEyeIconConfirm, setShowEyeIconConfirm] = useState(false)

    const handleEyeToogleNew = () => {
        setShowEyeIconNew(!showEyeIconNew)
    }
    const handleEyeToogleConfirm = () => {
        setShowEyeIconConfirm(!showEyeIconConfirm)
    }

    const updatePass = () => {
        if (!confirmPassword || !newPassword) {
            toast.dismiss()
            toast.warning('Please fill all the fields');
        }
        else if (confirmPassword === newPassword) {
            apiJson.put(`v2/auth/v2-submit-reset-password-email/${email}/${newPassword}`)
                .then((res) => {
                    navigate('/login')
                }).catch((error) => {
                    console.log(error.message)
                })
        } else if (confirmPassword !== newPassword) {
            toast.dismiss()
            toast.warning('Password Mismatch')
        }
        else {
            toast.dismiss();
            toast.error('Interval server error')
        }
    }
    return (
        <>
            <div className='vh-100 d-flex flex-column align-items-center'>
                <div className='mb-3 mt-3'>
                    <h3 className='fs-2 fw-semibold text-center'>Reset Your Password Here</h3>
                </div>
                <Card className='my-3 mx-2 col-12 col-md-6 text-center'>

                    <Card.Body>
                        <div className='mx-auto'>

                            <div className='mb-5 d-flex align-items-center justify-content-center'>
                                <TextField
                                    id="outlined-password-inpu"
                                    label=" New Password"
                                    // type="password"
                                    type={showEyeIconNew ? "text" : "password"}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            document.getElementById('outlined-password-input').focus();
                                        }
                                    }}
                                />
                                {
                                    showEyeIconNew ? (<div className='pe-2'><VisibilityIcon className='cursor-pointer' onClick={handleEyeToogleNew} /></div>) : (<div className='pe-2'><VisibilityOffIcon className='cursor-pointer' onClick={handleEyeToogleNew} /></div>)
                                }
                            </div>
                            <div className='d-flex align-items-center justify-content-center mb-4'>
                                <TextField
                                    id="outlined-password-input"
                                    label="Re-Write New Password"
                                    type={showEyeIconConfirm ? 'text' : 'password'}
                                    onChange={(e) => setconfirmPassword(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.type === 'click') {
                                            updatePass()
                                        }
                                    }}

                                />
                                {
                                    showEyeIconConfirm ? (<div className='pe-2'><VisibilityIcon className='cursor-pointer' onClick={handleEyeToogleConfirm} /></div>) : (<div className='pe-2'><VisibilityOffIcon className='cursor-pointer' onClick={handleEyeToogleConfirm} /></div>)
                                }
                            </div>
                            <div className='d-flex justify-content-around'>
                                <button onClick={() => navigate('/login')} className='btn btn-outline-danger rounded-2'>Cancel</button>
                                <button onClick={updatePass} className='btn btn-outline-success rounded-2'>Submit</button>
                            </div>

                        </div>
                    </Card.Body>

                </Card>
            </div>
        </>
    )
}

export default AuthV2PassReset
