import React, { useContext, useEffect, useState } from "react";
import OnBoardContext from "pages/OnBoarding/context/onBoardContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useGlobalContext } from "global/context";
import { apiJson } from "api";

export const Step0 = () => {
  const { preUser, userData } = useGlobalContext();
  
  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    studentFormik,
    studentOnBoardingData,
    setStudenteData,
  } = useContext(OnBoardContext);
  const [loading, setLoading] = useState(false);
  const [dob, setDob] = useState("2013-01-01");

  //========= A function to get student or teacher data if user does not complete the onboarding process and it come the fisrt step  ========

  const getUserOnboardingData = async () => {
    setLoading(true);
    try {
      const data = await apiJson.get(
        `/v2/register/getStudentOnboardingData/${userData?.email}/type/${userData?.role}`
      );
      if (data?.status === 200) {
        setLoading(false);
        const result = data?.data?.onBoardingData;
        studentFormik?.setValues({
          institution_name: result?.institution_name || "",
          email: userData?.email || result?.email || "",
          institute_id: result?.institute_id || "",
          activities: result?.activities || "",
          experience: result?.experience || "",
          interests: result?.interests || "",
          curriculum: result?.curriculum || "",
          achievements: result?.achievements || "",
          profile: result?.profile || "",
          website: result?.website || "",
          qualification: result?.qualification || "",
          specialization: result?.AssignmentIcon || "",
          skills: result?.skills || "",
          country: result?.country || "India",
          facebook_acc: result?.facebook_acc || "",
          twitter_acc: result?.twitter_acc || "",
          linkedin_acc: result?.linkedin_acc || "",
          insta_acc: result?.insta_acc || "",
          youtube_acc: result?.youtube_acc || "",
          first_name: result?.first_name || "",
          last_name: result?.last_name || "",
          gender: result?.gender || "",
          phone: result?.phone || "",
          date_of_birth: result?.date_of_birth || "",
          student_verification: result?.student_verification || false,
          father_name: result?.father_name || "", 
          mother_name: result?.mother_name || "", 
          address: result?.address || "", 
        });
        formik.setValues({
          firstName: result?.first_name || "",
          lastName: result?.last_name || "",
          phone: result?.phone || "",
          dob: result?.date_of_birth || "",
          gender: result?.gender || "",
          fatherName: result?.father_name || "",
          motherName: result?.mother_name || "",
          address: result?.address || "",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (studentOnBoardingData?.first_name === "") {
      getUserOnboardingData();
    }
  }, []);
  const handleDateChange = (event) => {
    setDob(event.target.value);
    formik.setFieldValue("dob", event.target.value);
    setStudenteData("date_of_birth", event.target.value);
  };

  const handleNextChild = () => {
    setActiveChildStep(activeChildStep + 1);

    if (activeChildStep >= stepperArray[count]?.childStep?.length - 1) {
      setActiveStep(activeStep + 1);
      setCount(count + 1);
      setActiveChildStep(0);
      if (activeStep > stepperArray.length - 2) {
        setCount(0);
        setActiveStep(0);
        setActiveChildStep(0);
      }
    }
    // if(activeStep>=stepperArray?.length-1){
    //   console.log("active step ", activeStep,"stepperArraydddddddddddddd", stepperArray.length)
    //   setActiveStep(0)
    //   setCount(0)
    // }
  };

  const nameExtrctor = (fullName) => {
    const nameArray = fullName.split(" ");
    const firstName = nameArray[0];
    setStudenteData("first_name", firstName || "");
    formik.setFieldValue("firstName", firstName || "");
    const lastName = nameArray.slice(1).join(" ");
    setStudenteData("last_name", lastName || "");
    formik.setFieldValue("lastName", lastName || "");
  };
  useEffect(() => {
    if (preUser?.name) {
      nameExtrctor(preUser?.name);
    }
    if (preUser?.phone) {
      setStudenteData("phone", preUser?.phone);
      formik.setFieldValue("phone", preUser?.phone);
    }
  }, [preUser?.name]);
  const validation = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    phone: Yup.number()
      .required("Required")
      .test("is-ten-digits", "Phone number must be 10 digits", (value) => {
        return String(value).length === 10;
      }),
    dob: Yup.string().required("Required"),
  gender: Yup.string().required("Required"),
  fatherName: Yup.string().when([], {
    is: () => userData.role === 'student',
    then: Yup.string().required('Required'),
  }),
  motherName: Yup.string().when([], {
    is: () => userData.role === 'student',
    then: Yup.string().required('Required'),
  }),
  address: Yup.string().when([], {
    is: () => userData.role === 'student',
    then: Yup.string().required('Required'),
  }),
    });
    const initialValues = {
    firstName: studentFormik.values
      ? studentFormik.values.first_name || ""
      : "",
    lastName: studentFormik.values ? studentFormik.values.last_name || "" : "",
    phone: studentFormik.values ? studentFormik.values.phone || "" : "",
    dob: studentFormik.values ? studentFormik.values.date_of_birth || "" : "",
    gender: studentFormik.values ? studentFormik.values.gender || "" : "",
    fatherName: studentFormik.values ? studentFormik.values.father_name || "" : "",
    motherName: studentFormik.values ? studentFormik.values.mother_name || "" : "",
    address: studentFormik.values ? studentFormik.values.address || "" : "",
  };

  useEffect(() => {
    formik.setValues({
      firstName: studentFormik.values
        ? studentFormik.values.first_name || ""
        : "",
      lastName: studentFormik.values
        ? studentFormik.values.last_name || ""
        : "",
      phone: studentFormik.values ? studentFormik.values.phone || "" : "",
      dob: studentFormik.values ? studentFormik.values.date_of_birth || "" : "",
      gender: studentFormik.values ? studentFormik.values.gender || "" : "",
      fatherName: studentFormik.values ? studentFormik.values.father_name || "" : "",
      motherName: studentFormik.values ? studentFormik.values.mother_name || "" : "",
      address: studentFormik.values ? studentFormik.values.address || "" : "",
    });
  }, [studentFormik.values]);

  function copyValues() {
    const fieldMappings = {
      firstName: "first_name",
      lastName: "last_name",
      phone: "phone",
      dob: "date_of_birth",
      gender: "gender",
    };
    const updatedValues = { ...studentFormik.values };

    for (const formikKey in fieldMappings) {
      const studentFormikKey = fieldMappings[formikKey];
      updatedValues[studentFormikKey] = formik.values[formikKey];
    }

    studentFormik.setValues(updatedValues);
  }
  const onSubmit = () => {
    copyValues();

    console.log();
    handleNextChild();
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit,
  });

  return (
    <>
      {loading && (
        <div className="google-verify-loader-overlay">
          <div className="google-verify-loader"></div>
        </div>
      )}
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-2 text-start text-lg-start">
          <span className=" fs-1 fw-600">About You</span>
        </div>
        <div>
          <div className="mb-50 mb-20 text-start text-lg-start">
            <span className="fs-6 fw-500 color-grey">
              Some detail about you
            </span>
          </div>
        </div>
        <div className="row mb-2">
          <div class=" mb-4 col-12 col-md-6">
            <span className="fs-5 mb-1 d-block">First Name</span>
            <input
              value={formik.values.firstName}
              type="text"
              class="form-control border-0 background-grey rounded-3 box-shadow-0"
              placeholder="First Name"
              onChange={(e) => {
                setStudenteData("first_name", e.target.value);
                formik.setFieldValue("firstName", e.target.value);
              }}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <span className="text-danger">{formik.errors.firstName}</span>
            )}
          </div>
          <div class="mb-4 col-12 col-md-6">
            <span className="onboard-form-span mb-1 d-block">Last Name</span>
            <input
              value={formik.values.lastName}
              type="text"
              class="form-control  border-0 background-grey rounded-3 box-shadow-0"
              placeholder="Last Name"
              onChange={(e) => {
                setStudenteData("last_name", e.target.value);
                formik.setFieldValue("lastName", e.target.value);
              }}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <span className="text-danger">{formik.errors.lastName}</span>
            )}
          </div>
          <div class="mb-4 col-12 col-md-6">
            <span className="onboard-form-span mb-1 d-block">Phone Number</span>
            <div class="d-flex background-grey">
              <select class="d-none d-md-block form-select  rounded-3 background-grey border-0 box-shadow-0 w-auto me-2">
                <option value="+91">+91</option>
                <option value="+1">+1</option>
              </select>
              <input
                value={formik.values.phone}
                type="number"
                class="form-control rounded-3 background-grey  box-shadow-0 border-0 box-shadow-0"
                placeholder="99*****99"
                onChange={(e) => {
                  setStudenteData("phone", e.target.value);
                  formik.setFieldValue("phone", e.target.value);
                }}
              />
            </div>
            {formik.touched.phone && formik.errors.phone && (
              <span className="text-danger">{formik.errors.phone}</span>
            )}
          </div>
          <div class="mb-4 col-12 col-md-6">
            <span className="onboard-form-span mb-1 d-block">
              Date of Birth
            </span>
            <input
              value={formik.values.dob}
              type="date"
              className="form-control background-grey border-0 rounded-3 box-shadow-0"
              placeholder="DOB"
              onChange={handleDateChange}
            />
            {formik.touched.dob && formik.errors.dob && (
              <span className="text-danger">{formik.errors.dob}</span>
            )}
          </div>
        </div>

        {/* =========================add parents field ========================== */}
        {  
          userData.role === "student"? (
            <div className="row" >
        <div className="mb-4 col-12 col-md-6">
            <span className="onboard-form-span mb-1 d-block">
              Father Name
            </span>
            <input
              value={formik.values.fatherName}
              type="text"
              className="form-control background-grey border-0 rounded-3 box-shadow-0"
              placeholder="Father Name"
              onChange={(e)=>{
                setStudenteData("father_name", e.target.value);
                formik.setFieldValue("fatherName", e.target.value);
              }}
            />
              {formik.touched.fatherName && formik.errors.fatherName && (
              <span className="text-danger">{formik.errors.fatherName}</span>
            )}
          </div>
        <div class="mb-4 col-12 col-md-6">
            <span className="onboard-form-span mb-1 d-block">
              Mother Name
            </span>
            <input
              value={formik.values.motherName}
              type="text"
              className="form-control background-grey border-0 rounded-3 box-shadow-0"
              placeholder="Enter Your Mother Name"
              onChange={(e)=>{
                setStudenteData("mother_name", e.target.value);
                formik.setFieldValue("motherName", e.target.value);
              }}
            />
            {formik.touched.motherName && formik.errors.motherName && (
              <span className="text-danger">{formik.errors.motherName}</span>
            )}
          </div>

        </div>
          ) : null
        }


        <div className="mb-4">
          <div className="mb-4">
            <span className="onboard-form-span mb-1 d-block">Gender</span>
          </div>
          <div className="d-flex">
            <div class="form-check me-4">
              <input
                class="form-check-input "
                type="radio"
                name="gender"
                id="Male"
                checked={formik.values.gender === "male"}
                onChange={(e) => {
                  setStudenteData("gender", "male");
                  formik.setFieldValue("gender", "male");
                }}
              />
              <label class="form-check-label" for="Male">
                Male
              </label>
            </div>
            <div class="form-check me-4">
              <input
                class="form-check-input"
                type="radio"
                name="gender"
                id="Female"
                checked={formik.values.gender === "female"}
                onChange={(e) => {
                  setStudenteData("gender", "female");
                  formik.setFieldValue("gender", "female");
                }}
              />
              <label class="form-check-label" for="Female">
                Female
              </label>
            </div>
            <div class="form-check me-4">
              <input
                class="form-check-input"
                type="radio"
                name="gender"
                id="Other"
                checked={formik.values.gender === "other"}
                onChange={(e) => {
                  setStudenteData("gender", "other");
                  formik.setFieldValue("gender", "other");
                }}
              />
              <label class="form-check-label" for="Other">
                Other
              </label>
            </div>
          </div>
          {formik.touched.gender && formik.errors.gender && (
            <span className="text-danger">{formik.errors.gender}</span>
          )}
        </div>

          {/* ========================add address field ======================== */}

          {
            userData.role === "student"? (
              <div className="mb-4 row">
          <div className="mb-4 col-12">
            <span className="onboard-form-span mb-1 d-block">Address</span>
          </div>
          <div className="d-flex ">
            <div class="me-4 w-100">
            <input
              value={formik.values.address}
              type="text"
              className="form-control background-grey border-0 rounded-3 box-shadow-0 w-100"
              placeholder="Enter Your Address"
              onChange={(e)=>{
                setStudenteData("address", e.target.value);
                formik.setFieldValue("address", e.target.value);
              }}
            />
            {formik.touched.address && formik.errors.address && (
              <span className="text-danger">{formik.errors.address}</span>
            )}
            </div>
          </div>
        </div>
            ):null
          }


        <div>
          <div className="d-flex p-2 rounded-3 border-1 border-purple background-purple">
            <span className="me-2">
              <AssignmentIcon sx={{ color: "#28007d" }} />
            </span>
            <span className="fw-500 fs-7 color-purple">
              It’s important that all the details provided by should match with
              institute’s owned records.
            </span>
          </div>
        </div>

        <div className="d-flex justify-content-between mt-4">
          <button className="btn-onboard"></button>

          <button type="submit" className="btn-onboard-fill">
            Next Step
            <span className="ms-4">
              <img src="./images/onBoarding/right-icon.png" />
            </span>
          </button>
        </div>
      </form>
    </>
  );
};
