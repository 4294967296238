import React from 'react'
import { DiscussionProvider } from '../discussionBoardContext/discussionContext'
import StudentCreateDiscussion from '../StudentCreateDiscussion'

const ParantStudentCreateDiscussion = () => {
  return (
    <>
    <DiscussionProvider>
        <StudentCreateDiscussion/>
    </DiscussionProvider>
    </>
  )
}

export default ParantStudentCreateDiscussion