import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    MenuItem,
    FormControl,
    Checkbox,
    FormControlLabel,
    Box,
    Typography,
} from '@mui/material';
import { useSubAdminCSV } from 'contexts/SubAdminCSVContext';

const HeaderMapping = () => {
    const { mappedHeaders = [], requiredColumns = [], updateHeaderMapping, validationErrors = [] } = useSubAdminCSV();

    return (
        <Box>
            {validationErrors.length > 0 && (
                <Box my={4}>
                    <Typography color="error">
                        The following required fields are missing: {validationErrors.join(', ')}
                    </Typography>
                </Box>
            )}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '20%', backgroundColor: '#2356FE', fontWeight: 'bold', color: 'white' }}>Found Header</TableCell>
                            <TableCell sx={{ width: '40%', backgroundColor: '#2356FE', fontWeight: 'bold', color: 'white' }}>Sample Data</TableCell>
                            <TableCell sx={{ width: '20%', backgroundColor: '#2356FE', fontWeight: 'bold', color: 'white' }}>Mapped To</TableCell>
                            <TableCell sx={{ width: '20%', backgroundColor: '#2356FE', fontWeight: 'bold', color: 'white' }}>Include</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mappedHeaders?.length > 0 ? (
                            mappedHeaders.map((header, index) => (
                                <TableRow key={index}>
                                    <TableCell>{header?.header}</TableCell>
                                    <TableCell>
                                        <Box display="flex" flexWrap="wrap">
                                            {header?.sampleData?.map((data, idx) => (
                                                <Box
                                                    key={idx}
                                                    sx={{
                                                        m: 0.5,
                                                        padding: '4px 8px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        backgroundColor: '#f0f0f0',
                                                    }}
                                                >
                                                    {data || "No Data"}
                                                </Box>
                                            ))}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <FormControl fullWidth>
                                            <Select
                                                value={header?.destination || ""}
                                                onChange={(e) => updateHeaderMapping(index, e.target.value)}
                                            >
                                                <MenuItem value="">None</MenuItem>
                                                {requiredColumns.map((column) => (
                                                    <MenuItem key={column?.key} value={column?.key}>
                                                        {column?.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={header?.include}
                                                    disabled={true}
                                                />
                                            }
                                            label="Include"
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4}>No headers found to map.</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default HeaderMapping;
