import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../Css/challengeday.css";
import groupimg from "../assests/Group 381.svg";
import seventyfiveimg from "../assests/seventyfive.png";
import { apiJson } from "api";
import SeventyCreditPoints from "./SeventyCreditPoints";
import { ThreeDots } from "react-loader-spinner";

const SeventyFiveChallengeDays = () => {
  const navigate = useNavigate();
  const params = useParams()
  const [todayStepsData, setTodayStepsData] = useState([])
  const [pastStepsData, setPastStepsData] = useState([])
  const [daycount, setDayCount] = useState();
  const [loader, setLoader] = useState(false)

  const getTodaySteps = async ()=>{
    setLoader(true)
    const response = await apiJson(`/api/v2/75daysChallenge/todaysChallenge/${params?.attemptId}`)
    setTodayStepsData([response?.data?.result])
    setDayCount(response?.data?.result?.day_count)
    setLoader(false)
  }
  const getPastSteps = async ()=>{
    setLoader(true)
    const response = await apiJson(`/api/v2/75daysChallenge/pastChallenge/${params?.attemptId}`)
    setPastStepsData(response?.data?.result)
    setLoader(false)
  }

  useEffect(()=>{
    getTodaySteps()
    getPastSteps()
  },[])

  return (
    <>
      <div className="challengeDays p-3">
        <div className="challengehead d-flex justify-content-between align-items-center">
          <img
            src={seventyfiveimg}
            alt="environment"
            className="sm-w-30"
          />
        <SeventyCreditPoints />
        </div>

        <div className="todayChallenge">
          <div className="d-flex justify-content-between align-items-center">

          <h4 className="my-3 sm-fs-18px">Today’s Step to Save Energy ({daycount}/75)</h4>
          {/* <button className="step border-0 text-white">Complete a Step</button> */}
          </div>

            {
              loader? <div className="d-flex justify-content-center align-items-center"><ThreeDots /></div> : todayStepsData?.map((val) => {
                return (
                  <>
                  <div
            className="challnDays d-flex justify-content-between align-items-center"
            onClick={() => navigate(`/steps/seventy-five-steps/today-steps/${params.attemptId}/todaytask/${val.id}`)}
          >
                    <div className="dayVal text-white"> Step {val?.day_count}</div>
                    <div className='daypara ps-3'>
                      <div className="align-align-self-lg-stretch">
                        <p className={`${val?.status !== null? 'text-success':null}`}>{val?.status === null?  'Incompleted' : 'Completed'}</p>
                        <p>{val?.description}</p>
                      </div>
                      <div className="daycredits">
                        <p>{val?.credit} Credit</p>
                      </div>
                    </div>
          </div>
                  </>
                );
            })
            }

        </div>
        {/* ===============================past steps ================================== */}
        <div className="pastChallenge">
          <h4 className="my-5">Past Steps</h4>
          {
            loader? <div className="d-flex justify-content-center align-items-center"><ThreeDots /></div> :pastStepsData?.map((val) => {
              return (
                <div
                  className="challnDays d-flex justify-content-between align-items-center my-4 "
                  onClick={()=> navigate(`/steps/seventy-five-steps/today-steps/${params.attemptId}/todaytask/${val.id}`)}
                >
                  <>
                    <div className="dayVal text-white"> Step {val?.day_count}</div>
                    <div className="daypara ps-3">
                    <div className="align-align-self-lg-stretch">
                      <p className={`${val?.status !== null? 'text-success':null}`}>{val?.status === null? 'Incompleted' : 'Completed'}</p>
                      <p>{val?.description}</p>
                    </div>
                    <div className="daycredits">
                      <p>{val?.total_credit} Credit</p>
                    </div>
                    </div>
                  </>
                </div>
              );
          })
          }
        </div>
      </div>
    </>
  );
};

export default SeventyFiveChallengeDays;
