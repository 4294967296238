import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, TablePagination, Typography } from '@mui/material';
import MaterialTable from '@material-table/core';
import SearchIcon from '@mui/icons-material/Search';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { CustomPagination } from '../Sewa-Institute/ProjectDetails';
import RemoveIcon from '@mui/icons-material/Remove';
import { fontSize, fontWeight, marginRight, paddingLeft } from '@xstyled/styled-components';

const ProjectRequests = () => {
    const [projects, setProjects] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('http://localhost:3000/api/approval/projects/mentor/66f55670c72396ed3073406c');
                console.log(response);
                setProjects(response.data.projects); 
            } catch (error) {
                console.error("Error fetching the project data:", error);
            }
        };

        fetchData();
    }, []);
 
    const handleStatusChange = async (projectId, status) => {
        try {
            const url = `http://localhost:3000/api/approval/appliedProject/${projectId}`;
            // console.log("Sending status update:", { status }); 
            const response = await axios.patch(url, { status });
            
            if (response.status === 200) {
                setProjects(prevProjects =>
                    prevProjects.map(project =>
                        project._id === projectId ? { ...project, status } : project
                    )
                );
                console.log(`Project ${status}:`, projectId);
            }
        } catch (error) {
            console.error(`Error updating the project status to ${status}:`, error);
        }
    };
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const columns = [
        {
            title: "Project Name",
            field: "projectName",
            headerStyle: {
                textAlign: "center",
                color:"#000000",
                fontWeight:"600",
                fontSize:"14px"
            },
            cellStyle: {
                textAlign: 'left',
                  color:"#000000",
                fontFamily:"Poppins",
                fontSize:"13px"
            }
        },
        {
            title: "Project Category",
            field: "category",
            headerStyle: {
                textAlign: "center",
                color:"#000000",
                fontWeight:"600",
                fontSize:"14px",
                fontFamily:"Poppins"
                        },
            cellStyle: {
                textAlign: 'center',
                paddingRight:"30px",
                  color:"#000000",
                fontFamily:"Poppins",
                fontSize:"13px"


            }
        },
        {
            title: "Participation Type",
            field: "participationType",

            headerStyle: {
                textAlign: "center",
                color:"#000000",
                fontWeight:"600",
                fontSize:"14px"
                        },
            cellStyle: {
                textAlign: 'center',
                paddingRight:"46px",
                color:"#000000",
                fontFamily:"Poppins",
                fontSize:"13px"

            }
        },
        {
            title: "Status",
            field: "status",
            headerStyle: {
                textAlign: "center",
                color:"#000000",
                paddingLeft:"45px",
                fontWeight:"600",
                fontSize:"14px"
                        },
            cellStyle: {
                textAlign: 'center',
                fontSize:"13px"


            },
            render: rowData => (
                rowData.status ? ( 
                    <button
                        style={{
                            backgroundColor:
                                rowData.status === "Pending"
                                    ? "#FFD700" 
                                    : rowData.status === "Decline"
                                    ? "#FF6347"
                                    :  "#2356FE" ,
                            color: "#fff", 
                            fontWeight: 'bold',
                            border: 'none',
                            borderRadius: '5px',
                            padding: '0.5rem 0.75rem', 
                            cursor: 'pointer',
                            boxShadow: 'none', 
                            transition: 'box-shadow 0.3s ease', 
                            width: '5rem', 
                        }}
                        onMouseEnter={(e) => {
                            e.target.style.boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.1)"; 
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.boxShadow = "none"; 
                        }}
                    >
                        {rowData.status?.[0]?.toUpperCase() + rowData.status?.slice(1)}
                    </button>
                ) : null 
            ),
        },
        
        
        {
            title: "Actions",
            field: "actions",
            headerStyle: {
                color: "#000000",
                fontWeight: "500",
                fontSize: "14px",
                textAlign: "center",
                paddingLeft: "30px",
            },
            render: (rowData) => (
                rowData.status ? (
                    rowData.status !== "Approved" ? (
                        <Box
                            sx={{
                                display: "flex",
                                gap: "0.5rem",
                                width: "150px",
                                justifyContent: "start",
                                flexWrap: "wrap",
                            }}
                        >
                            <Button
                                sx={{
                                    padding: "8px 0.5rem",
                                    border: "2px solid #2356FE",
                                    fontWeight: "600",
                                    borderRadius: "50%",
                                    fontSize: "0.8rem",
                                    minWidth: "40px",
                                    height: "40px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                variant="outlined"
                                onClick={() => handleStatusChange(rowData._id, "Approved")}
                            >
                                <DoneIcon sx={{ color: "#000000" }} />
                            </Button>
        
                            <Button
                                sx={{
                                    padding: "8px 0.5rem",
                                    border: "2px solid #FF0000",
                                    fontWeight: "600",
                                    borderRadius: "50%",
                                    fontSize: "0.8rem",
                                    minWidth: "40px",
                                    height: "40px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                variant="outlined"
                                onClick={() => handleStatusChange(rowData._id, "Decline")}
                                disabled={rowData.status === "Approved" || rowData.status === "Decline"}
                            >
                                <CloseIcon sx={{ color: "#000000" }} />
                            </Button>
                        </Box>
                    ) : <Typography sx={{
                        textAlign: "start",
                        color:"#000000",
                        fontWeight:"900",
                        fontSize:"1rem",
                        paddingLeft:"2rem"
                    }}>
                        <RemoveIcon/>
                    </Typography>
                ) : null
            ),
        }
        
    ];

    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent:"center",
            marginTop:"2rem",
           // postion:"relative"
           
         }}>
         
         
         
         <Typography
                     sx={{
                         fontFamily: 'Poppins',
                         fontSize: { xs: "12px", sm: "14px"  },
                         fontWeight: 400,
                         lineHeight: {md:'24px' , xs:"20px"},
                         textAlign: 'left',
                         color: "#575757",
                         marginBottom:"3rem",
                     }}
                 >
                     Mentors will review and accept incoming requests to guide and support mentees, ensuring a personalized and engaging mentorship experience.
                 </Typography>
         
         
         
                 <Box
                 sx={{
                   position: "relative", 
                   background: "#f8f8ff",
                   borderRadius: "1.5rem",
                   width: "100%",
                   display: "flex",          
                   flexDirection: {xl :"row", sm:"column" , md:"row" },
                   justifyContent:"center",
                   alignItems: "center",
                   boxSizing: "border-box",
                   ":before": {
                     content: '""', 
                     background: "linear-gradient(122.52deg, rgba(35, 86, 254, 0.7) 1.25%, rgba(35, 86, 254, 0.1) 98.44%)", 
                     borderRadius: "1.5rem",
                     position: "absolute",
                     top: 0,
                     bottom: 0,
                     right: 0,
                     left: 0,
                     zIndex: "1", 
                     margin: "-1rem",
                   }
                 }}
                 >
        <Box
            sx={{
                borderRadius: "1rem",
                position: "relative", 
                zIndex:"5",
                backgroundColor: "var(--color-ghostwhite)",
                boxSizing: "border-box",
                width: "100%",
                overflow: "auto",
            }}
        >
         
         <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 5,
    // padding: { xs: "1rem", sm: "2rem 1.5rem" }, 
  }}
>
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
    //   alignItems: "center",
      padding: { xs: "1rem", sm: "1rem 1.5rem" }, 
      borderTopLeftRadius: "1rem",
      borderTopRightRadius: "1rem",
      position: "relative",
      backgroundColor: "#ffffff",
      zIndex: 5,
      flexDirection: { xs: "column", sm: "row" }, 
    }}
  >
    <Box sx={{ width: { xs: "100%", sm: "90%" } }}> 
      <Typography
        variant="h4"
        component="div"
        gutterBottom
        sx={{
          fontFamily: "Poppins",
          fontWeight: 700,
          textAlign: "left",
          position: "relative",
          zIndex: 5,
        }}
      >
        <span style={{ color: "blue", zIndex: 5 }}>Projects</span> Request
      </Typography>
      <Typography
        gutterBottom
        component="div"
        sx={{
          fontFamily: "Poppins",
          width:{md:"80%" , xs:"100%"},
          fontWeight: 400,
          lineHeight: "18.97px",
          fontSize: { xs: "10px", sm: "12px"  },
          textAlign: "left",
          color: "#6D6D6D",
          zIndex: 5,
        }}
      >
        Explore key details such as project name, description, mentor, assigned students, start date, and progress status for an overview of all ongoing projects.
      </Typography>
    </Box>

    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent:"flex-start",
        // alignItems: "start",
        // justifyContent: "flex-end",
        position: "relative",
        zIndex: 5,
        // marginTop: { xs: "1rem", sm: 0 }, 
      }}
    >
      <div
        style={{
          position: "relative",
          zIndex: 5,
          width: { xs: "100%", sm: "16rem"  }, 
          height: "2.5rem",
          backgroundColor: "#e7e7e7",
          borderRadius: "0.8rem",
          display: "flex",
          alignItems: "center",
          padding: "0 12px",
        }}
      >
        <SearchIcon style={{ color: "#ACACAC", marginRight: "8px" }} />
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            fontSize: { xs: "0.9rem", sm: "1rem" }, 
            color: "#ACACAC",
            padding: "0",
            zIndex: 5,
          }}
        />
      </div>
    </Box>
  </Box>
</Box>

 

           <MaterialTable
    sx={{
        boxShadow: "none",
        width: "100%",
    }}
    options={{
        search: false,
        searchFieldStyle: {
            alignSelf: "center",
            width: '100%',
            fontSize: "0.9rem"
        },
        toolbar: false,
        showTitle: false,
        paging: false,
        headerStyle: {
            backgroundColor: '#F3F3F3',
            color: '#000000',
            padding: { xs: "0.5rem", sm: "1rem", md: "1.25rem" },
            fontSize: "0.8rem",
        },
        rowStyle: (rowData, index) => ({
            borderBottom: '1px solid #e0e0e0', 
            padding: '5px 0', 
            height:"60px"
        }),
    }}
    columns={columns}
    data={projects.length >= 5 ? projects : [...projects, ...Array(5 - projects.length).fill({})]}/>

            
           <Box
    sx={{
        display: 'flex',
        justifyContent: { xs: 'center', md: 'space-between' }, 
        alignItems: 'center',
        padding: '0.5rem 1rem',
        zIndex: "5",
        flexWrap: 'wrap', 
        gap: { xs: 2, md: 0 }, 
        backgroundColor:"#F3F3F3"   }}
>
    <TablePagination
        component="div"
        count={10}
        page={0}
        onPageChange={() => {}}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Rows per page"
        sx={{
            flexShrink: 0,
            '& .MuiTablePagination-toolbar': {
                paddingLeft: { xs: 0, md: '16px' }, 
            },
            '& .MuiTablePagination-selectLabel': {
                marginRight: 2,
            },
            '& .MuiSelect-select': {
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '6px 8px',
            },
        }}
        labelDisplayedRows={() => ''}
        slotProps={{
            actions: {
                nextButton: { sx: { display: 'none' } },
                backButton: { sx: { display: 'none' } },
                firstButton: { sx: { display: 'none' } },
                lastButton: { sx: { display: 'none' } },
            },
        }}
    />

    <CustomPagination
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        sx={{
            marginTop: { xs: '1rem', md: 0 }, 
            width: { xs: '100%', md: 'auto' }, 
        }}
    />
</Box>
        </Box>
        </Box>

        </Box>

    );
};

export default ProjectRequests;
