import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { Lock } from '@mui/icons-material';
import { useCareerContext } from 'contexts/CareerContext';

const TestCards = ({ handleTakeTest, handleViewResults }) => {
    const { attempts, selectedNavOption } = useCareerContext();
    const currentAttempt = attempts[selectedNavOption.toUpperCase()];
    const cards = [
        {
            icon: "https://www.16personalities.com/static/images/test-header-2.svg",
            header: 'Try out the Test',
            para: 'Be yourself and answer honestly to find out your personality type.',
            onClick: handleTakeTest,
            disabled: !currentAttempt.canAttempt,
            buttonText: currentAttempt.canAttempt ? (currentAttempt.attemptCount < 2 ? 'Take Test' : 'Test Locked') : 'Test Locked',
        },
        {
            icon: "https://www.16personalities.com/static/images/academy/explorers/icons/theory.svg",
            header: 'View Detailed Results',
            para: 'Learn how your personality type influences many areas of your life.',
            onClick: handleViewResults,
            disabled: currentAttempt.attemptCount === 0,
            buttonText: currentAttempt.attemptCount > 0 ? 'View Results' : null,
        },
    ];

    return (
        <Box
            sx={{
                width: '100%',
                minHeight: '80vh',
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                gap: 2,
                justifyContent: 'center',
                alignItems: 'flex-start',
                padding: '1rem',
            }}
        >
            {cards.map((card, index) => (
                <Box
                    key={index}
                    sx={{
                        margin: "auto",
                        maxWidth: '300px',
                        minHeight: '420px',
                        backgroundColor: '#ffffff',
                        borderRadius: '16px',
                        padding: '1rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        position: 'relative',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                            transform: 'translateY(-10px)',
                            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.2)',
                        },
                    }}
                >
                    <Box
                        sx={{
                            height: '130px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '1.5rem',
                        }}
                    >
                        <img
                            src={card.icon}
                            alt={`card-${index}`}
                            style={{
                                maxHeight: '80%',
                                maxWidth: '80%',
                                objectFit: 'contain',
                                borderRadius: '50%',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        />
                    </Box>
                    <Box sx={{ textAlign: 'center', px: 2 }}>
                        <Typography variant="h6" sx={{ color: '#333', fontWeight: 'bold', marginBottom: '0.5rem' }}>
                            {card.header}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#555' }}>
                            {card.para}
                        </Typography>
                    </Box>
                    {card.disabled ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'absolute',
                                bottom: '20px',
                                left: '0px',
                                right: '0px',
                            }}
                        >
                            <Lock sx={{ color: '#bbb' }} />
                        </Box>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={card.onClick}
                            disabled={card.disabled}
                            sx={{
                                position: 'absolute',
                                left: '0px',
                                bottom: '20px',
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                width: '100%',
                                padding: '10px',
                                fontWeight: 'bold',
                                backgroundColor: '#4caf50',
                                '&:hover': {
                                    backgroundColor: '#388e3c',
                                },
                            }}
                        >
                            {card.buttonText}
                        </Button>
                    )}
                </Box>
            ))}
        </Box>
    );
};

export default TestCards;
