import React, {  useState } from "react";
import PolicyForm from "./PolicyForm";
import CheckPolicy from "./CheckPolicy";
import LinkUpload from "./LinkUpload";
import { FaFileContract, FaDownload, FaUpload } from "react-icons/fa";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button } from "@mui/material";

const InstituteStepper = ({ showCheckPolicyButton, setShowCheckPolicyButton, setStartingView, instituteId ,isSidebarVisible  }) => {
  const [goSteps, setGoSteps] = useState(0);

  

  const handleStepClick = (step) => {
    console.log("step:", step);
    setGoSteps(step);
  };


  const handleBack = () => {
    setGoSteps(goSteps-1);
  }


  return (
    <Box style={{paddingTop: goSteps === 0 ? "40px" :"0px",...policyContainerStyle}}>
        { goSteps !== 0 && <Box sx={{
                          position:"sticky",
                          top:"75%",
                           zIndex:"5",
                          display:"flex",
                          justifyContent:"start",
                          width:"100%"
                        }}>
                        <Button variant="contained" color="primary"  onClick={handleBack} sx={{
                                borderTopRightRadius:"2rem",
                                borderBottomRightRadius:"2rem",
                            }}>
                           
                                <ArrowBackIcon sx={{
                                  alignSelf:"center",
                                  marginRight:"1rem"

                                }}/>
                             
                            Back
                                        </Button>
                        </Box>}
      <div style={stepperStyle}>
    
        {[ 
          { icon: <FaFileContract />, label: "Policy Form" }, 
          { icon: <FaDownload />, label: "Download" }, 
          { icon: <FaUpload />, label: "Upload" },
        ].map((step, index) => (
          <div
            key={index}
            style={{
              ...stepItemStyle,
              ...(goSteps === index && activeStepStyle),
              ...(index === 0 ? firstStepItemStyle : {}),
              ...(index === 2 ? lastStepItemStyle : {}),
            }}
            onClick={() => handleStepClick(index)}
          >
            <div style={whiteCircleStyle}></div>
            <div
              style={{
                ...stepStyle,
                backgroundColor: goSteps === index
                ? "#FBFF45"  
                : goSteps > index
                ? "#7FFFCF"  
                : "#FFFFFF" , 
                color: goSteps === index ? "#000000" : "#000",
                boxShadow: goSteps === index ? "0px 4px 15px rgba(0, 0, 0, 0.2)" : "none",
              }}
            >
              {step.icon}
            </div>
           
              
     
          </div>
        ))}
      </div>
      <Box
                sx={{
                  ...lineStyle,
                  background: "#2356FE",
                  top: { xl: "7%" , md: "8%" , sm: "7%", lg: "7%",  xs:"8.2%",},
                  width: { xs: "95%", sm: "90%", md: "88%", lg: "86%", xl: "85%" }
                  // position:"relative"
                }}
              />
            
      <div style={contentStyle}>
        
    

        {goSteps === 0 && (
          <PolicyForm
            showCheckPolicyButton={showCheckPolicyButton}
            setShowCheckPolicyButton={setShowCheckPolicyButton}
            setStartingView={setStartingView}
            instituteId={instituteId}
            setGoSteps={setGoSteps}
          />
        )}
        {goSteps === 1 && (
          <CheckPolicy setStartingView={setStartingView} instituteId={instituteId} setGoSteps={setGoSteps} />
        )}
        {goSteps === 2 && (
          <LinkUpload setStartingView={setStartingView} instituteId={instituteId} setGoSteps={setGoSteps} />
        )}
      </div>
    </Box>
  );
};

const policyContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  overflow: "auto",
  overflowX:"hidden",
  backgroundColor: "#f0f4f8",
  zIndex: "999",

  overflowY: "auto",  
  position: "relative",
};

const stepperStyle = {
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "nowrap", 
  width: "100%",
  maxWidth: "1200px",
  // margin: "0 auto",
  // position: "relative",
};

const stepItemStyle = {
  cursor: "pointer",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  flex: "1 1 auto", 
  minWidth: "120px",
};

const firstStepItemStyle = {
  alignItems: "center", 
};

const lastStepItemStyle = {
  alignItems: "center", 
};

const activeStepStyle = {
  zIndex: "2",
};

const stepStyle = {
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  zIndex: "2",
};

const whiteCircleStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "70px",
  height: "70px",
  borderRadius: "50%",
  backgroundColor: "#F0F3F8",
  transform: "translate(-50%, -50%)",
  zIndex: "1",
};

const lineStyle = {
  position: "absolute",
  top:{  base: "5%", sm: "7.2%", md: "10%", xl: "12%" },
  left: "50%",
  height: "4px",
 
  transform: "translateX(-50%)", 
  zIndex: "0",
};

const contentStyle = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  padding: "1.5rem",
};

export default InstituteStepper;
