import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode, Pagination } from "swiper";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import '../Css/LogoSlider.css';

const LogoSlider = () => {
  let [logoScrollData, setPressReleseDate] = useState([
    { logoImg: "./logoscroll/logo27.svg" },
    { logoImg: "./logoscroll/logo1.svg" },
    { logoImg: "./logoscroll/logo2.svg" },
    { logoImg: "./logoscroll/logo3.svg" },
    { logoImg: "./logoscroll/logo4.svg" },
    { logoImg: "./logoscroll/logo5.svg" },
    { logoImg: "./logoscroll/logo28.svg" }, 
    { logoImg: "./logoscroll/logo6.svg" },
    { logoImg: "./logoscroll/logo7.svg" },
    { logoImg: "./logoscroll/logo8.svg" },
    { logoImg: "./logoscroll/logo9.svg" },
    { logoImg: "./logoscroll/logo33.svg" },
    { logoImg: "./logoscroll/logo10.svg" },
    { logoImg: "./logoscroll/logo32.svg" },
    { logoImg: "./logoscroll/logo11.svg" },
    { logoImg: "./logoscroll/logo29.svg" },
    { logoImg: "./logoscroll/logo12.svg" },
    { logoImg: "./logoscroll/logo31.svg" },
    { logoImg: "./logoscroll/logo13.svg" },
    { logoImg: "./logoscroll/logo30.svg" },
    { logoImg: "./logoscroll/logo14.svg" },
    { logoImg: "./logoscroll/logo15.svg" },
    { logoImg: "./logoscroll/logo16.svg" },
    { logoImg: "./logoscroll/logo17.svg" },
    { logoImg: "./logoscroll/logo18.svg" },
    { logoImg: "./logoscroll/logo19.svg" },
    { logoImg: "./logoscroll/logo20.svg" },
    { logoImg: "./logoscroll/logo21.svg" },
    { logoImg: "./logoscroll/logo22.svg" },
    { logoImg: "./logoscroll/logo23.svg" },
    { logoImg: "./logoscroll/logo24.svg" },
    { logoImg: "./logoscroll/logo25.svg" },
    { logoImg: "./logoscroll/logo26.svg" },
]);

  // const [images, setImages] = useState([]);
  // const [currentIndex, setCurrentIndex] = useState(0);

  // useEffect(() => {
  //   if (logoScrollData.length > 0) {
  //     const temp = logoScrollData.map(ele => ({
  //       logoImg: ele.logoImg
  //     }));
  //     setImages([...logoScrollData, ...temp]);
  //   }
  // }, [logoScrollData]);

  // const handleAnimationEnd = () => {
  //   // When animation ends, reset index to 0 if it reached the last index
  //   if (currentIndex === images?.length - 1) {
  //     setCurrentIndex(0);
  //   } else {
  //     setCurrentIndex(currentIndex + 1);
  //   }
  // };
  // console.log(images)
  return (
    <>
      <div className="container overflow-hidden w-100">
        <h1 className="text-center fw-bolder mx-5 fs-1 mt-5">
          5000+ institutes registered and counting
        </h1>
        {/* <div className="logos" >
                    <div className="logos-slide d-flex">
                {logoScrollData?.map((ele,i) => {
                  return (
                  <div key={i} className="img_title">
                    <img src={ele?.logoImg} alt="" style={{
                                // transform: `translateX(${i === currentIndex ? "-100%" : "0"})`
              }}/>
                  </div>
)})}
                </div>
                </div> */}
                {/* <div className="logosIns">
                  <div className="logos-slide-ins" >
                    {logoScrollData?.map((ele,i)=>{
                      return (
                        <div className="img_title">
                          <img className="" src={ele?.logoImg} alt="" />

                         </div>
                      )
                    })}
                  </div>
                </div> */}


        <div className="infaSlider border-0" style={{ height: "132px" }}>

          <Swiper 
            slidesPerView={1}
            spaceBetween={0}
            freeMode={true}
            loop={true}
            grabCursor={true}
            autoplay={{
              delay: 2,
              disableOnInteraction: false,
            }}
            speed={16000}
            pagination={{
              clickable: true,
            }}
            freemodemomentum={"false"}
            modules={[Autoplay, FreeMode, Pagination]}
            className="galleryswiper"
          >
            <SwiperSlide>
              {logoScrollData?.map((ele, i) => {
                return (
                  <div className="">
                    <img className="" src={ele?.logoImg} style={{ width: "140px", height: "132px" }} alt="" />
                  </div>
                )
              })}
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default LogoSlider;
