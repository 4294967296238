import React, { useState } from "react";
import "../Css/univercity.css";
import { Modal } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';

const ShowMorePagination = ({ items, defaultItemsToShow, itemsPerPage }) => {
  const [itemsToShow, setItemsToShow] = useState(defaultItemsToShow);
  const [currentPage, setCurrentPage] = useState(1);
  const [showEndorse, setShowEndorse] = useState(false);
  const [currentItem, setCurrentItem] = useState({})

  const showMore = () => {
    setItemsToShow(itemsToShow + itemsPerPage);
    setCurrentPage(currentPage + 1);
  };

  const showLess = () => {
    setItemsToShow(Math.max(itemsToShow - itemsPerPage, itemsPerPage));
    setCurrentPage(1);
  };

  return (
    <div>
      <div className="univercityIcons">
        <div className="row text-center">
          {items.slice(0, itemsToShow).map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setShowEndorse(item?.showModal);
                setCurrentItem(item);
              }}
              className={`col-lg-2 col-3 text-center ${item?.showModal? 'Endorsed-Yuva-landing-page':null}  rounded-3 p-0 py-1`}
            >
              <div className="cursor-pointer">
                <img className="" src={item?.img} alt="" width={80} height={80} />
              </div>
              <p className="cursor-pointer">{item.para}</p>
            </div>
          ))}
        </div>
      </div>

      {/* <div className="d-flex justify-content-center mt-2">

        {itemsToShow < items.length ? (
          <button onClick={showMore} className="seeMore text-center text-white px-3 py-2">Show More</button>
        ) : (
          <button onClick={showLess} className="seeMore text-center text-white px-3 py-2">Show Less</button>
        )}
      </div> */}
      <Modal scrollable show={showEndorse} onHide={() => setShowEndorse(false)}>
        <Modal.Header>
          <span className="fs-3 fw-semibold"> {currentItem?.para}</span>
        </Modal.Header>
        <Modal.Body>

          <Carousel data-bs-theme="dark"  controls={currentItem?.pdfImages?.length>1?true:false}>
            {currentItem.pdfImages?.map((val, ind) => (
              <Carousel.Item key={ind}>
                <img className="d-block w-100" src={val} alt={`slide ${ind}`} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
        
        <Modal.Footer>
          {currentItem?.downloadPdf && (
            <a href={currentItem?.pdfLink} download  className="btn btn-outline-success rounded-3 border-1 fw-semibold btn-sm">
              Download PDF
            </a>
          )}
          {currentItem?.showLink && (
            <a href={currentItem?.link} target="_blank" className="btn btn-outline-primary rounded-3 border-1 fw-semibold btn-sm">
              Visit Site
            </a>
          )}
          <a
            className="btn btn-outline-danger rounded-3 border-1 fw-semibold btn-sm"
            onClick={() => setShowEndorse(false)}
          >
            Close
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ShowMorePagination;