import React, { useEffect, useState } from "react";
import { Typography, Box, TextField, Button } from "@mui/material";
import { useCareerContext } from "contexts/CareerContext";
import CareerSidePanel from "./CareerSidePanel";
import RIASEC from "../Career-RiasecTest/RIASEC";
import LSMT from "../Career-LsmtTest/LSMT";
import { useGlobalContext } from "global/context";
import { apiJson } from "api";
import YuvaLoader from "pages/Forum/components/Loader/YuvaLoader";
import { Popup } from "layout/Popup";
import ManageTeacher from "./ManageTeacher";
import RiasecAndLsmtPoster from "./RiasecAndLsmtPoster";

const CareerDashboard = () => {
	const {
		role,
		selectedNavOption,
		setClassInfo,
		isSidebarVisible,
		isDetailsVisible,
		toggleSidebar,
	} = useCareerContext();
	const { userData } = useGlobalContext();
	const [classInput, setClassInput] = useState("");
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1024);
	const [loading, setLoading] = useState(true); // Show loader initially
	const [showLockPage, setShowLockPage] = useState(false); // State for lock page
	const [hasClass, setHasClass] = useState(false); // State to track if class exists

	const componentMap = {
		RIASEC: RIASEC,
		LSMT: LSMT,
		"Manage Teachers": ManageTeacher,
	};

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth <= 1024);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		const fetchClassInfo = async () => {
			if ((role === "student" || role === "teacher") && userData?.id) {
				try {
					// Determine API endpoint based on role
					const endpoint =
						role === "teacher"
							? `api/v2/riasecTest/riasec-guide-info/${userData.id}`
							: `api/v2/user/studentClass/${userData?.id}/${userData?.role}`;

					const res = await apiJson(endpoint);

					// Check for teacher's guide data
					if (role === "teacher") {
						if (res?.data?.result) {
							console.log("For Teacher found: ", res);
							setHasClass(true); // Class found for teacher
						} else {
							setHasClass(false); // No class found for teacher
							setShowLockPage(true); // Lock page for teachers without class
						}
					} else {
						// Handle student role
						const fetchedClass = res?.data?.result?.class;
						if (!fetchedClass) {
							setHasClass(false); // No class found for student
						} else {
							setClassInfo(fetchedClass);
							setHasClass(true); // Mark class as found
						}
					}
				} catch (err) {
					console.error("Error fetching class info:", err);
					Popup(
						"error",
						"Something went wrong",
						"Please try again later",
						3000
					);
				} finally {
					setLoading(false); // Stop showing loader after fetch
				}
			} else {
				setLoading(false); // Stop loader for 'institute' role
			}
		};

		fetchClassInfo();
	}, [role, userData, setClassInfo]);

	const handleClassSave = async () => {
		setLoading(true); // Show loader during class save
		try {
			await apiJson.post(
				`/api/v2/user/updateClass/${userData.id}/${userData.role}`,
				{
					classValue: classInput,
				}
			);
			console.log(`Value updated for: ${userData.id} as class: ${classInput}`);
			setClassInfo(classInput);
			setHasClass(true); // Mark class as saved
		} catch (err) {
			console.error("Error saving class:", err);
			Popup("error", "Something went wrong", "Please try again later", 3000);
		} finally {
			setLoading(false); // Stop loader
		}
	};

	if (loading) {
		// Render white screen with loader while fetching data
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
					backgroundColor: "#fff",
				}}
			>
				<YuvaLoader setShow={false} show={loading} />
			</Box>
		);
	}

	if (showLockPage) {
		// Render lock page for teachers without a class
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
					backgroundColor: "#f0f0f0",
					padding: "2rem",
					textAlign: "center",
				}}
			>
				<Typography variant="h4" gutterBottom>
					Access Restricted
				</Typography>
				<Typography variant="body1">
					You do not belong to any class. Please contact your administrator.
				</Typography>
			</Box>
		);
	}

	if (role === "student" && !hasClass) {
		// Show input for class selection for students without class
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
					backgroundColor: "#f0f0f0",
					padding: "2rem",
					textAlign: "center",
				}}
			>
				<Typography variant="h4" gutterBottom>
					Enter Your Current Class
				</Typography>
				<Typography variant="body1" sx={{ marginBottom: "2rem" }}>
					Please enter your current class to proceed.
				</Typography>
				<TextField
					label="Class"
					variant="outlined"
					value={classInput}
					onChange={(e) => setClassInput(e.target.value)}
					sx={{ marginBottom: "1.5rem", width: "300px" }}
				/>
				<Button
					variant="contained"
					color="primary"
					onClick={handleClassSave}
					disabled={!classInput || loading}
				>
					{loading ? "Saving..." : "Submit"}
				</Button>
			</Box>
		);
	}
	const SelectedComponent = componentMap[selectedNavOption] || null; // Fallback for invalid option

	return (
		<section
			className="dashboard-content-container"
			style={{ padding: "1rem", display: "flex" }}
		>
			{isSidebarVisible && <CareerSidePanel />}
			{isDetailsVisible && (
				<section
					className={`options-progress-details-container ${
						!isSidebarVisible ? "hide" : ""
					}`}
					style={{ flex: "1", padding: "1rem", overflow: "auto" }}
				>
					<div
						style={{
							backgroundColor: "#FEDC55",
							display: "flex",
							paddingTop: "1rem",
							justifyContent: "space-between",
							paddingInline: "2rem",
							marginTop: "25px",
							borderTopLeftRadius: "1.5rem",
							borderBottomLeftRadius: "1.5rem",
							gap: 5,
						}}
					>
						<div>
							<p>
								<strong>Earn Credit From Social Working</strong>
							</p>
						</div>
						<div>
							<p>
								<a href="#">Click Here</a>
								<span> To Know More</span>
							</p>
						</div>
					</div>
					{SelectedComponent ? (
						<SelectedComponent />
					) : (
						<Typography>
							No component available for the selected option.
						</Typography>
					)}
				</section>
			)}
			{isSmallScreen && !isSidebarVisible && (
				<button
					className="btn clubPanelToggle coursePanelToggle btn-light border-dark rounded-2 bg-light py-2 px-1"
					onClick={toggleSidebar}
				>
					<img
						src="https://cdn-icons-png.flaticon.com/512/5994/5994725.png"
						alt=""
						width={30}
					/>
				</button>
			)}
		</section>
	);
};

export default CareerDashboard;
