import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Card, CardContent, TextField } from '@mui/material';
import { useCareerContext } from 'contexts/CareerContext';
import { useGlobalContext } from 'global/context';
import { Popup } from 'layout/Popup';
import { apiJson } from 'api';

const ResultView = () => {
    const { role, getScore, selectedStudentId } = useCareerContext();
    const { userData } = useGlobalContext();
    const [teacherComments, setTeacherComments] = useState([]);
    const [personalizedMessage, setPersonalizedMessage] = useState('');
    const [studentScore, setStudentScore] = useState(null);
    const [noFeedbackMessage, setNoFeedbackMessage] = useState(false);
    const [isFetchingFeedback, setIsFetchingFeedback] = useState(false); // To avoid multiple fetches

    const categories = {
        1: { min: 237, max: 265, description: "Highly Proficient" },
        2: { min: 215, max: 236, description: "Proficient" },
        3: { min: 170, max: 214, description: "Competent" },
        4: { min: 148, max: 169, description: "Basic" },
        5: { min: 53, max: 147, description: "Emerging" }
    };

    const getCategoryDescription = (score) => {
        for (let key in categories) {
            const { min, max, description } = categories[key];
            if (score >= min && score <= max) {
                return description;
            }
        }
        return "Unknown";
    };

    const handleFeedbackChange = (event) => {
        setPersonalizedMessage(event.target.value);
    };

    const handleSubmitFeedback = async () => {
        try {
            const feedbackPayload = {
                userId: userData.id,
                studentId: selectedStudentId,
                role,
                feedback: personalizedMessage,
            };

            await apiJson.post('/api/v2/lsmtTest/lsmt-student-feedback', feedbackPayload);
            Popup("success", "", "Feedback submitted successfully!", 3000);
            setPersonalizedMessage(''); // Clear feedback input
            fetchFeedback(); // Fetch feedback after successful submission
        } catch (error) {
            Popup("error", "", "Failed to submit feedback. Please try again.", 3000);
            console.error("Error submitting feedback:", error);
        }
    };

    const fetchFeedback = async () => {
        try {
            setIsFetchingFeedback(true); // Mark as fetching
            const response = await apiJson(`/api/v2/lsmtTest/lsmt-feedbacks/${selectedStudentId}`);

            if (response.status === 204) {
                setNoFeedbackMessage(true);
                setTeacherComments([]);
                return;
            }

            const feedbackData = response.data.result;

            // Check if feedbackData is valid
            if (!feedbackData) {
                setNoFeedbackMessage(true);
                return;
            }

            // Ensure that teacherFeedback and instituteFeedback are valid objects
            const teacherFeedback = feedbackData.teacherFeedback || {};
            const instituteFeedback = feedbackData.instituteFeedback || {};

            const feedbackList = [];

            // Iterate over teacherFeedback to extract comments
            for (const teacher in teacherFeedback) {
                if (Array.isArray(teacherFeedback[teacher])) {
                    teacherFeedback[teacher].forEach((comment) => {
                        feedbackList.push({ role: `Teacher (${teacher})`, comment });
                    });
                }
            }

            // Iterate over instituteFeedback to extract comments
            for (const institute in instituteFeedback) {
                if (Array.isArray(instituteFeedback[institute])) {
                    instituteFeedback[institute].forEach((comment) => {
                        feedbackList.push({ role: `Institute`, comment });
                    });
                }
            }

            // Sort feedback by latest first
            setTeacherComments(feedbackList.reverse());
            setNoFeedbackMessage(feedbackList.length === 0);
        } catch (error) {
            Popup("error", "", "Failed to fetch feedback. Please try again.", 3000);
            console.error("Error fetching feedback:", error);
        } finally {
            setIsFetchingFeedback(false); // Mark fetching complete
        }
    };


    const fetchCategoryScores = async () => {
        try {
            const scores = await getScore(selectedStudentId, 'student');
            if (scores) {
                setStudentScore(scores);
                fetchFeedback(); // Fetch feedback after getting the score
            } else {
                Popup("error", "", "No scores received. Please try again.", 3000);
            }
        } catch (error) {
            Popup("error", "", "Error occurred while fetching scores. Please try again.", 3000);
            console.error("Error fetching scores:", error);
        }
    };

    // Fetch feedback when selectedStudentId changes or after feedback is submitted
    useEffect(() => {
        if (userData && selectedStudentId && !isFetchingFeedback) {
            fetchCategoryScores();
        }
    }, [selectedStudentId]);

    return (
        <Box
            sx={{
                position: "relative",
                padding: "2rem",
                boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
                borderRadius: "1rem",
                border: "5px solid #2356fe",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "80vh",
                width: "100%",
                textAlign: "center",
                backgroundColor: "#f9f9f9"
            }}
        >
            <Typography
                variant="h2"
                sx={{
                    fontWeight: "bold",
                    color: "#2356fe",
                    fontSize: { xs: "2rem", sm: "2.5rem" },
                }}
            >
                Assessment Report
            </Typography>

            {/* Static data and score-based guidance */}
            {studentScore !== null && (
                <Box sx={{ mt: 4, width: '100%', textAlign: 'left' }}>
                    <Typography variant="h6" sx={{ fontSize: '1.75rem', fontWeight: 'bold', color: '#2356fe' }}>
                        Category: {getCategoryDescription(studentScore)} (Score: {studentScore})
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        {studentScore !== null && (
                            <>
                                <strong>Path Ahead:</strong>
                                <Box sx={{ mt: 2 }}>
                                    {getCategoryDescription(studentScore) === 'Highly Proficient' && (
                                        <>
                                            <ul>
                                                <li><strong>Advanced Opportunities:</strong> Encourage leadership roles in group projects and extracurricular activities; mentor peers in lower categories.</li>
                                                <li><strong>Skill Enhancement:</strong> Engage in advanced life skills workshops focusing on critical thinking and negotiation.</li>
                                                <li><strong>Real-World Application:</strong> Involve them in community service projects requiring high levels of decision-making.</li>
                                            </ul>
                                        </>
                                    )}
                                    {getCategoryDescription(studentScore) === 'Proficient' && (
                                        <>
                                            <ul>
                                                <li><strong>Skill Development:</strong> Participate in workshops that enhance interpersonal skills and emotional intelligence.</li>
                                                <li><strong>Peer Collaboration:</strong> Work in teams to solve complex problems.</li>
                                                <li><strong>Goal Setting:</strong> Set measurable goals with regular progress check-ins.</li>
                                            </ul>
                                        </>
                                    )}
                                    {getCategoryDescription(studentScore) === 'Competent' && (
                                        <>
                                            <ul>
                                                <li><strong>Foundational Skills:</strong> Build foundational life skills through structured programs.</li>
                                                <li><strong>Interactive Learning:</strong> Use games and simulations to practice skills.</li>
                                                <li><strong>Feedback Mechanism:</strong> Establish a system for constructive feedback to enhance self-reflection.</li>
                                            </ul>
                                        </>
                                    )}
                                    {getCategoryDescription(studentScore) === 'Basic' && (
                                        <>
                                            <ul>
                                                <li><strong>Targeted Support:</strong> Provide interventions for stress management and conflict resolution.</li>
                                                <li><strong>Skill Workshops:</strong> Organize workshops on basic life skills.</li>
                                                <li><strong>Encouragement:</strong> Foster a supportive environment to motivate small steps towards improvement.</li>
                                            </ul>
                                        </>
                                    )}
                                    {getCategoryDescription(studentScore) === 'Emerging' && (
                                        <>
                                            <ul>
                                                <li><strong>Individualized Learning Plans:</strong> Develop plans focusing on critical life skills.</li>
                                                <li><strong>Mentorship Programs:</strong> Pair students with mentors for guidance and support.</li>
                                                <li><strong>Basic Life Skills Training:</strong> Implement training on self-management and interpersonal relationships.</li>
                                            </ul>
                                        </>
                                    )}
                                </Box>
                            </>
                        )}
                    </Typography>

                </Box>
            )}

            {/* Feedback Section */}
            <Box sx={{ mt: 4, width: '100%' }}>
                <Typography variant="h6" sx={{ fontSize: '1.75rem', fontWeight: 'bold', color: '#2356fe', mb: 2, textAlign: 'left' }}>
                    Add Feedback
                </Typography>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={personalizedMessage}
                    onChange={handleFeedbackChange}
                    placeholder="Type your feedback here..."
                    sx={{ mt: 2 }}
                />
                <Button
                    variant="contained"
                    sx={{ mt: 2, backgroundColor: '#2356fe', color: '#fff' }}
                    onClick={handleSubmitFeedback}
                >
                    Submit Feedback
                </Button>
            </Box>

            {/* Existing Feedback */}
            <Box sx={{ mt: 4, width: '100%' }}>
                <Typography variant="h6" sx={{ fontSize: '1.75rem', fontWeight: 'bold', color: '#2356fe', mb: 2, textAlign: 'left' }}>
                    Other Feedbacks
                </Typography>
                {noFeedbackMessage ? (
                    <Typography variant="body2" sx={{ color: '#666', textAlign: 'left' }}>
                        No comments have been made yet.
                    </Typography>
                ) : (
                    teacherComments.map((feedback, index) => (
                        <Card key={index} sx={{
                            mb: 1,
                            backgroundColor: '#f9f9f9',
                            border: '1px solid #e0e0e0',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            padding: '1rem',
                        }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#2356fe', mr: 1 }}>
                                        {feedback.role}:
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {feedback.comment}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    ))
                )}
            </Box>

        </Box>
    );
};

export default ResultView;
