import { apiJson } from 'api';
import PressReleaseYuva2 from './PressReleaseYuva2'
import React, { useEffect, useState } from 'react'

import moment from "moment";
import { useNavigate } from 'react-router-dom';
import GotoTop from 'layout/GotoTop';

const MediaCoverage = () => {
    const [latestNews, setLatestNews] = useState([]);

    const navigate = useNavigate();
    const addElipsis = (str, limit) => {
        return str.length > limit ? str.substring(0, limit) + "..." : str;
      };

  const newsData = async () => {
    await apiJson("api/v2/user/getNewsArticle").then((data) => {
      setLatestNews(data.data.article);
    });
  };

  useEffect(() => {
    newsData();
  }, []);
  return (
    <>
        
<GotoTop />
      {/* Media coverage */}
      <section>
        <PressReleaseYuva2 />
      </section>

      <section>
        <div className="container">
          <div className="shadow-sm py-3 px-2 mt-4 rounded-4">
            <div className="d-flex justify-content-between align-items-center px-2">
              <div>
                <span className="fw-bold fs-5">Top News</span>
              </div>
              <div>
                <span
                  onClick={() => navigate("/news")}
                  className="fw-bold fs-6 cursor-pointer"
                  style={{ color: "#03a9f4", cursor: "pointer" }}
                >
                  See all
                </span>
              </div>
            </div>

            <hr />

            <div className="">
              <div className="row">
                {latestNews?.map((ele, i) => {
                  return (
                    <>
                      <div
                        className="col-12 m-0 p-0 col-md-6 col-xl-4 mb-2 border-bottom"
                    
                      >
                        <div
                          className="d-flex px-2 mb-3 cursor-pointer"
                          onClick={() => navigate(`/news/${ele?.slug}`)}
                        >
                          <div className="me-2">
                            <div
                              className=""
                              style={{ width: "160px", height: "90px" }}
                            >
                              <img
                                className="rounded h-100 w-100 object-fit-cover"
                                src={ele?.img}
                                alt=""
                              />
                            </div>
                          </div>

                          <div className="" style={{ width: "", height: "" }}>
                            <div>
                              <span
                                className="fw-bold"
                                style={{
                                  fontFamily:
                                    "BentonSans-Medium, Arial, Noto Sans, sans-serif",
                                }}
                              >
                                {addElipsis(ele?.title, 60)}
                              </span>
                            </div>
                            <div
                              className=""
                              style={{
                                color: "gray",
                                fontSize: "14px",
                                width: "",
                              }}
                            >
                              <span>
                                {ele?.publish_date
                                  ? ele?.publish_date
                                  : moment(ele?.createdAt).format("YYYY-DD-MM")}
                              </span>{" "}
                              <span>. </span>
                              {ele?.author}
                            </div>
                          </div>
                        </div>
                        {/* { [6, 7, 8]?.includes(i) ? "" : <hr className="d-none d-lg-block" />}
                        <hr className="d-sm-none d-sm-block" /> */}
                      </div>

                      {/* <div className="col-4 mt-3 px-3" style={{width: ""}}>
                      <div className="d-flex gap-2" style={{width: "300px"}}>
                        <div className="" style={{cursor: "pointer", maxWidth: "300px", maxHeight: "300px"}}>
                          
                           <div className=""> 
                            <img className="rounded h-100 w-100 object-fit-cover" 
                            onClick={() => navigate(`/news/${ele?.slug}`)}
                            src={ele?.img} alt=""/>
                           </div> 
                        </div>

                        <div className="">
                          <div><span className="fw-bold" style={{fontFamily: "BentonSans-Medium, Arial, Noto Sans, sans-serif"}}>{addElipsis(ele?.title, 60)}</span></div>
                          <div className="" style={{ color: "gray", fontSize: "14px", width: "200px" }}>
                            <span>{ele?.publish_date? ele?.publish_date: moment(ele?.createdAt).format("YYYY-DD-MM")}</span>{" "}
                            <span>. </span>{ele?.author}
                          </div>
                        </div>
                      </div>
                    </div> */}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MediaCoverage