import React, { useEffect, useState } from "react";
import "../style/MunApplicants.css";
import {
  UI2DashboardActivites,
  UI2DashboardDownload,
  UI2DashboardQR,
  UI2DashboardResources,
} from "pages/UI-2.0-Dashboard/component";
import { ModelUnPoliticalParties } from "./ModelUnPoliticalParties";
import { useGlobalContext } from "global/context";
import { apiJson, apiJsonAuth } from "api";
import { toast } from "react-toastify";
import { Avatar } from "@mui/material";
import moment from "moment";

export const ModelUnDashboard = () => {
  const {userData} = useGlobalContext();
  const [instituteDetail, setInstituteDetail] = useState()
  const [eventDetail, setEventDetail] = useState()
  let instituteId
  if(userData?.role === "institute"){
    instituteId=userData?.id
  }else{
    instituteId=userData?.instituteId
  }
  const [selectedCandidate,setSelectedcandidate] = useState({})
  const getAllSelectedCandidate = async()=>{
  try {
    if(instituteId){

      let  res = await apiJsonAuth.get(`/api/v2/modelUn-student/getAllSelectCandidateByRole/${instituteId}`)
      if(res.status===200){
        setSelectedcandidate(res?.data)
      }
    }
  } catch (error) {
    console.log("error",error)
  }
  }
  const getInstituteDetail = ()=>{
    apiJson(`api/v2/institute/getInstituteProfileData/id/${instituteId}/insituteDetail`)
    .then((res)=>{
      toast.dismiss()
      setInstituteDetail(res?.data?.result)
    }).catch((error)=>{
      toast.dismiss()
      toast.error('Internal server error')
    })
  }
  const getEventDetail = ()=>{
    apiJson(`api/v2/modelUn-student/getEventDetails/institute/${instituteId}`)
    .then((res)=>{
      toast.dismiss()
      setEventDetail(res?.data?.result)
    }).catch((er)=>{
      toast.dismiss()
      toast.error('Internal server error')
    })
  }
  useEffect(()=>{
    getAllSelectedCandidate()
    getInstituteDetail()
    getEventDetail()
  },[])
  return (
    <>
      <div className="overflow-x-hidden mt-4">
        <div className="container">
          <div className="d-flex justify-content-md-between flex-column-reverse flex-md-row text-md-center gap-3 align-items-center">
            <div className="w-50 w-md-100 text-center text-md-start">
              <p className="color-purple fs-29px m-0">Model United Nations</p>
              <p className="fs-1 color-light-purple m-0">
                Fostering Solutions to Real-world Problems
              </p>
            </div>
            <div className="logo w-50 w-md-100 d-flex justify-content-center justify-content-md-end pe-3">
              <img
                src="/modelUn/world_logo.png"
                alt="earthLogo"
                className="logohaw"
              />
            </div>
          </div>

          {/* slider */}

          <div
            id="carouselExampleIndicators"
            className="carousel slide mb-5 mt-3 position-relative"
            data-bs-ride="carousel"
          >
            {/* <div className="carousel-indicators position-absolute">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={0}
                className="active bg-dark"
                aria-current="true"
                aria-label="Slide 1"
                style={{height:'10px',width:'10px',borderRadius:'50%'}}
              />
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={1}
                aria-label="Slide 2"
                className=" bg-dark"
                style={{height:'10px',width:'10px',borderRadius:'50%'}}
              />
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={2}
                aria-label="Slide 3"
                className=" bg-dark"
                style={{height:'10px',width:'10px',borderRadius:'50%'}}
              />
            </div> */}
            <div className="carousel-inner">
              <div className="carousel-item active p-2">
          <div className="my-5 bg-white rounded-5 b-shadow p-4 position-relative overflow-hidden" style={{minHeight:'240px'}}>
              <div className="d-block d-md-none text-center">
                <Avatar className="mx-auto" sx={{width:'100px',height:'100px'}} src={instituteDetail?.logo} alt={instituteDetail?.institution_name} />
              </div>
                  <div>
                    {/* <div className="flex">
                    <img src="/modelUn/slider/bulb.svg" alt="" />
                    <span className="ms-2">Highlight</span>
                    </div> */}
                    <p className='fs-26px bold mt-3 text-center text-md-start '>{instituteDetail?.institution_name}</p>
                  </div>

                 <div >   <img  src="/modelUn/slider/FirstShape.svg" alt="shape" className='position-absolute top-0 modelunslider-img d-none d-lg-block'  />
                    <img src="/modelUn/slider/lastShape.svg" alt="shape" className='position-absolute top-0 modelunslider-imgTwo d-none d-md-block' /></div>

                  <p className='text-center text-md-start '>Summit on {moment(eventDetail?.date_proposed).format('Do MMMM YYYY')}</p>
                  <div>

                  </div>
                </div>     
              </div>
              {/* <div className="carousel-item p-2">
          <div className="h-252px my-5 bg-white rounded-5 b-shadow p-4 position-relative overflow-hidden">
                  <div>
                    <div className="flex">
                    <img src="/modelUn/slider/bulb.svg" alt="" />
                    <span className="ms-2">Highlight</span>
                    </div>
                    <p className='fs-26px bold mt-3'>Army Public School</p>
                  </div>

                  <img src="/modelUn/slider/FirstShape.svg" alt="shape" className='position-absolute top-0 modelunslider-img'  />
                  <img src="/modelUn/slider/lastShape.svg" alt="shape" className='position-absolute top-0 modelunslider-imgTwo' />

                  <p className='position-absolute modelunParaRotate'>Summit on 26th July, 2023</p>
                </div>     
              </div>
              <div className="carousel-item p-2">
              <div className="h-252px my-5 bg-white rounded-5 b-shadow p-4 position-relative overflow-hidden">
                  <div>
                    <div className="flex">
                    <img src="/modelUn/slider/bulb.svg" alt="" />
                    <span className="ms-2">Highlight</span>
                    </div>
                    <p className='fs-26px bold mt-3'>Army Public School</p>
                  </div>

                  <img src="/modelUn/slider/FirstShape.svg" alt="shape" className='position-absolute top-0 modelunslider-img'  />
                  <img src="/modelUn/slider/lastShape.svg" alt="shape" className='position-absolute top-0 modelunslider-imgTwo' />

                  <p className='position-absolute modelunParaRotate'>Summit on 26th July, 2023</p>
                </div>     
              </div> */}
            </div>
          </div>
          {userData.role === 'institute' ? (
          <div className="mb-4">
            <UI2DashboardQR />
          </div>
          ): null}
          <UI2DashboardActivites></UI2DashboardActivites>
          <div className="px-5">
            <div className="my-4">
              <p className="fw-600 f-family-Inter fs-31px color-dark-purple">
                Selected Theme
              </p>
            </div>

            <div className="fw-600 color-purple mt-4">
                <span className=" text-start font-weight-600 fs-23px font-size-18px d-block lh-34-5px f-family-Poppins">
                  {eventDetail?.format}
                </span>
                {/* <span className="text-start font-weight-600 fs-23px font-size-18px d-block lh-34-5px f-family-Poppins font-weight-600 fs-23px font-size-18px d-block lh-34-5px f-family-Poppins">
                  USHERING AN ERA OF NO WAR
                </span> */}
              </div>

              <div className="mt-4 mb-5">
              {eventDetail?.format ==="IGNITING INDIA’S JOURNEY TO 2047" ? (
                <p className="font-weight-400  text-start font-weight-600 fs-23px font-size-18px d-block lh-34-5px f-family-Poppins  f-family-Poppins fs-23px font-size-18px fs-sm-6 lh-31-5px">
                In a momentous address on the 77th Independence Day, Prime Minister Shri Narendra Modi juxtaposed the era of "Amrit" with the epoch of "Kartavya" - a time synonymous with duty and responsibility. 
                The Prime Minister emphasized that today's decisions will reverberate across the ages, their impacts resonating throughout a millennium.
                 With a resolute vision of propelling India into the League of Developed Nations by 2047, the Prime Minister outlined the pivotal role the forthcoming five years will play.
                </p>) :(
                  <p className="font-weight-400  text-start font-weight-600 fs-23px font-size-18px d-block lh-34-5px f-family-Poppins  f-family-Poppins fs-23px font-size-18px fs-sm-6 lh-31-5px">
                  LiFe is an initiative envisaged by the honourable Prime Minister Narendra Modi. 
                  Mission LiFe is expected to be an Indian-led global mass movement that will nudge individual and collective action to protect and preserve the environment.
                   The idea promotes an environmentally conscious lifestyle that focuses on ‘mindful and deliberate utilisation’ instead of ‘mindless and destructive consumption’. 
                   The P3 (pro-planet people) movement underlines India’s climate change commitments. This initiative is taken by the government that allows each citizen to take a collective duty to live a life that is in tune with Earth and does not put a risk on it. 
                  Those who practice such a lifestyle are recognised as Pro Planet People under LiFE.
                </p>
                )}
              </div>
              
            {userData.role === "institute" &&
              (<div className="mb-5">
                <span className="text-center text-sm-start f-family-Poppins color-light-blue fw-500 fs-20px lh-30px">
                  Download Background Guide{" "}
                  <span
                    style={{ color: "#000000" }}
                    className="text-center text-sm-start"
                  >
                    |
                  </span>{" "}
                  Get Oriented
                </span>
              </div>)}
          </div>

          {/* =================selected commitie============== */}
          {Object.keys(selectedCandidate).length > 0  && (
            <div className='mb-5 px-5'>
              <p className="fw-600 f-family-Inter fs-31px color-dark-purple">Selected Committees</p>
              {Object.keys(selectedCandidate).map(committeeName => (
                <div key={committeeName}>
                  <div>
                    <p className="fw-600 f-family-Inter fs-23px color-dark-purple m-0">{committeeName}</p>
                    <div>
                      <span className={`text-center text-sm-start f-family-Poppins color-light-blue fw-500 fs-20px lh-30px   ${selectedCandidate[committeeName]?.remainingSlots > 0 ? null : "text-danger"}  `}>
                        {selectedCandidate[committeeName]?.remainingSlots > 0 ? selectedCandidate[committeeName]?.remainingSlots + " Positions available" : "Positions Not Available"}
                      </span>
                    </div>
                  </div>
                  <ModelUnPoliticalParties data={selectedCandidate[committeeName]?.data}  />
                </div>
              ))}

            </div>
          )}
         
          {/* =================================What You Will Learn?============= */}
          <div className="px-5">
            <p className="fw-600 f-family-Inter fs-31px color-dark-purple">
              What You Will Learn?
            </p>

            <div className="row">
              <div className="col-lg-6 d-flex m-0 p-0 gap-3 p-2">
                <img
                  src="/modelUn/whatLearnImgs/youg people discussing the project.svg"
                  alt="whatlearn"
                  className="me-2"
                />
                <div className="whatlearnContens">
                  <p className="m-0 fw-bold mb-2">Team Building</p>
                  <p>
                    Work in groups to build a friendly rapport with other teams
                    to represent your team’s message.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 d-flex m-0 p-0 gap-3 p-2">
                <img src="/modelUn/whatLearnImgs/img2.svg" alt="whatlearn" />
                <div className="whatlearnContens">
                  <p className="m-0 fw-bold mb-2">Discussions With Consensus</p>
                  <p>
                    When opinions are shared as insights you learn to influence
                    others and draw meaningful conclusions.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 d-flex m-0 p-0 gap-3 p-2">
                <img src="/modelUn/whatLearnImgs/img3.svg" alt="whatlearn" />
                <div className="whatlearnContens">
                  <p className="m-0 fw-bold mb-2">International Diplomacy</p>
                  <p>
                    Influence actions through negotiations for a common cause
                    without rhetoric or upsetting others.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 d-flex m-0 p-0 gap-3 p-2">
                <img src="/modelUn/whatLearnImgs/img4.svg" alt="whatlearn" />
                <div className="whatlearnContens">
                  <p className="m-0 fw-bold mb-2">Multilateral Negotiations</p>
                  <p>
                    Negotiating with countries on economics and socio-politico
                    issues with varied perspectives.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 d-flex m-0 p-0 gap-3 p-2">
                <img
                  src="/modelUn/whatLearnImgs/img5.svg"
                  alt="whatlearn"
                  className="me-2"
                />
                <div className="whatlearnContens">
                  <p className="m-0 fw-bold mb-2">Strategy Building</p>
                  <p>
                    Working together with teams to create strategies that impact
                    the future of the world.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 d-flex m-0 p-0 gap-3 p-2">
                <img src="/modelUn/whatLearnImgs/img6.svg" alt="whatlearn" />
                <div className="whatlearnContens">
                  <p className="m-0 fw-bold mb-2">Macroeconomics</p>
                  <p>
                    Researching and deliberating thoughts on economic matters to
                    create resilient economies.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 d-flex m-0 p-0 gap-3 p-2">
                <img src="/modelUn/whatLearnImgs/img7.svg" alt="whatlearn" />
                <div className="whatlearnContens">
                  <p className="m-0 fw-bold mb-2">Research And Analysis</p>
                  <p>
                    Deep diving into topics and themes on global issues and
                    preparing content at short notice.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 d-flex m-0 p-0 gap-3 p-2">
                <img src="/modelUn/whatLearnImgs/img8.svg" alt="whatlearn" />
                <div className="whatlearnContens">
                  <p className="m-0 fw-bold mb-2">Public Speaking</p>
                  <p>
                    Perfect the art of speaking to a large audience, debating,
                    and influencing juries with impactful content.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UI2DashboardResources />
        
        {/* Download */}
        <UI2DashboardDownload />
      </div>
    </>
  );
};
