import React, { useState } from "react";

const Greencard = () => {
  const [credit, setCredit] = useState(parseInt(localStorage.getItem("credit75")) + parseInt(localStorage.getItem("credit21")) || 0);
  return (
    <div className="overflow-auto">
      <p
        className="position-fixed bg-white w-100 text-center p-3 h3"
        style={{
          fontSize: "30px",
          color: "#42AB83",
          fontWeight: 600,
          top: "53px",
        }}
      >
        Rewards
      </p>
      <div
        style={{
          position: "relative",
          zIndex: "-1",
          paddingTop: "65px",
        }}
      >
        <div>
          <p className="container" style={{ fontSize: "28px", fontWeight: 600 }} >You Have</p>

          <div className="d-flex justify-content-between align-items-center p-2" style={{ background: "#c7fcdc" }}>
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="p-0 m-0" style={{ fontSize: "38px" }}>
                    <img src="/sidepanel/LeafFrame.svg" alt="" />
                    {credit}
                  </p>
                  <p> <span className="p-0 m-0" style={{ color: "#42AB83" }} > Green Points </span> till now</p>
                </div>
                <div>
                  <button className="cursor-pointer text-white px-3 py-2" style={{ background: "#42AB83", borderRadius: "10px" }}  >Claim Now</button>
                </div>
              </div>

            </div>
          </div>
          {/* ===========================lower part=============== */}
          <div className="container">

            <div className="d-flex justify-content-between mt-3 align-items-center p-3" style={{ background: "#EFF3FF", borderRadius: "10px" }} >

              <div>
                <div className="d-flex gap-2 justify-content-between align-items-center">
                  <div className="p-3 text-center text-white" style={{ background: "#42AB83", borderRadius: "10px" }} >
                    <p className="text-white fs-2" style={{ fontWeight: 600, }} >15%</p>
                    <p className="text-white" >COMPLETED</p>
                  </div>
                  <div style={{ fontWeight: 400, fontSize: "18px" }} >
                    21 Days Challenge
                  </div>
                </div>
              </div>
              <div>
                <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                  <img src="/sidepanel/LeafFrame.svg" alt="" />
                  255
                </p>
              </div>
            </div>

            <div className="d-flex justify-content-between mt-3 align-items-center p-3" style={{ background: "#EFF3FF", borderRadius: "10px" }} >

              <div>
                <div className="d-flex gap-2 justify-content-between align-items-center">
                  <div className="p-3 text-center text-white" style={{ background: "#42AB83", borderRadius: "10px" }} >
                    <p className="text-white fs-2" style={{ fontWeight: 600, }} >15%</p>
                    <p className="text-white" >COMPLETED</p>
                  </div>
                  <div style={{ fontWeight: 400, fontSize: "18px" }} >
                    21 Days Challenge
                  </div>
                </div>
              </div>
              <div>
                <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                  <img src="/sidepanel/LeafFrame.svg" alt="" />
                  255
                </p>
              </div>
            </div>

            <div className="d-flex justify-content-between mt-3 align-items-center p-3" style={{ background: "#EFF3FF", borderRadius: "10px" }} >

              <div>
                <div className="d-flex gap-2 justify-content-between align-items-center">
                  <div className="p-3 text-center text-white" style={{ background: "#42AB83", borderRadius: "10px" }} >
                    <p className="text-white fs-2" style={{ fontWeight: 600, }} >15%</p>
                    <p className="text-white" >COMPLETED</p>
                  </div>
                  <div style={{ fontWeight: 400, fontSize: "18px" }} >
                    21 Days Challenge
                  </div>
                </div>
              </div>
              <div>
                <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                  <img src="/sidepanel/LeafFrame.svg" alt="" />
                  255
                </p>
              </div>
            </div>

            <div className="d-flex justify-content-between mt-3 align-items-center p-3" style={{ background: "#EFF3FF", borderRadius: "10px" }} >

              <div>
                <div className="d-flex gap-2 justify-content-between align-items-center">
                  <div className="p-3 text-center text-white" style={{ background: "#42AB83", borderRadius: "10px" }} >
                    <p className="text-white fs-2" style={{ fontWeight: 600, }} >15%</p>
                    <p className="text-white" >COMPLETED</p>
                  </div>
                  <div style={{ fontWeight: 400, fontSize: "18px" }} >
                    21 Days Challenge
                  </div>
                </div>
              </div>
              <div>
                <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                  <img src="/sidepanel/LeafFrame.svg" alt="" />
                  255
                </p>
              </div>
            </div>

            <div className="d-flex justify-content-between mt-3 align-items-center p-3" style={{ background: "#EFF3FF", borderRadius: "10px" }} >

              <div>
                <div className="d-flex gap-2 justify-content-between align-items-center">
                  <div className="p-3 text-center text-white" style={{ background: "#42AB83", borderRadius: "10px" }} >
                    <p className="text-white fs-2" style={{ fontWeight: 600, }} >15%</p>
                    <p className="text-white" >COMPLETED</p>
                  </div>
                  <div style={{ fontWeight: 400, fontSize: "18px" }} >
                    21 Days Challenge
                  </div>
                </div>
              </div>
              <div>
                <p className="p-0 m-0" style={{ fontSize: "20px" }}>
                  <img src="/sidepanel/LeafFrame.svg" alt="" />
                  255
                </p>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default Greencard;
