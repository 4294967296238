import axios from "axios";

export const handleEditandDownload = async (formType, data) => {
  const projectId = '66d1adda011e402922191020'; // Example projectId

  if (!projectId) {
    console.error('ProjectId is missing');
    return;
  }

  try {
    const response = await axios.post(
      `http://localhost:3000/api/document/generate-pdf/${projectId}`,
      { textData: data },
      {
        params: { formType },
        responseType: 'arraybuffer',  // Important for handling binary data
      }
    );

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${formType}.pdf`;  // Sets the name for the downloaded PDF

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading PDF:', error);
  }
};
