import React from 'react';
import "../Css/subscription.css";
import { useFormik } from 'formik';
import {validateEmail} from './EmailValidate'
import { apiJson } from 'api';
import Swal from 'sweetalert2';
const SubscribeSec = () => {

    const {values, errors, handleBlur, handleChange, handleSubmit} = useFormik({
      initialValues:{
        email:''
      },
      validationSchema:validateEmail,
      onSubmit:(value,action)=>{
        action.resetForm()
        apiJson.post(`api/v2/user/subscribe/${value?.email}/subscribe`)
        .then((res)=>{
          console.log('email sent to ', values?.email)
          Swal.fire({
            title: "Good job!",
            text: "You will now receive the latest updates and news from Yuvamanthan!",
            icon: "success"
          });
        }).catch((err)=>{
          console.log('email not sent')
        })
      }
    })

  return (
    <>
        <div className="container py-3 overflow-hidden">
            <div className='subscribe py-3 px-2 d-flex flex-column justify-content-center'>
                {/* <h3 className="text-center text-white fs-1 mb-2">Subscribe now to receive the latest updates and news from Yuvamanthan</h3> */}
                {/* <h3 className="text-center text-white fs-1 mb-2">Subscribe now </h3> */}
                <h6 className='text-center text-white fs-5 px-3 '>Don't miss out on exciting announcements and exclusive insights – be in the know, subscribe today!</h6>
                <form onSubmit={handleSubmit} className='d-flex align-items-center justify-content-center flex-column flex-md-row my-3 gap-2'>
                    <input type="email" placeholder='Your Email Here' name='email' value={values.email} onChange={handleChange} onBlur={handleBlur} />

                    <input type="submit" value="Submit" />
                </form>
                {/* <p className="text-center text-danger">{errors.email}</p> */}
            </div>
        </div>
    </>
  )
}

export default SubscribeSec