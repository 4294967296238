import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";
import * as XLSX from "xlsx";
import { Popup } from "layout/Popup";
import { Download } from "@mui/icons-material";
import { apiJsonAuth } from "api";
import { useGlobalContext } from "global/context";
import debounce from "lodash.debounce";
import YuvaLoader from "pages/Forum/components/Loader/YuvaLoader";
import moment from "moment";

const BulkUploadModal = ({ role, open, onError, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { token, userData } = useGlobalContext();
  const instituteId = userData?.id;

  // Maximum number of submissions per day
  const MAX_SUBMISSIONS = 6;

  // Supported file formats
  const SUPPORTED_FORMATS = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv'];

  // Simplified local storage format
  const getSubmissionInfo = () => {
    const submissionInfo = JSON.parse(localStorage.getItem("submissionCount")) || {};
    return submissionInfo[instituteId] || { student: { count: 0, date: '' }, teacher: { count: 0, date: '' } };
  };

  // Update submission info in local storage
  const setSubmissionInfo = (newInfo) => {
    const submissionInfo = JSON.parse(localStorage.getItem("submissionCount")) || {};
    submissionInfo[instituteId] = newInfo;
    localStorage.setItem("submissionCount", JSON.stringify(submissionInfo));
  };

  // Initialize submission count or reset if the day has changed
  const initializeSubmissionCount = () => {
    const today = moment().format('YYYY-MM-DD');
    const submissionInfo = getSubmissionInfo();

    // Check if the stored date matches today; if not, reset count and date for the role
    if (submissionInfo[role].date !== today) {
      submissionInfo[role] = { count: 0, date: today };
      setSubmissionInfo(submissionInfo);
    }
  };

  // Update the submission count after a successful upload
  const updateSubmissionCount = () => {
    const today = moment().format('YYYY-MM-DD');
    const submissionInfo = getSubmissionInfo();

    // Update count and date based on today's date for the specific role
    submissionInfo[role] = { count: submissionInfo[role].count + 1, date: today };
    setSubmissionInfo(submissionInfo);
  };

  // Check if the user can upload based on the submission count
  const canUpload = () => {
    const submissionInfo = getSubmissionInfo();
    return submissionInfo[role].count < MAX_SUBMISSIONS;
  };

  // Debounced file upload handler
  const handleDebouncedFileUpload = debounce(async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      // Check if the file format is supported
      if (!SUPPORTED_FORMATS.includes(file.type)) {
        Popup("error", "Unsupported file format", "Please upload an Excel (.xlsx, .xls) or CSV file.", 3000);
        onError(); // Close the dialog on error
        return;
      }

      readFile(file);
    }
  }, 300);

  // Read the file
  const readFile = (file) => {
    setIsLoading(true);
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Filter out empty rows
        jsonData = jsonData.filter((row) => row.some((cell) => cell !== ""));

        // Check if the file is empty or only contains one row (header)
        if (jsonData.length === 0 || jsonData.length === 1) {
          setIsLoading(false);
          onError();
          Popup("error", "Data Not Found or Only One Row Available!");
          return;
        }

        // Check if the file exceeds the 5000 record limit
        if (jsonData.length > 5000) {
          setIsLoading(false);
          onError();
          Popup("error", "Data exceeds the limit of 5000 records!");
          return;
        }

        // If valid, proceed with the upload
        uploadFile(file);
      } catch (error) {
        onError();
        Popup("error", "Error reading file");
        setIsLoading(false);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  // Upload the file
  const uploadFile = async (file) => {
    // Check if the user can upload
    if (!canUpload()) {
      Popup("error", "Submission limit reached for today!");
      onError();
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    try {
      await apiJsonAuth.post(`api/v2/csvUpload/institute-file-store/${instituteId}/${role}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      // Update the submission count
      updateSubmissionCount();

      Popup("success", "We have received your file", "Your data will be updated within 2 days");
      onSuccess();
    } catch (e) {
      Popup("error", "Error uploading file");
      onError();
    } finally {
      setIsLoading(false);
    }
  };

  // Initialize submission count when the component loads
  useEffect(() => {
    initializeSubmissionCount();
  }, []);

  return (
    isLoading ? <YuvaLoader show={isLoading} /> :
      <Dialog open={open} maxWidth="lg" fullWidth>
        <DialogTitle>Bulk Upload</DialogTitle>
        <DialogContent dividers>
          <div className="text-center">
            <div className="text-center item-center text-danger">
              <span className="font-weight-bold">NOTE</span>
              <br />
              <span className="lh-1 fw-semibold">
                1. Only the Provided Format is allowed for file upload <br />
                2. Data limit is 5000 Users per Upload.{" "}
              </span>{" "}
              <br />
              <Tooltip title="Use Provided file format to upload the student details">
                {role === "teacher" ? (
                  <Button
                    style={{ color: "white" }}
                    className="mt-2 rounded"
                    variant="contained"
                    color="success"
                    href="https://s3.ap-south-1.amazonaws.com/yuvamanthan.org/bulkRegister/Teacher_format.xlsx"
                    target="_blank"
                    startIcon={<Download />}
                  >
                    Download&nbsp;Template
                  </Button>
                ) : (
                  <Button
                    style={{ color: "white" }}
                    className="mt-2 rounded"
                    variant="contained"
                    color="success"
                    href="https://s3.ap-south-1.amazonaws.com/yuvamanthan.org/bulkRegister/Student_format.xlsx"
                    target="_blank"
                    startIcon={<Download />}
                  >
                    Download&nbsp;Template
                  </Button>
                )}
              </Tooltip>
            </div>
            <div className="file-upload-section">
              <div
                className="rounded-4 pb-3 mt-4 mx-auto"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  maxWidth: "500px",
                  border: "2px dashed grey",
                }}
              >
                <input
                  className="fade"
                  style={{
                    minHeight: 240,
                    width: "100%",
                    zIndex: 200,
                    position: "relative",
                  }}
                  type="file"
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onChange={handleDebouncedFileUpload}
                  id="fileData"
                  disabled={isLoading}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 100,
                  }}
                  className="w-100 h-100"
                >
                  <div className="h-100 w-100 bg-light">
                    <div className="py-4 text-center">
                      <h4>Drag and drop or Click To Add File.</h4>
                      <img
                        src="/images/upload-cloud-folder.png"
                        alt="upload"
                        width={200}
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onError} color="secondary" disabled={isLoading}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default BulkUploadModal;
