import React, { useState, useContext, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from 'global/context';
import './css/addpost.css';
import { MyContext } from '../EventTimeline';

function TopPosst() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { userPosts } = useContext(MyContext);
  const { userData } = useContext(UserContext);
  const location = useLocation();
  const isSinglePost = location.pathname.startsWith(`/timeline/${id}`);
  // const isProfilePage = location.pathname.startsWith(`/timeline/userProfile`)
  const [topPost, settopPost] = useState(null);

  const settingPost = () => {
    const sortedPosts = userPosts.sort((a, b) => b.likes - a.likes);
    settopPost(sortedPosts.slice(0, 3));
  };

  useEffect(() => {
    settingPost();
  }, [userPosts]);
  useEffect(() => {}, []);
  return (
    <div>
      <div className="border rounded-4 p-3 mb-3">
        <h4 className="border-bottom mb-3">Top Post</h4>
        {topPost
          ? topPost.map((post, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    navigate(`/timeline/${post.id}`);
                  }}
                  className="d-flex border-bottom justify-content-between"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                  }}>
                  {post.image ? (
                    <img
                      src={post.image ? post.image : null}
                      alt=""
                      style={{
                        width: '60px',
                        height: '60px',
                        borderRadius: '3px',
                      }}
                    />
                  ) : null}
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    className="p-1">
                    <h6 style={{ fontSize: '15px', marginTop: '' }}>{post.postBy}</h6>
                    <span
                      style={{
                        fontSize: '15px',
                        marginTop: '-3px',
                        overflow: 'hidden',
                      }}>
                      {post.content}
                    </span>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}

export default TopPosst;
