import React, { useState, useEffect } from 'react';
import { Box, Radio, Table, TableBody, TableCell, TableContainer, TableRow, Typography, Button } from '@mui/material';
import { useSubAdminCSV } from 'contexts/SubAdminCSVContext';
import YuvaLoader from 'pages/Forum/components/Loader/YuvaLoader';

const HeaderSelection = () => {
    const { parsedData, selectedSheet, selectedHeaderRow, setSelectedHeaderRow, loading, setLoading } = useSubAdminCSV();
    const sheetData = parsedData && Object.values(parsedData)[selectedSheet];
    const [selectedRowIndex, setSelectedRowIndex] = useState(selectedHeaderRow ?? 0);
    const [visibleRows, setVisibleRows] = useState(20);

    // Set default selected row when component loads or selectedHeaderRow changes
    useEffect(() => {
        if (selectedHeaderRow !== null && selectedHeaderRow !== undefined) {
            setSelectedRowIndex(selectedHeaderRow);
        }
    }, [selectedHeaderRow]);

    // Manage loading state when sheetData changes
    useEffect(() => {
        setLoading(!sheetData);
    }, [sheetData, setLoading]);

    // Handle row selection with loading state
    const handleRowSelection = (index) => {
        setLoading(true);
        setSelectedRowIndex(index);
        setSelectedHeaderRow(index);
        setTimeout(() => setLoading(false), 500);
    };

    // Show more rows on button click
    const handleShowMore = () => {
        setVisibleRows((prev) => prev + 20);
    };

    if (!sheetData) {
        return (
            <Typography variant="body1" color="textSecondary">
                No data available for the selected sheet.
            </Typography>
        );
    }

    return (
        <Box sx={{ padding: 2, position: 'relative' }}>
            {loading && <YuvaLoader setShow={false} show={loading} />}
            <TableContainer>
                <Table>
                    <TableBody>
                        {sheetData.slice(0, visibleRows).map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                <TableCell padding="checkbox">
                                    <Radio
                                        checked={rowIndex === selectedRowIndex}
                                        onChange={() => handleRowSelection(rowIndex)}
                                    />
                                </TableCell>
                                {row.map((cell, cellIndex) => (
                                    <TableCell key={cellIndex} align="center">
                                        {cell ?? ''}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {visibleRows < sheetData.length && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained" onClick={handleShowMore}>
                        Show More
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default HeaderSelection;
