import React from "react";
import StudentApply from "../Sewa-Student/StudentApply.jsx";
import StudentPOW from "../Sewa-Student/StudentPOW.jsx";
import StudentProjectDetails from "../Sewa-Student/ProjectDetails";
import MentorDetails from "../Sewa-Institute/MentorDetails";
import InstituteProjectDetails from "../Sewa-Institute/ProjectDetails";
import InstituteStudentDetails from "../Sewa-Institute/StudentDetails";
import CategoriesDetails from "../Sewa-Institute/CategoriesDetails";
import ProjectRequests from "../Sewa-Mentor/ProjectRequests";
import MentorProjectDetails from "../Sewa-Mentor/ProjectDetails";
import MentorStudentDetails from "../Sewa-Mentor/StudentDetails";
import Marking from "../Sewa-Mentor/Marking";
// import AddMentor from "../Sewa-Institute/AddMentor.jsx";
import AddCategory from "../Sewa-Institute/AddCategory.jsx";
import MentorDOC from "../Sewa-Institute/MentorDoc.jsx";
import PromiseDOC from "../Sewa-Student/PromiseDOC.jsx";
import StudentDOC from "../Sewa-Institute/EditPolicy.jsx";
// import SchoolDOC from "../Sewa-Institute/PdfViewer.jsx";
import MentorPOW from "../Sewa-Mentor/MentorPOW.jsx";
import WorkProof from "../Sewa-Mentor/WorkProof.jsx";
import InstituteWorkProof from "../Sewa-Institute/instituteWorkProof.jsx";
import StudentDocument from "../Sewa-Mentor/StudentDocuments.jsx";
import HourlySchedule from "../Sewa-Student/HourlySchedule.jsx";
// import PolicyDocuments from "../Sewa-Institute/PolicyDocument.jsx";

const SewaRenderer = ({ mainTopic, subTopic }) => {





  let careerComponent = null;

  // const [openDialog, setOpenDialog] = useState(true);
  // const [selectedOption, setSelectedOption] = useState('');

  // const handleSelectOption = (option) => {
  //     setSelectedOption(option);
  // };

  // const handleConfirm = () => {
  //     setOpenDialog(false);
  // };



  console.log('Main Topic:', mainTopic);
  console.log('Sub Topic:', subTopic);

  if (subTopic) {
    switch (subTopic.title) {
      // case "Add Category":
      //   careerComponent = <AddCategory />;
      //   break;
      // case "View Category":
      //   careerComponent = <CategoriesDetails />;
      //   break;
      // case "Add Mentor":
      //   careerComponent = <AddMentor />;
      //   break;
      case "My Promise":
        careerComponent = <PromiseDOC />;
        break;
      case "Hourly Schedule":
        careerComponent = <HourlySchedule />;
        break;
      case "Mentor Documents":
        careerComponent = <MentorDOC />;
        break;
      case "Student Documents":
        careerComponent = <StudentDOC />;
        break;
      case "Mentor Document":
        careerComponent = <MentorPOW />;
        break;
      case "Student Document":
        careerComponent = <StudentDocument />;
        break;
      // case "View Mentor":
      //   careerComponent = <MentorDetails />;
      //   break;
      default:
        careerComponent = null;
    }
  } else {
    switch (mainTopic?.title) {
      case "Apply":
        careerComponent = <StudentApply />;
        break;
      case "Add Mentor":
        careerComponent = <MentorDetails />;
        break;
      case "Proof of Work":
        careerComponent = <StudentPOW />;
        break;
      case "Status":
        careerComponent = <StudentProjectDetails />;
        break;
      case "View Projects":
        careerComponent = <InstituteProjectDetails />;
        break;
      case "Student Details":
        careerComponent = <InstituteStudentDetails />;
        break;
      case "Project Requests":
        careerComponent = <ProjectRequests />;
        break;
      case "Project Details":
        careerComponent = <MentorProjectDetails />;
        break;
      case "Student":
        careerComponent = <MentorStudentDetails />;
        break;
      case "Marking":
        careerComponent = <Marking />;
        break;
      case "Work Proof":
        careerComponent = <WorkProof />;
        break;
      case "Work Proofs":
        careerComponent = <InstituteWorkProof />;
        break;
      case "Category":
        careerComponent = <CategoriesDetails />;
        break;
      // case "Policy Document":
      //   careerComponent = <PolicyDocuments />;
      //   break;
      default:
        careerComponent = null;
    }
  }

  return <>{careerComponent}</>;
};

export default SewaRenderer;