import React, { useState } from "react";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import { useCareerContext } from "contexts/CareerContext";
import { apiJson } from "api";
import { Popup } from "layout/Popup";
import YuvaLoader from "pages/Forum/components/Loader/YuvaLoader";


const ClassCardView = () => {
	const {
		role,
		selectedNavOption,
		setSelectedSubNavOption,
		setClassInfo,
		setStudentsList,
		riasecClasses,

	} = useCareerContext();
	const [loading, setLoading] = useState(false);
	const userData = JSON.parse(localStorage.getItem("user"));
	const instituteId =
		role === "teacher"
			? userData.instituteId
			: role === "institute"
			? userData.id
			: "";

	// Default card count based on the selected navigation option
	let classNames = [];
	if (role === "institute") {
		classNames = Array.from({ length: 7 }, (_, i) => `Class ${i + 6}`); // Classes 6 to 12
	} else if (role === "teacher") {
		if (selectedNavOption === "RIASEC" && riasecClasses.length > 0) {
			classNames = riasecClasses.map((classId) => `Class ${classId}`);
		}
	}

	// Handle card click to fetch students for a specific class
	const handleCardClick = async (className) => {
		if (!instituteId) {
			console.error("Institute ID is missing in userData.");
			return; // Exit if instituteId is not available
		}
		setLoading(true);
		try {
			const classNumber = className.split(" ")[1];
			// console.log(`Data being sent: ${instituteId} and Class: ${classNumber}`);
			const res = await apiJson.post(
				`/api/v2/riasecTest/riasec-attempt-status`,
				{
					instituteId: instituteId,
					Class: classNumber,
				}
			);
			console.log("Response received:", res);
			const studentsList = res?.data?.result || [];

			if (studentsList.length > 0) {
				setStudentsList(studentsList);
			} else {
				setStudentsList([]);
				Popup("error", `No students found for Class ${className}`, "", 5000);
			}

			setClassInfo({ className });
			setSelectedSubNavOption("Student's List");
		} catch (error) {
			if (error?.response?.status === 404) {
				console.error("Error fetching student list:", error);
				Popup("error", `No students found for Class ${className}`, "", 5000);
			} else {
				console.error("Error fetching student list:", error);
				Popup("error", "Error fetching student list", "", 5000);
			}
			setStudentsList([]);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box sx={{ flexGrow: 1, padding: 2 }}>
			<div
				style={{
					marginTop: "5px",
					marginLeft: "10px",
				}}
			>
				<p style={{ fontSize: "24px" }}>
					<strong>
						<span style={{ color: "#2356fe" }}>Classes</span> List
					</strong>
				</p>
			</div>

			{loading && <YuvaLoader setShow={false} show={loading} />}
			<Grid container spacing={2} sx={{ height: "100%" }}>
				{classNames.map((className, index) => (
					<Grid item xs={12} sm={6} md={4} lg={4} key={index}>
						<Card
							id="raisecMainScreenButtonEffect"
							onClick={() => handleCardClick(className)}
							sx={{
								borderColor: "#2356fe",
								borderWidth: 2,
								borderStyle: "solid",
								color: "#2356fe",
								borderRadius: "16px",
								position: "relative",
								// "&:hover": {},
								transition: "300s",
								cursor: "pointer",
								height: "100%",
							}}
						>
							<img
								src="/button-top.png"
								alt=""
								style={{
									position: "absolute",
									top: 0,
									left: 0,
									height: "45px",
									opacity: 0,
								}}
							/>
							<img
								src="/button-bottom.png"
								alt=""
								style={{
									position: "absolute",
									bottom: 0,
									right: 0,
									height: "45px",
									opacity: 0,
								}}
							/>

							<CardContent sx={{ textAlign: "center", height: "100%" }}>
								<Typography variant="h5" component="div">
									{className}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default ClassCardView;
