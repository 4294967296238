import React from 'react'
import { OnBoardingNav, OnBoardingSidebar } from '.'
import { Outlet } from 'react-router-dom'
import { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";
import "../style/OnBoardingStyle.css"


export const OnBoardingMainPage = () => {
  return (
    <>
      <ToastContainer position="bottom-right" limit={3} autoClose={3000} />
      <Toaster position="bottom-right" />
      <div className="container-fluid">
        <div className='row'>
          <div className='d-none d-md-block col vh-100 col-md-4 p-0'>
            <OnBoardingSidebar />
          </div>

          <div className='col-12 col-md-8 px-2 px-md-4'>
            <div className="container">
              <div className='d-flex flex-column'>
                <div className='py-4 bg-white sticky-top'>
                  <OnBoardingNav />
                </div>

                <div className='row'>
                  <div className='col-12 col-lg-10 zero-scroll ' style={{height:'80vh', overflowX:'hidden'}}>
                    <Outlet />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


