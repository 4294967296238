import React, { useEffect, useState } from 'react'
import KeyboardArrowUpTwoToneIcon from '@mui/icons-material/KeyboardArrowUpTwoTone';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import ThumbUpAltTwoToneIcon from '@mui/icons-material/ThumbUpAltTwoTone';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAltTwoTone';

import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbDownAltTwoToneIcon from '@mui/icons-material/ThumbDownAltTwoTone';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import MoodTwoToneIcon from '@mui/icons-material/MoodTwoTone';
import AlternateEmailTwoToneIcon from '@mui/icons-material/AlternateEmailTwoTone';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import { DiscussionBoardComments } from '.';
import moment from 'moment';
import { Mention, MentionsInput } from 'react-mentions';

import { Avatar, Button, IconButton } from '@mui/material';
import { useGlobalContext } from 'global/context';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';



export const DiscussionBoardMessageNew = ({ message, time, comments, socket }) => {
    const { id } = useParams()
    const { userData } = useGlobalContext()
    const [isclicked, setIsclicked] = useState(false)
    const [MessageId, setMessageId] = useState([])
    const [currentComments, setCurrentComments] = useState([])

    const handleMessaageIcon = () => {
        setIsclicked(prev => !prev)
    }

    useEffect(() => {
        setMessageId(comments?.map((item) => item.msgId))
        setCurrentComments(comments?.filter((c) => c?.msgId === message?.id))
    }, [comments])



    return (
        <>
            <section className="comments-thread-list " >
                <div className="comment-thread-container hover">

                    {/* <LatestChatDesign message={message} userData={userData} currentComments={currentComments} /> */}
                    <OldChatDesign message={message} userData={userData} currentComments={currentComments} MessageId={MessageId} time={time} isclicked={isclicked} handleMessaageIcon={handleMessaageIcon} socket={socket} discussionId={id} />


                </div>

            </section>

        </>
    )
}


const LatestChatDesign = ({ message, userData, currentComments, socket }) => {

    return (
        <div className="comment-thread" >
            {/* showing name and logo only */}
            {
                (message?.userId === userData?.id && message?.role === userData?.role) ?

                    <div className="comment-message-block d-flex align-items-center justify-content-end">


                        <div className="comment-message-container">
                            <div className="comment-message-text">
                                <div className="d-flex flex-column">
                                    <span style={{ color: "#ACACAC" }}>{moment(message?.time).fromNow()} &nbsp; &nbsp; <span className="DisName">You  </span></span>
                                </div>
                            </div>
                        </div>
                        <div className="comment-initials-container " aria-hidden="true">
                            <div className='mx-2' style={{ width: "37px", height: "37px", borderRadius: "50%", }}>
                                {/* <img className='w-100 h-100' src={message?.profile} alt={message?.name} /> */}
                                <Avatar src={message?.profile} alt={message?.name} />
                            </div>
                        </div>
                    </div>
                    :
                    <div className="comment-message-block d-flex align-items-center">
                        <div className="comment-initials-container " aria-hidden="true">
                            <div className='mx-2' style={{ width: "37px", height: "37px", borderRadius: "50%", }}>
                                {/* <img className='w-100 h-100' src={message?.profile} alt={message?.name} /> */}
                                <Avatar src={message?.profile} alt={message?.name} />
                            </div>
                        </div>

                        <div className="comment-message-container">
                            <div className="comment-message-text">
                                <div className="d-flex ">
                                    <span className="DisName">{message?.name} &nbsp; &nbsp;</span> <span style={{ color: "#ACACAC" }}>{moment(message?.time).fromNow()}</span>

                                </div>
                            </div>
                        </div>
                    </div>

            }
            {/* point bottom */}

            {
                (message?.isPoint && message?.userId === userData?.id && message?.role === userData?.role) ? (
                    <div className="d-flex justify-content-end me-3">
                        {/* showing point message  current */}
                        {message?.isPoint &&
                            (<div className='d-flex flex-column rounded-3 p-3' style={{ background: "#F8F4FC", maxWidth: '72%' }}>
                                <p className='DisPoint'>{message?.message}</p>
                                <div>
                                    <span style={{ color: "#4979d1" }}> <ThumbUpAltTwoToneIcon />&nbsp; &nbsp;<ThumbDownAltTwoToneIcon /></span>
                                </div>
                            </div>)
                        }
                    </div>) :
                    (message?.isPoint && message?.userId !== userData?.id && message?.role !== userData?.role) ? (
                        <div className="d-flex ms-4 justify-content-start">
                            {/* showing point message  other */}

                            {message?.isPoint &&
                                (<div className='d-flex flex-column rounded-3 p-3' style={{ background: "#F8F4FC", maxWidth: '72%' }}>
                                    <p className='DisPoint'>{message?.message}</p>
                                    <div>
                                        <span style={{ color: "#4979d1" }}> <ThumbUpAltTwoToneIcon />&nbsp; &nbsp;<ThumbDownAltTwoToneIcon /></span>
                                    </div>
                                </div>)
                            }
                        </div>)
                        // showing current user message
                        : (message?.userId === userData?.id && message?.role === userData?.role)
                            ? <div className=' me-4 text-end d-flex justify-content-end align-items-center'>
                                <p className='DisChat  rounded-3 border-1'>{message?.message}</p>
                            </div>

                            // showing other message
                            : <div className='ms-5 text-start d-flex justify-content-start align-items-center'>
                                <p className='DisChat  rounded-3 border-1'>{message?.message}</p>
                            </div>
            }
            {/* showing comments */}
            {(
                currentComments?.map((comment, i) => {
                    let showThread = currentComments.length === 1 ? false : i === (currentComments.length - 1) ? false : true
                    return (
                        <div className="d-flex px-5 " key={comment?.id}>
                            <DiscussionBoardComments data={comment} showThread={showThread} />
                        </div>
                    );
                })
            )}
        </div>
    )
}

const OldChatDesign = ({ message, userData, currentComments, MessageId, time, comments, isclicked, handleMessaageIcon, socket, discussionId }) => {
    const [likeCount, setLikecount] = useState(0)
    const [dislikeCount, setdisLikecount] = useState(0)
    const [commentsReply, setCommentsReply] = useState('')

    const [showReplyModal, setShowReplyModal] = useState(false)
    const handleLikeSubmit = (discussionId, id) => {
        socket?.emit('hitChatLike', { id, discussionId })
        // socket?.emit('getAllPrevChat', discussionId)
    }
    const handleDislikeSubmit = (discussionId, id) => {
        socket?.emit('hitChatdisLike', { id, discussionId })
        // socket?.emit('getAllPrevChat', discussionId)

    }


    useEffect(() => {
        if (message) {
            setLikecount(message?.likes)
            setdisLikecount(message?.dislikes)
        }
    }, [message])

    const mentionsInputStyle = {
        control: {
            fontSize: 12,
            // fontWeight: 'normal',
        },

        "&multiLine": {
            control: {

                minHeight: 30
            },
            highlighter: {
                padding: 9
            },
            input: {
                outline: "none",
                border: "none",
                padding: 9
            }
        },

        "&singleLine": {
            display: "inline-block",


            highlighter: {
                padding: 1
            },
            input: {
                outline: "none",
                border: "none",
                padding: 1
            }
        },

        suggestions: {
            list: {
                fontSize: 16
            },
            item: {
                padding: "5px 15px",

                "&focused": {
                    backgroundColor: "#cee4e5"
                }
            }
        }
    };
    const handleInputChange = (e, newValue, newPlainTextValue, mentions) => {
        setCommentsReply(newPlainTextValue);
    };
    const users = [
        {
            id: '1',
            display: 'Nitesh',
        },
        {
            id: '2',
            display: 'Shailesh',
        },
        {
            id: '3',
            display: 'Sahil'
        },
        {
            id: '4',
            display: 'Rahul'
        },
    ];
    const handleSubmitComment = (chatId) => {
        const data = {
            user_id: userData?.id,
            role: userData?.role,
            institute_id: userData?.instituteId,
            discussion_id: Number(discussionId),
            chat_id: chatId,
            content: commentsReply,
        }
        socket.emit('postChatReply', data)
        setShowReplyModal(false)
        setCommentsReply('')
        console.log(commentsReply)
    }

    const handleReply = (chatId) => {
        const trimmedValue = commentsReply.trim()
        if (trimmedValue) {
            handleSubmitComment(chatId)
        } else {
            console.log('you need to write something')
        }

    }

    const defaultBackgroundColor = 'red'



    return (
        <>

            <div className="comment-thread" key={message.id}>
                <div className=''  >
                    <div className="d-flex align-items-start">
                        <div className="comment-initials-container" aria-hidden="true">
                            <div className='ms-1' style={{ width: "37px", height: "37px", borderRadius: "50%", }}>
                                <Avatar src={message?.profile} alt={message?.name} />

                            </div>
                        </div>

                        {/* for vertical line if containing comment or votable point */}
                        {/* {currentComments.length > 0 || message?.isPoint ? (
                            <div className="comment-initials-vline"></div>) : null} */}
                        <div className="comment-message-container">
                            <div className="comment-message-text">
                                <div className="d-flex flex-column ">
                                    <span className="fs-6 fw-semibold mb-2 ">{message.name}</span>
                                    <span style={{ color: "#ACACAC" }}>{moment(message?.time).fromNow()}</span>
                                    {message?.isPoint ? (<p className='DisPointHead'>Added New Point to the discussion.</p>) : null}
                                </div>
                            </div>
                        </div>
                    </div>




                    {message?.isPoint ? (
                        <div className="comment-message-block">
                            <div className="comment-initials-container" aria-hidden="true">
                                <div className='mx-2' >
                                    <img src="/ui2.0dashboard/Group 288.png" alt="message" style={{ height: 23, width: 23, marginLeft: "6px" }} />
                                </div>
                            </div>
                            <div className="comment-message-container">

                                <div className="comment-message-text">
                                    {message?.isPoint ?
                                        (<div className='d-flex flex-column rounded-3 p-3' style={{ background: "#F8F4FC" }}>
                                            <p className='DisVPoint mb-3'>{message.message}</p>
                                            <div className='d-flex align-items-center justify-content-start'>

                                                <div className='me-3 d-flex align-items-center'>
                                                    <IconButton >
                                                        <span onClick={() => { handleLikeSubmit(discussionId, message?.id) }} className='cursor-pointer ' style={{ color: "#4979d1" }}> <ThumbUpAltTwoToneIcon /> </span>
                                                    </IconButton>
                                                    <span className='fs-6 pt-1'>{message?.likes || 0}</span>
                                                </div>


                                                <div className='d-flex align-items-center me-3'>

                                                    <IconButton>
                                                        <span onClick={() => handleDislikeSubmit(discussionId, message?.id)} className='cursor-pointer' style={{ color: "#4979d1" }}><ThumbDownAltTwoToneIcon /> </span>
                                                    </IconButton>
                                                    <span className='fs-6 pt-1'>
                                                        {message?.dislikes || 0}
                                                    </span>
                                                </div>

                                                <div className=''>
                                                    <IconButton className=''>
                                                        <span onClick={() => setShowReplyModal(true)} className='cursor-pointer fs-6 fw-semibold' style={{ color: "#4979d1" }}>Reply </span>
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>) : null

                                    }



                                    {currentComments.length > 0 &&
                                        <Button

                                            color="primary"
                                            size="small"
                                            variant="text" className='ms-2 mt-2 text-caplitalize' style={{ textTransform: 'capitalize' }} onClick={() => handleMessaageIcon(message.id)}>

                                            <span className='fs-6 fw-semibold text-caplitalize'>
                                                {currentComments?.length}&nbsp;{currentComments?.length <= 1 ? "Reply" : "Replies"
                                                } {isclicked ? <KeyboardArrowUpTwoToneIcon /> : <KeyboardArrowDownTwoToneIcon />}
                                            </span>


                                        </Button>}
                                </div>
                            </div>
                        </div>) : (<>
                            {/* showing message for normal chat */}
                            <div className="comment-message-container ms-5">

                                <div className="comment-message-text">
                                    <div className='d-flex flex-column rounded-3 p-3' style={{ background: "#7878781a" }}>
                                        <span className='DisPara '>{message.message}</span>

                                        {/* bottm chat  */}
                                        <div className='d-flex align-items-center justify-content-start '>

                                            <div className='me-3 d-flex align-items-center '>
                                                <IconButton >
                                                    <span onClick={() => { handleLikeSubmit(discussionId, message?.id) }} className='cursor-pointer color-black' > <ThumbUpOutlinedIcon className='fs-5' /> </span>
                                                </IconButton>
                                                <span className='fs-6 pt-1  color-black'>{message?.likes || 0}</span>
                                            </div>


                                            <div className='d-flex align-items-center me-3'>

                                                <IconButton>
                                                    <span onClick={() => handleDislikeSubmit(discussionId, message?.id)} className='cursor-pointer color-black' ><ThumbDownAltOutlinedIcon className='fs-5' /> </span>
                                                </IconButton>
                                                <span className='fs-6 pt-1 color-black'>
                                                    {message?.dislikes || 0}
                                                </span>
                                            </div>

                                            <div className=''>
                                                <IconButton className=''>
                                                    <span onClick={() => setShowReplyModal(true)} className='cursor-pointer fs-6 fw-semibold color-black' >Reply </span>
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* showing comment for non votable point */}
                                {currentComments.length > 0 &&
                                    <Button

                                        color="primary"
                                        size="small"
                                        variant="text" className=' mt-2 text-caplitalize' style={{ textTransform: 'capitalize' }} onClick={() => handleMessaageIcon(message.id)}>

                                        <span className='fs-6 fw-semibold text-caplitalize'>
                                            {currentComments?.length}&nbsp;{currentComments?.length <= 1 ? "Reply" : "Replies"
                                            } {isclicked ? <KeyboardArrowUpTwoToneIcon /> : <KeyboardArrowDownTwoToneIcon />}
                                        </span>


                                    </Button>}
                            </div>

                        </>)}



                </div>

                {isclicked ? currentComments?.map((comment) => {
                    return (
                        <div className="rounded-3 ms-5 py-2" style={{ transform: 'scale(.8)',  }}  key={comment.id}>
                            <DiscussionBoardComments data={comment} />
                        </div>
                    );
                }) : null}
                <hr style={{ margin: 0 }} />
            </div>

            <Modal centered show={showReplyModal} onHide={() => setShowReplyModal(false)}>
                <Modal.Header>
                    Reply to {message?.name}
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex ">
                        <div className='d-none d-md-block' style={{ width: "46px", height: "46px", borderRadius: "50%" }}>
                            <Avatar src={`${userData?.profile || userData?.logo}`} alt={userData?.first_name || userData?.institution_name} />

                        </div>
                        {/*<============= Message Box Ui Start ============> */}
                        <div className='w-100 rounded-3 ms-2 p-3' style={{ background: "#F3F3F3" }}>
                            {/* <<============= Tag anyone and message input field start ============>> */}
                            <div>

                                <span className='rounded-3 cursor-pointer' style={{ background: "#DBDBDB", padding: "5px", fontSize: "12px", color: "#525252" }} ><span className='d-inline-block text-center' style={{ background: "#9D9D9D", height: "19px", width: "19px", borderRadius: "9px", color: "#FFFFFF", marginRight: "5px" }}>#</span>Everyone in the discussion</span>
                                <div className='w-100'>

                                    <MentionsInput
                                        className="mentions__mention"
                                        placeholder='Start typing a message '
                                        value={commentsReply}
                                        onChange={handleInputChange}
                                        style={mentionsInputStyle} >
                                        <Mention displayTransform={(id, display) => `@${display}`}
                                            data={users}
                                            style={{ color: "red" }}
                                        />
                                    </MentionsInput>

                                </div>
                            </div>
                            {/* <<============= Bottom icon section and Button section start ============>> */}

                            <div className='"d-grid gap-2 d-md-flex justify-content-md-between mt-4'>
                                {/* <div className='cursor-pointer'>
                                    <MoodTwoToneIcon sx={{ color: "#FFB800", height: "17px", width: "17px", marginRight: "10px" }} />
                                    <AlternateEmailTwoToneIcon sx={{ color: "#3B1BFF", height: "17px", width: "17px", marginRight: "10px" }} />

                                </div> */}
                                <div className='d-flex justify-content-end'>
                                    <button onClick={() => setShowReplyModal(false)} className='rounded-2 px-3 py-2 mx-2' style={{ background: "#E3E3E3", color: "#535353", fontSize: "13px", fontWidth: 500 }}>Discard</button>
                                    <button onClick={() => {
                                        handleReply(message?.id)
                                    }} className='rounded-2 px-3 py-2' style={{ background: "#8CB3FF", color: "#000000", fontSize: "13px", fontWidth: 500 }} >Post</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}
