import React from 'react'
import { FaArrowLeftLong } from 'react-icons/fa6';

const CarbonCalculationResult = ({ onBack }) => {
    return (
        <div className='calc-result-container'>
            <div className="carbon-option-heading">
                <h2>Calculation</h2>
                <FaArrowLeftLong className="back-arrow" onClick={onBack} />
            </div>
            <div className="calc-results-wrapper">
                <div className="calc-result-card">
                    <h3>Per Day (KgCO2e)</h3>
                    <p>2.56</p>
                </div>
                <div className="calc-result-card">
                    <h3>Per Day (KgCO2e)</h3>
                    <p>18.00</p>
                </div>
                <div className="calc-result-card">
                    <h3>Per Day (KgCO2e)</h3>
                    <p>78.00</p>
                </div>
                <div className="calc-result-card">
                    <h3>Per Day (KgCO2e)</h3>
                    <p>986.00</p>
                </div>
            </div>
        </div>
    )
}

export default CarbonCalculationResult