import { Avatar, Button, Divider } from "@mui/joy";
import { api, apiJson } from "api";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import { Modal } from "react-bootstrap";
import { useGlobalContext } from "global/context";
import SendIcon from "@mui/icons-material/Send";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

export const SubAdminSingleStateData = () => {
  const [highlightedNumbers, setHighlightedNumbers] = useState([]);

  useEffect(() => {
    const storedNumbers =
      JSON.parse(localStorage.getItem("highlightedNumbers")) || [];
    setHighlightedNumbers(storedNumbers);
  }, []);

  const handleSendWhatsapp = (contact) => {
    if (!highlightedNumbers.includes(contact)) {
      const updatedNumbers = [...highlightedNumbers, contact];
      setHighlightedNumbers(updatedNumbers);

      localStorage.setItem(
        "highlightedNumbers",
        JSON.stringify(updatedNumbers)
      );
    }

    sendwhatsapp(contact, whatsAppContent);
  };

  const [highlightedEmails, setHighlightedEmails] = useState([]); // Store highlighted emails

  // Load highlighted emails from localStorage when the component mounts
  useEffect(() => {
    const savedHighlightedEmails = JSON.parse(
      localStorage.getItem("highlightedEmails")
    );
    if (savedHighlightedEmails) {
      setHighlightedEmails(savedHighlightedEmails);
    }
  }, []);

  // Save highlighted emails to localStorage whenever they change
  useEffect(() => {
    if (highlightedEmails.length > 0) {
      localStorage.setItem("highlightedEmails", JSON.stringify(highlightedEmails));
    }
  }, [highlightedEmails]);

  // Function to toggle email highlight
  const toggleHighlightEmail = (email) => {
    if (!highlightedEmails.includes(email)) {
      setHighlightedEmails((prev) => [...prev, email]);
    }
  };
  

  const { id } = useParams();
  const navigate = useNavigate();
  const { userData } = useGlobalContext();
  const [singleStateData, setSingleStateData] = useState();
  const [handleShowEdit, setHandleShowEdit] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [commentsData, setCommentsData] = useState([]);
  const [comments, setComments] = useState({ text: "" });
  const [detailsStatus, setDetailsStatus] = useState({
    call_status: "",
    registration_status: "",
    detail_sent_status: "",
    reminder_status: "",
  });
  const [commentId, setCommentId] = useState(null);
  console.log(commentId);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setComments({ ...comments, [name]: value });
  };

  const getSingleStateData = () => {
    apiJson(`api/v2/admin/getSingleAdminStateData/${id}`)
      .then((res) => {
        setSingleStateData(res?.data?.singleSubadminStateData);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  console.log(singleStateData);
  // commetnsCreated
  const commentsCreated = () => {
    const data = {
      text: comments.text,
      state: singleStateData.state,
      postId: id,
    };
    if (commentId) {
      const data = {
        text: comments.text,
        // state: singleStateData.state,
        id: commentId,
      };
      apiJson
        .put(`api/v2/admin/editComments/${commentId}`, data)
        .then((res) => {
          getComments();
          setCommentId(null);
          setComments({ text: "" });
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else if (comments.text) {
      apiJson
        .post("api/v2/admin/adminCommentsCreated", data)
        .then((res) => {
          setComments({ text: "" });
          getComments();
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  // apiJson.get(`api/v2/admin/getComments/${singleStateData?.state}`)

  // getCommentes
  const getComments = () => {
    apiJson
      .get(`api/v2/admin/getComments/${id}`)
      .then((res) => {
        setCommentsData(res?.data?.getAllComments);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // form details status
  const handleDetailStatus = (e) => {
    const { name, value } = e.target;
    const updateStatus = {
      ...detailsStatus,
      [name]: value,
      subAdminId: userData?.id,
      project: singleStateData?.projects,
    };
    setDetailsStatus({ ...updateStatus });

    apiJson
      .put(`api/v2/admin/SchoolCallingStatus/${id}`, updateStatus)
      .then((res) => {
        getSchoolCallingStatus();
        console.log(res?.data);
        toast.success("Calling Status Updated Successfully");
      })
      .catch((err) => {
        console.log(err.message);
        toast.error("Failed to update Calling Status");
      });
  };
  console.log("detailsStatus,", detailsStatus);

  // details post status
  const getSchoolCallingStatus = () => {
    apiJson
      .get(`api/v2/admin/getSchoolCallingStatus/${id}`)
      .then((res) => {
        // setDetails(res?.data?.getSchoolStatus);
        setDetailsStatus(res?.data?.getSchoolStatus);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    getSingleStateData();
    getComments();
    getSchoolCallingStatus();
  }, []);

  const handleUpdateClick = (comment) => {
    setComments({ text: comment?.text });
    setCommentId(comment?.id);
  };

  const editComments = () => {
    const data = {
      text: comments.text,
      // state: singleStateData.state,
      // postId: id
    };
    apiJson
      .put(`api/v2/admin/editComments/${id}`, data)
      .then((res) => {
        getComments();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ cursor: "pointer" }}
    >
      {children}
    </span>
  ));

  const sendwhatsapp = (phonenumber, text) => {
    // console.log("text", text);
    var url = "https://wa.me/" + phonenumber + "?text=" + text;
    console.log("url", url);
    window.open(url, "_blank");
  };

  // const sendEmail = (value) => {
  const sendEmail = (email) => {
    debugger;
    apiJson
      .post(`api/v2/user/circular/${email}/nipamCircular`)
      .then((res) => {
        console.log("email sent to ", email);
        Swal.fire({
          title: "Email Send",
          text: "Email send to " + email,
          icon: "success",
        });
      })
      .catch((err) => {
        console.log("email not sent");
      });
  };

  const whatsAppContent = `
  Subject:-Follow-Up on CBSE has issued Circular No: Acad-37/2024 dated 16.04.2024 regarding the National Intellectual Property Awareness Mission (NIPAM)%0a

Dear Principal,%0a
I hope this message finds you well.%0a
%0a
Following our previous communication regarding the National Intellectual Property Awareness Mission (NIPAM), as outlined in CBSE Circular No: Acad-37/2024 dated 16.04.2024, we would like to provide additional guidance for the bulk registration process.%0a
%0a
Action Required:%0a
%0a
1. Register the School: Please register your school for NIPAM at (http://www.yuvamanthan.org/NIPAM).%0a
2. Appoint a Coordinator: Appoint a teacher coordinator for this initiative and share their details with us.%0a
%0a
3. Encourage Participation: Motivate students to join the 'IP Innovation Club' and ensure both students and teachers review the e-Modules on NIPAM.%0a
%0a
4.Bulk Registration: To facilitate the bulk registration process for all students and teachers, you can either:%0a
Use the bulk registration feature available on our website at www.yuvamanthan.org%0a
Alternatively, you may share the file with participant details via email to connect@yuvamanthan.org or by sending it to the phone number 9560771911.%0a
5. Certificates: Upon successful completion of the course, certificates will be issued by the Ministry of Commerce and Industry, Government of India. The provided email addresses in your CSV file will be used to send these certificates.%0a
%0a
Your prompt action in these matters is essential for ensuring full participation and benefiting from this valuable initiative. Should you require further assistance, please do not hesitate to contact us at [connect@yuvamanthan.org] or via the provided phone number.%0a
%0a
Thank you for your cooperation.%0a
%0a
Best regards,%0a
  `;

  //   var whatsAppContent = `
  // Dear Principal,%0a
  // %0a
  // CBSE has issued Circular No: Acad-37/2024 dated 16.04.2024 regarding the National Intellectual Property Awareness Mission (NIPAM), a flagship program of the Ministry of Commerce and Industry, Government of India. NIPAM aims to offer basic intellectual property (IP) training and instill foundational knowledge of Intellectual Property Rights (IPR) in educational institutions.%0a
  // %0a
  // In this regard, you are requested to take the following actions:
  // %0a
  // 1. Register the school for NIPAM at www.yuvamanthan.org/NIPAM.%0a
  // 2. After registration, invite students and teachers to join through a unique link provided in the school dashboard.%0a
  // 3. Appoint a teacher coordinator for the initiative and share the details with us.%0a
  // 4. Encourage students to join the 'IP Innovation Club' to focus on entrepreneurial and innovative instincts.%0a
  // 5. Encourage students and teachers go through the e-Modules on NIPAM.%0a
  // %0a
  // Please take the necessary actions as per the CBSE circular to ensure the participation of students and teachers in this important mission. It is essential to nurture the spirit of creativity, innovation, and entrepreneurship among our students.%0a
  // %0a
  // For any further assistance, please feel free to reach out to us on this number or email us at connect@yuvamanthan.org.%0a
  // %0a
  // Best regards,%0a
  // Yuvamanthan Team%0a
  // %0a
  // [Download the Circular PDF](${"https://cbseacademic.nic.in/web_material/Circulars/2024/37_Circular_2024.pdf"})`;

  const AddEmailAddressAndMobileNumber = () => {
    const formik = useFormik({
      initialValues: {
        email: "",
        mobileNumber: "",
      },
      validationSchema: Yup.object().shape({
        email: Yup.string()
          .email("Enter a valid email")
          .required("Email is required"),
        mobileNumber: Yup.string()
          .matches(/^\d+$/, "Enter a valid number")
          .min(10, "Min 10 digits required")
          .max(15, "Max 15 digits allowed")
          .required("Mobile number is required"),
      }),
    });
  
    const handleEmailSubmit = async (e) => {
      e.preventDefault();
      if (formik.values.email) {
        try {
          await apiJson.post(`api/v2/admin/addSingleCbseAltData/${id}`, {
            email: formik.values.email,
          });
          getSingleStateData();
          toast.success("Email Added Successfully");
        } catch (error) {
          console.error("Failed to post email: ", error);
          toast.error("Failed to post email");
        }
      }
    };
  
    const handleMobileSubmit = async (e) => {
      e.preventDefault();
      if (formik.values.mobileNumber) {
        try {
          await apiJson.post(`api/v2/admin/addSingleCbseAltData/${id}`, {
            mobileNumber: formik.values.mobileNumber,
          });
          getSingleStateData();
          toast.success("Mobile Number Added Successfully");
        } catch (error) {
          console.error("Failed to post mobile number: ", error);
          toast.error("Failed to post mobile number");
        }
      }
    };
  
    return (
      <form>
        <div className="row mt-2">
          <div className="fs-6 fw-600">Add Email</div>
          <div className="col-12 col-lg-9 d-flex align-items-center fs-6 fw-500">
            <TextField
              id="email"
              label="Enter Email"
              variant="standard"
              className="me-2"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <Button
              className="px-2 py-1"
              style={{ backgroundColor: "#0047AB" }}
              onClick={handleEmailSubmit}
            >
              <CheckIcon />
            </Button>
          </div>
        </div>
        <div className="row mt-2">
          <div className="fs-6 fw-600">Add Number</div>
          <div className="col-12 col-lg-9 d-flex align-items-center fs-6 fw-500">
            <TextField
              id="mobileNumber"
              label="Enter Number"
              variant="standard"
              className="me-2"
              value={formik.values.mobileNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)
              }
              helperText={
                formik.touched.mobileNumber && formik.errors.mobileNumber
              }
            />
            <Button
              className="px-2 py-1"
              style={{ backgroundColor: "#0047AB" }}
              onClick={handleMobileSubmit}
            >
              <CheckIcon />
            </Button>
          </div>
        </div>
      </form>
    );
  };
  

  const handleDeleteContact = async (id) => {
    try {
      await apiJson.delete(`api/v2/admin/deleteSingleCbseAltDataContact/${id}`);
      getSingleStateData();
    } catch (error) {
      console.error("Failed to delete number: ", error);
    }
  };

  const handleDeleteEmail = async (id) => {
    try {
      await apiJson.delete(`api/v2/admin/deleteSingleCbseAltDataEmail/${id}`);
      getSingleStateData();
    } catch (error) {
      console.error("Failed to delete email: ", error);
    }
  };

  return (
    <div>
      <div className="col-12 px-1 mx-auto">
        <div className="d-flex justify-content-between">
          <div>
            <span className="fs-3 fw-500 d-flex align-items-center">
              CBSE Details
            </span>
          </div>
          {/* <Button className='py-0 px-1' onClick={()=> {navigate('/subAdmin')}}>Previous</Button> */}
        </div>

        <div className="shadow-lg border-2 rounded-2 p-3">
          <div className="row mt-2">
            <div className="col-12 col-lg-2">
              <div className="fw-600">Affiliation No</div>
              <div className="">{singleStateData?.affliate_number}</div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="fw-600">Institute Name</div>
              <div className="">
                {singleStateData?.institution_name?.toUpperCase()}
              </div>
            </div>

            <div className="col-12 col-lg-4">
              <div className="fw-600 fs-6 ">Address</div>
              <div className="">
                <span className="text-grey">
                  {singleStateData?.address?.toUpperCase()}
                </span>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="fw-600 fs-6">State</div>
              <div className="">{singleStateData?.state?.toUpperCase()}</div>
            </div>
            <div className="col-12 col-lg-2 mt-2">
              <div className="fw-600 fs-6">Pincode</div>
              <div className="">{singleStateData?.pincode}</div>
            </div>
            <div className="col-12 col-lg-2 mt-2">
              <div className="fs-6 fw-600">Projects</div>
              <div className="">{singleStateData?.projects?.toUpperCase()}</div>
            </div>
          </div>

          <div className="row mt-4">
            {/* District Information (Currently commented out) */}
            {/* <div className='col-12 col-lg-3'>
        <div className='fw-600 fs-6'>District</div>
        <div className=''>{singleStateData?.district}</div>
    </div> */}

<div className="col-12 col-lg-6 col-xl-4 mt-2">
  <div className="fw-600 fs-6">Contact:</div>

  <div
    className="contacts-container"
    style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
  >
    {/* Main Contact List */}
 { /*  {singleStateData?.contact?.some(
      (contact) => contact !== null && contact !== ""
    ) && <div className="fw-600 fs-6">Contact:</div>} */}

    {singleStateData?.contact
      ?.filter((contact) => contact !== null && contact !== "")
      .map((contact, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: highlightedNumbers.includes(contact)
              ? "#ffcccb" // Highlight the contacted number with light red
              : "#fff", // Default background for uncontacted numbers
            boxShadow: "0px 1px 4px rgba(0,0,0,0.1)",
          }}
        >
          <span style={{ flex: 1, fontSize: "16px", color: "black" }}>
            {contact}
          </span>

          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {/* Call button */}
            <a
              className="p-2 rounded-2"
              href={`tel:${contact}`}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "8px",
              }}
            >
              <PhoneEnabledOutlinedIcon
                style={{ fontSize: "20px", background: "transparent", color: "black" }} // Remove background and set icon color to black
              />
            </a>

            {/* WhatsApp button */}
            <Button
              className="px-2 py-1"
              style={{
                backgroundColor: "#25D366",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "8px 12px",
                borderRadius: "5px",
              }}
              onClick={() => handleSendWhatsapp(contact)}
            >
              <WhatsAppIcon
                style={{ marginRight: "5px", fontSize: "20px" }}
              />
              CBSE Circular
            </Button>
          </div>
        </div>
      ))}

    {/* Alternate Contact Numbers */}
    {singleStateData?.altDetails?.some(
      (item) => item.contact != null
    ) && (
      <div className="fw-600 fs-6">Alternate Contact Numbers:</div>
    )}

    {singleStateData?.altDetails
      ?.filter(
        (detail) => detail.contact !== null && detail.contact !== ""
      )
      .map((detail, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: highlightedNumbers.includes(
              detail.contact
            )
              ? "#ffcccb" // Highlight the contacted number with light red
              : "#fff", // Default background for uncontacted numbers
            boxShadow: "0px 1px 4px rgba(0,0,0,0.1)",
          }}
        >
          <span style={{ flex: 1, fontSize: "16px", color: "black" }}>
            {detail.contact}
          </span>

          {/* Call button */}
          <a
            className="p-2 rounded-2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "8px",
            }}
            href={`tel:${detail.contact}`}
          >
            <PhoneEnabledOutlinedIcon
              style={{ fontSize: "20px", background: "transparent", color: "black" }} // Remove background and set icon color to black
            />
          </a>

          {/* WhatsApp button */}
          <Button
            style={{
              backgroundColor: "#25D366",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              padding: "8px 12px",
              borderRadius: "5px",
            }}
            onClick={() => handleSendWhatsapp(detail.contact)}
            className="px-2 py-1"
          >
            <WhatsAppIcon
              style={{ marginRight: "5px", fontSize: "20px" }}
            />
            CBSE Circular
          </Button>
        </div>
      ))}
  </div>
</div>



<div className="col-12 col-lg-6 col-xl-4 mt-2">
  <div className="fs-6 fw-600">Email:</div>
  <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
    {/* Main Email */}
    {singleStateData?.email.length > 0 &&
      singleStateData?.email.map((item, index) =>
        item ? (
          <div
            key={`email-${index}`}
            className="fs-6 fw-500 d-flex align-items-center"
            style={{
              padding: "10px", // Padding for alignment, matching contact section
              borderRadius: "5px",
              backgroundColor: highlightedEmails.includes(item)
                ? "#ffcccb" // Highlight the email with the same color as contact section
                : "transparent",
              boxShadow: "0px 1px 4px rgba(0,0,0,0.1)",
            }}
          >
            <a href={`mailto:${item}`} className="d-flex align-items-center">
              <div
                className="fs-8 fw-500 me-2"
                style={{
                  wordBreak: "break-all",
                  color: "black", // Change email color to black
                }}
              >
                {item}
              </div>
              <span
                className="py-1 px-2 rounded-2"
                style={{
                  background: "transparent", // Remove background of the icon
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <EmailOutlinedIcon
                  onClick={() => toggleHighlightEmail(item)} // Toggle highlight on click
                  style={{ color: "black" }} // Set email icon color to black
                />
              </span>
            </a>
          </div>
        ) : null
      )}

    {/* Alternate Emails */}
    {singleStateData?.altDetails?.some((item) => item.email) && (
      <div className="fw-600 fs-6">Alternate Emails:</div>
    )}
    {singleStateData?.altDetails
      ?.filter((detail) => detail.email)
      .map((detail, index) =>
        detail.email ? (
          <div
            key={`alt-email-${index}`}
            className="fs-6 fw-500 my-2 d-flex align-items-center"
            style={{
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: highlightedEmails.includes(detail.email)
                ? "#ffcccb" // Highlight with the same color
                : "transparent",
              boxShadow: "0px 1px 4px rgba(0,0,0,0.1)",
            }}
          >
            <a
              href={`mailto:${detail.email}`}
              className="d-flex align-items-center"
            >
              <div
                className="fs-8 fw-500 me-2"
                style={{
                  color: "black", // Change alternate email color to black
                }}
              >
                {detail.email.toLowerCase()}
              </div>
              <span
                className="py-1 px-2 rounded-2"
                style={{
                  background: "transparent", // Remove background of the icon
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <EmailOutlinedIcon
                  onClick={() => toggleHighlightEmail(detail.email)} // Toggle highlight
                  style={{ color: "black" }} // Set email icon color to black
                />
              </span>
            </a>
            <button
              className="px-2 py-1 rounded-2"
              style={{
                backgroundColor: "#ff0000",
                marginLeft: "10px",
              }}
              onClick={async () => {
                const res = await Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                });
                if (res.isConfirmed) {
                  handleDeleteEmail(detail.id);
                  Swal.fire({
                    title: "Deleted!",
                    text: "Email has been deleted.",
                    icon: "success",
                  });
                }
              }}
            >
              <DeleteIcon />
            </button>
          </div>
        ) : null
      )}
  </div>
</div>



            <div className="col-12 col-lg-6 col-xl-4 mt-2">
              <AddEmailAddressAndMobileNumber />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-6 12 col-md-6 col-lg-3">
              <div className="mb-2">
                <span className="fw-600">Calling</span>
              </div>
              <select
                className="rounded shadow-sm border-1 w-100 px-2 py-1"
                style={{ height: "35px" }}
                name="call_status"
                value={detailsStatus?.call_status}
                onChange={handleDetailStatus}
              >
                <option value="">Select</option>
                <option value="connected">Connected</option>
                <option value="ringing">Ringing</option>
                <option value="disconnected">Disconnected</option>
                <option value="wrongnumber">Wrong Number</option>
                <option value="cancelled">Cancelled</option>
                <option value="not interested">Not Interested</option>
              </select>

              <div className="mt-1">
                <span className="text-capitalize fs-6 ps-2 fw-500">
                  {detailsStatus?.call_status}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-6 col-lg-3">
              <div className="mb-2">
                <span className="fw-600">Registration</span>
              </div>
              <select
                className="rounded shadow-sm border-1 w-100 px-2 py-1"
                style={{ height: "35px" }}
                name="registration_status"
                value={detailsStatus?.registration_status}
                onChange={handleDetailStatus}
              >
                <option value="">Select</option>
                <option value="done">Done</option>
                <option value="pending">Pending</option>
              </select>

              <div className="mt-1">
                <span className="text-capitalize fs-6 ps-2 fw-500">
                  {detailsStatus?.registration_status}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-6 col-lg-3">
              <div className="mb-2">
                <span className="fw-600">Details (Document)</span>
              </div>
              <select
                className="rounded shadow-sm border-1 w-100 px-2 py-1"
                style={{ height: "35px" }}
                name="detail_sent_status"
                value={detailsStatus?.detail_sent_status}
                onChange={handleDetailStatus}
              >
                <option value="">Select</option>
                <option value="sent">Sent</option>
                <option value="notsent">NotSent</option>
              </select>

              <div className="mt-1">
                <span className="text-capitalize fs-6 ps-2 fw-500">
                  {detailsStatus?.detail_sent_status}
                </span>
              </div>
            </div>
            <div className="col-6 col-md-6 col-lg-3">
              <div>
                <span className="fw-600">Follow-Up</span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <input
                  className="rounded shadow-sm border-1 w-100 ps-2"
                  style={{ height: "35px" }}
                  placeholder="followup"
                  name="reminder_status"
                  value={detailsStatus?.reminder_status}
                  onChange={(e) =>
                    setDetailsStatus({
                      ...detailsStatus,
                      reminder_status: e.target.value,
                    })
                  }
                />

                <Button
                  className="px-2 py-1 rounded-2"
                  onClick={handleDetailStatus}
                  style={{
                    backgroundColor: "#0047AB",
                  }}
                >
                  <CheckIcon />
                </Button>
              </div>
              <div className="mt-1">
                <span className="text-capitalize fs-6 ps-2 fw-500">
                  {detailsStatus?.reminder_status}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-1 rounded-2 border-3 shadow-sm p-3 mb-1 bg-grey">
          <div>
            <span
              className="fs-5 fw-600 text-capitalize"
              style={{ color: "#434343" }}
            >
              Comments
            </span>
          </div>
          <hr />

          <div className="mb-2">
            <div className="d-flex align-items-center mb-2">
              <div className="pe-2">
                <span>
                  <Avatar src="" alt="" />
                </span>
              </div>
              <div>
                <span>
                  {userData?.first_name} {userData?.last_name}
                </span>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <div className="col-11 me-2">
                <input
                  placeholder="Comments"
                  name="text"
                  value={comments?.text}
                  onChange={handleTextChange}
                  className="w-100 border-1 rounded-1 px-2 py-1"
                />
              </div>
              <div className="col-1 cursor-pointer" onClick={commentsCreated}>
                <SendIcon />
              </div>
            </div>
          </div>

          <div
            className="rounded border-5 scroll-postComment-container"
            style={{ height: "210px" }}
          >
            {commentsData?.map((ele, index) => {
              return (
                <>
                  <div
                    className="mt-1 col-11 d-flex justify-content-between align-items-center "
                    key={index}
                  >
                    <div>
                      <div className="">
                        <span className="fw-500" style={{ fontSize: "15px" }}>
                          {ele?.text}
                        </span>
                      </div>
                      <div>
                        <span className="" style={{ fontSize: "12px" }}>
                          {moment(ele.createdAt).fromNow()}
                        </span>
                      </div>
                    </div>
                    {moment().diff(moment(ele.createdAt), "days") <= 1 ? (
                      <Dropdown align="start" className="exclude-this-click">
                        <Dropdown.Toggle as={CustomToggle}>
                          <MoreVertIcon sx={{ color: "#3a659b" }} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            position: "absolute",
                            inset: "0px auto auto 0px",
                            transform: "translate(-167px, 1px)",
                            // background: "rgb(130 73 222)"
                          }}
                          className="dropdown-menu-left dropdown-menu-custom-class"
                        >
                          {/* <Dropdown.Item className="update-delete-btn" onClick={() => setShowDeleteModal(true)}>Delete Post</Dropdown.Item> */}
                          <Dropdown.Item
                            className="update-delete-btn"
                            onClick={() => {
                              handleUpdateClick(ele);
                            }}
                          >
                            Update
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ""
                    )}
                  </div>
                  <Divider />
                </>
              );
            })}
          </div>
        </div>
      </div>

      {/* <Modal show={handleShowEdit} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          
          <Modal.Body>
            <div>
            <div className="mt-3">
                  <span className="d-block">Email</span>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                    style={{
                      color: "black",
                      background: "rgb(239, 239, 239)",
                      fontSize: "16px",
                      height: "40px",
                    }}
                    value={formik.values.credit}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.credit && formik.touched.credit ? (
                    <span className="text-danger">{formik.errors.credit}</span>
                  ) : null}
                </div>
            <div className="mt-3">
                  <span className="d-block">Phone</span>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                    style={{
                      color: "black",
                      background: "rgb(239, 239, 239)",
                      fontSize: "16px",
                      height: "40px",
                    }}
                    value={formik.values.credit}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.credit && formik.touched.credit ? (
                    <span className="text-danger">{formik.errors.credit}</span>
                  ) : null}
                </div>

            </div>

            <div className="mt-3 d-flex justify-content-end">
                  <Button
                    type="submit"
                    size="lg"
                    value="Add"
                    className="text-capitalize px-3"
                    style={{ background: "#0808ff", color: "white", fontSize: "16px" }}
                    disabled={isLoading}                  >
                    {isLoading ?
                      <>
                        <div className="px-3">
                          <div className="spinner-border text-light mx-auto d-flex" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        <div className="px-1">
                          <span>Edit Data</span>
                        </div>
                      </>
                    }
                  </Button>
                </div>
          </Modal.Body>
        </Modal> */}
    </div>
  );
};