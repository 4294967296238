import React from "react";

const NotFoundGif = ({ text, image }) => {
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <img
        src={image ? image : "/images/gif/Nodata.jpg"}
        alt=""
        style={{
          maxWidth: '100%',
          maxHeight: '400px',
          objectFit: 'contain',
          display: 'block',
          margin: '0 auto'
        }}
      />
      <h6 className="text-body-emphasis fs-4" style={{ marginTop: '16px' }}>
        {text}
      </h6>
    </div>
  );
};

export default NotFoundGif;
