import { Avatar, Button } from '@mui/joy';
import { apiJsonAuth } from 'api';
import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export const AdminInstituteDetails = () => {
  const [instituteData, setIntituteData] = useState({})
  const [showProofModal, setShowProofModal] = useState();
  const [showProof, setShowProof] = useState([{ src: '', isPdf: false }]);
  const {instituteId} = useParams()
  const navigate = useNavigate()
  const getSingleInstitute = ()=>{
    apiJsonAuth.get(`api/v2/admin/singleInstitute/${instituteId}`)
    .then((res)=>{
      setIntituteData(res.data.getSingleData)
    })
    .catch((error)=>{
      console.log(error)
    })
  }

  const updateInstitute= (id)=>{
    apiJsonAuth.put(`api/v2/admin/aprovedSingleInstitute/${id}`)
    .then((res)=>{
      getSingleInstitute()
    }).catch((err)=>{
        toast.dismiss()
        toast.error('Internal server error')
    })
}


const InstituteDelete = async(id) =>{
  try {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this institute!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
    const res = await apiJsonAuth.delete(`api/v2/admin/reject-institute/${id}`)
    if(res.status ===200){
      toast.success(res?.data?.message)
      getSingleInstitute()
      navigate('/admin/allInstituteDetails')
    }}
  } catch (error) {
  //  console.log(error?.response?.data?.message  ? error?.response?.data?.message : "Internal Server Error.")
   toast.dismiss()
   toast.error(error?.response?.data?.message  ? error?.response?.data?.message : "Internal Server Error.")
  }
}
  useEffect(()=>{
    getSingleInstitute()
  },[])

  const handleShowModal = (proof)=>{
  const isPdf = proof.endsWith('.pdf');
  console.log(isPdf)
  
  setShowProofModal(true);
  setShowProof({ src: proof, isPdf: isPdf });
  }

  const handleCloseModal = ()=>{
    setShowProofModal(false)
  }


  
  return (
    <div>
      <div className='container col-12 col-md-10 mx-auto mt-3'>
      <div className='mb-3'><span className='fs-1 fw-bolder'>Institute Details</span></div>

        <div className='row align-items-center'>
          <div className='col-lg-3'><div className="d-flex justify-content-center justify-content-md-start"><Avatar sx={{ height: 100, width: 100 }} src={instituteData?.logo} /></div></div>
          <div className='col-lg-9'>
            <div className='d-flex justify-content-center justify-content-md-start'><span className='fs-1 fw-semibold'>{instituteData?.institution_name}</span></div>
            <div><span className='fs-5 fw-500'>{instituteData?.bio}</span></div>
          </div>
        </div>

        <div className='mt-4'>

          <div className='row'>
            <div className='col-12 col-lg-2'><span className='fs-6 fw-500'>Email:</span></div>
            <div className='col-12 col-lg-10'><span>{instituteData?.email}</span></div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-2'><span className='fs-6 fw-500'>Institute Type:</span></div>
            <div className='col-12 col-lg-10'><span>{instituteData?.type_of_inst}</span></div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-2'><span className='fs-6 fw-500'>Medium:</span></div>
            <div className='col-12 col-lg-10'><span>{instituteData?.medium_of_education}</span></div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-2'><span className='fs-6 fw-500'>Address:</span></div>
            <div className='col-12 col-lg-10'><span>{instituteData?.street} {instituteData?.pincode}, {instituteData?.country}</span></div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-2'><span className='fs-6 fw-500'>State:</span></div>
            <div className='col-12 col-lg-10'><span>{instituteData?.state}</span></div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-2'><span className='fs-6 fw-500'>Phone:</span></div>
            <div className='col-12 col-lg-10'><span>{instituteData?.phone}</span></div>
          </div>

          <div>
            <div className='mt-5'>
              <span className='fs-5 fw-bolder'>Documents</span>
            </div>

            <div className='row mt-1'>
              <div className='col-6 mb-2 col-md-3 col-lg-3 '><Button className="w-100" onClick={()=>handleShowModal(instituteData?.proof_of_address)}>View Address</Button></div>
              <div className='col-6 mb-2 col-md-3 col-lg-3 '><Button className="w-100" onClick={()=>handleShowModal(instituteData?.proof_of_id)}>View ID</Button></div>
              {instituteData?.is_account_verified ? null : (
              <div className='col-6 mb-2 col-md-3 col-lg-3 '><Button className="w-100" disabled={instituteData?.is_account_verified} style={{background: "#68d970"}} onClick={()=>updateInstitute(instituteData?.institute_id)}>Approve</Button></div>
              )}
              <div className='col-6 mb-2 col-md-3 col-lg-3 '><Button className="w-100"  style={{background: "#ef443b"}} onClick={()=>InstituteDelete(instituteData?.institute_id)}>Delete</Button></div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showProofModal} onHide={handleCloseModal}>
        <Modal.Header>
          {showProof?.src === instituteData?.proof_of_address ?
            <span className='fs-5 fw-500'>Proof of Address</span> : <span className='fs-5 fw-500'>Proof of ID</span>}
        </Modal.Header>
        <Modal.Body >
          <div>
            {showProof?.isPdf ? (
              <iframe src={"https://docs.google.com/gview?embedded=true&url=" + showProof?.src} height={500} style={{ width: "100%" }}></iframe>
            ) : (
              <img className='h-100 w-100' src={showProof?.src} alt="Proof" />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
