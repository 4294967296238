import React, { useContext, useEffect, useState } from "react";
import OnBoardContext from "pages/OnBoarding/context/onBoardContext";
import { useGlobalContext } from "global/context";

  const interestTopic = [
    { name: "Future of Work and Careers", pick: false },
    { name: "Climate Change and Environmental Sustainability", pick: false },
    { name: "Mental Health and Well-being", pick: false },
    { name: "Artificial Intelligence and Robotics", pick: false },
    { name: "Digital Innovation and Technology Trends", pick: false },
    { name: "Social Media and Online Influence", pick: false },
    { name: "Personal Finance and Budgeting", pick: false },
    { name: "Global Politics and International Relations", pick: false },
    { name: "Gender Equality and Women Empowerment", pick: false },
    { name: "Cybersecurity and Online Safety", pick: false },
    { name: "Cultural Diversity and Inclusivity", pick: false },
    { name: "Health and Fitness", pick: false },
    { name: "Space Exploration and Astronomy", pick: false },
    { name: "Sustainable Development Goals (SDGs)", pick: false },
    { name: "Education and Learning Strategies", pick: false },
    { name: "Entrepreneurship and Startups", pick: false },
    { name: "Human Rights and Social Justice", pick: false },
    { name: "Innovation in Science and Technology", pick: false },
    { name: "Sports and Athletics", pick: false },
    { name: "Arts and Creativity", pick: false },
    { name: "Career Planning and Skill Development", pick: false },
    { name: "Travel and Adventure", pick: false },
    { name: "Nutrition and Healthy Eating", pick: false },
    { name: "Philanthropy and Social Impact", pick: false },
    { name: "Time Management and Productivity", pick: false },
    { name: "Language Learning and Linguistics", pick: false },
    { name: "Fashion and Lifestyle", pick: false },
    { name: "Mindfulness and Meditation", pick: false },
    { name: "Conservation and Wildlife Preservation", pick: false },
    { name: "History and Historical Events", pick: false },
  ];

  const activities =  [
    { name: "Sports (e.g., football, basketball, tennis, swimming)", pick: false },
    { name: "Music (e.g., singing, playing instruments)", pick: false },
    { name: "Dance (e.g., classical, contemporary, hip-hop)", pick: false },
    { name: "Drama and Theater", pick: false },
    { name: "Debate and Public Speaking", pick: false },
    { name: "Robotics and STEM Clubs", pick: false },
    { name: "Chess Club", pick: false },
    { name: "Art and Painting", pick: false },
    { name: "Photography", pick: false },
    { name: "Writing and Creative Writing", pick: false },
    { name: "Model United Nations (MUN)", pick: false },
    { name: "Science Clubs and Science Fairs", pick: false },
    { name: "Coding and Computer Programming", pick: false },
    { name: "Environmental Clubs and Eco-friendly Initiatives", pick: false },
    { name: "Community Service and Volunteering", pick: false },
    { name: "Film Making and Video Production", pick: false },
    { name: "Cooking and Culinary Clubs", pick: false },
    { name: "Foreign Language Clubs", pick: false },
    { name: "Book Clubs and Reading Circles", pick: false },
    { name: "Yoga and Meditation", pick: false },
    { name: "Entrepreneurship Clubs and Business Competitions", pick: false },
    { name: "Astronomy and Space Exploration Clubs", pick: false },
    { name: "Cultural and Heritage Clubs", pick: false },
    { name: "Debate and Mock Trial Clubs", pick: false },
    { name: "Leadership and Student Government", pick: false },
    { name: "Journalism and School Newspaper", pick: false },
    { name: "Quizzing and Trivia Clubs", pick: false },
    { name: "Public Service and Social Outreach", pick: false },
    { name: "Gardening and Horticulture Clubs", pick: false },
    { name: "Model Building and Crafting", pick: false },
  ];  


export const StudentStepInterest = () => {
  const [tempStep, setTempStep] = useState(1)
   
  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    studentFormik,
    setStudenteData,
  } = useContext(OnBoardContext);
const {userData} = useGlobalContext()
  const [interest, setInterest] = useState(interestTopic)
  const [activitiesArray, setactivitiesArray] = useState(activities)
 //============  Find interest data Initially when we come from next screen ============\\\ 
 useEffect(()=>{
  const temp = [...interestTopic]
  
 if(temp?.length>0){
  setInterest(temp?.map((interest, index) => {
    const matchingItem = studentFormik.values.interests && studentFormik.values.interests.find((newItem) => newItem.name === interest.name);
    if (matchingItem && matchingItem.pick) {
      // temp[index].pick = true;
      return {
        name:interest.name,
        pick:true
      }
    }
    else{
      return interest
    }
  }))
 }
 },[])
 //============  Find activities data Initially when we come from next screen ============\\\ 
 useEffect(()=>{
  const tempActivities = [...interestTopic]
 if(tempActivities?.length>0){
  setactivitiesArray(tempActivities?.map((interest, index) => {
    const matchingItem = studentFormik.values.activities && studentFormik.values.activities.find((newItem) => newItem.name === interest.name);
    if (matchingItem && matchingItem.pick) {
      // temp[index].pick = true;
      return {
        name:interest.name,
        pick:true
      }
    }
    else{
      return interest
    }
  }))
 }

 },[])
  const handleInterest = (i) => {
    let array = [...interest];
    const object = { ...interest[i], pick: !interest[i].pick };
    array[i] = object;
    console.log(array);
  
    const numberOfTruePicks = array.filter((item) => item.pick === true).length;
  
    if (object.pick && numberOfTruePicks > 5) {
      return;
    }
  
    setInterest(array);
  };
  const getAllSelected = ()=>{
    const arr = interest?.filter((i)=>i.pick===true)
    console.log('new arr', arr)
    const arr2 = activitiesArray?.filter((i)=>i.pick===true)
    setStudenteData('interests',arr)
    setStudenteData('activities',arr2)
    
  }
  useEffect(()=>{
    getAllSelected()
  },[interest, activitiesArray])
 

  const handleActivities=  (i)=>{
    let array = [...activitiesArray]
    const object = {...activitiesArray[i], pick:!activitiesArray[i].pick}
    array[i] = object
    console.log(array)
    const numberOfTruePicks = array.filter((item) => item.pick === true).length;
  
    if (object.pick && numberOfTruePicks > 5) {
      return;
    }
    setactivitiesArray(array)
  }


  const handleBack = () => {
    setActiveChildStep(activeChildStep - 1);
    if (activeChildStep <= 0) {
      setCount(count - 1);
      setActiveStep(activeStep - 1);
      if(userData?.role==="student"){
        setActiveChildStep(1);
      }else{
        setActiveChildStep(2);
      }
    }
    if (activeStep < 0) {
      setActiveStep(0);
    }
    if (activeChildStep < 0) {
      setActiveChildStep(0);
    }
    console.log("active", activeStep, " activechild", activeChildStep, " ");
  };

  const handleNextChild = () => {
    setActiveChildStep(activeChildStep + 1);

    if (activeChildStep >= stepperArray[count]?.childStep?.length - 1) {
      setActiveStep(activeStep + 1);
      setCount(count + 1);
      setActiveChildStep(0);
      console.log(
        "active step",
        activeStep,
        "stepperlength",
        stepperArray.length
      );
      if (activeStep > stepperArray.length - 2) {
        console.log("inside the if condition");
        setCount(0);
        setActiveStep(0);
        setActiveChildStep(0);
      }
    }
    // if(activeStep>=stepperArray?.length-1){
    //   console.log("active step ", activeStep,"stepperArraydddddddddddddd", stepperArray.length)
    //   setActiveStep(0)
    //   setCount(0)
    // }
    console.log("this is length", stepperArray?.length);
    console.log(
      "active step ",
      activeStep,
      "stepperArray",
      stepperArray.length
    );
  }




   if(tempStep===1){return (
    <>
      <div>
        <div className="mb-2">
            <span className=" fs-2 fw-semibold">Interests </span>
        </div>
        <div className="mb-4">
            <span
              className=""
              style={{
                color: "grey",
                fontSize: "17px",
                fontWeight: "500",
              }}
            >
            Help us to bring you best curated services for you
            </span>
          </div>

        <div>
                <div  className="mb-2">
                  <span  className="fs-6 fw-semibold">
                    Select any 5 top topics of interest for yourself
                  </span>
                </div>
        </div>

        <div className="d-flex flex-wrap">
          {
            interest?.map((i,index)=>{
              return (
                <span onClick={()=>handleInterest(index)} className={`mx-2 my-1 my-md-2 px-2 py-1  cursor-pointer color-purple-dark rounded-4 ${i?.pick?'background-purple-dark':'background-purple'}`}>{i.name}</span>
              )
            })
          }
        </div>
        <div className="text-center">
          <span className="fw-500 color-blue-dark fs-14px" style={{color:'#0115c8',textDecoration:'underline'}}> {interest.filter(i=>i.pick===true).length} interests are selected</span>
        </div>

        <div className="d-flex justify-content-between mt-4 z-index-1">
          <button onClick={handleBack} className="btn-onboard">
            Previous
          </button>

          <button onClick={()=>setTempStep(2)} className="btn-onboard-fill">
            Next Step <span className='ms-4'><img src='./images/onBoarding/right-icon.png'/></span>
          </button>
        </div>
      </div>
    </>
  )}else{
    return (
      <>
        <div>
          <div className="mb-2">
              <span className=" fs-2 fw-semibold">Activities</span>
          </div>
          <div className="mb-4">
              <span
                className=""
                style={{
                  color: "grey",
                  fontSize: "17px",
                  fontWeight: "500",
                }}
              >
              Help us to bring you best curated services for you
              </span>
            </div>
  
          <div>
                  <div className="mb-2">
                    <span className="fs-6 fw-semibold">
                    Select any 5 extra curriculum activities you like
                    </span>
                  </div>
          </div>
  
          <div className="d-flex flex-wrap">
            {
              activitiesArray?.map((i,index)=>{
                return (
                  <span onClick={()=>handleActivities(index)} className={`mx-2 my-1 my-md-2 px-2 py-1 cursor-pointer color-blue-dark rounded-4 ${i?.pick?'background-blue-dark':'background-blue'}`}>{i.name}</span>
                )
              })
            }
          </div>
          <div className="text-center">
          <span className="fw-500 color-blue-dark fs-14px" style={{color:'#0115c8',textDecoration:'underline'}}> {activitiesArray.filter(i=>i.pick===true).length} activities are selected</span>
        </div>
  
          <div className="d-flex justify-content-between mt-4 z-index-1">
            <button onClick={()=>setTempStep(1)} className="btn-onboard">
              Previous
            </button>
  
            <button onClick={handleNextChild} className="btn-onboard-fill">
              Next Step<span className='ms-4'><img src='./images/onBoarding/right-icon.png'/></span>
            </button>
          </div>
        </div>
      </>
    )
  }
}



