import { apiJson } from 'api'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const CreditPointes = ({img, totalCredit}) => {
    const param = useParams()
    const [credit, setCredit] = useState()
    const getCreditScore = async ()=>{
        try{
            const response = await apiJson(`api/v2/21daysChallenge/showScore/${param?.attemptId}`)
            
            setCredit(response?.data?.result)
            totalCredit(response?.data?.result)
        }catch(err){
            console.log(err.message)
        }
    }
    useEffect(()=>{
        getCreditScore()
    },[])
  return (
    <>
        <p className='sm-w-60 position-absolute text-end h-100' style={{top:'30%', right:'0%'}}>
            <img src={img} alt="logo" className="pe-2" /> <span>{credit} </span>
            credits
        </p>
    </>
  )
}

export default CreditPointes