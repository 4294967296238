import { Box, Button, Typography } from '@mui/material'
import React from 'react'
const ChallengeStarter = ({ handleStart, Ch_logo }) => {
    return (
        <Box
            sx={{
                padding: "2rem 2rem 2rem 1.5rem",
                boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
                borderRadius: "1rem",
                backgroundColor: "var(--color-ghostwhite)",
                border: "5px solid #2356fe",
                boxSizing: "border-box",
                width: "100%",
                // width: "70rem",
                // height: "38.713rem",
                overflow: "hidden",
                "@media(max-width:900px)": {
                    width: "100%",
                    overflow: "auto",
                },
            }}
        >
            <Box>
                {/* Icon Display */}
                <Box sx={{ textAlign: "center", paddingTop: "2rem" }}>
                    <Typography
                        component={"img"}
                        src={Ch_logo}
                        alt=""
                        sx={{
                            width: "350px ",
                            "@media(max-width:900px)": {
                                width: "270px ",
                            },
                        }}
                    />
                </Box>
                {/* Challenge Intro */}
                <Box>
                    <Typography
                        component={"p"}
                        sx={{
                            textAlign: "center",
                            color: "#666",
                            padding: "2rem 0rem 2rem 0rem",
                            fontSize: "1.2rem",
                        }}
                    >
                        Each day’s activity is based on a person’s habit of using
                        elevators, and plastic bags or forgetting to switch off
                        electrical appliances when not in use. If small steps are taken
                        by each individual to avoid wastage, then it could create a
                        bigger impact.
                    </Typography>
                </Box>
                {/* Start Button */}
                <Box sx={{ textAlign: "center", padding: "1rem" }}>
                    <Button
                        variant="contained"
                        sx={{
                            background: "#2356fe",
                            borderRadius: "0.5rem",
                            fontWeight: 900,
                            padding: "1rem 3rem 1rem 3rem",
                            ":hover": {
                                background: "#2356fe",
                            },
                        }}
                        onClick={() => handleStart()}
                    >
                        Start
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default ChallengeStarter