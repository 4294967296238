import React, { useContext, useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import ModelUnContext from '../context/contextModelUn';
import dayjs from 'dayjs';


const DatePickerOwn = ({ setDate, formikValue }) => {
  const { formik } = useContext(ModelUnContext)

  const handleDateClick = (newValue) => {
    const formattedDate = dayjs(newValue).format('YYYY-MM-DD');
    setDate(formattedDate);
  };


  return (
    <div className="calendar rounded-4">

      <div className="px-3 py-4">

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker orientation="portrait"
            value={formik?.values?.lastDateRegis}
            disablePast
            onChange={(newValue) => {
              handleDateClick(newValue)
            }}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default DatePickerOwn;