import React, { useContext, useEffect, useState } from 'react'
import { ClubContext } from '../TimelineClub'
import ClubPostCenter from './ClubPostCenter'
import { MyProvider } from '../ClubPosts'
import ClubPostSkeleton from './ClubPostSkeleton'
import { apiJson } from 'api'
import { toast } from 'react-toastify'
import { useGlobalContext } from 'global/context'
import FBEventPostsComponent from 'pages/FBEvent/Components/FBEventPostsComponent'

const ShowUserClubPosts = ({ userDetail, value }) => {
  // console.log("userDetail---------", userDetail)
  const {userData} = useGlobalContext()
  const {  clickUserAllPost,
    isLoadingClubProfile ,setIsLoadingClubProfile,setclickuserAllPost} = useContext(ClubContext)
  const [showNoPostMessage, setShowNoPostMessage] = useState(false);
  const fetchAllClickedUserPost = () => {
    const id = userData.role === "institute" ? userData.id : userData.instituteId
    setIsLoadingClubProfile(true);
    apiJson
      .get(`club/getAllPostFromUser/${id}`)
      .then((res) => {
        // console.log("inside the user all post", role, id);
        if(value === 1){
          let singleProfileUser = res?.data?.allPosts?.filter((ele)=> ele?.userId === userData?.id)
          setclickuserAllPost(singleProfileUser)
          // console.log("singleProfileUser", singleProfileUser)
          setIsLoadingClubProfile(false);
        }
        else{
          setclickuserAllPost(res.data.allPosts);
          setIsLoadingClubProfile(false);
        }
      })
      .catch((error) => {
        setIsLoadingClubProfile(false);
        toast.dismiss();
        toast.error("Internal server Error");
        console.log(error.message);
      });
  };

  useEffect(() => {
    if(userDetail){
      fetchAllClickedUserPost();
    }
  }, [userDetail, clickUserAllPost.length]);

  // useEffect(()=>{
  //   setIsLoadingClubProfile(true)
  // },[])

  useEffect(() => {
    if (!isLoadingClubProfile) {
      const timeout = setTimeout(() => {
        setShowNoPostMessage(true);
      }, 400); 

      return () => clearTimeout(timeout); 
    }
  }, [isLoadingClubProfile]);

  // console.log("clickUserAllPost", clickUserAllPost)
  // console.log("isLoadingClubProfile", isLoadingClubProfile)
  // console.log("clickUserAllPost", clickUserAllPost)
  const [postData , setPostData] = useState([])

  const fetchPostData = async ()=>{
    const data = apiJson(`/api/v2/events/getAllInterestedPostByInstituteId/${ userData?.role === "institute"? userData?.id : userData?.instituteId}`)
      .then((res)=>{
        setPostData(res?.data?.result)
        console.log(res?.data?.result)
      })
  }

  useEffect(()=>{
    fetchPostData()
    console.log(userData)
  },[])
  

  return (
    <>
      {isLoadingClubProfile ? (
        <ClubPostSkeleton />
      ) :<>
        {
          <div className="mb-3 ms-3">
                {postData?.map((data) => {
                  return (
                    <FBEventPostsComponent
                      postData={data}
                    />
                  );
                })}
              </div>
        }
        {
          clickUserAllPost?.length > 0 ? (
        clickUserAllPost?.map((post, index) => (
          <MyProvider key={post.id} post={post}>
            <ClubPostCenter key={post.id} post={post} index={index} />
          </MyProvider>
        ))
      ) : showNoPostMessage && clickUserAllPost?.length===0 &&(
        <div className='d-flex justify-content-center'>
        <div style={{height: "50%", width: "70%"}}>
        <img className='h-100 w-100' src={"/ProfileImg/NoPost.svg"} alt='Not Post Yet'/>
        </div>
        </div>
      )}

      
      </>
      }
      
    </>
  );
};

export default ShowUserClubPosts;
