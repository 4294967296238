import React from 'react'
import { Outlet } from 'react-router-dom'
import './Courses.css'
export const CourseOutlet = () => {
  return (
   <>
   <Outlet />
   </>
  )
}
