import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { apiAuth, apiJson } from "api";
import ProgressBar from "pages/course/ProgressBar";
import DayCard from "pages/course/DayCard";
import credit_icon from "../../../twentyOneDaysChallenge/assests/Group 381.svg";
import Ch_Logo_75 from "../../../../../src/pages/seventyFiveDaysGame/assests/seventyfive.png";
import ChallengeStarter from "../ChallengeStarter/ChallengeStarter";
import CompleteTaskPage from "pages/course/CompleteTaskPage/CompleteTaskPage";
const SeventyFiveDaysChallenge = ({
  markSubTopicComplete,
  selectedMainTopic,
  selectedSubTopic,
  setSelectedSubTopic,
  taskCompletionStatus,
}) => {
  const [fileName, setFileName] = useState("");
  const [dailyTaskFileLink, setDailyTaskFileLink] = useState("");
  const [dailyTaskFileError, setDailyTaskFileError] = useState("");
  const [activeDayData, setActiveDayData] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [allDayTask, setAllDayTask] = useState([]);
  const [credit, setCredit] = useState(
    parseInt(localStorage.getItem("credit75")) || 0
  );
  const [isAttempt, setIsAttempt] = useState(localStorage.getItem("attempt"));
  const [userData] = useState(() => JSON.parse(localStorage.getItem("user")));
  const taskEndpoint = "/api/v2/75daysChallenge";
  const submitEndpoint = "/api/v2/75daysChallenge/submitChallenge";
  const creditEndpoint = "/api/v2/75daysChallenge/showScore";
  useEffect(() => {
    getTasks();
    if (isAttempt) {
      // getCreditScore();
    }
  }, [isAttempt, taskEndpoint]);
  // const getCreditScore = async () => {
  //     try {
  //         const response = await apiJson.get(`${creditEndpoint}/${isAttempt}`);
  //         setCredit(response?.data?.result);
  //     } catch (err) {
  //         console.log(err.message);
  //     }
  // };
  useEffect(() => {
    if (selectedSubTopic) {
      const completedTasks =
        JSON.parse(localStorage.getItem("Club_Tasks_Status"))[
          selectedSubTopic?.title
        ] || {};
      const [start, end] = selectedSubTopic?.tasks;
      console.log("Completed Tasks: ", completedTasks);
      const lastCompletedTask = Object.keys(completedTasks)?.length || 0;
      console.log("lastComplteteTask: ", lastCompletedTask);
      const initialTask =
        allDayTask[start + lastCompletedTask] || allDayTask[start];
      console.log(initialTask);
      setActiveDayData(initialTask);
    }
  }, [selectedSubTopic, allDayTask]);

  const getTasks = async () => {
    try {
      const response = await apiJson.get(`${taskEndpoint}/getAllTask`);
      setAllDayTask(response.data.result);
    } catch (err) {
      console.log(err.message);
    }
  };
  const getImageLink = (imageFileData) => {
    if (imageFileData) {
      apiAuth
        .post("v2/register/uploadInstituteLogo", { img: imageFileData })
        .then((res) => {
          console.log(res, "response value");
          setDailyTaskFileLink(res?.data?.result);
        })
        .catch((error) => {
          console.log("Internal server error", error.message);
        });
    }
  };

  const handleStart = async () => {
    const userDetails = {
      userId: userData.id,
      role: userData.role,
      instituteId: userData.instituteId,
    };

    try {
      const response = await apiJson.post(
        `${taskEndpoint}/addAttempts`,
        userDetails
      );
      localStorage.setItem("attempt", response.data.result.id);
      setIsAttempt(response.data.result.id);
    } catch (err) {
      console.log(err.message);
    }
  };

  const validationSchema = yup.object({
    experience: yup.string().trim().required("Please enter your experience"),
  });

  const formik = useFormik({
    initialValues: { experience: "" },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!dailyTaskFileLink) {
        setDailyTaskFileError("Please select file");
        return;
      }

      const data = {
        userId: userData.id,
        role: userData.role,
        instituteId: userData.instituteId,
        taskId: activeDayData.taskId,
        attemptId: localStorage.getItem("attempt"),
        status: "Completed",
        experience: values.experience,
        credit_earned: activeDayData.credit,
        images: dailyTaskFileLink,
      };

      try {
        const response = await apiJson.post(submitEndpoint, data);
        if (response.status === 200) {
          const updatedCredit = credit + activeDayData.credit;
          setCredit(updatedCredit);
          localStorage.setItem("credit75", updatedCredit);
          markSubTopicComplete(
            selectedSubTopic.title,
            "Task " + activeDayData.day_count
          );
          setIsComplete(true);
          resetForm();
          setDailyTaskFileLink("");
          setFileName("");
        } else {
          console.log("Failed to submit the challenge");
        }
      } catch (err) {
        console.error("API call error:", err);
      }
    },
  });

  const handleFiles = (files) => {
    setDailyTaskFileError("");
    getImageLink(files[0]);
    setFileName(files[0].name);
  };
  const handleDays = (value) => {
    setActiveDayData(value);
  };
  const handleNextDay = () => {
    const [start, end] = selectedSubTopic?.tasks || [0, allDayTask.length - 1];
    const nextDay = allDayTask.find(
      (day) => day.day_count === activeDayData.day_count + 1
    );
    if (nextDay && nextDay.day_count <= end + 1) {
      setActiveDayData(nextDay);
      setIsComplete(false);
    } else {
      if (activeDayData.day_count === end + 1) {
        const currentIndex = selectedMainTopic.subtopics.findIndex(
          (sub) => sub.title === selectedSubTopic.title
        );
        if (
          currentIndex >= 0 &&
          currentIndex < selectedMainTopic.subtopics.length - 1
        ) {
          const nextSubTopic = selectedMainTopic.subtopics[currentIndex + 1];
          setSelectedSubTopic({ ...nextSubTopic });
          setIsComplete(false);
        }
      }
    }
  };

  useEffect(() => {
    const dropArea = document.getElementById("drop-area");

    if (!dropArea) {
      return;
    }

    const events = ["dragenter", "dragover", "dragleave", "drop"];

    const preventDefaults = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const highlight = (e) => {
      dropArea.classList.add("highlight");
    };

    const unHighlight = (e) => {
      dropArea.classList.remove("highlight");
    };

    const handleDrop = (e) => {
      const dt = e.dataTransfer;
      const files = dt.files;
      handleFiles(files);
    };

    events.forEach((eventName) => {
      dropArea.addEventListener(eventName, preventDefaults, false);
    });

    ["dragenter", "dragover"].forEach((eventName) => {
      dropArea.addEventListener(eventName, highlight, false);
    });

    ["dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, unHighlight, false);
    });

    dropArea.addEventListener("drop", handleDrop, false);

    return () => {
      events.forEach((eventName) => {
        dropArea.removeEventListener(eventName, preventDefaults, false);
      });

      ["dragenter", "dragover"].forEach((eventName) => {
        dropArea.removeEventListener(eventName, highlight, false);
      });

      ["dragleave", "drop"].forEach((eventName) => {
        dropArea.removeEventListener(eventName, unHighlight, false);
      });

      dropArea.removeEventListener("drop", handleDrop, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDayCards = () => {
    const [start, end] = selectedSubTopic?.tasks || [0, allDayTask.length - 1];
    return allDayTask
      .slice(start, end + 1)
      .map((item, i) => (
        <DayCard
          key={i}
          item={item}
          isActive={
            activeDayData
              ? activeDayData.day_count === item.day_count
              : 1 === item.day_count
          }
          handleDays={handleDays}
          totalDays={75}
        />
      ));
  };

  const isSubtopicCompleted = (subtopic) => {
    const tasks = subtopic.tasks;
    const completedTasks = taskCompletionStatus[subtopic.title] || {};
    const [startIndex, endIndex] = tasks;

    for (let i = startIndex; i <= endIndex; i++) {
      const taskTitle = `Task ${i + 1}`;
      if (!completedTasks[taskTitle]) {
        return false;
      }
    }
    return true;
  };

  const canAccessSubtopic = (index) => {
    if (index === 0) return true;
    for (let i = 0; i < index; i++) {
      if (!isSubtopicCompleted(selectedMainTopic.subtopics[i])) {
        return false;
      }
    }
    return true;
  };
  const handleSubtopicClick = (subtopic, index) => {
    if (canAccessSubtopic(index)) {
      setSelectedSubTopic({ ...subtopic });
    } else {
      console.log("Complete previous subtopics to access this one.");
    }
  };
  return (
    <>
      {/* Challenge Start Section */}
      {!isAttempt && !isComplete && (
        <ChallengeStarter handleStart={handleStart} Ch_logo={Ch_Logo_75} />
      )}
      {selectedSubTopic
        ? isAttempt &&
          !isComplete && (
            <Box
              sx={{
                padding: "2rem 2rem 2rem 1.5rem",
                boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
                borderRadius: "1rem",
                backgroundColor: "var(--color-ghostwhite)",
                border: "5px solid #2356fe",
                boxSizing: "border-box",
                width: "auto",
              }}
            >
              {/* Challenge Description Wrapper - Top Section */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "1rem",
                  padding: "1rem 0",
                  "@media(max-width:900px)": {
                    flexDirection: "column-reverse",
                  },
                }}
              >
                {/* Challenge Details - Left Pane */}
                <Box
                  component={"div"}
                  sx={{
                    flex: 1,
                    display: "flex",
                    gap: "1rem",
                    flexWrap: "wrap",
                    "@media(max-width:900px)": {
                      flexDirection: "column",
                    },
                  }}
                >
                  {/* Challenge Title */}
                  <Typography
                    component={"h1"}
                    sx={{
                      fontSize: "1.6rem",
                      lineHeight: "1rem",
                      fontWeight: 900,
                      color: "#2356fe",
                      width: "fit-content",
                    }}
                  >
                    Challenge {activeDayData ? activeDayData?.day_count : 1}:
                  </Typography>
                  {/* Challenge Description */}
                  <Typography
                    component={"p"}
                    sx={{
                      flex: 1,
                      fontWeight: 600,
                      color: "gray",
                    }}
                  >
                    {" "}
                    {activeDayData
                      ? activeDayData?.description
                      : "Carry A Non-Plastic Bottle While Steeping Out Of Homes"}
                  </Typography>
                </Box>

                {/* Credits Display Container - Right Pane */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  {/* Logo of 21 Days Challenge */}
                  <Box>
                    <Typography
                      component={"img"}
                      src={Ch_Logo_75}
                      alt="image here"
                      sx={{ width: "150px" }}
                    />
                  </Box>
                  {/* Credits Points Wrapper */}
                  <Box>
                    <Box
                      variant="contained"
                      sx={{
                        background: "#000",
                        textTransform: "none",
                        padding: "1rem",
                        borderRadius: "0.6rem",
                        fontWeight: 600,
                        color: "#fff",
                        ":hover": {
                          background: "#000",
                        },
                      }}
                    >
                      {/* Leaf Icon Display */}
                      <Typography
                        component={"img"}
                        src={credit_icon}
                        alt="image here"
                        sx={{ width: "30px" }}
                      />{" "}
                      {/* Credits Display */}
                      <Typography component={"span"} sx={{ fontWeight: 60 }}>
                        {" "}
                        {console.log(credit, "credit value")}
                        {credit}
                      </Typography>
                      <Typography component={"span"} sx={{ fontWeight: 60 }}>
                        {""} Credits
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* Progress Bar - Middle Section */}
              <Box>
                <Typography
                  component={"p"}
                  sx={{
                    fontWeight: 600,
                    color: "gray",
                  }}
                >
                  Progress
                </Typography>
                <Box sx={{ padding: "0.5rem 0rem 0.5rem 0rem" }}>
                  <ProgressBar
                    progress={
                      (Object.keys(
                        taskCompletionStatus[selectedSubTopic?.title] || {}
                      )?.length /
                        (selectedSubTopic?.tasks[1] + 1)) *
                        100 || 0
                    }
                    bgColor={"#2356FE"}
                  />
                </Box>
              </Box>

              {/* Challenge Display Area - Bottom Section */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                  "@media(max-width:900px)": {
                    flexDirection: "column",
                  },
                }}
              >
                {/* Task Upload - Left Pane */}
                <Box
                  sx={{
                    width: "58%",
                    padding: "0.8rem",
                    "@media(max-width:900px)": {
                      width: "100%",
                    },
                  }}
                >
                  {/* File Upload Section */}
                  <Box>
                    <Box
                      sx={{
                        position: "relative",
                      }}
                      id="drop-area"
                    >
                      {/* Input Section */}
                      <Typography
                        component={"input"}
                        sx={{
                          display: "none",
                        }}
                        type="file"
                        id="fileElem"
                        accept="image/*"
                        onChange={(e) => handleFiles(e.target.files)}
                      />
                      {/* Label for the Image Input */}
                      <Typography
                        component={"label"}
                        sx={{
                          padding: "10px",
                          cursor: "pointer",
                          borderRadius: "6px",
                          minWidth: "100%",
                          maxWidth: "100%",
                          boxSizing: "border-box",
                          border: "2px dashed black",
                          height: "150px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#f6f6f6",
                          ":hover": {
                            backgroundColor: "#e9e9e9",
                          },
                          ":active": {
                            backgroundColor: "#f6f6f6",
                          },
                        }}
                        htmlFor="fileElem"
                      >
                        <Typography
                          component={"div"}
                          sx={{
                            color: "#666",
                            userSelect: "none",
                            textAlign: "center",
                          }}
                        >
                          Drag Image here or click to upload
                        </Typography>
                      </Typography>

                      {/* Line Below the Input Section */}
                      <Typography
                        component={"p"}
                        sx={{
                          fontSize: "0.85rem",
                          padding: "0.2rem",
                          textAlign: "center",
                        }}
                      >
                        Drag File Here Or Click To{" "}
                        <Typography component={"span"} sx={{ fontWeight: 600 }}>
                          Select File To Upload{" "}
                        </Typography>{" "}
                        Proof Of Activity
                      </Typography>
                      {/* File Upload Error Box */}
                      <Typography
                        component={"p"}
                        sx={{
                          textAlign: "center",
                          color: "red",
                          padding: 0,
                        }}
                      >
                        {dailyTaskFileError}
                      </Typography>
                      {/* File Name Display */}
                      <Typography
                        component={"p"}
                        sx={{
                          textAlign: "center",
                          color: "#666",
                          padding: 0,
                        }}
                      >
                        {fileName}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Experience Section */}
                  <Box
                    sx={{
                      borderRadius: "0.4rem",
                      padding: "1rem 0rem 0rem 0rem",
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 2,
                      "@media(max-width:900px)": {
                        flexDirection: "column",
                      },
                    }}
                  >
                    {/* Left Pane for Form for Experience Input */}
                    <Box
                      sx={{
                        width: "65%",
                        "@media(max-width:900px)": { width: "100%" },
                      }}
                    >
                      <Box>
                        <form onSubmit={formik.handleSubmit}>
                          {/* Input field and Error Area */}
                          <Box>
                            {/* Textarea Input Section */}
                            <Typography
                              component={"textarea"}
                              rows="3"
                              cols="40"
                              id="experience"
                              type="text"
                              sx={{
                                borderRadius: "0.5rem",
                                backgroundColor: "#f0f0f0",
                                overflow: "hidden",
                                fontSize: "0.8rem",
                                border: "none",
                                padding: "0.7rem",
                                width: "100%",
                                ":focus-visible": {
                                  border: "none ! important",
                                  outline: "none",
                                },
                                "@media(max-width:900px)": {
                                  width: "100%",
                                },
                              }}
                              value={formik.values.experience}
                              placeholder="Share your Experience in 50 words"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {/* Error Display Area */}
                            {formik.touched.experience &&
                              formik.errors.experience && (
                                <Typography
                                  component={"p"}
                                  sx={{
                                    color: "red",
                                    paddingTop: "0.2rem",
                                  }}
                                >
                                  {formik.errors.experience}
                                </Typography>
                              )}
                          </Box>
                          {/* Button Container */}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingTop: "0.6rem",
                              gap: "10px",
                            }}
                          >
                            {/* Later Button */}
                            <Button
                              variant="contained"
                              type="submit"
                              sx={{
                                background: "gray",
                                borderRadius: "0.8rem",
                              }}
                            >
                              later
                            </Button>
                            {/* Submit Button */}
                            <Button
                              variant="contained"
                              type="submit"
                              sx={{
                                background: "#2356fe",
                                borderRadius: "0.8rem",
                              }}
                              disabled={!formik.isValid && !formik.dirty}
                            >
                              Submit
                            </Button>
                          </Box>
                        </form>
                      </Box>
                    </Box>
                    {/* Right Pane */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        "@media(max-width:900px)": {
                          alignItems: "flex-start",
                          gap: 2,
                        },
                      }}
                    >
                      <Typography
                        component={"p"}
                        sx={{
                          fontSize: "0.7rem",
                        }}
                      >
                        On Successful Completion Of This Challenge You Will Get
                      </Typography>
                      {/* Credits Display wrapper */}
                      <Box
                        sx={{
                          display: "flex",
                          gap: 0.48,
                          alignItems: "center",
                          backgroundColor: "#f0f0f0",
                          padding: "0.5rem",
                          borderRadius: "0.5rem",
                          color: "#2356fe",
                        }}
                      >
                        <Typography
                          component={"img"}
                          sx={{
                            width: "30px",
                          }}
                          src={credit_icon}
                          alt="image here"
                        />
                        <Typography
                          component={"span"}
                          sx={{
                            fontSize: "0.9rem",
                            color: "#000",
                            fontWeight: 600,
                          }}
                        >
                          25
                        </Typography>
                        <Typography
                          component={"span"}
                          sx={{
                            fontSize: "0.9rem",
                            color: "#000",
                            fontWeight: 600,
                          }}
                        >
                          Credits
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* Days Display - Right Pane */}
                <Box
                  sx={{
                    width: "38%",
                    height: "23.3rem",
                    borderRadius: "0.5rem",
                    padding: "1rem",
                    background: "#d5d2d269",
                    overflowX: "auto",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    ".scroll-container::-webkit-scrollbar": {
                      display: "none",
                    },
                    "@media(max-width:900px)": {
                      width: "100%",
                    },
                  }}
                >
                  {renderDayCards()}
                </Box>
              </Box>
            </Box>
          )
        : selectedMainTopic && (
            <div
              className="carbon-options-wrapper"
              style={{
                padding: "0.8rem",
                backgroundColor: "#FFFFFF",
                border: "5px solid #2356fe",
              }}
            >
              {selectedMainTopic.subtopics.map((subTopic, i) => (
                <button
                  key={i}
                  className="carbon-option"
                  onClick={() => handleSubtopicClick(subTopic, i)}
                  disabled={!canAccessSubtopic(i)}
                >
                  <span>{subTopic.title}</span>
                </button>
              ))}
            </div>
          )}

      {/* Challenge Finish Section */}
      {isComplete && (
        <CompleteTaskPage
          handleNextDay={handleNextDay}
          taskDetails={activeDayData}
          totalDays={75}
        />
      )}
    </>
  );
};

export default SeventyFiveDaysChallenge;
