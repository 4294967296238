import { apiJson } from 'api'
import React from 'react'
import { ButtonGroup, Card } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const AuthV2VerifyEmailPassReset = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const email = new URLSearchParams(location.search).get('email')
   
    return (
        <>
            <div className='vh-100'>
                <Card className='my-3 mx-2'>
                    <Card.Header>
                        <div className='mb-4'>
                            <h3 className='fs-2 text-center'>Email Verification Required</h3>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className=''>

                            <div className='mb-5'>
                                <h3 className='fs-5 '>Please Check Your Email Inbox, We Have Sent a Verification Link To Your Email <span className='fw-semibold text-primary'>{email}</span></h3>
                            </div>

                        </div>
                    </Card.Body>
                    <Card.Footer >
                        <button onClick={() => navigate('/login')} className='btn btn-outline-primary rounded-2'>Change Email</button>
                    </Card.Footer>
                </Card>
            </div>
        </>
    )
}

export default AuthV2VerifyEmailPassReset