import { AssignmentTwoTone, Error } from '@mui/icons-material';
import { Alert } from '@mui/joy';
import React from 'react';

const StyledAlert = (props) => {
    return (
        <>
            <Alert
                className="my-4"
                color={props?.type}
                variant="outlined"
                // color='deepPurple'
                sx={{ alignItems: 'flex-start', border: '1px solid #dbc8ff', borderRadius: '15px' }}
                startDecorator={props?.type === "danger" ? <Error/> : <AssignmentTwoTone />}
            >
                <div className='fw-light'>
                    {props?.content}
                </div>
            </Alert>
        </>
    )
}

export default StyledAlert;