import React from "react";
import ActionCalculator from "../ActionCalculator/ActionCalculator";
import CarbonFootPrintCalculator from "../CarbonFootPrintCalculator/CarbonFootPrintCalculator";
import TwentyOneDaysChallenge from "../TwentyOneDaysChallenge/TwentyOneDaysChallenge";
import SeventyFiveDaysChallenge from "../SeventyFiveDaysChallenge/SeventyFiveDaysChallenge";
import UnderProgress from "../UnderProgress/UnderProgress";

const ChallengeRenderer = ({ mainTopic, subTopic, markSubTopicComplete, setSelectedSubTopic, taskCompletionStatus }) => {
  let challengeComponent = null;
  switch (mainTopic?.title) {
    case "Sustainability Action Calculator":
      challengeComponent = <ActionCalculator />;
      break;
    case "21 Days Challenges":
      challengeComponent = (
        <TwentyOneDaysChallenge
          taskCompletionStatus={taskCompletionStatus}
          markSubTopicComplete={markSubTopicComplete}
          selectedSubTopic={mainTopic.subtopics || subTopic}
        />
      );
      break;
    case "75 Stages Towards Life":
      challengeComponent = (
        <SeventyFiveDaysChallenge
          taskCompletionStatus={taskCompletionStatus}
          markSubTopicComplete={markSubTopicComplete}
          selectedSubTopic={subTopic}
          setSelectedSubTopic={setSelectedSubTopic}
          selectedMainTopic={mainTopic}
        />
      );
      break;
    case "Carbon Footprint Calculator":
      challengeComponent = <CarbonFootPrintCalculator />;
      break;
    default:
      challengeComponent = <UnderProgress />;
  }

  return <>{challengeComponent}</>;
};

export default ChallengeRenderer;
