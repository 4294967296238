

import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import SewaRenderer from "./SewaRenderer";
import SewaSideBar from "./SewaSideBar";
import { handleDownload } from "./handleDownload.js";
import InstituteStepper from "../Sewa-Institute/InstituteStepper";
import { useParams } from "react-router-dom";
import ListIcon from '@mui/icons-material/List';
import { apiSewa } from "api";

const SewaPage = () => {
  const userOptions = {
    student: [
      {
        title: "Category",
        subtopics: [
        ],
      },   
      {
        title: "Add Mentor",
        subtopics: [],
      },
      {
        title: "View Projects",
        subtopics: [],
      },
      {
        title: "Student Details",
        subtopics: [],
      },
    
    ],
    institute: [
      {
        title: "Apply",
        subtopics: [],
      },
      {
        title: "Proof of Work",
        doc: true,
        subtopics: [],
      },
      {
        title: "Status",
        subtopics: [],
      },
      {
        title: "Documentation",
        doc: true,
        subtopics: [
          { title: "My Promise", subdoc: true },
          { title: "Hourly Schedule", subdoc: false },
        ],
      }
    ],
    teacher: [
      {
        title: "Project Requests",
        subtopics: [],
      },
      {
        title: "Project Details",
        subtopics: [],
      },
      {
        title: "Student",
        subtopics: [],
      },
      {
        title: "Marking",
        subtopics: [],
      },
      {
        title: "Work Proof",
        subtopics: [],
      },
      {
        title: "Documentation",
        doc: true,
        subtopics: [
          { title: "Mentor Document", subdoc: true },
          { title: "Student Document", subdoc: true },
        ],
      },
    ],
  };

  const getUserRoleOptions = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(user);
    return user && user.role && userOptions[user.role] ? userOptions[user.role] : [];
  };


  const getDefaultMainTopic = (role, options) => {
    if (role === "student") {
      return options.find(option => option.title === "Status") || options[0];
    }
    return options[0];
  };

  const getDefaultSubTopic = (mainTopic) => {
    return mainTopic.subtopics && mainTopic.subtopics.length > 0 ? mainTopic.subtopics[0] : null;
  };

  // const instituteId = 221;
  const { instituteId } = useParams();
console.log(instituteId);

  const [startingView, setStartingView] = useState(true);
  const [showCheckPolicyButton, setShowCheckPolicyButton] = useState(false);
  const [policyUploaded, setPolicyUploaded] = useState(false);
  const [options, setOptions] = useState(getUserRoleOptions());
  const [selectedMainTopic, setSelectedMainTopic] = useState(getDefaultMainTopic(JSON.parse(localStorage.getItem("user"))?.role, getUserRoleOptions()));
  const [selectedSubTopic, setSelectedSubTopic] = useState(getDefaultSubTopic(getDefaultMainTopic(JSON.parse(localStorage.getItem("user"))?.role, getUserRoleOptions())));
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1024);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isDetailsVisible, setIsDetailsVisible] = useState(true);


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1024);
      if (window.innerWidth > 1024) {
        setIsSidebarVisible(true);
        setIsDetailsVisible(true);
        document.body.classList.remove("show-details");
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isSmallScreen) {
      if (selectedSubTopic || selectedMainTopic) {
        setIsSidebarVisible(false);
        setIsDetailsVisible(true);
        document.body.classList.add("show-details");
      } else {
        setIsSidebarVisible(true);
        setIsDetailsVisible(false);
        document.body.classList.remove("show-details");
      }
    } else {
      setIsSidebarVisible(true);
      setIsDetailsVisible(true);
      document.body.classList.remove("show-details");
    }
  }, [selectedSubTopic, selectedMainTopic, isSmallScreen]);

  const handleToggle = () => {
    setIsSidebarVisible(prev => !prev);
    setIsDetailsVisible(prev => !prev);
    if (isSmallScreen) {
      document.body.classList.toggle("show-details");
    }
  };

  const getFormType = () => {
    if (selectedSubTopic) {
      switch (selectedSubTopic.title) {
        case "Mentor Documents":
          return "mentorObservation";
        case "Student Documents":
          return "promise";
        case "My Promise":
          return "promise";
        default:
          return null;
      }
    } else if (selectedMainTopic) {
      switch (selectedMainTopic.title) {
        case "Proof of Work":
          return "proofOfWork";
        case "Policy Document":
          return "sewapolicy";
        default:
          return null;
      }
    }
    return null;
  };



  // const handleButtonClick = () => {
  //   const formType = getFormType();
  //   if (formType) {
  //     handleDownload(formType);
  //   }
  // };
  

  const handleAcceptPolicy = () => {
    setPolicyUploaded(true);
    setStartingView(false);
    setShowCheckPolicyButton(false); 
  };



  useEffect(() => {
    console.log(process.env.SEWA_BACKEND_URL, "process.env.SEWA_BACKEND_URL value")
    const userData = JSON.parse(localStorage.getItem("user"));

    if (userData) {
      apiSewa.post(`/user_localstorage`, { data: userData })
        .then(response => {
          console.log('Data sent successfully:', response.data);
        })
        .catch(error => {
          console.error('Error sending data:', error);
        });
    }
  }, []);
  // useEffect(() => {
  //   if (instituteId) {
  //     apiSewa.get(`/institutes/check-startingview/${instituteId}`)
  //       .then(response => {
  //         const { policyUrl } = response.data;
  //         setStartingView(!policyUrl);
  //         console.log("check in policy",policyUrl)
  //       })
  //       .catch(error => {
  //         console.error("Error checking policy URL:", error);
  //       });
  //   }
  // }, [startingView]);

  return (
    <section className="dashboard-content-container" style={{ display: "flex" , background: "#F0F3F8",zIndex:0,
    }}>
      {startingView ? (
        <InstituteStepper
          onAccept={handleAcceptPolicy}
          setShowCheckPolicyButton={setShowCheckPolicyButton}
          showCheckPolicyButton={showCheckPolicyButton}
          setStartingView = {setStartingView}
          instituteId={instituteId}
          isSidebarVisible= {isSidebarVisible}

        />
      ) : (
        <>
          {isSidebarVisible && (
            <div className="options-progress-panel" style={{ flex: isSmallScreen ? '0 0 250px' : '0 0 300px' }}>
              <SewaSideBar
                pageName="Sewa"
                navigationData={options}
                setActiveTopic={setSelectedMainTopic}
                setActiveSubtopic={setSelectedSubTopic} 
                userOptions = {userOptions}
              />
            </div>
          )}
          {isDetailsVisible && (
            <section
              className={`options-progress-details-container ${isSmallScreen && !isSidebarVisible} ? "hide" : ""}`}
              style={{ flex: '1', padding: "1rem 0rem 1rem 0rem", gap:{md:"5rem" , xs:"3rem"} , flexDirection:"column",}}
            >
<Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: { xs: 'column', sm: 'row' }, 
    marginLeft:{xl:"0px" , xs:"1rem"},
      marginRight:{xl:"0px" , xs:"1rem"},
  }}
>
  <Box
    sx={{
      width: { xs: '100%', sm: '75%' , md:"90%" }, 
      height: '3.5rem',
      borderRadius: { xs: '30px', sm: '40px 0px 0px 40px' }, 
      backgroundColor: '#FFDD55',
      display: 'flex',
      padding: { xs: '1rem', sm: '5px 5px 5px 2rem' }, 
      alignItems: 'center',
      justifyContent: 'space-around',
      boxSizing: 'border-box',
      marginBottom: { xs:"5px" , md:"2rem"},
    }}
  >
    <div className="lets-begin-your-first-day-cha-parent">
      <Typography
        component="b"
        sx={{
          fontSize: { xs: '15px', md: '1.3rem' }, 
          fontWeight: 600,
          fontFamily: 'Poppins',
          
        }}
        className="preview"
      >
        {selectedSubTopic?.guideline || 'Earn Credits From Social Working'}
      </Typography>
      {/* <img className="tick-icon" alt="Tick Icon" src="/images/icons/tick.png" /> */}
    </div>
    <div className="click-here-to-container">
      <Typography
        component="span"
        sx={{
          fontSize: { xs: '15px', md: '1rem' }, 
          color: 'blue',
          textDecoration: 'underline',
          cursor: 'pointer',
          marginLeft:{xs:"0.3rem"}
        }}
        className="click-here"
      >
        Click here
      </Typography>
      <Typography component="span" className="to-know-more" sx={{   fontSize: { xs: '15px', md:'16px' }, }}>
        {' '}to know more.
      </Typography>
    </div>
  </Box>
</Box>

             
       <Box sx={{
        padding:"0rem 2.5rem  2.5rem 2.5rem"
       }}>
              {(!isSmallScreen || !isSidebarVisible) && (
                <SewaRenderer mainTopic={selectedMainTopic} subTopic={selectedSubTopic}  />
              )}
               </Box>
            </section>
          )}
        </>
      )}

      {isSmallScreen && !policyUploaded && showCheckPolicyButton && (
        <Button
          variant="contained"
          onClick={handleToggle}
          sx={{
            position: "fixed",
            bottom: "2rem",
            right: "2rem",
            backgroundColor: "#f55e39",
            color: "#fff",
            borderRadius: "50%",
            width: "3rem",
            height: "3rem",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            '&:hover': {
              backgroundColor: "#ca5639",
            },
          }}
        >
          Check Policy
        </Button>
      )}

{isSmallScreen && !isSidebarVisible && (
                <button
                    className="btn clubPanelToggle coursePanelToggle "
                    style={{border:"none",  backgroundColor: 'rgba(76, 175, 80, 0.5)' , color:"#fff"}}
                    onClick={handleToggle}
                >
                  <ListIcon />
            
                   
                </button>
            )}

    </section>
  );
};

export default SewaPage; 
