import React from "react";

const AdminDashboardComponent = () => {
	return (
		<>
			<div className="container">
				<div className="mx-2 px-2">
					<div className="mb-4">
						<h1 className="fs-2">Admin Dashboard</h1>
					</div>
					<div className="row">
						<div className="p-4 col-6">
							<div className="rounded-3 shadow  cursor-pointer">
								<div>
									<h3 className="fs-3 text-center w-100 fw-semibold">
										Call Details
									</h3>
								</div>
								<div>
									<p>Call Details</p>
								</div>
							</div>
						</div>
						<div className="p-4 col-6">
							<div className="rounded-3 shadow  cursor-pointer">
								<div>
									<h3 className="fs-3 text-center w-100 fw-semibold">
										Reminder
									</h3>
								</div>
								<div>
									<p>Reminder</p>
								</div>
							</div>
						</div>
						<div className="p-4 col-6">
							<div className="rounded-3 shadow  cursor-pointer">
								<div>
									<h3 className="fs-3 text-center w-100 fw-semibold">
										Registerations
									</h3>
								</div>
								<div>
									<p>Registerations</p>
								</div>
							</div>
						</div>
						<div className="p-4 col-6">
							<div className="rounded-3 shadow  cursor-pointer">
								<div>
									<h3 className="fs-3 text-center w-100 fw-semibold">
										Detail Sent
									</h3>
								</div>
								<div>
									<p>Detail Sent</p>
								</div>
							</div>
						</div>
						<div className="p-4 col-6">
							<div className="rounded-3 shadow  cursor-pointer">
								<div>
									<h3 className="fs-3 text-center w-100 fw-semibold">
										Detail Sent
									</h3>
								</div>
								<div>
									<p>Detail Sent</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AdminDashboardComponent;
