import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { apiJson } from 'api';
import { useParams } from 'react-router-dom';
import RemoveIcon from '@mui/icons-material/Remove';

export const AdminCallingStatusData = () => {
    const {id} = useParams();
    const [subAdminCallingStatusData, setSubAdminCallingStatusData] = useState([]);

    const getCallingStatus = ()=>{
        apiJson.get(`api/v2/admin/callingStatus/${id}`)
        .then((res)=>{
            setSubAdminCallingStatusData(res?.data?.getCallStatus)
        })
        .catch((error)=>{
            console.log(error.message);
        })
    }

    // Call Status
    const hanleGetCallData = (e) => {
        const value = e.target.value;
        if (value) {
            apiJson.get(`api/v2/admin/callingData/${value}/${id}`)
                .then((res) => {
                    setSubAdminCallingStatusData(res?.data?.getStatus)
                })
                .catch((error) => {
                    console.log(error.message);
                })
        }
        else {
            getCallingStatus();
        }
    }
    
    useEffect(()=>{
        getCallingStatus();
    },[])

  return (
    <div className='col-10 mx-auto'>
      <div><span className='fw-500 fs-3'>Calling Status Data</span></div>

      <div className='mt-2'>
      <div><span className='fw-500 fs-6 mt-1'>Calls</span></div>
      <div className='col-12 col-lg-3'>
        <select onClick={hanleGetCallData} className='rounded shadow-lg border-2 background-grey w-100'>
            <option value="">Select</option>
            <option value="connected">Connected</option>
            <option value="ringing">Ringing</option>
            <option value="disconnected">Disconnected</option>
            <option value="wrongnumber">Wrongnumber</option>
            <option value="cancelled">Cancelled</option>
            <option value="not interested">Not Interested</option>
        </select>
        </div>


      </div>


      <div className="mt-2 mb-2">
      <div><span className='fw-500 fs-6'>Total Calls: </span><span className='fw-500 fs-6'>{subAdminCallingStatusData.length}</span></div>
                <TableContainer component={Paper} >
                    <Table aria-label="simple table">
                        <TableHead sx={{background:"#9700de"}}>
                            <TableRow>
                                {/* <TableCell className='fw-semibold' align='center'  sx={{color:"#ffffff"}}>S.No</TableCell> */}
                                <TableCell className='fw-semibold' align='center'  sx={{color:"#ffffff"}}>Institute Name</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>Calling</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>Registration</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>Details</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>Reminder</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ padding: "5px 10px" }}>
                            {subAdminCallingStatusData?.map((row,i) => (
                                <TableRow
                                    key={row?.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, marginTop: "5px" }}
                                >
                                {/* <TableCell align='center'>{i + 1}</TableCell> */}
                                    <TableCell align="center"><span className={`text-capitalize`}>{row?.institution_name}</span></TableCell>
                                    <TableCell align="center" className={`text-capitalize fw-bolder ${row?.call_status === 'connected' ? 'text-success' : 'text-danger'}`}>{row?.call_status}</TableCell>
                                    <TableCell align='center' className={`text-capitalize fw-bolder ${row?.registration_status === 'done' ? 'text-success' : 'text-danger'}`}>{row?.registration_status}</TableCell>
                                    <TableCell align='center' className={`text-capitalize fw-bolder ${row?.detail_sent_status === 'sent' ? 'text-success' : 'text-danger'}`}>{row?.detail_sent_status}</TableCell>
                                    <TableCell align='center' className={`text-capitalize fw-bolder ${row?.reminder_status && 'text-warning'}`}>{row?.reminder_status !== null ? row?.reminder_status : <RemoveIcon/>}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
       </div>
    </div>
  )
}
