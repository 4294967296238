import React, { useEffect, useState } from 'react'
import groupimg from "../assests/Group 381.svg";
import { useParams } from 'react-router-dom'
import { apiJson } from 'api'

const SeventyCreditPoints = ({totalCredit}) => {
    const param = useParams()
    const [credit, setCredit] = useState()

    const getCreditScore = async ()=>{
        try{
            const response = await apiJson(`api/v2/75daysChallenge/showScore/${param?.attemptId}`)
            
            setCredit(response?.data?.result)
            totalCredit(response?.data?.result)
        }catch(err){
            console.log(err.message)
        }
    }

    useEffect(()=>{
        getCreditScore()
    },[])
  return (
    <p className='sm-w-60'>
    <img src={groupimg} alt="logo" className="pe-2" /> <span>{credit} </span>
    Credits
  </p>
  )
}

export default SeventyCreditPoints