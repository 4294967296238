import React, { useEffect, useState } from "react";
import "../carbonStyle/carbonStyle.css";
import Select from "react-select";
import { useContext } from "react";
import { CFContext } from "../context/carbonContext";
import { useNavigate } from "react-router";
import CarbonQuestion from "./CarbonQuestion";
import CarbonCategorySelector from "./CarbonCategorySelector";
import SaveIcon from '@mui/icons-material/Save';
import { toast } from "react-toastify";
import { apiJson } from "api";
import { Accordion, ButtonGroup, Card, ToggleButton } from "react-bootstrap";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useMediaQuery, useTheme } from "@mui/material";


export const CarbonMainSteper = () => {
  const { setCountStep,
    currentCategory, setCurrentCategory,
    questions, setQuestions,
    categoryQuestions, setCategoryQuestion,
    currentQuestionIndex, setCurrentQuestionIndex,
    categories,
    answerFormik,
    setAnswerData,
    handleNext,
    handlePrev, } = useContext(CFContext);
  // change countStep to 0 or 1 to toggle between view 
  const countStep = 0
  const renderStep = () => {
    switch (true) {
      case countStep === 0:
        return (
          <div className="mx-auto">
            <DropDownCarbonCalculator />
          </div>
        );
      case countStep === 1:
        return (
          <div>
            <SlideCarbonCalculator />
          </div>
        );
        // remove curly braces before removing comments from below line
      {
        // case countStep === 3:
      //   return (
      //     <div>
      //       <VehicleDetailStep3 />
      //     </div>
      //   );
      // case countStep === 4:
      //   return (
      //     <div>
      //       <RoundTripStep4 />
      //     </div>
      //   );
      // case countStep === 5:
      //   return (
      //     <div>
      //       <TravelbehaviourStep5 />
      //     </div>
      //   );
      // case countStep === 6:
      //   return (
      //     <div>
      //       <DomesticFlightStep6 />
      //     </div>
      //   );
      // case countStep === 7:
      //   return (
      //     <div>
      //       <InternationalFlightsStep7 />
      //     </div>
      //   );
      }

    }
  };
  const handleClickInc = () => {
    setCountStep(countStep + 1);
  };
  const handleClickDec = () => {
    setCountStep(countStep - 1);
  };
  return (
    <>
      {/* For large screen */}
      <div>
        <div className="">
    {/* {   countStep===1 &&   <div className="col-1" style={{ height: "100vh" }}>
            <div className="d-flex h-100 align-items-center">
              {
                //  countStep < 2 ? (
                //    <button
                //    className="ms-4 ms-2 d-none d-md-block"
                //    onClick={handlePrev}
                //    disabled={countStep < 2}
                //  >
                //    <img src="/modelUn/arrowdisabled.png" alt=""/>
                //  </button>
                //  ) :
                countStep === 1 && (
                  <button
                    className="d-none d-md-block"
                    onClick={handlePrev}
                  // disabled={countStep < 2}
                  >
                    <img src="/modelUn/arrowleft.png" alt="" />
                  </button>
                )
              }
            </div>
          </div>} */}


          <div className="">{renderStep()}</div>
    {/* {  countStep===1 &&    <div className="col-1" style={{ height: "100vh" }}>

            {countStep === 1 && <div className="d-flex h-100 align-items-center">
              <button
                className=" d-none d-md-block"
                onClick={handleNext}
                disabled={countStep > 6}
              >
                <img src="/modelUn/arrowRight.png" alt="" />
              </button>

            </div>}
          </div>} */}
        </div>
      </div>
      {/* for small screen */}
      {/* <div style={{ marginBottom: "65px" }}>
        <div className="d-flex justify-content-between mx-auto col-8 mt-4 mb-2 d-block d-md-none">
          {
            //   countStep < 2 ? (
            //    <button
            //    className="btn-onboard-fill-disabled d-block d-md-none"
            //    onClick={handlePrev}
            //    disabled={countStep < 2}
            //  >Previous
            //  </button>
            //  )
            //   : 
            countStep === 1 && (
              <button
                className="btn-onboard-fill d-block d-md-none"
                onClick={handlePrev}
              // disabled={countStep < 2}
              >Previous
              </button>
            )
          }
          {countStep === 1 && <button
            className="btn-onboard-fill d-block d-md-none"
            onClick={handleNext}
            disabled={countStep > 6}
          >Next
          </button>}
        </div>
      </div> */}

    </>
  );
};

const DropDownCarbonCalculator = () => {
  const navigate = useNavigate()
  const { setCountStep,
    currentCategory, setCurrentCategory,
    categoryQuestions, setCategoryQuestion,
    currentQuestionIndex, setCurrentQuestionIndex,
    categories,
    answerFormik,
    setAnswerData,
    handleNext,
    handlePrev, handleResetForm } = useContext(CFContext);

  const [category, setCategory] = useState([])
  const [questions, setquestions] = useState([])
  const [selectedOptions, setSelectedOptions] = useState({});
  const [activeKey, setActiveKey] = useState(null); 
  const [selectedOption, setSelectedOption] = useState(null);
  const [isCalculation, setIsCalculation] = useState(false)
  const [totalCarbon, setTotalCarbon] = useState(0)
  const [categoryTitle, setCategoryTitle] = useState("")
  const [screenWidth, setScreenWidth] = useState(document.documentElement.clientWidth);

    const handleAccordionClick = (key) => {
    setActiveKey(key === activeKey ? null : key); 
  };

  const transportCalculation = () =>{
    let fuelType= answerFormik?.values?.answer?.fuelTypeTrans ||"";
    let carbonEmissionByFuel = 0;
    if(fuelType==='Petrol'){
      carbonEmissionByFuel =2.31
    }
    else if(fuelType==='Diesel'){
      carbonEmissionByFuel =2.6391
    }
    else if(fuelType==='CNG'){
      carbonEmissionByFuel =2.666
    }
    else if(fuelType==='Electric'){
      carbonEmissionByFuel =1.2
    }
    let distance= answerFormik?.values?.answer?.distTrans || 5;
    let frequency= answerFormik?.values?.answer?.freqTrans || 2;
    let FuelEfficiency= answerFormik?.values?.answer?.fuelEfiTrans || 30;

    let total = Math.abs(((distance * frequency) / FuelEfficiency) * carbonEmissionByFuel * 52);

    console.log('total', total.toFixed(2))
    setAnswerData('carbonEmissionTrans', total.toFixed(2))
  }

  const cookingCalculation = () =>{
    let fuelType= answerFormik?.values?.answer?.fuelCook ||"";
    let carbonEmissionByFuel = 0;
    if(fuelType==='LPG (liquefied petroleum gas)'){
      carbonEmissionByFuel =1.51
    }
    else if(fuelType==='Natural Gas'){
      carbonEmissionByFuel =2.75
    }
    else if(fuelType==='Electric Stove'){
      carbonEmissionByFuel =1.5
    }
    else if(fuelType==='Biomass (wood, crop residues, etc.)'){
      carbonEmissionByFuel =1.8
    }else{
      carbonEmissionByFuel =1.5
    }
    let duration= answerFormik?.values?.answer?.durationCook || 2;
    let frequency= answerFormik?.values?.answer?.freqCook==='Daily'?7
    :answerFormik?.values?.answer?.freqCook==='Several times a week'?3
    :answerFormik?.values?.answer?.freqCook==='Once a week'?1 
    :1
    // let FuelEfficiency= answerFormik?.values?.answer?.fuelEfiTrans || '30';
    console.log('asfjsakldfj', frequency)
    let total = Math.abs((duration * frequency) * carbonEmissionByFuel * 52);

    console.log('total cook', total.toFixed(2))
    setAnswerData('carbonEmissionCook', total.toFixed(2))
  }
  const energyCalculation = () =>{
    let fuelType= answerFormik?.values?.answer?.typeEnergy ||"";
    let carbonEmissionByFuel = 0;
    if(fuelType==='LPG'){
      carbonEmissionByFuel =1.51
    }
    else if(fuelType==='Electricity'){
      carbonEmissionByFuel =2.75
    }
    else if(fuelType==='Solar panels'){
      carbonEmissionByFuel =1.5
    }
    else if(fuelType==='Wind turbine'){
      carbonEmissionByFuel =1.8
    }
    let consumption= answerFormik?.values?.answer?.consumptionEnergy || 20;
   
    // let FuelEfficiency= answerFormik?.values?.answer?.fuelEfiTrans || '30';
    // console.log('asfjsakldfj', frequency)
    let total = Math.abs((consumption * 12) * carbonEmissionByFuel);

    console.log('total cook', total.toFixed(2))
    setAnswerData('carbonEmissionEnergy', total.toFixed(2))
  }
  const heatingCalculation = () =>{
    let fuelType= answerFormik?.values?.answer?.heatingHeat ||"";
    let carbonEmissionByFuel = 0;
    if(fuelType==='Natural Gas'){
      carbonEmissionByFuel =1.51
    }
    else if(fuelType==='LPG (liquefied petroleum gas)'){
      carbonEmissionByFuel =2.75
    }
    else if(fuelType==='Electric Heating'){
      carbonEmissionByFuel =1.5
    }
    else if(fuelType==='Biomass (e.g., wood, crop residues)'){
      carbonEmissionByFuel =1.8
    }
    else if(fuelType==='No Heating Used'){
      carbonEmissionByFuel =0
    }
    let frequency= answerFormik?.values?.answer?.heatFreqHeat==='Daily'?7
    :answerFormik?.values?.answer?.heatFreqHeat==='Several times a week'?3
    :answerFormik?.values?.answer?.heatFreqHeat==='Once a week'?1 
    :1
    let total = Math.abs((frequency * 52) * carbonEmissionByFuel);

    // console.log('total cook', total.toFixed(2))
    setAnswerData('carbonEmissionHeat', total.toFixed(2))
  }
  const coolingCalculation = () =>{
    let fuelType= answerFormik?.values?.answer?.coolingHeat ||"";
    let carbonEmissionByFuel = 0;
    if(fuelType==='Air Conditioning (AC)'){
      carbonEmissionByFuel =1.51
    }
    else if(fuelType==='Ceiling Fans'){
      carbonEmissionByFuel =2.75
    }
    else if(fuelType==='No Cooling Used'){
      carbonEmissionByFuel =1.5
    }
  
    let frequency= answerFormik?.values?.answer?.coolFreqHeat==='Daily'?7
    :answerFormik?.values?.answer?.coolFreqHeat==='Several times a week'?3
    :answerFormik?.values?.answer?.coolFreqHeat==='Once a week'?1 
    :1
    let total = Math.abs((frequency * 52) * carbonEmissionByFuel);

    // console.log('total cook', total.toFixed(2))
    setAnswerData('carbonEmissionCool', total.toFixed(2))
  }

  const dietCalculation = () =>{
    let fuelType= answerFormik?.values?.answer?.habitDiet ||"";
    let carbonEmissionByFuel = 0;
    if(fuelType==='Vegetarian'){
      carbonEmissionByFuel =1.51
    }
    else if(fuelType==='Vegan'){
      carbonEmissionByFuel =2.75
    }
    else if(fuelType==='Omnivore'){
      carbonEmissionByFuel =1.5
    }
    else if(fuelType==='Pescatarian'){
      carbonEmissionByFuel =1.5
    }
  
    let frequency= answerFormik?.values?.answer?.freqDiet==='Daily'?7
    :answerFormik?.values?.answer?.freqDiet==='Several times a week'?3
    :answerFormik?.values?.answer?.freqDiet==='Once a week'?1 
    :1
    let total = Math.abs((frequency * 52) * carbonEmissionByFuel);

    // console.log('total cook', total.toFixed(2))
    setAnswerData('carbonEmissionCool', total.toFixed(2))
  }
  // const coolingCalculation = () =>{
  //   let fuelType= answerFormik?.values?.answer?.coolingHeat ||"";
  //   let carbonEmissionByFuel = 2.31;
  //   if(fuelType==='Natural Gas'){
  //     carbonEmissionByFuel =1.51
  //   }
  //   else if(fuelType==='LPG (liquefied petroleum gas)'){
  //     carbonEmissionByFuel =2.75
  //   }
  //   else if(fuelType==='Electric Heating'){
  //     carbonEmissionByFuel =1.5
  //   }
  //   else if(fuelType==='Biomass (e.g., wood, crop residues)'){
  //     carbonEmissionByFuel =1.8
  //   }
  //   else if(fuelType==='No Heating Used'){
  //     carbonEmissionByFuel =0
  //   }
  //   let frequency= answerFormik?.values?.answer?.coolFreqHeat==='Daily'?7
  //   :answerFormik?.values?.answer?.coolFreqHeat==='Several times a week'?3
  //   :answerFormik?.values?.answer?.coolFreqHeat==='Once a week'?1 
  //   :1
  //   let total= ((frequency*52))*carbonEmissionByFuel
  //   // console.log('total cook', total.toFixed(2))
  //   setAnswerData('carbonEmissionHeat', total.toFixed(2))
  // }



  const calculateTotalCarbon= ()=>{
    setIsCalculation(true)
    let total = 0
    total = Number(answerFormik?.values?.carbonEmissionTrans)
    +Number(answerFormik?.values?.carbonEmissionCook)
    +Number(answerFormik?.values?.carbonEmissionEnergy)+
    Number(answerFormik?.values?.carbonEmissionHeat)+
    Number(answerFormik?.values?.carbonEmissionDiet)+
    Number(answerFormik?.values?.carbonEmissionCool)
    // console.log(total)
    // console.log(Number(answerFormik?.values?.carbonEmissionTrans))
    setTotalCarbon(total.toFixed(2))

//     const stringsToMatch = ["Transportation", "Cooking", "Energy Consumption", "Home Heating and Cooling", "Diet"];
// const questionsNameShow = category?.find((ele) => stringsToMatch.includes(ele?.title));
// console.log("questionsNameShow", questionsNameShow);
// setCategoryTitle(questionsNameShow);
    // handleResetForm()
  }
  useEffect(()=>{
    transportCalculation()
    cookingCalculation()
    energyCalculation()
    heatingCalculation()
    coolingCalculation()
    dietCalculation()
    setIsCalculation(false)
  },[answerFormik?.values?.answer])

  const handleToggle = (value) => {
    setSelectedOptions((prevSelected) => ({
      ...prevSelected,
      [value]: !prevSelected[value],
    }));
  };

  const getallCategory = () => {
    apiJson('api/v2/cfc/cfcCategory')
      .then((res) => {
        setCategory(res.data.categories)
      }).catch((error) => {
        toast.dismiss()
        toast.error('Internal Server Error')
      })
  }
  const getAllQuestion = () => {
    apiJson('api/v2/cfc/cfcGetQuestion')
      .then((res) => {
        setquestions(res.data.questions)
      }).catch((error) => {
        toast.dismiss()
        toast.error('Internal Server Error')
      })
  }
  useEffect(() => {
    getallCategory();
    getAllQuestion();
  }, [])

  const handleResize = () => {
    setScreenWidth(document.documentElement.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 
  
  return (
    <div className="container">
    <div className="text-center mb-5 mt-3">
      <div className="d-flex justify-content-end">
      <h2 className="fs-2 m-auto text-center" style={{color:'purple'}}>Carbon Footprint Calculator</h2>
      {/* <Button varient="contained" sx={{background:"silver",color:"green"}} onClick={()=>navigate("/new-dashboard/carbon/carbon-leaderboard")}>Leaderboard</Button> */}
      </div>
    </div>
      <div className="col-12 container mb-3">
      {
        category?.map((cat) => {
          return( 
            <div >
              <div className="col-10">
          <Accordion defaultActiveKey={category[0]?.id} onSelect={handleAccordionClick} activeKey={activeKey}  >
            <Accordion.Item eventKey={cat?.id}>
              <Accordion.Header><span className="fs-5" style={{color:'#6100ff'}}>{cat?.title}</span></Accordion.Header>
              <div className="col-12">
              <Accordion.Body >
                <div className="col-lg-12">
                {questions?.map((question) => {
                  return question.cat_id === cat.id && <Card className='mb-3 shadow ' style={{background:'#fcefff'}}>
                    <Card.Body>
                      <div className="mb-3">
                        <span className="fs-17px " style={{color:'#670067'}}>{question.text}</span>
                      </div>
                      <div>
                     {/* if question type is radio checkbox */}
                        {
                          ['radio', 'checkbox'].includes(question?.type) &&
                          question?.options?.map((o, index) => (
                            <div class="btn-group rounded-3 box-shadow-0 border-0" role="group" >
                              <input onChange={(e) => {
                                const answerKey = question.correct || `type${question?.id}`;
                                setAnswerData('answer', {
                                  ...answerFormik.values.answer,
                                  [answerKey]: o.value,
                                });
                                // setSelectedOption(o.value);
                              }} style={{color: "grey"}} type="radio" class="btn-check rounded-2" name={`name-${question.id}`} id={o?.value+question?.id}  placeholder={`${question?.text}`} autocomplete="off" />
                              <label class={`  me-3 mb-2 rounded-3 ${answerFormik?.values?.answer[question.correct] === o.value ? 'btn-outline-carbon-active' : 'btn-outline-carbon-custom'}`} for={o?.value+question?.id}>{o.value}</label>
                            </div>
                          ))



                        }
                        {
                          // if question.type === text
                          ['input', 'text'].includes(question?.type) &&
                          <div className="col-lg-8">
                            <input onChange={(e) => {
                                const answerKey = question.correct || `type${question?.id}`;
                                setAnswerData('answer', {
                                  ...answerFormik.values.answer,
                                  [answerKey]: e.target.value,
                                });
                              }}  placeholder={`${question?.text}`} type='text' className='form-control resize-none rounded-3 box-shadow-0 border-0'  />
                          </div>
                        }
                        {
                          // if question.type === text
                          ['number'].includes(question?.type) &&
                          <div className="col-lg-8">
                            <input onChange={(e) => {
                                const answerKey = question.correct || `type${question?.id}`;
                                setAnswerData('answer', {
                                  ...answerFormik.values.answer,
                                  [answerKey]: e.target.value,
                                });
                              }}  placeholder={`${question?.text}`} type='number' className="form-control rounded-3 box-shadow-0 border-0"  />
                          </div>
                        }
                        {
                          // if question.type === select
                          question?.type === 'select' &&
                          <div>
                            <select onChange={(e) => {
                                const answerKey = question.correct || `type${question?.id}`;
                                setAnswerData('answer', {
                                  ...answerFormik.values.answer,
                                  [answerKey]: e.target.value
                                });
                              }} class="form-select rounded-3 form-control" aria-label="Default select example">
                              <option selected>Select an Option</option>

                              {
                                question.options?.map((op) => {
                                  return <option value={op.value}>{op.value}</option>
                                })
                              }
                            </select>
                          </div>
                        }
                      </div>
                    </Card.Body>
                  </Card>
                })}
                </div>
              </Accordion.Body>
              </div>
            </Accordion.Item>
          </Accordion>
              </div>
            </div>
        )})

      }
      </div>
      <div className="col-10 d-flex justify-content-center ms-4 mb-3 mt-3">
        {
          <Button onClick={calculateTotalCarbon} style={{background: "rgb(0, 20, 200)"}} variant="contained">
              <span className="fs-4 color-white"> Calculate</span>
              
          </Button>
        }
      </div>
      {/* <div className="text-center">
      { isCalculation &&
          <span className="fs-4 fw-semibold" style={{color:'purple'}}>{totalCarbon} kgCO2e Per Year</span>
        }
      </div>
      
      <div className="text-center">
      { isCalculation &&
          <span className="fs-4 fw-semibold" style={{color:'purple'}}>{(totalCarbon / 12).toFixed(2) } kgCO2e Per Month</span>
        }
      </div>
      <div className="text-center">
      { isCalculation &&
          <span className="fs-4 fw-semibold" style={{color:'purple'}}>{(totalCarbon / 52).toFixed(2)} kgCO2e Per Week</span>
        }
      </div>
      <div className="text-center">
      { isCalculation &&
          <span className="fs-4 fw-semibold" style={{color:'purple'}}>{(totalCarbon / 365).toFixed(2)} kgCO2e Per Day</span>
        }
      </div> */}

      <div className="margin-sm-screen col-lg-10 ms-4 p-md-3 p-lg-3 mb-5" style={{width:screenWidth<500?`${screenWidth-50}px`:""}}>
      <TableContainer component={Paper}  
      TabIndicatorProps={{
        style: {
          backgroundColor: "#914EFF",
          height: 4,
          width: 95,
          marginLeft: "2px"
        }
      }}
      variant={isMobile ? 'scrollable' : 'standard'}
      scrollButtons={isMobile ? 'auto' : 'off'}
      >
      <Table aria-label="">
        <TableHead>
          <TableRow style={{background: "#bbdefb"}}>
            {/* <TableCell>Dessert (100g serving)</TableCell> */}
            <TableCell align="center" style={{fontSize: "16px", fontWeight: "600", color: "#8c32ff"}}>Per Day&nbsp;(kgCO2e)</TableCell>
            <TableCell align="center" style={{fontSize: "16px", fontWeight: "600", color: "#8c32ff"}}>Per Week&nbsp;(kgCO2e)</TableCell>
            <TableCell align="center" style={{fontSize: "16px", fontWeight: "600", color: "#8c32ff"}}>Per Month&nbsp;(kgCO2e)</TableCell>
            <TableCell align="center" style={{fontSize: "16px", fontWeight: "600", color: "#8c32ff"}}>Per Year&nbsp;(kgCO2e)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow
              // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {/* <TableCell component="th" scope="row">
                {categoryTitle?.title || "-"}
              </TableCell> */}
              <TableCell align="center" size="large" style={{fontSize: "16px", fontWeight: "700"}}>{(totalCarbon / 365).toFixed(2) || 0.00}</TableCell>
              <TableCell align="center" style={{fontSize: "16px", fontWeight: "700"}}>{(totalCarbon / 52).toFixed(2) || 0.00}</TableCell>
              <TableCell align="center" style={{fontSize: "16px", fontWeight: "700"}}>{(totalCarbon / 12).toFixed(2) || 0.00}</TableCell>
              <TableCell align="center" style={{fontSize: "16px", fontWeight: "700"}}>{totalCarbon || 0.00}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </div>
      
    </div>

  );
};

const SlideCarbonCalculator = () => {
  const navigate = useNavigate()
  const {
    countStep,
    setCountStep,
    currentCategory, setCurrentCategory,
    questions, setQuestions,
    categoryQuestions, setCategoryQuestion,
    currentQuestionIndex, setCurrentQuestionIndex,
    categories,

    handleNext,
    handlePrev,
  } = useContext(CFContext)

  console.log('categoryQuestions', categoryQuestions)
  console.log('currentQuestionIndex', currentQuestionIndex)


  const handleClick = () => {
    navigate("/new-dashboard")
  }
  return (
    <div className=" container mt-5">
      <CarbonCategorySelector
        categories={categories}
        selectedCategory={currentCategory}
        onSelectCategory={setCurrentCategory}
      />
      <CarbonQuestion question={categoryQuestions[currentQuestionIndex]} />

    </div>
  );
};

const VehicleDetailStep3 = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate("/new-dashboard")
  }
  return (
    <div>
      {/* Please specify a vehicle Details */}
      <div className="col-11 mx-auto mt-4">
        <div className="d-flex align-items-center">
          <div onClick={handleClick}>
            <img src="/modelUn/VectorCarbon.png" alt="" />
          </div>
          <div className="carbon-heading px-2">
            <span className="" style={{ color: "#7209B7" }}>
              Carbon Footprint Calculator
            </span>
          </div>
        </div>

        <div style={{ marginTop: "7rem" }}>
          <div className="mt-5">
            <div className="text-center">
              <span className=" fw-400 fs-32px" style={{ color: "#B3B3B3" }}>
                Transportation{" "}
              </span>
            </div>
            <div className="text-center mt-4">
              <span className="fw-600-fs-32px">
                Please specify a vehicle Details (If Applicable)
              </span>
            </div>
          </div>

          <div
            className="d-flex justify-content-center mx-auto col-md-12 col-lg-10"
            tyle={{ border: "1px solid" }}
          >
            <div className="mt-2 ">
              {/* <div className="row"> */}
              <div className="">
                <form>
                  <div className="row">
                    {/* fuelType */}
                    <div className="col-12 col-md-6 mt-1">
                      <div>
                        <span className="" style={{ fontSize: "18px" }}>FuelType</span>
                      </div>
                      <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-12">
                        <Select
                          styles={customStyles}
                          options={fuelType}
                          defaultValue={fuelType[0]}
                          getOptionLabel={(option) => {
                            return (
                              <>
                                <div className="p-2">
                                  <span
                                    className="fs-17px fw-semibold text-center"
                                    style={{ color: "#000000" }}
                                  >
                                    {option.name}
                                  </span>
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>

                    {/* Fuel Efficiency */}

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div>
                        <div style={{ width: "20rem", marginTop: "5px" }}>
                          <span className="" style={{ fontSize: "18px" }}>Fuel Efficiency (in km/l or km/kWh)</span>
                        </div>
                        <div className="mt-3 col-8">
                          <input
                            className="input-handle p-3"
                            type="number"
                            placeholder="km/l or km/kWh"
                          />
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    {/* </div> */}

                    {/* fuel */}
                    {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-5">
                      <div>
                        <span className="fw-400 fs-21px">Fuel Type</span>
                      </div>
                      <div className="mt-3 col-12 col-sm-6 col-md-12 col-lg-6">
                      <Select
                          styles={customStyles}
                          options={fuelType}
                          defaultValue={fuelType[0]}
                          getOptionLabel={(option) => {
                            return (
                              <>
                                <div className="p-2">
                                  <span
                                    className="fs-17px fw-semibold text-center"
                                    style={{ color: "#000000" }}
                                  >
                                    {option.name}
                                  </span>
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div> */}

                    {/* km */}
                    {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-5">
                      <div>
                        <span className="fw-400 fs-21px">
                          Fuel Efficiency (in km/l or km/kWh)
                        </span>
                      </div>
                      <div className="mt-3 col-12 col-sm-6 col-md-12 col-lg-6">
                      <Select
                          styles={customStyles}
                          options={FuelEfficiency}
                          defaultValue={FuelEfficiency[0]}
                          getOptionLabel={(option) => {
                            return (
                              <>
                                <div className="p-2">
                                  <span
                                    className="fs-17px fw-semibold text-center"
                                    style={{ color: "#000000" }}
                                  >
                                    {option.name}
                                  </span>
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RoundTripStep4 = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate("/new-dashboard")
  }
  return (
    <div>
      {/* Please specify a vehicle Details */}
      <div className="col-11 mx-auto mt-4">
        <div className="d-flex align-items-center">
          <div onClick={handleClick}>
            <img src="/modelUn/VectorCarbon.png" alt="" />
          </div>
          <div className="carbon-heading px-2">
            <span className="" style={{ color: "#7209B7" }}>
              Carbon Footprint Calculator
            </span>
          </div>
        </div>

        <div style={{ marginTop: "7rem" }}>
          <div className="mt-5">
            <div className="text-center">
              <span className=" fw-400 fs-32px" style={{ color: "#B3B3B3" }}>
                Transportation{" "}
              </span>
            </div>
            <div className="text-center mt-4">
              <span className="fw-600-fs-32px">
                Average Daily Commute (Round Trip)
              </span>
            </div>
          </div>

          <div className="mt-5">
            <div className="d-flex justify-content-center mx-auto col-lg-11">
              <div className="col-11 d-flex justify-content-center">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div>
                      <div>
                        <span className="" style={{ fontSize: "18px" }}>Distance (in kilometers)</span>
                      </div>
                      <div className="mt-3">
                        <input
                          className="input-handle p-3"
                          type="number"
                          placeholder="kilometers"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div>
                      <div>
                        <span className="" style={{ fontSize: "18px" }}>Frequency (days per week)</span>
                      </div>
                      <div className="mt-3">
                        <input
                          className="input-handle p-3"
                          type="number"
                          placeholder="days per week"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TravelbehaviourStep5 = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate("/new-dashboard")
  }
  return (
    <div>
      {/*--- // Travel Behavior (per year, if applicable)---*/}
      <div className="col-11 mx-auto mt-4">
        <div className="d-flex align-items-center">
          <div onClick={handleClick}>
            <img src="/modelUn/VectorCarbon.png" alt="" />
          </div>
          <div className="carbon-heading px-2">
            <span className="" style={{ color: "#7209B7" }}>
              Carbon Footprint Calculator
            </span>
          </div>
        </div>

        <div style={{ marginTop: "7rem" }}>
          <div className="mt-5">
            <div className="text-center">
              <span className=" fw-400 fs-32px" style={{ color: "#B3B3B3" }}>
                Transportation{" "}
              </span>
            </div>
            <div className="text-center mt-4">
              <span className="fw-600-fs-32px">
                Travel Behavior (per year, if applicable)
              </span>
            </div>
          </div>

          <div className="mt-5">
            <div className="d-flex justify-content-center mx-auto col-12">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="d-flex align-items-center">
                      <div className="mt-1">
                        <div className="mb-4">
                          <label className="inp-label">
                            <input
                              type="checkbox"
                              className="p-3"
                            />
                            <span
                              className="checkmark"
                              style={{ borderRadius: "5px" }}
                            ></span>
                          </label>
                        </div>
                      </div>
                      <div className="px-4">
                        <span className="fw-600-fs-24px">Domestic Flights</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="d-flex align-items-center">
                      <div className="mt-1">
                        <div className="mb-4">
                          <label className="inp-label">
                            <input
                              type="checkbox"
                              className="p-3"
                            />
                            <span
                              className="checkmark"
                              style={{ borderRadius: "5px" }}
                            ></span>
                          </label>
                        </div>
                      </div>
                      <div className="px-4">
                        <span className="fw-600-fs-24px">
                          International Flights
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DomesticFlightStep6 = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate("/new-dashboard")
  }
  return (
    <div>
      {/* Please specify a vehicle Details */}
      <div className="col-11 mx-auto mt-4">
        <div className="d-flex align-items-center">
          <div onClick={handleClick}>
            <img src="/modelUn/VectorCarbon.png" alt="" />
          </div>
          <div className="carbon-heading px-2">
            <span className="" style={{ color: "#7209B7" }}>
              Carbon Footprint Calculator
            </span>
          </div>
        </div>

        <div style={{ marginTop: "7rem" }}>
          <div className="mt-5">
            <div className="text-center">
              <span className=" fw-400 fs-32px" style={{ color: "#B3B3B3" }}>
                Transportation{" "}
              </span>
            </div>
            <div className="text-center mt-4">
              <span className="fw-600-fs-32px">
                Travel Behavior (per year, if applicable)
              </span>
            </div>
          </div>

          <div className="mt-4 text-center">
            <span className="fw-600-fs-24px">Domestic Flights</span>
          </div>

          <div className="mt-4">
            <div className="d-flex justify-content-center mx-auto col-11">
              <div className="col-12 d-flex justify-content-center">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div>
                      <div>
                        <span className="" style={{ fontSize: "18px" }}>Number of flights</span>
                      </div>
                      <div className="mt-3">
                        <input
                          className="input-handle p-3"
                          type="number"
                          placeholder="Number of flights"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div>
                      <div>
                        <span className="" style={{ fontSize: "18px" }}>Average distance (one way)</span>
                      </div>
                      <div className="mt-3">
                        <input
                          className="input-handle p-3"
                          type="number"
                          placeholder="one way, in kilometers"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InternationalFlightsStep7 = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate("/new-dashboard")
  }
  return (
    <div>
      {/* Please specify a vehicle Details */}
      <div className="col-11 mx-auto mt-4">
        <div className="d-flex align-items-center">
          <div onClick={handleClick}>
            <img src="/modelUn/VectorCarbon.png" alt="" />
          </div>
          <div className="carbon-heading px-2">
            <span className="" style={{ color: "#7209B7" }}>
              Carbon Footprint Calculator
            </span>
          </div>
        </div>

        <div style={{ marginTop: "7rem" }}>
          <div className="mt-5">
            <div className="text-center">
              <span className=" fw-400 fs-32px" style={{ color: "#B3B3B3" }}>
                Transportation{" "}
              </span>
            </div>
            <div className="text-center mt-4">
              <span className="fw-600-fs-32px">
                Travel Behavior (per year, if applicable)
              </span>
            </div>
          </div>
        </div>

        <div className="mt-4 text-center">
          <span className="fw-600-fs-24px">International Flights</span>
        </div>

        <div className="mt-4">
          <div className="d-flex justify-content-center mx-auto col-11">
            <div className="col-12 d-flex justify-content-center">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div>
                    <div>
                      <span className="" style={{ fontSize: "18px" }}>Number of flights</span>
                    </div>
                    <div className="mt-3">
                      <input
                        className="input-handle p-3"
                        type="number"
                        placeholder="Number of flights"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div>
                    <div>
                      <span className="" style={{ fontSize: "18px" }}>Average distance (one way)</span>
                    </div>
                    <div className="mt-3">
                      <input
                        className="input-handle p-3"
                        type="number"
                        placeholder="one way, in kilometers"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "280px",
    // padding: "3px",
    border: "none",
    background: "#F9F9F9",
    height: "57px",
    // color: "black",
    // boxShadow:'none'
  }),
};

const fuelType = [
  {
    value: 1,
    name: "Petrol",
  },
  {
    value: 2,
    name: "Diesel",
  },
  {
    value: 3,
    name: "CNG"
  },
  {
    value: 4,
    name: "Electric"
  },
];

const residental = [
  {
    img: "/modelUn/YoungmanOrderingTaxi.png",
    area: "Urban",
  },
  {
    img: "/modelUn/Travelnaturalplaces.png",
    area: "Rural",
  },
];
