import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { apiAuth, apiJson } from "api";
import credit_icon from "../../../twentyOneDaysChallenge/assests/Group 381.svg";
import Seva_Logo from "../../assets/Seva/Seva_logo.png";

const StudentPOW = () => {
  const [fileName, setFileName] = useState("");
  const [dailyTaskFileLink, setPOWFileLink] = useState("");
  const [dailyTaskFileError, setPOWFileError] = useState("");
  const [activeDayData, setActiveDayData] = useState({
    credit: 25,
    description: "Do this and perform that.",
    task_count: 1,
  });
  const [isComplete, setIsComplete] = useState(false);
  const [userData] = useState(() => JSON.parse(localStorage.getItem("user")));
  const taskEndpoint = "/api/v2/seva";
  const submitEndpoint = "/api/v2/seva/submitPOW";

  // useEffect(() => {
  //     getTask();
  // }, [taskEndpoint]);

  // const getTask = async () => {
  //     try {
  //         const response = await apiJson.get(`${taskEndpoint}/getAllTask`);
  //         setActiveDayData(response?.data?.result[0]);
  //     } catch (err) {
  //         console.log(err.message);
  //     }
  // };

  const getImageLink = (imageFileData) => {
    if (imageFileData) {
      apiAuth
        .post("v2/register/uploadInstituteLogo", { img: imageFileData })
        .then((res) => {
          console.log(res, "response value");
          setPOWFileLink(res?.data?.result);
        })
        .catch((error) => {
          console.log("Internal server error", error.message);
        });
    }
  };

  const validationSchema = yup.object({
    experience: yup.string().trim().required("Please enter your experience"),
  });

  const formik = useFormik({
    initialValues: { experience: "" },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      debugger;
      if (!dailyTaskFileLink) {
        setPOWFileError("Please select file");
        return;
      }

      const data = {
        userId: userData.id,
        role: userData.role,
        instituteId: userData.instituteId,
        taskId: activeDayData?.taskId || null,
        status: "Completed",
        experience: values.experience,
        images: dailyTaskFileLink,
      };

      try {
        const response = await apiJson.post(submitEndpoint, data);
        if (response.status === 200) {
          console.log(data);
          setIsComplete(true);
          resetForm();
          setPOWFileLink("");
          setFileName("");
        } else {
          console.log("Failed to submit the challenge");
        }
      } catch (err) {
        console.error("API call error:", err);
      }
    },
  });

  const handleFiles = (files) => {
    setPOWFileError("");
    const file = files[0];
    if (file && file.type === "application/zip") {
      getImageLink(file);
      setFileName(file.name);
    } else {
      setPOWFileError("Invalid File. Please upload a zip folder.");
    }
  };

  useEffect(() => {
    const dropArea = document.getElementById("drop-area");

    if (!dropArea) {
      return;
    }

    const events = ["dragenter", "dragover", "dragleave", "drop"];

    const preventDefaults = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const highlight = (e) => {
      dropArea.classList.add("highlight");
    };

    const unHighlight = (e) => {
      dropArea.classList.remove("highlight");
    };

    const handleDrop = (e) => {
      const dt = e.dataTransfer;
      const files = dt.files;
      handleFiles(files);
    };

    events.forEach((eventName) => {
      dropArea.addEventListener(eventName, preventDefaults, false);
    });

    ["dragenter", "dragover"].forEach((eventName) => {
      dropArea.addEventListener(eventName, highlight, false);
    });

    ["dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, unHighlight, false);
    });

    dropArea.addEventListener("drop", handleDrop, false);

    return () => {
      events.forEach((eventName) => {
        dropArea.removeEventListener(eventName, preventDefaults, false);
      });

      ["dragenter", "dragover"].forEach((eventName) => {
        dropArea.removeEventListener(eventName, highlight, false);
      });

      ["dragleave", "drop"].forEach((eventName) => {
        dropArea.removeEventListener(eventName, unHighlight, false);
      });

      dropArea.removeEventListener("drop", handleDrop, false);
    };
  }, []);

  return (
    <>
   
      {!isComplete && (
        <Box
          sx={{
            padding: "2rem 2rem 2rem 1.5rem",
            boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
            borderRadius: "1rem",
            backgroundColor: "var(--color-ghostwhite)",
            border: "5px solid #2356fe",
            boxSizing: "border-box",
            width: "auto",
          }}
        >
          {/* Challenge Description Wrapper - Top Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "@media(max-width:900px)": {
                flexDirection: "column-reverse",
              },
            }}
          >
            {/* Challenge Details - Left Pane */}
            <Box>
              {/* Challenge Title */}
              <Typography
                component={"h1"}
                sx={{
                  fontSize: "1.6rem",
                  fontWeight: 900,
                  color: "#2356fe",
                  padding: "1rem 0rem 1rem 0rem",
                }}
              >
                Complete the Challenge : Take Action{" "}
              </Typography>
              {/* Challenge Description */}
              <Typography
                component={"p"}
                sx={{
                  fontWeight: 600,
                  color: "gray",
                }}
              >
                {" "}
                {activeDayData
                  ? activeDayData?.description
                  : "Carry A Non-Plastic Bottle While Steeping Out Of Homes"}
              </Typography>
            </Box>

            {/* Credits Display Container - Right Pane */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                alignItems: "center",
              }}
            >
              {/* Logo of 21 Days Challenge */}
              <Box>
                <Typography
                  component={"img"}
                  src={Seva_Logo}
                  alt="image here"
                  sx={{ width: "150px" }}
                />
              </Box>
              {/* Credits Points Wrapper */}
              {/* <Box>
                                    <Box
                                        variant="contained"
                                        sx={{
                                            background: "#000",
                                            textTransform: "none",
                                            padding: "1rem",
                                            borderRadius: "0.6rem",
                                            fontWeight: 600,
                                            color: "#fff",
                                            ":hover": {
                                                background: "#000",
                                            },
                                        }}
                                    > */}
              {/* Leaf Icon Display */}
              {/* <Typography
                                            component={"img"}
                                            src={credit_icon}
                                            alt="image here"
                                            sx={{ width: "30px" }}
                                        />{" "}
                                        {/* Credits Display */}
              {/* <Typography component={"span"} sx={{ fontWeight: 60 }}>
                                            {" "}
                                            {activeDayData?.credit || 0}
                                        </Typography>
                                        <Typography component={"span"} sx={{ fontWeight: 60 }}>
                                            {""} Credits
                                        </Typography> */}
              {/* </Box>
                                </Box> */}
            </Box>
          </Box>

          {/* Challenge Display Area - Bottom Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              "@media(max-width:900px)": {
                flexDirection: "column",
              },
            }}
          >
            {/* Task Upload - Left Pane */}
            <Box
              sx={{
                width: "100%",
                padding: "0.8rem",
                "@media(max-width:900px)": {
                  width: "100%",
                },
              }}
            >
              {/* File Upload Section */}
              <Box>
                <Box
                  sx={{
                    position: "relative",
                  }}
                  id="drop-area"
                >
                  {/* Input Section */}
                  <Typography
                    component={"input"}
                    sx={{
                      display: "none",
                    }}
                    type="file"
                    id="fileElem"
                    accept="image/*"
                    onChange={(e) => handleFiles(e.target.files)}
                  />
                  {/* Label for the Image Input */}
                  <Typography
                    component={"label"}
                    sx={{
                      padding: "10px",
                      cursor: "pointer",
                      borderRadius: "6px",
                      minWidth: "100%",
                      maxWidth: "100%",
                      boxSizing: "border-box",
                      border: "2px dashed black",
                      height: "150px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f6f6f6",
                      ":hover": {
                        backgroundColor: "#e9e9e9",
                      },
                      ":active": {
                        backgroundColor: "#f6f6f6",
                      },
                    }}
                    htmlFor="fileElem"
                  >
                    <Typography
                      component={"div"}
                      sx={{
                        color: "#666",
                        userSelect: "none",
                        textAlign: "center",
                      }}
                    >
                      Drag Image here or click to upload
                    </Typography>
                  </Typography>

                  {/* Line Below the Input Section */}
                  <Typography
                    component={"p"}
                    sx={{
                      fontSize: "0.85rem",
                      padding: "0.2rem",
                      textAlign: "center",
                    }}
                  >
                    Drag File Here Or Click To{" "}
                    <Typography component={"span"} sx={{ fontWeight: 600 }}>
                      Select File To Upload{" "}
                    </Typography>{" "}
                    Proof Of Activity
                  </Typography>
                  {/* File Upload Error Box */}
                  <Typography
                    component={"p"}
                    sx={{
                      textAlign: "center",
                      color: "red",
                      padding: 0,
                    }}
                  >
                    {dailyTaskFileError}
                  </Typography>
                  {/* File Name Display */}
                  <Typography
                    component={"p"}
                    sx={{
                      textAlign: "center",
                      color: "#666",
                      padding: 0,
                    }}
                  >
                    {fileName}
                  </Typography>
                </Box>
              </Box>

              {/* Experience Section */}
              <Box
                sx={{
                  borderRadius: "0.4rem",
                  padding: "1rem 0rem 0rem 0rem",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                  "@media(max-width:900px)": {
                    flexDirection: "column",
                  },
                }}
              >
                {/* Left Pane for Form for Experience Input */}
                <Box
                  sx={{
                    width: "100%",
                    "@media(max-width:900px)": { width: "100%" },
                  }}
                >
                  <Box>
                    <form onSubmit={formik.handleSubmit}>
                      {/* Input field and Error Area */}
                      <Box>
                        {/* Textarea Input Section */}
                        <Typography
                          component={"textarea"}
                          rows="3"
                          cols="40"
                          id="experience"
                          type="text"
                          sx={{
                            borderRadius: "0.5rem",
                            backgroundColor: "#f0f0f0",
                            overflow: "hidden",
                            fontSize: "0.8rem",
                            border: "none",
                            padding: "0.7rem",
                            width: "100%",
                            ":focus-visible": {
                              border: "none ! important",
                              outline: "none",
                            },
                            "@media(max-width:900px)": {
                              width: "100%",
                            },
                          }}
                          value={formik.values.experience}
                          placeholder="Share your Experience in 50 words"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* Error Display Area */}
                        {formik.touched.experience &&
                          formik.errors.experience && (
                            <Typography
                              component={"p"}
                              sx={{
                                color: "red",
                                paddingTop: "0.2rem",
                              }}
                            >
                              {formik.errors.experience}
                            </Typography>
                          )}
                      </Box>
                      {/* Button Container */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "0.6rem",
                        //   gap: "10px",
                        }}
                      >
                        {/* Later Button */}
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{
                            background: "gray",
                            borderRadius: "0.8rem",
                          }}
                        >
                          later
                        </Button>
                        {/* Submit Button */}
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{
                            background: "#2356fe",
                            borderRadius: "0.8rem",
                          }}
                          disabled={!formik.isValid && !formik.dirty}
                        >
                          Submit
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Box>
                {/* Right Pane */}
                {/* <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    "@media(max-width:900px)": {
                      alignItems: "flex-start",
                      gap: 0,
                    },
                  }}
                > */}
                  {/* <Typography
                    component={"p"}
                    sx={{
                      fontSize: "0.7rem",
                    }}
                  >
                    On Successful Completion Of This Challenge You Will Get
                  </Typography> */}
                  {/* Credits Display wrapper */}
                  {/* <Box
                                            sx={{
                                                display: "flex",
                                                gap: 0.48,
                                                alignItems: "center",
                                                backgroundColor: "#f0f0f0",
                                                padding: "0.5rem",
                                                borderRadius: "0.5rem",
                                                color: "#2356fe",
                                            }}
                                        >
                                            <Typography
                                                component={"img"}
                                                sx={{
                                                    width: "30px",
                                                }}
                                                src={credit_icon}
                                                alt="image here"
                                            />
                                            <Typography
                                                component={"span"}
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    color: "#000",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {activeDayData?.credit || 0}
                                            </Typography>
                                            <Typography
                                                component={"span"}
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    color: "#000",
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Credits
                                            </Typography>
                                        </Box> */}
                {/* </Box> */}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default StudentPOW;
