import React, { useState, useCallback } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Divider,
  CircularProgress,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { Popup } from "layout/Popup";

// Debounce function to limit the rate of API calls
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

const EmailInput = ({ title, goSteps, handleSteps, getSearchData, deleteData, errormsg }) => {
  const [searchData, setSearchData] = useState(null);
  const [deleteEmail, setDeleteEmail] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailNotFound, setEmailNotFound] = useState(false); // State for email not found
  const message = "Email has been successfully deleted.";
  const token = localStorage.getItem("token");

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  // API call function for searching the email
  const searchEmail = async (values) => {
    setLoading(true);
    setEmailNotFound(false); // Reset the state
    try {
      const response = await axios.post(
        `https://betaapi.yuvamanthan.org/api/v2/deleteUser${getSearchData}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data.email) {
        setSearchData(response.data);
      } else {
        setEmailNotFound(true); // Set the state to show the "Next" button
        Popup("error", "Email not found. Please click next to proceed.");
      }
    } catch (error) {
      setEmailNotFound(true); // Set the state to show the "Next" button
      console.log("error", "there is an error")
      // Popup("warning", "Email doesn't exist. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Debounced version of the search function
  const debouncedSearch = useCallback(debounce(searchEmail, 300), []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: debouncedSearch,
  });

  const handleOpenDialog = useCallback((email) => {
    setDeleteEmail(email);
    setDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => setDialogOpen(false), []);

  // API call function for deleting the email
  const handleDelete = async () => {
    const data = { email: deleteEmail };
    try {
      const response = await axios.post(
        `https://betaapi.yuvamanthan.org/api/v2/deleteUser${deleteData}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSearchData(null);
        Popup("success", message);
        handleSteps();
        handleCloseDialog();
      }
    } catch (error) {
      Popup("error", "An error occurred during deletion. Please try again.");
    }
  };

  return (
    <div className="container">
      <Box
        sx={{
          border: "2px solid #2356FE",
          padding: "24px",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "40vh",
          width: "90%",
          maxWidth: "900px",
          margin: "0 auto",
        }}
      >
        <Typography
          component={"h1"}
          sx={{
            fontWeight: "600",
            fontSize: "1.5rem",
            marginBottom: "20px",
          }}
        >
          {title}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <TextField
              label="Email"
              variant="outlined"
              name="email"
              size="small"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              sx={{
                marginRight: "8px",
                "& .MuiInputBase-root": { height: "40px" },
                "& .MuiOutlinedInput-input": { padding: "8px 14px" },
              }}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <Button
              variant="contained"
              type="submit"
              sx={{
                height: "40px",
                bgcolor: "#2356FE",
                "&:hover": { bgcolor: "#1f4ce5" },
                padding: "0px 20px",
              }}
              disabled={loading}
            >Search
              {/* {loading ? <CircularProgress size={24} color="inherit" /> : "Search"} */}
            </Button>
          </Box>
        </form>

       {console.log(goSteps, "goSteps value")}
        {emailNotFound && goSteps < 2 && (
          <Box sx={{ textAlign: "center", marginTop: "16px" }}>
            <Typography component={'p'}>
              {/* This email doesn't exist in Institutions. Click "Next" to proceed to the next page. */}
              Email doesn't exist click to {<a href="#" onClick={handleSteps}>Next</a>} step
            </Typography>
            {/* <Button
              variant="contained"
              onClick={handleSteps}
              sx={{
                marginTop: "8px",
                bgcolor: "#2356FE",
                "&:hover": { bgcolor: "#1f4ce5" },
              }}
            >
              Next
            </Button> */}
          </Box>
        )}
        {emailNotFound && goSteps == 2 && (
          <Box sx={{ textAlign: "center", marginTop: "16px" }}>
            <Typography component={'p'}>
              {/* This email doesn't exist in Institutions. Click "Next" to proceed to the next page. */}
              This email doesn't exist into our database 
            </Typography>
            {/* <Button
              variant="contained"
              onClick={handleSteps}
              sx={{
                marginTop: "8px",
                bgcolor: "#2356FE",
                "&:hover": { bgcolor: "#1f4ce5" },
              }}
            >
              Next
            </Button> */}
          </Box>
        )}

        {/* Search Result */}
        {searchData && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 50px",
              borderRadius: "4px",
              "@media (min-width:500px)": {
                gap: "62px",
                justifyContent: "center",
              },
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: "18px",
                lineHeight: "40px",
              }}
            >
              {searchData.email}
            </Typography>
            <IconButton
              color="error"
              onClick={() => handleOpenDialog(searchData.email)}
              sx={{
                padding: "4px",
                fontSize: "33px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <DeleteForeverIcon sx={{ fontSize: "inherit" }} />
            </IconButton>
          </Box>
        )}

        {/* Dialog for Deleting */}
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          PaperProps={{
            sx: {
              padding: "9px 5px",
              position: "relative",
              width: { xs: "70%", sm: "380px" },
              border: "2px solid #2356FE",
              borderRadius: "12px",
              boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Box>
            <DialogTitle
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "4px",
                paddingTop: "5px",
                position: "relative",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    flex: 1,
                    fontSize: "21px",
                  }}
                >
                  Delete Email
                </Typography>
                <ClearIcon sx={{ fontSize: 25 }} onClick={handleCloseDialog} />
              </Box>
            </DialogTitle>
          </Box>
          <Divider />
          <DialogContent sx={{ padding: "0.4rem 0.9rem" }}>
            <DialogContentText
              textAlign="center"
              sx={{ fontSize: "1rem", color: "#555", marginBottom: "0.3rem" }}
            >
              Do you really want to delete this email?
            </DialogContentText>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DeleteForeverIcon
                sx={{ fontSize: "3.8rem", color: "#f44336" }}
              />
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: "center", padding: "2px", gap: "180px" }}
          >
            <Button
              onClick={handleCloseDialog}
              sx={{
                bgcolor: "#9e9e9e",
                color: "#fff",
                "&:hover": { bgcolor: "#7e7e7e" },
                padding: "6px 16px",
              }}
            >
              No
            </Button>
            <Button
              onClick={handleDelete}
              sx={{
                bgcolor: "#2356FE",
                color: "#fff",
                "&:hover": { bgcolor: "#1f4ce5" },
                padding: "6px 16px",
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

export default React.memo(EmailInput);
