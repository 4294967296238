import React, { useState} from "react";
import {
    Box,  Typography,  Snackbar,
    Link,
    Grid,Modal
} from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PlayCircleIcon from '@mui/icons-material/PlayCircleFilled';
import MuiAlert from '@mui/material/Alert';



const InstituteWorkProof = () => {

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [activeIndex, setActiveIndex] = useState(0);

    const labels = ["All", "Images", "Videos", "PDF"];
    const documents = [
      { type: "image", label: "Image 1", url: "https://images.unsplash.com/photo-1568741049635-59b0c30bf2de?w=500&auto=format&fit=crop&q=60" },
      { type: "pdf", label: "PDF 1", url: "https://example.com/document1.pdf" },
      { type: "video", label: "Video 1", url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" },
      { type: "image", label: "Image 2", url: "https://images.unsplash.com/photo-1568741049635-59b0c30bf2de?w=500&auto=format&fit=crop&q=60" },
      { type: "pdf", label: "PDF 2", url: "https://morth.nic.in/sites/default/files/dd12-13_0.pdf" },
    ];
    

      const filteredDocuments = documents.filter((doc) => {
        if (labels[activeIndex] === "All") return true;
        if (labels[activeIndex] === "Images") return doc.type === 'image';
        if (labels[activeIndex] === "Videos") return doc.type === 'video';
        if (labels[activeIndex] === "PDF") return doc.type === 'pdf';
        return false; 
      });

      const [open, setOpen] = useState(false);
      const [selectedDocument, setSelectedDocument] = useState(null);
    
      const handleOpen = (doc) => {
        setSelectedDocument(doc);
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
        setSelectedDocument(null);
      };
    
    const handleCloseSnackbar = () => {
            setOpenSnackbar(false);
        };

    const handleClick = (index) => {
      setActiveIndex(index);
    };

    return (

        
        <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent:"center",
            marginTop:"2rem",           
         }}>
         
         
         
         <Typography
                     sx={{
                         fontFamily: 'Poppins',
                         fontSize: { xs: "12px", sm: "14px"  },
                         fontWeight: 400,
                         lineHeight: {md:'24px' , xs:"20px"},
                         textAlign: 'left',
                         color: "#575757",
                         marginBottom:"3rem",
                     }}
                 >
                     Mentors will review and accept incoming requests to guide and support mentees, ensuring a personalized and engaging mentorship experience.
                 </Typography>
         
         
         
                 <Box
                 sx={{
                   position: "relative", 
                   background: "#f8f8ff",
                   borderRadius: "1.5rem",
                   width: "100%",
                   display: "flex",          
                   flexDirection: {xl :"row", sm:"column" , md:"row" },
                   justifyContent:"center",
                   alignItems: "center",
                   boxSizing: "border-box",
                   ":before": {
                     content: '""', 
                     background: "linear-gradient(122.52deg, rgba(35, 86, 254, 0.7) 1.25%, rgba(35, 86, 254, 0.1) 98.44%)", 
                     borderRadius: "1.5rem",
                     position: "absolute",
                     top: 0,
                     bottom: 0,
                     right: 0,
                     left: 0,
                     zIndex: "1", 
                     margin: "-1rem",
                   }
                 }}
                 >
        <Box
            sx={{
                borderRadius: "1rem",
                position: "relative", 
                zIndex:"5",
                backgroundColor: "var(--color-ghostwhite)",
                boxSizing: "border-box",
                width: "100%",
                overflowX: "hidden",
                backgroundColor: "#ffffff",

            }}
        >
         
         <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 5,
  }}
>
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      padding: { xs: "1rem", sm: "1rem 1.5rem" }, 
      borderTopLeftRadius: "1rem",
      borderTopRightRadius: "1rem",
      position: "relative",
      backgroundColor: "#ffffff",
      zIndex: 5,
      flexDirection: { xs: "column", sm: "row" }, 
    }}
  >
    <Box sx={{ width: { xs: "100%", sm: "90%" } }}> 
      <Typography
        variant="h4"
        component="div"
        gutterBottom
        sx={{
          fontFamily: "Poppins",
          fontWeight: 700,
          textAlign: "left",
          position: "relative",
          zIndex: 5,
        }}
      >
        <span style={{ color: "blue", zIndex: 5 }}>Work</span> Proof
      </Typography>
      <Typography
        gutterBottom
        component="div"
        sx={{
          fontFamily: "Poppins",
          width:{md:"80%" , xs:"100%"},
          fontWeight: 400,
          lineHeight: "18.97px",
          fontSize: { xs: "10px", sm: "12px"  },
          textAlign: "left",
          color: "#6D6D6D",
          zIndex: 5,
        }}
      >
       Explore all the necessary work proof documents uploaded by you so far.
      </Typography>
    </Box>

  </Box>
  
</Box>
<Box sx={{padding:"0 1rem" , marginBottom:"1rem"}}>

<Box
      sx={{
        position: "relative",
        width: "100%",
        height: "3rem",
        border: "2px solid #2356FE",
        marginTop: "2rem",
        marginBottom: "2rem",
        borderRadius: "4rem",
        zIndex: 5,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: `${activeIndex * 25}%`, 
          width: "25%",
          height: "100%",
          backgroundColor: "#2356FE",
          borderRadius: "2rem",
          transition: 'left 0.4s ease-in-out',
          zIndex: 0, 
        }}
      />

      
      {labels.map((label, index) => (
        <Box
          key={index}
          onClick={() => handleClick(index)} 
          sx={{
            flex: 1,
            textAlign: "center",
            zIndex: 1, 
            cursor: "pointer",
            position: "relative",
            fontSize: "18px",
            fontWeight: "600",
            fontFamily:"Poppins"
          }}
        >
          <Typography sx={{color: activeIndex === index ? "#fff" : "#000000", }}>{label}</Typography>
        </Box>
      ))}
    </Box>

    <Box>

      <Box sx={{ width: "100%", height: "auto", padding: "1rem", overflowY: "scroll" }}>
        <Grid container spacing={2}>
          {filteredDocuments.map((doc, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
       

<Box
  onClick={() => handleOpen(doc)}
  sx={{
    cursor: "pointer",
    boxShadow: 3,
    position: "relative",
    overflow: "hidden", 
    "&:hover .hoverBox": { transform: "translateY(0%)" },
    borderRadius: "8px",
  }}
>
  {doc.type === "image" && (
    <Box
      component="img"
      src={doc.url}
      alt={doc.label}
      sx={{ width: "100%", height: "10rem", objectFit: "cover" }}
    />
  )}

  {doc.type === "pdf" && (
    <Box
      sx={{
        width: "100%",
        height: "10rem",
        backgroundColor: "#e0e0e0",
        backgroundImage: `url('https://images.unsplash.com/photo-1532153975070-2e9ab71f1b14?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHBhcGVyJTIwc2hlZXR8ZW58MHx8MHx8fDA%3D')`, 
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <PictureAsPdfIcon sx={{ fontSize: "4rem", color: "#d32f2f" }} />
    </Box>
  )}

  {doc.type === "video" && (
 <Box
 sx={{
   width: "100%",
   maxWidth: "640px", 
   height: "10rem",
   borderRadius: "0.5rem", 
   overflow: "hidden", 
   backgroundSize: "cover", 
   backgroundColor: "#e0e0e0",
   backgroundPosition: "center",
   position: "relative", 
   boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", 
   cursor: "pointer", 
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
 }}
>
 <PlayCircleIcon
   sx={{
     fontSize: "4rem",
     color: "#2356FE", 
     position: "absolute", 
     zIndex: 1,
     transition: "transform 0.3s ease", 
     "&:hover": {
       transform: "scale(1.2)", 
     },
   }}
 />
</Box>

  )}

  <Box
    className="hoverBox"
    sx={{
      position: "absolute",
      bottom: 0,
      left: 0,
      height:"3rem",
      width: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      color: "#fff",
      padding: "0.5rem",
      textAlign: "center",
      transform: "translateY(100%)", 
      transition: "transform 0.3s ease-in-out", 
    }}
  >
    <Typography variant="body2" sx={{ fontWeight: 500 , color:"#fff" }}>
      {doc.label}
    </Typography>
  </Box>
</Box>

            </Grid>
          ))}
        </Grid>
      </Box>
      <Modal
  open={open}
  onClose={handleClose}
  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
>
  <Box sx={{ maxWidth: '80%', maxHeight: '80%', overflow: 'auto' }}>
    {selectedDocument?.type === 'image' && (
      <Box
        component="img"
        src={selectedDocument.url}
        alt={selectedDocument.label}
        sx={{ width: '100%', height: 'auto' }}
      />
    )}
    {selectedDocument?.type === 'pdf' && (
      <iframe
        src={selectedDocument.url}
        title={selectedDocument.label}
        style={{
          width: '100%',
          height: '80vh',
          border: 'none', 
        }}
      />
    )}
    {selectedDocument?.type === 'video' && (
      <video
        controls
        autoPlay
        style={{ width: '100%', height: '40rem', maxHeight: '60vh' }}
      >
        <source src={selectedDocument.url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    )}
  </Box>
</Modal>





    </Box>
    </Box>


     
                   
                        <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                            <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                            {snackbarMessage}
                            </MuiAlert>
                        </Snackbar>
           
        </Box>
        </Box>
        </Box>

    );
};

export default InstituteWorkProof;




// const formik = useFormik({
//     initialValues: {
//         projectId: "",
//         marks: [],
//     },
//     validationSchema,
//     onSubmit: async (values) => {
//         const updatedStudentDetails = students.map((student, index) => ({
//             memberId: student.memberId,
//             marks: values.marks[index] || 0,  
//         }));
        
       
//         console.log("Updated Students:", updatedStudentDetails);

//         try {
//             await axios.put(`http://localhost:3000/api/approval/project/giveMarks/${values.projectId}`, { marks: updatedStudentDetails });

//             setOpenSnackbar(true);
//             setSnackbarMessage("Marks updated successfully!");
//             setSnackbarSeverity("success");
//         } catch (error) {
//             setOpenSnackbar(true);
//             setSnackbarMessage("Failed to update marks. Please try again.");
//             setSnackbarSeverity("error");
//         }

//         formik.resetForm();
//         setStudents([]);
//         setSelectedProject(null);
//     },
// });

// const handleCloseSnackbar = () => {
//     setOpenSnackbar(false);
// };

// const handleProjectSearch = async (projectId) => {
//     try {
//         const response = await axios.get(`http://localhost:3000/api/approval/project/members/${projectId}`);
//         const members = response.data.members;
//         const project = response.data.project;

//         if (members) {
//             setSelectedProject(project);
//             const projectStudents = members.map(member => ({
//                 memberId: member._id,
//                 rollNo: member.rollNo,
//                 marks: member.marks || 0,
//             }));
//             setStudents(projectStudents);
//             formik.setFieldValue("projectId", projectId);
//             formik.setFieldValue("marks", projectStudents.map(student => student.marks));
//         } else {
//             setSelectedProject(null);
//             setStudents([]);
//             formik.setFieldValue("projectId", projectId);
//             formik.setFieldValue("marks", []);
//         }
//     } catch (error) {
//         console.error("Error fetching project details:", error);
//         setSelectedProject(null);
//         setStudents([]);
//         formik.setFieldValue("projectId", projectId);
//         formik.setFieldValue("marks", []);
//     }
// };

// useEffect(() => {
//     if (formik.values.projectId) {
//         handleProjectSearch(formik.values.projectId);
//     }
// }, [formik.values.projectId]);