import React, { useEffect, useState } from 'react'
import { Card, Modal } from 'react-bootstrap'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { apiJson } from 'api';
import { toast } from 'react-toastify';

const AdminCarbonFootprintCalculator = () => {

    const [showCatModal, setShowCatModal]= useState(false)
    const [showQuestionModal, setshowQuestionModal]= useState(false)
  const [category, setCategory] = useState([])
const [questions, setquestions] = useState([])
    const typeOfQuestion = [
        {
            value:'checkbox',
        },
        {
            value:'input',
        },
        {
            value:'number',
        },
        {
            value:'radio',
        },
        {
            value:'select',
        },
    ]
    const options = [
        {
            value:'option 1'
        },
        {
            value:'option 2'
        },
        {
            value:'option 3'
        },
    ]

    const validationCat = Yup.object({
        title:Yup.string().required('Required'),
        description:Yup.string().required('Required'),
    })
    const initialValuesCat = {
        title:'',
        description:'',
    }
    const initialValuesQuestion = {
        text:'',
        type:'',
        option:[],
        cat_id:'',
        points:'',
        correct:'',
    }
    const handleAddOption = (e)=>{
        e.preventDefault()
        setQuestion('option', [...questionFormik.values.option, {value:''}])
    }
    const handleOptionChange = (i,e)=>{
        const newOption= [...questionFormik.values.option]
        newOption[i] = {value:e.target.value}
        setQuestion('option',newOption)
    }
    const handleRemove= (i,e)=>{
        e.preventDefault()
        const newOption = [...questionFormik.values.option]
        newOption.splice(i,1);
        setQuestion('option', newOption)
    }
    // get api for category
    const getallCategory = ()=>{
        apiJson('api/v2/cfc/cfcCategory')
        .then((res)=>{
            setCategory(res.data.categories)
        }).catch((error)=>{
            toast.dismiss()
            toast.error('Internal Server Error')
        })
    }
    const getAllQuestion= ()=>{
        apiJson('api/v2/cfc/cfcGetQuestion')
        .then((res)=>{
            setquestions(res.data.questions)
        }).catch((error)=>{
            toast.dismiss()
            toast.error('Internal Server Error')
        })
    }
    useEffect(()=>{
        getallCategory();
        getAllQuestion();
    },[])
  
       // post api for category

       const postCategory = ()=>{
        // console.log('hy')
        apiJson.post('api/v2/cfc/cfcAddCategory', catFormik.values)
        .then((res)=>{
        getallCategory()
        hideCatModal()
        catFormik.resetForm()
        toast.dismiss()
            toast.success('Created')
        }).catch((error)=>{
            toast.dismiss()
            toast.error('Internal Server Error')
        })
    }
    const postQuestion = ()=>{
        console.log('hy')
        apiJson.post('api/v2/cfc/cfcAddQuestion',{ ...questionFormik.values, options:questionFormik.values.option})
        .then((res)=>{
            getAllQuestion()
            hideQuestionModal()
            questionFormik.resetForm()
            toast.dismiss()
                toast.success('Created')
        }).catch((error)=>{
            toast.dismiss()
            toast.error('Internal Server Error')
        })
    }
    const catFormik = useFormik({
        initialValues:initialValuesCat,
        validationSchema: validationCat,
        onSubmit:postCategory
    })
    const questionFormik = useFormik({
        initialValues:initialValuesQuestion,
        // validationSchema: validationCat,
        onSubmit:postQuestion
    })
    
    const hideCatModal =()=>{
        setShowCatModal(false)
    }
    const hideQuestionModal=()=>{
        setshowQuestionModal(false)
    }
    const setQuestion = (name, value)=>{
        questionFormik.setFieldValue(name, value)
    }
 
    const categorySetOrder = (id,direction)=>{
        apiJson.put(`api/v2/cfc/cfcUpdateCategoryOrder/${id}/direction/${direction}`)
        .then((res)=>{
            getallCategory()
        }).catch((error)=>{
            toast.dismiss()
            toast.error('Internal server error')
        })
    }
    
  return (
    <>
        <div className='container'>
            <Card>
                <Card.Body>
                {/* header */}
                    <div className='d-flex justify-content-between mb-5'>
                        <button onClick={()=>setShowCatModal(true)} className='btn btn-outline-primary'> Add Category</button>
                        <button onClick={()=>{
                            setshowQuestionModal(true)
                        }} className='btn btn-outline-primary'> Add Question</button>
                    </div>
                    {/* body */}
                    <div className='mt-5'>
                       { category?.map((cat)=>{
                        return <Card className='mb-4'>
                            <Card.Body>
                               <div className='row justify-content-between'>
                                <div className='col-12 col-md-6 mb-2'>
                                    <span className='fs-3 fw-semibold'>{cat.title}</span></div>
                                <div className='col-12 col-md-6 text-md-end mb-2'>
                                    <button className='btn btn-outline-success me-3 rounded-4 btn-sm cursor-pointer'>Edit</button>
                                    <button onClick={()=>categorySetOrder(cat.id, 'up')} className='btn btn-outline-danger me-3 rounded-4 btn-sm cursor-pointer'>UP</button>
                                    <button onClick={()=>categorySetOrder(cat.id, 'down')} className='btn btn-outline-warning me-3 rounded-4 btn-sm cursor-pointer'>Down</button>
                              </div>
                               </div>
                               { questions?.map((question)=>{
                                return question.cat_id===cat.id && <Card className='mb-3'>
                                    <Card.Body>
                                    <div>
                                        <span className='fs-5 fw-semibold ' style={{color:'purple'}}>{question.text}</span>
                                    </div>
                                    <div>
                                        {// if question.type===radio
                                          ['radio', 'checkbox'].includes(question?.type) && 
                                            question?.options?.map((o, index) => (
                                                <div key={index}>
                                                    <input type="radio" name={`name-${question.id}`}  />
                                                    <span>{o.value}</span>
                                                </div>
                                            ))
                                        }{
                                            // if question.type === text
                                            ['input', 'text'].includes(question?.type) &&
                                            <div>
                                                <input type='text' className='form-control resize-none'/>
                                            </div>
                                        }
                                        {
                                            // if question.type === select
                                            question?.type==='select' &&
                                            <div>
                                            <select  class="form-select" aria-label="Default select example">
                                        <option selected>Select an Option</option>
                                        
                                        {
                                            question.options?.map((op)=>{
                                                return <option  value={op.value}>{op.value}</option>
                                            })
                                        }
                                    </select>
                                            </div>
                                        }
                                    </div>
                                    </Card.Body>
                                </Card>
                               })}
                            </Card.Body>
                        </Card>
                       })}
                    </div>


                    {/* modal for  question */}
                    <Modal show={showQuestionModal} onHide={hideQuestionModal}>
                        <Modal.Body>
                            <form onSubmit={questionFormik.handleSubmit}>
                            <div className='row'>
                               
                                <div className='col-12 mb-2'>
                                    <span className="fs-19px">Write Question</span>
                                    <input onChange={(e)=>{setQuestion('text', e.target.value)}} className='resize-none form-control rounded-3'/>
                                </div>
                                <div className='col-12 mb-3'>
                                    <span className="fs-19px">Category</span>
                                    <select onChange={(e)=>{setQuestion('cat_id', e.target.value)}} class="form-select form-control rounded-3" aria-label="Default select example">
                                        <option selected>Select Category</option>
                                        {
                                            category?.map((cat)=>{
                                                return <option  value={cat.id}>{cat.title}</option>
                                            })
                                        }
                                        
                                    </select>
                                </div>
                                <div className='col-12 mb-3'>
                                    <span className="fs-19px">Type</span>
                                    <select onChange={(e)=>{setQuestion('type', e.target.value)}} class="form-select form-control rounded-3" aria-label="Default select example">
                                        <option selected>Select Type of Question</option>
                                        
                                        {
                                            typeOfQuestion?.map((type)=>{
                                                return <option  value={type.value}>{type.value}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            {['checkbox', 'radio', 'select'].includes(questionFormik.values.type) &&  <div className='col-12 mb-2'>
                                    <span className="fs-19px d-block">Option</span>
                                    {
                                        questionFormik.values.option?.map((o,i)=>{
                                            return <>
                                                <div className='row'>
                                                    <div className='col-8'>
                                                    <input className='form-control rounded-3 mb-2' type='text' value={o.value} onChange={(e)=>{
                                                        handleOptionChange(i,e)
                                                    }}/>
                                                    </div>
                                                   <div className='col-4'>
                                                   <button className='btn btn-outline-danger mb-2 rounded-3' onClick={(e)=>{
                                                        handleRemove(i,e)
                                                    }}>Remove</button>
                                                   </div>

                                                </div>
                                            </>
                                        })
                                    }
                                    <button className='btn btn-outline-success mt-2 rounded-3' onClick={handleAddOption}>Add</button>
                                </div>}
                                <div className='col-12 mb-2'>
                                    <span className="fs-19px">Points</span>
                                    <input onChange={(e)=>{setQuestion('points', e.target.value)}} className='resize-none form-control rounded-3'/>
                                </div>
                                <div className='col-12 mb-2'>
                                    <span className="fs-19px">Correct Answer</span>
                                    <input onChange={(e)=>{setQuestion('correct', e.target.value)}} className='resize-none form-control rounded-3'/>
                                </div>
                                <div className='col-12 mb-2'>
                                    <button type='submit' className='btn btn-outline-success'>Create Question</button>
                                </div>
                            </div>

                            </form>
                        </Modal.Body>
                    </Modal>



                    {/* modal for category */}
                    <Modal show={showCatModal} onHide={hideCatModal}>
                        <Modal.Body>
                            <form onSubmit={catFormik.handleSubmit}>
                            <div className='row'>
                                <div className='col-12'>
                                    <h3>Title</h3>
                                    <input value={catFormik.values.title} onChange={(e)=>{catFormik.setFieldValue('title', e.target.value)}} className='form-control rounded-3'/>
                                </div>
                                <div className='col-12'>
                                    <h3>Description</h3>
                                    <textarea value={catFormik.values.description} onChange={(e)=>{catFormik.setFieldValue('description', e.target.value)}} className='resize-none form-control rounded-3'/>
                                </div>
                                <div className='col-12'>
                                    <button type='submit' className='btn btn-outline-success'>Create Category</button>
                                </div>
                            </div>

                            </form>
                        </Modal.Body>
                    </Modal>
                </Card.Body>
            </Card>
        </div>
    </>
  )
}

export default AdminCarbonFootprintCalculator