import React, { useContext, useEffect, useState } from "react";
import OnBoardContext from "pages/OnBoarding/context/onBoardContext";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import Button from "@mui/joy/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { apiAuth, apiJson, apiJsonAuth } from "api";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import { useGlobalContext } from "global/context";
import { useNavigate } from "react-router-dom";
import AccountBalanceTwoToneIcon from "@mui/icons-material/AccountBalanceTwoTone";
import DuplicateAccount from "../DuplicateInstitute/DuplicateAccount";
import "./allStepCss.css";
import RemoveCircleTwoToneIcon from "@mui/icons-material/RemoveCircleTwoTone";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import HelpIcon from "@mui/icons-material/Help";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Tooltip from "@mui/material/Tooltip";
import { CircularProgress, Grid } from "@mui/material";
import Swal from "sweetalert2";
import DocumentModal from "pages/OnBoarding/Component/DocumentModal";
import ShowProofId from "pages/OnBoarding/Component/ShowProofId";
import ShowProof_Address from "pages/OnBoarding/Component/ShowProof_Address";

export const AllStepInstitute = () => {
  const navigate = useNavigate();
  const { userData, setUser, token } = useGlobalContext();
  const [underReviewStatus, setUnderReviewStatus] = useState(false);

  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    setCount,
    updateInstituteOnboardData,
  } = useContext(OnBoardContext);

  // activeStep === 5 && activeChildStep === 0

  useEffect(() => {
    if (
      token &&
      userData.role == "institute" &&
      userData.onBoardStatus === true &&
      userData.reviewStatus === false
    ) {
      setActiveStep(5);
      setActiveChildStep(0);
      setCount(4);
    }
  }, [userData]);
  useEffect(() => {
    if (
      token &&
      userData.role == "institute" &&
      userData.onBoardStatus === true &&
      userData.reviewStatus === false
    ) {
      setActiveStep(5);
      setActiveChildStep(0);
      setCount(4);
    }
  }, []);

  const checkUnderReviewStatus = () => {
    apiJson
      .get("v2/register/checkUnderReviewStatus/email/" + userData.email)
      .then((res) => {
        setUnderReviewStatus(res.data.result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const checkUnderReviewStatusUser = () => {
    apiJson
      .get(
        "v2/register/checkUnderReviewStatus/email/" +
          userData?.email +
          "/role/" +
          userData?.role
      )
      .then((res) => {
        setUnderReviewStatus(res.data.result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    // correct here later add under Review status in UserData
    if (
      token &&
      userData.onBoardStatus === true &&
      userData.reviewStatus === false
    ) {
      if (userData.role == "institute") {
        let checkInterval = setInterval(() => {
          if (underReviewStatus) {
            clearInterval(checkInterval);
          } else {
            checkUnderReviewStatus();
          }
        }, 5000);
        return () => {
          clearInterval(checkInterval);
        };
      }
      if (userData?.role === "teacher" || userData?.role === "student") {
        let checkInterval = setInterval(() => {
          if (underReviewStatus) {
            clearInterval(checkInterval);
          } else {
            checkUnderReviewStatusUser();
          }
        }, 5000);
        return () => {
          clearInterval(checkInterval);
        };
      }
    }
  }, [userData]);

  useEffect(() => {
    if (underReviewStatus) {
      // createInstituteAfterReview()
      setUser({ ...userData, reviewStatus: true });
      updateInstituteOnboardData();
      navigate("/new-dashboard");
    }
  }, [underReviewStatus]);

  const renderStep = () => {
    switch (true) {
      case activeStep === 0 && activeChildStep === 0:
        return (
          <div>
            <Institution />
          </div>
        );
      case activeStep === 0 && activeChildStep === 1:
        return (
          <div>
            <AboutInstitute />
          </div>
        );
      case activeStep === 0 && activeChildStep === 2:
        return (
          <div>
            <RegisteredAddress />
          </div>
        );
      case activeStep === 0 && activeChildStep === 3:
        return (
          <div>
            <Appearance />
          </div>
        );
      case activeStep === 0 && activeChildStep === 4:
        return (
          <div>
            <SocialPresence />
          </div>
        );
      case activeStep === 0 && activeChildStep === 5:
        return (
          <div>
            <AboutYou />
          </div>
        );

      case activeStep === 0 && activeChildStep === 6:
        return (
          <div>
            <Documents />
          </div>
        );

      case activeStep === 1 && activeChildStep === 0:
        return (
          <div>
            <Mailing />
          </div>
        );

      case activeStep === 2 && activeChildStep === 0:
        return (
          <div>
            <Preferences />
          </div>
        );
      case activeStep === 3 && activeChildStep === 0:
        return (
          <div>
            <AddCooordinators />
          </div>
        );

      case activeStep === 3 && activeChildStep === 1:
        return (
          <div>
            <CreatePassword />
          </div>
        );
      case activeStep === 4 && activeChildStep === 0:
        return (
          <div>
            <Done />
          </div>
        );
      case activeStep === 5 && activeChildStep === 0:
        return (
          <div>
            <UnderReview />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div className="d-flex flex-column justify-content-between">
        <div>{renderStep()}</div>
      </div>
    </>
  );
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    padding: "3px",
    border: "none",
    background: "#eeeeee4d",
    // boxShadow:'none'
    padding: 8,
  }),
};
const customStylesPurple = {
  control: (provided) => ({
    ...provided,
    width: "170px",
    padding: "",
    border: "none",
    background: "#F1EBFF",
    textAlign: "center",
    height: "37px",
    // boxShadow:'none'
  }),
};
const customStylesNumber = {
  control: (provided) => ({
    ...provided,
    padding: "5px 0  ",
    border: "none",
    background: "#F1EBFF",
    color: "#4A00E8",
    width: "100&",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    borderRadius: "10px",
  }),
};

// for type of institution step Institution fucntion work

const Institution = () => {
  const { userData } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    handleNextChild,
    handleBack,
    selectedDesignation,
    setSelectedDesignation,
    onBoardingData,
    setOnboardData,
    instituteFormik,
    dropdownType,
    setdropdownType,
    setInstituteData,
  } = useContext(OnBoardContext);
  const [instituteDetail, setInstituteDetail] = useState();

  const getOnBoardData = async () => {
    try {
      setLoading(true);
      const res = await apiJsonAuth.get(
        `/v2/register/getOnboardingData/${userData?.email}`
      );
      if (res) {
        setLoading(false);
        const { id, password, ...onboardingData } = res?.data?.onBoardingData;
        instituteFormik.setValues({
          institution_name: onboardingData?.institution_name || "",
          email: onboardingData?.email || "",
          password: onboardingData?.password || "",
          type_of_inst: onboardingData?.type_of_inst || "",
          type_of_college: onboardingData?.type_of_college || "",
          education_board: onboardingData?.education_board || "",

          medium_of_education: onboardingData?.medium_of_education || "English",
          otherCollege: onboardingData?.otherCollege || "",
          bio: onboardingData?.bio || onBoardingData?.bio,
          country: onboardingData?.country || "India",
          state: onboardingData?.state || "",
          district: onboardingData?.district || "",
          street: onboardingData?.street || "",
          city: onboardingData?.city || "",
          logo: onboardingData?.logo || "",
          pinCode: onboardingData?.pinCode || "",
          website: onboardingData?.website || "",
          facebook_acc: onboardingData?.facebook_acc || "",
          twitter_acc: onboardingData?.twitter_acc || "",
          linkedin_acc: onboardingData?.linkedin_acc || "",
          insta_acc: onboardingData?.insta_acc || "",
          youtube_acc: onboardingData?.youtube_acc || "",
          proof_of_id: onboardingData?.proof_of_id || "",
          proof_of_address: onboardingData?.proof_of_address || "",
          proof_of_id_file: onboardingData?.proof_of_id_file || "",
          proof_of_address_file: onboardingData?.proof_of_address_file || "",
          account_manager: onboardingData?.account_manager || [],
          first_name: onboardingData?.first_name || "",
          last_name: onboardingData?.last_name || "",
          admin_country: onboardingData?.admin_country || "",
          admin_state: onboardingData?.admin_state || "",
          admin_district: onboardingData?.admin_district || "",
          admin_street: onboardingData?.admin_street || "",
          admin_city: onboardingData?.admin_city || "",
          admin_pincode: onboardingData?.admin_pincode || "",
          designation: onboardingData?.designation || "",
          phone: onboardingData?.phone || "",
          student_verification: false,
        });
        formik?.setValues({
          typeOfInstitute: onBoardingData?.type_of_inst || "",
          selectedTypeOfInstitute: onBoardingData?.type_of_college || "",
          udise: onBoardingData?.udise_code || "",
          board: onBoardingData?.education_board || "",
          medium: onBoardingData?.medium_of_education || "English",
          otherCollege: onBoardingData?.other_college || "",
        });
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  useEffect(() => {
    if (onBoardingData?.type_of_inst === "") {
      getOnBoardData();
    }
  }, []);

  const validation = Yup.object({
    typeOfInstitute: Yup.string().required("Please Select Institute"),
    selectedTypeOfInstitute: Yup.string()
      .when("typeOfInstitute", {
        is: "College",
        then: Yup.string().required("Please Select One College"),
      })
      .when("typeOfInstitute", {
        is: "School",
        then: Yup.string().required("Please Select One School"),
      })
      .when("typeOfInstitute", {
        is: "University",
        then: Yup.string().required("Please Select One University"),
      }),

    board: Yup.string().when("typeOfInstitute", {
      is: "School",
      then: Yup.string().required("Please select Board"),
    }),
    medium: Yup.string().when("typeOfInstitute", {
      is: "Coaching Institute",
      then: Yup.string(),
      otherwise: Yup.string().required("Please Select Medium"),
    }),
    otherCollege: Yup.string().when("selectedTypeOfInstitute", {
      is: "Other (Please specify)",
      then: Yup.string().required(`Please select `),
    }),
    affiliationId: Yup.string().when("board", {
      is: "CBSE",
      then: Yup.string()
        .matches(
          /^[1-9]\d{5,6}$/,
          "Affiliation ID must be a 6-7 digit number starting from 1 to 9"
        )
        .required("Affiliation ID is required"),
      otherwise: Yup.string(),
    }),
    country: Yup.string().when("board", {
      is: "CBSE",
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    state: Yup.string().when("board", {
      is: "CBSE",
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
  });
  const initialValues = {
    typeOfInstitute: instituteFormik.values.type_of_inst || "",
    selectedTypeOfInstitute: instituteFormik.values.type_of_college || "",
    udise: instituteFormik.values.udise_code || "",
    affiliationId: instituteFormik.values?.affiliation_id || "",
    board: instituteFormik.values.education_board || "",
    medium: instituteFormik.values.medium_of_education || "",
    otherCollege: instituteFormik.values.type_of_inst || "",
    country: instituteFormik.values.country || "India",
    state: instituteFormik.values.state || "",
  };
  const onSubmit = () => {
    copyValues();
    handleNextChild();
  };

  function copyValues() {
    const fieldMappings = {
      typeOfInstitute: "type_of_inst",
      selectedTypeOfInstitute: "type_of_college",
      udise: "udise_code",
      board: "education_board",
      medium: "medium_of_education",
    };
    const updatedValues = { ...instituteFormik.values };

    for (const formikKey in fieldMappings) {
      const instituteFormikKey = fieldMappings[formikKey];
      updatedValues[instituteFormikKey] = formik.values[formikKey];
    }

    instituteFormik.setValues(updatedValues);
  }

  const handleFormData = (name, value) => {
    setInstituteDetail({
      ...instituteDetail,
      [name]: value,
    });
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit,
  });

  useEffect(() => {
    formik.setValues({
      typeOfInstitute: instituteFormik.values.type_of_inst,
      selectedTypeOfInstitute: instituteFormik.values.type_of_college,
      udise: instituteFormik.values.udise_code,
      board: instituteFormik.values.education_board,
      medium: instituteFormik.values.medium_of_education,
      otherCollege: instituteFormik.values.type_of_inst,
    });
  }, [instituteFormik?.values]);
  useEffect(() => {
    setSelectedDesignation(formik?.values?.typeOfInstitute);
  }, [formik?.values?.typeOfInstitute]);

  useEffect(() => {
    if (formik.values.typeOfInstitute) {
      if (formik.values.typeOfInstitute === "School") {
        setdropdownType(typeOfSelectedInstitute);
      } else if (formik.values.typeOfInstitute === "College") {
        setdropdownType(collegeTypes);
      } else if (formik.values.typeOfInstitute === "University") {
        setdropdownType(universityTypes);
      } else {
        setdropdownType([]);
      }
    }
  }, [formik.values.typeOfInstitute]);
  const [country, setCountry] = useState([{ name: "India", value: "India" }]);
  const [stateData, setState] = useState(statesOfIndia);
  const [countryLoader, setCountryLoader] = useState(false);
  const [stateLoader, setstateLoader] = useState(false);

  const fetchCountryData = () => {
    setCountryLoader(true);
    apiJson
      .get(`v2/institute/fetchCountryData`)
      .then((res) => {
        setCountry(res.data.allCountryData);
        setCountryLoader(false);
        // console.log(res.data.allCountryData);
      })
      .catch((err) => {
        setCountryLoader(false);

        console.log(err);
      });
  };
  const fetchStateData = () => {
    setstateLoader(true);
    apiJson
      .get(`v2/institute/fetchStateData/${formik.values.country}`)
      .then((res) => {
        setState(res?.data?.result);
        setstateLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setstateLoader(false);
      });
  };

  useEffect(() => {
    // fetchCountryData();
    fetchStateData();
  }, [formik?.values.country]);
  useEffect(() => {
    formik.setFieldValue("country", "India");
    fetchCountryData();
  }, []);

  return (
    <>
      {loading && (
        <div className="google-verify-loader-overlay">
          <div className="google-verify-loader"></div>
        </div>
      )}
      <div>
        <div className="mb-2">
          <span className=" fs-2 fw-semibold">Institute Details</span>
        </div>

        <div className="mb-50">
          <span
            className=""
            style={{
              color: "grey",
              fontSize: "17px",
              fontWeight: "500",
            }}
          >
            Provide information about the nature of your institution and the
            medium of education.
          </span>
        </div>

        {/* step 1 on of onboarding type of institute form */}
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div className="mb-2">
              <span className="fs-19px fw-500">Type of Institute</span>
            </div>
            <div className="mb-4 col-12 col-md-6">
              <Select
                styles={customStyles}
                options={typeOfSchool}
                // value={selectedTypeOfSchool}
                value={typeOfSchool.find(
                  (i) => i.name === formik.values.typeOfInstitute
                )}
                defaultValue={typeOfSchool.find(
                  (i) => i.name === formik.values.typeOfInstitute
                )}
                onChange={(selected) => {
                  setSelectedDesignation(selected.name);
                  handleFormData("typeOfInstitute", selected.name);
                  formik.setFieldValue("typeOfInstitute", selected.name);
                }}
                getOptionLabel={(option) => {
                  return (
                    <>
                      <div>
                        <span>{option.name}</span>
                      </div>
                    </>
                  );
                }}
              />
              {formik.touched.typeOfInstitute &&
                formik.errors.typeOfInstitute && (
                  <span className="text-danger">
                    {formik.errors.typeOfInstitute}
                  </span>
                )}
            </div>

            {formik.values.typeOfInstitute && (
              <div className="p-2 rounded-4 background-purple border-purple mb-4">
                <span className="me-1 ">
                  <AssignmentIcon sx={{ color: "#28007d" }} />{" "}
                </span>
                <span className="color-purple">
                  {formik.values.typeOfInstitute === "School"
                    ? `Only for ${formik.values.typeOfInstitute} who are operational under statutory Indian boards`
                    : formik.values.typeOfInstitute === "College"
                    ? `Only for ${formik.values.typeOfInstitute} recognised by statutory higher educational body`
                    : formik.values.typeOfInstitute === "University"
                    ? `Only for ${formik.values.typeOfInstitute} recognised by by statutory higher educational body`
                    : `Only for ${formik.values.typeOfInstitute} duly registered with competent authorities`}
                </span>
              </div>
            )}

            {formik.values.typeOfInstitute &&
              formik.values.typeOfInstitute !== "Coaching Institute" && (
                <div className="row mb-2">
                  {formik.values.typeOfInstitute === "School" && (
                    <div class="mb-4 col-12 col-lg-6">
                      <span className="fs-19px fw-500 mb-2">Board</span>
                      <div className="thin-scroll">
                        <Select
                          options={selectBoard}
                          styles={customStyles}
                          defaultValue={selectBoard.find(
                            (i) => i.name === formik.values.board
                          )}
                          onChange={(selected) => {
                            handleFormData("board", selected.name);
                            formik.setFieldValue("board", selected.name);
                          }}
                          getOptionLabel={(option) => {
                            return (
                              <>
                                <div>
                                  <span>{option.name}</span>
                                </div>
                              </>
                            );
                          }}
                        />
                        {formik.touched.board && formik.errors.board && (
                          <span className="text-danger">
                            {formik.errors.board}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  {formik.values.typeOfInstitute === "School" && (
                    <div class="mb-4 col-12 col-lg-6">
                      <span className="fs-19px fw-500 d-block">
                        {formik.values.board === "CBSE"
                          ? "Affiliation ID (compulsory)"
                          : "UDISE Code (optional)"}
                      </span>
                      {formik.values.board === "CBSE" ? (
                        <>
                          <input
                            className="box-shadow-0 border-0 background-grey form-control rounded-3"
                            type="text"
                            value={formik.values.affiliationId}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "affiliationId",
                                e.target.value
                              );
                            }}
                            placeholder="Enter code here"
                          />
                          {formik.touched.affiliationId &&
                            formik.errors.affiliationId && (
                              <span className="text-danger">
                                {formik.errors.affiliationId}
                              </span>
                            )}
                        </>
                      ) : (
                        <>
                          <input
                            className="box-shadow-0 border-0 background-grey form-control rounded-3"
                            type="text"
                            value={formik.values.udise}
                            onChange={(e) => {
                              handleFormData("code", e.target.value);
                              formik.setFieldValue("udise", e.target.value);
                            }}
                            placeholder="Enter code here"
                          />
                          {formik.touched.udise && formik.errors.udise && (
                            <span className="text-danger">
                              {formik.errors.udise}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {formik.values.board === "CBSE" && (
                    <>
                      <div className="col-12 col-lg-6 mb-2 mb-md-4">
                        <span className="fw-500 fs-6 fs-md-5  mb-2 d-block">
                          Country
                        </span>
                        {/* <input value={formik.values.country} onChange={(e)=>formik.setFieldValue('country', e.target.value)} placeholder="Enter Country..." className="form-control rounded-3 border-0 box-shadow-0 background-grey" type='text' /> */}
                        {countryLoader ? (
                          <CircularProgress />
                        ) : (
                          <Select
                            isSearchable={true}
                            styles={customStyles}
                            options={country}
                            value={country.find(
                              (i) => i?.name === formik.values.country
                            )}
                            defaultValue={country.find(
                              (i) => i?.name === formik.values.country
                            )}
                            onChange={(selected) => {
                              console.log(selected);
                              formik.setFieldValue("country", selected?.name);
                            }}
                            getOptionLabel={(option) => {
                              return (
                                <>
                                  <span>{option?.name}</span>
                                </>
                              );
                            }}
                          />
                        )}
                        {formik.touched.country && formik.errors.country && (
                          <span className="text-danger">
                            {formik.errors.country}
                          </span>
                        )}
                      </div>
                      <div className="col-12 col-lg-6 mb-2 mb-md-4">
                        <span className="fw-500 fs-6 fs-md-5  mb-2 d-block">
                          State
                        </span>
                        {stateLoader ? (
                          <CircularProgress />
                        ) : (
                          <Select
                            isSearchable={true}
                            styles={customStyles}
                            options={stateData}
                            value={stateData?.find(
                              (i) => i?.name === formik.values.state
                            )}
                            onChange={(selected) => {
                              formik.setFieldValue("state", selected.name);
                            }}
                            getOptionLabel={(option) => {
                              return (
                                <>
                                  <span>{option?.name}</span>
                                </>
                              );
                            }}
                          />
                        )}

                        {formik.touched.state && formik.errors.state && (
                          <span className="text-danger">
                            {formik.errors.state}
                          </span>
                        )}
                      </div>{" "}
                    </>
                  )}
                  {formik.values.selectedTypeOfInstitute ===
                    "Other (Please specify)" && (
                    <div className="mb-4 col-12 col-lg-6">
                      <span className="fs-19px fw-500 mb-2 d-block">
                        Please Specify
                      </span>
                      <input
                        placeholder="Please Specify"
                        className="box-shadow-0 border-0 background-grey form-control"
                        type="text"
                        onChange={(e) => {
                          handleFormData("innerType", e.target.value);
                          formik.setFieldValue("otherCollege", e.target.value);
                        }}
                      />
                      {formik.touched.otherCollege &&
                        formik.errors.otherCollege && (
                          <span className="text-danger">
                            {formik.errors.otherCollege}
                          </span>
                        )}
                    </div>
                  )}

                  <div class=" mb-4 col-12 col-lg-6">
                    <span className="fs-19px fw-500 mb-2 d-block">
                      Type of {formik.values.typeOfInstitute}
                    </span>
                    <div className="">
                      <Select
                        styles={customStyles}
                        options={dropdownType}
                        value={dropdownType.find(
                          (i) =>
                            i.name === formik.values.selectedTypeOfInstitute
                        )}
                        defaultValue={dropdownType.find(
                          (i) =>
                            i.name === formik.values.selectedTypeOfInstitute
                        )}
                        onChange={(selected) => {
                          handleFormData("innerType", selected.name);
                          formik.setFieldValue(
                            "selectedTypeOfInstitute",
                            selected.name
                          );
                        }}
                        getOptionLabel={(option) => {
                          return (
                            <>
                              <div className="mb-2 d-flex z-index-1">
                                <span>{option.name}</span>
                              </div>
                            </>
                          );
                        }}
                      />
                      {formik.touched.selectedTypeOfInstitute &&
                        formik.errors.selectedTypeOfInstitute && (
                          <span className="text-danger">
                            {formik.errors.selectedTypeOfInstitute}
                          </span>
                        )}
                    </div>
                  </div>

                  {formik.values.typeOfInstitute && (
                    <div class="mb-4 col-12 col-lg-6">
                      <span className="onboard-form-span mb-2 d-block">
                        Medium of Education
                      </span>
                      <div className="">
                        <Select
                          styles={customStyles}
                          options={languages}
                          defaultValue={languages.find(
                            (i) => i.name === formik.values.medium
                          )}
                          onChange={(selected) => {
                            handleFormData("mediumOfEducation", selected.name);
                            formik.setFieldValue("medium", selected.name);
                          }}
                          getOptionLabel={(option) => {
                            return (
                              <>
                                <div>
                                  <span>{option.name}</span>
                                </div>
                              </>
                            );
                          }}
                        />
                        {formik.touched.medium && formik.errors.medium && (
                          <>
                            <span className="text-danger">
                              {formik.errors.medium}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
          </div>
          <div className="d-flex justify-content-between  mt-4">
            {
              <button className="btn-onboard-disabled disabled">
                Previous
              </button>
            }

            <button
              type="submit"
              onClick={() => {}}
              className="btn-onboard-fill"
            >
              <span>Next Step</span>
              <img className="ms-2" src="./images/onBoarding/right-icon.png" />
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

//  for About institute step AboutInstitue function work
const AboutInstitute = () => {
  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    handleNextChild,
    handleBack,
    modalStep,
    setModalStep,
    handleNextModalStep,
    handleHideDuplicateWarning,
    handleShowDuplicateWarning,
    showDuplicateWarning,
    setShowDuplicateWarning,
    instituteFormik,
    onBoardingData,
    setInstituteData,
  } = useContext(OnBoardContext);
  // const [isDuplicate,setIsDuplicate]= useState(true)
  const [duplicateIns, setDuplicateIns] = useState([]);
  const [instId, setInstId] = useState("");
  const [popupclass, setPopupclass] = useState(false);
  const [display, setdisplay] = useState(false);
  const [DuplicateInsDetails, setDuplicateInsDetails] = useState([]);
  function displaynoneContent(id) {
    setInstId(id);
    setdisplay("d-none");
  }
  const renderStepModal = () => {
    switch (true) {
      case modalStep === 0:
        return <div>{/* <DuplicateWarning /> */}</div>;

      case modalStep === 1:
        return <div>{/* <DuplicateAccount /> */}</div>;
      default:
        return null;
    }
  };

  const getAllDuplicateInstitute = () => {
    apiJson
      .get("api/v2/institute/getAllInstitute")
      .then((res) => {
        setDuplicateInsDetails(res?.data?.result);
      })
      .catch((error) => {
        console.log("err", error);
      });
  };

  useEffect(() => {
    getAllDuplicateInstitute();
  }, []);

  const validation = Yup.object({
    name: Yup.string().required("Write Insitute Name"),
    description: Yup.string().required("Write Description"),
    // .max(40, 'Description must not exceed 40 characters'),
  });
  const initialValues = {
    name:
      instituteFormik.values.institution_name ||
      onBoardingData?.institution_name ||
      "",
    description: instituteFormik.values.bio || onBoardingData?.bio || "",
  };
  function copyValues() {
    const fieldMappings = {
      name: "institution_name",
      description: "bio",
    };
    const updatedValues = { ...instituteFormik.values };

    for (const formikKey in fieldMappings) {
      const instituteFormikKey = fieldMappings[formikKey];
      updatedValues[instituteFormikKey] = formik?.values[formikKey];
    }

    instituteFormik.setValues(updatedValues);
  }
  const onSubmit = () => {
    copyValues();
    // handleShowDuplicateWarning()
    if (
      DuplicateInsDetails?.some((i) =>
        i?.institution_name
          ?.trim()
          ?.toLowerCase()
          ?.replace(/\s+/g, " ")
          ?.includes(formik?.values?.name.toLowerCase()?.replace(/\s+/g, " "))
      )
    ) {
      let duplicates = DuplicateInsDetails?.filter((i) =>
        i?.institution_name
          ?.trim()
          ?.toLowerCase()
          ?.replace(/\s+/g, " ")
          ?.includes(formik?.values?.name.toLowerCase()?.replace(/\s+/g, " "))
      );
      setDuplicateIns(duplicates);
      setPopupclass(true);
    } else {
      handleNextChild();
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit,
  });
  const handleOutPop = () => {
    setPopupclass(false);
    handleNextChild();
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="mb-2">
            <span className=" fs-35px fw-600">About Institution</span>
          </div>
          <div className="mb-50 mb-20">
            <span className="fs-19px fw-500 color-grey">
              Share the essence of your institution with us.
            </span>
          </div>

          <div className="mb-4">
            <div className="d-flex">
              <span className="fw-500 fs-6 fs-md-5  mb-2 d-block">
                Name of the Institute
              </span>
              <span className="ms-2">
                <Grid item>
                  <Tooltip
                    title="For multiple branches, kindly provide the full name of the institution, such as Delhi Public School, RK Puram."
                    placement="bottom"
                  >
                    <HelpIcon sx={{ color: "#28b8d4" }} />
                  </Tooltip>
                </Grid>
              </span>
            </div>
            <input
              value={formik.values.name}
              onChange={(e) => {
                const inputValue = e.target.value;
                const capitalizedValue = inputValue
                  .toLowerCase() // Convert to lowercase
                  .replace(/\s+/g, " ") // Replace consecutive spaces with a single space
                  .replace(/(^|\s)\S/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word

                formik.setFieldValue("name", capitalizedValue);
              }}
              placeholder="XYZ Institute"
              className="form-control rounded-3 border-0 custom-placeholder box-shadow-0 background-grey"
              type="text"
            />
            {formik.touched.name && formik.errors.name && (
              <span className="text-danger">{formik.errors.name}</span>
            )}
          </div>

          <div className="background-purple border-purple rounded px-2 py-2 mb-50 mb-20">
            <span className="me-2">
              <AssignmentIcon sx={{ color: "#28007d" }} />
            </span>
            <span className="fs-15px fw-500 color-purple ">
              {" "}
              For multiple branches, kindly provide the full name of the
              institution. E.g: Delhi Public School, RK Puram.
            </span>
          </div>

          <div>
            <span className="fw-500 fs-6 fs-md-5  mb-2 d-block">
              About institute
              <span className="color-grey">(Max 400 Characters)</span>
            </span>
            <div>
              <textarea
                value={formik.values.description}
                onChange={(e) => {
                  e.target.value?.length <= 500 &&
                    formik.setFieldValue("description", e.target.value);
                }}
                placeholder="e.g. XYZ University is a leading institution dedicated to fostering academic excellence and holistic growth for students"
                className={`form-control custom-placeholder background-grey resize-none box-shadow-0 rounded-3  thin-scroll  ${
                  formik.touched.description && formik.errors.description
                    ? "border-1 border-danger"
                    : "border-0"
                }`}
              />
              {formik.touched.description && formik.errors.description && (
                <span className="text-danger">{formik.errors.description}</span>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-between  mt-4">
            <button onClick={handleBack} className="btn-onboard">
              Previous
            </button>
            <button type="submit" className="btn-onboard-fill">
              <span>Next Step</span>
              <img className="ms-2" src="./images/onBoarding/right-icon.png" />
            </button>
          </div>
        </div>
      </form>
      {/* Duplicate Institute modal start */}

      {/* ==============================popup============================== */}
      {popupclass && (
        <div className={`${popupclass ? "popup" : "popnone"}`}>
          <div
            className={`popedelement discussionBoardScroll ${
              popupclass ? "toppopup" : "popnone"
            }`}
          >
            <div className={`text-center ${display ? "d-none" : "inneritems"}`}>
              <p>Duplicate account warning!</p>
              <p>
                We have noticed that similar Yuvamanthan institutional account/s
                already exist!
              </p>
            </div>

            <div className={`${display ? "d-none" : "innerContent"}`}>
              <div className="d-flex justify-content-between">
                <p className="matchFoundPara">
                  {duplicateIns?.length} Matches Found{" "}
                </p>
                <button
                  className="px-2 rounded"
                  style={{ background: "#494bf3", color: "white" }}
                  onClick={() => setPopupclass(false)}
                >
                  Close
                </button>
              </div>
              {duplicateIns?.map((item) => (
                <div className="row colleges p-0 py-3" key={item?.id}>
                  <div
                    className="col-lg-3 p-2 background-grey rounded"
                    style={{ height: "100px", width: "105px" }}
                  >
                    <img
                      className="w-100 h-100 object-fit-cover rounded"
                      src={item?.logo}
                      alt="institute logo"
                    />
                  </div>
                  <div className="col-lg-9 para collegeItems">
                    <p className="d-flex justify-content-between pe-5">
                      {item?.institution_name.trim()}
                      <span style={{ fontSize: "15px" }}>
                        {item?.totalStudent} Students
                      </span>
                    </p>
                    <p className="collegeparaFirst">{item?.address}</p>
                    <div className="d-flex justify-content-between pe-5">
                      <h6>
                        {" "}
                        Admin{" "}
                        <span className="collegeItemsSpan">
                          : {item?.first_name + " " + item?.last_name}
                        </span>
                      </h6>
                      <h6>
                        {" "}
                        Designation{" "}
                        <span className="collegeItemsSpan">
                          : {item?.designation}
                        </span>{" "}
                      </h6>
                    </div>
                    <a
                      href="#"
                      className="text-end d-block text-decoration-none text-capitalize pe-5 detailbtn mt-2"
                      onClick={() => displaynoneContent(item?.id)}
                    >
                      detail
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <button
                className="border-0 bg-transparent d-block m-auto text-center nonepara"
                onClick={handleOutPop}
              >
                None of the above
              </button>{" "}
            </div>

            <div className={`${display ? "duplicateAccount" : "d-none"}`}>
              <DuplicateAccount
                popupclass={popupclass}
                setPopupclass={setPopupclass}
                DuplicateInsDetails={DuplicateInsDetails}
                instId={instId}
                handleNextChild={handleNextChild}
              />
            </div>
          </div>
        </div>
      )}

      <Modal
        show={showDuplicateWarning}
        onHide={handleHideDuplicateWarning}
        size="lg"
        className="rounded-4"
        centered
      >
        <Modal.Body className="rounded-4">
          <div className="p-3">{renderStepModal()}</div>
        </Modal.Body>
      </Modal>
    </>
  );
};

// const DuplicateWarning = () => {
//     const {
//         activeStep,
//         setActiveStep,
//         activeChildStep,
//         setActiveChildStep,
//         count,
//         setCount,
//         stepperArray,
//         handleNextChild,
//         handleBack,
//         modalStep,
//         setModalStep,
//         handleNextModalStep,
//         handleHideDuplicateWarning,
//         handleShowDuplicateWarning,
//         showDuplicateWarning,
//         setShowDuplicateWarning,
//     } = useContext(OnBoardContext);
//     return (
//         <>
//             <div>
//                 <div className="d-flex px-3 py-3  mb-3 flex-column rounded-4 justify-content-center align-items-center" style={{
//                     background: '#FFFDD9'
//                 }}>
//                     <span style={{
//                         color: '#675D00',
//                     }} className="fs-19px fw-600 mb-3 d-block">Duplicate Account Warning!</span>
//                     <span style={{ color: 'black' }} className="fw-400 fs-19px col-10 text-center ">We have noticed that similar Yuvamanthan institutional account/s already exist!</span>
//                 </div>
//                 <div className=" d-flex rounded-4 p-2 mb-3" style={{
//                     border: '3px solid #4200FF'
//                 }} >
//                     <div style={{
//                         height: '145px',
//                         width: '145px',
//                     }}>
//                         <img src="./images/onBoarding/university-2.png" />
//                     </div>
//                     <div className="ms-2">
//                         <div className="mb-2">
//                             <span style={{
//                                 color: '#2D008D'
//                             }} className="fw-600 fs-19px mb-1 d-block">
//                                 Administrative Management College
//                             </span>
//                             <span
//                                 style={{
//                                     color: '#636363'
//                                 }}
//                                 className="fs-15px fw-500 mb-2 d-block"
//                             >AMC Campus, Bannerghatta Main Rd, Bengaluru, Karnataka 560083</span>
//                         </div>
//                         <div className=" row mb-4">
//                             <div className="col-6">
//                                 <span style={{ color: 'black' }}>Admin:</span>
//                                 <span>Saurabh Sharma</span>
//                             </div>
//                             <div className="col-6">
//                                 <span style={{ color: 'black' }}>Designation:</span>
//                                 <span>Principal</span>
//                             </div>
//                         </div>
//                         <div className="d-flex justify-content-end">
//                             <span
//                                 style={{
//                                     color: '#6100FF'
//                                 }}
//                                 onClick={() => {
//                                     setModalStep(1)
//                                 }} className="cursor-pointer fw-600 fs-15px  d-block">Claim Ownership </span>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="d-flex justify-content-center">
//                     <span className="me-3 cursor-pointer"><img src="./images/onBoarding/left-icon.png" /></span>
//                     <span className="me-3">1</span>
//                     <span className="me-3">2</span>
//                     <span className="me-3">3</span>
//                     <span className="cursor-pointer"><img src="./images/onBoarding/right-icon-2.png" /></span>
//                 </div>

//             </div>
//         </>
//     )
// }

// const DuplicateAccount = () => {
//     const {
//         activeStep,
//         setActiveStep,
//         activeChildStep,
//         setActiveChildStep,
//         count,
//         setCount,
//         stepperArray,
//         handleNextChild,
//         handleBack,
//         modalStep,
//         setModalStep,
//         handleNextModalStep,
//         handleHideDuplicateWarning,
//         handleShowDuplicateWarning,
//         showDuplicateWarning,
//         setShowDuplicateWarning,
//     } = useContext(OnBoardContext);
//     return (
//         <>
//             <div>
//                 <div className="d-flex px-3 py-3  mb-3 flex-column rounded-4 justify-content-center align-items-center" style={{
//                     background: '#FFFDD9'
//                 }}>
//                     <span style={{
//                         color: '#675D00',
//                     }} className="fs-19px fw-600 mb-3 d-block">Duplicate Account</span>
//                     <span style={{ color: 'black' }} className="fw-400 fs-19px col-10 text-center ">We have noticed that similar Yuvamanthan institutional account/s already exist!</span>
//                 </div>
//                 <div className="d-flex justify-content-end">
//                     <span
//                         style={{
//                             color: '#6100FF'
//                         }}
//                         onClick={() => {
//                             setShowDuplicateWarning(false)
//                             setModalStep(0);
//                             handleNextChild()
//                         }} className="cursor-pointer fw-600 fs-15px  d-block">Claim Ownership </span>
//                 </div>
//                 <div className="d-flex justify-content-center">
//                     <span className="me-3 cursor-pointer"><img src="./images/onBoarding/left-icon.png" /></span>
//                     <span className="me-3">1</span>
//                     <span className="me-3">2</span>
//                     <span className="me-3">3</span>
//                     <span className="cursor-pointer"><img src="./images/onBoarding/right-icon-2.png" /></span>
//                 </div>

//             </div>
//         </>
//     )
// }

// for institute Address step RegisteredAddress function work
const RegisteredAddress = () => {
  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    handleNextChild,
    handleBack,
    setInstituteAddress,
    instituteAddress,
    instituteFormik,
    setInstituteData,
  } = useContext(OnBoardContext);

  const validation = Yup.object({
    country: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    district: Yup.string().required("required"),
    street: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    pinCode: Yup.number().required("Required"),
  });
  const initialValues = {
    country: instituteFormik.values.country || "",
    state: instituteFormik.values.state || "",
    district: instituteFormik.values.district || "",
    street: instituteFormik.values.street || "",
    city: instituteFormik.values.city || "",
    pinCode: instituteFormik.values.pinCode || "",
  };
  const onSubmit = () => {
    copyValues();
    setInstituteAddress({ ...formik.values });

    handleNextChild();
  };
  function copyValues() {
    const fieldMappings = {
      country: "country",
      state: "state",
      district: "district",
      street: "street",
      city: "city",
      pinCode: "pinCode",
    };
    const updatedValues = { ...instituteFormik.values };

    for (const formikKey in fieldMappings) {
      const instituteFormikKey = fieldMappings[formikKey];
      updatedValues[instituteFormikKey] = formik.values[formikKey];
    }

    instituteFormik.setValues(updatedValues);
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit,
  });

  const [country, setCountry] = useState([{ name: "India", value: "India" }]);
  const [stateData, setState] = useState(statesOfIndia);
  const [countryLoader, setCountryLoader] = useState(false);
  const [stateLoader, setstateLoader] = useState(false);

  const fetchCountryData = () => {
    setCountryLoader(true);
    apiJson
      .get(`v2/institute/fetchCountryData`)
      .then((res) => {
        setCountry(res.data.allCountryData);
        setCountryLoader(false);
        // console.log(res.data.allCountryData);
      })
      .catch((err) => {
        setCountryLoader(false);

        console.log(err);
      });
  };
  const fetchStateData = () => {
    setstateLoader(true);
    apiJson
      .get(`v2/institute/fetchStateData/${formik.values.country}`)
      .then((res) => {
        setState(res?.data?.result);
        setstateLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setstateLoader(false);
      });
  };

  useEffect(() => {
    // fetchCountryData();
    fetchStateData();
  }, [formik?.values.country]);
  useEffect(() => {
    fetchCountryData();
  }, []);

  // useEffect(()=>{
  // },[formik.values.country])

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="mb-2">
            <span className=" fs-35px fw-600">Institute Address</span>
          </div>

          <div className="mb-50 mb-20">
            <span className="fs-17px fw-500 color-grey">
              Enter the registered address of your institute
            </span>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 mb-2 mb-md-4">
              <span className="fw-500 fs-6 fs-md-5  mb-2 d-block">Country</span>
              {/* <input value={formik.values.country} onChange={(e)=>formik.setFieldValue('country', e.target.value)} placeholder="Enter Country..." className="form-control rounded-3 border-0 box-shadow-0 background-grey" type='text' /> */}
              {countryLoader ? (
                <CircularProgress />
              ) : (
                <Select
                  isSearchable={true}
                  styles={customStyles}
                  options={country}
                  value={country.find((i) => i.name === formik.values.country)}
                  onChange={(selected) => {
                    console.log(selected);
                    formik.setFieldValue("country", selected?.name);
                  }}
                  getOptionLabel={(option) => {
                    return (
                      <>
                        <span>{option?.name}</span>
                      </>
                    );
                  }}
                />
              )}
              {formik.touched.country && formik.errors.country && (
                <span className="text-danger">{formik.errors.country}</span>
              )}
            </div>
            <div className="col-12 col-lg-6 mb-2 mb-md-4">
              <span className="fw-500 fs-6 fs-md-5  mb-2 d-block">State</span>
              {stateLoader ? (
                <CircularProgress />
              ) : (
                <Select
                  isSearchable={true}
                  styles={customStyles}
                  options={stateData}
                  value={stateData?.find(
                    (i) => i?.name === formik.values.state
                  )}
                  onChange={(selected) => {
                    formik.setFieldValue("state", selected.name);
                  }}
                  getOptionLabel={(option) => {
                    return (
                      <>
                        <span>{option?.name}</span>
                      </>
                    );
                  }}
                />
              )}
              {/* <Select
                         isSearchable={true}
                            styles={customStyles}
                            options={statesOfIndia}
                            value={statesOfIndia.find((i)=>i.name===formik.values.state)}
                            onChange={(selected) => {
                                
                                    formik.setFieldValue('state', selected.name)
                                }}
                            getOptionLabel={
                                (option) => {
                                    return (
                                        <>
                                                <span>{option.name}</span>
                                        </>
                                    )
                                }
                            }
                        /> */}

              {formik.touched.state && formik.errors.state && (
                <span className="text-danger">{formik.errors.state}</span>
              )}
            </div>
            <div className="col-12 col-lg-6 mb-2 mb-md-4">
              <span className="fw-500 fs-6 fs-md-5  mb-2 d-block">
                District
              </span>
              <input
                value={formik.values.district}
                onChange={(e) =>
                  formik.setFieldValue("district", e.target.value)
                }
                placeholder="Enter District..."
                className="form-control rounded-3 border-0 box-shadow-0 background-grey"
                type="text"
              />
              {formik.touched.district && formik.errors.district && (
                <span className="text-danger">{formik.errors.district}</span>
              )}
            </div>
            <div className="col-12 col-lg-6 mb-2 mb-md-4">
              <span className="fw-500 fs-6 fs-md-5  mb-2 d-block">
                Street Address
              </span>
              <input
                value={formik.values.street}
                onChange={(e) => formik.setFieldValue("street", e.target.value)}
                placeholder="Enter Street Address..."
                className="form-control rounded-3 border-0 box-shadow-0 background-grey"
                type="text"
              />
              {formik.touched.street && formik.errors.street && (
                <span className="text-danger">{formik.errors.street}</span>
              )}
            </div>
            <div className="col-12 col-lg-6 mb-2 mb-md-4">
              <span className="fw-500 fs-6 fs-md-5  mb-2 d-block">City</span>
              <input
                value={formik.values.city}
                onChange={(e) => formik.setFieldValue("city", e.target.value)}
                placeholder="Enter City..."
                className="form-control rounded-3 border-0 box-shadow-0 background-grey"
                type="text"
              />
              {formik.touched.city && formik.errors.city && (
                <span className="text-danger">{formik.errors.city}</span>
              )}
            </div>
            <div className="col-12 col-lg-6 mb-2 mb-md-4">
              <span className="fw-500 fs-6 fs-md-5  mb-2 d-block">
                Pin Code
              </span>
              <input
                value={formik.values.pinCode}
                onChange={(e) => {
                  formik.setFieldValue("pinCode", e.target.value);
                }}
                placeholder="Enter Pin Code..."
                className="form-control rounded-3 border-0 box-shadow-0 background-grey"
                type="number"
              />
              {formik.touched.pinCode && formik.errors.pinCode && (
                <span className="text-danger">{formik.errors.pinCode}</span>
              )}
            </div>
          </div>

          <div className="background-purple border-puple rounded-3 px-2 py-2 mb-50">
            <span className="me-2">
              <AssignmentIcon sx={{ color: "#28007d" }} />
            </span>
            <span className="fs-15px  fw-500 color-purple ">
              Institute address should match with valid proof of address
            </span>
          </div>

          <div className="d-flex justify-content-between  mt-4">
            <button onClick={handleBack} className="btn-onboard">
              Previous
            </button>
            <button type="submit" className="btn-onboard-fill">
              <span>Next Step</span>
              <img className="ms-2" src="./images/onBoarding/right-icon.png" />
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

// for Appearance step Appearance function work

const Appearance = () => {
  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    handleNextChild,
    handleBack,
    instituteFormik,
    setInstituteData,
  } = useContext(OnBoardContext);
  const [image, setImage] = useState("");
  const [imageLink, setimageLink] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [isValid, setIsValid] = useState(true);

  const validationSchema = Yup.object().shape({
    image: Yup.mixed().when("logo", {
      is: (logo) => !logo,
      then: Yup.mixed()
        .required("Image is required")
        .test("fileSize", "Image file is too large", (value) => {
          return value && value.size <= 5000000; // 5 MB limit
        })
        // .test("fileType", "Unsupported image file type", (value) => {
        //   return (
        //     value &&
        //     ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
        //   );
        // }),
    }),
  });
  const onSubmit = () => {
    if ((url === "" || isValid) && !imageSizeError) {
      setInstituteData("website", url);
      handleNextChild();
    }
  };
  const formik = useFormik({
    initialValues: {
      logo: instituteFormik.values.logo || "",
      image: "",
      website: instituteFormik.values.website || "",
    },
    validationSchema: validationSchema,
    onSubmit,
  });

  const [imageSizeError, setImageSizeError] = useState(false);
  const getImageLink = () => {
    setImageLoading(true);
    if (image) {
      apiAuth
        .post("v2/register/uploadInstituteLogo", { img: image })
        .then((res) => {
          setImageLoading(false);
          setimageLink(res.data.result);
          setInstituteData("logo", res.data.result);
          formik.setFieldValue("logo", res.data.result);
          toast.dismiss();
        })
        .catch((error) => {
          setImageLoading(false);
          toast.dismiss();
          // toast.error('Server Busy! Please Wait')
        });
    }
  };
  useEffect(() => {
    if (image?.size > 1 * 1024 * 1024) {
      console.log(imageSizeError, "imageSizeError 1589");
      setImageSizeError(true);
      setInstituteData("logo", null);
      setInstituteData("Image", null);
      return;
    }
    if (image) {
      console.log(imageSizeError, "imageSizeError 1589");
      setImageSizeError(false);

      getImageLink();
    }
  }, [image]);

  function showFileInput() {
    var img = document.getElementById("img");
    img.click();
  }

  const validateURL = (inputURL) => {
    // Regular expression for a simple URL validation
    const urlPattern =
      /^(http:\/\/|https:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?$/;
    return urlPattern.test(inputURL);
  };
  useEffect(() => {
    setUrl(instituteFormik.values.website || "");
  }, []);
  // ************To check URL validation************
  const handleURLChange = (e) => {
    const inputURL = e.target.value;
    setUrl(inputURL);

    if (inputURL === "" || validateURL(inputURL)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    formik.setFieldValue("website", inputURL);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="mb-2">
            <span className=" fs-35px fw-600">Appearance</span>
          </div>
          <div>
            <div className="mb-50">
              <span className="fw-500 fs-19px color-grey">
                Customize your institution's profile
              </span>
            </div>
          </div>

          <div className="d-flex mb-4">
            {imageLoading ? (
              <>
                <div
                  className="me-4 d-flex justify-content-center align-items-center"
                  style={{ height: "100px", width: "100px" }}
                >
                  <div class="spinner-border" role="status"></div>
                </div>
              </>
            ) : (
              <div
                className="me-4  "
                style={{ height: "100px", width: "100px" }}
              >
                {
                  formik?.values?.logo ? (
                    <img
                      className="w-100 h-100 rounded-3"
                      src={formik?.values?.logo}
                    />
                  ) : (
                    // <img

                    <AccountBalanceTwoToneIcon
                      style={{ color: "#80808036" }}
                      className="w-100 h-100 rounded-3"
                    />
                  )
                  // src={"./images/onBoarding/default_inst.png"}
                  // />
                }
              </div>
            )}
            <div className="">
              <div className="mb-4">
                <span className="fs-17px fw-500">
                  Upload your Institute's Logo
                </span>
              </div>

              <div
                className="p-2 rounded-3 text-center w-auto border-1 cursor-pointer"
                style={{
                  background: "#F0EBFF",
                }}
                onClick={showFileInput}
              >
                <input
                  type="file"
                  id="img"
                  style={{ display: "none" }}
                  accept=".png, .jpg, .jpeg"
                  name="files[]"
                  onChange={(e) => {
                    if (e.target.files.length) {
                      // formik.setFieldValue('image',e.target.files[0])
                      setImage(e.target.files[0]);
                    }
                  }}
                />

                <span className="me-2 ">
                  <CloudUploadIcon
                    sx={{ color: "#6909ff", width: "30px", height: "30px" }}
                  />
                </span>
                <span className="fs-15px color-purple fw-500 text-center  mb-4">
                  Upload
                </span>
              </div>
              {/* <span className="text-danger">{formik.errors.image}</span> */}
              {imageSizeError && (
                <span className="text-danger ">Image too Large</span>
              )}
              {!imageSizeError && formik?.errors?.image && (
                <span className="text-danger">{formik.errors.image}</span>
              )}
            </div>
          </div>

          <div className="d-flex  rounded-3 background-purple border-purple p-3">
            <span className="me-1">
              <AssignmentIcon className="mt-1" sx={{ color: "#28007d" }} />{" "}
            </span>
            <span className="fw-500 fs-15px color-purple">
              Image Quality: To avoid issues during uploading, ensure your logo
              does not exceed the 1MB file size limit. Only JPEG and PNG files
              are accepted.
            </span>
          </div>

          <div className="my-4">
            <div>
              <div className="mb-4">
                <span className="onboard-form-span-sm">
                  Add your institute's website
                </span>
              </div>
              <div className="d-flex align-items-center">
                <div
                  className="p-2 rounded-3  w-auto border-1 cursor-pointer"
                  style={{
                    background: "#F0EBFF",
                  }}
                >
                  <span className="color-purple fs-17px fw-500">https://</span>
                </div>
                <div className="ms-2">
                  <input
                    placeholder="example.com"
                    className="border-0 box-shadow-0 form-control text-dark"
                    type="text"
                    name="website"
                    value={formik.values.website}
                    onChange={handleURLChange}
                  />
                  {!isValid && (
                    <span className="text-danger error-message">
                      Invalid URL
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between  mt-4">
            <button onClick={handleBack} className="btn-onboard">
              Previous
            </button>
            {imageLoading ? (
              <div className="text-center">
                {" "}
                <CircularProgress />
              </div>
            ) : (
              <button type="submit" className="btn-onboard-fill">
                <span>Next Step</span>
                <img
                  className="ms-2"
                  src="./images/onBoarding/right-icon.png"
                />
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

const SocialPresence = () => {
  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    instituteFormik,
    setInstituteData,
    handleNextChild,
    handleBack,
  } = useContext(OnBoardContext);

  const [urls, setUrls] = useState(Array(socialHandles.length).fill(""));
  const [isValid, setIsValid] = useState(
    Array(socialHandles.length).fill(true)
  );

  // const validateURL = (inputURL) => {
  //     // Regular expression for a simple URL validation
  //     const urlPattern = /^(?!(https?:\/\/|www\.))[a-zA-Z]+\.[^\s/$.?#][^\s]*$/;
  //     return urlPattern.test(inputURL);
  //   };
  useEffect(() => {
    setUrls([
      instituteFormik.values.facebook_acc || "",
      instituteFormik.values.twitter_acc || "",
      instituteFormik.values.linkedin_acc || "",
      instituteFormik.values.insta_acc || "",
      instituteFormik.values.youtube_acc || "",
    ]);
  }, []);
  // ************To check URL validation************
  const handleURLChange = (e, index) => {
    const inputURL = e.target.value;
    const newUrls = [...urls];
    newUrls[index] = inputURL;
    setUrls(newUrls);
    setError(false);
    const newIsValid = [...isValid];
    if (inputURL === "" || socialHandles[index].socialurls.test(inputURL)) {
      newIsValid[index] = true;
    } else {
      newIsValid[index] = false;
    }
    setIsValid(newIsValid);
  };
  const [error, setError] = useState(false);
  const handleSubmitSocialUrl = () => {
    if (urls.every((url, i) => url === "" || isValid[i])) {
      setInstituteData("facebook_acc", urls[0]);
      setInstituteData("twitter_acc", urls[1]);
      setInstituteData("linkedin_acc", urls[2]);
      setInstituteData("insta_acc", urls[3]);
      setInstituteData("youtube_acc", urls[4]);
      setError(false);
      handleNextChild();
    } else {
      setError(true);
    }
  };
  return (
    <>
      <div>
        <div className="mb-2">
          <span className=" fs-35px fw-600">Social Media</span>
        </div>
        <div>
          <div className="mb-4">
            <span className="fs-19px fw-500 color-grey">
              Expand your network and showcase achievements through integrated
              social media connections
            </span>
          </div>
        </div>

        <div>
          <div className="mb-4">
            <span className="fs-19px fw-500">
              Add your Social Media Accounts (Optional)
            </span>
          </div>

          <div className="col-12 col-md-5">
            {socialHandles?.map((s, index) => {
              return (
                <div className="cursor-pointer mb-4 ">
                  <div className="d-flex align-items-center  ">
                    <div
                      className=" me-3 rounded-3 p-2 background-purple"
                      style={{ height: "50px" }}
                    >
                      {s?.icons || (
                        <img
                          className=" rounded-3 h-100 "
                          src={s.icon}
                          alt="img"
                        />
                      )}
                    </div>
                    <div className="r">
                      <input
                        className="fs-19px fs-15px fw-400 form-control background-grey custom-placeholder border-0 box-shadow-0 rounded-3 "
                        placeholder={s.url}
                        type="text"
                        value={urls[index]}
                        onChange={(e) => handleURLChange(e, index)}
                      />
                      <div>
                        {isValid[index]
                          ? null
                          : error && (
                              <span className="error text-danger">
                                Invalid URL
                              </span>
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4">
          <button onClick={handleBack} className="btn-onboard">
            Previous
          </button>

          <button onClick={handleSubmitSocialUrl} className="btn-onboard-fill">
            <span>Next Step</span>
            <img className="ms-2" src="./images/onBoarding/right-icon.png" />
          </button>
        </div>
      </div>
    </>
  );
};

const Documents = () => {
  const {
    // eslint-disable-next-line no-unused-vars
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    handleNextChild,
    handleBack,
    instituteFormik,
    setInstituteData,
  } = useContext(OnBoardContext);

  const [dragging, setDragging] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFiles2, setSelectedFiles2] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(false);

  const validationSchema = Yup.object().shape({
    proof_of_id_file: Yup.mixed().when("proof_of_id", {
      is: (proof_of_id) => Boolean(proof_of_id),
    //   then: Yup.mixed().test("fileType", "Unsupported file", (value) => {
    //     return (
    //       value &&
    //       ["image/jpeg", "image/png", "image/jpg", "application/pdf"].includes(
    //         value?.type
    //       )
    //     );
    //   }),
      otherwise: Yup.mixed().notRequired(),
    }),
    proof_of_address_file: Yup.mixed().when("proof_of_address", {
      is: (proof_of_address) => Boolean(proof_of_address),
    //   then: Yup.mixed().test("fileType", "Unsupported file", (value) => {
    //     return (
    //       value &&
    //       ["image/jpeg", "image/png", "image/jpg", "application/pdf"].includes(
    //         value?.type
    //       )
    //     );
    //   }),
      otherwise: Yup.mixed().notRequired(),
    }),
  });

  const onSubmit = () => {
    handleNextChild();
  };
  const formik = useFormik({
    initialValues: {
      proof_of_id: instituteFormik.values.proof_of_id || "",
      proof_of_address: instituteFormik.values.proof_of_address || "",
      proof_of_id_file: instituteFormik.values.proof_of_id_file || "",
      proof_of_address_file: instituteFormik.values.proof_of_address_file || "",
    },
    validationSchema: validationSchema,
    onSubmit,
  });
  const [imageSizeError, setImageSizeError] = useState(false);
  const [imageSizeError2, setImageSizeError2] = useState(false);

  const get_file1_link = () => {
    if (selectedFiles) {
      setLoader(true);
      console.log("inside the true");

      apiAuth
        .post("v2/register/uploadInstituteLogo", { img: selectedFiles[0] })
        .then((res) => {
          setInstituteData("proof_of_id", res.data.result);
          setInstituteData("proof_of_id_file", selectedFiles[0].name);
          formik.setFieldValue("proof_of_id", res.data.result);
          formik.setFieldValue("proof_of_id_file", selectedFiles[0].name);
          setLoader(false);
        })
        .catch((error) => {
          toast.dismiss();
          // toast.error('Internal server Error')
          setLoader(false);
        });
    }
  };

  const get_file2_link = () => {
    if (selectedFiles2) {
      setLoader(true);

      apiAuth
        .post("v2/register/uploadInstituteLogo", { img: selectedFiles2[0] })
        .then((res) => {
          setInstituteData("proof_of_address", res.data.result);
          setInstituteData("proof_of_address_file", selectedFiles2[0].name);
          formik.setFieldValue("proof_of_address", res.data.result);
          formik.setFieldValue("proof_of_address_file", selectedFiles2[0].name);
          setLoader(false);
        })
        .catch((error) => {
          toast.dismiss();
          // toast.error('Internal server Error')
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    if (selectedFiles.length > 0 && selectedFiles[0].size > 1 * 1024 * 1024) {
        setImageSizeError(true);
        setInstituteData("proof_of_id", null);
        formik.setFieldValue("proof_of_id", null);
        formik.setFieldValue("proof_of_id_file", "");
        return;
      }
    if (selectedFiles.length > 0) {
      setImageSizeError(false);
      get_file1_link();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  useEffect(() => {
    if (selectedFiles2.length > 0 && selectedFiles2[0].size > 1 * 1024 * 1024) {
      setImageSizeError2(true);
      setInstituteData("proof_of_address", null);
      formik.setFieldValue("proof_of_address", null);
      formik.setFieldValue("proof_of_address_file", "");
      return;
    }
    if (selectedFiles2.length > 0) {
      setImageSizeError2(false);
      get_file2_link();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles2]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles(files);
    formik.setFieldValue("proof_of_id_file", files[0]?.name);
  };

  const handleDrop2 = (e) => {
    e.preventDefault();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles2(files);
    formik.setFieldValue("proof_of_address_file", files[0]?.name);
  };

  const handleFileInputChange = (e) => {

    const fileTypes = ["application/pdf", "image/png", "image/jpeg"];
    const files = Array.from(e.target.files);
    if (files.length > 0 && fileTypes.includes(files[0]?.type)) {
      setSelectedFiles(files);
    } else {
      toast.dismiss();
      toast.error("Unsupported file type.");
    }
  };

  const handleFileInputChange2 = (e) => {
    const fileTypes = ["application/pdf", "image/png", "image/jpeg"];
    const files = Array.from(e.target.files);
    if (files.length > 0 && fileTypes.includes(files[0]?.type)) {
      setSelectedFiles2(files);
    } else {
      toast.dismiss();
      toast.error("Unsupported file type.");
    }
  };

  const handleFileInputClick = (e, inputId) => {
    const fileInput = document.getElementById(inputId);
    if (fileInput) fileInput.click();
  };

  const removeFile = (i) => {
    if (i === 0) {
      setSelectedFiles([]);
      formik.setFieldValue("proof_of_id_file", "");
      setInstituteData("proof_of_id", null);
    } else {
      setSelectedFiles2([]);
      formik.setFieldValue("proof_of_address_file", "");
      setInstituteData("proof_of_address", null);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const files = [
    {
      name: "POA.pdf",
      size: "1MB",
    },
    {
      name: "LatestDocument.pdf",
      size: "543KB",
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="mb-2">
            <span className=" fs-35px fw-600">Upload Documents</span>
          </div>
          <div className="mb-50">
            <span className="fs-19px fw-500 color-grey">
              Assist us in verifying the authenticity of your institution.
            </span>
          </div>
          <div className="row justify-content-between mb-4">
            <div className="col-lg-6 col-12 px-4 py-2">
              <div
                className={`dashed-border-grey rounded-3 p-4 d-flex flex-column justify-content-center align-items-center cursor-pointer  file-drop-zone ${
                  dragging ? "dragging" : ""
                }`}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={(e) => {
                  handleFileInputClick(e, "input1");
                }}
                style={{ minHeight: "159px" }}
              >
                <input
                  accept="image/jpeg, image/png, application/pdf"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                  id="input1"
                />

                {formik.values.proof_of_id_file.length > 0 ? (
                  <span className="text-success">
                    <TaskAltOutlinedIcon />
                  </span>
                ) : (
                  <>
                    <span>
                      <CloudUploadOutlinedIcon
                        sx={{ color: "#5d81ad", fontSize: "40px" }}
                      />
                    </span>
                    <span className="fw-500 fs-17px d-block ">
                      Drag file here or click to
                    </span>
                    <span className="color-purple fs-17px fw-500 text-decoration-underline cursor-pointer">
                      Select file to upload
                    </span>
                  </>
                )}
                <span className="color-grey">
                  Proof of identity <span>(POI)</span>
                </span>
              </div>
              <div className="text-center">
                {imageSizeError && (
                  <span className="text-danger ">Image too Large</span>
                )}
                {!imageSizeError &&
                formik?.errors?.proof_of_id_file &&
                formik?.touched?.proof_of_id_file ? (
                  <span className="text-danger">
                    {formik?.errors?.proof_of_id_file}
                  </span>
                ) : null}
              </div>
            </div>

            <div className="col-lg-6 col-12 px-4 py-2">
              <div
                className={`dashed-border-grey rounded-3 p-4 d-flex justify-content-center flex-column align-items-center cursor-pointer  file-drop-zone ${
                  dragging ? "dragging" : ""
                }`}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop2}
                onClick={(e) => {
                  handleFileInputClick(e, "input2");
                }}
                style={{ minHeight: "159px" }}
              >
                <input
                  type="file"
                  accept="image/jpeg, image/png, application/pdf"
                  style={{ display: "none" }}
                  id="input2"
                  onChange={handleFileInputChange2}
                />

                {formik.values.proof_of_address_file.length > 0 ? (
                  <span className="text-success">
                    <TaskAltOutlinedIcon />
                  </span>
                ) : (
                  <>
                    <span>
                      <CloudUploadOutlinedIcon
                        sx={{ color: "#5d81ad", fontSize: "40px" }}
                      />
                    </span>
                    <span className="fw-500 fs-17px d-block ">
                      Drag file here or click to
                    </span>
                    <span className="color-purple fs-17px fw-500 text-decoration-underline cursor-pointer">
                      Select file to upload
                    </span>
                  </>
                )}
                <span className="color-grey">
                  Proof of address<span>(POA)</span>
                </span>
              </div>
              <div className="text-center">
                {imageSizeError2 && (
                  <span className="text-danger ">Image too Large</span>
                )}
                {!imageSizeError2 &&
                formik?.errors?.proof_of_address_file &&
                formik?.touched?.proof_of_address_file ? (
                  <span className="text-danger">
                    {formik?.errors?.proof_of_address_file}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="background-purple rounded-3 px-3 py-3 mb-50 border-purple d-flex">
            <div>
              <span className="me-2">
                {<AssignmentIcon className="mt-1" sx={{ color: "#28007d" }} />}
              </span>
            </div>
            <div>
              <span
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "#633CFF",
                }}
              >
                Accepted files types are .jpg .png or a pdf format.{" "}
                <span
                  onClick={handleShowModal}
                  className="fs-15px"
                  style={{ fontWeight: "400", color: "#633CFF" }}
                >
                  Upto 2 files or 1 MB maximum,{" "}
                  <span
                    className="fs-15px color-purple text-decoration-underline"
                    style={{ cursor: "pointer", fontWeight: "400" }}
                  >
                    click here to know about poa and poi documents{" "}
                  </span>
                </span>
              </span>
            </div>
            {/* <span className="fs-15px fw-500 color-purple "> <span className="me-2">{<img src="./images/onBoarding/note.png" />}</span>Accepted files types are .jpg .png or a pdf format. <span>Upto 2 files or 2 MB maximum, click here to know about poa and poi documents</span></span> */}
          </div>

          <div className="container">
            <DocumentModal
              documentModal={showModal}
              closeDocumentModal={handleClose}
            />
          </div>

          {formik.values.proof_of_id_file.length > 0 && (
            <div>
              <div className="mb-2 px-2 px-md-3 py-4 border-purple rounded-3 d-flex justify-content-between align-items-center">
                <span className="fw-500 fst-italic dashboardFeatureTitle">
                  {formik.values.proof_of_id_file?.length > 15
                    ? `${formik.values.proof_of_id_file?.slice(0, 15)}...`
                    : formik.values.proof_of_id_file}
                </span>
                <div className="d-flex justify-content-end col-4 col-md-3">
                  <div className="ms-2 d-flex justify-content-between ">
                    <span
                      className="fw-bold dashboardFeatureTitle"
                      style={{ fontFamily: "Poppins" }}
                    >
                      POI
                    </span>
                    <span
                      className="cursor-pointer"
                      onClick={() => removeFile(0)}
                    >
                      {" "}
                      <RemoveCircleOutlineIcon
                        className="ms-3"
                        sx={{ color: "#ff9090" }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {formik.values.proof_of_address_file.length > 0 && (
            <div>
              <div className="mb-2 px-2 px-md-3 py-4 border-purple rounded-3 d-flex justify-content-between align-items-center">
                <span className="fw-500 fst-italic dashboardFeatureTitle">
                  {formik.values.proof_of_address_file?.length > 15
                    ? `${formik.values.proof_of_address_file?.slice(0, 15)}...`
                    : formik.values.proof_of_address_file}
                </span>
                <div className="d-flex justify-content-end col-4 col-md-3">
                  <div className="ms-2 d-flex justify-content-between">
                    <span
                      className="fw-bold dashboardFeatureTitle"
                      style={{ fontFamily: "Poppins" }}
                    >
                      POA
                    </span>
                    <span
                      className="cursor-pointer"
                      onClick={() => removeFile(1)}
                    >
                      {" "}
                      <RemoveCircleOutlineIcon
                        className="ms-3"
                        sx={{ color: "#ff9090" }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="d-flex justify-content-between  mt-4">
            <button onClick={handleBack} className="btn-onboard">
              Previous
            </button>
            {/* {  loader?<CircularProgress/>:  */}
            <button type="submit" className="btn-onboard-fill">
              <span>Next Step</span>
              <img className="ms-2" src="./images/onBoarding/right-icon.png" />
            </button>

            {/* } */}
          </div>
        </div>
      </form>
    </>
  );
};

const AboutYou = () => {
  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    handleNextChild,
    handleBack,
    selectedDesignation,
    setSelectedDesignation,
    instituteFormik,
    setInstituteData,
  } = useContext(OnBoardContext);

  const [showDesList, setShowDesList] = useState([]);
  useEffect(() => {
    if (selectedDesignation === "School") {
      setShowDesList(designationSchool);
    } else if (selectedDesignation === "Coaching Institute") {
      setShowDesList(designationCoachingInstitute);
    } else if (["College", "University"].includes(selectedDesignation)) {
      setShowDesList(designationUniversityCollege);
    }
  }, [selectedDesignation]);

  const validation = Yup.object().shape({
    first_name: Yup.string().required("Write First Name"),
    last_name: Yup.string().required("Write Last Name"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
    designation: Yup.string().required("Select Designation"),
  });
  const initialValues = {
    first_name: instituteFormik.values.first_name || "",
    last_name: instituteFormik.values.last_name || "",
    phone: instituteFormik.values.phone || "",
    designation: instituteFormik.values.designation || "",
  };
  function copyValues() {
    const fieldMappings = {
      first_name: "first_name",
      last_name: "last_name",
      phone: "phone",
      designation: "designation",
    };
    const updatedValues = { ...instituteFormik.values };

    for (const formikKey in fieldMappings) {
      const instituteFormikKey = fieldMappings[formikKey];
      updatedValues[instituteFormikKey] = formik.values[formikKey];
    }

    instituteFormik.setValues(updatedValues);
  }
  const onSubmit = () => {
    // handleShowDuplicateWarning()
    copyValues();
    handleNextChild();
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit,
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-2">
          <span className=" fs-35px fw-600">Introduce Yourself, Admin</span>
        </div>
        <div className="mb-50 mb-20 d-flex">
          <span className="fs-17px fw-500 color-grey">
            As the account owner, we require some essential details from you for
            a seamless registration process.
          </span>
          <span className="ms-2">
            <Grid item>
              <Tooltip
                title="We're collecting this information as a precaution for any future ownership matters, ensuring your peace of mind."
                placement="bottom"
              >
                <HelpIcon sx={{ color: "#28b8d4" }} />
              </Tooltip>
            </Grid>
          </span>
        </div>

        <div className="row mb-2">
          <div class=" mb-4 col-12 col-lg-6">
            <span className="onboard-form-span mb-2 d-block">First Name</span>
            <input
              value={formik.values.first_name}
              type="text"
              className="form-control rounded-3 box-shadow-0 background-grey border-0"
              placeholder="Enter First Name..."
              onChange={(e) => {
                formik.setFieldValue("first_name", e.target.value);
                instituteFormik.setFieldValue("first_name", e.target.value);
              }}
            />
            {formik.touched.first_name && formik.errors.first_name ? (
              <span className="text-danger">{formik.errors.first_name}</span>
            ) : null}
          </div>
          <div class=" mb-4 col-12 col-lg-6">
            <span className="onboard-form-span mb-2 d-block">Last Name</span>
            <input
              value={formik.values.last_name}
              type="text"
              className="form-control rounded-3 box-shadow-0 background-grey border-0"
              placeholder="Enter Last Name..."
              onChange={(e) => {
                formik.setFieldValue("last_name", e.target.value);
                instituteFormik.setFieldValue("last_name", e.target.value);
              }}
            />
            {formik.touched.last_name && formik.errors.last_name ? (
              <span className="text-danger">{formik.errors.last_name}</span>
            ) : null}
          </div>

          <div class="mb-4 col-12 col-lg-12 ">
            <span className="onboard-form-span d-block mb-2">Phone</span>
            <div class="w-100 w-md-50  ">
              <div className="col-12 col-md-6">
                <input
                  value={formik.values.phone}
                  type="number"
                  class="form-control rounded-3 border-0 background-grey box-shadow-0"
                  placeholder="99*****99"
                  onChange={(e) => {
                    formik.setFieldValue("phone", e.target.value);
                    instituteFormik.setFieldValue("phone", e.target.value);
                  }}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <span className="text-danger">{formik.errors.phone}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div class="mb-4 col-12 col-lg-12">
            <span className="fs-5 fw-500 d-block mb-4 ">
              Tell us how you are associated with this institute{" "}
            </span>
            <div className="">
              <Select
                isSearchable={true}
                styles={customStyles}
                options={showDesList}
                value={showDesList.find(
                  (i) => i.name === formik.values.designation
                )}
                onChange={(selected) => {
                  formik.setFieldValue("designation", selected.name);
                  instituteFormik.setFieldValue("designation", selected.name);
                }}
                getOptionLabel={(option) => {
                  return (
                    <>
                      <span>{option.name}</span>
                    </>
                  );
                }}
              />
              {formik.touched.designation && formik.errors.designation ? (
                <span className="text-danger">{formik.errors.designation}</span>
              ) : null}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between  mt-4">
          <button onClick={handleBack} className="btn-onboard">
            Previous
          </button>
          <button type="submit" className="btn-onboard-fill">
            <span>Next Step</span>
            <img className="ms-2" src="./images/onBoarding/right-icon.png" />
          </button>
        </div>
      </form>
    </>
  );
};

const Mailing = () => {
  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    handleNextChild,
    handleBack,
    setInstituteAddress,
    instituteAddress,
    instituteFormik,
    setInstituteData,
  } = useContext(OnBoardContext);

  const [sameAddress, setSameAddress] = useState(false);

  const validation = Yup.object({
    country: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    district: Yup.string().required("Required"),
    street: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    pinCode: Yup.number().required("Required"),
  });
  const initialValues = {
    country: instituteFormik.values.admin_country || "",
    state: instituteFormik.values.admin_state || "",
    district: instituteFormik.values.admin_district || "",
    street: instituteFormik.values.admin_street || "",
    city: instituteFormik.values.admin_city || "",
    pinCode: instituteFormik.values.admin_pincode || "",
  };

  const onSubmit = () => {
    handleNextChild();
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit,
  });
  useEffect(() => {
    if (sameAddress) {
      formik.setValues({
        country: instituteAddress.country,
        state: instituteAddress.state,
        district: instituteAddress.district,
        street: instituteAddress.street,
        city: instituteAddress.city,
        pinCode: instituteAddress.pinCode,
      });
      instituteFormik.setValues({
        ...instituteFormik.values,
        admin_country: instituteAddress.country,
        admin_state: instituteAddress.state,
        admin_district: instituteAddress.district,
        admin_street: instituteAddress.street,
        admin_city: instituteAddress.city,
        admin_pincode: instituteAddress.pinCode,
      });
    } else {
      formik.setValues({
        country: instituteFormik.values.admin_country || "",
        state: instituteFormik.values.admin_state || "",
        district: instituteFormik.values.admin_district || "",
        street: instituteFormik.values.admin_street || "",
        city: instituteFormik.values.admin_city || "",
        pinCode: instituteFormik.values.admin_pincode || "",
      });
    }
  }, [sameAddress]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-2">
          <span className=" fs-2 fw-semibold">Mailing Address</span>
        </div>
        <div className="mb-50">
          <span className="fs-5 fw-500 color-grey">
            Share your mailing address for effective correspondence.
          </span>
        </div>

        <div className="mb-4">
          <div className="mb-2 d-flex align-items-center">
            <input
              className="form-check-input me-2"
              type="checkbox"
              value={sameAddress}
              onChange={() => setSameAddress(!sameAddress)}
            />
            <span className="fs-19px fw-500" style={{ color: "#3A3A3A" }}>
              Same as Institute address
            </span>
          </div>
          {sameAddress && (
            <span className="fs-15px fw-normal text-primary">
              Expect communications addressed to you, delivered to your
              institute's address.
            </span>
          )}
        </div>

        <div className="row mb-2">
          <div class=" mb-4 col-12 col-lg-6">
            <span className="onboard-form-span mb-2 d-block">Country</span>
            <input
              value={formik.values.country}
              onChange={(e) => {
                formik.setFieldValue("country", e.target.value);
                instituteFormik.setFieldValue("admin_country", e.target.value);
              }}
              type="text"
              className="form-control rounded-3 box-shadow-0 background-grey border-0"
              placeholder="Enter Country..."
            />
            {formik.touched.country && formik.errors.country && (
              <span className="text-danger">{formik.errors.country}</span>
            )}
          </div>
          <div class=" mb-4 col-12 col-lg-6">
            <span className="onboard-form-span mb-2 d-block">State</span>
            {/* <input
                        type="text"
                        value={formik.values.state}
                        onChange={(e)=>{
                            instituteFormik.setFieldValue('admin_state', e.target.value)
                            formik.setFieldValue('state', e.target.value)}}
                        className={"form-control rounded-3 box-shadow-0 background-grey border-0"}
                        placeholder="Enter State..."
                    /> */}
            <Select
              isSearchable={true}
              styles={customStyles}
              options={statesOfIndia}
              value={statesOfIndia.find((i) => i.name === formik.values.state)}
              onChange={(selected) => {
                instituteFormik.setFieldValue("admin_state", selected?.name);
                formik.setFieldValue("state", selected?.name);
              }}
              getOptionLabel={(option) => {
                return (
                  <>
                    <span>{option.name}</span>
                  </>
                );
              }}
            />
            {formik.touched.state && formik.errors.state && (
              <span className="text-danger">{formik.errors.state}</span>
            )}
          </div>
          <div class=" mb-4 col-12 col-lg-6">
            <span className="onboard-form-span mb-2 d-block">District</span>
            <input
              type="text"
              value={formik.values.district}
              onChange={(e) => {
                instituteFormik.setFieldValue("admin_district", e.target.value);
                formik.setFieldValue("district", e.target.value);
              }}
              className="form-control rounded-3 box-shadow-0 background-grey border-0"
              placeholder="Enter District..."
            />
            {formik.touched.district && formik.errors.district && (
              <span className="text-danger">{formik.errors.district}</span>
            )}
          </div>
          <div class=" mb-4 col-12 col-lg-6">
            <span className="onboard-form-span mb-2 d-block">
              Street Address
            </span>
            <input
              type="text"
              value={formik.values.street}
              onChange={(e) => {
                instituteFormik.setFieldValue("admin_street", e.target.value);
                formik.setFieldValue("street", e.target.value);
              }}
              className="form-control rounded-3 box-shadow-0 background-grey border-0"
              placeholder="Enter Street Address..."
            />
            {formik.touched.street && formik.errors.street && (
              <span className="text-danger">{formik.errors.street}</span>
            )}
          </div>

          <div class=" mb-4 col-12 col-lg-6">
            <span className="onboard-form-span mb-2 d-block">City</span>
            <input
              type="text"
              value={formik.values.city}
              onChange={(e) => {
                instituteFormik.setFieldValue("admin_city", e.target.value);
                formik.setFieldValue("city", e.target.value);
              }}
              className="form-control rounded-3 box-shadow-0 background-grey border-0"
              placeholder="Enter City..."
            />
            {formik.touched.city && formik.errors.city && (
              <span className="text-danger">{formik.errors.city}</span>
            )}
          </div>
          <div class=" mb-4 col-12 col-lg-6">
            <span className="onboard-form-span mb-2 d-block">Pin Code</span>
            <input
              type="number"
              value={formik.values.pinCode}
              onChange={(e) => {
                instituteFormik.setFieldValue("admin_pincode", e.target.value);
                formik.setFieldValue("pinCode", e.target.value);
              }}
              className="form-control rounded-3 box-shadow-0 background-grey border-0"
              placeholder="Enter Pin Code..."
            />
            {formik.touched.pinCode && formik.errors.pinCode && (
              <span className="text-danger">{formik.errors.pinCode}</span>
            )}
          </div>
        </div>
        <div className="background-purple rounded px-2 py-2 mb-50 border-purple">
          <span className="me-2">
            {<AssignmentIcon sx={{ color: "#28007d" }} />}
          </span>
          <span className="fs-15px fw-500 color-purple ">
            Please enter a valid address so that we can send you updates.
          </span>
        </div>

        <div className="d-flex justify-content-between  mt-4">
          <button onClick={handleBack} className="btn-onboard">
            Previous
          </button>
          <button type="submit" className="btn-onboard-fill">
            <span>Next Steps</span>
            <img className="ms-2" src="./images/onBoarding/right-icon.png" />
          </button>
        </div>
      </form>
    </>
  );
};
const AddCooordinators = () => {
  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    handleNextChild,
    handleBack,
    selectedDesignation,
    setSelectedDesignation,
    instituteFormik,
    setInstituteData,
  } = useContext(OnBoardContext);
  const { setUser, setToken } = useGlobalContext();

  const [managerModal, setManagerModel] = useState(false);
  const [showDesList, setShowDesList] = useState([]);
  const [formData, setFormData] = useState({});
  const [accountManagers, setAccountManagers] = useState([]);
  const [showGetHelpModal, setGetHelpModal] = useState(false);
  const [onboardingLoader, setonboardingLoader] = useState(false);
  const [accManager, setAccManager] = useState("");

  useEffect(() => {
    setInstituteData("account_manager", accountManagers);
  }, [accountManagers]);
  useEffect(() => {
    setAccountManagers(instituteFormik.values.account_manager);
  }, []);

  const handleAddManager = () => {
    if (accountManagers.length < 11) {
      setAccountManagers([...accountManagers, formData]);
      setFormData([]);
      setManagerModel(false);
    }
  };
  const handleRemoveManager = (i) => {
    const updatedManagers = accountManagers.filter((_, index) => index !== i);
    setAccountManagers(updatedManagers);
  };

  const [typeManager, setTypeManager] = useState("Admin");

  const handleFormData = (name, value) => {
    setFormData({ ...formData, [name]: value });

    if (value === "Admin") {
      setTypeManager("Admin");
    } else if (value === "Moderator") {
      setTypeManager("Moderator");
    } else {
      setTypeManager("Manager");
    }
  };

  useEffect(() => {
    if (selectedDesignation === "School") {
      setShowDesList(designationSchool);
    } else if (selectedDesignation === "Coaching Institute") {
      setShowDesList(designationCoachingInstitute);
    } else if (["College", "University"].includes(selectedDesignation)) {
      setShowDesList(designationUniversityCollege);
    }
  }, [selectedDesignation]);

  const showManagerModal = () => {
    setManagerModel(true);
  };
  const hideManagerModal = () => {
    setManagerModel(false);
    formik.resetForm();
  };

  const validation = Yup.object({
    name: Yup.string().required("Required"),
    designation: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    phone: Yup.string() // Use string for mobile number
      .required("Required")
      .matches(/^[0-9]{10}$/, "Invalid mobile number"),
    typeOfManager: Yup.string().required("Required"),
  });

  const initialValues = {
    name: "",
    designation: "",
    email: "",
    phone: "",
    typeOfManager: "",
  };

  const instituteRegisterDetail = () => {
    const data = {
      email: instituteFormik.values.email,
      institution_name: instituteFormik.values.institution_name,
      type_of_inst: instituteFormik.values.type_of_inst,
      type_of_college: instituteFormik.values.type_of_college,
      education_board: instituteFormik.values.education_board,
      udise_code: instituteFormik.values.udise_code,
      medium_of_education: instituteFormik.values.medium_of_education,
      bio: instituteFormik.values.bio,
      country: instituteFormik.values.country,
      state: instituteFormik.values.state,
      district: instituteFormik.values.district,
      street: instituteFormik.values.street,
      city: instituteFormik.values.city,
      pinCode: instituteFormik.values.pinCode,
      logo: instituteFormik.values.logo,
      website: instituteFormik.values.website,
      facebook_acc: instituteFormik.values.facebook_acc,
      twitter_acc: instituteFormik.values.twitter_acc,
      linkedin_acc: instituteFormik.values.linkedin_acc,
      insta_acc: instituteFormik.values.insta_acc,
      youtube_acc: instituteFormik.values.youtube_acc,
      proof_of_id: instituteFormik.values.proof_of_id,
      proof_of_address: instituteFormik.values.proof_of_address,
      first_name: instituteFormik.values.first_name,
      last_name: instituteFormik.values.last_name,
      phone: instituteFormik.values.phone,
      designation: instituteFormik.values.designation,
      admin_state: instituteFormik.values.admin_state,
      admin_district: instituteFormik.values.admin_district,
      admin_street: instituteFormik.values.admin_street,
      admin_city: instituteFormik.values.admin_city,
      admin_pincode: instituteFormik.values.admin_pincode,
      student_verification: instituteFormik.values.student_verification,
      account_manager: instituteFormik.values.account_manager,
      is_account_verified: false,
    };
    setonboardingLoader(true);
    apiJson
      .put("v2/register/institute/on-board-data", data)
      .then((res) => {
        setUser(res.data.user);
        setToken(res.data.token);
        handleNextChild();
        setonboardingLoader(false);
      })
      .catch((error) => {
        // toast.error('Internal Server Error')
      });
  };

  const onSubmitData = () => {
    instituteRegisterDetail();
  };

  const onSubmit = () => {
    handleAddManager();
    // handleFormData()
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit,
  });

  // Modal Toggle
  const handleShowModal = () => {
    setGetHelpModal(true);
  };
  const handleClose = () => {
    setGetHelpModal(false);
  };

  const accountToggle = (value) => {
    if (value === "Admin") {
      setAccManager(value);
    } else if (value === "Moderator") {
      setAccManager(value);
    } else {
      setAccManager(value);
    }
  };

  return (
    <>
      <div className="mb-2">
        <span className=" fs-2 fw-semibold">
          Add Account Managers <span className="fs-4 color-grey">optional</span>
        </span>
      </div>

      <div className="mb-50">
        <span className="color-grey fs-17px fw-500">
          Add Teaching, Non-Teaching Staff or Students who can be assigned admin
          duties for Yuvamanthan Portal.
        </span>
      </div>

      <div
        onClick={showManagerModal}
        className="card px-2 dashed-border cursor-pointer mb-4"
      >
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="me-2">
              <span>
                <AddCircleIcon color="success" style={{ fontSize: "35px" }} />
              </span>
            </div>
            <div className="ms-2">
              <span className="fs-5 fw-500">Add new account manager</span>
            </div>
          </div>
        </div>
      </div>

      <div className="background-purple rounded px-2 py-2 mb-50 border-purple">
        <span className="fs-6 fw-semibold color-purple ">
          {" "}
          <span className="me-2">
            {<AssignmentIcon sx={{ color: "#28007d" }} />}
          </span>
          You can add up to 10 account managers at a time.
        </span>
      </div>

      <div className="d-flex flex-column mb-50">
        <div className="d-flex justify-content-between mb-4">
          <span className="fs-4 fw-500 d-block">Account Management</span>
          <span className="fw-200 d-block ">
            <span
              style={{ cursor: "pointer", fontWeight: "600", color: "#7e27ec" }}
              onClick={() => handleShowModal()}
            >
              Who is an Account Manager?
            </span>
          </span>
          <div className="d-none">
            <span className="color-purple">
              <KeyboardArrowLeftIcon style={{ fontSize: "35px" }} />
            </span>
            <span className="color-purple">
              <KeyboardArrowRightIcon style={{ fontSize: "35px" }} />
            </span>
          </div>
        </div>
        <div
          style={{
            overflowX: "hidden",
            height: "189px",
          }}
          className="thin-scroll"
        >
          {accountManagers.length === 0 ? (
            <div className="text-center mt-4">
              <span className="color-grey fs-19px fw-400">
                No Account Manager Selected
              </span>
            </div>
          ) : (
            accountManagers?.map((c, index) => {
              return (
                <>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <span className="fs-5 fw-500">{c.name}</span>
                    <div className="d-flex align-items-center">
                      <div className="me-3">
                        <div className="p-2">
                          <span className="color-purple fs-17px fw-semibold text-center">
                            {c.typeOfManager}
                          </span>
                        </div>
                      </div>
                      <div
                        onClick={() => handleRemoveManager(index)}
                        className="d-flex align-items-center justify-content-center cursor-pointer "
                        style={{
                          background: "#FFD6D6",
                          width: "37px",
                          height: "37px",
                          borderRadius: "7px",
                        }}
                      >
                        <span>
                          {" "}
                          <RemoveCircleTwoToneIcon color="error" />
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          )}
        </div>
      </div>

      <div className="d-flex justify-content-between  mt-4">
        <button onClick={handleBack} className="btn-onboard">
          Previous
        </button>
        {onboardingLoader ? (
          <CircularProgress />
        ) : (
          <button onClick={onSubmitData} className="btn-onboard-fill">
            <span>Next Step</span>
            <img className="ms-2" src="./images/onBoarding/right-icon.png" />
          </button>
        )}
      </div>
      <Modal
        show={managerModal}
        className="left-rounded-modal"
        onHide={hideManagerModal}
        size="lg"
        centered
      >
        <div className="modal-content">
          <Modal.Body className="p-5">
            <form onSubmit={formik.handleSubmit}>
              <div>
                <div className="d-flex justify-content-between mb-4 ps-2">
                  <span className="fs-2 fw-600 color-black ">
                    New Account Manager
                  </span>
                  <span
                    className="d-block d-md-none"
                    onClick={() => setManagerModel(false)}
                  >
                    <CloseTwoToneIcon />
                  </span>
                </div>
                <div>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                      <span className="fs-19px fw-500 color-black">
                        Full Name
                      </span>
                      <input
                        type="text"
                        value={formik.values.name}
                        onChange={(e) => {
                          handleFormData("name", e.target.value);
                          formik.setFieldValue("name", e.target.value);
                        }}
                        placeholder="Name here..."
                        className="form-control border-0 box-shadow-0 background-grey"
                      />
                      {formik.errors.name && formik.touched.name && (
                        <span className="text-danger">
                          {formik.errors.name}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <span className="fs-19px fw-500 color-black">
                        Designation
                      </span>
                      <Select
                        styles={customStyles}
                        options={showDesList}
                        // value={selectedTypeOfSchool}
                        defaultValue={formik.values.designation}
                        onChange={(e) => {
                          handleFormData("designation", e.name);
                          formik.setFieldValue("designation", e.name);
                        }}
                        getOptionLabel={(option) => {
                          return (
                            <>
                              <div>
                                <span>{option.name}</span>
                              </div>
                            </>
                          );
                        }}
                      />
                      {formik.errors.designation &&
                        formik.touched.designation && (
                          <span className="text-danger">
                            {formik.errors.designation}
                          </span>
                        )}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <span className="fs-19px fw-500 color-black">
                        Email ID
                      </span>
                      <input
                        value={formik.values.email}
                        onChange={(e) => {
                          handleFormData("email", e.target.value);
                          formik.setFieldValue("email", e.target.value);
                        }}
                        placeholder="abc@abc.com"
                        type="email"
                        className="form-control border-0 box-shadow-0 background-grey"
                      />
                      {formik.errors.email && formik.touched.email && (
                        <span className="text-danger">
                          {formik.errors.email}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <span className="fs-19px fw-500 color-black">Phone</span>
                      <input
                        value={formik.values.phone}
                        onChange={(e) => {
                          handleFormData("phone", e.target.value);
                          formik.setFieldValue("phone", e.target.value);
                        }}
                        placeholder="99******99"
                        type="number"
                        className="form-control border-0 box-shadow-0 background-grey"
                      />
                      {formik.touched.phone && formik.errors.phone && (
                        <span className="text-danger">
                          {formik.errors.phone}
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <span className="fs-19px fw-500 color-black">
                        Type of Manager
                      </span>
                      <Select
                        styles={customStyles}
                        options={designations}
                        defaultValue={formik.values.typeOfManager}
                        onChange={(selected) => {
                          handleFormData("typeOfManager", selected.des);
                          formik.setFieldValue("typeOfManager", selected.des);
                        }}
                        getOptionLabel={(option) => {
                          return (
                            <>
                              <div>
                                <span>{option.des}</span>
                              </div>
                            </>
                          );
                        }}
                      />
                      {formik.touched.typeOfManager &&
                        formik.errors.typeOfManager && (
                          <span className="text-danger">
                            {formik.errors.typeOfManager}
                          </span>
                        )}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <div className="mb-4"></div>
                      <button
                        style={{
                          width: "249px",
                          height: "50px",
                        }}
                        type="submit"
                        className="col-11 col-lg-5 text-center color-white fs-19px fw-500  background-purple-button rounded-4 d-block m-auto d-md-inline-block"
                      >
                        Add {typeManager}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div className="background-purple rounded px-2 py-2 mb-40 border-purple mt-3 col-12">
              {typeManager === "Admin" ? (
                <>
                  <span className="fs-6 fw-semibold color-purple ">
                    <span className="me-2">
                      {<img src="./images/onBoarding/note.png" />}
                    </span>
                    An Account Admin can register for events and perform all
                    admin duties. Please
                    <br />
                    <span style={{ marginLeft: "33px" }}>
                      only add competent teachers as admins.
                    </span>
                  </span>
                </>
              ) : typeManager === "Moderator" ? (
                <>
                  <span className="fs-6 fw-semibold color-purple ">
                    {" "}
                    <span className="me-2">
                      {<img src="./images/onBoarding/note.png" />}
                    </span>
                    A Moderator can verify students who wish to access the
                    portal and perform
                    <br />
                    <span className="" style={{ marginLeft: "33px" }}>
                      admin duties like posting, announcements and managing
                      events.
                    </span>
                    {/* <br/> */}
                    {/* <span style={{marginLeft: "33px"}}>managing events.</span> */}
                  </span>
                </>
              ) : (
                <>
                  <span className="fs-6 fw-semibold color-purple ">
                    <span className="me-2">
                      {<img src="./images/onBoarding/note.png" />}
                    </span>
                    Add students as Account Managers, they will be allowed to
                    post on behalf of the <br />
                    <span className="" style={{ marginLeft: "33px" }}>
                      {" "}
                      Institute, and make announcements.
                    </span>
                    {/* <br/> */}
                    {/* <span style={{marginLeft: "33px"}}>managing events.</span> */}
                  </span>
                </>
              )}
            </div>
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        size="lg"
        show={showGetHelpModal}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {/* <Modal.Header closeButton>
       <Modal.Title id="example-modal-sizes-title-lg">
         Help
       </Modal.Title>
     </Modal.Header> */}
        <Modal.Body>
          <div className="p-2">
            <div>
              <span className="fs-4 fw-500">
                You can add 3 types of Account Managers, namely
              </span>
            </div>
            <div
              className="color-purple"
              style={{
                fontSize: "18px",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              <div onClick={() => accountToggle("Admin")}>
                <span>Admin</span>
              </div>
              <div onClick={() => accountToggle("Moderator")}>
                <span>Moderator</span>
              </div>
              <div onClick={() => accountToggle("Manager")}>
                <span>Manager</span>
              </div>
            </div>

            {
              // Admin
              accManager === "Admin" ? (
                <div className="mt-4">
                  <div>
                    <span className="fs-4 fw-500">Admin</span>
                  </div>
                  <div className="mt " style={{ fontSize: "18px" }}>
                    <span>
                      An Account Admin can register for events and perform all
                      admin duties. Please only add competent teachers as
                      admins.
                    </span>
                  </div>
                  <div>
                    <div className="mt-4">
                      <span className="fw-600 fs-5">
                        Tasks which an Admin can perform:
                      </span>
                    </div>
                    <div style={{ fontSize: "17px", fontWeight: "400" }}>
                      <div>
                        <span className="">
                          - Verify students, delete or moderate students,
                          teachers and other users
                        </span>
                      </div>
                      <div>
                        <span>- Register for an event</span>
                      </div>
                      <div>
                        <span className="">
                          - Select and manage students to participate in the
                          events
                        </span>
                      </div>
                      <div>
                        <span className="">
                          - Make posts and announcements on behalf of the
                          institute
                        </span>
                      </div>
                      <div>
                        <span className="">
                          - Access Discussion Boards as an Institute and create
                          meetings
                        </span>
                      </div>

                      <div>
                        <span className="">
                          - Add new Account moderators and managers
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : // Moderator
              accManager === "Moderator" ? (
                <div className="mt-4">
                  <div>
                    <span className="fs-4 fw-500">Moderator</span>
                  </div>
                  <div className="" style={{ fontSize: "18px" }}>
                    <span>
                      A Moderator can verify students who wish to access the
                      portal and perform admin duties like posting,
                      announcements and managing events.
                    </span>
                  </div>
                  <div>
                    <div className="mt-4">
                      <span className="fw-600 fs-5">
                        Tasks which an Moderator can perform:
                      </span>
                    </div>
                    <div style={{ fontSize: "17px", fontWeight: "400" }}>
                      <div>
                        <span className="">
                          - Verify students or moderate students, teachers and
                          other users
                        </span>
                      </div>
                      <div>
                        <span>
                          - Make posts and announcements on behalf of the
                          institute
                        </span>
                      </div>
                      <div>
                        <span className="">
                          - Access Discussion Boards as an Institute and create
                          meetings
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                // Manager
                <div className="mt-4">
                  <div>
                    <span className="fs-4 fw-500">Manager</span>
                  </div>
                  <div className="" style={{ fontSize: "18px" }}>
                    <span>
                      Add students as Account Managers, they will be allowed to
                      post on behalf of the Institute, and make announcements.
                    </span>
                  </div>
                  <div>
                    <div className="mt-4">
                      <span className="fw-600 fs-5">
                        Tasks which an Manager can perform:
                      </span>
                    </div>
                    <div style={{ fontSize: "17px", fontWeight: "400" }}>
                      <div>
                        <span className="">
                          - Make posts and announcements on behalf of the
                          institute
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Preferences = () => {
  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    handleNextChild,
    handleBack,
    instituteFormik,
    setInstituteData,
  } = useContext(OnBoardContext);
  const { setUser, setToken } = useGlobalContext();
  const [select, setselect] = useState("1");

  useEffect(() => {
    setInstituteData("student_verification", select === "1" ? false : true);
  }, [select]);
  useEffect(() => {
    setInstituteData("student_verification", select === "1" ? false : true);
  }, []);

  // const instituteRegisterDetail = ()=>{
  //     const data = {
  //         email:instituteFormik.values.email,
  //         institution_name:instituteFormik.values.institution_name,
  //         type_of_inst:instituteFormik.values.type_of_inst,
  //         type_of_college:instituteFormik.values.type_of_college,
  //         education_board :instituteFormik.values.education_board,
  //         udise_code:instituteFormik.values.udise_code,
  //         medium_of_education:instituteFormik.values.medium_of_education,
  //         bio:instituteFormik.values.bio,
  //         country:instituteFormik.values.country,
  //         state:instituteFormik.values.state,
  //         street:instituteFormik.values.street,
  //         city:instituteFormik.values.city,
  //         pinCode:instituteFormik.values.pinCode,
  //         logo:instituteFormik.values.logo,
  //         website:instituteFormik.values.website,
  //         facebook_acc:instituteFormik.values.facebook_acc,
  //         twitter_acc:instituteFormik.values.twitter_acc,
  //         linkedin_acc:instituteFormik.values.linkedin_acc,
  //         insta_acc:instituteFormik.values.insta_acc,
  //         youtube_acc:instituteFormik.values.youtube_acc,
  //         proof_of_id:instituteFormik.values.proof_of_id,
  //         proof_of_address:instituteFormik.values.proof_of_address,
  //         first_name:instituteFormik.values.first_name,
  //         last_name:instituteFormik.values.last_name,
  //         phone:instituteFormik.values.phone,
  //         designation:instituteFormik.values.designation,
  //         admin_state:instituteFormik.values.admin_state,
  //         admin_street:instituteFormik.values.admin_street,
  //         admin_city:instituteFormik.values.admin_city,
  //         admin_pincode:instituteFormik.values.admin_pincode,
  //         student_verification:instituteFormik.values.student_verification,
  //         account_manager:instituteFormik.values.account_manager,
  //         is_account_verified:false,
  //     }
  //     apiJson.put('v2/register/institute/on-board-data',data)
  //     .then((res)=>{
  //         setUser(res.data.user)
  //         setToken(res.data.token)
  //     }).catch((error)=>{
  //         toast.error('Internal Server Error')
  //     })
  // }

  const onSubmit = () => {
    // instituteRegisterDetail()
    handleNextChild();
  };

  return (
    <>
      <div className="mb-2">
        <span className=" fs-2 fw-semibold">Preferences</span>
      </div>
      <div className="mb-50 d-flex align-items-center">
        <span className="fs-5 fw-500 color-grey">Joining Preferences</span>
        <Grid item>
          <Tooltip
            title="Make verification of all students and teachers by account managers (admin or moderator) mandatory for joining."
            placement="bottom"
          >
            <HelpIcon sx={{ color: "#28b8d4" }} />
          </Tooltip>
        </Grid>
      </div>

      <div>
        <div
          className={` card cursor-pointer d-flex border-3  p-2  rounded-3 mb-4 ${
            select === "1" ? "border-purple-dark" : "border-transparent"
          }`}
        >
          <div
            onClick={() => {
              setselect("1");
            }}
            className="card-body d-flex align-items-center"
          >
            <span>
              <input
                onChange={() => setselect("1")}
                class="form-check-input me-4"
                type="radio"
                name="preference"
                value="1"
                id="1"
                checked={select === "1"}
              />
            </span>
            <span className="fw-500 fs-5">
              Allow, students and teachers to join without verification
            </span>
          </div>
        </div>

        <div
          onClick={() => setselect("2")}
          className={`cursor-pointer card d-flex border-3 p-2  rounded-3 ${
            select === "2" ? "border-purple-dark" : "border-transparent"
          }`}
        >
          <div className="card-body d-flex align-items-center">
            <span>
              <input
                onChange={() => setselect("2")}
                class="form-check-input me-4"
                type="radio"
                name="preference"
                value="2"
                id="2"
                checked={select === "2"}
              />
            </span>
            <span className="fw-500 fs-5">
              Make verification of all students and teachers by account managers
              (admin or moderator) mandatory for joining.
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between  mt-4">
        <button onClick={handleBack} className="btn-onboard">
          Previous
        </button>
        <button onClick={onSubmit} className="btn-onboard-fill">
          <span>Next Step</span>
          <img className="ms-2" src="./images/onBoarding/right-icon.png" />
        </button>
      </div>
    </>
  );
};
const CreatePassword = () => {
  const { setUser } = useGlobalContext();
  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    handleNextChild,
    handleBack,
    instituteFormik,
    setInstituteData,
  } = useContext(OnBoardContext);
  const [select, setselect] = useState("1");

  const validation = Yup.object().shape({
    newPassword: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])(?=.{6,})/,
        "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 symbol, 1 number, and be at least 6 characters"
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const initialValues = {
    newPassword: instituteFormik.values.password || "",
    confirmPassword: instituteFormik.values.password || "",
  };
  const instituteRegisterDetail = () => {
    const data = {
      institution_name: instituteFormik.values.institution_name,
      email: instituteFormik.values.email,
      password: instituteFormik.values.password,
      type_of_inst: instituteFormik.values.type_of_inst,
      type_of_college: instituteFormik.values.type_of_college,
      education_board: instituteFormik.values.education_board,
      udise_code: instituteFormik.values.udise_code,
      medium_of_education: instituteFormik.values.medium_of_education,
      bio: instituteFormik.values.bio,
      state: instituteFormik.values.state,
      street: instituteFormik.values.street,
      city: instituteFormik.values.city,
      pinCode: instituteFormik.values.pinCode,
      logo: instituteFormik.values.logo,
      website: instituteFormik.values.website,
      facebook_acc: instituteFormik.values.facebook_acc,
      twitter_acc: instituteFormik.values.twitter_acc,
      linkedin_acc: instituteFormik.values.linkedin_acc,
      insta_acc: instituteFormik.values.insta_acc,
      youtube_acc: instituteFormik.values.youtube_acc,
      proof_of_id: instituteFormik.values.proof_of_id,
      proof_of_address: instituteFormik.values.proof_of_address,
      first_name: instituteFormik.values.first_name,
      last_name: instituteFormik.values.last_name,
      phone: instituteFormik.values.phone,
      designation: instituteFormik.values.designation,
      admin_state: instituteFormik.values.admin_state,
      admin_street: instituteFormik.values.admin_street,
      admin_city: instituteFormik.values.admin_city,
      admin_pincode: instituteFormik.values.admin_pincode,
      student_verification: instituteFormik.values.student_verification,
      is_account_verified: false,
    };
    apiJson
      .put("v2/register/institute/on-board-data", data)
      .then((res) => {
        setUser(res.data.user);
      })
      .catch((error) => {
        // toast.error('Internal Server Error')
      });
  };

  const onSubmit = () => {
    copyValues();
    instituteRegisterDetail();
    handleNextChild();
  };

  function copyValues() {
    const fieldMappings = {
      confirmPassword: "password",
    };
    const updatedValues = { ...instituteFormik.values };

    for (const formikKey in fieldMappings) {
      const instituteFormikKey = fieldMappings[formikKey];
      updatedValues[instituteFormikKey] = formik.values[formikKey];
    }

    instituteFormik.setValues(updatedValues);
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit,
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-2">
          <span className=" fs-2 fw-600">Create Password</span>
        </div>
        <div className="mb-50">
          <span className="fs-5 fw-500 color-grey">
            Create a strong password for your yuvamanthan account
          </span>
        </div>

        <div className="d-flex flex-column mb-4">
          <div className="d-flex flex-column col-12 col-md-6 mb-4">
            <span className="fs-5 fw-500 mb-3 d-block">New Password</span>
            <input
              onChange={(e) =>
                formik.setFieldValue("newPassword", e.target.value)
              }
              placeholder="********"
              type="password"
              className="form-control fs-26px letter-spacing-5px rounded-3 background-grey box-shadow-0 border-0"
            />
            {formik.errors.newPassword && formik.touched.newPassword && (
              <span className="text-danger">{formik.errors.newPassword}</span>
            )}
          </div>
          <div className="d-flex flex-column col-12 col-md-6">
            <span className="fs-5 fw-500 mb-3 d-block">Confirm Password</span>
            <input
              onChange={(e) => {
                setInstituteData("password", e.target.value);
                formik.setFieldValue("confirmPassword", e.target.value);
              }}
              placeholder="********"
              type="password"
              className="form-control fs-26px letter-spacing-5px rounded-3 background-grey box-shadow-0 border-0"
            />
            {formik.errors.confirmPassword &&
              formik.touched.confirmPassword && (
                <span className="text-danger">
                  {formik.errors.confirmPassword}
                </span>
              )}
          </div>
        </div>

        <div className=" d-flex background-purple p-3 rounded-3 border-purple">
          <span>
            {" "}
            <img className="me-2" src="./images/onBoarding/note.png" />
          </span>
          <span className="color-purple fs-17px fw-500">
            Your password should contain, atleast 1 capital, 1 small, 1 symbol
            and 1 number.{" "}
          </span>
        </div>

        <div className="d-flex justify-content-between  mt-4">
          <button onClick={handleBack} className="btn-onboard">
            Previous
          </button>
          <button type="submit" className="btn-onboard-fill">
            <span>Next Step</span>
            <img className="ms-2" src="./images/onBoarding/right-icon.png" />
          </button>
        </div>
      </form>
    </>
  );
};

const Done = () => {
  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    handleNextChild,
    handleBack,
  } = useContext(OnBoardContext);
  const [select, setselect] = useState("1");
  return (
    <>
      <div className="mb-2">
        <span className=" fs-2 fw-semibold">All Done</span>
      </div>
      <div className="mb-50">
        <span className="fs-5 fw-500 color-grey">Everything looks great</span>
      </div>

      <div className="mb-50">
        <span className="fw-500 fs-26px">
          Thankyou for completing a our onboarding process.
        </span>
      </div>

      <div className="mb-50">
        <span className="fs-20px fw-400">
          Your Yuvamanthan institutional account awaits verification by our
          dedicated team. An executive will be reaching out to you shortly via
          phone to facilitate this process. It’s hardly takes 24 hours.
        </span>
      </div>

      <div className="background-purple border-purple rounded-3 text-center p-4 mb-4">
        <span className="color-purple fw-500  fs-26px">
          Your account is under review
        </span>
      </div>

      <div className="d-flex justify-content-between  mt-4">
        <button onClick={handleBack} className="btn-onboard">
          Previous
        </button>
        <button onClick={handleNextChild} className="btn-onboard-fill">
          <span>Next Step</span>
          <img className="ms-2" src="./images/onBoarding/right-icon.png" />
        </button>
      </div>
    </>
  );
};

const UnderReview = () => {
  const { userData } = useGlobalContext();
  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    handleNextChild,
    handleBack,
    instituteFormik,
  } = useContext(OnBoardContext);
  const [select, setselect] = useState("1");
  // Modal Toggle
  const [showModal, setShowModal] = useState(false);
  const [documentModal, setDocumentModal] = useState(false);
  const [message, setMessage] = useState("");
  const [updatedetailModal, setUpdateDetailsModal] = useState({
    profile: false,
    document: false,
  });
  const [showProof, setShowProof] = useState([
    { src: "", isPdf: false, type: "" },
  ]);
  const [ShowProofAddress, setShowProofAddress] = useState([
    { src: "", isPdf: false, type: "" },
  ]);

  const [dragging, setDragging] = useState(false);
  const [imageSizeError, setImageSizeError] = useState(false);
  const [insDetails, setInsDetails] = useState({});
  const [imageLoading, setImageLoading] = useState(false);
  const [image, setImage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFiles2, setSelectedFiles2] = useState([]);
  const [loader, setLoader] = useState(false);
  const [modalText, setModalText] = useState("");

  //=========== Handle Open and  close document Details modal ======= \\
  const openDocumentModal = () => {
    setDocumentModal(true);
  };
  const closeDocumentModal = () => {
    setDocumentModal(false);
  };

  //====== Institute Details api call for updating Data ============ \\
  const getInsDetail = async () => {
    try {
      const res = await apiJson.get(
        `/v2/register/getInsDetail/${userData?.id}`
      );
      if (res.status === 200) {
        setInsDetails(res?.data?.getInstituteData);
      }
    } catch (error) {
      console.log("error in gettin details", error);
    }
  };
  useEffect(() => {
    if (userData?.id) {
      getInsDetail();
    }
  }, []);

  useEffect(() => {
    formik?.setFieldValue("logo", insDetails?.logo);
    Documentformik?.setFieldValue("proof_of_id", insDetails?.proof_of_id);
    Documentformik?.setFieldValue(
      "proof_of_address",
      insDetails?.proof_of_address
    );
  }, [insDetails]);

  const getImageLink = () => {
    if (image) {
      apiAuth
        .post("v2/register/uploadInstituteLogo", { img: image })
        .then((res) => {
          formik.setFieldValue("logo", res.data.result);
          toast.dismiss();
        })
        .catch((error) => {
          toast.dismiss();
          // toast.error('Server Busy! Please Wait')
        });
    }
  };
  useEffect(() => {
    if (image?.size > 1 * 1024 * 1024) {
      setImageSizeError(true);
      formik.setFieldValue("logo", null);
      return;
    }
    if (image) {
      setImageSizeError(false);
      getImageLink();
    }
  }, [image]);

  const documentSubmit = async (values, { resetForm }) => {
    try {
      const updateProfile = await apiJson.put(
        `v2/register/updateData/${userData?.id}`,
        {
          logo: values?.logo,
          proof_of_id: values?.proof_of_id,
          proof_of_address: values?.proof_of_address,
        }
      );
      if (updateProfile?.status === 200) {
        toast.success(updateProfile?.data?.message);
        resetForm();
        handleOpenCloseProfileModal();
      }
    } catch (error) {
      handleOpenCloseProfileModal();
      toast.dismiss();
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Internal Server Error."
      );
    }
    if (modalText === "profile") {
      setUpdateDetailsModal({ ...updatedetailModal, profile: false });
    } else if (modalText === "document") {
      setUpdateDetailsModal({ ...updatedetailModal, document: false });
    }
  };
  const validationSchema = Yup.object().shape({
    image: Yup.mixed().when("logo", {
      is: (logo) => !logo,
      then: Yup.mixed()
        .required("Image is required")
        .test("fileSize", "Image file is too large", (value) => {
          return value && value.size <= 5000000; // 5 MB limit
        })
        // .test("fileType", "Unsupported image file type", (value) => {
        //   return (
        //     value &&
        //     ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
        //   );
        // }),
    }),
    //   proof_of_id_file: Yup.mixed().required("proof_of_id_file is Required")
    //   .when('proof_of_id', {
    //       is: (proof_of_id) => !proof_of_id,
    //       then: Yup.mixed()
    //         .required('Proof of Identitiy is required')
    //         .test('fileType', 'Unsupported file', (value) => {
    //           return value && ['image/jpeg', 'image/png', 'image/jpg','application/pdf'].includes(value?.type);
    //         }),
    //     }),
    //     proof_of_address_file: Yup.mixed().required("proof_of_address_file is Required")
    //   .when('proof_of_address', {
    //       is: (proof_of_address) => !proof_of_address,
    //       then: Yup.mixed()
    //         .required('Proof of Address is required')
    //         .test('fileType', 'Unsupported file', (value) => {
    //           return value && ['image/jpeg', 'image/png', 'image/jpg','application/pdf'].includes(value?.type);
    //         }),
    //     }),
  });
  const formik = useFormik({
    initialValues: {
      logo: insDetails?.logo || "",
    },
    validationSchema: validationSchema,
    onSubmit: documentSubmit,
  });

  //=========== Document Update formik   ================\\
  const documentValidationSchema = Yup.object().shape({
    proof_of_id: Yup.mixed(),
    proof_of_address: Yup.mixed(),
  });
  const Documentformik = useFormik({
    initialValues: {
      proof_of_id: insDetails?.proof_of_id || "",
      proof_of_address: insDetails?.proof_of_address || "",
    },
    validationSchema: documentValidationSchema,
    onSubmit: documentSubmit,
  });

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  //=========Open and close  Edit  profile modal ========\\
  const handleOpenCloseProfileModal = (modal) => {
    setModalText(modal);
    setUpdateDetailsModal({
      profile: modal === "profile" ? !updatedetailModal.profile : false,
      document: modal === "document" ? !updatedetailModal.document : false,
    });
  };

  const postMessage = () => {
    if (message) {
      apiJson
        .post("api/v2/institute/addMessageToAdmin", {
          message,
          email: instituteFormik.values.email,
        })
        .then((res) => {
          handleClose();
          Swal.fire({
            title: "Good job!",
            text: "Message Sent Successfully !",
            icon: "success",
          });
          setMessage("");
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  ///============  Drag and Drop function call here == = ==============\\
  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles(files);
    Documentformik.setFieldValue("proof_of_id", files);
  };
  const handleDrop2 = (e) => {
    e.preventDefault();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles2(files);
    Documentformik.setFieldValue("proof_of_address", files);
  };
  const handleFileInputChange = (e) => {
    const fileTypes = ["application/pdf", "image/png", "image/jpeg"];
    const files = Array.from(e.target.files);
    if (fileTypes.includes(files[0]?.type)) {
      setSelectedFiles(files);
    } else {
      toast.dismiss();
    }
  };

  const handleFileInputChange2 = (e) => {
    const fileTypes = ["application/pdf", "image/png", "image/jpeg"];
    const files = Array.from(e.target.files);

    if (fileTypes.includes(files[0]?.type)) {
      setSelectedFiles2(files);
    } else {
      toast.dismiss();
    }
  };
  const handleFileInputClick = (e, inputId) => {
    const fileInput = document.getElementById(inputId);
    if (fileInput) fileInput.click();
  };

  const [imageSizeError1, setImageSizeError1] = useState(false);
  const [imageSizeError2, setImageSizeError2] = useState(false);

  const get_file1_link = () => {
    if (selectedFiles) {
      setLoader(true);

      apiAuth
        .post("v2/register/uploadInstituteLogo", { img: selectedFiles[0] })
        .then((res) => {
          Documentformik.setFieldValue("proof_of_id", res.data.result);
          setLoader(false);
        })
        .catch((error) => {
          toast.dismiss();
          // toast.error('Internal server Error')
          setLoader(false);
        });
    }
  };

  const get_file2_link = () => {
    if (selectedFiles2) {
      setLoader(true);

      apiAuth
        .post("v2/register/uploadInstituteLogo", { img: selectedFiles2[0] })
        .then((res) => {
          Documentformik.setFieldValue("proof_of_address", res.data.result);
          setLoader(false);
        })
        .catch((error) => {
          toast.dismiss();
          // toast.error('Internal server Error')
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    if (selectedFiles[0]?.size > 1 * 1024 * 1024) {
      setImageSizeError1(true);
      Documentformik.setFieldValue("proof_of_id", null);
      return;
    }
    if (selectedFiles[0]) {
      setImageSizeError1(false);
      get_file1_link();
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (selectedFiles2[0]?.size > 1 * 1024 * 1024) {
      setImageSizeError2(true);
      Documentformik.setFieldValue("proof_of_address", null);
      return;
    }
    if (selectedFiles2[0]) {
      setImageSizeError2(false);
      get_file2_link();
    }
  }, [selectedFiles2]);

  return (
    <>
      <div className="row">
        <div className="col-1"></div>
        <div className="d-flex flex-column align-items-center col-12 col-lg-10  text-center">
          <div className="mb-2">
            <span className=" fs-2 fw-semibold">Account Under Review</span>
          </div>
          <div className="mb-2">
            <img src={"./images/social-icons/Hourglass.svg"} />
          </div>
          <div className="mb-50">
            <span
              className="fw-400 fs-17px "
              style={{
                color: "#663700",
              }}
            >
              Your Yuvamanthan institutional account awaits verification by our
              dedicated team. An executive will be reaching out to you shortly
              via phone to facilitate this process.
            </span>
          </div>
          <div
            className=" d-flex rounded-3 p-4 "
            style={{ background: "#FFFBEB" }}
          >
            <span className="me-2">
              <img className="me-2" src="./images/onBoarding/note.png" />
            </span>
            <span
              style={{ color: "#905F00" }}
              className="fw-500 fs-17px d-block "
            >
              To accelerate this verification, don't hesitate to send us a{" "}
              <span
                className="cursor-pointer"
                onClick={handleShowModal}
                style={{ color: "#510090" }}
              >
                message
              </span>{" "}
              or give us a{" "}
              <a
                href="tel:9560771911"
                className="cursor-pointer"
                style={{ color: "#510090" }}
              >
                call
              </a>
              . Thank you for your cooperation
            </span>
          </div>
        </div>
        <div className="col-1"></div>
      </div>
      <div className="mt-3">
        <span className="fs-5 fw-semibold">
          You can update here your profile and document.
        </span>
        <div className="mt-4 d-flex ">
          <Button
            size="md"
            variant="soft"
            color="primary"
            className="me-3"
            onClick={() => {
              handleOpenCloseProfileModal("profile");
            }}
          >
            Edit Profile
          </Button>
          <Button
            size="md"
            variant="soft"
            color="primary"
            onClick={() => {
              handleOpenCloseProfileModal("document");
            }}
          >
            Edit Documents
          </Button>
        </div>
      </div>

      <div className="d-flex justify-content-between  mt-4">
        <button onClick={handleBack} className="btn-onboard-disabled" disabled>
          Previous
        </button>
        <button
          onClick={handleNextChild}
          className="btn-onboard-fill-disabled "
          disabled
        >
          <span>Next Step</span>
          <img className="ms-2" src="./images/onBoarding/right-icon.png" />
        </button>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className=" fw-bold color-black"
            style={{ fontSize: "25px", fontFamily: "Poppins" }}
          >
            Request Approval
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="">
          <div>
            <div className="mt-2">
              <span
                className="fw-500"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  color: "black",
                }}
              >
                Message
              </span>
            </div>

            <div
              className="mt-2"
              style={{ fontStyle: "italic", fontFamily: "Poppins" }}
            >
              <textarea
                type="text"
                className="form-control p-3 rounded fw-500 box-shadow-0 resize-none border-0 color-black"
                name=""
                id=""
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Eg. Hi, Please complete my verification"
                style={{
                  color: "#B6B6B6",
                  background: "#EDEDED",
                  fontSize: "16px",
                }}
              ></textarea>
            </div>

            <div className="mt-3 mb-3 d-flex justify-content-between align-items-center text-center">
              <div>
                <button
                  onClick={handleClose}
                  className="rounded"
                  style={{
                    height: "36px",
                    width: "122px",
                    background: "#E7E7E7",
                    color: "#747474",
                  }}
                >
                  Discard
                </button>
              </div>
              <div>
                <button
                  onClick={postMessage}
                  className="rounded"
                  style={{
                    height: "36px",
                    width: "99px",
                    background: "#C08EFF",
                    color: "#2B0062",
                  }}
                >
                  Send
                </button>
              </div>
            </div>

            <div></div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={updatedetailModal?.profile}
        onHide={() => {
          handleOpenCloseProfileModal("profile");
        }}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className=" fw-bold color-black"
            style={{ fontSize: "25px", fontFamily: "Poppins" }}
          >
            Edit Profile Image
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="">
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <div className="d-flex mb-4">
                  <div
                    className="me-4  "
                    style={{ height: "100px", width: "100px" }}
                  >
                    {imageLoading ? (
                      <>
                        <div
                          className="me-4 d-flex justify-content-center align-items-center"
                          style={{ height: "100px", width: "100px" }}
                        >
                          <div class="spinner-border" role="status"></div>
                        </div>
                      </>
                    ) : (
                      <div
                        className="me-4  "
                        style={{ height: "100px", width: "100px" }}
                      >
                        {formik?.values?.logo ? (
                          <img
                            className="w-100 h-100 rounded-3"
                            src={formik?.values?.logo}
                          />
                        ) : (
                          <AccountBalanceTwoToneIcon
                            style={{ color: "#80808036" }}
                            className="w-100 h-100 rounded-3"
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="">
                    <div className="mb-4">
                      <span className="fs-17px fw-500">
                        Upload your Institute's Logo
                      </span>
                    </div>

                    <div
                      className="p-2 rounded-3 text-center w-auto border-1 cursor-pointer"
                      style={{
                        background: "#F0EBFF",
                      }}
                    >
                      <label
                        htmlFor="img"
                        className=" rounded-3 text-center w-auto border-1 cursor-pointer"
                        style={{ background: "#F0EBFF" }}
                      >
                        <CloudUploadIcon
                          sx={{
                            color: "#6909ff",
                            width: "30px",
                            height: "30px",
                          }}
                        />
                        <span className="fs-15px color-purple fw-500 text-center ms-2  mb-4">
                          Upload
                        </span>
                      </label>
                      <input
                        type="file"
                        id="img"
                        style={{ display: "none" }}
                        accept=".png, .jpg, .jpeg"
                        name="files[]"
                        onChange={(e) => {
                          if (e.target.files.length) {
                            setImage(e.target.files[0]);
                          }
                        }}
                      />
                    </div>
                    {/* <span className="text-danger">{formik.errors.image}</span> */}
                    {imageSizeError && (
                      <span className="text-danger ">Image too Large</span>
                    )}
                    {!imageSizeError && formik?.errors?.logo && (
                      <span className="text-danger">{formik.errors.logo}</span>
                    )}
                  </div>
                </div>

                <div className="d-flex  rounded-3 background-purple border-purple p-3">
                  <span className="me-1">
                    <AssignmentIcon
                      className="mt-1"
                      sx={{ color: "#28007d" }}
                    />{" "}
                  </span>
                  <span className="fw-500 fs-15px color-purple">
                    Image Quality: To avoid issues during uploading, ensure your
                    logo does not exceed the 1MB file size limit. Only JPEG and
                    PNG files are accepted.
                  </span>
                </div>
              </div>
              <div className="mt-3 mb-3 d-flex justify-content-end gap-3 align-items-center text-center">
                <div>
                  <button
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default form submission
                      handleOpenCloseProfileModal("profile");
                    }}
                    className="rounded"
                    style={{
                      height: "36px",
                      width: "122px",
                      background: "#E7E7E7",
                      color: "#747474",
                    }}
                  >
                    Discard
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    onClick={() => {
                      handleOpenCloseProfileModal("profile");
                    }}
                    className="rounded"
                    style={{
                      height: "36px",
                      width: "99px",
                      background: "#C08EFF",
                      color: "#2B0062",
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={updatedetailModal?.document}
        onHide={() => {
          handleOpenCloseProfileModal("document");
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className=" fw-bold color-black"
            style={{ fontSize: "25px", fontFamily: "Poppins" }}
          >
            Edit Your Identity And Adderess
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="">
          <div>
            <form onSubmit={Documentformik.handleSubmit}>
              <div>
                <div className="mb-50">
                  <span className="fs-19px fw-500 color-grey">
                    Assist us in verifying the authenticity of your institution.
                  </span>
                </div>
                <div className="row justify-content-between mb-4">
                  <div className="col-lg-6 col-12 px-4 py-2">
                    <div
                      className={`dashed-border-grey rounded-3 p-4 d-flex flex-column justify-content-center align-items-center cursor-pointer  file-drop-zone ${
                        dragging ? "dragging" : ""
                      }`}
                      onDragEnter={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                      onClick={(e) => {
                        handleFileInputClick(e, "input1");
                      }}
                      style={{ minHeight: "159px" }}
                    >
                      <input
                        accept="image/jpeg, image/png, application/pdf"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                        id="input1"
                      />

                      <>
                        <span>
                          <CloudUploadOutlinedIcon
                            sx={{ color: "#5d81ad", fontSize: "40px" }}
                          />
                        </span>
                        <span className="fw-500 fs-17px d-block ">
                          Drag file here or click to
                        </span>
                        <span className="color-purple fs-17px fw-500 text-decoration-underline cursor-pointer">
                          Select file to upload
                        </span>
                      </>
                      <span className="color-grey">
                        Proof of identity <span>(POI)</span>
                      </span>
                    </div>
                    <div className="text-center">
                      {imageSizeError1 && (
                        <span className="text-danger ">Image too Large</span>
                      )}
                      {!imageSizeError1 &&
                      Documentformik?.errors?.proof_of_id &&
                      Documentformik?.touched?.proof_of_id ? (
                        <span className="text-danger">
                          {Documentformik?.errors?.proof_of_id}
                        </span>
                      ) : null}
                    </div>
                    <ShowProofId
                      title={"Proof Of Id"}
                      url={Documentformik?.values?.proof_of_id}
                      setShowProof={setShowProof}
                      showProof={showProof}
                    />
                  </div>

                  <div className="col-lg-6 col-12 px-4 py-2">
                    <div
                      className={`dashed-border-grey rounded-3 p-4 d-flex justify-content-center flex-column align-items-center cursor-pointer  file-drop-zone ${
                        dragging ? "dragging" : ""
                      }`}
                      onDragEnter={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={handleDrop2}
                      onClick={(e) => {
                        handleFileInputClick(e, "input2");
                      }}
                      style={{ minHeight: "159px" }}
                    >
                      <input
                        type="file"
                        accept="image/jpeg, image/png, application/pdf"
                        style={{ display: "none" }}
                        id="input2"
                        onChange={handleFileInputChange2}
                      />

                      <>
                        <span>
                          <CloudUploadOutlinedIcon
                            sx={{ color: "#5d81ad", fontSize: "40px" }}
                          />
                        </span>
                        <span className="fw-500 fs-17px d-block ">
                          Drag file here or click to
                        </span>
                        <span className="color-purple fs-17px fw-500 text-decoration-underline cursor-pointer">
                          Select file to upload
                        </span>
                      </>
                      <span className="color-grey">
                        Proof of address<span>(POA)</span>
                      </span>
                    </div>
                    <div className="text-center">
                      {imageSizeError2 && (
                        <span className="text-danger ">Image too Large</span>
                      )}
                      {!imageSizeError2 &&
                      Documentformik?.errors?.proof_of_address &&
                      Documentformik?.touched?.proof_of_address ? (
                        <span className="text-danger">
                          {Documentformik?.errors?.proof_of_address}
                        </span>
                      ) : null}
                    </div>

                    <ShowProof_Address
                      title={"Proof Of Address"}
                      url={Documentformik?.values?.proof_of_address}
                      setShowProofAddress={setShowProofAddress}
                      ShowProofAddress={ShowProofAddress}
                    />
                  </div>
                </div>
                <div className="background-purple rounded-3 px-3 py-3 mb-50 border-purple d-flex">
                  <div>
                    <span className="me-2">
                      {
                        <AssignmentIcon
                          className="mt-1"
                          sx={{ color: "#28007d" }}
                        />
                      }
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "#633CFF",
                      }}
                    >
                      Accepted files types are .jpg .png or a pdf format.{" "}
                      <span
                        onClick={openDocumentModal}
                        className="fs-15px"
                        style={{ fontWeight: "400", color: "#633CFF" }}
                      >
                        Upto 2 files or 1 MB maximum,{" "}
                        <span
                          className="fs-15px color-purple text-decoration-underline"
                          style={{ cursor: "pointer", fontWeight: "400" }}
                        >
                          click here to know about poa and poi documents{" "}
                        </span>
                      </span>
                    </span>
                  </div>
                  {/* <span className="fs-15px fw-500 color-purple "> <span className="me-2">{<img src="./images/onBoarding/note.png" />}</span>Accepted files types are .jpg .png or a pdf format. <span>Upto 2 files or 2 MB maximum, click here to know about poa and poi documents</span></span> */}
                </div>
              </div>

              <div className="mt-3 mb-3 d-flex justify-content-end gap-3 align-items-center text-center">
                <div>
                  <button
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default form submission
                      handleOpenCloseProfileModal("document");
                    }}
                    className="rounded"
                    style={{
                      height: "36px",
                      width: "122px",
                      background: "#E7E7E7",
                      color: "#747474",
                    }}
                  >
                    Discard
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    className="rounded"
                    style={{
                      height: "36px",
                      width: "99px",
                      background: "#C08EFF",
                      color: "#2B0062",
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <DocumentModal
        documentModal={documentModal}
        closeDocumentModal={closeDocumentModal}
      />
    </>
  );
};

const typeOfSelectedInstitute = [
  { name: "Pre-School", value: 1 },
  { name: "Primary", value: 2 },
  { name: "Middle School", value: 3 },
  { name: "High School", value: 4 },
  { name: "Senior Secondary", value: 5 },
];

const selectBoard = [
  { name: "CBSE", value: 1 },
  { name: "CISCE", value: 2 },
  { name: "IGOT-Health", value: 3 },
  { name: "NIOS", value: 4 },
  { name: "OTHERS", value: 5 },
  { name: "STATE (Andhra Pradesh)", value: 6 },
  { name: "STATE (Arunachal Pradesh)", value: 7 },
  { name: "STATE (Assam)", value: 8 },
  { name: "STATE (Bihar)", value: 9 },
  { name: "STATE (Chandigarh)", value: 10 },
  { name: "STATE (Chattisgarh)", value: 11 },
  { name: "STATE (Delhi)", value: 12 },
  { name: "STATE (Goa)", value: 13 },
  { name: "STATE (Gujarat)", value: 14 },
  { name: "STATE (Haryana)", value: 15 },
  { name: "STATE (Himachal Pradesh)", value: 16 },
  { name: "STATE (Jammu & Kashmir)", value: 17 },
  { name: "STATE (Jharkhand)", value: 18 },
  { name: "STATE (Karnataka)", value: 19 },
  { name: "STATE (Kerala)", value: 20 },
  { name: "STATE (Madhya Pradesh)", value: 21 },
  { name: "STATE (Maharashtra)", value: 22 },
  { name: "STATE (Manipur)", value: 23 },
  { name: "STATE (Meghalaya)", value: 24 },
  { name: "STATE (Mizoram)", value: 25 },
  { name: "STATE (Nagaland)", value: 26 },
  { name: "STATE (Odisha)", value: 27 },
  { name: "STATE (Punjab)", value: 28 },
  { name: "STATE (Rajasthan)", value: 29 },
  { name: "STATE (Sikkim)", value: 30 },
  { name: "STATE (Tamil Nadu)", value: 31 },
  { name: "STATE (Tripura)", value: 32 },
  { name: "STATE (Uttar Pradesh)", value: 33 },
  { name: "STATE (Uttarakhand)", value: 34 },
  { name: "UT (Andaman & Nicobar Islands)", value: 35 },
  { name: "UT ( DNH & DD)", value: 36 },
  { name: "UT (Ladakh)", value: 37 },
  { name: "UT (Puducherry)", value: 38 },
];

const languages = [
  { name: "Bengali", value: 1 },
  { name: "English", value: 2 },
  { name: "Gujarati", value: 3 },
  { name: "Hindi", value: 4 },
  { name: "Kannada", value: 5 },
  { name: "Malayalam", value: 6 },
  { name: "Marathi", value: 7 },
  { name: "Odiya", value: 8 },
  { name: "Punjabi", value: 9 },
  { name: "Sanskrit", value: 10 },
  { name: "Tamil", value: 11 },
  { name: "Telugu", value: 12 },
  { name: "Urdu", value: 13 },
];

const typeOfSchool = [
  {
    value: 1,
    name: "School",
  },
  {
    value: 2,
    name: "College",
  },
  {
    value: 3,
    name: "University",
  },
  {
    value: 4,
    name: "Coaching Institute",
  },
];

const universityTypes = [
  { name: "Central University", value: 1 },
  { name: "State University", value: 2 },
  { name: "Deemed University", value: 3 },
  { name: "Private University", value: 4 },
  { name: "Autonomous Higher Education Institution", value: 5 },
  { name: "Other (Please specify)", value: 6 },
];

const collegeTypes = [
  { name: "Engineering College", value: 1 },
  { name: "Medical College", value: 2 },
  { name: "Arts College", value: 3 },
  { name: "Science College", value: 4 },
  { name: "Commerce College", value: 5 },
  { name: "Law College", value: 6 },
  { name: "Management College", value: 7 },
  { name: "Pharmacy College", value: 8 },
  { name: "Agriculture College", value: 9 },
  { name: "Dental College", value: 10 },
  { name: "Architecture College", value: 11 },
  { name: "Hotel Management College", value: 12 },
  { name: "Fine Arts College", value: 13 },
  { name: "Education College", value: 14 },
  { name: "Nursing College", value: 15 },
  { name: "Physical Education College", value: 16 },
  { name: "Journalism and Mass Communication College", value: 17 },
  { name: "Fashion Designing College", value: 18 },
  { name: "Social Work College", value: 19 },
  { name: "Veterinary College", value: 20 },
  { name: "Ayurvedic College", value: 21 },
  { name: "Homeopathic College", value: 22 },
  { name: "Unani College", value: 23 },
  { name: "Polytechnic College", value: 24 },
  { name: "ITI College", value: 25 },
  { name: "B.Ed. College", value: 26 },
  { name: "BBA College", value: 27 },
  { name: "Animation and Multimedia College", value: 28 },
  { name: "Film and Television Institute", value: 29 },
  { name: "Aviation College", value: 30 },
  { name: "Event Management College", value: 31 },
  { name: "Hotel Management Institute", value: 32 },
  { name: "Multi-disciplinary College", value: 33 },
  { name: "Other (Please specify)", value: 34 },
];

const college = [
  {
    value: "1",
    name: "AMC Engineering College",
    address: "AMC Campus , Banglore, karnataka 560606",
  },
  {
    value: "2",
    name: "AMC Boys College",
    address: "AMC Campus , Banglore, karnataka 560606",
  },
  {
    value: "3",
    name: "Indian Institute Of Technology",
    address: "AMC Campus , Banglore, karnataka 560606",
  },
  {
    value: "4",
    name: "AMC Engineering College",
    address: "AMC Campus , Banglore, karnataka 560606",
  },
];

const countryCode = [
  {
    value: 1,
    name: "India",
    code: "+91",
  },
  {
    value: 2,
    name: "USA",
    code: "+1",
  },
];

const socialHandles = [
  {
    platform: "Facebook",
    url: "facebook.com/",
    icons: <FacebookIcon sx={{ color: "#3f51b5", fontSize: "35px" }} />,
    socialurls: /facebook\.com\/[a-zA-Z0-9_.-]+\/?/,
    icon: "./images/social-new-icons/facebook.png",
  },
  {
    platform: "Twitter",
    url: "twitter.com/",
    socialurls: /twitter\.com\/[a-zA-Z0-9_.-]+\/?/,
    icon: "./images/social-new-icons/twitter.png",
  },
  {
    platform: "Linkdin",
    icons: <LinkedInIcon sx={{ color: "#0288d1", fontSize: "35px" }} />,
    url: "linkedin.com/",
    socialurls: /linkedin\.com\/[a-zA-Z0-9_.-]+\/?/,
    icon: "./images/social-new-icons/linkedin.png",
  },
  {
    platform: "Instagram",
    url: "instagram.com/",
    socialurls: /instagram\.com\/[a-zA-Z0-9_.-]+\/?/,
    icon: "./images/social-new-icons/instagram.png",
  },
  {
    platform: "YouTube",
    icons: <YouTubeIcon sx={{ color: "#ff3d00", fontSize: "35px" }} />,
    url: "youtube.com/",
    socialurls: /youtube\.com\/[a-zA-Z0-9_.-]+\/?/,
    icon: "./images/social-new-icons/youtube.png",
  },
];

const coordinators = [
  {
    name: "Sahil Gagan",
    des: "Admin",
  },

  {
    name: "Shivam Mishra",
    des: "Admin",
  },
  {
    name: "Ayush Antiwal",
    des: "Manager",
  },
];

const designations = [
  {
    value: 1,
    des: "Admin",
  },
  {
    value: 2,
    des: "Moderator",
  },
  {
    value: 3,
    des: "Manager",
  },
];

const designationSchool = [
  { value: "Principal", name: "Principal" },
  { value: "Vice Principal", name: "Vice Principal" },
  { value: "Headmaster/Headmistress", name: "Headmaster/Headmistress" },
  { value: "School Administrator", name: "School Administrator" },
  { value: "School Coordinator", name: "School Coordinator" },
  { value: "Teacher", name: "Teacher" },
  {
    value:
      "Subject Teacher (e.g., English Teacher, Math Teacher, Science Teacher)",
    name: "Subject Teacher (e.g., English Teacher, Math Teacher, Science Teacher)",
  },
  { value: "Special Educator", name: "Special Educator" },
  { value: "School Counselor", name: "School Counselor" },
  { value: "Librarian", name: "Librarian" },
  {
    value: "Physical Education Teacher (PET)",
    name: "Physical Education Teacher (PET)",
  },
  { value: "Lab Assistant", name: "Lab Assistant" },
  {
    value: "Administrative Staff (e.g., Accountant, Office Assistant)",
    name: "Administrative Staff (e.g., Accountant, Office Assistant)",
  },
  {
    value: "IT Coordinator/IT Teacher",
    name: "IT Coordinator/IT Teacher",
  },
  { value: "Art Teacher", name: "Art Teacher" },
  { value: "Music Teacher", name: "Music Teacher" },
  { value: "Dance Teacher", name: "Dance Teacher" },
  { value: "Sports Coach", name: "Sports Coach" },
];

const designationUniversityCollege = [
  { value: "Vice Chancellor", name: "Vice Chancellor" },
  { value: "Pro Vice-Chancellor", name: "Pro Vice-Chancellor" },
  { value: "Dean", name: "Dean" },
  {
    value: "Head of Department (HOD)",
    name: "Head of Department (HOD)",
  },
  { value: "Professor", name: "Professor" },
  { value: "Associate Professor", name: "Associate Professor" },
  { value: "Assistant Professor", name: "Assistant Professor" },
  { value: "Lecturer", name: "Lecturer" },
  { value: "Research Scholar", name: "Research Scholar" },
  { value: "Registrar", name: "Registrar" },
  {
    value: "Director (of specific schools, institutes, or centers)",
    name: "Director (of specific schools, institutes, or centers)",
  },
  { value: "Librarian", name: "Librarian" },
  { value: "Student Advisor", name: "Student Advisor" },
  {
    value: "Administrative Staff (e.g., Accountant, Office Assistant)",
    name: "Administrative Staff (e.g., Accountant, Office Assistant)",
  },
  { value: "Laboratory Technician", name: "Laboratory Technician" },
  {
    value: "IT Coordinator/IT Faculty",
    name: "IT Coordinator/IT Faculty",
  },
  {
    value: "Physical Education Instructor (PEI)",
    name: "Physical Education Instructor (PEI)",
  },
  { value: "Career Counselor", name: "Career Counselor" },
  { value: "Placement Officer", name: "Placement Officer" },
  { value: "Research Assistant", name: "Research Assistant" },
];

const designationCoachingInstitute = [
  { value: "Director", name: "Director" },
  { value: "Academic Head", name: "Academic Head" },
  { value: "Subject Experts/Faculty", name: "Subject Experts/Faculty" },
  { value: "Teaching/Subject Mentor", name: "Teaching/Subject Mentor" },
  { value: "Content Developer", name: "Content Developer" },
  { value: "Counselor", name: "Counselor" },
  { value: "Academic Coordinator", name: "Academic Coordinator" },
  { value: "Center Manager", name: "Center Manager" },
  { value: "Marketing Manager", name: "Marketing Manager" },
  { value: "Admission Counselor", name: "Admission Counselor" },
  { value: "Front Office Executive", name: "Front Office Executive" },
  { value: "Online Platform Manager", name: "Online Platform Manager" },
  {
    value: "Digital Marketing Executive",
    name: "Digital Marketing Executive",
  },
  { value: "Course Coordinator", name: "Course Coordinator" },
  { value: "Administrative Staff", name: "Administrative Staff" },
  { value: "Test Coordinator", name: "Test Coordinator" },
  { value: "Research Analyst", name: "Research Analyst" },
  { value: "IT Support Specialist", name: "IT Support Specialist" },
  {
    value: "Online Content Moderator",
    name: "Online Content Moderator",
  },
  {
    value: "Online Learning Facilitator",
    name: "Online Learning Facilitator",
  },
];

const DuplicateInsDetail = [
  {
    id: 1,
    insLogo: "./assets/Rectangle 3247.svg",
    institute_name: "FIT COLLEGE",
    no_of_student: 2100,
    address: "Mawana road buxcer MEERUT 233222",
    name: "Sahil gagan",
    designation: "Coordinator",
    email: "sahilgagan@gmail.com",
    Affiliation: "",
    medium_of_education: "English",
    phone: 9766126613,
    website_link: " https://amcgroup.edu.in/",
  },
  {
    id: 2,
    insLogo: "./assets/Rectangle 3247.svg",
    institute_name: "Administrative Management College",
    no_of_student: 2100,
    address: "AMC Campus, Bannerghatta Main Rd, Bengaluru, Karnataka 560083",
    name: "Saurabh Sharma",
    designation: "Principal",
    email: "shaileshsainee3450@gmail.com",
    Affiliation: "",
    medium_of_education: "English",
    phone: 8419810848,
    website_link: " https://amcgroup.edu.in/",
  },
  {
    id: 3,
    insLogo: "./assets/Rectangle 3247.svg",
    institute_name: "Ram LAl Aanand college",
    no_of_student: 2100,
    address: "AMC Campus, Bannerghatta Main Rd, Bengaluru,Motibag 560083",
    name: "Nitesh ",
    designation: "HOD",
    email: "sk19810848@gmail.com",
    Affiliation: "",
    medium_of_education: "English",
    phone: 7398063450,
    website_link: " https://amcgroup.edu.in/",
  },
];
const statesOfIndia = [
  { value: "Andhra Pradesh", name: "Andhra Pradesh" },
  { value: "Arunachal Pradesh", name: "Arunachal Pradesh" },
  { value: "Assam", name: "Assam" },
  { value: "Bihar", name: "Bihar" },
  { value: "Chhattisgarh", name: "Chhattisgarh" },
  { value: "Goa", name: "Goa" },
  { value: "Gujarat", name: "Gujarat" },
  { value: "Haryana", name: "Haryana" },
  { value: "Himachal Pradesh", name: "Himachal Pradesh" },
  { value: "Jharkhand", name: "Jharkhand" },
  { value: "Karnataka", name: "Karnataka" },
  { value: "Kerala", name: "Kerala" },
  { value: "Madhya Pradesh", name: "Madhya Pradesh" },
  { value: "Maharashtra", name: "Maharashtra" },
  { value: "Manipur", name: "Manipur" },
  { value: "Meghalaya", name: "Meghalaya" },
  { value: "Mizoram", name: "Mizoram" },
  { value: "Nagaland", name: "Nagaland" },
  { value: "Odisha", name: "Odisha" },
  { value: "Punjab", name: "Punjab" },
  { value: "Rajasthan", name: "Rajasthan" },
  { value: "Sikkim", name: "Sikkim" },
  { value: "Tamil Nadu", name: "Tamil Nadu" },
  { value: "Telangana", name: "Telangana" },
  { value: "Tripura", name: "Tripura" },
  { value: "Uttar Pradesh", name: "Uttar Pradesh" },
  { value: "Uttarakhand", name: "Uttarakhand" },
  { value: "West Bengal", name: "West Bengal" },
  { value: "Andaman and Nicobar Islands", name: "Andaman and Nicobar Islands" },
  { value: "Chandigarh", name: "Chandigarh" },
  {
    value: "Dadra and Nagar Haveli and Daman and Diu",
    name: "Dadra and Nagar Haveli and Daman and Diu",
  },
  { value: "Delhi", name: "Delhi" },
  { value: "Lakshadweep", name: "Lakshadweep" },
  { value: "Puducherry", name: "Puducherry" },
  { value: "Jammu and Kashmir", name: "Jammu and Kashmir" },
];
