import React, { useContext, useEffect, useState } from 'react'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import HttpsTwoToneIcon from '@mui/icons-material/HttpsTwoTone';
import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
import { DiscussionBoardMessageBox, DiscussionBoardMesssageArea } from './component';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import io from 'socket.io-client';
import "./DiscussionBoardChat.css"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { apiJson } from 'api';
import { useGlobalContext } from 'global/context';
import ListIcon from '@mui/icons-material/List';

import { toast } from 'react-toastify';
import { useDiscussionContext } from './discussionBoardContext/discussionContext';
import { Modal } from 'react-bootstrap';
const menuItemStyle = {
  '&:hover': {
    backgroundColor: '#F8F5FF',
    borderRadius: "8px",
    color: "#160054"
  },
  '&.Mui-selected': {
    backgroundColor: '#F8F5FF',
    borderRadius: "8px",
    color: "#160054",
    fontWeight: "bold"
  },
};
let socket;
export const DiscussionBoardChatRoom = () => {
  const chattingPermission = ['Delegates', '']
  const nonChattingPermission = ['PressCorp','jury', 'Sec']
  const { id } = useParams()
  const navigate = useNavigate();
  const { userData } = useGlobalContext()
  const { comments, setComments, message, setMessage } = useDiscussionContext()
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMessage, setCurrentMessage] = useState('')
  const [showOption, setShowOption] = useState(false)
  const [eventDetail, setEventDetail] = useState({})
  const [attendeeDetail, setAttendeeDetail] = useState({})

  const [discussData, setDiscussdata] = useState({})
  const open = Boolean(anchorEl);
  const [showInfo, setShowInfo] = useState(false)

  const getAttendeeDetail = ()=>{
    apiJson.get(`api/v2/discussion_board/getAttendeeDetail/user/${userData?.id}/role/${userData?.role}/discussionId/${id}`)
    .then((res)=>{
      setAttendeeDetail(res?.data?.result)
      
    }).catch((error)=>{
      toast.error('Internal Server Error')
      console.log('Internal Server Error')
    })
  }

  const getEventDetail = ()=>{
    apiJson.get(`api/v2/discussion_board/discussionDetail/discussionId/${id}`)
    .then((res)=>{
      setEventDetail(res?.data?.result)
    }).catch((error)=>{
      toast.error('Internal Server Error')
      console.log('Internal Server Error')
    })
  }

  useEffect(()=>{
    getAttendeeDetail()
    getEventDetail()
  },[])
  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setNewMessage = (message) => {
    setMessage(message)
  }
  const handleOptionModal = () => {
    setShowOption(prev => !prev)
  }

  // socket connection

  useEffect(() => {

    // now i will send the disscussion id to the server 
    socket = io(process.env.REACT_APP_API_BASE_URL);

    socket.on('welcome', welComeMessage => {
      console.log('messaege', welComeMessage, ' ', socket.id);
      const userdata = {
        name: userData?.role === 'institute' ? userData.institution_name : userData?.first_name + ' ' + userData?.last_name,
        role: userData.role,
        id: userData.id,
        instituteId: userData.role === 'institute' ? userData.id : userData.instituteId,
        profile: userData.profile || userData.logo
      }
      socket.emit('userDetail', userdata);

      // on new user
      socket.on('newUserJoined', user => {
        toast.dismiss();
        toast.success(`${user.name} joined the chat`);
        console.log(user.name, ' joined the chat');
      });

      // add chat
      socket.on('addChat', newChat => {
        setMessage(prev => [...prev, newChat])
        console.log('inside the setmessage', [...message, newChat])
      })

      // new chat added
      socket.on('newChatAdded', (chat) => {
        console.log('chat', chat)
        // socket.emit('getAllPrevChat', 28)
        setMessage(prev => [...prev, chat])

      })
      // get votable point count
      socket.emit('getVotablePointCount', id)

      // get prev chat
      socket.emit('getAllPrevChat', id)

      socket.on('allPrevChat', allchats => {
        console.log('allchats', allchats);
        setMessage(allchats)
      })

      // get all reply on reload 
      socket.emit('getAllReplyByDiscussionId', id)
      socket.on('getChatReplyAll', data => {
        console.log('chat repply', data)
        setComments(data)
      })

      // when a user add reply in comment 
      socket.on('getChatReply', data => {
        console.log('chat repply', data)
        setComments(prev => [...prev, data])
      })

      // when a user leave
      socket.on('userLeave', user => {
        toast.dismiss();
        toast.warning(`${user.name} is disconnected`);
      });
    });
    return () => {
      // Disconnect the socket when the component unmounts
      socket.disconnect();
    };
  }, []);


  const [screenWidth, setScreenWidth] = useState(document.documentElement.clientWidth);
  const [screenHeight, setScreenHeight] = useState(document.documentElement.clientHeight);

  const handleResize = () => {
    setScreenWidth(document.documentElement.clientWidth);
    setScreenHeight(document.documentElement.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleShowInfo = () => {
    setShowInfo(prev => !prev)
  }


  return (
    <>
      <div className='d-flex flex-column justify-content-between' style={{
        position: 'relative',
        height: '89vh'
      }}>
        <div className='mb-3 ms-3' style={{ position: 'sticky', top: '0' }}>
          <div className='d-flex justify-content-between'>
            <div>

              <Button onClick={handleShowInfo} >
                <span className='text-Info text-capitalize me-2 fs-6 fw-semibold'>Detail</span> <InfoOutlinedIcon />
              </Button>
            </div>

            <IconButton onClick={handleOptionModal} >
              <ListIcon />
            </IconButton>
          </div>
        </div>
        <div >
          <div className="border-bottom" style={{ color: "#5E5E5E" }}>
            <div className="mx-3">

            </div>
          </div>

          <div className="col-12 discussionBoardScroll ps-3 mt-1" style={{ overflowY: "scroll", }}>

            <DiscussionBoardMesssageArea socket={socket} message={message} comments={comments} screenWidth={screenWidth} screenHeight={screenHeight} />
          </div>

        </div>

        <div className="col-12  mx-2 my-3" style={{
          position: 'sticky',
          bottom: '0',
          zIndex: '20',
          background: 'white'
        }}>
          <DiscussionBoardMessageBox socket={socket} />
        </div>
      </div>





      <Modal show={showInfo} onHide={handleShowInfo} centered>
        <Modal.Body>
          <div className='row '>
            <div className='col-12  '>
              <div >
                <span className='fs-5 fw-semibold'>Title: </span><span className='fs-5 fw-semibold'>This is Title</span>
              </div>
            </div>
            <div className='col-12 col-6 '>
              <div >
                <span className='fs-5 fw-semibold'>Description: </span><span className='fs-5 fw-semibold'>This is Description</span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showOption} onHide={handleOptionModal} centered size='sm'>
        <Modal.Body>
          <div className='row p-2 justify-content-center'>
            <Button onClick={() => navigate(`/new-dashboard/discussion-leaderboard/${id}`)} className='col-10 m-2  ' variant="outlined" color="success">
              LeaderBoard
            </Button>
            <Button onClick={() => navigate(`/new-dashboard/discussion-judgingAndJury/${id}`)} className='col-10 m-2 ' variant="outlined" color="warning">
              Judging and Jury
            </Button>
            <Button className='col-10 m-2 ' variant="outlined" color="error">
              Exit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
