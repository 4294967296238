import React, { useEffect, useState } from "react";
import {
  UI2DashboardActivites,
  UI2DashboardBanner,
  UI2DashboardClub,
  UI2DashboardDownload,
  UI2DashboardEvent,
  UI2DashboardFeatures,
  UI2DashboardGallery,
  UI2DashboardGroups,
  UI2DashboardQR,
  UI2DashboardResources,
} from "./component";
import './Dashboard.css'
import { useGlobalContext } from "global/context";
import { apiJson, apiJsonAuth } from "api";
import { toast } from "react-toastify";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export const UI2MainDashboard = () => {
  const {userData,setUser} = useGlobalContext();
  const [nipamStudent, setnipamStudent] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(()=>{
    if(userData?.isNipam){
      setnipamStudent(userData?.isNipam?false:true)
    }
  },[userData])
  const geteventDetail = () => {
    const id =
      userData?.role === "institute" ? userData?.id : userData?.instituteId;
    apiJson("api/v2/modelUn-student/getEventDetails/institute/" + id)
      .then((res) => {
        setUser({...userData, munRegister: res?.data?.result?.id})
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getAllRoles = async()=>{
    try {
      const result = await apiJsonAuth.get(`v2/register/getSubroles/${userData?.email}`)
      if(result?.status ===200){
        setUser({...userData,subRoles: result?.data?.roles})
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }
  useEffect(()=>{
    getAllRoles();
  },[])
  const fetchInstituteDetail = ()=>{
    const id =
      userData?.role === "institute" ? userData?.id : userData?.instituteId;
    apiJson(`api/v2/institute/getInstituteProfileData/id/${id}/insituteDetail/`)
    .then((res)=>{
      isNipam(res.data.result.email)
    }).catch((error)=>{
      console.log('internal server error')
    })
  }
  const isNipam = (email)=>{
    setIsLoading(true)
    apiJson.get(`api/v2/institute/isNipamInstitute/${email}`)
    .then((res)=>{
    setIsLoading(false)
      if(res.data.result){
        setUser({...userData, isNipam:true})
      }
    }).catch((error)=>{
    setIsLoading(false)
      console.log(error.message)
    })
  }
  useEffect(()=>{
    geteventDetail()
    if(userData?.role==='institute' || userData?.role === 'student' || userData?.role === 'teacher'){
      fetchInstituteDetail()
    }
  },[])
  
  return (
    <>
      <div className="d-flex justify-content-center mainDashboard">
        <div className="col-11">
          {/*<=========== Dashboard banner start ============> */}
          {
            isLoading ? 
            <div id="loading-bar-spinner" class="spinner"><div class="spinner-icon"></div></div>
           
            :
            <div className="mt-4 mb-3">
               <UI2DashboardBanner nipamStudent={nipamStudent} />
             </div>
          }
          {/*<======== Dashboard banner End=========> */}
          {userData.role === 'institute' ? (
          <div className="mb-4 mt-3">
            <UI2DashboardQR />
          </div>
          ): null}
          {/* Dashboard Features start */}
          <div className="mb-4 mt-5">
            <UI2DashboardFeatures />
          </div>
          {/* <=========== Dashboard Activities start ================> */}
          <div className="mb-4">
            <UI2DashboardActivites />
          </div>
          {/* <=========== Dashboard Events start ================> */}
          <div className="mb-4">
            <UI2DashboardEvent />
          </div>

          {/* <=========== Dashboard Gallery start ================> */}
       {   
      //  <div className="mb-4">
      //       <UI2DashboardGallery />
      //     </div>
          }
          {/* <=========== Dashboard Clubs start ================> */}
          <div className="mb-4 shadow rounded-3">
            <UI2DashboardClub />
          </div>
          {/* <=========== Dashboard Groups start ================> */}
        {
          // <div className="mb-4">
          //   <UI2DashboardGroups />
          // </div>
          }
          {/* <=========== Dashboard Resources start ================> */}
          <div className="mb-4">
            <UI2DashboardResources />
          </div>
          {/* <=========== Dashboard Downloads start ================> */}
        { userData.role==='institute' &&   <div className="mb-3">
            <UI2DashboardDownload />
          </div>}
        </div>
      </div>
    </>
  );
};
