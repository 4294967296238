import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import axios from "axios";

const ProjectDetails = () => {

    const [studentData, setStudentData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("http://localhost:3000/api/approval/projects/status/66b68c59241a366b501c54e5");
                setStudentData(response.data.appliedProjects);
                // console.log(response.data.appliedProjects)
            } catch (error) {
                console.error("Error fetching the student data:", error);
            }
        };
        fetchData();
    }, []);
    console.log(studentData)

    // const studentData = [
    //     {
    //         title: "Project of Student 1",
    //         category: "Category 1",
    //         status: "Rejected",
    //     },
    //     {
    //         title: "Project of Student 1",
    //         category: "Category 1",
    //         status: "Rejected",
    //     },
    //     {
    //         title: "Project of Student 1",
    //         category: "Category 2",
    //         status: "Accepted",
    //     },
    // ];

    return (
        studentData.length > 0 ?
            <Box
                sx={{
                    width: "auto",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                }}
            >
                {studentData.map((project, index) => (
                    <Box
                        key={index}
                        sx={{
                            boxSizing: "border-box",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "1rem",
                            padding: "2rem 2rem 2rem 1.5rem",
                            boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
                            borderRadius: "1rem",
                            backgroundColor: "var(--color-ghostwhite)",
                            border: "5px solid #2356fe",
                        }}
                    >
                        <Typography variant="body1" className="project-title" sx={{ color: "#2356fe", fontWeight: "600" }}>
                            {project.projectName}
                        </Typography>
                        <Typography variant="body1" className="project-category" sx={{ color: "#2356fe", fontWeight: "600" }}>
                            {project.category}
                        </Typography>
                        <Typography
                            variant="body1"
                            className="project-status"
                            sx={{
                                borderRadius: "10px",
                                padding: "10px",
                                backgroundColor: project.status === "Rejected" ? "#ff6347" : (project.status === "Accepted" ? "#32cd32" : "#e6e219"),
                                color: "white"
                            }}>
                            {project.status}
                        </Typography>
                    </Box>
                ))}
            </Box>
            : <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                    width: "100%",
                }}
            >
                <Typography
                    sx={{
                        color: "#2356fe",
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                    }}
                >
                    Please Apply Before Checking Status...
                </Typography>
            </Box>
    );
};

export default ProjectDetails;
