import React, { useState } from 'react';
import { List, ListItem, ListItemText, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import './OptionList.css';

const OptionList = ({
  topic,
  setActiveTopic,
  setActiveSubtopic,
  taskCompletionStatus,
}) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
    setActiveTopic({ ...topic });
    if (topic.subtopics.length === 1) {
      setActiveSubtopic(topic.subtopics[0]);
    } else {
      setActiveSubtopic(null);
    }
  };

  const isSubtopicCompleted = (subtopic) => {
    const tasks = subtopic?.tasks;
    const completedTasks = taskCompletionStatus[subtopic.title] || {};
    const [startIndex, endIndex] = tasks;

    for (let i = startIndex; i <= endIndex; i++) {
      const taskTitle = `Task ${i + 1}`;
      if (!completedTasks[taskTitle]) {
        return false;
      }
    }
    return true;
  };

  const canAccessSubtopic = (index) => {
    if (index === 0) return true;
    for (let i = 0; i < index; i++) {
      if (!isSubtopicCompleted(topic.subtopics[i])) {
        return false;
      }
    }
    return true;
  };

  const handleSubtopicClick = (subtopic, index) => {
    if (canAccessSubtopic(index)) {
      setActiveSubtopic({ ...subtopic });
      setActiveTopic({ ...topic, selectedSubtopic: subtopic });
    } else {
      alert("Complete previous subtopics to access this one.");
    }
  };

  return (
    <div className="custom-accordion-option">
      <h4 className="custom-accordion-heading" onClick={() => handleClick()}>
        {topic.title}
        {open ? <ExpandLess /> : <ExpandMore />}
      </h4>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {topic.subtopics.map((subtopic, idx) => (
            <ListItem
              button
              key={idx}
              onClick={() => handleSubtopicClick(subtopic, idx)}
              disabled={!canAccessSubtopic(idx)}
              className={`subtopic-item ${!canAccessSubtopic(idx) ? "disabled" : ""
                }`}
            >
              {subtopic.tasks.length > 1 && (
                <div className="subtopic-status-icon">
                  {isSubtopicCompleted(subtopic) ? (
                    <CheckIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </div>
              )}
              <ListItemText primary={subtopic.title} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  );
};

export default OptionList;
