// import React, { useEffect, useState } from "react";
// import { Box, Button, Typography, TextField } from "@mui/material";
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import axios from "axios";

// const LinkInput = ({ setStartingView , setGoSteps }) => {
//   const [link, setLink] = useState("");
//   const [isLinkValid, setIsLinkValid] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");

//   const instituteId = 221;
//   const validateUrl = (url) => {
//     const urlPattern = new RegExp(
//       '^(https?:\\/\\/)' +               
//       '((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|' + 
//       '((\\d{1,3}\\.){3}\\d{1,3}))' +    
//       '(\\:\\d+)?' +                      
//       '(\\/[-a-zA-Z0-9@:%._\\+~#=]*)*' +  
//       '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' +    
//       '(\\#[-a-zA-Z0-9_]*)?$'             
//     );
//     return urlPattern.test(url);
//   };

//   const handleLinkChange = (e) => {
//     const newLink = e.target.value;
//     setLink(newLink);
//     const isValid = validateUrl(newLink);

//     if (isValid) {
//       setIsLinkValid(true);
//       setErrorMessage("");
//     } else {
//       setIsLinkValid(false);
//       setErrorMessage("Please enter a valid URL.");
//     }
//   };

//   const handleProceed = async (e) => {
//     e.preventDefault();
//     console.log("in handle upload ")
//       try {
//         await axios.post(`http://localhost:3000/api/document/policy-link/${instituteId}`, { url: link });
//           setStartingView(false); 
//           setGoSteps(2)

//       } catch (error) {
//         setErrorMessage("Failed to upload link. Please try again.");
//         console.error("Failed to upload link:", error);
//       }
    
//   };


//   // useEffect(() => {
//   //   if (instituteId) {
//   //     axios.patch(`http://localhost:3000/api/institutes/check-policy-url/${instituteId}`)
//   //       .then(response => {
//   //         if (response.data.status) {
//   //           setStartingView(!response.data.policyUploaded); 
//   //         } else {
//   //           setStartingView(false);
//   //         }
//   //       })
//   //       .catch(error => {
//   //         console.error("Error checking policy URL:", error);
//   //       });
//   //   }
//   // }, []);


//   return (

//       <Box >
//         <TextField
//           label="Policy Link"
//           variant="outlined"
//           fullWidth
//           sx={inputStyle}
//           value={link}
//           onChange={handleLinkChange}
//           error={!!errorMessage}
//           helperText={errorMessage}
//         />
//         <Box sx={nextButtonContainerStyle}>
//           <Button
//             variant="contained"
//             endIcon={<ArrowForwardIcon />}
//             sx={nextButtonStyle}
//             onClick={handleProceed}
//             disabled={!isLinkValid}
//           >
//             Proceed
//           </Button>
//         </Box>
//       </Box>
//   );
// };

// // Styling

// const inputStyle = {
//   width:"100%",
//   backgroundColor: '#ffffff',
//   color: '#1b1b1b',
//   borderRadius: '0.75rem',
//   marginBottom: '1rem',
//   '& .MuiOutlinedInput-root': {
//     borderRadius: '0.75rem',
//   },
//   '& .MuiInputLabel-root': {
//     fontSize: '1rem',
//   },
//   '& .MuiInputBase-input': {
//     fontSize: '1rem',
//   },
// };

// const nextButtonContainerStyle = {
//   display: 'flex',
//   justifyContent: 'center',
//   width: '100%',
// };

// const nextButtonStyle = {
//   backgroundColor: '#1418f0',
//   color: '#ffffff',
//   borderRadius: '0.75rem',
//   textTransform: 'none',
//   padding: '0.75rem 1.5rem',
//   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
//   '&:hover': {
//     backgroundColor: '#0e4a9a',
//   },
// };

// export default LinkInput;



import React, { useState } from "react";
import { Box, Button, TextField, InputAdornment, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import axios from "axios";
import { apiSewa } from "api";


const LinkInput = ({ setStartingView, setGoSteps , instituteId  }) => {
  const [link, setLink] = useState("");
  const [isLinkValid, setIsLinkValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");



  console.log(instituteId);
  
  const validateUrl = (url) => {
    return url.startsWith('http://') || url.startsWith('https://');
  };

  const handleLinkChange = (e) => {
    const newLink = e.target.value;
    setLink(newLink);
    const isValid = validateUrl(newLink);

    if (isValid) {
      setIsLinkValid(true);
      setErrorMessage("");
    } else {
      setIsLinkValid(false);
      setErrorMessage("Please enter a valid URL starting with http or https.");
    }
  };

  const handleProceed = async (e) => {
    e.preventDefault();
    try {
      // await apiSewa.post(`/document/policy-link/${instituteId}`, { url: link });
      setStartingView(false);
      setGoSteps(2);
    } catch (error) {
      setErrorMessage("Failed to upload link. Please try again.");
      // console.error("Failed to upload link:", error);
    }
  };

  return (
    <Box>
      <TextField
        // label="Policy Link"
        variant="outlined"
        fullWidth
        sx={inputStyle}
        value={link}
        onChange={handleLinkChange}
        InputProps={{
          endAdornment: errorMessage ? (
            <InputAdornment position="end">
              <ErrorOutlineIcon color="error" />
            </InputAdornment>
          ) : null,
        }}
      />
      {errorMessage && <Typography sx={errorTextStyle}>{errorMessage}</Typography>}
      <Box sx={nextButtonContainerStyle}>
        <Button
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          sx={nextButtonStyle}
          onClick={handleProceed}
          disabled={!isLinkValid}
        >
          Proceed
        </Button>
      </Box>
    </Box>
  );
};

// Styling

const inputStyle = {
  width: "100%",
  backgroundColor: '#ffffff',
  color: '#1b1b1b',
  borderRadius: '0.75rem',
  marginBottom: '0.5rem',
  '& .MuiOutlinedInput-root': {
    borderRadius: '0.75rem',
  },
  
  '& .MuiInputLabel-root': {
    fontSize: '1rem',
  },
  '& .MuiInputBase-input': {
    fontSize: '1rem',
  },
};

const errorTextStyle = {
  color: '#d32f2f',
  fontSize: '0.875rem',
  marginTop: '-0.5rem',
  marginBottom: '1rem',
};

const nextButtonContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
};

const nextButtonStyle = {
  backgroundColor: '#1418f0',
  color: '#ffffff',
  borderRadius: '0.75rem',
  textTransform: 'none',
  padding: '0.75rem 1.5rem',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    backgroundColor: '#0e4a9a',
  },
};

export default LinkInput;
