import React from "react";
import satyaIcon from "../assests/satmew-jayte-logo-FBBEBAEA31-seeklogo 2.svg";
import "../Css/univercity.css";
import arrow from "../assests/Vector.svg";
import unione from "../assests/univercity/uniOne.svg";
import uniTwo from "../assests/univercity/uniTwo.svg";
import uniThree from "../assests/univercity/uniThree.svg";
import luck from "../assests/univercity/lucknowUnivercity.png";
import uniFive from "../assests/univercity/uniFive.svg";
import uniSix from "../assests/univercity/uniSix.svg";
import satyaimg from "../assests/univercity/satmew-jayte-logo-FBBEBAEA31-seeklogo 1.svg";
import unaUnivlogo from "../assests/univercity/uvaUnivlogo.svg";
import ShowMorePagination from "./ShowMorePaginatoin";

const Universities = () => {
  // Sample array of items
  const itemsArray = [
    {
      para: "MINISTRY OF EDUCATION",
      img: satyaIcon,
      modal:'',
      showLink:false,
      downloadPdf:false,
      link:'',
      showModal:false,
      pdfLink:"",
      pdfImages:[
        '',
      ]
    },
    {
      para: "MINISTRY OF AYUSH",
      img: satyaIcon,
      modal:'',
      showLink:false,
      downloadPdf:true,
      link:'',
      showModal:false,
    },
    {
      para: "MINISTRY OF CULTURE",
      img: satyaIcon,
      modal:'',
      showLink:true,
      downloadPdf:false,
      link:'https://ccrtindia.gov.in/',
      showModal:false,
      pdfImages:[
        // '/endorse/moc.png'
      ]
    },
    {
      para: "MINISTRY OF DEFENCE",
      img: satyaIcon,
      modal:'',
      showLink:false,
      downloadPdf:true,
      link:'',
      showModal:false,
    },
    {
      para: "MINISTRY OF YOUTH AFFAIRS AND SPORTS",
      img: satyaIcon,
      modal:'',
      showLink:false,
      downloadPdf:true,
      link:'',
      showModal:false,
    },
    {
      para: "MINISTRY OF ENVIRONMENT, FORESTS & CLIMATE CHANGE",
      img: satyaIcon,
      modal:'',
      showLink:false,
      downloadPdf:true,
      link:'',
      showModal:false,
    },
    {
      para: "ARMY WELFARE EDUCATION SOCIETY",
      img: unione,
      modal:'',
      showLink:false,
      downloadPdf:true,
      link:'',
      showModal:false,
    },
    {
      para: "UNIVERSITY GRANTS COMMISSION",
      img: uniTwo,
      modal:'',
      showLink:false,
      downloadPdf:true,
      link:'',
      pdfLink:"/endorse/pdf/ugc.pdf",
      showModal:false,
      pdfImages:[
        // '/endorse/ugc-1.png',
        // '/endorse/ugc-2.png',
        // '/endorse/ugc-3.png',
        // '/endorse/ugc-4.png',
        // '/endorse/ugc-5.png',
        // '/endorse/ugc-6.png',
        // '/endorse/ugc-7.png',
        // '/endorse/ugc-8.png',
        // '/endorse/ugc-9.png',
      ]
    },
    {
      para: "UNITED NATIONS DEVELOPMENT PROGRAMME",
      img: uniSix,
    },
    // {
    //   para: "DELHI UNIVERSITY",
    //   img: uniThree,
    //   modal:'',
    //   showLink:false,
    //   downloadPdf:true,
    //   link:'',
    //   pdfLink:'/endorse/pdf/du.pdf',
    //   showModal:false,
    //   pdfImages:[
    //     // '/endorse/duImages/du-01.png',
    //     // '/endorse/duImages/du-02.png',
    //     // '/endorse/duImages/du-03.png',
    //     // '/endorse/duImages/du-04.png',
    //     // '/endorse/duImages/du-05.png',
    //     // '/endorse/duImages/du-06.png',
    //     // '/endorse/duImages/du-07.png',
    //     // '/endorse/duImages/du-08.png',
    //     // '/endorse/duImages/du-09.png',
    //     // '/endorse/duImages/du-10.png',
    //     // '/endorse/duImages/du-11.png',
    //     // '/endorse/duImages/du-12.png',
    //     // '/endorse/duImages/du-13.png',
    //     // '/endorse/duImages/du-14.png',
    //   ]
    // },
    // {
    //   para: "UNIVERSITY OF LUCKNOW",
    //   img: luck,
    // },
    {
      para: "YOUTH20",
      img: uniFive,
      modal:'',
      link:'',
    },
    {
      para: "All India Council For Technical Education",
      img: "/aicte.png",
      modal:'',
      showLink:false,
      downloadPdf:true,
      link:'',
      pdfLink:'/endorse/pdf/aicte.pdf',
      showModal:false,
      pdfImages:[
        // '/endorse/aicte-1.png',
      ]
    },
    {
      para: "Ekal Abhiyan",
      img:"/logoscroll/logo4.svg",
      modal:'',
      link:'',
    },
    
    // {
    //   para: "ARMY WELFARE EDUCATION SOCIETY",
    //   img: unione,
    // },
    // {
    //   para: "UNIVERSITY GRANTS COMMISSION",
    //   img: uniTwo,
    // },
    // {
    //   para: "UNIVERSITY GRANTS COMMISSION",
    //   img: uniThree,
    // },
    // {
    //   para: "UNIVERSITY GRANTS COMMISSION",
    //   img: uniThree,
    // },
    // {
    //   para: "UNIVERSITY GRANTS COMMISSION",
    //   img: uniFive,
    // },
    // {
    //   para: "UNIVERSITY GRANTS COMMISSION",
    //   img: uniSix,
    // },
    // {
    //   para: "ARMY WELFARE EDUCATION SOCIETY",
    //   img: unione,
    // },
    // {
    //   para: "UNIVERSITY GRANTS COMMISSION",
    //   img: uniTwo,
    // },
    // {
    //   para: "UNIVERSITY GRANTS COMMISSION",
    //   img: uniThree,
    // },
    // {
    //   para: "UNIVERSITY GRANTS COMMISSION",
    //   img: uniThree,
    // },
    // {
    //   para: "UNIVERSITY GRANTS COMMISSION",
    //   img: uniFive,
    // },
    // {
    //   para: "UNIVERSITY GRANTS COMMISSION",
    //   img: uniSix,
    // },
  ];
  return (
    <>
      <div className="container satya position-relative overflow-hidden">
        <img
          src={satyaimg}
          alt="logo"
          id="satyaimg"
          className="position-absolute"
        />
        <img
          src={unaUnivlogo}
          alt="logo"
          id="unaUni"
          className="position-absolute"
        />

        <h2 className="text-center fw-bolder mb-5 fs-1">
        Endorsed by government ministries and departments
        </h2>
        <ShowMorePagination
          items={itemsArray}
          defaultItemsToShow={12}
          itemsPerPage={6}
        />
      </div>
    </>
  );
};

export default Universities;
