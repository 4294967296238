import React from "react";
import Artestic from "../../assets/Raisec/personality_type_icons/artestic.png";
import conventional from "../../assets/Raisec/personality_type_icons/conventional.png";
import enterprising from "../../assets/Raisec/personality_type_icons/enterprising.png";
import investaging from "../../assets/Raisec/personality_type_icons/investagating.png";
import realestic from "../../assets/Raisec/personality_type_icons/realesticPng.png";
import social from "../../assets/Raisec/personality_type_icons/social.png";
import downArrow from "../../assets/Raisec/Test_Card_Background/doubleArrow.png";

import {
  Container,
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import {
  FaHammer,
  FaMicroscope,
  FaPaintBrush,
  FaUsers,
  FaBullhorn,
  FaClipboardList,
  FaCheckCircle,
  FaTasks,
  FaCalculator,
  FaChartLine,
  FaFileAlt,
} from "react-icons/fa";

const AboutRiasec = () => {
  const lineChartData = [
    { name: "Realistic", Score: 6 },
    { name: "Investigative", Score: 5 },
    { name: "Artistic", Score: 5 },
    { name: "Social", Score: 3 },
    { name: "Enterprising", Score: 5 },
    { name: "Conventional", Score: 6 },
  ];

  const pieChartData = [
    { name: "Realistic", value: 7 },
    { name: "Investigative", value: 5 },
    { name: "Artistic", value: 5 },
    { name: "Social", value: 2 },
    { name: "Enterprising", value: 6 },
    { name: "Conventional", value: 4 },
  ];

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#A28FF9",
    "#FF6688",
  ];

  const personalityTypes = [
    {
      label: "Realistic (R)",
      icon: realestic,
      description:
        'Realistic individuals, often referred to as "Doers," are practical, hands-on people who enjoy physical activity, working with tools, and solving tangible problems...',
      careers: [
        "Mechanical Engineer",
        "Carpenter",
        "Electrician",
        "Forestry Worker",
        "Chef",
      ],
    },
    {
      label: "Investigative (I)",
      icon: investaging,
      description:
        'Investigative individuals, known as "Thinkers," are analytical, curious, and intellectual...',
      careers: [
        "Scientist",
        "Data Analyst",
        "Software Developer",
        "Researcher",
        "Medical Doctor",
      ],
    },
    {
      label: "Artistic (A)",
      icon: Artestic,
      description:
        'Artistic individuals, or "Creators," are creative, imaginative, and expressive...',
      careers: [
        "Graphic Designer",
        "Writer",
        "Musician",
        "Film Director",
        "Fashion Designer",
      ],
    },
    {
      label: "Social (S)",
      icon: social,
      description:
        'Social individuals, known as "Helpers," are empathetic, compassionate, and enjoy working with people...',
      careers: [
        "Teacher",
        "Counselor",
        "Nurse",
        "Social Worker",
        "Human Resources Specialist",
      ],
    },
    {
      label: "Enterprising (E)",
      icon: enterprising,
      description:
        'Enterprising individuals, or "Persuaders," are ambitious, energetic, and confident...',
      careers: [
        "Entrepreneur",
        "Sales Manager",
        "Marketing Director",
        "Financial Advisor",
        "Real Estate Agent",
      ],
    },
    {
      label: "Conventional (C)",
      icon: conventional,
      description:
        'Conventional individuals, known as "Organizers," are detail-oriented, methodical, and enjoy working in structured environments...',
      careers: [
        "Accountant",
        "Administrative Assistant",
        "Bank Teller",
        "Logistics Coordinator",
        "Data Entry Clerk",
      ],
    },
  ];

  const steps = [
    {
      step: "Step 1",
      description:
        "Users give answers of all the 42 questions based on 6 personality types.",
      icon: <FaTasks color="#2356fe" size={24} />,
    },
    {
      step: "Step 2",
      description:
        "Scores are calculated for each personality type based on responses",
      icon: <FaCalculator color="#2356fe" size={24} />,
    },
    {
      step: "Step 3",
      description:
        "Scores are compared to determine the dominant personality types.",
      icon: <FaChartLine color="#2356fe" size={24} />,
    },
    {
      step: "Step 4",
      description:
        "Results are then combined to provide career recommendations",
      icon: <FaFileAlt color="#2356fe" size={24} />,
    },
  ];

  return (
    <Container>
      <Box textAlign="center" my={5}>
        <Typography
          variant="h3"
          sx={{
            color: "#000000",
            fontSize: {
              xs: "24px",
              sm: "28px",
              md: "32px",
              lg: "36px",
            },
            fontWeight: "bold",
          }}
        >
          <Typography
            variant="span"
            sx={{
              color: "#2356fe",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "36px",
              },
              fontWeight: "bold",
            }}
          >
            Understanding
          </Typography>{" "}
          the RIASEC Career Test
        </Typography>

        <Typography variant="subtitle1" mt={2}>
          The RIASEC Test is a scientifically-backed assessment designed to help
          you understand your personality and match it with potential career
          paths. Developed by psychologist John L. Holland, this test
          categorizes personalities into six broad types and combines your
          scores to provide a comprehensive career recommendation.
        </Typography>
      </Box>

      <Box my={5}>
        <Typography
          variant="h4"
          textAlign="start"
          mb={3}
          sx={{
            display: "inline",
            color: "#000000",
            fontSize: {
              xs: "24px",
              sm: "28px",
              md: "32px",
              lg: "36px",
            },
            fontWeight: "bold",
          }}
        >
          <Typography
            variant="span"
            sx={{
              color: "#2356fe",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "36px",
              },
              fontWeight: "bold",
            }}
          >
            How Your
          </Typography>{" "}
          Scores Were Combined
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={lineChartData}
            margin={{ top: 10, right: 50, left: -10, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              tick={{
                angle: window.innerWidth < 768 ? -45 : 0,
                fontSize: window.innerWidth < 768 ? 12 : 14,
              }}
              interval={window.innerWidth < 768 ? 0 : "preserveStartEnd"}
              height={window.innerWidth < 768 ? 80 : 60}
              tickFormatter={(label) =>
                label.length > 10 ? `${label.substring(0, 10)}...` : label
              }
            />
            <YAxis tick={{ fontSize: window.innerWidth < 768 ? 12 : 14 }} />
            <Tooltip
              contentStyle={{ fontSize: window.innerWidth < 768 ? 12 : 14 }}
            />
            <Legend
              wrapperStyle={{
                width: "100%",
                fontSize: window.innerWidth < 768 ? 12 : 14,
              }}
            />
            <Line
              type="monotone"
              dataKey="Score"
              stroke="#2356fe"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
        <Typography variant="body1" textAlign="center" mt={2}>
          The graph above illustrates how your scores across different
          personality types were combined to determine the best career match for
          you. The scores reflect your dominant traits and how they align with
          potential work environments.
        </Typography>
      </Box>

      <Box my={5}>
        <Typography
          variant="h4"
          textAlign="center"
          mb={3}
          sx={{
            color: "#000000",
            fontSize: {
              xs: "24px",
              sm: "28px",
              md: "32px",
              lg: "36px",
            },
            fontWeight: "bold",
          }}
        >
          <Typography
            variant="span"
            sx={{
              color: "#2356fe",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "36px",
              },
              fontWeight: "bold",
            }}
          >
            Distribution
          </Typography>{" "}
          of Your Personality Traits
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={pieChartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#8884d8"
              label
            >
              {pieChartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
        <Typography variant="body1" textAlign="center" mt={2}>
          The pie chart provides a visual breakdown of your personality traits.
          Each slice represents a different aspect of your personality, giving
          you a clear picture of your strengths and preferences.
        </Typography>
      </Box>

      <Box my={5}>
        <Typography
          variant="h4"
          textAlign="center"
          mb={4}
          sx={{
            color: "#000000",
            fontSize: {
              xs: "24px",
              sm: "28px",
              md: "32px",
              lg: "36px",
            },
            fontWeight: "bold",
          }}
        >
          <Typography
            variant="span"
            sx={{
              color: "#2356fe",
              margin: "0.5rem 0",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "36px",
              },
              fontWeight: "bold",
            }}
          >
            The Six
          </Typography>{" "}
          Personality Types
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {personalityTypes.map((type) => (
            <Grid item sm={12} md={12} key={type.label}>
              <Card sx={{ height: "100%" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "center",
                      padding: "1rem",
                      margin: "2rem 0",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "3rem",
                        color: "#2356fe",
                        padding: "0.2rem",

                        flexBasis: "0",
                        flexGrow: "1",
                      }}
                    >
                      <img
                        src={type.icon}
                        alt={type.label}
                        style={{
                          flexBasis: "0",
                          flexGrow: "1",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        fontSize: "3rem",
                        color: "#2356fe",
                        padding: "0.2rem",

                        flexBasis: "0",
                        flexGrow: "1",
                      }}
                    >
                      <Typography
                        variant="h5"
                        mt={2}
                        sx={{
                          color: "#2356fe",
                          fontWeight: "bold",
                          textAlign: "start",
                          margin: "0.5rem 0",
                        }}
                      >
                        {type.label}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ textAlign: "start", margin: "0.5rem 0" }}
                      >
                        {type.description}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        mt={2}
                        sx={{
                          color: "#000000",
                          fontWeight: "bold",
                          textAlign: "start",
                        }}
                      >
                        Careers Available :
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: "row",
                          gap: 1,
                          textDecoration: "underline",
                        }}
                      >
                        {type.careers.map((career) => (
                          <Box
                            key={career}
                            sx={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "#2356FE",
                              textTransform: "none",
                              borderRadius: "2px",
                              margin: "1px",
                              padding: "2px 4px",
                              display: "inline-flex",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            {career}
                          </Box>
                        ))}
                      </Box>
                    </div>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box my={5} textAlign="center">
        <Typography
          variant="h4"
          mb={3}
          sx={{
            color: "#000000",
            fontSize: {
              xs: "24px",
              sm: "28px",
              md: "32px",
              lg: "36px",
            },
            fontWeight: "bold",
          }}
        >
          <Typography
            variant="span"
            sx={{
              color: "#2356fe",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "36px",
              },
              fontWeight: "bold",
            }}
          >
            Why
          </Typography>{" "}
          Choose Us
        </Typography>
        <Typography variant="body1" paragraph>
          Our platform offers a unique and engaging experience to explore and
          understand your career potential. We leverage cutting-edge AI
          algorithms to provide personalized career recommendations based on
          your RIASEC test results. Our user-friendly interface makes it easy to
          navigate through your results and discover career options that match
          your personality.
        </Typography>
        <Typography variant="body1" paragraph>
          The seamless integration of our frontend and backend systems ensures a
          smooth and responsive experience. Our robust backend infrastructure
          securely handles all data, providing you with accurate and reliable
          results. Choose us to benefit from our expertise and innovative
          approach to career counseling.
        </Typography>
      </Box>

      <Box my={4}>
        <Typography
          variant="h4"
          mb={2}
          textAlign="center"
          sx={{
            color: "#000000",
            fontSize: {
              xs: "24px",
              sm: "28px",
              md: "32px",
              lg: "36px",
            },
            fontWeight: "bold",
          }}
        >
          <Typography
            variant="span"
            sx={{
              color: "#2356fe",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "36px",
              },
              fontWeight: "bold",
            }}
          >
            Why
          </Typography>{" "}
          Analyze Your Results
        </Typography>
        <Grid container spacing={2}>
          {steps.map((step, index) => (
            <Grid
              item
              xs={12}
              key={index}
              sx={{
                display: "flex",
                justifyContent: index % 2 === 0 ? "flex-start" : "flex-end",
                mb: 3,
              }}
            >
              <div
                className="arrow"
                style={{
                  borderTop: "2px dotted black",
                  borderLeft: "2px dotted black",
                  position: "relative",
                  display:
                    index === 3 ? "none" : index === 1 ? "block" : "none",
                  top: "5rem",
                  left: index === 1 ? "0" : "0",
                  height: "7rem",
                  width: "12rem",
                }}
              >
                {" "}
                <img
                  src={downArrow}
                  style={{
                    position: "relative",
                    // width: window.innerWidth < "500" ? "35px" : "",
                    // top: window.innerWidth < "500" ? "1.3rem" : "1.7rem",
                    // left: window.innerWidth < "500" ? "-2.3rem" : "-6rem",
                    width: window.innerWidth < "500" ? "35px" : "",
                    top:
                      window.innerWidth < "350"
                        ? "1.3rem"
                        : window.innerWidth < "500"
                        ? "1.3rem"
                        : "1.7rem",
                    left:
                      window.innerWidth < "350"
                        ? "-2.3rem"
                        : window.innerWidth < "500"
                        ? "-2.3rem"
                        : "-6rem",
                  }}
                  alt="down-arrow-direction"
                />
              </div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "2px solid #2356fe",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  padding: 2,
                  width: { sm: "100%", lg: "50%" },
                  maxWidth: "100%",
                  height: "150px",
                  boxSizing: "border-box",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: index % 2 === 0 ? "left" : "right",
                }}
              >
                {/* <Box
                  className="icon"
                  sx={{ display: "flex", alignItems: "center", mr: 2 }}
                >
                  {step.icon}
                </Box> */}
                <Box
                  className="step-text"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center ",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#000000",
                      fontWeight: "bold",
                    }}
                  >
                    {step.step}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      width: "100%",
                    }}
                    variant="body1"
                    mt={1}
                  >
                    {step.description}
                  </Typography>
                </Box>
              </Box>
              <div
                className="arrow"
                style={{
                  borderTop: "2px dotted black",
                  borderRight: "2px dotted black",
                  position: "relative",
                  display: index % 2 === 1 ? "none" : "block",
                  top: "5rem",
                  left: index === 1 ? "-38rem" : "0",
                  height: "7rem",
                  width: "12rem",
                }}
              >
                <img
                  src={downArrow}
                  alt="down-arrow-direction"
                  style={{
                    position: "relative",
                    width: window.innerWidth < "500" ? "35px" : "",
                    top: window.innerWidth < "500" ? "1.3rem" : "1.7rem",
                    left: window.innerWidth < "500" ? "2.5rem" : "6rem",
                  }}
                />
              </div>
            </Grid>
          ))}
        </Grid>
        <Box textAlign="center" mt={4}>
          <FaCheckCircle color="#2356fe" size={100} />
          <Typography
            variant="h5"
            mt={2}
            sx={{
              color: "#000000",
              fontSize: {
                xs: "24px",
                sm: "28px",
                md: "32px",
                lg: "36px",
              },
              fontWeight: "bold",
            }}
          >
            <Typography
              variant="span"
              sx={{
                color: "#2356fe",
                fontSize: {
                  xs: "24px",
                  sm: "28px",
                  md: "32px",
                  lg: "36px",
                },
                fontWeight: "bold",
              }}
            >
              Final
            </Typography>{" "}
            Report
          </Typography>
          <Typography variant="body1" mt={1}>
            The final report consolidates all your results and provides a
            comprehensive overview of your career recommendations based on your
            personality profile.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default AboutRiasec;
