import React, { useState, useEffect } from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	TextField,
	Pagination,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Grid,
	OutlinedInput,
} from "@mui/material";
import { useCareerContext } from "contexts/CareerContext";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { apiJson } from "api";
import YuvaLoader from "pages/Forum/components/Loader/YuvaLoader";

const StudentListView = () => {
	const [isHovered, setIsHovered] = useState(false);
	const [isHoveredById, setIsHoveredById] = useState(null);
	const {
		classInfo,
		studentsList,
		setSelectedSubNavOption,
		setSelectedStudentId,
	} = useCareerContext();
	const [searchQuery, setSearchQuery] = useState("");
	const [filteredStudents, setFilteredStudents] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [filter, setFilter] = useState("all");
	const itemsPerPage = 15; // Display 15 records per page
	const [csvData, setCsvData] = useState([]);
	const [loading, setLoading] = useState(false);
	const fetchCareersAndFeedbacks = async (student) => {
		try {
			// const [careersResponse, feedbackResponse] = await Promise.all([
			//     apiJson(`/api/v2/riasecTest/riasec-careers/${student.id}`),
			//     apiJson(`/api/v2/feedbacks/${student.id}`)
			// ]);
			const [careersResponse] = await Promise.all([
				apiJson(`/api/v2/riasecTest/riasec-careers/${student.id}`),
				// apiJson(`/api/v2/feedbacks/${student.id}`)
			]);
			const careers = careersResponse?.data?.result;
			// const feedbacks = feedbackResponse.data;
			return {
				newGenCareers: careers
					.slice(0, 3)
					.map((c) => c.careerName)
					.join(", "),
				traditionalCareers: careers
					.slice(3)
					.map((c) => c.careerName)
					.join(", "),
				// feedbacks: feedbacks.map(fb => fb.comment).join(' | ')
				feedbacks: "",
			};
		} catch (error) {
			console.error(`Error fetching data for student ${student.id}:`, error);
			return { newGenCareers: "", traditionalCareers: "", feedbacks: "" };
		}
	};

	useEffect(() => {
		const filtered = studentsList.filter((student) => {
			const fullName = (student.first_name + " " + (student.last_name || ""))
				.toLowerCase()
				.replace(/\s+/g, "");
			const matchesSearch = fullName.includes(
				searchQuery.toLowerCase().replace(/\s+/g, "")
			);

			const matchesFilter =
				filter === "all" ||
				(filter === "attempted" && student.result) ||
				(filter === "notAttempted" && !student.result);
			return matchesSearch && matchesFilter;
		});
		setFilteredStudents(filtered);
		setCsvData(filtered); // Update csvData with filtered students
		setCurrentPage(1);
	}, [searchQuery, studentsList, filter]);

	const paginatedStudents = filteredStudents.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	const downloadCSV = async () => {
		setLoading(true);

		const csvRows = await Promise.all(
			csvData.map(async (student, index) => {
				if (student.result) {
					// Sort the result keys by score in descending order
					const resultKeys = Object.keys(student.result)
						.sort((a, b) => student.result[b] - student.result[a])
						.map((key) => `${key.toUpperCase()}: ${student.result[key]}`);

					const { newGenCareers, traditionalCareers, feedbacks } =
						await fetchCareersAndFeedbacks(student);

					return [
						student.id,
						student.first_name + " " + (student.last_name || ""),
						student.father_name,
						`"${resultKeys.join(", ")}"`,
						`"${newGenCareers}"`,
						`"${traditionalCareers}"`,
						feedbacks,
					];
				} else {
					return [
						student.id,
						student.first_name + " " + (student.last_name || ""),
						student.father_name,
						"",
						"",
						"",
						"",
					];
				}
			})
		);
		const headers = [
			"Id",
			"Student Name",
			"Father Name",
			"Personality Traits",
			"New Generation Careers",
			"Traditional Careers",
			"Teacher Feedbacks",
		];
		const csvContent = [headers, ...csvRows].map((e) => e.join(",")).join("\n");
		const className = [classInfo?.className];

		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", `${className}.csv`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

		setLoading(false);
	};

	const handlePageChange = (event, value) => {
		setCurrentPage(value);
	};

	const handleViewResult = (id) => {
		setSelectedStudentId(id);
		setSelectedSubNavOption("View Result");
	};

	const handleBack = () => {
		setSelectedSubNavOption("Classes");
	};

	// TOOLTIPS: customization
	const TooltipCustomColor = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "#2356fe",
			color: "#ffffff",
			boxShadow: theme.shadows[1],
			fontSize: 11,
		},
	}));

	const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

	// Update `isMobile` state on window resize
	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth < 900);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<Box sx={{ paddingTop: 3 }}>
			{loading && <YuvaLoader setShow={false} show={loading} />}

			<button
				style={{
					// backgroundColor: "#2356fe",
					paddingInline: "1.5rem",
					paddingBlock: ".7rem",
					color: "#2356fe",
					cursor: "pointer",
					borderRadius: ".5rem",
					display: !isMobile && "none",
				}}
				onClick={() => handleBack()}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="25"
					height="25"
					fill="#2356fe"
					class="bi bi-arrow-left"
					viewBox="0 0 16 16"
					style={{ marginRight: 10 }}
				>
					<path
						fill-rule="evenodd"
						d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
					/>
				</svg>
				Back
			</button>

			{/* Search and Filter Container */}
			<Box sx={{}}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<p
						style={{
							fontSize: {
								xs: "24px",
								sm: "24px",
							},
							paddingTop: "15px",
						}}
					>
						<strong>
							<span style={{ color: "#6287FD" }}>Class 6</span> Student's List
						</strong>
					</p>

					<TextField
						label="Search by Name"
						variant="outlined"
						fullWidth
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						sx={{ width: "38%" }}
					/>
				</div>

				<div
					style={{
						marginBlock: "20px",
						display: "flex",
						flexWrap: "wrap",
						gap: "15px",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
						<FormControl
							variant="outlined"
							sx={{
								minWidth: {
									sm: "auto",
									md: "250px",
								},
								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										borderColor: "#2356FE",
									},
									"&:hover fieldset": {
										borderColor: "#2356FE",
									},
									"&.Mui-focused fieldset": {
										borderColor: "#2356FE",
									},
								},
							}}
							style={{ borderColor: "red" }}
						>
							<Select
								value={filter}
								onChange={(e) => setFilter(e.target.value)}
								input={<OutlinedInput />}
								style={{ height: 45, outlineColor: "red" }}
							>
								<MenuItem value="all">All Students</MenuItem>
								<MenuItem value="attempted">Attempted Test</MenuItem>
								<MenuItem value="notAttempted">Not Attempted Test</MenuItem>
							</Select>
						</FormControl>

						<Button
							variant="contained"
							onClick={downloadCSV}
							startIcon={<DownloadIcon />}
							sx={{
								backgroundColor: "#2356fe",
								padding: "1rem",
								color: "white",
								borderColor: "#2356fe",
								"&:hover": {
									backgroundColor: "white",
									color: "#2356fe",
								},
								textTransform: "Capitalize",
								height: 45,
							}}
							disabled={loading}
						>
							Detailed Data
						</Button>
					</div>

					<div
						style={{
							backgroundColor: "#2356fe",
							paddingInline: "1.5rem",
							paddingBlock: ".7rem",
							color: "white",
							cursor: "pointer",
							borderTopLeftRadius: "2rem",
							borderBottomLeftRadius: "2rem",
							display: isMobile && "none",
						}}
						onClick={() => handleBack()}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							class="bi bi-arrow-left"
							viewBox="0 0 16 16"
							style={{ marginRight: 10 }}
						>
							<path
								fill-rule="evenodd"
								d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
							/>
						</svg>
						Back
					</div>
				</div>
			</Box>

			{/* Students List Table */}
			<TableContainer component={Paper} sx={{ overflowX: "auto" }}>
				<Table sx={{ minWidth: 650 }} aria-label="students table">
					<TableHead>
						<TableRow>
							<TableCell
								sx={{
									fontWeight: "bold",
									backgroundColor: "#f5f5f5",
									color: "#333",
								}}
							>
								S No.
							</TableCell>

							<TableCell
								sx={{
									fontWeight: "bold",
									backgroundColor: "#f5f5f5",
									color: "#333",
								}}
							>
								Student Name
							</TableCell>

							<TableCell
								sx={{
									fontWeight: "bold",
									backgroundColor: "#f5f5f5",
									color: "#333",
								}}
							>
								Parent Name
							</TableCell>

							{filter !== "notAttempted" ? (
								<>
									<TableCell
										sx={{
											fontWeight: "bold",
											backgroundColor: "#f5f5f5",
											color: "#333",
										}}
									>
										Result
									</TableCell>

									<TableCell
										sx={{
											fontWeight: "bold",
											backgroundColor: "#f5f5f5",
											color: "#333",
										}}
									>
										Personalities in Descending Order
									</TableCell>
								</>
							) : (
								<>
									<TableCell
										sx={{
											fontWeight: "bold",
											backgroundColor: "#f5f5f5",
											color: "#333",
											textAlign: "center",
										}}
									>
										Notify Through Email
									</TableCell>
								</>
							)}
						</TableRow>
					</TableHead>

					<TableBody>
						{paginatedStudents.length ? (
							paginatedStudents.map((student, index) => {
								const topPersonalities = student.result
									? Object.keys(student.result)
											.sort((a, b) => student.result[b] - student.result[a])
											.slice(0, 6)
											.map((key) => key.toUpperCase())
									: [" ", " ", " ", " ", " ", " "];

								return (
									<TableRow key={student.id}>
										<TableCell>
											{(currentPage - 1) * itemsPerPage + index + 1}
										</TableCell>
										<TableCell>
											{student.first_name + " " + (student.last_name || "")}
										</TableCell>
										<TableCell>{student.father_name}</TableCell>

										{filter !== "notAttempted" ? (
											<>
												<TableCell>
													<button
														style={{
															border: "1px solid #2356fe",
															borderRadius: "5px",
															paddingBlock: "5px",
															paddingInline: "10px",
															textAlign: "center",

															backgroundColor:
																isHovered && isHoveredById === student.id
																	? "#2356fe"
																	: "transparent",
															color:
																isHovered && isHoveredById === student.id
																	? "white"
																	: "black",
															cursor: student.result
																? "pointer"
																: "not-allowed",
														}}
														variant="outlined"
														onClick={() => handleViewResult(student.id)}
														disabled={!student.result}
														onMouseEnter={() => {
															setIsHovered(true);
															setIsHoveredById(student.id);
														}}
														onMouseLeave={() => {
															setIsHovered(false);
															setIsHoveredById(null);
														}}
													>
														See Result
													</button>
												</TableCell>

												<TableCell>
													<Grid
														container
														spacing={1} // Controls the gap between boxes
														sx={{
															"@media (min-width: 200px)": {
																// Default behavior, show 6 boxes when width is >= 200px
																gridTemplateColumns: "repeat(6, 1fr)",
															},
															"@media (max-width: 199px)": {
																// When width < 200px, show 3 boxes
																gridTemplateColumns: "repeat(3, 1fr)",
															},
														}}
													>
														{topPersonalities.slice(0, 6).map(
															(
																personality,
																idx // Ensure max 6 items are shown
															) => (
																<Grid
																	item
																	key={idx}
																	xs={4} // Use xs={4} to ensure 3 boxes per row in a 12-column grid (12 / 3 = 4)
																	sm={2} // Use sm={2} to ensure 6 boxes per row in a 12-column grid (12 / 6 = 2)
																>
																	<TooltipCustomColor
																		title={
																			personality === "R"
																				? "REALISTIC"
																				: personality === "A"
																				? "ARTISTIC"
																				: personality === "I"
																				? "INVESTIGATIVE"
																				: personality === "S"
																				? "SOCIAL"
																				: personality === "E"
																				? "ENTERPRISING"
																				: personality === "C" && "CONVENTIONAL"
																		}
																		placement="top"
																		arrow
																	>
																		<Box
																			sx={{
																				width: "30px",
																				height: "30px",
																				display: "flex",
																				justifyContent: "center",
																				alignItems: "center",
																				textAlign: "center",
																				color: "#ffffff",
																				background: "#2356fe",
																				borderRadius: "6px",
																			}}
																		>
																			{personality}
																		</Box>
																	</TooltipCustomColor>
																</Grid>
															)
														)}
													</Grid>
												</TableCell>
											</>
										) : (
											<>
												<TableCell>
													<div
														style={{
															display: "flex",
															justifyContent: "space-between",
															gap: 5,
															width: "100%",
														}}
													>
														<p
															style={{
																color: "#28A745",
																fontSize: "14px",
																paddingTop: 5,
															}}
														>
															Email already sent by institute.
														</p>

														<button
															style={{
																border: "1px solid #28A745",
																borderRadius: "5px",
																paddingBlock: "5px",
																paddingInline: "10px",
																textAlign: "center",

																backgroundColor:
																	isHovered && isHoveredById === student.id
																		? "#28A745"
																		: "transparent",
																color:
																	isHovered && isHoveredById === student.id
																		? "white"
																		: "black",
																cursor: "pointer",
															}}
															variant="outlined"
															onMouseEnter={() => {
																setIsHovered(true);
																setIsHoveredById(student.id);
															}}
															onMouseLeave={() => {
																setIsHovered(false);
																setIsHoveredById(null);
															}}
														>
															Re-send
														</button>
													</div>
												</TableCell>
											</>
										)}
									</TableRow>
								);
							})
						) : (
							<TableRow>
								<TableCell colSpan={6} align="center">
									No students found.
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>

			{/* Pagination */}
			{filteredStudents.length > itemsPerPage && (
				<Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
					<Pagination
						count={Math.ceil(filteredStudents.length / itemsPerPage)}
						page={currentPage}
						onChange={handlePageChange}
						color="primary"
					/>
				</Box>
			)}
		</Box>
	);
};

export default StudentListView;
