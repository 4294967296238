import React from "react";
import ModelUnUnivercity from "./ModelUnUnivercity";
import YuvaRightSideBar from "./YuvaRightSideBar";
import "../Compon/munlandingpage.css"
import { apiJson } from "api";
import { useGlobalContext } from "global/context";
import { useEffect, useState } from "react";
import StudentpollPage from "pages/student/studentPoll/StudentpollPage";
import YuvaLoader from "pages/Forum/components/Loader/YuvaLoader";
import { MunEventScroller } from "./MunEventScroller";
import { Link, useNavigate } from "react-router-dom";
import PressReleaseYuva2 from "pages/LandingPage/Components/PressReleaseYuva2";
import LogoSlider from "pages/LandingPage/Components/LogoSlider";
import BlueGirl from "../SVG_Components/BlueGirl";
import { height } from "@xstyled/styled-components";
import GroupImage from "../SVG_Components/GroupImage";
import GroupStudents from "../SVG_Components/GroupStudents";

const ModelUNMain = () => {
  const {userData} = useGlobalContext();
  const [studentDetail, setStudentDetail] = useState();
  const [isRegister, setIsRegister] = useState();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate()
  const getStudentDetail = ()=>{
    setLoader(true)
    apiJson('api/v2/modelUn-student/getStudentParticipates/student/'+userData?.id)
    .then((res)=>{
      setStudentDetail(res?.data?.result)
      setLoader(false)
    })
    .catch((error)=>{
      console.log(error.message)
    })
  }

  const regValue = (val)=>{
    setIsRegister(val)
  }

  useEffect(()=>{
    getStudentDetail()
  },[])

  return (
    <>
      {loader ? (
        <YuvaLoader />
      ) : (
        <div>
          <div className="d-flex justify-content-center mt-3">
            <div className="model-un-slider d-flex flex-column-reverse align-items-center flex-md-row">
              <div className="d-flex flex-column model-un-innerleftItem position-relative">
                <h1 className="fs-2">
                  Fostering Solutions to Real-World Problems
                </h1>
                <p>
                  YMUN allows the youth to step into the shoes of global
                  diplomats and representatives of different nations. Through
                  poly-cultural research and the orchestration of real-life
                  situations, YMUN provides an invaluable opportunity to
                  understand international relations and diplomacy.
                </p>
                <div className="flex">
                  <Link to="/ymun" className="left-un-link pe-5">
                    Explore More
                  </Link>
                  <a href="#" className="right-un-link">
                    Watch Video
                  </a>
                </div>
                {isRegister ? (
                  <div
                    style={{ bottom: "-40px" }}
                    className="model-active-event d-flex flex-column align-items-center position-absolute"
                  >
                    <p className="m-0 p-0">Active Events</p>
                    <div className="modelun-slider-dots">
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="model-un-innerRightItem ">
                <img src="/modelUn/MG20.svg" alt="mg20" width="100%" />
              </div>
            </div>
          </div>

          {/* =============================univercity secion========================= */}

          <section className="modelun-univercity-section mt-5">
            <ModelUnUnivercity
              logo="/modelUn/ymlogo.svg"
              undpimg="/modelUn/united-nations-development-programme 1.svg"
              drdoimg="/modelUn/Defence_Research_and_Development_Organisation_Logo 1.svg"
              univercityimg="/modelUn/Bangalore_University_logo 1.svg"
              stdetail={studentDetail}
              isRegister={regValue}
            />
          </section>


          {/* ============================== what is Yuvamanthan section ============== */}

          <section className="whatIsYuva mt-5 overflow-x-hidden">
            <div className="d-flex justify-content-center">
              <div className="Yuva-main-Content">
                <div className="yuva-heading m-auto mt-5">
                  <img
                    src="/modelUn/PinkRectangle.svg"
                    alt="rectangle"
                    className="position-absolute"
                    width="100%"
                  />
                  <img
                    src="/modelUn/PurpleRectangle.svg"
                    alt="rectangle"
                    className="position-absolute"
                    width="95%"
                  />
                  <p className="position-absolute text-white mt-5 " >
                    What is Yuvamanthan <br /> Model United Nations
                  </p>
                </div>
                <div className="mt-5 whatuva-content">
                  <p className="text-center">
                    YMUN allows the youth to step into the shoes of global
                    diplomats and representatives of different nations and
                    organisations. Through poly-cultural research and the
                    orchestration of real-life situations, YMUN provides an
                    invaluable opportunity to understand international relations
                    and diplomacy.
                  </p>
                  <p className="mt-4 text-center cursor-pointer" onClick={()=>{navigate('/ymun')}}>
                    Read more about Yuvamanthan <br /> Model United Nations
                  </p>
                </div>

                {/* ===============================who can participate=============== */}

                <section>
                  <div className="yuva-heading position-relative m-auto mt-5">
                    <img
                      src="/modelUn/PinkRectangle.svg"
                      alt="rectangle"
                      className="position-absolute"
                      width="100%"
                    />
                    <img
                      src="/modelUn/PurpleRectangle.svg"
                      alt="rectangle"
                      className="position-absolute"
                      width="95%"
                    />
                    <p className="text-white position-absolute mt-5">
                      Who Can participate?
                    </p>
                  </div>
                  <div className="whocan-contents text-center mt-5">
                    <p className="m-0">FOR SCHOOLS</p>
                    <p className="mt-2">
                      Any student can participate <br /> who are in class group
                    </p>
                  </div>

                  <div className="studentIMages">
                    <div className="d-flex justify-content-center flex-column flex-md-row align-items-center gap-3">
                      <div className="position-relative">
                        <img src="/modelUn/pinkgirl.svg" alt="studentimg" />
                        <img
                          src="/modelUn/greenRectangle.svg"
                          alt="greenRectangle"
                          className="greenRectangle"
                        />
                        <p className="model-studentpara">
                          Class VI-VIII JUNIORS
                        </p>
                      </div>
                      <div className="position-relative">
                        <img src="/modelUn/redgirl.svg" alt="studentimg" />
                        <img
                          src="/modelUn/greenRectangle.svg"
                          alt="greenRectangle"
                          className="greenRectangles"
                        />
                        <p className="model-studentparas">
                          Class IX-X HIGH SCHOOL
                        </p>
                      </div>
                      <div className="position-relative">
                        {/* <img src="/modelUn/bluegirl.svg" alt="studentimg" style={{height:"140px"}}/> */}
                        <BlueGirl style={{height:'133px',}}/>
                        <img
                          src="/modelUn/greenRectangle.svg"
                          alt="greenRectangle"
                          className="greenRectangle"
                        />
                        <p className="model-studentpara mb-4 ms-1">
                          Class XI-XII SENIOR
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="whocan-contents text-center mt-5">
                    <p className="m-0">
                      FOR COLLEGES AND <br /> UNIVERSITIES  
                    </p>
                    <p className="mt-2">
                      All graduation, post-graduation and <br /> doctoral
                      students
                    </p>
                  </div>

                  {/* <img
                    src="/modelUn/groupStudents.svg"
                    alt="students"
                    id="enroll"
                    className="d-block m-auto"
                  /> */}
                  <GroupStudents/>
                </section>

                {/* =============================pathway of engagement======================== */}

                <div className="yuva-heading position-relative m-auto mt-5">
                  <img
                    src="/modelUn/PinkRectangle.svg"
                    alt="rectangle"
                    className="position-absolute"
                    width="100%"
                  />
                  <img
                    src="/modelUn/PurpleRectangle.svg"
                    alt="rectangle"
                    className="position-absolute"
                    width="95%"
                  />
                  <p className="text-white position-absolute mt-5">
                    Pathways to Engagement
                  </p>
                </div>
                <div className="pathway-engage mt-5">
                  <p className="text-center ">OPTIONS TO PARTICIPATE</p>
                </div>

                <div className="d-flex gap-5 justify-content-center flex-column flex-md-row align-items-center">
                  <div className="engage-boxs d-flex flex-column align-items-center p-0 justify-content-center">
                    <img
                      src="/modelUn/EngagePathway/Lecturer.svg"
                      alt="lectureImg"
                    />
                    <span className="m-0 fw-bolder">Delegate</span>
                  </div>
                  <div className="engage-boxs d-flex flex-column align-items-center p-0 justify-content-center">
                    <img
                      src="/modelUn/EngagePathway/Certificate.svg"
                      alt="lectureImg"
                    />
                    <span className="m-0 fw-bolder">Secretariat</span>
                  </div>
                  <div className="engage-boxs d-flex flex-column align-items-center p-0 justify-content-center">
                    <img
                      src="/modelUn/EngagePathway/Magazine.svg"
                      alt="lectureImg"
                    />
                    <span className="m-0 fw-bolder">Press Corps</span>
                  </div>
                </div>

                {/* ================================how to participate =================== */}

                <div className="yuva-heading position-relative m-auto mt-5">
                  <img
                    src="/modelUn/PinkRectangle.svg"
                    alt="rectangle"
                    className="position-absolute"
                    width="100%"
                  />
                  <img
                    src="/modelUn/PurpleRectangle.svg"
                    alt="rectangle"
                    className="position-absolute"
                    width="95%"
                  />
                  <p className="text-white position-absolute mt-5">
                    How to participate?
                  </p>
                </div>

                <div className="row mt-5 gap-5">
                  <div className="col-lg-6">
                    {/* <img
                      src="/modelUn/EngagePathway/groupimg.svg"
                      alt="groupimage"
                      width="100%"
                    /> */}
                    <GroupImage />
                  </div>
                  <div className="participateContent col-lg-5 p-4">
                    <p className="fw-bold">Steps to be followed</p>
                    <p className="d-flex align-items-center gap-2">
                      {" "}
                      <i className="bi bi-patch-check-fill color-light-blue fs-20px"></i>{" "}
                      <span className="color-light-blue">
                        {" "}
                        Register for the event
                      </span>
                    </p>
                    <p className="d-flex align-items-center gap-2">
                      <i class="bi bi-patch-check fs-20px"></i>{" "}
                      <span>
                        Selection of Participants by Appointed Faculty
                        Coordinator
                      </span>{" "}
                    </p>
                    {/* <p className="d-flex align-items-center gap-2"><i class="bi bi-patch-check fs-20px"></i> <span>Selection of delegates by teachers</span> </p> */}
                    <p className="d-flex align-items-center gap-2">
                      <i class="bi bi-patch-check fs-20px"></i>{" "}
                      <span>Ready to participate</span>{" "}
                    </p>
                    <button>Readiness check!</button>
                  </div>
                </div>

                

                {/* =========================what they will learn section ========================== */}

                <div className="yuva-heading position-relative m-auto mt-5">
                  <img
                    src="/modelUn/PinkRectangle.svg"
                    alt="rectangle"
                    className="position-absolute"
                    width="100%"
                  />
                  <img
                    src="/modelUn/PurpleRectangle.svg"
                    alt="rectangle"
                    className="position-absolute"
                    width="95%"
                  />
                  <p className="text-white position-absolute mt-5">
                    What they will learn?
                  </p>
                </div>

                <p className="whatlearnpara mt-5">
                  As a YMUN participant, you will learn the art of
                </p>

                <div className="row">
                  <div className="col-lg-6 d-flex m-0 p-0 gap-3 p-2">
                    <img
                      src="/modelUn/whatLearnImgs/youg people discussing the project.svg"
                      alt="whatlearn"
                      className="me-2"
                    />
                    <div className="whatlearnContens">
                      <p className="m-0 fw-bold mb-2">Team Building</p>
                      <p>
                        Work in groups to build a friendly rapport with other
                        teams to represent your team’s message.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-6 d-flex m-0 p-0 gap-3 p-2">
                    <img
                      src="/modelUn/whatLearnImgs/img2.svg"
                      alt="whatlearn"
                    />
                    <div className="whatlearnContens">
                      <p className="m-0 fw-bold mb-2">
                        Discussions With Consensus
                      </p>
                      <p>
                        When opinions are shared as insights you learn to
                        influence others and draw meaningful conclusions.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex m-0 p-0 gap-3 p-2">
                    <img
                      src="/modelUn/whatLearnImgs/img3.svg"
                      alt="whatlearn"
                    />
                    <div className="whatlearnContens">
                      <p className="m-0 fw-bold mb-2">
                        International Diplomacy
                      </p>
                      <p>
                        Influence actions through negotiations for a common
                        cause without rhetoric or upsetting others.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex m-0 p-0 gap-3 p-2">
                    <img
                      src="/modelUn/whatLearnImgs/img4.svg"
                      alt="whatlearn"
                    />
                    <div className="whatlearnContens">
                      <p className="m-0 fw-bold mb-2">
                        Multilateral Negotiations
                      </p>
                      <p>
                        Negotiating with countries on economics and
                        socio-politico issues with varied perspectives.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex m-0 p-0 gap-3 p-2">
                    <img
                      src="/modelUn/whatLearnImgs/img5.svg"
                      alt="whatlearn"
                      className="me-2"
                    />
                    <div className="whatlearnContens">
                      <p className="m-0 fw-bold mb-2">Strategy Building</p>
                      <p>
                        Working together with teams to create strategies that
                        impact the future of the world.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex m-0 p-0 gap-3 p-2">
                    <img
                      src="/modelUn/whatLearnImgs/img6.svg"
                      alt="whatlearn"
                    />
                    <div className="whatlearnContens">
                      <p className="m-0 fw-bold mb-2">Macroeconomics</p>
                      <p>
                        Researching and deliberating thoughts on economic
                        matters to create resilient economies.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex m-0 p-0 gap-3 p-2">
                    <img
                      src="/modelUn/whatLearnImgs/img7.svg"
                      alt="whatlearn"
                    />
                    <div className="whatlearnContens">
                      <p className="m-0 fw-bold mb-2">Research And Analysis</p>
                      <p>
                        Deep diving into topics and themes on global issues and
                        preparing content at short notice.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex m-0 p-0 gap-3 p-2">
                    <img
                      src="/modelUn/whatLearnImgs/img8.svg"
                      alt="whatlearn"
                    />
                    <div className="whatlearnContens">
                      <p className="m-0 fw-bold mb-2">Public Speaking</p>
                      <p>
                        Perfect the art of speaking to a large audience,
                        debating, and influencing juries with impactful content.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <LogoSlider/> */}
              </div>
              {/* =========================================Yuvarightsiderbar========================== */}

            </div>
              <YuvaRightSideBar />
          </section>
        </div>
      )}
    </>
  );
};

export default ModelUNMain;
