import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useCareerContext } from 'contexts/CareerContext';
import { useGlobalContext } from 'global/context';
import { Popup } from 'layout/Popup';

const CompleteLsmt = () => {
    const { role, getScore, setSelectedSubNavOption } = useCareerContext();
    const { userData } = useGlobalContext();
    const [message, setMessage] = useState('');
    const [score, setScore] = useState(null);

    useEffect(() => {
        const fetchCategoryScores = async () => {
            if (userData) {
                try {
                    const fetchScore = await getScore(userData.id, role);
                    console.log(fetchScore);
                    if (fetchScore) {
                        setScore(fetchScore); // Set the fetched score
                        setMessage('You have completed the test successfully! We really appreciate your effort.');
                    } else {
                        Popup("error", "Score Missing", "No scores received. Please try again.", 3000);
                        setSelectedSubNavOption("Test Cards");
                    }
                } catch (error) {
                    console.error("Error fetching scores:", error);
                    Popup("error", "", "Error occurred while fetching scores. Please try again.", 3000);
                }
            }
        };

        fetchCategoryScores();
    }, [getScore, userData, role]);

    return (
        <Box
            sx={{
                position: "relative",
                padding: "2rem",
                boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
                borderRadius: "1rem",
                border: "5px solid #2356fe",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "80vh",
                width: "100%",
                textAlign: "center",
            }}
        >
            {score &&  // Conditional rendering based on score
                <>
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: "bold",
                            color: "#2356fe",
                            fontSize: { xs: "2rem", sm: "2.5rem" },
                        }}
                    >
                        Assessment Report
                    </Typography>

                    <Typography
                        variant="h5"
                        sx={{
                            margin: "1rem 0",
                            color: "#000",
                        }}
                    >
                        {message}
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{
                            color: "#555",
                            margin: "1rem 0",
                        }}
                    >
                        Based on your results, we encourage you to continue exploring your interests and strengths. Remember, every effort counts towards your growth and success!
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{
                            color: "#555",
                        }}
                    >
                        Keep pushing forward, and know that your dedication and hard work are truly commendable!
                    </Typography>
                </>
            }
        </Box>
    );
};

export default CompleteLsmt;
