import React, { useEffect, useState } from "react";
import "../style/MunApplicants.css"
import Select from "react-select"
import ViewListIcon from '@mui/icons-material/ViewList';
import GridOnIcon from '@mui/icons-material/GridOn';
import Box from '@mui/material/Box';
import { DataGrid} from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Modal } from "react-bootstrap";
import { Avatar, Button } from "@mui/joy";
import { AIPPM_Role, COP28_countries, G20_Countries, G20_roles, UNDP_Countries, UNEP_Countries } from "global/AllCountryData";
import { ButtonGroup } from "pages/ModelUnParliament";
import { useGlobalContext } from "global/context";
import { apiJson, apiJsonAuth } from "api";
import { toast } from "react-toastify";
import SwapVertTwoToneIcon from '@mui/icons-material/SwapVertTwoTone';
import moment from "moment";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import ReportProblemTwoToneIcon from '@mui/icons-material/ReportProblemTwoTone';
import StatsCard from "components/elements/StatsCard";
import ActiveBadgePart from "components/ActiveBadgePart";

export const MunApplicants = () => {
  const columns = [
    // ... (your existing columns)
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'blue-header',
      width: 125,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* <div style={{ height: "35px", width: "45px" }}><img className='h-100 w-100' src={params.row?.avatarUrl ? params.row?.avatarUrl : '/modelUn/download3.png'} size="40" round={true} alt={params.row.name}/></div> */}
          <ActiveBadgePart overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
            backgroundColor={params.row?.studentId !== 0 ? "#44b700" : "red"}
            // sx={{bottom: "12%",right:"20%"}}
            >
          <Avatar
            size="sm"
            src={params.row?.avatarUrl ? params.row?.avatarUrl : '/modelUn/download3.png'}
            alt={params.row.name}
          />
          </ActiveBadgePart>
          <div className='ms-2'><span className='text-wrap' style={{ fontFamily: "Lexend" }}>{params.row?.name} </span></div>
        </div>
      ),
      //   headerClassName: 'blue-header',
    },
    {
      field: 'Class',
      headerName: 'Class',
      width: 84,
      headerClassName: 'blue-header',
      renderCell: (params) => {
        // Parse the JSON-formatted string in the "class" field
        const classInfo = params.row?.class ? JSON.parse(params.row.class) : null;

        // Access the "course" property
        const course = classInfo?.course || 'N/A';

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className='fw-400 fs-13-5px text-wrap' style={{ color: "#000000", fontFamily: "Lexend" }}>{course}</span>
          </div>
        );
      },

    },
    {
      field: 'age',
      headerName: 'Age',
      width: 74,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className='fw-400 fs-13-5px text-wrap' style={{ color: "#000000", fontFamily: "Lexend" }}>{params.row?.age}</span>
        </div>
      ),
      headerClassName: 'blue-header',

    },
    {
      field: 'gender',
      headerName: 'Gender',
      width: 96,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className='fw-400 fs-13-5px text-wrap' style={{ color: "#000000", fontFamily: "Lexend" }}>{params.row?.gender}</span>
        </div>
      ),
      headerClassName: 'blue-header',

    },
    {
      field: 'preferredComm',
      headerName: 'Preferred Committee',
      width: 180,
      renderCell: (params) => (
        <div>
          <span className='fw-400 fs-13-5px text-wrap text-center' style={{ color: "#000000", fontFamily: "Lexend" }}>{params.row?.preferredComm ? params.row?.preferredComm : "-"}</span>
        </div>
      ),
      headerClassName: 'blue-header',

    },
    {
      field: 'pref_designation',
      headerName: 'Preferred Designation',
      width: 180,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className='fw-400 fs-13-5px text-wrap' style={{ color: "#000000", fontFamily: "Lexend" }}>{params.row.pref_designation ? params.row?.pref_designation : "-"}</span>
        </div>
      ),
      headerClassName: 'blue-header',

    },
    {
      field: 'preferredRole',
      headerName: 'Preferred Country/Role',
      width: 180,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className='text-wrap fw-400 fs-13-5px text-wrap' style={{ color: "#000000", fontFamily: "Lexend" }}>{params.row?.preferredRole || params.row?.preferredCountry  ? params.row?.preferredRole 
          ||params.row?.preferredCountry  : "-"} </span>
        </div>
      ),
      headerClassName: 'blue-header',

    },
    {
      field: 'dateofApplication',
      headerName: 'Date of Application',
      width: 180,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className='fw-400 fs-13-5px text-wrap' style={{ color: "#000000", fontFamily: "Lexend" }}>{moment(params.row?.dateofApplication, "YYYY/MM/DD").format("DD/MM/YYYY")}</span>
        </div>
      ),
      headerClassName: 'blue-header',

    },
    {
      field: 'status',
      headerName: 'Status',
      width: 122,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className={`fw-400 fs-13-5px text-wrap text-capitalize ${params?.row?.status==="approved" ? "text-success" : params?.row?.status==="rejected" ?  "text-danger"  : "text-warning"}`} style={{ color: "#000000", fontFamily: "Lexend" }}>{params?.row?.status==="approved" ? "Participant" : params?.row?.status==="rejected" ?  "Rejected"  : "Applicant"}</span>
        </div>
      ),
      headerClassName: 'blue-header',

    },
    {
      field: 'reason_of_reject',
      headerName: 'Reason of Reject',
      width: 150,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className='fw-400 fs-13-5px text-wrap text-capitalize' style={{ color: "#000000", fontFamily: "Lexend" }}>{params?.row?.reason_of_reject ? params?.row?.reason_of_reject : " - "}</span>
        </div>
      ),
      headerClassName: 'blue-header',

    },
    {
      field: 'Actions',
      headerName: 'Actions',
      width: 92,

      renderCell: (params) => {
       return (
        <>
         
          <div style={{ display: 'flex', alignItems: 'center', height: "25px", cursor: "pointer", width: "25px" }}
            data-bs-toggle="dropdown">
            <MoreVertIcon sx={{ color: "#23538f", cursor: "pointer" }} className='h-100 w-100' />
            
          </div>

          {/*============ Action button is Define Here ==============*/}
          <div className="dropdown-menu border-0 rounded-3" style={{ boxShadow: "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px" }}>
            <div className="d-flex flex-column ">
              <div className="d-flex actionType">
                <span className={`ms-2 ${(params?.row?.status === "approved" && params?.row?.nominationType === "secure") ? "cursor-na" : null}`} onClick={() => {
                  if ((params?.row?.status !== "approved" && params?.row?.nominationType !== "secure"))
                    handlePreferredCommitee(params?.row?.id, params?.row?.registerId)

                }}>
                  {(params?.row?.status === "approved" && params?.row?.nominationType === "secure") ? (
                    <CheckTwoToneIcon color="success" className="me-2" />) : null}
                 Add as a participants
                </span>
              </div>
              <span className="actionType" onClick={() => {
                CommiteeUserDetails(params?.row?.id, params?.row?.registerId);
              }}>Edit Role</span>
              {params?.row?.status !== "rejected" ? (
                <span className="actionType border-bottom-0" onClick={() => {
                  handleOpenReject(params?.row?.id, params?.row?.registerId)
                }}>Reject Applicant</span>) : null}
            </div>

          </div>
          {/*============ Action button is End Here ==============*/}
     
        </>

      )},

    },
  ];
  const [value, setValue] = useState('')
  const { userData } = useGlobalContext();
  const [isNewDate, setIsNewDate] = useState(false)
  const [isloading, setIsLoading] = useState(false);
  const [activeFilter, setActiveFilter] = useState("all");
  const [allApplicants, setAllApplicants] = useState([])
  const [getAllCommiteeList, setAllCommiteeList] = useState([])
  const [filteredRows, setfilteredRows] = useState([])
  const [commiteeStudentDetails, setCommiteeStudentDetails] = useState({})
  const [getStudentId, setStudentId] = useState('');
  const [getRegisterId, setRegisterId] = useState('')
  const [studentType, setStudentType] = useState('')
  const [rejectModal, setRejectModal] = useState(false);
  const [eventDetail, setEventDetail] = useState({})
  const [committeType, setCommitteeType] = useState('')
  const [allSec, setAllSec] = useState([])
  const [allPc, setAllPc] = useState([])
  const [selectDeg,setSelectDeg] = useState("")
  const [delegateRemainingSlot, setDelegateRemainingSlot] = useState()
  const [secRemainingSlot, setSecRemainingSlot] = useState()
  const [remainingPcSlots, setRemainingPcSlot] = useState();
  const [singleDetails, setSingleDetails] = useState({})
  const [availPartRoleSlot, setAvailPartRoleSlot] = useState(false)
  const [isSubmited,seIsSubmitted] = useState(false)
  const [selectCom,setSelectCom] = useState(participate)
  const [rejectError,setRejectError] = useState(false)
  const [getReason,setReason] = useState("")
  const [RejectDec,setRejectDec] = useState("")
  const [filterEb,setFilterEb] = useState([])
  const [filteredCountry,setFilteredCountry] = useState([])
  const [slotsCheck, setSlotsCheck] = useState({
    delegate: false,
    secretariat: false,
    pressCorp: false
  })
  const [showModal, setShowModal] = useState({
    secure: false,
    manually: false,
  });
  const [showError, setShowError] = useState({
    pref_country: false,
    pref_role: false,
    pref_committee: false,
    pref_designation: false,
  });
  const [initialFormValues, setInitialFormValues] = useState({
    committeeId:"",
    pref_country: '',
    pref_role: '',
    pref_designation:'',
    pref_committee: '',
    secretariatsId: '',
    secretariatType: '',
    pressCorpsId: '',
  });
  const [cardModalShow, setCardModalShow] = useState(false)
  const [buttonText, setButtonText] = useState('');
  const handleCardModalShow = (buttonText) => {
    setButtonText(buttonText);
    setCardModalShow(true);
  };

  const handleCardModalClose = () => {
    setCardModalShow(false);
  };

  ///============  A function for check slots with role  =========\\
  const slotCheckerWithRole = (type) => {
    setSlotsCheck({
      delegate: type === "delegate" ? !slotsCheck.delegate : false,
      secretariat: type === "secretariat" ? !slotsCheck.secretariat : false,
      pressCorp: type === "pressCorp" ? !slotsCheck.pressCorp : false,
    });
  }
  //======= A function for open the secure nomination and assign manually modal =========\\
  const handleModal = (modal) => {
    setShowModal({
      secure: modal === "secure" ? !showModal.secure : false,
      manually: modal === "manually" ? !showModal.manually : false,

    })
  };
  ///======== handleChangeCommitteeType details ===
  const handleChangeCommitteeType = (e) => {
    setSelectDeg("")
    setStudentType(e.title)
    if (e.title === committeType) {
      setSelectDeg(commiteeStudentDetails?.studentDetail?.pref_designation)
      setInitialFormValues({
        committeeId: commiteeStudentDetails?.studentDetail?.committeeId || '',
        pref_country: commiteeStudentDetails?.studentDetail?.pref_country || '',
        pref_role: commiteeStudentDetails?.studentDetail?.pref_role || '',
        pref_designation: commiteeStudentDetails?.studentDetail?.pref_designation || '',
        pref_committee: commiteeStudentDetails?.studentDetail?.pref_committee || '',
        secretariatsId: commiteeStudentDetails?.studentDetail?.secretariatsId || '',
        secretariatType: commiteeStudentDetails?.studentDetail?.secretariatType || '',
        pressCorpsId: commiteeStudentDetails?.studentDetail?.pressCorpsId || '',
      })

    } else {
        setInitialFormValues({
          committeeId: '',
          pref_country: '',
          pref_role: '',
          pref_committee: e?.title,
          secretariatsId: '',
          secretariatType: '',
          pressCorpsId: '',
          pref_designation:''
        });
    }

  }


  //=========Get all Secretariat api call here ============\\

  const getAllSec = () => {
    apiJson(`api/v2/modelUn-institute/getAllSecDetails/institute/${userData?.id}/registerId/${eventDetail?.id}`)
      .then((res) => {
        setAllSec(res.data.updatedAllSec)
        setSecRemainingSlot(res.data?.totalRemainingSlots)
      })
      .catch((error) => {
        console.log(error.message)
      })
  }

  //=========Get all Press corp api call here ============\\
  const getAllPc = () => {
    apiJson(`api/v2/modelUn-institute/getAllPcDetails/institute/${userData?.id}/registerId/${eventDetail?.id}`)
      .then((res) => {

        setRemainingPcSlot(res.data.remaining_slots)
        setAllPc(res.data.allSec)
      }).catch((error) => {
        console.log(error.message)
      })
  }
  ///=========== Secretariat  change  handler =========\\
  const handleSecretariatChange = (selectedOption) => {
    setShowError({ ...showError, pref_designation: false })
    setInitialFormValues({
      ...initialFormValues,
      pref_designation: selectedOption?.role,
      secretariatsId: selectedOption?.value,
      secretariatType:"Organisation Committee"
    })
  }

  ///=========== Prescorp change handle=====\\
  const handlePcChange = (selectedOption) => {
    setShowError({ ...showError, pref_designation: false })
    setInitialFormValues({
      ...initialFormValues,
      pref_designation: selectedOption?.role,
      pressCorpsId: selectedOption?.value,
    })
  }
  // ========== Handle Change Event Date Modal Show =============\\
  const handleChangeDate = () => {
    setIsNewDate(true)
  }
  // ========== Handle Change Event Date Modal Show =============\\
  const handleChangeHideDate = () => {
    setIsNewDate(false)
  }
  /// ============= Event Details Api  ==========
  const geteventDetail = () => {
    apiJson('api/v2/modelUn-student/getEventDetails/institute/' + userData?.id)
      .then((res) => {
        setEventDetail(res?.data?.result)

      })
      .catch((error) => {
        console.log(error.message)
      })
  }
  // handle open reject modal open handle = \\
  const handleOpenReject = (studentId, registerId) => {
    setRejectModal(true);
    setStudentId(studentId);
    setRegisterId(registerId);

  }

  // ======= Handle Hide reject Modal handle ======= \\
  const hideRejectmodal = () => {
    setRejectModal(false);
  }

  //=== Fetch all commitee by Institute Id  for commitee select ======== \\\
  const getAllCommitee = async () => {
    try {
      const getCommiteeList = await apiJsonAuth.get(`/api/v2/modelUn-student/getAllCommitteenew/${userData?.id}`)
      const data = getCommiteeList?.data?.updatedAllDelegate?.map((i) => ({
        value: i?.type,
        title: i?.type
      })) || [];
  
      const uniqueValuesSet = new Set([...data.map(item => item.value),...selectCom.map(item => item.value)]);

      // Convert Set back to an array
      const concatenatedArray = Array.from(uniqueValuesSet, value => ({
        value,
        title: value
      }));

      setSelectCom(concatenatedArray)
      setAllCommiteeList(getCommiteeList?.data?.updatedAllDelegate)
      setDelegateRemainingSlot(getCommiteeList?.data?.totalRemainingSlots)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (userData?.id) {
      getAllCommitee();
      geteventDetail()

      setValue(eventDetail?.date_proposed || '')
    }
  }, [])

  useEffect(() => {
    if (eventDetail?.id) {
      getAllSec();
      getAllPc();
    }
  }, [eventDetail?.id])

  // Define the onChange event handler to update the selected committee type
  const handleCommitteeTypeChange = (selectedOption) => {
    setShowError({
      pref_committee: false,
      pref_country: false,
      pref_role: false,
      pref_designation:false
    })
      setInitialFormValues({
        ...initialFormValues,
        pref_committee: selectedOption.title
      });

  };


  /// ============= Handle change role =================== \\
  const handleChangeRole = (selectedOption) => {
    setShowError({
      ...showError,
      pref_role: false,
    })
    setInitialFormValues({ ...initialFormValues, pref_role: selectedOption.value })
  }

  // ============== country change handler  =======
  const HandleCountryChange = (selectedOption) => {
    setShowError({ ...showError, pref_country: false })
    setInitialFormValues({ ...initialFormValues, pref_country: selectedOption.name })
  }
    useEffect(()=>{
  if(selectDeg==="Delegate"){
   filterCountry(studentType)
  }

  },[studentType,selectDeg])
  //============ Get committee type function ============\\

  const handleCommitteeDesignation = (selectedOption)=>{
    setSelectDeg(selectedOption.name)
    setShowError({ ...showError, pref_designation: false })

    if(["Organisation Committee","Jury","Press Corps","Coordinator"].includes(selectedOption.name) && selectedOption.name ==="Delegate"){
      setInitialFormValues({
        ...initialFormValues,
        pref_designation: selectedOption.name
      });
    }else{
     
      let data = getAllCommiteeList?.find((i)=>i?.type == studentType ) 
      if(selectedOption.name ==="Delegate"){
        setInitialFormValues({
          ...initialFormValues,
          secretariatType:"",
          secretariatsId:"",
          committeeId: data.committeeId,
          pref_designation: selectedOption.name
        });
      }else{
        let secData = allSec?.find((i)=>i?.role ===  selectedOption?.name)
        setInitialFormValues({
          ...initialFormValues,
          committeeId:"",
          pref_designation: selectedOption.name,
          secretariatsId:secData?.value,
          secretariatType:"Executive Board"

        });
      }
    }
  }
const handleCheckExecutive = (studentType,selectDeg)=>{
  const filterExe = allApplicants?.filter((i)=>i?.preferredComm ===studentType && i?.pref_designation ===selectDeg && i?.status==="approved" ) 
  setFilterEb(filterExe)
}
useEffect(()=>{
if(studentType && selectDeg !=="Delegate"){
  handleCheckExecutive(studentType,selectDeg);
}
},[studentType,selectDeg])
  // =========== get commitee details list by student Id and register Id==========\\
  const CommiteeUserDetails = async (studentId, registerId) => {
    setStudentId(studentId);
    setRegisterId(registerId);
    try {
      const res = await apiJsonAuth.get(`api/v2/modelUn-institute/getParticipateDetail/student/${studentId}/register/${registerId}`)
      let updatedData = res?.data
      setSelectDeg(updatedData?.studentDetail?.pref_designation)
      setCommiteeStudentDetails(updatedData)
      if(updatedData?.studentDetail?.pref_designation ==="Jury Member" ){
        setStudentType("Jury")
        setCommitteeType("Jury")
      }else{
        setStudentType(updatedData?.studentDetail?.pref_committee)
        setCommitteeType(updatedData?.studentDetail?.pref_committee)
      }
      setInitialFormValues({
        pref_designation:updatedData?.studentDetail?.pref_designation || '',
        committeeId: updatedData?.studentDetail?.committeeId || '',
        pref_country: updatedData?.studentDetail?.pref_country || '',
        pref_role: updatedData?.studentDetail?.pref_role || '',
        pref_committee: updatedData?.studentDetail?.pref_committee || '',
        secretariatsId: updatedData?.studentDetail?.secretariatsId || '',
        secretariatType: updatedData?.studentDetail?.secretariatType || '',
        pressCorpsId: updatedData?.studentDetail?.pressCorpsId || '',
      });
      if (res) {
        handleModal("manually")

      }
    } catch (error) {
      console.log(error, "detals error")
    }

  }


  // ===== Manualy commitee assign  Hide modal function ========\\ 
  const handleShowModal = () => {
    handleModal("manually");
  }
  // ====== Menual Update the user commmitte ================= \\
  const UpdateStudentDetails = async () => {
    seIsSubmitted(true)
    try {
      const res = await apiJsonAuth.put(`/api/v2/modelUn-institute/nominateStudent/student/${getStudentId}/register/${getRegisterId}`, {
        ...initialFormValues,
        status: "approved",
       nominationType: "manually",
       is_participant:true
      })
      if (res.status === 200) {
        seIsSubmitted(false)
        toast.dismiss()
        toast.success("Role assigned successfully.")
        handleModal("manually");
        getAllCommitee()
        getAllApplicants();
        getAllPc();
        getAllSec()
      }

    } catch (error) {
      seIsSubmitted(false)
      console.log(error)
    }
  }
  const onSubmit = () => {
    if (["UNEP","COP29","UNDP"].includes(studentType)) {
      setShowError({
        ...showError,
        pref_committee: initialFormValues.pref_committee === "",
        pref_designation: initialFormValues.pref_designation === "",
        pref_country: initialFormValues.pref_country === "",

      })} else if (["UNEP","COP29","UNDP","AIPPM","G20"].includes(studentType) && selectDeg ==="Delegate") {
        setShowError({
          ...showError,
          pref_role: initialFormValues.pref_role === "",
          pref_designation: initialFormValues.pref_designation === "",

        })
      } else if (studentType === "AIPPM" && selectDeg !=="Delegate") {
        setShowError({
          ...showError,
          pref_role: initialFormValues.pref_committee === "",
          pref_designation: initialFormValues.pref_designation === "",

        })}else if (studentType === "G20") {
        setShowError({
          ...showError,
          pref_country: initialFormValues.pref_country === "",
          pref_role: initialFormValues.pref_role === "",

        });
      }else{
        if(["Organisation Committee","Jury","Press Corps","Coordinator"].includes(studentType)){
          setShowError({
            ...showError,
            pref_committee: initialFormValues.pref_committee === "",
            pref_designation: initialFormValues.pref_designation === "",   
          })
        }
      }

      // Additional conditions for UpdateStudentDetails
      if (studentType === "AIPPM" && selectDeg === "Delegate"&& initialFormValues.pref_designation !== "" && initialFormValues.pref_role !== "") {
        console.log("hello1==>")
        UpdateStudentDetails();
        return
      }else if((["UNEP","COP29","UNDP","AIPPM","G20"].includes(studentType) && selectDeg !== "Delegate") &&  initialFormValues.pref_designation !== ""){
        if(filterEb?.length ===0 ){
        console.log("hello2==>")

          UpdateStudentDetails();
          return
        }else{
          toast.warning("This designation is also assign anyone.")
        }
      }else if (["UNEP","COP29","UNDP"].includes(studentType)  && initialFormValues.pref_country !== "" ) {
        console.log("hello3==>")

        UpdateStudentDetails();
        return
      }else if (studentType === "G20" && initialFormValues.pref_country !== "" && initialFormValues.pref_role !== "") {
        console.log("hello4==>")

        UpdateStudentDetails();
        return
      } else {
        if(["Organisation Committee","Jury","Press Corps","Coordinator"].includes(studentType) && initialFormValues.pref_committee !== "" && initialFormValues.pref_designation !== ""){
        console.log("hello5==>")
         
          UpdateStudentDetails();
          return
        }
      }
   
  }

  // ============= Get all Applicants details  api ===============\\
  const getAllApplicants = async () => {
    try {
      setIsLoading(true)
      let applicants = await apiJsonAuth.get(`api/v2/modelUn-student/getAllStudentsDetailNew/${userData?.id}`)
      setAllApplicants(applicants?.data?.studentsParticipants);
      setIsLoading(false)
    } catch (error) {
      console.log("Error on Applicants Api", error)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getAllApplicants();
  }, [])
  // =============== Filter  applicants with his status ================\\
  useEffect(() => {
    setfilteredRows(activeFilter === "all" ? (allApplicants && allApplicants) : allApplicants?.filter(row => row.status === activeFilter))
  }, [activeFilter])
  useEffect(() => {
    setfilteredRows(activeFilter === "all" ? (allApplicants && allApplicants) : allApplicants?.filter(row => row.status === activeFilter))
  }, [allApplicants])

  //======== custom style for select Input Feild  =============\\
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F9F9F9",
      border: "none",
      outline: "none"
    }),
  }
const checkRoleIsEmpty = (data) =>{
if(data?.secretariatType ==="Executive Board"){
let result = allApplicants?.some(item => (item.status === "approved" && item.preferredComm === data.preferredComm &&
      item.pref_designation === data.pref_designation))
     setAvailPartRoleSlot(result)
  }else if(data?.secretariatType ==="Judging and Jury"){
  let filterRes = allApplicants?.filter(item => (item.status === "approved" && item.preferredComm === data.preferredComm &&
      item.pref_designation === data.pref_designation))
      setAvailPartRoleSlot(filterRes?.length ===5)
    }else if(data?.secretariatType ==="Organisation Committee"){
   let filterRes = allApplicants?.filter(item => (item.status === "approved" && item.preferredComm === data.preferredComm &&
      item.pref_designation === data.pref_designation))
      setAvailPartRoleSlot(filterRes?.length ===10)
    }else if(["UNEP","COP29","UNDP","AIPPM"].includes(data?.preferredComm)){
    let filterRes
    if(data?.preferredComm ==="AIPPM"){
   filterRes = allApplicants?.some(item => (item.status === "approved" && item.preferredComm === data.preferredComm &&
      item.pref_designation === data.pref_designation && item?.preferredRole ===data?.preferredRole))
    }else{

   filterRes = allApplicants?.some(item => (item.status === "approved" && item.preferredComm === data.preferredComm &&
      item.pref_designation === data.pref_designation && item?.preferredCountry ===data?.preferredCountry))
      }
      setAvailPartRoleSlot(filterRes)
    }else{
     if(data.preferredComm==="Press Corps"){
      let filterRes = allApplicants?.filter(item => (item.status === "approved" && item.preferredComm === data.preferredComm))
      setAvailPartRoleSlot(filterRes?.length ===10)
      }
    }
    }
  //============ Find  Single user Data based on secure nomination action clicked  ====================\\
  const handlePreferredCommitee = (studentId, registerId) => {
    let data = allApplicants.find((i) => i.id === studentId && i.registerId === registerId)
    const classInfo = data?.class ? JSON.parse(data.class) : null;  
        // Access the "course" property
    const course = classInfo?.course || 'N/A';
    checkRoleIsEmpty({...data,course})
    setSingleDetails({...data,course})
    handleModal("secure");
    setStudentId(studentId);
    setRegisterId(registerId);
  }

  //=============  A function to secure an user ==========\\
  const handleSecureUser = async () => {
    seIsSubmitted(true)
    try {
      const assignRes = await apiJsonAuth.put(`/api/v2/modelUn-institute/nominateStudent/student/${getStudentId}/register/${getRegisterId}`, { 
        committeeId:singleDetails?.committeeId,
        secretariatsId:singleDetails?.secretariatsId,
        pressCorpsId:singleDetails?.pressCorpsId,
        pref_committee:singleDetails?.preferredComm,
        secretariatType:singleDetails?.secretariatType,
        status: "approved", nominationType: "secure" ,
       is_participant:true
      })
      if (assignRes?.status === 200) {
        seIsSubmitted(false)
        toast.success("Secure successfully..")
        getAllApplicants();
        getAllPc();
        getAllSec();
        getAllCommitee()
        handleModal("secure");
      }
    } catch (error) {
      seIsSubmitted(false)
      console.log(error, "Error in Secure nominate")
    }
  }

  //=============== Student Reject  api handler ================= \\

  const HandleRejectStudent = async () => {
    try {
      if(getReason === ""){
        setRejectError(true)
        return
      }
      const assignRes = await apiJsonAuth.put(`/api/v2/modelUn-institute/rejectApplicant/student/${getStudentId}/register/${getRegisterId}`, { role:userData?.role,instituteId:userData?.id,rejectOption:getReason,RejectDec })
      if (assignRes?.status === 200) {
        toast.success(assignRes?.data?.message)
        getAllApplicants();
        getAllPc();
        getAllSec()
        setRejectModal(false)
      }
    } catch (error) {
      console.log(error, "Error in Secure nominate")
    }
  }

  //============== CHANGE EVENT DATE API HANDLER ===============\\
  const changeEventdate = async () => {
    try {
      if (value) {
        const inputDate = new Date(value);
        const year = inputDate.getFullYear();
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        const day = inputDate.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        const res = await apiJsonAuth.put('api/v2/modelUn-student/updateEventDetails/institute/' + userData?.id, { formattedDate })
        if (res.status === 200) {
          geteventDetail()
          handleChangeHideDate()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

///==================  here we can the filter country when applicants available  ==================\\
  const filterCountry =(studentType)=>{
    let countries;
    if(studentType ==="UNEP"){
     countries = UNEP_Countries.filter(role => {
      // Check if the role is not in the applicants array
      const isRoleNotInApplicants = !allApplicants?.some(applicant => applicant?.preferredCountry === role.value);
      // Check if the preferredComm is 'AIPPM' and status is 'approved'
      const isPreferredCommAIPPM = allApplicants?.some(applicant => applicant?.preferredComm === 'UNEP' && applicant?.status === 'approved');
      return isRoleNotInApplicants && isPreferredCommAIPPM;
    })
  }else if(studentType ==="COP29"){
      countries = COP28_countries?.filter(role => {
        const isRoleNotInApplicants = !allApplicants?.some(applicant => applicant?.preferredCountry === role?.value);
        const isPreferredCommAIPPM = allApplicants?.some(applicant => applicant?.preferredComm === 'COP29' && applicant?.status === 'approved');
        return isRoleNotInApplicants && isPreferredCommAIPPM;
    })
  
  }else{
    if(studentType ==="UNDP"){
      countries = UNDP_Countries.filter(role => {
        const isRoleNotInApplicants = !allApplicants?.some(applicant => applicant?.preferredCountry === role?.value);
        const isPreferredCommAIPPM = allApplicants?.some(applicant => applicant?.preferredComm === 'UNDP' && applicant.status === 'approved');
        return isRoleNotInApplicants && isPreferredCommAIPPM;})
    }
  }
    if (countries && countries.length > 0) {
    setFilteredCountry(countries);
  } else {
    // If the filtered countries list is empty, set the original country list
    if (studentType === "UNEP") {
      setFilteredCountry(UNEP_Countries);
    } else if (studentType === "COP29") {
      setFilteredCountry(COP28_countries);
    } else {
      setFilteredCountry(UNDP_Countries);
    }
  }
  }
///==========For AIPPM ROLE OPTION ================ \\\
  const filterAIPPM_RoleOptions = (allApplicants) => {
    const filteredOptions = AIPPM_Role.filter(role => {
      const isRoleNotInApplicants = !allApplicants?.some(applicant => applicant?.preferredRole === role?.value);
      const isPreferredCommAIPPM = allApplicants?.some(applicant => applicant?.preferredComm === 'AIPPM' && applicant?.status === 'approved');
      return isRoleNotInApplicants && isPreferredCommAIPPM;
    });
  
    // Return the filtered options if not empty, otherwise return the original AIPPM_Role options
    return filteredOptions.length > 0 ? filteredOptions : AIPPM_Role;
  };


  return (
    <>
      <div className="col-11 col-md-10 mx-auto mt-4">
        <div className="row">
          <div>
            <span className="fs-32px-fw-600">Applicants</span>
          </div>
          {/*================== Rmaining Slot card component====================== */}
          <div className="row g-6 mb-6 mt-3">
            <div className="col-xl-4 col-sm-6 col-12 mb-3">
              <StatsCard
                title="Total Delegates"
                value={delegateRemainingSlot > 0 ? delegateRemainingSlot + " Slots " : "NA"}
                onAction={() => handleCardModalShow("delegate")}
                progressValue={0}
                // actionDisabled={delegateRemainingSlot}
              />
            </div>
            <div className="col-xl-4 col-sm-6 col-12 mb-3">
              <StatsCard
                title="Total Secretariats"
                value={secRemainingSlot > 0 ? secRemainingSlot + " Slots " : "NA"}
                color="success"
                progressValue={50}
                onAction={() => handleCardModalShow("secretariat")}
              />
            </div>
            <div className="col-xl-4 col-sm-6 col-12 mb-3">
              <StatsCard
                title="Total Press Corps"
                value={remainingPcSlots > 0 ? remainingPcSlots + " Slots " :  "NA"}
                color="warning"
                progressValue={80}
                onAction={() => handleCardModalShow("pressCorp")}
              />
            </div>

          </div>
          {/*============= Remaining slots card end here =============*/}

          <div className="d-flex flex-column flex-md-row justify-content-between">

            <div className="d-flex flex-column flex-md-row justify-content-between col-12 col-md-7">
            
              <div className="d-none col-12 col-md-4 d-flex justify-content-between align-items-center mb-3 mb-md-0">
                <div style={{ fontFamily: 'Lexend', wordWrap: 'break-word' }}>
                  <span className="fs-17-50px fw-400">Event</span>
                </div>
                <div>
                  <Select
                    styles={customStyles}
                  />
                </div>
              </div>


              <div className="d-none col-12 col-md-4  d-flex justify-content-between align-items-center mb-3 mb-md-0">
                <div style={{ fontFamily: 'Lexend', wordWrap: 'break-word' }}>
                  <span className="fs-17-50px fw-400">Comm</span>
                </div>
                <div>
                  <Select
                    styles={customStyles}
                  />
                </div>
              </div>

              <div className="d-flex d-none align-items-center mb-3 mb-md-0">
                <div className="d-flex justify-content-evenly align-items-center" style={{ background: "#F9ECFF", borderRadius: "7px", width: "81px", height: "40px" }}>
                  <div className='' style={{ width: "40px" }}><ViewListIcon sx={{ color: "#DBCFFF", width: "28px", height: "28px" }} /></div>
                  <div className='' style={{ width: "40px" }}><GridOnIcon sx={{ color: "#3D00FF", width: "28px", height: "28px" }} /></div>
                </div>
              </div>


            </div>

          </div>


          <div className='mt-3 d-flex flex-column flex-md-row justify-content-between'>
            <div><span className='fs-19px fw-600' style={{ color: "#32009C", fontFamily: 'Inter' }}>Nomination is set to be declared on {moment(eventDetail?.last_date, "YYYY/MM/DD").format("DD MMM, YYYY")}</span>
              <span className='fs-19px fw-600 ms-3 cursor-pointer' style={{ color: "#8F00FF", fontFamily: 'Inter' }} onClick={handleChangeDate}>Change Date</span>
            </div>
            <div className='d-none'>
              <div><span className='fw-400 fs-17-50px' style={{ fontFamily: 'Inter' }}>Auto Assign</span></div>

              {/* <div className='' style={{border: "1px solid red"}}> */}
              <div class="center mt-4 ms-5">
                <input type="checkbox" className="ParticipantsCheck" />
                {/* </div> */}
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-between mt-4'>
            <div className='d-flex justify-content-between ms-md-3 col-12 col-md-5'>
              {statuses?.map(filter => (
                <div key={filter}>
                  <span
                    className={`fw-400 fs-17-50px cursor-pointer pe-1 ${activeFilter === filter?.value?.toLowerCase() ? 'active-filter' : ''}`}
                    style={{ fontFamily: "Lexend" }}
                    onClick={() => setActiveFilter(filter?.value?.toLowerCase())}
                  >
                    {filter?.name}
                  </span>
                </div>
              ))}
            </div>

            <div>
              <span className='fw-400 d-none fs-17-50px' style={{ color: "#001AFF", fontFamily: "Lexend" }}>View Available Role</span>
            </div>
          </div>
        </div>
        {/* <hr className='mt-4'/> */}
        {isloading ? (<>
          <div className="d-flex justify-content-center my-4">
            <div className="spinner-border  text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </>) : (<>
          <Box sx={{ height: 462, width: '100%', marginTop: "15px" }}>
            <DataGrid
              rowHeight={70}
              disableColumnMenu
              rows={filteredRows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection={true}
              disableSelectionOnClick
            />
          </Box>
      </>
        )}


      </div>


      {/*======= Secure Nomination modal where we see the all details of Applicants====== */}
      <Modal
        size="lg"
        show={showModal.secure} onHide={() => handleModal("secure")}
      >
        <div className="px-4 py-3">
          {availPartRoleSlot ===true && (
            <div class="alert alert-danger alert-dismissible fade show" role="alert"><ReportProblemTwoToneIcon color="error" /> Slot is not available in this role.
              <strong className="cursor-pointer" onClick={() => handleModal("manually")}>Click here assign manually.</strong>
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>)}
          <h2 className="mb-3 fs-2 fw-normal" >Applicants Details</h2>
          <div className="row mt-3 flex-column-reverse flex-md-row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-md-4">
                  <img src={singleDetails?.avatarUrl ? singleDetails?.avatarUrl : '/modelUn/download3.png'} round={true} width={150} height={150} alt={singleDetails?.name} style={{ borderRadius: "50%", border: "2px solid yellow",minHeight:150, minWidth:150 }} />
                </div>
                <div className="col-12 col-md-8 mt-3 mt-md-0">
                  <h1 className="fs-3 fw-normal mb-2">{singleDetails?.name}</h1>
                  <span className="fw-semibold">Class :</span><span> {singleDetails?.course}</span><br />
                  <span className="fw-semibold">Gender :</span><span> {singleDetails?.gender}</span><br />
                  <span className="fw-semibold">age :</span><span> {singleDetails.age}</span>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12 col-md-6">
                  <div className="d-flex flex-column">
                    <div>
                      <span className="fw-semibold">Prefered Committee : </span><span>{singleDetails?.preferredComm}</span>
                    </div>
                    <div>
                      <span className="fw-semibold">Prefered Designation :</span> <span>{singleDetails?.pref_designation ? singleDetails?.pref_designation : "N/A"}</span>
                    </div>
                    <div>
                      <span className="fw-semibold">Prefered Country : </span><span>{singleDetails?.preferredCountry ? singleDetails?.preferredCountry : "N/A"}</span>
                    </div>
                    <div>
                      <span className="fw-semibold">Prefered Role :</span> <span>{singleDetails?.preferredRole ? singleDetails?.preferredRole : "N/A"}</span>
                    </div>

                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="d-flex flex-column">
                    <div>
                      <span className="fw-semibold">Email : </span><span>{singleDetails?.email}</span>
                    </div>
                    <div>
                      <span className="fw-semibold">Contact : </span><span>{singleDetails?.contact}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-md-4 mx-auto  mb-3 mb-md-0">

              <div className="d-flex flex-column mt-2">
                <div>
                  <span className="fw-semibold cursor-pointer " onClick={() => slotCheckerWithRole("delegate")} style={{ color: "rgb(161 87 255)" }}>Click to see Delegate : </span><span>{delegateRemainingSlot > 0 ? delegateRemainingSlot + " Slots available" : "Not available"}  </span>
                  {slotsCheck.delegate && (

                    getAllCommiteeList?.map((i) => {
                      return (
                        <div className="row bg-light px-2 py-2 rounded-3 mb-2">
                          <>
                            <div className="col-8">
                              {i.title}
                            </div>
                            <div className={`col-4 ${i?.remaining_slots < 1 ? "text-danger" : "text-success"}`}>
                              {i?.remaining_slots > 0 ? i?.remaining_slots + " slot" : "Not Available"}
                            </div>
                          </>

                        </div>
                      )
                    })
                  )}
                </div>
                <div>
                  <span className="fw-semibold cursor-pointer " onClick={() => slotCheckerWithRole("secretariat")} style={{ color: "rgb(161 87 255)" }}>Click to see Secretariats : </span><span>{secRemainingSlot > 0 ? secRemainingSlot + " Slots available" : "Not available"}</span>
                  {slotsCheck.secretariat && (
                    allSec?.map((i) => {
                      return (
                        <div className="row bg-light px-2 py-2 rounded-3 mb-2">
                          <>
                            <div className="col-8">
                              {i.role}
                            </div>
                            <div className={`col-4 ${i?.remaining_slots < 1 ? "text-danger" : "text-success"}`}>
                              {i?.remaining_slots > 0 ? i?.remaining_slots + " slot" : "Not Available"}
                            </div>
                          </>
                        </div>
                      )
                    })
                  )}

                </div>
                <div>
                  <span className="fw-semibold cursor-pointer" onClick={() => slotCheckerWithRole("pressCorp")} style={{ color: "rgb(161 87 255)" }}>Click to see Press Corps : </span><span>{remainingPcSlots > 0 ? remainingPcSlots + " Slots available" : "Not available"}</span>
                  {slotsCheck.pressCorp && (
                    allPc?.map((i) => {
                      return (
                        <div className="row bg-light px-2 py-2 rounded-3 mb-2">
                          <>
                            <div className="col-12">
                              {i.role}
                            </div>

                          </>

                        </div>
                      )
                    })
                  )}
                </div>
              </div>
            </div> */}
          </div>
          {availPartRoleSlot === false && (
            isSubmited ?(
              <div className="d-flex justify-content-end mt-3">
    
              <Button loading loadingPosition="start">
                Submitting
               </Button>
              </div>):(
            <div className="d-flex my-3  justify-content-center">
              <Button
                variant="soft"
                color="success"
                sx={{
                  padding: "8px 12px"
                }}
                onClick={() => handleSecureUser()}
              >Proceed to Secure Applicant </Button>
            </div>))}
        </div>

      </Modal>

      {/* //===========  Assign New role Modal Start here ============= */}
      <Modal
        size="lg"
        show={showModal.manually} onHide={() => handleModal("manually")}
      >

        <div className="px-3 py-4">
          <h2 className="fs-2 fw-semibold " style={{ color: "#000000" }}>Nominate</h2>

          <p className="fs-19px" style={{ color: "#989898" }}>Please provide the details of nominating a applicant.</p>

          <div className="fs-19px fw-500 mt-4" style={{ color: "#000000", marginBottom: "10px" }}>Select a committee  Type </div>

            <Select
              options={selectCom}
              isSearchable={false}
              defaultValue={selectCom.find((i) => i.title === studentType)}
              onChange={handleChangeCommitteeType}
              styles={{ ...customStyles, ...customSelectStyles }} getOptionLabel={
                (option) => {
                  return (
                    <>
                      <div className="d-flex justify-content-between
                     align-items-center">
                        <div className='delegate-secretariet-pressCorps fw-500' style={{ color: "#3A3A3A" }}>{option?.title}</div>
                      </div>
                    </>
                  )
                }
              }

            />

          { studentType === "Organisation Committee" ? (
            <div>
              <div className="col-12 col-md-12">
                  <>
                    <div className='mt-4'><span className='fs-19px fw-500' style={{ fontFamily: "Poppins" }}>Select a Designation</span></div>
                    <div>

                      <Select
                      options={allSec
                        .filter((i) => i.typeName === studentType)
                        .map((option) => ({
                          ...option,
                          isDisabled: allApplicants?.filter((i) => i?.pref_designation === "Member" && i?.status === "approved").length >= 10,
                        }))}
                      defaultValue={allSec.find((i) => i.role === initialFormValues?.pref_designation)}
                      isSearchable={false}
                      styles={{
                        ...customStyles, ...customSelectStyles,
                        option: (provided, state) => ({
                          ...provided,
                          cursor: state?.data.isDisabled ? "not-allowed" : "pointer",
                          opacity: state?.data.isDisabled ? 0.6 : 1,
                        }),
                      }}
                        onChange={handleSecretariatChange}
                        getOptionLabel={
                          (option) => {
                            return (
                              <>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className='delegate-secretariet-pressCorps fw-500' style={{ color: "#3A3A3A" }}>{option.role}</div>
                                  {/* <div className="d-flex justify-content-between">
                                    <div className='delegate-secretariet-pressCorps fw-500 me-4' style={{ color: "#BD00FF", fontFamily: "Poppins" }}>{option.remaining_slots ===0?"Not Available" : `${option.remaining_slots} Available`}</div>
                                    <div className="delegate-secretariet-pressCorps" style={{ fontWeight: "500", fontSize: "15px", color: "#ACACAC" }}>{option.applicants} {option.applicants > 1 ? "Applicants" : "Applicant"}</div>
                                  </div> */}

                                </div>
                              </>
                            )
                          }
                        }
                      />

                      {showError.pref_designation ? (
                        <div className="text-danger">Please Select a Designation</div>
                      ) : null}


                    </div>
                  </>

              </div>

              {/* // ========When Select  Secretariat then It is show the its Select DropDown=====\\ */}


            </div>
          ) : studentType === "Jury" ? (
            <>
            <div className="fs-19px fw-500 mt-4" style={{ color: "#000000", marginBottom: "10px" }}>Select a committee to be  assigned</div>
              <Select
                options={getAllCommiteeList}
                isSearchable={false}
                styles={{ ...customStyles, ...customSelectStyles }}
                defaultValue={getAllCommiteeList?.find((i) => {
                  return i.type === initialFormValues?.pref_committee
                })}
                onChange={handleCommitteeTypeChange}
                getOptionLabel={
                  (option) => {
                    return (
                      <>
                        <div className="d-flex justify-content-between
                          align-items-center">
                          <div className='fw-500 fs-19px' style={{ color: "#3A3A3A" }}>{option.title}</div>
                          <div className="d-flex justify-content-between">
                          
                          </div>
                        </div>
                      </>
                    )
                  }
                }
              />
              {showError.pref_committee ? (
                <div className="text-danger">Please Select a committee</div>
              ) : null}
              <div className='mt-4'><span className='fs-19px fw-500' style={{ fontFamily: "Poppins" }}>Select a Designation</span></div>
                    <div>
                      <Select
                        options={allSec
                          .filter((i) => i.typeName === "Judging and Jury")
                          .map((option) => ({
                            ...option,
                            isDisabled: allApplicants?.filter((i) =>i?.preferredComm===initialFormValues?.pref_committee && i?.pref_designation === "Jury Member" && i?.status === "approved").length >= 5,
                          }))}
                        defaultValue={allSec.find((i) => i.role === initialFormValues?.pref_designation)}
                        isSearchable={false}
                        styles={{
                          ...customStyles, ...customSelectStyles,
                          option: (provided, state) => ({
                            ...provided,
                            cursor: state?.data.isDisabled? "not-allowed" : "pointer",
                            opacity: state?.data.isDisabled? 0.6 : 1,
                          }),
                        }}
                    onChange={(selectedOption) => {
                      setShowError({ ...showError, pref_designation: false })
                      setInitialFormValues({
                        ...initialFormValues,
                        pref_designation: selectedOption?.role,
                        secretariatsId: selectedOption?.value,
                        secretariatType: "Judging and Jury"
                      })
                    }}
                        getOptionLabel={
                          (option) => {
                            return (
                              <>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className='delegate-secretariet-pressCorps fw-500' style={{ color: "#3A3A3A" }}>{option.role}</div>
                                </div>
                              </>
                            )
                          }
                        }
                      />

                      {showError.pref_designation ? (
                        <div className="text-danger">Please Select a Role</div>
                      ) : null}


                    </div>
              </>
          ): studentType === "Press Corps" ? (<>  <div className="col-12 col-md-12">
            <div className='mt-4'><span className='fs-19px fw-500' style={{ fontFamily: "Poppins" }}>Select a Designation</span></div>
            <div>
              <Select
                options={allPc.map((option) => ({
                  ...option,
                  isDisabled: remainingPcSlots === 0,
                }))}
                isSearchable={false}
                styles={{
                  ...customStyles,
                  ...customSelectStyles,
                  option: (provided, state) => ({
                    ...provided,
                    cursor: remainingPcSlots < 1 ? "not-allowed" : "pointer",
                    opacity: remainingPcSlots < 1 ? 0.6 : 1,
                  }),
                }}
                onChange={handlePcChange}
                defaultValue={allPc.find((i) => i.role === initialFormValues?.pref_designation)}
                getOptionLabel={(option) => (
                  <>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className='delegate-secretariet-pressCorps fw-500' style={{ color: "#3A3A3A" }}>{option.role}</div>
                      <div className="d-flex justify-content-between">
                        <div className='delegate-secretariet-pressCorps fw-500 me-4' style={{ color: "#BD00FF", fontFamily: "Poppins" }}>
                          {option.isAssigned ? "Not Available" : "Available"}
                        </div>
                        <div className="delegate-secretariet-pressCorps" style={{ fontWeight: "500", fontSize: "15px", color: "#ACACAC" }}>{option.applicants > 0 ? option.applicants + " " + "Applicants" : null}</div>
                      </div>
                    </div>
                  </>
                )}
                isOptionDisabled={(option) => option.isDisabled}
              />
              {showError.pref_designation ? (
                <div className="text-danger">Please Select a designation</div>
              ) : null}
            </div>
          </div></>) : ["UNEP","COP29","UNDP","AIPPM","G20"].includes(studentType)   ? (
            <>
              <div className="fs-19px fw-500 mt-4" style={{ color: "#000000", marginBottom: "10px" }}>Select a designation</div>
                    <Select
                      options={
                        Desination.map(option => ({
                          ...option,
                          isDisabled: option.name !== "Delegate" && allApplicants.some(applicant => applicant.pref_designation === option.name && applicant.status === 'approved')
                        }))
                      }
                      isSearchable={false}
                      styles={{
                        ...customStyles, ...customSelectStyles, option: (provided, state) => ({
                          ...provided,
                          cursor: state?.data?.isDisabled ? "not-allowed" : "pointer",
                          opacity: state?.data?.isDisabled ? 0.6 : 1,
                        }),
                      }}
                      defaultValue={Desination?.find((i) => {
                        return i.name === selectDeg
                      })}
                      onChange={handleCommitteeDesignation}
                      getOptionLabel={
                        (option) => {
                          return (
                            <>
                              <div className="d-flex justify-content-between
                          align-items-center">
                                <div className='fw-500 fs-19px' style={{ color: "#3A3A3A" }}>{option?.name}</div>
                                {/* <div className="d-flex justify-content-between">
                            <div className='fw-500 fs-19px me-4' style={{ color: "#BD00FF", fontFamily: "Poppins" }}>{option.remaining_slots}  Available</div>
                            <div style={{ fontWeight: "500", fontSize: "15px", color: "#ACACAC" }}>{option?.applicants} Applicants</div>
                          </div> */}

                              </div>
                            </>
                          )
                        }
                      }
                    />
              {showError.pref_designation ? (
                <div className="text-danger">Please Select a designation.</div>
              ) : null}

              {["UNEP","COP29","UNDP"].includes(studentType) &&  selectDeg ==="Delegate"  ? (
                <>
                  <div className='mt-4'><span className='fs-19px fw-500 mt-1' style={{ fontFamily: "Poppins", color: "#000000" }}>Select a country</span></div>
                  <Select
                    options={filteredCountry}
                    isSearchable={true}
                    styles={{ ...customStyles, ...customSelectStyles }}
                    defaultValue={studentType === "UNEP" ? UNEP_Countries.find((i) => i?.value === initialFormValues.pref_country) : studentType === "COP29" ? COP28_countries.find((i) => i?.value === initialFormValues.pref_country) : UNDP_Countries.find((i) => i?.value === initialFormValues.pref_country)}
                    onChange={HandleCountryChange}
                    getOptionLabel={
                      (option) => {
                        return (
                          <>
                            <div className='fw-500 fs-19px' style={{ color: "#3A3A3A" }}>{option.name}</div>
                          </>)
                      }}
                  />
                  {showError.pref_country ? (
                    <div className="text-danger">Please Select a Country</div>
                  ) : null}
                </>

              ) : null}

              {studentType === "AIPPM" &&  selectDeg ==="Delegate" && (
                <>
                  <div className='mt-3'><span className='fs-19px fw-500 mt-1' style={{ fontFamily: "Poppins", color: "#000000" }}>Select a role</span></div>
                  <Select
                          options={filterAIPPM_RoleOptions(allApplicants)}
                    isSearchable={true}
                    styles={{ ...customStyles, ...customSelectStyles }}
                    defaultValue={AIPPM_Role.find((i) => i?.value === initialFormValues.pref_role)}
                    onChange={handleChangeRole}
                    getOptionLabel={
                      (option) => {
                        return (
                          <>
                            <div className='fw-500 fs-19px' style={{ color: "#3A3A3A" }}>{option.value}</div>
                          </>)
                      }}
                  />
                  {showError.pref_role ? (
                    <div className="text-danger">Please Select a Role</div>
                  ) : null}
                </>
              )}

              {studentType === "G20" && selectDeg ==="Delegate" (
                <>
                  <div className='mt-4'><span className='fs-19px fw-500 mt-1' style={{ fontFamily: "Poppins", color: "#000000" }}>Select a country</span></div>
                  <Select
                    options={G20_Countries}
                    isSearchable={true}
                    styles={{ ...customStyles, ...customSelectStyles }}
                    defaultValue={G20_Countries[0]}
                    onChange={HandleCountryChange}
                    getOptionLabel={
                      (option) => {
                        return (
                          <>
                            <div className='fw-500 fs-19px' style={{ color: "#3A3A3A" }}>{option.name}</div>
                          </>)
                      }}
                  />
                  {showError.pref_country ? (
                    <div className="text-danger">Please Select a Country</div>
                  ) : null}
                  <div className='mt-4'><span className='fs-19px fw-500 mt-1' style={{ fontFamily: "Poppins" }}>Select a role</span></div>
                  <Select
                    options={G20_roles}
                    isSearchable={true}
                    styles={{ ...customStyles, ...customSelectStyles }}
                    defaultValue={G20_roles.find((i) => i?.value === initialFormValues.pref_role)}
                    onChange={handleChangeRole}
                    getOptionLabel={
                      (option) => {
                        return (
                          <>
                            <div className='fw-500 fs-19px' style={{ color: "#3A3A3A" }}>{option.role}</div>
                          </>)
                      }}
                  />
                  {showError.pref_role ? (
                    <div className="text-danger">Please Select a Role</div>
                  ) : null}
                </>
              )}
            </>
          ):(<><h6>Teacher coordinator </h6></>)}

          {isSubmited ?(
          <div className="d-flex justify-content-end mt-3">

          <Button loading loadingPosition="start">
            Submitting
           </Button>
          </div>) : (
          <ButtonGroup btnName="Submit" btnPrev="Cancel" handlePrev={handleShowModal} handleNext={onSubmit} /> )}
        </div>
      </Modal>

      {/* //======== Applicants Rejection  reason  Modal Start here ==========*/}
      <Modal
        size="lg"
        show={rejectModal}
        onHide={hideRejectmodal}
      >
        <div className="px-3 py-4">

        <div className="fs-6 fw-semibold" style={{ color: "#000000", marginBottom: "10px" }}>Reason of Reject Applicant</div>

        <Select
          options={reasons}
          isSearchable={false}
          onChange={(selectedOption)=>{
            setReason(selectedOption?.value)
            setRejectError(false)
          }}
          styles={{ ...customStyles, ...customSelectStyles }} getOptionLabel={
            (option) => {
              return (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className='fs-6 cursor-pointer fw-500' style={{ color: "#3A3A3A" }}>{option?.name}</div>
                  </div>
                </>
              )
            }
          }

        />
        {rejectError   &&  (
          <span className="text-danger fs-6">Please select a reason</span>
        )}
        {getReason ==="Any other (Please specify)" && (
          <>
          <label className="my-3 fs-6 fw-semibold" style={{ color: "#000000" }}>Please specify for reject applicant.</label>
          <textarea className="form-control rounded-3 shadow-none resize-none"  onChange={(e)=>setRejectDec(e?.target?.value)} placeholder="Write the reason." id="floatingTextarea" required></textarea>
          </>
        )}


          <ButtonGroup btnName="Submit" btnPrev="Cancel" handlePrev={hideRejectmodal} handleNext={HandleRejectStudent} />
        </div>
      </Modal>
      {/* ========= Change date modal start ========= */}
      <Modal
        size="md"
        show={isNewDate}
        onHide={handleChangeHideDate}
      >

        <div className="px-3 py-4">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker orientation="portrait" value={value} disablePast onChange={(newValue) => { setValue(newValue?.$d) }} />
          </LocalizationProvider>
          <ButtonGroup btnName="Submit" btnPrev="Cancel" handlePrev={handleChangeHideDate} handleNext={changeEventdate} />
        </div>
      </Modal>

      {/*====== Modal to view slots on behalf of a role. ====== */}
      <Modal
        size="lg"
        show={cardModalShow} onHide={handleCardModalClose}
      >
        <div className="p-5">
          <h2 className="mb-3 fs-2 fw-normal">Available Slots  in particular role</h2>
          <div className="d-flex flex-column mt-2">
            <div>
              {buttonText === "delegate" && (

                getAllCommiteeList?.map((i) => {
                  return (
                    <div className="row bg-light px-2 py-2 rounded-3 mb-2">
                      <>
                        <div className="col-8">
                          {i.title}
                        </div>
                        <div className={`col-4 ${i?.remaining_slots < 1 ? "text-danger" : "text-success"}`}>
                          {i?.remaining_slots > 0 ? i?.remaining_slots + " slot" : "Not Available"}
                        </div>
                      </>

                    </div>
                  )
                })
              )}
            </div>
            <div>
              {buttonText === "secretariat" && (
                allSec?.map((i) => {
                  return (
                    <div className="row bg-light px-2 py-2 rounded-3 mb-2">
                      <>
                        <div className="col-8">
                          {i.role}
                        </div>
                        <div className={`col-4 ${i?.remaining_slots < 1 ? "text-danger" : "text-success"}`}>
                          {i?.remaining_slots > 0 ? i?.remaining_slots + " slot" : "Not Available"}
                        </div>
                      </>
                    </div>
                  )
                })
              )}

            </div>
            <div>
              {buttonText === "pressCorp" && (
                allPc?.map((i) => {
                  return (
                    <div className="row bg-light px-2 py-2 rounded-3 mb-2">
                      <>
                        <div className="col-8">
                          {i.role}
                        </div>
                        <div className={`col-4 ${remainingPcSlots < 1 ? "text-danger" : "text-success"}`}>
                          {remainingPcSlots > 0 ? remainingPcSlots + " Slots available" : "Not available"}
                        </div>

                      </>

                    </div>
                  )
                })
              )}
            </div>
          </div>
        </div>

      </Modal>

    </>

  );
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "143px",
    height: "40px",
    padding: "3px",
    border: "none",
    background: "#F9ECFF",
    color: 'black'
    // boxShadow:'none'
  }),
};
const participate = [
  {
    value: "Organisation Committee",
    title: "Organisation Committee",
  },
  {
    value: "Jury",
    title: "Jury",
  },
  {
    value: "Press Corps",
    title: "Press Corps",
  },
  // {
  //   value: "Coordinator",
  //   title: "Coordinator",
  // }
];
const Desination = [
  {
    value: 1,
    name: "Delegate",
  },
  {
    value: 2,
    name: "Secretary-general",
  },
  {
    value: 3,
    name: "Chairperson",
  },
  {
    value: 4,
    name: "Vice-Chairperson",
  },
  {
    value: 5,
    name: "Rapporteur",
  },
]
const statuses = [
  { name: 'All', value: 'all' },
  { name: 'Applicant', value: 'pending' },
  { name: 'Participant', value: 'approved' },
  { name: 'Rejected', value: 'rejected' }
];
const reasons = [
  { name: "Late application", value: "Late application" },
  { name: "Applicant wants preferred role only", value: "Applicant wants preferred role only" },
  { name: "Does not fulfil participation criteria", value: "Does not fulfil participation criteria" },
  { name: "Not available on the event day", value: "Not available on the event day" },
  { name: "Did not join pre-event activities", value: "Did not join pre-event activities" },
  { name: "Any other (Please specify)", value: "Any other (Please specify)" }
];
