import React from 'react'

export const ModelUnPoliticalParties = ({data}) => {
  return (
    <div>
        <div className="modelUN_comittees">
              <div className="scroll-container d-flex">
                {data.map((ele) => {
                  return (
                    <>
                      <div className="py-4">
                  <div className="card margin-right-25px  px-6 py-2 border-0 shadow rounded-5" style={{width: "173px", height: "243px"}}>
                    <div className="card-body mx-auto d-flex px-0 py-0">
                      <div className="h-100 w-100" >
                        
                        <div className="margin-top-11px d-flex justify-content-center">
                          <div
                            style={{
                              objectFit: "cover",
                              height: "98px",
                              width: "98px",
                            }}
                          >
                            <img
                              className="h-100 w-100"
                              style={{
                                borderRadius: "64.5px",
                                border:"4px solid rgb(239 201 10)"
                              
                              }}
                              src={ele?.profile}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className="degination d-flex justify-content-center align-items-center"
                          style={{ background: "#FFE7AA", position: "relative", bottom: "8px"}}
                        >
                          <span
                            className="font-family-Lexend font-size-11px d-block text-center"
                            style={{ color: "#7B4300" }}
                          >
                           {ele?.role?.toUpperCase()}
                          </span>
                        </div>
                        <div className="height-48px-width-99px text-center">
                          <p className="font-size-19px font-family-Lexend font-weight-600 text-center">
                            {`${ele?.first_name} ${ele?.last_name}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                    </>
                  );
                })}
              </div>
            </div>
    </div>
  )
}
