import React, { useEffect, useState } from 'react';
import { Box, Button, TablePagination, Typography } from '@mui/material';
import MaterialTable from '@material-table/core';
import axios from 'axios';
import { CustomPagination } from '../Sewa-Institute/ProjectDetails';
import SearchIcon from '@mui/icons-material/Search';


const ProjectDetails = () => {
    const [projectData, setProjectData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("http://localhost:3000/api/approval/projects/mentor/66b4582a0741a05dd0888e70");
                setProjectData(response.data.projects || []);
            } catch (error) {
                console.error("Error fetching the project data:", error);
            }
        };
        fetchData();
    }, []);

        
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const columns = [
        // {
        //     title: "Project ID",
        //     field: "pt_id",
        //     headerStyle: {
        //         textAlign: "center",
        //         minWidth: '100px', 
        //     },
        //     cellStyle: {
        //         textAlign: 'center',
        //         wordWrap: 'break-word',
        //     }
        // },
        {
            title: "Project Name",
            field: "projectName",
            headerStyle: {
                textAlign: "center",
                minWidth: '180px', 
            },
            cellStyle: {
                textAlign: 'left',
                wordWrap: 'break-word',
            }
        },
        {
            title: "Category",
            field: "category",
            headerStyle: {
                textAlign: "center",
                minWidth: '120px', 
            },
            cellStyle: {
                textAlign: 'center',
                wordWrap: 'break-word',
            }
        },
        {
            title: "Participation Type",
            field: "participationType",
            headerStyle: {
                textAlign: "center",
                minWidth: '150px', 
            },
            cellStyle: {
                textAlign: 'center',
                wordWrap: 'break-word',
            }
        },
        {
            title: "Marks",
            field: "marks",
            headerStyle: {
                textAlign: "center",
                minWidth: '80px', 
            },
            cellStyle: {
                textAlign: 'center',
                wordWrap: 'break-word',
            }
        },
        {
            title: "Status",
            field: "status",
            headerStyle: {
                textAlign: "center",
                minWidth: '120px', 
            },
            cellStyle: {
                textAlign: 'center',
                wordWrap: 'break-word',
            }
        }
    ];

    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent:"center",
            marginTop:"2rem",
           // postion:"relative"
           
         }}>
         
         
         
         <Typography
                     sx={{
                         fontFamily: 'Poppins',
                         fontSize: { xs: "12px", sm: "14px"  },
                         fontWeight: 400,
                         lineHeight: {md:'24px' , xs:"20px"},
                         textAlign: 'left',
                         color: "#575757",
                         marginBottom:"3rem",
                     }}
                 >
Mentors can view detailed profiles of their assigned students, their project id and marks given all in one place for streamlined mentorship.

                 </Typography>
         
         
         
                 <Box
                 sx={{
                   position: "relative", 
                   background: "#f8f8ff",
                   borderRadius: "1.5rem",
                   width: "100%",
                   display: "flex",          
                   flexDirection: {xl :"row", sm:"column" , md:"row" },
                   justifyContent:"center",
                   alignItems: "center",
                   boxSizing: "border-box",
                   ":before": {
                     content: '""', 
                     background: "linear-gradient(122.52deg, rgba(35, 86, 254, 0.7) 1.25%, rgba(35, 86, 254, 0.1) 98.44%)", 
                     borderRadius: "1.5rem",
                     position: "absolute",
                     top: 0,
                     bottom: 0,
                     right: 0,
                     left: 0,
                     zIndex: "1", 
                     margin: "-1rem",
                   }
                 }}
                 >
        <Box
            sx={{
                borderRadius: "1rem",
                position: "relative", 
                zIndex:"5",
                backgroundColor: "var(--color-ghostwhite)",
                boxSizing: "border-box",
                width: "100%",
                overflow: "auto",
            }}
        >
         
         <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 5,
    // padding: { xs: "1rem", sm: "2rem 1.5rem" }, 
  }}
>
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
    //   alignItems: "center",
      padding: { xs: "1rem", sm: "1rem 1.5rem" }, 
      borderTopLeftRadius: "1rem",
      borderTopRightRadius: "1rem",
      position: "relative",
      backgroundColor: "#ffffff",
      zIndex: 5,
      flexDirection: { xs: "column", sm: "row" }, 
    }}
  >
    <Box sx={{ width: { xs: "100%", sm: "90%" } }}> 
      <Typography
        variant="h4"
        component="div"
        gutterBottom
        sx={{
          fontFamily: "Poppins",
          fontWeight: 700,
          textAlign: "left",
          position: "relative",
          zIndex: 5,
        }}
      >
        <span style={{ color: "blue", zIndex: 5 }}>Projects</span> Details
              </Typography>
      <Typography
        gutterBottom
        component="div"
        sx={{
          fontFamily: "Poppins",
          width:{md:"80%" , xs:"100%"},
          fontWeight: 400,
          lineHeight: "18.97px",
          fontSize: { xs: "10px", sm: "12px"  },
          textAlign: "left",
          color: "#6D6D6D",
          zIndex: 5,
        }}
      >
        Explore key information such as project’s name, project’s category, participation type, marks alotted and project id.
      </Typography>
    </Box>

    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent:"flex-start",
        // alignItems: "start",
        // justifyContent: "flex-end",
        position: "relative",
        zIndex: 5,
        // marginTop: { xs: "1rem", sm: 0 }, 
      }}
    >
      <div
        style={{
          position: "relative",
          zIndex: 5,
          width: { xs: "100%", sm: "16rem"  }, 
          height: "2.5rem",
          backgroundColor: "#e7e7e7",
          borderRadius: "0.8rem",
          display: "flex",
          alignItems: "center",
          padding: "0 12px",
        }}
      >
        <SearchIcon style={{ color: "#ACACAC", marginRight: "8px" }} />
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            fontSize: { xs: "0.9rem", sm: "1rem" }, 
            color: "#ACACAC",
            padding: "0",
            zIndex: 5,
          }}
        />
      </div>
    </Box>
  </Box>
</Box>

 

           <MaterialTable
    sx={{
        boxShadow: "none",
        width: "100%",
    }}
    options={{
        search: false,
        searchFieldStyle: {
            alignSelf: "center",
            width: '100%',
            fontSize: "0.9rem"
        },
        toolbar: false,
        showTitle: false,
        paging: false,
        headerStyle: {
            backgroundColor: '#F3F3F3',
            color: '#000000',
            padding: { xs: "0.5rem", sm: "1rem", md: "1.25rem" },
            fontSize: "0.8rem",
        },
        rowStyle: (rowData, index) => ({
            borderBottom: '1px solid #e0e0e0', 
            padding: '5px 0', 
            height:"60px"
        }),
    }}
    columns={columns}
    data={projectData.length >= 5 ? projectData : [...projectData, ...Array(5 - projectData.length).fill({})]}/>

            
           <Box
    sx={{
        display: 'flex',
        justifyContent: { xs: 'center', md: 'space-between' }, 
        alignItems: 'center',
        padding: '0.5rem 1rem',
        zIndex: "5",
        flexWrap: 'wrap', 
        gap: { xs: 2, md: 0 }, 
        backgroundColor:"#F3F3F3"   }}
>
    <TablePagination
        component="div"
        count={10}
        page={0}
        onPageChange={() => {}}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Rows per page"
        sx={{
            flexShrink: 0,
            '& .MuiTablePagination-toolbar': {
                paddingLeft: { xs: 0, md: '16px' }, 
            },
            '& .MuiTablePagination-selectLabel': {
                marginRight: 2,
            },
            '& .MuiSelect-select': {
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '6px 8px',
            },
        }}
        labelDisplayedRows={() => ''}
        slotProps={{
            actions: {
                nextButton: { sx: { display: 'none' } },
                backButton: { sx: { display: 'none' } },
                firstButton: { sx: { display: 'none' } },
                lastButton: { sx: { display: 'none' } },
            },
        }}
    />

    <CustomPagination
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        sx={{
            marginTop: { xs: '1rem', md: 0 }, 
            width: { xs: '100%', md: 'auto' }, 
        }}
    />
</Box>
        </Box>
        </Box>

        </Box>
    );
};

export default ProjectDetails;
