import React, { useContext, useEffect, useState } from "react";
import "../style/ModelUnstyle.css";
import { MUContext } from "../context/contextMU";
import { ModelUnCommittees } from "./ModelUnCommittees";
import { ModelUnPoliticalParties } from "./ModelUnPoliticalParties";
import { useNavigate } from "react-router-dom";
import Select from "react-select"
import { AIPPM_Role, COP28_countries, G20_Countries, G20_roles, UNDP_Countries, UNEP_Countries } from "global/AllCountryData";
import { apiJson, apiJsonAuth } from "api";
import { useGlobalContext } from "global/context";
import { toast } from "react-toastify";
import moment from "moment";
import CloudDoneTwoToneIcon from '@mui/icons-material/CloudDoneTwoTone';
import CloudCircleTwoToneIcon from '@mui/icons-material/CloudCircleTwoTone';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import PsychologyIcon from '@mui/icons-material/Psychology';
import AssignmentIcon from '@mui/icons-material/Assignment';

export const MUNmainStepper = () => {
  const { stepCount, setStepCount } = useContext(MUContext);
  const renderStepper = () => {
    switch (true) {
      case stepCount === 1:
        return (
          <div>
            <Step4 />
          </div>
        );
      case stepCount === 2:
        return (
          <div>
            <Step5 />
          </div>
        );
      case stepCount === 3:
        return (
          <div>
            <Step7 />
          </div>
        );
      // case stepCount === 4:
      //   return (
      //     <div>
      //       <Step4 />
      //     </div>
      //   );
      // case stepCount === 5:
      //   return (
      //     <div>
      //       <Step5 />
      //     </div>
      //   );
      // case stepCount === 6:
      //   return (
      //     <div>
      //       <Step6 />
      //     </div>
      //   );
      // case stepCount === 7:
      // return (
      //   <div>
      //     <Step7 />
      //   </div>
      // );
    }
  };
  return (
    <>
      {" "}
      <div>{renderStepper()}</div>
    </>
  );
};

const Step1 = () => {
  const { stepCount, setStepCount } = useContext(MUContext);

  const handleNext = () => {
    setStepCount(stepCount + 1);
  };
  const handlePrev = () => {
    setStepCount(stepCount - 1);
  };

  return (
    <>
      <div>
        <div className="container mt-4">
          <div className="row justify-content-center mt-2">
            <div className="col-9">
              <div className="custom-div">
                <span className="respon-font">
                  Events {">"} United Nations {">"} Participation
                </span>
              </div>

              <div className="" style={{ marginTop: "40px" }}>
                <p className="fw-600 f-family-Inter fs-31px">Selected Theme</p>
              </div>

              <div className=" color-purple mt-4">
                <span className="fw-600 text-center text-sm-start fs-23px d-block lh-34-5px f-family-Poppins">
                  PEACE-BUILDING AND RECONCILIATION
                </span>
                <span className="fw-600 text-center text-sm-start fs-23px d-block lh-34-5px f-family-Poppins">
                  USHERING AN ERA OF NO WAR
                </span>
              </div>

              <div className="mt-4 ">
                <p className="font-weight-400 f-family-Poppins font-size-21px lh-31-5px text-center text-sm-start">
                  Every person has a personal obligation and duty to contribute
                  to world peace or to "be" the peace they want to see. People
                  can choose to effect revolutionary change in society. We may
                  conceive and believe in the potential of peace by
                  reprogramming the mind to a new way of thinking and building a
                  paradigm of "power with" rather than "power over" Is it
                  possible to repair our world by thinking globally and acting
                  locally? Peace education and structural transformation can be
                  used to build positive peace or social justice.
                </p>
              </div>

              <div>
                <span className="text-center text-sm-start f-family-Poppins color-light-blue fw-500 fs-20px lh-30px">
                  Download Background Guide{" "}
                  <span
                    className="text-center text-sm-start"
                    style={{ color: "#000000" }}
                  >
                    |
                  </span>{" "}
                  Get Oriented
                </span>
              </div>

              <div className="mt-5">
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div
                    className="d-flex justify-content-between align-items-center"
                  // style={{ width: "501px" }}
                  >
                    <div onClick={handlePrev}>
                      <span
                        style={{ color: "#808080" }}
                        className="pt-4f-family-Poppins fw-500 fs-20px lh-28-5px"
                      >
                        Discard
                      </span>
                    </div>
                    <div className="">
                      <button
                        onClick={handleNext}
                        className="bg-blue d-flex align-items-center justify-content-around"
                        style={{
                          width: "233px",
                          height: "55px",
                          color: "white",
                          borderRadius: "9px",
                        }}
                      >
                        Continue <img src="/modelUn/Right.png" alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Step2 = () => {
  const { stepCount, setStepCount } = useContext(MUContext);

  const handleNext = () => {
    setStepCount(stepCount + 1);
  };
  const handlePrev = () => {
    setStepCount(stepCount - 1);
  };

  return (
    <>
      <div>
        <div className="container mt-4">
          <div className="row justify-content-center mt-2">
            <div className="col-9">
              <div className="custom-div">
                <span className="respon-font">
                  Events {">"} United Nations {">"} Participation
                </span>
              </div>

              <div className="modelUN_comittees">
                <div className="" style={{ marginTop: "40px" }}>
                  <p className="fw-600 f-family-Inter fs-32px">
                    Selected Committees
                  </p>
                </div>

                <div className="d-flex justify-content-between">
                  <div>
                    <div className="">
                      <span className="fs-23px fw-500 f-family-Poppins lh-34-5px">
                        UNITED NATIONS SECURITY COUNCIL (UNSC)
                      </span>
                    </div>
                    <div className="position">
                      <span>
                        <a
                          className="font-weight-500 font-size-18px f-family-Poppins text-decoration-underline"
                          href="#"
                        >
                          94 Positions available
                        </a>{" "}
                      </span>
                    </div>
                  </div>

                  <div className="">
                    <button
                      className="fs-16-07px fw-600 bg-purple color-white f-family-Inter"
                      style={{
                        borderRadius: "7px",
                        height: "32px",
                        width: "201.33px",
                        textAlign: "center",
                      }}
                    >
                      Request Nomination
                    </button>
                  </div>
                </div>

                <ModelUnCommittees comittees={comittees} />
              </div>


              <div className="d-flex justify-content-between mt-5">
                <div>
                  <div className="">
                    <span className="fs-23px fw-500 f-family-Poppins lh-34-5px">
                      ALL INDIA POLITICAL PARTIES MEET
                    </span>
                  </div>
                  <div className="position">
                    <span>
                      <a
                        className="font-weight-500 font-size-18px f-family-Poppins text-decoration-underline"
                        href="#"
                      >
                        94 Positions available
                      </a>{" "}
                    </span>
                  </div>
                </div>

                <div className="">
                  <button
                    className="fs-16-07px fw-600 bg-purple color-white f-family-Inter"
                    style={{
                      borderRadius: "7px",
                      height: "32px",
                      width: "201.33px",
                      textAlign: "center",
                    }}
                  >
                    Request Nomination
                  </button>
                </div>
              </div>

              <div className="mt-5">
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ width: "501px" }}
                >
                  <div onClick={handlePrev}>
                    <span
                      style={{ color: "#808080" }}
                      className="pt-4f-family-Poppins fw-500 fs-20px lh-28-5px"
                    >
                      Previous
                    </span>
                  </div>
                  <div className="">
                    <button
                      onClick={handleNext}
                      className="bg-blue d-flex align-items-center justify-content-around"
                      style={{
                        width: "233px",
                        height: "55px",
                        color: "white",
                        borderRadius: "9px",
                      }}
                    >
                      Continue <img src="/modelUn/Right.png" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Step3 = () => {
  const { stepCount, setStepCount } = useContext(MUContext);

  const handleNext = () => {
    setStepCount(stepCount + 1);
  };
  const handlePrev = () => {
    setStepCount(stepCount - 1);
  };

  return (
    <>
      <div>
        <div className="container mt-4">
          <div className="row justify-content-center mt-2">
            <div className="col-9">
              <div className="custom-div">
                <span className="respon-font">
                  Events {">"} United Nations {">"} Participation

                </span>
              </div>

              <div className="modelUN_comittees" style={{ marginTop: "40px" }}>
                <div className="">
                  <p className="fw-600 f-family-Inter fs-32px">
                    Selected Committees
                  </p>
                </div>

                <div className="d-flex justify-content-between">
                  <div>
                    <div className="">
                      <span className="fs-23px fw-500 f-family-Poppins lh-34-5px">
                        UNITED NATIONS SECURITY COUNCIL (UNSC)
                      </span>
                    </div>
                    <div className="position">
                      <span>
                        <a
                          className="font-weight-500 font-size-18px f-family-Poppins text-decoration-underline"
                          href="#"
                        >
                          94 Positions available
                        </a>{" "}
                      </span>
                    </div>
                  </div>

                  <div className="">
                    <button
                      className="fs-16-07px fw-600 bg-purple color-white f-family-Inter"
                      style={{
                        borderRadius: "7px",
                        height: "32px",
                        width: "201.33px",
                        textAlign: "center",
                      }}
                    >
                      Request Nomination
                    </button>
                  </div>
                </div>

                <ModelUnPoliticalParties comittees={comittees} />
              </div>

              <div className="mt-5">
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ width: "501px" }}
                >
                  <div onClick={handlePrev}>
                    <span
                      style={{ color: "#808080" }}
                      className="pt-4f-family-Poppins fw-500 fs-20px lh-28-5px"
                    >
                      Previous
                    </span>
                  </div>
                  <div className="">
                    <button
                      onClick={handleNext}
                      className="bg-blue d-flex align-items-center justify-content-around"
                      style={{
                        width: "233px",
                        height: "55px",
                        color: "white",
                        borderRadius: "9px",
                      }}
                    >
                      Save & Continue <img src="/modelUn/Right.png" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Step4 = () => {
  const navigate = useNavigate()
 const {userData} = useGlobalContext()
  const { stepCount, setStepCount,eventDetail } = useContext(MUContext);
  const [isChecked, setIsChecked] = useState(false);

  const getStudentDetail = ()=>{
    apiJson('api/v2/modelUn-student/getStudentParticipates/student/'+userData?.id)
    .then((res)=>{
      if(res?.data?.result){
        navigate('/modelUn/munDashboard')
      }
    })
    .catch((error)=>{
      console.log(error.message)
    })
  }
 
  useEffect(()=>{
    if(userData?.id){
      getStudentDetail();
    }
  },[userData?.id])
  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
 
  const handleNext = () => {
    setStepCount(stepCount + 1);
  };

  const naviageModelUn = () => {
    navigate("/modelUn")
  }

  return (
    <>
        <div className="mt-4">
          <div className="row justify-content-center mt-2">
            <div className="col-11 col-md-9">
              <div className="col-12 col-sm-10 col-md-8 col-lg-5 col-xl-5">
                <div className="d-flex justify-content-between">
                  <div>
                    <span className="fw-500 font-size-16px color-primary-light-purple">
                      Model United Nations
                    </span>
                  </div>
                  <div className="">
                    <span className="color-grey" style={{ fontSize: "15px" }}>
                      /
                    </span>
                  </div>
                  <div>
                    <span className="color-grey fw-500 font-size-16px font-family-inter ml-2">
                      Participation
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <p className="fw-600 f-family-Inter fs-31px">Selected Theme</p>
              </div>

              <div className="fw-600 color-purple mt-4">
                <span className=" text-start font-weight-600 fs-23px font-size-18px d-block lh-34-5px f-family-Poppins">
                  {eventDetail?.format}
                </span>
                {/* <span className="text-start font-weight-600 fs-23px font-size-18px d-block lh-34-5px f-family-Poppins font-weight-600 fs-23px font-size-18px d-block lh-34-5px f-family-Poppins">
                  USHERING AN ERA OF NO WAR
                </span> */}
              </div>

              <div className="mt-4 ">
              {eventDetail?.format ==="IGNITING INDIA’S JOURNEY TO 2047" ? (
                <p className="font-weight-400  text-start font-weight-600 fs-23px font-size-18px d-block lh-34-5px f-family-Poppins  f-family-Poppins fs-23px font-size-18px fs-sm-6 lh-31-5px">
                In a momentous address on the 77th Independence Day, Prime Minister Shri Narendra Modi juxtaposed the era of "Amrit" with the epoch of "Kartavya" - a time synonymous with duty and responsibility. 
                The Prime Minister emphasized that today's decisions will reverberate across the ages, their impacts resonating throughout a millennium.
                 With a resolute vision of propelling India into the League of Developed Nations by 2047, the Prime Minister outlined the pivotal role the forthcoming five years will play.
                </p>) :(
                  <p className="font-weight-400  text-start font-weight-600 fs-23px font-size-18px d-block lh-34-5px f-family-Poppins  f-family-Poppins fs-23px font-size-18px fs-sm-6 lh-31-5px">
                  LiFe is an initiative envisaged by the honourable Prime Minister Narendra Modi. 
                  Mission LiFe is expected to be an Indian-led global mass movement that will nudge individual and collective action to protect and preserve the environment.
                   The idea promotes an environmentally conscious lifestyle that focuses on ‘mindful and deliberate utilisation’ instead of ‘mindless and destructive consumption’. 
                   The P3 (pro-planet people) movement underlines India’s climate change commitments. This initiative is taken by the government that allows each citizen to take a collective duty to live a life that is in tune with Earth and does not put a risk on it. 
                  Those who practice such a lifestyle are recognised as Pro Planet People under LiFE.
                </p>
                )}
              </div>

              {/* <div>
                <span className="text-center text-sm-start f-family-Poppins color-light-blue fw-500 fs-20px lh-30px">
                  Download Background Guide{" "}
                  <span
                    style={{ color: "#000000" }}
                    className="text-center text-sm-start"
                  >
                    |
                  </span>{" "}
                  Get Oriented
                </span>
              </div> */}

              {/* ====== */}

              <div className="mt-4">
                <div className="d-flex justify-content-start align-items-md-center">
                  <div style={{ marginTop: "5px" }}>
                    <input className="acceptInp"
                      style={{
                        height: "16px",
                        width: "16px",
                        borderRadius: "2px",
                      }}
                      type="checkbox"
                      name=""
                      id=""
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                  <div className="ms-2">
                    <span
                      className="f-family-Inter font-weight-400 font-size-16px lh-28-5px"
                      style={{ color: "#343A40" }}
                    >
                      I accept the selected theme by institution and designed by
                      yuvamanthan.
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="col-11 col-md-7">
                  <div
                    className="d-flex justify-content-between align-items-center mt-5 mb-2"
                  // style={{ width: "501px" }}
                  >
                    <div onClick={naviageModelUn}>
                      <span
                        style={{ color: "#808080" }}
                        className="pt-4f-family-Poppins fw-500 fs-20px lh-28-5px cursor-pointer"
                      >
                        Discard
                      </span>
                    </div>

                    <div className="">
                      <button
                        onClick={handleNext}
                        className={`${isChecked ? "btn-onboard-fill"  : "btn-onboard-fill-disabled"}  `}
                       
                        disabled={!isChecked}
                      >
                        Continue <img src="/modelUn/Right.png" alt="" />
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

const Step5 = () => {
    const {userData} = useGlobalContext()
  const { stepCount, setStepCount,totalDelegateRemaining,getAllCommiteeList, studentFormik, setStudentFormik,eventDetail } = useContext(MUContext);
  const [participateData, setParticipateData] = useState(participate)
  const [activeFilter, setActiveFilter] = useState('Delegate');
  const [selectedCommitteeType, setSelectedCommitteeType] = useState('');
  const[isSubmit,setIsSubmit] = useState(false)
   const [showError,setShowError] = useState(false);
   const[committeeError,setCommitteeError] = useState(false)
  const [allSec, setAllSec] = useState([])
  const [allPc, setAllPc] = useState([])
   const[secretarietType,setSecretariatType] = useState("")
   const [country,setCountry] = useState('')
   const [role,setRole] = useState('')
   const [pcLimit,setPcLimit]= useState(10)
   const [totalSecSlots,setTotalSecSlots] = useState()
  const [userDesignation,setUserDesignation] =useState("")
  const [committeeList, setCommitteeList] = useState([])
  const [selectedCommitee, setSelectedCommitee] = useState("")
  const [filterSecretariatData, setFilterSecretariat] = useState([]);
  const [allApplicants, setAllApplicants] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const getAllApplicants = async () => {
    try {
      setIsLoading(true)
      let applicants = await apiJsonAuth.get(`api/v2/modelUn-student/getAllStudentsDetailNew/${userData?.instituteId}`)
      setAllApplicants(applicants?.data?.studentsParticipants);
      setIsLoading(false)
    } catch (error) {
      console.log("Error on Applicants Api", error)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if(userData?.instituteId){
      getAllApplicants();
    }
  }, [])
  const fetchPreRegisterSecretariat = async () => {
    try {
      if (eventDetail?.id) {
        const res = await apiJson.post(`api/v2/modelUn-institute/getAllselectSecretariatDetails`, {
          instituteId: `${userData.instituteId}`,
          registerId:eventDetail?.id
        });

        setFilterSecretariat(res?.data?.allSelectSec)
      }
    } catch (error) {
      console.error("Error fetching secretariat details:", error);
    }
  };

  useEffect(() => {
    fetchPreRegisterSecretariat()
  }, [eventDetail?.id])


  const getAllSec = ()=>{
    apiJson(`api/v2/modelUn-institute/getAllSecDetails/institute/${userData?.instituteId}/registerId/${eventDetail?.id}`)
    .then((res)=>{
      setAllSec(res.data.updatedAllSec)
      setTotalSecSlots(res.data?.totalRemainingSlots)
    })
    .catch((error)=>{
      console.log(error.message)
    })
  }
  const getAllPc = ()=>{
    apiJson(`api/v2/modelUn-institute/getAllPcDetails/institute/${userData?.instituteId}/registerId/${eventDetail?.id}`)
    .then((res)=>{
      setAllPc(res?.data?.allSec)
      setPcLimit(res?.data?.remaining_slots)
    }).catch((error)=>{
      console.log(error.message)
    })
  }
 const getSelectedCommitteeBYIns =async()=>{
try {
  const res = await apiJson.get(`api/v2/modelUn-institute/getSelectedCommittee/${eventDetail?.id}`);
  setCommitteeList(res?.data?.committeeRes)
} catch (error) {
console.log("Error in Selected Committee by ins",error)
}
 
 }
// =========== When User select all requirement then submit form handler ===========\\

  const handlePost = ()=>{ 
    setIsLoading(true);
    setStudentFormik('studentId',userData?.id)
    setStudentFormik('instituteId',userData?.instituteId)
    setStudentFormik('model_un_register_id',eventDetail?.id)
    let  data = {...studentFormik.values, 
      studentId:userData?.id,
      instituteId:userData?.instituteId, 
      model_un_register_id:eventDetail?.id,
      nominationType:"pref",
      last_registration_date:eventDetail?.last_date,
      selectedCommittee:selectedCommitee,
      email:userData?.email,
      activeFilter
    }
    apiJson.post('api/v2/modelUn-student/participate',data )
    .then((res)=>{
    setIsLoading(false);
    setStepCount(stepCount + 1);
    toast.dismiss()
    toast.success(res?.data?.message)
    }).catch((error)=>{
      console.log(error.message)
    })
  }
  useEffect(()=>{
  if(eventDetail?.id){
    getSelectedCommitteeBYIns();
  }
  },[eventDetail?.id])
  useEffect(()=>{
    if(eventDetail?.id){
      getAllSec();
      getAllPc();
    }
  },[eventDetail?.id])


  // ======== Initially set commitee ========\\

  //=======After click Continue button handle function ===========\\
  const handleNext = () => {
    setIsSubmit(true);
    if(activeFilter==="Delegate" && selectedCommitteeType==="AIPPM" && role ==="" ){
      setShowError(true)
    }else if(activeFilter==="Delegate" && country === ''){
        setShowError(true)
    }else if(role === '' && (activeFilter==="Secretariat" || activeFilter==="Press Corps" )){
      setShowError(true)
    }else if( selectedCommitee ==="" && activeFilter==="Secretariat" &&  ["Executive Board","Judging and Jury"]?.includes(secretarietType) ){
      setCommitteeError(true)
    }else if(activeFilter==="Secretariat" && secretarietType === "Organisation Committee" && role ===""){ 
      setCommitteeError(true)
    }

    ///===================Api  hitting function call here===================\\
    if(activeFilter==="Delegate"  && selectedCommitteeType==="AIPPM" && role !=="" && totalDelegateRemaining !==0 ){
      handlePost()
      return
    }else if(activeFilter==="Delegate" && studentFormik.isValid  && country !== ''  && totalDelegateRemaining !==0 ){
      handlePost()
      return
    }else if(activeFilter==="Secretariat"  && role !== '' && selectedCommitee !=='' &&  (secretarietType ==="Executive Board" || secretarietType ==="Judging and Jury" )){
      handlePost();
    }
    else if(activeFilter==="Secretariat"  && role !== '' && secretarietType==="Organisation Committee" && totalSecSlots !==0 ){  
      handlePost();
    }else if(activeFilter==="Press Corps" && role !== '' && pcLimit !==0){
      handlePost();
   }
  };
  const handlePrev = () => {
    setStepCount(stepCount - 1);
  };

//  ======== Select Position  handler ==========\\
  const designationPostion = (value) => {
    setRole("")
    setCountry("")
    setSelectedCommitee("")
    setUserDesignation("")
    setSelectedCommitteeType('')
    setActiveFilter(value)
    if(value==="Delegate"){
      studentFormik.setValues({...studentFormik.initialValues , pref_designation:"Delegate"})
      setShowError(false)
      setSelectedCommitteeType(getAllCommiteeList[0].type || "UNDP");
      setStudentFormik("committeeId",getAllCommiteeList[0].committeeId );
      setStudentFormik("pref_committee",getAllCommiteeList[0].title )
    }else if(value==="Secretariat"){
      setShowError(false)
      studentFormik.setValues({...studentFormik.initialValues , pref_designation:""})
      setSecretariatType(assignDesination[0].name)
      setStudentFormik("secretariatType", "Executive Board" )
    }else{
      setShowError(false)
      studentFormik.setValues({...studentFormik.initialValues , pref_designation:""})
     setStudentFormik("pref_committee",value || "Press Corps" )
    }
    setParticipateData(participateData)

  }
  // Define the onChange event handler to update the selected committee type
  const handleCommitteeTypeChange = (selectedOption) => {
    setShowError(false);
    setIsSubmit(false)
    setSelectedCommitteeType(selectedOption.type);
    setStudentFormik("pref_country",'')
    setCountry('')
  if(activeFilter ==="Delegate"){

    setStudentFormik("committeeId",selectedOption.committeeId);
    setStudentFormik("pref_committee",selectedOption.type)
  }
  };
  const handleSecretariatChange=(selectedOption)=>{ 
    setRole(selectedOption?.role)
    setShowError(false)
    if(activeFilter ==="Secretariat"){
    setStudentFormik('secretariatsId',selectedOption?.value)
    setStudentFormik('pref_designation',selectedOption?.role)
    setUserDesignation(selectedOption?.role)
  }
  }
  const handlePcChange=(selectedOption)=>{
    setRole(selectedOption?.role)
    setShowError(false)
    if(activeFilter ==="Press Corps"){
    setStudentFormik('pressCorpsId',selectedOption?.value)
    setStudentFormik('pref_designation',selectedOption?.role)
    setUserDesignation(selectedOption?.role)
    }
  }

  // =======Set the selectedCommitteeType to the type of the initially selected committee========\\
  useEffect(() => {
   
    setSelectedCommitteeType(getAllCommiteeList[0].type || "UNDP");
    setStudentFormik("committeeId",getAllCommiteeList[0].committeeId );
    setStudentFormik("pref_committee",getAllCommiteeList[0].title )
  }, []);
  

  //======== custom style for select Input Feild  =============\\
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'black' : 'inherit',
    
    }),
  };
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F9F9F9", 
      border:"none",
      outline:"none"
    }),
  }
// ==========  Default Role select value  =========\\
  useEffect(()=>{
    if(selectedCommitteeType==='AIPPM'){
      setStudentFormik('pref_role',AIPPM_Role[0].value)
    }else if(selectedCommitteeType==='G20'){
      setStudentFormik('pref_role',G20_roles[0].value)
    }else{
      setStudentFormik('pref_role',"")
    }
  },[selectedCommitteeType])

  const handleChangeSecType = (e)=>{
    studentFormik.setValues({...studentFormik.initialValues,pref_designation:""})

    if(e?.name ==="Organisation Committee"){
      setStudentFormik("pref_committee","Organisation Committee")
    }
    setSelectedCommitee("")
    setStudentFormik("secretariatType",e?.name)
    setSecretariatType(e.name)
  }

  const disableFunction = (secretarietType, option)=>{

    if(secretarietType==='Executive Board'){
      return filterSecretariatData?.find((item) => item?.assign_designation === option?.role && item?.selectedCommittee === selectedCommitee && item?.status ==="approved")
    }
    if(secretarietType==='Organisation Committee'){
  return filterSecretariatData?.filter((item) => item?.status ==="approved")?.length>=10
    }
    if(secretarietType==='Judging and Jury'){
      return filterSecretariatData?.filter((item) => item?.secretariatType ===secretarietType && item?.selectedCommittee === selectedCommitee && item?.status ==="approved")?.length>=5
        }
    return false
  }
  const disableCountries = (committeeType, countries, allApplicants) => {
    if (!committeeType || !allApplicants || !Array.isArray(allApplicants)) {
      return countries;
    }
  
    const applicantsCountries = allApplicants
      .filter(applicant => applicant.committeeType === committeeType && applicant.preferredCountry && applicant?.status ==="approved" )
      .map(applicant => applicant.preferredCountry);
    return countries.map(country => {
      if (applicantsCountries.includes(country.name)) {
        return { ...country, isDisabled: true };
      } else {
        return country;
      }
    });
  };
  const disableAIIPPMROLE = (committeeType, Roles, allApplicants) => {

    if (!committeeType || !allApplicants || !Array.isArray(allApplicants)) {   
      return Roles
    }
  
    const applicantsCountries = allApplicants?.filter(applicant => applicant?.committeeType === committeeType &&  applicant?.preferredRole && applicant?.status ==="approved")?.map(applicant => applicant?.preferredRole);
    return Roles?.map(role => {

      if (applicantsCountries?.includes(role?.value)) {
        return { ...role, isDisabled: true };
      } else {
        return role;
      }
    });

  };
  

  return (
    <>
        <div className="mt-4">
          <div className="row justify-content-center mt-2">
            <div className="col-11 col-md-9">
              <div className="col-12 col-sm-10 col-md-8 col-lg-5 col-xl-5">
                <div
                  className="d-flex justify-content-between"
                // style={{ height: "47px", width: "320px" }}
                >
                  <span className="fw-500 font-size-16px color-primary-light-purple">
                    Model United Nations
                  </span>
                  <div>
                    <span className="color-grey">/</span>
                  </div>
                  <div>
                    <span className="color-grey fw-500 font-size-16px font-family-inter ">
                      Participation
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <p className="fw-600 f-family-Inter fs-31px fs-24px">
                  Choose Your Preferred Designation
                </p>
              </div>

              <div>
                <span className="color-ligth-grey font-family-inter font-weight-400 fs-25px font-size-18px">
                  Excited to be a part of the YMUN party? As students, you have
                  the following choices to become a part of the exercise:
                </span>
              </div>

              {/* <ModelUnParticipate participate={participate} /> */}
              <div>
                <div className="col-11" style={{ marginTop: "50px" }}>
                  <div className="row">
                    {participateData.map((ele, i) => {
                      return (
                        <>
                          <div className="col-12 col-sm-6 col-lg-3 mb-3">
                            {/* <div className="card  d-flex border-0"> */}
                            <div>
                              <div className="">
                                <div onClick={() => designationPostion(ele.title)} className={`card-body bg-ligth-blue mx-auto text-center d-flex flex-column cursor-pointer justify-content-center ${activeFilter === ele?.title && "active-filter-border"}`} style={{ width: "181px", height: "181px", borderRadius: "21px" }}>
                                  <div className="height-100px">
                                    <img className="h-100" src={ele.img} alt="" />
                                  </div>
                                  <div>
                                    <span>{ele.title}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="text-center" style={{ width: "", height: "23px" }}>
                                <span className="fs-15px text-center" style={{ fontWeight: "500" }}>{ele.title ==="Delegate" ? totalDelegateRemaining : ele.title ==="Secretariat" ? totalSecSlots : pcLimit > 0 ? pcLimit : "Not"} Positions Available</span>
                              </div>
                            </div>
                          </div>
                          {/* </div> */}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="mt-5">
                {/* <div className="row"> */}
                <div
                  className="d-flex align-items-center gap-2  border-purple b-radius-13px bg-mix-purple-white"
                  style={{ maxWidth: "820px", minHeight: "72px" }}
                >
                  <div style={{ maxWidth: "45px", minHeight: "26px" }}>
                  <AssignmentIcon className="mt-1" sx={{color: "#28007d"}} />
                  </div>

                  {activeFilter === "Delegate" ? (<div className="mt-2" style={{ maxWidth: "720.74px", minHeight: "46px", lineHeight: "1.1px" }}>
                    <span
                      className="f-family-Poppins fw-500 fs-15px line-height-22-5px"
                      style={{ color: "#633CFF" }}
                    >
                      Participate as a <span className="fw-700 ">Delegate</span> to represent a country. Picture this: a student representing the United Kingdom adeptly tackling global challenges like nuclear non-proliferation or climate change.
                    </span>
                  </div>)
                    :
                    activeFilter === "Secretariat" ? (<div className="mt-2" style={{  maxWidth: "720.74px", minHeight: "46px", lineHeight: "1.1px" }}>
                      <span
                        className="f-family-Poppins fw-500 fs-15px line-height-22-5px"
                        style={{ color: "#633CFF" }}
                      >
                        Participate as a member of the  <span className="fw-700 ">Secretariat</span>. You will help in organizing the event and ensuring a smooth flow of work. There are multiple designations up for grabs!
                      </span>
                    </div>)
                      : (
                        <div className="mt-2" style={{  maxWidth: "720.74px", minHeight: "46px", lineHeight: "1.1px" }}>
                          <span
                            className="f-family-Poppins fw-500 fs-15px line-height-22-5px"
                            style={{ color: "#633CFF" }}
                          >
                            Participate as a member of the  <span className="fw-700 ">Press Corps</span> and showcase your creativity by capturing the event like never before.
                          </span>
                        </div>)
                  }
                </div>
              </div>

          {/*========= When we click Delegate then open its select dropdowns =========  */}
              {activeFilter === "Delegate" ?(  
              <div  className="col-12 col-md-10">
                <div className='mt-4'><span className='fs-19px fw-500' style={{ fontFamily: "Poppins" }}>Select a committee</span></div>
                
                <div>
                {/* ============== Commitee Select Dropdown start =====================*/}
                {selectedCommitteeType && (
                  <>
                  <Select
                  options={getAllCommiteeList?.map((option)=>({
                      ...option,
                      isDisabled: option.slots == option?.booked_slots,
                    }))}
                  isSearchable={false}
                  styles={{ ...customStyles,
                    option: (provided, state) => ({
                      ...provided,
                      opacity: state.data.isDisabled ? 0.6 : 1,
                      cursor: state.data.isDisabled ? "not-allowed" : "pointer",
                    }),
                  }}
                  defaultValue={getAllCommiteeList.find((i)=>i?.type ===selectedCommitteeType)}
                  onChange={handleCommitteeTypeChange}
                  getOptionLabel={
                    (option) => {
                      return (
                        <>
                          <div className="d-flex justify-content-between
                          align-items-center">
                            <div className='delegate-secretariet-pressCorps  fw-500 'style={{ color: "#3A3A3A" }}>{option.title}</div>
                            <div className="d-flex justify-content-between">
                            <div className='delegate-secretariet-pressCorps fw-500 me-4'style={{ color: "#BD00FF", fontFamily: "Poppins" }}>{option.remaining_slots === 0 ? "Not Availabile" : `${option.remaining_slots} Availabile`}</div>
                            <div className="delegate-secretariet-pressCorps" style={{ fontWeight: "500", fontSize: "15px", color: "#ACACAC" }}>{option?.applicants} Applicants</div>
                            </div>
                           
                          </div>
                        </>
                      )
                    }
                  }
                />
                    {studentFormik.errors.pref_committee && isSubmit? (
                      <div className="text-danger">{studentFormik.errors.pref_committee}</div>
                    ) : null}
                </>   )}
                </div>
            {/*==== Select country if selectedCommitteeType is UNEP, COP28,UNDP ======== */}
            {selectedCommitteeType === "UNEP" || selectedCommitteeType === "COP29" || selectedCommitteeType === "UNDP" ? (
              <>
              <div className='mt-4'><span className='fs-19px fw-500' style={{ fontFamily: "Poppins" }}>Select a country</span></div>
                    {selectedCommitteeType && (
                      <Select
                        options={disableCountries(selectedCommitteeType,
                          selectedCommitteeType === "UNEP" ? UNEP_Countries :
                            selectedCommitteeType === "COP29" ? COP28_countries :
                              selectedCommitteeType === "UNDP" ? UNDP_Countries : null,
                          allApplicants)}
                        isSearchable={true}
                        onChange={(i) => {
                          setShowError(false)
                          setCountry(i.value)
                          setStudentFormik("pref_country", i.value)
                        }}
                        styles={{
                          ...customStyles, ...customSelectStyles, option: (provided, state) => ({
                            ...provided,
                            opacity: state.data.isDisabled ? 0.6 : 1,
                            cursor: state.data.isDisabled ? "not-allowed" : "pointer",
                          }),
                        }}
                        //  defaultValue={selectedCommitteeType === "UNEP" ? UNEP_Countries[0] : selectedCommitteeType === "COP28"? COP28_countries[0] : UNDP_Countries[0]}
                        getOptionLabel={
                          (option) => {
                            return (
                              <>
                                <div className='delegate-secretariet-pressCorps fw-500' style={{ color: "#3A3A3A" }}>{option.name}</div>
                              </>)
                          }}
                      />

                    )}
                {showError ? (
                        <div className="text-danger">Please Select a country</div>
                      ) : null}
                
              </>
              
            ):null}

            {/*======== Role Select Dropdown if SelectedCommiteeType is AIIPM =============== */}
              {selectedCommitteeType === "AIPPM" && (  
                <>
                 <div className='mt-4'><span className='fs-19px fw-500' style={{ fontFamily: "Poppins" }}>Select a role</span></div>
                    <Select
                      options={disableAIIPPMROLE(selectedCommitteeType,AIPPM_Role,allApplicants)}
                      isSearchable={true}
                      styles={{
                        ...customStyles, ...customSelectStyles, option: (provided, state) => ({
                          ...provided,
                          opacity: state.data.isDisabled ? 0.6 : 1,
                          cursor: state.data.isDisabled ? "not-allowed" : "pointer",
                        }),
                      }}
               
                onChange={(i)=>{
                  setIsSubmit(false)
                  setShowError(false)
                  setRole(i.value)
                  setStudentFormik("pref_role",i.value)
                }}
                getOptionLabel={
                 (option) => {
                   return (
                     <>
                         <div className='delegate-secretariet-pressCorps fw-500'style={{ color: "#3A3A3A" }}>{option.value}</div>
                     </>)}}
                     />
                     {showError ? (
                        <div className="text-danger">Please Select a Role</div>
                      ) : null}
             </>
             )}
            {/*======== Role and Country Select Dropdown if SelectedCommiteeType is G20 =============== */}

                {selectedCommitteeType === "G20" && (
                  <>
                  <div className='mt-4'><span className='fs-19px fw-500' style={{ fontFamily: "Poppins" }}>Select a country</span></div>
                  <Select 
                   options={G20_Countries}
                   isSearchable={true}
                   styles={{ ...customStyles, ...customSelectStyles }}
               
                   onChange={(i)=>{
                    setCountry(i.value)
                    setShowError(false)
                    setStudentFormik('pref_country',i.value)}}
                   getOptionLabel={
                    (option) => {
                      return (
                        <>
                            <div className='delegate-secretariet-pressCorps fw-500'style={{ color: "#3A3A3A" }}>{option.name}</div>
                        </>)}}
                        />
                         {showError ? (
                        <div className="text-danger">Please Select a country</div>
                      ) : null}
                        <div className='mt-4'><span className='fs-19px fw-500' style={{ fontFamily: "Poppins" }}>Select a role</span></div>
                    <Select
                      options={G20_roles}
                      isSearchable={true}
                      styles={{ ...customStyles, ...customSelectStyles }}
                     
                      onChange={(i) => setStudentFormik('pref_role', i.value)}
                      getOptionLabel={
                        (option) => {
                          return (
                            <>
                              <div className='delegate-secretariet-pressCorps fw-500' style={{ color: "#3A3A3A" }}>{option.role}</div>
                            </>)
                        }}
                    />
                </>
                )}
                
              </div>) : activeFilter === "Secretariat" ? (

                <div>
                  <div  className="col-12 col-md-10">
                <div className='mt-4'><span className='fs-19px fw-500' style={{ fontFamily: "Poppins" }}>Secretariat Type</span></div>
                
                <div>
                  {secretarietType && (
                     <Select
                     options={assignDesination}
                     // isSearchable={false}
                     styles={{ ...customStyles, ...customSelectStyles }}
                     onChange={(e)=> {handleChangeSecType(e)}}
                    defaultValue={assignDesination.find((i)=>i?.name ===secretarietType)}
                     getOptionLabel={
                       (option) => {
                         return (
                           <>
                             <div className="d-flex justify-content-between
                             align-items-center">
                               <div className='delegate-secretariet-pressCorps fw-500'style={{ color: "#3A3A3A" }}>{option?.name}</div>
                             </div>
                           </>
                         )
                       }
                     }
                   />
                  )}
               
                </div>
                
              </div>
              {["Executive Board","Judging and Jury"]?.includes(secretarietType) &&  (
                <div  className="col-12 col-md-10">
                <div className='mt-4'><span className='fs-19px fw-500' style={{ fontFamily: "Poppins" }}>Choose a committee</span></div>
                  <Select
                     styles={{ ...customStyles, ...customSelectStyles }}
                    options={committeeList}
                    onChange={(e) => {
                      // handleFormData('designation', e.name)
                      setStudentFormik("pref_committee" , e?.committee)
                      setSelectedCommitee(e?.committee)
                      setCommitteeError(false)
                    }}
                    getOptionLabel={
                      (option) => {
                        return (
                          <>
                            <div>
                              <span className="delegate-secretariet-pressCorps fw-500">{option.committee}</span>
                            </div>
                          </>
                        )
                      }
                    }
                  />
                  {committeeError ? (
                        <div className="text-danger">Please Select a committee.</div>
                      ) : null}
                  </div>
              )}
              
              <div  className="col-12 col-md-10">
                <div className='mt-4'><span className='fs-19px fw-500' style={{ fontFamily: "Poppins" }}>Select a role</span></div>
                
                <div>
                  {secretarietType && (
                    <Select
                  options={allSec
                    .filter((i) => i.typeName === secretarietType)
                    .map((option) => ({
                      ...option,
                      isDisabled: disableFunction(secretarietType, option)
                    }))}
                  isSearchable={false}
                  styles={{ ...customStyles, ...customSelectStyles, 
                  option: (provided, state) => ({
                    ...provided,
                    cursor: state.data.isDisabled ? "not-allowed" : "pointer",
                    opacity: state.data.isDisabled ? 0.6 : 1,
                  }), }}
                  onChange={handleSecretariatChange}
                  getOptionLabel={
                    (option) => {
                      return (
                        <>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className='delegate-secretariet-pressCorps fw-500'style={{ color: "#3A3A3A" }}>{option.role}</div>
                            <div className="d-flex justify-content-between">
                            <div className='delegate-secretariet-pressCorps fw-500 me-4'style={{ color: "#BD00FF", fontFamily: "Poppins" }}>{option.isDisabled ? (<span className="text-danger">NA</span>) : null}</div>
                            </div>
                           
                          </div>
                        </>
                      )
                    }
                  }
                />
                  )}
                   {showError ? (
                        <div className="text-danger">Please Select a Role</div>
                      ) : null}
                
                </div>
                <div className="adminRight d-flex justify-content- align-items-center mt-md-3">
                                  <div><img src="/modelUn/Clipboard.png" alt="Clipboard" /></div>
                                  {userDesignation === "Chairperson" ? (
                                    <div className="admin-content"><span className="" style={{ color: "#979797" }}>Like a teacher, they lead the discussions, keep order, and make sure everyone follows the rules.</span></div>
                                  ) : userDesignation === "Vice-Chairperson" ? (
                                    <div className="admin-content"><span className="" style={{ color: "#979797" }}>Helps the Chairperson and takes over if the Chairperson is not there.</span></div>
                                  ) : userDesignation === "Rapporteur" ? (
                                    <div className="admin-content"><span className="" style={{ color: "#979797" }}>Keeps a record of what everyone says and helps with organising documents.</span></div>
                                  ) : userDesignation === "Member" ? (
                                    <div className="admin-content"><span className="" style={{ color: "#979797" }}>A group of helpers who assist the Secretary-General in organising the conference.</span></div>
                                  ):(
                                      <div className="admin-content"><span className="" style={{ color: "#979797" }}>The big boss of the whole event. They make sure everything runs smoothly..</span></div>
                                    )
                                  }
                </div>
              </div>
                
                {/* // ========When Select  Secretariat then It is show the its Select DropDown=====\\ */}
              
              
              </div>
              ):(<>  <div  className="col-12 col-md-10">
                <div className='mt-4'><span className='fs-19px fw-500' style={{ fontFamily: "Poppins" }}>Select a role</span></div>
                
                <div>
                <Select
                    options={allPc?.map((option) => ({
                      ...option,
                      isDisabled: pcLimit === 0,
                    }))}
                    isSearchable={false}
                    styles={{
                      ...customStyles,
                      ...customSelectStyles,
                      option: (provided, state) => ({
                        ...provided,
                        cursor: pcLimit === 0 ? "not-allowed" : "pointer",
                      opacity: pcLimit === 0 ? 0.6 : 1,
                        
                      }),
                    }}
                  onChange={handlePcChange}
                  getOptionLabel={
                    (option) => {
                      return (
                        <>
                          <div className="d-flex justify-content-between
                          align-items-center">
                            <div className='delegate-secretariet-pressCorps fw-500'style={{ color: "#3A3A3A" }}>{option.role}</div>
                            <div className="d-flex justify-content-between">
                            <div className='delegate-secretariet-pressCorps fw-500 me-4'style={{ color: "#BD00FF", fontFamily: "Poppins" }}>{option.isAssigned?"Not Available":"Available"}</div>
                            <div className="delegate-secretariet-pressCorps" style={{ fontWeight: "500", fontSize: "15px", color: "#ACACAC" }}>{option.applicants > 0  ? option.applicants+" " + "Applicants" :null }</div>
                            </div>
                           
                          </div>
                          
                        </>
                      )
                    }
                  }
                />
                {showError ? (
                        <div className="text-danger">Please Select a Role</div>
                      ) : null}
                </div>
                <div className="adminRight d-flex justify-content- align-items-center mt-md-3">
                <div><img src="/modelUn/Clipboard.png" alt="Clipboard" /></div>
                  {userDesignation === "Reporter" ? (

                    <div className="admin-content"><span className="" style={{ color: "#979797" }}>Writes stories and reports on what's happening at the conference.</span></div>
                  ) : userDesignation === "Caricaturist" ? (
                    <div className="admin-content"><span className="" style={{ color: "#979797" }}>Draws funny and creative cartoons to add a bit of humour to the conference.</span></div>
                  ) :
                    (
                      <div className="admin-content"><span className="" style={{ color: "#979797" }}> Takes pictures to capture moments during the conference.</span></div>
                    )
                  }
                  </div>
              </div></>)}
              
              <div className="mt-3">
                <div
                  className="d-flex justify-content-between align-items-center mt-5"
                  style={{ maxWidth: "501px"}}
                >
                  <div onClick={handlePrev}>
                    <span
                      style={{ color: "#808080" }}
                      className=" pt-4f-family-Poppins fw-500 fs-20px lh-28-5px cursor-pointer"
                    >
                      Back
                    </span>
                  </div>
                  <div className="">
                    <button
                      onClick={handleNext}
                      className="bg-blue d-flex align-items-center justify-content-around"
                      style={{
                        maxWidth: "233px",
                        height: "55px",
                        color: "white",
                        borderRadius: "9px",
                        padding:"2px 10px"
                      }}
                      disabled={isLoading}
                      >
                    {isLoading ?
                  <>
                    <div className="px-3">
                      <div className="spinner-border text-light mx-auto d-flex" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="px-1">
                    <span>Continue < img src="/modelUn/Right.png" alt="" /></span>
                    </div>
                  </>
                }
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

const Step6 = () => {
  const { stepCount, setStepCount } = useContext(MUContext);
  const handleNext = () => {
    setStepCount(stepCount + 1);
  };
  const handlePrev = () => {
    setStepCount(stepCount - 1);
  };

  return (
    <div className="mt-4">
      <div className="row justify-content-center mt-2">
        <div className="col-11 col-md-9">
          <div className="col-11 col-md-4">
            <div
              className="d-flex justify-content-between"
            // style={{ height: "47px", width: "320px" }}
            >
              <span className="fw-500 font-size-16px color-primary-light-purple">
                Model United Nations
              </span>
              <div>
                <span className="color-grey">/</span>
              </div>
              <div>
                <span className="color-grey fw-500 font-size-16px font-family-inter ">
                  Participation
                </span>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <p className="fw-600 font-family-inter fs-31px fs-24px">
              Choose Your Preferred Committee
            </p>
          </div>

          <div className="mt-1">
            <span className="font-weight-500 f-family-Poppins font-size-19px lh-28-5px">
              Select a committee
            </span>
          </div>

          <div className="mt-4">
            <div
              className="d-flex justify-content-between align-items-center bg-grey b-radius-8px"
              style={{ width: "820px", height: "46px" }}
            >
              <div style={{ width: "414px", height: "29px" }}>
                <span className="f-family-Poppins font-weight-500 font-size-19px lh-28-5px">
                  UNITED NATIONS SECURITY COUNCIL (UNSC)
                </span>
              </div>
              <div>
                <select
                  style={{
                    color: "#8F00FF",
                    width: "210px",
                    border: "1px solid red",
                  }}
                  className="f-family-Poppins font-weight-500 font-size-19px lh-28-5px border-0 bg-grey"
                  name=""
                  id=""
                >
                  <option value="">30 Available</option>
                </select>
              </div>
            </div>
          </div>

          <ModelUnCommittees comittees={comittees} />

          <div className="mt-5">
            {/* <div className="row"> */}
            <div
              className="d-flex align-items-center border-purple b-radius-13px bg-mix-purple-white"
              style={{ width: "820px", height: "72px" }}
            >
              <div style={{ width: "45px", height: "26px" }}>
                <img
                  className="h-100 w-100"
                  src="/modelUn/Clipboard.png"
                  alt=""
                />
              </div>
              {/* <div> */}
              <div className="mt-1" style={{ width: "720.74px", height: "46px", lineHeight: "1.1px" }} >
                <span
                  className="f-family-Poppins fw-500 fs-15px line-height-22-5px"
                  style={{ color: "#633CFF" }}
                >
                  You have selected your preferred designation as a{" "}
                  <span className="fw-700 ">Delegate</span>, you will be
                  assigned as your preferred designation if available during
                  nomination process done by your institution.{" "}
                </span>
                {/* </div> */}
              </div>
            </div>
            {/* </div> */}
          </div>

          <div className="mt-2">
            <div
              className="d-flex justify-content-between align-items-center mt-5"
              style={{ width: "501px" }}
            >
              <div onClick={handlePrev}>
                <span
                  style={{ color: "#808080" }}
                  className=" pt-4f-family-Poppins fw-500 fs-20px lh-28-5px"
                >
                  Back
                </span>
              </div>
              <div className="">
                <button
                  //   onClick={() => setStepCount(1)}
                  onClick={handleNext}
                  className="bg-blue d-flex align-items-center justify-content-around"
                  style={{
                    width: "233px",
                    height: "55px",
                    color: "white",
                    borderRadius: "9px",
                  }}
                >
                  Submit <img src="/modelUn/Right.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Step7 = () => {
  const {eventDetail } = useContext(MUContext);
  const [showThemes, setThemes] = useState([])
  const navigate = useNavigate();
    
  useEffect(() => {
    const getTheme = features?.filter((ele) => ele?.theme === eventDetail?.format)
    console.log(getTheme)
    setThemes(getTheme)
  }, [eventDetail])

  const navigateDashboaerd = () => {
    navigate('/modelUn/munDashboard')
  }

  const handleNavigate = (url, isDiseble) => {
    if (url && isDiseble === false) {
      navigate(url);
    } else {
      navigate("/new-dashboard");
    }
  }

  return (
    <div className="mt-4">
      <div className="row justify-content-center mt-2">
        <div className="col-11 col-md-9">
          <div className="col-12 col-md-4">
            <div
              className="d-flex justify-content-between"
            // style={{ height: "47px", width: "320px" }}
            >
              <span className="fw-500 font-size-16px color-primary-light-purple">
                Model United Nations
              </span>
              <div>
                <span className="color-grey">/</span>
              </div>
              <div>
                <span className="color-grey fw-500 font-size-16px font-family-inter ">
                  Participation
                </span>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <p className="fw-600 font-family-inter fs-31px lh-37-52px">
              Under Nomination
            </p>
          </div>

          <div>
            <span
              className=" color-ligth-grey font-family-inter font-weight-500 fs-23px font-size-18px lh-27-84px "
              style={{ color: "#A79BB1" }}
            >
              Your application for participation in Yuvamanthan Model United
              Nations is submitted. You will notified once you are nominated.
            </span>
          </div>

          <div className="mt-2">
            <span className="color-pink lh-31-5px font-weight-600 font-family-inter fs-26px fs-22px">
              Selected students will be declared on { moment(eventDetail?.last_date).format("MMMM D, YYYY")} at 11:00
              AM IST
            </span>
          </div>

          {showThemes?.length > 0 &&
            <div>
              <div className="mt-2">
                <p className="fw-700 f-family-Inter fs-24px">Resources</p>
              </div>
              <div className="col-11">
                <div className="row">
                  {showThemes?.map((data) => {
                    return (
                      <>
                        <div key={data?.id} className="col-4 col-md-3 col-lg-2">
                          <div className="mt-3 d-flex flex-column align-items-center justify-content-center">
                            <div onClick={() => handleNavigate(data?.url, data?.isDiseble)}
                              className={`d-flex align-items-center justify-content-center border-0 rounded-circle p-3 ${data?.isDiseble ? "cursor-na iconDisableColor " : "iconEffect cursor-pointer "}`}
                              style={{
                                height: "68px",
                                width: "68px",
                                background: "#F7F2FF",
                              }}
                            >
                              {
                                data?.icons ||
                                <img
                                  src={data?.featureImg}
                                  className="w-100 h-100"
                                  alt="name"
                                />
                              }
                            </div>
                            <div className="align-items-center text-center mt-2">
                              <span
                                className="font-family-inter font-weight-400 fs-17px fs-14px fw-semibold"

                              >
                                {data?.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                  }
                </div>
              </div>
            </div>
          }

          <div className="col-12 col-md-10">
            <div
              className="d-flex justify-content-between align-items-center mt-5"
            // style={{ width: "501px" }}
            >
              <div>
                <button
                  onClick={navigateDashboaerd}
                  className="bg-blue d-flex align-items-center justify-content-around"
                  style={{
                    maxWidth: "233px",
                    height: "55px",
                    color: "white",
                    borderRadius: "9px",
                    padding:"2px 10px"
                  }}
                >
                  Go to Dashboard <img src="/modelUn/Right.png" alt="" />
                </button>
              </div>
              {/* <div className=""
              //  onClick={handleNaviageApplicant}
              >
                <span
                  // style={{ color: "#808080" }}
                  className="color-blue pt-4f-family-Poppins fw-500 font-size-19px lh-28-5px"
                >
                  Invite a friend
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const comittees = [
  {
    countryFLag: "/modelUn/flag.png",
    img: "/modelUn/download1.jpeg",
    degination: "Prime Minister",
    name: "Nitesh Rawat",
  },
  {
    countryFLag: "/modelUn/flag.png",
    img: "/modelUn/download1.jpeg",
    degination: "Prime Minister",
    name: "Nitesh Rawat",
  },
  {
    countryFLag: "/modelUn/flag.png",
    img: "/modelUn/download2.jpeg",
    degination: "Prime Minister",
    name: "Nitesh Rawat",
  },
  {
    countryFLag: "/modelUn/flag.png",
    img: "/modelUn/download2.jpeg",
    degination: "Prime Minister",
    name: "Nitesh Rawat",
  },
  {
    countryFLag: "/modelUn/flag.png",
    img: "/modelUn/download2.jpeg",
    degination: "Prime Minister",
    name: "Nitesh Rawat",
  },
  {
    countryFLag: "/modelUn/flag.png",
    img: "/modelUn/download2.jpeg",
    degination: "Prime Minister",
    name: "Nitesh Rawat",
  },
];

const participate = [
  {
    img: "/modelUn/Lecturer.png",
    title: "Delegate",
  },
  {
    img: "/modelUn/Certificate.png",
    title: "Secretariat",
  },
  {
    img: "/modelUn/Magazine.png",
    title: "Press Corps",
  }
];

const features = [
  {
    id: 1,
    icons : <CloudDoneTwoToneIcon  sx={{color: "#6909ff",width: "40px", height: "40px"}}/>,
    featureImg: "/modelUn/Clouddone.png",
    url:"/life",
    name: "Lifestyle for Environment",
    isDiseble:false,
    theme: 'Lifestyle for Environment in Partnership with UNDP',
  },
  {
    id: 2,
    icons : <CloudCircleTwoToneIcon sx={{color: "#6909ff",width: "40px", height: "40px"}}/>,
    featureImg: "/modelUn/Vector.png",
    url:"/climate-change-and-disaster-risk-reduction",
    name: "Climate Change",
    isDiseble:false,
    theme: 'Climate Change',
  },
  {
    id: 3,
    featureImg: "/modelUn/TajMahal.png",
    name: "Bharat at 2047",
    isDiseble:true,
    theme: 'Bharat at 2047',
  },
  {
    id: 4,
    icons: <PsychologyIcon sx={{color: "#6909ff",width: "40px", height: "40px"}}/>,
    featureImg: "/modelUn/Innovation.png",
    name: "IP Innovation",
    isDiseble:true,
    theme: 'IP Innovation',
  },
  {
    id: 5,
    icons: <BusinessCenterRoundedIcon sx={{color: "#6909ff",width: "40px", height: "37px"}}/>,
    featureImg: "/modelUn/Business.png",
    url:"/future-of-work",
    name: "Future of Work",
    isDiseble:false,
    theme: 'Future of Work',
  },
  {
    id: 6,
    featureImg: "/modelUn/PeacePigeon.png",
    url:"/peacebuilding-and-reconciliation",
    name: "Peace building and Reconciliation",
    isDiseble:false,
    theme: 'Peace building and Reconciliation',
  },
  {
    id: 7,
    icons : <HandshakeTwoToneIcon sx={{color: "#6909ff",width: "40px", height: "30px"}}/>,
    featureImg: "/modelUn/Handshake.png",
    url: "/shared-future",
    name: "Shared Future",
    isDiseble:false,
    theme: 'Shared Future',
  },
  {
    id: 8,
    icons : <svg style={{color: "#6909ff"}} xmlns="http://www.w3.org/2000/svg" width="30" height="50" fill="currentColor" className="bi bi-heart-pulse" viewBox="0 0 16 16">
    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053.918 3.995.78 5.323 1.508 7H.43c-2.128-5.697 4.165-8.83 7.394-5.857.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17c3.23-2.974 9.522.159 7.394 5.856h-1.078c.728-1.677.59-3.005.108-3.947C13.486.878 10.4.28 8.717 2.01zM2.212 10h1.315C4.593 11.183 6.05 12.458 8 13.795c1.949-1.337 3.407-2.612 4.473-3.795h1.315c-1.265 1.566-3.14 3.25-5.788 5-2.648-1.75-4.523-3.434-5.788-5Z"/>
    <path d="M10.464 3.314a.5.5 0 0 0-.945.049L7.921 8.956 6.464 5.314a.5.5 0 0 0-.88-.091L3.732 8H.5a.5.5 0 0 0 0 1H4a.5.5 0 0 0 .416-.223l1.473-2.209 1.647 4.118a.5.5 0 0 0 .945-.049l1.598-5.593 1.457 3.642A.5.5 0 0 0 12 9h3.5a.5.5 0 0 0 0-1h-3.162z"/>
  </svg>,
    featureImg: "/modelUn/HeartwithPulse.png",
    name: "Health, Well-being & Sports",
    isDiseble:false,
    theme: 'Health, Well-being & Sports',
  },
  {
    id: 9,
    icons : <PendingOutlinedIcon sx={{color: "#6909ff",width: "40px", height: "40px"}}/>,
    featureImg: "/modelUn/ViewMore.png",
    name: "See All",
    isDiseble:true,
    theme: 'See All',
  },
];

const assignDesination = [
  {
    value : 1,
    name : "Executive Board",
  },
  {
    value : 2,
    name : "Organisation Committee",
  },
  {
    value : 3,
    name : "Judging and Jury",
  },
]
