import { Avatar } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const DiscussionBoardComments = ({ data }) => {


  return (
    <>

<div className='d-flex rounded-3' style={{ width: '', padding: '3px 39px 0 3px' }}>
  <div className="d-flex" style={{ background: '#ebebebbd', borderRadius: '10px', padding: '10px' }}>
    <div className='mx-3' style={{ width: '37px', height: '37px', borderRadius: '50%' }}>
      <Avatar src={data?.profile} alt={data?.name} />
    </div>
    <div className="d-flex flex-column">
      <div className='d-flex flex-column'>
        <span className='fw-semibold fs-6' style={{ fontSize: '13px', fontWeight: '600' }}>{data?.name}</span>
        <span style={{ color: '#ACACAC', fontSize: '13px', fontWeight: '500', marginLeft: 8 }}>{moment(data?.time).fromNow()}</span>
      </div>
      <span className='DisPara'>{data?.message}</span>
    </div>
  </div>
</div>

    </>
  )
}
