import React, { createContext, useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from "yup"
import { apiJsonAuth } from 'api';
import { useGlobalContext } from 'global/context';

const ModelUnContext = createContext();

export const useMyContext = () => {
  return useContext(ModelUnContext);
};

export const MyContextProvider = ({ children }) => {

  const validationSchema = yup.object().shape({
    eventType : yup.string().required("ChooseEvent is required"), 
    chooseFormat : yup.string().required("ChooseFormat is required"), 
    // eventTheme: yup.string().required("EventTheme is required"),
    // subTheme: yup.string().required("SubTheme is required"),
    // committee: yup.string().required("Committee is required"),
    // positions: yup.string().required("Positions is required"),
    lastDateRegis: yup.string().required("LastDate of Registration is required"),
    lastDateEvent: yup.string().required("Date of Event is required."),
    event_venue:yup.string().required("Please provide the name of the venue"),
    event_time:yup.string().required("Please provide the time at which the Event will start"),
  });
  const {userData, setUser} = useGlobalContext()
  const [currentStep, setCurrentStep] = useState(0);
  const [eventTheme, setEventTheme] = useState([]);
  const [subTheme, setSubTheme] = useState([]);
  const [format, setFormat] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues : {
      eventType : "Intra Institutional",
      chooseFormat : "Lifestyle for Environment in Partnership with UNDP",
      eventTheme : "",
      subTheme : "",
      slectedCommittee : [],
      lastDateRegis : "",
      lastDateEvent : "",
      event_venue:"",
      event_time:"",
    },
    validationSchema,
  onSubmit : async(values) =>{
    // console.log(values);
      try {
        const data={
          event_type:values.eventType,
    format:values.chooseFormat,
    event_theme:values.eventTheme,
    sub_theme:values.subTheme,
    last_date:values.lastDateRegis,
    event_venue:values?.event_venue,
    event_time:values?.event_time,
    date_proposed:values.lastDateEvent,
    slectedCommittee:values.slectedCommittee
        }
        setIsLoading(true)
        const res = await apiJsonAuth.post(`api/v2/modelUn-institute/register/${userData?.id}`,data)
        if(res.status === 201){
          setIsLoading(false)
          geteventDetail()
          setCurrentStep(6);   
        }
        // action.resetForm();
      } catch (error) {
        setIsLoading(false)
        console.log("error", error);
      }
    } 
  })
    const geteventDetail = () => {
      const id =
        userData?.role === "institute" ? userData?.id : userData?.instituteId;
      apiJsonAuth("api/v2/modelUn-student/getEventDetails/institute/" + id)
        .then((res) => {
          setUser({...userData, munRegister: res?.data?.result?.id})
        })
        .catch((error) => {
          console.log(error.message);
        });
    };
  const setUnData = (name, value)=>{
    formik.setFieldValue(name, value)
  }

  const studentFormik = useFormik({
    initialValues : {
      committeeId:'',
      pref_country:'',
      pref_role:'',
      pref_committee:'',
      secretariatsId:'',
      pressCorpsId:'',
    },
   
  })
  const setStudentFormik = (name, value)=>{
    studentFormik.setFieldValue(name, value)
  }

 

  // GetAllTheme
  const getAllTheme = async() =>{
    try {
      const res = await apiJsonAuth.get('api/v2/modelUn-institute/gettheme')
        // console.log("alldata",res.data.result)
        setEventTheme(res?.data?.result)
    } catch (error) {
      console.log(error);
    }
  }

  // GetSubTheme
  const getSubTheme = async() =>{
    try {
      const res = await apiJsonAuth.get('api/v2/modelUn-institute/getsubtheme')
        // console.log("alldata",res.data.result[0].dataValues)
        setSubTheme(res?.data?.result)
    } catch (error) {
      console.log(error);
    }
  }

  // GetFormat
  const getFormat = async() =>{
    try {
      const res = await apiJsonAuth.get('api/v2/modelUn-institute/getformat')
        // console.log("alldata",res.data.result)
        setFormat(res?.data?.result)
    } catch (error) {
      console.log(error);
    }
  }

  // console.log(eventTheme);

  useEffect(()=>{
    getFormat()
    getAllTheme();
    getSubTheme();
  },[])

  const contextValue = {
   currentStep,
   setCurrentStep,
   formik,
   eventTheme,
   subTheme,
   format,
   setUnData,
   studentFormik,setStudentFormik,
   isLoading,
   setIsLoading,

  }

  return (
    <ModelUnContext.Provider value={contextValue} >
      {children}
    </ModelUnContext.Provider>
  );
};



export default ModelUnContext;
