import React from 'react'
import Modal from 'react-bootstrap/Modal';
const DocumentModal = ({documentModal,closeDocumentModal}) => {
  return (
    <Modal show={documentModal} onHide={closeDocumentModal} size="lg">
    <Modal.Header closeButton>
        <Modal.Title className="fw-bold fs-2 p-3" style={{ color: "black" }}>
            Documents that you can submit as proof of address and identity
        </Modal.Title>
    </Modal.Header>

    <Modal.Body>
        <div className="bd-example-modal-lg p-4">
            <div>

                <div className="">
                    <p className="fw-bold fs-5"> Proof of Identity (POI):</p>
                    <p>(a) Aadhar Card</p>
                    <p>(b) Voter identity card</p>
                    <p>(c) PAN Card</p>
                    <p>(d) Passport</p>
                    <p>(e) Ration card</p>
                    <p>(f) Driving License</p>
                    <p>(g) Government ID Card</p>
                </div>

                <div className="mt-5" >
                    <p className="fw-bold fs-5">Proof of address (POA) :</p>
                    <p>(a) Aadhar Card</p>
                    <p>(b) Voter identity card</p>
                    <p>(c) Passport</p>
                    <p>(e) Ration card</p>
                    <p>(e) Driving licence</p>
                </div>
            </div>

        </div>
    </Modal.Body>

</Modal>
  )
}

export default DocumentModal