import React from 'react'
import styles from "./Nipam.module.css";

const BenefitCard = ({ benefit }) => {
    return (
        <>
        <div className={`${styles.childCardbenContainer}`}>
        <div className="d-flex flex-column">
            <h4 className='fs-4'>{benefit?.title}</h4>
            <div className={`${styles.childCardben}`}>
                <div className="text-left p-3 h-100" style={{ background: benefit?.background, borderRadius: "10px",boxShadow: "0 14px 54px #00000026 "}}>
                    <p className={`fs-5 fw-normal `}>{benefit?.desc}</p>
                </div>
            </div>
        </div>
        </div>

        </>
    )
}

export default BenefitCard