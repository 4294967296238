import React, { useEffect, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Button from "@mui/material/Button";
import { Card, CardBody, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { apiJson, apiJsonAuth } from "api";
import {toast} from "react-toastify"

export const Admin21DaysChallenge = () => {
  const [showChallenge, setShowChallenge] = useState(false);
  const [showUpdateChallenge, setShowUpdateChallenge] = useState(false);
  const [showDelteChallenge, setShowDelteChallenge] = useState(false);
  const [challenge, setChallenge] = useState([]);
  const [condtionModal, setCondtionModal] = useState("");
  const [updateId, setUpdateId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    day_count: yup.string().required("Day count is required"),
    credit: yup.string().required("Credit is required"),
    description: yup.string().required("Description is required"),
    order: yup.number().required("Order is required"),
  });

  // Add all challenges
  const AddChallenges = (values) => {
    setIsLoading(true)
    apiJsonAuth
      .post("api/v2/21daysChallenge/addTask", values)
      .then((res) => {
        setIsLoading(false)
        getAllChallanges();
        handleCloseChallenge();
        toast.dismiss();
        toast.success("Challenge added successfully")
      })
      .catch((error) => {
        setIsLoading(false)
        toast.dismiss()
        toast.error("Internal server error")
        console.log("error", error);
      });
  };

  // Update challenges
  const updateChallenges = () => {
    if (condtionModal === "Edit") {
      setIsLoading(true)
      apiJson
        .put(`api/v2/21daysChallenge/updateTask/${updateId}`, formik?.values)
        .then((res) => {
          setIsLoading(false)
          getAllChallanges();
          handleCloseUpdateChallenge();
          toast.dismiss()
          toast.success("Update challenge successfully");
        })
        .catch((error) => {
          toast.dismiss()
          toast.error("Internal server error")
          console.log("error", error);
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      day_count: "",
      credit: "",
      description: "",
      order: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }, action) => {
      if (condtionModal === "Add") {
        AddChallenges(values);
      } else if (condtionModal === "Edit") {
        updateChallenges();
      }
      resetForm();
    },
  });

  // Get all challenges
  const getAllChallanges = () => {
    apiJson
      .get("api/v2/21daysChallenge/getAllTask")
      .then((res) => {
        setChallenge(res?.data?.result);
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Internal server error")
        console.log("error", error);
      });
  };

  //   Delete Challenges
  const deleteChallenges = () => {
    setIsLoading(true)
    apiJson
      .delete(`api/v2/21daysChallenge/deleteTask/${deleteId}`)
      .then((res) => {
        setIsLoading(false)
        getAllChallanges();
        handleCloseDeleteModal();
        toast.dismiss();
        toast.success("Delete challenge successfully")
      })
      .catch((error) => {
        setIsLoading(false)
        toast.dismiss();
        toast.error("Internal server error")
        console.log("error", error);
      });
  };

  useEffect(() => {
    getAllChallanges();
  }, []);

  const setEditFormValues = (challenge) => {
    formik.setValues({
      day_count: challenge?.day_count,
      credit: challenge?.credit,
      description: challenge?.description,
      order: challenge?.order
    })
  }

  const handleShowChallenge = () => {
    setCondtionModal("Add");
    setShowChallenge(true);
  };
  const handleCloseChallenge = () => {
    setShowChallenge(false);
    formik.setErrors({});
    formik.handleReset();
  };

  const handleShowUpdateChallenge = (id) => {
    setCondtionModal("Edit");
    setUpdateId(id);

    const selectedChallenge = challenge?.find((ele) => ele?.id === id)
    setEditFormValues(selectedChallenge)
    setShowUpdateChallenge(true);
  };
  const handleCloseUpdateChallenge = () => {
    setShowUpdateChallenge(false);
    formik.setErrors({});
    formik.handleReset();
  };

  const handleShowDeleteModal = (id) => {
    setDeleteId(id);
    setShowDelteChallenge(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDelteChallenge(false);
  };

  return (
    <div>
      <div className="col-10 mx-auto mt-4">
        <div className="row d-flex align-items-center">
          <div className="col-6 d-flex align-items-center">
            <div className="">
              <ArrowBackIosNewIcon sx={{ color: "#da9eff" }} />
            </div>
            <div className=" fs-3">Twenty One Days Challenge</div>
          </div>

          <div
            onClick={handleShowChallenge}
            className="col-6 d-flex align-items-center"
          >
            <Button
              className="text-capitalize"
              size="lg"
              style={{ background: "#da9eff", color: "white" }}
            >
              Add Challenges
            </Button>
          </div>
        </div>

        <div className="mt-5">
          <Card>
            <CardBody>
              <div>
                <p className="fs-3">All Challanges</p>
              </div>

              {challenge?.map((ele, i) => {
                return (
                  <div>
                    <Card className="mt-2">
                      <CardBody>

                        <div className="row">

                            <div className="col-12 col-md-8 col-lg-10">
                            <div className="row mt-3">
                          <div className="row">
                            <div className="col-8 col-lg-2">
                              <span
                                style={{ fontSize: "18px", fontWeight: "600" }}
                              >
                                Day Count :
                              </span>
                            </div>

                            <div className="col-2 col-lg-10">
                              <span style={{ fontSize: "18px" }}>
                                {ele?.day_count}
                              </span>
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col-8 col-lg-2">
                              <span
                                style={{ fontSize: "18px", fontWeight: "600" }}
                              >
                                Credit :
                              </span>
                            </div>
                            <div className="col-2 col-lg-10">
                              <span style={{ fontSize: "18px" }}>
                                {ele?.credit}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-lg-2">
                                <span  style={{ fontSize: "18px", fontWeight: "600" }}>Description :</span>
                            </div>

                            <div className="col-lg-10">
                                <span style={{ fontSize: "18px" }}>{ele?.description}</span>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-7 col-lg-2">
                              <span
                                style={{ fontSize: "18px", fontWeight: "600" }}
                              >
                                Order :
                              </span>
                            </div>
                            <div className="col-2 col-lg-10">
                              <span style={{ fontSize: "18px" }}>
                                {ele?.order}
                              </span>
                            </div>
                          </div>
                            </div>

                            <div className="col-12 col-md-4
                             col-lg-2 d-flex align-items-center justify-content-around">
                            <div>
                            <Button
                              className="text-capitalize"
                              size="lg"
                              style={{ background: "#0571ed", color: "white" }}
                              onClick={() => handleShowUpdateChallenge(ele?.id)}
                            >
                              Edit
                            </Button>
                          </div>
                          <div>
                            <Button
                              className="text-capitalize"
                              size="lg"
                              style={{ background: "#f40157", color: "white" }}
                              onClick={() => handleShowDeleteModal(ele?.id)}
                            >
                              Delete
                            </Button>
                          </div>

                            </div>
                        </div>

                      </CardBody>
                    </Card>
                  </div>
                );
              })}
            </CardBody>
          </Card>
        </div>
      </div>

      {condtionModal === "Add" ? (
        <Modal show={showChallenge} onHide={handleCloseChallenge}>
          <Modal.Header closeButton>
            <Modal.Title>Create Challenges</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="p-2">
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <span className="d-block">Day Count</span>
                  <input
                    type="text"
                    className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                    style={{
                      color: "black",
                      background: "rgb(239, 239, 239)",
                      fontSize: "16px",
                      height: "40px",
                    }}
                    value={formik.values.day_count}
                    onChange={formik.handleChange}
                    name="day_count"
                    placeholder="Day Count"
                  />
                  {formik.errors.day_count && formik.touched.day_count ? (
                    <span className="text-danger">
                      {formik.errors.day_count}
                    </span>
                  ) : null}
                </div>

                <div className="mt-3">
                  <span className="d-block">Credit</span>
                  <input
                    type="text"
                    name="credit"
                    placeholder="Credit"
                    className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                    style={{
                      color: "black",
                      background: "rgb(239, 239, 239)",
                      fontSize: "16px",
                      height: "40px",
                    }}
                    value={formik.values.credit}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.credit && formik.touched.credit ? (
                    <span className="text-danger">{formik.errors.credit}</span>
                  ) : null}
                </div>

                <div className="mt-3">
                  <span className="d-block">Description</span>
                  <textarea
                    type="text"
                    className="form-control p-3 rounded fw-500 box-shadow-0 resize-none border-0"
                    name="description"
                    id=""
                    placeholder="Write Discription"
                    style={{
                      color: "black",
                      background: "rgb(239, 239, 239)",
                      fontSize: "16px",
                    }}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  ></textarea>
                  {formik.errors.description && formik.touched.description ? (
                    <span className="text-danger">
                      {formik.errors.description}
                    </span>
                  ) : null}
                </div>

                <div className="mt-3">
                  <span className="d-block">Order</span>
                  <input
                    type="text"
                    name="order"
                    placeholder="Order"
                    className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                    style={{
                      color: "black",
                      background: "rgb(239, 239, 239)",
                      fontSize: "16px",
                      height: "40px",
                    }}
                    value={formik.values.order}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.order && formik.touched.order ? (
                    <span className="text-danger">{formik.errors.order}</span>
                  ) : null}
                </div>

                <div className="mt-3 d-flex justify-content-end">
                  <Button
                    type="submit"
                    size="lg"
                    value="Add"
                    className="text-capitalize px-3"
                    style={{ background: "#0808ff", color: "white", fontSize: "16px" }}
                    disabled={isLoading}                  >
                    {/* {isLoading ? }Add Challenge */}
                    {isLoading ?
                      <>
                        <div className="px-3">
                          <div className="spinner-border text-light mx-auto d-flex" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        <div className="px-1">
                          <span>Add Challenge</span>
                        </div>
                      </>
                    }
                  </Button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        condtionModal === "Edit" && (
          <Modal show={showUpdateChallenge} onHide={handleCloseUpdateChallenge}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Challenge</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="p-2">
                <form onSubmit={formik.handleSubmit}>
                  <div>
                    <span className="d-block">Day Count</span>
                    <input
                      type="text"
                      className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                      style={{
                        color: "black",
                        background: "rgb(239, 239, 239)",
                        fontSize: "16px",
                        height: "40px",
                      }}
                      value={formik.values.day_count}
                      onChange={formik.handleChange}
                      name="day_count"
                      placeholder="Day Count"
                    />
                    {formik.errors.day_count && formik.touched.day_count ? (
                      <span className="text-danger">
                        {formik.errors.day_count}
                      </span>
                    ) : null}
                  </div>

                  <div className="mt-3">
                    <span className="d-block">Credit</span>
                    <input
                      type="text"
                      name="credit"
                      placeholder="Credit"
                      className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                      style={{
                        color: "black",
                        background: "rgb(239, 239, 239)",
                        fontSize: "16px",
                        height: "40px",
                      }}
                      value={formik.values.credit}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.credit && formik.touched.credit ? (
                      <span className="text-danger">
                        {formik.errors.credit}
                      </span>
                    ) : null}
                  </div>

                  <div className="mt-3">
                    <span className="d-block">Description</span>
                    <textarea
                      type="text"
                      className="form-control p-3 rounded fw-500 box-shadow-0 resize-none border-0"
                      name="description"
                      id=""
                      placeholder="Write Discription"
                      style={{
                        color: "black",
                        background: "rgb(239, 239, 239)",
                        fontSize: "16px",
                      }}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    ></textarea>
                    {formik.errors.description && formik.touched.description ? (
                      <span className="text-danger">
                        {formik.errors.description}
                      </span>
                    ) : null}
                  </div>

                  <div className="mt-3">
                    <span className="d-block">Order</span>
                    <input
                      type="text"
                      name="order"
                      placeholder="Order"
                      className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                      style={{
                        color: "black",
                        background: "rgb(239, 239, 239)",
                        fontSize: "16px",
                        height: "40px",
                      }}
                      value={formik.values.order}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.order && formik.touched.order ? (
                      <span className="text-danger">{formik.errors.order}</span>
                    ) : null}
                  </div>

                  <div className="mt-3 d-flex justify-content-end">
                    <Button
                      type="submit"
                      size="lg"
                      value="Add"
                      className=""
                      style={{ background: "#0808ff", color: "white" }}
                      disabled={isLoading}
                    >
                      {isLoading ?
                        <>
                          <div className="px-3">
                            <div className="spinner-border text-light mx-auto d-flex" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </>
                        :
                        <>
                          <div className="px-1">
                            <span>Edit</span>
                          </div>
                        </>
                      }
                    </Button>

                  </div>
                </form>
              </div>
            </Modal.Body>
          </Modal>
        )
      )}

      <Modal show={showDelteChallenge} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Challenges</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <span> Are you sure want delete this challenge?</span>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex gap-2">
            <div>
              <Button
                size="lg"
                style={{ color: "grey" }}
                className="text-capitalize"
                onClick={handleCloseDeleteModal}
              >
                Discard
              </Button>
            </div>

            <div>
              <Button
                size="lg"
                className="text-capitalize"
                style={{ background: "#0808ff", color: "white" }}
                onClick={deleteChallenges}
                disabled={isLoading}
              >
                {isLoading ?
                  <>
                    <div className="px-3">
                      <div className="spinner-border text-light mx-auto d-flex" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="px-1">
                      <span>Delete</span>
                    </div>
                  </>
                }
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
