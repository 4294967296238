import { ArrowRight, ArrowRightRounded, Close, DoubleArrowRounded, MenuOpen } from "@mui/icons-material";
import { useGlobalContext } from "global/context";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import { NavLink, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import RegisterAsk from "pages/Auth/logincomps/RegisterAsk";
import { AvatarMenu } from "./navigation";
import { Button, Chip, IconButton, Input, Stack, Typography } from "@mui/joy";
import { useMediaQuery } from "@mui/material";

const Navbar = ({ handleDrawerToggle, sidePanelOpen }) => {
  const { token, removeToken, removeUser, } = useGlobalContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showMenu, setShowMenu] = useState(false);

useEffect(() => {
  const handleResize = () => {
    if (window.innerWidth >= 768 && window.innerWidth < 1024) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  };

  // Call handleResize initially to set the initial value of showMenu
  handleResize();

  window.addEventListener("resize", handleResize);

  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);
  const handlelogout = () => {
    removeToken();
    removeUser();
    toast.dismiss();
    toast.success("Logged out successfully");
    navigate("/");
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };
  useEffect(() => {
    toggleDrawer("left", false)();
  }, [pathname]);

  // FOr Register Button
  const [showRegisterPopup, setshowRegisterPopup] = useState(false);
  const handleClose = () => setshowRegisterPopup(false);
  // const handleShow = () => setshowRegisterPopup(true);
  const [showRibbon, setShowRibbon] = useState(true);
  const [showIcon, setShowIcon] = useState(false);
  const handleCloseRibbon = () => {
    setShowRibbon(false);
  }

  const showIcons = ()=>{
    setShowIcon(prev=>!prev)
  }
  const handlekeydownOnSearch = (e)=>{
    if(e.key === "Enter"){

      navigate(`/search/${e.target.value}`);
      e.target.value = ""
    }
  }

  return (

    <>
      <div className="sticky-top">
      {/* {
        showRibbon ?
          <div className="container-fluid border-bottom bg-white" style={{borderBottomColor: 'rgb(249 249 249) !important'}}>
            <div className="container py-1 text-white">
              <Typography
                startDecorator={<><Chip variant="plain" color="warning">Live Updates</Chip></>}
                className="text-dark text-small"
                endDecorator={<IconButton variant="plain" color="warning" size="sm" onClick={handleCloseRibbon}><Close /></IconButton>}
              >
                <marquee behavior="scroll" direction="left">
                Get ready for the launch of Yuvamanthan Model United Nations registrations – stay tuned for updates! | Presenting 'The World's Largest Student-Led Carbon Footprint Reduction Initiative'.
                </marquee>
              </Typography>
            </div>
          </div> : null
      } */}
      <nav className={` px-2 px-md-5 navbar-expand-xl bg-white  ${token ? "logged-navbar" : ""}`}>
        <div className="row navbar justify-content-between">
         <div className="col-2">
         <div className="d-flex me-5 align-items-center ">
            <IconButton onClick={()=>{{handleDrawerToggle(); showIcons()}}} className={`border rounded p-2 fs-3  me-2  ${token? 'd-block':'d-block display-none' }`}>
              {sidePanelOpen ? <MenuOpen className="fs-3 text-dark" /> : <MenuIcon className="fs-3 text-success" />}
            </IconButton>
            {!token ? (<NavLink className="navbar-brand d-none d-md-inline-block" to={"/"}>
              <img style={{ maxHeight: 60, position: "relative", maxWidth: "60vw", zIndex: 200, objectFit: "contain" }} className="img-responsive py-2" src={"/logo.png"} alt="G20india" />
            </NavLink>) : null}

          </div>
         </div>
        <div className="col-10">
        <div className="d-flex w-100 justify-content-end justify-content-md-between align-items-center">
         <div className=" d-none d-md-flex  mb-2 gap-2 gap-md-3  mb-lg-0 ">
              <span className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle fw-bolder  text-capitalize navbar-title" id="programmesDropdown" to="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Programmes
                </Link>
                <div className="dropdown-menu" aria-labelledby="programmesDropdown" style={{ left: '0px', minWidth: "400px" }}>
                  <h5 className="px-3 mb-3">Programmes Overview {" "} <DoubleArrowRounded color="primary" /></h5>
                  <hr className="px-3 dropdown-divider" />
                  <Link className="dropdown-item" to="/nipam">
                    <span className="design">
                      <ArrowRightRounded color="primary" />
                    </span>
                    <span className="text-initial">Yuvamanthan NIPAM</span>
                  </Link>
                  <Link className="dropdown-item" to="/ymun">
                    <span className="design">
                      <ArrowRightRounded color="primary" />
                    </span>
                    <span className="text-initial">Yuvamanthan Model United Nations</span>
                  </Link>
                  <Link className="dropdown-item" to="/ymh">
                    <span className="design">
                      <ArrowRightRounded color="primary" />
                    </span>
                    <span className="text-initial">Yuvamanthan Hackathon</span>
                  </Link>
                  <Link className="dropdown-item" to="/ymyp">
                    <span className="design">
                      <ArrowRightRounded color="primary" />
                    </span>
                    <span className="text-initial">Yuvamanthan Model Youth Parliament</span>
                  </Link>
                  <Link className="dropdown-item" to="/model-g20">
                    <span className="design">
                      <ArrowRightRounded color="primary" />
                    </span>
                    <span className="text-initial">Yuvamanthan Model G20</span>
                  </Link>
                </div>
              </span>
              {/* <li className="nav-item ">
                <Link className="nav-link  text-capitalize navbar-title" to="/course/detail/g20-orientation-course">
                  Orientations
                </Link>
              </li> */}
              <span className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle text-capitalize navbar-title" to="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Themes
                </Link>
                <div
                  className="dropdown-menu"
                  style={{
                    minWidth: showMenu ? "420px" :"700px",
                    left: "-190px",
                    maxHeight:"500px",
                    overflowY:"auto"
                    // // right: "-150px",
                    // right: "0px",
                  }}>
                  <div className=" row ">
                    <div className="col-12 col-md-12 col-lg-6">
                      <h5 className="px-3 mb-3">Main Themes {" "} <DoubleArrowRounded color="primary" /></h5>
                      <hr className="dropdown-divider px-3" />
                      <Link className="dropdown-item" to="#">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">India’s Vision 2047</span>
                      </Link>
                      <Link className="dropdown-item d-flex" to="#">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <div>
                        <Link to="/life">
                          <span className="text-initial">
                            Mission LiFE (Lifestyle for Environment) <br /> in partnership with UNPD
                          </span>
                        </Link>
                        </div>
                      </Link>
                      <Link className="dropdown-item" to="/future-of-work">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">Future of Work</span>
                      </Link>
                      <Link className="dropdown-item" to="/peacebuilding-and-reconciliation">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">Peacebuilding and Reconciliation</span>
                      </Link>
                      <Link className="dropdown-item d-flex" to="/climate-change-and-disaster-risk-reduction">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <div>
                          <span className="text-initial">
                            Climate Change and Disaster <br /> Risk Reduction
                          </span>
                        </div>
                      </Link>
                      <Link className="dropdown-item" to="/health-well-being-and-sports">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">Health , Well-Being and Sports</span>
                      </Link>
                      <Link className="dropdown-item" to="/shared-future">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">Shared Future</span>
                      </Link>

                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <h5 className="px-3 mb-3">Sub Themes & Topics {" "} <DoubleArrowRounded color="primary" /></h5>
                      <hr className="dropdown-divider px-3" />
                      <div
                        className="w-100 pe-3"
                        style={{
                          maxHeight:  "450px",
                          overflowY: "auto",
                          overflowX: "hidden",
                        }}>
                        {/* <Link className="dropdown-item" to="/life">
                          <span className="design">
                            <ArrowRightRounded color="primary" />
                          </span>
                          <span className="text-initial">LiFE</span>
                        </Link> */}
                        <Link className="dropdown-item w-100" to="/nep">
                          <span className="design">
                            <ArrowRightRounded color="primary" />
                          </span>{" "}
                          NEP 2020
                        </Link>{" "}
                        {/* <Link className="dropdown-item" to="/education-for-all">
                          <span className="design">
                            <ArrowRightRounded color="primary" />
                          </span>{" "}
                          Education For All
                        </Link>{" "} */}
                        <Link className="dropdown-item" to="/startup-india">
                          <span className="design">
                            <ArrowRightRounded color="primary" />
                          </span>{" "}
                          Startup India
                        </Link>
                        <Link className="dropdown-item" to="/digital-transform">
                          <span className="design">
                            <ArrowRightRounded color="primary" />
                          </span>{" "}
                          Digital Transformation
                        </Link>
                        <Link className="dropdown-item" to="/cdri">
                          <span className="design">
                            <ArrowRightRounded color="primary" />
                          </span>{" "}
                          CDRI
                        </Link>
                        <Link className="dropdown-item" to="/woman-empowerment">
                          <span className="design">
                            <ArrowRightRounded color="primary" />
                          </span>{" "}
                          Woman Empowerment
                        </Link>
                        <Link className="dropdown-item" to="/unlearn-relearn-and-reskill">
                          <span className="design">
                            <ArrowRightRounded color="primary" />
                          </span>{" "}
                          Unlearn, Relearn and Reskill
                        </Link>
                        <Link className="dropdown-item d-flex align-items-center" to="/cyber-safety-for-youth">
                          <span className="design">
                            <ArrowRightRounded color="primary" />
                          </span>{" "}
                          Cyber Safety for Youth & <br /> Post-Pandemic Culture
                        </Link>
                        <Link className="dropdown-item d-flex align-items-center" to="/gig-economy">
                          <span className="design">
                            <ArrowRightRounded color="primary" />
                          </span>{" "}
                          Gig Economy
                        </Link>
                        <Link className="dropdown-item d-flex align-items-center" to="/cross-border-innovation">
                          <span className="design">
                            <ArrowRightRounded color="primary" />
                          </span>{" "}
                          Cross-Border Innovation
                        </Link>
                        <Link className="dropdown-item d-flex align-items-center" to="/transitioning-to-sustainable-living">
                          <span className="design">
                            <ArrowRightRounded color="primary" />
                          </span>{" "}
                          Transitioning to Sustainable Living
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </span>

              {/* <li className="nav-item">
                <Link className="nav-link  text-capitalize navbar-title" to="/team">
                  Team
                </Link>
              </li> */}
              <span className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle text-capitalize  navbar-title" to="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  About
                </Link>
                <div
                  className="dropdown-menu"
                  style={{
                    minWidth: "200px",
                    right: "0px",
                  }}>
                  <div className=" row row-cols-1">
                    <div className="col">
                      <Link className="dropdown-item" to="/about">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">About Yuvamanthan</span>
                      </Link>
                      <Link className="dropdown-item" to="/partnerships">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">Partnerships</span>
                      </Link>
                      <Link className="dropdown-item" to="/media-coverage">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">Media-Coverage</span>
                      </Link>
                      <a className="dropdown-item" href="/team">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">Our Team</span>
                      </a>
                      {/* <a className="dropdown-item" href="/lifeAtYuvamanthan">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">Life At Yuvamanthan</span>
                      </a> */}
                       <Link className="dropdown-item" to="">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">Work With Us</span>
                      </Link>
                      <Link className="dropdown-item" to="/eventGlimpses">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">Event Glimpses</span>
                      </Link>
                     

                    </div>
                  </div>
                </div>
              </span>
              <span className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle text-capitalize  navbar-title" to="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Engage
                </Link>
                <div
                  className="dropdown-menu"
                  style={{
                    minWidth: "200px",
                    right: "0px",
                  }}>
                  <div className=" row row-cols-1">
                    <div className="col">
                      <Link className="dropdown-item" to="/youth-community">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">Youth-Connect</span>
                      </Link>
                      <Link className="dropdown-item" to="/media">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">YMG20 Media</span>
                      </Link>
                      <Link className="dropdown-item" to="/blog">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">Blogs</span>
                      </Link>
                      <Link className="dropdown-item" to="/news">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">News</span>
                      </Link>
                      <Link className="dropdown-item" to="/contactus">
                        <span className="design">
                          <ArrowRightRounded color="primary" />
                        </span>
                        <span className="text-initial">Contact Us</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </span>
            </div>
        
         
         <div className="">
            <div className="d-flex">
              <Input 
              sx={{minWidth:180,width:180,marginRight:"5px"}}
                startDecorator={<SearchIcon sx={{ color: '#9B9B9B'}} />}
                variant="plain"
                color="neutral"
                type="search" name="search" id="search" placeholder='Search'
                onKeyDown={(e)=>handlekeydownOnSearch(e)}
                className="d-flex  d-md-none d-lg-flex"
              /> 
                {!token ? (

              <Button variant="solid" size="lg" onClick={() => { navigate("/login") }} className=" rounded-3">
                Login
              </Button>
                ):(
                  <>
                  <div className="">
                  <AvatarMenu handlelogout={handlelogout} />
                  </div>
                  </>
                )}
            </div>

          </div>
         </div>
        </div>
        </div>
      </nav>
      </div>
      {showRegisterPopup && <RegisterAsk handleClose={handleClose} />}
    </>
  );
};

export default Navbar;
