import React from 'react';
import { Facebook, Instagram, LinkedIn, Twitter, YouTube } from "@mui/icons-material";
import { Avatar, IconButton, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OptionList from '../OptionsList/OptionsList';

const ClubSideBar = ({ pageName, navigationData, setActiveTopic, setActiveSubtopic, taskCompletionStatus }) => {
    const modules = {};
    return (
        <section className="options-progress-panel">
            <div className="heading-wrapper">
                <h2 className="main-heading">{pageName}</h2>
                <h3 className="sub-heading">Progress</h3>
            </div>
            <div className="options-list">
                {navigationData.map((club, index) => (
                    <Accordion key={index} className="custom-accordion-option"
                        sx={{
                            overflow: 'hidden',
                            '&:not(:last-of-type)': {
                                borderRadius: '15px',
                            },
                            '&:not(:first-of-type)': {
                                borderRadius: '15px',
                            },
                        }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{
                                color: "white"
                            }} />}
                            className="accordion-summary"
                            sx={{ backgroundColor: "#2356fe" }}
                        >
                            <Typography className="accordion-title" sx={{
                                color: "white"
                            }}>{club.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="accordion-details">
                            {club.topics.map((topic, idx) => (
                                <OptionList
                                    key={idx}
                                    topic={topic}
                                    setActiveTopic={setActiveTopic}
                                    setActiveSubtopic={setActiveSubtopic}
                                    taskCompletionStatus={taskCompletionStatus}
                                />
                            ))}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>

            <div className="social-media-section">
                <div className="d-flex align-items-center justify-content-center">
                    <Avatar
                        alt={modules?.author}
                        src={"/android-chrome-192x192.png"}
                        sx={{ width: 66, height: 66 }}
                        className="me-4"
                    />
                    <h5 className="author-name">{modules?.author}</h5>
                </div>
                <div className="d-flex justify-content-center">
                    <IconButton
                        href="https://www.facebook.com/profile.php?id=100089167461647&mibextid=ZbWKwL"
                        target={"_blank"}
                        className="social-icon"
                    >
                        <Facebook sx={{ color: "blue" }} />
                    </IconButton>
                    <IconButton
                        href="https://twitter.com/Yuvamanthan_org"
                        target={"_blank"}
                        className="social-icon"
                    >
                        <Twitter sx={{ color: "skyblue" }} />
                    </IconButton>
                    <IconButton
                        href="https://www.linkedin.com/in/yuva-manthan-09aa2025b/"
                        target={"_blank"}
                        className="social-icon"
                    >
                        <LinkedIn sx={{ color: "blue" }} />
                    </IconButton>
                    <IconButton
                        href="https://www.instagram.com/yuvamanthan_org/"
                        target={"_blank"}
                        className="social-icon"
                    >
                        <Instagram sx={{ color: "tomato" }} />
                    </IconButton>
                    <IconButton
                        href="https://www.youtube.com/@yuvamanthan_"
                        target={"_blank"}
                        className="social-icon"
                    >
                        <YouTube sx={{ color: "red" }} />
                    </IconButton>
                </div>
            </div>
        </section>
    )
};

export default ClubSideBar;
