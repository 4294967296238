import React from 'react'
import { MyContextProvider } from '../context/contextModelUn'
import '../style/modelUn.css'
import { ModelunManageSlot } from './ModelunManageSlot'
export const ModelUnEventManage = () => {
  return (
    <MyContextProvider>
        <ModelunManageSlot/>
    </MyContextProvider>
  )
}
