import React from 'react'

export const StudentCourseScreen = () => {
  return (
    <>
    <div className='position-absolute'>
        <img src="/ui2.0dashboard/Rectangle 3396.svg" alt="player"/>
    </div>
    <div className='position-relative' style={{top: "50px",
    left: "170px"}}>
    <img src="/ui2.0dashboard/Polygon 4.svg" alt="player" />
    </div>
    </>
    
  )
}
