

// // import React, { useState, useEffect } from "react";
// // import { Box, Button, Typography } from "@mui/material";
// // import DownloadIcon from '@mui/icons-material/Download';
// // import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// // import ReactQuill from 'react-quill';
// // import 'react-quill/dist/quill.snow.css'; 
// // import * as mammoth from 'mammoth';
// // import LinkUpload from "./LinkUpload";
// // import html2pdf from 'html2pdf.js';

// // const CheckPolicy = ({ setStartingView  }) => {
// //   const [isEditing, setIsEditing] = useState(false);
// //   const [docContent, setDocContent] = useState(''); 
// //   const [showLinkUpload, setShowLinkUpload] = useState(false);

// //   useEffect(() => {
// //     const fetchDocxFile = async () => {
// //       try {
// //         const response = await fetch('/uploads/newpolicy.docx');
// //         if (!response.ok) throw new Error('Network response was not ok');
// //         const arrayBuffer = await response.arrayBuffer();
// //         const result = await mammoth.convertToHtml({ arrayBuffer });
// //         setDocContent(result.value);
// //       } catch (error) {
// //         console.error("Error fetching or converting the DOCX file: ", error);
// //       }
// //     };

// //     fetchDocxFile();
// //   }, []);

// //   const handleDownloadPdf = async () => {
// //     const element = document.getElementById('doc-content');

// //     html2pdf().from(element).toPdf().get('pdf').then(async (pdf) => {
// //       pdf.save('edited-document.pdf');
// //     });
// //   };

// //   const handleEditClick = () => {
// //     setIsEditing(!isEditing);
// //   };

// //   const handleNextClick = () => {
// //     setStartingView(true);
// //   };

// //   const handleLinkUploadClick = () => {
// //         setShowLinkUpload(true);
// //       };
// //   return (
// //     <Box sx={pageStyle}>
// //       {showLinkUpload ? (
// //         <LinkUpload setStartingView={setStartingView} />
// //       ) :

// //      ( <Box sx={contentStyle}>
// //         <Box sx={headerStyle}>
// //           <Typography sx={titleStyle}>Policy Documentation</Typography>
// //           <Box sx={buttonsWrapperStyle}>
// //             <Button
// //               variant="contained"
// //               startIcon={<DownloadIcon />}
// //               sx={{ ...buttonStyle, backgroundColor: isEditing ? "#4CAF50" : "#2a48f5" }}
// //               onClick={handleEditClick}
// //             >
// //               {isEditing ? "Save" : "Edit"}
// //             </Button>

// //             <Button
// //               variant="contained"
// //               startIcon={<DownloadIcon />}
// //               sx={{ ...buttonStyle, backgroundColor: "#4CAF50" }}
// //               onClick={handleDownloadPdf}
// //             >
// //               Download PDF
// //             </Button>
// //           </Box>
// //         </Box>

// //         <Box sx={formBoxStyle}>
// //           {isEditing ? (
// //             <ReactQuill value={docContent} onChange={setDocContent} theme="snow" />
// //           ) : (
// //             <Box
// //               id="doc-content"
// //               sx={docContentStyle}
// //               dangerouslySetInnerHTML={{ __html: docContent }}
// //             />
// //           )}
// //         </Box>

// //         <Box sx={buttonContainerStyle}>
// //           <Button
// //             variant="contained"
// //             startIcon={<ArrowForwardIcon />}
// //             sx={{ ...buttonStyle, backgroundColor: "#4CAF50" }}
// //             onClick={handleLinkUploadClick}
// //           >
// //             Next
// //           </Button>
// //         </Box>
// //       </Box>)
// // }
// //     </Box>
// //   );
// // };

// // // Styling
// // const pageStyle = {
// //   display: "flex",
// //   justifyContent: "center",
// //   alignItems: "center",
// //   width: "90%",
// //   height: "100%",
// //   marginTop:"4rem",
// //   backgroundColor: "#f5f5f5",
// //   paddingBottom: "2rem",
// //   boxSizing: "border-box",
// //   "@media (max-width: 600px)": {
// //     padding: "1rem",
// //   },
// // };

// // const contentStyle = {
// //   width: "90%",
// //   // maxWidth: "1200px",
// //   marginBottom:"2rem",
// //   backgroundColor: "#ffffff",
// //   borderRadius: "1rem",
// //   boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
// //   padding: "2rem",
// //   display: "flex",
// //   flexDirection: "column",
// //   alignItems: "center",
// //   boxSizing: "border-box",
// //   position: "relative",
// //   overflowY: "auto",
// //   height:"100%",
// //   // maxHeight: "calc(100vh - 2rem)",
// //   "@media (max-width: 600px)": {
// //     padding: "1rem",
// //     maxWidth: "100%",
// //     maxHeight: "calc(100vh - 5rem)",
// //   },
// // };

// // const headerStyle = {
// //   display: "flex",
// //   flexDirection: "column",
// //   alignItems: "flex-start",
// //   width: "100%",
// //   marginBottom: "1.5rem",
// //   "@media (min-width: 600px)": {
// //     flexDirection: "row",
// //     justifyContent: "space-between",
// //   },
// // };

// // const titleStyle = {
// //   fontSize: "2rem",
// //   fontWeight: "600",
// //   marginBottom: "1rem",
// //   color: "#333",
// //   "@media (max-width: 600px)": {
// //     fontSize: "1.5rem",
// //   },
// // };

// // const buttonsWrapperStyle = {
// //   display: "flex",
// //   flexDirection: "column",
// //   gap: "0.5rem",
// //   "@media (min-width: 600px)": {
// //     flexDirection: "row",
// //     gap: "1rem",
// //   },
// // };

// // const buttonStyle = {
// //   color: "#fff",
// //   padding: "0.75rem 1.25rem",
// //   borderRadius: "0.5rem",
// //   textTransform: "none",
// //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
// //   "&:hover": {
// //     backgroundColor: "#1068ec",
// //   },
// //   "@media (max-width: 600px)": {
// //     padding: "0.5rem 1rem",
// //   },
// // };
// // const formBoxStyle = {
// //   flex: 1,
// //   width: "90%",
// //   maxWidth: "1200px",
// //   height: "90vh",
// //   backgroundColor: "#f9f9f9",
// //   justifyItems:"center",
// //   borderRadius: "0.75rem",
// //   boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
// //   marginTop: "1.5rem",
// //   marginBottom: "1.5rem",
// //   overflowY: "auto",
// //   padding: "1.5rem",
// //   boxSizing: "border-box",
// //   "@media (max-width: 600px)": {
// //     maxWidth: "100%",
// //     height: "calc(100vh - 10rem)",
// //   }
// // };

// // const docContentStyle = {
// //   width: "100%",
// //   maxWidth: "100%",
// //   maxHeight: "90%",
// //   margin: "1rem",
// //   wordBreak: "break-word",
// //   overflowWrap: "break-word",
// //   lineHeight: "1.4",
// //   fontSize : "11px",
// //   boxSizing: "border-box",
// //   color: "#333",
// //   // padding: "1", 
// // };

// // const buttonContainerStyle = {
// //   display: "flex",
// //   justifyContent: "center",
// //   position: "absolute",
// //   bottom: "1.5rem",
// //   width: "100%",
// // };

// // export default CheckPolicy;
// import React, { useState, useEffect, useRef } from "react";
// import { Box, Button, Typography, TextField } from "@mui/material";
// import DownloadIcon from '@mui/icons-material/Download';
// import EditIcon from '@mui/icons-material/Edit';
// import LinkUpload from "./LinkUpload";
// import EditPolicy from "./EditPolicy";
// import Loader from "../SewaPage/Loader";
// import { pdfjs } from 'react-pdf';

// // Set up PDF worker
// pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// const CheckPolicy = ({ setStartingView , instituteId }) => {
//   const [showLinkUpload, setShowLinkUpload] = useState(false);
//   const [showFillableForm, setShowFillableForm] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [pdfUrl, setPdfUrl] = useState(null);
//   const [textFields, setTextFields] = useState([]);
//   const canvasRefs = useRef([]);
//   const [numPages, setNumPages] = useState(0);

//   const fetchPdf = async () => {
//     try {
//       // setLoading(true);
//       const response = await fetch(`http://localhost:3000/api/document/generate-pdf/${instituteId}?formType=sewapolicy`);
//       if (!response.ok) throw new Error('Network response was not ok.');
//       const pdfData = await response.arrayBuffer();
//       const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
//       const pdfUrl = URL.createObjectURL(pdfBlob);
//       setPdfUrl(pdfUrl);
//       // setLoading(false);

//     } catch (error) {
//       console.error('Error fetching PDF:', error);
//     }
//   };

//   const renderPdf = async () => {
//     if (pdfUrl) {
//       try {
//         const pdf = await pdfjs.getDocument(pdfUrl).promise;
//         setNumPages(pdf.numPages);

//         for (let i = 1; i <= pdf.numPages; i++) {
//           const page = await pdf.getPage(i);
//           const viewport = page.getViewport({ scale: 1.5 });
//           const canvas = canvasRefs.current[i - 1];
//           if (canvas) {
//             const context = canvas.getContext('2d');
//             canvas.height = viewport.height;
//             canvas.width = viewport.width;

//             const renderContext = {
//               canvasContext: context,
//               viewport,
//             };

//             await page.render(renderContext).promise;
//           }
//         }
//       } catch (error) {
//         console.error('Error rendering PDF:', error);
//       }
//     }
//   };

//   const downloadPdf = async () => {

//     if (pdfUrl) {
      
//       const link = document.createElement('a');
//       link.href =  pdfUrl;
//       // link.href = await pdfUrl;
//       link.download = 'policy.pdf'; 
//       link.click();
//     }
//     setLoading(true);
//     setTimeout(() => {
//       setLoading(false);
//       setShowLinkUpload(true);
//     }, 1000);
//   };

//   useEffect(() => {
//     fetchPdf();
//   }, []);

//   useEffect(() => {
//     renderPdf();
//   }, [pdfUrl]);

//   const handleTextChange = (pageIndex, index, event) => {
//     const updatedFields = [...textFields];
//     updatedFields[pageIndex][index].text = event.target.value;
//     setTextFields(updatedFields);
//   };

//   const handleEditClick = () => {
//     setLoading(true);
//     setShowFillableForm(true);
//     setLoading(false);
//   };

//   if (loading) {
//     return <Loader />;
//   }

//   return (
//     <>
//       {showLinkUpload ? (
//         <LinkUpload setStartingView={setStartingView} />
//       ) : showFillableForm ? (
//         <EditPolicy setShowLinkUpload={setShowLinkUpload} setStartingView={setStartingView} />
//       ) : (
//         <Box sx={pageStyle}>
//           <Box sx={contentStyle}>
//             <Typography variant="h4" sx={titleStyle}>
//               Download Your Policy
//             </Typography>

//             <Box sx={buttonContainerStyle}>
//               <Button
//                 variant="contained"
//                 startIcon={<DownloadIcon />}
//                 onClick={downloadPdf}
//                 sx={downloadButtonStyle}
//               >
//                 Download
//               </Button>

//               <Button
//                 variant="contained"
//                 startIcon={<EditIcon />}
//                 sx={editButtonStyle}
//                 onClick={handleEditClick}
//               >
//                 Edit
//               </Button>
//             </Box>

//             <Box sx={pdfViewerContainerStyle}>
//               <Box sx={pdfBoxStyle}>
//                 {Array.from({ length: numPages }).map((_, index) => (
//                   <Box key={index} sx={{ position: 'relative', width: '100%', height: 'auto', mb: 2 }}>
//                     <canvas
//                       ref={(el) => (canvasRefs.current[index] = el)}
//                       style={{ width: '100%', border: '1px solid #ddd' }}
//                     />
//                     {textFields[index] && textFields[index].map((field, fieldIndex) => (
//                       <TextField
//                         key={fieldIndex}
//                         value={field.text}
//                         onChange={(event) => handleTextChange(index, fieldIndex, event)}
//                         sx={{
//                           position: 'absolute',
//                           left: field.x,
//                           top: field.y,
//                           backgroundColor: 'transparent',
//                           border: '1px solid #ccc',
//                           color: '#000',
//                         }}
//                       />
//                     ))}
//                   </Box>
//                 ))}
//               </Box>
//             </Box>
//           </Box>
//         </Box>
//       )}
//     </>
//   );
// };

// // Responsive styling
// const pageStyle = {
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   height: "100%",
//   width: '100%',
//   backgroundColor: '#f0f4f8',
//   '@media (max-width: 600px)': {
//     padding: '1rem',
//   },
// };

// const contentStyle = {
//   backgroundColor: '#fff',
//   padding: '2rem',
//   borderRadius: '12px',
//   border: '1px solid #ddd',
//   boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
//   maxWidth: '900px',
//   width: '100%',
//   overflow: 'auto',
//   height: "100%",
//   '@media (max-width: 900px)': {
//     maxWidth: '90%',
//     overflow: 'auto',
//   },
//   '@media (max-width: 600px)': {
//     maxWidth: '100%',
//     padding: '1.5rem',
//   },
// };

// const buttonContainerStyle = {
//   display: 'flex',
//   justifyContent: 'flex-end',
//   gap: '1rem',
//   marginBottom: '1rem',
// };

// const downloadButtonStyle = {
//   backgroundColor: '#f55e39',
//   color: '#fff',
//   borderRadius: '0.5rem',
//   textTransform: 'none',
//   padding: '0.5rem 1rem',
//   fontSize: '0.875rem',
//   '&:hover': {
//     backgroundColor: '#ca5639',
//   },
// };

// const editButtonStyle = {
//   backgroundColor: '#1068ec',
//   color: '#fff',
//   borderRadius: '0.5rem',
//   textTransform: 'none',
//   padding: '0.5rem 1rem',
//   fontSize: '0.875rem',
//   '&:hover': {
//     backgroundColor: '#005bb5',
//   },
// };

// const pdfViewerContainerStyle = {
//   width: '100%',
//   marginTop: '1rem',
//   boxSizing: 'border-box',
// };

// const pdfBoxStyle = {
//   height: '700px',
//   borderRadius: '0.5rem',
//   overflow: 'auto',
// };

// const titleStyle = {
//   fontWeight: 'bold',
//   marginBottom: '1rem',
//   textAlign: 'center',
// };

// export default CheckPolicy;



// // Handle download click
//   // const handleDownloadClick = () => {
//   //   setLoading(true);
//   //   setTimeout(() => {
//   //     handleDownload('sewapolicy');
//   //     setLoading(false);
//   //     setShowLinkUpload(true);
//   //   }, 1000);
//   // };



// // import React, { useState, useRef, useEffect } from 'react';
// // import { PDFDocument, rgb } from 'pdf-lib';
// // import { Box, TextField, Button, IconButton, Typography } from '@mui/material';
// // import { ChevronLeft, ChevronRight } from '@mui/icons-material';
// // import { pdfjs } from 'react-pdf';

// // pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// // const Checkpolicy = ({ formType }) => {
// //   const [pdfUrl, setPdfUrl] = useState(null);
// //   const [pdfDoc, setPdfDoc] = useState(null);
// //   const [textFields, setTextFields] = useState([]);
// //   const [currentField, setCurrentField] = useState(null);
// //   const [pageNumber, setPageNumber] = useState(1);
// //   const [numPages, setNumPages] = useState(0);
// //   const canvasRefs = useRef([]);

// //   useEffect(() => {
// //     const fetchPdf = async () => {
// //       try {
// //         const response = await fetch(`http://localhost:3000/api/document/generate-pdf/292?formType=${formType}`);
// //         if (response.ok) {
// //           const pdfData = await response.arrayBuffer();
// //           const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
// //           const pdfUrl = URL.createObjectURL(pdfBlob);
// //           setPdfUrl(pdfUrl);

// //           const pdfDoc = await PDFDocument.load(pdfData);
// //           setPdfDoc(pdfDoc);
// //         } else {
// //           console.error('Failed to fetch PDF:', response.statusText);
// //         }
// //       } catch (error) {
// //         console.error('Error fetching PDF:', error);
// //       }
// //     };

// //     fetchPdf();
// //   }, [formType]);

// //   useEffect(() => {
// //     if (pdfUrl) {
// //       const renderPdf = async () => {
// //         try {
// //           const loadingTask = pdfjs.getDocument(pdfUrl);
// //           const pdf = await loadingTask.promise;
// //           setNumPages(pdf.numPages);

// //           for (let i = 1; i <= pdf.numPages; i++) {
// //             const page = await pdf.getPage(i);
// //             const viewport = page.getViewport({ scale: 1.5 });
// //             const canvas = canvasRefs.current[i - 1];
// //             const context = canvas.getContext('2d');
// //             canvas.height = viewport.height;
// //             canvas.width = viewport.width;

// //             const renderContext = {
// //               canvasContext: context,
// //               viewport,
// //             };

// //             await page.render(renderContext).promise;
// //           }
// //         } catch (error) {
// //           console.error('Error rendering PDF:', error);
// //         }
// //       };

// //       renderPdf();
// //     }
// //   }, [pdfUrl]);

// //   const handleTextChange = (pageIndex, index, event) => {
// //     const updatedFields = [...textFields];
// //     updatedFields[pageIndex][index].text = event.target.value;
// //     setTextFields(updatedFields);
// //   };

// //   const handleSave = async () => {
// //     if (pdfDoc) {
// //       try {
// //         const pdfCopy = await PDFDocument.load(await pdfDoc.save());
// //         const pages = pdfCopy.getPages();

// //         textFields.forEach((fields, pageIndex) => {
// //           const page = pages[pageIndex];
          
// //           // Clear existing text by drawing white rectangles
// //           page.drawRectangle({
// //             x: 0,
// //             y: 0,
// //             width: page.getWidth(),
// //             height: page.getHeight(),
// //             color: rgb(1, 1, 1),
// //           });

// //           fields.forEach((field) => {
// //             page.drawText(field.text, {
// //               x: field.x,
// //               y: field.y,
// //               size: 12,
// //               color: rgb(0, 0, 0),
// //             });
// //           });
// //         });

// //         const pdfBytes = await pdfCopy.save();
// //         const editedPdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
// //         const editedPdfUrl = URL.createObjectURL(editedPdfBlob);

// //         const link = document.createElement('a');
// //         link.href = editedPdfUrl;
// //         link.download = 'edited-document.pdf';
// //         link.click();
// //       } catch (error) {
// //         console.error('Error saving PDF:', error);
// //       }
// //     }
// //   };

// //   const handleCanvasClick = (pageIndex, e) => {
// //     if (currentField !== null) {
// //       const { offsetX, offsetY } = e.nativeEvent;
// //       const newField = { x: offsetX, y: offsetY, text: '' };
// //       const updatedFields = [...textFields];
// //       updatedFields[pageIndex] = [...(updatedFields[pageIndex] || []), newField];
// //       setTextFields(updatedFields);
// //       setCurrentField(newField);
// //     }
// //   };

// //   const handlePageChange = (delta) => {
// //     setPageNumber((prev) => Math.min(Math.max(prev + delta, 1), numPages));
// //   };

// //   return (
// //     <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
// //       <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%">
// //         {Array.from({ length: numPages }).map((_, index) => (
// //           <Box
// //             key={index}
// //             onClick={(e) => handleCanvasClick(index, e)}
// //             sx={{ position: 'relative', width: '100%', height: 'auto', mb: 2 }}
// //           >
// //             <canvas
// //               ref={(el) => (canvasRefs.current[index] = el)}
// //               style={{ width: '100%', border: '1px solid #ddd' }}
// //             />
// //             {textFields[index] && textFields[index].map((field, fieldIndex) => (
// //               <TextField
// //                 key={fieldIndex}
// //                 value={field.text}
// //                 onChange={(event) => handleTextChange(index, fieldIndex, event)}
// //                 sx={{
// //                   position: 'absolute',
// //                   left: field.x,
// //                   top: field.y,
// //                   backgroundColor: 'transparent',
// //                   border: '1px solid #ccc',
// //                   color: '#000',
// //                 }}
// //               />
// //             ))}
// //           </Box>
// //         ))}
// //       </Box>
// //       <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
// //         <IconButton
// //           onClick={() => handlePageChange(-1)}
// //           disabled={pageNumber <= 1}
// //           sx={{ backgroundColor: '#1068ec', color: '#fff', '&:hover': { backgroundColor: '#005bb5' } }}
// //         >
// //           <ChevronLeft />
// //         </IconButton>
// //         <Typography mx={2}>{`Page ${pageNumber} of ${numPages}`}</Typography>
// //         <IconButton
// //           onClick={() => handlePageChange(1)}
// //           disabled={pageNumber >= numPages}
// //           sx={{ backgroundColor: '#1068ec', color: '#fff', '&:hover': { backgroundColor: '#005bb5' } }}
// //         >
// //           <ChevronRight />
// //         </IconButton>
// //       </Box>
// //       <Box mt={2}>
// //         <Button onClick={handleSave} variant="contained" color="primary">
// //           Save PDF
// //         </Button>
// //       </Box>
// //     </Box>
// //   );
// // };

// // export default Checkpolicy;



import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font ,PDFDownloadLink  } from '@react-pdf/renderer';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import logoTopLeftUrl from "../../assets/Seva/schoollogo.png";
import logoTopRightUrl from "../../assets/Seva/logoYum.png";
import LinkUpload from './LinkUpload';
import Loader from '../SewaPage/Loader';
import { apiSewa } from 'api';


Font.register({
    family: 'Roboto',
    fonts: [
        { src: 'https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxP.ttf', fontWeight: 'normal' },
        { src: 'https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc9.ttf', fontWeight: 'bold' },
    ]
});

const styles = StyleSheet.create({
    page: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-between",
        backgroundColor: '#fff',
        fontFamily: 'Roboto',
        position: 'relative',
        height: '100%',
        gap: 20,
        width:"100%"
    },
    LogosContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    mainContainer: {
        flexGrow: 1,
        width: "100%",
        paddingHorizontal: 36,
    },
    logoTopLeft: {
        width: '60px',
        objectFit: "contain",
    },
    logoTopRight: {
        width: '60px',
        objectFit: "contain",
    },
    headingContainer: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    heading: {
        fontWeight: 'bold',
        fontSize: 14,
        textAlign: 'center',
        marginBottom: 10,
    },
    subheading: {
        fontWeight: 'normal',
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 15,
    },
    section: {
        padding: 10,
    },
    content: {
        fontSize: 12,
        marginBottom: 5,
        textAlign: 'justify',
        lineHeight: 1.5,
    },
    pointHeading: {
        fontWeight: 'bold',
        fontSize: 12,
        marginBottom: 5,
        marginTop: 10,
    },
    listItem: {
        fontSize: 12,
        marginBottom: 3,
        marginLeft: 10,
        textAlign: 'justify',
    },
    footer: {
        textAlign: 'center',
        fontSize: 10,
        fontWeight: 'normal',
    },
});

const MyDoc = ({ content , schoolName}) => (
    <Document>
        <Page size="A4" style={styles.page} >
            {/* Logos Container */}
            <View fixed style={styles.LogosContainer} >
                <Image src={logoTopLeftUrl} style={styles.logoTopLeft} />
                <Image src={logoTopRightUrl} style={styles.logoTopRight} />
            </View>

            <View style={styles.mainContainer}>
                {/* Heading */}
                <View style={styles.headingContainer}>
                    <Text style={styles.heading}>{schoolName.trim() + "_Sewa_Policy"}</Text>
                    <Text style={styles.subheading}>
                        Powered by Yuvamanthan.org - India’s Largest Youth Engagement Platform
                    </Text>
                </View>

                {/* Content Section */}
                <View wrap>
                    {content.map((section, index) => (
                        <View key={index} style={styles.section} >
                            {section.title && (
                                <Text style={styles.pointHeading}>{section.title}</Text>
                            )}
                            {section.text && (
                                <Text style={styles.content}>{section.text}</Text>
                            )}
                        </View>
                    ))}
                </View>
            </View>

            <Text fixed style={styles.footer}>{footerText}</Text>
        </Page>
    </Document>
);


const footerText = "Yuvamanthan.org - India’s Largest Youth Engagement Platform";


const Checkpolicy = ({ setStartingView, instituteId, setGoSteps }) => {
    const [EditPolicy, setEditPolicy] = useState(true);
    const [schoolName, setSchoolName] = useState("");
    const [loader , setLoader] = useState(false);
    const [content, setContent] = useState([
        {
            title: 'Introduction',
            text: `Social Empowerment through Work and Action (SEWA) is an initiative by CBSE aimed at instilling a sense of social responsibility, empathy, and active citizenship among students. The SEWA program encourages students to engage in community service, fostering personal growth and contributing to societal development. This policy outlines the framework for implementing SEWA activities at our school, with Yuvamanthan [India’s Largest Youth Engagement Platform] providing the platform and overall support for effective execution and monitoring.`
        },
        {
            title: 'Vision',
            text: `To create an environment where students and teachers are empowered to innovate, collaborate, and contribute to solving real-world challenges, thereby preparing them for the future.`
        },
        {
            title: "Objectives",
            text: `The primary objectives of the SEWA Policy are:
    - To promote social awareness and community involvement among students.
    - To provide opportunities for students to engage in meaningful service activities.
    - To develop students’ leadership, teamwork, and organizational skills through SEWA projects.
    - Seamless implementation, tracking, and reporting of SEWA activities.`
        },
        {
            title: "Scope of Innovation Activities",
            text: `SEWA activities, supported by Yuvamanthan, include but are not limited to:
    - Community Service Projects: These projects allow students to engage with and contribute to local communities.
    - Environmental Conservation Efforts: Students can participate in activities such as tree planting, waste management, and water conservation.
    - Health and Wellness Campaigns: Campaigns focused on promoting health, hygiene, and wellness within the school and the broader community.
    - Educational Outreach: Organizing tutoring, literacy drives, and other educational support activities for underprivileged groups.
    - Social Awareness Initiatives: Students can organize campaigns to raise awareness on social issues such as gender equality, child rights, and digital literacy.`
        }
    ]);

    // const [newTitle, setNewTitle] = useState('');
    // const [newText, setNewText] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [editTitle, setEditTitle] = useState('');
    const [editText, setEditText] = useState('');
    const [showLinkUpload, setShowLinkUpload] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState(content[0].title);
    const [isEditing, setIsEditing] = useState(false);
    const [EditNewTitle , setEditNewTitle] = useState("");
    const [EditNewText , setEditNewText] = useState("");
    

    const handleTitleChange = (event) => {
        const selected = event.target.value;
        setSelectedTitle(selected);
        const index = content.findIndex((section) => section.title === selected);
        setEditIndex(index);
        setEditTitle(content[index].title);
        setEditText(content[index].text);
    };

    const handledownloadPdf = () => {
      setLoader(true)
        setTimeout(() => {
            setShowLinkUpload(true);
            setGoSteps(2);
            setLoader(false);
        }, 1000);
    };

    useEffect(() => {
        const fetchPdf = async () => {
            try {
                setLoader(true);
                const response = await apiSewa.get(`/institutes/detail/${instituteId}`);
                const school = response.data.institute.institution_name;
                setSchoolName(school);
                setLoader(false)

            } catch (error) {
                console.error('Error fetching institute details:', error);
            }
        };

        if (instituteId) {
            fetchPdf();
        }
    }, [instituteId]);


    const handleSaveEdit = () => {
        if (editIndex !== null) {
            const updatedContent = content.map((section, index) =>
                index === editIndex ? { title: editTitle, text: editText } : section
            );
            setContent(updatedContent);
            setEditIndex(null);
            setEditTitle('');
            setEditText('');
        }
    };

       
    const handleEditingChange = () => {
        setIsEditing(!isEditing);
    }

    const HandleNewTitleAndText = () => {
      setContent([
        ...content,
        {
          title: EditNewTitle,
          text: EditNewText,
        },
      ]);
    
      setEditNewTitle("");
      setEditNewText("");
    }

if(loader){
 return  <Loader text={"Loading Your Documents "}/>
}

    return (
        <>
            {showLinkUpload ? (
                <LinkUpload setStartingView={setStartingView} />
            ) : (
                <Box
                    sx={{
                        height: { sm:"100%" ,},
                        overflowX: 'hidden',
                        width: {md :'55rem', xs:"20rem" , sm:"20rem" , lg:"60rem" , xl:"65rem"},
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: '#f7f7f7',
                    }}
                >
               
                    <Box>
                        <Box sx={{ position: "relative", display: "flex", justifyContent: 'center',  Direction: 'row', width: '100%' }}>
                            <Typography variant="h2" sx={{ color: "#2356FE", display: 'flex', justifyContent: 'center', fontFamily: "Poppins, Arial, sans-serif", fontWeight: 700, letterSpacing: "0.2rem",
                               fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' } , marginTop:"1rem"
                             }}>
                                Download Policy
                            </Typography>
                            <Box sx={{ position: "absolute", top: "50%", left: {md : "27%" , sm : "10%" , xs:0 },
                            display:{xs:"none" , sm:"block"}
                          
                          }}>
                                <img src="/uploads/HorizontalLine.png" alt="Logo" />
                            </Box>
                        </Box>

                        <Box 
  sx={{ 
    display: "flex", 
    flexDirection: { xs: "column", sm: "row" },  // Column layout on small screens, row on larger
    justifyContent: "space-between", 
    alignItems: "center", // Align buttons centrally on smaller screens
    marginBottom: "1rem", 
    marginTop: "2rem"
  }}
>
  <Box sx={{ width: { xs: "100%", sm: "auto" }, mb: { xs: "1rem", sm: "0" } }}>
    <Button 
      variant="contained" 
      color="primary"  
      onClick={handleEditingChange} 
      sx={{
        borderTopRightRadius: "2rem",
        borderBottomRightRadius: "2rem",
        width: "100%",  
      }}
    >
      Edit your PDF
    </Button>
  </Box>
  <Box sx={{ width: { xs: "100%", sm: "auto" }, mb: { xs: "1rem", sm: "0" } }}>
  <PDFDownloadLink
    document={<MyDoc content={content} schoolName={schoolName} />}
    fileName={`${schoolName.trim()}_Sewa_Policy.pdf`}
  >
    {({ loading }) =>
      loading ? 'Preparing document...' : (
        <Button 
          variant="contained" 
          onClick={handledownloadPdf} 
          sx={{
            borderTopLeftRadius: "2rem",
            borderBottomLeftRadius: "2rem",
            background: "#FF1414",
            width: "100%", 
          }}
        >
          Save & Download
        </Button>
      )
    }
  </PDFDownloadLink>
  </Box>

</Box>


                        
                        <Box>
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: { xs: '1fr', md: '35% 65%' , xl:"30% 70%" }, 
      gap: '2rem', // Space between columns
      justifyContent: 'center',
      marginTop: '3rem',
      width: '100%',
      height: '100%',
    }}
  >
    {/* Left PDF Editor section */}
    {isEditing && (
      <Box
        sx={{
          backgroundColor: '#f7f7f7',
          display: 'flex',
          flexDirection: 'column',
          // padding: 2,
          gap: { xs: 2, md: 4 },
          minHeight: '400px',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            background: 'linear-gradient(180deg, #FFF4CA 0%, #FFF4CA 100%)',
            padding: { xs: 2, sm: 3 },
            borderTopRightRadius: '0.2rem',
            borderBottomRightRadius: '2rem',
            marginBottom: '2rem',
            boxShadow: 5,

          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'row', md: 'column' },
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: { xs: 2, md: 3 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                position: 'relative',
                gap: { xs: 1, md: 1 },
                marginBottom: { xs: '1rem', md: '2rem' },
              }}
            >
              <Box
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: { xs: '24px', md: '39.37px' },
                  fontWeight: 500,
                  lineHeight: { xs: '35px', md: '53.05px' },
                  textAlign: { xs: 'center', md: 'center' },
                }}
              >
                PDF EDITOR
              </Box>
              <img src='/uploads/Write.png' alt='write' style={{ height: '3rem', width: '3rem' }} />
              <img
                src='/uploads/starLine.png'
                alt='write'
                style={{ position: 'absolute', width: '100%', top: '70%', bottom: 0, left: 0 }}
              />
            </Box>
          </Box>
          <TextField
            variant="outlined"
            fullWidth
            placeholder='Add new Title'
            value={EditNewTitle}
            onChange={(e) => setEditNewTitle(e.target.value)}
            sx={{ marginBottom: { xs: 1, md: 2 }, width: '100%' }}
          />
          <TextField
            variant="outlined"
            fullWidth
            placeholder='Add new Text'
            multiline
            rows={4}
            value={EditNewText}
            onChange={(e) => setEditNewText(e.target.value)}
            sx={{ marginBottom: { xs: 1, md: 2 }, width: '100%', borderRadius: '1rem' }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#FFC9DF',
                marginRight: 2,
                width: { xs: '8rem', md: '10rem' },
                display: 'flex',
                justifyContent: 'center',
                marginTop: { xs: '1.5rem', md: '2rem' },
                color: '#000000',
              }}
              onClick={HandleNewTitleAndText}
            >
              Save Changes
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            background: 'linear-gradient(180deg, #FFF4CA 0%, #FFF4CA 100%)',
            padding: { xs: 2, md: 3 },
            borderTopRightRadius: '0.2rem',
            borderBottomRightRadius: '2rem',
            marginBottom: '2rem',
            boxShadow: 5,
          }}
        >
           <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'row', md: 'column' },
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: { xs: 2, md: 3 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                position: 'relative',
                gap: { xs: 1, md: 1 },
                marginBottom: { xs: '1rem', md: '2rem' },
              }}
            >
              <Box
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: { xs: '19px', md: '25.37px' },
                  fontWeight: 500,
                  lineHeight: { xs: '32px', md: '53.05px' },
                  textAlign: { xs: 'center', md: 'start' },
                }}
              >
                Default Content
              </Box>
              <img src='/uploads/Write.png' alt='write' style={{ height: '3rem', width: '3rem' }} />
              <img
                src='/uploads/starLine.png'
                alt='write'
                style={{ position: 'absolute', width: '100%', top: '70%', bottom: 0, left: 0 }}
              />
            </Box>
          </Box>
          <FormControl sx={{ marginBottom: 2, width: '100%' }}>
            <Select value={selectedTitle} onChange={handleTitleChange}>
              {content.map((section, index) => (
                <MenuItem key={index} value={section.title}>
                  {section.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={editText}
            onChange={(e) => setEditText(e.target.value)}
            sx={{ marginBottom: 2, width: '100%', borderRadius: '1rem' }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#FFC9DF',
                marginRight: { xs: 0, md: 2 },
                width: { xs: '8rem', md: '10rem' },
                display: 'flex',
                justifyContent: 'center',
                marginTop: { xs: '1.5rem', md: '2rem' },
                color: '#000000',
              }}
              onClick={handleSaveEdit}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      </Box>
    )}

    {/* Right PDF Viewer section */}
    <Box
  sx={{
    display: 'grid',
    gridTemplateColumns: { xs: '1fr', md: "100%" }, // Adjust grid columns for responsiveness
    backgroundColor: '#f7f7f7',
    width: !isEditing ? { xs: "100%", sm: "90%", md: "65rem" } : "90%", // Adjust width based on screen size
    justifyContent: "center",
    flexDirection: 'column',
    minHeight: "40rem",
    height: 'auto',
    marginBottom: '2rem',
    padding: { xs: '1rem', md: '2rem' }, // Add padding for small screens
  }}
>
  <Box
    sx={{
      flexGrow: 1,
      overflow: 'auto',
      width: "100%", // Always take full width
      height: { xs: '50vh', md: '70vh' }, // Responsive height for different screens
    }}
  >
    <PDFViewer
      style={{
        width: '100%',  // Full width of the container
        height: '100%', // Full height of the container
        maxHeight: '100vh', // Prevent overflow on smaller screens
        border: 'none',
      }}
    >
      <MyDoc content={content} schoolName={schoolName} />
    </PDFViewer>
  </Box>
</Box>

  </Box>
</Box>

                    </Box>
                </Box>
            )}
        </>
    );
};

export default Checkpolicy;
