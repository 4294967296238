import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import environment from "../assests/Group 386.svg";
import "../Css/challengeday.css";
import groupimg from "../assests/Group 381.svg";
import { apiJson } from "api";
import CreditPointes from "./CreditPointes";
import { ThreeDots } from 'react-loader-spinner';

const ChallengeDays = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  const [todayChallenge, setTodayChallenge] = useState([])
  const [pastChallenge, setPastChallenge] = useState([])
  const param = useParams()
  const getTodayChallenge = async ()=>{
    try{
      setLoader(true)
      const response = await apiJson.get(`/api/v2/21daysChallenge/todaysChallenge/${param.attemptId}`)
        setTodayChallenge([response.data.result])
        setLoader(false)
    }catch(err){
      console.log(err.message)
    }
}
  const getPastChallenge = async ()=>{
    try{
      setLoader(true)
      const response = await apiJson.get(`/api/v2/21daysChallenge/pastChallenge/${param?.attemptId}`)
        setPastChallenge(response?.data?.result)
        setLoader(false)
    }catch(err){
      console.log(err.message)
    }
}
useEffect(()=>{
  getTodayChallenge()
  getPastChallenge()
},[])


  return (
    <>
      <div className="challengeDays p-3 w-100">
        <div className="challengehead position-relative d-flex justify-content-between">
          <img
            src={environment}
            alt="environment"
            width="146px"
            height="59px"
          />
          <div>
            <CreditPointes img={groupimg} />
          </div>
        </div>

    {/* ======================================today challenge====================================== */}

        <div className="todayChallenge">
          <h4 className="my-3">Today's challenge</h4>
          {
            loader ? (<div className='d-flex justify-content-center align-items-center'>
            <ThreeDots />
          </div>) : (todayChallenge?.map((value) => {
                return (
          <div
            className="challnDays d-flex justify-content-between align-items-center"
            onClick={() => navigate(`Today-Challenge/${value?.id}`) }
          >
                  <>
                    <div className="dayVal text-white w-sm-50"> Day {value?.day_count}</div>
                    <div className="daypara ps-3">
                    <div className="align-align-self-lg-stretch">
                      <p>{value?.status == null ? "Not Done" :  <span className="text-success fw-bolder">Completed</span>}</p>
                      <p>{value?.description}</p>
                    </div>
                    <div className="daycredits">
                      <p>{value?.credit} Credit</p>
                    </div>
                    </div>
                  </>
          </div>
                );
            }))
          }
        </div>

        {/* ======================================past challenges====================================== */}

        <div className="pastChallenge">
          <h4 className="my-5">Past challenge</h4>
          {
            loader ? (<div className='d-flex justify-content-center align-items-center'>
            <ThreeDots />
          </div>) : (pastChallenge?.map((value) => {
              return (
                <div
                  className="challnDays d-flex justify-content-between align-items-center my-4 "
                  onClick={() => navigate(`Today-Challenge/${value?.id}`) }
                >
                  <>
                    <div className="dayVal text-white w-sm-40 px-5"> Day {value?.day_count}</div>
                    <div className="daypara ps-3">
                    <div className="align-align-self-lg-stretch">

                      <p>{value?.status == null ? 
                      <div className="d-flex gap-5"> 
                      <span className="text-danger fw-bolder">Missed</span>
                      <span className="text-primary fw-bolder">Attempt</span> 
                      </div>
                      : <span className="text-success fw-bolder">Completed</span>}</p>
                      <p>{value?.description}</p>
                    </div>
                    <div className="daycredits">
                      <p>{value?.credit_earned ? value?.credit_earned : value?.total_credit } {value?.credit_earned ? 'Credit Earned' : 'Credit' }</p>
                    </div>
                    </div>
                  </>
                </div>
              );
          }))
          }
        </div>
      </div>
    </>
  );
};

export default ChallengeDays;
