import React, { useState , useEffect } from "react";
import "../Css/challengeday.css";
import "../Css/congress.css";
import groupimg from "../assests/Group 381.svg";
import confitiBall from "../assests/Confetti Ball.svg";
import environGroup from "../assests/Group 386.svg";
import { useNavigate } from "react-router-dom";
import { apiJson } from "api";
import { useParams } from "react-router-dom";
import CreditPointes from "./CreditPointes";
import { useGlobalContext } from "global/context";

const Congress = () => {
  const param = useParams();
  const { userData } = useGlobalContext();
  const navigate = useNavigate();
  const [pastChallenge, setPastChallenge] = useState([]);
  const [loader, setLoader] = useState(false);
  const [totalCredit, setTotalCredit] = useState();
  const userDetails = {
    userId: userData.id,
    role: userData.role,
    instituteId: userData.instituteId,
  };
  const attempChallenge = async () => {
    try {
      const response = await apiJson.post(
        "/api/v2/21daysChallenge/addAttempts/",
        userDetails
      );
      navigate(`/challenge/twenty-one-day/gameday/${response.data.result.id}`);
    } catch (err) {
      console.log(err.message);
    }
  };
  const totalCre = (value)=>{
    setTotalCredit(value)
    console.log("credit value is = " + value)
  }
  const getPastChallenge = async ()=>{
    try{
      setLoader(true)
      const response = await apiJson.get(`/api/v2/21daysChallenge/pastChallenge/${param?.attemptId}`)
        setPastChallenge(response?.data?.result)
        setLoader(false)
    }catch(err){
      console.log(err.message)
    }
}
useEffect(()=>{
  getPastChallenge()
  totalCre()
},[])
  return (
    <>
      <header>

        <main>
          <div className="my_container">
            <div className="challengehead position-relative d-flex justify-content-between align-items-center w-75 m-auto">
              <img
                src={environGroup}
                alt="environment"
                width="146px"
                height="59px"
              />

              <CreditPointes img={groupimg} totalCredit={totalCre} />

            </div>

            <div className="mainCongressSec m-auto w-75 d-flex align-items-center flex-column position-relative">
              <img
                src={environGroup}
                alt="environment"
                className="position-absolute mt-4"
              />
              <img src={confitiBall} alt="confitiBall" className="mt-5 pt-5" height="51px" width="51px" />
            <h3 className="text-center cong">Congratulations!</h3>
            </div>
            <p className="text-center congpara m-auto w-75 pb-3">
              By adopting a eco friendly habits and completing 21 days
              challenge, you have earned {totalCredit} Carbon Credits.
            </p>

            <button
              className="retakebtn border-0 "
              onClick={attempChallenge}
            >
              Retake
            </button>

            <div className="pastChallenges w-75 m-auto mt-3">
              <h3>Past Challenges</h3>

              {pastChallenge?.map((value) => {
                return (
                  <div
                    className="challnDays d-flex justify-content-between align-items-center my-4 "
                  >
                    <>
                      <div className="dayVal text-white"> Day {value?.day_count}</div>
                      <div className="daypara ps-3">
                        <div className="align-align-self-lg-stretch">

                      <p>{value?.status == null ? 
                      <div className="d-flex gap-5 pe-3"> 
                      <span className="text-danger fw-bolder">Missed</span>
                      <span className="text-primary fw-bolder">Attempt</span> 
                      </div>
                      : <span className="text-success fw-bolder">Completed</span>}</p>
                      <p>{value?.description}</p>
                        </div>
                      <div className="daycredits">
                      <p>{value?.credit_earned ? value?.credit_earned : value?.total_credit } {value?.credit_earned ? 'Credit Earned' : 'Credit' }</p>
                      </div>
                      </div>
                    </>
                  </div>
                );
              })}
            </div>
          </div>
        </main>
      </header>
    </>
  );
};

export default Congress;
