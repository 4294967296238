import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
// import Typography from "@mui/material/Typography";

const CourseViewUpdated = () => {
  const CustomExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
    color: "white",
  }));

  const [value, setValue] = React.useState(2);

  return (
    <div className="container">
      <div className="row">
        <div
          className="col-md-3"
          style={{
            background: "#C7D4FF",
            borderRadius: "0 0 15px 15px ",
            height: "140px",
            boxShadow: "0 4px 64px #C7D4FF",
          }}
        >
          <div className="d-flex flex-column justify-content-end h-100 p-4">
            <p className="h3 m-0 p-0 fs-3">Courses</p>
            <p className="m-0 p-0" style={{ fontSize: "1rem" }}>
              progress
            </p>
          </div>
          <Accordion style={{ marginTop: "1rem" }}>
            <AccordionSummary
              sx={{ background: "#2356FE", borderRadius: "10px 10px 0 0" }}
              expandIcon={<CustomExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography sx={{ color: "white" }}>
                IP Innovation Club
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ borderRadius: "10px 10px 0 0" }}>
                <div>
                  <p className="fw-semibold">1- WORK SHOPS</p>
                  <p
                    style={{ background: "#F0F0F0", borderRadius: "10px" }}
                    className="p-2"
                  >
                    {" "}
                    <CheckCircleIcon sx={{ color: "#2356FE" }} /> coding
                  </p>
                  <p
                    style={{ background: "#F0F0F0", borderRadius: "10px" }}
                    className="p-2"
                  >
                    {" "}
                    <CheckCircleIcon sx={{ color: "#2356FE" }} /> designing
                  </p>
                </div>
                <div>
                  <p className="fw-semibold">2- Training Programs</p>
                  <p
                    style={{ background: "#F0F0F0", borderRadius: "10px" }}
                    className="p-2"
                  >
                    {" "}
                    <CheckCircleIcon sx={{ color: "#2356FE" }} /> coding
                  </p>
                  <p
                    style={{ background: "#F0F0F0", borderRadius: "10px" }}
                    className="p-2"
                  >
                    {" "}
                    <CheckCircleIcon sx={{ color: "#2356FE" }} /> designing
                  </p>
                </div>
                <div>
                  <p className="fw-semibold">3-Exhibitions</p>
                  <p
                    style={{ background: "#F0F0F0", borderRadius: "10px" }}
                    className="p-2"
                  >
                    {" "}
                    <CheckCircleIcon sx={{ color: "#2356FE" }} /> coding
                  </p>
                  <p
                    style={{ background: "#F0F0F0", borderRadius: "10px" }}
                    className="p-2"
                  >
                    {" "}
                    <CheckCircleIcon sx={{ color: "#2356FE" }} /> designing
                  </p>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="col-md-9">
          <div
            style={{ height: "140px" }}
            className="d-flex align-items-end w-100"
          >
            <div
              style={{ background: "#f0f0f0" }}
              className="p-4 rounded-4 w-100"
            >
              <p className="m-0 p-0 fw-semibold ">
                Here we are introducing some activities under IP Innovation Club
              </p>
              <a href="#" className="m-0 p-0">
                click here{" "}
              </a>{" "}
              <span>to know more.</span>
            </div>
          </div>

          <p className="fs-3 mt-2 text-semibold">WORKSHOP</p>

          <div className="rounded-4" style={{ border: "5px solid #2356FE" }}>
            <img
              src="/newCourse.svg"
              alt="new img"
              width="100%"
              className="rounded-3"
            />
          </div>

          <div className="d-flex justify-content-center">
            <div
              style={{ left: "50%", transform: "translate(-50%)" }}
              className="position-relative d-flex justify-content-end gap-1 p-2"
            >
              <button className="rounded-2 px-3 py-1 border border-primary">
                Preview
              </button>
              <button
                className="rounded-2 px-3 py-1 text-white"
                style={{ background: "#2356FE" }}
              >
                Next
              </button>
            </div>

            <div className="d-flex justify-content-end w-100 p-2">
              <span className="bg-light p-1 px-5 rounded-3 fs-5 d-flex justify-content-center align-items-center">
                1/6
              </span>
            </div>
          </div>

          <p className="fs-4 mt-2">WORKSHOP</p>

          <p style={{ color: "#564F4F" }}>
            dynamic student workshop! It's all about sparking curiosity,
            fostering creativity, and hands-on learning. Dive into interactive
            activities, encourage collaboration, and make it fun. Let their
            imaginations soar as they discover, create, and grow!
          </p>

          <button
            className="rounded-3 mt-2"
            style={{
              background: "#2356FE",
              color: "white",
              padding: "0.2rem 2rem",
            }}
          >
            Enroll
          </button>

          <p
            className="fw-semibold mt-4 p-1"
            style={{ borderBottom: "1px solid silver", width: "fit-content" }}
          >
            Nipam Certificate Course For Teacher And Students
          </p>

          <p className="fs-5 ">Description</p>
          <p style={{ color: "#564F4F" }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam
            illo explicabo doloribus, quidem velit animi voluptas at libero
            harum nulla consectetur fuga doloremque possimus odio quasi placeat,
            suscipit optio repellat.
          </p>

          {/* =============================review============================= */}

          <p
            className="fw-semibold mt-4 p-1"
            style={{ borderBottom: "1px solid silver", width: "fit-content" }}
          >
            Review
          </p>

          <form>
            <input type="text" placeholder="Title" className="w-100 p-1" />

            <textarea
              name="review"
              id="review"
              className="w-100 p-2 h-75 d-inline-block my-3"
              placeholder="Write A Review For Course"
            ></textarea>

          <Box
            sx={{
              "& > legend": { mt: 2 },
            }}
          >
            {/* <Typography component="legend">Controlled</Typography> */}
            <p className="p-0 m-0 fs-6" >Rate The Course</p>
            <Rating
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
          </Box>

            <input type="submit" value="Submit"
              className="rounded-3 mt-2 border-0"
            style={{
              background: "#2356FE",
              color: "white",
              padding: "0.2rem 2rem",
            }}
             />

          </form>
        </div>
      </div>
    </div>
  );
};

export default CourseViewUpdated;
