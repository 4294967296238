import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { apiAuth, apiJson } from "api";
import { useGlobalContext } from "global/context";
import { useNavigate } from "react-router-dom";
import { Card, Dropdown, Modal } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";

export const ModelUnTeamRegist = () => {
  const {userData} = useGlobalContext()
 const navigate = useNavigate();
  const api =`/api/v2/modelUn-institute/getUNTeam/institute/${userData.id}`;

  const [fetchAllData, setFetchAllData] = useState([]);
  const [spinner, setSpinner] = useState(true);

  async function fetchData() {
    try {
      const response = await apiJson(api);
      setFetchAllData([
        ...response.data.allSecretariats,
        ...response.data.allCoordinators,
        ...response.data.allPressCorps,
      ]);
      // setSpinner(false)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const [filteredData, setFilteredData] = useState([]);
  const [activeFilter, setActiveFilter] = useState("All");

  // Filter Manage United Nations Team

  useEffect(()=>{
    setFilteredData(fetchAllData)
    setSpinner(false)
  },[fetchAllData])

  const handleFilter = (value) => {
    if (value === "All") {
      setActiveFilter("All");
      setFilteredData(fetchAllData);
    } else {
      setActiveFilter(value);
      let filter = fetchAllData?.filter((ele) => ele?.type === value);
      setFilteredData(filter);
    }
  };

  // console.log("userData", userData);

  const deleteMember = async(coorId, type)=>{
    const role = type === "Coordinators" ? "teachcerCoord" : type === "Press Corp" ? "pressCorps" : "secretariats"
    apiAuth.delete(`api/v2/modelUn-institute/deleteMember/institute/${userData?.id}/role/${role}/id/${coorId}`)
    .then((res)=>{
      console.log("res", res)
      // setFetchAllData([
      //   ...response.data.allSecretariats,
      //   ...response.data.allCoordinators,
      //   ...response.data.allPressCorps,
      // ]);
      fetchData()
    })
    .catch ((error)=> {
      console.error("Error:", error);
    })
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ cursor: 'pointer' }}>
      {children}
    </span>
  ));

  

  return (
    <>
      <div className="pt-4 px-2 px-md-5 col-11">
        <div>
          <span className="fw-500 fs-20px" style={{ color: "#979797" }}>
            Events {">"} United Nations {">"} Registration
          </span>
        </div>

        <div className="d-flex justify-content-md-between flex-column flex-md-row col-md-10">
          <div className="mt-3 mb-2">
            <span className="fw-600 fs-32px">Manage United Nations Team</span>
          </div>

          <div className="mt-3">
            <button
            onClick={()=>{
            navigate("/model-un/registration?count=7")
            }}
              className="fw-400 text-center"
              style={{
                height: "40px",
                width: "115px",
                background: "#DA9EFF",
                color: "#39005B",
                fontSize: "17.5px",
                borderRadius: "5px",
              }}
            >
              Add New
            </button>
          </div>
        </div>

        <div className="mt-3 mt-md-0">
          <span className="fw-500 fs-20px" style={{ color: "#979797" }}>
            Add or Remove members for united nation team members
          </span>
        </div>

        <div className="mt-4">
          {/* Buttons for filter */}
          <div className="">
            <ul
              class="nav nav-pills mb-3"
              id="pills-tab"
              role="tablist"
              className="col-12 col-md-7 d-flex justify-content-between"
            >
              <li class="nav-item" role="presentation">
                <button
                  onClick={() => handleFilter("All")}
                  class=""
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  className={`fw-400" ${
                    activeFilter === "All" ? "active-filter-button" : ""
                  }`}
                  style={{ fontSize: "15.5px" }}
                >
                  All
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  onClick={() => handleFilter("Secretariat")}
                  class=""
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  className={`fw-400", ${
                    activeFilter === "Secretariat" ? "active-filter-button" : ""
                  }`}
                  style={{ fontSize: "15.5px" }}
                >
                  Secretariat
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  onClick={() => handleFilter("Coordinators")}
                  class=""
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                  className={`fw-400", ${
                    activeFilter === "Coordinators"
                      ? "active-filter-button"
                      : ""
                  }`}
                  style={{ fontSize: "15.5px" }}
                >
                  Coordinators
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  onClick={() => handleFilter("Press Corp")}
                  class=""
                  id="pills-disabled-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-disabled"
                  type="button"
                  role="tab"
                  aria-controls="pills-disabled"
                  aria-selected="false"
                  className={`fw-400", ${
                    activeFilter === "Press Corp" ? "active-filter-button" : ""
                  }`}
                  style={{ fontSize: "15.5px" }}
                >
                  Press Corps
                </button>
              </li>
            </ul>
          </div>

          <div
            class="tab-content"
            id="pills-tabContent"
            className="mt-3 col-10"
          >
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabindex="0"
            >
              <div className="">
                <table class="table">

                { spinner? <div className="d-flex justify-content-center align-items-center"><TailSpin /></div>:
                  filteredData.length <= 0 ?
                  <img src="/nodata.jpg" alt="" className="d-block m-auto" width="50%" />
                  :
                  <tbody>

                    { spinner? <div className="d-flex justify-content-center align-items-center"><TailSpin /></div> : filteredData?.map((ele, i) => {
                      return (
                        <>
                          <tr>
                          <td className="col-1">
                          <div
                              className=""
                              style={{ height: "30px", width: "30px" }}
                            >
                            <img className="h-100 w-100" src={ele?.img ? ele?.img:"/assets/images/avatars/avatar_12.jpg"} alt="avatar" />

                            </div>
                          </td>
                          <td className="col-3">
                            <div>
                              {" "}
                              <span
                                className="fw-400"
                                style={{ fontSize: "14px" }}
                              >
                                {ele?.name}
                              </span>
                            </div>
                          </td>
                          <td>
                            <span
                              className="fw-400"
                              style={{ fontSize: "14px" }}
                            >
                              {ele?.role}
                            </span>{" "}
                          </td>
                          <td>
                            <span
                              className="fw-400"
                              style={{ fontSize: "14px" }}
                            >
                              {ele?.type}
                            </span>
                          </td>
                          <td>
                          <Dropdown align="start" className="exclude-this-click">
                <Dropdown.Toggle
                 as={CustomToggle}
                 >
                <span
                  className="fw-400"
                  style={{ fontSize: "14px" }}
                >
                  <MoreVertIcon
                    sx={{ color: "#23538f", fontSize: "25px" }}
                  />
                </span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    position: 'absolute',
                    inset: '0px auto auto 0px',
                    transform: 'translate(-167px, 1px)',
                    // background: "rgb(130 73 222)"
                  }}
                  className="dropdown-menu-left dropdown-menu-custom-class">
                  <Dropdown.Item className="update-delete-btn" onClick={()=>deleteMember(ele?.coordId, ele?.type)}>Delete Member</Dropdown.Item>
                 
                </Dropdown.Menu>
              </Dropdown>
                           
                          </td>
                        </tr>
                          
                        </>

                      );
                    })}
                  </tbody>
                }

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
