import React, { useEffect, useRef, useState } from 'react'
import { useGlobalContext } from "global/context";
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
export const UI2DashboardQR = () => {
    const { userData } = useGlobalContext();
    const [registerLink, setRegisterLink] = useState(`${process.env.REACT_APP_MAIN_URL}registration?registration_type=url&count=4&type=Student&instituteId=${userData?.id}`)
    const [downloadTriggered, setDownloadTriggered] = useState(false);
    const [copyLink, setCopyLink] = useState({
        Student: false,
        Teacher: false
    })
    const qrCodeRef = useRef(null);
    const qrCodeRefteacher = useRef(null)
    //======= A function for download QR code=======\\
    const downloadQRCode = () => {
        if (qrCodeRef.current) {
            html2canvas(qrCodeRef.current).then((canvas) => {
                const url = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = url;
                link.download = 'studentQrcode.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
            setDownloadTriggered(false);
        }
    };
    const downloadTeacherQRCode = () => {
        if (qrCodeRefteacher.current) {
            html2canvas(qrCodeRefteacher.current).then((canvas) => {
                const url = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = url;
                link.download = 'TeacherQrcode.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
            setDownloadTriggered(false);
        }
    };

    //======== A function for  copied the  register link ======\\
    const handleCopy = (type) => {
        let linkElement;
        let copyText;

        if (type === 'Student') {
            linkElement = document.getElementById('StudentLink');
        } else {
            linkElement = document.getElementById('TeacherLink');
        }
        setCopyLink({
            Student: type === "Student" ? !copyLink.Student : false,
            Teacher: type === "Teacher" ? !copyLink.Teacher : false,

        })

        if (linkElement) {

            copyText = linkElement.textContent || linkElement.innerText;
            const textarea = document.createElement('textarea');
            textarea.value = copyText;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');

            // Highlight the link by adding a temporary red background
            const originalBackgroundColor = linkElement.style.backgroundColor;
            linkElement.style.backgroundColor = '#9ed060';

            // Set a timeout to remove the red background after a brief period
            setTimeout(() => {
                linkElement.style.backgroundColor = originalBackgroundColor;
                setCopyLink({
                    Student: false,
                    Teacher: false,

                })
            }, 2000); // Adjust the duration as needed

            // Remove the dynamically created textarea
            document.body.removeChild(textarea);
        }
    };


    return (
        <>
            <div className="w-100 rounded-4 shadow ">
                <div className="row p-4">
                    <div className="col">
                        <span className="fw-bold">Please share these QR codes with your students and teachers to make them register.</span>
                    </div>
                </div>
                <div className="row px-4 ">
                    <div className="col ">
                        <span className="">You can also COPY the following URLs and share the same with the students or teachers to make them register on the platform.</span>
                    </div>
                </div>
                <div className="row px-4 mt-3">
                    <div className="col ">
                        <span className="">(<span className="fw-semibold fs-6">Note:</span> Please don’t share the same URL or QR Code with students as well as teachers. There are separate links to register for both. Judges/Jury or other external stakeholders can join as Teachers)</span>
                    </div>
                </div>
                <div className="row p-4">
                    <div className=" col-12 col-md-6 ">
                        <div className="d-block d-md-none mb-3">
                            <div className='mt-2 mt-md-4'>
                                <span className="fw-bold">Registration link for students</span> &nbsp;
                                {copyLink?.Student ? (<><CheckTwoToneIcon color='success' sx={{ fontSize: 18, cursor: "pointer" }} /><span className='fs-13px fw-semibold' >Copied</span></>) : (<span className='cursor-pointer' onClick={() => handleCopy('Student')}>
                                    <ContentCopyTwoToneIcon sx={{ fontSize: 18, cursor: "pointer", color: "#a277f7" }} /> <span className='fs-13px fw-semibold'>Copy Link</span></span>)}
                            </div>
                            <div>
                                <a className="text-break" href={`${process.env.REACT_APP_MAIN_URL}registration?registration_type=url&count=4&type=Student&instituteId=${userData?.id}`} id='StudentLink'>{`${process.env.REACT_APP_MAIN_URL}registration?registration_type=url&count=4&type=Student&instituteId=${userData?.id}`} </a>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-10  m-auto m-md-0 mt-2' style={{ height: "210px", minWidth: "219px", maxWidth: "212px" }} ref={qrCodeRef} >
                            <QRCode
                                style={{ height: "100%", width: "100%" }}
                                value={`${process.env.REACT_APP_MAIN_URL}registration?registration_type=url&count=4&type=Student&instituteId=${userData?.id}`} />
                        </div>
                        <div className="d-none d-md-block">
                            <div className='mt-4'>
                                <span className="fw-bold">Registration link for students</span> &nbsp;
                                {copyLink?.Student ? (<><CheckTwoToneIcon color='success' sx={{ fontSize: 18, cursor: "pointer" }} /><span className='fs-13px fw-semibold' >Copied</span></>) : (<span className='cursor-pointer' onClick={() => handleCopy('Student')}>
                                    <ContentCopyTwoToneIcon sx={{ fontSize: 18, cursor: "pointer", color: "#a277f7" }} /> <span className='fs-13px fw-semibold'>Copy Link</span></span>)}
                            </div>
                            <div>
                                <a className="text-break" href={`${process.env.REACT_APP_MAIN_URL}registration?registration_type=url&count=4&type=Student&instituteId=${userData?.id}`} id='StudentLink'>{`${process.env.REACT_APP_MAIN_URL}registration?registration_type=url&count=4&type=Student&instituteId=${userData?.id}`} </a>
                            </div>
                        </div>
                        <div className='mt-3 text-center text-md-start'>
                            <button className="mb-3 btn rounded border-0" style={{ backgroundColor: '#D3D5FF', color: '#140065' }} onClick={downloadQRCode}>Download QR Code  for student</button>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 ">

                        <div className='d-block d-md-none mb-3'>
                            <div className="mt-4">
                                <span className="fw-bold">Registration link for Teachers</span>&nbsp;
                                {copyLink?.Teacher ? (<><CheckTwoToneIcon color='success' sx={{ fontSize: 18, cursor: "pointer" }} /><span className='fs-13px fw-semibold'>Copied</span></>) : (<span className='cursor-pointer' onClick={() => handleCopy('Teacher')}  >
                                    <ContentCopyTwoToneIcon sx={{ fontSize: 18, cursor: "pointer", color: "#a277f7" }} /> <span className='fs-13px fw-semibold'>Copy Link</span>
                                </span>)}
                            </div>
                            <div>
                                <a className="text-break" href={`${process.env.REACT_APP_MAIN_URL}registration?registration_type=url&count=4&type=Teacher&instituteId=${userData?.id}`} id="TeacherLink">{`${process.env.REACT_APP_MAIN_URL}registration?registration_type=url&count=4&type=Teacher&instituteId=${userData?.id}`}</a>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-10 m-auto m-md-0 mt-2' style={{ height: "210px", minWidth: "219px", maxWidth: "212px" }} ref={qrCodeRefteacher} >
                            <QRCode
                                style={{ height: "100%", width: "100%" }}
                                value={`${process.env.REACT_APP_MAIN_URL}registration?registration_type=url&count=4&type=Teacher&instituteId=${userData?.id}`}
                            />
                        </div>
                        <div className='d-none d-md-block'>
                            <div className="mt-4">
                                <span className="fw-bold">Registration link for Teachers</span>&nbsp;
                                {copyLink?.Teacher ? (<><CheckTwoToneIcon color='success' sx={{ fontSize: 18, cursor: "pointer" }} /><span className='fs-13px fw-semibold'>Copied</span></>) : (<span className='cursor-pointer' onClick={() => handleCopy('Teacher')}  >
                                    <ContentCopyTwoToneIcon sx={{ fontSize: 18, cursor: "pointer", color: "#a277f7" }} /> <span className='fs-13px fw-semibold'>Copy Link</span>
                                </span>)}
                            </div>
                            <div>
                                <a className="text-break" href={`${process.env.REACT_APP_MAIN_URL}registration?registration_type=url&count=4&type=Teacher&instituteId=${userData?.id}`} id="TeacherLink">{`${process.env.REACT_APP_MAIN_URL}registration?registration_type=url&count=4&type=Teacher&instituteId=${userData?.id}`}</a>
                            </div>
                        </div>
                            <div className=' mt-3 text-center text-md-start'>
                                <button className="mb-3 btn  rounded border-0" style={{ backgroundColor: '#D3D5FF', color: '#140065' }} onClick={downloadTeacherQRCode}>Download QR Code for Teacher</button>
                            </div>
                            {/* <div className="">
                                <button className="btn mb-3  rounded border-0" style={{ backgroundColor: '#D3D5FF', color: '#140065' }}>Share</button>
                            </div> */}
                       
                    </div>
                </div>
            </div>
        </>
    )
}
