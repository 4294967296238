import React from 'react'

export const OnBoardingStudentSidebarNav = () => {
  return (
    <>
         <div className='d-flex align-items-center justify-content-center '>
                <div className='d-flex '
                    // classname="me-3"
                    style={{width:'17rem'}}
                >
                    <img
                    className='h-100 w-100 d-block'
                     src={'./images/onBoarding/new-logo-yuva.png'}/>
                </div>
              
            </div>
    </>
  )
}
