import React from 'react'

const CarbonCategorySelector = ({ categories, selectedCategory, onSelectCategory }) => {
  return (
    <div>
    {/* <h2>Categories {selectedCategory?.title}</h2> */}

    <div className="text-center">
              <h1 className=" fw-semibold fs-1" style={{ color: "#B3B3B3" }}>
              {selectedCategory?.title}{" "}
              </h1>
            </div>
   
  </div>
  )
}

export default CarbonCategorySelector