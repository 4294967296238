import { YmBreadCrumbs } from 'pages/ModelUnParliament'
import React, { useEffect, useState } from 'react'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from 'global/context';
import { apiJson } from 'api';
import { TextField } from '@mui/material';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import moment from "moment";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker} from '@mui/x-date-pickers';
import Select from 'react-select';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Input from '@mui/joy/Input';
import { DiscussionBoardStudentProfileList } from './component';


const StudentCreateDiscussion = () => {
    const navigate = useNavigate();
    const { userData } = useGlobalContext()
    const [userListByRole, setUserListByRole] = useState([])
    const [userList, setUserList] = useState([])
    const [submitState, setSubmitState] = useState(false)
    const [attendeeType,setAttendeeType] = useState(attendee[0].name)
    const [attendeeList,setAttendeeList] = useState([])

    let role = userData?.role
    let userId = userData?.id
    let instituteId = userData.instituteId;

    // ==============Add Event validate ==============\\\

    // ========== Find All User  api Call ========
    useEffect(() => { 
        const res = async () => {
            const result = await apiJson.get(`/api/v2/discussion_board/userList?instituteId=${instituteId}`)
            setUserList(result?.data?.userList)
        }
        res();
    }, [])
    // =============== create discussion  input field validation ============
    const CreateDiscSchema = Yup.object().shape({
        discTitle: Yup.string().required('Discussion title is required.'),
        eventDate: Yup.string().required('Event Date is required.'),
        eventSTime: Yup.string().required("Event Start time is required"),
        eventETime: Yup.string().required('Event End Time is required. ')
    })

    const CreateDiscussionFormik = useFormik({
        initialValues: {
            discTitle: "",
            eventDate: new Date(),
            eventSTime: "01:00",
            eventETime: "02:00",
            eventName: "Personal",
            attendees: []
        },
        validationSchema: CreateDiscSchema,
        onSubmit: async (values, action) => {

            const EventDate = moment(values.eventDate).format('L');
            const date = new Date();
            const currentDate = moment(date).format('L')
            if (EventDate < currentDate) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You are provide previous date!',

                })
                return
            }
            try {
                setSubmitState(true)

                const res = await apiJson.post(`/api/v2/discussion_board/createDiscussion?instituteId=${instituteId}&role=${role}&userId=${userId}`, values);
                toast.success(res?.data?.message)
                if (res) {
                    setSubmitState(false)
                }
                action.resetForm();
                navigate("/new-dashboard/discussion-board")
            } catch (error) {
                setSubmitState(false)
                console.log("Error", error)
            }
        }
    })
    const handleChangeData  = (selectSkill)=>{
        if(selectSkill){
            const addAttendees = CreateDiscussionFormik?.values?.attendees?.some((ele)=> ele.value === selectSkill?.value)
            if(!addAttendees){
            const attendeesArray = CreateDiscussionFormik?.values?.attendees || [];
            const newAttendees = [...attendeesArray, selectSkill];
            CreateDiscussionFormik.setFieldValue("attendees", newAttendees);
              }
            }
          }


    // <=========When we click select all then all user selected and when click deselect button then all user is deselected========>>
    useEffect(() => {
        if (userListByRole.length > 0) {
            CreateDiscussionFormik.setFieldValue('attendees', userListByRole);
        }

    }, [userListByRole])
    useEffect(()=>{
        if(attendeeType==="All"){
            CreateDiscussionFormik.setFieldValue('attendees',userList)
            }else{
                CreateDiscussionFormik.setFieldValue('attendees',[])
            }
    },[userList])

    const handleEventDateChange = (e) => {
    const newDate = moment(e?.$d).format('YYYY-MM-DD');
    CreateDiscussionFormik.setFieldValue('eventDate', newDate);
};

 ///======== Handle remove user ==========\\
 const handleRemoveUser = (id) => {
    let arr = CreateDiscussionFormik?.values?.attendees?.filter(item => item?.value !== id)
    CreateDiscussionFormik.setFieldValue("attendees",arr)
}
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <>
      <div className="px-3 px-md-5">
        <YmBreadCrumbs start='Events' middle="United Nations" end="Discussions" />
      </div>
        <div className="px-3 px-md-5 create-discussion" style={{ marginBottom: "40px" }} >
            {/* <=========== Second Breadcrumb start ================> */}
            <div className="d-flex justify-content-between pb-3">
                <p className='fs-3 fs-md-2 fs-lg-2 fw-semibol text-start text-lg-center cursor-pointer  '><ArrowBackIosNewTwoToneIcon sx={{ fontSize: "32px", color: "#7700FF" }} onClick={() => navigate("/new-dashboard/discussion-board")} />Create Discussion</p>
                <button
                    disabled={submitState}
                    type='submit'
                    className='btn-save-create'
                    onClick={CreateDiscussionFormik.handleSubmit}
                >
                    <AddCircleTwoToneIcon sx={{ padding: "4px" }} />
                    Save
                </button>
            </div>
            {/* <============= Discussion create  form start =============> */}
            <div className='row'>
                {/*<=================== Left Column of form start ================> */}

                <div className="col-12 col-md-6 col-lg-6 mb-2">
                    <div className="row g-2">
                        <div className="col-12 col-lg-12">
                            <label htmlFor="title" className="form-label discTitle ">Title of the discussion <span className='text-danger'>*</span></label>
                            <Input  sx={{height:45}} className="px-3" id="title" name='discTitle' placeholder='First Delegate Meeting'
                                value={CreateDiscussionFormik?.values?.discTitle}
                                onChange={CreateDiscussionFormik.handleChange}
                            />
                            {CreateDiscussionFormik.touched.discTitle && CreateDiscussionFormik.errors.discTitle ? <div className='discError'>{CreateDiscussionFormik.errors.discTitle}</div> : null}
                        </div>


                        <label htmlFor="title" className="form-label discTitle">Date and Time</label>
                        <div className="col-12 col-lg-5">
                                <MobileDatePicker                                
                                    label='Start Date'
                                    closeOnSelect
                                    disablePast
                                    renderInput={(params) => <TextField {...params} />}
                                    name='eventDate'
                                    value={CreateDiscussionFormik.values.eventDate}
                                    onChange={(date)=>handleEventDateChange(date)} 
                                />
                            {CreateDiscussionFormik.touched.eventDate && CreateDiscussionFormik.errors.eventDate ? <div className='discError'>{CreateDiscussionFormik.errors.eventDate}</div> : null}
                        </div>
                        <div className="col-12 col-lg-3">
                            <input type="time" className="form-control shadow-none border border-1 rounded-2 px-2" id="date" name='eventSTime'
                                onChange={CreateDiscussionFormik.handleChange}
                                value={CreateDiscussionFormik?.values?.eventSTime}
                            />
                            {CreateDiscussionFormik.touched.eventSTime && CreateDiscussionFormik.errors.eventSTime ? <div className='discError'>{CreateDiscussionFormik.errors.eventSTime}</div> : null}
                        </div>
                        <div className="d-flex col-lg-1 align-items-center justify-content-center ">To</div>
                        <div className="col-12 col-lg-3">
                            <input type="time" className="form-control shadow-none border border-1 rounded-2 px-2"
                                onChange={CreateDiscussionFormik.handleChange}
                                value={CreateDiscussionFormik?.values?.eventETime}
                                id="date" name='eventETime'
                            />
                            {CreateDiscussionFormik.touched.eventETime && CreateDiscussionFormik.errors.eventETime ? <div className='discError'>{CreateDiscussionFormik.errors.eventETime}</div> : null}
                        </div>
                      
                        <div className="col-12">
                            <label htmlFor="forEvent" className='form-label discTitle'>Type Of Meeting</label>
                            <Input color="neutral" disabled={false} value="Personal"  variant="outlined" sx={{height:45}} />

                        </div>
                        <div className="col-12">
                            <label htmlFor="forEvent" className='form-label discTitle'>List of Attendees</label>
                            <Select
                                onChange={(selectSkill) => {setAttendeeType(selectSkill?.name)
                                if(selectSkill?.name==="All"){
                                CreateDiscussionFormik.setFieldValue('attendees',userList)
                                }else{
                                CreateDiscussionFormik.setFieldValue('attendees',[])
                                }
                                }}
                                defaultValue={attendee.find((i)=>i?.name===attendeeType)}
                                
                                styles={customStyles}
                                options={attendee}
                                getOptionLabel={(option) => {
                                    return (
                                        <>
                                            <div className="d-flex">
                                           <span>{option?.name}</span>
                                            </div>
                                        </>
                                    )
                                }
                                }
                            />
                        </div>
                    </div>
                </div>

                {/*<=================== Right Column of form start ================> */}
                
                <div className="col-12 col-md-6 col-lg-6 ">
                {attendeeType === "Custom"  && (
                    <div className='px-2'>
                        <label htmlFor="title" className="form-label discTitle">Attendees</label>
                            <Select
                                onChange={(selectSkill) => {handleChangeData(selectSkill)}}
                                styles={customStyles}
                                options={userList?.filter((option)=> option.value !== userData?.id).map((option)=>option)}
                                getOptionLabel={(option) => {
                                    return (
                                        <>
                                            <div className="d-flex z-index-1 justify-content-between align-items-center">
                                                <div className='d-flex align-items-center'>
                                                    <Avatar
                                                        loading="lazy"
                                                        width="30"
                                                        src={option?.profile ? option?.profile : "/ui2.0dashboard/Rectangle 3148.png"}
                                                        alt={option?.first_name}
                                                    />
                                                
                                                     <span className='ms-2 fw-semibold'>{option?.first_name} {option?.last_name ? option?.last_name : null}</span>
                                                </div>
                                                <Chip label={option?.role} variant="outlined"  color='warning'  />
                                            </div>
                                        </>
                                    )
                                }
                                }
                                filterOption={({ data }, inputValue) => {
                                            const eventLowerCase = data?.first_name?.toLowerCase();
                                            const optionLowerCase = data?.last_name?.toLowerCase();
                                            const inputLowerCase = inputValue?.toLowerCase();
                                            return (
                                                eventLowerCase.includes(inputLowerCase) || optionLowerCase.includes(inputLowerCase)
                                            );
                                        }}
                            />
                    </div>    )}
                    <div className='mx-3'>
                                 <h2 className='fs-4 fw-semibold mt-2'>Select Candidates</h2>
                        {CreateDiscussionFormik?.values?.attendees?.map((data) => {

                            return (
                                <>
                                <div className='d-flex align-items-center justify-content-md-between mt-3' key={data?.value}>

                                    <DiscussionBoardStudentProfileList data={data} handleRemoveUser={handleRemoveUser} />
                                </div>
                                </>
                            )
                        })}
                    </div>         
                </div>
              
                <div className="col-12 col-md-6 col-lg-6">
                     {/* <========= student card list  ============> */}
                    
                </div>
                {/*<=================== Right Column of form End ================> */}

            </div>

        </div>

    </>
</LocalizationProvider>
  )
}
const customStyles = {
    control: (provided) => ({
        ...provided,
        padding: '3px',
       cursor:"pointer",
        background: '#eeeeee4d',
        // boxShadow:'none'
    }),
  
  };

  const attendee = [
    {
        value:1,
        name:"All",

    },
    {
        value:2,
        name:"Custom"
    }
  ]
export default StudentCreateDiscussion