import React from 'react'
import "./UnderProgress.css";
import loadingGif from "../../assets/building-website.jpg";
const UnderProgress = () => {
    return (
        <div className='under-progress-container'>
            <h2>Work in Progress</h2>
            <img src={loadingGif} alt="Module Under Construction" />
        </div>
    )
}

export default UnderProgress