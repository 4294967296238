import React, { useEffect, useState } from 'react'
import { Avatar, Button, Divider } from '@mui/joy';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from 'react-router-dom';
import { apiJson } from 'api';
import { useGlobalContext } from 'global/context';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import { Modal } from "react-bootstrap";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PendingIcon from '@mui/icons-material/Pending';
import TaskIcon from '@mui/icons-material/Task';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import moment from "moment";

export const SubAdminStateData = () => {
  const [assignData, setAssignData] = useState({});
  const {userData} = useGlobalContext();
  const [stateDataSubAdmin, setStateDataSubAdmin] = useState([]);
  const [handleShowEdit, setHandleShowEdit]= useState(false)
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate()
  const {state} = useParams();
  console.log(state)

      // Get subAdmin name
  const getSubAdminData = ()=>{
    apiJson.get(`api/v2/admin/getAssignData/${userData?.id}`)
    .then((res)=>{
      setAssignData(res?.data?.getAssignSingle)
    })
    .catch((error)=>{
      console.log(error.message);
    })
  }

  
  const getBasedOnStateDistrict = ()=>{
      apiJson.get(`api/v2/admin/getStateDistrict/${state}`)
    .then((res)=>{
        setStateDataSubAdmin(res?.data?.getCbseData)
    })
    .catch((err)=>{
        console.log(err.message);
    })
}

useEffect(()=>{
  getBasedOnStateDistrict();
},[])

// useEffect(() => {
//   const tableBody = document.getElementById('table-body');

//   const handleKeyDown = (event) => {
//       if (event.ctrlKey && (event.key === 'a' || event.key === 'c' )) {
//           event.preventDefault();
//       }
//   };

//   const handleDragStart = (event) => {
//       event.preventDefault();
//   };

//   tableBody.addEventListener('keydown', handleKeyDown);
//   tableBody.addEventListener('dragstart', handleDragStart);

//   return () => {
//       tableBody.removeEventListener('keydown', handleKeyDown);
//       tableBody.removeEventListener('dragstart', handleDragStart);
//   };
// }, []);

const handleShowEditModal = ()=>{
  setHandleShowEdit(true)
}
const handleCloseEditModal = ()=>{
  setHandleShowEdit(false)
}
  return (
    <div>
        <div>
        <div className='col-10 mx-auto'>
        <div className='d-flex justify-content-between align-items-center'>
        <div><span className='fs-3 fw-500'>CBSE Data {assignData?.state}</span></div>
        {/* <div><Button onClick={getBasedOnStateDistrict}>{assignData?.state}</Button></div> */}
        </div>


        <div className='row mt-3'>
                {stateDataSubAdmin?.map((ele)=>{
                  // const time = moment().diff(moment(ele.creation_time), "days")
                  const currentDate = new Date();
                  const creationTime = new Date(ele?.creation_time?.replace(' ', 'T'));
                  const timeDifference = currentDate - creationTime;
                  const dayCount = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                  console.log("dayCount", dayCount)
                        return (
                                <div
                                key={ele?.id} 
                                onClick={(ele?.call_status == 'connected' && ele?.detail_sent_status == 'sent' && ele?.registration_status == 'done') || 
                                 ( ele?.call_status == 'connected' && ele?.detail_sent_status == 'notsent' && ele?.registration_status == 'pending') ||
                                 (ele?.call_status == 'connected' && ele?.detail_sent_status == 'sent' && ele?.registration_status == 'pending' ) ||
                                 (ele?.call_status == 'connected' && ele?.detail_sent_status == 'sent' && (ele?.registration_status !== 'done' || ele?.registration_status !== 'pending') ) ||
                                 (ele?.call_status == 'connected' && ele?.detail_sent_status == 'notsent' && ele?.registration_status == 'done' ) ||
                                 (ele?.call_status == 'connected' && (ele?.detail_sent_status !== 'notsent' || ele?.detail_sent_status !== 'sent') && ele?.registration_status == 'done' ) ||
                                 (ele?.call_status == null && ele?.detail_sent_status == null && ele?.registration_status == null )
                                 ? () => navigate(`/subAdmin/SingleStateData/${ele?.id}`) : null}
                                 className={`col-12 col-md-6 col-lg-4 rounded shadow-sm border-3 text-center mt-5 p-3 cursor-pointer
                                  ${
                                    // (ele?.call_status !== 'connected' && ele?.detail_sent_status == 'sent' && ele?.registration_status == 'done') || 
                                 ( ele?.call_status !== 'connected' && ele?.detail_sent_status == 'notsent' && ele?.registration_status == 'pending') ||
                                //  (ele?.call_status !== 'connected' && ele?.detail_sent_status == 'sent' && ele?.registration_status == 'pending' ) ||
                                 (ele?.call_status !== 'connected' && ele?.detail_sent_status == 'notsent' && ele?.registration_status == 'done' )
                                    ? 'btn-onboard-fill-disabled-Inter' : null}`
                                  }>
                                    <div className='mt-4'>
                                        <div><span className='fw-600 fs-6'>Institute Name: </span><span> {ele?.institution_name?.toUpperCase()}</span></div>
                                    </div>
                                    {ele?.email && 
                                    <div className='mt-2'>
                                        <div style={{wordWrap: 'break-word'}}><span className='fw-600 fs-6'>Email: </span><a href={`mailto:${ele.email}`}>{ele?.email?.toLowerCase()}</a></div>
                                    </div>
                                    }

                            {dayCount < 1 ? (
                                <div className='mt-2'>
                                  <div><span className={`${ele?.call_status === 'connected' ? 'text-success' : 'text-danger'} fw-bold`}>
                                    {ele?.call_status === 'connected' ?
                                      <span>Call Connected  <CheckCircleIcon /></span>
                                      : ''}
                                  </span></div>
                                </div>
                              ) : ("")
                            }
                                    <div className='mt-2 fw-bold'>
                                        <div><span className={ele?.registration_status === 'done' ? 'text-success' : 'text-danger'}>{ele?.registration_status === 'done' ? <span>Registration Done <HowToRegIcon/></span> : ele?.registration_status === 'pending' ? <span>Registration Pending <PendingIcon/></span> : ''}</span></div>
                                    </div>
                                    <div className='mt-2 fw-bold'>
                                        <div><span className={ele?.detail_sent_status === 'sent' ? 'text-success' : 'text-danger'}>{ele?.detail_sent_status === 'sent' ?  <span>Details Sent <TaskIcon/></span> : ele?.detail_sent_status === 'notsent' ?  <span>Details Not Sent <AssignmentLateIcon/></span> : '' }</span></div>
                                    </div>
                                    <div className='mb-4 mt-2'>
                                        <div>{ele?.reminder_status ? <span className='fw-bold text-warning'>Reminder {ele?.reminder_status} <PendingActionsIcon className='blink_reminder'/></span> : ""}</div>
                                    </div>
                                </div>
                        )
                    })
                }
              </div>

            {/* {stateDataSubAdmin?.map((ele)=>{
                return (
                  <>
            <div className='shadow-lg border-2 rounded-2 p-3 mt-3'style={{userSelect: "none"}}>
        <div className='row mt-2'>
            <div className='col-12 col-lg-3 fs-6 fw-600'>Affiliation No:</div>
            <div className='col-12 col-lg-6 fs-6 fw-500'>{ele?.affliate_number} </div>
            <div className='col-lg-3 d-flex justify-content-end'><Button onClick={handleShowEditModal}>Edit</Button></div>

        </div>
        <div className='row mt-2'>
            <div className='col-12 col-lg-3 fs-6 fw-600'>Institute Name:</div>
            <div className='col-12 col-lg-9 fs-6 fw-500'>{ele?.institution_name}</div>
        </div>
        <div className='row mt-2'>
            <div className='col-12 col-lg-3 fs-6 fw-600'>Address:</div>
            <div className='col-12 col-lg-9 fs-6 fw-500'>{ele?.address}</div>
        </div>
        <div className='row mt-2'>
            <div className='col-12 col-lg-3 fs-6 fw-600'>Email:</div>
            <div className='col-12 col-lg-9 fs-6 fw-500'><a href={`mailto:${ele?.email}`}>{ele?.email} <span className='p-2 rounded-2' style={{background: "#ecf5f5"}}><EmailOutlinedIcon/></span></a></div>
        </div>
        <div className='row mt-2'>
            <div className='col-12 col-lg-3 fs-6 fw-600'>State:</div>
            <div className='col-12 col-lg-9 fs-6 fw-500'>{ele?.state}</div>
        </div>
        <div className='row mt-2'>
            <div className='col-12 col-lg-3 fs-6 fw-600'>District:</div>
            <div className='col-12 col-lg-9 fs-6 fw-500'>{ele?.district}</div>
        </div>
        <div className='row mt-2'>
            <div className='col-12 col-lg-3 fs-6 fw-600'>Pincode:</div>
            <div className='col-12 col-lg-9 fs-6 fw-500'>{ele?.pincode}</div>
        </div>
        <div className='row mt-2'>
            <div className='col-12 col-lg-3 fs-6 fw-600'>Stdcode:</div>
            <div className='col-12 col-lg-9 fs-6 fw-500'>{9650840214} <a className='p-2 rounded-2' style={{background: "#ecf5f5"}} href={`tel:${8383954512}`}><PhoneEnabledOutlinedIcon/></a></div>
        </div>
        </div>
                  </>
                )
              })
            } */}

        {/* <div className='mt-5 rounded-2 border-3 shadow-sm p-3 mb-5 bg-grey'>
            <div><span className='fs-3 fw-600' style={{color: '#434343'}}>Comments</span></div>
            <hr />
            
            <div className='' style={{height: '300px'}}>
                <div className='d-flex align-items-center'>
                <div>
                <span><Avatar src='' alt=''/></span>
                </div>
                <div><span>Nitesh Rawat</span></div>
                </div>
            </div>

            <div className='rounded border-5'>

            </div>
        </div> */}


        {/* <div className="mt-5 mb-2">
                <TableContainer component={Paper} >
                    <Table aria-label="simple table">
                        <TableHead sx={{background:"#9700de"}}>
                            <TableRow>
                                <TableCell className='fw-semibold' align='center'  sx={{color:"#ffffff"}}>Affliation</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>Institute Name</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>State</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>Email</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>StdCode</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ padding: "5px 10px" }} id="table-body">
                            {stateDataSubAdmin?.map((row,i) => (
                                <TableRow
                                    key={row?.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, marginTop: "5px",  userSelect: "none", MozUserSelect: "none", WebkitUserSelect: "none", msUserSelect: "none" }}
                                > 
                                <TableCell align='center'>{row?.affliate_number}</TableCell>
                                    <TableCell align="center">{row?.institution_name}</TableCell>
                                    <TableCell align="center"><span className=''>{row?.state}</span></TableCell>
                                    <TableCell align='center'><a href={`mailto:${row?.email}`}>{row?.email}</a></TableCell>
                                    <TableCell align='center'><a href={`tel:${8383954512}`}>{row?.stdcode }</a></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
       </div> */}
       <Divider className="my-3" />

      </div>
        </div>

        <Modal show={handleShowEdit} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          
          <Modal.Body>
            <div>
            <div className="mt-3">
                  <span className="d-block">Email</span>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                    style={{
                      color: "black",
                      background: "rgb(239, 239, 239)",
                      fontSize: "16px",
                      height: "40px",
                    }}
                    // value={formik.values.credit}
                    // onChange={formik.handleChange}
                  />
                  {/* {formik.errors.credit && formik.touched.credit ? (
                    <span className="text-danger">{formik.errors.credit}</span>
                  ) : null} */}
                </div>
            <div className="mt-3">
                  <span className="d-block">Phone</span>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    className="form-control ps-3 rounded fw-500 box-shadow-0 resize-none border-0"
                    style={{
                      color: "black",
                      background: "rgb(239, 239, 239)",
                      fontSize: "16px",
                      height: "40px",
                    }}
                    // value={formik.values.credit}
                    // onChange={formik.handleChange}
                  />
                  {/* {formik.errors.credit && formik.touched.credit ? (
                    <span className="text-danger">{formik.errors.credit}</span>
                  ) : null} */}
                </div>

            </div>

            <div className="mt-3 d-flex justify-content-end">
                  <Button
                    type="submit"
                    size="lg"
                    value="Add"
                    className="text-capitalize px-3"
                    style={{ background: "#0808ff", color: "white", fontSize: "16px" }}
                    disabled={isLoading}                  >
                    {/* {isLoading ? }Add Challenge */}
                    {isLoading ?
                      <>
                        <div className="px-3">
                          <div className="spinner-border text-light mx-auto d-flex" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        <div className="px-1">
                          <span>Edit Data</span>
                        </div>
                      </>
                    }
                  </Button>
                </div>
          </Modal.Body>
        </Modal>

          {/* <Modal>
            <Modal.Header>
              <Modal.Title>Edit</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              
            </Modal.Body>
          </Modal> */}
    </div>
  )
}
