import React, { useState } from "react";
import "../style/OnBoardingStyle.css";
import { Link, useNavigate } from "react-router-dom";
import { Typography } from "@mui/joy";
import { Modal } from "react-bootstrap";
import Faq from "./Faq";


export const OnBoardingNav = () => {
  const navigate = useNavigate();
  const [showGetHelpModal, setGetHelpModal] = useState(false);

  // Modal Toggle
  const handleShowModal = () => {
    setGetHelpModal(true);
  };
  const handleClose = () => {
    setGetHelpModal(false);
  };

  return (
    <>
      {/* <div className='d-flex flex-column flex-md-row justify-content-md-between align-items-center'>
            <div style={{width:''}} onClick={()=>navigate("/")} className='yuva-logo-img d-flex align-items-center cursor-pointer'>
                    <img
                    style={{width: '250px'}}
                    src={'./images/onBoarding/new-logo-yuva.png'}/>
            </div>
            <div className='d-flex justify-content-center align-items-center'>
                <span
                style={{
                    fontWeight:'500',
                    // fontSize:'18px'
                }}
                 className="having-trouble me-2 ">
                 Having Trouble? 
                  </span> 
                &nbsp;
                <span className="having-trouble"
                    style={{
                        marginTop:"0.2rem",
                        color:'#3800D8',
                        fontWeight:'600',
                        cursor:'pointer',
                        // fontSize:'18px'
                    }}
                >Get Help</span>
            </div>
        </div> */}
      <div className="auth-header d-flex flex-column flex-md-row justify-content-between align-items-center">
        <Link to={"/"} className="brand my-5 my-md-0">
          <img
            src={"./images/onBoarding/new-logo-yuva.png"}
            alt="Yuvamanthan"
            style={{
              width: "18em",
            }}
          />
        </Link>
        <Typography className="fw-bold d-none d-md-block">
          Having Trouble?{" "}
          <Link
            to={"#"}
            onClick={handleShowModal}
            className="text-decoration-none"
          >
            Get Help
          </Link>
        </Typography>
      </div>

      <Modal
        size="lg"
        show={showGetHelpModal}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {/* <Modal.Header closeButton>
       <Modal.Title id="example-modal-sizes-title-lg">
         Help
       </Modal.Title>
     </Modal.Header> */}
        <Modal.Body>
          <div className="mt-3">
            <span
              style={{
                fontWeight: "500",
              }}
              classname="me-2"
            >
              Having Trouble?
            </span>
            &nbsp;
            <span
              style={{
                // color: "#7341e6",
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              Get Help
            </span>
          </div>

          <div className="mt-3">
            <h3 style={{ fontSize: "17px", fontWeight: "600" }}>
              Contact our dedicated customer support at{" "}
              <a href="mailto:help@yuvamanthan.org">help@yuvamanthan.org</a> or
              call us on 9560771911
            </h3>
          </div>

          <div>
            <div className="mt-4">
              <span style={{ fontSize: "1.3rem", fontWeight: "600" }}>FAQs</span>
            </div>

               <Faq />

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
