import React, { useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
} from "@mui/material";


import CheckPolicy from "./CheckPolicy";
import LinkInput from "./LinkInput";

const LinkUpload = ({ setStartingView, instituteId ,setGoSteps}) => {
  const [policyAccepted, setPolicyAccepted] = useState(true);
  const [showCheckPolicy, setShowCheckPolicy] = useState(false);


  return (
    <>
      <Box sx={policyContainerStyle}>
        {showCheckPolicy ? (
          <CheckPolicy setStartingView={setStartingView} instituteId={instituteId} />
        ) : (
          <>
            <Box sx={policyStyle}>
              <Box sx={titleContainerStyle}>
                <Box sx={{ display: "flex"}}>
                  <Typography
                    component="span"
                    variant="h1"
                    sx={{
                      color: "#2356FE",
                      marginRight: "1.5rem",
                      fontFamily: "Poppins, Arial, sans-serif",
                      lineHeight: "156px",
                      fontWeight: 700,
                      marginLeft:"1.5rem",
                      fontSize:{sm:"4rem" , xs:"3rem" , md:"6rem"}

                    }}
                  >
                    ONE
                  </Typography>
                  <Typography
                    component="span"
                    variant="h1"
                    sx={{
                      color: "#0D0D0D",
                      fontFamily: "Poppins, Arial, sans-serif",
                      lineHeight: "156px",
                      fontWeight: 700,
                      fontSize:{sm:"4rem" , xs:"3rem" , md:"6rem"}
                    }}
                  >
                    Last
                  </Typography>
                  <Box
  component="img"
  src="/uploads/leaf.png"
  alt="Leaf"
  sx={{
    width: { xs: "3rem", sm: "5rem", xl: "12rem" },
    height: { xs: "7rem", sm: "8rem", xl: "9rem" },
    marginTop: "1rem",
  }}
/>

                </Box>
              </Box>
              <Box sx={formSectionStyle}>
                <Typography variant="h1" sx={{ fontWeight: 700,marginLeft:"1.5rem", ...formTitleStyle, fontSize:{sm:"4rem" , xs:"3rem" , md:"6rem"}


 }}>
                  STEP
                </Typography>
                <Typography variant="body1" sx={NoteparagraphStyle}>
                Note : Kindly paste your policy link over here to proceed.
                </Typography>
              </Box>
              <Box sx={formFields}>
                <Grid container spacing={2} >
                  <Grid item xs={12} md={7}>
                    <Box sx={formContainerStyle}>
                      <Divider sx={{ margin: "20px 0" }} />
                      <Typography variant="h5" sx={{...subTitleStyle, marginLeft:"1.5rem"}}>
                        Key Terms:
                      </Typography>
                      <Typography variant="body1" sx={{...paragraphStyle , marginLeft:"1.5rem"}}>
                        1. All participants must adhere to the ethical standards outlined.
                        <br />
                        2. Projects must be completed within the given timeframe.
                        <br />
                        3. Weekly progress reports must be submitted to your mentor.
                        <br />
                        4. Any issues must be reported to the mentor immediately.
                      </Typography>
                      <Typography variant="h6" sx={{...subTitleStyle,  marginLeft:"1.5rem"}}>
                        Next Steps:
                      </Typography>
                      <Typography variant="body1" sx={{...paragraphStyle, marginLeft:"1.5rem"}}>
                        - Submit your final project report.
                        <br />
                        - Ensure mentor and institution approvals.
                        <br />
                        - Maintain regular communication with your mentor.
                        <br />
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Box sx={sideBanner}>
                      <img
                        src="/uploads/policylink.png"
                        alt="Logo"
                        style={{
                          width: "70%",
                          height: "auto",
                          marginTop: "1rem",
                        }}
                      />
                    
                      <Box sx={{ width:"90%" , margin:"1rem 1rem "  }}>
                        <LinkInput setStartingView={setStartingView} setGoSteps={setGoSteps}  instituteId={instituteId}/>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ justifyContent: "center", alignContent: "center", display: "flex" }}>
                <img src="/uploads/HorizontalLine.png" alt="Logo" />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

const policyContainerStyle = {
  display: "flex",
  flexDirection: "column",
  marginBottom:"3rem",
  alignItems: "center",
  borderRadius: "1.5rem",
  width:{ xl: "140%" , md:"120%" , sm:"100%" , xs:"90%"},
  height: "auto",
  overflow: "auto",
  backgroundColor: "#f0f4f8",
  boxShadow: "2px 5px 5px rgba(0, 0, 0, 0.1)",
};

const policyStyle = {
  backgroundColor: "#fff",
  borderRadius: "1.5rem",
  border: "1px solid #ddd",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  width: "100%",
  height: "auto",
  position: "relative",
  overflow: "auto",
  // overflowX: "hidden",
  overflowWrap: "break-word",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "40%",
    left: "0%",
    width: "50%",
    height: "100%",
    background: "linear-gradient(50deg, transparent, #4b87f59b)",
    opacity: 0.1,
    borderRadius: "50%",
    transform: "translate(-50%, -50%)",
    transition: "all 0.3s ease-in-out",
  },
};

const titleContainerStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const formFields = {
  display: "flex",
  flexDirection: "column",
  marginBottom: "2rem",
  columnGap: "4rem",
  alignItems: "flex-start",
  width: "100%",
};

const formSectionStyle = {
  display: "flex",
  flexDirection: "row",
  columnGap: "4rem",
  alignItems: "center",
  marginBottom: "0",
  overflowWrap: "break-word",
};

const formContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
 
};

const sideBanner = {
  background: "linear-gradient(to bottom right, #2356FE 0%, #2356FE66 100%)",
  borderTopLeftRadius: "3rem",
  borderBottomLeftRadius: "3rem",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "3rem",
};

const NoteparagraphStyle = {
  marginBottom: '1.5rem',
  lineHeight: '1.75',
  textAlign: 'start',
  alignItems:"base-line",
  width: '60%',
  overflowWrap: 'break-word',
  color:"red",
};

const paragraphStyle = {
  marginBottom: '1.5rem',
  lineHeight: '2.5',
  textAlign: 'start',
  width: "100%",
  fontWeight:"400",
  overflowWrap: 'break-word',
};
// const formTitleStyle = {
//   textAlign: 'left',
//   justifyContent: 'flex-start',
// };
const subTitleStyle = {
  marginBottom: "1rem",
  fontWeight: "bold",
  fontSize: "1.3rem",
  textAlign: "start",
  width: "100%",
  overflowWrap: "break-word",
};

const formTitleStyle = {
  fontWeight: 700,
  justifyContent: 'flex-start',
  fontFamily: "Poppins, Arial, sans-serif",
  textAlign: "center",
  overflowWrap: "break-word",
  color: "#000",
  marginBottom: "0.3rem",
};

const buttonStyle = {
  borderRadius: "25px",
  height: "45px",
  color: "#fff",
  backgroundColor: "#2356FE",
  fontWeight: 700,
  fontSize: "1rem",
  "&:hover": {
    backgroundColor: "#4b87f5",
  },
};

export default LinkUpload;
