import { DeleteForever,Link} from '@mui/icons-material';
import { Button, TextField } from '@mui/material';
import {  apiAuth } from 'api';
import { useFormik } from 'formik';
import { useGlobalContext } from 'global/context';
import NotFoundGif from 'layout/NotFoundGif';
import React, { memo, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import AddIcon from '@mui/icons-material/Add';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
function CommuinqueUpload() {
  const { userData} = useGlobalContext();
  const [mediaPost, setMediapost] = useState([]);
  const [loading, setLoading] = useState(false)

  const model = useRef();
  let id = userData.id;
  const fetchMedia=async() =>{
    try {
      const { data } = await apiAuth.get(`api/v2/modelUnMedia/institute-commuinque/${id}`);
      if(data?.result){
        setMediapost(data.result);
      }
    } catch (error) {
      console.log(error?.message)
    }
  }
  useEffect(() => {
    if(id){
      fetchMedia();
    }
  }, []);

  async function handelDelete(id) {
    if (id) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You wanted to delete this Commuinque!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (res) => {
        if (res.isConfirmed) {
          try {
            const res = await apiAuth.delete(`api/v2/modelUnMedia/institute-commuinque/${id}`);
            if (res) {
              toast.success('Deleted Successfully..');
              fetchMedia();
            }
          } catch (error) {
            toast.error('Somting went Wrong!!');
          }
        }
      });
    }
  }

  const form = useFormik({
    initialValues: {
      title: '',
      link: '',
      desc: '',
      file: '',
    },
    onSubmit: async function (values, actions) {
      try {
     if(mediaPost?.length ===1){
      toast.dismiss()
      toast.error('You have reached your limit for adding a commuinque..');
      return;
     }
        if (values?.title && values?.desc && (values.link || values.file)) {
          setLoading(true)
          const res = await apiAuth.post(`api/v2/modelUnMedia/institute-commuinque/${id}`, values);
          if (res.status == 200) {
            actions.resetForm();
            setLoading(false)
            toast.dismiss()
            toast.success('Media Post Uploaded..');
            fetchMedia();
            model.current.click();
          } else {
            toast.dismiss()
            toast.error('All Fields are Required!');
          }
        }
        else if(values?.link && values?.file){
          toast.dismiss()
          toast.warning('Please provide a either Link or an Image')
        }
         else {
          toast.dismiss()
          toast.warning('Please fill Required fields.');
        }
      } catch (err) {
        toast('error', 'Something Went Worng..');
      }
    },
  });
  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <h2 className="fs-3 mx-2">Commuinque</h2>
        {mediaPost?.length !==1 && (
          <>
        <Button type="submit" className="rounded-3 py-0 py-lg-2 px-1 text-center text-capitalize fw-semibold d-none d-lg-block d-md-block" data-bs-toggle="modal" data-bs-target="#Commuinque"
         variant="solid" 
          sx={{
            background: "#9700DE",
            color: "white",
            '&:hover': {
              background: "#9700DE",
              color: "white",
            },
          }}
        >
          Add Commuinque
        </Button>
            <div className='d-block d-sm-none'><AddCircleOutlineTwoToneIcon className='fw-bold' data-bs-toggle="modal" data-bs-target="#Commuinque"
              sx={{ fontSize: '40px', color: "#7e27ec" }} /></div>
        </>
        )}
      </div>
      <div className="modal fade" id="Commuinque" tabIndex="-1" aria-labelledby="Commuinque" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <form
              onSubmit={form.handleSubmit}
              onSubmitCapture={(e) => {
                e.target.img.value = null;
              }}>
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="Commuinque">
                  Upload Commuinque
                </h1>
                <button type="button" ref={model} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <TextField
                    className="my-2"
                    name="title"
                    label="Document Name"
                    value={form.values.title}
                    onChange={form.handleChange}
                    required
                    fullWidth
                  />
                  <TextField
                    multiline
                    minRows={1}
                    className="my-2"
                    name="desc"
                    label="Document Details"
                    value={form.values.desc}
                    onChange={form.handleChange}
                    required
                    fullWidth
                  />
                  <TextField className="my-2" name="link" label="Link" value={form.values.link} onChange={form.handleChange} fullWidth />
                  <p className="mx-5 my-0 lh-1 p-0">OR</p>
                  <Button className="my-2 w-100" variant="outlined">
                    <input
                      id="img"
                      onClick={(e) => (e.target.value = null)}
                      className="my-2 w-100"
                      type="file"
                      name="file"
                      onChange={(e) => {
                        if (e.target.files[0]?.size < 1000000) {
                          form.setFieldValue('file', e.target.files[0]);
                        } else {
                          toast.warning('File size too Large. \n Max Size 1MB.');
                          e.target.value = null;
                        }
                      }}
                      label="Image"
                    />
                  </Button>
                </div>
              </div>
              <div className="modal-footer">
              <Button type="button" className="m-2 text-dark"  data-bs-dismiss="modal">
                  Discard
                </Button>
                <Button className="m-2" type="submit" data-bs-dismiss="modal" 
               variant="solid" 
               sx={{background: "#9700DE",
                 color: "white",
                 '&:hover': {
                background: "#9700DE", 
                 color: "white", 
                    },
                 }}
                 disabled={loading}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 flex-wrap  justify-content-start">
          {mediaPost?.length ? (
            mediaPost?.map((media) => {
              return (
                <div className="col-12 col-md-6 col-lg-3" key={media?.id}>
                  <div className="card  position-relative overflow-hidden">
                    <div
                      className="position-absolute top-0 end-0 shadow-lg "
                      onClick={() => {
                        handelDelete(media?.id);
                      }}>
                      <DeleteForever className='cursor-pointer' sx={{ color: 'red', fontSize: '50' }} />
                    </div>
                    <div className="my-4">
                      {/* <a target="_blank" href={media?.link || media?.img}> */}
                        <img
                          src={media?.img || "https://glcloud.in/images/static/media/document.jpeg"}
                          alt="Image"
                          className=" p-3 w-100 d-block rounded"
                          style={{ maxHeight: '150px', objectFit: 'contain' }}
                        />
                      {/* </a> */}
                    </div>
                    <div className="container rounded text-center position-relative p-2">
                      <p className="fs-5 p-1 lh-1 mb-0">{media?.title}</p>
                      <small className="lh-1 pb-1 text-truncate line-clamp text-dark">{media?.desc}</small>
                      {media?.link ? (
                        <Button variant="outlined" href={media?.link} target="_blank" className="mt-2" style={{border: "1px solid #c4c4c4e6", color: "#0064ff"}}>
                          <Link /> Communique Link
                        </Button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="container text-center item-center mx-auto">
              <NotFoundGif text={'Documents Not Added Yet!!'} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(CommuinqueUpload);
