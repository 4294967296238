import React, { useEffect, useRef, useState } from 'react';

import '../../ChatRoom.css';
import useChat from '../../useChat';
import { useGlobalContext } from 'global/context';
import { apiJsonAuth } from 'api';
import { useParams } from 'react-router-dom';
import StudentDeclarationItem from '../StudentDeclarationItem';

const ChatRoomDeclaration = ({ showVote, studentData, meetingData }) => {
  const { userData, token } = useGlobalContext();
  const chatDiv = useRef();
  const params = useParams();
  const roomId = params?.meetingid;
  const { reload, vote } = useChat(roomId);
  const [votesArr, setVotesArr] = useState([]);
  const [DeclarationPointsData, setDeclarationPointsData] = useState([]);

  const fetchVotes = async () => {
    const response = await apiJsonAuth.get('/discussion/points/vote', {
      headers: {
        Authorization: token,
      },
    });
    // console.log("VOTES--=->", response?.data?.result);
    if (response.status === 200) {
      setVotesArr(response?.data?.result);
    }
  };

  // console.log("StudentData", studentData?.instituteId,params?.meetingid)

  const fetchLeaderBoard = async () => {
    if (params?.meetingid && studentData?.instituteId) {
      try {
        const response = await apiJsonAuth.post(
          `/discussion/declaration/leaderboard`,
          {
            roomId: params?.meetingid,
            institutueId: studentData?.instituteId,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (response.status === 200) {
          // console.log(response?.data?.result)
          setDeclarationPointsData(response?.data?.result);
        }
      } catch (error) {}
    }
  };
  useEffect(() => {
    fetchVotes();
    fetchLeaderBoard();
  }, [params, token, params?.meetingid, studentData, reload, vote]);

  return (
    <div className="chat-room-container">
      <div className="messages-container" ref={chatDiv}>
        <ol className="messages-list">
          {DeclarationPointsData?.map((point, i) => (
            <StudentDeclarationItem
              key={i}
              reloadVote={fetchVotes}
              meetingData={meetingData}
              userData={userData}
              votesArr={votesArr}
              studentData={studentData}
              message={point}
              i={i}
              showVote={showVote}
            />
          ))}
        </ol>
      </div>
    </div>
  );
};

export default ChatRoomDeclaration;
