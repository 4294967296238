import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { apiJson } from "api";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from "react-toastify";

const AffiliateInstitute = () => {
  const [sarasData, setSarasData] = useState({
    schoolName: "GOVT SR SEC SCHOOL",
    affliateNumber: 2000019,
    state: "Andaman & Nicobar",
    district: "Nicobar",
    status: "Senior Secondar Level",
    address: "sawai car-nocobar a & n islands",
    phone: 456789159,
    email: "@cbseshiksha.in",
  });

  const validation = Yup.object({
    affiliate_name:Yup.string().min(2).max(25).required("please enter affiliate name"),
    number:Yup.string().min(10, 'Phone number must be at least 10 digits').max(15, 'Phone number must be at most 15 digits').required("please enter your phone number"),

    email:Yup.string().email().required("please enter affiliate email"),
    affiliate_designation:Yup.string().required("please enter affiliate_designation")
 
  })

  const formik = useFormik({
    initialValues:{
        affiliate_name: "",
        number: "",
        email: "",
        affiliate_designation: ""
    },
    validationSchema:validation,
    onSubmit: async (values, {resetForm}) => {
        let payloadData = {
            name:values.affiliate_name,
            phone_number:values.number,
            email:values.email,
            designation:values.affiliate_designation,
            affiliate_number:sarasData?.affliateNumber
        }
        // console.log(payloadData)
        try {
            const data = await apiJson.post(
              `/api/v2/affiliateInRoute/affiliate`, payloadData
            )
            .then((res)=>{
                console.log(res.data.message)
                toast.success(res.data.message)
            })

          } catch (err) {
            console.log(err.message);
          }

        resetForm()
    },
  });

//   const postAffiliateData = async () => {

//   };

  return (
    <>
      <div className="container">
        <div className="d-flex flex-column m-auto" style={{ width: "60%" }}>
          <form
            initialValues={formik.initialValues}
            className="row g-3 mt-4 pb-3 px-1 rounded-3 shadow"
            onSubmit={formik.handleSubmit}
            resetForm
          >
            <div className="row mt-4 text-capitalized">
              <h2 className="col-12 text-center"> {sarasData.schoolName} </h2>
              <p className="col-6">

                <span className="fw-semibold text-capitialize">
                  Affiliate NO.
                </span>
                : <span>{sarasData.affliateNumber}</span>{" "}
              </p>

              <p className="col-6">

                <span className="fw-semibold text-capitialize">
                  State
                </span> : <span>{sarasData.state}</span>
              </p>

              <p className="col-6">
                <span className="fw-semibold text-capitialize">
                  District
                </span> : <span>{sarasData.district}</span>{" "}
              </p>

              <p className="col-6">
                
                <span className="fw-semibold text-capitialize">
                  Status
                </span> : <span>{sarasData.status}</span>{" "}
              </p>

              <p className="col-6">
                
                <span className="fw-semibold text-capitialize">
                  Address
                </span> : <span>{sarasData.address}</span>{" "}
              </p>

              <p className="col-6">
                
                <span className="fw-semibold text-capitialize">
                  Phone
                </span> : <span>{sarasData.phone}</span>{" "}
              </p>

              <p className="col-6 d-flex">
                <span className="fw-semibold text-capitialize">
                  Email
                </span> : <span className="d-flex"> <input type="number" name="number" style={{width:"100px"}}  /> {sarasData?.email}</span>
              </p>
            </div>

            <hr />
            <h3 className="text-center fs-semibold fs-3">
              Add Teacher Cordinator
            </h3>
            <div className="col-md-6">
              <TextField
                id="outlined-basic"
                label="Affiliate_Name"
                variant="outlined"
                name="affiliate_name"
                sx={{ width: "100%" }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.affiliate_name}
              />
              <p className="text-danger">{formik.errors.affiliate_name}</p>
            </div>
            <div className="col-md-6">
              <TextField
                id="outlined-basic"
                type="number"
                name="number"
                label="Affiliate_Phone no."
                variant="outlined"
                sx={{ width: "100%" }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.number}
              />
              <p className="text-danger">{formik.errors.number}</p>
            </div>
            <div className="col-12">
              <TextField
                id="outlined-basic"
                type="email"
                name="email"
                label="Affiliate_Email"
                variant="outlined"
                sx={{ width: "100%" }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <p className="text-danger">{formik.errors.email}</p>
            </div>
            <div className="col-md-12">
              <TextField
                id="outlined-basic"
                label="Affiliate_designation"
                variant="outlined"
                name="affiliate_designation"
                sx={{ width: "100%" }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.affiliate_designation}
              />
              <p className="text-danger">{formik.errors.affiliate_designation}</p>
            </div>
            <div className="col-12 d-flex justify-content-end">
              <button
                type="submit"
                className=" fw-semibold btn btn-primary rounded-3"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AffiliateInstitute;
