import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Container,
    Typography,
    Card,
    CardContent,
    Grid,
} from '@mui/material';
import { useSubAdminCSV } from 'contexts/SubAdminCSVContext';
import { Popup } from 'layout/Popup';
import YuvaLoader from 'pages/Forum/components/Loader/YuvaLoader';

const SubAdminAssignedInstitutes = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { setInstituteId, fetchInstitutes, institutes } = useSubAdminCSV();
    const location = useLocation();
    const userRole = location.pathname.includes('admin') ? 'admin' :
        location.pathname.includes('subAdmin') ? 'subAdmin' : null;
    useEffect(() => {
        const loadInstitutes = async () => {
            try {
                setLoading(true);
                await fetchInstitutes(userRole);
            } catch (e) {
                Popup('error', 'Server Error', 'An error occurred while fetching institutes', 3000);
            } finally {
                setLoading(false);
            }
        };

        loadInstitutes();
    }, []);

    return (
        <Container sx={{ padding: '5%' }}>
            {loading && <YuvaLoader setShow={false} show={loading} />}
            <Typography variant="h4" align="center" sx={{ alignSelf: 'flex-start', marginBottom: '20px', color: '#2356FE' }}>
                Assigned Institutes for CSV Data Management
            </Typography>
            <Container sx={{
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {institutes.length === 0 ? (
                    <Typography
                        variant="h5"
                        align="center"
                        sx={{ color: '#A97BC5' }}
                    >
                        No tasks have been assigned yet.
                    </Typography>
                ) : (
                    <Grid container spacing={3}>
                        {institutes.map((institute, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card
                                    onClick={() => {
                                        setInstituteId(institute.id);
                                        navigate(`/${userRole}/handle-institute-csv/${institute.id}`);
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        transition: 'transform 0.2s',
                                        backgroundColor: 'white',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                        borderRadius: '10px',
                                        padding: '20px',
                                        textAlign: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        height: '100%',
                                    }}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                                >
                                    <CardContent sx={{ flex: 1 }}>
                                        <Typography variant="h6" component="h2" style={{ color: '#2356FE' }}>
                                            {institute?.institution_name}
                                        </Typography>
                                        <Typography variant="body1" component="h3" style={{ color: '#2356FE' }}>
                                            {institute?.email}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Click to manage CSV data
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                )}
            </Container>
        </Container>
    );
};

export default SubAdminAssignedInstitutes;
