import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import AddCareerGuide from './AddCareerGuide';
import EditCareerGuide from './EditCareerGuide';
import { useGlobalContext } from 'global/context';

const ManageCareerGuide = () => {
    const { userData } = useGlobalContext();

    const [mode, setMode] = useState('add');

    return (
        <Box sx={{ padding: '2rem' }}>
            <Typography variant="h4" sx={{ marginBottom: '2rem' }}>Manage Career Guide</Typography>

            <Box sx={{ display: 'flex', gap: '1rem', marginBottom: '2rem' }}>
                <Button
                    variant={mode === 'add' ? 'contained' : 'outlined'}
                    onClick={() => setMode('add')}
                >
                    Add Career Guide
                </Button>
                <Button
                    variant={mode === 'edit' ? 'contained' : 'outlined'}
                    onClick={() => setMode('edit')}
                >
                    Edit Career Guide
                </Button>
            </Box>

            {mode === 'add' ? <AddCareerGuide userId={userData.id} /> : <EditCareerGuide userId={userData.id} />}
        </Box>
    );
};

export default ManageCareerGuide;
