import React from "react";
import { useState, useContext } from "react";
import { useGlobalContext } from "global/context";
import { useEffect } from "react";
import ClubActivity from "./ClubActivity";
import ClubProfileAbout from "./ClubProfileAbout";
import ClubAddPost from "./ClubAddPost";
import { ClubContext } from "../TimelineClub";
import ClubProfileTopDetail from "./ClubProfileTopDetail";
import ShowUserClubPosts from "./ShowUserClubPosts";
import ClubProfileGallery from "./ClubProfileGallery";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { ScrollableTabsButtonAuto } from '@mui/material/Tabs';
import "../style/clubStyle.css"
import { useMediaQuery, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { apiJson } from "api";

const ClubProfileLeft = ({ isInstitute, userId, existUser }) => {
  const { userData } = useGlobalContext();
  const role = isInstitute ? "institute" : "student";
  // const {id, role} = userData
  const location = useLocation()

  const { clubDetail, fetchClickUserDetail,
    clickuserDetail, fetchClickClubUserDetail,
    clickclubUserDetail } = useContext(ClubContext);

  const [screenWidth, setScreenWidth] = useState(document.documentElement.clientWidth);
  const [value, setValue] = useState(1);
  const [profileUser, setProfileUser] = useState([]);
  const [profileUserCurrent, setProfileUserCurrent] = useState({});

  const getProfileData = () => {
    apiJson(
      `api/v2/profile/userDetail/role/${isInstitute ? "institute" : "student"
      }/user/${userId}`
    )
      .then((res) => {
        setProfileUser(res?.data?.userDetail);
        console.log(res?.data?.userDetail);
        // console.log("res?.data?.userDetail", res?.data?.userDetail)
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getProfileDataCurrent = () => {
    apiJson(`api/v2/profile/userDetail/role/${userData.role}/user/${userData.id}`)
      .then((res) => {
        // console.log("res?.data?.userDetail", res?.data?.userDetail)
        setProfileUserCurrent(res?.data?.userDetail)

      })
      .catch((error) => {
        console.log(error.message);
      })
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (existUser) {
      fetchClickUserDetail(userId, role);
      fetchClickClubUserDetail(userId, isInstitute ? "institute" : "student");
    }
  }, [userId]);

  useEffect(() => {
    setValue(isInstitute ? 0 : 1);
    getProfileData()
    getProfileDataCurrent()
  }, []);
  useEffect(() => {
    setValue(isInstitute ? 0 : 1);
    getProfileData()
    getProfileDataCurrent()
  }, [location.pathname]);

  const handleResize = () => {
    setScreenWidth(document.documentElement.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  return (
    <>

      <div className="container ">
        <div className="col-12">
              <ClubProfileTopDetail clickuserDetail={clickuserDetail} clickclubUserDetail={clickclubUserDetail} />

          <div className="scroll-tab-section ">
            <div className='d-flex align-items-center shadow rounded-2 hover-shadow-for-componen mt-4' style={{ borderRadius: "16px", height: "58px", width: screenWidth < 500 ? `${screenWidth - 50}px` : "" }}>

              <div className={`mb-3 col-11 ${isMobile ? 'mobile-tabs' : ''}`}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#914EFF",
                      height: 4,
                      width: 95,
                      marginLeft: "2px"
                    }
                  }}
                  variant={isMobile ? 'scrollable' : 'standard'}
                  scrollButtons={isMobile ? 'auto' : 'off'}
                >
                  {['institute','teacher', 'student'].includes(profileUser?.role) && 
                  <Tab className="text-capitalize" label="Profile" sx={{ fontSize: "14px", fontWeight: "600" }} />}
                  <Tab className="text-capitalize" label="Feeds" sx={{ fontSize: "14px", fontWeight: "600" }} />
                  <Tab className="text-capitalize" label="Photos" sx={{ fontSize: "14px", fontWeight: "600" }} />
                  <Tab className="text-capitalize" label="Activity" sx={{ fontSize: "14px", fontWeight: "600" }} />
                  {/* Add more tabs as needed */}
                </Tabs>
              </div>

            </div>
          </div>

          {['institute','teacher', 'student'].includes(profileUser?.role) && value === 0 && <ClubProfileAbout value={1} profileUser={profileUser} profileUserCurrent={profileUserCurrent} />}
          {((['teacher', 'student'].includes(profileUser?.role) && value === 1) || (isInstitute && value === 1)) && (
            <div>
              <div className="mb-4">{userId == userData?.id && <ClubAddPost userDetail={profileUserCurrent} clubDetail={clubDetail} />}</div>
              <div className="d-flex justify-content-center">
              <div className="col-12 col-md-9">
                <ShowUserClubPosts userDetail={profileUser} />
              </div>
            </div>
            </div>
          )}

          {((['teacher', 'student'].includes(profileUser?.role) && value === 2) || (isInstitute && value === 2)) && (
            <div>
              <ClubProfileGallery clickuserDetail={profileUser} />
            </div>
          )}
          {((['teacher', 'student'].includes(profileUser?.role) && value === 3) || (isInstitute && value === 3)) && <ClubActivity />}
        </div>
      </div>
    </>
  );
};

export default ClubProfileLeft;
