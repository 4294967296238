import React, { useState } from 'react'

export const EnviromentLogo = () => {
    const [itemsToShow, setItemsToShow] = useState("defaultItemsToShow");
    const [currentPage, setCurrentPage] = useState(1);
    const [showEndorse, setShowEndorse] = useState(false);
    const [currentItem, setCurrentItem] = useState({})
    const enviromentLogo = [
      {
        title: "Central Board of Secondary Education",
        enviroimg:"/logoscroll/cbsc2.png",
       
      },
      {
        title: "Kendriya Vidyalaya Sangathan",
        enviroimg:"/logoscroll/kvs.svg",
       
      },
      {
        title: "Jawahar Navodaya Vidyalaya",
        enviroimg:"/logoscroll/jnv2.png",
      
      },
      {
        enviroimg: './logoscroll/logo6.svg',
        title: "Eklavya Model Residential Schools"
      },
      {
        enviroimg: './logoscroll/logo10.svg',
        title: "Vidya Bharati"
      },
      {
        enviroimg: './enviroment_logo/apngc.png',
        title: "Andhra Pradesh National Green Corps"
      },
      {
        enviroimg: './enviroment_logo/astec.png',
        title: "Assam Science Technology and Environment Council"
      },
      {
        enviroimg: './enviroment_logo/goa.png',
        title: "Goa State Council for Science and Technology"
      },
      {
        enviroimg: './enviroment_logo/gujarat.png',
        title: "Gujarat Ecological Education and Research Foundation"
      },
      {
        enviroimg: './enviroment_logo/kerala.png',
        title: "Kerala State Council for Science, Technology and Environment"
      },
      {
        enviroimg: './enviroment_logo/madhyapradesh.png',
        title: "Environmental Planning and Coordination Organization, Madhya Pradesh"
      },
      {
        enviroimg: './enviroment_logo/mizoram.png',
        title: "Mizoram Pollution Control Board"
      },
      {
        enviroimg: './enviroment_logo/karnatka.png',
        title: "Environmental Management & Policy Research Institute Karnataka"
      },
      {
        enviroimg: './enviroment_logo/nagaland.png',
        title: "Nagaland Pollution Control Board"
      },
      {
        enviroimg: './enviroment_logo/punjab.png',
        title: "Punjab State Council for Science and Technology"
      },
      {
        enviroimg: './enviroment_logo/rajashthan.png',
        title: "Rajasthan State Bharat Scout and Guide"
      },
      {
        enviroimg: './enviroment_logo/sikkim.png',
        title: "Forest and Environment Department Government of Sikkim"
      },
      {
        enviroimg: './enviroment_logo/himachal.png',
        title: "Himachal Pradesh Council for Science, Technology and Environment"
      },
      {
        enviroimg: './enviroment_logo/telengana.png',
        title: "Telengana National Green Corps"
      },
      {
        enviroimg: './enviroment_logo/tripura.png',
        title: "Tripura State Pollution Control Board"
      },
      {
        enviroimg: './enviroment_logo/tamilnadu.png',
        title: "Environment Management Agency of Tamil Nadu"
      }
    ];
  return (
    <div>
        <div className="container satya position-relative overflow-hidden">
        {/* <img
          src={satyaimg}
          alt="logo"
          id="satyaimg"
          className="position-absolute"
        />
        <img
          src={unaUnivlogo}
          alt="logo"
          id="unaUni"
          className="position-absolute"
        /> */}

        {/* <h2 className="text-center fw-bolder mb-5 fs-1">
        Endorsed by diverse government ministries, departments and universities.
        </h2> */}
        <div className="univercityIcons">
        <div className="row text-center">
          {enviromentLogo.map((item, index) => (
            <div
              key={index}
            //   onClick={() => {
            //     setShowEndorse(item?.showModal);
            //     setCurrentItem(item);
            //   }}
              className={`col-lg-2 col-3 text-center   rounded-3 px-1`}
            >
              <div className="cursor-pointer">
                <img className="" src={item?.enviroimg} alt="" style={{ height: "80px",width: "80px",objectFit: "contain"}}/>
              </div>
              <p className="cursor-pointer text-capitalize">{item.title}</p>
            </div>
          ))}
        </div>
      </div>
      </div>
    </div>
  )
}
