import { apiJson } from "api";
import { useFormik } from "formik";
import { useGlobalContext } from "global/context";
import React, { createContext, useContext, useEffect, useState} from "react";
import { toast } from "react-toastify";

export const CFContext = createContext();

export const useMyContext = () =>{
    return useContext(CFContext)
}

export const MyCarbonContextProvider = ({children}) =>{
    // data.js
//  const categories = [
//     { id: 1, title: "Category 1" },
//     { id: 2, title: "Category 2" },
//   ];
  
//    const questions = [
//     { text: "Question 1", cat_id: 1, options: ["Option 1", "Option 2", "Option 3"] },
//     { text: "Question 2", cat_id: 1, options: ["Option 1", "Option 2", "Option 3"] },
//   ];
    const {userData} = useGlobalContext()
    const [countStep, setCountStep] = useState(1);
    const [currentCategory, setCurrentCategory] = useState([]);
    const [questions, setQuestions] = useState([])
    const [categories, setCategories] = useState([])
    const [categoryQuestions, setCategoryQuestion] = useState([])
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);


    const answerFormik= useFormik({
        initialValues:{
            userId:userData?.id,
            role:userData?.role,
            type:'',
            questionId:'',
            answer:{},
            carbonEmissionTrans:'',
            carbonEmissionCook:'',
            carbonEmissionEnergy:'',
            carbonEmissionHeat:'',
            carbonEmissionCool:'',
            carbonEmissionDiet:'',
        }
    })
    const setAnswerData = (name, value)=>{
        answerFormik.setFieldValue(name, value)
    }

    const handleResetForm = ()=>{
        answerFormik.resetForm();
    }

    const getQuestions = ()=>{
        apiJson('api/v2/cfc/cfcGetQuestion')
        .then((res)=>{
            setQuestions(res.data.questions)
        }).catch((error)=>{
            toast.dismiss()
            toast.error('Internal Server Error')
        })
    }

    const getCategory = ()=>{
        apiJson('api/v2/cfc/cfcCategory')
        .then((res)=>{
            setCategories(res.data.categories)
        }).catch((error)=>{
            toast.dismiss()
            toast.error('Internal Server Error')
        })
    }
    useEffect(()=>{
        getQuestions()
        getCategory()
    },[])

    useEffect(()=>{
        if(questions?.length>0 && currentCategory){
            setCategoryQuestion( questions.filter(q => q.cat_id === currentCategory.id))
        }
    },[questions,currentCategory])

    useEffect(()=>{
        if(categories?.length>0){
            setCurrentCategory(categories[0])
        }
    },[categories])

    const handleNext = () => {
        if (currentQuestionIndex < categoryQuestions.length - 1) {
          setCurrentQuestionIndex(prevIndex => prevIndex + 1);
        } else { 
          const nextCategoryIndex = (categories.findIndex(c => c.id === currentCategory.id) + 1) % categories.length;
          setCurrentCategory(categories[nextCategoryIndex]);
          setCurrentQuestionIndex(0);
        }
      };
      
      const handlePrev = () => {
        if (currentQuestionIndex > 0) {
          setCurrentQuestionIndex(prevIndex => prevIndex - 1);
          console.log('new console.log', currentQuestionIndex)

        } else {
            console.log('new console.log', currentQuestionIndex)
            console.log('category', currentCategory)
          const prevCategoryIndex =
            (categories.findIndex(c => c.id === currentCategory) - 1 + categories.length) % categories.length;
          setCurrentCategory(categories[prevCategoryIndex].id);
          setCurrentQuestionIndex(categoryQuestions.length - 1);
        }
      };

    const values = {
        answerFormik,
        setAnswerData,
        countStep,
        setCountStep,
        currentCategory,setCurrentCategory,
        questions,
        // setQuestions,
        categoryQuestions,setCategoryQuestion,
        currentQuestionIndex,setCurrentQuestionIndex,
        questions,
        categories,
        handleNext,
        handlePrev,
        handleResetForm
    }
    return (
        <CFContext.Provider value={values}>
            {children}
        </CFContext.Provider>
    )
}