// import { Box, Button, Snackbar, Typography } from "@mui/material";
// import React, { useState } from "react";
// import Seva_Logo from "../../assets/Seva/Seva_logo.png";
// import axios from "axios";
// import MuiAlert from '@mui/material/Alert';


// const StudentDOC = () => {
//     const [files, setFiles] = useState([]);
//     const [fileNames, setFileNames] = useState([]);
//     const [uploadError, setUploadError] = useState("");
//     const [uploadSuccess, setUploadSuccess] = useState(false);

//     const [openSnackbar, setOpenSnackbar] = useState(false);
//     const [snackbarMessage, setSnackbarMessage] = useState("");
//     const [snackbarSeverity, setSnackbarSeverity] = useState("success");

//     const projectId = "66c722a8e7a3f631e137c202";

//     const handleFiles = (event) => {
//         const selectedFiles = Array.from(event.target.files);
//         setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
//         setFileNames((prevNames) => [...prevNames, ...selectedFiles.map(file => file.name)]);
//         setUploadError("");
//     };

//     const handleUploadClick = async () => {
//         if (files.length === 0) {
//             setUploadError("Please select files to upload.");
//             return;
//         }

//         const formData = new FormData();
//         Array.from(files).forEach((file) => {
//             formData.append('files', file);
//         });

//         try {
//             const uploadResponse = await axios.post(`http://localhost:3000/api/document/upload/${projectId}`, formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                 },
//             });

//             if (uploadResponse.status === 200) {
//                 setUploadSuccess(true);
//                 setFileNames([]);
//                 setFiles([]);
//                 setOpenSnackbar(true);
//                 setSnackbarMessage("Files uploaded successfully!");
//                 setSnackbarSeverity("success");
//             } else {
//                 setUploadError("File upload failed.");
//             }
//         } catch (err) {
//             console.error("Error during API call:", err);
//             setUploadError("Error during file upload.");
//             setOpenSnackbar(true);
//             setSnackbarMessage("File upload failed. Please try again.");
//             setSnackbarSeverity("error");
//         }
//     };

//     const handleCloseSnackbar = () => {
//         setOpenSnackbar(false);
//         setUploadSuccess(false);
//     };

//     return (
//         <Box
//             sx={{
//                 padding: "2rem",
//                 boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
//                 borderRadius: "1rem",
//                 backgroundColor: "var(--color-ghostwhite)",
//                 border: "5px solid #2356fe",
//                 boxSizing: "border-box",
//                 width: "auto",
//             }}
//         >
//             <Box
//                 sx={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     alignItems: "center",
//                     "@media(max-width:900px)": {
//                         flexDirection: "column-reverse",
//                     },
//                 }}
//             >
//                 <Box>
//                     <Typography
//                         component={"h1"}
//                         sx={{
//                             fontSize: "1.6rem",
//                             fontWeight: 900,
//                             color: "#2356fe",
//                             padding: "1rem 0rem",
//                         }}
//                     >
//                         Upload Student Documents
//                     </Typography>
//                 </Box>

//                 <Box
//                     sx={{
//                         display: "flex",
//                         flexDirection: "column",
//                         gap: 1,
//                         alignItems: "center",
//                     }}
//                 >
//                     <Box>
//                         <Typography
//                             component={"img"}
//                             src={Seva_Logo}
//                             alt="Seva Logo"
//                             sx={{ width: "150px" }}
//                         />
//                     </Box>
//                 </Box>
//             </Box>

//             <Box
//                 sx={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     gap: 2,
//                     "@media(max-width:900px)": {
//                         flexDirection: "column",
//                     },
//                 }}
//             >
//                 <Box
//                     sx={{
//                         width: "100%",
//                         padding: "0.8rem",
//                     }}
//                 >
//                     <Box
//                         sx={{
//                             position: "relative",
//                         }}
//                         id="drop-area"
//                     >
//                         <input
//                             type="file"
//                             id="fileElem"
//                             accept=".pdf, .doc, .docx, .jpg, .jpeg"
//                             onChange={handleFiles}
//                             multiple
//                             style={{ display: "none" }}
//                         />
//                         <label
//                             htmlFor="fileElem"
//                             style={{
//                                 padding: "10px",
//                                 cursor: "pointer",
//                                 borderRadius: "6px",
//                                 minWidth: "100%",
//                                 maxWidth: "100%",
//                                 boxSizing: "border-box",
//                                 border: "2px dashed black",
//                                 height: "150px",
//                                 display: "flex",
//                                 alignItems: "center",
//                                 justifyContent: "center",
//                                 backgroundColor: "#f6f6f6",
//                                 textAlign: "center",
//                             }}
//                         >
//                             Drag File here or click to upload
//                         </label>

//                         <Typography
//                             component={"p"}
//                             sx={{
//                                 fontSize: "0.85rem",
//                                 padding: "0.2rem",
//                                 textAlign: "center",
//                             }}
//                         >
//                             Drag File Here Or Click To{" "}
//                             <span style={{ fontWeight: 600 }}>Select File To Upload</span>{" "}
//                             Documents
//                         </Typography>

//                         {fileNames.length > 0 && (
//                             <Typography
//                                 component={"p"}
//                                 sx={{
//                                     textAlign: "center",
//                                     color: "#666",
//                                     padding: 0,
//                                 }}
//                             >
//                                 Selected files: {fileNames.join(', ')}
//                             </Typography>
//                         )}

//                         {uploadError && (
//                             <Typography
//                                 component={"p"}
//                                 sx={{
//                                     color: "red",
//                                     fontSize: "0.85rem",
//                                     padding: "0.2rem",
//                                     textAlign: "center",
//                                 }}
//                             >
//                                 {uploadError}
//                             </Typography>
//                         )}

//                         {uploadSuccess && (
//                             <Typography
//                                 component={"p"}
//                                 sx={{
//                                     color: "green",
//                                     fontSize: "0.85rem",
//                                     padding: "0.2rem",
//                                     textAlign: "center",
//                                 }}
//                             >
//                                 Files uploaded successfully!
//                             </Typography>
//                         )}
//                     </Box>

//                     <Box
//                         sx={{
//                             display: "flex",
//                             justifyContent: "flex-end",
//                             paddingTop: "0.8rem",
//                             "@media(max-width:900px)": {
//                                 justifyContent: "center",
//                             },
//                         }}
//                     >
//                         <Button
//                             variant="contained"
//                             onClick={handleUploadClick}
//                             disabled={files.length === 0}
//                             sx={{
//                                 padding: "0.5rem 1.5rem",
//                                 backgroundColor: "#2356fe",
//                                 color: "white",
//                                 fontWeight: 700,
//                                 textTransform: "none",
//                                 borderRadius: "0.8rem",
//                                 fontSize: "1.2rem",
//                                 ":hover": {
//                                     backgroundColor: "#0038ff",
//                                 },
//                                 ":active": {
//                                     backgroundColor: "#2356fe",
//                                 },
//                                 ":disabled": {
//                                     backgroundColor: "#2356fe",
//                                     color: "rgba(255, 255, 255, 0.7)",
//                                     opacity: 0.5,
//                                     cursor: "not-allowed",
//                                 },
//                             }}
//                         >
//                             Upload
//                         </Button>

//                         <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
//                             <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
//                                 {snackbarMessage}
//                             </MuiAlert>
//                         </Snackbar>
//                     </Box>
//                 </Box>
//             </Box>
//         </Box>
//     );
// };

// export default StudentDOC;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Document, Page } from 'react-pdf';
// import { Box, CircularProgress, Typography, Button } from '@mui/material';
// import DownloadIcon from '@mui/icons-material/Download';
// import { handleDownload } from '../SewaPage/handleDownload';

// const EditPolicy = ({ setShowLinkUpload, formType = 'sewapolicytemplate' }) => {
//   const [pdfUrl, setPdfUrl] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);

//   const projectId = '66d1adda011e402922191020'; 

//   useEffect(() => {
//     const fetchPdf = async () => {
//       try {
//         const response = await axios.get(`http://localhost:3000/api/document/generate-pdf/${projectId}`, {
//           params: { formType },
//           responseType: 'arraybuffer',
//         });

//         const blob = new Blob([response.data], { type: 'application/pdf' });
//         const pdfUrl = URL.createObjectURL(blob);
//         setPdfUrl(pdfUrl);
//         setLoading(false);
//       } catch (err) {
//         console.error('Error fetching PDF:', err);
//         setError('Failed to load PDF');
//         setLoading(false);
//       }
//     };

//     fetchPdf();
//   }, [formType]);

//   const handleDownloadClick = () => {
//     handleDownload(formType); 
//     setShowLinkUpload(true); 
//   };

//   const handleLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };

//   return (
//     <Box 
//       display="flex" 
//       flexDirection="column" 
//       alignItems="center" 
//       justifyContent="center" 
//       sx={{ width: '100%', maxWidth: '900px',height:"100%", margin: '0 auto', padding: '1rem' }}
//     >
//       <Button
//         variant="contained"
//         startIcon={<DownloadIcon />}
//         onClick={handleDownloadClick}
//         sx={{ 
//           backgroundColor: '#1068ec', 
//           color: '#fff', 
//           '&:hover': { backgroundColor: '#005bb5' }, 
//           marginBottom: '1rem',
//         }}
//       >
//         Download and Proceed
//       </Button>

//       <Box 
//         sx={{
//           width: '100%',
//           maxWidth: '600px',
//           height: '600px',
//           border: '1px solid #ddd',
//           borderRadius: '0.5rem',
//           overflow: 'hidden',
//           backgroundColor: '#fff',
//         }}
//       >
//         {loading ? (
//           <CircularProgress />
//         ) : error ? (
//           <Typography color="error">{error}</Typography>
//         ) : (
//           pdfUrl && (
//             <Document
//               file={pdfUrl}
//               onLoadSuccess={handleLoadSuccess}
//               onLoadError={(error) => console.error('Error loading document', error)}
              
//             >
//               <Page pageNumber={pageNumber} />
//             </Document>
//           )
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default EditPolicy;



import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import LinkUpload from "./LinkUpload";
import { handleEditandDownload } from "../SewaPage/handleEditandDownload";

const EditPolicy = ({ setStartingView }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [docContent, setDocContent] = useState(''); 
  const [isSaved, setIsSaved] = useState(false); 
  const [showLinkUpload, setShowLinkUpload] = useState(false);

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const handleDownloadPdf = () => {
    const formType = 'sewapolicytemplate';
    const textContent = stripHtmlTags(docContent); 
    handleEditandDownload(formType, textContent); 
    setShowLinkUpload(true);
    setTimeout(()=> {
      setStartingView(true);
    },1000);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    
    
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    setIsSaved(true); 
  };

  const handleContent = (content) => {
    setDocContent(content);
    setIsSaved(false); 
  };

  return (
    <Box sx={pageStyle}>
      {showLinkUpload ? (
        <LinkUpload setStartingView={setStartingView} />
      ) : (
        <Box sx={contentStyle}>
          <Box sx={headerStyle}>
            <Typography sx={titleStyle}>Write your SEWA Policy... </Typography>
            <Box sx={buttonsWrapperStyle}>
              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                sx={{ ...buttonStyle, backgroundColor: isEditing ? "#4CAF50" : "#2a48f5" }}
                onClick={isEditing ? handleSaveClick : handleEditClick}
              >
                {isEditing ? "Save" : "Edit"}
              </Button>

              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                sx={{ ...buttonStyle, backgroundColor: "#4CAF50" }}
                onClick={handleDownloadPdf}
                disabled={!isSaved} 
              >
                Download PDF
              </Button>
            </Box>
          </Box>

          <Box sx={formBoxStyle}>
            <ReactQuill value={docContent} onChange={handleContent} theme="snow" readOnly={!isEditing} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const pageStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "90%",
  height: "100%",
  marginTop:"4rem",
  backgroundColor: "#f5f5f5",
  paddingBottom: "2rem",
  boxSizing: "border-box",
  "@media (max-width: 600px)": {
    padding: "1rem",
  },
};

const contentStyle = {
  width: "90%",
  marginBottom:"2rem",
  backgroundColor: "#ffffff",
  borderRadius: "1rem",
  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
  padding: "2rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  boxSizing: "border-box",
  position: "relative",
  overflowY: "auto",
  height:"100%",
  "@media (max-width: 600px)": {
    padding: "1rem",
    maxWidth: "100%",
    maxHeight: "calc(100vh - 5rem)",
  },
};

const headerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  marginBottom: "1.5rem",
  "@media (min-width: 600px)": {
    flexDirection: "row",
    justifyContent: "space-between",
  },
};

const titleStyle = {
  fontSize: "2rem",
  fontWeight: "600",
  marginBottom: "1rem",
  color: "#333",
  "@media (max-width: 600px)": {
    fontSize: "1.5rem",
  },
};

const buttonsWrapperStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  "@media (min-width: 600px)": {
    flexDirection: "row",
    gap: "1rem",
  },
};

const buttonStyle = {
  color: "#fff",
  padding: "0.75rem 1.25rem",
  borderRadius: "0.5rem",
  textTransform: "none",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  "&:hover": {
    backgroundColor: "#1068ec",
  },
  "@media (max-width: 600px)": {
    padding: "0.5rem 1rem",
  },
};

const formBoxStyle = {
  flex: 1,
  width: "90%",
  maxWidth: "1200px",
  height: "90vh",
  backgroundColor: "#f9f9f9",
  justifyItems:"center",
  borderRadius: "0.75rem",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  marginTop: "1.5rem",
  marginBottom: "1.5rem",
  overflowY: "auto",
  padding: "1.5rem",
  boxSizing: "border-box",
  "@media (max-width: 600px)": {
    maxWidth: "100%",
    height: "calc(100vh - 10rem)",
  }
};

export default EditPolicy;
