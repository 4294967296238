import React from 'react';
import "../Css/footer.css";
import yuva from "../assests/yuvamanthan top 4.svg";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { Link, useNavigate } from 'react-router-dom';

const Footer = () => {
    const date = new Date().getFullYear();

    const navigate = useNavigate()

    return (
        <>
            <div className="container footer">
                <div className="footerItems overflow-hidden">
                    <div className="d-flex justify-content-between flex-column flex-lg-row flex-md-row">
                        <div className='footerBox boxOne p-3'>
                            <img src={yuva} alt="yuva" className='d-md-inline-block d-block m-auto' />

                            <p className="text-white fw-light fs-6 text-md-justify">Engaging the youth in a global dialogue on international diplomacy, leadership and collective
                                duty. A platform to drive change by creating awareness on issues that matter and build
                                capacity to navigate the changing economic scenarios.</p>

                            <p className="text-white">Follow Us On</p>
                            <div className='icons d-flex gap-3 justify-content-between align-items-center'>
                                <a href="https://www.facebook.com/profile.php?id=100088850808774" target='_blank' className='socialLink'>
                                    <FacebookIcon sx={{ height: '43px', width: '43px', cursor: "pointer" }} />
                                </a>
                                <a href="https://twitter.com/Yuvamanthan_org" target='_blank'>
                                    <i class="fa-brands fa-square-x-twitter text-white cursor-pointer"></i>
                                </a>
                                <a href="https://www.linkedin.com/company/yuvamanthan-org/" target='_blank'>
                                    <i class="fa-brands fa-linkedin text-white cursor-pointer"></i>
                                </a>
                                <a href="https://www.instagram.com/yuvamanthan_org?igsh=MTQyZnZtYjgyNHI3eA%3D%3D" target='_blank' className='socialLink'>
                                    <InstagramIcon sx={{ height: '43px', width: '43px', cursor: "pointer" }} />
                                </a>
                                <a href="https://www.youtube.com/@yuvamanthan_org" target='_blank' className='socialLink'>
                                    <YouTubeIcon sx={{ height: '43px', width: '43px', cursor: "pointer" }} />
                                </a>

                            </div>
                        </div>
                        <div className='footerBox text-start text-white box pt-md-5 ps-3 ps-md-0'>
                            <p className='fw-bolder text-white'>Our Programmes</p>
                           
                            <ul>
                                <li><Link to="/nipam" className='footer-link'>Yuvamanthan NIPAM</Link></li>
                                <li><Link to="/ymun" className='footer-link'>Yuvamanthan Model United Nations</Link></li>
                                <li><Link to="/ymh" className='footer-link'>Yuvamanthan Hackathon</Link></li>
                                <li><Link to="/ymyp" className='footer-link'>Yuvamanthan Youth Parliament</Link></li>
                                <li><Link to="/model-g20" className='footer-link'>Yuvamanthan Model G20</Link></li>

                            </ul>
                        </div>
                        <div className='footerBox text-white box pt-md-5 ps-3 ps-md-0 text-start'>
                            <p className='fw-bolder text-white'>Our Themes</p>
                            <ul>
                                <li><a href='#' className='footer-link'>Vision 2047</a></li>
                                <li><Link to="/life" className='footer-link'>Lifestyle for Environment</Link></li>
                                {/* <li><a href='#' className='footer-link'>Other Themes</a></li> */}
                            </ul>
                        </div>
                        <div className='footerBox text-white box pt-md-5 ps-3 ps-md-0 text-start'>
                            <p className='fw-bolder text-white'>Engage With Us</p>
                            <ul>
                                {/* <li><a href='#' className='footer-link'>Press & Media</a></li> */}
                                {/* <li><a href='career' className='footer-link'>Careers</a></li> */}
                                <li><Link to="/youth-community" className='footer-link'>Youth Connect</Link></li>
                                <li><Link to="/media" className='footer-link'>YMG20 Media</Link></li>
                                <li><Link to="/blog" className='footer-link'>Blogs</Link></li>
                                <li><Link to="/news" className='footer-link'>News</Link></li>
                                <li><Link to="/contactus"  className='footer-link'>Contact</Link></li>

                            </ul>
                        </div>
                        <div className='footerBox text-white box pt-md-5 ps-3 ps-md-0 text-start'>
                            <p className='fw-bolder text-white'>About us</p>
                            <ul>
                                <li><Link to="/about" className='footer-link'>About Yuvamanthan</Link></li>
                                <li><Link to="/team" className='footer-link'>Our Team</Link></li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div className="lowerFooter mt-3 d-sm-flex flex-column flex-md-row align-items-center pt-3 d-none">
                    <p className="text-center text-md-start text-white w-50">
                        Copyright <CopyrightIcon /> {date}
                    </p>
                    <p className='text-center text-white text-sm-start'>All Rights Reserved. Registration on or use of this site constitutes acceptance of our <Link to={'#'} style={{
                        color: "white",
                        '&hover': {
                            color: 'darkblue',
                        }
                    }}> Terms and Conditions</Link> <Link to={'#'} style={{
                        color: "white",
                        '&hover': {
                            color: 'darkblue',
                        }
                    }}> Privacy Policy</Link> <Link to={'#'} style={{
                        color: "white",
                        '&hover': {
                            color: 'darkblue',
                        }
                    }}> Accessibility Statement</Link></p>
                </div>
            </div>
        </>
    )
}

export default Footer