/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from "react";
import OnBoardContext from "pages/OnBoarding/context/onBoardContext";
import Select from 'react-select';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { apiJson } from "api";
import { useFormik } from "formik";
import { useGlobalContext } from "global/context";


// const college = [
//   {
//     value:'1',
//     name:'AMC Engineering College',
//     address:'AMC Campus , Banglore, karnataka 560606'
//   },
//   {
//     value:'2',
//     name:'AMC Boys College',
//     address:'AMC Campus , Banglore, karnataka 560606'
//   },
//   {
//     value:'3',
//     name:'Indian Institute Of Technology',
//     address:'AMC Campus , Banglore, karnataka 560606'
//   },
//   {
//     value:'4',
//     name:'AMC Engineering College',
//     address:'AMC Campus , Banglore, karnataka 560606'
//   },
// ]



export const StudentStepConnect = () => {
  const {preUser, userData} = useGlobalContext()

  const {
    activeStep,
    setActiveStep,
    activeChildStep,
    setActiveChildStep,
    count,
    setCount,
    stepperArray,
    studentFormik,
    setStudenteData,
    studentOnBoardingData
  } = useContext(OnBoardContext);
  const [college, setcollege] = useState([])
  const [collegeCurrent, setcollegeCurrent] = useState()
  


  const initialValues = {
    institution_name:studentFormik.values.institution_name|| '',
    logo:  '',
    district:'',
    state:'',
    pincode:'',
   
}
const validation = Yup.object({
  institution_name: Yup.string(),
  // institution_name: Yup.string().required('Required'),
})

const onSubmit = () => {

    handleNextChild()
}

const formik = useFormik({
  initialValues,
  validationSchema: validation,
  onSubmit
})
  const getAllInstitute = ()=>{
    apiJson.get('v2/institute/getAllInstitute')
    .then((res)=>{
      setcollege(res.data.result)
    }).catch((error)=>{
      console.log(error.message)
    })
  }
  const getInstituteDetail = ()=>{
    apiJson.get(`api/v2/institute/fetchInstituteDetails/${preUser?.instituteId || Number(userData?.instituteId) || studentOnBoardingData?.institute_id}`)
    .then((res)=>{
      setcollegeCurrent(res.data.result)
    }).catch((error)=>{
      console.log(error.message)
    })
  }
  useEffect(()=>{
    if(!preUser?.instituteId){
      getAllInstitute()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const handleBack = () => {
    setActiveChildStep(activeChildStep - 1);
    if (activeChildStep <= 0) {
      setCount(count - 1);
      setActiveStep(activeStep - 1);
      setActiveChildStep(2);
    }
    if (activeStep < 0) {
      setActiveStep(0);
    }
    if (activeChildStep < 0) {
      setActiveChildStep(0);
    }
 
  };

  const handleNextChild = () => {
    setActiveChildStep(activeChildStep + 1);

    if (activeChildStep >= stepperArray[count]?.childStep?.length - 1) {
      setActiveStep(activeStep + 1);
      setCount(count + 1);
      setActiveChildStep(0);
      if (activeStep > stepperArray.length - 2) {
        setCount(0);
        setActiveStep(0);
        setActiveChildStep(0);
      }
    }
    // if(activeStep>=stepperArray?.length-1){
    //   console.log("active step ", activeStep,"stepperArraydddddddddddddd", stepperArray.length)
    //   setActiveStep(0)
    //   setCount(0)
    // }

  }
  useEffect(()=>{
    formik.setFieldValue("institution_name",studentFormik?.values?.institution_name || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    useEffect(()=>{
      // if(college?.length>0 && preUser?.instituteId){
        // let data = college?.find((i)=>i?.id===preUser?.instituteId )
        formik.setFieldValue('institution_name', collegeCurrent?.institution_name || studentOnBoardingData?.institution_name)
        formik.setFieldValue('logo', collegeCurrent?.logo)
        formik.setFieldValue('district', collegeCurrent?.district)  
        formik.setFieldValue('state', collegeCurrent?.state)
        formik.setFieldValue('pincode', collegeCurrent?.pincode)
        formik.setFieldValue('address', collegeCurrent?.institution_address)
        setStudenteData('institute_id', collegeCurrent?.id)
        setStudenteData('institution_name',collegeCurrent?.institution_name)

      // }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[collegeCurrent])
      useEffect(()=>{
        if(userData?.instituteId || preUser?.instituteId || studentOnBoardingData?.institute_id){
          getInstituteDetail()
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-2">
          <span className=" fs-35px fw-600">Connect Your Institute</span>
        </div>
        <div >
          <div className="mb-4">
            <span
              className="fs-19px fw-500 color-grey"
              
            >
            {/* Find your current insitute */}
            </span>
          </div>
        </div>
{ !preUser?.instituteId &&
        <div>
          <div className="mb-4">
            <span className="fs-19px fw-500">
              Search Your Institute
            </span>

          </div>
        </div>}

        
        <div className="mb-4">
       {college?.length>0 && !preUser?.instituteId &&(
            <Select
                styles={customStyles}
                options={college?.map(option => ({
                  ...option,
                  isDisabled: preUser?.instituteId?(option.id !== preUser?.instituteId):false
                }))}
                defaultValue = {college?.find((i)=>i?.institution_name===formik.values?.institution_name || i?.id===preUser?.instituteId )}
                onChange={(selected) => {
                  setStudenteData('institution_name', selected.institution_name)
                  setStudenteData('institute_id', selected.id)
                  formik.setFieldValue('institution_name', selected.institution_name)
                  formik.setFieldValue('logo', selected.logo)
                  formik.setFieldValue('district', selected.district)
                  formik.setFieldValue('state', selected.state)
                  formik.setFieldValue('pincode', selected.pincode)
                  formik.setFieldValue('address', selected.address)
                                }}
                getOptionLabel={
                  (option)=>{
                    return (
                      <>
                        <div className="mb-2 d-flex align-items-center z-index-1">
                          <div className="insitute-logo" style={{height:'50px', width:'50px'}}>
                            <img className="h-100 w-100" src={option.logo || './images/social-icons/institute-logo.avif'} />
                          </div>
                            <div className="ms-3">
                              <span className="fs-6 fw-semibold">{option.institution_name}</span>

                            </div>
                        </div>
                      </>
                    )
                  }
                }
             />)}
              {
                    formik.touched.institution_name && formik.errors.institution_name &&
                    <span className="text-danger">{formik.errors.institution_name}</span>
                  }
        </div>
   { formik?.values?.institution_name &&  
     <div className="d-flex flex-column p-4 rounded-4 shadow">
          <div className="d-flex mb-4">
            <div className="me-3 rounded-3" style={{height:'92px', width:'92px'}}>
                <img className="w-100 h-100 rounded-3" src={formik.values.logo ||"./images/onBoarding/university.png"} />
            </div>
            <div className="d-flex flex-column">
              <span className="fs-19px fw-500 mb-1"> {formik.values.institution_name}</span>
              <span className="color-grey fs-14px fw-500">{formik.values.address} </span>
            </div>
          </div>
          <div>
            <button className="background-purple fw-500 fs-15px color-purple-dark px-3 py-2   rounded-3" onClick={(e)=>e.preventDefault()}>Selected</button>
          </div>
        </div>}
        <div className="d-flex justify-content-between mt-4 z-index-1">
          <button onClick={handleBack} className="btn-onboard">
            Previous
          </button>

          <button type="submit" className="btn-onboard-fill">
            Next Step<span className='ms-4'><img src='./images/onBoarding/right-icon.png'/></span>
          </button>
        </div>
      </form> 
    </>
  )
}

const customStyles = {
  control : (provided) => ({
    ...provided,
    padding : '3px',
    border: 'none',
    background: '#eeeeee4d',
  })
}