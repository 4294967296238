import Select from "react-select";
import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import { useFormik } from "formik";
import { apiJson, apiJsonAuth } from "api";
import * as yup from 'yup';
import { useGlobalContext } from "global/context";
import { toast } from "react-toastify";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Dropdown } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button } from "@mui/material";
import StyledBadge from "components/StyledBadge";

export const AddExecutiveBoard = () => {
  const { userData } = useGlobalContext()
  const [openSearch, setOpenSearch] = useState(false)
  const [searchTitle, SetSearchTitle] = useState("");
  const [allUserList, setAllUserList] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)
  const [showModal, setShowModal] = useState({
    add: false,
    edit: false
  });
  const [isInputDisebled, setIsInputDisebled] = useState(false)
  const [selectedCommitee, setSelectedCommitee] = useState("")
  const [committeeList, setCommitteeList] = useState([])
  const [secretariatType, setSecretariatType] = useState("Executive Board")
  const [secretariat, setSecretariat] = useState([])
  const [allSec, setAllSec] = useState([])
  const [eventDetail, setEventDetail] = useState({})
  const [secretariatLimit, setSecretariatLimit] = useState(10)
  const [spinner, setSpinner] = useState(false);
  const [filterSecretariatData, setFilterSecretariat] = useState([]);
  const [condtionCheckEv, setCondtionCheckEv] = useState("");
  const [editEvId, seteditEvId] = useState(null);
  const [userDesignation, setUserDesignation] = useState("Chairperson")
  const [isMobile, setIsMobile] = useState(false);
  const [cardWidth, setCardWidth] = useState()

  useEffect(() => {
    const handleResize = () => {
      setCardWidth(document.body.clientWidth);
      setIsMobile(document.body.clientWidth < 640);// Adjust the breakpoint as needed
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);

    };
  }, [])

  //======= Get Event Details which is institute register for perform =====\\
  const geteventDetail = () => {
    apiJson('api/v2/modelUn-student/getEventDetails/institute/' + userData?.id)
      .then((res) => {
        // Set the response data in a state variable
        setEventDetail(res?.data?.result)
        if (res?.data.result.id) {
          return apiJson('api/v2/modelUn-institute/getSelectedCommittee/' + res?.data?.result?.id);
        } else {
          return Promise.resolve(null);
        }
      })
      .then((secondApiResponse) => {
        if (secondApiResponse) {
          // Set the response data in a state variable
          setCommitteeList(secondApiResponse?.data?.committeeRes)
        }
      })
      .catch((error) => {
        console.log(error.message)
      })
  }
  useEffect(() => {
    geteventDetail();
  }, [])

  // ===========  Get All User List  Api calling =================\\
  const getAllUserList = async () => {
    try {
      const res = await apiJson.get(`/api/v2/modelUn-institute/betaCheckApplicantUserList/${userData?.id}`)
      setAllUserList(res?.data?.allUser)
    } catch (error) {
      console.log("Error in Appoint Coordinator", error)
    }
  }

  //=========== Fetch all user Which is register in institute ==\\
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getAllUserList();
      } catch (error) {
        console.error("Error fetching user list:", error);
      }
    };

    fetchData();
  }, [userData.id, secretariat]);
  //========== Get All secretariat  role with slots api ==========\\
  const fetchSecretariat = async () => {
    try {
      if (eventDetail?.id) {
        const res = await apiJson(`api/v2/modelUn-institute/getAllSecDetails/institute/${userData?.id}/registerId/${eventDetail?.id}`);
        setAllSec(res?.data?.updatedAllSec);
      }
    } catch (error) {
      console.error("Error fetching secretariat details:", error);
    }
  };
  useEffect(() => {
    fetchSecretariat();
  }, [eventDetail?.id]);

  //======  Get all pre Selected secretariat details api  ====\\
  const fetchPreRegisterSecretariat = async () => {
    try {
      if (eventDetail?.id) {
        setSpinner(true)
        const res = await apiJson.post(`api/v2/modelUn-institute/getAllselectSecretariatDetails`, {
          instituteId: `${userData.id}`,
          registerId : eventDetail?.id
        });
        setSpinner(false)
        setSecretariat(res?.data?.allSelectSec)
        setFilterSecretariat(res?.data?.allSelectSec?.filter((ele) => ele?.secretariatType === "Executive Board"))
        setSecretariatLimit(10 - res?.data?.allSelectSec?.length);
      }
    } catch (error) {
      console.error("Error fetching secretariat details:", error);
    }
  };

  useEffect(() => {
    fetchPreRegisterSecretariat()
  }, [eventDetail?.id, secretariat.length])


  ///  ============ User Search modal open handler ===================== \\
  const searchOpenHandle = () => {
    setOpenSearch(true)
  }
  ///  ============ User Search modal hide handler ===================== \\
  const searchHideHandle = () => {
    setOpenSearch(false)
  }
  const handleShowModal = (modal) => {
    setCondtionCheckEv(modal)
    if (modal === "add") {
      setIsInputDisebled(false)
      formik.resetForm({
        values: {
          name: "",
          role: "",
          email: "",
          phone: "",
          registerId: "",
          studentId: "",
          selectedCommittee: "",
          pref_committee: "Secretariat",
          secretariatType: "Executive Board",
          secretariatsId: "",
          assign_designation: "",
          nominationType: "secure",
          status: "approved"
        },
      });
      searchHideHandle()
    }
    setShowModal({
      add: modal === "add" ? !showModal.add : false,
      edit: modal === "edit" ? !showModal.edit : false,

    })

  }

  const handleEditShowModal = (id, modal) => {
    setCondtionCheckEv(modal)
    seteditEvId(id)
    setIsInputDisebled(true)
    const selectedEv = filterSecretariatData?.find((ele) => ele?.id === id)

    formik.setValues({
      name: selectedEv?.name,
      role: selectedEv?.role,
      email: selectedEv?.email,
      phone: selectedEv?.phone,
      assign_designation: selectedEv?.assign_designation,
      selectedCommittee: selectedEv?.selectedCommittee
    });
    //  setEditFormValues(selectedEv)
    // setEditShowModal(true)
    // searchHideHandle()
    setShowModal({
      add: modal === "add" ? !showModal.add : false,
      edit: modal === "edit" ? !showModal.edit : false,

    })
  }

  //============== Secretariat Select function  =====\\
  const handleSecretariatChange = (selectedOption) => {
    formik.setFieldValue("assign_designation", selectedOption.role);
    formik.setFieldValue("secretariatsId", selectedOption.value)
    setUserDesignation(selectedOption.role)
  }

  const validationSchema = yup.object().shape({
    name: yup.string().required("name is required"),
    role: yup.string().required("UserType is required"),
    email: yup.string()
      .required("Email is required")
      .email("Invalid Email Address"),
    phone: yup
      .string()
      .required("Phone Number is Required")
      .matches(/^[0-9]{10}$/, "Invalid Mobile Number"),
    assign_designation: yup.string().required("Designation is required"),
    selectedCommittee: yup.string().required("Please select a Committee."),

  });

  //=========== Sec Form Data Submit function =======\\
  const onSubmit = async (values, { resetForm }) => {
    if (condtionCheckEv === "add") {
      const checkUserExist = secretariat?.find((i) => i?.email === values?.email)
    if (checkUserExist) {
      toast.dismiss();
      toast.error("User email Already exist.")
      return
    }
      setIsDisabled(true)
      if (secretariatLimit !== 0) {
        try {
          const res = await apiJsonAuth.post(`/api/v2/modelUn-institute/addSecMember`, {
            ...values, instituteId: userData.id,
            registerId: eventDetail?.id,
            last_registration_date: eventDetail?.last_date
          })
          if (res?.status === 201) {
            toast.success("Secretariat is assign successfully.")
            resetForm()
            handleShowModal("add");
            fetchPreRegisterSecretariat()
            getAllUserList();
            fetchSecretariat();
          }
          setIsDisabled(false)
        } catch (error) {
          toast.dismiss();
          toast.error(error?.response?.data?.message? error?.response?.data?.message: "Internal server Error")
          setIsDisabled(false)
        }
      }
    }
    else if (condtionCheckEv === "edit") {
      apiJsonAuth.put(`api/v2/modelUn-institute/editSecMember/${editEvId}`, {
        ...values, instituteId: userData.id,
        registerId: eventDetail?.id,
        last_registration_date: eventDetail?.last_date,
        secretariatType: "Executive Board"
      })
        .then((res) => {
          handleShowModal("edit")
          fetchPreRegisterSecretariat()
        })
        .catch((error) => {
          console.log("error", error.meesage)
          toast.error("Error")
        })
    }
    else {
      toast.dismiss();
      toast.error("The limit for Secretariat members has been reached.")
    }
  }

  //=========== Formik initially Define here ======\\
  const formik = useFormik({
    initialValues: {
      name: "",
      role: "student",
      email: "",
      phone: "",
      registerId: "",
      studentId: "",
      selectedCommittee: "",
      pref_committee: "",
      secretariatType: "Executive Board",
      secretariatsId: "",
      assign_designation: "",
      nominationType: "secure",
      status: "approved"
    },
    validationSchema: validationSchema,
    onSubmit
  })
  // ========= Select User From the modal where i click on the name of user =====
  const handleData = async (email, modal) => {
    const checkUserExist = filterSecretariatData?.find((i) => i?.email === email)
    if (checkUserExist) {
      toast.dismiss();
      toast.error("User email Already exist.")
      return
    }
    setIsInputDisebled(true)
    setCondtionCheckEv(modal)
    const res = allUserList.find((i) => i?.email === email);
    formik.setValues({
      name: res?.name,
      role: res?.role,
      email: res?.email,
      phone: res?.phone,
      registerId: eventDetail?.id,
      studentId: res?.id,
      pref_committee:"",
      secretariatType: "Executive Board",
      secretariatsId: "",
      assign_designation: "",
      selectedCommittee: "",
      nominationType: "secure",
      status: "approved"
    });
    setShowModal({
      add: modal === "add" ? !showModal.add : false,
      edit: modal === "edit" ? !showModal.edit : false,
    })
    searchHideHandle();
  }

  //  Notification
  const sendNotification = async (email) => {
    const res = allUserList.find((i) => i?.email === email);
    const notifyData = {
      instituteId: userData?.id,
      userId: res?.id,
    }
    try {
      let response = await apiJsonAuth.post(`api/v2/notification/createNotify/${userData?.id}/${res?.id}`, notifyData)
      if (response?.status === 201) {
        toast.dismiss();
        toast.success("Notification sent successfully.")
      }
    } catch (error) {
      toast.error(error.meesage)
    }
  }

  //======== Delete selected sec member  funtion ====\\
  const deleteSecMember = async (email) => {
    try {
      const res = await apiJsonAuth.delete(`/api/v2/modelUn-institute/deleteSelectSecMember/${email}`)
      if (res.status === 200) {
        toast.dismiss();
        toast.success("Executive Board member deleted successfully..")
        fetchPreRegisterSecretariat()
        fetchSecretariat();
       
      }
    } catch (error) {
      console.log("error", error.meesage)
    }
  }

  //======== Delete selected sec member  funtion ====\\

  // Search for secretariatMember
  const handleKeypress = (e) => {
    if (e.key === "Enter" || e.type === "click") {
      e.preventDefault();
      const searchInput = document.getElementById("searchInput").value
      const filterData = filterSecretariatData?.filter((ele) => {
        const nameWithoutSpaces = ele?.name.replace(/\s/g, '').toLowerCase();
        const sanitizedWithoutSpaces = searchInput.trim().replace(/\s/g, '').toLowerCase();
        return nameWithoutSpaces.includes(sanitizedWithoutSpaces);
      });
      setFilterSecretariat(filterData)
    }
    else if (!e.target.value) {
      fetchPreRegisterSecretariat()
    }
  }
  const handleClickSearch = (e) => {
    handleKeypress(e)
  }


  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ cursor: 'pointer' }}>
      {children}
    </span>
  ));


  return (
    <div className='col-12 col-md-10 px-3 mt-4'>
      <div>
        <span className="fw-500 fs-20px" style={{ color: "#979797" }}>
          Events {">"} United Nations {">"} Executive Board
        </span>
      </div>

      <form>
        <div className="d-flex mt-2 col-sm-12 col-10">
          <div className="col-md-6 col-lg-6">
            <input id="searchInput" style={{ height: "45px", width: "100%" }} onKeyDown={handleKeypress} className="rounded ps-2" placeholder="Search" autoComplete="off" />
          </div>
          <div><Button onClick={handleClickSearch} style={{ height: "45px", background: "#9b5ffd", color: "white" }}>Search</Button></div>
        </div>
      </form>

      <div className="d-flex justify-content-md-between flex-column flex-md-row col-md-10">
        <div className="mt-3 mb-2">
          <span className="fw-600 fs-32px">Add Executive Members</span>
        </div>

        <div className="mt-3">
          <button
            onClick={() => {
              searchOpenHandle()
            }}
            className="fw-400 text-center"
            style={{
              height: "40px",
              width: "115px",
              background: "#DA9EFF",
              color: "#39005B",
              fontSize: "17.5px",
              borderRadius: "5px",
            }}
          >
            Add New
          </button>
        </div>
      </div>

      {spinner ? (
        <div className="d-flex justify-content-center align-items-center">
          <TailSpin />
        </div>
      ) : secretariat.length <= 0 ? (
        <img src="/nodata.jpg" alt="" className="d-block m-auto" width="50%" />
      ) : (
        <>
          <div className="table-responsive" style={{ width: isMobile ? `${cardWidth - 30}px` : '100%' }}>
            <table class="table table-responsive">
              <thead>
                <tr>
                  <th >Name</th>
                  <th style={{ minWidth: "100px" }}>User Type</th>
                  <th >Committee</th>
                  <th >Designation</th>
                  <th >Action</th>
                </tr>
              </thead>
              <tbody>

                {spinner ? <div className="d-flex justify-content-center align-items-center"><TailSpin /></div> :
                  filterSecretariatData?.map((ele, i) => {
                    return (
                      <>
                        <tr>

                          <td>
                            <div className="px-2">
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant="dot" 
                              backgroundColor = {ele?.studentId !== 0 ? "#44b700" :"red"}                            
                              >
                                  {ele?.name}
                              </StyledBadge>
                            </div>
                          </td>
                          <td>
                            <span
                              className="fw-400 text-capitalize"
                              style={{ fontSize: "14px" }}
                            >
                              {ele?.role}
                            </span>{" "}
                          </td>
                          <td>
                            <span
                              className="fw-400"
                              style={{ fontSize: "14px" }}
                            >
                              {ele?.selectedCommittee}
                            </span>
                          </td>
                          <td>
                            <span
                              className="fw-400"
                              style={{ fontSize: "14px" }}
                            >
                              {ele?.assign_designation}
                            </span>
                          </td>
                          <td>
                            <Dropdown align="start" className="exclude-this-click">
                              <Dropdown.Toggle
                                as={CustomToggle}
                              >
                                <span
                                  className="fw-400"
                                  style={{ fontSize: "14px" }}
                                >
                                  <MoreVertIcon
                                    sx={{ color: "#23538f", fontSize: "25px" }}
                                  />
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                style={{
                                  position: 'absolute',
                                  inset: '0px auto auto 0px',
                                  transform: 'translate(-167px, 1px)',
                                  // background: "rgb(130 73 222)"
                                }}
                                className="dropdown-menu-left dropdown-menu-custom-class">
                                <Dropdown.Item className="update-delete-btn" onClick={() => deleteSecMember(ele?.email)}>Delete Member</Dropdown.Item>
                                <Dropdown.Item className="update-delete-btn" onClick={() => handleEditShowModal(ele?.id, "edit")}>Edit Member</Dropdown.Item>

                              </Dropdown.Menu>
                            </Dropdown>

                          </td>
                        </tr>

                      </>

                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      )
      }

      <Modal show={openSearch} className="left-rounded-modal" onHide={searchHideHandle} size="lg">
        <div className="modal-content">
          <span className="content-title fw-600 color-black fs-2 ps-4 px-md-5 mt-3">
            Add Secretariat Member
          </span>
          <span className="fs-19px ps-4  px-md-5 mt-4" style={{ color: "#000000" }}>Search for the Student/Teacher here by typing their name</span>
          <Modal.Body>

            <form>
              <div className="mt-2 px-2 px-md-4 px-lg-4">
                <input className="search-handle px-2" type="text" name="name" placeholder="Type a name"
                  onChange={(e) => SetSearchTitle(e.target.value)} />

              </div>
              <div className="bg-light search-handleList mt-3  mx-2 mx-md-4 rounded-3">
                <div className="databox" style={{overflowY:"auto"}}>

                  {allUserList?.filter((value) => {
                    if (searchTitle === "") {
                      return value;
                    } else if (
                      value.name.toLowerCase().includes(searchTitle.toLowerCase())
                    ) {
                      return value;
                    }

                  }).map((i) =>
                  (
                    <div className={`d-flex  mt-2 cursor-pointer p-2 rounded-3 ${i?.applicantId !== null ? "opacity-50" : null}`} onClick={()=>i?.applicantId === null ? handleData(i?.email,"add") : null} key={i?.email} style={{background: "#d1d1d1",alignItems: "center"}}>
                          <div style={{width:50,height:50 ,minWidth:50,minHeight:50}}><img src={i?.profile} alt="profile_image" className="w-100 h-100 rounded-circle" style={{border:"2px solid #b139f4"}}/></div>
                          <div className="ms-3">
                            <span className="fs-6 text-capitalize">{i?.name}</span>
                            {i?.applicantId !== null  ? <span className="fs-6 text-capitalize"> Applicant for {`${i?.preferredComm} ${i?.preferredDesignation} ${i?.preferredCountry} ${i?.preferredRole}`} </span> : <span className="fs-6 text-capitalize" style={{color:"#5A00EE"}}>(Not applied for participation [Invite])</span>
                             }
                          </div>
                          {i?.applicantId === null  ?(
                          <div className="me-3">
                          <span>
                            <AddCircleIcon  color="success"/>
                          </span>
                          </div>) : null}
                      </div>  
                  )
                  )}
                </div>
    
              </div>

            </form>
          </Modal.Body>
        </div>
      </Modal>

      {
        condtionCheckEv === "add" ? (
          <Modal show={showModal.add} className="left-rounded-modal" onHide={() => handleShowModal("add")} size="lg">
            <div className="modal-content">
              <Modal.Header closeButton>
                <Modal.Title className="content-title fw-600 color-black fs-34px ps-4 ps-md-0 px-md-5">
                  New Secretariat Member
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <div class="bd-example-modal-lg px-5">
                  <div className="row">
                    <div className="column col-12  col-lg-10 ">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                          {/* userName */}
                          <div className="firstName col-12  col-md-12  col-lg-6">
                            <div>
                              <div>
                                <label className="color-black fw-500 fs-19px">Full Name</label>
                              </div>
                              <div className="mt-2">
                                <input className="input-handle w-100 px-2" placeholder="Name" type="text" name="name" value={formik.values.name} disabled={isInputDisebled} onChange={formik.handleChange} id="" />
                                {formik.errors.name && formik.touched.name ? (<span className="text-danger">{formik.errors.name}</span>) : (null)}
                              </div>
                            </div>
                          </div>

                          {/* Designation */}
                          <div className="typesOfcoordinator col-12 col-md-12 col-lg-6">
                            <div>
                              <div>
                                <label className="color-black fw-500 fs-19px">User Type</label>
                              </div>
                              <div className="mt-2">
                                <Select
                                  styles={customStyles}
                                  options={showDesList}
                                  // value={formik?.values?.designation}
                                  defaultValue={showDesList.find((i) => i?.value === formik.values.role)}
                                  isDisabled={isInputDisebled}
                                  onChange={(e) => {
                                    // handleFormData('designation', e.name)
                                    formik.setFieldValue('role', e.value)
                                  }}
                                  getOptionLabel={
                                    (option) => {
                                      return (
                                        <>
                                          <div>
                                            <span>{option.name}</span>
                                          </div>
                                        </>
                                      )
                                    }
                                  }
                                />
                                {formik.errors.role && formik.touched.role ? (
                                  <span className="text-danger">{formik.errors.role}</span>
                                ) : (null)}
                              </div>
                            </div>
                          </div>

                          {/* email */}
                          <div className="emailId col-12 col-sm-12 col-md-12 col-lg-6">
                            <div>
                              <div className="mt-4">
                                <label className="color-black fw-500 fs-19px">Email ID</label>
                              </div>
                              <div className="mt-2">
                                <input className="input-handle w-100 px-2" type="email" name="email" placeholder="Email" id="" disabled={isInputDisebled} value={formik.values.email} onChange={(e) => { formik.setFieldValue("email", e.target.value) }} />
                                {formik.errors.email && formik.touched.email ? (
                                  <span className="text-danger">{formik.errors.email}</span>
                                ) : (null)}
                              </div>
                            </div>
                          </div>

                          {/* Phone */}
                          <div className="phone col-12 col-sm-12 col-md-12 col-lg-6">
                            <div className="col-12">
                              <div className="mt-4">
                                <label className="color-black fw-500 fs-19px">Phone</label>
                              </div>
                              <div className="d-flex" >
                                <div className="mt-2">
                                  <select className="input-selects-phone input-group-text fw-500">
                                    <option value="">+91</option>
                                  </select>
                                </div>
                                <div className="mt-2" style={{ width: "100%" }}>
                                  <input className="input-handle-phone px-2" type="text" name="phone" placeholder="99******88" id="" disabled={isInputDisebled} value={formik.values.phone} onChange={formik.handleChange} />
                                  {formik.errors.phone && formik.touched.phone ? (
                                    <span className="text-danger">{formik.errors.phone}</span>
                                  ) : (null)}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* type */}

                          <div className="typesOfcoordinator col-12 col-sm-12 col-md-12 col-lg-6">
                            <div>
                              <div className="mt-4">
                                <label className="color-black fw-500 fs-19px">Secretariat Type</label>
                              </div>
                              <div className="mt-2">

                                <input type="text" name="coordinator" className="input-selects px-2" value="Executive Board" id="" onChange={(e) => {
                                  formik.setFieldValue("secretariatType", e.target.value)
                                  setSecretariatType(e.target.value)
                                }} />
                              </div>
                            </div>
                          </div>
                          {/* Choose a committee */}
                          <div className="typesOfcoordinator col-12 col-sm-12 col-md-12 col-lg-6">
                            <div>
                              <div className="mt-4">
                                <label className="color-black fw-500 fs-19px">Choose a committee</label>
                              </div>
                              <div className="mt-2">
                                <Select
                                  styles={customStyles}
                                  options={committeeList}
                                  onChange={(e) => {
                                    // handleFormData('designation', e.name)
                                    formik.setFieldValue('pref_committee', e?.type)
                                    setSelectedCommitee(e?.type)
                                    formik.setFieldValue('selectedCommittee', e?.type)
                                  }}
                                  getOptionLabel={
                                    (option) => {
                                      return (
                                        <>
                                          <div>
                                            <span>{option.committee}</span>
                                          </div>
                                        </>
                                      )
                                    }
                                  }
                                />
                                {formik.errors.selectedCommittee && formik.touched.selectedCommittee ? (
                                  <span className="text-danger">{formik.errors.selectedCommittee}</span>
                                ) : (null)}
                              </div>
                            </div>
                          </div>
                          <div className="typesOfcoordinator col-12 col-sm-12 col-md-12 col-lg-6">
                            <div>
                              <div className="mt-4">
                                <label className="color-black fw-500 fs-19px">Assign Designation</label>
                              </div>
                              <div className="mt-2">
                                {secretariatType && (
                                  <Select
                                    options={allSec.filter((i) => i.typeName === "Executive Board").map((option) => ({
                                      ...option,
                                      isDisabled: secretariat?.find((item) => item?.assign_designation === option?.role && item?.selectedCommittee == selectedCommitee)
                                    }))}
                                    defaultValue={allSec.filter((i) => i.typeName === "Executive Board" && i.role === "Member OC")}
                                    isSearchable={false}
                                    styles={{
                                      ...customStyles,
                                      option: (provided, state) => ({
                                        ...provided,
                                        opacity: state.data.isDisabled ? 0.6 : 1,
                                        cursor: state.data.isDisabled ? "not-allowed" : "pointer",
                                      }),
                                    }}
                                    onChange={handleSecretariatChange}
                                    getOptionLabel={
                                      (option) => {
                                        return (
                                          <>
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div className='delegate-secretariet-pressCorps fw-500' style={{ color: "#3A3A3A" }}>{option.role}</div>
                                              <div className="delegate-secretariet-pressCorps" style={{ fontWeight: "500", fontSize: "15px", color: "#ACACAC" }}>{option.isDisabled ? (<span className="text-danger">NA</span>) : null}</div>

                                            </div>
                                          </>
                                        )
                                      }
                                    }
                                  />
                                )}
                                {formik.errors.assign_designation && formik.touched.assign_designation ? (
                                  <span className="text-danger">{formik.errors.assign_designation}</span>
                                ) : (null)}
                              </div>
                            </div>
                          </div>

                          {/* Button */}
                          <div className="addCoordinator col-12 col-sm-12 col-md-12 col-lg-6  mt-3">
                            <div className="w-100 d-flex justify-content-center">
                              <input className="input-handle " type="submit" disabled={isDisabled} style={{ background: "#6100FF", color: "white" }} value="Add Secretariat Member" />
                            </div>
                          </div>
                          <div className="adminRight d-flex justify-content- align-items-center mt-md-3">
                            <div><img src="/modelUn/Clipboard.png" alt="Clipboard" /></div>
                            {userDesignation === "Chairperson" ? (

                              <div className="admin-content"><span className="" style={{ color: "#979797" }}>Like a teacher, they lead the discussions, keep order, and make sure everyone follows the rules.</span></div>
                            ) : userDesignation === "Vice-Chairperson" ? (
                              <div className="admin-content"><span className="" style={{ color: "#979797" }}>Helps the Chairperson and takes over if the Chairperson is not there.</span></div>
                            ) : userDesignation === "Rapporteur" ? (
                              <div className="admin-content"><span className="" style={{ color: "#979797" }}>Keeps a record of what everyone says and helps with organising documents.</span></div>
                            ) :
                              (
                                <div className="admin-content"><span className="" style={{ color: "#979797" }}>The big boss of the whole event. They make sure everything runs smoothly..</span></div>
                              )
                            }
                          </div>
                        </div>
                      </form>
                    </div>

                  </div>

                </div>
              </Modal.Body>
            </div>
          </Modal>
        ) : condtionCheckEv === "edit" && (
          <Modal show={showModal.edit} className="left-rounded-modal" onHide={() => handleShowModal("edit")} size="lg">
            <div className="modal-content">
              <Modal.Header closeButton>
                <Modal.Title className="content-title fw-600 color-black fs-34px ps-4 ps-md-0 px-md-5">
                  Edit Secretariat Member
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <div class="bd-example-modal-lg px-5">
                  <div className="row">
                    <div className="column col-12  col-lg-10 ">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                          <p className="text-danger fs-6">Note:You can change only the designation and  Committee.</p>
                          {/* userName */}
                          <div className="firstName col-12  col-md-12  col-lg-6">
                            <div>
                              <div>
                                <label className="color-black fw-500 fs-19px">Full Name</label>
                              </div>
                              <div className="mt-2">
                                <input className="input-handle w-100 px-2" type="text" name="name" placeholder="Name" value={formik.values.name} onChange={formik.handleChange} id="" />
                                {formik.errors.name && formik.touched.name ? (<span className="text-danger">{formik.errors.name}</span>) : (null)}
                              </div>
                            </div>
                          </div>

                          {/* Designation */}
                          <div className="typesOfcoordinator col-12 col-md-12 col-lg-6">
                            <div>
                              <div>
                                <label className="color-black fw-500 fs-19px">User Type</label>
                              </div>
                              <div className="mt-2">
                                <Select
                                  styles={customStyles}
                                  options={showDesList}
                                  // value={formik?.values?.designation}
                                  defaultValue={showDesList.find((i) => i?.value === formik.values.role)}
                                  isDisabled={isInputDisebled}
                                  onChange={(e) => {
                                    // handleFormData('designation', e.name)
                                    formik.setFieldValue('role', e.value)
                                  }}
                                  getOptionLabel={
                                    (option) => {
                                      return (
                                        <>
                                          <div>
                                            <span>{option.name}</span>
                                          </div>
                                        </>
                                      )
                                    }
                                  }
                                />
                                {formik.errors.role && formik.touched.role ? (
                                  <span className="text-danger">{formik.errors.role}</span>
                                ) : (null)}
                              </div>
                            </div>
                          </div>

                          {/* email */}
                          <div className="emailId col-12 col-sm-12 col-md-12 col-lg-6">
                            <div>
                              <div className="mt-4">
                                <label className="color-black fw-500 fs-19px">Email ID</label>
                              </div>
                              <div className="mt-2">
                                <input className="input-handle w-100 px-2" type="email" name="email" placeholder="Email" id="" value={formik.values.email} disabled={formik.values.email} onChange={(e) => { formik.setFieldValue("email", e.target.value) }} />
                                {formik.errors.email && formik.touched.email ? (
                                  <span className="text-danger">{formik.errors.email}</span>
                                ) : (null)}
                              </div>
                            </div>
                          </div>

                          {/* Phone */}
                          <div className="phone col-12 col-sm-12 col-md-12 col-lg-6">
                            <div className="col-12">
                              <div className="mt-4">
                                <label className="color-black fw-500 fs-19px">Phone</label>
                              </div>
                              <div className="d-flex" >
                                <div className="mt-2">
                                  <select className="input-selects-phone input-group-text fw-500">
                                    <option value="">+91</option>
                                  </select>
                                </div>
                                <div className="mt-2" style={{ width: "100%" }}>
                                  <input className="input-handle-phone px-2" type="text" name="phone" placeholder="99******88" id="" value={formik.values.phone} onChange={formik.handleChange} />
                                  {formik.errors.phone && formik.touched.phone ? (
                                    <span className="text-danger">{formik.errors.phone}</span>
                                  ) : (null)}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* type */}

                          <div className="typesOfcoordinator col-12 col-sm-12 col-md-12 col-lg-6">
                            <div>
                              <div className="mt-4">
                                <label className="color-black fw-500 fs-19px">Secretariat Type</label>
                              </div>
                              <div className="mt-2">
                                <input type="text" name="coordinator" className="input-selects px-2" value="Executive Board" id="" onChange={(e) => {
                                  formik.setFieldValue("secretariatType", e.target.value)
                                  setSecretariatType(e.target.value)
                                }} />
                              </div>
                            </div>
                          </div>
                          {/* Choose a committee */}

                          <div className="typesOfcoordinator col-12 col-sm-12 col-md-12 col-lg-6">
                            <div>
                              <div className="mt-4">
                                <label className="color-black fw-500 fs-19px">Choose a committee</label>
                              </div>
                              <div className="mt-2">
                                <Select
                                  styles={customStyles}
                                  options={committeeList}
                                  onChange={(e) => {
                                    setSelectedCommitee(e?.type)
                                    formik.setFieldValue('selectedCommittee', e?.type)
                                  }}
                                  defaultValue={committeeList?.find((i) => i?.committee === formik?.values?.selectedCommittee)}
                                  getOptionLabel={
                                    (option) => {
                                      return (
                                        <>
                                          <div>
                                            <span>{option.committee}</span>
                                          </div>
                                        </>
                                      )
                                    }
                                  }
                                />
                                {formik.errors.selectedCommittee && formik.touched.selectedCommittee ? (
                                  <span className="text-danger">{formik.errors.selectedCommittee}</span>
                                ) : (null)}
                              </div>
                            </div>
                          </div>
                          <div className="typesOfcoordinator col-12 col-sm-12 col-md-12 col-lg-6">
                            <div>
                              <div className="mt-4">
                                <label className="color-black fw-500 fs-19px">Assign Designation</label>
                              </div>
                              <div className="mt-2">
                               
                                {secretariatType && (
                                  <Select
                                    options={allSec.filter((i) => i.typeName === "Executive Board").map((option) => ({
                                      ...option,
                                      isDisabled: secretariat?.find((item) => item?.assign_designation == option?.role && item?.selectedCommittee == selectedCommitee)
                                    }))}
                                    defaultValue={allSec.find((i) => i.typeName === "Executive Board" && i.role === formik?.values?.assign_designation)}
                                    isSearchable={false}
                                    styles={{
                                      ...customStyles,
                                      option: (provided, state) => ({
                                        ...provided,
                                        opacity: state.data.isDisabled ? 0.6 : 1,
                                        cursor: state.data.isDisabled ? "not-allowed" : "pointer",
                                      }),
                                    }}
                                    onChange={handleSecretariatChange}
                                    getOptionLabel={
                                      (option) => {
                                        return (
                                          <>
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div className='delegate-secretariet-pressCorps fw-500' style={{ color: "#3A3A3A" }}>{option.role}</div>
                                              <div className="delegate-secretariet-pressCorps" style={{ fontWeight: "500", fontSize: "15px", color: "#ACACAC" }}>{option.isDisabled ? (<span className="text-danger">NA</span>) : null}</div>

                                            </div>
                                          </>
                                        )
                                      }
                                    }
                                  />
                                )}
                                {formik.errors.assign_designation && formik.touched.assign_designation ? (
                                  <span className="text-danger">{formik.errors.assign_designation}</span>
                                ) : (null)}
                              </div>
                            </div>
                          </div>

                          {/* Button */}
                          <div className="addCoordinator col-12 col-sm-12 col-md-12 col-lg-6  mt-3">
                            <div className="w-100 d-flex justify-content-center">
                              <input className="input-handle " type="submit" disabled={isDisabled} style={{ background: "#6100FF", color: "white" }} value="Edit Secretariat Member" />
                            </div>
                          </div>
                          <div className="adminRight d-flex justify-content- align-items-center mt-md-3">
                            <div><img src="/modelUn/Clipboard.png" alt="Clipboard" /></div>
                            {userDesignation === "Chairperson" ? (
                              <div className="admin-content"><span className="" style={{ color: "#979797" }}>Like a teacher, they lead the discussions, keep order, and make sure everyone follows the rules.</span></div>
                            ) : userDesignation === "Vice-Chairperson" ? (
                              <div className="admin-content"><span className="" style={{ color: "#979797" }}>Helps the Chairperson and takes over if the Chairperson is not there.</span></div>
                            ) : userDesignation === "Rapporteur" ? (
                              <div className="admin-content"><span className="" style={{ color: "#979797" }}>Keeps a record of what everyone says and helps with organising documents.</span></div>
                            ) :
                              (
                                <div className="admin-content"><span className="" style={{ color: "#979797" }}>The big boss of the whole event. They make sure everything runs smoothly..</span></div>
                              )
                            }
                          </div>
                        </div>
                      </form>
                    </div>

                  </div>

                </div>
              </Modal.Body>
            </div>
          </Modal>
        )
      }


    </div>
  )
}

const showDesList = [
  {
    value: "student",
    name: "Student",
  },
  {
    value: "teacher",
    name: "Teacher",
  },
  {
    value: "admin",
    name: "Admin",
  },
  {
    value: "external",
    name: "External",
  },
]

const customStyles = {
  control: (provided) => ({
    ...provided,
    padding: "3px",
    border: "none",
    background: "#a2a2a221",
    color: 'black'
    // boxShadow:'none'
  }),
};