import React, { useState, useEffect } from "react";
 import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';
import { useParams } from "react-router-dom";
import Loader from "../SewaPage/Loader.jsx";
import { apiSewa } from "api/index.js";



const ProjectDetails = () => {
    const [studentData, setStudentData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const { instituteId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiSewa.get(`/approval/projects/institute/${instituteId}`);
                console.log("response data project",response.data.projects);
                setStudentData(response.data.projects);
            } catch (err) {
                setError(err.message || "Something went wrong.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const filteredData = studentData.filter(data =>
        data.mentorName.toLowerCase().includes(searchQuery)
    );

    const paginatedData = studentData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    // Create padding rows if the paginated data is less than rowsPerPage
    const emptyRows = rowsPerPage - paginatedData.length;

    if (loading) {
        return <Loader text={'Wait for a Moment'} />;
    }

    if (error) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                    width: "100%",
                }}
            >
                <Typography
                    sx={{
                        color: "#ff6347",
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                    }}
                >
                    {error}
                </Typography>
            </Box>
        );
    }

    return (
        <>

   <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography
                sx={{
                    fontFamily: 'Poppins',
                    fontSize: '1rem',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left',
                    color: "#575757",
                    marginBottom: "3rem",
                    marginTop: "1rem",
                }}
            >
                This third step tracks all pending, ongoing, and completed projects, helping users manage progress, mentors, and timelines effectively.
            </Typography>

            <Box
                sx={{
                    padding: "1rem",
                    backgroundColor: "var(--color-ghostwhite)",
                    position: "relative",
                    boxSizing: "border-box",
                    width: "100%",
                    borderRadius: "1rem", 
                    overflow: "hidden",
                    zIndex: 1,
                    ":before": {
                        content: '""',
                        background: "linear-gradient(122.52deg, rgba(35, 86, 254, 0.7) 1.25%, rgba(35, 86, 254, 0.1) 98.44%)",
                        borderRadius: "1rem", 
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        zIndex: "1",
                        margin: "-1rem",
                    }
                }}
            >

<Box sx={{display:"flex" , flexDirection: "column" , position: "relative",
                            zIndex: 5}}>



<Box
  sx={{
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 5,
    // padding: { xs: "1rem", sm: "2rem 1.5rem" }, // Responsive padding
  }}
>
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: { xs: "1rem", sm: "2rem 1.5rem" }, 
      borderTopLeftRadius: "1rem",
      borderTopRightRadius: "1rem",
      position: "relative",
      backgroundColor: "#ffffff",
      zIndex: 5,
      flexDirection: { xs: "column", sm: "row" }, 
    }}
  >
    <Box sx={{ width: { xs: "100%", sm: "90%" } }}> 
      <Typography
        variant="h4"
        component="div"
        gutterBottom
        sx={{
          fontFamily: "Poppins",
          fontWeight: 700,
          textAlign: "left",
          position: "relative",
          zIndex: 5,
        }}
      >
        <span style={{ color: "blue", zIndex: 5 }}>View</span> Projects
      </Typography>
      <Typography
        gutterBottom
        component="div"
        sx={{
          fontFamily: "Poppins",
          fontWeight: 400,
          lineHeight: "18.97px",
          fontSize: { xs: "12px", sm: "12.65px" },
          textAlign: "left",
          color: "#6D6D6D",
          zIndex: 5,
        }}
      >
        Explore key details such as project name, description, mentor, assigned students, start date, and progress status for an overview of all ongoing projects.
      </Typography>
    </Box>

    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        position: "relative",
        zIndex: 5,
        marginTop: { xs: "1rem", sm: 0 }, 
      }}
    >
      <div
        style={{
          position: "relative",
          zIndex: 5,
          width: { xs: "100%", sm: "16rem" }, 
          height: "2.5rem",
          backgroundColor: "#e7e7e7",
          borderRadius: "0.7rem",
          display: "flex",
          alignItems: "center",
          padding: "0 12px",
        }}
      >
        <SearchIcon style={{ color: "#42A5F5", marginRight: "8px" }} />
        <input
          type="text"
          placeholder="Search Categories"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            fontSize: { xs: "0.9rem", sm: "1rem" }, 
            color: "#333",
            padding: "0",
            zIndex: 5,
          }}
        />
      </div>
    </Box>
  </Box>
</Box>


            {studentData.length > 0 ? (
                <Box sx={{zIndex:"5" ,backgroundColor: "#fff" ,  borderBottomLeftRadius: '1rem' ,   borderBottomRightRadius:"1rem"  }}>
                <TableContainer component={Paper} sx={{ width: 'auto', position: "relative", zIndex:"5"}}>
                    <Table sx={{ minWidth: 650 }}>
                    <TableHead>
    <TableRow>
        <TableCell 
            sx={{ 
                color: '#000000', 
                backgroundColor: "#F3F3F3", 
                fontWeight: '600', 
                textAlign: { md: 'center', xs: 'left' },  
            }}
        >
            Project Mentor
        </TableCell>
        <TableCell 
            sx={{ 
                color: '#000000', 
                backgroundColor: "#F3F3F3", 
                fontWeight: '600', 
                textAlign: { md: 'center', xs: 'left' },  
            }}
        >
            Project Name
        </TableCell>
        <TableCell 
            sx={{ 
                color: '#000000', 
                backgroundColor: "#F3F3F3", 
                fontWeight: '600', 
                textAlign: { md: 'center', xs: 'left' },  
            }}
        >
            Project Status
        </TableCell>
    </TableRow>
</TableHead>

                        <TableBody>
                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project, index) => (
                                <TableRow key={index} sx={{justifyContent: {md:'center' , xs:"start" },}}>
                                    <TableCell sx={{ color: '#2356fe', fontWeight: '600',   textAlign: { md: 'center', xs: 'left' }, justifyContent: {md:'center' , xs:"start" },  
}}>
                                        {project?.mentorName}
                                    </TableCell>
                                    <TableCell sx={{ color: '#2356fe', fontWeight: '600',   textAlign: { md: 'center', xs: 'left' } , justifyContent: {md:'center' , xs:"start" }, }}>
                                        {project.projectName}
                                    </TableCell>
                                    <TableCell
                                    sx={{
                                        display: 'flex',   
                                         justifyContent: {md:'center' , xs:"start" },  
                                         alignItems: 'center',
                                    }}>
    <Box 
        sx={{
            backgroundColor: project.status === 'Rejected' 
                ? '#ff6347' 
                : project.status === 'Approved' 
                ? '#32cd32' 
                : project.status === 'Pending' 
                ? '#ffeb3b' 
                : '#ffffff',  
            borderRadius: '10px',
            padding: '10px',
            alignContent: "center",
            justifyContent:"center",
            textAlign:"center",
            fontWeight:"500",
            fontFamily: "Poppins",
            width:"50%"
        }}
    >
        {project.status}
    </Box>
</TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows  }}>
                                    <TableCell colSpan={3}  />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                   
                </TableContainer>
                
 <Box
 sx={{
    display: 'flex',
    justifyContent: { xs: 'center', md: 'space-between' }, 
    alignItems: 'center',
    padding: '0.5rem 1rem',
    zIndex: "5",
    flexWrap: 'wrap', 
    gap: { xs: 2, md: 0 }, 
 }}
>
<TablePagination
 component="div"
 count={50} 
 page={0} 
 onPageChange={() => {}}
 rowsPerPage={rowsPerPage}
 onRowsPerPageChange={handleChangeRowsPerPage} 
 rowsPerPageOptions={[5, 10, 25]}
 labelRowsPerPage="Rows per page"
 sx={{
     flexShrink: 0,
     '& .MuiTablePagination-toolbar': {
         paddingLeft: 0,
     },
     '& .MuiTablePagination-selectLabel': {
         marginRight: 2, 
     },
     '& .MuiSelect-select': {
         border: '1px solid #ccc',
         borderRadius: '4px',
         padding: '6px 8px', 
     },
     overflowX:"hidden",
 }}
 labelDisplayedRows={() => ''}
 slotProps={{
     actions: {
         nextButton: { sx: { display: 'none' } },
         backButton: { sx: { display: 'none' } }, 
         firstButton: { sx: { display: 'none' } }, 
         lastButton: { sx: { display: 'none' } }, 
     },
 }}
/>
 <CustomPagination
     // count={studentData.length}
     page={page}
     rowsPerPage={rowsPerPage}
     onPageChange={handleChangePage}
 />
</Box>

</Box>

            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '60vh',
                        width: '100%',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#2356fe',
                            fontWeight: 'bold',
                            fontSize: '1.5rem',
                        }}
                    >
                        Please Apply Before Checking Status...
                    </Typography>
                </Box>
            )}

</Box> 
            </Box>
            </Box>
        </>
    );
};

export default ProjectDetails;




export const CustomPagination = ({ count = 50, page, rowsPerPage, onPageChange }) => {
    const totalPages = Math.ceil(count / rowsPerPage);

    const handlePreviousPage = (e) => {
        if (page > 0) {
            onPageChange(e, page - 1);
        }
    };

    const handleNextPage = (e) => {
        if (page < totalPages - 1) {
            onPageChange(e, page + 1);
        }
    };

    const renderPaginationItems = () => {
        const paginationItems = [];

        // Previous Page Button
        paginationItems.push(
            <IconButton
                key="prev"
                onClick={handlePreviousPage}
                disabled={page === 0}
                sx={{
                    backgroundColor: 'transparent',
                    color: page === 0 ? '#cccccc' : '#000000',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                <ArrowBackIosIcon sx={{ color: page === 0 ? '#cccccc' : '#000000' }} />
            </IconButton>
        );

        // First Page
        paginationItems.push(
            <Box
                key={0}
                sx={{
                    width: { xs: '1.5rem', md: '2rem' },
                    height: { xs: '1.5rem', md: '2rem' },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    backgroundColor: '#ffffff',
                    color: '#000000',
                    borderRadius: '50%',
                    border: page === 0 ? '2px solid #2356fe' : '2px solid transparent',
                    '&:hover': {
                        backgroundColor: '#f0f0f0',
                    },
                }}
                onClick={(e) => onPageChange(e, 0)}
            >
                1
            </Box>
        );

        // Ellipsis after first page
        paginationItems.push(
            <Typography
                key="ellipsis"
                sx={{ padding: '0.5rem', color: '#000000', fontWeight: 'bold' }}
            >
                ...
            </Typography>
        );

        // Next Page (relative to the current one)
        if (page > 0 && page < totalPages - 1) {
            paginationItems.push(
                <Box
                    key={page}
                    sx={{
                        width: { xs: '1.5rem', md: '2rem' },
                        height: { xs: '1.5rem', md: '2rem' },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        backgroundColor: '#ffffff',
                        color: '#000000',
                        borderRadius: '50%',
                        border: '2px solid #2356fe',
                        textAlign: 'center',
                        '&:hover': {
                            backgroundColor: '#f0f0f0',
                        },
                    }}
                    onClick={(e) => onPageChange(e, page)}
                >
                    {page + 1}
                </Box>
            );
        }

        // Last Page
        paginationItems.push(
            <Box
                key={totalPages - 1}
                sx={{
                    width: { xs: '1.5rem', md: '2rem' },
                    height: { xs: '1.5rem', md: '2rem' },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    backgroundColor: '#ffffff',
                    color: '#000000',
                    borderRadius: '50%',
                    border: page === totalPages - 1 ? '2px solid #2356fe' : '2px solid transparent',
                    textAlign: 'center',
                    '&:hover': {
                        backgroundColor: '#f0f0f0',
                    },
                }}
                onClick={(e) => onPageChange(e, totalPages - 1)}
            >
                {totalPages}
            </Box>
        );

        // Next Page Button
        paginationItems.push(
            <IconButton
                key="next"
                onClick={handleNextPage}
                disabled={page >= totalPages - 1}
                sx={{
                    backgroundColor: 'transparent',
                    color: page >= totalPages - 1 ? '#cccccc' : '#000000',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                <ArrowForwardIosIcon sx={{ color: page >= totalPages - 1 ? '#cccccc' : '#000000' }} />
            </IconButton>
        );

        return paginationItems;
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                justifyContent: 'center',
                flexWrap: 'wrap',
                '@media (max-width: 600px)': {
                    gap: '0.25rem',
                },
            }}
        >
            {renderPaginationItems()}
        </Box>
    );
};



// import React, { useState, useEffect } from "react";
// import {
//     Box,
//     Typography,
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TableRow,
//     Paper,
// } from "@mui/material";
// import axios from "axios";

// const ProjectDetails = () => {
//     const [studentData, setStudentData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await axios.get("http://localhost:3000/api/approval/projects/mentor/66b5db123d9f051457e4708e");
//                 setStudentData(response.data.projects);
//             } catch (err) {
//                 setError(err.message || "Something went wrong.");
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchData();
//     }, []);

//     if (loading) {
//         return (
//             <Box
//                 sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     height: "60vh",
//                     width: "100%",
//                 }}
//             >
//                 <Typography
//                     sx={{
//                         color: "#2356fe",
//                         fontWeight: "bold",
//                         fontSize: "1.5rem",
//                     }}
//                 >
//                     Loading...
//                 </Typography>
//             </Box>
//         );
//     }

//     if (error) {
//         return (
//             <Box
//                 sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     height: "60vh",
//                     width: "100%",
//                 }}
//             >
//                 <Typography
//                     sx={{
//                         color: "#ff6347",
//                         fontWeight: "bold",
//                         fontSize: "1.5rem",
//                     }}
//                 >
//                     {error}
//                 </Typography>
//             </Box>
//         );
//     }

//     return (
//         <Box
//             sx={{
//                 width: "auto",
//                 padding: "1rem",
//             }}
//         >
//             <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
//                 <Table sx={{ minWidth: 650 }} aria-label="project details table">
//                     <TableHead>
//                         <TableRow sx={{ backgroundColor: '#2356fe' }}>
//                             <TableCell sx={{ color: '#FFF', fontWeight: 'bold' }}>Project Name</TableCell>
//                             <TableCell sx={{ color: '#FFF', fontWeight: 'bold' }}>Category</TableCell>
//                             <TableCell sx={{ color: '#FFF', fontWeight: 'bold' }}>Status</TableCell>
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {studentData.length > 0 ? (
//                             studentData.map((project, index) => (
//                                 <TableRow key={index}>
//                                     <TableCell sx={{ color: "#2356fe", fontWeight: "600", borderBottom: "none" }}>
//                                         {project.projectName}
//                                     </TableCell>
//                                     <TableCell sx={{ color: "#2356fe", fontWeight: "600", borderBottom: "none" }}>
//                                         {project.category}
//                                     </TableCell>
//                                     <TableCell sx={{
//                                         borderRadius: "10px",
//                                         padding: "10px",
//                                         backgroundColor: project.status === "Rejected" ? "#ff6347" : "#32cd32",
//                                         color: "white",
//                                         borderBottom: "none",
//                                         textAlign: 'center',
//                                     }}>
//                                         {project.status}
//                                     </TableCell>
//                                 </TableRow>
//                             ))
//                         ) : (
//                             <TableRow>
//                                 <TableCell colSpan={3} align="center" sx={{ borderBottom: "none" }}>
//                                     <Typography
//                                         sx={{
//                                             color: "#2356fe",
//                                             fontWeight: "bold",
//                                             fontSize: "1.5rem",
//                                         }}
//                                     >
//                                         Please Apply Before Checking Status...
//                                     </Typography>
//                                 </TableCell>
//                             </TableRow>
//                         )}
//                     </TableBody>
//                 </Table>
//             </TableContainer>
//         </Box>
//     );
// };

// export default ProjectDetails;
