import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination, TextField } from '@mui/material';
import { Box } from '@mui/system';
import debounce from 'lodash/debounce';
import moment from 'moment';
import YuvaLoader from 'pages/Forum/components/Loader/YuvaLoader';
import { useParams } from 'react-router-dom';

const extractMappedData = (data, mappedHeaders) => {
    // console.log(data, mappedHeaders);
    return data.map(entry => {
        const mappedEntry = {};
        mappedHeaders.forEach(header => {
            if (header.destination) {
                if (header.destination === 'guardian1') {
                    mappedEntry[header.destination] = entry['guardian_one'] || '';
                } else if (header.destination === 'guardian2') {
                    mappedEntry[header.destination] = entry['guardian_two'] || '';
                } else {
                    mappedEntry[header.destination] = entry[header.destination] || '';
                }
            }
        });
        return mappedEntry;
    });
};

const preprocessData = (incorrectData, mappedHeaders) => {
    const extractedData = extractMappedData(incorrectData, mappedHeaders);
    return extractedData.map(entry => {
        if (entry.dob && moment(entry.dob, "YYYY-MM-DD", true).isValid()) {
            entry.dob = moment(entry.dob).format("DD/MM/YYYY");
        }
        return entry;
    });
};

// Helper Function for Calculate Age
const calculateAge = (dob) => {
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const m = today.getMonth() - dob.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
        age--;
    }
    // console.log("Age: ", age);
    return age;
};

// Helper function for validating a single row
const validateRow = (row, role) => {
    let isRowValid = true;
    const validatedRow = {};

    // First, validate each field individually
    for (const key in row) {
        if (key !== 'otherData') {
            const value = row[key]?.value !== undefined ? row[key].value : row[key];
            validatedRow[key] = validateField(key, value, row, role);
            if (!validatedRow[key].isValid) isRowValid = false;  // Keep track of row validity
        } else {
            validatedRow[key] = row[key];  // Preserve otherData as is
        }
    }

    // After individual validation, apply group validation logic
    const finalValidatedRow = applyGroupValidation(validatedRow);
    // console.log("Final Row: ", finalValidatedRow);
    // Update the isRowValid flag based on group validation result
    isRowValid = Object.values(finalValidatedRow).every(
        (field) => field?.isValid !== undefined ? field.isValid : true
    );

    // console.log(isRowValid);
    return { validatedRow: finalValidatedRow, isRowValid };
};

// Function to validate each individual field
const validateField = (key, value, row, role) => {
    const emailRegex = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/;
    const nameRegex = /^[a-zA-Z\s.]+$/;
    let isValid = true; // Default to true

    switch (key) {
        case 'first_name':
            // First name should not be empty and must contain only letters, spaces, and periods
            if (value?.toString().trim() !== '' && nameRegex.test(value)) {
                value = value.toString().trim(); // Store the trimmed value
            } else {
                isValid = false;
            }
            break;

        case 'email':
            // Email should match the email regex or be marked as incorrect if empty
            if (value?.toString().trim() !== '' && emailRegex.test(value)) {
                value = value.toString().trim(); // Store the trimmed value
            } else {
                isValid = false;
            }
            break;

        case 'contact':
            // Contact should not be empty, must be 10 digits, and contain only numbers
            if (value?.toString().trim() !== '' && value.length === 10 && !isNaN(value)) {
                value = value.toString().trim(); // Store the value as-is
            } else {
                isValid = false;
            }
            break;

        case 'father_name':
        case 'mother_name':
        case 'guardian1':
        case 'guardian2':
            // These fields should follow the same rules as first_name (not empty, only letters, spaces, and periods allowed)
            if (value?.toString().trim() !== '' && nameRegex.test(value)) {
                value = value.toString().trim(); // Store the trimmed value
            } else {
                isValid = false;
            }
            break;

        case 'class':
            // Class should be between 6 and 12
            const classNum = parseInt(value);
            if (!isNaN(classNum) && classNum >= 6 && classNum <= 12) {
                value = classNum; // Store the parsed number
            } else {
                isValid = false;
            }
            break;

        case 'stream':
            // Stream must not be empty if class is 11 or 12
            // console.log("Class before parsing: ", row?.class?.value);
            const classValue = parseInt(row?.class?.value);
            // console.log("Value for class is: ", classValue);
            // console.log("Value for Stream:", value);

            // Check if classValue is NaN, and if it's 11 or 12, check the stream value
            if (!isNaN(classValue) && !(classValue === 11 || classValue === 12) || value?.toString().trim() !== '') {
                value = value.toString().trim(); // Store the trimmed value
            } else {
                isValid = false;
            }

            // console.log("isValid:", isValid);
            break;

        case 'dob':
            // Validate the date format
            const dateFormats = [
                "DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY",
                "MM/DD/YYYY", "MM/DD/YY", "MM-DD-YYYY", "MM-DD-YY",
                "YYYY-MM-DD", "D/M/YYYY", "D-M-YYYY",
                "D.M.YY", "DD.MM.YY", "DD.MM.YYYY", "D.M.YYYY", "DD.M.YYY", "D.MM.YYYY",
                "MMMM D, YYYY", "D MMMM YYYY", "D MMM YYYY"
            ];

            let parsedDate = null;
            isValid = false; // Initially set to false

            for (const format of dateFormats) {
                parsedDate = moment(value, format, true);
                if (parsedDate.isValid()) {
                    value = parsedDate.format("YYYY-MM-DD"); // Store the formatted date
                    isValid = true;
                    break;
                }
            }

            if (isValid) {
                // Calculate age based on the DOB
                const dob = new Date(value);
                const age = calculateAge(dob);

                // Validate age based on role
                if (role === 'student' && (age < 8 || age > 25)) {
                    isValid = false; // Student age must be between 8 and 25 years
                } else if (role === 'teacher' && (age < 18 || age > 75)) {
                    isValid = false; // Teacher age must be between 18 and 75 years
                }
            }
            break;

        default:
            // Default case is always valid
            isValid = true;
            break;
    }

    return { value, isValid };
};

// Function to apply group validation logic after individual fields are validated
const applyGroupValidation = (validatedRow) => {
    // Group validation for contact and email
    const contactEmailValid = ['contact', 'email'].some((key) => validatedRow[key]?.isValid);

    ['contact', 'email'].forEach((key) => {
        // Mark both fields valid if either is valid, otherwise mark both invalid
        validatedRow[key] && (validatedRow[key].isValid = contactEmailValid);
    });

    // Group validation for guardian fields
    const guardianFields = ['father_name', 'mother_name', 'guardian1', 'guardian2'];
    const hasGuardian = guardianFields.some((key) => validatedRow[key]?.isValid);

    guardianFields.forEach((key) => {
        // Mark all guardians invalid if none are valid or missing, otherwise mark them valid
        validatedRow[key] && (validatedRow[key].isValid = hasGuardian);
    });

    return validatedRow;
};

// Memoized Row Component
const MemoizedTableRow = memo(({ row, index, handleFieldChange, handleBlur }) => (
    <TableRow>
        {Object.keys(row).map(
            (key) =>
                key !== 'otherData' && (
                    <TableCell key={key} style={{ padding: '0.5rem' }}>
                        {key === 'gender' ? (
                            <TextField
                                select
                                value={row[key].value === 'M' || row[key].value === 'F' || row[key].value === ''
                                    ? row[key].value
                                    : 'O'} // Handle gender selection logic
                                onChange={(e) => handleFieldChange(index, key, e.target.value)}
                                onBlur={() => handleBlur(index, key)} // Debounced onBlur
                                variant="outlined"
                                size="small"
                                style={{ width: '120px' }}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <option value="">None</option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                                <option value="O">Other</option>
                            </TextField>
                        ) : (
                            <TextField
                                value={row[key].value}
                                error={!row[key].isValid}
                                onChange={(e) => handleFieldChange(index, key, e.target.value)}
                                onBlur={() => handleBlur(index, key)} // Debounced onBlur
                                variant="outlined"
                                size="small"
                                style={{
                                    borderColor: row[key].isValid ? '' : 'red',
                                    width: '120px'
                                }}
                            />
                        )}
                    </TableCell>
                )
        )}
    </TableRow>
));

const SpreadsheetView = (props) => {
    const { role } = useParams();
    const [mappedHeaders, setMappedHeaders] = useState();
    const [incorrectData, setIncorrectData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const rowsPerPage = 15;
    // Preprocess and validate data on mount
    useEffect(() => {
        const targetKeys = [
            { destination: 'id' },
            { destination: 'first_name' },
            { destination: 'father_name' },
            { destination: 'mother_name' },
            { destination: 'dob' },
            { destination: 'email' },
            { destination: 'contact' },
            { destination: 'guardian1' },
            { destination: 'guardian2' },
        ];

        if (role === "student") {
            targetKeys.push({ destination: 'class' }, { destination: 'stream' });
        }
        setMappedHeaders(targetKeys);

        // console.log("Incorrect Data: ", props.incorrectData);
        const cleanedData = preprocessData(props?.incorrectData || [], targetKeys);
        // console.log("Data after Cleaning:", cleanedData);

        const formattedData = cleanedData.map((entry) => {
            const { validatedRow } = validateRow(entry, role);
            return { ...validatedRow };
        });

        // console.log("Formatted Data: ", formattedData);
        setIncorrectData(formattedData);
    }, [props?.incorrectData, role]);

    // Debounced version of handleFieldBlur to reduce lag
    const debouncedHandleFieldBlur = useCallback(
        debounce((index, key) => {
            setLoading(true);
            const updatedIncorrectData = [...incorrectData];
            const row = updatedIncorrectData[index];

            const { validatedRow, isRowValid } = validateRow(row, role);
            // console.log("validated row: ", validatedRow);
            // console.log("Row's validity: ", isRowValid);
            if (isRowValid) {
                const normalizedRow = {};
                for (const key in validatedRow) {
                    if (key !== 'otherData') {
                        normalizedRow[key] = validatedRow[key].value;
                    } else {
                        normalizedRow[key] = validatedRow[key];  // Keep otherData unchanged
                    }
                }
                // console.log("Final Payload: ", normalizedRow);
                props.onUpdateUser(normalizedRow?.id, normalizedRow);
                updatedIncorrectData.splice(index, 1); // Remove from incorrectData
                setIncorrectData(updatedIncorrectData);
            } else {
                updatedIncorrectData[index] = validatedRow;
                setIncorrectData(updatedIncorrectData);
            }
            setLoading(false);
        }, 300), [incorrectData]
    );

    const handleFieldChange = (index, key, value) => {
        const updatedIncorrectData = [...incorrectData];
        updatedIncorrectData[index][key].value = value;
        setIncorrectData(updatedIncorrectData);
    };

    const handleBlur = (index, key) => {
        debouncedHandleFieldBlur(index, key);
    };

    // Memoizing displayData for pagination
    const displayData = useMemo(() => incorrectData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    ), [incorrectData, currentPage, rowsPerPage]);

    return (
        <Box style={{ width: '100%' }}>
            {loading && (
                <YuvaLoader setShow={false} show={loading} />
            )}
            {incorrectData?.length !== 0 && (
                <>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {mappedHeaders
                                        .filter(header => header.destination && header.destination !== 'id')
                                        .map((header, index) => (
                                            <TableCell key={index}>
                                                {header.destination.replace('_', ' ').toUpperCase()}
                                            </TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {displayData.map((row, index) => {
                                    const filteredRow = Object.fromEntries(
                                        Object.entries(row).filter(([key]) => key !== 'id')
                                    );
                                    // Calculate the correct index in the entire dataset
                                    const actualIndex = (currentPage - 1) * rowsPerPage + index;
                                    return (
                                        <MemoizedTableRow
                                            key={actualIndex}
                                            row={filteredRow}
                                            index={actualIndex} // Pass the correct index to the handler
                                            handleFieldChange={handleFieldChange}
                                            handleBlur={handleBlur}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box mt={2} display="flex" justifyContent="center">
                        <Pagination
                            count={Math.ceil(incorrectData.length / rowsPerPage)}
                            page={currentPage}
                            onChange={(e, page) => setCurrentPage(page)}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default SpreadsheetView;