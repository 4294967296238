import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useCareerContext } from "contexts/CareerContext";
import TestCards from "./TestCards";
import CareerAdviceSection from "./CareerAdviceSection";
import QuestionsSection from "./QuestionsSection";
import ClassCardView from "./ClassCardView";
import StudentListView from "./StudentListView";
import ResultView from "./ResultView";
import YuvaLoader from "pages/Forum/components/Loader/YuvaLoader";
import CompleteRiasec from "./CompleteRaisec";
import { useGlobalContext } from "global/context";
import { Popup } from "layout/Popup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { apiJson } from "api";

const RIASEC = () => {
	const { userData } = useGlobalContext();
	const {
		role,
		postAttempt,
		selectedNavOption,
		selectedSubNavOption,
		setSelectedSubNavOption,
		classInfo,
	} = useCareerContext();
	const [loading, setLoading] = useState(false);

	const handleTestCompletion = async (categoryScores) => {
		if (userData?.id) {
			setLoading(true);
			try {
				const res = await postAttempt(
					userData.id,
					userData.role,
					categoryScores
				);
				if (!res) {
					Popup(
						"error",
						"",
						"Error occurred while posting the attempt. Please try again.",
						3000
					);
					setSelectedSubNavOption("Test Cards"); // Redirect to Test Cards
				} else {
					Popup(
						"success",
						"Successfull Attempt!",
						"Test Attempt Saved SuccesFully",
						3000
					);
					try {
						const emailRes = await apiJson.post(
							`/api/v2/riasecTest/riasec-result-email/${userData.id}`
						);
						if (emailRes && emailRes.status === 200) {
							console.log("Email sent Successfully !!");
						}
					} catch (e) {
						console.log("Error occured during sending email !!", e);
					}
					setSelectedSubNavOption("View Result"); // Redirect to results page
				}
			} catch (error) {
				Popup(
					"error",
					"",
					"Error occurred while posting the attempt. Please try again.",
					3000
				);
				console.error("Error posting attempt:", error);
			} finally {
				setLoading(false);
			}
		}
	};
	const handleBackClick = () => {
		if (selectedSubNavOption === "Student's List") {
			setSelectedSubNavOption("Classes");
		} else {
			setSelectedSubNavOption("Student's List");
		}
	};

	const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

	// Update `isMobile` state on window resize
	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth < 900);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: "end",
					position: "relative",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						gap: 2,
						alignItem: "center",
						backgroundColor: "#2356fe",
						marginTop: "20px",
						paddingInline: "4rem",
						borderTopLeftRadius: "2rem",
						borderBottomLeftRadius: "2rem",
					}}
				>
					<Typography
						component={"h1"}
						sx={{
							fontSize: {
								xs: "1.3rem",
								sm: "2rem",
							},
							fontWeight: 600,
							padding: "1rem 0",
							color: "white",
						}}
					>
						<span style={{}}>{selectedNavOption}</span> - {selectedSubNavOption}{" "}
						{classInfo && selectedSubNavOption === "Student's List"
							? ` for ${classInfo.className}`
							: ""}
					</Typography>
					{/* {(role === "institute" || role == "teacher") &&
						selectedSubNavOption !== "Classes" &&
						isMobile && (
							<button
								onClick={handleBackClick}
								style={{
									paddingInline: "20px",
									paddingBlock: "5px",
									backgroundColor: "#6085FD",
									border: "1px solid #6085FD",
									borderRadius: "10px",
									position: "absolute",
									top: "60%",
									right: 5,
									transform: "translateY(-50%)",
								}}
							>
								<ArrowBackIcon
									sx={{
										fontSize: { xs: "1.5rem", sm: "2.5rem" },
										color: "white",
									}}
								/>
							</button>
						)} */}
				</Box>
			</div>

			<div
				style={{
					background: "linear-gradient(90deg, #6085FD , #DBE2F8 )",
					padding: "1rem",
					marginTop: "20px",
					borderRadius: "1.5rem",
				}}
			>
				<div
					style={{
						background: "white",
						padding: 5,
						borderRadius: "1rem",
					}}
				>
					{loading && <YuvaLoader setShow={false} show={loading} />}

					{role === "institute" || role == "teacher" ? (
						selectedSubNavOption === "Classes" ? (
							<ClassCardView />
						) : selectedSubNavOption === "Student's List" ? (
							<StudentListView />
						) : selectedSubNavOption === "View Result" ? (
							<ResultView />
						) : (
							<Typography>Invalid view for institution</Typography>
						)
					) : selectedSubNavOption === "Test Cards" ? (
						<TestCards
							handleTakeTest={() => setSelectedSubNavOption("Take test")}
							handleViewResults={() => setSelectedSubNavOption("View Result")}
							handleViewCareer={() =>
								setSelectedSubNavOption("Career Guidance")
							}
						/>
					) : selectedSubNavOption === "Take test" ? (
						<QuestionsSection onComplete={handleTestCompletion} />
					) : selectedSubNavOption === "View Result" ? (
						<CompleteRiasec
							handleTryAgain={() => setSelectedSubNavOption("Take test")}
						/>
					) : selectedSubNavOption === "Career Guidance" ? (
						<CareerAdviceSection />
					) : (
						<Typography>Invalid view for student</Typography>
					)}
				</div>
			</div>
		</>
	);
};

export default RIASEC;
