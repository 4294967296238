import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import RecommendIcon from "@mui/icons-material/Recommend";
import CommentIcon from "@mui/icons-material/Comment";
import TextField from "@mui/material/TextField";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SendIcon from "@mui/icons-material/Send";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router";
import { apiJson } from "api";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { apiAuth } from "api";
import { useGlobalContext } from "global/context";
import { useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import Carousel from 'react-bootstrap/Carousel';

const FBEventPostsComponent = ({ postData, render }) => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { userData } = useGlobalContext();
  const userName =
    userData?.role === "institute"
      ? userData?.institution_name
      : userData?.first_name;
  const userProfile =
    userData?.role === "institute" ? userData?.logo : userData?.profile;
  const userInstituteId =
    userData?.role === "institute" ? userData?.id : userData?.instituteId;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [commentOpen, setCommentOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [postFormData, setPostFormData] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [VideoId, setVideoId] = useState();
  const [comments, setComments] = useState([]);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [isPostLike, setIsPostLike] = useState(false);
  const [commentData, setCommentData] = useState("");

  const checkPostLike = () => {
    apiJson(
      `api/v2/events/checkPostLike/${postData?.id}/${userData?.id}/${userData?.role}`
    )
      .then((res) => {
        setIsPostLike(res?.data?.result===0?false:true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    checkPostLike();
    setPostFormData({
      text: postData?.text,
      img: postData?.img,
    });
  }, []);

  const presetFormData = () => {
    setPostFormData({
      text: postData?.text,
      img: postData?.img,
    });
  };

  const setDatas = (name, value) => {
    // name?.text;
    setPostFormData({ ...postFormData, [name]: value });
  };

  const getAllComments = () => {
    apiJson(`api/v2/events/getAllCommentsByPost/${postData?.id}`)
      .then((res) => {
        setComments(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getImageLink = (e) => {
    const file = e.target.files[0];
    apiAuth
      .post("v2/register/uploadInstituteLogo", { img: file })
      .then((res) => {
        setDatas("img", res.data.result);
      })
      .catch((err) => {
        // toast.dismiss();
        // toast.error("Internal Server Error");
      });
  };
  const handleCommentBox = () => {
    setCommentOpen((prev) => !prev);
  };

  const deleteComment = async (commentId)=>{
    try{
      await apiJson.delete(`api/v2/events/deleteComment/${commentId}`)
      .then((res)=>{
        getAllComments();
        // setCommentOpen((pre)=>!prev)
      })
    }catch(err){
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (commentOpen) {
      getAllComments();
    }
  }, [commentOpen]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const opened = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleLike = () => {
    setIsPostLike(prev=>{
      return !prev
    })

    const data = {
      userId: userData?.id,
      role: userData?.role,
      instituteId: userInstituteId,
      postId: postData?.id,
    };
    apiJson
      .post(`api/v2/events/addLikes`, data)
      .then((res) => {
        checkPostLike();
        render();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // =======================================post delete function=========================================

  const deletePost = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        apiJson
          .delete(`/api/v2/events/deleteEventPost/${postData?.id}`)
          .then((response) => {
            Swal.fire({
              title: "Deleted!",
              text: "Your post has been deleted.",
              icon: "success",
            });
            render();
          })
          .catch((error) => {
            console.error("Error deleting post:", error);
            Swal.fire({
              title: "Error",
              text: "An error occurred while deleting the post.",
              icon: "error",
            });
          });
      }
    });
  };

  // =============================================

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const upload = (e) => {
    setUploadedFileName(e.target.files[0].name);
    const file = e.target.files[0];

    if (file) {
      // Create a URL for the image preview
      const objectURL = URL.createObjectURL(file);
      setPostFormData(objectURL);
      setImagePreview(objectURL);
    }
  };
  const extractVideoId = (link) => {
    if (link === "") {
      setVideoId(null);
      return;
    }
    const regex =
      /(?:[?v=]|\/embed\/|\/\d\/|\/vi\/|youtu.be\/|\/v\/|\/e\/|\/embed\/|\/user\/|\/videos\/|\/channels\/[^\/]+\/|\/c\/[^\/]+\/|\/user\/[^\/]+\/|\/[a-zA-Z0-9_-]{11})[a-zA-Z0-9_-]{11}/;
    const match = link.match(regex);
    setVideoId(match[0].split("/")[1]);
    return match ? match[0].substr(-11) : null;
  };

  const handleSubmit = async () => {
    const data = {
      userId: userData?.id,
      role: userData?.role,
      instituteId:
        userData?.role === "institute" ? userData?.id : userData?.instituteId,
      eventId: Number(eventId),
      ...postFormData,
    };

    if (postFormData?.text || imagePreview) {
      try {
        await apiJson
          .put(`/api/v2/events/updateEventPost/${postData?.id}`, data)
          .then((res) => {
            setPostFormData({});
            setImagePreview("");
            render();
            setOpen(false);
          });
      } catch (err) {
        console.log(err.message);
      }
    } else {
      // alert("Please enter something");
    }
  };

  const addComment = async () => {
    const data = {
      userId: userData?.id,
      role: userData?.role,
      instituteId:
        userData?.role === "institute" ? userData?.id : userData?.instituteId,
      text: commentData,
      postId: postData?.id,
    };
    // alert(commentData)
    if (commentData) {
      try {
        await apiJson.post(`/api/v2/events/addComment`, data).then((res) => {
          getAllComments();
          setCommentData("");
        });
      } catch (err) {
        console.log(err.message);
      }
    } else {
      alert("please enter something");
    }
  };

  return (
    <div className="row w-100 mb-3">
      <div
        className="d-flex w-100 flex-column  border shadow rounded-3 p-2"
        style={{ background: "white" }}
      >
        <div className="d-flex gap-1 align-items-center mb-3">
          <Avatar src={postData?.profile} alt={postData?.name} />
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex flex-column p-0 m-0">
              <h5 className="fs-6 m-0 p-0">{postData?.name}</h5>
              <span
                style={{ color: "grey", fontSize: "12px" }}
                className="m-0 p-0"
              >
                {moment(postData.createdAt).fromNow()}
              </span>
            </div>

    {/* ================================hamberger for edit =========================== */}
            <div
              className="d-flex justify-content-center align-items-center gap-1 cursor-pointer"
              onClick={handleClick}
            >
              {userData?.role === postData?.role &&
              userData?.id === postData?.userId ? (
                <MoreVertIcon color="primary" />
              ) : null}
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={opened}
              onClose={handleClosed}
              sx={{ width: "180px" }}
            >
              <MenuItem
                onClick={() => {
                  handleClickOpen();
                  presetFormData();
                }}
              >
                Edit
              </MenuItem>
              <MenuItem onClick={deletePost}>Delete</MenuItem>
            </Menu>
          </div>
        </div>

        <div>
        {postData?.text && (
    <div>
      {postData.text.split('\n').map((paragraph, index) => (
        <p className="fs-6 ms-3" key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
      ))}
    </div>
  )}
         
        </div>

        <div
          style={{
            maxHeight: "336px",
          }}
        >

        <Carousel 
          pause={"hover"}
          indicators={false}
          controls={postData?.img && postData?.videoId ? true: false}
        >
          {
            postFormData?.img?
          <Carousel.Item>
            <div style={{height:"300px"}}>

            <img
            className="h-100 object-fit-contain rounded-top-4 w-100"
            src={postData?.img}
            alt="img"
            style={{zIndex:"-2"}}
          />
            </div>
        </Carousel.Item>
          : null
          }
          {postData?.videoId && (
          <Carousel.Item>
                  <iframe
                    width="100%"
                    height="300px"
                    src={`https://www.youtube.com/embed/${postData?.videoId}`}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
        </Carousel.Item>
                )}
        </Carousel>
          
        </div>

        <div
          className="d-flex justify-content-between align-itmes-center rounded-bottom-4 px-2 py-1"
          style={{ background: "#dfdfff" }}
        >
          <div className="m-0 p-0" style={{ width: "50%" }}>
            <p className="m-0 p-0 text-danger" style={{ fontSize: "15px" }}>
              {moment(postData?.eventStartDate).format("LLL")}
            </p>
            <h6 className="m-0 p-0">{postData?.eventTitle}</h6>
            <p className="m-0 p-0" style={{ fontSize: "15px" }}>
              By {postData?.eventBy}
            </p>
            <p className="m-0 p-0" style={{ fontSize: "15px" }}>
              {postData?.interested} Interested
            </p>
          </div>
          <div className="m-0 p-0 d-flex justify-content-between align-items-center">

            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                navigate(`/new-dashboard/eventsfb/${postData?.eventId}`)
              }
              className="text-capitalize fw-semibold py-2 rounded-3 "
            >
              <StarBorderIcon /> Interested
            </Button>
          </div>
        </div>

        {/* ------------------------like & comment======================== */}
        <div className="d-flex justify-content-between mt-1">
          {postData?.likes > 0 ? (
            <div className="ms-3">
              <span className="">
                <RecommendIcon color="primary" className="me-1" />
              </span>
              {/* Check if the user liked the post */}
              {isPostLike ? <span className="ms-1">You</span> : null}
              {/* Check if others liked the post */}
              {postData?.likes > 1 && isPostLike ? (
                <span className="ms-1">and {postData.likes - 1} others</span>
              ) :!isPostLike? <span className="ms-1">{postData?.likes}</span>:null}
            </div>
          ) : (
            <div></div>
          )}
          <div className="me-3 my-1">
            <span className="" style={{ color: "grey" }}>
              {postData?.comments}
              {postData?.comments > 1 ? "Comments" : "Comment"}
            </span>
          </div>
        </div>
        <div
          className="d-flex my-2"
          style={{
            borderBottom: "1px solid silver",
            borderTop: "1px solid silver",
          }}
        >
          <Button className="col-6" onClick={handleLike}>
            <div className=" text-center d-flex cursor-pointer">
              {isPostLike ? <ThumbUpIcon /> : <ThumbUpOffAltOutlinedIcon />}
              <span className="fs-6 fw-semibold ms-1 text-capitalize">
                Like
              </span>
            </div>
          </Button>
          <Button className="col-6" onClick={handleCommentBox}>
            <div className=" text-center  cursor-pointer">
              <CommentIcon />
              <span className="fs-6 fw-semibold text-capitalize me-1">
                {"Comment"}
              </span>
            </div>
          </Button>
        </div>

        <div className="d-flex gap-2 ">
          <Avatar src={userProfile} alt={userName} />
          <div className="d-flex " style={{ width: "80%" }}>
            <TextField
              id="outlined-textarea"
              placeholder={`Comment here`}
              multiline
              className="rounded-4 border-0 outline-0"
              sx={{ width: "100%" }}
              value={commentData}
              onKeyDown={(e) => (e.key === "Enter" ? addComment() : null)}
              onChange={(e) => setCommentData(e.target.value)}
            />
          </div>
          <div>
            <Button>
              <SendIcon className="fs-1" onClick={addComment} />
            </Button>
          </div>
        </div>

        {/* ===============comments================ */}
        {commentOpen && (
          <div className="overflow-auto" style={{ maxHeight: "300px" }}>
            {comments?.map((c) => {
              return (
                <>
                  <div className="d-flex justify-content-between rounded-2 gap-1 align-items-center my-2">
                    <div className="d-flex">
                      <Avatar src={c?.profile} alt="Avatar" />
                      <div className="d-flex flex-column align-items-start w-100">
                        <div
                          className="d-flex flex-column p-0 m-0 rounded-3 p-1"
                          style={{ background: "#1976d2" }}
                        >
                          <h5
                            className="m-0 p-0 text-white"
                            style={{ fontSize: "15px" }}
                          >
                            {c?.name}
                          </h5>
                          <p
                            className="m-0 p-0 text-white"
                            style={{ fontSize: "15px" }}
                          >
                            {c?.text}
                          </p>
                        </div>
                        <p className="" style={{ fontSize: "12px" }}>
                          {moment(c?.createdAt).fromNow()}
                        </p>
                      </div>
                    </div>
                    <span className="cursor-pointer" onClick={()=>deleteComment(c?.id)}>
                      {userData?.role === postData?.role &&
                      userData?.id === postData?.userId ? (
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      ) : null}
                    </span>
                  </div>
                </>
              );
            })}
          </div>
        )}
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const titile = formJson.text;
            const file = formJson.file;
            handleClose();
          },
        }}
      >
        <DialogTitle>Update Your Details</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Photos" value="1" />
                  <Tab label="Video" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="my-1">
                  <textarea
                    name="textarea"
                    placeholder="Enter Your Title"
                    value={postFormData?.text}
                    onChange={(e) => setDatas("text", e.target.value)}
                    className="p-1 rounded-3"
                    style={{ width: "100%", height: "100px" }}
                  ></textarea>
                </div>
                <div
                  className="my-1 border cursor-pointer position-relative rounded-3"
                  style={{ width: "100%", height: "200px" }}
                >
                  <input
                    type="file"
                    name="file"
                    id="file"
                    className="border border-success w-100 h-100 opacity-0 position-absolute top-0 z-2"
                    onChange={(e) => {
                      upload(e);
                      getImageLink(e);
                    }}
                  />
                  <div className="position-absolute cursor-pointer w-100 h-100 top-0 z-1 d-flex flex-column justify-content-center align-items-center">
                    {!postFormData?.img ? (
                      <>
                        <i class="fa-solid fa-photo-film text-success fs-2"></i>
                        <h4>Add Photos/videos</h4>
                      </>
                    ) : (
                      <img
                        src={postFormData?.img}
                        alt="image"
                        width="55%"
                        height="100%"
                      />
                    )}
                  </div>
                  <span
                    className="fs-3 cursor-pointer position-absolute bg-muted"
                    style={{ top: "-5%", right: "0%", zIndex:"10" }}
                    onClick={()=>{
                      setPostFormData({
                        text: postData?.text,
                        img: "",
                      });
                    }}
                  >
                    x
                  </span>
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="my-1">
                  <textarea
                    name="textarea"
                    placeholder="Enter Your Title"
                    value={postFormData?.text}
                    onChange={(e) => setDatas("text", e.target.value)}
                    className="p-1 rounded-3"
                    style={{ width: "100%", height: "100px", outline:"none", border:"none" }}
                  ></textarea>
                </div>
                <input
                  type="url"
                  name="url"
                  id="url"
                  className="w-100 p-2 mb-2 rounded-3"
                  onChange={(e) => {
                    setDatas("videoUrl", e.target.value);
                    extractVideoId(e.target.value);
                  }}
                  placeholder="Enter video Link"
                />
                {VideoId && (
                  <iframe
                    width="100%"
                    height="200px"
                    src={`https://www.youtube.com/embed/${VideoId}`}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FBEventPostsComponent;
