import { Button } from '@mui/joy';
import { apiJson } from 'api'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PendingIcon from '@mui/icons-material/Pending';
import TaskIcon from '@mui/icons-material/Task';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import RingVolumeIcon from '@mui/icons-material/RingVolume';

export const AdminInstituteCbseData = () => {
    const [cbseData, setCbseData]= useState([]);
    const navigate = useNavigate();

    const getAllCbseData =()=>{
        apiJson.get('api/v2/admin/getAllCbseDetail')
        .then((res)=>{
            setCbseData(res?.data?.cbseDetails)
        })
        .catch((err)=>{
            console.log(err.message);
        })
    }

    useEffect(()=>{
        getAllCbseData()
    },[])
  return (
      <div>
          <div className='col-10 mx-auto'>
            <div className='d-flex justify-content-between align-items-center mt-3'>
              <div className=''>
                  <span className='fs-3 fw-500 d-flex align-items-center'>CBSE Data <Button className='px-3' onClick={()=> {navigate('/admin/data-provider')}}>Next</Button></span>
              </div>
              <div><Button onClick={()=> {navigate('/admin/data-provider')}} className='' style={{background: "#da9eff"}}>Data Assign</Button></div>
              </div>

              <div className='row mt-3'>
                {
                    cbseData?.map((ele)=>{
                        return (
                            // <div>
                                <div key={ele?.id} onClick={()=> {navigate(`/admin/singleCbseData/${ele?.id}`)}} className='col-12 col-md-6 col-lg-4 rounded shadow-sm border-3 text-center mt-5 p-3 cursor-pointer'>
                                    <div className='mt-4'>
                                        <div><span className='fw-600 fs-6'>Institute Name: </span><span> {ele?.institution_name}</span></div>
                                    </div>
                                    <div>
                                        <div className='mt-2'><span className='fw-600 fs-6'>Affiliation: </span><span>{ele?.affliate_number}</span></div>
                                    </div>
                                    {/* <div>
                                        <div><span>{ele?.address}</span></div>
                                    </div> */}
                                    <div className='mb-4 mt-2'>
                                        <div><span className='fw-600 fs-6'>Email: </span><a href={`mailto:${ele.email}`}>{ele?.email}</a></div>
                                    </div>

                                    <div className='mt-2'>
                              <div><span className={`${ele?.call_status === 'connected' ? 'text-success' : 'text-danger'} fw-bold`}>{ele?.call_status === 'connected' 
                                ? <span>Call Connected  <CheckCircleIcon/></span>
                                : ele?.call_status === 'ringing'
                                  ? <span>Ringing  <RingVolumeIcon/></span>
                                : ele?.call_status === 'not interested'
                                  ? <span>Not Interested<RingVolumeIcon/></span>
                                : ele?.call_status === 'disconnected'
                                  ? <span>Call Disconnected  <PhoneDisabledIcon/></span>
                                  : ele?.call_status === 'wrongnumber'
                                    ?  <span>Call Wrong Number  <PhoneDisabledIcon/></span>
                                    : ele?.call_status === 'cancelled'
                                      ? <span>Call Cancelled  <PhoneDisabledIcon/></span>
                                      : ''}</span></div>
                                    </div>
                                    <div className='mt-2 fw-bold'>
                                        <div><span className={ele?.registration_status === 'done' ? 'text-success' : 'text-danger'}>{ele?.registration_status === 'done' ? <span>Registration Done <HowToRegIcon/></span> : ele?.registration_status === 'pending' ? <span>Registration Pending <PendingIcon/></span> : ''}</span></div>
                                    </div>
                                    <div className='mt-2 fw-bold'>
                                        <div><span className={ele?.detail_sent_status === 'sent' ? 'text-success' : 'text-danger'}>{ele?.detail_sent_status === 'sent' ?  <span>Details Sent <TaskIcon/></span> : ele?.detail_sent_status === 'notsent' ?  <span>Details Not Sent <AssignmentLateIcon/></span> : '' }</span></div>
                                    </div>
                                    <div className='mb-4 mt-2'>
                                        <div>{ele?.reminder_status ? <span className='fw-bold text-warning'>Reminder {ele?.reminder_status} <PendingActionsIcon/></span> : ""}</div>
                                    </div>
                                </div>
                            // </div>

                        )
                    })
                }
                {/* <div className='col-12 col-lg-4 rounded shadow-lg border-1 text-center'>
                    <div className='mt-3 mb-3'>
                        <div><span className=''>GOVT SR SEC SCHOOL</span></div>
                        <div><span>2520019</span></div>
                        <div><span>niteshinstitute1@gmail.com</span></div>
                    </div>
                </div>
                <div className='col-12 col-lg-4 rounded shadow-lg border-1 text-center'>
                    <div className='mt-3 mb-3'>
                        <div><span className=''>GOVT SR SEC SCHOOL</span></div>
                        <div><span>2520019</span></div>
                        <div><span>niteshinstitute1@gmail.com</span></div>
                    </div>
                </div>
                <div className='col-12 col-lg-4 rounded shadow-lg border-1 text-center'>
                    <div className='mt-3 mb-3'>
                        <div><span className=''>GOVT SR SEC SCHOOL</span></div>
                        <div><span>2520019</span></div>
                        <div><span>niteshinstitute1@gmail.com</span></div>
                    </div>
                </div> */}

              </div>
          </div>
      </div>
  )
}
