/* eslint-disable no-lone-blocks */
import React, { lazy } from "react";
import {
  AutoStoriesOutlined,
  BarChartOutlined,
  BookOnline,
  BookOutlined,
  CelebrationOutlined,
  CollectionsOutlined,
  ContactSupportOutlined,
  DashboardOutlined,
  EmailOutlined,
  EmojiEventsOutlined,
  FlagCircleOutlined,
  FormatQuoteOutlined,
  ForumOutlined,
  GroupOutlined,
  Groups2Outlined,
  LocalActivityOutlined,
  // Groups3Icon,
  MapOutlined,
  MarkEmailReadOutlined,
  MilitaryTechOutlined,
  NotificationAddOutlined,
  PeopleOutlineSharp,
  QuestionAnswer,
  QuestionAnswerOutlined,
  RssFeedOutlined,
  SchoolOutlined,
  SettingsOutlined,
  SourceOutlined,
  SubscriptionsOutlined,
  SupervisorAccountOutlined,
  TimelineOutlined,
  TrendingUpSharp,
} from "@mui/icons-material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import EventNoteIcon from "@mui/icons-material/EventNote";
import WebStoriesIcon from "@mui/icons-material/WebStories";
import Groups3OutlinedIcon from "@mui/icons-material/Groups3Outlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";

import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PersonAddTwoToneIcon from "@mui/icons-material/PersonAddTwoTone";
import ContactsTwoToneIcon from "@mui/icons-material/ContactsTwoTone";
import ContactMailTwoToneIcon from "@mui/icons-material/ContactMailTwoTone";
import PlaylistAddCircleTwoToneIcon from "@mui/icons-material/PlaylistAddCircleTwoTone";
import RuleFolderTwoToneIcon from "@mui/icons-material/RuleFolderTwoTone";
import GroupAddTwoToneIcon from "@mui/icons-material/GroupAddTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import Person3Icon from "@mui/icons-material/Person3";

// import Clubposts from 'pages/TimelineClubs/Clubposts';
import ClubPosts from "pages/TimelineClubs/ClubPosts";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "global/context";
// import Home from "pages/static/Home";
// import Team from "pages/static/Team";
import Error from "pages/Error";
import FaqPage from "pages/static/FaqPage";
import Press from "pages/static/Press/Press";
// import Modelg20Page from "pages/static/ModelG20Page";
// import Life from "pages/static/LifeEnvironment";
import CampusAmbass from "pages/static/Topics/CampusAmbass";
// import StartupIndia from "pages/static/Topics/StartupIndia";
// import SharedFuture from "pages/static/themes/SharedFuture";
// import HealthWell from "pages/static/themes/HealthWell";
// import FutureOfWork from "pages/static/themes/FutureOfWork";
// import CrossBorderInnovation from "pages/static/Topics/CrossBorderInnovation";
// import GigEconomy from "pages/static/Topics/GigEconomy";
import Presidency from "pages/static/Presidency";
// import MediaPage from "pages/events/MediaPage";
// import Youth from "pages/static/Topics/Youth";
import StatePage from "pages/static/States/StatePage";
import EventsScroll from "pages/events/components/EventsScroll";
import Goa from "pages/events/states/Goa";
import CommunityGuidliness from "pages/static/Topics/CommunityGuidliness";
import PCCAS from "pages/events/states/PCCAS";
import Uttarakhand from "pages/events/states/Uttarakhand";
import Haryana from "pages/events/states/Haryana";
import TermsConditions from "layout/TermsConditions";
import IndiaAchivements from "pages/static/Topics/IndiaAchivements";
import InfoIcon from "@mui/icons-material/Info";
// import DigitalTransform from "pages/static/Topics/DigitalTransform";
// import DynamicBlog from "pages/static/Blog/DynamicBlog";
// import SingleBlog from "pages/static/Blog/SingleBlog";
// import Cdri from "pages/static/Topics/Cdri";
// import DynamicNews from "pages/static/News/DynamicNews";
// import SingleNews from "pages/static/News/SingleNews";
// import WomenEmpower from "pages/static/Topics/WomenEmpower";
// import PeaceBuilding from "pages/static/themes/PeaceBuilding";
// import ClimateChange from "pages/static/themes/ClimateChange";
// import UnlearnRelearnReskill from "pages/static/Topics/UnlearnRelearnReskill";
// import CyberSafety from "pages/static/Topics/CyberSafety";
// import SustainableLiving from "pages/static/Topics/SustainableLiving";
// import Nip from "pages/static/Topics/Nip";
import InstituteBasicDetails from "pages/college/Editprofile/components/InstituteBasicDetails";
import StudentEditPassword from "pages/student/EditProfile/components/StudentEditPassword";
import InstituteEditPassword from "pages/college/Editprofile/components/InstituteEditPassword";
import InstituteAdditionalDetails from "pages/college/Editprofile/components/InstituteAdditionalDetails";
import InstituteEditInfrastructure from "pages/college/Editprofile/components/InstituteEditInfrastructure";
import YuvaLoader from "pages/Forum/components/Loader/YuvaLoader";
import StudentEditBasicDetails from "pages/student/EditProfile/components/StudentEditBasicDetails";
import AdditionalDetails from "pages/student/EditProfile/components/StudentEditAdditionalDetails";
import Gallery from "pages/Gallery/Gallery";
import GalleryView from "pages/Gallery/GalleryView";
import StudentEditPreference from "pages/student/EditProfile/components/StudentEditPreference";
import InstitutePublicPage from "pages/college/InstitutePublicPage";
import InstituteEnrolledUser from "pages/college/dashboard/InstituteEnrolledUser";
import InstituteCertifiedUser from "pages/college/dashboard/InstituteCertifiedUser";
import InstituteAffiliatedinstitute from "pages/college/InstituteAffiliatedinstitute";
import PrintCertificate from "pages/course/PrintCertificate/PrintCertificate";
import SingleInstitutesData from "pages/Admin/components/SingleInstitutesData";
import Forum from "pages/Forum/Forum";
import AllQuestions from "pages/Forum/components/Questions/AllQuestions";
import SingleForumPage from "pages/Forum/SingleForumPage";
import InstituteGallery from "pages/college/dashboard/components/InstituteGallery";
import StudentOpenRegister from "pages/Registration/student/StudentOpenRegister";
import InstituteRegister2 from "pages/Registration/InstituteRegister2";
import InstituteDelegates from "pages/college/dashboard/InstituteDelegates";
import StudentCoordinators from "pages/college/dashboard/StudentCoordinators";
import EventWinners from "pages/college/dashboard/EventWinners";
import ShowCertificate from "pages/course/PrintCertificate/ShowCertificate";
// import ContactUs from "pages/static/contactUs/ContactUs";
import AccountVerify from "pages/Auth/verify/AccountVerify";
import StudentDiscussionRoom from "pages/discussion/student/StudentDiscussionRoom";
import MeetingRoom from "pages/discussion/institute/MeetingRoom";
import InstituteScreen1 from "pages/discussion/institute/InstituteScreen1";
import StudentScreen1 from "pages/discussion/student/StudentScreen1";
import StudentBoard from "pages/discussion/student/StudentBoard";
import InstituteBoard from "pages/discussion/institute/InstituteBoard";
import MainDashboardContent from "pages/student/dashboardtabs/MainDashboardContent";
import InstituteMainTab from "pages/college/dashboard/InstituteMainTab";
import SinglePost from "pages/EventTimeline/SinglePost";
import InstitutePosts from "pages/EventTimeline/InstitutePosts";
import Posts from "pages/EventTimeline/Posts";
import ClickProfile from "pages/EventTimeline/ClickProfile";
import AdminUserManagement from "pages/Admin/pages/AdminUserManagement";
import EditDetailPageInstitute from "pages/Admin/users/EditDetailPageInstitute";
import EditDetailPageStudent from "pages/Admin/users/EditDetailPageStudent";
import InstituteDataTable from "pages/Admin/users/InstituteDataTable";
import StudentDataTable from "pages/Admin/users/StudentDataTable";
import WebsiteContent from "pages/Admin/pages/websitecontent/WebsiteContent";
import AdminIssuesCertificates from "pages/Admin/pages/AdminIssuesCertificates";
import AdminAnalytics from "pages/Admin/pages/analytics/AdminAnalytics";
import QuotesDataTable from "pages/Admin/components/QuotesDataTable";
import Blogs from "pages/Admin/pages/websitecontent/Blogs";
import AddBlog from "pages/Admin/pages/websitecontent/AddBlog";
import EditBlog from "pages/Admin/pages/websitecontent/EditBlog";
import News from "pages/Admin/pages/websitecontent/News";
import AddNews from "pages/Admin/pages/websitecontent/AddNews";
import EditNews from "pages/Admin/pages/websitecontent/EditNews";
import States from "pages/Admin/pages/States";
import AdminCourses from "pages/Admin/course/AdminCourses";
import AdminCoursesSection from "pages/Admin/course/AdminCoursesSection";
import AdminCoursesSectionVideo from "pages/Admin/course/AdminCoursesSectionVideo";
import SingleState from "pages/Admin/pages/SingleState";
import StudentPoll from "pages/Admin/pages/websitecontent/StudentPoll";
import Contact from "pages/Admin/pages/Contact";
import QuizQuestions from "pages/Admin/course/QuizQuestions";
import GalleryControl from "pages/Admin/pages/websitecontent/GalleryControl";
import Notification from "pages/Admin/pages/Notification";
import SocialWall from "pages/college/dashboard/components/SocialWall";
import ForgetPassword from "pages/Auth/reset/ForgetPassword";
import EmailDeliverable from "pages/Admin/email/EmailDeliverable";
import EmailList from "pages/Admin/email/EmailList";
import InstituteEditEvent from "pages/college/Editprofile/components/InstituteEditEvent";
import ClubSinglePost from "pages/TimelineClubs/components/ClubSinglePost";
import ClubProfile from "pages/TimelineClubs/ClubProfile";
import {
  OnBoardingComponent,
  OnBoardingInstituteComponent,
  OnBoardingMainPage,
  OnBoardingStudentComponent,
  OnboardingComponentVerify,
  OnboardingGoogleVerify,
} from "pages/OnBoarding";
import { OnBoardingStudentApp } from "pages/OnBoarding/Component/OnBoardingStudentApp";
import {
  ModelUNMain,
  ModelUnEventManage,
  ModelUnMainComponent,
} from "pages/modelUN";
import { InstituteRegisteredUserV2 } from "views/institute";
import {
  ModelUnManuallyAssign,
  MunMain,
  MunParticipants,
} from "pages/Model-UN/components";
import { MUNParentApp } from "pages/Model-UN/components/MUNParentApp";
import { UI2MainDashboard } from "pages/UI-2.0-Dashboard";
import {
  ParliamentEventRegister,
  ParliamentMain,
} from "pages/ModelUnParliament/component";
import { HackathonEventRegister, HackathonMain } from "pages/ModelUnHackathon";

import { CarbonParentApp } from "pages/CarbonFootprintCalculator/components/CarbonParentApp";
// import Home from "pages/LandingPage/Components/Home";
import {
  DiscussionBoardChatRooms,
  DiscussionBoardCreateDiscussion,
  DiscussionBoardMain,
} from "pages/discussionBoard/parentComp";
import { ModelUnTeamRegist } from "pages/modelUN/component/ModelUnTeamRegist";
import { MunApplicants } from "pages/Model-UN/components/MunApplicants";
import {
  CourseOutlet,
  StudentAllCouses,
  StudentCourseOverView,
  StudentCoursePlayerContainer,
  StudentMyCourses,
} from "pages/courses";
import { StudentCourseScreen } from "pages/courses/component";
import { UsersUnderReview } from "pages/UnderReview";
// import Home from "pages/LandingPage/Components/Home";

import AdminInstituteUnderReview from "pages/Admin/pages/AdminInstituteUnderReview";
import { ModelUnDashboard } from "pages/Model-UN/components/ModelUnDashboard";
import TwentyOneDaysChallengeHome from "pages/twentyOneDaysChallenge/Components/Home";

import ChallengeDays from "pages/twentyOneDaysChallenge/Components/ChallengeDays";
import Congress from "pages/twentyOneDaysChallenge/Components/Congress";
import AdminCarbonFootprintCalculator from "pages/Admin/pages/AdminCarbonFootprintCalculator";
import { Admin21DaysChallenge } from "pages/Admin/pages/Admin21DaysChallenge";
import Main from "pages/twentyOneDaysChallenge/Components/Main";
import GameDay from "pages/twentyOneDaysChallenge/Components/GameDay";
import TodayChallenCompoent from "pages/twentyOneDaysChallenge/Components/TodayChallenCompoent";
import { Admin75DaysChallenge } from "pages/Admin/pages/Admin75DaysChallenge";
// import About from 'pages/LandingPage/pages/About';
import Career from "pages/LandingPage/Components/FooterPages/Careers";
import SeventyFiveMain from "pages/seventyFiveDaysGame/Components/Main";
import SeventyFiveChallengeDays from "pages/seventyFiveDaysGame/Components/ChallengeDays";
import SeventyFiveTodayChallenComponent from "pages/seventyFiveDaysGame/Components/TodayChallenCompoent";
import SeventyFiveCongress from "pages/seventyFiveDaysGame/Components/Congress";

import { AddExecutiveBoard } from "pages/modelUN/component/AddExecutiveBoard";
import { AddOrganistionComm } from "pages/modelUN/component/AddOrganistionComm";
import { JudgingAndJury } from "pages/modelUN/component/JudgingAndJury";
import { Teacher_FullAccess } from "pages/modelUN/component/Teacher_FullAccess";
import { Teacher_LimitedAccess } from "pages/modelUN/component/Teacher_LimitedAccess";
import BakeryDiningOutlinedIcon from "@mui/icons-material/BakeryDiningOutlined";
import EscalatorOutlinedIcon from "@mui/icons-material/EscalatorOutlined";

import AuthV2VerifyEmailPassReset from "pages/Auth/Version2 Password Reset Comp/AuthV2VerifyEmailPassReset";
import AuthV2PassReset from "pages/Auth/Version2 Password Reset Comp/AuthV2PassReset";
// import { DownloadBackground, DownloadEventCollaterals, DownloadFormats } from 'pages/DownloadResources/components';

import { UI2DashboardClub } from "pages/UI-2.0-Dashboard/component";
import { Add_Account_manager } from "pages/manageAccountManager/Add_Account_manager";
import { AdminRatings } from "pages/Admin/pages/AdminRatings";
import { AdminInstituteDetails } from "pages/Admin/pages/AdminInstituteDetails";
import { AdminAllInstitute } from "pages/Admin/pages/AdminAllInstitute";
import CarbonLatestApp from "pages/CarbonFootprintCalculator/components/CarbonLatestApp";
import { StudentEditProfileView } from "pages/student/EditProfile/components/StudentEditProfileView";
import { LifeAtYuvamanthan } from "pages/static/lifeatyuvamanthan/LifeAtYuvamanthan";
import InstituteEditPrefrence from "pages/college/Editprofile/components/InstituteEditPrefrence";
import ParantStudentCreateDiscussion from "pages/discussionBoard/parentComp/ParantStudentCreateDiscussion";
import AffiliateInstitute from "pages/AffliateInstitute/AffiliateInstitute";
import UploadCSV from "components/UploadCSV";
import Ministries from "pages/LandingPage/Components/Ministries";
import { AdminAllnipamInstitute } from "pages/Admin/pages/AdminAllnipamInstitute";
import MediaCoverage from "pages/LandingPage/Components/MediaCoverage";
import { AdminInstituteCbseData } from "pages/Admin/pages/AdminInstituteCbseData";
import { AdminInstituteCbseSingleData } from "pages/Admin/pages/AdminInstituteCbseSingleData";
import { AdminDataProvider } from "pages/Admin/pages/AdminDataProvider";
import { SubAdminStateData } from "pages/Admin/pages/SubAdmin/SubAdminStateData";
import { SubAdminSingleStateData } from "pages/Admin/pages/SubAdmin/SubAdminSingleStateData";
import { SubAdminAssginData } from "pages/Admin/pages/SubAdmin/SubAdminAssginData";
import Unsubscribe from "pages/Unsubscribe";
import Compliance from "pages/compliance/Compliance";
import ComplianceResult from "pages/compliance/ComplianceResult";
import AttemptsTable from "pages/compliance/components/AttemptsTable";
import { AdminCallingStatusData } from "pages/Admin/pages/AdminCallingStatusData";
import { ViewerDashboard } from "pages/Admin/pages/Viewer/ViewerDashboard";
import { ViewerCallStatus } from "pages/Admin/pages/Viewer/ViewerCallStatus";
import { ViewerFollowUp } from "pages/Admin/pages/Viewer/ViewerFollowUp";
import CourseViewUpdated from "pages/course/CourseViewUpdated";
import NewNip from "pages/nipam/NewNip";
import Greencard from "pages/greenCard/Greencard";
import { CareerProvider } from "contexts/CareerContext";
import CareerDashboard from "pages/UI-2.0-Dashboard/component/CareerDashBoard/CareerDashboard";
import { HealthPage } from "pages/UI-2.0-Dashboard/component/Health/HealthPage";
import InstituteRegisteredUser from "pages/college/dashboard/InstituteRegisteredUser";
import ReportManagerAdminTeacher from "pages/college/dashboard/ReportManagerAdminTeacher";
import SewaPage from "pages/UI-2.0-Dashboard/component/SewaPage/SewaPage";
import EmailDeleteByAdmin from "pages/Admin/EmailDeleteByAdmin/EmailDeleteByAdmin";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InstituteCsvDetails from "components/InstituteCsvCheck/InstituteCsvDetails";
import AdminAssignCSV from "pages/Admin/pages/AssignCSV/AdminAssignCSV";
import SubAdminAssingedInstitutes from 'pages/Admin/pages/SubAdmin/HandleCSV/SubAdminAssingedInstitutes';
import { SubAdminCSVProvider } from "contexts/SubAdminCSVContext";
import SubAdminHandleCSV from "pages/Admin/pages/SubAdmin/HandleCSV/SubAdminHandleCSV";
// import SewaPage from 'pages/UI-2.0-Dashboard/component/SewaPage/SewaPage/SewaPage';

// import RiasecAdmin from 'pages/RIASEC/components/RiasecAdmin';

const FBEventMyEvent = React.lazy(
  async () => await import("pages/FBEvent/FBEventMyEvent")
);
const RiasecScore = React.lazy(
  async () => await import("pages/RIASEC/components/RiasecScore")
);
const CarbonLearderboard = React.lazy(
  async () =>
    await import(
      "pages/CarbonFootprintCalculator/components/CarbonLearderboard"
    )
);

const FBEventDetails = React.lazy(
  async () => await import("pages/FBEvent/FBEventDetails")
);
const FBEvent = React.lazy(async () => await import("pages/FBEvent/FBEvent"));

const Ytvideos = React.lazy(
  async () => await import("pages/LandingPage/Components/Ytvideos")
);

const EventGlimpses = React.lazy(
  async () => await import("pages/LandingPage/Components/EventGlimpses")
);
const RiasecAdmin = React.lazy(
  async () => await import("pages/RIASEC/components/RiasecAdmin")
);

const RiasecResult = React.lazy(
  async () => await import("pages/RIASEC/components/RiasecResult")
);

const RiasecTest = React.lazy(
  async () => await import("pages/RIASEC/RiasecTest")
);

const CarbonLatestAppResult = React.lazy(
  async () =>
    await import(
      "pages/CarbonFootprintCalculator/components/CarbonLatestAppResult"
    )
);

const CarbonLatestParentApp = React.lazy(
  async () =>
    await import(
      "pages/CarbonFootprintCalculator/components/CarbonLatestParentApp"
    )
);
const CourseDetails = React.lazy(
  async () => await import("pages/course/CourseDetails")
);
const Search = React.lazy(async () => await import("pages/search/Search"));
const CourseViewPage = React.lazy(
  async () => await import("pages/course/CourseViewPage")
);

const AllCourses = React.lazy(
  async () => await import("pages/course/AllCourses")
);
const Certificate = React.lazy(
  async () => await import("pages/course/certificate/Certificate")
);

const DiscussionLeaderBoard = React.lazy(
  async () => await import("pages/discussionBoard/DiscussionLeaderBoard")
);
const DiscussionBoardJury = React.lazy(
  async () => await import("pages/discussionBoard/DiscussionBoardJury")
);
const DiscussionBoardTopPointsList = React.lazy(
  async () => await import("pages/discussionBoard/DiscussionBoardTopPointsList")
);
const EventReportWithGallary = React.lazy(
  async () =>
    await import("pages/UI-2.0-Dashboard/component/EventReportWithGallary")
);

const DownloadBackground = React.lazy(
  async () =>
    await import("pages/DownloadResources/components/DownloadBackground")
);
const DownloadEventCollaterals = React.lazy(
  async () =>
    await import("pages/DownloadResources/components/DownloadEventCollaterals")
);
const DownloadFormats = React.lazy(
  async () => await import("pages/DownloadResources/components/DownloadFormats")
);
const Home = React.lazy(
  async () => await import("pages/LandingPage/Components/Home")
);
const About = React.lazy(
  async () => await import("pages/LandingPage/pages/About")
);
const AboutYMUN = React.lazy(
  async () => await import("pages/LandingPage/pages/ymun/AboutYMUN")
);
const AboutYMH = React.lazy(
  async () => await import("pages/LandingPage/pages/ymh/AboutYMH")
);
const AboutYMYP = React.lazy(
  async () => await import("pages/LandingPage/pages/ymyp/AboutYMYP")
);

const ContactUs = React.lazy(
  async () => await import("pages/static/contactUs/ContactUs")
);
const Youth = React.lazy(async () => await import("pages/static/Topics/Youth"));
const MediaPage = React.lazy(
  async () => await import("pages/events/MediaPage")
);
const Team = React.lazy(async () => await import("pages/static/Team"));
const Modelg20Page = React.lazy(
  async () => await import("pages/static/ModelG20Page")
);
const ClimateChange = React.lazy(
  async () => await import("pages/static/themes/ClimateChange")
);
const Life = React.lazy(
  async () => await import("pages/static/LifeEnvironment")
);
const FutureOfWork = React.lazy(
  async () => await import("pages/static/themes/FutureOfWork")
);
const PeaceBuilding = React.lazy(
  async () => await import("pages/static/themes/PeaceBuilding")
);
const HealthWell = React.lazy(
  async () => await import("pages/static/themes/HealthWell")
);
const SharedFuture = React.lazy(
  async () => await import("pages/static/themes/SharedFuture")
);
const Nip = React.lazy(async () => await import("pages/static/Topics/Nip"));
const StartupIndia = React.lazy(
  async () => await import("pages/static/Topics/StartupIndia")
);
const DigitalTransform = React.lazy(
  async () => await import("pages/static/Topics/DigitalTransform")
);
const SustainableLiving = React.lazy(
  async () => await import("pages/static/Topics/SustainableLiving")
);
const Cdri = React.lazy(async () => await import("pages/static/Topics/Cdri"));
const WomenEmpower = React.lazy(
  async () => await import("pages/static/Topics/WomenEmpower")
);
const UnlearnRelearnReskill = React.lazy(
  async () => await import("pages/static/Topics/UnlearnRelearnReskill")
);
const CyberSafety = React.lazy(
  async () => await import("pages/static/Topics/CyberSafety")
);
const GigEconomy = React.lazy(
  async () => await import("pages/static/Topics/GigEconomy")
);
const CrossBorderInnovation = React.lazy(
  async () => await import("pages/static/Topics/CrossBorderInnovation")
);
const Nipam = React.lazy(async () => await import("pages/nipam/Nipam"));
const DynamicBlog = React.lazy(
  async () => await import("pages/static/Blog/DynamicBlog")
);
const SingleBlog = React.lazy(
  async () => await import("pages/static/Blog/SingleBlog")
);
const DynamicNews = React.lazy(
  async () => await import("pages/static/News/DynamicNews")
);
const SingleNews = React.lazy(
  async () => await import("pages/static/News/SingleNews")
);

const AdminCompliance = lazy(() => import("pages/Admin/AdminCompliance"));
const AdminComplianceQuestion = lazy(() =>
  import("pages/Admin/pages/AdminComplianceQuestion")
);
const AdminComplianceCategory = lazy(() =>
  import("pages/Admin/pages/AdminComplianceCategory")
);

const PublicOnlyRoute = ({ component }) => {
  const { token, userData } = useGlobalContext();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (token) {
      navigate("/new-dashboard"); // dashboard => check(onboard.status) => true(navigate(dashboard)) => false(navigate(institute-dashboard))
    } else if (userData.token && userData.role === "institute") {
      // if(token && role==='institute' && on-board-status===false)
      navigate("/institute-on-boarding");
    }
  }, []);
  return <>{!token ? component : <YuvaLoader space={true} />}</>;
};
const PrivateRoute = ({ component, roles, subroles }) => {
  const navigate = useNavigate();
  const { userData, token } = useGlobalContext();

  if (!token) {
    navigate("/login");
  }

  if (
    token &&
    userData.role === "institute" &&
    userData.onBoardStatus === false
  ) {
    navigate("/institute-on-boarding");
  }

  if (
    token &&
    userData.role === "student" &&
    userData.onBoardStatus === false
  ) {
    navigate("/student-on-boarding");
  }

  if (
    token &&
    userData.role === "teacher" &&
    userData.onBoardStatus === false
  ) {
    navigate("/teacher-on-boarding");
  }

  React.useEffect(() => {
    if (
      !(
        roles.includes(userData?.role) ||
        ((userData?.role === "teacher" || userData?.role === "student") &&
          subroles?.some((role) => userData?.subRoles?.includes(role)))
      )
    ) {
      navigate("/");
    }
  }, []);

  return (
    <>
      {roles.includes(userData?.role) ||
      ((userData?.role === "teacher" || userData?.role === "student") &&
        subroles?.some((role) => userData?.subRoles?.includes(role))) ? (
        component
      ) : (
        <YuvaLoader />
      )}
    </>
  );
};

// const VerifyRoute = ({ component }) => {
//   const { token } = useGlobalContext();
//   const navigate = useNavigate();
//   React.useEffect(() => {
//     if (!token) {
//       navigate("/on-boarding");
//     }
//   }, []);
//   return <>{token ? component : <YuvaLoader space={true} />}</>;
// };
const useRoutes = (props) => {
  const { userData } = useGlobalContext();
  const navigate = useNavigate();
  const userTypeChecker = (
    studentComponent,
    instituteComponent,
    AdminComponent
  ) => {
    if (userData?.onBoardStatus === false || userData?.reviewStatus === false) {
      if (userData.role === "institute") {
        // window.location.href = "/institute-on-boarding";
        navigate("/institute-on-boarding");
      } else if (userData.role === "student") {
        {
          // window.location.href = "/student-on-boarding";
          navigate("/student-on-boarding");
        }
      } else if (userData.role === "teacher") {
        {
          // window.location.href = "/teacher-on-boarding";
          navigate("/teacher-on-boarding");
        }
      } else if (userData.role === "teacher") {
        {
          window.location.href = "/teacher-on-boarding";
        }
      }
      return;
    }
    switch (Number(userData.type)) {
      case 0:
      case 4:
      case 5:
        return studentComponent ? studentComponent : <Error />;
      case 1:
        return instituteComponent ? instituteComponent : <Error />;
      case 2:
        return AdminComponent ? AdminComponent : <Error />;
      default:
        return <Error />;
    }
  };

  const protectedTypeChecker = (
    studentComponent,
    instituteComponent,
    AdminComponent,
    subRoles
  ) => {
    if (userData?.onBoardStatus === false || userData?.reviewStatus === false) {
      if (userData.role === "institute") {
        // window.location.href = "/institute-on-boarding";
        navigate("/institute-on-boarding");
      } else if (userData.role === "student") {
        {
          // window.location.href = "/student-on-boarding";
          navigate("/student-on-boarding");
        }
      } else if (userData.role === "teacher") {
        {
          // window.location.href = "/teacher-on-boarding";
          navigate("/teacher-on-boarding");
        }
      } else if (userData.role === "teacher") {
        {
          window.location.href = "/teacher-on-boarding";
        }
      }
      return;
    }
    switch (Number(userData.type)) {
      case 0:
      case 4:
      case 5:
        if (
          subRoles &&
          subRoles?.some((role) => userData?.subRoles?.includes(role))
        ) {
          return studentComponent;
        } else {
          return <Error />;
        }
      case 1:
        return instituteComponent ? instituteComponent : <Error />;
      case 2:
        return AdminComponent ? AdminComponent : <Error />;
      default:
        return <Error />;
    }
  };

  const publicRoutes = [
    { path: "/", component: <Home /> },
    { path: "/career", component: <Career /> },
    { path: "/contactus", component: <ContactUs /> },
    { path: "/team", component: <Team /> },
    { path: "/lifeAtYuvamanthan", component: <LifeAtYuvamanthan /> },
    { path: "/faq", component: <FaqPage /> },
    { path: "/press-and-media", component: <Press /> },
    { path: "/model-g20", component: <Modelg20Page /> },
    { path: "/life", component: <Life /> },
    { path: "/googleVerify", component: <OnboardingGoogleVerify /> },
    { path: "/g20-campus-sherpa", component: <CampusAmbass /> },
    { path: "/startup-india", component: <StartupIndia /> },
    { path: "/shared-future", component: <SharedFuture /> },
    { path: "/health-well-being-and-sports", component: <HealthWell /> },
    { path: "/future-of-work", component: <FutureOfWork /> },
    { path: "/cross-border-innovation", component: <CrossBorderInnovation /> },
    { path: "/gig-economy", component: <GigEconomy /> },
    { path: "/g20-presidency", component: <Presidency /> },
    { path: "/media", component: <MediaPage /> },
    { path: "/youth-community", component: <Youth /> },
    { path: "/states/goa", component: <StatePage /> },
    { path: "/goa", component: <Goa /> },
    { path: "/goa/pccas", component: <PCCAS /> },
    { path: "/uttarakhand", component: <Uttarakhand /> },
    { path: "/haryana", component: <Haryana /> },
    { path: "/events", component: <EventsScroll /> },
    { path: "/social-media-guideliness", component: <CommunityGuidliness /> },
    { path: "/terms-conditions", component: <TermsConditions /> },
    { path: "/achievements-of-india", component: <IndiaAchivements /> },
    { path: "/digital-transform", component: <DigitalTransform /> },
    { path: "/blog", component: <DynamicBlog /> },
    { path: "/blog/:slug", component: <SingleBlog /> },
    { path: "/cdri", component: <Cdri /> },
    { path: "/news", component: <DynamicNews /> },
    { path: "/news/:slug", component: <SingleNews /> },
    { path: "/woman-empowerment", component: <WomenEmpower /> },
    { path: "/peacebuilding-and-reconciliation", component: <PeaceBuilding /> },
    { path: "/yt-videos", component: <Ytvideos /> },
    { path: "/EventGlimpses", component: <EventGlimpses /> },

    //New pages 2.0
    { path: "/about", component: <About /> },
    { path: "/unsubscribe", component: <Unsubscribe /> },
    { path: "/ymun", component: <AboutYMUN /> },
    { path: "/ymh", component: <AboutYMH /> },
    { path: "/ymyp", component: <AboutYMYP /> },
    { path: "/nipam", component: <Nipam /> },
    { path: "/newnip", component: <NewNip /> },
    { path: "/affiliate-institute", component: <AffiliateInstitute /> },
    { path: "/partnerships", component: <Ministries /> },
    { path: "/media-coverage", component: <MediaCoverage /> },
    {
      path: "/v2/auth/password-reset/email/verify",
      component: <AuthV2VerifyEmailPassReset />,
    },
    {
      path: "/v2/auth/password-reset",
      component: <AuthV2PassReset />,
    },

    {
      path: "/climate-change-and-disaster-risk-reduction",
      component: <ClimateChange />,
    },
    {
      path: "/unlearn-relearn-and-reskill",
      component: <UnlearnRelearnReskill />,
    },
    { path: "/cyber-safety-for-youth", component: <CyberSafety /> },
    {
      path: "/transitioning-to-sustainable-living",
      component: <SustainableLiving />,
    },
    { path: "/nep", component: <Nip /> },
    { path: "/gallery", component: <Gallery /> },
    { path: "/gallery/:id", component: <GalleryView /> },
    { path: "/courses", component: <AllCourses /> },
    { path: "/course-view", component: <CourseViewUpdated /> },
    { path: "/course/detail/:slug", component: <CourseDetails /> }, // for Admin
    { path: "/course/overview/:courseId", component: <CourseViewPage /> }, // for student
    { path: "/courses/:slug", component: <CourseDetails /> },
    {
      path: "/courses/:slug/certificates/:certkey",
      component: <ShowCertificate />,
    },
    { path: "/search/:query", component: <Search /> },
    // { path: "/modelUn", component: <MunMain /> },
    {
      path: "/institute/:slug/:user",
      component: <PublicOnlyRoute component={<InstitutePublicPage />} />,
    },
    {
      path: "/:club/:slug",
      component: <PublicOnlyRoute component={<InstitutePublicPage />} />,
    },
    // {
    //   path: "/registration",
    //   component: <PublicOnlyRoute component={<InstituteRegister2 />} />,
    // },
    {
      path: "/registration/:user",
      component: <PublicOnlyRoute component={<StudentOpenRegister />} />,
    },
    {
      path: "/:state/registration",
      component: <PublicOnlyRoute component={<InstituteRegister2 />} />,
    },

    { path: "/forget-password", component: <ForgetPassword /> },
    { path: "/auth/account-verify/:token", component: <AccountVerify /> },
    {
      path: "/auth/email-verify/:token",
      component: <OnboardingComponentVerify />,
    },

    { path: "*", component: <Error /> },
  ];

  //new v2 auth routes/ on-boarding
  const v2AuthPublicRoutes = [
    {
      path: "/login",
      component: <PublicOnlyRoute component={<OnBoardingMainPage />} />,
      children: [
        {
          index: true,
          element: <PublicOnlyRoute component={<OnBoardingComponent />} />,
        },
      ],
    },

    {
      path: "/registration",
      component: <PublicOnlyRoute component={<OnBoardingMainPage />} />,
      children: [
        {
          index: true,
          element: <PublicOnlyRoute component={<OnBoardingComponent />} />,
        },
      ],
    },
    // {
    //   path: "/nipam",
    //   component: <PublicOnlyRoute component={<OnBoardingMainPage />} />,
    //   children: [
    //     {
    //       index: true,
    //       element: <PublicOnlyRoute component={<OnBoardingComponent />} />,
    //     },
    //   ],
    // },

    {
      path: "/student-on-boarding",
      component: (
        <PrivateRoute
          component={<OnBoardingStudentApp />}
          roles={["student", "institute", "teacher"]}
        />
      ),
      children: [
        {
          index: true,
          element: (
            <PrivateRoute
              component={<OnBoardingStudentComponent />}
              roles={["student", "institute", "teacher"]}
            />
          ),
        },
      ],
    },
    {
      path: "/teacher-on-boarding",
      component: (
        <PrivateRoute
          component={<OnBoardingStudentApp />}
          roles={["teacher"]}
        />
      ),
      children: [
        {
          index: true,
          element: (
            <PrivateRoute
              component={<OnBoardingStudentComponent />}
              roles={["teacher"]}
            />
          ),
        },
      ],
    },
    {
      path: "/institute-on-boarding", // convert to <privateRoute/>
      component: (
        <PrivateRoute
          component={<OnBoardingStudentApp />}
          roles={["institute"]}
        />
      ),
      children: [
        {
          index: true,
          element: (
            <PrivateRoute
              component={<OnBoardingInstituteComponent />}
              roles={["institute"]}
            />
          ),
        },
      ],
    },
  ];

  const adminRoutes = [
    {
      path: "/admin/",
      element: (
        <PrivateRoute component={<AdminUserManagement />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/analytics",
      element: (
        <PrivateRoute component={<AdminAnalytics />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/institutes",
      element: (
        <PrivateRoute component={<InstituteDataTable />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/editdetail/institute/:instituteId",
      element: (
        <PrivateRoute
          component={<EditDetailPageInstitute />}
          roles={["admin"]}
        />
      ),
    },
    {
      path: "/admin/institutes/:id",
      element: (
        <PrivateRoute component={<SingleInstitutesData />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/instituteUnderReview/",
      element: (
        <PrivateRoute
          component={<AdminInstituteUnderReview />}
          roles={["admin"]}
        />
      ),
    },
    {
      path: "/admin/allInstituteDetails/",
      element: (
        <PrivateRoute component={<AdminAllInstitute />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/allInstituteNipamDetails/",
      element: (
        <PrivateRoute
          component={<AdminAllnipamInstitute />}
          roles={["admin"]}
        />
      ),
    },
    {
      path: "/admin/instituteDetails/:instituteId",
      element: (
        <PrivateRoute component={<AdminInstituteDetails />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/assign-institute-csv",
      element: (
        <PrivateRoute component={<AdminAssignCSV />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/handle-institute-csv",
      element: (
        <PrivateRoute component={
          <SubAdminCSVProvider>
            <SubAdminAssingedInstitutes />
          </SubAdminCSVProvider>
        } roles={["admin"]} />
      ),
    },
    {
      path: "/admin/handle-institute-csv/:instituteId",
      element: (
        <PrivateRoute component={
          <SubAdminCSVProvider>
            <SubAdminHandleCSV />
          </SubAdminCSVProvider>
        } roles={["admin"]} />
      ),
    },
    {
      path: "/admin/students",
      element: (
        <PrivateRoute
          component={<StudentDataTable role={"student"} />}
          roles={["admin"]}
        />
      ),
    },
    {
      path: "/admin/teachers",
      element: (
        <PrivateRoute
          component={<StudentDataTable role={"teacher"} />}
          roles={["admin"]}
        />
      ),
    },
    {
      path: "/admin/editdetail/student/:studentId",
      element: (
        <PrivateRoute component={<EditDetailPageStudent />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/content",
      element: (
        <PrivateRoute component={<WebsiteContent />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/certificates",
      element: (
        <PrivateRoute
          component={<AdminIssuesCertificates />}
          roles={["admin"]}
        />
      ),
    },
    {
      path: "/admin/quotes",
      element: (
        <PrivateRoute component={<QuotesDataTable />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/blogs",
      element: <PrivateRoute component={<Blogs />} roles={["admin"]} />,
    },
    {
      path: "/admin/blogs/add",
      element: <PrivateRoute component={<AddBlog />} roles={["admin"]} />,
    },
    {
      path: "/admin/blogs/edit/:id",
      element: <PrivateRoute component={<EditBlog />} roles={["admin"]} />,
    },
    {
      path: "/admin/news",
      element: <PrivateRoute component={<News />} roles={["admin"]} />,
    },
    {
      path: "/admin/news/add",
      element: <PrivateRoute component={<AddNews />} roles={["admin"]} />,
    },
    {
      path: "/admin/news/edit/:id",
      element: <PrivateRoute component={<EditNews />} roles={["admin"]} />,
    },
    {
      path: "/admin/states",
      element: <PrivateRoute component={<States />} roles={["admin"]} />,
    },
    {
      path: "/admin/courses",
      element: <PrivateRoute component={<AdminCourses />} roles={["admin"]} />,
    },
    {
      path: "/admin/courses/sections/:id",
      element: (
        <PrivateRoute component={<AdminCoursesSection />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/courses/sections/:id/videos/:id",
      element: (
        <PrivateRoute
          component={<AdminCoursesSectionVideo />}
          roles={["admin"]}
        />
      ),
    },
    {
      path: "/admin/states/:id",
      element: <PrivateRoute component={<SingleState />} roles={["admin"]} />,
    },
    {
      path: "/admin/createpoll",
      element: <PrivateRoute component={<StudentPoll />} roles={["admin"]} />,
    },
    {
      path: "/admin/contactus",
      element: <PrivateRoute component={<Contact />} roles={["admin"]} />,
    },
    {
      path: "/admin/quiz/:id",
      element: <PrivateRoute component={<QuizQuestions />} roles={["admin"]} />,
    },
    {
      path: "/admin/gallery",
      element: (
        <PrivateRoute component={<GalleryControl />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/notification",
      element: <PrivateRoute component={<Notification />} roles={["admin"]} />,
    },
    {
      path: "/admin/emaildeliverable",
      element: (
        <PrivateRoute component={<EmailDeliverable />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/email-delete",
      element: (
        <PrivateRoute component={<EmailDeleteByAdmin />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/email-list",
      element: <PrivateRoute component={<EmailList />} roles={["admin"]} />,
    },
    {
      path: "/admin/carbonFootprintCalculator",
      element: (
        <PrivateRoute
          component={<AdminCarbonFootprintCalculator />}
          roles={["admin"]}
        />
      ),
    },
    {
      path: "/admin/21-days-challenge",
      element: (
        <PrivateRoute component={<Admin21DaysChallenge />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin75-days-challenge",
      element: (
        <PrivateRoute component={<Admin75DaysChallenge />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/manage-ratings",
      element: <PrivateRoute component={<AdminRatings />} roles={["admin"]} />,
    },
    {
      path: "/admin/riasec",
      element: <PrivateRoute component={<RiasecAdmin />} roles={["admin"]} />,
    },
    {
      path: "/admin/institute-csv-details",
      element: (
        <PrivateRoute component={<InstituteCsvDetails />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/upload-csv",
      element: <PrivateRoute component={<UploadCSV />} roles={["admin"]} />,
    },
    {
      path: "/admin/cbse-data",
      element: (
        <PrivateRoute
          component={<AdminInstituteCbseData />}
          roles={["admin"]}
        />
      ),
    },
    {
      path: "/admin/singleCbseData/:id",
      element: (
        <PrivateRoute
          component={<AdminInstituteCbseSingleData />}
          roles={["admin"]}
        />
      ),
    },
    {
      path: "/admin/data-provider",
      element: (
        <PrivateRoute component={<AdminDataProvider />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/compliance",
      element: (
        <PrivateRoute component={<AdminCompliance />} roles={["admin"]} />
      ),
    },
    {
      path: "/admin/compliance/questions/:id",
      element: (
        <PrivateRoute
          component={<AdminComplianceQuestion />}
          roles={["admin"]}
        />
      ),
    },
    {
      path: "/admin/compliance/category/:id",
      element: (
        <PrivateRoute
          component={<AdminComplianceCategory />}
          roles={["admin"]}
        />
      ),
    },
    {
      path: "/admin/callingTeamstatus/:id",
      element: (
        <PrivateRoute
          component={<AdminCallingStatusData />}
          roles={["admin"]}
        />
      ),
    },
  ];

  const subAdminRoutes = [
    {
      path: "/subAdmin/SubAdminAssginData/",
      element: (
        <PrivateRoute component={<SubAdminAssginData />} roles={["subAdmin"]} />
      ),
    },
    {
      path: "/subAdmin/handle-institute-csv",
      element: (
        <PrivateRoute component={
          <SubAdminCSVProvider>
            <SubAdminAssingedInstitutes />
          </SubAdminCSVProvider>
        } roles={["subAdmin"]} />
      ),
    },
    {
      path: "/subAdmin/handle-institute-csv/:instituteId",
      element: (
        <PrivateRoute component={
          <SubAdminCSVProvider>
            <SubAdminHandleCSV />
          </SubAdminCSVProvider>
        } roles={["subAdmin"]} />
      ),
    },
    {
      path: "/subAdmin/stateData/:state",
      element: (
        <PrivateRoute component={<SubAdminStateData />} roles={["subAdmin"]} />
      ),
    },
    {
      path: "/subAdmin/SingleStateData/:id",
      element: (
        <PrivateRoute
          component={<SubAdminSingleStateData />}
          roles={["subAdmin"]}
        />
      ),
    },
  ];

  const viewerRoutes = [
    {
      path: "/viewer/ViewerCallStatus/",
      element: (
        <PrivateRoute component={<ViewerCallStatus />} roles={["viewer"]} />
      ),
    },
    {
      path: "/viewer/ViewerSingleStatus/:id",
      element: (
        <PrivateRoute
          component={<SubAdminSingleStateData />}
          roles={["viewer"]}
        />
      ),
    },
    {
      path: "/viewer/ViewerFollowUp",
      element: (
        <PrivateRoute component={<ViewerFollowUp />} roles={["viewer"]} />
      ),
    },
  ];

  const userRoutes = [
    {
      index: true,
      element: userTypeChecker(
        <MainDashboardContent />, //For Student
        <InstituteMainTab /> //For Institute
      ),
    },
    {
      path: "/dashboard/forum",
      element: userTypeChecker(<Forum />, <Forum />, <Forum />),
      children: [
        {
          index: true,
          element: userTypeChecker(<AllQuestions />),
        },
        {
          path: "/dashboard/forum/:slug",
          element: userTypeChecker(<SingleForumPage />),
        },
      ],
    },

    // ROUTES INSTITUTE DASHBOARD

    {
      path: "/dashboard/registered/users",
      element: userTypeChecker(undefined, <InstituteRegisteredUserV2 />),
    },
    {
      path: "/dashboard/registeration/:role",
      element: userTypeChecker(undefined, <InstituteRegisteredUser />),
    },

    // new route for report manager
    {
      path: "/dashboard/registeration/report-manager/:role",
      element: <ReportManagerAdminTeacher />,
    },

    // new route for report manager end
    {
      path: "/dashboard/enrollments/:role",
      element: userTypeChecker(undefined, <InstituteEnrolledUser />),
    },
    {
      path: "/dashboard/certifications/:role",
      element: userTypeChecker(undefined, <InstituteCertifiedUser />),
    },
    {
      path: "/dashboard/affiliate-institutes",
      element: userTypeChecker(undefined, <InstituteAffiliatedinstitute />),
    },
    {
      path: "/dashboard/affiliate-institutes/:id",
      element: userTypeChecker(undefined, <SingleInstitutesData />),
    },
    {
      path: "/dashboard/reports",
      element: userTypeChecker(undefined, <InstituteGallery />),
    },
    // YMG20
    {
      path: "/dashboard/delegates",
      element: userTypeChecker(undefined, <InstituteDelegates />),
    },
    {
      path: "/dashboard/studentCoordinators",
      element: userTypeChecker(undefined, <StudentCoordinators />),
    },
    {
      path: "/dashboard/event-winners",
      element: userTypeChecker(undefined, <EventWinners />),
    },
    // student Dashboard
    {
      path: "/dashboard/mycertificates",
      element: userTypeChecker(<Certificate />),
    },
    {
      path: "/dashboard/certificate/:courseId",
      element: userTypeChecker(<PrintCertificate />),
    },
    {
      path: "/dashboard/socialshare",
      element: userTypeChecker(<SocialWall />, <SocialWall />),
    },
    // Discussion Board
    {
      path: "/dashboard/discussion",
      element: userTypeChecker(<StudentBoard />, <InstituteBoard />),
      children: [
        {
          index: true,
          element: userTypeChecker(<StudentScreen1 />, <InstituteScreen1 />),
        },
        {
          path: "/dashboard/discussion/:page/:meetingid",
          element: userTypeChecker(<StudentDiscussionRoom />, <MeetingRoom />),
        },
      ],
    },
  ];
  //================ YMUN routes for student =================== \\

  const ymunRoutes = [
    {
      index: true,
      element: userTypeChecker(<ModelUNMain />),
    },
    {
      path: "/modelUn/participates",
      element: userTypeChecker(<MUNParentApp />),
    },
    {
      path: "/modelUn/munDashboard",
      element: userTypeChecker(<ModelUnDashboard />),
    },
  ];

  //<=========== Ym Parliament All Rounte ============ >
  const ymParliamentRoute = [
    {
      index: true,
      element: userTypeChecker(<ParliamentMain />, <ParliamentMain />),
    },
    {
      path: "/Mu-parliament/registration",
      element: userTypeChecker(
        <ParliamentEventRegister />,
        <ParliamentEventRegister />
      ),
    },
  ];
  //<=========== Ym Hackathon All Rounte ============ >
  const ymHackathonRoute = [
    {
      index: true,
      element: userTypeChecker(<HackathonMain />, <HackathonMain />),
    },
    {
      path: "/Mu-hackathon/registration",
      element: userTypeChecker(
        <HackathonEventRegister />,
        <HackathonEventRegister />
      ),
    },
  ];
  // <=========== YMUN Model-un routes for Institute ====================> \\
  const modelUnTeamRoutes = [
    {
      index: true,
      element: userTypeChecker(undefined, <ModelUNMain />),
    },
    {
      path: "registration",
      element: userTypeChecker(undefined, <ModelUnMainComponent />),
    },
    {
      path: "munDashboard",
      element: userTypeChecker(undefined, <ModelUnDashboard />),
    },
    {
      path: "teamRegistration",
      element: userTypeChecker(undefined, <ModelUnTeamRegist />),
    },
    {
      path: "manage-event",
      element: userTypeChecker(undefined, <ModelUnEventManage />),
    },
    {
      path: "applicants",
      element: userTypeChecker(undefined, <MunApplicants />),
    },
    {
      path: "participants",
      element: userTypeChecker(undefined, <MunParticipants />),
    },
    {
      path: "assign-manual-role/:id",
      element: userTypeChecker(undefined, <ModelUnManuallyAssign />),
    },
    {
      path: "addExecutive",
      element: userTypeChecker(undefined, <AddExecutiveBoard />),
    },
    {
      path: "organisation-committee",
      element: userTypeChecker(undefined, <AddOrganistionComm />),
    },
    {
      path: "judging-jury",
      element: userTypeChecker(undefined, <JudgingAndJury />),
    },
    // {
    //   path : "teacher-fullAccess",
    //   element: userTypeChecker(
    //     undefined,
    //     <Teacher_FullAccess/>
    //   )
    // },
    // {
    //   path : "teacher-limitedAccess",
    //   element: userTypeChecker(
    //     undefined,
    //     <Teacher_LimitedAccess/>
    //   )
    // },
  ];

  // FOR SEWA INSTITUTE ID
  const user = JSON.parse(localStorage.getItem("user"));
  const instituteId = user ? user.instituteId : null;

  const newUserRoutes = [
    {
      index: true, // new-dashboard
      element: userTypeChecker(
        <UI2MainDashboard />, //For Student
        <UI2MainDashboard /> //For Institute
      ),
    },
    {
      path: "discussion-board", // new-dashboard/discussion-board
      element: userTypeChecker(
        <DiscussionBoardMain />,
        <DiscussionBoardMain />
      ),
    },
    {
      path: "under-review",
      element: protectedTypeChecker(
        <UsersUnderReview />, //For Student
        <UsersUnderReview />, //For Institute
        undefined,
        ["Moderator", "Manager", "Admin"]
      ),
    },
    {
      path: "manage-account-manager",
      element: userTypeChecker(
        undefined, //For Student
        <Add_Account_manager /> //For Institute
      ),
    },
    {
      path: "eventsfb",
      element: userTypeChecker(<FBEvent />, <FBEvent />),
    },
    {
      path: "eventsfb/myEvents/all",
      element: userTypeChecker(<FBEventMyEvent />, <FBEventMyEvent />),
    },

    {
      path: "eventsfb/:eventId",
      element: userTypeChecker(<FBEventDetails />, <FBEventDetails />),
    },
    {
      path: "create-discussion",
      element: userTypeChecker(
        <ParantStudentCreateDiscussion />,
        <DiscussionBoardCreateDiscussion />
      ),
    },
    {
      path: "event-reports",
      element: userTypeChecker(undefined, <EventReportWithGallary />),
    },
    {
      path: "discussion-chat-room/:id",
      element: userTypeChecker(
        <DiscussionBoardChatRooms />,

        <DiscussionBoardChatRooms />
      ),
    },
    {
      path: "discussion-leaderboard/:id",
      element: userTypeChecker(
        <DiscussionLeaderBoard />,

        <DiscussionLeaderBoard />
      ),
    },
    {
      path: "discussion-judgingAndJury/:id",
      element: userTypeChecker(
        <DiscussionBoardJury />,

        <DiscussionBoardJury />
      ),
    },
    {
      path: "discussion-scoreList/:id",
      element: userTypeChecker(
        <DiscussionBoardTopPointsList />,
        <DiscussionBoardTopPointsList />
      ),
    },
    {
      path: "all-clubs",
      element: userTypeChecker(<UI2DashboardClub />, <UI2DashboardClub />),
    },
    {
      path: "career",
      element: userTypeChecker(
        <CareerProvider>
          <CareerDashboard />
        </CareerProvider>,
        <CareerProvider>
          <CareerDashboard />
        </CareerProvider>
      ),
    },

    {
      path: `sewa/:instituteId`,
      element: userTypeChecker(<SewaPage />, <SewaPage />),
    },
    {
      path: "health",
      element: userTypeChecker(
        <HealthPage />,
        <HealthPage />
      ),
    },
    {
      path: "carbon-footprint/calculator",
      element: userTypeChecker(<CarbonParentApp />, <CarbonParentApp />),
    },
    {
      path: "carbon/calculator",
      element: userTypeChecker(
        <CarbonLatestParentApp />,
        <CarbonLatestParentApp />
      ),
    },
    {
      path: "carbon/carbon-leaderboard",
      element: userTypeChecker(<CarbonLearderboard />, <CarbonLearderboard />),
    },
    {
      path: "riasec",
      element: userTypeChecker(<RiasecTest />, undefined),
    },
    {
      path: "riasec/result",
      element: userTypeChecker(<RiasecResult />, undefined),
    },
    {
      path: "riasec/leaderboard",
      element: userTypeChecker(<RiasecScore />, undefined),
    },
    {
      path: "carbon/calculator/result/:score",
      element: userTypeChecker(
        <CarbonLatestAppResult />,
        <CarbonLatestAppResult />
      ),
    },
    {
      path: "all-courses",
      element: userTypeChecker(<CourseOutlet />, <CourseOutlet />),
      children: [
        {
          index: true,
          element: userTypeChecker(<StudentAllCouses />, <StudentAllCouses />),
        },
        {
          path: "course-overview/:id",
          element: userTypeChecker(
            <StudentCourseOverView />,
            <StudentCourseOverView />
          ),
        },
      ],
    },
    {
      path: "student-course",
      element: userTypeChecker(
        <StudentCoursePlayerContainer />,
        <StudentCoursePlayerContainer />
      ),
      children: [
        {
          index: true,
          element: userTypeChecker(
            <StudentCourseScreen />,
            <StudentAllCouses />
          ),
        },
      ],
    },
    {
      path: "my-courses",
      element: userTypeChecker(<StudentMyCourses />, <StudentMyCourses />),
    },
    {
      path: "school-compliance/:complianceid",
      element: userTypeChecker(<Compliance />, <Compliance />),
    },
    {
      path: "school-compliance/:complianceid/continue/:reportId",
      element: userTypeChecker(<Compliance />, <Compliance />),
    },
    {
      path: "school-compliance/:complianceid/continue/:reportId/edit/currentPage/:page",
      element: userTypeChecker(<Compliance />, <Compliance />),
    },
    {
      path: "school-compliance/report/:resultid",
      element: userTypeChecker(<ComplianceResult />, <ComplianceResult />),
    },
    {
      path: "school-compliance/attempt-table",
      element: userTypeChecker(<AttemptsTable />, <AttemptsTable />),
    },
    {
      path: "green-card",
      element: userTypeChecker(<Greencard />, <Greencard />),
    },
  ];

  const clubRoutes = [
    {
      index: true,
      element: (
        <PrivateRoute
          component={<ClubPosts />}
          roles={["institute", "student", "teacher"]}
        />
      ),
    },
    {
      path: "/clubs/:id/post/:postId",
      element: (
        <PrivateRoute
          component={<ClubSinglePost />}
          roles={["institute", "student", "teacher"]}
        />
      ),
    },
  ];
  // =============== Download section   route here =====================\\
  const downloadSection = [
    {
      path: "EventCollaterals",
      element: userTypeChecker(
        undefined, //For Student
        <DownloadEventCollaterals /> //For Institute
      ),
    },
    {
      path: "background-Guides",
      element: userTypeChecker(
        undefined, //For Student
        <DownloadBackground /> //For Institute
      ),
    },
    {
      path: "formats",
      element: userTypeChecker(
        undefined, //For Student
        <DownloadFormats /> //For Institute
      ),
    },
  ];
  const clubProfileRoutes = [
    {
      path: "/profile/user/:userId",
      element: (
        <PrivateRoute
          component={<ClubProfile />}
          roles={["institute", "student", "teacher"]}
        />
      ),
    },
    {
      path: "/profile/institute/:userId",
      element: (
        <PrivateRoute
          component={<ClubProfile />}
          roles={["institute", "student", "teacher"]}
        />
      ),
    },
  ];

  const timelineRoutes = [
    {
      index: true,
      element: (
        <PrivateRoute
          component={<Posts />}
          roles={["institute", "student", "teacher"]}
        />
      ),
    },

    {
      path: "/timeline/:id",
      element: (
        <PrivateRoute
          component={<SinglePost />}
          roles={["institute", "student", "teacher"]}
        />
      ),
    },
    {
      path: "/timeline/posts/:instituteId",
      element: (
        <PrivateRoute
          component={<InstitutePosts />}
          roles={["institute", "student", "teacher"]}
        />
      ),
    },
    {
      path: "/timeline/userprofile/:userId",
      element: (
        <PrivateRoute
          component={<ClickProfile />}
          roles={["institute", "student", "teacher"]}
        />
      ),
    },
    {
      path: "/timeline/userprofile/institute/:instituteId",
      element: (
        <PrivateRoute
          component={<ClickProfile />}
          roles={["institute", "student", "teacher"]}
        />
      ),
    },
  ];
  const settingRoutes = [
    {
      index: true,
      path: "/setting",
      element: userTypeChecker(
        <StudentEditBasicDetails />,
        <InstituteBasicDetails />
      ),
    },
    {
      path: "/setting/account",
      element: userTypeChecker(
        <StudentEditPassword />,
        <InstituteEditPassword />
      ),
    },
    {
      path: "/setting/preferences",
      element: userTypeChecker(
        <StudentEditProfileView />,
        <InstituteEditPrefrence />
      ),
    },
    {
      path: "/setting/additional",
      element: userTypeChecker(
        <AdditionalDetails />,
        <InstituteAdditionalDetails />
      ),
    },
    {
      path: "/setting/infrastructure",
      element: userTypeChecker(undefined, <InstituteEditInfrastructure />),
    },
    {
      path: "/setting/event",
      element: userTypeChecker(
        <StudentEditPreference />,
        <InstituteEditEvent />
      ),
    },
  ];

  const twentyOneDayRoutes = [
    {
      index: true,
      path: "twenty-one-day",
      element: userTypeChecker(<Main />, undefined),
    },
    {
      path: "twenty-one-day/gameday/:attemptId",
      element: userTypeChecker(<GameDay />, undefined),
    },
    {
      path: "twenty-one-day/gameday/:attemptId/Today-Challenge/:tastId",
      element: userTypeChecker(<TodayChallenCompoent />, undefined),
    },
    {
      path: "twenty-one-day/gameday/:attemptId/Today-Challenge/Congress",
      element: userTypeChecker(<Congress />, undefined),
    },
  ];

  const seventyFiveDayRoutes = [
    {
      index: true,
      path: "seventy-five-steps",
      element: userTypeChecker(<SeventyFiveMain />, undefined),
    },
    {
      path: "seventy-five-steps/today-steps/:attemptId",
      element: userTypeChecker(<SeventyFiveChallengeDays />, undefined),
    },
    {
      path: "seventy-five-steps/today-steps/:attemptId/todaytask/:taskid",
      element: userTypeChecker(<SeventyFiveTodayChallenComponent />, undefined),
    },
    {
      path: "seventy-five-steps/today-steps/:attemptId/todaytask/Congress",
      element: userTypeChecker(<SeventyFiveCongress />, undefined),
    },
  ];
  // roles are
  /* student parent teacher teachercoordinator instititute staff admin*/

  const nipamStudent = userData?.nipamCheck ? true : false;

  const sideDrawer = [
    {
      icon: <DashboardOutlined />,
      path: "/new-dashboard",
      title: "Dashboard",
      roles: ["student", "teacher", "parent", "staff", "institute"],
      dock: true,
    },
    {
      icon: <BookOutlined />,
      path: "/new-dashboard/school-compliance/attempt-table",
      title: "Compliance",
      roles: ["institute", "coordinator"],
      dock: true,
    },

    {
      icon: <Person3Icon />,
      path: "/profile/user/" + userData.id,
      title: "Profile",
      roles: ["student", "teacher", "parent", "staff"],
      dock: true,
    },
    {
      icon: <Person3Icon />,
      path: "/profile/institute/" + userData.id,
      title: "Profile",
      roles: ["institute"],
      dock: true,
    },
    userData?.student_verification && userData?.reviewStatus
      ? {
          icon: <DashboardOutlined />,
          path: "/new-dashboard/under-review",
          title: "Approvals",
          roles: ["institute"],
          subRoles: ["Moderator", "Manager", "Admin"],
          dock: true,
        }
      : null,
    {
      icon: <SchoolOutlined />,
      path: "/dashboard/registeration/teacher",
      title: "My Teachers",
      roles: ["institute", "teachercoordinator"],
      dock: true,
    },
    {
      icon: <AutoStoriesOutlined />,
      path: "/dashboard/registeration/student",
      title: "My Students",
      roles: ["institute", "teachercoordinator"],
      dock: true,
    },
    {
      icon: <FlagCircleOutlined />,
      path: "/dashboard/registeration/report-manager/teacher",
      title: "Teacher Report",
      roles: ["institute", "teachercoordinator"],
      dock: true,
    },
    {
      icon: <FlagCircleOutlined />,
      path: "/dashboard/registeration/report-manager/student",
      title: "Student Report",
      roles: ["institute", "teachercoordinator"],
      dock: true,
    },
    {
      icon: <TimelineOutlined />,
      path: "/new-dashboard/manage-account-manager",
      title: "Add manager",
      roles: ["institute"],
      dock: true,
    },
    // {
    //   icon: <SubscriptionsOutlined />,
    //   path: "#",
    //   title: "Enrollments",
    //   roles: ["institute", "teachercoordinator"],
    //   dock: false,
    //   subList: [
    //     {
    //       icon: <SchoolOutlined />,
    //       path: "/dashboard/enrollments/teacher",
    //       title: "Teacher Enrollments",
    //       roles: ["institute", "teachercoordinator"],
    //     },
    //     {
    //       icon: <AutoStoriesOutlined />,
    //       path: "/dashboard/enrollments/student",
    //       title: "Student Enrollments",
    //       roles: ["institute", "teachercoordinator"],
    //     },
    //   ],
    // },
    {
      icon: <QuestionAnswerOutlined />,
      path: "/admin/compliance",
      title: "Compliances",
      roles: ["admin"],
      dock: false,
    },
    {
      icon: <AutoStoriesOutlinedIcon />,
      path: "/course/overview/4",
      title: "Courses",
      roles: ["student", "teacher", "teachercoordinator", "parent", "staff"],
      dock: true,
    },
    // !nipamStudent && {
    //   icon: <TimelineOutlined />,
    //   path: "/modelUn",
    //   title: "ModelUn",
    //   roles: ["student", "teacher"],
    //   dock: true,
    // },

    {
      icon: <RuleFolderTwoToneIcon />,
      path: "#",
      title: "YMUN",
      roles: ["institute"],
      dock: false,
      subList: [
        {
          // icon: <TimelineOutlined />,
          path: "/model-un",
          title: "Event",
          roles: ["institute"],
          dock: true,
        },
        userData?.munRegister && {
          // icon: <PersonAddTwoToneIcon />,
          path: "/model-un/registration?count=7",
          title: "Coordinators",
          roles: ["institute"],
        },
        userData?.munRegister && {
          // icon: <ContactMailTwoToneIcon />,
          path: "/model-un/registration?count=8",
          title: "Secretariat",
          roles: ["institute"],
        },
        userData?.munRegister && {
          // icon: <ContactsTwoToneIcon />,
          path: "/model-un/registration?count=9",
          title: "Press Corps",
          roles: ["institute"],
        },
        userData?.munRegister && {
          // icon: <ContactsTwoToneIcon />,
          path: "/model-un/addExecutive",
          title: "Executive Board",
          roles: ["institute"],
        },
        userData?.munRegister && {
          // icon: <ContactsTwoToneIcon />,
          path: "/model-un/organisation-committee",
          title: "Organisation Committee",
          roles: ["institute"],
        },
        userData?.munRegister && {
          // icon: <ContactsTwoToneIcon />,
          path: "/model-un/judging-jury",
          title: "Judging and Jury",
          roles: ["institute"],
        },
        // {
        //   // icon: <ContactsTwoToneIcon />,
        //   path: "/model-un/teacher-fullAccess",
        //   title: "Teacher FullAccess",
        //   roles: ["institute"],
        // },
        // {
        //   // icon: <ContactsTwoToneIcon />,
        //   path: "/model-un/teacher-limitedAccess",
        //   title: "Teacher LimitedAccess",
        //   roles: ["institute"],
        // },
        userData?.munRegister && {
          // icon: <PlaylistAddCircleTwoToneIcon />,
          path: "/model-un/teamRegistration",
          title: "YMUN Team",
          roles: ["institute"],
        },
        userData?.munRegister && {
          // icon: <PeopleAltTwoToneIcon />,
          path: "/model-un/applicants",
          title: "Applicants",
          roles: ["institute"],
        },
        userData?.munRegister && {
          // icon: <GroupAddTwoToneIcon  />,
          path: "/model-un/participants",
          title: "Participants",
          roles: ["institute"],
        },
        userData?.munRegister && {
          // icon: <PeopleAltTwoToneIcon />,
          path: "/model-un/manage-event",
          title: "Manage-event",
          roles: ["institute"],
        },
      ],
    },

    // {
    //   icon: <LocalActivityOutlined />,
    //   path: "#",
    //   title: "Certfications",
    //   roles: ["institute", "teachercoordinator"],
    //   dock: false,
    //   subList: [
    //     {
    //       icon: <SchoolOutlined />,
    //       path: "/dashboard/certifications/teacher",
    //       title: "Teacher Certifications",
    //       roles: ["institute", "teachercoordinator"],
    //     },
    //     {
    //       icon: <AutoStoriesOutlined />,
    //       path: "/dashboard/certifications/student",
    //       title: "Student Certifications",
    //       roles: ["institute", "teachercoordinator"],
    //     },
    //   ],
    // },
    // {
    //   icon: <LocalActivityOutlined />,
    //   path: "/dashboard/mycertificates",
    //   title: "My Certificates",
    //   roles: ["student", "teacher", "teachercoordinator", "parent", "staff"],
    //   dock: true,
    // },
    {
      icon: <Groups3OutlinedIcon />,
      path: "/new-dashboard/eventsfb",
      title: "Events",
      roles: [
        "student",
        "teacher",
        "teachercoordinator",
        "parent",
        "staff",
        "institute",
      ],
      dock: false,
      subList: [
        userData.role === "student"
          ? null
          : {
              // icon: <EmojiEventsIcon />,
              path: "/new-dashboard/eventsfb",
              title: "Discover Events",
              roles: [
                "student",
                "teacher",
                "teachercoordinator",
                "parent",
                "staff",
                "institute",
              ],
              dock: true,
            },
        {
          // icon: <EmojiEventsIcon />,
          path: "/new-dashboard/eventsfb/myEvents/all",
          title: "My Events",
          roles: [
            "student",
            "teacher",
            "teachercoordinator",
            "parent",
            "staff",
            "institute",
          ],
          dock: true,
        },
      ],
    },
    {
      icon: <Groups3OutlinedIcon />,
      path: "/new-dashboard/all-clubs",
      title: "Clubs",
      roles: [
        "student",
        "teacher",
        "teachercoordinator",
        "parent",
        "staff",
        "institute",
      ],
      dock: true,
    },
    {
      icon: <Groups3OutlinedIcon />,
      path: "/new-dashboard/career",
      title: "Career",
      roles: [
        "student",
        "teacher",
        "teachercoordinator",
        "parent",
        "staff",
        "institute",
      ],
      dock: true,
    },

    {
      icon: <Groups3OutlinedIcon />,
      path: `/new-dashboard/sewa/${instituteId}`,
      title: "Sewa",
      roles: [
        "student",
        "teacher",
        "teachercoordinator",
        "parent",
        "staff",
        "institute",
      ],
      dock: true,
    },
    {
      icon: <HealthAndSafetyIcon />,
      path: "/new-dashboard/health",
      title: "Health",
      roles: ["student", "teacher", "teachercoordinator", "parent", "staff", 'institute'],
      dock: true,
    },

    // !nipamStudent && {
    //   icon: <EmojiEventsIcon />,
    //   path: "/challenge/twenty-one-day",
    //   title: "Challenges",
    //   roles: ["student", "teacher", "teachercoordinator"],
    //   dock: false,
    //   subList: [
    //     {
    //       // icon: <EmojiEventsIcon />,
    //       path: "/challenge/twenty-one-day",
    //       title: "21 Days Challenge",
    //       roles: ["student", "teacher", "teachercoordinator"],
    //       dock: true,
    //     },
    //     {
    //       // icon: <EmojiEventsIcon />,
    //       path: "/steps/seventy-five-steps",
    //       title: "75 Steps Challenge",
    //       roles: ["student", "teacher", "teachercoordinator"],
    //       dock: true,
    //     },
    //   ]
    // },

    // {
    //   icon: <CelebrationOutlined />,
    //   path: "/ymg20",
    //   title: "YMG20",
    //   badge: { text: "Live", color: "success" },
    //   roles: ["institute", "teachercoordinator", "student", "teacher", "staff"],
    //   dock: false,
    //   subList: [
    //     {
    //       icon: <Groups2Outlined />,
    //       path: "/dashboard/delegates",
    //       title: "All Participants",
    //       roles: ["institute", "teachercoordinator"],
    //     },
    //     {
    //       icon: <EmojiEventsOutlined />,
    //       path: "/dashboard/event-winners",
    //       title: "Winners",
    //       roles: ["institute", "teachercoordinator"],
    //     },
    //     {
    //       icon: <MilitaryTechOutlined />,
    //       path: "/dashboard/studentcoordinators",
    //       title: "Coordinators",
    //       roles: ["institute", "teachercoordinator"],
    //     },
    //     {
    //       icon: <ForumOutlined />,
    //       path: "/dashboard/discussion",
    //       title: "Discussion Board",
    //       roles: [
    //         "institute",
    //         "student",
    //         "teacher",
    //         "teachercoordinator",
    //         "parent",
    //         "staff",
    //       ],
    //     },
    //     {
    //       icon: <QuestionAnswer />,
    //       path: "/dashboard/forum",
    //       title: "Q&A Portal",
    //       roles: [
    //         "student",
    //         "teacher",
    //         "teachercoordinator",
    //         "parent",
    //         "staff",
    //       ],
    //       dock: false,
    //     },
    //     {
    //       icon: <BarChartOutlined />,
    //       path: "/dashboard/reports",
    //       title: "Event Reports",
    //       roles: ["institute", "teachercoordinator"],
    //     },
    //     {
    //       icon: <GroupOutlined />,
    //       path: "/dashboard/socialshare",
    //       title: "YMG20 Social",
    //       roles: ["teachercoordinator", "student", "teacher", "staff"],
    //     },
    //   ],
    // },
    // {
    //   icon: <CelebrationOutlined />,
    //   path: "/mun",
    //   title: "Model United Nation",
    //   roles: ["institute", "teachercoordinator", "student", "teacher", "staff"],
    //   dock: false,
    //   subList: [
    //     {
    //       icon: <GroupOutlined />,
    //       path: "/dashboard/socialshare",
    //       title: "YMG20 Social",
    //       roles: [
    //         "institute",
    //         "teachercoordinator",
    //         "student",
    //         "teacher",
    //         "staff",
    //       ],
    //     },
    //   ],
    // },
    {
      icon: <EventNoteIcon />,
      path: "/new-dashboard/event-reports/",
      title: "Event Reports",
      roles: ["institute"],
      dock: true,
    },
    {
      icon: <SettingsOutlined />,
      path: "/setting/",
      title: "Settings",
      roles: ["institute", "teachercoordinator", "student", "teacher", "staff"],
      dock: true,
    },
    // !nipamStudent && {
    //   icon: <QuestionAnswerOutlined />,
    //   path: "/faq",
    //   title: "FAQ",
    //   roles: ["student", "teacher", "teachercoordinator", "parent", "staff"],
    //   dock: false,
    // },

    {
      icon: <SupervisorAccountOutlined />,
      path: "/admin/",
      title: "Admin Users",
      roles: ["admin"],
      dock: false,
    },
    {
      icon: <TrendingUpSharp />,
      path: "/admin/analytics",
      title: "Analytics",
      roles: ["admin"],
      dock: false,
    },

    {
      icon: <PeopleOutlineSharp />,
      path: "/admin/institutes",
      title: "Users",
      roles: ["admin"],
      dock: false,
      subList: [
        {
          icon: <SchoolOutlined />,
          path: "/admin/institutes",
          title: "Institutes",
          roles: ["admin"],
          dock: false,
        },
        {
          icon: <AutoStoriesOutlined />,
          path: "/admin/students",
          title: "Students",
          roles: ["admin"],
          dock: false,
        },
        {
          icon: <BookOutlined />,
          path: "/admin/teachers",
          title: "Teachers",
          roles: ["admin"],
          dock: false,
        },
      ],
    },
    {
      icon: <PeopleOutlineSharp />,
      path: "/admin/instituteUnderReview",
      title: "Institute Review",
      roles: ["admin"],
      dock: false,
      subList: [
        {
          icon: <SupervisorAccountOutlined />,
          path: "/admin/instituteUnderReview",
          title: "Institute Under Review",
          roles: ["admin"],
          dock: false,
        },
        {
          icon: <SupervisorAccountOutlined />,
          path: "/admin/assign-institute-csv",
          title: "Assign CSVs",
          roles: ["admin"],
          dock: false,
        },
        {
          icon: <SupervisorAccountOutlined />,
          path: "/admin/handle-institute-csv",
          title: "Handle CSVs",
          roles: ["admin"],
          dock: false,
        },
        {
          icon: <SupervisorAccountOutlined />,
          path: "/admin/allInstituteDetails",
          title: "Institute Details",
          roles: ["admin"],
          dock: false,
        },
        {
          icon: <SupervisorAccountOutlined />,
          path: "/admin/allInstituteNipamDetails",
          title: "Nipam Details",
          roles: ["admin"],
          dock: false,
        },
      ],
    },
    {
      icon: <SupervisorAccountOutlined />,
      path: "/admin/carbonFootprintCalculator",
      title: "Carbon Footprint",
      roles: ["admin"],
      dock: false,
    },
    {
      icon: <SupervisorAccountOutlined />,
      path: "/admin/21-days-challenge",
      title: "21 Days Challenge",
      roles: ["admin"],
      dock: false,
    },
    {
      icon: <SupervisorAccountOutlined />,
      path: "/admin/75-days-challenge",
      title: "75 Days Challenge",
      roles: ["admin"],
      dock: false,
    },
    {
      icon: <TrendingUpSharp />,
      path: "/admin/riasec",
      title: "RIASEC",
      roles: ["admin"],
      dock: false,
    },
    {
      icon: <SupervisorAccountOutlined />,
      path: "/admin/manage-ratings",
      title: "Ratings",
      roles: ["admin"],
      dock: false,
    },
    {
      icon: <UploadFileIcon />,
      path: "/admin/cbse-data",
      title: "CBSE Data",
      roles: ["admin"],
      dock: false,
    },
    {
      icon: <SubscriptionsOutlined />,
      path: "/admin/courses",
      title: "Courses",
      roles: ["admin"],
      dock: false,
    },
    {
      icon: <LocalActivityOutlined />,
      path: "/admin/certificates",
      title: "Certificates",
      roles: ["admin"],
      dock: false,
    },
    {
      icon: <SourceOutlined />,
      path: "/admin/content",
      title: "Manage Content",
      roles: ["admin"],
      dock: false,
      subList: [
        {
          icon: <SourceOutlined />,
          path: "/admin/content",
          title: "Website Content",
          roles: ["admin"],
          dock: false,
        },
        {
          icon: <NotificationAddOutlined />,
          path: "/admin/notification",
          title: "Notifications",
          roles: ["admin"],
          dock: false,
        },
        {
          icon: <FormatQuoteOutlined />,
          path: "/admin/quotes",
          title: "Quotes",
          roles: ["admin"],
          dock: false,
        },
        {
          icon: <RssFeedOutlined />,
          path: "/admin/blogs",
          title: "Blogs",
          roles: ["admin"],
          dock: false,
        },
        {
          icon: <RssFeedOutlined />,
          path: "/admin/news",
          title: "News",
          roles: ["admin"],
          dock: false,
        },
        {
          icon: <BarChartOutlined />,
          path: "/admin/createpoll",
          title: "Public Polls",
          roles: ["admin"],
          dock: false,
        },
        {
          icon: <MapOutlined />,
          path: "/admin/states",
          title: "Manage States",
          roles: ["admin"],
          dock: false,
        },
        {
          icon: <CollectionsOutlined />,
          path: "/admin/gallery",
          title: "Manage Gallery",
          roles: ["admin"],
          dock: false,
        },
      ],
    },

    {
      icon: <ContactSupportOutlined />,
      path: "/admin/contactUs",
      title: "Contacts",
      roles: ["admin"],
      dock: false,
    },

    {
      icon: <EmailOutlined />,
      path: "/admin/email-list",
      title: "Email Management",
      roles: ["admin"],
      dock: false,
    },

    {
      icon: <MarkEmailReadOutlined />,
      path: "/admin/emaildeliverable",
      title: "Email Reports",
      roles: ["admin"],
      dock: false,
    },
    {
      icon: <DeleteForeverIcon />,
      path: "/admin/email-delete",
      title: "Email Delete",
      roles: ["admin"],
      dock: false,
    },
    {
      icon: <InfoIcon />,
      path: "/admin/institute-csv-details",
      title: "Institute CSV Details",
      roles: ["admin"],
      dock: false,
    },
    {
      icon: <UploadFileIcon />,
      path: "/admin/upload-csv",
      title: "Upload CSV",
      roles: ["admin"],
      dock: false,
    },
    {
      icon: <UploadFileIcon />,
      path: "/subAdmin/SubAdminAssginData",
      title: "State Data",
      roles: ["subAdmin"],
      dock: false,
    },
    {
      icon: <UploadFileIcon />,
      path: "/subAdmin/handle-institute-csv",
      title: "Institute CSVs",
      roles: ["subAdmin"],
      dock: false,
    },
    {
      icon: <UploadFileIcon />,
      path: "/viewer/viewerDashboard",
      title: "Call Status",
      roles: ["viewer"],
      dock: false,
    },
  ];
  const MegaMenuArr = [
    // {
    //   id: 1,
    //   title: "Solutions",
    //   icon: <BookOnline />,
    //   state: props?.solutionCollapse,
    //   setState: props?.setSolutionCollapse,
    //   links: [
    //     {
    //       icon: <ArrowForwardIosSharp />,
    //       name: "SIS Learning",
    //       path: "/learning",
    //       description:
    //         "Whether it is a student, teacher, staff or a parent, we have everything they ought to know about safety in your school. Packaged as training, courses and exercises, get access to the most comprehensive school safety modules ever created.",
    //     },
    //     {
    //       icon: <ArrowForwardIosSharp />,
    //       name: "SIS Compliances",
    //       path: "/compliance",
    //       description:
    //         "An easy to use interface built for the school administration to ensure that your school meets the mandatory safety standards prescribed by the Government",
    //     },
    //     {
    //       icon: <ArrowForwardIosSharp />,
    //       name: "SIS Certifications",
    //       path: "/certification",
    //       description:
    //         "It's time to get certified! We have special training sessions with certifications for school staff, students and parents alike. These certifications will help us create school safety experts for sustainable schools.",
    //     },
    //   ],
    // },
    // {
    //   id: 2,
    //   icon: <GroupOutlined />,
    //   title: "About",
    //   state: props?.aboutCollapse,
    //   setState: props?.setAboutCollapse,
    //   links: [
    //     {
    //       icon: <ArrowForwardIosSharp />,
    //       name: "About Us",
    //       path: "/about",
    //       description:
    //         "Read about our vision, mission and story and know more about values that guide us at SafeInSchool.",
    //     },
    //     {
    //       icon: <ArrowForwardIosSharp />,
    //       name: "Blogs",
    //       path: "/blog",
    //       description:
    //         "Get the latest news on our events, programmes and initiatives as and when they happen.",
    //     },
    //     {
    //       icon: <ArrowForwardIosSharp />,
    //       name: "Events",
    //       path: "/event",
    //       description:
    //         "Check the latest upcoming and past events and get dates for our webinars, trainings and podcasts",
    //     },
    //     {
    //       icon: <ArrowForwardIosSharp />,
    //       name: "Careers",
    //       path: "/career",
    //       description:
    //         "Join our mission to create safer schools as a member of our growing team. Browse career opportunities at SafeInSchool.",
    //     },
    //   ],
    // },
    {
      id: 3,
      icon: <WebStoriesIcon />,
      title: "Programmes",
      state: props?.aboutCollapse,
      setState: props?.setAboutCollapse,
      links: [
        {
          name: "Yuvamanthan Nipam programme",
          path: "/nipam",
        },
        {
          name: "Yuvamanthan Model United Nations",
          path: "/ymun",
        },
        {
          name: "Yuvamanthan  Hackathon",
          path: "/ymh",
        },
        {
          name: "Yuvamanthan Model Youth Parliament",
          path: "/ymyp",
        },
        {
          name: "Yuvamanthan Model G20",
          path: "/model-g20",
        },
      ],
    },
    {
      id: 4,
      icon: <BakeryDiningOutlinedIcon />,
      title: "Themes",
      state: props?.aboutCollapse,
      setState: props?.setAboutCollapse,
      links: [
        {
          name: "Future of Work",
          path: "/future-of-work",
        },
        {
          name: "Peacebuilding and Reconciliation",
          path: "/peacebuilding-and-reconciliation",
        },
        {
          name: "Climate Change and Disaster Risk Reduction",
          path: "/climate-change-and-disaster-risk-reduction",
        },
        {
          name: "Health, Well-Being, and Sports",
          path: "/health-well-being-and-sports",
        },
        {
          name: "Shared Future",
          path: "/shared-future",
        },
        {
          name: "challenge Future",
          path: "challenge/twenty-one-day",
        },
        {
          name: "India’s Vision 2047",
        },
        {
          name: "Mission LiFE (Lifestyle for Environment) in partnership with UNPD",
        },
      ],
    },
    {
      id: 5,
      icon: <GroupOutlined />,
      title: "About",
      state: props?.aboutCollapse,
      setState: props?.setAboutCollapse,
      links: [
        {
          name: "About Yuvamanthan",
          path: "/about",
        },
        {
          name: "Our Team",
          path: "/team",
        },
        // {
        //   name: "Life At Yuvamanthan",
        //   path: "/lifeAtYuvamanthan",
        // },
      ],
    },
    {
      id: 6,
      icon: <EscalatorOutlinedIcon />,
      title: "Engage",
      state: props?.aboutCollapse,
      setState: props?.setAboutCollapse,
      links: [
        {
          name: "Youth-Connect",
          path: "/youth-community",
        },
        {
          name: "Contact Us",
          path: "/contactus",
        },
        {
          name: "YMG20 Media",
          path: "/media",
        },
      ],
    },
  ];
  return {
    publicRoutes,
    v2AuthPublicRoutes,
    adminRoutes,
    subAdminRoutes,
    viewerRoutes,
    sideDrawer,
    MegaMenuArr,
    userRoutes,
    newUserRoutes,
    settingRoutes,
    timelineRoutes,
    clubProfileRoutes,
    clubRoutes,
    ymunRoutes,
    ymParliamentRoute,
    ymHackathonRoute,
    modelUnTeamRoutes,
    twentyOneDayRoutes,
    seventyFiveDayRoutes,
    downloadSection,
  };
};

export default useRoutes;
