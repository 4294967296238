import { Avatar } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
import "../style/clubStyle.css"
const ClubCommentPeople = ({ comment, post, isLoading }) => {

  const [user, setUser] = useState({})

  useEffect(() => {
    setUser(comment?.userDetail)
  }, [comment])

  return (
    <>
      <div className="d-flex  justify-content-between mb-1">
        <div className="d-flex">
          <div style={{ width: '40px', height: '40px', marginRight: '12px' }} className="">
            {isLoading ? <Skeleton height={40} width={40} circle={true} /> : <Avatar className="img-comment-avatar" style={{ borderRadius: '50%', objectFit: 'contain' }}
              src={user?.logo ?? user?.profile}
              alt={"img"}
              onError={(e) => {
                e.target.src = "https://w7.pngwing.com/pngs/313/964/png-transparent-cartoon-animated-series-randomness-girl-cartoon-hand-illustrator-fictional-character.png"
              }}
            />}
          </div>
          <div>
          <div style={{background:"#f3f3f3",borderTopLeftRadius:0 ,borderTopRightRadius:"20px", borderBottomLeftRadius:"20px" ,borderBottomRightRadius:"20px"}}
            className=" px-3 py-2"
          >
            {user?.institution_name ?
              <div>
                <h6 className="user-contetnt mb-1"
                  style={{ fontSize: '14px', lineHeight: "1.4" }}
                >
                  {user?.institution_name}
                </h6>
              </div>
              : <div>
                <h6 className="user-contetnt mb-1"
                  style={{ fontSize: '14px', lineHeight: "1.4" }}
                >
                  {user?.first_name + " " + user?.last_name}
                </h6>
              </div>
            }
            <div>
              {isLoading ?
                <Skeleton count={3} width={700} height={10} style={{ marginBottom: '10px' }} />
                : <div className=""><p style={{ fontSize: '13px', color: "#050505", marginBottom: '0px'}}>{comment.content}</p></div>}
            </div>
          </div>
          <p className="px-3 py-1 postcenter-time"
           style={{fontSize: '10px'}}
          >{moment(comment.createdAt).fromNow()}</p>
          </div>
        </div>
      </div>


    </>
  );
};

export default ClubCommentPeople;
