import React, { useEffect, useState } from 'react'
import Tooltip from '@mui/material/Tooltip';
import { Grid } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { useGlobalContext } from 'global/context';
import { toast } from "react-toastify";
import { apiJson } from 'api';

const InstituteEditPrefrence = () => {
    const {userData,setUser} = useGlobalContext();
    const [select, setselect] = useState('1')
    const [student_verification,setStudent_Verication] = useState()

    const documentSubmit = async () => {
        try {
            const updateProfile = await apiJson.put(`v2/register/updateData/${userData?.id}`, { logo:userData?.logo,student_verification})
            if (updateProfile?.status === 200) {
               setUser({...userData, student_verification})
                toast.success(updateProfile?.data?.message)

            }
        } catch (error) {
            toast.dismiss();
            toast.error(error?.response?.data?.message ? error?.response?.data?.message : "Internal Server Error.")
        }

    };
    useEffect(() => {
        setStudent_Verication(select === '1' ? false : true)
    }, [select])
    useEffect(() => {
        setselect(userData?.student_verification=== true ? "2" : "1")
    }, [])

    return (
        <div> <div className="mb-2">
            <span className=" fs-2 fw-semibold">Preferences</span>
        </div>
            <div className="mb-50 d-flex align-items-center">
                <span className="fs-5 fw-500 color-grey">
                    Joining Preferences
                </span>
                <Grid item><Tooltip title="Make verification of all students and teachers by account managers (admin or moderator) mandatory for joining." placement="bottom"><HelpIcon sx={{ color: "#28b8d4" }} /></Tooltip></Grid>
            </div>

            <div className='col-9 px-4 py-3 rounded-2' style={{background:"#f0f0f0"}}>
                <div className={` card cursor-pointer d-flex border-3  p-2  rounded-3 mb-4 ${select === '1' ? 'border-purple-dark' : 'border-transparent'}`}>

                    <div onClick={() => { setselect('1') }} className="card-body d-flex align-items-center">
                        <span>
                            <input onChange={() => setselect('1')} class="form-check-input me-4" type="radio" name="preference" value='1' id="1" checked={select === '1'} />

                        </span>
                        <span className="fw-500 fs-5">Allow, students and teachers to join without verification</span>

                    </div>
                </div>


                <div onClick={() => setselect('2')} className={`cursor-pointer card d-flex border-3 p-2  rounded-3 ${select === '2' ? 'border-purple-dark' : 'border-transparent'}`}>

                    <div className="card-body d-flex align-items-center">
                        <span>
                            <input onChange={() => setselect('2')} class="form-check-input me-4" type="radio" name="preference" value='2' id="2" checked={select === '2'} />

                        </span>
                        <span className="fw-500 fs-5">Make verification of all students and teachers by account managers (admin or moderator) mandatory for joining.</span>

                    </div>
                </div>
                <div className="mt-3 mb-3 d-flex justify-content-end gap-3 align-items-center text-center">

                    <div>
                        <button type="submit" className="rounded" style={{ height: "36px", width: "99px", background: "#C08EFF", color: "#2B0062" }} onClick={documentSubmit}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InstituteEditPrefrence