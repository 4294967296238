import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';

const CompleteCalculator = ({ percentage, handleTryAgain }) => {
    const getPerformanceMessage = (percentage) => {
        if (percentage >= 90) return "Excellent";
        if (percentage >= 75) return "Great";
        if (percentage >= 50) return "Good";
        if (percentage >= 30) return "Needs Improvement";
        return "Try Again";
    };

    const performanceMessage = getPerformanceMessage(percentage);

    return (
        <Box sx={{
            position: "relative",
            padding: "0.8rem",
            boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
            borderRadius: "1rem",
            border: "5px solid #2356fe",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
            minHeight: "62.5vh",
            maxHeight: "100vh",
            width: "100%",
            overflow: "hidden",
            textAlign: "center",
        }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="h2"
                    sx={{
                        fontWeight: 'bold',
                        color: '#2356fe',
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                        fontSize: '2.5rem',
                    }}
                >
                    Challenge Completed
                    <br />
                    Successfully
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1rem',
                }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        fontWeight: 'bold',
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                        fontSize: '2rem',
                    }}
                >
                    You Scored: {percentage}%
                </Typography>
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: '500',
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                        fontSize: "1.5rem"
                    }}
                >
                    {performanceMessage}
                </Typography>
            </Box>
            <Button
                onClick={handleTryAgain}
                sx={{
                    width: "fit-content",
                    backgroundColor: '#2356fe',
                    color: 'white',
                    padding: { xs: '8px 16px', md: '10px 20px' },
                    borderRadius: '25px',
                    display: 'flex',
                    alignItems: 'center',
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: '#1d4fd1',
                    },
                }}
            >
                <Box
                    sx={{
                        marginRight: '10px',
                    }}
                >
                    <ReplayIcon />
                </Box>
                <Typography sx={{ color: "white" }}>Try Again</Typography>
            </Button>
        </Box>
    );
};

export default CompleteCalculator;
