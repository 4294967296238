import React from "react";

const ShowAllClubs = () => {
  


  return (
    <>
      {/* <ShowAllClubComponent allClub={allClub} getAllClubs={getAllClubs}
       privateClub={privateClub} publicClub={publicClub}
       allClubInstitute={allClubInstitute} 
       getAllInstituteClub={getAllInstituteClub}
       allClubStudent={allClubStudent}
       getAllStudentClub={getAllStudentClub}
       /> */}
    </>
  );
};

export default ShowAllClubs;
