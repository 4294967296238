import React, { useRef } from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import SheetSelection from './steps/SheetSelection';
import HeaderSelection from './steps/HeaderSelection';
import HeaderMapping from './steps/HeaderMapping';
import ExcelReview from './steps/ExcelReview';
import { useSubAdminCSV } from 'contexts/SubAdminCSVContext';
import YuvaLoader from 'pages/Forum/components/Loader/YuvaLoader';

const StepWizard = () => {
    const { currentStep, setSheetView, handleBack, handleSubmitSheet, handleNext, selectedSheet, loading } = useSubAdminCSV();
    const excelReviewRef = useRef(null);

    const steps = ['Select Sheet', 'Select Header Row', 'Map Headers', 'Review and Edit Data'];

    const handleClose = () => {
        if (currentStep === 0) {
            setSheetView(false);
        } else {
            handleBack();
        }
    };

    const handleSubmitClick = () => {
        if (excelReviewRef.current) {
            const submittedPayload = excelReviewRef?.current?.handleSubmit();
            if (submittedPayload) {
                handleSubmitSheet(submittedPayload);
            }
        }
    };

    // Render content for each step
    const renderStepContent = () => {
        switch (currentStep) {
            case 0: return <SheetSelection />;
            case 1: return <HeaderSelection />;
            case 2: return <HeaderMapping />;
            case 3: return <ExcelReview ref={excelReviewRef} />;
            default: return null;
        }
    };

    // Determine if the current step is the last one
    const isLastStep = currentStep === steps.length - 1;

    return (
        <Container sx={{ padding: '0 !important' }}>
            {loading && <YuvaLoader setShow={false} show={loading} />}
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: "center",
                gap: '1rem',
                marginBottom: 3,
            }}>
                {/* Show the current step title */}
                <Typography variant="h4">{steps[currentStep]}</Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    alignItems: "center",
                    gap: '1rem',
                }} >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClose}
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={isLastStep ? handleSubmitClick : handleNext}
                        disabled={selectedSheet === null || loading}
                    >
                        {isLastStep ? 'Submit' : 'Next'}
                    </Button>
                </Box>
            </Box>
            {/* Render step-specific content */}
            {renderStepContent()}
        </Container >
    );
};

export default StepWizard;
