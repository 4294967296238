import { Box, Button, Typography } from '@mui/material';
import { useSubAdminCSV } from 'contexts/SubAdminCSVContext'
import React, { useEffect } from 'react'
import StepWizard from './StepWizard';
import FileList from './FileList';
import YuvaLoader from 'pages/Forum/components/Loader/YuvaLoader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Popup } from 'layout/Popup';

const SubAdminHandleCSV = () => {
    const { sheetView, data, fetchData, loading, setLoading } = useSubAdminCSV();
    const navigate = useNavigate();
    const { instituteId } = useParams();
    const location = useLocation();
    const userRole = location.pathname.includes('admin') ? 'admin' :
        location.pathname.includes('subAdmin') ? 'subAdmin' : null;
    const handleClose = () => {
        navigate(`/${userRole}/handle-institute-csv`);
    }
    useEffect(() => {
        const loadFiles = async () => {
            try {
                setLoading(true);
                await fetchData(instituteId);
            } catch (e) {
                Popup('error', 'Server Error', 'An error occurred while fetching Files', 3000);
            } finally {
                setLoading(false);
            }
        };

        loadFiles();
    }, []);

    return (
        <Box sx={{ padding: '5%', position: 'relative' }}>
            {loading && <YuvaLoader setShow={false} show={loading} />}
            {sheetView ? (
                <StepWizard />
            ) : (
                <>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: "center",
                        gap: '1rem',
                        marginBottom: 3,
                    }}>
                        <Typography variant="h4" color="#2356FE" sx={{ marginBottom: 3 }}>
                            Files for Institute
                        </Typography>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleClose}
                        >
                            Go Back
                        </Button>
                    </Box>
                    <FileList role="student" />
                    <FileList role="teacher" />
                    {data?.student?.length === 0 && data?.teacher?.length === 0 && (
                        <Typography variant="body1" color="textSecondary">
                            No files available for Student or Teacher.
                        </Typography>
                    )}
                </>
            )
            }
        </Box>
    )
}
export default SubAdminHandleCSV