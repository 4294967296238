import { apiJson } from 'api';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import jwt from 'jwt-decode';
import { useGlobalContext } from 'global/context';

export const OnboardingComponentVerify = () => {
  const {token} = useParams()
  const {  setUser } = useGlobalContext()
  const [decodedToken, setDecodedToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const closeTab = () => {
      // window.close(); 
  };

  const verifyingUser = ()=>{
    console.log('==>', decodedToken)
    if(decodedToken?.type==='institute'){
      apiJson.put('v2/register/verifying/institute',{
        email:decodedToken?.email
      })
      .then((res)=>{
        setLoading(false)
        closeTab()
        if(decodedToken?.isNipam=='true'){
          navigate('/newnip?count=4&type=Institution')
          setUser({ email: decodedToken?.email, token,isNipam:decodedToken?.isNipam});
        }else{
          navigate('/registration?count=8&type=Institution')
          setUser({ email: decodedToken?.email, token});
        }
        console.log(res.data)
      })
      .catch((error)=>{
        setLoading(false)
        console.log(error.message)
      })
    }
    if(decodedToken?.type==='student'){
      apiJson.put('v2/register/verifying/user',{
        email:decodedToken?.email,
        role:'student'
      })
      .then((res)=>{
        closeTab()
        navigate(`/registration?registration_type=url&count=8&type=Student&instituteId=${decodedToken?.instituteId}`)
        setUser({ email: decodedToken?.email, token});
      })
      .catch((error)=>{
        
        console.log(error.message)
      })
    }
    if(decodedToken?.type==='teacher'){
      apiJson.put('v2/register/verifying/user',{
        email:decodedToken?.email,
        role:'teacher'
      })
      .then((res)=>{
        closeTab()
        navigate(`/registration?registration_type=url&count=8&type=Teacher&instituteId=${decodedToken?.instituteId}`)
        setUser({ email: decodedToken?.email, token});

        console.log(res.data)
      })
      .catch((error)=>{
        
        console.log(error.message)
      })
    }
  }

  useEffect(() => {
    const decoded = jwt(token);
    setDecodedToken(decoded);
  }, []);

  useEffect(()=>{
    if(decodedToken){
      verifyingUser()
    }
  },[decodedToken])

  return (
    <>
      {loading && (
      <div className="google-verify-loader-overlay">
        <div className="google-verify-loader"></div>
      </div>
    )}
    </>
  )
}
