import { useGlobalContext } from 'global/context';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

export const OnboardingGoogleVerify = () => {
    const navigate = useNavigate()
    const {setUser, setToken,token} = useGlobalContext()
    const [loading, setLoading] = useState(true);
    const userObjectString = decodeURIComponent(window.location.search.split('=')[1]);

    const userObject = JSON.parse(userObjectString);
    
    useEffect( ()=>{
        // const userObjectString = decodeURIComponent(window.location.search.split('=')[1]);
        // const userObject = JSON.parse(userObjectString);
        console.log('userobject', userObject)

        setUser(userObject.user)
        setToken(userObject.jwt)
        
    },[])
    useEffect(()=>{
     

       if(token){
        // navigate('/new-dashboard')
        if(userObject?.user?.nipamCheck && ['student', 'teacher'].includes(userObject?.user?.role)){
          console.log('this is course')
          navigate('/course/detail/4')
        }else{
          navigate('/new-dashboard')

        }
       }
    },[token])
  return (
    <div>
    
    {loading && (
      <div className="google-verify-loader-overlay">
        <div className="google-verify-loader"></div>
      </div>
    )}
  </div>
  )
}

