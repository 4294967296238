import React from 'react';
import AddedPost from './AddedPost';
import CommentPostActivity from './CommentPostActivity';

const AllActivity = ({ data }) => {
  const renderContent = () => {
    switch (data?.actionType) {
      case 'post':
        return (
          <><AddedPost data={data} /></>
        );
      case 'comment':
        return (
          <><CommentPostActivity data={data} /></>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
};

export default AllActivity;
