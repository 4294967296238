import React, { useState, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Pagination, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useCareerContext } from 'contexts/CareerContext';
import YuvaLoader from 'pages/Forum/components/Loader/YuvaLoader';
import DownloadIcon from '@mui/icons-material/Download';

const StudentListView = () => {
    const { classInfo, studentsList, setSelectedSubNavOption, setSelectedStudentId } = useCareerContext();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState('all');
    const itemsPerPage = 15;  // Display 15 records per page
    const [csvData, setCsvData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const filtered = studentsList.filter(student => {
            const fullName = (student.first_name + ' ' + (student.last_name || '')).toLowerCase().replace(/\s+/g, '');
            const matchesSearch = fullName.includes(searchQuery.toLowerCase().replace(/\s+/g, ''));
            const matchesFilter = filter === 'all' || (filter === 'attempted' && student.result) || (filter === 'notAttempted' && !student.result);
            return matchesSearch && matchesFilter;
        });
        setFilteredStudents(filtered);
        setCsvData(filtered);  // Update csvData with filtered students
        setCurrentPage(1);
    }, [searchQuery, studentsList, filter]);

    // Function to determine the category based on score
    const getCategory = (score) => {
        console.log("Score was: ", score);
        if (score === null || score === undefined) return 'N/A';  // Handle cases where score is not available
        if (score >= 237) return 'Highly Proficient';
        if (score >= 215) return 'Proficient';
        if (score >= 170) return 'Competent';
        if (score >= 148) return 'Basic';
        return 'Emerging';
    };

    const downloadCSV = () => {
        setLoading(true);
        const headers = ['Id', 'Student Name', 'Parent Name', 'Category'];  // Define the headers
        const rows = csvData.map((student) => [
            student.id,
            student.first_name + " " + (student.last_name || ""),
            student.father_name,
            student.result ? getCategory(student.result) : 'N/A',
        ]);

        const csvContent = [headers, ...rows]
            .map(e => e.join(','))
            .join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${classInfo?.className}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
    };

    const paginatedStudents = filteredStudents.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleViewResult = (id) => {
        setSelectedStudentId(id);
        setSelectedSubNavOption('View Result');
    }

    return (
        <Box sx={{ padding: 4 }}>
            {loading && <YuvaLoader setShow={false} show={loading} />}
            {/* Search and Filter Container */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 4 }}>
                <TextField
                    label="Search by Name"
                    variant="outlined"
                    fullWidth
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{ marginRight: 2, flex: 1 }}
                />

                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                    <InputLabel>Filter</InputLabel>
                    <Select
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        label="Filter"
                    >
                        <MenuItem value="all">All Students</MenuItem>
                        <MenuItem value="attempted">Attempted Test</MenuItem>
                        <MenuItem value="notAttempted">Not Attempted Test</MenuItem>
                    </Select>
                </FormControl>

                <Button
                    variant="contained"
                    onClick={downloadCSV}
                    startIcon={<DownloadIcon />}
                    sx={{
                        backgroundColor: 'green',
                        padding: '1rem',
                        color: 'white',
                        borderColor: 'green',
                        '&:hover': {
                            backgroundColor: 'white',
                            color: 'green',
                            borderColor: 'green',
                        },
                        marginLeft: 2,
                        textTransform: "Capitalize",
                    }}
                    disabled={loading}
                >
                    Detailed Data
                </Button>
            </Box>

            {/* Students List Table */}
            <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
                <Table sx={{ minWidth: 650 }} aria-label="students table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>S No.</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Student Name</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Parent Name</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Result</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Category</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedStudents.length ? (
                            paginatedStudents.map((student, index) => (
                                <TableRow key={student.id}>
                                    <TableCell>{(currentPage - 1) * itemsPerPage + index + 1}</TableCell>
                                    <TableCell>{student.first_name + ' ' + (student.last_name || '')}</TableCell>
                                    <TableCell>{student.father_name}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleViewResult(student.id)}
                                            disabled={!student.result}
                                        >
                                            See Result
                                        </Button>
                                    </TableCell>
                                    <TableCell>{student.result ? getCategory(student.result) : 'N/A'}</TableCell> {/* Show category based on score */}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    No students found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Pagination */}
            {
                filteredStudents.length > itemsPerPage && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Pagination
                            count={Math.ceil(filteredStudents.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                )
            }
        </Box>
    );
};

export default StudentListView;
