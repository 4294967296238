import { Avatar, Button, Divider } from '@mui/joy'
import { apiJson } from 'api'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FilePresentTwoTone } from "@mui/icons-material";
import { ButtonBase } from "@mui/material";
import * as XLSX from 'xlsx';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';

export const AdminAllnipamInstitute = () => {
    const [instituteData, setInstituteData] = useState([]);
    const [selectDate, setSelectDate] = useState({ startDate: "", endDate: "" });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [nipamData , setNipamData] = useState('nipamDataSubmit')
    const [downloadNipamData , setDownloadNipamData] = useState('')
    const navigate = useNavigate()

    const getInstituteData = () => {
        apiJson(`api/v2/admin/getNipamData`)
            .then((res) => {
                setInstituteData(res?.data?.result);
                console.log(res.data.result)
                // setTotalCount(res?.data?.getInsData?.count);
                setSelectDate({ startDate: "", endDate: "" });
            }).catch((error) => {
                toast.dismiss()
                toast.error('Internal server error')
            })
    }

    const handleInstituteSearch = (e) => {
        handleSearchChange(e)
    }

    const handleSearchChange = (e) => {
        if (e.key === 'Enter' || e.type === 'click') {
            const searchInput = document.getElementById("searchId").value
            SearchData(searchInput)
        }
        else if (!e.target.value) {
            getInstituteData()
        }
    }

    const SearchData = (searchInput) => {
        console.log(searchInput)
        apiJson(`api/v2/admin/searchNipamData?query=${searchInput}`)
            .then((res) => {
                console.log(res.data.result);
                setInstituteData(res.data.result)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleDateSelect = (name, value) => {
        setSelectDate(prevState =>
            ({ ...prevState, [name]: value }));
    }

    const getDataByDate = () => {
        apiJson.get(`api/v2/admin/allNipamWithDataDate/${selectDate?.startDate}/${selectDate?.endDate}/${nipamData}`)
            .then((res) => {
                // console.log(res.data)
                // setNipamData('submit')
                setDownloadNipamData('download')
                setInstituteData(res?.data?.result)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getInstituteData()
    }, [])

    // downloadSheet
    const downloadData = () => {
        apiJson(`api/v2/admin/allNipamWithDataDate/${selectDate?.startDate}/${selectDate?.endDate}/${downloadNipamData}`)
            .then((res) => {
                const allData = res?.data?.result;

                // Export data to Excel
                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.json_to_sheet(allData);
                XLSX.utils.book_append_sheet(wb, ws, "Institute Data");
                XLSX.writeFile(wb, "institute_data.xlsx");
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // nextPagePagination
    const handlePaginationNext = () => {
        let increment = Number(page) + Number(rowsPerPage);
        console.log("increment", increment)
        if (increment < totalCount) {
            setPage(increment);
        }
    };
    // prevPagePagination
    const handlePaginationPrev = () => {
        let decrement = Number(page) - Number(rowsPerPage);
        console.log("decrement", decrement)
        if (decrement > 0) {
            setPage(decrement);
        } else {
            setPage(0);
        }
    };

    // getNipamData
    const getNipamData = () => {
        apiJson.get('api/v2/admin/getNipamData')
            .then((res) => {
                // console.log(res.data.result);
                setNipamData('nipamData')
                setDownloadNipamData('downloadNipam')
                setInstituteData(res.data.result)
            })
            .catch((err) => {
                console.log(err.message);
            })
    } 

        // getNipamWithoutKv
        const getNipamWithoutKv = () => {
            apiJson.get('api/v2/admin/getNipamDataWithoutKv')
                .then((res) => {
                    // console.log(res.data.result);
                    setNipamData('nipamWithoutKv')
                    setDownloadNipamData('downloadNipamWithoutKv')
                    setInstituteData(res.data.result)
                })
                .catch((err) => {
                    console.log(err.message);
                })
        }

        // getNipamWithoutKv
        const getNipamWithKv = () => {
            apiJson.get('api/v2/admin/allNipamWithKv')
                .then((res) => {
                    // console.log(res.data.result);
                    setNipamData('nipamWithKv')
                    setDownloadNipamData('downloadNipamWithKv')
                    setInstituteData(res.data.result)
                })
                .catch((err) => {
                    console.log(err.message);
                })
        }

    return (
        <>
            <div className='container col-12 col-md-10 mx-auto mt-4'>

                <div className='row mb-2'>

                    <div className='col-12 col-lg-6'>
                        <div className='d-flex justify-content-between'>
                        <div className=''><span className='fs-3 fw-semibold'>Nipam Details</span></div>
                        <div>
                                <button style={{background: '#6100ff'}} className='text-white rounded py-2 px-1' onClick={getNipamWithKv}>Nipam kv</button>
                            </div>
                    </div>
                        </div>

                    <div className='col-12 col-lg-6'>
                        <div className='d-flex align-items-center text-center justify-content-between'>
                            
                            <div className='mb-1'>
                                <button style={{background: '#6100ff'}} className='text-white rounded py-2 px-1' onClick={getNipamWithoutKv}>Nipam without kv</button>
                            </div>
                            <div className=''>
                                <input className='form-control box-shadow-0 rounded background-grey border-0' id='searchId' style={{height:45}} onKeyDown={handleSearchChange} placeholder='Search' />
                            </div>

                            <div className=''>
                                <button className='btn btn-success fw-thin text-white border-0 rounded' onClick={handleInstituteSearch} style={{ background: "#7e27ec", color: 'white', padding: "10px 12px"}}>Search</button>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='row  mb-2'>
                    <div className='col-12 col-md-6 col-lg-3'>
                    <input type="date" className='form-control box-shadow-0 rounded background-grey border-1 mb-2' style={{height:45}}
                        onChange={(e) => handleDateSelect('startDate', e.target.value)} value={selectDate?.startDate} name="startDate" id=""  /></div>
                    <div className='col-12 col-md-6 col-lg-3'>
                    <input type="date" className='form-control box-shadow-0 rounded background-grey border-1 mb-2' style={{height:45}}
                        onChange={(e) => handleDateSelect('endDate', e.target.value)} value={selectDate?.endDate} name="endDate" id="" /></div>
                    <div className='col-12 col-md-12 col-lg-3 d-flex align-items-center  justify-content-between mb-2'>
                        <Button onClick={() => getInstituteData()} variant='outlined' color='danger' className='w-50 me-2' sx={{height:45}}>Reset</Button>
                       <Button onClick={getDataByDate} className='w-50' sx={{height:45}}>Submit</Button>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3 mt-3 mt-md-0'>
                        <Button type="button" variant="outlined" color="success" style={{ border: "1px solid #8fcdff",height:45 }} data-bs-toggle="" data-bs-target="#staticBackdrop" onClick={downloadData} className="text-capitalize  rounded-3 px-4 w-100">
                            <FilePresentTwoTone />
                            Download&nbsp;Sheet
                        </Button>
                    </div>
                </div>
       <div className="mt-3">
                <TableContainer component={Paper} >
                    <Table aria-label="simple table">
                        <TableHead sx={{background:"#a365f1"}}>
                            <TableRow>
                                <TableCell className='fw-semibold'  sx={{color:"#ffffff"}}>Logo</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>Institute Name</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>Email</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}> Contact</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ padding: "5px 10px" }}>
                            {instituteData?.map((row) => (
                                <TableRow
                                    key={row?.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, marginTop: "5px" }}
                                >
                                <TableCell >

                                    <Avatar src={row?.logo} />
                                </TableCell>
                                    <TableCell align="center">{row?.institution_name}</TableCell>
                                    <TableCell align="center">{row?.email}</TableCell>
                                    <TableCell align='center'>{row?.contact}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
       </div>
                <Divider className="my-3" />

                {/* <div className='row'>
                    <div className='col-6 col-md-4 col-lg-4'>
                        <div >
                        <Button className='text-center' onClick={getNipamData}>Nipam Data</Button>
                        </div>
                    </div>
                    <div className='col-6 col-md-4 col-lg-4'>
                        <div>
                            <Button className='text-center' onClick={getNipamWithoutKv}>Nipam Without Kv</Button>
                        </div>
                    </div>
                    <div className='col-12 col-md-4 col-lg-4 text-center'>
                        <div>
                            <Button className='text-center px-2' onClick={getNipamWithKv}>Nipam Kv</Button>
                        </div>
                    </div>
                </div> */}
                {/* {instituteData?.map((ele) => {
                    return (
                        <>
                            <div className='row justify-content-between align-items-center rounded-2 text-center mt-3' style={{ background: "" }}>
                                <div className='col-12 col-lg-2'>
                                    <Avatar src={ele?.logo}></Avatar>
                                </div>
                                <div className='col-12 col-lg-3'>
                                    <div><span className='fs-6 fw-500'>{ele?.institution_name}</span></div>
                                </div>
                                <div className='col-12 col-lg-4'>
                                    <div><span className='fs-6 fw-500'>{ele?.email}</span></div>
                                </div>
                                <div className='col-12 col-lg-3'>
                                    <Button onClick={() => navigate(`/admin/instituteDetails/${ele?.institute_id}`)}>Details</Button>
                                </div>
                            </div>
                            <Divider className="my-3" />
                        </>
                    )
                })
                } */}

                {/* <div className="bg-light p-2 mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="text-dark">
                            Showing {instituteData?.length} out of {totalCount}
                        </span>
                        <div className="d-flex align-items-center">
                            <select name="rowsPerPage" value={rowsPerPage} className="form-select form-select-sm" onChange={(e) => setRowsPerPage(e.target.value)}>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                                <option value="30">30</option>
                            </select>
                            <Button size="small" variant="outlined" disabled={Number(page) < Number(rowsPerPage) ? true : false} onClick={handlePaginationPrev} className="text-capitalize h-100 mx-2">
                                prev
                            </Button>
                            <ButtonBase>{page ? page / rowsPerPage + 1 : 1}</ButtonBase>
                            <Button size="small" onClick={handlePaginationNext} disabled={Number(page) + Number(rowsPerPage) + 1 > totalCount ? true : false} variant="outlined" className="text-capitalize h-100 ms-2">
                                next
                            </Button>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}