import React from "react";
// import driveIconimg from "./assests/modelUn/driveIcon.svg"
import { apiJson } from "api";
import { useGlobalContext } from "global/context";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CountDownTimer from "./CountDownTimer";
import { FallingLines } from "react-loader-spinner";

const ModelUnUnivercity = ({
  logo,
  undpimg,
  drdoimg,
  univercityimg,
  stdetail,
  isRegister,
}) => {
  const [isRegis, setIsRegis] = useState(false);
  const { userData, setUser } = useGlobalContext();
  isRegister(isRegis);
  const [loader, setLoader] = useState(false);
  const [dataFromChild, setDataFromChild] = useState(null);

  // Function to receive data from the child component
  const receiveDataFromChild = (data) => {
    setDataFromChild(data);
  };

  const geteventDetail = () => {
    const id =
      userData?.role === "institute" ? userData?.id : userData?.instituteId;
    apiJson("api/v2/modelUn-student/getEventDetails/institute/" + id)
      .then((res) => {
        if (res?.data?.result?.id) {
          setIsRegis(true);
        } else {
          setIsRegis(false);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  useEffect(() => {
    geteventDetail();
    receiveDataFromChild()
  }, []);
  const navigate = useNavigate();
  const data = [
    {
      id: 1,
      logo: logo,
      partnerShiplogo: undpimg,
      univercitylogo: univercityimg,
      univercityName: "Bangalore University",
      eventName: "Yuvamanthan Model United Nations",
      eventTheme: "Lifestyle for Environment (LiFE)",
      eventType: "INTER-UNIVERSITY",
      eventDate: "21st Jul, 2023",
      eventVannue: "Santosh Auditorium, Hansraj College, University of Delhi",
    },
  ];

  const [UnivercityAllData, setUnivercityAllData] = useState([]);
  const [UnivercitymergeObjects, setUnivercitymergeObjects] = useState([]);

  function mergeObjects(obj1, obj2) {
    for (let prop in obj2) {
      if (obj2.hasOwnProperty(prop)) {
        obj1[prop] = obj2[prop];
      }
    }
    return obj1;
  }

  function mergeObjectsFromArray(objectsArray) {
    if (objectsArray.length < 1) {
      // If there are fewer than two objects, return the first (or only) object
      return objectsArray[0] || {};
    }

    let mergedObject = objectsArray[0];

    for (let i = 1; i < objectsArray.length; i++) {
      mergedObject = mergeObjects(mergedObject, objectsArray[i]);
    }

    return mergedObject;
  }

  let mergedObjectFromArray = mergeObjectsFromArray(UnivercityAllData);

  useEffect(() => {
    setUnivercitymergeObjects([mergedObjectFromArray]);
  }, [UnivercityAllData]);

  const univercityDatas = () => {
    try {
      setLoader(true)
      apiJson
        .get(
          `/api/v2/modelUn-institute/institute-details/${
            userData?.role === "institute"
              ? userData?.id
              : userData?.instituteId
          }`
        )
        .then((res) => {
          setUnivercityAllData(res.data.data);
          setLoader(false)
        });
    } catch (err) {
      console.log(err.message);
      // console.log("something went wrong while fetching univercity data");
    }
  };
  useEffect(() => {
    univercityDatas();
  }, [userData.id]);

  return (
    <>
    {
      loader?( 
        <div className="d-flex justify-content-center align-items-center w-75">

        <FallingLines color="blue" />
        </div>
        ):(    <div className="my-3">
      {isRegis ? (
        <div className="d-flex justify-content-center">
          <div className="modelun-univercitys d-flex justify-content-center flex-column align-items-center flex-md-row gap-3">
            {/* //card */}

            {UnivercitymergeObjects?.map((val) => {
              return (
                <div className="modelun-univer-items p-3 mx-4">
                  <div className="d-flex justify-content-between">
                    <img src={data[0].logo} alt="logo" />
                    <span className="d-flex flex-column align-items-center institute-imgitems">
                      <span className="miniimagpara me-3">
                        In Partnership with
                      </span>
                      <img
                        src={data[0].partnerShiplogo}
                        alt="unpdimage"
                        className="me-3 ms-3"
                        width="100%"
                      />
                    </span>
                  </div>

                  <div className="mt-2 p-0">
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                        }}
                      >
                        <img
                          src={val?.logo}
                          alt="univercityicon"
                          className="border-0 w-100"
                        />
                      </div>
                      <p className="fw-bold univercitypara pt-1 m-0">
                        {val?.institution_name}
                      </p>
                    </div>
                    <p className="text-center cardPara fw-bold m-0 mb-1">
                      Presents <br />
                      {data[0].eventName}
                    </p>
                    <p className="cardThemePara fw-bold text-center m-0">
                      THEME <br /> {val?.format}
                    </p>
                    <p className="interPara m-0 my-1">{val?.event_type}</p>
                    <p className="registrationPara fw-bold m-0">
                      Time Left Registration of Students
                    </p>
                    <div className="modelun-Date px-1">
                      <CountDownTimer
                        date={val?.last_date}
                        time={val?.event_time}
                        sendDataToParent={receiveDataFromChild}
                      />
                    </div>
                   {dataFromChild  && (
                    <div className="modelun-Days d-flex justify-content-between px-1">
                      <div className="day">Days</div>
                      <div className="hour">Hours</div>
                      <div className="minute">Minutes</div>
                      <div className="sec">Seconds</div>
                    </div>
                   )} 

                    <p className="text-center eventModel-para m-0">
                      Date of Event
                    </p>
                    <p className="modelunDatepara text-center m-0">
                      {val?.date_proposed}
                    </p>
                    <p className="text-center modelCardLastPara m-0">
                      {val?.event_venue}
                    </p>

                    <div className="modelunCard-buttons mt-3 d-flex justify-content-center">
                      {isRegis ? (
                        <div className="mb-4 mt-3 mt-md-0">
                          {userData.role === "institute" ? (
                            <button
                              onClick={() => navigate("munDashboard")}
                              style={{
                                textAlign: "center",
                                background: "#9700DE",
                                color: "white",
                                borderRadius: "8px",
                              }}
                            >
                              Go To Dashboard
                            </button>
                          ) : stdetail ? (
                            <button
                              onClick={() => navigate("/modelUn/munDashboard")}
                              style={{
                                textAlign: "center",
                                background: "#9700DE",
                                color: "white",
                                borderRadius: "8px",
                              }}
                            >
                              Go To Dashboard
                            </button>
                          ) : (
                            dataFromChild  && (
                            <button
                              onClick={() => navigate("participates")}
                              style={{
                                textAlign: "center",
                                background: "#9700DE",
                                color: "white",
                                borderRadius: "8px",
                              }}
                            >
                              Participate Now
                            </button>)
                          )}
                        </div>
                      ) : (
                        <div
                          onClick={() => navigate("/model-un/registration")}
                          className="mb-4 mt-3 mt-md-0"
                        >
                          <button
                            style={{
                              textAlign: "center",
                              background: "#9700DE",
                              color: "white",
                              borderRadius: "8px",
                            }}
                          >
                            Register Now
                          </button>
                        </div>
                      )}

                      {/* <button>Register Now</button> */}
                      <div className="mb-4 mt-3 mt-md-0">
                        <button
                          style={{
                            textAlign: "center",
                            background: "white",
                            color: "purple",
                            borderRadius: "8px",
                            boxShadow: "1px 1px 20px silver",
                          }}
                          onClick={()=>navigate("/ymun")}
                        >
                          Explore More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : ( 
        userData?.role ==="institute" ? (
        <div className="mb-4 mt-3 mt-md-0 d-flex flex-column align-items-center justify-content-center w-75 border shadow p-3 rounded-3 m-auto" style={{background:"#f5f6f7"}}>
          <p>Sorry, you have not registered for any event yet!</p>
          <button
            onClick={() => navigate("/model-un/registration")}
            style={{
              textAlign: "center",
              background: "#9700DE",
              color: "white",
              borderRadius: "8px",
            }}
            className="px-3 py-2"
          >
            Register Now
          </button>
        </div>
        ): null
        
      )}
    </div>)
    }

    </>
  );
};

export default ModelUnUnivercity;
