import React, { useState, useEffect } from 'react';
import {
    Checkbox, FormControl, Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Button, TextField, Pagination
} from '@mui/material';
import { CircleOutlined, CheckCircleOutlined } from '@mui/icons-material';
import { apiJson } from 'api'; // Assuming apiJson is correctly set up
import { Popup } from 'layout/Popup';
import YuvaLoader from 'pages/Forum/components/Loader/YuvaLoader';
import { useGlobalContext } from 'global/context';

const AddCareerGuide = () => {
    const [teachers, setTeachers] = useState([]);
    const [filteredTeachers, setFilteredTeachers] = useState([]);
    const [mentorData, setMentorData] = useState({});
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const { userData } = useGlobalContext();
    const itemsPerPage = 15;

    const classOptionsRiasec = [6, 7, 8, 9, 10, 11, 12];
    const classOptionsLsmt = [6, 7, 8];

    const fetchTeachers = async () => {
        setLoading(true);
        try {
            const res = await apiJson.get(`/api/v2/riasecTest/riasec-unassigned/${userData.id}`);
            if (res.data.result.length === 0) {
                setTeachers([]);
            } else {
                setTeachers(res.data.result);
                setFilteredTeachers(res.data.result);
            }
            setLoading(false);
        } catch (err) {
            Popup('error', 'Server Busy', 'Please Try again later', 3000);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTeachers();
    }, [userData.id]);

    const handleSearchChange = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setSearchTerm(searchValue);
        const filtered = teachers.filter((teacher) => {
            const fullName = `${teacher.first_name} ${teacher.middle_name || ''} ${teacher.last_name}`.trim();
            return fullName.toLowerCase().includes(searchValue);
        });
        setFilteredTeachers(filtered);
        setCurrentPage(1); // Reset to page 1 after search
    };

    const handleClassChange = (teacherId, program, selectedClasses) => {
        setMentorData((prev) => ({
            ...prev,
            [teacherId]: {
                ...prev[teacherId],
                [program]: selectedClasses
            }
        }));
    };

    const isChecked = (teacherId) => {
        const teacher = mentorData[teacherId] || {};
        return (teacher.riasec && teacher.riasec.length > 0) || (teacher.lsmt && teacher.lsmt.length > 0);
    };

    const handleSubmit = async () => {
        const filteredMentorData = Object.entries(mentorData)
            .filter(([teacherId, classes]) => {
                return (classes.riasec && classes.riasec.length > 0) || (classes.lsmt && classes.lsmt.length > 0);
            })
            .map(([teacherId, classes]) => ({
                teacherId: Number(teacherId),
                riasec: classes.riasec || [],
                lsmt: classes.lsmt || []
            }));

        if (filteredMentorData.length === 0) {
            Popup('error', 'No teacher selected', 'Please assign classes to at least one teacher', 3000);
            return;
        }

        const payload = {
            mentorData: filteredMentorData,
            instituteId: userData.id
        };

        try {
            setLoading(true);
            await apiJson.post('/api/v2/riasecTest/riasec-guide-assign', payload);
            setLoading(false);
            Popup('success', 'Success', 'Mentor data submitted successfully.', 3000);
            setMentorData({});
            fetchTeachers();
        } catch (error) {
            setLoading(false);
            Popup('error', 'Server Error', 'Failed to submit mentor data. Please try again later.', 3000);
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const paginatedTeachers = filteredTeachers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <>
            {loading ? (
                <YuvaLoader setShow={false} show={loading} />
            ) : (
                <>
                    <TextField
                        label="Search by Name"
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: '1.5rem' }}
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Sno.</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>RIASEC</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>LSMT</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#333' }}>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedTeachers.map((teacher, index) => (
                                <TableRow key={teacher.id}>
                                    <TableCell>{(currentPage - 1) * itemsPerPage + index + 1}</TableCell>
                                    <TableCell>{`${teacher.first_name} ${teacher.middle_name || ''} ${teacher.last_name}`}</TableCell>

                                    <TableCell>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', textAlign: "center" }}>
                                            {classOptionsRiasec.map((cls) => (
                                                <FormControl key={cls}>
                                                    <Checkbox
                                                        checked={mentorData[teacher.id]?.riasec?.includes(cls) || false}
                                                        onChange={(e) =>
                                                            handleClassChange(teacher.id, 'riasec', e.target.checked
                                                                ? [...(mentorData[teacher.id]?.riasec || []), cls]
                                                                : mentorData[teacher.id]?.riasec?.filter((c) => c !== cls)
                                                            )
                                                        }
                                                    />
                                                    <Typography>{cls}</Typography>
                                                </FormControl>
                                            ))}
                                        </Box>
                                    </TableCell>

                                    <TableCell>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', textAlign: "center" }}>
                                            {classOptionsLsmt.map((cls) => (
                                                <FormControl key={cls}>
                                                    <Checkbox
                                                        checked={mentorData[teacher.id]?.lsmt?.includes(cls) || false}
                                                        onChange={(e) =>
                                                            handleClassChange(teacher.id, 'lsmt', e.target.checked
                                                                ? [...(mentorData[teacher.id]?.lsmt || []), cls]
                                                                : mentorData[teacher.id]?.lsmt?.filter((c) => c !== cls)
                                                            )
                                                        }
                                                    />
                                                    <Typography>{cls}</Typography>
                                                </FormControl>
                                            ))}
                                        </Box>
                                    </TableCell>

                                    <TableCell>
                                        {isChecked(teacher.id) ? <CheckCircleOutlined sx={{ color: 'green' }} /> : <CircleOutlined sx={{ color: 'grey' }} />}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination
                        count={Math.ceil(filteredTeachers.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        sx={{ marginTop: '1.5rem' }}
                    />

                    <Button variant="contained" onClick={handleSubmit} sx={{ marginTop: '2rem' }}>Submit</Button>
                </>
            )}
        </>
    );
};

export default AddCareerGuide;
