import { Box, Button, Typography } from "@mui/material";
import OtpInput from "react-otp-input";
import React, { useEffect, useState } from "react";
import HealthRules from "./HealthRules";
import { useFormik } from "formik";
import * as yup from "yup";
import { apiJson } from "api";

const HealthPageContent = () => {
  const [steps, setSteps] = useState(0);
  const [otp, setOtp] = useState("");

  const [time, setTime] = useState(300);

  useEffect(() => {
    let timer;
    if (steps === 2) {
      timer = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime === 0) {
            clearInterval(timer);
            return 0;
          } else return prevTime - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer); // Clean up the timer on unmount or step change
  }, [steps]);

  const handleChange = (otp) => {
    console.log(otp, "otp value");
    setOtp(otp);
  };

  const handleLogin = () => {
    setSteps(steps + 1);
  };
  const handleSendOtp = () => {
    setSteps(steps + 1);
  };

  const handleOtpVerify = () => {
    setSteps(steps + 1);
  };

  const validationSchema = yup.object({
    phone_no: yup
      .string()
      .trim()
      .required("Please enter your phone no")
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
  });

  const formik = useFormik({
    initialValues: {
      phone_no: "",
    },

    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {

      const data1 = {};

      try {
        const response = await apiJson.post("url here", data1);
        console.log(response);

        if (response.status === 200) {
          console.log(" submitted successfully");
          resetForm();
        } else {
          console.log("Failed to submit successfully");
        }
      } catch (err) {
        console.error("API call error:", err);
        console.error("Full error object:", err);
      }
    },
  });

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            overflow: "auto",
            "@media(max-width:900px)": {
              width: "100%",
            },
          }}
        >
          <Box
            sx={{
              fontSize: "1.25rem",
              padding: "2rem",
              "@media(max-width:900px)": {
                padding: "0.5rem",
              },
            }}
          >
            {steps === 0 && (
              <Box
                sx={{
                  padding: "2rem 2rem 2rem 1.5rem",
                  boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
                  borderRadius: "1rem",
                  marginTop: "3rem",
                  backgroundColor: "var(--color-ghostwhite)",
                  border: "5px solid #2356fe",
                  boxSizing: "border-box",
                  width: "100%",
                  height: "25.713rem",
                  overflow: "hidden",
                  "@media(max-width:900px)": {
                    width: "100%",
                    overflow: "auto",
                  },
                }}
              >
                <Box>
                  <Box>
                    <Typography
                      component={"h1"}
                      sx={{
                        fontWeight: 600,
                        fontSize: "1.9rem",
                        padding: "1rem",
                      }}
                    >
                      Dear Parents,
                    </Typography>
                    <Typography
                      component={"p"}
                      sx={{ fontSize: "1.4rem", padding: "1rem" }}
                    >
                      Please Complete the mandatary{" "}
                      <Typography
                        component={"span"}
                        sx={{ fontWeight: 600, fontSize: "1.4rem" }}
                      >
                        {" "}
                        CBSE
                      </Typography>{" "}
                      medical form for your child. Follow these steps:
                    </Typography>
                  </Box>

                  <Box>
                    <Typography component={"ul"} sx={{ padding: "1rem" }}>
                      <Typography component={"li"} sx={{ fontSize: "1.4rem" }}>
                        1.{" "}
                        <Typography
                          component={"span"}
                          sx={{
                            color: "#2356fe",
                            fontWeight: 600,
                            fontSize: "1.4rem",
                          }}
                        >
                          Login:
                        </Typography>{" "}
                        Sign up or login to the Student Dashboard with your
                        registered mobile number.
                      </Typography>
                      <Typography component={"li"} sx={{ fontSize: "1.4rem" }}>
                        2.{" "}
                        <Typography
                          component={"span"}
                          sx={{
                            color: "#2356fe",
                            fontWeight: 600,
                            fontSize: "1.4rem",
                          }}
                        >
                          {" "}
                          Access Form :{" "}
                        </Typography>
                        Go to the Medical Form section.
                      </Typography>
                      <Typography component={"li"} sx={{ fontSize: "1.4rem" }}>
                        3.{" "}
                        <Typography
                          component={"span"}
                          sx={{
                            color: "#2356fe",
                            fontWeight: 600,
                            fontSize: "1.4rem",
                          }}
                        >
                          {" "}
                          Fill and Submit:
                        </Typography>{" "}
                        Complete and Submit the form.
                      </Typography>
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      textAlign: "end",
                      "@media(max-width:900px)": { textAlign: "center" },
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        fontSize: "1.3rem",
                        fontWeight: 600,
                        border: "1px solid #fff",
                        borderRadius: "0.8rem",
                        color: "white",
                        background: "#2356fe",
                        fontFamily: "Poppins",
                        padding: "0.8rem 2rem 0.8rem 2rem",
                        ":hover": {
                          background: "#2356fe",
                        },
                      }}
                      onClick={() => handleLogin()}
                    >
                      LOGIN
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}

            {steps === 1 && (
              <Box
                sx={{
                  padding: "2rem 2rem 2rem 1.5rem",
                  boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
                  borderRadius: "1rem",
                  marginTop: "3rem",
                  backgroundColor: "var(--color-ghostwhite)",
                  border: "5px solid #2356fe",
                  boxSizing: "border-box",
                  width: "100%",
                  height: "26.713rem",
                  overflow: "hidden",
                  "@media(max-width:900px)": {
                    width: "100%",
                    overflow: "auto",
                  },
                }}
              >
                <Box>
                  <Box>
                    <Typography
                      component={"h1"}
                      sx={{
                        fontWeight: 600,
                        fontSize: "1.9rem",
                        color: "#2356fe",
                      }}
                    >
                      Verification You Phone No.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography component={"p"} sx={{ fontSize: "1.3rem" }}>
                      Your Registered Mobile Number.
                    </Typography>
                    <form onSubmit={formik.handleSubmit}>
                      <Box sx={{ padding: "1.2rem 0rem 1.2rem 0rem" }}>
                        <Typography
                          component={"input"}
                          rows="3"
                          cols="40"
                          id="phone_no"
                          type="number"
                          sx={{
                            borderRadius: "0.5rem",
                            overflow: "hidden",
                            width: "20%",
                            border: "2px solid black",
                            fontSize: "1.3rem",
                            padding: "0.9rem",
                            "@media(max-width:900px)": {
                              width: "100%",
                            },
                          }}
                          value={formik.values.phone_no}
                          placeholder="Please Enter Your Number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.phone_no && formik.errors.phone_no && (
                          <Typography
                            component={"p"}
                            sx={{
                              color: "red",
                              paddingTop: "0.2rem",
                            }}
                          >
                            {formik.errors.phone_no}
                          </Typography>
                        )}
                      </Box>

                      <Box
                        sx={{
                          marginTop: "7rem",
                          textAlign: "end",
                          "@media(max-width:900px)": {
                            marginTop: "2rem",
                            textAlign: "center",
                          },
                        }}
                      >
                        <Button
                          type="submit"
                          variant="outlined"
                          sx={{
                            fontSize: "1.3rem",
                            fontWeight: 600,
                            border: "1px solid #fff",
                            borderRadius: "0.8rem",
                            color: "white",
                            background: "#2356fe",
                            fontFamily: "Poppins",
                            padding: "0.8rem 2rem 0.8rem 2rem",
                            ":hover": {
                              background: "#2356fe",
                            },
                          }}
                          onClick={() => handleSendOtp()}
                          disabled={!formik.isValid && !formik.dirty}
                        >
                          SEND OTP
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Box>
              </Box>
            )}

            {steps === 2 && (
              <Box
                sx={{
                  padding: "2rem 2rem 2rem 1.5rem",
                  boxShadow: "0 3.4px 46.5px rgba(0, 0, 0, 0.1)",
                  borderRadius: "1rem",
                  marginTop: "3rem",
                  backgroundColor: "var(--color-ghostwhite)",
                  border: "5px solid #2356fe",
                  boxSizing: "border-box",
                  width: "100%",
                  overflow: "auto",
                  height: "26.713rem",
                  "@media(max-width:900px)": {
                    width: "100%",
                    overflow: "auto",
                  },
                }}
              >
                <Box>
                  <Box>
                    <Typography
                      component={"h1"}
                      sx={{
                        fontWeight: 600,
                        fontSize: "1.9rem",
                        color: "#2356fe",
                      }}
                    >
                      Verification Code
                    </Typography>
                  </Box>
                  <Box>
                    <Typography component={"p"} sx={{ fontSize: "1.3rem" }}>
                      Enter the OTP Which has been sent to your given Number.
                    </Typography>
                    <Typography component={"p"} sx={{ fontSize: "1.3rem" }}>
                      Time left: {`${Math.floor(time / 60)}`.padStart(2, 0)}:
                      {`${time % 60}`.padStart(2, 0)}
                    </Typography>
                    <Box
                      sx={{
                        padding: "1rem",
                        "@media (max-width: 900px)": {
                          "& input": {
                            width: "2rem !important",
                            height: "2rem !important",
                            fontSize: "1rem !important",
                            margin: "0 0.3rem !important",
                          },
                        },
                        "& input": {
                          width: "2rem !important",
                          height: "2.5rem",
                          margin: "0 1rem",
                          fontSize: "1.2rem",
                          borderRadius: 1,
                          border: "1px solid #2356fe",
                          ":focus-visible": {
                            outline: "#2356fe",
                          },
                        },
                      }}
                    >
                      <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={6}
                        separator={
                          <span>
                            <strong> </strong>
                          </span>
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        marginTop: "7rem",
                        textAlign: "end",
                        "@media(max-width:900px)": {
                          marginTop: "2rem",
                          textAlign: "center",
                        },
                      }}
                    >
                      <Button
                        type="submit"
                        variant="outlined"
                        sx={{
                          fontSize: "1.3rem",
                          fontWeight: 600,
                          border: "1px solid #fff",
                          borderRadius: "0.8rem",
                          color: "white",
                          background: "#2356fe",
                          fontFamily: "Poppins",
                          padding: "0.8rem 2rem 0.8rem 2rem",
                          ":hover": {
                            background: "#2356fe",
                          },
                        }}
                        onClick={() => handleOtpVerify()}
                      >
                        Verify
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            {steps === 3 && <>Hello Aakash Thakur this is your otp {otp}</>}

            <Box sx={{ padding: "1rem" }}>
              <Box>
                <Typography
                  component={"h1"}
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "1.7rem",
                    fontWeight: 500,
                    padding: "1.3rem 0rem 0rem 0rem",
                  }}
                >
                  Rules and Regulations for Filling the Medical Form and Signing
                  Up
                </Typography>
              </Box>
              <HealthRules />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HealthPageContent;
