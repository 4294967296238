import {DeleteForever, Link } from '@mui/icons-material';
import {  IconButton, TextField,Tooltip } from '@mui/material';
import { apiAuth } from 'api';
import { useFormik } from 'formik';
import NotFoundGif from 'layout/NotFoundGif';
import React, { memo, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Button from '@mui/joy/Button';
import { useGlobalContext } from 'global/context';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';

function MediaCoverages() {
  const { userData} = useGlobalContext();
  const [mediaPost, setMediapost] = useState([]);
  const [loading, setLoading] = useState(false)

  const model = useRef();
  let id = userData.id;
const fetchMedia=async() =>{
    try {
      const { data } = await apiAuth.get(`api/v2/modelUnMedia/institute-media/${id}`);
      if(data?.result){
        setMediapost(data.result);
        console.log(data?.result)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }
  useEffect(() => {
    if(id){
      fetchMedia();
    }
  }, []);
  async function handelDelete(id) {
    if (id) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You wanted to delete this Media Coverage!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (res) => {
        if (res.isConfirmed) {
          try {
            const res = await apiAuth.delete(`api/v2/modelUnMedia/institute-media/${id}`);
            if (res) {
              toast.success('Deleted Successfully..');
              fetchMedia();
            }
          } catch (error) {
            toast.error('Somting went Wrong!!');
          }
        }
      });
    }
  }

  const form = useFormik({
    initialValues: {
      title: '',
      link: '',
      desc: '',
      img: '',
    },
    onSubmit: async function (values, actions) {
      toast.loading('Adding Media Coverage');
      try {
        if (values?.title && values?.desc && (values?.img && !values?.link) || (!values?.img && values?.link)) {
          setLoading(true)
          const res = await apiAuth.post(`api/v2/modelUnMedia/institute-media/${id}`, values);
          if (res.status == 200) {
            setLoading(false)
            actions.resetForm();
            setMediapost(false);
            fetchMedia();
            if(mediaPost?.length  < 9){
              toast.dismiss();
              toast.success(`You can add ${10 - mediaPost?.length - 1} more Media Post..`);  
            }
            else {
              toast.dismiss();
             toast.success(`You have successfully added your 10 post`);
            }
            model.current.click();
          } else {
            toast.dismiss();
            toast.error('Something Went Wrong!!');
          }
        }
        else if(values?.img && values?.link){
          toast.dismiss()
          toast.warning("Please provide a either Link or an Image")
        } else {
          toast.dismiss();
          toast.warning('Fields are Required!');
        }
      } catch (err) {
        toast.dismiss();
        toast('error', 'Something Went Worng..');
      }
    },
  });
  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <h2 className="fs-3 mx-2">Media Coverage</h2>
        {/* <Button type="submit" className="rounded-3 p-1 fs-6 fw-semibold" variant="solid" data-bs-toggle="modal" data-bs-target="#mediaCoverages"
         disabled={mediaPost?.length >= 10}
         sx={{background: "#9700DE",
                 color: "white",
                 '&:hover': {
                background: "#9700DE", 
                 color: "white", 
                    },
                 }}>
          {mediaPost?.length > 0 ? 'Add More Coverage' : 'Add Media Coverage'} 
        </Button> */}

        {mediaPost?.length === 0 &&
          <div>
            <Button type="submit"
              className="rounded-3 py-1 py-lg-3 px-1 fw-semibold d-none d-md-block d-lg-block"
              data-bs-toggle="modal" data-bs-target="#mediaCoverages"
              variant="solid"
              sx={{
                background: "#9700DE",
                color: "white",
                '&:hover': {
                  background: "#9700DE",
                  color: "white",
                },
              }}
            >
              {mediaPost?.length > 0 ? 'Add More Coverage' : 'Add Media Coverage'}
            </Button>
            <div className='d-block d-sm-none'><AddCircleOutlineTwoToneIcon className='fw-bold' data-bs-toggle="modal" data-bs-target="#mediaCoverages"
              sx={{ fontSize: '40px', color: "#7e27ec" }} /></div>
          </div>
        }
        
      </div>
      <div className="modal fade" id="mediaCoverages" tabIndex="-1" aria-labelledby="mediaCoverages" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <form
              onSubmit={form.handleSubmit}
              onSubmitCapture={(e) => {
                e.target.img.value = null;
              }}>
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="mediaCoverages">
                  Media Coverage
                </h1>
                <button type="button" ref={model} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <TextField className="my-2 rounded-5" name="title" label="Title" value={form.values.title} onChange={form.handleChange} required fullWidth sx={{background: "#F9F9F9",outline:"none" }} />
                  <TextField
                    multiline
                    minRows={2}
                    className="my-2 rounded-3"
                    name="desc"
                    label="Description"
                    value={form.values.desc}
                    onChange={form.handleChange}
                    required
                    fullWidth
                    sx={{background: "#F9F9F9",outline:"none" }}
                  />
                  <TextField className="my-2 rounded-3" name="link" label="Link" value={form.values.link} onChange={form.handleChange} fullWidth sx={{background: "#F9F9F9",outline:"none" }} />
                  <p className="mx-5 my-0 lh-1 p-0">OR</p>              
                  <Button className="my-2 w-100" variant="outlined" sx={{background: "#F9F9F9",outline:"none" }}>
                    <input
                      id="img"
                      onClick={(e) => (e.target.value = null)}
                      className="my-2 w-100"
                      accept=".png, .jpg, .jpeg"
                      type="file"
                      name="img"
                      onChange={(e) => {
                        if (e.target.files[0]?.size < 1000000) {
                          form.setFieldValue('img', e.target.files[0]);
                        } else {
                          toast.warning('File size too Large. \n Max Size 1MB.');
                          e.target.value = null;
                        }
                      }}
                      label="Image"
                    />
                  </Button>
                </div>
              </div>
              <div className="modal-footer">
              <Button type="button" className="m-2" color="error" data-bs-dismiss="modal">
                  Discard
                </Button>
                <Button className="m-2 rounded-3" type="submit" variant="solid"  sx={{background: "#9700DE",
                 color: "white",
                 '&:hover': {
                background: "#9700DE", 
                 color: "white", 
                    },
                 }} 
                 data-bs-dismiss="modal"
                 disabled={loading}
                 >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        <div className="">
          {mediaPost?.length ? (
            <div className='row'>

           { mediaPost?.map((media) => {
              return (
                <div className="col-12 col-md-6 col-lg-3 mt-3" key={media?.id}>
                  <div className="card overflow-hidden p-2 p-lg-3">
                    {media?.img ?
                      <>
                      <img style={{ height: '220px', objectFit: 'cover', borderRadius:"5px"}} src={media?.img} alt="" className="w-100 d-block" />
                    <div className="container rounded position-relative p-2">
                    <Tooltip title="Delete">
                      <IconButton
                        className="position-absolute top-0 end-0 "
                        onClick={() => {
                          handelDelete(media?.id);
                        }}>
                        <DeleteForever sx={{ color: 'tomato', fontSize: '50' }} />
                      </IconButton>
                      </Tooltip>
                      <p className="fs-5 lh-1">{media?.title}</p>
                      <small className="lh-1 line-clamp">{media?.desc}</small>
                    </div>  </>
                    : 
                    <>
                    {/* <img style={{ height: '220px', objectFit: 'cover', borderRadius:"5px"}} src={"https://glcloud.in/images/static/media/document.jpeg"} alt="" className="w-100 d-block" /> */}
                    <div className="my-4">
                      <a target="_blank" href={media?.link}>
                        <img
                          src={"https://glcloud.in/images/static/media/document.jpeg"}
                          alt=""
                          className=" p-3 w-100 d-block"
                          style={{ maxHeight: '150px', objectFit: 'contain' }}
                        />
                      </a>
                    </div>
                    <div className="container rounded position-relative p-2">
                    <Tooltip title="Delete" className='mt-3'>
                      <IconButton
                        className="position-absolute top-0 end-0 "
                        onClick={() => {
                          handelDelete(media?.id);
                        }}>
                        <DeleteForever sx={{ color: 'tomato', fontSize: '50' }} />
                      </IconButton>
                      </Tooltip>
                      <div className='mt-4'>
                      {media?.link ? (
                        <Button variant="outlined" href={media?.link} target="_blank" className="mt-2">
                          <Link /> <a href={media?.link} target="_blank">Communique Link</a>
                        </Button>
                      ) : (
                        ''
                      )}
                      </div>
                    </div>
                    </>
                    }
                  </div>
                </div>
              );
            })
            }
              {mediaPost?.length < 10 &&
                <div style={{ height: "260px" }} className="col-12 col-md-4 col-lg-3 mt-3"
                  data-bs-toggle="modal" data-bs-target="#mediaCoverages">
                  <div className='h-100 cursor-pointer shadow rounded-4 w-100 d-flex justify-content-center align-items-center'>
                    <AddIcon className='fw-semibold' sx={{ fontSize: "70px", color: "#9555fd" }} />
                  </div>
                </div>
              }
            </div>

          ) : (
            <div className="container text-center item-center mx-auto">
              <NotFoundGif text={'Media Coverage Not Added Yet!!'} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(MediaCoverages);
