import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
} from "@mui/material";
import { keyframes } from '@emotion/react';
import { styled } from '@mui/system';
import CheckPolicy from "./CheckPolicy";
import { margin } from "@xstyled/styled-components";


const PolicyForm = ({ showCheckPolicyButton, setShowCheckPolicyButton, setStartingView, instituteId , setGoSteps}) => {
  const [policyAccepted, setPolicyAccepted] = useState(true);
  const [showCheckPolicy, setShowCheckPolicy] = useState(false);
  const [animate, setAnimate] = useState(false);

  const slideStripLarge = keyframes`
  from {
    transform: translateX(200%);
  }
  to {
    transform: translateX(70%);
  }
`;

// Keyframes for tablets/smaller screens
const slideStripTablet = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(50%);
  }
`;

// Keyframes for mobile screens
const slideStripMobile = keyframes`
  from {
    transform: translateX(80%);
  }
  to {
    transform: translateX(10%);
  }
`;

  useEffect(() => {
    setAnimate(false); 
    const timeout = setTimeout(() => {
      setAnimate(true); 
    }, 0);

    return () => clearTimeout(timeout);
  }, []);

  const handleCheckPolicyClick = () => {
    if (policyAccepted) {
      setShowCheckPolicy(true);
      setGoSteps(1)
    } else {
      alert("Please accept the policy before proceeding.");
    }
  };

  const handleCheckboxChange = (event) => {
    setPolicyAccepted(event.target.checked);
  };



  return (
    <>
      <Box sx={policyContainerStyle}>
        {showCheckPolicy ? (
          <CheckPolicy setStartingView={setStartingView} instituteId={instituteId} />
        ) : (
          <>
   
            <Box sx={{...policyStyle ,  }}>
            <Box sx={{ ...titleContainerStyle, position: "relative" }}>
  <Box sx={{ display: "flex", flexWrap: "wrap" , justifyContent:{md:"start" , xs:"center"}}}>
    <Typography
      variant="h1"
      sx={{
        color: "#2356FE",
        marginRight: { xs: "1rem", md: "1.5rem" },
        fontFamily: "Poppins, Arial, sans-serif",
        fontWeight: 700,
        marginLeft: { xs: "1rem", md: "2rem" },
        lineHeight: { xs: "80px", md: "125px" },
        letterSpacing: { xs: "0.1rem", md: "0.2rem" },
        fontSize: { xs: "2rem", md: "6rem" },
      }}
    >
      SEWA
      <Typography
        component="span"
        variant="h1"
        sx={{
          color: "#000000",
          marginRight: { xs: "1rem", md: "1.5rem" },
          fontFamily: "Poppins, Arial, sans-serif",
          fontWeight: 700,
          lineHeight: { xs: "80px", md: "125px" },
          marginLeft: { xs: "1rem", md: "2rem" },
          letterSpacing: { xs: "0.1rem", md: "0.2rem" },
          fontSize: { xs: "2rem", md: "6rem" },
        }}
      >
        Nurturing
      </Typography>
    </Typography>
    <Typography
      component="span"
      variant="h1"
      sx={{
        color: "#000000",
        marginRight: { xs: "1rem", md: "1.5rem" },
        fontFamily: "Poppins, Arial, sans-serif",
        fontWeight: 700,
        marginLeft: { xs: "1rem", md: "1rem" , xl:"0px" },
        letterSpacing: { xs: "0.1rem", md: "0.2rem" },
        fontSize: { xs: "2rem", md: "6rem" },
        marginBottom:{xs:"1rem" , md:"2rem"},
        lineHeight: { xs: "2px", md: "124px" },
      }}
    >
      values
    </Typography>
  </Box>
</Box>

<Box
        sx={{
          backgroundColor: "#013BF8",
          position: "absolute",
          top: { md: "17%", xs: "13%" },
          borderTopLeftRadius: "2rem",
          borderBottomLeftRadius: "2rem",
          width: { xs: "90%", md: "60%" },
          height: { xs: "2.5rem", md: "3.5rem" },
          alignContent: "center",
          animation: {
            xs: `${slideStripMobile} 2s forwards`,
            sm: `${slideStripTablet} 2s forwards`,
            md: `${slideStripLarge} 2s forwards`,
          },
        }}
    

>
  <Typography
    component="div"
    variant="h6"
    sx={{
      color: "#ffffff",
      fontFamily: "Poppins, Arial, sans-serif",
      fontWeight: 500,
      textAlign: "center",
      fontSize: { xs: "0.7rem", md: "1rem" },
    }}
  >
    Social Empowerment through work education and action
  </Typography>
</Box>

                  
              <Box sx={formSectionStyle}>
                
                <Typography variant="body1" sx={NoteparagraphStyle}>
                  Note: Please read the terms below and ensure compliance before proceeding with the SEWA project.
                </Typography>
              </Box>
              <Box sx={formFields}>
                <Grid container spacing={2} >
                  <Grid item xs={12} md={8}>
                    <Box sx={formContainerStyle}>
                      <Divider sx={{ margin: "20px 0" }} />
                      <Typography variant="h5" sx={{...subTitleStyle, marginLeft:{md:"1.5rem" ,xs:"1rem"} ,  fontSize: { xs: "1.2rem", md: "1.5rem" },
}}>
                        Key Terms:
                      </Typography>
                      <Typography variant="body1" sx={{...paragraphStyle , marginLeft:{md:"1.5rem" ,xs:"1rem"} , paddingRight:{md:"1px" ,xs:"1rem"}}}>
                        1. All participants must adhere to the ethical standards outlined.
                        <br />
                        2. Projects must be completed within the given timeframe.
                        <br />
                        3. Weekly progress reports must be submitted to your mentor.
                        <br />
                        4. Any issues must be reported to the mentor immediately.
                      </Typography>
                      <Typography variant="h6" sx={{...subTitleStyle,  marginLeft:{md:"1.5rem" ,xs:"1rem"} }}>
                        Next Steps:
                      </Typography>
                      <Typography variant="body1" sx={{...paragraphStyle,marginLeft:{md:"1.5rem" ,xs:"1rem"} , paddingRight:{md:"1px" ,xs:"1rem"}}}>
                        - Submit your final project report.
                        <br />
                        - Ensure mentor and institution approvals.
                        <br />
                        - Maintain regular communication with your mentor .
                        <br />
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={sideBanner}>
                      <Box

component="img"
src="/uploads/logo2.png"
alt="Logo"
sx={{
  width: { xs: "60%", sm: "70%", md: "70%", lg: "80%" }, 
  height: "auto", 
  marginTop: { xs: "1rem", sm: "1.5rem", md: "2rem" }, 
}}

                        
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={policyAccepted}
                            onChange={handleCheckboxChange}
                            name="acceptPolicy"
                            sx={{
                              "& .MuiSvgIcon-root": { color: "white" },
                              "& .Mui-checked": { color: "white" },
                              fontWeight: "600",
                              fontSize: "1rem",
                            }}
                          />
                        }
                        label="I have read and agree to the policy"
                        sx={{ marginTop: "1rem", color: "#ffffff" }}
                      />
                      <Box sx={{ display: "flex", justifyContent: "center", padding: "1rem 2rem", marginTop: "0.75rem ", marginBottom:"2rem", fontSize:"1px" }}>
                        <Button
                          variant="contained"
                          sx={buttonStyle}
                          onClick={handleCheckPolicyClick}
                          disabled={!policyAccepted}
                        >
                          Check & Proceed
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ justifyContent: "center", alignContent: "center", display: "flex" }}>
                <img src="/uploads/HorizontalLine.png" alt="Logo" />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

const policyContainerStyle = {
  display: "flex",
  flexDirection: "column",
  marginBottom:"3rem",
  alignItems: "center",
  borderRadius: "1.5rem",
  // marginTop:"3rem",
  width: {md: "100%", xs:"90%"},
  height: "auto",
  overflow: "auto",
  backgroundColor: "#f0f4f8",
  // boxShadow: "2px 5px 5px rgba(0, 0, 0, 0.1)",
};


const policyStyle = {
  backgroundColor: "#fff",
  borderRadius: "1.5rem",
  border: "1px solid #ddd",
  boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
  width: "100%",
  height: "auto",
  position: "relative",
  overflow: "auto",
  overflowX: "hidden",
  overflowWrap: "break-word",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "40%",
    left: "0%",
    width: "50%",
    height: "100%",
    background: "linear-gradient(50deg, transparent, #4b87f59b)",
    opacity: 0.1,
    borderRadius: "50%",
    transform: "translate(-50%, -50%)",
    transition: "all 0.3s ease-in-out",
  },
};

const titleContainerStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent:"space-between"
};


const formFields = {
  display: "flex",
  flexDirection: "column",
  marginBottom: "1rem",
  alignItems: "flex-start",
  width: "100%",
};


const formSectionStyle = {
  display: "flex",
  flexDirection: "row",
  columnGap: "2.7rem",
  alignItems: "center",
  marginBottom: "0",
  overflowWrap: "break-word",
};

const formContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
};

const sideBanner = {
  background: "linear-gradient(to bottom right, #2356FE 0%, #2356FE66 100%)",
  borderTopLeftRadius: "3rem",
  borderBottomLeftRadius: "3rem",
  width: "100%",
  margin:{xs:"1rem"},
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "3rem",
};

const NoteparagraphStyle = {
  marginBottom: '1.5rem',
  lineHeight: '1.75',
  fontSize: { xs: "11px", md: "1rem" },
  textAlign: 'start',
  marginTop:{md:"0px" , xs:"1rem"},
  alignItems:"base-line",
  width: { xs:"100%" , md:'50%' , xl:"40%" },
  overflowWrap: 'break-word',
  color:"red",
  marginLeft:"2rem"
};

const paragraphStyle = {
  marginBottom: '1.5rem',
  lineHeight:{ md: '2.5' , xs:"2"},
  textAlign: 'start',
  width: "100%",
  fontWeight:"400",
  overflowWrap: 'break-word',
  
};
// const formTitleStyle = {
//   textAlign: 'left',
//   justifyContent: 'flex-start',
// };
const subTitleStyle = {
  marginBottom: "1rem",
  fontWeight: "bold",
  fontSize: "1.3rem",
  textAlign: "start",
  width: "100%",
  overflowWrap: "break-word",
};

const formTitleStyle = {
  fontWeight: 700,
  justifyContent: 'flex-start',
  fontFamily: "Poppins, Arial, sans-serif",
  textAlign: "center",
  overflowWrap: "break-word",
  color: "#000",
  marginBottom: "0.3rem",
};

const buttonStyle = {
  borderRadius: "25px",
  height: "45px",
  color: "#fff",
  backgroundColor: "#2356FE",
  fontWeight: 600,
  fontSize: "0.75rem",
  "&:hover": {
    backgroundColor: "#4b87f5",
  },
};

export default PolicyForm;




const AnimatedBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#013BF8",
  borderTopLeftRadius: "2rem",
  borderBottomLeftRadius: "2rem",
  width: "60%",
  height: "3.5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  animation: "grow-strip 2s forwards",
  '&.grow-animation': {
    animation: 'grow-strip 2s forwards',
  },
  '@keyframes grow-strip': {
    from: {
      transform: 'scale(0)',
    },
    to: {
      transform: 'scale(1)',
    },
  },
}));
