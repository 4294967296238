import { api, apiJson } from "api";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import { Avatar, Divider } from "@mui/joy";
import { Button, TextField } from "@mui/material";
import { WhatsappShareButton } from "react-share";
import Swal from "sweetalert2";
import { useGlobalContext } from "global/context";
import SendIcon from "@mui/icons-material/Send";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { Dropdown } from "react-bootstrap";
import MoreVertIcon from "@mui/icons-material/MoreVert";


export const AdminInstituteCbseSingleData = () => {
  const { id } = useParams();
  const { userData } = useGlobalContext();
  const [singleCbseData, setSingleCbseData] = useState();
  const [toolTipTitle, setToolTipTitle] = useState("Copy to clipboard");
  const [textExtraction, setTextExtraction] = useState("");
  const [commentsData, setCommentsData] = useState([]);
  const [comments, setComments] = useState({ text: "" });
  const [commentId, setCommentId] = useState(null);
  // Get single CBSE details
  const getSingleCbseDetails = () => {
    apiJson
      .get(`api/v2/admin/getSingleCbseData/${id}`)
      .then((res) => {
        setSingleCbseData(res?.data?.getSingleData);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getSingleCbseDetails();
    getComments();
  }, []);

  // Comments API
  const postCommentsadmins = () => {
    apiJson("api/v2/admin/adminCommentsCreated")
      .then((res) => {
        console.log(res.data.adminCreatedComments);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const QueryComponent = () => {
    const query = `I need the contact details for ${singleCbseData?.institution_name} in the ${singleCbseData?.district}, which has the CBSE affiliation number ${singleCbseData?.affliate_number}. Could you please provide the school's email address and phone number?
    `;
    const contentToCopy = () => {
      navigator.clipboard.writeText(query).then(() => {
        setToolTipTitle("Copied");
      });
    };

    return (
      <div className="row mt-4">
        <div className="col-12 col-lg-3 fs-5 fw-600">Query: </div>
        <div className="col-12 col-lg-9 fs-6 fw-500">
          {query}{" "}
          <Tooltip title={toolTipTitle}>
            <ContentCopyIcon onClick={contentToCopy} />
          </Tooltip>
        </div>
      </div>
    );
  };

  const TextExtraction = () => {
    const phoneNoRegex = /(\d{3,4}-\d{5,})|(011-\d{8})/g;
    const emailRegex = /[\w.-]+@[\w.-]+\.\w+/g;

    const handleExtract = async () => {
      try {
        if (textExtraction) {
          const phoneNumbers = textExtraction.match(phoneNoRegex);
          const emails = textExtraction.match(emailRegex);
          setTextExtraction("");
          console.log(phoneNumbers);
          console.log(emails);
          const data = {
            phoneNumbers,
            emails,
          };
          await apiJson.post(`api/v2/admin/addMultiCbseAltData/${id}`, data);
          getSingleCbseDetails();
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    return (
      <div className="row mt-4">
        <div className="col-12 col-lg-3 fs-5 fw-600">Output: </div>
        <div className="col-12 col-lg-9 d-flex flex-column">
          <TextField
            className="fs-6 fw-500"
            id="filled-multiline-static"
            label="Paste output here"
            multiline
            rows={2}
            value={textExtraction}
            onChange={(e) => setTextExtraction(e.target.value)}
          />
          <Button
            variant="contained"
            size="small"
            className="align-self-start my-2"
            onClick={handleExtract}
          >
            Extract
          </Button>
        </div>
      </div>
    );
  };

  const sendwhatsapp = (phonenumber, text) => {
    var url = "https://wa.me/" + phonenumber + "?text=" + text;
    window.open(url, "_blank");
  };

  const sendEmail = (email) => {
    apiJson
      .post(`api/v2/user/circular/${email}/nipamCircular`)
      .then((res) => {
        console.log("email sent to ", email);
        Swal.fire({
          title: "Email Send",
          text: "Email send to " + email,
          icon: "success",
        });
      })
      .catch((err) => {
        console.log("email not sent");
      });
  };
  var whatsAppContent = `
Dear Principal,%0a
%0a
CBSE has issued Circular No: Acad-37/2024 dated 16.04.2024 regarding the National Intellectual Property Awareness Mission (NIPAM), a flagship program of the Ministry of Commerce and Industry, Government of India. NIPAM aims to offer basic intellectual property (IP) training and instill foundational knowledge of Intellectual Property Rights (IPR) in educational institutions.%0a
%0a
In this regard, you are requested to take the following actions:
%0a
1. Register the school for NIPAM at www.yuvamanthan.org/NIPAM.%0a
2. After registration, invite students and teachers to join through a unique link provided in the school dashboard.%0a
3. Appoint a teacher coordinator for the initiative and share the details with us.%0a
4. Encourage students to join the 'IP Innovation Club' to focus on entrepreneurial and innovative instincts.%0a
5. Encourage students and teachers go through the e-Modules on NIPAM.%0a
%0a
Please take the necessary actions as per the CBSE circular to ensure the participation of students and teachers in this important mission. It is essential to nurture the spirit of creativity, innovation, and entrepreneurship among our students.%0a
%0a
For any further assistance, please feel free to reach out to us on this number or email us at connect@yuvamanthan.org.%0a
%0a
Best regards,%0a
Yuvamanthan Team%0a`;

  const getComments = () => {
    apiJson
      .get(`api/v2/admin/getComments/${id}`)
      .then((res) => {
        setCommentsData(res?.data?.getAllComments);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const commentsCreated = () => {
    const data = {
      text: comments.text,
      state: singleCbseData.state,
      postId: id,
    };
    if (commentId) {
      const data = {
        text: comments.text,
        // state: singleStateData.state,
        id: commentId,
      };
      apiJson
        .put(`api/v2/admin/editComments/${commentId}`, data)
        .then((res) => {
          getComments();
          setCommentId(null);
          setComments({ text: "" });
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      apiJson
        .post("api/v2/admin/adminCommentsCreated", data)
        .then((res) => {
          setComments({ text: "" });
          getComments();
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setComments({ ...comments, [name]: value });
  };

  const AddEmailAddressAndMobileNumber = () => {
    const formik = useFormik({
      initialValues: {
        email: '',
        mobileNumber: '',
      },
      validationSchema: Yup.object().shape({
        email: Yup.string().email('Enter a valid email'),
        mobileNumber: Yup.string().matches(/^\d+$/, 'Enter a valid number').min(10, 'Min 10 digits required').max(15, 'Max 15 digits allowed'),
      }),
      onSubmit: async (values) => {
        try {
          await apiJson.post(`api/v2/admin/addSingleCbseAltData/${id}`, values);
          getSingleCbseDetails();
        } catch (error) {
          console.error('Failed to post email: ', error);
        }
      },
    });
  
    return (
      <form onSubmit={formik.handleSubmit}>
        <div className="row mt-2">
          <div className="col-12 col-lg-3 fs-5 fw-600 d-flex align-items-center">
            Add Email
          </div>
          <div className="col-12 col-lg-9 d-flex align-items-center fs-6 fw-500">
            <TextField
              id="email"
              label="Enter Email"
              variant="standard"
              className="me-2"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <Button variant="contained" color="success" type="submit">
              <CheckIcon />
            </Button>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 col-lg-3 fs-5 fw-600 d-flex align-items-center">
            Add Number
          </div>
          <div className="col-12 col-lg-9 d-flex align-items-center fs-6 fw-500">
            <TextField
              id="mobileNumber"
              label="Enter Number"
              variant="standard"
              className="me-2"
              value={formik.values.mobileNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
              helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
            />
            <Button variant="contained" color="success" type="submit">
              <CheckIcon />
            </Button>
          </div>
        </div>
      </form>
    );
  };

  const handleUpdateClick = (comment) => {
    console.log(comment);
    setComments({ text: comment?.text });
    setCommentId(comment?.id);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ cursor: "pointer" }}
    >
      {children}
    </span>
  ));

  return (
    <div>
      <div className="col-10 mx-auto">
        <div className="d-flex justify-content-between">
          <div>
            <span className="fs-3 fw-500">CBSE Details</span>
          </div>
        </div>

        <div className="shadow-lg border-2 rounded-2 p-4">
          <div className="row mt-5">
            <div className="col-12 col-lg-3 fs-5 fw-600">Affiliation No:</div>
            <div className="col-12 col-lg-9 fs-6 fw-500">
              {singleCbseData?.affliate_number}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 col-lg-3 fs-5 fw-600">Institute Name:</div>
            <div className="col-12 col-lg-9 fs-6 fw-500">
              {singleCbseData?.institution_name}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 col-lg-3 fs-5 fw-600">Address:</div>
            <div className="col-12 col-lg-9 fs-6 fw-500">
              {singleCbseData?.address}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 col-lg-3 fs-5 fw-600 d-flex align-items-center">
              Email:
            </div>
            <div className="col-12 col-lg-9">
              {singleCbseData?.email
                ?.filter((email) => email !== null && email !== "")
                .map((email, index) => (
                  <div
                    key={index}
                    className="fs-6 fw-500 my-2 d-flex align-items-center"
                  >
                    <a
                      className="d-flex align-items-center"
                    >
                      <div className="me-2">{email}</div>
                      <span
                        className="p-2 rounded-2"
                        style={{ background: "#ecf5f5" }}
                      >
                        <EmailOutlinedIcon onClick={() => sendEmail(email)} />
                      </span>
                    </a>
                  </div>
                ))}
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-12 col-lg-3 fs-5 fw-600">State:</div>
            <div className="col-12 col-lg-9 fs-6 fw-500">
              {singleCbseData?.state}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 col-lg-3 fs-5 fw-600">District:</div>
            <div className="col-12 col-lg-9 fs-6 fw-500">
              {singleCbseData?.district}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 col-lg-3 fs-5 fw-600">Pincode:</div>
            <div className="col-12 col-lg-9 fs-6 fw-500">
              {singleCbseData?.pincode}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 col-lg-3 fs-5 fw-600 d-flex align-items-center">
              Contact:
            </div>
            <div className="col-12 col-lg-9">
              {singleCbseData?.contact
                ?.filter((contact) => contact !== null && contact !== "")
                .map((contact, index) => (
                  <i
                    key={index}
                    className="fs-6 fw-500 my-2 d-flex align-items-center"
                  >
                    <i className="me-2">{contact}</i>
                    <a
                      className="p-2 rounded-2 mx-2"
                      style={{ background: "#ecf5f5" }}
                      href={`tel:${contact}`}
                    >
                      <PhoneEnabledOutlinedIcon />
                    </a>
                    <Button
                      color="success"
                      variant="contained"
                      onClick={() => {
                        sendwhatsapp("+91"+contact, whatsAppContent);
                      }}
                      className="p-2 rounded-2 mx-2 d-flex align-items-center"
                    >
                      <WhatsAppIcon />
                      CBSE Circular
                    </Button>
                  </i>
                ))}
            </div>
          </div>

          <AddEmailAddressAndMobileNumber />
          <QueryComponent />
          <TextExtraction />
        </div>

        <div className="mt-1 rounded-2 border-3 shadow-sm p-3 mb-1 bg-grey">
          <div>
            <span className="fs-5 fw-600" style={{ color: "#434343" }}>
              Comments
            </span>
          </div>
          <hr />

          <div className="">
            <div className="d-flex align-items-center">
              <div>
                <span>
                  <Avatar src="" alt="" />
                </span>
              </div>
              <div>
                <span>
                  {userData?.first_name} {userData?.last_name}
                </span>
              </div>
            </div>

            <div className="d-flex">
              <div className="col-11">
                <input
                  placeholder="Comments"
                  name="text"
                  value={comments?.text}
                  onChange={handleTextChange}
                  className="w-100 border-3 rounded-2"
                />
              </div>
              <div className="col-1 cursor-pointer" onClick={commentsCreated}>
                <SendIcon />
              </div>
            </div>
          </div>

          <div className="rounded border-5 scroll-postComment-container"
            style={{ height: "210px" }}>
            {commentsData?.map((ele) => {
              return (
                <>
                  <div className="mt-1 col-10 d-flex justify-content-between align-align-items-center">
                    <div>
                      <div className="">
                        <span className="fw-500" style={{ fontSize: "15px" }}>
                          {ele?.text}
                        </span>
                      </div>
                      <div>
                        <span className="" style={{ fontSize: "12px" }}>
                          {moment(ele.createdAt).fromNow()}
                        </span>
                      </div>
                    </div>
                    {moment().diff(moment(ele.createdAt), "days") <= 1 ? (
                      <Dropdown align="start" className="exclude-this-click">
                        <Dropdown.Toggle as={CustomToggle}>
                          <MoreVertIcon sx={{ color: "#3a659b" }} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            position: "absolute",
                            inset: "0px auto auto 0px",
                            transform: "translate(-167px, 1px)",
                            // background: "rgb(130 73 222)"
                          }}
                          className="dropdown-menu-left dropdown-menu-custom-class"
                        >
                          {/* <Dropdown.Item className="update-delete-btn" onClick={() => setShowDeleteModal(true)}>Delete Post</Dropdown.Item> */}
                          <Dropdown.Item
                            className="update-delete-btn"
                            onClick={() => {
                              handleUpdateClick(ele);
                            }}
                          >
                            Update
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ""
                    )}
                  </div>
                  <Divider />
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
