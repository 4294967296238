import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Paper, Grid } from '@mui/material';
import ProgressBar from 'pages/course/ProgressBar';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import LoaderSplash from 'layout/LoaderSplash';
import { apiJson } from 'api';
import { Popup } from 'layout/Popup';

const QuestionsSection = ({ onComplete }) => {
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currQuesIndex, setCurrQuesIndex] = useState(0);
    const [responses, setResponses] = useState([]);
    const [totalSum, setTotalSum] = useState(0); // Changed from categoryScores to totalSum
    const [progress, setProgress] = useState(0);
    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        fetchQuestions();
    }, []);

    useEffect(() => {
        setProgress((currQuesIndex / (questions.length - 1)) * 100);
    }, [currQuesIndex, questions.length]);

    const fetchQuestions = async () => {
        try {
            const res = await apiJson('api/v2/lsmtTest/allQuestion');
            setQuestions(res.data?.result || []);
            setResponses(Array(res.data?.result.length).fill(null));
        } catch (err) {
            setError('Failed to load questions. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleOptionClick = (optionIndex) => {
        const newResponses = [...responses];
        const selectedScore = questions[currQuesIndex].option[optionIndex].score;

        // Update responses and total sum
        newResponses[currQuesIndex] = optionIndex;
        setTotalSum(prevSum => prevSum + selectedScore); // Update totalSum
        setResponses(newResponses);

        // Set completed status
        if (currQuesIndex === questions.length - 1) {
            setCompleted(true);
        } else {
            handleNext();
        }
    };

    const handleSubmit = () => {
        if (responses.length === 53 && responses.every(response => response !== null)) {
            onComplete(totalSum);
        }
        else {
            Popup("error", `Please Attempt all questions!!`);
        }
    };

    const handleNext = () => {
        if (currQuesIndex < questions.length - 1) {
            setCurrQuesIndex(currQuesIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currQuesIndex > 0) {
            setCurrQuesIndex(currQuesIndex - 1);
        }
    };

    if (loading) {
        return <LoaderSplash />;
    }

    if (error) {
        return (
            <Box textAlign="center" mt={4}>
                <Typography variant="h6" color="error">{error}</Typography>
                <Button variant="contained" color="primary" onClick={fetchQuestions}>
                    Retry
                </Button>
            </Box>
        );
    }

    const question = questions[currQuesIndex];

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            p={2}
            sx={{ backgroundColor: '#f0f4f8', borderRadius: 2, boxShadow: 3 }}
        >
            {/* Progress Bar */}
            <Box width="100%" mb={2}>
                <ProgressBar bgColor="green" progress={progress} />
                <Typography variant="body2" align="center" sx={{ color: '#555', fontWeight: 'bold' }}>
                    {progress.toFixed(0)}%
                </Typography>
            </Box>

            {/* Question Text */}
            <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#333' }}>
                {question?.text}
            </Typography>

            {/* Options Container */}
            <Grid container spacing={2} justifyContent="center">
                {question && question.option.map((optionObj, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Paper
                            elevation={3}
                            onClick={() => handleOptionClick(index)}
                            className={`option ${responses[currQuesIndex] === index ? 'selected' : ''}`}
                            sx={{
                                padding: 3,
                                textAlign: 'center',
                                cursor: 'pointer',
                                backgroundColor: responses[currQuesIndex] === index ? '#d1e7dd' : '#ffffff',
                                transition: 'background-color 0.3s, transform 0.2s',
                                '&:hover': {
                                    backgroundColor: '#f1f1f1',
                                    transform: 'scale(1.02)',
                                }
                            }}
                        >
                            <Typography variant="h6" sx={{ color: '#333' }}>{optionObj.text}</Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>

            {/* Arrow Buttons */}
            <Box display="flex" justifyContent="space-between" width="100%" mt={4}>
                {currQuesIndex > 0 && (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handlePrev}
                        startIcon={<FaAngleLeft />}
                        sx={{ borderRadius: 20 }}
                    >
                        Prev
                    </Button>
                )}

                {currQuesIndex < questions.length - 1 ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        endIcon={<FaAngleRight />}
                        sx={{ borderRadius: 20 }}
                    >
                        Next
                    </Button>
                ) : (
                    completed && (
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleSubmit}
                            sx={{ marginLeft: 'auto', borderRadius: 20 }}
                        >
                            Submit Test
                        </Button>
                    )
                )}
            </Box>
        </Box>
    );
};

export default QuestionsSection;
