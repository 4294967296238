import { apiJson } from 'api'
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button,Avatar } from '@mui/joy'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const AdminInstituteUnderReview = () => {
    const [institute, setInstitute]= useState([])
    const navigate = useNavigate()

    const getInstitute = ()=>{
        apiJson('api/v2/admin/getUnderReviewInstitute')
        .then((res)=>{
            setInstitute(res.data.institutes)
        }).catch((error)=>{
            toast.dismiss()
            toast.error('Internal server error')
        })
    }
    const updateInstitute= (id)=>{
        apiJson.put(`api/v2/admin/approveinstitute/institute/${id}`)
        .then((res)=>{
            setInstitute(res.data.institutes)
        }).catch((err)=>{
            toast.dismiss()
            toast.error('Internal server error')
        })
    }
    
    useEffect(()=>{
        getInstitute()
    },[])

    
    const handleInstituteSearch = (e) => {
        handleSearchChange(e)
    }

    const handleSearchChange = (e) => {
        if (e.key === 'Enter' || e.type === 'click') {
            const searchInput = document.getElementById("searchId").value
            SearchData(searchInput)
        }
        else if (!e.target.value) {
            getInstitute()
        }
    }

    const SearchData = (searchInput) => {
        apiJson(`api/v2/admin/getUnderReviewInstituteNew?query=${searchInput}`)
            .then((res) => {
      
                setInstitute(res.data.searchIns)
            })
            .catch((err) => {
                console.log(err.response.data)
            })
    }
  return (
    <>
        <div className='container mt-5'>
            
                   <h3 className='fs-3 fw-semibold'> Institutes Under Review</h3>
                
                <div className='col-12 col-lg-6'>
                        <div className='d-flex align-items-center mt-3'>
                            <div className=''>
                                <input className='form-control box-shadow-0 rounded background-grey border-1' id='searchId' style={{height:45}} onKeyDown={handleSearchChange}  placeholder='Search.....' />
                            </div>

                            <div className='my-2 ms-3'>
                                <button className='btn btn-success fw-thin text-white border-0 rounded' onClick={handleInstituteSearch} style={{ background: "#7e27ec", color: 'white', padding: "10px 12px"}}>Search</button>
                            </div>
                        </div>
                    </div>
                        <div className="mt-3">
                <TableContainer component={Paper} >
                    <Table aria-label="simple table">
                        <TableHead sx={{background:"#a365f1"}}>
                            <TableRow>
                                <TableCell className='fw-semibold'  sx={{color:"#ffffff"}}>Logo</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>Institute Name</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>Email</TableCell>
                                <TableCell className='fw-semibold' align="center"  sx={{color:"#ffffff"}}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ padding: "5px 10px" }}>
                            {institute?.length>0 ? institute?.map((row) => (
                                <TableRow
                                    key={row?.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, marginTop: "5px" }}
                                >
                                <TableCell >

                                    <Avatar src={row?.logo} />
                                </TableCell>
                                    <TableCell align="center">{row?.institution_name}</TableCell>
                                    <TableCell align="center">{row?.email}</TableCell>
                                    <TableCell align="center"> 
                                    <Button onClick={() => navigate(`/admin/instituteDetails/${row?.institute_id}`)}>Details</Button>
                                    <Button onClick={()=>{updateInstitute(row?.institute_id)}} variant='outlined'  color='success'  className='ms-2'>Approved</Button>
                                    </TableCell>
                                </TableRow>
                            )):(  <h4 className='text-center p-3'>Record Not Found</h4>)}
                        </TableBody>
                    </Table>
                </TableContainer>
       </div>
                
        </div>  
    </>
  )
}

export default AdminInstituteUnderReview