// Question.js
import { Button, ThemeProvider, ToggleButton, ToggleButtonGroup, alpha, createTheme, getContrastRatio } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StarIcon from '@mui/icons-material/Star';

import { Card } from 'react-bootstrap';

const CarbonQuestion = ({ question, handleSetAnswer,handleNext }) => {
  const [alignment, setAlignment] = useState();
  const [userAnswer, setuserAnswer] = useState([]);
  const [showSelectedAnswer, setShowSelectedAnswer] = useState('')

  const [showEmoji, setShowEmoji] = useState(false);
  
  const handleMouseEnter = () => {
    setShowEmoji(true);
  };

  const handleMouseLeave = () => {
    setShowEmoji(false);
  };

  const selectedAnswer = () => {
    const answer = userAnswer?.find(i => i?.questionId === question?.id)
    // console.log('answer is ', answer)
    if (answer) {
      // console.log('answer new', answer?.answer)
      setShowSelectedAnswer(answer?.answer)
    }
    else {
      // console.log('answer but why null', answer)
      setShowSelectedAnswer('')
    }
  }
  useEffect(() => {
    selectedAnswer();
  }, [question])

  useEffect(() => {
    handleSetAnswer(userAnswer)
  }, [userAnswer])

  const handleChangeAnswer = (newAlignment) => {
    // console.log(newAlignment)
    if (!newAlignment) {
      let indexToRemove = userAnswer?.findIndex(i => i?.questionId === question?.id)
      // console.log(indexToRemove)
      let array = userAnswer
      if (indexToRemove > -1) {
        array.splice(indexToRemove, 1);
        setuserAnswer(array)
      }
      // console.log(array)
      setShowSelectedAnswer(newAlignment)
      return;
    }
    const findScore = question?.option.find(i => i?.opt === newAlignment);
    const answerIndex = userAnswer?.findIndex(i => i?.questionId === question?.id)
    // answer already exist
    if (answerIndex !== -1) {
      // console.log('inside this ' )
      let updatedAnswer = userAnswer
      const answer = {
        questionId: question.id,
        cat_id: question.cat_id,
        answer: newAlignment,
        score: findScore?.score
      }
      updatedAnswer[answerIndex] = answer
      // console.log('updatedAnswer', updatedAnswer)
      setuserAnswer(updatedAnswer);
      // return ;
    }
    else {
      // console.log('Score is ', findScore)
      const answer = {
        questionId: question.id,
        cat_id: question.cat_id,
        answer: newAlignment,
        score: findScore?.score
      }
      setuserAnswer([...userAnswer, answer])
    }
    setShowSelectedAnswer(newAlignment)
    // setTimeout(() => {
    //   handleNext(); // Call handleNext after 1 second delay
    // }, 1200);
  }

  const handleChange = (event, newAlignment) => {
    console.log('new', newAlignment)
    setAlignment(newAlignment);
  };
  const violetBase = '#7F00FF';
  const violetMain = alpha(violetBase, 0.7);

  const yellowBase = '#ffbd00';
  const yellowMain = alpha(yellowBase, 0.7);
 

  const greenBase = '#00bb00';
  const greenMain = alpha(greenBase, 0.7);
 
  const redBase = '#ff4141';
  const redMain = alpha(redBase, 0.7);
  const theme = createTheme({
    palette: {
      violet: {
        main: violetMain,
        light: alpha(violetBase, 0.5),
        dark: alpha(violetBase, 0.9),
        contrastText: getContrastRatio(violetMain, '#fff') > 4.5 ? '#fff' : '#111',
      },
      warning: {
        main: yellowMain,
        light: alpha(yellowBase, 0.5),
        dark: alpha(yellowBase, 0.9),
        contrastText: getContrastRatio(yellowMain, '#fff') > 4.5 ? '#fff' : '#111',
      },
      success: {
        main: greenMain,
        light: alpha(greenBase, 0.5),
        dark: alpha(greenBase, 0.9),
        contrastText: getContrastRatio(greenMain, '#fff') > 4.5 ? '#fff' : '#111',
      },
      error: {
        main: redMain,
        light: alpha(redBase, 0.5),
        dark: alpha(redBase, 0.9),
        contrastText: getContrastRatio(redMain, '#fff') > 4.5 ? '#fff' : '#111',
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <div>
        <div className="text-center mt-4">
          <h1 className="fw-semibold fs-5">
            {question?.text}
          </h1>
        </div>
        <div className='row align-items-center'>
          {question?.img ? <div className=' col-12  rounded-3 text-center mt-4 mb-4' style={{ height: '200px' }}>
            <img className='h-100 shadow-lg  rounded-3' src={question?.img} alt={question?.img} />
          </div> : <div className='mb-5'></div>}
          <div className=" text-center col-12  ">
            {

            }
            <div className='d-flex justify-content-center'>
              {question?.option?.map((option, index) => (
                <>
                  <div className='cursor-pointer me-3' onClick={() => handleChangeAnswer(option?.opt)}>
                    <div className='mb-3' style={{ height: '50px' }}>
                      <img className={`h-100 ${showSelectedAnswer === option?.opt?'bounce-animation-undp':''}`}  src={['No','Hardly']?.includes(option?.opt)? '/carbonEmoji/1.svg':['Yes','Frequently']?.includes(option?.opt)?'/carbonEmoji/3.svg':'/carbonEmoji/2.svg'} />
                      
                    </div>
                    <Button
                      onMouseEnter={() => handleMouseEnter(option?.opt)}
                      onMouseLeave={() => handleMouseLeave()}
                      onClick={() => handleChangeAnswer(option?.opt)}
                      className='fw-semibold text-capitalize mb-3 rounded-2 rounded-3 border-2 px-5 py-2 fs-5'
                      size='small'
                      color={['No','Hardly']?.includes(option?.opt)? 'error':['Yes','Frequently']?.includes(option?.opt)?'success':'warning'}
                      variant={showSelectedAnswer === option?.opt ? 'contained' : 'contained'}
                      value={option?.opt}>
                      {showSelectedAnswer === option?.opt && <StarIcon sx={{position:'absolute' , fontSize:'30px', color:'black'}}/>}
                    </Button>
                    <h1 className='fs-6 fw-semibold' style={{color:showSelectedAnswer === option?.opt?'gray':'lightgray'}}>{option?.opt}</h1>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>

  );
};

export default CarbonQuestion;