import React, { useEffect, useRef, useState } from 'react'
import {DiscussionBoardMessageNew } from '.'
import moment from 'moment';
import { Divider } from '@mui/material';
import { useDiscussionContext } from '../discussionBoardContext/discussionContext';
import { MoveDown } from '@mui/icons-material';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';

export const DiscussionBoardMesssageArea = ({socket,screenWidth,screenHeight}) => {
    const {comments, setComments, message, setMessage} = useDiscussionContext()

    const containerRef = useRef(null);
    const newMessageDivRef = useRef(null);

    const moveDownClick = ()=>{
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }

      useEffect(() => {
          // Scroll to the bottom when a new message is received
        socket?.on('moveChatToBottom', ()=>{
            if (containerRef.current) {
                containerRef.current.scrollTop = containerRef.current.scrollHeight;
            }
        })
        checkIfAtBottom();

    }, [socket,message]);

    const checkIfAtBottom = () => {
        const container = containerRef.current;
        const newMessageDiv = newMessageDivRef.current;

        if (container && newMessageDiv) {
            const isAtBottom = container.scrollTop + container.clientHeight >= container.scrollHeight - 20;

            // Show/hide the "New Message" div based on the check
        

            if (isAtBottom) {
                newMessageDiv.style.display = 'none';
                newMessageDiv.classList.remove('bounceLatestChat');
            } else {
                newMessageDiv.style.display = 'block';
                newMessageDiv.classList.add('bounceLatestChat');
            }
        }
    };

    const handleContainerScroll = () => {
        // Check if the user is at the bottom when the container is scrolled
        checkIfAtBottom();
    };

    return (
        <div ref={containerRef}
         className='thin-scroll position-relative' 
         style={{ overflowY: 'auto', maxHeight:  `541px` }}
         onScroll={handleContainerScroll}
         >
            {message?.map((m, index) => (
                <DiscussionBoardMessageNew socket={socket} key={index} comments={comments} message={m} />
            ))}
            <div className='d-flex justify-content-center'>

            <div className='px-3 py-1  rounded-4 cursor-pointer' 
                onClick={moveDownClick}
                ref={newMessageDivRef}
            style={{
                
                background:'#e324ff80',
                position:'fixed', 
                top:'126px',
                color:'white',
                transform: 'translateY(0)', // Initial position
                transition: 'transform 0.3s ease-in-out', // CSS transition
                zIndex:'13'
                
            }}>Check Latest <ArrowDownwardOutlinedIcon/></div>
            </div>
        </div>
    )
}
