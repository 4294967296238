import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import ProgressBar from 'pages/course/ProgressBar';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import LoaderSplash from 'layout/LoaderSplash';
import car from '../../assets/Raisec/f7_car-fill.svg';
import puzzle from '../../assets/Raisec/bi_puzzle-fill.svg';
import individual from '../../assets/Raisec/healthicons_man.svg';
import team from '../../assets/Raisec/ph_microsoft-teams-logo-fill.svg';
import goals from '../../assets/Raisec/mage_goals-fill.svg';
import build from '../../assets/Raisec/ant-design_build-filled.svg';
import art from '../../assets/Raisec/flat-color-icons_music.svg';
import teach from '../../assets/Raisec/mdi_teach.svg';
import follow from '../../assets/Raisec/ic_baseline-follow-the-signs.svg';
import influence from '../../assets/Raisec/noun-communication-6054926 1.svg';
import experiment from '../../assets/Raisec/noun-practical-5979935 1.svg';
import help from '../../assets/Raisec/mdi_help-outline.svg';
import animal from '../../assets/Raisec/solar_heart-bold.svg';
import office from '../../assets/Raisec/vaadin_office.svg';
import money from '../../assets/Raisec/10603249_42589 1.svg';
import creativeWriting from '../../assets/Raisec/fluent_draw-shape-20-regular.svg';
import science from '../../assets/Raisec/game-icons_materials-science.svg';
import leader from '../../assets/Raisec/noun-lead-4574738 1.svg';
import heal from '../../assets/Raisec/material-symbols-light_healing-outline.svg';
import creative from '../../assets/Raisec/noun-creative-person-1454273 1.svg';
import analyze from '../../assets/Raisec/noun-analyze-2087625 1.svg';
import attention from '../../assets/Raisec/icon-park-solid_attention.svg';
import records from '../../assets/Raisec/vaadin_records.svg';
import instrument from '../../assets/Raisec/noun-instruments-6274334 1.svg';
import business from '../../assets/Raisec/ic_twotone-add-business.svg';
import cook from '../../assets/Raisec/game-icons_cook.svg';
import acting from '../../assets/Raisec/noun-acting-1680796 1.svg';
import charts from '../../assets/Raisec/ep_trend-charts.svg';
import chat from '../../assets/Raisec/teenyicons_chat-typing-alt-outline.svg';
import math from '../../assets/Raisec/hugeicons_math.svg';
import speech from '../../assets/Raisec/mingcute_speech-fill.svg';
import hours8 from '../../assets/Raisec/late-working.png';
import drawing from '../../assets/Raisec/drawing.png';
import organize from '../../assets/Raisec/organized-person.png';
import figureOut from '../../assets/Raisec/understanding-task.png';
import assemble from '../../assets/Raisec/assembling-items.png';
import filing from '../../assets/Raisec/typing.png';
import culture from '../../assets/Raisec/learning-cultures.png';
import practical from '../../assets/Raisec/thinking.png';
import lead from '../../assets/Raisec/leading.png';
import outdoors from '../../assets/Raisec/working-outdoor.png';
import helping from '../../assets/Raisec/helping-out.png';
import yesImage from "../../assets/frequently.png";
import noImage from "../../assets/hardly.png";
import { apiJson } from 'api';
import { Popup } from 'layout/Popup';



const QuestionsSection = ({ onComplete }) => {
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currQuesIndex, setCurrQuesIndex] = useState(0);
    const [responses, setResponses] = useState([]);
    const [categoryScores, setCategoryScores] = useState({
        r: 0,
        i: 0,
        a: 0,
        s: 0,
        e: 0,
        c: 0,
    });
    const [progress, setProgress] = useState(0);
    const [completed, setCompleted] = useState(false);

    // Array of images in the same order as the questions
    const questionImages = [
        car, puzzle, individual, team, goals, build, art, teach, follow, influence,
        experiment, help, animal, office, money, creativeWriting, science, leader,
        heal, creative, analyze, attention, records, instrument, business, cook,
        acting, charts, chat, math, speech, hours8, drawing, organize, figureOut,
        assemble, filing, culture, practical, lead, outdoors, helping
    ];
    const optionImages = [yesImage, noImage];

    useEffect(() => {
        fetchQuestions();
    }, []);

    useEffect(() => {
        setProgress((currQuesIndex / (questions.length - 1)) * 100);
    }, [currQuesIndex, questions.length]);

    const fetchQuestions = async () => {
        try {
            const res = await apiJson('api/v2/riasecTest/allQuestion');
            setQuestions(res.data?.result || []);
            setResponses(Array(res.data?.result.length).fill(null));
        } catch (err) {
            setError('Failed to load questions. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleOptionClick = (optionIndex) => {
        const newResponses = [...responses];
        const questionType = questions[currQuesIndex].type;

        const previousResponse = newResponses[currQuesIndex];
        const selectedScore = questions[currQuesIndex].option[optionIndex].score;

        if (previousResponse === null) {
            newResponses[currQuesIndex] = optionIndex;
            setCategoryScores(prevScores => ({
                ...prevScores,
                [questionType]: prevScores[questionType] + selectedScore,
            }));
        } else {
            newResponses[currQuesIndex] = optionIndex;
            const previousScore = questions[currQuesIndex].option[previousResponse].score;
            setCategoryScores(prevScores => ({
                ...prevScores,
                [questionType]: prevScores[questionType] - previousScore + selectedScore,
            }));
        }

        setResponses(newResponses);

        if (currQuesIndex === questions.length - 1) {
            setCompleted(true);
        } else {
            handleNext();
        }
    };

    const handleSubmit = () => {
        if (responses.length === 42 && responses.every(response => response !== null)) {
            onComplete(categoryScores);
        }
        else {
            Popup("error", `Please Attempt all questions!!`);
        }
    };

    const handleNext = () => {
        if (currQuesIndex < questions.length - 1) {
            setCurrQuesIndex(currQuesIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currQuesIndex > 0) {
            setCurrQuesIndex(currQuesIndex - 1);
        }
    };

    if (loading) {
        return <LoaderSplash />;
    }

    if (error) {
        return (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Typography variant="h6" color="error">{error}</Typography>
                <Button variant="contained" color="primary" onClick={fetchQuestions}>
                    Retry
                </Button>
            </div>
        );
    }

    const question = questions[currQuesIndex];
    const questionImage = questionImages[currQuesIndex];

    return (
        <div className="action-calculator">
            {/* Heading */}
            <h2 className="heading">Yuvamanthan - RIASEC Test</h2>

            {/* Progress Bar */}
            <div className="progress-container">
                <ProgressBar bgColor="green" progress={progress} />
                <span>{progress.toFixed(0)}%</span>
            </div>

            {/* Question Box */}
            <div className="questions-box">
                {question && (
                    <>
                        {/* Question Text */}
                        <h3 className="question-text">{question.text}</h3>
                        {/* Question Image */}
                        <div className="img-wrapper"
                            style={{
                                margin: "3rem 0 2rem 0",
                                overflow: "hidden",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src={questionImage}
                                alt={question.text}
                                className="question-image"
                            />
                        </div>
                    </>
                )}
            </div>

            {/* Options Container */}
            <div className="options-container">
                {/* Previous Button */}
                {currQuesIndex > 0 && (
                    <div className="prev-btn change-btn" onClick={handlePrev} style={{ left: 0 }}>
                        <span><FaAngleLeft /></span>
                        Prev
                    </div>
                )}

                {/* Options Wrapper */}
                <div className="options-wrapper">
                    {question && question.option.map((optionObj, index) => (
                        <button
                            key={index}
                            className={`option ${responses[currQuesIndex] === index ? 'selected' : ''}`}
                            onClick={() => handleOptionClick(index)}
                        >
                            <div className="img-wrapper">
                                <img src={optionImages[index]} alt={optionObj.text} />
                            </div>
                            <span>{optionObj.text}</span>
                        </button>
                    ))}
                </div>

                {/* Next Button */}
                {currQuesIndex < questions.length - 1 && (
                    <div className="next-btn change-btn" onClick={handleNext} style={{ right: 0 }}>
                        <span><FaAngleRight /></span>
                        Next
                    </div>
                )}
            </div>

            {/* Submit Button */}
            {completed && (
                <Button onClick={handleSubmit} variant="contained" color="primary" style={{ marginBottom: '1rem' }}>
                    Submit Test
                </Button>
            )}
        </div>
    );
};

export default QuestionsSection;
