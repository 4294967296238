import { useGlobalContext } from "global/context";
import React from "react";
import { useNavigate } from "react-router-dom";
export const UI2DashboardBanner = ({ nipamStudent }) => {
  const navigate = useNavigate();
  const { userData } = useGlobalContext();
  const munNavigate = () => {
    if (userData?.role === "institute") {
      navigate("/model-un");
    } else {
      navigate("/modelUn");
    }
  };
  return (
    <>
      {nipamStudent ? (
        <div
          id="carouselExampleFade"
          className="carousel slide carousel-fade d-lg-block rounded-4 "
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div
              className="carousel-item active cursor-pointer caro-inner-bg rounded-4"
              onClick={munNavigate}
            >
              {/* <img className="w-100 bannerImage rounded-4" src="banner_large.png" alt="Dashboard Banner" /> */}
              <div className="row align-items-center px-2 py-2 ">
                <div className="col-12 col-lg-4 p-3">
                  <p className="fs-6" style={{color:"#2768AE"}}>THE THEME - <span className="fw-semibold" style={{color:"#0255A1"}}>LIFESTYLE FOR ENVIRONMENT</span> </p>
                  <p
                    className="text-white p-2"
                    style={{ background: "#0255A1", fontSize:"12px" }}
                  >
                    Life engages citizens in combating climate change,
                    advocating a circular existence aligned with Earth's finite
                    resources. By promoting harmony with nature and endorsing
                    the 'reduce, reuse, recycle' ethos, LIFE seeks to inspire
                    individuals and industries to curb resource depletion,
                    fostering a sustainable and circular approach to
                    consumption.
                  </p>
                </div>
                <div className="col-12 col-lg-5 text-center pt-2">
                  <img src="/secbanner/2.png" alt="" height="150px" />
                  <p style={{fontSize:"12px",color:"#0255A1"}} className="fw-semibold">
                    World's Largest Student-Led Carbon Footprint Reduction
                    Initiative
                  </p>
                  <p style={{fontSize:"12px",color:"#2768AE"}}>
                    Join the biggest student action against climate change, take
                    the <span className="fw-semibold" style={{color:"#0255A1"}}>21-Day Challenge.  75-steps towards LIFE,</span> Join <span className="fw-semibold" style={{color:"#0255A1"}}>LIFE Club</span> 
                    or use the <span className="fw-semibold" style={{color:"#0255A1"}}>Carbon Footprint Calculator.</span>  Earn <span className="fw-semibold" style={{color:"#0255A1"}}>Carbon Credits</span> 
                    with each activity.
                  </p>

                  <button style={{ border:"4px solid #0255A1", background:"#2768AE"}} className="text-uppercase text-white px-3 py-2 rounded-3 fw-semibold ">Register now</button>
                </div>
                <div className="col-12 col-lg-3">
                  <img src="/secbanner/3.png" alt="" height={200} />
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      ) : (
        <div
          id="carouselExampleFade"
          className="carousel slide carousel-fade d-lg-block "
          data-bs-ride="carousel"
        >
          <div className="carousel-inner ">
            {/* ================ inner items================== */}

            <>
              <div className="carousel-item active ">
                <div className="row largeSlider justify-content-between">
                  <div className="col-3 d-none d-lg-block">
                    <div className="d-flex flex-column">
                      <div style={{ height: "90px" }}>
                        <img
                          src="bannerimages/2.png"
                          alt=""
                          className="h-100"
                        />
                      </div>
                      <div style={{ height: "250px" }}>
                        <img
                          src="bannerimages/7.png"
                          alt=""
                          className="h-100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-9 m-auto">
                    <div className="d-flex justify-content-center align-items-center flex-column p-0 m-0 text-center">
                      <img
                        src="bannerimages/1.png"
                        alt=""
                        height={70}
                        className="p-0 m-0"
                      />
                      {/* <div> */}
                      <p
                        className="fw-bold p-0 m-0"
                        style={{ fontSize: "0.5rem" }}
                      >
                        Government of India
                      </p>
                      <p
                        className="fw-bold p-0 m-0"
                        style={{ fontSize: "0.5rem" }}
                      >
                        Ministry of Commerce and Industry
                      </p>
                      <p
                        className="fw-bold p-0 m-0"
                        style={{ fontSize: "0.5rem" }}
                      >
                        Department for Promotion of Industry and Internal Trade
                      </p>
                      <p className="fw-bold p-0 m-0 fs-6">
                        Office of the Controller General of Patents, Designs &
                        Trademarks
                      </p>
                      {/* </div> */}
                      <div className="d-flex justify-content-between sliderimg p-0">
                        <div>
                        <img
                          src="bannerimages/3.png"
                          alt=""
                          height={100}
                          width={100}
                        />
                          
                        </div>

                        <div>
                        <img
                          src="bannerimages/5.png"
                          alt=""
                          height={100}
                          width={100}
                        />
                        </div>

                        <div>
                        <img
                          src="bannerimages/4.png"
                          alt=""
                          height={100}
                          width={100}
                        />
                        </div>
                      </div>

                      <h2
                        className="sliderpara fs-1 p-0 m-0 py-1"
                        style={{ color: "#a82c2c" }}
                      >
                        IPR Awareness Programme
                      </h2>
                      <h4
                        className="sliderpara fs-5 p-0 m-0"
                        style={{ color: "blue" }}
                      >
                        (Under Nation intellectual Property Awareness Mission)
                      </h4>

                      <button
                        className="fs-3 px-3 py-2 sliderbtn"
                        onClick={() => navigate("/courses")}
                      >
                        Take the Certificate
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
              className="carousel-item cursor-pointer caro-inner-bg rounded-4 "
              onClick={munNavigate}
            >
              {/* <img className="w-100 bannerImage rounded-4" src="banner_large.png" alt="Dashboard Banner" /> */}
              <div className="row align-items-center px-2 py-5 rounded-4">
                <div className="col-12 col-lg-4 p-3">
                  <p className="fs-6" style={{color:"#2768AE"}}>THE THEME - <span className="fw-semibold" style={{color:"#0255A1"}}>LIFESTYLE FOR ENVIRONMENT</span> </p>
                  <p
                    className="text-white p-2"
                    style={{ background: "#0255A1", fontSize:"12px" }}
                  >
                    Life engages citizens in combating climate change,
                    advocating a circular existence aligned with Earth's finite
                    resources. By promoting harmony with nature and endorsing
                    the 'reduce, reuse, recycle' ethos, LIFE seeks to inspire
                    individuals and industries to curb resource depletion,
                    fostering a sustainable and circular approach to
                    consumption.
                  </p>
                </div>
                <div className="col-12 col-lg-5 text-center pt-4">
                  <img src="/secbanner/2.png" alt="" height="150px" />
                  <p style={{fontSize:"12px",color:"#0255A1"}} className="fw-semibold">
                    World's Largest Student-Led Carbon Footprint Reduction
                    Initiative
                  </p>
                  <p style={{fontSize:"12px",color:"#2768AE"}}>
                    Join the biggest student action against climate change, take
                    the <span className="fw-semibold" style={{color:"#0255A1"}}>21-Day Challenge.  75-steps towards LIFE,</span> Join <span className="fw-semibold" style={{color:"#0255A1"}}>LIFE Club</span> 
                    or use the <span className="fw-semibold" style={{color:"#0255A1"}}>Carbon Footprint Calculator.</span>  Earn <span className="fw-semibold" style={{color:"#0255A1"}}>Carbon Credits</span> 
                    with each activity.
                  </p>

                  <button style={{ border:"4px solid #0255A1", background:"#2768AE"}} className="text-uppercase text-white px-3 py-2 rounded-3 fw-semibold ">Register now</button>
                </div>
                <div className="col-12 col-lg-3 d-none d-lg-block">
                  <img src="/secbanner/3.png" alt="" height={200} />
                </div>
              </div>
            </div>
            </>
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      )}
    </>
  );
};
