import Select from "react-select";
import React, { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ModelUnContext from "../context/contextModelUn";
import DatePickerOwn from "./DatePickerOwn";
import { styled } from '@mui/material/styles';
import { Modal } from 'react-bootstrap';
import { useFormik } from "formik";
import { apiJson, apiJsonAuth } from "api";
import * as yup from 'yup';
import { useLocation, useNavigate } from "react-router-dom";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useGlobalContext } from "global/context";
import { toast } from "react-toastify";
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Button } from "@mui/material";
import DatePickerPropDate from "./DatePickerPropDate";
import StyledBadge from "components/StyledBadge";

export const ModelUnComponent = () => {
  const location = useLocation()
    const {currentStep ,setCurrentStep} = useContext(ModelUnContext)
    const searchParams = new URLSearchParams(location.search);
    const count = searchParams.get('count');
    useEffect(()=>{
      if(count){
        setCurrentStep(count)
      }
    },[location.pathname,count])

   

  return (
    <>
      <>
        <div className="col-12 col-md-11 pt-4 px-3 px-md-5">{modelUnRegistrationSteps[currentStep]}</div>
      </>
    </>
  );
};
// 0
const Registration = () => {
  const navigate = useNavigate()

  const navigateLandinfPage =()=>{
    navigate("/model-un")
  }

  const [selectedType, setSelectedType] = useState(eventType[0]?.type);
  const {setCurrentStep, formik} = useContext(ModelUnContext)
  return (
    <div className="">
      <div>
      <span className="fw-500 fs-20px" style={{color: "#979797"}}>Events {">"} United Nations {">"} Registration</span>
    </div>
     <div className="mt-4">
       <span className="fw-600 fs-31px">Choose Event Type</span>
       </div>

      <div className="col-12 col-sm-12 col-md-8 col-lg-7">
     <div
       style={{
         height: "65vh",
       }}
       className=" d-flex flex-column justify-content-between mt-3"
     >
       <div>
       <form onSubmit={formik.handleSubmit}>
         {eventType.map((acc, index) => {
           return (
             <>
               {!acc.showFor && (
                 <Card
                   onClick={() => setSelectedType(acc.type)}
                   style={{ borderColor: "transparent" , background: "#FAFAFA"}}
                   className={`cursor-pointer rounded-4 mb-4  border-3 ${
                     index === 0  && "border-blue"}
                     ${acc.diabledcss ? 'btn-onboard-fill-disabled-Inter' : null }`
                   }
                 >
                   <Card.Body className=""  >
                     <div className="form-check d-flex ">
                       <span>
                         <input
                          //  onChange={() => setSelectedType(acc.type)}
                          onChange={(e)=> formik.setFieldValue("eventType", acc.type)}
                           className="form-check-input"
                           type="radio"
                           name="eventType"
                           id={acc.type}
                          //  value={acc.type}
                          value={formik.values.eventType}
                           checked={index === 0}
                         />
                       </span>
                       <div className="form-check-label ms-2 " for={acc.type}>
                         <div>
                           <span className="fw-500 fs-22px">
                             {" "}
                             {acc.type} 
                             &nbsp;
                             {
                              index === 1 && 
                             <span  className="fw-500" style={{color: "#413B64",  fontSize: "16px"}}>Upcoming Feature</span>
                              }
                           </span>
                         </div>
                         <div>
                           <span
                             style={{
                               color: "#9A9A9A",
                             }}
                             className="fs-15px fw-500"
                           >
                             {" "}
                             {acc.body}
                           </span>
                         </div>
                       </div>
                     </div>
                   </Card.Body>
                 </Card>
               )}
             </>
           );
         })}
      </form>
       </div>
       <div className=" d-flex justify-content-between mt-4">
         <button
           className="btn-onboard fs-19px fw-500"
             onClick={() => navigateLandinfPage()}
         >
           Discard 
         </button>
        <button
            onClick={() => { setCurrentStep(1) }}
           className="btn-onboard-fill btn-block"
         >
           Save & Continue{" "}
           <span className="ms-4">
             <img src="/images/onBoarding/right-icon.png" alt="right-icon" />
           </span>
         </button>
       </div>
     </div>
      </div>
    
    </div>
  );
};

const ChooseFormat = () =>{
  const [selectedType, setSelectedType] = useState(chooseFormat[0]?.type);
  // console.log(selectedType);

  const {setCurrentStep, formik,format} = useContext(ModelUnContext)
  return (
    <div>
        <div>
      <span className="fw-500 fs-20px" style={{color: "#979797"}}>Events {">"} United Nations {">"} Registration</span>
    </div>

    {/* ChhoseFormat */}
    <div className="mt-3 mb-2 d-flex align-items-center" >
      <div>
        <span className="contetnt-appoint fs-2 fw-semibold">
        Choose Format
        </span>
      </div>
      <div>
      <Tooltip title="You can select from any of the ongoing YMUN events." placement="bottom">
          <span>
            <HelpOutlineIcon className="ms-2" sx={{color: "#5686E1", fontSize: "20px"}}/>
          </span>
          </Tooltip>
      </div>
    </div>
    

    <div className="col-12 col-sm-12 col-md-8 col-lg-7 mt-3">
        <div>
          <span></span>
        </div>
      <form onSubmit={formik.handleSubmit}>
         {format.map((acc, index) => {
           return (
             <>
               {!acc.showFor && (
                 <Card
                   onClick={() => {setSelectedType(acc.event_format)
                    formik.setFieldValue("chooseFormat", acc.event_format)
                  }}
                   style={{ borderColor: "transparent" , background: "#FAFAFA"}}
                   className={`cursor-pointer rounded-4 mb-4  border-3 
                   ${formik.values.chooseFormat === acc.event_format && "border-blue"}
                   `}
                    // ${
                    // index === 0 && "border-blue"
                    // formik.values.chooseFormat === acc.event_format && "border-blue"
                  //  }   
                  //  ${index !== 0 ? 'btn-onboard-fill-disabled-Inter' : null }  
                  //  }   ${acc.diabledcss ? 'btn-onboard-fill-disabled-Inter' : null }  `}
                 >
                   <Card.Body className=""  >
                     <div className="form-check d-flex ">
                       <span>
                         <input
                          //  onChange={() => setSelectedType(acc.type)}
                          onChange={(e)=> formik.setFieldValue("chooseFormat", acc.event_format)}
                           className="form-check-input"
                           type="radio"
                           name="chooseFormat"
                           id={acc.event_format}
                          //  value={acc.event_format}
                          value={formik.values.chooseFormat}
                          // checked={index === 0}
                           checked={formik.values.chooseFormat === acc.event_format}
                         />
                       </span>
                       <div className="form-check-label ms-2 " for={acc.event_format}>
                         <div>
                           <span className="fw-500 fs-22px">
                             {" "}
                             {acc.event_format}
                           </span>
                         </div>
                         <div>
                           <span
                             style={{
                               color: "#9A9A9A",
                             }}
                             className="fs-15px fw-500"
                           >
                             {" "}
                             {acc.description}
                           </span>
                         </div>
                         <div>
                           <span
                             style={{
                               color: "#9A9A9A",
                             }}
                             className="fs-13px fw-500"
                           >
                             {" "}
                             {acc.event_content}
                           </span>
                         </div>
                       </div>
                       
                     </div>
                   </Card.Body>
                 </Card>
               )}
             </>
           );
         })}
         {/* {formik.errors.chooseFormat && formik.touched.chooseFormat ?
          <span className="text-danger">{formik.errors.chooseFormat}</span> : null} */}
      </form>
        <div className="d-flex justify-content-between  mt-4">
        <button
        
          onClick={()=>{setCurrentStep(0)}}
          className="btn-onboard fs-19px fw-500"
        >
          Previous
        </button>
        <button
          onClick={()=>setCurrentStep(3)}
          className="btn-onboard-fill"
        >
          <span>Save & Continue</span>
          <img className="ms-2" src="/images/onBoarding/right-icon.png" alt="right-icon" />
        </button>
      </div>

      </div>
     
    </div>
  )
}

// 1
const AppointCoordinators = () => {
   const [openSearch,setOpenSearch] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [allUserList,setAllUserList] = useState([])
  const [searchTitle, SetSearchTitle] = useState("");
  const [isDisabled,setIsDisabled] = useState(false)
  const [allTeacherCoordinatorList,setAllTeacherCoordinatorList] = useState([])
  const [coordinatorLimit,setCoordinatorLimit] = useState(5)
  const [eventDetail, setEventDetail] = useState({})
  const {userData} = useGlobalContext()
    const {setCurrentStep} = useContext(ModelUnContext)
  
//======= Get Event Details which is institute register for perform =====\\
const geteventDetail = ()=>{
  apiJson('api/v2/modelUn-student/getEventDetails/institute/'+userData?.id)
  .then((res)=>{
    setEventDetail(res.data.result)
  })
  .catch((error)=>{
    console.log(error.message)
  })
}
useEffect(()=>{
  geteventDetail();
},[])
  ///============= get all coordinator details ======\\
  const getAllCoordinatorDetails =async()=>{
    try {
      const res = await apiJson.get(`/api/v2/modelUn-institute/getAllTeacherCoordinator/${userData?.id}`)
      if(res.status===200){    
        setAllTeacherCoordinatorList(res?.data?.getTeacherCord)
        setCoordinatorLimit(5 - res?.data?.getTeacherCord.length);
      }
    } catch (error) {
      console.log(error?.message)
    }
  }
// ===========  Get All User List  Api calling =================\\
const getAllUserList = async() =>{
  try {
    const res= await apiJson.get(`/api/v2/modelUn-institute/betaFindApplicantTeacher/${userData?.id}`)
    setAllUserList(res?.data?.allUser)

  } catch (error) {
    console.log("Error in Appoint Coordinator",error)
  }
}
//========= Delete coordinator  api handler =====\\
const  deleteCoordinator =async(email)=>{
  try {
    const res = await apiJsonAuth.delete(`/api/v2/modelUn-institute/deleteCoordinator/${email}`)
    if(res.status===200){
      toast.dismiss();
      toast.success("teacher coordinator deleted successfully..")
      getAllCoordinatorDetails()
  
    }
  } catch (error) {
    console.log("error",error.meesage)
  }
}

useEffect(()=>{
    getAllUserList()
},[allTeacherCoordinatorList])

  useEffect(() => {
    if(userData?.id){
      getAllCoordinatorDetails()
    }
  }, [])

  ///  ============ User Search modal open handler ===================== \\
  const searchOpenHandle = () => {
      setOpenSearch(true)
  }
  ///  ============ User Search modal hide handler ===================== \\
  const searchHideHandle = () => {
    setOpenSearch(false)
  }
    const handleShowModal = () =>{
      setOpenSearch(false)
      setShowModal(true)
    }
    const handleClose = () =>{
      setShowModal(false)
    }
//========== Feild Validation here========= \\
  const validationSchema = yup.object().shape({
      name: yup.string().required("name is required"),
      designation: yup.string().required("role is required"),
      email: yup.string()
        .required("Email is required")
        .email("Invalid Email Address"),
      phone: yup
        .string()
        .required("Phone Number is Required")
        .matches(/^[0-9]{10}$/, "Invalid Mobile Number"),
        typeOfCoordinator: yup.string().required("Coordinator is required"),
    });
 //============ Initialize the formik state=========\\   
const formik = useFormik({
  initialValues: {
    name : "",
    designation: rolesArray[0].value,
    email: "",
    phone : "",
    typeOfCoordinator : "Limited Access",
    pref_committee:"Coordinator",
    pref_designation:"Teacher Coordinator",
    nominationType:"secure",
    status:"approved"
  },
  validationSchema : validationSchema,
  // onSubmit

  onSubmit : async(values,{ resetForm }) =>{
    const coordinatorExist = allTeacherCoordinatorList.some((coordinator) => coordinator.email === values?.email);

    if (coordinatorExist) {
      toast.dismiss();
      toast.error("Teacher coordinator with this email already exists");
      return;
    }
  
    setIsDisabled(true)
    try {
      if(allTeacherCoordinatorList?.length !==5){
        const res = await apiJsonAuth.post(`/api/v2/modelUn-institute/addCoodinator`,{...values,instituteId: userData.id,
          registerId:eventDetail?.id,
          last_registration_date: eventDetail?.last_date})
        if(res.status===200){
          resetForm();
          toast.dismiss()
          toast.success("Teacher coordinator added successfully")
          getAllCoordinatorDetails()
          getAllUserList()
        }
      }else{
        toast.dismiss()
        toast.warning("Please select up to 20 Teacher Coordinators")
      }
      setIsDisabled(false)
    } catch (error) {
      console.log(error.message)
      setIsDisabled(false)
    }   
    setShowModal(false)
  }
})

//============ When we click username then post api hit=========
 const handleData = async(email) =>{
  const coordinatorExist = allTeacherCoordinatorList.some((coordinator) => coordinator.email === email);

  if (coordinatorExist) {
    toast.dismiss();
    toast.error("Teacher coordinator with this email already exists");
    return;
  }

  const res = allUserList.find((i) => i?.email === email);
 console.log("hello==>",res)

formik?.setValues({
    studentId:res?.value,
    name: res.name,
    designation: res.role,
    email: res.email,
    phone: res.phone,
    typeOfCoordinator:"",
    pref_committee:"Coordinator",
    pref_designation:"Teacher Coordinator",
    nominationType:"secure",
    status:"approved"
  })
  handleShowModal()

 }
 const BootstrapTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
      },
    }));
  //  Notification
  // const sendNotification = async(email)=>{
  //   const res = allUserList.find((i) => i?.email === email);
  //   const notifyData = {
  //     instituteId: userData?.id,
  //     userId: res?.id,
  //     role : res?.role
  //   }
  //   try {
  //     let response = await apiJsonAuth.post(`api/v2/notification/createNotify/${userData?.id}/${res?.id}`, notifyData)
  //     if(response?.status === 201){
  //       toast.dismiss();
  //         toast.success("Notification sent successfully.")
  //     }
  //   } catch (error) {
  //     toast.error(error.meesage)
  //   }
  //  }


  return (
    <>
    <div>
      <span className="fw-500 fs-20px" style={{color: "#979797"}}>Events {">"} United Nations {">"} Registration</span>
    </div>
      <div className="mt-3 mb-2" >
        <span className="contetnt-appoint fs-2 fw-semibold">
          Appoint a Teacher Coordinators
        </span>
        <BootstrapTooltip title="Teacher Coordinator or Faculty Coordinators are responsible for organisation of the whole event including selecting students for participation, assignment designations, roles and countries.
">
        <Button sx={{border:"2px solid silver", color:"black", padding:"0",minWidth:"30px", marginBottom:"10px", marginLeft:"10px"}}>?</Button>
      </BootstrapTooltip>
      </div>

      <div className="mb-50">
        <span className="color-grey fs-17px fw-500">
        A Teacher Coordinator will help manage, coordinate and organise the event.
        </span>
      </div>

      <div className="col-12 col-sm-12 col-md-10 col-lg-7">
      <div className="card px-2 dashed-border rounded-4 cursor-pointer mb-4">
        <div className="card-body">
          <div className="d-flex align-items-center" onClick={searchOpenHandle}>
            <div className="me-2">
            <span>
                <AddCircleIcon color="success" style={{ fontSize: "35px" }} />
                  </span>
            </div>
            <div className="ms-2" >
              <span className="fs-6 fs-md-5 fw-semibold">
                Search or Add new teacher coordinator
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="background-purple rounded px-2 py-2 mb-50 border-purple">
        <span className="fs-6 fw-semibold color-purple ">
          <span className="me-2">
            {<AssignmentIcon className="mt-1" sx={{color: "#28007d"}} />}
          </span>
          You can add up to  {coordinatorLimit} Coordinators at a time.
        </span>
      </div>
      

      <div className="d-flex flex-column mb-50">
        <div className="d-flex justify-content-between mb-4">
          <span className="fs-4 fw-500">Teacher Coordinators</span>
          {/* <div className="d-flex">
          <span className="color-purple"><KeyboardArrowLeftIcon style={{ fontSize: "35px" }}/></span>
          <span className="color-purple"><KeyboardArrowRightIcon style={{ fontSize: "35px" }}/></span>
           
          </div> */}
        </div>
        <div className="scroll-modelun-register">
          {allTeacherCoordinatorList?.map((c, id) => {
          
            return (
              <>
                <div className="d-flex justify-content-between align-items-center mb-3 me-1"  key={c?.email}>
                  <span className="fs-5 fw-500">{c.name}</span>
                  <div className="d-flex align-items-center">
                    <div className="me-3 cursor-pointer d-flex">
                      {/* <Select
                        // styles={customStylesPurple}
                        styles={{ customStylesPurple: (provided) => ({ ...provided, zIndex: 9999 }) }}
                        options={rolesArray}
                        defaultValue={
                          rolesArray.find((i) => i.value === c?.designation)
                        }
                      //  onChange={()=>handleChageDesignation(id)}
                        getOptionLabel={(option) => {
                          return (
                            <>
                              <div className="p-2"> */}
                              <span className="color-purple fs-6 fw-semibold rounded-3 text-center px-3  py-2 me-2" style={{background:"#E5D6FF70"}}>
                                  {c?.typeOfCoordinator}
                                </span>
                              <span className="color-purple fs-6 fw-semibold rounded-3 text-center px-3 d-none d-md-block py-2" style={{background:"#E5D6FF70"}}>
                                  {c?.designation}
                                </span>
                              {/* </div>
                            </>
                          );
                        }}
                      /> */}
                    </div>
                    <div onClick={()=>deleteCoordinator(c?.email)}
                      className="d-flex align-items-center justify-content-center cursor-pointer "
                      style={{
                        background: "#FFD6D6",
                        width: "37px",
                        height: "37px",
                        borderRadius: "7px",
                      }}
                      >
                <span> <RemoveCircleTwoToneIcon color='error' /></span>
                      
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>

      <div className="d-flex justify-content-between  mb-5 mb-md-0 mt-md-4">
        <button
          onClick={()=>setCurrentStep(6)}
          className="btn-onboard fs-19px fw-500 mr-3 mb-3 mb-md-0"
        >
          Previous
        </button>

        <form >
        <button
        type="submit"
          onClick={
            ()=>{setCurrentStep(8)}
            // coordinators.length !== 0 ?
            // : null
          }
          className="btn-onboard-fill mb-3 mb-md-0"
        >
          <span>Save & Continue</span>
          <img className="ms-2" src="/images/onBoarding/right-icon.png" alt="right-icon" />
        </button>
      </form>

      </div>
      </div>
     
     {/*  ========= Open search user modal for assign  teacher coordinator  for  same  institute =======*/}
     <div className="container" >
      <Modal show={openSearch} className="left-rounded-modal" onHide={searchHideHandle} size="lg">
      <div className="modal-content">  
          <span className="content-title fw-600 color-black fs-2 ps-4 px-md-5 mt-3">
          Add Teacher Coordinator
          </span>
      <span className="fs-19px ps-4 px-md-5 mt-4" style={{color:"#000000"}}>Search for the Teacher here by typing their name</span>
        <Modal.Body>
       
                <form>
                <div className="mt-2 px-2 px-md-4 px-lg-4">

                {/* <Select
                styles={customStyles}
                options={allUserList}

                getOptionLabel={
                  (option)=>{
                    return (
                      <>
                        <div className="mb-2 d-flex align-items-center z-index-1" style={{background:"#d1f1f085"}}>
                          <div className="insitute-logo" style={{height:'50px', width:'50px'}}>
                            <img className="h-100 w-100 rounded-circle" src={option.profile || './images/social-icons/institute-logo.avif'} />
                          </div>
                            <div className="ms-3">
                              <span className="fs-6 fw-semibold">{option.name}</span>

                            </div>
                        </div>
                      </>
                    )
                  }
                }
             /> */}


                  <input className="search-handle px-2" type="text" name="userName" placeholder="Type a name"  
                   onChange={(e)=>SetSearchTitle(e.target.value)}/>
                 
                    </div>
                    <div className="bg-light search-handleList mt-3 mx-2 mx-md-4 rounded-3">
                     <div className="databox" style={{overflowY:"auto"}}>

                      {allUserList?.filter((value) => {
                      if (searchTitle === "") {
                        return value;
                      } else if (
                        value.name.toLowerCase().includes(searchTitle.toLowerCase())
                      ) {
                        return value;
                      }

                    }).map((i)=>
                        (

                          <div className={`d-flex  mt-2 cursor-pointer p-2 rounded-3 ${i?.applicantId !== null ? "opacity-50" : null}`} onClick={()=>i?.applicantId === null ? handleData(i?.email) : null} key={i?.email} style={{background: "#d1d1d1",alignItems: "center"}}>
                          <div style={{width:50,height:50 ,minHeight:50 ,minWidth:50}}><img src={i?.profile} alt="profile_image" className="w-100 h-100 rounded-circle" style={{border:"2px solid #b139f4"}}/></div>
                          <div className="ms-3">
                            <span className="fs-6 text-capitalize">{i?.name}</span>
                            {i?.applicantId !== null  ? <span className="fs-6 text-capitalize cursor-pointer"> Applicant for {`${i?.preferredComm} ${i?.preferredDesignation} ${i?.preferredCountry} ${i?.preferredRole}`} </span> : <span className="fs-6 text-capitalize" style={{color:"#5A00EE"}}>(Not applied for participation [Invite])</span>
                             }
                          </div>
                          {i?.applicantId === null  ?(
                          <div className="me-3">
                          <span>
                            <AddCircleIcon  color="success"/>
                          </span>
                          </div>) : null}
                          </div> 
                        )
                      )}
                     </div>
             
                    </div>
                     
                </form>
        </Modal.Body>
      </div>
      </Modal>
    </div>

       {/* ======= ADD external user for as a  Teacher coordinator  Modal =========  */}
    <div className="container" >
      <Modal show={showModal} className="left-rounded-modal" onHide={handleClose} size="lg">
      <div className="modal-content">
        <Modal.Header closeButton>
          <Modal.Title className="content-title fw-600 color-black fs-34px ps-4 ps-md-0 px-md-5">
            Appoint a Teacher Coordinator
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
          <div class="bd-example-modal-lg px-5">
            <div className="row">
              <div className="column col-12  col-lg-10 ">
                <form onSubmit={formik.handleSubmit}>
              <div className="row">
              {/* name */}
                <div className="firstName col-12  col-md-12  col-lg-6">
                  <div>
                    <div>
                  <label className="color-black fw-500 fs-19px">Full Name</label>
                    </div>
                    <div className="mt-2">
                  <input className="input-handle w-100 px-3" placeholder="Name" type="text" name="name" value={formik.values.name} onChange={formik.handleChange} id="" />
                  {formik.errors.name && formik.touched.name ?
                      <span className="text-danger">{formik.errors.name}</span> : null}
                    </div>
                  </div>
                </div>

                {/* Designation */}
                <div className="typesOfcoordinator col-12 col-md-12 col-lg-6">
                  <div>
                    <div>
                <label className="color-black fw-500 fs-19px">Designation</label>
                    </div>
                    <div className="mt-2">
                  <select className="input-selects px-3" name="designation"  value={formik.values.designation} onChange={formik.handleChange}>
                    {rolesArray.map((item)=>(

                    <option key={item?.value} value={item?.value}>{item?.name}</option>
                    ))}
                    
                  </select>
                  {formik.errors.designation && formik.touched.designation ?
                      <span className="text-danger">{formik.errors.designation}</span> : null}
                    </div>
                  </div>
                </div>

                {/* email */}
                <div className="emailId col-12 col-sm-12 col-md-12 col-lg-6">
                  <div>
                    <div className="mt-4">
                <label className="color-black fw-500 fs-19px">Email ID</label>
                    </div>
                    <div className="mt-2">
                  <input className="input-handle w-100 px-3" type="email" name="EmailId" placeholder="Enter your email." id=""  value={formik.values.email} onChange={(e)=>{formik.setFieldValue("email", e.target.value)}}/>
                    {formik.errors.email && formik.touched.email ?
                      <span className="text-danger">{formik.errors.email}</span> : null}
                    </div>
                  </div>
                </div>

                {/* Phone */}
                <div className="phone col-12 col-sm-12 col-md-12 col-lg-6">
            <div className="col-12">
              <div className="mt-4">
                <label className="color-black fw-500 fs-19px">Phone</label>
              </div>
              <div className="d-flex" >
                <div className="mt-2">
                  <select className="input-selects-phone input-group-text fw-500">
                    <option value="">+91</option>
                  </select>
                </div>
                <div className="mt-2" style={{ width: "100%"}}>
                  <input className="input-handle-phone px-3" type="text" name="phone" placeholder="99********88" id=""  value={formik.values.phone} onChange={formik.handleChange}/>
                  {formik.errors.phone && formik.touched.phone ?
                      <span className="text-danger">{formik.errors.phone}</span> : null}
                </div>
              </div>
            </div>
          </div>

                {/* typeOfCoordinator */}
                <div className="typesOfcoordinator col-12 col-sm-12 col-md-12 col-lg-6">
                  <div>
                    <div className="mt-4">
                <label className="color-black fw-500 fs-19px">Types of Coordinator</label>
                    </div>
                    <div className="mt-2">
                  <select name="typeOfCoordinator" className="input-selects px-3" value={formik.values.typeOfCoordinator} onChange={formik.handleChange}>
                    <option value="Full Access">Full Access</option>
                    <option value="Limited Access">Limited Access</option>
                  </select>
                  {formik.errors.typeOfCoordinator && formik.touched.typeOfCoordinator ?
                      <span className="text-danger">{formik.errors.typeOfCoordinator}</span> : null}
                    </div>
                  </div>
                </div>

                {/* Button */}
                <div className="addCoordinator col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="w-100 d-flex justify-content-center">
                  <input className="input-handle text-align-center"  type="submit" value="Add Coordinator" disabled={isDisabled} style={{background: "#6100FF", color:"white"}}/>
                  </div>
                </div>
                <div className="adminRight mx-auto d-flex align-items-center mt-md-3">
                  <div><img src="/modelUn/Clipboard.png" alt="Clipboard" /></div>
                  <div className="admin-content"><span className="color-purple">Admin Has full rights to all admin features of the portal.</span></div>
                </div>
              </div>

                </form>
              </div>
            </div>
              </div>
        </Modal.Body>
      </div>
      </Modal>
    </div>

    </>
  );
};
// 5
const AppointStudentCoordinators = () => {
  
  const {userData} = useGlobalContext()
  const {setCurrentStep} = useContext(ModelUnContext)
  const [openSearch,setOpenSearch] = useState(false)
  const [searchTitle, SetSearchTitle] = useState("");
  const [allUserList,setAllUserList] = useState([])
  const [searchResults, setSearchResults] = useState([]);
  const [isDisabled,setIsDisabled] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [secretariatType,setSecretariatType] = useState("Organisation Committee")
  const [secretariat, setSecretariat] = useState([])
  const [allSec, setAllSec] = useState([])
  const [eventDetail, setEventDetail] = useState({})
  const [secretariatLimit,setSecretariatLimit]=useState(10)
  const [isInputDisebled, setIsInputDisebled] = useState(false)

//======= Get Event Details which is institute register for perform =====\\
  const geteventDetail = ()=>{
    apiJson('api/v2/modelUn-student/getEventDetails/institute/'+userData?.id)
    .then((res)=>{
      setEventDetail(res.data.result)
    })
    .catch((error)=>{
      console.log(error.message)
    })
  }
  useEffect(()=>{
    geteventDetail();
  },[])
// ===========  Get All User List  Api calling =================\\
const getAllUserList = async() =>{
  try {
    const res= await apiJson.get(`/api/v2/modelUn-institute/betaCheckApplicantUserList/${userData?.id}`)
    setAllUserList(res?.data?.allUser)
    setSearchResults(res?.data?.allUser)

  } catch (error) {
    console.log("Error in Appoint Coordinator",error)
  }
}

//=========== Fetch all user Which is register in institute ==\\
useEffect(() => {
  const fetchData = async () => {
    try {
      await getAllUserList();
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };

  fetchData();
}, [userData.id,secretariat]);
//========== Get All secretariat  role with slots api ==========\\
const fetchSecretariat = async () => {
  try {
    if (eventDetail?.id) {
      const res = await apiJson(`api/v2/modelUn-institute/getAllSecDetails/institute/${userData?.id}/registerId/${eventDetail?.id}`);
      setAllSec(res?.data?.updatedAllSec);
      let data =res?.data?.updatedAllSec?.find((i)=>i.role ==="Member OC") 
      formik.setFieldValue("assign_designation",data?.role)
      formik.setFieldValue("secretariatsId",data?.value)
    }
  } catch (error) {
    console.error("Error fetching secretariat details:", error);
  }
};
useEffect(() => {
  fetchSecretariat();
}, [eventDetail?.id]);

//======  Get all pre Selected secretariat details api  ====\\
const fetchPreRegisterSecretariat = async () => {
  try {
    if (eventDetail) {
      const res = await apiJson.post(`api/v2/modelUn-institute/getAllselectSecretariatDetails`,{
        instituteId:`${userData.id}`,
        registerId:eventDetail?.id
      });
      setSecretariat(res?.data?.allSelectSec?.filter((ele) => ele?.secretariatType === "Organisation Committee"))
      setSecretariatLimit(10-res?.data?.allSelectSec?.filter((ele) => ele?.secretariatType === "Organisation Committee" && ele?.status === "approved")?.length);
    }
  } catch (error) {
    console.error("Error fetching secretariat details:", error);
  }
};
useEffect(()=>{
 if(eventDetail?.id){
  fetchPreRegisterSecretariat()
 }
},[eventDetail?.id])

  ///  ============ User Search modal open handler ===================== \\
  const searchOpenHandle = () => {
    setOpenSearch(true)
  }
  ///  ============ User Search modal hide handler ===================== \\
  const searchHideHandle = () => {
    setOpenSearch(false)
  }
    const handleClose = () =>{
      setShowModal(false)
    }

    //============== Secretariat Select function  =====\\
    const handleSecretariatChange=(selectedOption)=>{ 
      formik.setFieldValue("assign_designation",selectedOption.role);
      formik.setFieldValue("secretariatsId",selectedOption.value)
    }


    const validationSchema = yup.object().shape({
      name: yup.string().required("name is required"),
      role: yup.string().required("UserType is required"),
      email: yup.string()
        .required("Email is required")
        .email("Invalid Email Address"),
      phone: yup
        .string()
        .required("Phone Number is Required")
        .matches(/^[0-9]{10}$/, "Invalid Mobile Number"),
        assign_designation: yup.string().required("Designation is required"),
    });

//=========== Sec Form Data Submit function =======\\
    const onSubmit = async(values,{ resetForm }) => {
    setIsDisabled(true)
    const emailExists = secretariat.some((i) => i.email === values.email);

    if (!emailExists) {
      if (secretariatLimit !== 0) {
        try {
          const res = await apiJsonAuth.post(`/api/v2/modelUn-institute/addSecMember`, {
            ...values,
            instituteId: userData.id,
            registerId: eventDetail?.id,
            last_registration_date: eventDetail?.last_date
          });
  
          if (res?.status === 201) {
            toast.success("Secretariat is assigned successfully.");
            resetForm();
            handleClose();
            fetchPreRegisterSecretariat();
            getAllUserList();
            fetchSecretariat();
          }
          setIsDisabled(false);
        } catch (error) {
          toast.dismiss();
          console.error(error);
          setIsDisabled(false);
        }
      } else {
        toast.dismiss();
        toast.error("The limit for Secretariat members has been reached.");
      }
    } else {
      // Email already exists in the secretariat array
      toast.dismiss();
      toast.warning("User is already exist in the Secretariat.");
      setIsDisabled(false);
    }
  };

//=========== Formik initially Define here ======\\
const formik = useFormik({
  initialValues: {
    name : "",
    role: "student",
    email: "",
    phone : "",
    registerId: "",
    pref_committee:"Secretariat",
    secretariatType:"Organisation Committee",
    secretariatsId:"",
    assign_designation : "",
    nominationType:"secure",
    status:"approved"
  },
  validationSchema : validationSchema,
  onSubmit
})

// ========= Select User From the modal where i click on the name of user =====
const handleData =async(email) =>{
  const emailExists = secretariat.some((i) => i.email === email);
  if (emailExists) {
    toast.dismiss();
    toast.error("User email Already exist.")
    return
  }
  setShowModal(true)
  const res = allUserList.find((i) => i?.email === email);
  formik.setValues({
    name: res?.name,
    role: res?.role,
    email: res?.email,
    phone: res?.phone,
    registerId: "",
    studentId: res?.id,
    pref_committee: "Organisation Committee",
    secretariatType: "Organisation Committee",
    secretariatsId: "",
    assign_designation: "",
    nominationType: "secure",
    status: "approved"
  });
  setIsInputDisebled(true)
  searchHideHandle();
 }

   //  Notification
  //  const sendNotification = async(email)=>{
  //   const res = allUserList.find((i) => i?.email === email);
  //   const notifyData = {
  //     instituteId: userData?.id,
  //     userId: res?.id,
  //   }
  //   try {
  //     let response = await apiJsonAuth.post(`api/v2/notification/createNotify/${userData?.id}/${res?.id}`, notifyData)
  //     if(response?.status === 201){
  //       toast.dismiss();
  //         toast.success("Notification sent successfully.")
  //     }
  //   } catch (error) {
  //     toast.error(error.meesage)
  //   }
  //  }

//======== Delete selected sec member  funtion ====\\
const deleteSecMember =async(email) =>{
  try {
    const res = await apiJsonAuth.delete(`/api/v2/modelUn-institute/deleteSelectSecMember/${email}`)
    if(res.status===200){
      toast.dismiss();
      toast.success("teacher coordinator deleted successfully..")
      fetchPreRegisterSecretariat()
      fetchSecretariat();
    const updatedCoordinatorData = allUserList?.find((i) => i?.email === email);
  if(allUserList.some((item) => item?.email === updatedCoordinatorData?.email)){

    setSearchResults([...searchResults,updatedCoordinatorData])
  }
    }
  } catch (error) {
    console.log("error",error.meesage)
  }
}
const BootstrapTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
      },
    }));


  return (
    <>
    <div>
      <span className="fw-500 fs-20px" style={{color: "#979797"}}>Events {">"} United Nations {">"} Registration</span>
    </div>
      <div className="mt-3 mb-2">
        <span className=" fs-2 fw-semibold">
          Appoint a Secretariat Members
        </span>
        <BootstrapTooltip title="The Secretariat comprises the student-led Organization Committee, the Executive Board, and the entire Jury where you may appoint teachers as well.
">
        <Button sx={{border:"2px solid silver", color:"black", padding:"0",minWidth:"30px", marginBottom:"10px", marginLeft:"10px"}}>?</Button>
      </BootstrapTooltip>
      </div>

      <div className="mb-50  col-12 col-sm-12 col-md-7">
        <span className="color-grey fs-15px fw-500">
        They will form the core team of the Secretariat. The Secretariat is responsible for helping the Teacher Coordinator/s in the organisation and logistics of various activities.
        </span>
      </div>

      <div className="col-12 col-sm-12 col-md-8 col-lg-7">
      <div  className="card px-2 dashed-border rounded-4 cursor-pointer mb-4">
        <div className="card-body">
          <div className="d-flex align-items-center" onClick={searchOpenHandle}>
            <div className="me-2">
              <span>
              <AddCircleIcon color="success" style={{ fontSize: "35px" }} />
              </span>
            </div>
            <div className="ms-2">
              <span className="fs-6 fs-md-5 fw-semibold">
                Search or Add Secretariat Member
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="background-purple rounded px-2 py-2 mb-50 border-purple">
        <span className="fs-6 fw-semibold color-purple d-flex">
          <div>
          <span className="me-2">
            {<AssignmentIcon className="mt-1" sx={{color: "#28007d"}} />}
          </span>
          </div>
          {
            secretariatLimit ==0 ?(<div>You have reached the maximum limit.</div>):(
          <div>
          You may appoint up to {secretariatLimit} student coordinators who will help organise the event.
          </div>

            )
          }
        </span>
      </div>

      <div className="d-flex flex-column mb-50">
        <div className="d-flex justify-content-between mb-4">
          <span className="fs-4 fw-500">Secretariat Members</span>
          {/* <div className="d-flex">
          <span className="color-purple"><KeyboardArrowLeftIcon style={{ fontSize: "35px" }}/></span>
          <span className="color-purple"><KeyboardArrowRightIcon style={{ fontSize: "35px" }}/></span>
           
          </div> */}
        </div>
        <div className="scroll-modelun-register">
          {secretariat?.map((c, id) => {
       
            return (
              <>
                <div className="d-flex justify-content-between align-items-center mb-3" key={c?.email}>
                  <div className="px-3">
                  <StyledBadge overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                    backgroundColor={c?.studentId !== 0 ? "#44b700" : "red"}>
                  <span className="fs-5 fw-500">{c?.name}</span>
                  </StyledBadge>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="cursor-pointer">
                                <span className="color-purple fs-17px fw-semibold rounded-3 text-center px-3  py-2" style={{background:"#E5D6FF70"}}>
                                  {c?.assign_designation}
                                </span>
                    </div>
                    <div onClick={()=> deleteSecMember(c?.email)}
                      className="d-flex align-items-center justify-content-center cursor-pointer "
                      style={{
                        background: "#FFD6D6",
                        width: "37px",
                        height: "37px",
                        borderRadius: "7px",
                        marginLeft:3
                      }}
                    >
                   <span> <RemoveCircleTwoToneIcon color='error' className="cursor-pointer"/></span>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>

      <div className="d-flex justify-content-between  mb-5 mb-md-0 mt-md-4">
        <button
          onClick={()=>setCurrentStep(7)}
          className="btn-onboard fs-19px fw-500 mr-3 mb-3 mb-md-0"
          style={{color: "#808080"}}
        >
          Previous
        </button>
        <button
          onClick={()=>{
            setCurrentStep(9)}}
          className="btn-onboard-fill mb-3 mb-md-0"
        >
          <span>Save & Continue</span>
          <img className="ms-2" src="/images/onBoarding/right-icon.png" alt="right-icon"/>
        </button>
      </div>
      </div>

 {/*  ========= Open search user modal for assign SECRETARIAT MEMBERS  for  same  institute =======*/}
 <div className="container" >
      <Modal show={openSearch} className="left-rounded-modal" onHide={searchHideHandle} size="lg">
      <div className="modal-content">  
          <span className="content-title fw-600 color-black fs-2 ps-4 px-md-5 mt-3">
          Add Secretariat Member 
          <Tooltip title={
          <>
            <div>
              <strong>Secretariat (Secretary-General and Organizing Committee):</strong>
            </div>
            <div>
              <strong>Secretary-General:</strong> The big boss of the whole event. They make sure everything runs smoothly.
            </div>
            <div>
              <strong>Organizing Committee:</strong> A group of helpers who assist the Secretary-General in organizing the conference.
            </div>
            <div>
              <strong>Executive Board (Chairperson, Vice-Chairperson, Rapporteur):</strong>
            </div>
            <div>
              <strong>Chairperson:</strong> Like a teacher, they lead the discussions, keep order, and make sure everyone follows the rules.
            </div>
            <div>
              <strong>Vice-Chairperson:</strong> Helps the Chairperson and takes over if the Chairperson is not there.
            </div>
            <div>
              <strong>Rapporteur:</strong> Keeps a record of what everyone says and helps with organizing documents.
            </div>
          </>
} placement="bottom">
  <span>
    <HelpOutlineIcon sx={{color: "#5686E1", fontSize: "20px"}} />
  </span>
        </Tooltip>

          </span>
      <span className="fs-19px ps-4  px-md-5 mt-4" style={{color:"#000000"}}>Search for the Student/Teacher here by typing their name</span>
        <Modal.Body>       
                <form>
                <div className="mt-2 px-2 px-md-4 px-lg-4">
                  <input className="search-handle px-2" type="text" name="name" placeholder="Type a name" onChange={(e)=>SetSearchTitle(e.target.value)}/>                 
                    </div>
                    <div className="bg-light search-handleList mt-3  mx-2 mx-md-4 rounded-3">
                     <div className="databox" style={{overflowY:"auto"}}>
                      {searchResults?.filter((value) => {
                      if (searchTitle === "") {
                        return value;
                      } else if (
                        value.name.toLowerCase().includes(searchTitle.toLowerCase())
                      ) {
                        return value;
                      }}).map((i)=>
                        (
                          <div className={`d-flex  mt-2 cursor-pointer p-2 rounded-3 ${i?.applicantId !== null ? "opacity-50" : null}`} onClick={()=>i?.applicantId === null ? handleData(i?.email) : null} key={i?.email} style={{background: "#d1d1d1",alignItems: "center"}}>
                          <div style={{width:50,height:50 ,minWidth:50,minHeight:50}}><img src={i?.profile} alt="profile_image" className="w-100 h-100 rounded-circle" style={{border:"2px solid #b139f4"}}/></div>
                          <div className="ms-3">
                            <span className="fs-6 text-capitalize">{i?.name}</span>
                            {i?.applicantId !== null  ? <span className="fs-6 text-capitalize"> Applicant for {`${i?.preferredComm} ${i?.preferredDesignation} ${i?.preferredCountry} ${i?.preferredRole}`} </span> : <span className="fs-6 text-capitalize" style={{color:"#5A00EE"}}>(Not applied for participation [Invite])</span>
                             }
                          </div>
                          {i?.applicantId === null  ?(
                          <div className="me-3">
                          <span>
                            <AddCircleIcon  color="success"/>
                          </span>
                          </div>) : null}
                          </div>  
                        )
                      )}
                     </div>
                    </div>
                     
                </form>
        </Modal.Body>
      </div>
      </Modal>
    </div>
     {/* ======= ADD external user for as a  SECRETARIAT MEMBERS  Modal =========  */}
      <div className="container" >
      <Modal show={showModal} className="left-rounded-modal" onHide={handleClose} size="lg">
       <div className="modal-content">
        <Modal.Header closeButton>
          <Modal.Title className="content-title fw-600 color-black fs-34px ps-4 ps-md-0 px-md-5">
          New Secretariat Member
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
          <div class="bd-example-modal-lg px-5">
            <div className="row">
              <div className="column col-12  col-lg-10 ">
                <form onSubmit={formik.handleSubmit}>
              <div className="row">
              {/* userName */}
                <div className="firstName col-12  col-md-12  col-lg-6">
                  <div>
                    <div>
                  <label className="color-black fw-500 fs-19px">Full Name</label>
                    </div>
                    <div className="mt-2">
                  <input className="input-handle w-100 px-3" type="text" name="name" disabled={isInputDisebled} placeholder="Name" value={formik.values.name} onChange={formik.handleChange} id="" />
                  {formik.errors.name && formik.touched.name ? (<span className="text-danger">{formik.errors.name}</span>) : (null)}
                    </div>
                  </div>
                </div>

                {/* Designation */}
                <div className="typesOfcoordinator col-12 col-md-12 col-lg-6">
                  <div>
                    <div>
                <label className="color-black fw-500 fs-19px">User Type</label>
                            </div>
                            <div className="mt-2">
                              <Select
                                styles={customStyles}
                                options={showDesList}
                                // value={formik?.values?.designation}
                                defaultValue={showDesList.find((i) => i?.value === formik.values.role)}
                                isDisabled={isInputDisebled}
                                onChange={(e) => {
                                  // handleFormData('designation', e.name)
                                  formik.setFieldValue('role', e.value)
                                }}
                                getOptionLabel={
                                  (option) => {
                                    return (
                                      <>
                                        <div>
                                          <span>{option.name}</span>
                                        </div>
                                      </>
                                    )
                                  }
                                }
                              />
                              {formik.errors.role && formik.touched.role ? (
                                <span className="text-danger">{formik.errors.role}</span>
                              ) : (null)}
                            </div>
                          </div>
                </div>

                {/* email */}
                <div className="emailId col-12 col-sm-12 col-md-12 col-lg-6">
                  <div>
                    <div className="mt-4">
                <label className="color-black fw-500 fs-19px">Email ID</label>
                    </div>
                    <div className="mt-2">
                  <input className="input-handle w-100 px-3" type="email" name="email" id="" disabled={isInputDisebled} placeholder="Email"  value={formik.values.email} onChange={(e)=>{formik.setFieldValue("email", e.target.value)}}/>
                    {formik.errors.email && formik.touched.email ? (
                      <span className="text-danger">{formik.errors.email}</span>
                    ) : (null)}
                    </div>
                  </div>
                </div>

                {/* Phone */}
                <div className="phone col-12 col-sm-12 col-md-12 col-lg-6">
            <div className="col-12">
              <div className="mt-4">
                <label className="color-black fw-500 fs-19px">Phone</label>
              </div>
              <div className="d-flex" >
                <div className="mt-2">
                  <select className="input-selects-phone input-group-text fw-500">
                    <option value="">+91</option>
                  </select>
                </div>
                <div className="mt-2" style={{ width: "100%"}}>
                  <input className="input-handle-phone px-2" type="text" name="phone" id="" disabled={isInputDisebled} placeholder="99******88" value={formik.values.phone} onChange={formik.handleChange}/>
                  {formik.errors.phone && formik.touched.phone ? (
                      <span className="text-danger">{formik.errors.phone}</span>
                    ) : (null)}
                </div>
              </div>
            </div>
          </div>
          {/* type */}

          <div className="typesOfcoordinator col-12 col-sm-12 col-md-12 col-lg-6">
                  <div>
                    <div className="mt-4">
                <label className="color-black fw-500 fs-19px">Secretariat Type</label>
                    </div>
                    <div className="mt-2">
                  <Select
                    options={assignDesination}
                  
                  isSearchable={false}
                  styles={{ ...customStyles}}
                  onChange={(e)=>{
                    formik.setFieldValue("secretariatType",e?.name)
                    setSecretariatType(e?.name);
                  }}
                  defaultValue={assignDesination?.find((i)=>i?.name === secretariatType)}
                  getOptionLabel={
                    (option) => {
                      return (
                        <>
                          <div className="d-flex justify-content-between
                          align-items-center">
                            <div className='delegate-secretariet-pressCorps fw-500' style={{ color: "#3A3A3A",fontSize:14 }}>{option.name}</div>                           
                          </div>
                        </>
                      )
                    }
                  }
                />
                    </div>
                  </div>
                </div>
                {/* Coordinator */}
                <div className="typesOfcoordinator col-12 col-sm-12 col-md-12 col-lg-6">
                  <div>
                    <div className="mt-4">
                <label className="color-black fw-500 fs-19px">Assign Designation</label>
                    </div>
                    <div className="mt-2">
                  {secretariatType && (
                    <Select
                    options={allSec.filter((i) => i.typeName === secretariatType).map((option) => ({
                      ...option,
                      isDisabled: option.role !== "Member",
                    }))}
                    defaultValue={allSec.filter((i) => i.typeName === secretariatType && i.role==="Member OC")}
                  isSearchable={false}
                  styles={{ ...customStyles,
                    option: (provided, state) => ({
                      ...provided,
                      opacity: state.data.isDisabled ? 0.6 : 1,
                      cursor: state.data.isDisabled ? "not-allowed" : "pointer",
                    }),
                  }}
                  onChange={handleSecretariatChange}
                  getOptionLabel={
                    (option) => {
                      return (
                        <>
                          <div className="d-flex justify-content-between
                          align-items-center">
                            <div className='delegate-secretariet-pressCorps fw-500' style={{ color: "#3A3A3A",fontSize:14 }}>{option.role}</div>
                            <div className="delegate-secretariet-pressCorps" style={{ fontWeight: "500", fontSize: "15px", color: "#ACACAC" }}>{option.applicants > 0  ? option.applicants+" " + "Applicants" :null }</div> 
                           
                          </div>
                        </>
                      )
                    }
                  }
                />
                  )}
                  {formik.errors.assign_designation && formik.touched.assign_designation ? (
                      <span className="text-danger">{formik.errors.assign_designation}</span>
                    ) : (null)}
                    </div>
                  </div>
                </div>
                

                {/* Button */}
                <div className="addCoordinator col-12 col-sm-12 col-md-12 col-lg-6  mt-3">
                  <div className="w-100 d-flex justify-content-center">
                  <input className="input-handle " type="submit" disabled={isDisabled} style={{background: "#6100FF", color:"white"}} value="Add Secretariat Member"/>
                  </div>
                </div>
                <div className="adminRight d-flex justify-content- align-items-center mt-md-3">
                <div><img src="/modelUn/Clipboard.png" alt="Clipboard" /></div>
              <div className="admin-content"><span className="" style={{color: "#979797"}}>The Secretariat is responsible for helping the...</span></div>
              </div>
              </div>
                </form>
              </div>
             
            </div>
           
            </div>
        </Modal.Body>
      </div>
      </Modal>
  </div>
    </>
  );
};
// 6
const AppointStudentMediaTeam = () => {
  const {userData} = useGlobalContext()
  const {setCurrentStep} = useContext(ModelUnContext)
  const [searchTitle, SetSearchTitle] = useState("");
  const [userDesignation,setUserDesignation] = useState("Reporter")
  const [openSearch,setOpenSearch] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [isDisabled,setIsDisabled] = useState(false)
  const [searchResults, setSearchResults] = useState([]);
  const [allUserList,setAllUserList] = useState([])
  const [pc,setPc] = useState([])
  const [pcLimit,setPcLimit]= useState(10)
  const [eventDetail, setEventDetail] = useState({})
  const [allPcRole,setAllPcRole] = useState([])
  const [isInputDisebled, setIsInputDisebled] = useState(false)

  const navigate = useNavigate()

  //======= Get Event Details which is institute register for perform =====\\
  const geteventDetail = ()=>{
    apiJson('api/v2/modelUn-student/getEventDetails/institute/'+userData?.id)
    .then((res)=>{
      setEventDetail(res.data.result)
    })
    .catch((error)=>{
      console.log(error.message)
    })
  }
  useEffect(()=>{
    geteventDetail();
  },[])
// ===========  Get All User List  Api calling =================\\
const getAllUserList = async() =>{
  try {
    const res= await apiJson.get(`/api/v2/modelUn-institute/betaCheckApplicantUserList/${userData?.id}`)
    setAllUserList(res?.data?.allUser)
      setSearchResults(res?.data?.allUser)
  } catch (error) {
    console.log("Error in Appoint Coordinator",error)
  }
}
//=========== Fetch all user Which is register in institute ==\\
useEffect(() => {
  const fetchData = async () => {
    try {
      await getAllUserList();
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };

  fetchData();
}, [userData.id]);

//========== Get All Presscorp  role with slots api ==========\\
const fetchSecretariat = async () => {
  try {
    if (eventDetail?.id) {
      const res = await apiJson(`api/v2/modelUn-institute/getAllPcDetails/institute/${userData?.id}/registerId/${eventDetail?.id}`);
      setAllPcRole(res.data.allSec);
    }
  } catch (error) {
    console.error("Error fetching secretariat details:", error);
  }
};
useEffect(() => {
  fetchSecretariat();
}, [eventDetail?.id]);

//======  Get all pre Selected secretariat details api  ====\\
const fetchPreRegisterPc = async () => {
  try {
    if (eventDetail?.id) {
      const res = await apiJson.get(`api/v2/modelUn-institute/getAllselectPcDetails/institute/${userData?.id}/registerId/${eventDetail?.id}`);
      setPc(res?.data?.allSelectPc?.filter((i)=> i?.status ==="approved"))
      setPcLimit(10-res?.data?.allSelectPc?.filter((i)=> i?.status ==="approved")?.length);
    }
  } catch (error) {
    console.error("Error fetching secretariat details:", error);
  }
};
useEffect(()=>{
  fetchPreRegisterPc()
},[eventDetail?.id])   

  ///  ============ User Search modal open handler ===================== \\
  const searchOpenHandle = () => {
    setOpenSearch(true)
  }
  ///  ============ User Search modal hide handler ===================== \\
  const searchHideHandle = () => {
    setOpenSearch(false)
  }
    const handleShowModal = () =>{
      setShowModal(true)
      searchHideHandle()
    }
    const handleClose = () =>{
      setShowModal(false)
    }

    const handlePcChange =(selectedOption)=>{
      formik.setFieldValue("assign_designation",selectedOption.role);
      formik.setFieldValue("pressCorpsId",selectedOption.value)
      setUserDesignation(selectedOption.role)
    }

    const validationSchema = yup.object().shape({
      name: yup.string().required("Username is required"),
      role: yup.string().required("Role is required"),
      email: yup.string().required("Email is required").email("Invalid Email Address"),
      phone: yup.string().required("Phone Number is Required").matches(/^[0-9]{10}$/, "Invalid Mobile Number"),
      assign_designation:yup.string().required("Please assign a designation.")
    });

    // ========== Submit handler when we select menually user for press corp in modal =============\\
    const onSubmit = async(values,{ resetForm }) => {
      const pcExist = pc.some((prc) => prc.email === values?.email);

      if (pcExist) {
        toast.dismiss();
        toast.error("Press corp with this email already exists");
        return;
      } 
    setIsDisabled(true)
      if (pcLimit !== 0) {
          try {
            const res = await apiJsonAuth.post(`/api/v2/modelUn-institute/addPcMember`, {
              ...values, instituteId: userData.id,
              registerId: eventDetail?.id,
              last_registration_date: eventDetail?.last_date
            })
            if (res?.status === 201) {
              toast.success("Press Corp is assign successfully.")
              resetForm()
              handleClose()
              fetchPreRegisterPc()
              getAllUserList()
              fetchSecretariat()
               setIsDisabled(false)
            }
          } catch (error) {
            toast.dismiss();
            console.log(error)
            setIsDisabled(false)

          }}else {
            toast.dismiss();
            toast.error("The limit for Press corp members has been reached.")
          }
        } 

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      role: "",
      pressCorpsId:"",
      assign_designation:"",
      pref_committee:"Press Corps",
      nominationType:"secure",
      status:"approved"
    },
    validationSchema: validationSchema,
    onSubmit
  })
const handleData =async(email) =>{
  const pcExist = pc.some((prc) => prc.email === email);
 
  if (pcExist) {
    toast.dismiss();
    toast.error("Press corp with this email already exists");
    return;
  } 
  setIsInputDisebled(true)
  const res = allUserList.find((i) => i?.email === email);
 formik.setValues({
  name: res?.name,
  email: res?.email,
  phone: res?.phone,
  role: res?.role,
  assign_designation:"",
  pressCorpsId:"",
  pref_committee:"Press Corps",
  nominationType:"secure",
  status:"approved",
  userRole:res?.role,
  studentId:res?.id
 })
 handleShowModal();
  searchHideHandle();
 }

  //  Notification
  // const sendNotification = async (email) => {
  //   const res = allUserList.find((i) => i?.email === email);
  //   const notifyData = {
  //     instituteId: userData?.id,
  //     userId: res?.id,
  //   }
  //   try {
  //     let response = await apiJsonAuth.post(`api/v2/notification/createNotify/${userData?.id}/${res?.id}`, notifyData)
  //     if (response?.status === 201) {
  //       toast.dismiss();
  //       toast.success("Notification sent successfully.")
  //     }
  //   } catch (error) {
  //     toast.error(error.meesage)
  //   }
  // }

//=========== Delete pc member by email========\\
const deleteSelectedPc = async(email)=>{
  try {
    const res = await apiJsonAuth.delete(`/api/v2/modelUn-institute/deleteSelectPcMember/${email}`)
    if(res.status===200){
      toast.dismiss();
      toast.success("Press Corp member deleted successfully..")
      fetchPreRegisterPc()
      fetchSecretariat();
      getAllUserList();
  //   const updatedCoordinatorData = allUserList?.find((i) => i?.email === email);
  // if(allUserList.some((item) => item?.email === updatedCoordinatorData?.email)){

  //   setSearchResults([...searchResults,updatedCoordinatorData])
  // }
    }
  } catch (error) {
    console.log("error",error.meesage)
  }
}
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));


  return (
    <>
    <div>
      <span className="fw-500 fs-20px" style={{color: "#979797"}}>Events {">"} United Nations {">"} Registration</span>
    </div>
      <div className="mt-3 mb-2">
        <span className=" fs-2 fw-semibold">Appoint Press Corps Members</span>
        <BootstrapTooltip title="The Press Corps stands as the dedicated team of students tasked with creatively documenting the event through their skills as writers, photographers, and caricaturists.
">
        <Button sx={{border:"2px solid silver", color:"black", padding:"0",minWidth:"30px", marginBottom:"10px", marginLeft:"10px"}}>?</Button>
      </BootstrapTooltip>
      </div>

      <div className="mb-50 col-12 col-sm-12 col-md-7">
        <span className="color-grey fs-15px fw-500">
        They will be required to click photos/videos, write the content for promotional activities and draw the summary of the event. They will act as Photographers, Reporters and Caricaturists.
        </span>
      </div>

      <div className="col-12 col-sm-12 col-md-8 col-lg-7">
      <div className="card px-2 dashed-border rounded-4 cursor-pointer mb-4">
        <div className="card-body">
          <div className="d-flex align-items-center" onClick={searchOpenHandle}>
            <div className="me-2">
              <span>
              <AddCircleIcon color="success" style={{ fontSize: "35px" }} />
              </span>
            </div>
            <div className="ms-2">
              <span className="fs-6 fs-md-5 fw-semibold">
              Add Press Corps Members
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="background-purple rounded px-2 py-2 mb-50 border-purple">
        <span className="fs-6 fw-semibold color-purple ">
          <span className="me-2">
            {<AssignmentIcon className="mt-1" sx={{color: "#28007d"}} />}
          </span>
          Select up to {pcLimit} students who will act like journalists for the events
        </span>
      </div>

      <div className="d-flex flex-column mb-50">
        <div className="d-flex justify-content-between mb-4">
          <span className="fs-4 fw-500">Press Corps Members</span>
          {/* <div className="d-flex">
          <span className="color-purple"><KeyboardArrowLeftIcon style={{ fontSize: "35px" }}/></span>
          <span className="color-purple"><KeyboardArrowRightIcon style={{ fontSize: "35px" }}/></span>
          </div> */}
        </div>
        <div className="scroll-modelun-register">
          {pc?.map((c,id) => {
          
            return (
              <>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span className="fs-5 fw-500">{c.name}</span>
                  <div className="d-flex align-items-center">
                    <div className="me-3 cursor-pointer">
                      {/* <Select
                        styles={customStylesPurple}
                        options={studentMedia}
                        defaultValue={
                          studentMedia.find((i) => i.des === c?.role)
                        }
                        getOptionLabel={(option) => {
                          return (
                            <>
                              <div className="p-2"> */}
                              <span className="color-purple fs-17px fw-semibold rounded-3 text-center px-3  py-2" style={{background:"#E5D6FF70"}}>

                                  {c?.assign_designation}
                                </span>
                              {/* </div>
                            </>
                          );
                        }}
                      /> */}
                    </div>
                    <div onClick={()=>deleteSelectedPc(c.email)}
                      className="d-flex align-items-center justify-content-center cursor-pointer "
                      style={{
                        background: "#FFD6D6",
                        width: "37px",
                        height: "37px",
                        borderRadius: "7px",
                      }}
                    >
                 <span> <RemoveCircleTwoToneIcon color='error' /></span>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>

      <div className="d-flex justify-content-between mb-3 mb-md-0 mt-4">
        <button
          onClick={()=>setCurrentStep(8)}
          className="btn-onboard fs-19px fw-500 mb-5 mb-md-0 "
          style={{color: "#808080"}}
        >
          Previous
        </button>
        <button
          onClick= {()=>navigate("/model-un/teamRegistration")}
          className="btn-onboard-fill mb-5 mb-md-0"
        >
                <span>Submit</span>
                <img className="ms-2" src="/images/onBoarding/right-icon.png" alt="right-icon" />    
        </button>
      </div>

      </div>
{/*  ========= Open search user modal for assign SECRETARIAT MEMBERS  for  same  institute =======*/}
<div className="container" >
      <Modal show={openSearch} className="left-rounded-modal" onHide={searchHideHandle} size="lg">
      <div className="modal-content">  
          <span className="content-title fw-600 color-black fs-2 ps-4 px-md-5 mt-3">
          Add Press Corp Members
          <Tooltip title={
          <>
            <div>
              <strong>Press Corps (Photographer, Caricaturist, Reporter):</strong>
            </div>
            <div>
              <strong>Photographer:</strong> Takes pictures to capture moments during the conference.
            </div>
            <div>
              <strong>Caricaturist:</strong> Draws funny and creative cartoons to add a bit of humour to the conference.
            </div>
            <div>
              <strong>Reporter:</strong> Writes stories and reports on what's happening at the conference.
            </div>
          </>
} placement="bottom">
  <span>
    <HelpOutlineIcon sx={{color: "#5686E1", fontSize: "20px"}} />
  </span>
</Tooltip>
          </span>
      <span className="fs-19px ps-4 px-md-5 mt-4" style={{color:"#000000"}}>Search for the Student/Teacher here by typing their name</span>
        <Modal.Body>
       
                <form>
                <div className="mt-2 px-2 px-md-4 px-lg-4">
                  <input className="search-handle px-2" type="text" name="name" placeholder="Type a name" onChange={(e) =>{
                    SetSearchTitle(e.target.value)
                   }}/>
                    </div>
                    <div className="bg-light search-handleList mt-3  mx-2 mx-md-4 rounded-3">
                     <div className="databox" style={{overflowY:"auto"}}>

                    {searchResults?.filter((value) => {
                      if (searchTitle === "") {
                        return value;
                      } else if (
                        value.name.toLowerCase().includes(searchTitle.toLowerCase())
                      ) {
                        return value;
                      }

                    }).map((i) =>
                        (
                          <div className={`d-flex  mt-2 cursor-pointer p-2 rounded-3 ${i?.applicantId !== null ? "opacity-50" : null}`} onClick={()=>i?.applicantId === null ? handleData(i?.email,"add") : null} key={i?.email} style={{background: "#d1d1d1",alignItems: "center"}}>
                          <div style={{width:50,height:50 ,minWidth:50,minHeight:50}}><img src={i?.profile} alt="profile_image" className="w-100 h-100 rounded-circle" style={{border:"2px solid #b139f4"}}/></div>
                          <div className="ms-3">
                            <span className="fs-6 text-capitalize">{i?.name}</span>
                            {i?.applicantId !== null  ? <span className="fs-6 text-capitalize"> Applicant for {`${i?.preferredComm} ${i?.preferredDesignation} ${i?.preferredCountry} ${i?.preferredRole}`} </span> : <span className="fs-6 text-capitalize" style={{color:"#5A00EE"}}>(Not applied for participation [Invite])</span>
                             }
                          </div>
                          {i?.applicantId === null  ?(
                          <div className="me-3">
                          <span>
                            <AddCircleIcon  color="success"/>
                          </span>
                          </div>) : null}
                      </div> 
                        )
                      )}
                     </div>
                     
                    </div>
                     
                </form>
        </Modal.Body>
      </div>
      </Modal>
    </div>
    {/* ======= ADD external user for as a  SECRETARIAT MEMBERS  Modal =========  */}
      <div className="container" >
      <Modal show={showModal} className="left-rounded-modal" onHide={handleClose} size="lg">
  <div className="modal-content">
        <Modal.Header closeButton>
          <Modal.Title className="content-title fw-600 color-black fs-34px ps-4 ps-md-0 px-md-5 ">
          New Press Corps Member
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
          <div class="bd-example-modal-lg px-5" style={{height: ""}}>
            <div className="row">
              <div className="column col-12  col-lg-10 ">
                <form onSubmit={formik.handleSubmit}>
              <div className="row">
              {/* userName */}
                <div className="firstName col-12  col-md-12  col-lg-6">
                  <div>
                    <div className="mt-2 mt-md-1">
                  <label className="color-black fw-500 fs-19px">Full Name</label>
                    </div>
                    <div className="mt-2">
                  <input className="input-handle w-100 px-2" style={{  border:"1px solid #e9e9e9"}} type="text" name="name" placeholder="Name" value={formik.values.name} disabled={isInputDisebled} onChange={formik.handleChange} id="" />
                  {formik.errors.name && formik.touched.name ? 
                  (<span className="text-danger">{formik.errors.name}</span>) : (null)}
                    </div>
                  </div>
                </div>
                <div className=" col-12 col-md-12 col-lg-6">
                  <div>
                    <div>
                <label className="color-black fw-500 fs-19px">User Type</label>
                    </div>
                        <div className="mt-2">
                          <Select
                            styles={customStyles}
                            options={showDesList}
                            isDisabled={isInputDisebled}
                            defaultValue={showDesList.find((i) => i?.value === formik.values.role)}
                            onChange={(e) => {
                              formik.setFieldValue('role', e.value)
                            }}
                      
                            getOptionLabel={
                              (option) => {
                                return (
                                  <>
                                    <div>
                                      <span>{option.name}</span>
                                    </div>
                                  </>
                                )
                              }
                            }
                          />
                          {formik.errors.role && formik.touched.role ? (
                            <span className="text-danger">{formik.errors.role}</span>
                          ) : (null)}
                        </div>
                        </div>
                        </div>
                {/* assignRole */}
                <div className="col-12 col-md-12 col-lg-6">
                  <div>
                    <div className="mt-2 mt-md-1">
                <label className="color-black fw-500 fs-19px">Assign Designation</label>
                    </div>
                    <div className="mt-2">
                  <Select
                     options={allPcRole.map((option) => ({
                      ...option,
                      isDisabled: pcLimit === 0,
                    }))}
                  isSearchable={false}
                  styles={{ ...customStyles,option: (provided, state) => ({
                    ...provided,
                    cursor: pcLimit < 1 ? "not-allowed" : "pointer",
                    opacity: pcLimit < 1 ? 0.6 : 1,
                  }),}}
                  onChange={handlePcChange}
                  getOptionLabel={
                    (option) => {
                      return (
                        <>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className='delegate-secretariet-pressCorps fw-500'style={{ color: "#3A3A3A" }}>{option.role}</div>
                            <div className="delegate-secretariet-pressCorps" style={{ fontWeight: "500", fontSize: "15px", color: "#ACACAC" }}>{option.applicants > 0  ? option.applicants+" " + "Applicants" :null }</div> 
                           
                          </div>
                        </>
                      )
                    }
                  }
                />
                  {formik.errors.assign_designation && formik.touched.assign_designation ? 
                  (<span className="text-danger">{formik.errors.assign_designation}</span>) : (null)}
                    </div>
                  </div>
                </div>

                {/* EmailId */}
                <div className="emailId col-12 col-sm-12 col-md-12 col-lg-6">
                  <div>
                    <div className="mt-2 mt-md-1">
                <label className="color-black fw-500 fs-19px">Email ID</label>
                    </div>
                    <div className="mt-2">
                  <input className="input-handle w-100 px-2" style={{  border:"1px solid #e9e9e9"}} disabled={isInputDisebled}  type="email" name="email" id="" placeholder="email"  value={formik.values.email} onChange={(e)=>{formik.setFieldValue("email", e.target.value)}}/>
                  {formik.errors.email && formik.touched.email ? 
                  (<span className="text-danger">{formik.errors.email}</span>) : (null)}
                    </div>
                  </div>
                </div>

                {/* Phone */}
                <div className="phone col-12 col-sm-12 col-md-12 col-lg-6">
            <div className="col-12">
              <div className=" mt-2 mt-md-1">
                <label className="color-black fw-500 fs-19px">Phone</label>
              </div>
              <div className="d-flex" >
                <div className="mt-2">
                  <select className="input-selects-phone input-group-text fw-500">
                    <option value="">+91</option>
                  </select>
                </div>
                <div className="mt-2" style={{ width: "100%"}}>
                  <input className="input-handle-phone px-2" type="text" name="phone" id="" disabled={isInputDisebled} style={{  border:"1px solid #e9e9e9"}}  placeholder="99******88"  value={formik.values.phone} onChange={formik.handleChange}/>
                  {formik.errors.phone && formik.touched.phone ? 
                  (<span className="text-danger">{formik.errors.phone}</span>) : (null)}
                </div>
              </div>
            </div>
          </div>

                {/* Button */}
                <div className="addCoordinatorcol-12 col-sm-12 col-md-12 col-lg-6 mt-2 mt-md-4">
                  <div className="w-100 d-flex justify-content-center mt-2 mt-md-3" >
                  <input className="input-handle" type="submit" disabled={isDisabled} style={{background: "#6100FF", color:"white"}} value="Add Member"/>
                  </div>
                </div>
                <div className="adminRight d-flex justify-content- align-items-center mt-md-3">
                <div><img src="/modelUn/Clipboard.png" alt="Clipboard" /></div>
                {userDesignation ==="Reporter"  ? (

              <div className="admin-content"><span className="" style={{color: "#979797"}}>Writes stories and reports on what's happening at the conference.</span></div>
                ) : userDesignation ==="Caricaturist" ? (
              <div className="admin-content"><span className="" style={{color: "#979797"}}>Draws funny and creative cartoons to add a bit of humour to the conference.</span></div>
                ) :
                (
                  <div className="admin-content"><span className="" style={{color: "#979797"}}> Takes pictures to capture moments during the conference.</span></div>
                )       
                }
              </div>
              </div>
                </form>
              </div>
            </div>
            </div>


        </Modal.Body>
      </div>
      </Modal>
  </div>
    </>
  );
};
// 4
const Congratulations = () => {
  const [viewSummary, setViewSummary] = useState(false);
  const {userData} = useGlobalContext()
    const {setCurrentStep,formik} = useContext(ModelUnContext)

    const navigate = useNavigate()
    const handleCountinue = ()=> {
        navigate("/model-un")
    }

    const handleToggleViewSummary = ()=>{
      setViewSummary(!viewSummary)
    }

  return (
    <>
      <div className="py-1">
      <div>
      <span className="fw-500 fs-20px" style={{color: "#979797"}}>Events {">"} United Nations {">"} Registration</span>
      </div>

      <div className="col-12 col-sm-12 col-md-8 col-lg-8">
        <div className="d-flex mt-4 justify-content-center align-items-md-center">
          <div style={{ position: "relative" }}>
            <img
              src="/images/model-UN/prize.png"
              alt="prize"
              style={{ position: "absolute", top: 0, left: "100px", width: "125px",height: "125px" }}
            />
            <img style={{width: "328px",height: "140px"}} src="/images/model-UN/piece-of-paper.png"  alt="piece-of-paper"/>
          </div>
        </div>

        <div className="d-flex  justify-content-center justify-content-md-center mb-4">
          <span className="fs-35px fw-600">Congratulations!</span>
        </div>

        <div className="d-flex justify-content-md-center text-center mb-4 px-3">
          <span className="fs-19px fw-500">
            Congratulations on completing your registration for{" "}
            <span className="fw-700 fs-19px">Yuvamanthan Model United Nations!</span>{" "}
            We're thrilled that you've taken the initiative to organize this
            event. Here are a few impactful next steps you might consider
            (although you can also entrust these steps to your teacher
            coordinator if you prefer):
          </span>
        </div>
            {/* View summary */}
            <div>
              <div onClick={handleToggleViewSummary} className="d-flex justify-content-center mx-auto">
              <div><span className="fw-600 fs-19px" style={{color: "#9000E9"}}>View event summary</span></div>
              <div ><div><KeyboardArrowDownIcon sx={{color: "#7D0092"}}/></div></div>
              </div>
                
                {
                  viewSummary ? (
                    <>
              <div className="mt-2 d-flex flex-row align-items-center">
                <div style={{flexGrow: "0" , border: "1px"}}><span className="fw-500 fs-15px" style={{color: "#B4B4B4"}}>Overview</span></div>
                <div style={{flexGrow: "1", height: "2.5px", margin: "0 0 0 0.8rem", background: "#E0E0E0"}}></div>
              </div>

              <div className="mt-2 row">
                <div className="col-4">
                <div className="mt-3 fw-500"><span>Event Type</span></div>
                </div>
                <div className="col-8
                ">
                <div className="mt-3 fw-700 fs-19px"><span>{formik.values.eventType}</span></div>
                </div>
                <div className="col-4">
                <div className="mt-3 fw-500"><span>Event Format</span></div>
                </div>
                <div className="col-8
                ">
                <div className="mt-2 fw-700 fs-19px"><span>{formik.values.chooseFormat}</span></div>
                </div>
                <div className="col-4">
                <div className="mt-3 fw-500"><span>Date of Event</span></div>
                </div>
                <div className="col-8
                ">
                <div className="mt-2 fw-700 fs-19px"><span>{formik.values.lastDateEvent}</span></div>
                </div>
                <div className="col-4">
                <div className="mt-3 fw-500"><span>Registration Deadline</span></div>
                </div>
                <div className="col-8
                ">
                <div className="mt-2 fw-700 fs-19px"><span>{formik.values.lastDateRegis}</span></div>
                </div>
                <div className="col-4">
                <div className="mt-3 fw-500"><span>Venue</span></div>
                </div>
                <div className="col-8
                ">
                <div className="mt-2 fw-700 fs-19px"><span>{formik.values.event_venue}</span></div>
                </div>
                <div className="col-4">
                <div className="mt-3 fw-500"><span>Event Time</span></div>
                </div>
                <div className="col-8
                ">
                <div className="mt-2 fw-700 fs-19px"><span>{formik.values.event_time}</span></div>
                </div>
              </div>

              {/* Committees */}
              <div className="mt-3 d-flex flex-row align-items-center">
                <div style={{flexGrow: "0" , border: "1px"}}><span className="fw-500 fs-15px" style={{color: "#B4B4B4"}}>Committees</span></div>
                <div style={{flexGrow: "1", height: "2.5px", margin: "0 0 0 0.8rem", background: "#E0E0E0"}}></div>
              </div>

              <div className="mt-2 row">
      <div className="scroll-modelun-register">
      
      {  formik?.values.slectedCommittee?.map((i)=>{
        return <>
        <div className="d-flex justify-content-around align-items-center mt-1" style={{background: "#F9F9F9", height: "55px", borderRadius: "8px"}}>
          <div className="col-8"><span className="fw-500 fs-19px" style={{color: "#3A3A3A"}}>{i.committee}</span></div>
          <div className=""><span className="fs-19px fw-500" style={{color: "#4A00E8"}}>{i.slots}</span></div>
          </div>
        </>
      })  
          }
            
      </div>
          </div>
                    </>
                  ) : (null)
                }


            </div>

        <div className="d-flex justify-content-center justify-content-md-center mb-5 mb-md-0 mt-md-4 mt-3">
        <button
          onClick={handleCountinue}
          className="btn-onboard-fill mb-3 mb-md-0"
        >
          <span>Go to Event</span>
        </button>
      </div>
      </div>

        
      </div>
    </>
  );
};

// 7
const ChooseEventTheme = () => {
    const {setCurrentStep, formik,subTheme } = useContext(ModelUnContext)

    const [subThemeToggle, setSubThemeToggle] = useState(false);

    const themeToggle = () =>{
      setSubThemeToggle(true)
    }
    // console.log("subTheme", subTheme.mainthemeId);
  return (
    <>
      <div>
      <div>
      <span className="fw-500 fs-20px" style={{color: "#979797"}}>Events {">"} United Nations {">"} Registration</span>
    </div>

    {/* Choose EventTheme */}
        <div className="d-flex align-items-center mt-3 mb-2">
          <div>
          <span className="fs-32px fw-600">Choose a Event Theme</span>
          </div>
          <div>
        <div className="ms-3"><HelpOutlineIcon sx={{color: "#5686E1", fontSize: "20px"}}/></div>
      </div>
        </div>

        <div className="mb-50">
          <span className="fs-20px fw-500" style={{color: "#979797"}}>
            Choose a theme for the event that you want to organise.
          </span>
        </div>
        
        <div className="col-12 col-sm-12 col-md-8 col-lg-7">
          <form onSubmit={formik.handleSubmit}>
        {/* Sub theme */}
        <div className="mt-4">
          <span className="fs-19px fw-500 mb-2 d-block">Select a sub theme</span>
          <div  onClick={themeToggle}>
            <Select 
            onChange={(e)=> formik.setFieldValue("subTheme", e.dataValues?.sub_theme_title)}
              styles={customStyles}
              options={subTheme}
              defaultValue={subTheme[0]}
              getOptionLabel={(option, id) => {
                // console.log("option", option.dataValues);
                return (
                  <>
                    <div key={id} className="">
                      <span className=" fs-19px fw-500 text-center">
                        {option?.dataValues?.sub_theme_title}
                      </span>
                    </div>
                  </>
                );
              }}
            />
          </div>
          {formik.errors.subTheme && formik.touched.subTheme ?
          <span className="text-danger">{formik.errors.subTheme}</span> : null}
        </div> 

            {/* Theme title */}
            { subThemeToggle &&  
              <div>
                <span className="fs-19px fw-500 mb-2 d-block mt-4">Enter your theme title</span>
                <div className="col-12">
                  <input
                  //  onChange={(e)=> formik.setFieldValue("eventTheme", eventTheme)}
                  name="eventTheme"
                  onChange={formik.handleChange}
                  value={formik.values.eventTheme} 
                  style={{width: "100%", height: "45px", background: "#a2a2a221",  border: "none", outline: "none" ,padding: "11px", borderRadius: "8px"}} placeholder="Eg. Protect Earth"/>
                </div>
                {/* {formik.errors.eventTheme && formik.touched.eventTheme ?
                <span className="text-danger">{formik.errors.eventTheme}</span> : null} */}
              </div>
            }

        <div className="d-flex justify-content-between align-items-end mx-auto mt-5">
        <button
          onClick={()=>setCurrentStep(1)}
          className="btn-onboard fs-19px fw-500"
          style={{color: "#808080"}}
        >
          Previous
        </button>
        <button
        type="submit"
          onClick={
            formik.values.subTheme ?
              ()=> setCurrentStep(3) :
              null
           }
          className="btn-onboard-fill"
        >
          <span>Save & Continue</span>
          <img className="ms-2" src="/images/onBoarding/right-icon.png"  alt="sendicon" />
        </button>
      </div>
      </form>

        </div>
      </div>
    </>
  );
};

// 8
const PositionAllotment = () =>{
    const {setCurrentStep, formik,setUnData,} = useContext(ModelUnContext)

// Delete

const [committeeForm, setCommitteeForm] = useState(formik?.values?.slectedCommittee||[]);
const [committeeArray, setCommitteeArray] = useState([])
const [showDetailModal, setShowDetailModal] = useState(false)
const [comIndex, setcomIndex] = useState(false)

const newHandleChange = ()=>{
  setUnData('slectedCommittee',committeeForm);
}

  // handle input committee and slots
  const handlePositionsChange = (e,title,change,id,minSlots) => {
      const isAlreadyAdded = committeeForm?.some((item) => item?.committee === title);

      if (isAlreadyAdded) {
        if(change){
          setCommitteeForm((prevForm) =>
          prevForm.map((item) =>
            item.committee === title
              ? { ...item, slots: parseInt(e.target.value), committeeId:id ,minSlots}
              : item
          )
        );
        }
        else{
        setCommitteeForm((prevForm) => prevForm?.filter((item) => item?.committee !== title));
        }
      } else {
        setCommitteeForm((prevForm) => [...prevForm, { committee: title, slots: change && e.target.value, committeeId:id,minSlots}]);
      }
  };

  const handleTrack = (track, committeeId) => {
    const hasG20Committee = committeeForm?.find((ele) => ele?.committee === "G20");
  
    if (hasG20Committee) {
      const existingCommitteeForm = [...committeeForm];
  
      const index = existingCommitteeForm.findIndex((ele) => ele?.committee === "G20");
  
      if (index !== -1) {
        let tracksTemp = existingCommitteeForm[index]?.tracks || [];
        let currentValue = existingCommitteeForm[index]?.slots || 0;
  
        // Check if the track is already selected
        const isTrackSelected = tracksTemp.some((t) => t.name === track);
  
        if (isTrackSelected) {
          // If track is selected, decrease the value by 20
          const updatedValue = currentValue - 20;
          existingCommitteeForm[index] = {
            ...existingCommitteeForm[index],
            tracks: tracksTemp.filter((t) => t.name !== track),
            slots: updatedValue >= 0 ? updatedValue : 0,
          };
        } else {
          // If track is not selected, increase the value by 20
          const updatedValue = currentValue + 20;
          existingCommitteeForm[index] = {
            ...existingCommitteeForm[index],
            tracks: [...tracksTemp, { name: track }],
            slots: updatedValue,
          };
        }
  
        // Use setCommitteeForm to update the state
        setCommitteeForm(existingCommitteeForm);
      }
    }
  };
  const LIFEEVENT = [
    'COP29','All India Political Parties Meet (AIPPM)','UNDP (United Nations Development Programme)',
    'UNEP (United Nations Environment Programme)','ECOSOC (United Nations Economic and Social Council)',
  ]

  const getAllCommittee = ()=>{
    apiJson('api/v2/modelUn-institute/getAllCommitee')
    .then((res)=>{
      if(formik?.values?.chooseFormat==="Lifestyle for Environment in Partnership with UNDP"){
      setCommitteeArray(res?.data?.result?.filter((i)=>LIFEEVENT.includes(i?.committees)))

      }
      else{
        setCommitteeArray(res.data.result)
      }
    })
  }
  useEffect(()=>{
    getAllCommittee()
  },[])
  // useEffect(()=>{
  // //  setCommitteeForm([])
  // },[formik?.values?.chooseFormat])

  const handleNextComm = () => {
    let emptyFieldIndex = committeeForm.findIndex((ele) => (!ele?.committee || !ele?.slots) && ele?.committee !== "G20");
    let findSlots = committeeForm?.some((ele) => ele?.committee === "G20" && (ele?.slots === false || ele?.slots === 0));
    let miniSlotsProblem = committeeForm?.some((ele)=>ele?.slots<ele?.minSlots)
    let reachMaxEventLimit = committeeForm?.length>3
    // console.log("findSlots", findSlots);
    if (reachMaxEventLimit){
      toast.dismiss()
      toast.error(`Max 3 Event Allowed`)
    }
    else  if ( emptyFieldIndex !== -1) {
      toast.dismiss()
      toast.error("Slots is Required")
    }
 
    else if(committeeForm.length === 0){
      toast.dismiss()
      toast.error("Committee and Slots are Required")
    }
    
    else if(miniSlotsProblem){
      toast.dismiss()
      toast.error(`Slots cannot be less than the minimum requirement`)
    }
    else if(findSlots){
      toast.dismiss();
      toast.error("Select Track Slots")
    }
    
    else  {
      toast.dismiss();
      setCurrentStep(4);
    }
  };

  const checkIfLengthGreaterThan3AndValuePresent = (id)=>{
    return (committeeForm?.some(i=>i?.committeeId==id) && committeeForm?.length>=3)
  }


  // console.log("committeeForm", committeeForm)

  return(
    <>
    <div>
     <div>
      <span className="fw-500 fs-20px" style={{color: "#979797"}}>Events {">"} United Nations {">"} Registration</span>
    </div>

      {/* Select Committees */}
        <div className="d-flex align-items-center mt-3">
          <div>
          <span className="fs-32px fw-600">Select Committees</span> 
          <Tooltip title="Each Committee meeting will require a separate room/hall for at least 3 hours. You will manually input the number of desired participants in each committee meeting. The minimum number of participants per committee is already specified." placement="bottom">
          <span>
            <HelpOutlineIcon sx={{color: "#5686E1", fontSize: "20px"}}/>
          </span>
          </Tooltip>
          </div>
          <div>
        
      </div>
        </div>

        <div><span className="fw-500 fs-20px" style={{color: "#979797"}}>Specify the number of participants you wish to have in each committee meeting. (You can choose up to three committees.)</span></div>

      {/* Select  committee */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-11">
        <form onSubmit={formik.handleSubmit}>
      <div className="mt-4">
      <div className="col-12">

        <div className="row ">

          <div className="d-flex justify-content-between col-12 col-md-8">
            <div><span className="fs-19px fw-600">Committee</span></div>
            <div><span className="fs-19px fw-600">No. of Students</span></div>
          </div>

          <div className="">
          <div className="row">
            {committeeArray?.map((ele,i)=>{
               const committeeItem = committeeForm?.find((item, i) => item?.committee === ele?.committees) || { committee: "", slots: 0};
              //  console.log("tracks",committeeItem?.tracks);
              // console.log('committeeForm?.length>=3',committeeForm?.length>=3)
                return (
                  <>
                  
                  <div key={i} className={`col-6 col-lg-6 mt-3 ${checkIfLengthGreaterThan3AndValuePresent(ele?.id)?'':committeeForm?.length<=2?'':'btn-onboard-fill-disabled-Committee-New'} ${ele?.diabledcss ? "btn-onboard-fill-disabled-Committee" : null}`}>
                    <div className="d-flex align-items-center">
                      <div><input style={{height: "18px", width:"18px"}} onClick={() => ele?.committees === "G20" } className="inputcheck" type="checkbox" disabled={checkIfLengthGreaterThan3AndValuePresent(ele?.id)?false:committeeForm?.length<=2?false:true}
                      name="committee" checked={committeeItem?.committee === ele?.committees} onChange={(e)=>{
                        handlePositionsChange(e,ele?.committees, false, ele.id,ele?.minSlots)
                      }}/></div>
                    
                      <div className="col-12 ms-lg-3">
                        <span className="committee-content fw-500 d-block" style={{ fontFamily : "Poppins"}}>{ele?.committees}</span>
                        <span className="fs-19px fw-600" style={{color :"#4A00E8", fontFamily : "Poppins"}}>{ele?.range}</span>
                      </div>
                    </div>
              </div>

              <div className={`col-6 col-lg-6 mt-3  ` }>
              <div className="d-flex justify-content-start justify-content-md-around align-items-center MunSlotsContainer " >
                <div className={`${checkIfLengthGreaterThan3AndValuePresent(ele?.id)?'':committeeForm?.length<=2?'':'btn-onboard-fill-disabled-Committee-New'}`}>

                <div className="MunSlots">
                  <input className={`MunSlotsInput ${ele?.committees === "G20" || ele?.committees === "G20" && committeeForm?.find((i) => i?.committee === "G20")?.tracks?.length > 0 ? "btn-onboard-fill-disabled-Committee" : null}`}
                   placeholder={`Min ${ele?.minSlots}`}
                  type="number" name="slots" value={committeeForm?.find((i)=>i?.committee===ele?.committees)?.slots || ""} onChange={(e)=> handlePositionsChange(e,ele?.committees, true, ele.id,ele?.minSlots)}
                  disabled={ele?.committees === "G20" || ele?.committees === "G20" && committeeForm?.find((i) =>i?.committee === "G20")?.tracks?.length > 0 || checkIfLengthGreaterThan3AndValuePresent(ele?.id)?false:committeeForm?.length<=2?false:true}
                  />
                  {/* {committeeItem?.committee === ele?.committees && <div className="text-center"><span className="text-danger">{errorHandle}</span></div>} */}
                  

                </div>

                </div>
               {ele?.detail && <div className=" cursor-pointer">
                  <Button onClick={()=>{setShowDetailModal(true);setcomIndex(i)}} className="fw-semibold rounded-3 fs-6 text-capitalize" style={{color: "#4A00E8"}}>Details</Button>
                </div>}
              </div>
              </div>
              
                    <div>
                    {ele?.committees === "G20" && (
                    <div>
                      {committeeItem?.committee === ele?.committees && 
                        <div>
                        <div>
                          <span>Select Track</span>
                        </div>
                        {committeeTrack?.map((ele,i ) => {
                          const committeeTracks =
                            committeeForm?.find((item, i) => item?.committee === "G20") || {
                              committee: "",
                              slots: 0,
                            };
                          
                          const committeeTrackMap = Object.fromEntries(
                            committeeTracks.tracks?.map((item) => [item.name, item]) || []
                          );

                          const trackInfo = committeeTrackMap[ele?.track] || {
                            committee: "",
                            slots: 0,
                          };
                        
                      
                          return (
                            <div key={ele?.id}>
                              <div className="expand">
                                <div className="col-3">
                                  <div className="d-flex align-items-center mt-2">
                                    <div>
                                      <input
                                        type="checkbox"
                                        className="inputcheck"
                                        checked={trackInfo?.name === ele?.track} // Changed committeeItem to committeeTracks
                                        name="tracks"
                                        style={{ width: "18px", height: "18px" }}
                                        onChange={(e) => handleTrack(ele?.track, ele.id)}
                                      />
                                    </div>
                                    <div>
                                      <span>{ele?.track}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      
                      }
                      </div>
                  )}
                  </div>
              <div className="col-10">
              <hr style={{color: "#F1F1F1"}}/>
              </div>
            
                  </>
                )
              })
            }
          </div>
          </div>
          </div>
        </div>

      </div>

      </form>
    

      
   

    <div className="d-flex justify-content-between mt-5 mb-5 mb-md-2 col-12">
        <button
          onClick={()=>{setCurrentStep(1);setCommitteeForm([])}}
          className="btn-onboard fs-19px fw-500 mb-2 mb-md-0"
          style={{color: "#808080"}}
        >
          Previous
        </button>
        <button
          onClick={()=>{
            newHandleChange()
            handleNextComm()
          }}
          className="btn-onboard-fill fw-500 mb-3 mb-md-2 mb-md-0"
          type=""
          >
          Save & Contitue
          <img className="ms-2" src="/images/onBoarding/right-icon.png"  alt="sendicon" />
        </button>
      </div>

        </div>


    </div>
    <Modal size="lg" show={showDetailModal} scrollable onHide={()=>setShowDetailModal(false)}>
                <Modal.Body>
                        <div>
                          <div className="mb-3">
                            <h3 className="fs-4 fw-semibold">{committeeArray[comIndex]?.detail?.title}</h3>
                          </div>
                          <div>
                            {
                              committeeArray[comIndex]?.detail?.info?.map((i)=>{
                                return (<>
                                    <div className=" ">
                                      <h3 className="fs-5 fw-semibold">{i?.infoTitle}: </h3><p className="fs-6 fw-300">{i?.infoDetail}</p>
                                    </div>
                                </>)
                              })
                            }
                          </div>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                  <button onClick={()=>setShowDetailModal(false)} className="btn btn-sm btn-outline-danger rounded-3">Close</button>
                </Modal.Footer>
              </Modal>
    </>
  )
}
const detailArray = [
  {
    title: "COP'29 (UNFCCC)",
    info: [
      {
        infoTitle: 'Negotiations',
        infoDetail: `Countries talk about how to cooperate on climate issues, focusing on the preservation and improvement of life on Earth.`,
      },
      {
        infoTitle: 'Review of Progress',
        infoDetail: `Countries share updates on what they've done to tackle climate change and contribute to the overall mission of sustaining and enhancing life.`,
      },
      {
        infoTitle: 'Discussion of Key Issues',
        infoDetail: `People discuss important topics such as new problems, scientific findings, and ways to fix things, all within the broader goal of supporting and enriching life on our planet.`,
      },
      {
        infoTitle: 'Adoption of Resolutions',
        infoDetail: `Countries agree on official plans and actions that align with the LIFE Mission, working together to create a better environment for all living beings.`,
      },
      {
        infoTitle: 'Side Events',
        infoDetail: `Extra activities and talks by different groups emphasize ideas and solutions that directly contribute to the mission of enhancing and safeguarding life.`,
      },
      {
        infoTitle: 'Participation of Observers',
        infoDetail: `Various groups, including organizations and experts, join in to share their thoughts and suggestions, contributing to the collective effort to fulfill LIFE Mission and address climate challenges.`,
      },
    ],
  },
  {
    title: "All India Political Parties Meet",
    info: [
      {
        infoTitle: 'Talk about Big National Issues',
        infoDetail: `Political parties from all over India meet to discuss and solve important problems that affect everyone.`,
      },
      {
        infoTitle: 'Decide on Rules for a Better Life',
        infoDetail: `They make decisions on rules and plans that aim to make life better for all citizens, considering things like the environment and long-term well-being.`,
      },
      {
        infoTitle: 'Work Together for a Sustainable Future',
        infoDetail: `Even if they have different ideas, parties cooperate to tackle challenges and aim for a future that's good for people and the planet.`,
      },
      {
        infoTitle: 'Think About Different Parts of India',
        infoDetail: `They consider the needs of different states and regions to make sure everyone benefits from the decisions.`,
      },
      {
        infoTitle: 'Plan for Fair Development',
        infoDetail: `The meeting is about making plans that help cities and villages grow in a way that's fair for everyone.`,
      },
      {
        infoTitle: 'Care About People and Nature',
        infoDetail: `The main goal is to make sure people are happy and that the environment stays healthy, following the principles of the LIFE Mission.`,
      },
      {
        infoTitle: 'Team Up to Solve Big National Problems',
        infoDetail: `Parties work together to handle national issues, keeping in mind the long-term well-being of all citizens.`,
      },
    ],
  },
  {
    title: "UNGA (United Nations General Assembly)",
    info: [
      {
        infoTitle: 'Discussion on Global Issues',
        infoDetail: `Delegates from all UN member states talk about broad international issues like peace, development, and cooperation.`,
      },
      {
        infoTitle: 'Sharing National Perspectives',
        infoDetail: `Each delegate represents their country and shares their perspective on the topics. They discuss ways to improve global conditions.`,
      },
      {
        infoTitle: 'Resolution Drafting',
        infoDetail: `Delegates work together to create resolutions that suggest actions to address the discussed issues.`,
      },
      {
        infoTitle: 'Voting',
        infoDetail: `Resolutions are voted on, and if a majority agrees, they become official recommendations of the General Assembly.`,
      },
    ],
  },
  {
    title: "UNHRC (United Nations Human Rights Council)",
    info: [
      {
        infoTitle: 'Human Rights Discussions',
        infoDetail: `Delegates focus on human rights issues worldwide, discussing violations and proposing solutions.`,
      },
      {
        infoTitle: 'Country Statements',
        infoDetail: `Delegates present their country's stance on human rights matters, highlighting their efforts to protect and promote human rights.`,
      },
      {
        infoTitle: 'Resolution Crafting',
        infoDetail: `Delegates collaborate to draft resolutions suggesting measures to address human rights concerns globally.`,
      },
      {
        infoTitle: 'Voting',
        infoDetail: `Resolutions are subject to a vote, and those with majority support become official recommendations of the UNHRC.`,
      },
    ],
  },
  {
    title: "UNDP (United Nations Development Programme)",
    info: [
      {
        infoTitle: 'Development Discussions',
        infoDetail: `Delegates discuss strategies for global development, poverty reduction, and sustainable growth.`,
      },
      {
        infoTitle: 'Country Plans',
        infoDetail: `Delegates share their countries' development plans and discuss ways to support each other in achieving development goals.`,
      },
      {
        infoTitle: 'Project Proposals',
        infoDetail: `Delegates collaborate on proposing and funding development projects that benefit multiple nations.`,
      },
      {
        infoTitle: 'Consensus Building',
        infoDetail: `Delegates work towards consensus on development strategies, emphasizing cooperation and mutual assistance.`,
      },
    ],
  },
  {
    title: "UNODC (United Nations Office on Drugs and Crime)",
    info: [
      {
        infoTitle: 'Anti-Drug and Crime Measures',
        infoDetail: `Delegates discuss strategies to combat drug trafficking, organized crime, and corruption.`,
      },
      {
        infoTitle: 'Information Sharing',
        infoDetail: `Delegates share information on their countries' efforts to combat drugs and crime, fostering international cooperation.`,
      },
      {
        infoTitle: 'Resolution Development',
        infoDetail: `Delegates draft resolutions proposing coordinated actions against drug-related and criminal activities.`,
      },
      {
        infoTitle: 'Voting',
        infoDetail: `Resolutions are put to a vote, and those garnering majority support become official recommendations.`,
      },
    ],
  },
  {
    title: "UNW (United Nations Women)",
    info: [
      {
        infoTitle: 'Gender Equality Talks',
        infoDetail: `Delegates focus on issues related to gender equality, women's rights, and empowerment.`,
      },
      {
        infoTitle: 'Sharing National Efforts',
        infoDetail: `Delegates discuss the steps their countries are taking to promote gender equality and protect women's rights.`,
      },
      {
        infoTitle: 'Policy Proposals',
        infoDetail: `Delegates work together to propose policies and initiatives that advance gender equality globally.`,
      },
      {
        infoTitle: 'Voting',
        infoDetail: `Resolutions addressing gender issues are voted on, and those with majority support become official recommendations of UNW.`,
      },
    ],
  },
  {
    title: "WHO (World Health Organization)",
    info: [
      {
        infoTitle: 'Global Health Discussions',
        infoDetail: `Delegates discuss international health challenges, pandemics, and strategies for better healthcare.`,
      },
      {
        infoTitle: 'Sharing Health Policies',
        infoDetail: `Countries share their health policies and initiatives, emphasizing cooperation in improving global health.`,
      },
      {
        infoTitle: 'Emergency Response Plans',
        infoDetail: `Delegates collaborate on drafting emergency response plans for health crises.`,
      },
      {
        infoTitle: 'Consensus Building',
        infoDetail: `Delegates work towards consensus on global health strategies, ensuring the well-being of people worldwide.`,
      },
    ],
  },
  {
    title: "ICJ (International Court of Justice)",
    info: [
      {
        infoTitle: 'Legal Dispute Resolution',
        infoDetail: `Delegates work on resolving legal disputes between countries following international law.`,
      },
      {
        infoTitle: 'Case Presentations',
        infoDetail: `Countries present their cases, and delegates act as judges, deliberating on legal arguments.`,
      },
      {
        infoTitle: 'Judgment Crafting',
        infoDetail: `Delegates collaborate on crafting judgments based on legal principles and international laws.`,
      },
      {
        infoTitle: 'Verdict',
        infoDetail: `The ICJ delivers a verdict on the legal dispute, providing a resolution based on international law.`,
      },
    ],
  },
  {
    title: "UNSC (United Nations Security Council)",
    info: [
      {
        infoTitle: 'Global Security Talks',
        infoDetail: `Delegates discuss and address current and potential threats to international peace and security.`,
      },
      {
        infoTitle: 'Information Exchange',
        infoDetail: `Delegates share information on security issues, potential conflicts, and diplomatic efforts.`,
      },
      {
        infoTitle: 'Resolution Drafting',
        infoDetail: `Delegates work on drafting resolutions proposing actions to address security concerns.`,
      },
      {
        infoTitle: 'Voting',
        infoDetail: `Resolutions undergo a vote, and those with majority support become official Security Council decisions.`,
      },
    ],
  },
  {
    title: "UNIDO (United Nations Industrial Development Organization)",
    info: [
      {
        infoTitle: 'Industrial Development Strategies',
        infoDetail: `Delegates focus on promoting sustainable industrial development worldwide.`,
      },
      {
        infoTitle: 'Country Plans for Industry',
        infoDetail: `Countries share their plans for industrial growth and discuss collaborative initiatives.`,
      },
      {
        infoTitle: 'Project Proposals',
        infoDetail: `Delegates collaborate on proposing and supporting industrial development projects.`,
      },
      {
        infoTitle: 'Consensus Building',
        infoDetail: `Delegates work towards consensus on strategies for balanced and sustainable industrial growth.`,
      },
    ],
  },
  {
    title: "UNICEF (United Nations Children's Fund)",
    info: [
      {
        infoTitle: 'Child Welfare Discussions',
        infoDetail: `Delegates discuss issues related to child rights, education, health, and protection.`,
      },
      {
        infoTitle: 'Sharing National Efforts',
        infoDetail: `Countries share their efforts to ensure the well-being and rights of children.`,
      },
      {
        infoTitle: 'Policy Proposals',
        infoDetail: `Delegates work together to propose policies and initiatives that benefit children globally.`,
      },
      {
        infoTitle: 'Voting',
        infoDetail: `Resolutions addressing child welfare are voted on, and those with majority support become official recommendations of UNICEF.`,
      },
    ],
  },
  {
    title: "UNEP (United Nations Environment Programme)",
    info: [
      {
        infoTitle: 'Environmental Talks',
        infoDetail: `Delegates discuss global environmental issues, climate change, and conservation.`,
      },
      {
        infoTitle: 'Sharing Environmental Policies',
        infoDetail: `Countries share their policies and efforts to protect the environment.`,
      },
      {
        infoTitle: 'Project Proposals',
        infoDetail: `Delegates collaborate on proposing and supporting projects that promote environmental sustainability.`,
      },
      {
        infoTitle: 'Consensus Building',
        infoDetail: `Delegates work towards consensus on strategies for global environmental conservation.`,
      },
    ],
  },
  {
    title: "ECOSOC (United Nations Economic and Social Council)",
    info: [
      {
        infoTitle: 'Economic and Social Development Talks',
        infoDetail: `Delegates discuss global economic issues, social development, and cooperation.`,
      },
      {
        infoTitle: 'Sharing National Development Plans',
        infoDetail: `Countries share their plans for economic and social development and discuss collaborative initiatives.`,
      },
      {
        infoTitle: 'Project Proposals',
        infoDetail: `Delegates collaborate on proposing and supporting projects that promote economic and social progress.`,
      },
      {
        infoTitle: 'Consensus Building',
        infoDetail: `Delegates work towards consensus on strategies for balanced and sustainable economic and social development.`,
      },
    ],
  },
];

const detailsArrayJson=[
  {
    "title": "COP'29 (UNFCCC)",
    "info": [
      {
        "infoTitle": "Negotiations",
        "infoDetail": "Countries talk about how to cooperate on climate issues, focusing on the preservation and improvement of life on Earth."
      },
      {
        "infoTitle": "Review of Progress",
        "infoDetail": "Countries share updates on what they've done to tackle climate change and contribute to the overall mission of sustaining and enhancing life."
      },
      {
        "infoTitle": "Discussion of Key Issues",
        "infoDetail": "People discuss important topics such as new problems, scientific findings, and ways to fix things, all within the broader goal of supporting and enriching life on our planet."
      },
      {
        "infoTitle": "Adoption of Resolutions",
        "infoDetail": "Countries agree on official plans and actions that align with the LIFE Mission, working together to create a better environment for all living beings."
      },
      {
        "infoTitle": "Side Events",
        "infoDetail": "Extra activities and talks by different groups emphasize ideas and solutions that directly contribute to the mission of enhancing and safeguarding life."
      },
      {
        "infoTitle": "Participation of Observers",
        "infoDetail": "Various groups, including organizations and experts, join in to share their thoughts and suggestions, contributing to the collective effort to fulfill LIFE Mission and address climate challenges."
      }
    ]
  },
  {
    "title": "All India Political Parties Meet",
    "info": [
      {
        "infoTitle": "Talk about Big National Issues",
        "infoDetail": "Political parties from all over India meet to discuss and solve important problems that affect everyone."
      },
      {
        "infoTitle": "Decide on Rules for a Better Life",
        "infoDetail": "They make decisions on rules and plans that aim to make life better for all citizens, considering things like the environment and long-term well-being."
      },
      {
        "infoTitle": "Work Together for a Sustainable Future",
        "infoDetail": "Even if they have different ideas, parties cooperate to tackle challenges and aim for a future that's good for people and the planet."
      },
      {
        "infoTitle": "Think About Different Parts of India",
        "infoDetail": "They consider the needs of different states and regions to make sure everyone benefits from the decisions."
      },
      {
        "infoTitle": "Plan for Fair Development",
        "infoDetail": "The meeting is about making plans that help cities and villages grow in a way that's fair for everyone."
      },
      {
        "infoTitle": "Care About People and Nature",
        "infoDetail": "The main goal is to make sure people are happy and that the environment stays healthy, following the principles of the LIFE Mission."
      },
      {
        "infoTitle": "Team Up to Solve Big National Problems",
        "infoDetail": "Parties work together to handle national issues, keeping in mind the long-term well-being of all citizens."
      }
    ]
  },
  {
    "title": "UNGA (United Nations General Assembly)",
    "info": [
      {
        "infoTitle": "Discussion on Global Issues",
        "infoDetail": "Delegates from all UN member states talk about broad international issues like peace, development, and cooperation."
      },
      {
        "infoTitle": "Sharing National Perspectives",
        "infoDetail": "Each delegate represents their country and shares their perspective on the topics. They discuss ways to improve global conditions."
      },
      {
        "infoTitle": "Resolution Drafting",
        "infoDetail": "Delegates work together to create resolutions that suggest actions to address the discussed issues."
      },
      {
        "infoTitle": "Voting",
        "infoDetail": "Resolutions are voted on, and if a majority agrees, they become official recommendations of the General Assembly."
      }
    ]
  },
  {
    "title": "UNHRC (United Nations Human Rights Council)",
    "info": [
      {
        "infoTitle": "Human Rights Discussions",
        "infoDetail": "Delegates focus on human rights issues worldwide, discussing violations and proposing solutions."
      },
      {
        "infoTitle": "Country Statements",
        "infoDetail": "Delegates present their country's stance on human rights matters, highlighting their efforts to protect and promote human rights."
      },
      {
        "infoTitle": "Resolution Crafting",
        "infoDetail": "Delegates collaborate to draft resolutions suggesting measures to address human rights concerns globally."
      },
      {
        "infoTitle": "Voting",
        "infoDetail": "Resolutions are subject to a vote, and those with majority support become official recommendations of the UNHRC."
      }
    ]
  },
  {
    "title": "UNDP (United Nations Development Programme)",
    "info": [
      {
        "infoTitle": "Development Discussions",
        "infoDetail": "Delegates discuss strategies for global development, poverty reduction, and sustainable growth."
      },
      {
        "infoTitle": "Country Plans",
        "infoDetail": "Delegates share their countries' development plans and discuss ways to support each other in achieving development goals."
      },
      {
        "infoTitle": "Project Proposals",
        "infoDetail": "Delegates collaborate on proposing and funding development projects that benefit multiple nations."
      },
      {
        "infoTitle": "Consensus Building",
        "infoDetail": "Delegates work towards consensus on development strategies, emphasizing cooperation and mutual assistance."
      }
    ]
  },
  {
    "title": "UNODC (United Nations Office on Drugs and Crime)",
    "info": [
      {
        "infoTitle": "Anti-Drug and Crime Measures",
        "infoDetail": "Delegates discuss strategies to combat drug trafficking, organized crime, and corruption."
      },
      {
        "infoTitle": "Information Sharing",
        "infoDetail": "Delegates share information on their countries' efforts to combat drugs and crime, fostering international cooperation."
      },
      {
        "infoTitle": "Resolution Development",
        "infoDetail": "Delegates draft resolutions proposing coordinated actions against drug-related and criminal activities."
      },
      {
        "infoTitle": "Voting",
        "infoDetail": "Resolutions are put to a vote, and those garnering majority support become official recommendations."
      }
    ]
  },
  {
    "title": "UNW (United Nations Women)",
    "info": [
      {
        "infoTitle": "Gender Equality Talks",
        "infoDetail": "Delegates focus on issues related to gender equality, women's rights, and empowerment."
      },
      {
        "infoTitle": "Sharing National Efforts",
        "infoDetail": "Delegates discuss the steps their countries are taking to promote gender equality and protect women's rights."
      },
      {
        "infoTitle": "Policy Proposals",
        "infoDetail": "Delegates work together to propose policies and initiatives that advance gender equality globally."
      },
      {
        "infoTitle": "Voting",
        "infoDetail": "Resolutions addressing gender issues are voted on, and those with majority support become official recommendations of UNW."
      }
    ]
  },
  {
    "title": "WHO (World Health Organization)",
    "info": [
      {
        "infoTitle": "Global Health Discussions",
        "infoDetail": "Delegates discuss international health challenges, pandemics, and strategies for better healthcare."
      },
      {
        "infoTitle": "Sharing Health Policies",
        "infoDetail": "Countries share their health policies and initiatives, emphasizing cooperation in improving global health."
      },
      {
        "infoTitle": "Emergency Response Plans",
        "infoDetail": "Delegates collaborate on drafting emergency response plans for health crises."
      },
      {
        "infoTitle": "Consensus Building",
        "infoDetail": "Delegates work towards consensus on global health strategies, ensuring the well-being of people worldwide."
      }
    ]
  },
  {
    "title": "ICJ (International Court of Justice)",
    "info": [
      {
        "infoTitle": "Legal Dispute Resolution",
        "infoDetail": "Delegates work on resolving legal disputes between countries following international law."
      },
      {
        "infoTitle": "Case Presentations",
        "infoDetail": "Countries present their cases, and delegates act as judges, deliberating on legal arguments."
      },
      {
        "infoTitle": "Judgment Crafting",
        "infoDetail": "Delegates collaborate on crafting judgments based on legal principles and international laws."
      },
      {
        "infoTitle": "Verdict",
        "infoDetail": "The ICJ delivers a verdict on the legal dispute, providing a resolution based on international law."
      }
    ]
  },
  {
    "title": "UNSC (United Nations Security Council)",
    "info": [
      {
        "infoTitle": "Global Security Talks",
        "infoDetail": "Delegates discuss and address current and potential threats to international peace and security."
      },
      {
        "infoTitle": "Information Exchange",
        "infoDetail": "Delegates share information on security issues, potential conflicts, and diplomatic efforts."
      },
      {
        "infoTitle": "Resolution Drafting",
        "infoDetail": "Delegates work on drafting resolutions proposing actions to address security concerns."
      },
      {
        "infoTitle": "Voting",
        "infoDetail": "Resolutions undergo a vote, and those with majority support become official Security Council decisions."
      }
    ]
  },
  {
    "title": "UNIDO (United Nations Industrial Development Organization)",
    "info": [
      {
        "infoTitle": "Industrial Development Strategies",
        "infoDetail": "Delegates focus on promoting sustainable industrial development worldwide."
      },
      {
        "infoTitle": "Country Plans for Industry",
        "infoDetail": "Countries share their plans for industrial growth and discuss collaborative initiatives."
      },
      {
        "infoTitle": "Project Proposals",
        "infoDetail": "Delegates collaborate on proposing and supporting industrial development projects."
      },
      {
        "infoTitle": "Consensus Building",
        "infoDetail": "Delegates work towards consensus on strategies for balanced and sustainable industrial growth."
      }
    ]
  },
  {
    "title": "UNICEF (United Nations Children's Fund)",
    "info": [
      {
        "infoTitle": "Child Welfare Discussions",
        "infoDetail": "Delegates discuss issues related to child rights, education, health, and protection."
      },
      {
        "infoTitle": "Sharing National Efforts",
        "infoDetail": "Countries share their efforts to ensure the well-being and rights of children."
      },
      {
        "infoTitle": "Policy Proposals",
        "infoDetail": "Delegates work together to propose policies and initiatives that benefit children globally."
      },
      {
        "infoTitle": "Voting",
        "infoDetail": "Resolutions addressing child welfare are voted on, and those with majority support become official recommendations of UNICEF."
      }
    ]
  },
  {
    "title": "UNEP (United Nations Environment Programme)",
    "info": [
      {
        "infoTitle": "Environmental Talks",
        "infoDetail": "Delegates discuss global environmental issues, climate change, and conservation."
      },
      {
        "infoTitle": "Sharing Environmental Policies",
        "infoDetail": "Countries share their policies and efforts to protect the environment."
      },
      {
        "infoTitle": "Project Proposals",
        "infoDetail": "Delegates collaborate on proposing and supporting projects that promote environmental sustainability."
      },
      {
        "infoTitle": "Consensus Building",
        "infoDetail": "Delegates work towards consensus on strategies for global environmental conservation."
      }
    ]
  },
  {
    "title": "ECOSOC (United Nations Economic and Social Council)",
    "info": [
      {
        "infoTitle": "Economic and Social Development Talks",
        "infoDetail": "Delegates discuss global economic issues, social development, and cooperation."
      },
      {
        "infoTitle": "Sharing National Development Plans",
        "infoDetail": "Countries share their plans for economic and social development and discuss collaborative initiatives."
      },
      {
        "infoTitle": "Project Proposals",
        "infoDetail": "Delegates collaborate on proposing and supporting projects that promote economic and social progress."
      },
      {
        "infoTitle": "Consensus Building",
        "infoDetail": "Delegates work towards consensus on strategies for balanced and sustainable economic and social development."
      }
    ]
  }
]




// 2
const LastDateRegis = () => {

    const {  setCurrentStep, formik} = useContext(ModelUnContext)

    const [lastDate, setLastDate] = useState(formik?.values?.lastDateRegis || "")
    const setDate = (date) =>{
      console.log("--**date", date);
      setLastDate(date)
      formik.setFieldValue("lastDateEvent", "")
    }

    useEffect(()=>{
      formik.setFieldValue("lastDateRegis", lastDate)
    },[lastDate])

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(true);

  useEffect(() => {
    if (isDatePickerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDatePickerOpen]);

  const handleClickOutside = (event) => {
    if (event?.target &&
      !event.target.classList.contains('react-datepicker')
    ) {
      setIsDatePickerOpen(false);
    }
  };

  return (
    <>
      <div>
      <div>
      <span className="fw-500 fs-20px" style={{color: "#979797"}}>Events {">"} United Nations {">"} Registrations</span>
      </div>
        {/* <div className="mt-3">
          <span className="fs-32px fw-600">Last date of registration of students  </span>
        </div> */}
        <div className="d-flex align-items-center mt-3">
          <div>
          <span className="fs-32px fw-600">Last date of registration of students</span> 
          <Tooltip title="Student registration must be completed at least seven days before the event, providing the Faculty Coordinator with sufficient time to finalize the selection process." placement="bottom">
          <span>
            <HelpOutlineIcon sx={{color: "#5686E1", fontSize: "20px"}}/>
          </span>
          </Tooltip>
          </div>
          <div>
        
      </div>
        </div>
        <div className="mt-3 ">
          <span className="fs-20px fw-500" style={{color: "#979797"}}>
          Last date of registration for students should be at least 7 days prior to the event date.
          </span>
        </div>
        
     
        <div className="mt-3 col-12 col-sm-12 col-md-8 col-lg-7">
          <form onSubmit={formik.handleSubmit}>
          
      <div className="mt-4 mb-4">
      <DatePickerOwn setDate={setDate} formikValue="lastDateRegis"/>
      </div>

      <div className="ms-2">
      {formik.errors.lastDateRegis && formik.touched.lastDateRegis ?
      <span className="text-danger">{formik.errors.lastDateRegis}</span> : null}
      </div>


        <div className="d-flex justify-content-between  mb-5 mb-md-0 mt-md-4">
        <button
          onClick={()=>setCurrentStep(3)}
          className="btn-onboard fs-19px fw-500 mr-3 mb-3 mb-md-0"
          style={{color: "#808080"}}
        >
          Previous
        </button>
        <button
        type="submit"
        
          onClick={
            formik.values.lastDateRegis ? 
            ()=>setCurrentStep(5) : null
          }
          className="btn-onboard-fill mb-3 mb-md-0"
        >
          <span>Save & Continue</span>
          <img className="ms-2" src="/images/onBoarding/right-icon.png"  alt="sendicon" />
        </button>
      </div>
      </form>

      </div>
      </div>
    </>
  );
};

// 3
const LastDateEvent = () => {
    const { setCurrentStep, formik, isLoading, setIsLoading} = useContext(ModelUnContext)
    const [lastDate, setLastDate] = useState( formik?.values?.lastDateEvent || "")

    const setDate = (date) =>{
      // console.log("--**date", date);
      setLastDate(date)
    }

    useEffect(()=>{
      formik.setFieldValue("lastDateEvent", lastDate)
    },[lastDate])
  return (
    <>
      <div>
      <div>
      <span className="fw-500 fs-20px" style={{color: "#979797"}}>Events {">"} United Nations {">"} Registration</span>
      </div>
        <div className="mt-3">
          <span className="fs-32px fw-600">Date of the Proposed Event  </span>
        </div>
        <div className="mt-3">
          <span className="fs-20px fw-500" style={{color: "#979797"}}>
          You can change this date at any time
          </span>
        </div>

        <div className="col-12 col-sm-12 col-md-8 col-lg-7">
          <form onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-column  flex-md-row gap-md-3 pr-3 mt-3">
            <div className="mb-3 col-12 col-md-8 col-lg-8">
              <label htmlFor="exampleFormControlInput1" className="form-label fw-semibold">Venue <Tooltip title="Write the name of the venue. e.g Shrishti Auditorium." placement="bottom">
          <span>
            <HelpOutlineIcon sx={{color: "#5686E1", fontSize: "20px"}}/>
          </span>
          </Tooltip></label>
              <input type="text" className="form-control shadow-none rounded-3" name="event_venue" onChange={(e)=>{formik.setFieldValue("event_venue", e.target.value)}} value={formik?.values?.event_venue} id="exampleFormControlInput1" placeholder="E.g Shrishti Auditorium" />
              <div className="ms-2">
      {formik.errors.event_venue && formik.touched.event_venue ?
      <span className="text-danger">{formik.errors.event_venue}</span> : null}
      </div>
            </div>
            <div className="col-12 col-md-8 col-lg-8">

              <label htmlFor="event_time" className="form-label fw-semibold">Event Time</label>
              <input type="time" className="form-control shadow-none rounded-3" onChange={(e)=>{formik.setFieldValue("event_time", e.target.value)}} value={formik?.values?.event_time} name ="event_time"id="event_time" />
              <div className="ms-2">
              {formik.errors.event_time && formik.touched.event_time ?
      <span className="text-danger">{formik.errors.event_time}</span> : null}
      </div>
            </div>
          </div>
        <div className="mt-4 mb-4 ">
      <DatePickerPropDate setDate={setDate} formikValue="lastDateEvent"/>
      </div>

      <div className="ms-2">
      {formik.errors.lastDateEvent && formik.touched.lastDateEvent ?
      <span className="text-danger">{formik.errors.lastDateEvent}</span> : null}
      </div>
      

        <div className="d-flex justify-content-between  mt-4">
        <button
          onClick={()=>setCurrentStep(4)}
          className="btn-onboard fs-19px fw-500"
          style={{color: "#808080"}}
        >
          Previous
        </button>
        <button 
        // type="submit"
        onClick={
          (e)=>{
            e.preventDefault()
            if(formik.isValid){
              formik.handleSubmit()
              
            }
          }
        }
          disabled={isLoading}
          className="btn-onboard-fill"
        >
                {isLoading ?
                  <>
                    <div className="spinner-border text-light mx-auto d-flex" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </>
                  :
                  <>
                    <span>Save & Continue</span>
                    <img className="ms-2" src="/images/onBoarding/right-icon.png" alt="sendicon" />
                  </>
                }

        </button>
      </div>
      </form>
        </div>

        
      </div>
    </>
  );
};

const eventType = [
  {
    type: "Intra Institutional",
    body: "Students from the same educational institute can participate",
    show: "Register Student",
  },
  {
    type: "Inter Institutional",
    body: "Students from other educational institutions will also participate",
    show: "Register Teacher",
    diabledcss: "btn-onboard-fill-disabled-Inter",
  },
];

const chooseFormat = [
  {
    type: "IMission Life",
    body: "Description about model 2",
    show: "In partnership with United Nations Development Programme",
  },
  {
    type: "Bharat@2047",
    body: "Description about model 2",
  },
  {
    type: "IGNITING INDIA'S JOURNEY TO 2047",
    body: "PROMOTION OF SYSTEM OF ALTERNATIVE HEALTHCARE FOR A HEALTHY FUTURE.",
  },
];


const customStyles = {
  control: (provided) => ({
    ...provided,
    padding: "3px",
    background: "#F9F9F9",
    color:'black',
    fontSize:14,
    height:57,
    borderRadius:12,
    border:"1px solid #e9e9e9"
    // boxShadow:'none'
  }),
};
const customStylesPurple = {
  control: (provided) => ({
    ...provided,
    width: "170px",
    padding: "",
    border: "none",
    background: "#F1EBFF",
    textAlign: "center",
    height: "37px",
    fontSize:"10px"
    // boxShadow:'none'
  }),
};


const SecretariatMember = [
  {
    value: "Chairperson",
    assign_designation: "Chairperson",
  },
  {
    value: "Vice-Chairperson",
    assign_designation: "Vice-Chairperson",
  },
  {
    value: "Director",
    assign_designation: "Director",
  },
  {
    value: "Secretary-General",
    assign_designation: "Secretary-General",
  },
  {
    value: "Deputy-Secretary-General",
    assign_designation: "Deputy-Secretary-General",
  },
  {
    value: "Director-General",
    assign_designation: "Director-General",
  },
  {
    value: "Under-Secretary-General ",
    assign_designation: "Under-Secretary-General ",
  },
  {
    value: "Member OC",
    assign_designation: "Member OC",
  },
  {
    value: "Jury Member",
    assign_designation: "Jury Member",
  }
];

const studentMedia = [
  {
    value: 1,
    des: "Rapporteur",
  },
  {
    value: 2,
    des: "Caricaturist",
  },
  {
    value: 3,
    des: "Photographer",
  },
  
];
const assignDesination = [
  {
    value : 1,
    name : "Executive Board",
  },
  {
    value : 2,
    name : "Organisation Committee",
  },
  {
    value : 3,
    name : "Judging and Jury",
  },
]

const committeeTrack = [
  { 
    id : 1,
    name : "G20",
    track : "Leader Track"
  },
  { 
    id : 2,
    name : "G20",
    track : "Sherpa Track"
  },
  { 
    id : 3,
    name : "G20",
    track : "Foreign minister"
  },
  { 
    id : 4,
    name : "G20",
    track : "Finance Track"
  }
]
const showDesList = [
  {
    value: "student",
    name: "Student",
  },
  {
    value: "teacher",
    name: "Teacher",
  },
  {
    value: "admin",
    name: "Admin",
  },
  {
    value: "external",
    name: "External",
  },
]

const rolesArray = [
  { value: 'student', name: 'Student' },
  { value: 'principal', name: 'Principal' },
  { value: 'vicePrincipal', name: 'Vice Principal' },
  { value: 'headmaster', name: 'Headmaster/Headmistress' },
  { value: 'schoolAdministrator', name: 'School Administrator' },
  { value: 'schoolCoordinator', name: 'School Coordinator' },
  { value: 'teacher', name: 'Teacher' },
  { value: 'subjectTeacher', name: 'Subject Teacher' },
  { value: 'specialEducator', name: 'Special Educator' },
  { value: 'schoolCounselor', name: 'School Counselor' },
  { value: 'librarian', name: 'Librarian' },
  { value: 'physicalEducationTeacher', name: 'Physical Education Teacher (PET)' },
  { value: 'labAssistant', name: 'Lab Assistant' },
  { value: 'administrativeStaff', name: 'Administrative Staff' },
  { value: 'itCoordinator', name: 'IT Coordinator/IT Teacher' },
  { value: 'artTeacher', name: 'Art Teacher' },
  { value: 'musicTeacher', name: 'Music Teacher' },
  { value: 'danceTeacher', name: 'Dance Teacher' },
  { value: 'sportsCoach', name: 'Sports Coach' },
  { value: 'director', name: 'Director' },
  { value: 'academicHead', name: 'Academic Head' },
  { value: 'subjectExperts', name: 'Subject Experts/Faculty' },
  { value: 'teachingMentor', name: 'Teaching/Subject Mentor' },
  { value: 'contentDeveloper', name: 'Content Developer' },
  { value: 'academicCoordinator', name: 'Academic Coordinator' },
  { value: 'centerManager', name: 'Center Manager' },
  { value: 'marketingManager', name: 'Marketing Manager' },
  { value: 'admissionCounselor', name: 'Admission Counselor' },
  { value: 'frontOfficeExecutive', name: 'Front Office Executive' },
  { value: 'onlinePlatformManager', name: 'Online Platform Manager' },
  { value: 'digitalMarketingExecutive', name: 'Digital Marketing Executive' },
  { value: 'courseCoordinator', name: 'Course Coordinator' },
  { value: 'administrativeStaff', name: 'Administrative Staff' },
  { value: 'testCoordinator', name: 'Test Coordinator' },
  { value: 'researchAnalyst', name: 'Research Analyst' },
  { value: 'itSupportSpecialist', name: 'IT Support Specialist' },
  { value: 'onlineContentModerator', name: 'Online Content Moderator' },
  { value: 'onlineLearningFacilitator', name: 'Online Learning Facilitator' }
];


// if adding component then add afterward (latest at-last position)
const modelUnRegistrationSteps  = [
    <Registration/>,//0
    <ChooseFormat/>,//1
    <ChooseEventTheme/>,//2
    <PositionAllotment/>, //3
    <LastDateRegis/>,//4
    <LastDateEvent/>,//5
    <Congratulations/>,     //6
    <AppointCoordinators/>,//7
    <AppointStudentCoordinators/>,//8
    <AppointStudentMediaTeam/>,//9
]