import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Grid, Button, Modal, Typography, Box, Checkbox } from '@mui/material';
import MaterialTable from '@material-table/core';
import { apiJson, apiJsonAuth } from 'api';
import useError from 'lib/errorResponse';
import { useGlobalContext } from 'global/context';
import NotFoundGif from 'layout/NotFoundGif';
import { toast } from 'react-toastify';
import { Popup } from 'layout/Popup';
import moment from 'moment';

const ReportManagerAdminTeacherNew = () => {
    const { ErrorResponder } = useError();
    const { role } = useParams();
    const { userData } = useGlobalContext();
    const location = useLocation();

    const [correctData, setCorrectData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { token } = useGlobalContext();

    const fetchData = async () => {
        try {
            const response = await apiJson.get(`api/v2/institute/getMultipleTeacherStudent/${userData?.id}/${role}`);
            const getAllTeachStud = response?.data?.getAllTeachStud || [[], []];
            const [correctData, incorrectData] = getAllTeachStud;

            console.log("Response received:", incorrectData);

            const fieldsToExtract = [
                "id",
                "first_name",
                "last_name",
                "father_name",
                "contact",
                "email",
                "instituteId",
                "dob",
                "role",
                "class",
                "stream",
                "gender",
                "instituteId",
                "isPresent"
            ];

            const formatData = (data) =>
                data
                    .filter(user => user.role === role && user.instituteId === userData?.id && user.isPresent === false)
                    .map(user => {
                        const filteredUser = {};
                        fieldsToExtract.forEach(field => {
                            if (user[field] !== undefined) {
                                filteredUser[field] = field === 'dob'
                                    ? moment(user[field]).format('DD-MM-YYYY')
                                    : user[field];
                            }
                        });
                        return filteredUser;
                    });

            const filteredCorrectData = formatData(correctData);

            setCorrectData(filteredCorrectData);
            setFilteredData(filteredCorrectData);

        } catch (error) {
            console.error(error);
            toast.error('Error occurred while fetching data.');
            setCorrectData([]);
            setFilteredData([]);
        }
    };

    useEffect(() => {
        fetchData();
    }, [role]);

    useEffect(() => {
        if (isSubmitted) {
            fetchData();
            setIsSubmitted(false);
        }
    }, [isSubmitted, location]);

    const handleReset = () => {
        setFilteredData(prevData =>
            prevData.map(item => ({ ...item, isPresent: 0 }))
        );
    };

    const handleSubmit = async () => {
        try {
            const presentStudents = filteredData
                .filter(user => user.isPresent === 1)
                .map(user => user.id);

            console.log("Students to update:", presentStudents);

            const response = await apiJsonAuth.put(
                `api/v2/institute/markStudentAttendance/${userData?.id}`, { presentStudents },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                const { message } = response.data;

                setIsSubmitted(true);
                setModalOpen(false);
                Popup(message || "Attendance Uploaded Successfully!", 10000);
                toast.success(message || "Attendance Uploaded Successfully!");

            } else if (response.status === 404) {
                setModalOpen(false);
                toast.warning(response.data.message || 'No updates were made.');
                Popup('warning', 'No Updates Made', `Error: ${response.data.message || 'No students found for the provided IDs and instituteId'}`, 10000);
            } else {
                setModalOpen(false);
                toast.error(response.data.message || 'Failed to Upload Attendance');
                Popup('error', 'Failed to Upload Attendance', `Error: ${response.data.message || 'Unknown error'}`, 10000);
            }
        } catch (error) {
            setModalOpen(false);
            console.error('Error submitting data:', error);
            toast.error('Error Submitting Data');
            Popup('error', 'Error Submitting Data', `Error: ${error.message || 'Unknown error'}`, 10000);
        }
    };

    const renderTable = () => (
        <MaterialTable
            columns={[
                { title: 'ID', field: 'id', cellStyle: { padding: '8px' } },
                { title: 'First Name', field: 'first_name', cellStyle: { padding: '8px' } },
                { title: 'Last Name', field: 'last_name', cellStyle: { padding: '8px' } },
                { title: 'Father\'s Name', field: 'father_name', cellStyle: { padding: '8px' } },
                { title: 'Email', field: 'email', cellStyle: { padding: '8px' } },
                { title: 'Contact', field: 'contact', cellStyle: { padding: '8px' } },
                { title: 'DOB', field: 'dob', cellStyle: { padding: '8px' } },
                { title: 'Gender', field: 'gender', cellStyle: { padding: '8px' } },
                { title: 'Class', field: 'class', cellStyle: { padding: '8px' } },
                { title: 'Stream', field: 'stream', cellStyle: { padding: '8px' } },
                {
                    title: 'Attendance',
                    field: 'isPresent',
                    render: rowData => (
                        <Checkbox
                            checked={rowData.isPresent === 1}
                            onChange={e => {
                                setFilteredData(prevData =>
                                    prevData.map((item) =>
                                        item.id === rowData.id
                                            ? { ...item, isPresent: e.target.checked ? 1 : 0 }
                                            : item
                                    )
                                );
                            }}
                            color="primary"
                        />
                    ),
                },
            ]}
            data={filteredData}
            options={{
                toolbar: true,
                showTitle: false,
                sorting: true,
                search: true,
                searchFieldStyle: {
                    alignSelf: "center",
                    width: '100%'
                },
                headerStyle: {
                    backgroundColor: '#2356FE',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    padding: '8px'
                },
                paging: false,
            }}
            style={{
                width: '100%',
                margin: '0 auto',
            }}
        />
    );

    return (
        <Grid container flexWrap='nowrap' direction="column" justifyContent="center" alignItems="center" style={{ maxHeight: '100vh', overflowY: 'auto', padding: '1.5rem' }}>
            <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '20px' }}>
                <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#2356FE' }}>
                    {role === 'teacher' ? 'Teacher Report Manager' : 'Student Report Manager'}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ width: '100%', overflowY: 'auto' }}>
                {filteredData.length === 0 || isSubmitted ? (
                    <NotFoundGif text={`No New Event Attendance found for ${role}!!`} />
                ) : (
                    renderTable()
                )}
            </Grid>
            {filteredData.length > 0 && !isSubmitted && (
                <Grid container item xs={12} justifyContent="center" gap="1rem" style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button variant="contained" color="primary" onClick={() => setModalOpen(true)} style={{ marginRight: '20px' }}>
                        Submit
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleReset}>
                        Reset
                    </Button>
                </Grid>
            )}
            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    style={{ transform: 'translate(-50%, -50%)', padding: '20px', backgroundColor: 'white', borderRadius: '8px', textAlign: 'center' }}
                >
                    <Typography variant="h6">Are you sure you want to submit?</Typography>
                    <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
                        <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginRight: '10px' }}>
                            Yes
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => setModalOpen(false)}>
                            No
                        </Button>
                    </Grid>
                </Box>
            </Modal>
        </Grid>
    );
};

export default ReportManagerAdminTeacherNew;
