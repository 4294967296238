import React from 'react'
import "../style/OnBoardingStyle.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import { Typography } from "@mui/joy";


const Faq = () => {

    const [expanded, setExpanded] = React.useState(false);

    const handleExpansion = () => {
      setExpanded((prevExpanded) => !prevExpanded);
    };
  return (
    <>
                    <div className="w-100 overflow-hidden">
              <Accordion
                expanded={expanded}
                onChange={handleExpansion}
                slots={{ transition: Fade }}
                slotProps={{ transition: { timeout: 400 } }}
                sx={{
                  "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
                  "& .MuiAccordionDetails-root": {
                    display: expanded ? "block" : "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography sx={{ fontSize: "1.1rem", fontWeight: "700" }}>
                  I forgot my password after initiating the registration, what
                should I do?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  Go to{" "}
                <a target="_blank" href="https://www.yuvamanthan.org/">
                  www.yuvamanthan.org
                </a>{" "}
                and click on Login on the top right corner. Enter your email ID
                and click next. Click on Forgot Password and follow the
                instructions. A link to change your password will be sent to
                your email ID.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography sx={{ fontSize: "1.1rem", fontWeight: "700" }}>I am a student and have completed my registration process but I
                cannot access my account.</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  You will need to contact your account admins and moderators to
                allow you to access the account.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography sx={{ fontSize: "1.1rem", fontWeight: "700" }}>When will I get a call to confirm my Institutional registration?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  You should get a call in 4-5 working days, if you do not get a
                call please check your email for Account Activation email.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography sx={{ fontSize: "1.1rem", fontWeight: "700" }}>When can I start adding students?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  You will be able to add students and teachers to the platform
                from your dashboard after your account is duly verified by our
                team. On the Dashboard, you will see unique invite links through
                which you can invite students and teachers to join the platform.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography sx={{ fontSize: "1.1rem", fontWeight: "700" }}>What should I do if I do not receive any phone call?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  To expedite the process of registration, you may call our call
                centre during work hours - 10am - 5pm on 9560771911
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
    </>
  )
}

export default Faq