import React, { useEffect, useState } from "react";
import environment from "../assests/Group 386.svg";
import "../Css/main.css";
import { Link, useNavigate } from "react-router-dom";
import { apiJson } from "api";
import { useGlobalContext } from "global/context";
import { ThreeDots } from "react-loader-spinner";

const Main = () => {
  const [attemptId, setAttemptId] = useState();
  const [status, setStatus] = useState();
  const navigate = useNavigate();
  const { userData } = useGlobalContext();
  const [loader, setLoader] = useState(false);
  const [challAttempt, setChallAttempt] = useState(null);

  const userDetails = {
    userId: userData.id,
    role: userData.role,
    instituteId: userData.instituteId,
  };
  const attempChallenge = async () => {
    try {
      const response = await apiJson.post(
        "/api/v2/21daysChallenge/addAttempts/",
        userDetails
      );
      navigate(`gameday/${response.data.result.id}`);
    } catch (err) {
      console.log(err.message);
    }
  };

  // console.log("attempt id is =" + attemptId)
  const firstAttempChallenge = async () => {
    try {
      setLoader(true);
      const response = await apiJson.get(
        `api/v2/21daysChallenge/checkUserAttempt/${userData.id}/role/${userData.role}`
        );
        setLoader(false);
        setAttemptId(response.data.result.id);
        setStatus(response.data.result.status);
        setChallAttempt(response.data.result);
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    firstAttempChallenge();
  }, []);

  return (
    <>
      <div className="mainContent position-relative">
        <img src={environment} alt="environment" className="w-80 h-288px" />
        <p className="text-center w-75 m-auto">
          Each day’s activity is based on a person’s habit of using elevators,
          and plastic bags or forgetting to switch off electrical appliances
          when not in use. If small steps are taken by each individual to avoid
          wastage, then it could create a bigger impact.
        </p>

        <div className="d-flex justify-content-center box-shadow-0">
          {loader ? (
            <ThreeDots />
          ) : challAttempt ? (
            <button
              onClick={() => {
                navigate(`gameday/${attemptId}`);
              }}
              className="mt-4 text-decoration-none text-center "
            >
              Continue
            </button>
          ) : (
            <button
              onClick={() => {
                attempChallenge();
              }}
              className="mt-4 text-decoration-none text-center "
            >
              Start Game
            </button>
          )}
        </div>

        <hr className="w-75 m-auto mt-5" />

        <div className="lowerContent p-0 box-shadow-0">
          <p className="text-center my-4 p-0">How to Play</p>

          <div className="tasks row text-center m-auto p-0 m-0 d-flex justify-content-center box-shadow-0">
            <div className="col-sm-6 col-lg-3 box-shadow-0">
              <p className="text-start text-white one m-auto">1</p>
              <p className="secondpara text-center mt-3">Take a daily assigned challenge</p>
            </div>
            <div className="col-sm-6 col-lg-3 box-shadow-0">
              <p className="text-start text-white one m-auto">2</p>
              <p className="secondpara text-center mt-3">
                Complete the challenge by doing activity
              </p>
            </div>
            <div className="col-sm-6 col-lg-3 box-shadow-0">
              <p className="text-start text-white one m-auto">3</p>
              <p className="secondpara text-center mt-3">Take a selfie of a challenge</p>
            </div>
            <div className="col-sm-6 col-lg-3 box-shadow-0">
              <p className="text-start text-white one m-auto">4</p>
              <p className="secondpara text-center mt-3">Upload the selfie</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
