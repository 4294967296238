import React, { useEffect } from 'react';
import styles from "./Nipam.module.css";


const slides = [
    {
        id: 1,
        title: 'Vocal For Local',
        desc: 'Shri Narendra Modi, our Prime Minister, spearheaded the "Vocal for Local" campaign, calling for backing local products to propel the nation\'s progress and actualize the vision of Aatmanirbhar Bharat.'
    },
    {
        id: 2,
        title: 'One District- One Product',
        desc: 'One District, One Product Programme aims to encourage innovative, indigenous and specialized products and crafts in India.'
    },
    {
        id: 3,
        title: 'Start-Up India',
        desc: 'A crucial focus for businesses is maintaining vigilance on Intellectual Property Rights. IPR becomes instrumental in distinguishing your business from competitors. The Start-up India program expedites IPR processes for emerging entrepreneurs.'
    },
];

const NipamTxtSlider = () => {
    useEffect(() => {
        const totalSlides = slides.length;
        const animationDuration = 20; // e.g., 5 seconds per slide

        let styles = '';
        for (let i = 0; i < totalSlides; i++) {
            styles += `
                @keyframes fade${i + 1} {
                    ${((i * 100) / totalSlides)}% { opacity: 0; }
                    ${(((i + 1) * 100) / totalSlides - 20)}% { opacity: 1; }
                    ${(((i + 1) * 100) / totalSlides)}% { opacity: 0; }
                }
                .slide${i + 1} {
                    animation: fade${i + 1} ${animationDuration}s infinite;
                }
            `;
        }

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = styles;
        document.head.appendChild(styleSheet);

        return () => {
            document.head.removeChild(styleSheet);
        };
    }, []);

    return (
        <div className={styles.slider}>
        <div className={styles.Niptitle}></div>
            {slides.map((slideContent, index) => (
                <div key={slideContent.id} className={`${styles.slide} slide${index + 1}`}>
                
                <div className={styles.NipTxt}>{slideContent.title}</div>

                    <div className={styles.NipDesc}>{slideContent.desc}</div>
                </div>
            ))}
        </div>
    )
}

export default NipamTxtSlider;
