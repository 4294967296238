import React from "react";
// import { Button, List, ListItem, ListItemAvatar, ListItemText, Tooltip } from "@mui/material";
// import Avatar from "@mui/material/Avatar";
// import IconButton from "@mui/material/IconButton";
// import moment from "moment";
// import { CalendarMonth, Call, Email, Facebook, Instagram, LinkedIn, Twitter, VerifiedOutlined, YouTube } from "@mui/icons-material";
// import { Modal } from "react-bootstrap";

const StudentProfileModal = () => {
  const [show, setShow] = React.useState(false);
  return <></>;
};

export default StudentProfileModal;
