import React, { useState, useEffect } from 'react';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import bicycle from "../../assets/Icons Updated/bike parking-rafiki-2.svg";
import bottle from "../../assets/Icons Updated/Bottle of water-rafiki-2.svg";
import printer from "../../assets/Icons Updated/Printer-cuate-2.svg";
import shoppingBag from "../../assets/Icons Updated/Shopping bag-rafiki-2.svg";
import kitchen from "../../assets/Icons Updated/Kitchen appliances-pana-2.svg";
import waterTap from "../../assets/Icons Updated/World water day-cuate-2.svg";
import publicBus from "../../assets/Icons Updated/city bus-cuate-2.svg";
import documents from "../../assets/Icons Updated/Documents-cuate-2.svg";
import repair from "../../assets/Icons Updated/Product teardown-bro-2.svg";
import seperateWaste from "../../assets/Icons Updated/Waste management-pana-2.svg";
import seperateEwaste from "../../assets/Icons Updated/Waste management-rafiki-2.svg";
import homeGarden from "../../assets/Icons Updated/People taking care of plants-cuate-2.svg";
import sprout from "../../assets/Icons Updated/Sprout-amico-2.svg";
import plantation from "../../assets/Icons Updated/planting tree-amico-2.svg";
import kitchenGarden from "../../assets/Icons Updated/People taking care of plants-rafiki.svg";
import stairs from "../../assets/Icons Updated/Next steps-bro-2.svg";
import socket from "../../assets/Icons Updated/404 Error Page not Found with people connecting a plug-rafiki-2.svg";
import writing from "../../assets/Icons Updated/Hand holding pen-amico-2.svg";
import gardeningWater from "../../assets/Icons Updated/Gardening-rafiki-2.svg";
import compost from "../../assets/Icons Updated/Compost cycle-cuate.svg";
import books from "../../assets/Icons Updated/Thesis-rafiki-2.svg";
import stationary from "../../assets/Icons Updated/Notebook-bro.svg";
import ecoClub from "../../assets/Icons Updated/Taking care of the Earth-amico.svg";
import dryWetWaste from "../../assets/Icons Updated/Waste management-bro.svg";
import weatherTemp from "../../assets/Icons Updated/Weather-amico.svg";
import news from "../../assets/Icons Updated/News-pana.svg";
import shower from "../../assets/Icons Updated/Taking a shower-rafiki.svg";
import lunch from "../../assets/Icons Updated/Lunch time-pana-2.svg";
import canteen from "../../assets/Icons Updated/Eating together-amico.svg";
import toilet from "../../assets/Icons Updated/toilet.png";

import option1Img from "../../assets/hardly.png";
import option2Img from "../../assets/sometimes.png";
import option3Img from "../../assets/frequently.png";

import "./ActionCalculator.css";
import ProgressBar from 'pages/course/ProgressBar';
import CompleteCalculator from './CompleteCalculator';

const ActionCalculator = () => {
    const tempQuestion = [
        {
            id: 18,
            cat_id: 12,
            text: "Use bicycles for local or short commute?",
            type: "radio",
            image: bicycle,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'Hardly',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Sometimes',
                    score: 2
                },
                {
                    id: 3,
                    opt: 'Frequently',
                    score: 3
                }
            ]
        },
        {
            id: 19,
            cat_id: 12,
            text: "Switch off appliances from plug points when not in use?",
            type: "radio",
            image: kitchen,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'No',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Yes',
                    score: 2
                }
            ]
        },
        {
            id: 20,
            cat_id: 12,
            text: "Take the stairs instead of an elevator wherever possible?",
            type: "radio",
            image: stairs,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'Hardly',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Sometimes',
                    score: 2
                },
                {
                    id: 3,
                    opt: 'Frequently',
                    score: 3
                }
            ]
        },
        {
            id: 21,
            cat_id: 12,
            text: "Opt for buckets instead of showers for bathing purposes?",
            type: "radio",
            image: shower,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'Hardly',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Sometimes',
                    score: 2
                },
                {
                    id: 3,
                    opt: 'Frequently',
                    score: 3
                }
            ]
        },
        {
            id: 22,
            cat_id: 12,
            text: "Reuse water from water purifier / AC to water plants or clean surfaces?",
            type: "radio",
            image: gardeningWater,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'Hardly',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Sometimes',
                    score: 2
                },
                {
                    id: 3,
                    opt: 'Frequently',
                    score: 3
                }
            ]
        },
        {
            id: 23,
            cat_id: 12,
            text: "Turn off running taps when not in active use?",
            type: "radio",
            image: waterTap,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'No',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Yes',
                    score: 2
                }
            ]
        },
        {
            id: 24,
            cat_id: 12,
            text: "Carry your own non-plastic water bottle wherever possible?",
            type: "radio",
            image: bottle,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'Hardly',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Sometimes',
                    score: 2
                },
                {
                    id: 3,
                    opt: 'Frequently',
                    score: 3
                }
            ]
        },
        {
            id: 25,
            cat_id: 12,
            text: "Use cloth bag instead of plastic bags?",
            type: "radio",
            image: shoppingBag,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'Hardly',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Sometimes',
                    score: 2
                },
                {
                    id: 3,
                    opt: 'Frequently',
                    score: 3
                }
            ]
        },
        {
            id: 26,
            cat_id: 12,
            text: "Opt for reusable steel lunchboxes?",
            type: "radio",
            image: lunch,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'Hardly',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Sometimes',
                    score: 2
                },
                {
                    id: 3,
                    opt: 'Frequently',
                    score: 3
                }
            ]
        },
        {
            id: 27,
            cat_id: 12,
            text: "Repair and use electronic devices over discarding the devices?",
            type: "radio",
            image: repair,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'Hardly',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Sometimes',
                    score: 2
                },
                {
                    id: 3,
                    opt: 'Frequently',
                    score: 3
                }
            ]
        },
        {
            id: 28,
            cat_id: 12,
            text: "Responsibly dispose of e-waste at appropriate collection centres?",
            type: "radio",
            image: seperateWaste,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'No',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Yes',
                    score: 2
                }
            ]
        },
        {
            id: 29,
            cat_id: 12,
            text: "Segregate e-waste from regular waste at home / school?",
            type: "radio",
            image: seperateEwaste,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'No',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Yes',
                    score: 2
                }
            ]
        },
        {
            id: 30,
            cat_id: 12,
            text: "Refrain from carrying plastic / disposable cutlery to school?",
            type: "radio",
            image: canteen,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'Hardly',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Sometimes',
                    score: 2
                },
                {
                    id: 3,
                    opt: 'Frequently',
                    score: 3
                }
            ]
        },
        {
            id: 31,
            cat_id: 12,
            text: "Compost food waste at home / school?",
            type: "radio",
            image: compost,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'Hardly',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Sometimes',
                    score: 2
                },
                {
                    id: 3,
                    opt: 'Frequently',
                    score: 3
                }
            ]
        },
        {
            id: 32,
            cat_id: 12,
            text: "Helped to create a kitchen garden / terrace gardens at home / school?",
            type: "radio",
            image: kitchenGarden,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'No',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Yes',
                    score: 2
                }
            ]
        },
        {
            id: 33,
            cat_id: 12,
            text: "Refrain from littering on campus?",
            type: "radio",
            image: toilet,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'Hardly',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Sometimes',
                    score: 2
                },
                {
                    id: 3,
                    opt: 'Frequently',
                    score: 3
                }
            ]
        },
        {
            id: 34,
            cat_id: 12,
            text: "Recycle and reuse paper from old notebooks and newspapers?",
            type: "radio",
            image: news,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'Hardly',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Sometimes',
                    score: 2
                },
                {
                    id: 3,
                    opt: 'Frequently',
                    score: 3
                }
            ]
        },
        {
            id: 35,
            cat_id: 12,
            text: "Set the AC temperature to 24 degrees in classrooms / laboratories or at home?",
            type: "radio",
            image: weatherTemp,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'Hardly',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Sometimes',
                    score: 2
                },
                {
                    id: 3,
                    opt: 'Frequently',
                    score: 3
                }
            ]
        },
        {
            id: 36,
            cat_id: 12,
            text: "Donate old clothes and books?",
            type: "radio",
            image: books,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'No',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Yes',
                    score: 2
                }
            ]
        },
        {
            id: 37,
            cat_id: 12,
            text: "Set printer default to double-side printing?",
            type: "radio",
            image: printer,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'No',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Yes',
                    score: 2
                }
            ]
        },
        {
            id: 38,
            cat_id: 12,
            text: "Practice segregation of dry and wet waste?",
            type: "radio",
            image: dryWetWaste,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'No',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Yes',
                    score: 2
                }
            ]
        },
        {
            id: 39,
            cat_id: 12,
            text: "Initiate and/or join green clubs in your school / residential area?",
            type: "radio",
            image: ecoClub,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'No',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Yes',
                    score: 2
                }
            ]
        },
        {
            id: 40,
            cat_id: 12,
            text: "Plant trees to reduce the impact of pollution?",
            type: "radio",
            image: plantation,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'Hardly',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Sometimes',
                    score: 2
                },
                {
                    id: 3,
                    opt: 'Frequently',
                    score: 3
                }
            ]
        },
        {
            id: 41,
            cat_id: 12,
            text: "Refill ink in pens and reuse old stationary?",
            type: "radio",
            points: 0,
            image: stationary,
            option: [
                {
                    id: 1,
                    opt: 'Hardly',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Sometimes',
                    score: 2
                },
                {
                    id: 3,
                    opt: 'Frequently',
                    score: 3
                }
            ]
        },
        {
            id: 42,
            cat_id: 12,
            text: "Use public transport and carpooling wherever possible?",
            type: "radio",
            image: publicBus,
            points: 0,
            option: [
                {
                    id: 1,
                    opt: 'Hardly',
                    score: 1
                },
                {
                    id: 2,
                    opt: 'Sometimes',
                    score: 2
                },
                {
                    id: 3,
                    opt: 'Frequently',
                    score: 3
                }
            ]
        }
    ];


    const [currQuesIndex, setCurrQuesIndex] = useState(0);
    const [questions, setQuestions] = useState(tempQuestion);
    const [responses, setResponses] = useState(Array(tempQuestion.length).fill(null));
    const [progress, setProgress] = useState(0);
    const [completed, setCompleted] = useState(false);

    const optionImages = {
        3: [option1Img, option2Img, option3Img],
        2: [option1Img, option3Img]
    };

    useEffect(() => {
        setProgress((currQuesIndex / (questions.length - 1)) * 100);
    }, [currQuesIndex]);

    useEffect(() => {
        if (responses.every(response => response !== null)) {
            setCompleted(true);
        }
    }, [responses]);

    const handleNext = () => {
        if (currQuesIndex < questions.length - 1) {
            setCurrQuesIndex(currQuesIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currQuesIndex > 0) {
            setCurrQuesIndex(currQuesIndex - 1);
        }
    };

    const handleOptionClick = (optionIndex) => {
        const newResponses = [...responses];
        newResponses[currQuesIndex] = optionIndex;
        setResponses(newResponses);

        if (currQuesIndex === questions.length - 1) {
            const totalHighestMarks = calculateTotalHighestMarks();
            const percentage = calculatePercentage(newResponses, totalHighestMarks);
            console.log('Your percentage:', percentage);
        } else {
            handleNext();
        }
    };

    const calculateTotalHighestMarks = () => {
        let totalMarks = 0;
        questions.forEach(question => {
            let highestScore = 0;
            question.option.forEach(option => {
                if (option.score > highestScore) {
                    highestScore = option.score;
                }
            });
            totalMarks += highestScore;
        });
        return totalMarks;
    };

    const calculatePercentage = (responses, totalHighestMarks) => {
        let totalScore = 0;
        responses.forEach((response, index) => {
            if (response !== null) {
                totalScore += questions[index].option[response].score;
            }
        });
        const percentage = (totalScore / totalHighestMarks) * 100;
        return percentage.toFixed(2);
    };
    const handleTryAgain = () => {
        setCompleted(false);
        setCurrQuesIndex(0);
        setResponses(Array(tempQuestion.length).fill(null));
        setProgress(0);
        console.log("Trying Again !!");
    }
    const question = questions[currQuesIndex];
    const imagesToShow = optionImages[question.option.length] || [];

    return (
        <>
            {!completed ? (
                <div className="action-calculator">
                    <h2 className="heading">Yuvamanthan - UNDP Sustainability Action Calculator</h2>
                    <div className="progress-container">
                        <ProgressBar bgColor="green" progress={progress} />
                        <span>{progress.toFixed(0)}%</span>
                    </div>
                    <div className="questions-box">
                        <div className="img-wrapper">
                            <img src={tempQuestion[currQuesIndex].image} alt={question.text} />
                        </div>
                        <h3>{question.text}</h3>
                    </div>
                    <div className="options-container">
                        {currQuesIndex > 0 && (
                            <div className="prev-btn change-btn" onClick={handlePrev}>
                                <span><FaAngleLeft /></span>
                                Prev
                            </div>
                        )}
                        <div className="options-wrapper">
                            {question.option.map((optionObj, index) => (
                                <button
                                    key={index}
                                    className={`option ${responses[currQuesIndex] === index ? 'selected' : ''}`}
                                    onClick={() => handleOptionClick(index)}
                                >
                                    <div className="img-wrapper">
                                        <img src={imagesToShow[index] || option1Img} alt={optionObj.opt} />
                                    </div>
                                    <span>{optionObj.opt}</span>
                                </button>
                            ))}
                        </div>
                        {currQuesIndex < questions.length - 1 && (
                            <div className="next-btn change-btn" onClick={handleNext}>
                                <span><FaAngleRight /></span>
                                Next
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <CompleteCalculator percentage={calculatePercentage(responses, calculateTotalHighestMarks())} handleTryAgain={handleTryAgain} />
            )}
        </>
    );
}

export default ActionCalculator;
