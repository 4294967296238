import React, { useEffect, useState } from 'react'
import { Dropdown } from "react-bootstrap";
import { Modal } from 'react-bootstrap';
import { TailSpin } from "react-loader-spinner";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import Select from 'react-select';
import { apiJson, apiJsonAuth } from 'api';
import { useGlobalContext } from 'global/context';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Avatar from '@mui/material/Avatar';

export const Add_Account_manager = () => {
  const { userData } = useGlobalContext()
  const [spinner, setSpinner] = useState(false);
  const [openSearch, setOpenSearch] = useState(false)
  const [managerModal, setManagerModel] = useState(false)
  const [inputDisebled, setInputDisebled] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [modalText,setModalText] = useState("")
  const [typeManager, setTypeManager] = useState("Admin")
  const [searchTitle, SetSearchTitle] = useState("");
  const [showDesList, setShowDesList] = useState([])
  const [allUserList, setAllUserList] = useState([])
  const [searchResults, setSearchResults] = useState([]);
  const [studentId, setStudentId] = useState()
  const [instituteId,setInstituteId] = useState()
  const [managerId,setManagerId] = useState()
  // const [selectedDesignation, setSelectedDesignation] = useState('')

  // const getInstDetails=async()=>{
  //   try {
  //     const result = await apiJsonAuth.get(`api/v2/institute/getInsituteData/${userData?.id}`)
  //     setSelectedDesignation(result?.data?.result?.type_of_inst)
  //   } catch (error) {
  //     console.log(error?.message)
  //   }
  // }
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ cursor: 'pointer' }}>
      {children}
    </span>
  ));

  //============  Get All Student and teacher of particular institute  =================\\
  const getAllUserList = async () => {
    try {
      const res = await apiJson.get(`/api/v2/institute/getInsituteUsers/${userData?.id}`)
      setSearchResults(res?.data?.allUser)
    } catch (error) {
      console.log("Error in All User", error)
    }
  }
  //===============  Get all institute account manager of particular institute  ==============\\
  const getAllAccManagerList = async () => {
    try {
      setSpinner(true)
      const res = await apiJson.get(`/api/v2/institute/getAccManager/${userData?.id}`)
      if (res?.status === 200) {
        setAllUserList(res?.data?.getAllManager)
        setSpinner(false)
      }
    } catch (error) {
      console.log("Error in All User", error)
      setSpinner(false)
    }
  }

  useEffect(() => {
    // getInstDetails()
    setShowDesList(designationList)
    getAllUserList()
    getAllAccManagerList()
  }, [])



  ///  ============ User Search modal hide handler ===================== \\
  const searchHideHandle = () => {
    setOpenSearch(false)
  }
  //======== Open manager add modal  =================\\

  const showManagerModal = (modal) => {
    // setModalText(modal)
    // if(modal === "add"){
    //   formik.resetForm({
    //     values: {
    //       name: '',
    //       designation: '',
    //       email: '',
    //       phone: '',
    //       typeOfManager: '',
    //     },
    //   });
    //   searchHideHandle()
    // }
    // setInputDisebled(false)
    setManagerModel({
      add: modal === "add" ? !managerModal.add : false,
      edit: modal === "edit" ? !managerModal.edit : false,

    })

  }
  ///=========  A function for make manager,moderator to an  existing  user of institute ==============\\
  const handleData = (email,modal) => {
    setModalText(modal)
    let singleData = searchResults?.find((i) => i?.email === email);
    formik.setValues({
      designation: singleData?.role,
      email: singleData?.email,
      name: singleData?.name,
      phone: singleData?.phone,
      typeOfManager: ""
    });
    setStudentId(singleData?.id)
    setInputDisebled(true)
    setManagerModel({
      add: modal === "add" ? !managerModal.add : false,
      edit: modal === "edit" ? !managerModal.edit : false,

    })
    searchHideHandle()
  };

  //========  Validation Schema for formik state============\\
  const validation = Yup.object({
    name: Yup.string().required('Required'),
    designation: Yup.string().required('Required'),
    email: Yup.string().email().required('Required'),
    phone: Yup.string() // Use string for mobile number
      .required('Required')
      .matches(/^[0-9]{10}$/, 'Invalid mobile number'),
    typeOfManager: Yup.string().required('Required'),
  })
  ///========== Initial value of formik  ============= \\
  const initialValues = {
    name: '',
    designation: '',
    email: '',
    phone: '',
    typeOfManager: '',
  }
  // ===============  A function for add the moderator and manager  =================\\
  const onSubmit = async (values, { resetForm }) => {
  if(modalText =="add"){
    try {
      setIsSubmitting(true)
      const res = await apiJsonAuth.post(`api/v2/institute/postAddManager`, { ...values, studentId, instituteId: userData?.id })
      if (res?.status === 201) {
        toast.dismiss()
        toast.success(res?.data?.message)
        resetForm();
        showManagerModal("add");
        setStudentId()
        getAllAccManagerList();
        setIsSubmitting(false)
      }
    } catch (error) {
      console.log(error)
      showManagerModal("add");
      setIsSubmitting(false)
      toast.error("Server Error")
    }}else{
   try {
      setIsSubmitting(true)
      const res = await apiJsonAuth.put(`api/v2/institute/editAddManager/${managerId}`, { ...values, studentId, instituteId})
      if (res?.status === 200) {
        resetForm();
        showManagerModal("edit");
        setManagerId();
        setStudentId();
        setInstituteId();
        getAllAccManagerList();
        setIsSubmitting(false)
        toast.dismiss();
        console.log(res?.data?.message)
        toast.success(res?.data?.message)
      }
    } catch (error) {
      console.log(error)
      showManagerModal("edit");
      setIsSubmitting(false)
      toast.error("Server Error")
    }
    }

    // resetForm();
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit
  })

  ///=========== A function for edit  user  type of manager   ================\\

  const handleEditManager = (id,modal) => {
    setModalText(modal)
    showManagerModal(modal)
    let data = allUserList?.find((i)=>i?.id === id)
    // console.log(data)
    formik.setValues({
      designation: data?.designation,
      email: data?.email,
      name: data?.name,
      phone: data?.phone,
      typeOfManager:data?.type_of_manager
    });
    setManagerId(data?.id)
    setStudentId(data?.studentId)
    setInstituteId(data?.instituteId)
    setInputDisebled(true)
  }
  ///=========== A function for Delete user  type of manager   ================\\

  const handleDeleteManager = async (id) => {
    try {
      const res = await apiJsonAuth.delete(`api/v2/institute/deleteAccManager/${id}`)
      if (res?.status === 200) {
        toast.success(res?.data?.message)
        getAllAccManagerList();
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }
  return (
    <>
      <div>
        <div className='container col-10 mx-auto mt-4'>
          <div>
            <span className="fw-500 fs-20px" style={{ color: "#979797" }}>

            </span>
          </div>

          <div className="d-flex justify-content-md-between flex-column flex-md-row col-md-10">
            <div className="mt-3 mb-2">
              <span className="fw-600 fs-32px">Manage Account Manager</span>
            </div>

            <div className="mt-3">
              <button
                onClick={() => setOpenSearch(true)}
                className="fw-400 text-center"
                style={{
                  height: "40px",
                  width: "115px",
                  background: "#DA9EFF",
                  color: "#39005B",
                  fontSize: "17.5px",
                  borderRadius: "5px",
                }}
              >
                Add New
              </button>
            </div>
          </div>

          <div
            className="tab-content mt-3 col-10"
            id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabindex="0"
            >
              <div className="">
                <table class="table">

                  {spinner ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <TailSpin />
                    </div>
                  ) : allUserList.length <= 0 ? (
                    <img src="/nodata.jpg" alt="" className="d-block m-auto" width="50%" />
                  ) : (
                    <>
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Manager Type</th>
                          <th scope="col">Designation</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>

                      <tbody>

                        {spinner ? <div className="d-flex justify-content-center align-items-center"><TailSpin /></div> :
                          allUserList?.map((ele, i) => {
                            return (
                              <>
                                <tr key={i}>

                                  <td className="col-3">
                                    <div>
                                      {" "}
                                      <span
                                        className="fw-400"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {ele?.name}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <span
                                      className="fw-400"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {ele?.type_of_manager}
                                    </span>{" "}
                                  </td>
                                  <td>
                                    <span
                                      className="fw-400"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {ele?.designation}
                                    </span>
                                  </td>
                                  <td>
                                    <Dropdown align="start" className="exclude-this-click">
                                      <Dropdown.Toggle
                                        as={CustomToggle}
                                      >
                                        <span
                                          className="fw-400"
                                          style={{ fontSize: "14px" }}
                                        >
                                          <MoreVertIcon
                                            sx={{ color: "#23538f", fontSize: "25px" }}
                                          />
                                        </span>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        style={{
                                          position: 'absolute',
                                          inset: '0px auto auto 0px',
                                          transform: 'translate(-167px, 1px)',
                                          // background: "rgb(130 73 222)"
                                        }}
                                        className="dropdown-menu-left dropdown-menu-custom-class">
                                        <Dropdown.Item className="update-delete-btn" onClick={() => handleDeleteManager(ele?.id)}>Delete Member</Dropdown.Item>
                                        <Dropdown.Item className="update-delete-btn" onClick={() => handleEditManager(ele?.id,"edit")}>Edit Member</Dropdown.Item>

                                      </Dropdown.Menu>
                                    </Dropdown>

                                  </td>
                                </tr>

                              </>

                            );
                          })}
                      </tbody>
                    </>
                  )
                  }

                </table>
              </div>
            </div>
          </div>
{/* ///==============   A Modal for  Open search user which is existing in the institute =========================\\ */}
          <div className="container" >
            <Modal show={openSearch} className="left-rounded-modal" onHide={searchHideHandle} size="lg">
              <div className="modal-content">
                <span className="content-title fw-600 color-black fs-2 ps-4 px-md-5 mt-3">
                  Add Account Manager
                </span>
                <span className="fs-19px ps-4  px-md-5 mt-4" style={{ color: "#000000" }}>Search for the Student/Teacher here by typing their name</span>
                <Modal.Body>

                  <form>
                    <div className="mt-2 px-2 px-md-4 px-lg-4">
                      <input className="search-handle px-2" type="text" name="name" placeholder="Type a name" onChange={(e) => SetSearchTitle(e.target.value)} />

                    </div>
                    <div className="bg-light search-handleList mt-3 px-2 mx-2 mx-md-4 rounded-3">
                      <div className="databox" style={{overflowY:"auto"}}>

                        {searchResults?.filter((value) => {
                          if (searchTitle === "") {
                            return value;
                          } else if (
                            value.name.toLowerCase().includes(searchTitle.toLowerCase())
                          ) {
                            return value;
                          }

                        }).map((i) =>
                        (

                          <div className="d-flex justify-content-between mt-2 cursor-pointer border-1  p-3 rounded-3" onClick={() => handleData(i?.email,"add")} key={i?.id} style={{background:"#e4e4e4"}}>
                            <div className="d-flex align-items-center">
                            <Avatar alt={i?.name} src={i?.profile} />
                            <span className="fs-19px text-capitalize ms-2">{i?.name}</span>
                            </div>
                            <span className="fs-19px text-capitalize" style={{ color: "#5A00EE" }}>{i?.role}</span>
                          </div>
                        )
                        )}
                      </div>
                    </div>

                  </form>
                </Modal.Body>
              </div>
            </Modal>
          </div>
{/* ///==============   A Modal for  Open modal for add account manager which is not existing in the institute =========================\\ */}
          <div className="container">
            <Modal show={managerModal?.add} className="left-rounded-modal" onHide={()=>showManagerModal("add")} size="lg" centered>
              <div className="modal-content">
                <Modal.Body className="p-5" >
                  <form onSubmit={formik.handleSubmit} >
                    <div>
                      <div className="d-flex justify-content-between mb-4 ps-2">
                        <span className="fs-2 fw-600 color-black ">New Account Manager</span>
                        <span className="d-block d-md-none" onClick={() => setManagerModel(false)}><CloseTwoToneIcon /></span>
                      </div>
                      <div >
                        <div className="row">
                          <div className="col-12 col-lg-6 mb-3">
                            <span className="fs-19px fw-500 color-black">Full Name</span>
                            <input type='text' value={formik?.values?.name} readOnly={inputDisebled} onChange={(e) => {
                              // handleFormData('name', e.target.value)
                              formik.setFieldValue('name', e.target.value)
                            }} placeholder="Name here..." className="form-control border-0 box-shadow-0 background-grey" />
                            {
                              formik.errors.name && formik.touched.name && <span className="text-danger">{formik.errors.name}</span>
                            }
                          </div>
                          <div className="col-12 col-lg-6 mb-3">
                            <span className="fs-19px fw-500 color-black">Designation</span>
                            <Select
                              styles={customStyles}
                              options={showDesList}
                              // value={formik?.values?.designation}
                              defaultValue={showDesList.find((i) => i?.value === formik?.values?.designation)}
                              isDisabled={inputDisebled}
                              onChange={(e) => {
                                // handleFormData('designation', e.name)
                                formik.setFieldValue('designation', e.value)
                              }}
                              getOptionLabel={
                                (option) => {
                                  return (
                                    <>
                                      <div>
                                        <span>{option.name}</span>
                                      </div>
                                    </>
                                  )
                                }
                              }
                            />
                            {
                              formik.errors.designation && formik.touched.designation && <span className="text-danger">{formik.errors.designation}</span>
                            }
                          </div>
                          <div className="col-12 col-lg-6 mb-3">
                            <span className="fs-19px fw-500 color-black">Email ID</span>
                            <input placeholder="abc@abc.com" type='email' readOnly={inputDisebled} value={formik?.values?.email} onChange={(e) => {
                              formik.setFieldValue('email', e.target.value)
                            }} className="form-control border-0 box-shadow-0 background-grey" />
                            {
                              formik.errors.email && formik.touched.email && <span className="text-danger">{formik.errors.email}</span>
                            }
                          </div>
                          <div className="col-12 col-lg-6 mb-3">
                            <span className="fs-19px fw-500 color-black">Phone</span>
                            <input placeholder="99******99" type='number' readOnly={inputDisebled} value={formik?.values?.phone} onChange={(e) => {
                              formik.setFieldValue('phone', e.target.value)
                            }} className="form-control border-0 box-shadow-0 background-grey" />
                            {
                              formik.errors.phone && formik.touched.phone && <span className="text-danger">{formik.errors.phone}</span>
                            }
                          </div>
                          <div className="col-12 col-lg-6 mb-3">
                            <span className="fs-19px fw-500 color-black">Type of Manager</span>
                            <Select
                              styles={customStyles}
                              options={designations}
                              onChange={(selected) => {
                                setTypeManager(selected.des)
                                formik.setFieldValue('typeOfManager', selected?.des)
                              }}
                              getOptionLabel={
                                (option) => {
                                  return (
                                    <>
                                      <div>
                                        <span>{option.des}</span>
                                      </div>
                                    </>
                                  )
                                }
                              }
                            />
                            {
                              formik.errors.typeOfManager && formik.touched.typeOfManager && <span className="text-danger">{formik.errors.typeOfManager}</span>
                            }
                          </div>
                          <div className="col-12 col-lg-6 mb-3">
                            <div className="mb-4"></div>
                            <button style={{
                              width: '249px',
                              height: '50px'
                            }} type='submit'
                              disabled={isSubmitting}
                              className="col-11 col-lg-5 text-center color-white fs-19px fw-500  background-purple-button rounded-4 d-block m-auto d-md-inline-block">
                              Add {typeManager}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <div className="background-purple rounded px-2 py-2 mb-40 border-purple mt-3 col-12">
                    {
                      typeManager === "Admin" ? (
                        <>
                          <span className="fs-6 fw-semibold color-purple ">
                            <span className="me-2">
                              {<img src="/images/onBoarding/note.png" />}
                            </span>An Account Admin can register for events and perform all admin duties. Please
                            <br />
                            <span style={{ marginLeft: "33px" }}>only add competent teachers as admins.</span>
                          </span>
                        </>
                      ) : typeManager === "Moderator" ? (
                        <>
                          <span className="fs-6 fw-semibold color-purple "> <span className="me-2">
                            {<img src="/images/onBoarding/note.png" />}
                          </span>A Moderator can verify students who wish to access the portal and perform
                            <br />
                            <span className="" style={{ marginLeft: "33px" }}>admin duties like
                              posting, announcements and managing events.</span>
                            {/* <br/> */}
                            {/* <span style={{marginLeft: "33px"}}>managing events.</span> */}

                          </span>
                        </>
                      ) : (
                        <>
                          <span className="fs-6 fw-semibold color-purple ">
                            <span className="me-2">
                              {<img src="/images/onBoarding/note.png" />}
                            </span>Add students as Account Managers, they will be allowed to post on behalf of the <br />
                            <span className="" style={{ marginLeft: "33px" }}> Institute,and make announcements.</span>
                            {/* <br/> */}
                            {/* <span style={{marginLeft: "33px"}}>managing events.</span> */}
                          </span>
                        </>
                      )
                    }
                  </div>
                </Modal.Body>
              </div>
            </Modal>
          </div>
          {/* ///==============   A Modal for  Open modal for add account manager which is not existing in the institute =========================\\ */}
          <div className="container">
            <Modal show={managerModal?.edit} className="left-rounded-modal" onHide={()=>showManagerModal("edit")} size="lg" centered>
              <div className="modal-content">
                <Modal.Body className="p-5" >
                  <form onSubmit={formik.handleSubmit} >
                    <div>
                      <div className="d-flex justify-content-between mb-4 ps-2">
                        <span className="fs-2 fw-600 color-black ">Update Account Manager</span>
                        <span className="d-block d-md-none" onClick={() => setManagerModel(false)}><CloseTwoToneIcon /></span>
                      </div>
                      <div >
                        <div className="row">
                          <div className="col-12 col-lg-6 mb-3">
                            <span className="fs-19px fw-500 color-black">Full Name</span>
                            <input type='text' value={formik?.values?.name} readOnly={inputDisebled} onChange={(e) => {
                              // handleFormData('name', e.target.value)
                              formik.setFieldValue('name', e.target.value)
                            }} placeholder="Name here..." className="form-control border-0 box-shadow-0 background-grey" />
                            {
                              formik.errors.name && formik.touched.name && <span className="text-danger">{formik.errors.name}</span>
                            }
                          </div>
                          <div className="col-12 col-lg-6 mb-3">
                            <span className="fs-19px fw-500 color-black">Designation</span>
                            <Select
                              styles={customStyles}
                              options={showDesList}
                              // value={formik?.values?.designation}
                              defaultValue={showDesList.find((i) => i?.value === formik?.values?.designation)}
                              isDisabled={inputDisebled}
                              onChange={(e) => {
                                // handleFormData('designation', e.name)
                                formik.setFieldValue('designation', e.value)
                              }}
                              getOptionLabel={
                                (option) => {
                                  return (
                                    <>
                                      <div>
                                        <span>{option.name}</span>
                                      </div>
                                    </>
                                  )
                                }
                              }
                            />
                            {
                              formik.errors.designation && formik.touched.designation && <span className="text-danger">{formik.errors.designation}</span>
                            }
                          </div>
                          <div className="col-12 col-lg-6 mb-3">
                            <span className="fs-19px fw-500 color-black">Email ID</span>
                            <input placeholder="abc@abc.com" type='email' readOnly={inputDisebled} value={formik?.values?.email} onChange={(e) => {
                              formik.setFieldValue('email', e.target.value)
                            }} className="form-control border-0 box-shadow-0 background-grey" />
                            {
                              formik.errors.email && formik.touched.email && <span className="text-danger">{formik.errors.email}</span>
                            }
                          </div>
                          <div className="col-12 col-lg-6 mb-3">
                            <span className="fs-19px fw-500 color-black">Phone</span>
                            <input placeholder="99******99" type='number' readOnly={inputDisebled} value={formik?.values?.phone} onChange={(e) => {
                              formik.setFieldValue('phone', e.target.value)
                            }} className="form-control border-0 box-shadow-0 background-grey" />
                            {
                              formik.errors.phone && formik.touched.phone && <span className="text-danger">{formik.errors.phone}</span>
                            }
                          </div>
                          <div className="col-12 col-lg-6 mb-3">
                            <span className="fs-19px fw-500 color-black">Type of Manager</span>
                            <Select
                              styles={customStyles}
                              options={designations}
                              onChange={(selected) => {
                                setTypeManager(selected.des)
                                formik.setFieldValue('typeOfManager', selected?.des)
                              }}
                              defaultValue={designations.find((i) => i?.des === formik?.values?.typeOfManager)}
                              getOptionLabel={
                                (option) => {
                                  return (
                                    <>
                                      <div>
                                        <span>{option.des}</span>
                                      </div>
                                    </>
                                  )
                                }
                              }
                            />
                            {
                              formik.errors.typeOfManager && formik.touched.typeOfManager && <span className="text-danger">{formik.errors.typeOfManager}</span>
                            }
                          </div>
                          <div className="col-12 col-lg-6 mb-3">
                            <div className="mb-4"></div>
                            <button style={{
                              width: '249px',
                              height: '50px'
                            }} type='submit'
                              disabled={isSubmitting}
                              className="col-11 col-lg-5 text-center color-white fs-19px fw-500  background-purple-button rounded-4 d-block m-auto d-md-inline-block">
                             Update {typeManager}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <div className="background-purple rounded px-2 py-2 mb-40 border-purple mt-3 col-12">
                    {
                      typeManager === "Admin" ? (
                        <>
                          <span className="fs-6 fw-semibold color-purple ">
                            <span className="me-2">
                              {<img src="/images/onBoarding/note.png" />}
                            </span>An Account Admin can register for events and perform all admin duties. Please
                            <br />
                            <span style={{ marginLeft: "33px" }}>only add competent teachers as admins.</span>
                          </span>
                        </>
                      ) : typeManager === "Moderator" ? (
                        <>
                          <span className="fs-6 fw-semibold color-purple "> <span className="me-2">
                            {<img src="/images/onBoarding/note.png" />}
                          </span>A Moderator can verify students who wish to access the portal and perform
                            <br />
                            <span className="" style={{ marginLeft: "33px" }}>admin duties like
                              posting, announcements and managing events.</span>
                            {/* <br/> */}
                            {/* <span style={{marginLeft: "33px"}}>managing events.</span> */}

                          </span>
                        </>
                      ) : (
                        <>
                          <span className="fs-6 fw-semibold color-purple ">
                            <span className="me-2">
                              {<img src="/images/onBoarding/note.png" />}
                            </span>Add students as Account Managers, they will be allowed to post on behalf of the <br />
                            <span className="" style={{ marginLeft: "33px" }}> Institute,and make announcements.</span>
                            {/* <br/> */}
                            {/* <span style={{marginLeft: "33px"}}>managing events.</span> */}
                          </span>
                        </>
                      )
                    }
                  </div>
                </Modal.Body>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  )
}
const designations = [
  {
    value: 1,
    des: 'Admin',
  },
  {
    value: 2,
    des: 'Moderator',
  },
  {
    value: 3,
    des: 'Manager',
  },
]

const designationList = [
  { value: 'student', name: 'Student' },
  { value: 'teacher', name: 'teacher' }
]
const designationSchool = [
  { value: 'Principal', name: 'Principal' },
  { value: 'Vice Principal', name: 'Vice Principal' },
  { value: 'Headmaster/Headmistress', name: 'Headmaster/Headmistress' },
  { value: 'School Administrator', name: 'School Administrator' },
  { value: 'School Coordinator', name: 'School Coordinator' },
  { value: 'Teacher', name: 'Teacher' },
  {
    value: 'Subject Teacher (e.g., English Teacher, Math Teacher, Science Teacher)',
    name: 'Subject Teacher (e.g., English Teacher, Math Teacher, Science Teacher)'
  },
  { value: 'Special Educator', name: 'Special Educator' },
  { value: 'School Counselor', name: 'School Counselor' },
  { value: 'Librarian', name: 'Librarian' },
  {
    value: 'Physical Education Teacher (PET)',
    name: 'Physical Education Teacher (PET)'
  },
  { value: 'Lab Assistant', name: 'Lab Assistant' },
  {
    value: 'Administrative Staff (e.g., Accountant, Office Assistant)',
    name: 'Administrative Staff (e.g., Accountant, Office Assistant)'
  },
  {
    value: 'IT Coordinator/IT Teacher',
    name: 'IT Coordinator/IT Teacher'
  },
  { value: 'Art Teacher', name: 'Art Teacher' },
  { value: 'Music Teacher', name: 'Music Teacher' },
  { value: 'Dance Teacher', name: 'Dance Teacher' },
  { value: 'Sports Coach', name: 'Sports Coach' }
]

const designationUniversityCollege = [
  { value: 'Vice Chancellor', name: 'Vice Chancellor' },
  { value: 'Pro Vice-Chancellor', name: 'Pro Vice-Chancellor' },
  { value: 'Dean', name: 'Dean' },
  {
    value: 'Head of Department (HOD)',
    name: 'Head of Department (HOD)'
  },
  { value: 'Professor', name: 'Professor' },
  { value: 'Associate Professor', name: 'Associate Professor' },
  { value: 'Assistant Professor', name: 'Assistant Professor' },
  { value: 'Lecturer', name: 'Lecturer' },
  { value: 'Research Scholar', name: 'Research Scholar' },
  { value: 'Registrar', name: 'Registrar' },
  {
    value: 'Director (of specific schools, institutes, or centers)',
    name: 'Director (of specific schools, institutes, or centers)'
  },
  { value: 'Librarian', name: 'Librarian' },
  { value: 'Student Advisor', name: 'Student Advisor' },
  {
    value: 'Administrative Staff (e.g., Accountant, Office Assistant)',
    name: 'Administrative Staff (e.g., Accountant, Office Assistant)'
  },
  { value: 'Laboratory Technician', name: 'Laboratory Technician' },
  {
    value: 'IT Coordinator/IT Faculty',
    name: 'IT Coordinator/IT Faculty'
  },
  {
    value: 'Physical Education Instructor (PEI)',
    name: 'Physical Education Instructor (PEI)'
  },
  { value: 'Career Counselor', name: 'Career Counselor' },
  { value: 'Placement Officer', name: 'Placement Officer' },
  { value: 'Research Assistant', name: 'Research Assistant' }
];


const designationCoachingInstitute = [
  { value: 'Director', name: 'Director' },
  { value: 'Academic Head', name: 'Academic Head' },
  { value: 'Subject Experts/Faculty', name: 'Subject Experts/Faculty' },
  { value: 'Teaching/Subject Mentor', name: 'Teaching/Subject Mentor' },
  { value: 'Content Developer', name: 'Content Developer' },
  { value: 'Counselor', name: 'Counselor' },
  { value: 'Academic Coordinator', name: 'Academic Coordinator' },
  { value: 'Center Manager', name: 'Center Manager' },
  { value: 'Marketing Manager', name: 'Marketing Manager' },
  { value: 'Admission Counselor', name: 'Admission Counselor' },
  { value: 'Front Office Executive', name: 'Front Office Executive' },
  { value: 'Online Platform Manager', name: 'Online Platform Manager' },
  {
    value: 'Digital Marketing Executive',
    name: 'Digital Marketing Executive'
  },
  { value: 'Course Coordinator', name: 'Course Coordinator' },
  { value: 'Administrative Staff', name: 'Administrative Staff' },
  { value: 'Test Coordinator', name: 'Test Coordinator' },
  { value: 'Research Analyst', name: 'Research Analyst' },
  { value: 'IT Support Specialist', name: 'IT Support Specialist' },
  {
    value: 'Online Content Moderator',
    name: 'Online Content Moderator'
  },
  {
    value: 'Online Learning Facilitator',
    name: 'Online Learning Facilitator'
  }
];
const customStyles = {
  control: (provided) => ({
    ...provided,
    padding: '3px',
    border: 'none',
    background: '#eeeeee4d',
    // boxShadow:'none'
    padding: 8
  }),

};
