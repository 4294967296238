import React, { useEffect, useState } from 'react';
import './munlandingpage.css';
import { apiJson } from 'api';
import { useGlobalContext } from 'global/context';
import StudentpollPage from 'pages/student/studentPoll/StudentpollPage';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

const YuvaRightSideBar = () => {

  const navigate = useNavigate()
  const [questionArray, setQuestionArray] = useState([])
  const [questionNo, setQuestionNo] = useState(0)
  const [questionId, setQuestionId] = useState(0)
  const [optionString, setOptionString] = useState()
  const {userData} = useGlobalContext()
  const [attempt, setAttempt] = useState(null)
  const [modalShow, setModalShow] = React.useState(false);
  const [trendingBlogs, setTrendingBlogs] = useState([]);

  const getQuestionId = (id)=>{
    setQuestionId(id)
  }
  const getOptionString = (value)=>{
    setOptionString(value)
  }

  const getAllQuestion = ()=>{
    apiJson(`api/v2/institute/getAllPollsQuestion`)
    .then((res)=>{
      setQuestionArray(res.data.result)
    })
  }

  const handleNextButton = ()=>{
    setQuestionNo((prev)=>prev+1<=(questionArray?.length-1)?(prev+1):questionArray?.length-1)
    checkAttemp()
  }
  const handlePrevButton = ()=>{
    setQuestionNo((prev)=>{
      return prev-1>=0?prev-1:0
    })
    checkAttemp()
  }

  const pollData = {
    "studentId":userData.id, 
    "questionId":questionId,
    "vote":optionString
  }

  const selectedPollOption = async ()=>{
    await apiJson.post(`api/v2/institute/selectedPollOption` , pollData)
    .then(()=>checkAttemp())
  }

  useEffect(()=>{
    if(optionString){
      selectedPollOption()
    }
  },[optionString])


  useEffect(()=>{
    getAllQuestion()
  },[])

  const checkAttemp =async ()=>{
    const result = await apiJson(`api/v2/institute/checkUserAlreadyAttempt/studentId/${userData?.id}/questionId/${questionId}`)
    .then((res)=>{
      setAttempt(res.data.result)
    })
  }

  useEffect(() => {
    checkAttemp()
  }, [questionId,optionString])

  const trendingBlogss = async ()=>{
    const result = await apiJson(`api/v2/institute/getLatestPolls`)
    .then((res)=>{
      setTrendingBlogs(res?.data?.data)
    })
  }
  useEffect(()=>{
    trendingBlogss()
  },[])


  return (
    <>
      <div
        style={{
          background: "white",
        }}
      >
        <div className="Yuvarightsiderbar p-4  w-100">
          {/* ======================un right sider bar =================== */}

          {/* <div className="p-4 d-none d-lg-block w-100 mt-5">
          <div className="model-sider-items">
            <p className="px-3">Resources</p>

            <div className="right-siderbar-inneritems p-2">
              <img src="/modelUn/Video Play.svg" alt="videoplayer" />
              <span>Orientation on YMG20</span>
            </div>
            <div className="right-siderbar-inneritems p-2">
              <img src="/modelUn/earth.svg" className="px-2" alt="videoplayer" />
              <span>Explore themes</span>
            </div>
            <div className="right-siderbar-inneritems p-2">
              <img src="/modelUn/leaf.svg" className="px-2 py-1" alt="videoplayer" />
              <span>Orientation on YMG20</span>
            </div>
            <div className="right-siderbar-inneritems p-2">
              <img src="/modelUn/books.svg" className="px-2 py-1" alt="videoplayer" />
              <span>Orientation on YMG20</span>
            </div>
          </div>
        </div> */}

          {/* =============================right side bar============================= */}
          <div className="model-main-rightSiderbar py-4 d-none d-lg-block">
            {userData?.role === "institute" && (
              <div className="model-sider-items">
                <p className="px-3">Resources</p>

                <div
                  onClick={() => navigate("/Download/EventCollaterals")}
                  className="cursor-pointer right-siderbar-inneritems p-2 py-2"
                >
                  <img
                    src="drive.svg"
                    className="px-2 py-1"
                    alt="videoplayer"
                  />
                  <span>Background Guides</span>
                </div>
                <div
                  onClick={() => navigate("/Download/EventCollaterals")}
                  className="cursor-pointer right-siderbar-inneritems p-2 py-2"
                >
                  <img src="drive.svg" className="px-2" alt="videoplayer" />
                  <span>Event Collaterals</span>
                </div>
                {/* <div className="right-siderbar-inneritems p-2 py-2">
              <img src="drive.svg" className="px-2 py-1" alt="videoplayer" />
              <span>Event Collaterals</span>
            </div>
            <div className="right-siderbar-inneritems p-2 py-2">
              <img src="drive.svg" className="px-2 py-1" alt="videoplayer" />
              <span>Certificates</span>
            </div>
            <div className="right-siderbar-inneritems p-2 py-2">
              <img src="drive.svg" className="px-2 py-1" alt="videoplayer" />
              <span>Score Sheets</span>
            </div>
            <div className="right-siderbar-inneritems p-2">
              <img src="drive.svg" className="px-2 py-1" alt="videoplayer" />
              <span>Backdrop & Standee</span>
            </div> */}
              </div>
            )}
          </div>
          {/* <div className="yuva-box-shadow p-3 d-none">
              <p>Live Polls</p>

             {questionArray?.length>0 && <PollsQuestion arr={arr} data ={questionArray[questionNo]} checkAttemp={checkAttemp} attempt={attempt} questionid={getQuestionId} getOptionString={getOptionString} selectedPollOption={selectedPollOption} />}

              <div className="livePoll-btns d-flex justify-content-between">
                <button onClick={handlePrevButton}>Previous</button>
                <button onClick={handleNextButton}>Next</button>
              </div>
            </div> */}

          {/* ===========================new poll system ============================== */}

          <div className="m-auto w-100">
            <p
              className="text-white text-center fs-6 fw-bolder mt-3 cursor-pointer bg-primary rounded-3 px-5 py-3"
              onClick={() => setModalShow(true)}
            >
              Open Polls
            </p>
          </div>

          {/* ===================================== trending blogs ======================== */}

          <div className="trending-yuva-blogs mt-4 p-2">
            <div className="tending-heading d-flex justify-content-between align-items-center">
              <p className="fs-5" >Trending blogs</p>
            </div>

            <div className="trending-content row">
              {trendingBlogs.map((val, ind) => (
                <div
                  className="d-flex gap-2 justify-content-between mt-2 cursor-pointer border col-12 col-md-6"
                  key={val?.id}
                  onClick={() => navigate(`/blog/${val?.slug}`)}
                >
                  <div className="trending-innnercontent m-0 p-1">
                    <img src={val?.img} alt="blogImg" width="100%" height={200} style={{objectFit:"cover", objectPosition:"center"}} />
                    <p className="m-0" style={{fontSize:"0.8rem"}}>{val?.title}</p>
                    <p className="m-0" style={{fontSize:"0.8rem"}}>{val?.heading}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal
        scrollable
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        centered
      >
        <Modal.Body>
          <StudentpollPage></StudentpollPage>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-3 bg-danger border-0"
            onClick={() => setModalShow(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


const PollsQuestion = ({data, questionid, getOptionString, selectedPollOption ,attempt, checkAttemp, arr })=>{
  const options = JSON.parse(data?.options)
  questionid(data?.id)
  const  optionArray = Object.values(data?.options)
  
  const getString = (val)=>{
    getOptionString(val)
  }

  return (
    <>
    <div>
      <span>{data?.poll_ques}</span>
    </div>
     <div>

     {options &&
  Object.values(options).map((option, index) => (
    
    <div key={option} className="my-3 yuva-progressbar position-relative rounded h-fit d-flex justify-content-between " >
      <div style={{width:'60%'}} className={`yuvaBar ${attempt? null:'d-none'}`}></div>
      <div className="rounded d-flex justify-content-between mt-2 px-2 w-100 z-index">
        <div>
          <input
            type="checkbox"
            name="checkbox"
            id={`checkbox-${data?.poll_ques}-${option}`}
            className={`me-2 z-3 ${attempt? 'd-none':null}`}
            onClick={()=>{
              getString(option)
               checkAttemp()
               }}
          />
            
        </div>
          <span className='poll-para'>{option}</span>
          {
            arr.map((val, ind)=>{
              return <span className={`${attempt? null:'d-none'}`}>{val}%</span>
            })
          }
      </div>
    </div>
  ))
}
     </div>

    </>
  )
}


export default YuvaRightSideBar