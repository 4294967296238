import React from 'react'
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

export const DiscussionBoardStudentProfileList = ({ data, handleRemoveUser }) => {

    return (
        <>

                <div className='d-flex flex-row align-items-center'>
                    <div style={{ width: "45px", height: "45px" }}>
                        <Avatar src={data?.profile ? data?.profile : "/ui2.0dashboard/Rectangle 3148.png"} alt={data?.first_name} className="h-100 w-100" />
                    </div>
                    <span className='mx-2  fw-semibold'>{`${data?.first_name} ${data?.last_name}`}</span>
                </div>
                <div className="d-flex">
                    <Chip label={data?.role} sx={{ background: "#EDDEFF", color: "#7700FF" }} variant="outlined" />
                    <span className='cursor-pointer ms-1 mt-1' onClick={() => handleRemoveUser(data?.value)} ><CancelTwoToneIcon color="error" /></span>
                </div>
    
        </>
    )
}
